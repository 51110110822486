import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const HamburgerIcon = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      role="img"
      aria-labelledby="burgerButtonTitle"
    >
      <title id="burgerButtonTitle">{t("Open navigation menu")}</title>
      <path
        fill="none"
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth="3"
        d="M2,5 L22,5 M2,12 L22,12 M2,19 L22,19"
      />
    </svg>
  );
};
export default HamburgerIcon;
