import { Typography } from "antd";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles";

const NoTalentFound = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div css={styles.noTalentSearchContainer}>
      <div css={styles.noTalentSearchContentContainer}>
        <Typography.Text css={styles.textHeader}>
          {t("TalentPage##No contact found")}
        </Typography.Text>
        <Typography.Text css={styles.searchTextContainer}>
          {t(
            "TalentPage##Try removing some of the applied filters, or use a wider search term"
          )}
        </Typography.Text>
      </div>
    </div>
  );
};

export default NoTalentFound;
