import { Col, Row, Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { IProjectTerm } from "src/pages/projects/project/interfaces";
import Term from "./components/term";
import sharedStyles from "../details/styles";
import styles from "./styles";

interface IProps {
  terms?: IProjectTerm[];
}

const Licensing = ({ terms }: IProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Row css={styles.licensingRow}>
      <Col css={styles.wrapper} flex="auto" span={24}>
        <Typography css={[sharedStyles.sectionHeader, styles.licensingHeader]}>
          {t("ProjectsPage##licensingSection##Licensing")}
        </Typography>
        {terms &&
          terms.map((term, index) => {
            return (
              <div
                key={`${term.id}-container`}
                css={[styles.termContainer, index > 0 && styles.extraPadding]}
              >
                <Term term={term} key={term.id} />
              </div>
            );
          })}
      </Col>
    </Row>
  );
};

export default Licensing;
