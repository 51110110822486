import { Form, Input, Switch, Typography } from "antd";
import { startCase } from "lodash";
import {
  IContactReferences,
  IService,
  IThirdParty,
  MusicTypes,
  ServicesTypes,
} from "src/pages/projects/project/interfaces";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next/";
import Button from "src/components/button";
import ReactSelect, { OnChangeValue } from "react-select";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import SecondaryModalButtonLink from "src/components/modals/modal-buttons/secondary-button-link";
import mainStyle from "src/pages/projects/project/styles";
import CurrencyAndPrice from "src/components/currency-and-price";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import { Currencies } from "src/constants";
import { useForm } from "antd/lib/form/Form";
import { formatCurrency } from "src/utils/currency";
import { ErrorToast } from "src/components/toast-notification";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import { customStyles } from "../../../details/styles";
import { IOption, ServiceFormType } from "../../types";
import styles from "../../styles";
import ThirdParties from "../thirdParties";
import TalentHubInput from "../../../talent-hub-input";

interface IProps {
  projectService?: IService;
  type: ServiceFormType;
  serviceChanged: boolean;
  isServicesLoading?: boolean;
  talentsResponse?: IContactsResponse;
  areTalentsLoading: boolean;
  agenciesAndContacts: IAgencyWithContactsResults[];
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onFormSubmit: (service: IService, action: ServiceFormType) => void;
  onFormCancel: () => void;
  onChangesMade: (updatedServices: { [serviceId: string]: boolean }) => void;
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
}

const ServicesForm = ({
  projectService,
  serviceChanged,
  type,
  isServicesLoading,
  talentsResponse,
  agenciesAndContacts,
  areTalentsLoading,
  onScrollTalents,
  onFormSubmit,
  onFormCancel,
  onChangesMade,
  onUpdateNarrowSearchText,
  onResetNarrowSearch,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = useForm();
  useWindowSize();
  const isMobile = getIsMobile();
  const talentHubRef = useRef<HTMLDivElement>(null);

  const getSelectedTalents = useCallback(() => {
    if (
      projectService?.serviceProvider?.name &&
      projectService?.serviceProvider?.email
    ) {
      return projectService.serviceProvider;
    }
    return undefined;
  }, [projectService?.serviceProvider]);

  const [selectedTalents, setSelectedTalents] = useState<
    IContactReferences | undefined
  >(getSelectedTalents());

  const [thirdPartyServices, setThirdPartyServices] = useState<IThirdParty[]>(
    projectService?.thirdParties ?? []
  );

  const [serviceType, setServiceType] = useState<IOption | undefined>(
    projectService?.serviceType
      ? { label: projectService.serviceType, value: projectService.serviceType }
      : undefined
  );
  const [feeAmount, setFeeAmount] = useState(projectService?.feeAmount);
  const [taxWithholdingFeeAmount, setTaxWithholdingFeeAmount] = useState(
    projectService?.taxWithholdingFee
  );
  const [bankFeeAmount, setBankFeeAmount] = useState(projectService?.bankFee);
  const [musicType, setMusicType] = useState<IOption | undefined>(
    projectService?.musicType
      ? { value: projectService?.musicType, label: projectService?.musicType }
      : undefined
  );
  const [serviceName, setServiceName] = useState(projectService?.asset?.name);
  const [serviceUrl, setServiceUrl] = useState(projectService?.asset?.link);

  const [thirdPartyInvolved, setThirdPartyToggle] = useState(
    projectService?.thirdPartyInvolved
  );

  const updateServiceChanged = useCallback(() => {
    if (!serviceChanged) {
      onChangesMade({ [projectService?.id ?? "new"]: true });
    }
  }, [onChangesMade, projectService?.id, serviceChanged]);

  const musicTypeOptions = useMemo(
    () =>
      Object.values(MusicTypes)
        .sort((a, b) => a.localeCompare(b))
        .map((key) => ({
          value: key,
          label: startCase(key),
        })),
    []
  );

  const servicesTypeOptions = useMemo(
    () =>
      Object.keys(ServicesTypes)
        .sort((a, b) => a.localeCompare(b))
        .map((key) => ({
          value: key,
          label: startCase(key),
        })),
    []
  );

  const handleSubmit = useCallback(() => {
    // Due to the way the backend handles patches we need to conditional set these fields
    const hasAllThirdPartyServiceSelected = thirdPartyServices
      .map((service) => service.thirdParty?.id)
      .every((service) => service);
    const hasServiceTypeSelected = serviceType?.value;
    if (!hasAllThirdPartyServiceSelected || !hasServiceTypeSelected) {
      ErrorToast(
        "project-validation-error",
        "Fill the required fields, before saving this service."
      );

      return;
    }
    let thirdPartyData;

    if (thirdPartyInvolved && thirdPartyServices) {
      thirdPartyData = thirdPartyServices;
    }

    const selectedServiceType = serviceType?.value;
    const selectedMusicType = musicType?.value;

    onFormSubmit(
      {
        serviceType: selectedServiceType,
        musicType: selectedMusicType,
        asset: {
          name: serviceName,
          link: serviceUrl,
        },
        serviceProvider: selectedTalents,
        feeAmount,
        taxWithholdingFee: taxWithholdingFeeAmount,
        bankFee: bankFeeAmount,
        thirdPartyInvolved,
        thirdParties: thirdPartyData,
        id: projectService?.id ?? "",
        version: projectService?.version,
      },
      type
    );
  }, [
    thirdPartyServices,
    serviceType?.value,
    thirdPartyInvolved,
    musicType?.value,
    onFormSubmit,
    serviceName,
    serviceUrl,
    selectedTalents,
    feeAmount,
    taxWithholdingFeeAmount,
    bankFeeAmount,
    projectService?.id,
    projectService?.version,
    type,
  ]);

  const handleSetThirdPartyServices = useCallback(
    (items: IThirdParty[]) => {
      setThirdPartyServices(items);
      updateServiceChanged();
    },
    [updateServiceChanged]
  );

  const handleToggleChange = useCallback(
    (value: boolean) => {
      if (!value) {
        handleSetThirdPartyServices([]);
      }
      setThirdPartyToggle(value);
      updateServiceChanged();
    },
    [handleSetThirdPartyServices, updateServiceChanged]
  );

  const handleServiceChange = useCallback(
    (newValue: OnChangeValue<IOption, false>) => {
      if (newValue !== null) {
        setServiceType(newValue);
        updateServiceChanged();
      }
    },
    [updateServiceChanged]
  );

  const handleMusicChange = useCallback(
    (newValue: OnChangeValue<IOption, false>) => {
      if (newValue !== null) {
        setMusicType(newValue);
        updateServiceChanged();
      }
    },
    [updateServiceChanged]
  );

  const handleSetFeeAmount = useCallback(
    (fieldName: string, value: IMonetaryValue) => {
      switch (fieldName) {
        case "serviceFee":
          setFeeAmount(value);
          break;
        case "taxWithholdingFee":
          setTaxWithholdingFeeAmount(value);
          break;
        case "bankFee":
          setBankFeeAmount(value);
          break;
        default:
          break;
      }
      updateServiceChanged();
    },
    [updateServiceChanged]
  );

  const handleSetServiceName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setServiceName(e.target.value);
      updateServiceChanged();
    },
    [updateServiceChanged]
  );

  const handleSetServiceUrl = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setServiceUrl(e.target.value);
      updateServiceChanged();
    },
    [updateServiceChanged]
  );

  const currencyOptions: IOption[] = useMemo(
    () =>
      Object.values(Currencies).map((value) => {
        return {
          value: value.code,
          label: value.displayText,
        };
      }),
    []
  );

  const getCurrency = useCallback(
    (currentCurrency: string) =>
      currencyOptions.find((option) => option.value === currentCurrency),
    [currencyOptions]
  );

  const serviceFeeCurrency = useMemo(
    () => feeAmount?.currency ?? Currencies.USD.code,
    [feeAmount?.currency]
  );
  const serviceFeePrice = useMemo(() => feeAmount?.value ?? "", [
    feeAmount?.value,
  ]);
  const taxWithholdingFeeCurrency = useMemo(
    () => taxWithholdingFeeAmount?.currency ?? Currencies.USD.code,
    [taxWithholdingFeeAmount?.currency]
  );
  const taxWithholdingFeePrice = useMemo(
    () => taxWithholdingFeeAmount?.value ?? "",
    [taxWithholdingFeeAmount?.value]
  );

  const bankFeeCurrency = useMemo(
    () => bankFeeAmount?.currency ?? Currencies.USD.code,
    [bankFeeAmount?.currency]
  );
  const bankFeePrice = useMemo(() => bankFeeAmount?.value ?? "", [
    bankFeeAmount?.value,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      serviceFeePrice: serviceFeePrice
        ? formatCurrency(serviceFeePrice)
        : serviceFeePrice,
      taxWithholdingFeePrice: taxWithholdingFeePrice
        ? formatCurrency(taxWithholdingFeePrice)
        : taxWithholdingFeePrice,
      bankFeePrice: bankFeePrice ? formatCurrency(bankFeePrice) : bankFeePrice,
    });
  }, [
    bankFeePrice,
    form,
    getCurrency,
    serviceFeePrice,
    taxWithholdingFeePrice,
  ]);

  const validationFormItemFieldNames = useMemo(() => {
    const generateRowKeys = (fieldName: string, index: number) => {
      return [`${fieldName}${index}Service`];
    };
    const generateThirdPartyServiceKeys = (
      fieldName: string,
      splits: IThirdParty[]
    ) => {
      let keys: string[] = [];
      if ((splits ?? []).length === 0) {
        keys = [...generateRowKeys(fieldName, 0)];
      } else {
        splits.forEach((_, index) => {
          keys = [...keys, ...generateRowKeys(fieldName, index)];
          return keys;
        });
      }
      return keys;
    };

    return ["serviceType"].concat(
      generateThirdPartyServiceKeys("thirdParty", thirdPartyServices ?? [])
    );
  }, [thirdPartyServices]);

  const initialThirdPartyServices = useMemo(() => {
    return (thirdPartyServices ?? [])
      .map(
        (
          {
            thirdPartyFee,
            thirdParty,
            taxWithholdingFee,
            bankFee,
          }: IThirdParty,
          index
        ) => {
          return {
            [`thirdParty${index}Currency`]: getCurrency(
              thirdPartyFee?.currency ?? Currencies.USD.code
            ),
            [`thirdParty${index}Price`]: thirdPartyFee?.value
              ? formatCurrency(thirdPartyFee.value)
              : "",
            [`thirdParty${index}Service`]: !thirdParty?.id
              ? null
              : {
                  label: thirdParty?.name ?? "",
                  value: thirdParty?.id ?? "",
                },
            [`bankFee${index}Currency`]: getCurrency(
              bankFee?.currency ?? Currencies.USD.code
            ),
            [`bankFee${index}Price`]: bankFee?.value
              ? formatCurrency(bankFee?.value)
              : "",
            [`taxWithholdingFee${index}Currency`]: getCurrency(
              taxWithholdingFee?.currency ?? Currencies.USD.code
            ),
            [`taxWithholdingFee${index}Price`]: taxWithholdingFee?.value
              ? formatCurrency(taxWithholdingFee?.value)
              : "",
          };
        }
      )
      .reduce((cv, pv) => ({ ...pv, ...cv }), {});
  }, [getCurrency, thirdPartyServices]);

  useEffect(() => {
    form.setFieldsValue(initialThirdPartyServices);
  }, [form, initialThirdPartyServices, thirdPartyServices]);

  useEffect(() => {
    void form.validateFields(validationFormItemFieldNames);
  }, [thirdPartyServices, form, validationFormItemFieldNames]);

  const handleSelectedTalentsChange = useCallback(
    (selectedValues: IContactReferences[]) => {
      // We will always have only one value
      const selectedValue = selectedValues[0];
      setSelectedTalents(selectedValue);
      updateServiceChanged();
    },
    [updateServiceChanged]
  );

  return (
    <Form
      form={form}
      initialValues={{
        serviceType,
        serviceFeeCurrency: getCurrency(serviceFeeCurrency),
        serviceFeePrice: serviceFeePrice
          ? formatCurrency(serviceFeePrice)
          : serviceFeePrice,
        taxWithholdingFeeCurrency: getCurrency(taxWithholdingFeeCurrency),
        taxWithholdingFeePrice: taxWithholdingFeePrice
          ? formatCurrency(taxWithholdingFeePrice)
          : taxWithholdingFeePrice,
        bankFeeCurrency: getCurrency(bankFeeCurrency),
        bankFeePrice: bankFeePrice
          ? formatCurrency(bankFeePrice)
          : bankFeePrice,
        ...initialThirdPartyServices,
      }}
      validateTrigger="onChange"
      scrollToFirstError
    >
      <div css={styles.serviceFormContainer} data-testid="add-service-section">
        <div
          css={
            isMobile
              ? mainStyle.mobileSectionContainer
              : mainStyle.clientContainer
          }
        >
          <div>
            <CurrencyAndPrice
              currency={serviceFeeCurrency}
              price={serviceFeePrice}
              currencyFormItemName="serviceFeeCurrency"
              priceFormItemName="serviceFeePrice"
              onChange={handleSetFeeAmount}
              label="Service fee"
              propertyName="serviceFee"
              validateRequiredFields={false}
            />
          </div>
          <div>
            <div css={mainStyle.basicInputContainer}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##servicesSection##Service type")}
              </Typography.Text>
            </div>
            <Form.Item
              name="serviceType"
              rules={[
                () => ({
                  validator(_rule, value?: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error("This is a required field")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <ReactSelect
                isClearable
                menuPlacement="auto"
                isSearchable
                placeholder=""
                styles={customStyles}
                onChange={handleServiceChange}
                options={servicesTypeOptions}
                data-testid="service-type-dropdown"
                id="serviceTypeDropdown"
                isMulti={false}
              />
            </Form.Item>
          </div>
          <div>
            <div css={mainStyle.basicInputContainer}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##servicesSection##Service name")}
              </Typography.Text>
            </div>
            <Input
              type="text"
              onChange={handleSetServiceName}
              defaultValue={serviceName}
              data-testid="service-name-textbox"
            />
          </div>
          <div>
            <div css={mainStyle.basicInputContainer} ref={talentHubRef}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##servicesSection##Service provider")}
              </Typography.Text>
            </div>
            <TalentHubInput
              talenthubwidth={talentHubRef.current?.clientWidth}
              selectedTalents={selectedTalents ? [selectedTalents] : []}
              talentsResponse={talentsResponse}
              agenciesAndContacts={agenciesAndContacts}
              areTalentsLoading={areTalentsLoading}
              isServiceProvider
              isMultiSelect={false}
              onScrollTalents={onScrollTalents}
              onSelectedTalentsChange={handleSelectedTalentsChange}
              onUpdateNarrowSearchText={onUpdateNarrowSearchText}
              onResetNarrowSearch={onResetNarrowSearch}
            />
          </div>
          <div>
            <div css={mainStyle.basicInputContainer}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##servicesSection##Music type")}
              </Typography.Text>
            </div>
            <ReactSelect
              isClearable
              isSearchable
              placeholder=""
              menuPlacement="auto"
              styles={customStyles}
              onChange={handleMusicChange}
              defaultValue={musicType}
              options={musicTypeOptions}
              data-testid="music-type-dropdown"
              id="serviceMusicTypeDropdown"
              isMulti={false}
            />
          </div>
          <div>
            <div css={mainStyle.basicInputContainer}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##servicesSection##URL")}
              </Typography.Text>
            </div>
            <Input
              type="text"
              defaultValue={serviceUrl}
              onChange={handleSetServiceUrl}
              data-testid="service-url-textbox"
            />
          </div>
        </div>
        <div
          css={
            isMobile
              ? mainStyle.mobileSectionContainer
              : mainStyle.clientContainer
          }
        >
          <div>
            <CurrencyAndPrice
              currency={taxWithholdingFeeCurrency}
              price={taxWithholdingFeePrice}
              currencyFormItemName="taxWithholdingFeeCurrency"
              priceFormItemName="taxWithholdingFeePrice"
              onChange={handleSetFeeAmount}
              label="Tax withholding fee"
              propertyName="taxWithholdingFee"
              validateRequiredFields={false}
            />
          </div>
          <div>
            <CurrencyAndPrice
              currency={bankFeeCurrency}
              price={bankFeePrice}
              currencyFormItemName="bankFeeCurrency"
              priceFormItemName="bankFeePrice"
              onChange={handleSetFeeAmount}
              label="Bank fee"
              propertyName="bankFee"
              validateRequiredFields={false}
            />
          </div>
          <div />
          <div css={styles.thirdPartyContainer}>
            <Typography.Text
              css={mainStyle.basicInputLabel}
              style={{ whiteSpace: "nowrap" }}
            >
              {t(
                "ProjectsPage##servicesSection##Has a 3rd party carried out this service?"
              )}
            </Typography.Text>
            <Switch
              onChange={handleToggleChange}
              checked={thirdPartyInvolved}
            />
            <SecondaryModalButtonLink
              onClick={onFormCancel}
              ariaLabel="Cancel"
              buttonText="Cancel"
            />
            <Button
              loading={isServicesLoading}
              noLabelTranslation
              ariaLabel="Submit"
              css={styles.submitButton}
              onClick={handleSubmit}
              data-testid="add-update-service-button"
            >
              {type === ServiceFormType.Add
                ? t("ProjectsPage##servicesSection##Add service")
                : t("ProjectsPage##servicesSection##Update service")}
            </Button>
          </div>
        </div>
        {thirdPartyInvolved && (
          <ThirdParties
            form={form}
            talentsResponse={talentsResponse}
            thirdPartyServices={thirdPartyServices}
            areTalentsLoading={areTalentsLoading}
            agenciesAndContacts={agenciesAndContacts}
            onScrollTalents={onScrollTalents}
            setThirdPartyServices={handleSetThirdPartyServices}
            onThirdPartyToggleChange={handleToggleChange}
            onUpdateNarrowSearchText={onUpdateNarrowSearchText}
            onResetNarrowSearch={onResetNarrowSearch}
          />
        )}
      </div>
    </Form>
  );
};

export default ServicesForm;
