import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  button: css`
    border: 1.2px solid ${theme.colors.primary.blue} !important;
    color: ${theme.colors.primary.blue};
    background: #e5e5e5;
    width: 144px;
    min-height: 56px;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  buttonRow: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
  clearText: css`
    color: ${theme.colors.primary.blue};
    margin-right: 16px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    height: 25px;
    cursor: pointer;
  `,
  text: css`
    margin-left: 5px;
  `,
};
