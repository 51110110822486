import { ReactElement } from "react";
import IIconComponentProps from "../../interfaces";

const DocumentsIconSmall = ({
  height = "24",
  width = "24",
  title,
}: IIconComponentProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <title id="documents-icon-title">{title}</title>
      <path
        d="M14.4038 13.9692H9.49142C9.40015 13.9692 9.32617 14.0432 9.32617 14.1345V15.126C9.32617 15.2172 9.40015 15.2912 9.49142 15.2912H14.4038C14.4951 15.2912 14.5691 15.2172 14.5691 15.126V14.1345C14.5691 14.0432 14.4951 13.9692 14.4038 13.9692Z"
        fill="#10267C"
      />
      <path
        d="M13.0819 9.62585H9.49142C9.40015 9.62585 9.32617 9.69984 9.32617 9.7911V10.7826C9.32617 10.8738 9.40015 10.9478 9.49142 10.9478H13.0819C13.1731 10.9478 13.2471 10.8738 13.2471 10.7826V9.7911C13.2471 9.69984 13.1731 9.62585 13.0819 9.62585Z"
        fill="#10267C"
      />
      <path
        d="M13.7429 11.7975H9.49142C9.40015 11.7975 9.32617 11.8715 9.32617 11.9627V12.9542C9.32617 13.0455 9.40015 13.1195 9.49142 13.1195H13.7429C13.8341 13.1195 13.9081 13.0455 13.9081 12.9542V11.9627C13.9081 11.8715 13.8341 11.7975 13.7429 11.7975Z"
        fill="#10267C"
      />
      <path
        d="M12.4475 18.095H7.23134C7.10344 18.095 7 17.9916 7 17.8637V13.3865C7 13.2586 7.10377 13.1552 7.23134 13.1552H8.09062C8.21852 13.1552 8.32197 13.2589 8.32197 13.3865V16.5414C8.32197 16.6693 8.42541 16.7727 8.55331 16.7727H12.4475C12.5754 16.7727 12.6788 16.8762 12.6788 17.0041V17.8633C12.6788 17.9912 12.5754 18.0947 12.4475 18.0947V18.095Z"
        fill="#10267C"
      />
      <path
        d="M16.7683 18.095H14.7874C14.6595 18.095 14.556 17.9916 14.556 17.8637V17.0044C14.556 16.8765 14.6595 16.7731 14.7874 16.7731H15.4464C15.5743 16.7731 15.6777 16.6696 15.6777 16.5417V10.7429C15.6777 10.6824 15.6539 10.6243 15.6116 10.581L11.9931 6.8917C11.9494 6.84741 11.89 6.8223 11.8278 6.8223H8.55331C8.42541 6.8223 8.32197 6.92574 8.32197 7.05364V10.3619C8.32197 10.4898 8.21852 10.5932 8.09062 10.5932H7.23134C7.10344 10.5932 7 10.4898 7 10.3619V5.73134C7 5.60344 7.10344 5.5 7.23134 5.5H12.383C12.4452 5.5 12.5047 5.52512 12.5483 5.5694L16.9336 10.0406C16.9759 10.0839 16.9997 10.1421 16.9997 10.2026V17.8633C16.9997 17.9913 16.8962 18.0947 16.7683 18.0947V18.095Z"
        fill="#10267C"
      />
    </svg>
  );
};

export default DocumentsIconSmall;
