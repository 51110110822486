import axios from "axios";
import {
  ICreateTermRequest,
  ICreateTermResponse,
} from "src/pages/projects/project/interfaces";
import { createTermUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const createTerm = async (
  accessToken: string,
  projectId: string,
  createRequest: ICreateTermRequest
): Promise<ICreateTermResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.post(createTermUrl(projectId), createRequest, options);
};

export default createTerm;
