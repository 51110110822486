import config from "../config/config";

export const getThirdPartyServicesUrl = (organisationId: string): string => {
  return `${config.projectManagementService.url}/third-parties?orgId=${organisationId}`;
};

export const createThirdPartyServicesUrl = (): string => {
  return `${config.projectManagementService.url}/third-parties`;
};

export const getThirdPartyServicesDeleteUrl = (
  organisationId: string,
  thirdPartyId: string
): string => {
  return `${config.projectManagementService.url}/third-parties/${thirdPartyId}?orgId=${organisationId}`;
};
