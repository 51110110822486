import { debounce } from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import getSharedFileUrl from "src/api/attachments/get-shared-file-url";
import getPublicProjectSummary from "src/api/projects/get-public-project-summary";
import useAuth from "src/auth/use-auth";
import { handlePublicFileDownload } from "src/components/file-upload/helpers";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { IProjectUploadFile } from "src/pages/projects/project/components/details/components/interfaces";
import {
  IProjectAttachment,
  IProjectForm,
  IProjectSharePublicParams,
} from "src/pages/projects/project/interfaces";
import Share from "..";
import { formatAttachments } from "../../utils";

const ShareContainer = (): ReactElement => {
  const { getAccessToken, organisationId } = useAuth();
  const { projectId } = useParams<IProjectSharePublicParams>();
  const [tracks, setTracks] = useState<IProjectAttachment[]>([]);
  const [projectData, setProjectData] = useState<IProjectForm>();
  const shareCode = window.location.pathname.split("/").pop();
  const { t } = useTranslation();

  const publicDownloadFile = debounce(
    async (file: IProjectUploadFile): Promise<void> => {
      SuccessToast(t("File downloading to your file storage"));

      if (shareCode) {
        const downloadFileRequest = await handlePublicFileDownload(
          file,
          shareCode,
          projectId
        );

        if (!downloadFileRequest) {
          ErrorToast(
            `FileUploadErrorToast${file.uid}`,
            t("There was a problem downloading the file")
          );
        }
      }
    },
    500
  );

  useEffect(() => {
    async function fetchProjectPreview() {
      if (shareCode) {
        const response = await getPublicProjectSummary(
          shareCode,
          projectId,
          organisationId
        );
        if (response) {
          const project = formatAttachments(response);
          setProjectData(project);

          if (project.attachments) {
            const trackData = project.attachments.filter(
              (track) => track.attachmentType === "FinalTrack"
            );

            trackData.map(async (track) => {
              const presignedResponse = await getSharedFileUrl(
                projectId,
                track.id,
                shareCode
              );

              const value = {
                ...track,
                url: presignedResponse.data.url,
              };
              setTracks((previous) => [...previous, value]);
            });
          }
        }
      }
    }

    void fetchProjectPreview();
  }, [getAccessToken, projectId, shareCode, organisationId]);

  return (
    <Share
      projectData={projectData}
      publicDownloadFile={publicDownloadFile}
      tracks={tracks}
    />
  );
};

export default ShareContainer;
