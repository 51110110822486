import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { Operation, TalentContactAttachmentType } from "src/constants";
import mainStyles from "src/pages/projects/project/styles";
import * as uuid from "uuid";
import { DocumentsTableColumns } from "src/pages/projects/project/components/documents/table-data";
import { useParams } from "react-router-dom";
import { FileDrawerTypes } from "src/pages/projects/project/components/documents/components/mobile-drawers";
import { getIsMobile } from "@songtradr/spa-common";
import useAuth from "src/auth/use-auth";
import MobileDrawer from "src/components/mobile-drawer";
import { cloneDeep } from "lodash";
import DisclaimerInfoIcon from "src/app/assets/icons/component-icons/disclaimer-info-icon";
import ShowReelLinkRow from "./showreel-link-row";
import FileUploader from "../file-uploader";
import { IDeleteSelectedFile } from "../file-uploader/interfaces";
import DocumentsTableData from "../file-uploader/table-data";
import { IContactUploadFile } from "../../interfaces";
import style from "./styles";
import { generateDrawer } from "../file-uploader/components/mobile-drawer";
import { checkFilesCurrentlyUploading } from "../file-uploader/helpers";

interface IProps {
  fieldName: string;
  items: string[];
  showReels: IContactUploadFile[];
  isEditMode: boolean;
  setShowReelLinks: (link: string[]) => void;
}

const ShowReel = ({
  fieldName,
  items,
  showReels,
  isEditMode,
  setShowReelLinks,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const [
    selectedFileToDelete,
    setSelectedFileToDelete,
  ] = useState<IDeleteSelectedFile>();
  const { id: contactId } = useParams<{ id: string }>();
  const { getAccessToken, organisationId } = useAuth();
  const [showReelsFileList, setShowReelsFileList] = useState<
    IContactUploadFile[]
  >([]);
  const handleSetShowReelFileList = useCallback(
    (files: IContactUploadFile[]) => {
      setShowReelsFileList(files);
    },
    []
  );
  const accessToken = useMemo(() => getAccessToken(), [getAccessToken]);
  const [showFileUploadDeleteModal, setShowFileUploadDeleteModal] = useState(
    false
  );
  const [activeDrawer, setActiveDrawer] = useState<FileDrawerTypes>(
    FileDrawerTypes.FileInfoDrawer
  );
  const [activeFile, setActiveFile] = useState<IContactUploadFile>();
  const [isDrawerVisible, setDrawerVisible] = useState<boolean>(false);

  const handleSetSelectedFileToDelete = useCallback(
    (file: IDeleteSelectedFile | undefined) => {
      setSelectedFileToDelete(file);
    },
    []
  );

  const handleShowFileUploadDeleteModal = useCallback((show: boolean) => {
    setShowFileUploadDeleteModal(show);
  }, []);

  const handleSetActiveDrawer = useCallback((drawer: FileDrawerTypes) => {
    setActiveDrawer(drawer);
  }, []);

  const handleSetActiveFile = useCallback(
    (file: IContactUploadFile | undefined) => {
      setActiveFile(file);
    },
    []
  );

  const toggleDrawer = useCallback(() => {
    setDrawerVisible((state) => !state);
  }, []);

  const onPerformOperation = useCallback(
    (operation: Operation, data?: string, index?: number) => {
      if (data !== undefined) {
        const isRemoveOperation = operation === Operation.remove;
        const isUpdateOperation = operation === Operation.update;
        const isValidIndex = index || index === 0;

        if (isRemoveOperation && isValidIndex) {
          const newArray = [...items];
          newArray.splice(index, 1);
          setShowReelLinks(newArray);
        } else if (operation === Operation.add) {
          setShowReelLinks([...items, data]);
        } else if (isUpdateOperation && isValidIndex) {
          const newArray = [...items];
          newArray[index] = data;
          setShowReelLinks(newArray);
        }
      }
    },
    [items, setShowReelLinks]
  );

  const handleAddRowItem = useCallback(() => {
    onPerformOperation(Operation.add, "", items.length);
  }, [items.length, onPerformOperation]);

  const handleDeleteRowItem = useCallback(
    (index: number) => {
      const itemToDelete = items[index];
      if (itemToDelete !== undefined) {
        onPerformOperation(Operation.remove, itemToDelete, index);
      }
    },
    [items, onPerformOperation]
  );

  const handleUpdateRowItem = useCallback(
    (index: number, value: string) => {
      onPerformOperation(Operation.update, value, index);
    },
    [onPerformOperation]
  );

  useEffect(() => {
    if (!checkFilesCurrentlyUploading(showReels)) {
      setShowReelsFileList(cloneDeep(showReels));
    }
  }, [showReels]);

  return (
    <div css={mainStyles.sectionFormContainer}>
      <Typography.Text css={mainStyles.basicInputLabel}>
        {t("ContactPage##Showreel")}
      </Typography.Text>
      {!isEditMode && (
        <Typography.Text css={mainStyles.disclaimerLabel}>
          <DisclaimerInfoIcon />
          {t(
            "ContactPage##Document/files can be uploaded once a contact is created"
          )}
        </Typography.Text>
      )}
      {isEditMode && (
        <>
          <div css={style.documentsUploadContainer}>
            <FileUploader
              attachmentType={TalentContactAttachmentType.ShowReel}
              invalidFileSizeErrorMessage={t(
                "File size exceeds maximum limit of 2GB"
              )}
              existingFiles={showReelsFileList}
              setFileList={handleSetShowReelFileList}
              tableData={DocumentsTableData({
                filesList: showReelsFileList,
                accessToken,
                contactId,
                organisationId,
                setShowModal: handleShowFileUploadDeleteModal,
                setSelectedFileToDelete: handleSetSelectedFileToDelete,
                toggleDrawer,
                setActiveDrawer: handleSetActiveDrawer,
                setActiveFile: handleSetActiveFile,
              })}
              selectedFileToDelete={selectedFileToDelete}
              tableColumns={DocumentsTableColumns(isMobile, t)}
              showModal={showFileUploadDeleteModal}
              setShowModal={handleShowFileUploadDeleteModal}
              additionalContainerStyles={style.documentsFileUploader}
            />
          </div>
          {isMobile && (
            <MobileDrawer
              placement="bottom"
              onClose={toggleDrawer}
              open={isDrawerVisible}
              key="bottom"
              closable={false}
              data-testid="mobile-drawer-container"
              className="documents-drawer-container"
            >
              {generateDrawer(
                activeDrawer,
                handleSetActiveDrawer,
                toggleDrawer,
                accessToken,
                contactId,
                organisationId,
                handleSetShowReelFileList,
                showReelsFileList,
                handleShowFileUploadDeleteModal,
                selectedFileToDelete,
                activeFile
              )}
            </MobileDrawer>
          )}
        </>
      )}
      {items.map((showReeLink, index) => {
        return (
          <ShowReelLinkRow
            key={`${fieldName}-linkRow-${uuid.v4()}`}
            data={showReeLink}
            index={index}
            totalRows={items.length}
            fieldName={fieldName}
            onAddNewRow={handleAddRowItem}
            onDeleteRow={handleDeleteRowItem}
            onUpdateRow={handleUpdateRowItem}
          />
        );
      })}
    </div>
  );
};

export default ShowReel;
