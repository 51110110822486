import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common/lib/utils";
import DeleteButton from "src/components/control-buttons/delete-button";
import ControlButton from "src/components/control-buttons/base-button";
import { PlusOutlined } from "@ant-design/icons";
import { INote } from "src/pages/contact/interfaces";
import styles from "./styles";
import mainStyles from "../../../styles";

interface IProps {
  data: INote;
  index: number;
  totalRows: number;
  fieldName: string;
  disableActions: boolean;
  userData: { userId: string; userFullname: string };
  onDeleteRow: (index: number) => void;
  onUpdateRow: (index: number, value: INote) => void;
  onAddNewRow: () => void;
}

const NoteRow = ({
  data,
  index,
  totalRows,
  fieldName,
  disableActions,
  userData,
  onAddNewRow,
  onDeleteRow,
  onUpdateRow,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  useWindowSize();
  const isMobile = getIsMobile();

  const showDeleteButton = useMemo(
    () => index !== 0 || (totalRows - 1 > 0 && index === 0),
    [index, totalRows]
  );

  return (
    <div
      css={
        isMobile
          ? styles.mobileMusicSectionContainer
          : styles.musicSectionContainer
      }
    >
      <div
        css={
          disableActions || isMobile
            ? mainStyles.entireSizeOfDiv
            : mainStyles.sizeNinetyPercent
        }
      >
        <Input.TextArea
          autoComplete="off"
          id={`${fieldName}-note-${index}`}
          aria-label={t("ContactPage##Note")}
          onBlur={(e) => {
            if (e.target.value !== data.note) {
              onUpdateRow(index, {
                note: e.target.value,
                date: data.date,
                ...userData,
              });
            }
          }}
          defaultValue={data.note}
          rows={5}
        />
      </div>
      {!disableActions && (
        <div css={styles.iconsContainer}>
          <div
            css={
              showDeleteButton
                ? styles.deleteIconContainer
                : styles.invisibleContainer
            }
          >
            <DeleteButton onClick={() => onDeleteRow(index)} />
          </div>
          <div
            css={
              index === totalRows - 1
                ? styles.addIconContainer
                : styles.invisibleContainer
            }
          >
            <div css={styles.addIconContainer}>
              <ControlButton
                showTooltip
                tooltipColor="#222222"
                label={t("Add new")}
                onClick={onAddNewRow}
              >
                <PlusOutlined />
              </ControlButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoteRow;
