import React, { ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Form, Typography } from "antd";
import Button from "src/components/button";
import styles from "src/components/modals/styles";
import STLoadingLogo from "src/components/st-loading-logo";
import { IDeleteProjectProps } from "src/pages/projects/project-listings/interfaces";

interface IProps {
  data?: IDeleteProjectProps;
  onClose?: () => void;
  handleDeleteProject: (id: string) => void;
}

const Content = ({
  data,
  onClose,
  handleDeleteProject,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  if (!data) {
    return <STLoadingLogo />;
  }

  return (
    <div data-testid="delete-project-modal">
      <Typography.Title css={styles.modalTitle}>Are you sure?</Typography.Title>
      <Form>
        <p>
          <Trans i18nKey="ProjectsPage##DeleteProject##confirmationPrompt">
            {{ projectName: data.name }}
          </Trans>
        </p>
        <div css={styles.footer}>
          <Button ariaLabel={t("Cancel")} type="secondary" onClick={onClose}>
            {t("Cancel")}
          </Button>
          <Button
            ariaLabel={t("ProjectsPage##DeleteProject##Delete project")}
            htmlType="submit"
            data-testid="delete-project-button"
            onClick={() => handleDeleteProject(data.id)}
          >
            {t("ProjectsPage##DeleteProject##Delete project")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Content;
