import { css } from "@emotion/react";
import { StylesConfig } from "react-select";
import theme from "src/theme";

type MyOptionType = {
  label: string;
  value: string;
};
export const customStyles: StylesConfig<MyOptionType> = {
  menu: (provided) => ({
    ...provided,
    fontWeight: 400,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 4,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
    color: `${theme.colors.primary.blue}`,

    ":hover": {
      color: `${theme.colors.secondary.lightGray}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 56,
    fontWeight: 400,
  }),
  control: (provided) => ({
    ...provided,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    border: `0.0625rem solid ${theme.colors.background.lightGray}`,
    borderRight: "none",
    maxHeight: 56,
  }),
};

export default {
  phoneContainer: css`
    display: flex;
    flex-direction: row;
  `,
  mobilePhoneContainer: css`
    display: flex;
    flex-direction: column;
  `,
  valueInput: css`
    :not(:focus) {
      border-left: none;
    }
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  `,
};
