import { Form, Input } from "antd";
import { RuleObject } from "antd/lib/form";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/button";
import FloatingLabel from "src/components/floating-label";
import SecondaryModalButtonLink from "src/components/modals/modal-buttons/secondary-button-link";
import { useImmer } from "use-immer";
import { IMediaTypeState } from "../../interfaces";
import styles from "./styles";

interface IProps {
  handleToggle: () => void;
  addCustomMediaType: (addCustomMediaType: IMediaTypeState) => void;
  currentMediaTypes: IMediaTypeState[];
  customMediaTypeToRename?: IMediaTypeState;
  renameCustomMediaType: (newName: string, oldName: string) => void;
}

const CustomMediaModalContent = ({
  handleToggle,
  addCustomMediaType,
  currentMediaTypes,
  customMediaTypeToRename,
  renameCustomMediaType,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [formData, setFormData] = useImmer<IMediaTypeState>(
    customMediaTypeToRename ||
      ({
        isSelected: true,
        isCustom: true,
        name: "",
      } as IMediaTypeState)
  );

  const handleCancel = () => {
    form.resetFields();
    handleToggle();
  };

  const onFormSubmit = (customMediaType: IMediaTypeState) => {
    const trimmedCustomMediaType = customMediaType;

    trimmedCustomMediaType.name = customMediaType.name.trim();

    if (customMediaTypeToRename) {
      if (customMediaTypeToRename.name === trimmedCustomMediaType.name) {
        handleCancel();
      } else {
        renameCustomMediaType(
          trimmedCustomMediaType.name,
          customMediaTypeToRename.name
        );
      }
    } else {
      addCustomMediaType(trimmedCustomMediaType);
    }
  };

  const getAddUpdateButtonText = (): string => {
    const operationOnMediaTypeTranslationKey = customMediaTypeToRename
      ? "ProjectsPage##licensingSection##UpdateType"
      : "ProjectsPage##licensingSection##AddType";

    return t(operationOnMediaTypeTranslationKey, {
      type: t("ProjectsPage##licensingSection##MediaType").toLowerCase(),
    });
  };

  const FIELDS = {
    name: ["name"],
    isSelected: ["isSelected"],
    isCustom: ["isCustom"],
  };

  return (
    <div data-testid="custom-media-type-modal">
      <div css={styles.header}>
        {customMediaTypeToRename
          ? t("ProjectsPage##licensingSection##Rename custom media type")
          : t("ProjectsPage##licensingSection##Add custom media type")}
      </div>
      <Form
        form={form}
        onFinish={onFormSubmit}
        initialValues={formData}
        layout="vertical"
        aria-label="form"
      >
        <div css={styles.contentContainer}>
          <FloatingLabel
            label={t("ProjectsPage##licensingSection##Custom media type")}
            helpText={t("ProjectsPage##licensingSection##Custom media type")}
            name={FIELDS.name[0]}
            value={formData.name}
          >
            <Form.Item
              name={FIELDS.name}
              rules={[
                {
                  validator: (rule: RuleObject, value: string) => {
                    const customMediaTypes = currentMediaTypes.filter(
                      (mediaType) => mediaType.isCustom
                    );

                    const defaultMediaTypes = currentMediaTypes.filter(
                      (mediaType) => !mediaType.isCustom
                    );
                    try {
                      if (
                        customMediaTypeToRename?.name.toLocaleLowerCase() ===
                        value.toLocaleLowerCase()
                      ) {
                        return Promise.resolve();
                      }
                      if (!value || value.trim() === "") {
                        throw new Error(
                          t(
                            "ProjectsPage##licensingSection##Please enter a custom media type"
                          )
                        );
                      } else if (
                        customMediaTypes.filter(
                          (mediaType) =>
                            mediaType.name.toLowerCase() ===
                            value.trim().toLowerCase()
                        ).length > 0
                      ) {
                        throw new Error(
                          t(
                            "ProjectsPage##licensingSection##A custom media type with this name already exists"
                          )
                        );
                      } else if (
                        defaultMediaTypes.filter(
                          (mediaType) =>
                            mediaType.name.toLowerCase() ===
                            value.trim().toLowerCase()
                        ).length > 0
                      ) {
                        throw new Error(
                          t(
                            "ProjectsPage##licensingSection##A media type with this name already exists"
                          )
                        );
                      }

                      return Promise.resolve();
                    } catch (error) {
                      return Promise.reject(error);
                    }
                  },
                },
              ]}
            >
              <Input
                name={FIELDS.name[0]}
                aria-label={t(
                  "ProjectsPage##licensingSection##Custom media type"
                )}
                onBlur={(e) =>
                  setFormData((draft) => {
                    draft.name = e.target.value;
                  })
                }
              />
            </Form.Item>
          </FloatingLabel>
          <Form.Item name={FIELDS.isSelected} noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name={FIELDS.isCustom} noStyle>
            <Input type="hidden" />
          </Form.Item>
        </div>
        <div css={styles.tabletDesktopButtonContainer}>
          <SecondaryModalButtonLink
            ariaLabel="Cancel"
            onClick={handleCancel}
            buttonText="Cancel"
            className="inviteMembersCancelButton"
          />
          <Button
            ariaLabel={getAddUpdateButtonText()}
            htmlType="submit"
            css={styles.primaryButton}
            role="button"
            name={getAddUpdateButtonText()}
            noLabelTranslation
          >
            {getAddUpdateButtonText()}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CustomMediaModalContent;
