import { ReactElement } from "react";

interface IProps {
  fill?: string;
  height?: number;
  width?: number;
}

const DownloadIcon = ({
  fill = "#8058FF",
  height = 38,
  width = 38,
}: IProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
    >
      <g fill={fill} fillRule="evenodd">
        <rect width="14" height="1.5" x="12" y="25.305" rx=".75" />
        <rect
          width="5"
          height="1.5"
          x="10.25"
          y="23.555"
          rx=".75"
          transform="rotate(90 12.75 24.305)"
        />
        <rect
          width="5"
          height="1.5"
          x="22.75"
          y="23.555"
          rx=".75"
          transform="rotate(90 25.25 24.305)"
        />
        <path
          d="M13.3792067,16.7546476 C13.3913097,16.5804969 13.4639279,16.4097643 13.5970612,16.276631 L13.5970612,16.276631 L18.1932553,11.680437 C18.4861485,11.3875437 18.9610222,11.3875437 19.2539154,11.680437 C19.5468086,11.9733302 19.5468086,12.4482039 19.2539154,12.7410971 L19.2539154,12.7410971 L15.8962067,16.097767 L23.8707933,16.0981831 C24.2850068,16.0981831 24.6207933,16.4339696 24.6207933,16.8481831 C24.6207933,17.2623967 24.2850068,17.5981831 23.8707933,17.5981831 L15.9612067,17.597767 L19.2539154,20.8903567 C19.5468086,21.1832499 19.5468086,21.6581237 19.2539154,21.9510169 C18.9610222,22.2439101 18.4861485,22.2439101 18.1932553,21.9510169 L13.5970612,17.3548228 C13.4639279,17.2216895 13.3913097,17.050957 13.3792067,16.8768063 Z"
          transform="scale(1 -1) rotate(90 35.816 0)"
        />
      </g>
    </svg>
  );
};

export default DownloadIcon;
