import React, { useCallback, useMemo, useState } from "react";
import { Dropdown, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import { ITalentHubTypes } from "src/api/talent-hub/interfaces";
import MoreInfoButton from "src/components/control-buttons/more-info-button";
import {
  IContactsFilters,
  ITalentContactGroupsResponse,
  ITalentTab,
} from "src/pages/talent-hub/interfaces";
import styles from "src/pages/talent-hub/styles";
import ControlButton from "src/components/control-buttons/base-button";
import { PlusOutlined } from "@ant-design/icons";
import EditGroups from "src/app/assets/icons/component-icons/edit_groups";
import DeleteButton from "src/components/control-buttons/delete-button";
import { IContactsFiltersProviderContext } from "src/providers/talent-search/state/context";
import mapContactDataForViewContact from "src/pages/talent-hub/view-contact/utils";
import MenuItem from "src/components/menu-item";

enum GroupType {
  MyGroups = "myGroups",
  OrgGroups = "orgGroups",
}

export enum StaticTabs {
  Header = "Header",
  AllContacts = "AllContacts",
  SelectGroups = "SelectGroups",
}

const MappedContactsTabs = (
  talentHubTypes: ITalentHubTypes,
  organisationId: string,
  allGroups: ITalentContactGroupsResponse[],
  onRemoveContactFromGroup: (groupId: string, talentId: string) => void,
  onAddContactToGroup: (contactId: string) => void,
  onCloseOrOpenCreateGroup: () => void,
  onDeleteGroupClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    groupId: string | null
  ) => void,
  onChangeIsUserGroup: (isUserGroupSelected: boolean) => void,
  contactsSearchAndFilters: IContactsFiltersProviderContext
): ITalentTab[] => {
  const {
    handlePageChange: onAllContactsPageChange,
    paginationOptions: allContactsPaginationOptions,
    specificGroupContacts,
    contactsResponse,
    availableContactFilters,
    selectedContactFilters,
    searchText,
    handleUpdateSearchText,
    handleFetchSelectedFilters,
    availableGroupContactFilters,
    selectedGroupContactFilters,
    groupSearchText,
    handleUpdateGroupSearchText,
    handleFetchSelectedGroupFilters,
    groupCurrentPage,
    handleGroupPageChange,
  } = contactsSearchAndFilters;
  const { t } = useTranslation();
  const history = useHistory();
  const [isEditGroup, setIsEditGroup] = useState(false);
  const [activeButtonId, setActiveButtonId] = useState(GroupType.MyGroups);
  const defaultTab = useMemo(() => {
    return {
      tabKey: StaticTabs.Header,
      tabTitle: (
        <div css={styles.mainTabContainer}>
          <Typography css={styles.tabHeaderText}>Groups</Typography>
          <div css={styles.mainTabIcons}>
            <ControlButton
              showTooltip
              additionalStyles={
                isEditGroup ? styles.activeEditGroup : undefined
              }
              tooltipColor="#222222"
              label={t("Edit")}
              onClick={() => setIsEditGroup(!isEditGroup)}
              data-testid="edit-group-button"
            >
              <EditGroups />
            </ControlButton>

            <div css={styles.addIconContainer}>
              <ControlButton
                showTooltip
                tooltipColor="#222222"
                label={t("Add new")}
                onClick={onCloseOrOpenCreateGroup}
                data-testid="add-group-button"
              >
                <PlusOutlined css={styles.removeMarginRight} />
              </ControlButton>
            </div>
          </div>
        </div>
      ),
      dataSource: [],
      rawData: [],
      totalResults: 0,
    };
  }, [isEditGroup, onCloseOrOpenCreateGroup, t]);

  const getAllContactsDataSource = useCallback(
    (isSpecificGroup: boolean) => {
      const getColumnCellValue = (
        value?: string | null,
        showTooltip = false
      ): JSX.Element => {
        const data = value || "-";
        return (
          <div css={styles.tableDataValue}>
            {showTooltip && (
              <Tooltip placement="topLeft" title={data}>
                {data}
              </Tooltip>
            )}
            {!showTooltip && data}
          </div>
        );
      };

      const contactsData =
        (isSpecificGroup
          ? specificGroupContacts?.results
          : contactsResponse?.results) ?? [];
      const totalRows = (contactsData ?? []).length;
      return (contactsData ?? []).map((contact, index) => {
        const currentContactData = mapContactDataForViewContact(
          contact,
          talentHubTypes
        );
        const menuItems =
          2 + (isSpecificGroup && specificGroupContacts?.groupId ? 1 : 0);
        const getTopRightPlacementOfTheDropdownForTheLastRows = (
          numberOfRows: number,
          rowIndex: number
        ): "topRight" | "bottomRight" => {
          const isOnlyOneRow = totalRows === 1;
          const hasMultipleMenuItemsForTheLastTwoRow =
            menuItems > 2 && rowIndex === totalRows - 2;
          if (isOnlyOneRow || hasMultipleMenuItemsForTheLastTwoRow) {
            return "topRight";
          }
          return rowIndex >= totalRows - numberOfRows
            ? "topRight"
            : "bottomRight";
        };
        const items = [
          {
            disabled: organisationId !== contact.createdByOrgId,
            label: (
              <MenuItem
                ariaLabel="Edit"
                buttonLabel="Edit"
                customStyles={styles.dropdownButton}
                onClick={() => {
                  if (organisationId === contact.createdByOrgId) {
                    history.push(`/talent/${contact.id}`);
                  }
                }}
              />
            ),
            key: "Edit",
          },
          {
            label: (
              <MenuItem
                ariaLabel="Add to group"
                buttonLabel="Add to group"
                customStyles={styles.dropdownButton}
                onClick={() => {
                  onAddContactToGroup(contact.id);
                }}
              />
            ),
            key: "Add to group",
          },
        ];
        if (isSpecificGroup && specificGroupContacts?.groupId) {
          const dropDownItem = {
            key: "Remove",
            label: (
              <MenuItem
                ariaLabel="Remove"
                buttonLabel="Remove"
                customStyles={styles.dropdownButton}
                onClick={() => {
                  onRemoveContactFromGroup(
                    specificGroupContacts.groupId,
                    contact.id
                  );
                }}
              />
            ),
          };
          items.push(dropDownItem);
        }

        return {
          id: contact.id,
          key: contact.id,
          name: getColumnCellValue(currentContactData.name, true),
          skillSet: getColumnCellValue(currentContactData.skillSet, true),
          instruments: getColumnCellValue(
            currentContactData.principalInstruments,
            true
          ),
          genres: getColumnCellValue(currentContactData.keyGenres, true),
          vocals: getColumnCellValue(currentContactData.vocalist),
          rating: getColumnCellValue(currentContactData.collaborationStatus),
          pronoun: getColumnCellValue(currentContactData.pronoun),
          agencyWritingPartnership: getColumnCellValue(
            contact.agencyWritingPartnership
          ),
          email: getColumnCellValue(currentContactData.email),
          phoneNumber: getColumnCellValue(currentContactData.phone),
          baseLocation: getColumnCellValue(currentContactData.baseLocation),
          socialMediaWebsite: getColumnCellValue(
            currentContactData.socialMediaWebsite,
            true
          ),
          showreel: getColumnCellValue(currentContactData.showreelUrls, true),
          nativeMainLanguage: getColumnCellValue(
            currentContactData.mainLanguage
          ),
          otherLanguages: getColumnCellValue(
            currentContactData.otherLanguages,
            true
          ),
          neurodiverse: getColumnCellValue(currentContactData.neurodiverse),
          neurodiverseNote: getColumnCellValue(
            currentContactData.neurodiverseNote
          ),
          physicalConditionIllness: getColumnCellValue(
            currentContactData.physicalConditionsIllness
          ),
          racialCharacteristics: getColumnCellValue(
            currentContactData.racialCharacteristics
          ),
          genderIdentity: getColumnCellValue(currentContactData.genderIdentity),
          sexualOrientation: getColumnCellValue(
            currentContactData.sexualOrientation
          ),
          cMASigned: getColumnCellValue(currentContactData.signedCMA),
          nDASigned: getColumnCellValue(currentContactData.signedNDA),
          minimumDemoFee: getColumnCellValue(currentContactData.minimumDemoFee),
          performingRightsOrganisationNumber: getColumnCellValue(
            currentContactData.performingRightsOrganisationNumber,
            true
          ),
          additionalInstruments: getColumnCellValue(
            currentContactData.additionalInstruments,
            true
          ),
          additionalGenres: getColumnCellValue(
            currentContactData.additionalGenres,
            true
          ),
          minimumDemoTurnaroundHours: getColumnCellValue(
            currentContactData.minimumDemoTurnaroundHours
          ),
          previousWork: getColumnCellValue(
            currentContactData.previousWork,
            true
          ),
          interestedProjects: getColumnCellValue(
            currentContactData.interestedProjects,
            true
          ),
          menuMore: (
            <Dropdown
              aria-label="action-items-dropdown"
              data-testid="action-items-dropdown"
              css={styles.dropdownWrapper}
              menu={{ items }}
              trigger={["click"]}
              getPopupContainer={(triggerNode) => triggerNode}
              placement={getTopRightPlacementOfTheDropdownForTheLastRows(
                1,
                index
              )}
            >
              <MoreInfoButton
                showTooltip
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                }}
              />
            </Dropdown>
          ),
        };
      });
    },
    [
      specificGroupContacts?.results,
      specificGroupContacts?.groupId,
      contactsResponse?.results,
      talentHubTypes,
      organisationId,
      history,
      onAddContactToGroup,
      onRemoveContactFromGroup,
    ]
  );

  const handleGroupChange = useCallback(
    (groupId: GroupType) => {
      setActiveButtonId(groupId);
      onChangeIsUserGroup(groupId === GroupType.MyGroups);
    },
    [onChangeIsUserGroup]
  );

  if (!contactsResponse?.results) {
    return [defaultTab];
  }

  const allContactsTab = {
    tabKey: StaticTabs.AllContacts,
    tabTitle: (
      <div>
        <div css={styles.tabTitle}>{t("TalentPage##All contacts")}</div>
        <div
          css={styles.tabSubTitle}
        >{`${allContactsPaginationOptions.total} contacts`}</div>
      </div>
    ),
    dataSource: getAllContactsDataSource(false),
    rawData: contactsResponse?.results,
    totalResults: allContactsPaginationOptions.total,
    paginationOptions: allContactsPaginationOptions,
    onPageChange: onAllContactsPageChange,
    availableContactFilters,
    selectedContactFilters,
    searchText,
    onUpdateSearchText: handleUpdateSearchText,
    onFetchSelectedFilters: handleFetchSelectedFilters,
  };

  const selectGroupTab = {
    tabKey: StaticTabs.SelectGroups,
    tabTitle: (
      <div
        css={[styles.activeAndInactiveGroupContainer, styles.mainTabContainer]}
      >
        <button
          type="button"
          css={[
            styles.removeButtonStyles,
            activeButtonId === GroupType.MyGroups && styles.activeGroup,
          ]}
          onClick={() => handleGroupChange(GroupType.MyGroups)}
        >
          My groups
        </button>
        <button
          type="button"
          css={[
            styles.removeButtonStyles,
            activeButtonId === GroupType.OrgGroups && styles.activeGroup,
          ]}
          onClick={() => handleGroupChange(GroupType.OrgGroups)}
        >
          Org groups
        </button>
      </div>
    ),
    dataSource: [],
    rawData: contactsResponse?.results,
    totalResults: allContactsPaginationOptions.total,
  };
  const additionalGroups = allGroups?.map((group, index) => {
    return {
      tabKey: group.id ?? "",
      tabTitle: (
        <div css={styles.groupContainer}>
          <div>
            <div css={styles.tabTitle}>{group.name}</div>
            <div css={styles.tabSubTitle}>{`${group.size} contacts`}</div>
          </div>
          {isEditGroup && (
            <div data-testid={index === 0 ? "delete-group-button" : undefined}>
              <DeleteButton onClick={(e) => onDeleteGroupClick(e, group.id)} />
            </div>
          )}
        </div>
      ),

      dataSource:
        group.id === specificGroupContacts?.groupId
          ? getAllContactsDataSource(true)
          : [],
      rawData: [],
      totalResults: specificGroupContacts?.totalResults ?? 0,
      paginationOptions: {
        total: specificGroupContacts?.totalResults ?? 0,
        currentPage: groupCurrentPage,
      },
      onPageChange: handleGroupPageChange,
      availableContactFilters: availableGroupContactFilters,
      selectedContactFilters: selectedGroupContactFilters,
      searchText: groupSearchText,
      onUpdateSearchText: (searchValue: string) => {
        handleGroupPageChange(1, false);
        handleUpdateGroupSearchText(searchValue);
      },
      onFetchSelectedFilters: (selectedGroupFilters: IContactsFilters) => {
        handleGroupPageChange(1, false);
        handleFetchSelectedGroupFilters(selectedGroupFilters);
      },
    };
  });

  const groups = [
    defaultTab,
    allContactsTab,
    selectGroupTab,
    ...additionalGroups,
  ];
  return groups;
};

export default MappedContactsTabs;
