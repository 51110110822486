import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import mainStyles from "../../styles";

const ServiceCollapsibleHeader = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={mainStyles.collapsibleTableHeaderContainer}>
      <div css={mainStyles.collapsibleTableHeaderFirstCell}>
        {t("ProjectsPage##servicesSection##Service type")}
      </div>
      <div css={mainStyles.collapsibleTableHeaderCell}>
        {t("ProjectsPage##servicesSection##Music type")}
      </div>
      <div css={mainStyles.collapsibleTableHeaderCell}>
        {t("ProjectsPage##servicesSection##3rd party")}
      </div>
      <div css={mainStyles.collapsibleTableHeaderCell}>
        {t("ProjectsPage##servicesSection##3rd party fee")}
      </div>
      <div css={mainStyles.collapsibleTableHeaderCell}>
        {t("ProjectsPage##servicesSection##Service fee")}
      </div>
      <div css={mainStyles.collapsibleTableHeaderCell}>
        {t("ProjectsPage##servicesSection##Total fee")}
      </div>
      <div css={mainStyles.summaryIconContainer} />
    </div>
  );
};

export default ServiceCollapsibleHeader;
