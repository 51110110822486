import React from "react";
import { Form, Typography } from "antd";
import StyledButton from "src/components/button";
import styles from "src/components/modals/styles";
import { useTranslation } from "react-i18next";
import SecondaryModalButtonLink from "../modal-buttons/secondary-button-link";

interface IUnlimitedScriptsProps {
  onClose: () => void;
  removeAllScripts: () => void;
}

const Content = ({
  removeAllScripts,
  onClose,
}: IUnlimitedScriptsProps): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  return (
    <div>
      <Typography.Title data-testid="modal-Title" css={styles.modalTitle}>
        {t("ProjectsPage##licensingSection##Are you sure?")}
      </Typography.Title>
      <Form form={form} layout="vertical" onFinish={() => removeAllScripts()}>
        <p>
          {t("ProjectsPage##licensingSection##You’re about to remove")}{" "}
          <b>{t("all")}</b>{" "}
          {t(
            "ProjectsPage##licensingSection##the scripts added to this project, this action"
          )}{" "}
          <b>{t("ProjectsPage##licensingSection##cannot be undone.")}</b>{" "}
        </p>
        <div css={styles.footer}>
          <SecondaryModalButtonLink
            onClick={onClose}
            ariaLabel="defaultSecondaryButton"
            buttonText="defaultSecondaryButton"
            className="confirmationModalCancelButton"
          />
          <StyledButton
            ariaLabel="primaryButtonLabel"
            type="primary"
            htmlType="submit"
            className="confirmationModalConfirmButton"
          >
            {t("ProjectsPage##licensingSection##Remove all")}
          </StyledButton>
        </div>
      </Form>
    </div>
  );
};

export default Content;
