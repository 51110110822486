import { Typography } from "antd";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import STLoadingLogo from "src/components/st-loading-logo";
import Waveform from "src/components/music-player";
import { IProjectAttachment } from "src/pages/projects/project/interfaces";
import styles from "./styles";

interface IProps {
  tracks?: IProjectAttachment[];
  handleDownloadClick: (data: IProjectAttachment) => void;
  artists?: string;
  title?: string;
}

const FinalTrackDetails = ({
  tracks,
  handleDownloadClick,
  artists,
  title,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [isTrackPlaying, setTrackPlaying] = useState<boolean>(false);

  return (
    <div css={styles.container}>
      <div css={styles.verticalTitleContainer}>{t("Final track details")}</div>
      <div css={styles.trackContentContainer}>
        <Typography.Text css={styles.trackTitle}>{title}</Typography.Text>
        <Typography.Text css={styles.trackArtist}>{artists}</Typography.Text>
        {tracks &&
          tracks.map((track) => {
            return (
              <div key={track.id} css={styles.waveformContainer}>
                {track.url ? (
                  <Waveform
                    url={track.url}
                    fileName={track.name}
                    handleDownloadClick={() => handleDownloadClick(track)}
                    setTrackPlaying={setTrackPlaying}
                    isTrackPlaying={isTrackPlaying}
                  />
                ) : (
                  <STLoadingLogo />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FinalTrackDetails;
