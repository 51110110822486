import React, { ComponentType, ReactElement } from "react";
import { Table } from "antd";
import { IStyledTableProps } from "./interfaces";
import styles from "./styles";

/*
    This allows us to wrap AntDesign's Table component and inject our
    own custom props and styling when needed.

    For styling the table, first try with the theme-variables.json,
    as some parts of AntDesign's components can be overriden through 
    modifying AntDesign's LESS theme which makes things a bit easier.

    Any data source applied to the table must have a matching interface 
    outlining the data model within the interfaces file.
*/
const withTableStyles = <P extends unknown>(Component: ComponentType<P>) => {
  return ({
    className,
    dataSource,
    columns,
    pagination,
    ...rest
  }: IStyledTableProps): ReactElement => {
    return (
      <Component
        {...(rest as P)}
        css={styles.main}
        className={className}
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
      />
    );
  };
};

export default withTableStyles(Table);
