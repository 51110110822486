import { ReactElement } from "react";
import theme from "src/theme";
import IIconComponentProps from "../../interfaces";

interface IProps extends IIconComponentProps {
  stroke: string;
  className: string;
}

const UploadDeleteIcon = ({
  fill = theme.colors.secondary.cyan,
  height = "38",
  width = "38",
  title,
  role = "img",
  className = "uploadDeleteIconValid",
  stroke = theme.colors.secondary.cyan,
}: IProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
      aria-labelledby="upload-delete-title"
      role={role}
      className={className}
    >
      <g fill={fill} fillRule="evenodd">
        <circle cx="19" cy="19" r="11.25" stroke={stroke} strokeWidth="1.5" />
        <title id="upload-delete-title">{title}</title>
        <g
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="1.5"
          transform="rotate(45 -4.046 28.546)"
        >
          <line x1="5.5" x2="5.5" y1=".5" y2="10.5" />
          <line
            x1="5.5"
            x2="5.5"
            y1=".5"
            y2="10.5"
            transform="rotate(90 5.5 5.5)"
          />
        </g>
      </g>
    </svg>
  );
};

export default UploadDeleteIcon;
