import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  infoDrawerContainer: css`
    width: 100%;
    height: 200px;
    padding-top: 1.75rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};

    .ant-menu-item-selected {
      background-color: ${theme.colors.white} !important;
    }

    .ant-menu-item {
      background-color: ${theme.colors.white};
      font-family: ${theme.fonts.sofiaPro};

      button {
        display: flex;
        width: 100%;
        text-align: left;
        border: none;
        margin: 0;
        text-decoration: none;
        background-color: ${theme.colors.white};
        color: ${theme.colors.black} !important;
        cursor: pointer;

        span {
          padding-top: 0.2rem;
          padding-left: 0.5rem;
          font-size: 1rem;
        }
      }
    }
  `,
};
