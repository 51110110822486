import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  container: css`
    background-color: ${theme.colors.secondary.black};
    display: flex;
    flex-direction: column;

    @media ${queries.medium} {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 3.75rem;
      padding-bottom: 5rem;
    }

    @media ${queries.large} {
      padding-left: 0;
      padding-right: 0;
      padding-top: 5rem;
      align-items: center;
      flex-direction: row;
    }
  `,
  waveformContainer: css`
    width: 100%;
    margin-bottom: 3rem;
  `,
  verticalTitleContainer: css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 0.8rem !important;
    line-height: 1.125rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    height: 50px;
    font-weight: 600;
    padding-top: 1.5rem;
    padding-left: 1rem;

    @media ${queries.medium} {
      color: ${theme.colors.white};
      padding-top: 0;
      padding-left: 0;
      background-color: ${theme.colors.secondary.black};
      margin-bottom: 1rem;
      opacity: 70%;
    }

    @media ${queries.large} {
      letter-spacing: 0.07rem;
      font-size: 0.8rem;
      transform: rotate(270deg);
      width: 160px;
      opacity: 100%;
      font-weight: 500;
      height: 70px;
      margin-bottom: 12rem;
      text-align: right;
    }
  `,
  trackContentContainer: css`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${queries.large} {
      margin-left: -2rem;
    }
  `,
  trackTitle: css`
    padding-left: 1rem;
    padding-top: 1.5rem;
    font-size: 1.75rem;
    font-weight: 600;
    padding-bottom: 0.25rem;
    color: ${theme.colors.white};
    line-height: 2.375rem;
    display: block;
    width: 100%;

    @media ${queries.medium} {
      padding-left: 0;
      font-weight: 700;
      padding-top: 0;
      font-size: 2rem;
    }
  `,
  trackArtist: css`
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${theme.colors.white};
    display: block;
    width: 100%;
    padding-bottom: 2.5rem;

    @media ${queries.medium} {
      padding-left: 0;
    }
  `,
};
