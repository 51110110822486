import axios from "axios";
import {
  IUpdateTermResponse,
  IUpdateTermRequest,
} from "src/pages/projects/project/interfaces";
import { updateTermUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const updateTerm = async (
  accessToken: string,
  projectId: string,
  termId: string,
  updateRequest: IUpdateTermRequest
): Promise<IUpdateTermResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.put(updateTermUrl(projectId, termId), updateRequest, options);
};

export default updateTerm;
