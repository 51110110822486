import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";
import { GetNavigationLinksFillClasses } from "./helper";

const TalentIcon = ({
  height = theme.svgSize.nav,
  width = theme.svgSize.nav,
  isActive = false,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  const fillClasses = GetNavigationLinksFillClasses(isActive);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 -5 40 50"
      aria-labelledby="projects-nav-link"
    >
      <title id="team-nav-link">{t("TalentHub")}</title>
      <path
        className={fillClasses}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3292 8H9V32H28.3292C30.1771 32 31.6804 30.6332 31.6804 28.9531V11.0469C31.6804 9.36683 30.1771 8 28.3292 8ZM30.1341 28.9531C30.1341 29.8578 29.3246 30.5937 28.3296 30.5937H26.4944V24.4687H30.1341V28.9531ZM24.9473 9.40625H10.5467V30.5937H24.9473V9.40625ZM30.1341 23.0625H26.4944V16.9375H30.1341V23.0625ZM26.4944 9.40625V15.5313H30.1341V11.0469C30.1341 10.1422 29.3246 9.40625 28.3296 9.40625H26.4944Z"
        fill="white"
      />
      <circle
        cx="17.8695"
        cy="16.9017"
        r="2.31186"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M12.7661 26.3033V26.3033V24.7263C12.7661 22.8474 14.1941 21.3242 15.9555 21.3242H19.7829C21.5443 21.3242 22.9723 22.8474 22.9723 24.7263C22.9723 25.2414 22.9723 25.7565 22.9723 26.2717"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};
export default TalentIcon;
