import React, { ReactNode } from "react";
import theme from "src/theme";
import SortButton, {
  sortTypes,
} from "src/components/control-buttons/sort-button";
import MobileSortButton from "src/components/control-buttons/mobile-sort-button";
import { TFunction } from "react-i18next";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import style from "../styles";
import { OrgMembersSortType } from "../interfaces";

export default (
  isTablet: boolean,
  isMobile: boolean,
  nameSortAscending: boolean,
  jobTitleSortAscending: boolean,
  handleNameSort: () => void,
  handleJobTitleSort: () => void,
  currentSortType: OrgMembersSortType,
  t: TFunction<string>,
  handleMobileSortClick: () => void
): Array<IColumnsData> => {
  if (isMobile) {
    return [
      {
        title: (
          <div css={style.titleContainer}>
            <span>{t("manageTeam##members##Name")}</span>
          </div>
        ),
        dataIndex: "name",
        key: "key",
        width: "90%",
      },
      {
        title: (
          <div css={style.sortContainer}>
            <span css={style.sortSpan}>{t("Sort")}</span>
            <MobileSortButton onClick={() => handleMobileSortClick()} />
          </div>
        ),
        dataIndex: "ellipsis",
        key: "name",
        width: "10%",
        className: "ellipsis-button",
      },
    ];
  }
  if (isTablet) {
    return [
      {
        title: (
          <div css={style.titleContainer}>
            <span>{t("manageTeam##members##Name")}</span>
          </div>
        ),
        dataIndex: "name",
        key: "key",
        width: "200px",
      },
      {
        title: (
          <div css={style.titleContainer}>
            <span>{t("manageTeam##members##Job title")}</span>
          </div>
        ),
        dataIndex: "jobTitle",
        key: "key",
        width: "100px",
      },
      {
        title: (
          <div css={style.titleContainer && style.teamAdminCol}>
            <span>{t("manageTeam##members##Admin")}</span>
          </div>
        ),
        dataIndex: "isAdmin",
        key: "key",
        width: "100px",
      },
      {
        title: (
          <div css={style.sortContainer}>
            <span css={style.sortSpan}>{t("Sort")}</span>
            <MobileSortButton onClick={() => handleMobileSortClick()} />
          </div>
        ),
        dataIndex: "ellipsis",
        key: "name",
        width: "10%",
        className: "ellipsis-button",
      },
    ];
  }

  return [
    {
      title: (
        <div css={style.titleContainer}>
          <span>{t("manageTeam##members##Name")}</span>
          <SortButton
            tooltipText={nameSortAscending ? t("Sort A-Z") : t("Sort Z-A")}
            css={style.sortButton}
            dataTestId="team-name-sort-button"
            sortType={nameSortAscending ? sortTypes.A_Z : sortTypes.Z_A}
            onClick={handleNameSort}
            {...(currentSortType !== OrgMembersSortType.Name && {
              fillColor: theme.colors.background.lightGray,
            })}
          />
        </div>
      ),
      dataIndex: "name",
      key: "key",
      width: "200px",
    },
    {
      title: (
        <div css={style.titleContainer}>
          <span>{t("manageTeam##members##Job title")}</span>
          <SortButton
            tooltipText={jobTitleSortAscending ? t("Sort A-Z") : t("Sort Z-A")}
            css={style.sortButton}
            dataTestId="job-title-sort-button"
            sortType={jobTitleSortAscending ? sortTypes.A_Z : sortTypes.Z_A}
            onClick={handleJobTitleSort}
            {...(currentSortType !== OrgMembersSortType.JobTitle && {
              fillColor: theme.colors.background.lightGray,
            })}
          />
        </div>
      ),
      dataIndex: "jobTitle",
      key: "key",
      width: "100px",
    },
    {
      title: (
        <div css={style.titleContainer}>
          <span>{t("manageTeam##members##Phone")}</span>
        </div>
      ),
      dataIndex: "phoneNumber",
      key: "key",
      width: "120px",
    },
    {
      title: (
        <div css={style.titleContainer && style.teamAdminCol}>
          <span>{t("manageTeam##members##Admin")}</span>
        </div>
      ),
      dataIndex: "isAdmin",
      key: "key",
      width: "100px",
    },
  ];
};
