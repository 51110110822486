import axios from "axios";
import { getAuthHeader, getAxiosOptions } from "../../headers";
import getJsonContentHeader from "../../headers/get-json-content-header";
import { getOrganisationUserUrl } from "../../organisation-members-url-helper";

async function deleteOrganisationMember(
  accessToken: string,
  organisationId: string,
  userId: string
): Promise<void> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  const response = await axios.delete(
    getOrganisationUserUrl(organisationId, userId),
    options
  );
  if (response.status !== 200) {
    throw new Error(
      `Error deleting organisation member. ${response.status}: ${response.statusText}`
    );
  }
}

export default deleteOrganisationMember;
