import React, { Fragment, ReactElement, useState } from "react";
import { Drawer, Button, Typography } from "antd";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  HamburgerIcon,
  DashboardIcon,
  CloseIcon,
} from "src/app/assets/icons/component-icons";
import {
  ISideNavProp,
  ISideNavProps,
  INavRouteProps,
} from "src/pages/navigation";
import theme from "src/theme";
import style from "./styles";

interface IProps extends ISideNavProps, INavRouteProps {
  isMobile: boolean;
}

const MobileNavigation = ({
  navLinks,
  navRoutes,
  isMobile,
}: IProps): ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const toggleMobileNavigation = () => {
    setIsDrawerOpen((state) => !state);
  };

  const title =
    navRoutes.find(
      (navRoute) =>
        navRoute &&
        navRoute.routeMatch &&
        navRoute.routeMatch.exec(window.location.pathname)
    )?.titleTranslation ?? "404 Error";

  return (
    <div css={style.main} data-testid="mobile-navigation-container">
      <NavLink css={style.dashboardNavLink} to="/dashboard">
        <DashboardIcon />
      </NavLink>
      <Typography.Title ellipsis css={style.title}>
        {title ? t(title) : ""}
      </Typography.Title>
      <Button
        data-testid="hamburger-menu"
        onClick={toggleMobileNavigation}
        css={style.burgerButton}
        type="text"
      >
        <HamburgerIcon />
      </Button>
      <Drawer
        data-testid="mobile-nav-drawer"
        placement="right"
        onClose={toggleMobileNavigation}
        open={isDrawerOpen}
        css={style.drawer}
        width={isMobile ? "100%" : "40%"}
        closeIcon={
          <CloseIcon
            fill={theme.colors.white}
            height={13}
            width={14}
            title="Close"
            role="button"
          />
        }
      >
        <ul>
          {navLinks.map(
            ({
              key,
              visible,
              icon,
              to,
              dataTestId,
              title: translation,
              customStyles,
            }: ISideNavProp): ReactElement => {
              return visible ? (
                <li key={key}>
                  <NavLink
                    css={customStyles}
                    to={to}
                    onClick={toggleMobileNavigation}
                    data-testid={dataTestId}
                  >
                    {icon(false)}
                    <span>{translation}</span>
                  </NavLink>
                </li>
              ) : (
                <Fragment key={key} />
              );
            }
          )}
        </ul>
      </Drawer>
    </div>
  );
};

export default MobileNavigation;
