import React, { ReactElement } from "react";
import copy from "copy-to-clipboard";
import Button from "src/components/button";
import CopyLink from "src/app/assets/icons/component-icons/copy";
import { CopyProjectLinkToast } from "src/components/toast-notification";
import CloseIcon from "src/app/assets/icons/component-icons/close-icon";
import STLoadingLogo from "src/components/st-loading-logo";
import { useTranslation } from "react-i18next";
import theme from "src/theme";
import { DebouncedFunc } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import shareProject from "src/api/projects/share-project";
import useAuth from "src/auth/use-auth";
import {
  IProjectAttachment,
  IProjectForm,
  IProjectRouteParams,
} from "../../project/interfaces";
import styles from "./styles";
import { IProjectUploadFile } from "../../project/components/details/components/interfaces";
import ShareProject from "..";

interface IProps {
  projectData: IProjectForm | undefined;
  previewDownloadFile: DebouncedFunc<
    (file: IProjectUploadFile) => Promise<void>
  >;
  tracks?: IProjectAttachment[];
}

const PreviewShareProject = ({
  projectData,
  previewDownloadFile,
  tracks,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: projectId } = useParams<IProjectRouteParams>();
  const { organisationId, fetchWrapper } = useAuth();

  const handleCloseClick = () => {
    history.push(`/project/${projectId}`);
  };

  const handleShareClick = async () => {
    const response = await fetchWrapper(
      shareProject,
      projectId,
      organisationId
    );
    copy(response.shareCodeUrl);
    CopyProjectLinkToast();
  };

  return (
    <div css={styles.pageContainer}>
      <div css={styles.stickyHeaderContainer}>
        <div css={styles.stickyTextContainer}>
          <div css={styles.previewModeContainer}>Preview mode</div>
        </div>
        <div css={styles.stickyButtonsContainer}>
          <Button
            ariaLabel={t("ProjectsPage##Share project")}
            noLabelTranslation
            onClick={handleShareClick}
          >
            {t("ProjectsPage##Share project")}
            <CopyLink fill={theme.colors.white} />
          </Button>
          <button
            css={styles.closeButton}
            type="button"
            aria-label={t("Close")}
            onClick={handleCloseClick}
          >
            {t("Close")}
            <CloseIcon
              fill={theme.colors.primary.blue}
              height={14}
              width={14}
              title="Close"
              role="button"
            />
          </button>
        </div>
      </div>
      <div css={styles.projectContainer}>
        {projectData ? (
          <ShareProject
            projectData={projectData}
            downloadFile={previewDownloadFile}
            tracks={tracks}
          />
        ) : (
          <STLoadingLogo />
        )}
      </div>
    </div>
  );
};

export default PreviewShareProject;
