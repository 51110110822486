import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";

const Dashboard = ({
  height = theme.svgSize.nav,
  width = theme.svgSize.nav,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      width={width}
      height={height}
      viewBox="-2 -10 30 55"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="dashboard-nav-link"
    >
      <title id="dashboard-nav-link">{t("Dashboard Page")}</title>
      <path
        d="M11.991 0.0117576C15.1747 0.0117576 18.3574 0.0376774 21.5401 0.0002214C22.9959 -0.017066 24.0158 0.97984 23.9998 2.35323C23.9538 6.33509 23.9769 10.317 23.9919 14.2988C23.9919 14.731 23.8839 14.8751 23.4089 14.8751C20.0092 14.8559 16.6095 14.8645 13.2098 14.8645C12.5879 14.8645 12.5049 14.7857 12.5049 14.1653C12.5049 10.9191 12.5066 7.67295 12.5099 4.42676C12.5099 4.11751 12.4359 3.86589 12.1099 3.8073C11.955 3.77945 11.741 3.87357 11.61 3.9773C11.52 4.04837 11.49 4.22989 11.489 4.36146C11.483 7.7229 11.461 11.0776 11.5 14.4362C11.51 15.3572 12.1779 15.9286 13.4488 15.9334C16.7315 15.9459 20.0142 15.9334 23.2969 15.9382C23.9828 15.9382 23.9828 15.9382 23.9828 16.5894C23.9828 22.1073 23.9845 27.6245 23.9879 33.1411C23.9879 34.4386 23.0729 35.3914 21.639 35.3933C15.207 35.4022 8.77423 35.4022 2.34082 35.3933C0.913952 35.3933 -0.00497924 34.4386 2.02982e-05 33.1334C0.014019 29.1516 0.00504913 25.1697 0.00504913 21.1879C0.00504913 20.5444 0.00504328 20.5434 0.648984 20.5434H10.7481C11.409 20.5434 11.483 20.6135 11.484 21.2608C11.484 24.475 11.482 27.6892 11.478 30.9034C11.478 31.2213 11.578 31.4575 11.893 31.5123C12.045 31.5392 12.2599 31.4306 12.3929 31.3202C12.4809 31.2462 12.5019 31.0647 12.5019 30.9312C12.5079 27.621 12.5079 24.3105 12.5019 20.9996C12.5019 20.1084 11.7549 19.4697 10.734 19.4687C7.38435 19.4687 4.03466 19.4687 0.684967 19.4687C0.00602953 19.4687 0.00504913 19.4687 0.00504913 18.8128V2.16691C0.00504913 0.939509 0.959973 0.0146398 2.24185 0.0127189C5.49156 0.00759674 8.74126 0.00727567 11.991 0.0117576Z"
        fill="white"
      />
    </svg>
  );
};

export default Dashboard;
