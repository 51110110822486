import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";

const ShareIcon = ({
  height = theme.svgSize.large,
  width = theme.svgSize.large,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="share-button"
    >
      <title id="share-button">{t("Share")}</title>
      <g clipPath="url(#clip0)">
        <circle
          cx="16.4639"
          cy="14"
          r="3.75"
          stroke={theme.colors.secondary.purple}
          strokeWidth="1.5"
        />
        <path
          d="M8.96387 27.8177V27.8177V25.5C8.96387 22.7386 11.0625 20.5 13.6514 20.5H19.2764C21.8652 20.5 23.9639 22.7386 23.9639 25.5C23.9639 26.2571 23.9639 27.0142 23.9639 27.7712"
          stroke={theme.colors.secondary.purple}
          strokeWidth="1.5"
        />
        <path
          d="M25.5361 14.4307V20.4307"
          stroke={theme.colors.secondary.purple}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M28.5361 17.4307H22.5361"
          stroke={theme.colors.secondary.purple}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="38" height="37" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShareIcon;
