import buildOrganisationsBaseUrl from "./base-url-helper";

export const getOrganisationUserUrl = (
  organisationId: string,
  userId: string
): string => {
  return `${buildOrganisationsBaseUrl(organisationId)}/members/${userId}`;
};

export const getOrganisationMembersUrl = (organisationId: string): string => {
  return `${buildOrganisationsBaseUrl(organisationId)}/members/_search`;
};

export const patchOrganisationMemberUrl = (
  organisationId: string,
  userId: string
): string => {
  return `${buildOrganisationsBaseUrl(organisationId)}/members/${userId}`;
};

export const applicationsUrl = (
  organisationId: string,
  userId: string
): string => {
  return `${buildOrganisationsBaseUrl(
    organisationId
  )}/members/${userId}/applications`;
};
