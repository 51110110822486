import React, { useCallback, useEffect, useMemo } from "react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import { ITalentHubTypes } from "src/api/talent-hub/interfaces";
import { useTranslation } from "react-i18next";
import { Form, Typography } from "antd";
import contactValidation from "src/utils/contactValidation";
import { useForm } from "antd/lib/form/Form";
import useContact from "src/providers/contact/hooks";
import style from "../../styles";
import ContactInput from "../contact-input";
import ContactSelect from "../contact-select";
import {
  IPersonalDetailsData,
  IPhoneNumber,
  Neurodiverse,
  PhysicalCondition,
} from "../../interfaces";
import PhoneNumberWithCountryCode from "../phone-number-with-country-code";
import getCountryPhoneCodeOptions from "../../utils";
import SocialMediaLinks from "../social-media-links";
import ShowReel from "../showreels";
import AgencyWritingInput from "../agency-writing-input";

interface IProps {
  personalDetailsData: IPersonalDetailsData;
  talentHubTypes: ITalentHubTypes;
  validateTabFields: number;
  isEditMode?: boolean;
  onPersonalDetailsDataChange: (data: IPersonalDetailsData) => void;
}

const PersonalDetails = ({
  personalDetailsData,
  talentHubTypes,
  validateTabFields,
  isEditMode,
  onPersonalDetailsDataChange,
}: IProps): React.ReactElement => {
  useWindowSize();
  const isMobile = getIsMobile();
  const { t } = useTranslation();
  const [form] = useForm();
  const contact = useContact();

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onPersonalDetailsDataChange({ [event.target.name]: event.target.value });
    },
    [onPersonalDetailsDataChange]
  );

  const onSingleSelectChange = useCallback(
    (property: string, option: IOption) => {
      onPersonalDetailsDataChange({
        [property]: option.value,
      });
    },
    [onPersonalDetailsDataChange]
  );

  const onMultipleSelectChange = useCallback(
    (property: string, options: IOption[]) => {
      onPersonalDetailsDataChange({
        [property]: options.map((option) => option.value),
      });
    },
    [onPersonalDetailsDataChange]
  );

  const physicalConditionOptions = useMemo(() => {
    return Object.entries(PhysicalCondition).map(([value, label]) => ({
      label,
      value,
    }));
  }, []);

  const neurodiverseConditionOptions = useMemo(() => {
    return Object.entries(Neurodiverse).map(([value, label]) => ({
      label,
      value,
    }));
  }, []);

  useEffect(() => {
    if (validateTabFields) {
      void form.validateFields([
        "name",
        "email",
        "phoneCountryCode",
        "phoneNumber",
        "baseLocation",
        "mainLanguage",
        "billingName",
        "billingAddress",
      ]);
    }
  }, [form, validateTabFields]);

  const findSingleSelectOption = useCallback(
    (options: IOption[], value: string) => {
      return options.find((option) => option.value === value);
    },
    []
  );

  const filterMultipleSelectOptions = useCallback(
    (options: IOption[], values: string[]) => {
      return options.filter((option) => values.includes(option.value));
    },
    []
  );

  const countryCodeOptions = useMemo(() => getCountryPhoneCodeOptions(), []);

  const socialMedia = useMemo(() => {
    let socialMediaData = personalDetailsData.socialMediaWebsite ?? [""];

    if (socialMediaData.length === 0) {
      socialMediaData = [""];
    }

    return socialMediaData;
  }, [personalDetailsData.socialMediaWebsite]);

  const showReelUrls = useMemo(() => {
    let showReelUrlsData = personalDetailsData.showreelUrls ?? [""];

    if (showReelUrlsData.length === 0) {
      showReelUrlsData = [""];
    }

    return showReelUrlsData;
  }, [personalDetailsData.showreelUrls]);

  const handleAgencyChange = useCallback(
    (agency?: string) => {
      onPersonalDetailsDataChange({ agencyWritingPartnership: agency });
    },
    [onPersonalDetailsDataChange]
  );

  return (
    <Form
      form={form}
      initialValues={{
        name: personalDetailsData.name,
        pronoun:
          personalDetailsData.pronoun &&
          findSingleSelectOption(
            talentHubTypes.genderPronouns,
            personalDetailsData.pronoun
          ),
        ipi: personalDetailsData.ipi,
        agencyWritingPartnership: personalDetailsData.agencyWritingPartnership,
        email: personalDetailsData.email,
        phoneCountryCode:
          personalDetailsData.phone?.countryCode &&
          findSingleSelectOption(
            countryCodeOptions,
            personalDetailsData.phone.countryCode
          ),
        phoneNumber: personalDetailsData.phone?.number,
        baseLocation:
          personalDetailsData.baseLocation &&
          findSingleSelectOption(
            talentHubTypes.countryCodes,
            personalDetailsData.baseLocation
          ),
        mainLanguage:
          personalDetailsData.mainLanguage &&
          findSingleSelectOption(
            talentHubTypes.languageCodes,
            personalDetailsData.mainLanguage
          ),
        otherLanguages:
          personalDetailsData.otherLanguages &&
          filterMultipleSelectOptions(
            talentHubTypes.languageCodes,
            personalDetailsData.otherLanguages
          ),
        billingName: personalDetailsData.billingName,
        billingAddress: personalDetailsData.billingAddress,
        neurodiverse:
          personalDetailsData.neurodiverse &&
          findSingleSelectOption(
            neurodiverseConditionOptions,
            personalDetailsData.neurodiverse
          ),
        neurodiverseNote: personalDetailsData.neurodiverseNote,
        physicalConditionsIllness:
          personalDetailsData.physicalConditionsIllness &&
          findSingleSelectOption(
            physicalConditionOptions,
            personalDetailsData.physicalConditionsIllness
          ),
        racialCharacteristics:
          personalDetailsData.racialCharacteristics &&
          findSingleSelectOption(
            talentHubTypes.racialCharacteristics,
            personalDetailsData.racialCharacteristics
          ),
        genderIdentity:
          personalDetailsData.genderIdentity &&
          findSingleSelectOption(
            talentHubTypes.genderIdentities,
            personalDetailsData.genderIdentity
          ),
        sexualOrientation:
          personalDetailsData.sexualOrientation &&
          findSingleSelectOption(
            talentHubTypes.sexualOrientations,
            personalDetailsData.sexualOrientation
          ),
      }}
      scrollToFirstError
    >
      <div id="PersonalDetails" css={style.sectionContainer}>
        <Typography.Text css={style.tabsTitle}>
          {t("ContactPage##Personal Details")}
        </Typography.Text>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactInput
            title={t("ContactPage##Full Name")}
            name="name"
            rules={contactValidation.requiredFieldValidator()}
            onChange={onInputChange}
          />
          <ContactSelect
            title={t("ContactPage##Pronouns")}
            name="pronoun"
            rules={[]}
            options={talentHubTypes.genderPronouns}
            id="pronoun-dropdown"
            onChange={(pronoun: IOption) =>
              onSingleSelectChange("pronoun", pronoun)
            }
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <AgencyWritingInput
            areAgenciesLoading={contact.areAgenciesLoading}
            agencies={contact.agencies}
            selectedAgency={personalDetailsData.agencyWritingPartnership}
            onSelectedAgencyChange={handleAgencyChange}
            onScrollAgencies={contact.handleAgenciesPageChange}
            onResetNarrowSearch={contact.handleResetAgencies}
            onUpdateNarrowSearchText={contact.handleUpdateNarrowSearchText}
          />
          <ContactInput
            title={t("ContactPage##Email")}
            name="email"
            rules={[
              ...contactValidation.emailValidator(),
              ...contactValidation.requiredFieldValidator(),
            ]}
            onChange={onInputChange}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactInput
            title={t("ContactPage##Writer IPI")}
            name="ipi"
            rules={[]}
            onChange={onInputChange}
          />
          {/* We inserted empty div for visual consistency */}
          <div />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <PhoneNumberWithCountryCode
            title={t("ContactPage##Phone Number")}
            countryCode={personalDetailsData.phone?.countryCode ?? ""}
            phoneNumber={personalDetailsData.phone?.number ?? ""}
            countryCodeFormItemName="phoneCountryCode"
            phoneNumberFormItemName="phoneNumber"
            propertyName="phone"
            onChange={(field: string, value: IPhoneNumber) =>
              onPersonalDetailsDataChange({ [field]: value })
            }
          />
          <ContactSelect
            title={t("ContactPage##Base Location")}
            name="baseLocation"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.countryCodes}
            id="base-location-dropdown"
            onChange={(baseLocation: IOption) =>
              onSingleSelectChange("baseLocation", baseLocation)
            }
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <SocialMediaLinks
            fieldName="socialMediaLinks"
            items={socialMedia}
            setSocialMediaLinks={(socialMediaWebsite: string[]) =>
              onPersonalDetailsDataChange({ socialMediaWebsite })
            }
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ShowReel
            isEditMode={isEditMode ?? false}
            fieldName="showreelUrls"
            items={showReelUrls}
            showReels={personalDetailsData.showReel ?? []}
            setShowReelLinks={(showreelLinks: string[]) =>
              onPersonalDetailsDataChange({ showreelUrls: showreelLinks })
            }
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##Native Language")}
            name="mainLanguage"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.languageCodes}
            id="main-language-dropdown"
            onChange={(mainLanguage: IOption) => {
              onSingleSelectChange("mainLanguage", mainLanguage);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Other Languages")}
            name="otherLanguages"
            rules={[]}
            options={talentHubTypes.languageCodes}
            id="other-language-dropdown"
            isMulti
            onChange={(otherLanguages: IOption[]) => {
              onMultipleSelectChange("otherLanguages", otherLanguages);
            }}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactInput
            title={t("ContactPage##Billing Name")}
            name="billingName"
            rules={[]}
            onChange={onInputChange}
          />
          <ContactInput
            title={t("ContactPage##Billing Address")}
            name="billingAddress"
            rules={[]}
            onChange={onInputChange}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##Neurodiverse")}
            name="neurodiverse"
            rules={[]}
            options={neurodiverseConditionOptions}
            id="neurodiverse-dropdown"
            onChange={(neurodiverse: IOption) => {
              onSingleSelectChange("neurodiverse", neurodiverse);
            }}
          />
          <ContactInput
            title={t("ContactPage##Neurodiverse Note")}
            name="neurodiverseNote"
            rules={[]}
            onChange={onInputChange}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##Physical Condition")}
            name="physicalConditionsIllness"
            rules={[]}
            options={physicalConditionOptions}
            id="physical-condition-illness-dropdown"
            onChange={(physicalConditionsIllness: IOption) => {
              onSingleSelectChange(
                "physicalConditionsIllness",
                physicalConditionsIllness
              );
            }}
          />
          <ContactSelect
            title={t("ContactPage##Racial Characteristics")}
            name="racialCharacteristics"
            rules={[]}
            options={talentHubTypes.racialCharacteristics}
            id="racial-characteristics-dropdown"
            onChange={(racialCharacteristics: IOption) => {
              onSingleSelectChange(
                "racialCharacteristics",
                racialCharacteristics
              );
            }}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##Gender Identity")}
            name="genderIdentity"
            rules={[]}
            options={talentHubTypes.genderIdentities}
            id="gender-identity-dropdown"
            onChange={(genderIdentity: IOption) => {
              onSingleSelectChange("genderIdentity", genderIdentity);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Sexual Orientation")}
            name="sexualOrientation"
            rules={[]}
            options={talentHubTypes.sexualOrientations}
            id="sexual-orientation-dropdown"
            onChange={(sexualOrientation: IOption) => {
              onSingleSelectChange("sexualOrientation", sexualOrientation);
            }}
          />
        </div>
      </div>
    </Form>
  );
};

export default PersonalDetails;
