import React, { Fragment } from "react";
import { format, parseISO } from "date-fns";
import { Dropdown, Tooltip } from "antd";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { FilePdfFilled } from "@ant-design/icons";
import MoreInfoButton from "src/components/control-buttons/more-info-button";
import getMusicUsageDeclarationReport from "src/api/music-usage-declaration-report";
import useAuth from "src/auth/use-auth";
import MenuItem from "src/components/menu-item";
import saveAs from "file-saver";
import style from "../styles";
import { IProjectLibraryTrackDetails } from "../../../interfaces";
import { ILibraryTrackDataSource } from "../interfaces";

export enum ChannelType {
  Youtube = "Youtube",
  Facebook = "Facebook",
  Instagram = "Instagram",
  Twitter = "Twitter",
  TikTok = "TikTok",
  CompanyWebsite = "CompanyWebsite",
  Other = "Other",
}
export default (
  libraryTracks: IProjectLibraryTrackDetails[]
): ILibraryTrackDataSource[] => {
  const { getAccessToken, organisationId } = useAuth();

  return libraryTracks.map(
    ({
      id,
      trackId,
      trackTitle,
      trackArtist,
      downloadedByUser,
      declaredByUser,
      createdDate,
      projectName,
      projectDescription,
      businessBrand,
      regions,
      channels,
      goLiveDate,
      projectId,
    }) => {
      const channelsByType: {
        [key: string]: string[];
      } = {
        youtube: [],
        facebook: [],
        instagram: [],
        twitter: [],
        tikTok: [],
        companyWebsite: [],
        other: [],
        videoLinks: (channels ?? [])?.[0]?.usageLinks ?? [],
      };

      channels.forEach((channel) => {
        switch (channel.type) {
          case ChannelType.Youtube:
            channelsByType.youtube.push(channel.name);
            break;
          case ChannelType.Facebook:
            channelsByType.facebook.push(channel.name);
            break;
          case ChannelType.Instagram:
            channelsByType.instagram.push(channel.name);
            break;
          case ChannelType.Twitter:
            channelsByType.twitter.push(channel.name);
            break;
          case ChannelType.TikTok:
            channelsByType.tikTok.push(channel.name);
            break;
          case ChannelType.CompanyWebsite:
            channelsByType.companyWebsite.push(channel.id);
            break;
          case ChannelType.Other:
            channelsByType.other.push(channel.id);
            break;
          default:
            break;
        }
      });
      const territories = (regions ?? []).join(", ");
      const youtubeChannels = channelsByType.youtube.join(", ");
      const facebookPages = channelsByType.facebook.join(", ");
      const instagramAccounts = channelsByType.instagram.join(", ");
      const twitterAccounts = channelsByType.twitter.join(", ");
      const tikTokAccounts = channelsByType.tikTok.join(", ");
      const companyWebsite = channelsByType.companyWebsite.join(", ");
      const otherChannels = channelsByType.other.join(", ");
      const videoLinks = channelsByType.videoLinks.join(", ");

      const items = [
        {
          label: (
            <MenuItem
              ariaLabel="Download license PDF"
              buttonLabel="Download license PDF"
              onClick={async () => {
                try {
                  SuccessToast("File downloading to your file storage");
                  const musicUsageDeclarationResponse = await getMusicUsageDeclarationReport(
                    projectId,
                    id,
                    getAccessToken(),
                    organisationId
                  );
                  saveAs(
                    musicUsageDeclarationResponse,
                    `Music Usage Declaration - ${id}.pdf`
                  );
                } catch {
                  ErrorToast(
                    "download-license-pdf--error",
                    "There was a problem downloading the file"
                  );
                }
              }}
              icon={<FilePdfFilled css={style.mr5} />}
            />
          ),
          key: "Download license PDF",
        },
      ];

      return {
        key: id,
        trackId: (
          <Tooltip placement="topLeft" title={trackId ?? ""}>
            <div css={style.tableDataText}>{trackId ?? ""}</div>
          </Tooltip>
        ),
        trackTitle: (
          <Tooltip placement="topLeft" title={trackTitle ?? ""}>
            <div css={style.tableDataText}>{trackTitle ?? ""}</div>
          </Tooltip>
        ),
        trackArtist: (
          <Tooltip placement="topLeft" title={trackArtist ?? ""}>
            <div css={style.tableDataText}>{trackArtist ?? ""}</div>
          </Tooltip>
        ),
        downloadedBy: (
          <Fragment>
            <Tooltip placement="topLeft" title={downloadedByUser.name ?? ""}>
              <div css={style.tableDataText}>{downloadedByUser.name ?? ""}</div>
            </Tooltip>
            <Tooltip placement="topLeft" title={downloadedByUser.email ?? ""}>
              <div css={style.tableDataSubText}>
                {downloadedByUser.email ?? ""}
              </div>
            </Tooltip>
          </Fragment>
        ),
        declaredBy: (
          <Fragment>
            <Tooltip placement="topLeft" title={declaredByUser.email ?? ""}>
              <div css={style.tableDataText}>{declaredByUser.name ?? ""}</div>
            </Tooltip>
            <Tooltip placement="topLeft" title={declaredByUser.email ?? ""}>
              <div css={style.tableDataSubText}>
                {declaredByUser.email ?? ""}
              </div>
            </Tooltip>
          </Fragment>
        ),
        createdDate: (
          <div css={style.tableDataText}>
            {createdDate && format(parseISO(createdDate), "MMM do, yyyy")}
          </div>
        ),
        projectName: (
          <Tooltip placement="topLeft" title={projectName ?? ""}>
            <div css={style.tableDataText}>{projectName ?? ""}</div>
          </Tooltip>
        ),
        projectDescription: (
          <Tooltip placement="topLeft" title={projectDescription ?? ""}>
            <div css={style.tableDataText}>{projectDescription ?? ""}</div>
          </Tooltip>
        ),
        businessBrand: (
          <Tooltip placement="topLeft" title={businessBrand ?? ""}>
            <div css={style.tableDataText}>{businessBrand ?? ""}</div>
          </Tooltip>
        ),
        territories: (
          <Tooltip placement="topLeft" title={territories}>
            <div css={style.tableDataSubText}>{territories}</div>
          </Tooltip>
        ),
        goLiveDate: (
          <div css={style.tableDataText}>
            {goLiveDate && format(parseISO(goLiveDate), "MMM do, yyyy")}
          </div>
        ),
        youtubeChannels: (
          <Tooltip placement="topLeft" title={youtubeChannels}>
            <div css={style.tableDataSubText}>{youtubeChannels}</div>
          </Tooltip>
        ),
        facebookPages: (
          <Tooltip placement="topLeft" title={facebookPages}>
            <div css={style.tableDataSubText}>{facebookPages}</div>
          </Tooltip>
        ),
        instagramPages: (
          <Tooltip placement="topLeft" title={instagramAccounts}>
            <div css={style.tableDataSubText}>{instagramAccounts}</div>
          </Tooltip>
        ),
        tikTokAccounts: (
          <Tooltip placement="topLeft" title={tikTokAccounts}>
            <div css={style.tableDataSubText}>{tikTokAccounts}</div>
          </Tooltip>
        ),
        twitterAccounts: (
          <Tooltip placement="topLeft" title={twitterAccounts}>
            <div css={style.tableDataSubText}>{twitterAccounts}</div>
          </Tooltip>
        ),
        companyWebsite: (
          <Tooltip placement="topLeft" title={companyWebsite}>
            <div css={style.tableDataSubText}>{companyWebsite}</div>
          </Tooltip>
        ),
        other: (
          <Tooltip placement="topLeft" title={otherChannels}>
            <div css={style.tableDataSubText}>{otherChannels}</div>
          </Tooltip>
        ),
        videoLinks: (
          <Tooltip placement="topLeft" title={videoLinks}>
            <div css={style.tableDataSubText}>{videoLinks}</div>
          </Tooltip>
        ),
        menuDropDown: (
          <Dropdown
            aria-label="action-items-dropdown"
            menu={{ items }}
            data-testid="action-items-dropdown"
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode}
            placement="bottomRight"
            css={style.dropdownMenu}
          >
            <MoreInfoButton
              showTooltip
              customStyles={style.moreInfoButton}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        ),
      };
    }
  );
};
