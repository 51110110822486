import { Form, Input, Typography } from "antd";
import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "@songtradr/spa-common";
import { IFormContactsInvoices } from "src/pages/projects/project/interfaces";
import style from "src/pages/projects/project/styles/index";
import ControlButton from "src/components/control-buttons/base-button";
import { PlusOutlined } from "@ant-design/icons";
import DeleteButton from "src/components/control-buttons/delete-button";
import { getIsValidEmail } from "src/pages/team/components/org-invites/components/invite-members/email-validator";
import { ValidateFormItem } from "../../../music/components/fee-split/fee-split";

type IContactInvoice = {
  contactInvoice: IFormContactsInvoices;
  index: number;
  propertyName: string;
  isLastItem: boolean;
  onAddNewRowItem: () => void;
  onDeleteRowItem: (index: number) => void;
  onUpdateRow: (index: number, invoice: IFormContactsInvoices) => void;
};
const ContactInvoice = ({
  contactInvoice,
  index,
  isLastItem,
  propertyName,
  onAddNewRowItem,
  onDeleteRowItem,
  onUpdateRow,
}: IContactInvoice): ReactElement => {
  const { t } = useTranslation();
  const [validationType, setValidationType] = useState<string | undefined>(
    ValidateFormItem.onBlur
  );
  const [invoiceContact, setInvoiceContact] = useState<string>(
    contactInvoice.name ?? ""
  );
  const [invoiceContactEmail, setInvoiceContactEmail] = useState<string>(
    contactInvoice.email ?? ""
  );
  const isMobile = getIsMobile();

  const handleUpdateContactRow = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, property: string) => {
      onUpdateRow(index, {
        ...contactInvoice,
        [property]: event.target.value,
      });
    },
    [contactInvoice, index, onUpdateRow]
  );
  return (
    <div
      css={[
        isMobile ? style.mobileSectionContainer : style.clientContainer,
        style.lastOfSectionBottom,
      ]}
    >
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##contactsSection##Invoicee Contact")}
          </Typography.Text>
        </div>
        <Input
          type="text"
          name={`${propertyName}Contact`}
          defaultValue={invoiceContact}
          onBlur={(e) => {
            if (e.target.value !== invoiceContact) {
              setInvoiceContact(e.target.value);
              onUpdateRow(index, {
                name: e.target.value,
                email: contactInvoice.email,
                key: contactInvoice.key,
              });
            }
          }}
        />
      </div>
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##contactsSection##Invoicee Contact Email")}
          </Typography.Text>
        </div>
        <Form.Item
          css={style.formItem}
          name={`${propertyName}Email`}
          validateTrigger={validationType}
          initialValue={contactInvoice.email}
          rules={[
            () => ({
              validator(_rule, value?: string) {
                if (value && validationType !== ValidateFormItem.onChange) {
                  const isEmailValid = getIsValidEmail(value);
                  if (!isEmailValid) {
                    return Promise.reject(new Error("Email format is invalid"));
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="text"
            name={`${propertyName}Email`}
            onChange={(event) => {
              handleUpdateContactRow(event, "email");
            }}
            value={contactInvoice.email}
            onBlur={(e) => {
              setValidationType(ValidateFormItem.onBlur);
              if (e.target.value !== invoiceContactEmail) {
                setInvoiceContactEmail(e.target.value);
                onUpdateRow(index, {
                  name: contactInvoice.name,
                  email: e.target.value,
                  key: contactInvoice.key,
                });
              }
            }}
          />
        </Form.Item>
      </div>
      <div css={style.iconsContainer}>
        {isLastItem ? (
          <div css={style.addIconContainer}>
            <ControlButton
              showTooltip
              tooltipColor="#222222"
              label={t("Add new")}
              data-testid="add-new-row-button"
              onClick={onAddNewRowItem}
            >
              <PlusOutlined />
            </ControlButton>
          </div>
        ) : (
          <div css={style.formItemWidth} />
        )}
        <div css={style.deleteIconContainer}>
          <DeleteButton onClick={() => onDeleteRowItem(index)} />
        </div>
      </div>
    </div>
  );
};

export default ContactInvoice;
