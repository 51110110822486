import React, { ReactElement, useMemo } from "react";
import Lottie from "react-lottie-player";
import loadingLogo from "src/app/assets/animations/st-loading-logo.json";
import { SerializedStyles } from "@emotion/react";
import style from "./styles";

export enum STLoadingLogoSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

interface ISTLoadingLogoProps {
  relativePosition?: boolean;
  pageCentered?: boolean;
  size?: STLoadingLogoSize;
  width?: number;
  height?: number;
  styles?: SerializedStyles;
  darkMode?: boolean;
}

const STLoadingLogo = ({
  relativePosition = false,
  pageCentered = false,
  size = STLoadingLogoSize.Large,
  width = 300,
  height = 300,
  styles,
  darkMode = true,
}: ISTLoadingLogoProps): ReactElement => {
  const position = useMemo(() => (relativePosition ? "relative" : "absolute"), [
    relativePosition,
  ]);

  const dimensions = useMemo(() => {
    switch (size) {
      case STLoadingLogoSize.Small:
        return { width: 100, height: 100 };
      case STLoadingLogoSize.Medium:
        return { width: 160, height: 160 };
      case STLoadingLogoSize.Large:
        return { width: 300, height: 300 };
      default:
        return { width, height };
    }
  }, [size, width, height]);

  return (
    <div
      css={[style.loadingContainer(position, pageCentered, darkMode), styles]}
      data-testid="projects-loading-spinner"
      id="projects-loading-spinner"
    >
      <Lottie animationData={loadingLogo} loop play style={{ ...dimensions }} />
    </div>
  );
};

export default STLoadingLogo;
