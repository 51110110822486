import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import theme from "src/theme";
import "./index.css";

const sharedDescriptionStyle = css`
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: bold;
`;

export default {
  descriptionLink: css`
    ${sharedDescriptionStyle}
    color: ${theme.colors.primary};

    :hover {
      color: ${theme.colors.primary};
      text-decoration: none;
    }
    @media ${queries.large} {
      :hover {
        color: ${theme.colors.white};
      }
    }

    :visited {
      text-decoration: none;
    }

    :focus {
      text-decoration: none;
    }

    :active {
      text-decoration: none;
    }
  `,
  description: css`
    ${sharedDescriptionStyle}
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
  `,
  messageTitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: bold;
    color: ${theme.colors.white};
  `,
  downloadTrackMessageTitle: css`
    font-weight: bold;
    color: ${theme.colors.white};
    line-height: 1rem;
  `,
  iconWrapper: css`
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.3125rem;
  `,
};
