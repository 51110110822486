import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { ConfigProvider, Typography } from "antd";
import { getIsTablet, useWindowSize } from "@songtradr/spa-common";
import Table from "src/components/table";
import { LibraryTrackPageSize } from "src/constants";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import STLoadingLogo from "src/components/st-loading-logo";
import { IProjectLibraryTrackDetails } from "../../../interfaces";
import style from "../styles";
import getColumns from "../table-data/get-columns";
import getDataSource from "../table-data/get-data-source";
import { ILibraryTrackTableSort } from "../interfaces";

const NoLibraryTracksFound = (): ReactElement => {
  return (
    <div css={style.noProjectSearchContainer}>
      <div css={style.noProjectSearchContentContainer}>
        <Typography.Text css={style.textHeader}>
          No declared tracks found
        </Typography.Text>
      </div>
    </div>
  );
};

interface IProps {
  isLoading: boolean;
  libraryTracks: IProjectLibraryTrackDetails[];
  handlePageChange: (page: number) => void;
  paginationOptions: { total: number; currentPage: number };
  debouncedSearchTerm: string;
  sortOptions: ILibraryTrackTableSort;
  handleSort: (params: ILibraryTrackTableSort) => void;
}

const LibraryTrackTable = ({
  isLoading,
  libraryTracks,
  handlePageChange,
  paginationOptions,
  debouncedSearchTerm,
  handleSort,
  sortOptions,
}: IProps): ReactElement => {
  const [paginationRange, setPaginationRange] = useState([
    1,
    paginationOptions.total > 0 &&
    paginationOptions.total < LibraryTrackPageSize
      ? paginationOptions.total
      : LibraryTrackPageSize,
  ]);

  useWindowSize();
  const isTablet = getIsTablet();
  const columns = getColumns(isTablet, handleSort, sortOptions);
  const dataSource = getDataSource(libraryTracks);

  useEffect(() => {
    if (paginationOptions.total > 0) {
      const min =
        (paginationOptions.currentPage - 1) * LibraryTrackPageSize + 1;
      const max =
        paginationOptions.total <
        LibraryTrackPageSize * paginationOptions.currentPage
          ? paginationOptions.total
          : LibraryTrackPageSize * paginationOptions.currentPage;
      setPaginationRange([min, max]);
    }
  }, [paginationOptions.total, paginationOptions.currentPage]);

  const onPageChange = (page: number) => {
    handlePageChange(page);
    if (page === 1) {
      setPaginationRange([
        1,
        paginationOptions.total < LibraryTrackPageSize
          ? paginationOptions.total
          : LibraryTrackPageSize,
      ]);
      return;
    }
    const min = (page - 1) * LibraryTrackPageSize + 1;
    const max =
      min + LibraryTrackPageSize < paginationOptions.total
        ? min + LibraryTrackPageSize - 1
        : paginationOptions.total;
    setPaginationRange([min, max]);
  };

  const itemRender = useCallback(
    (current: number, type: string, originalElement: ReactElement) => {
      if (type === "prev") {
        return <ArrowLeftOutlined />;
      }
      if (type === "next") {
        return <ArrowRightOutlined />;
      }
      return originalElement;
    },
    []
  );

  return (
    <div css={style.libraryTrackTableContainer}>
      <ConfigProvider
        renderEmpty={() => {
          return !isLoading ? <NoLibraryTracksFound /> : <></>;
        }}
      />
      <ConfigProvider renderEmpty={() => <NoLibraryTracksFound />}>
        <Table
          columns={columns}
          dataSource={dataSource}
          loading={{
            indicator: <STLoadingLogo />,
            spinning: isLoading,
          }}
          css={style.libraryProjectsTable}
          pagination={{
            pageSize: LibraryTrackPageSize,
            total: paginationOptions.total,
            current: paginationOptions.currentPage,
            position: ["bottomCenter"],
            onChange: (page) => onPageChange(page),
            showSizeChanger: false,
            showLessItems: true,
            itemRender,
          }}
          scroll={{ x: true }}
        />
      </ConfigProvider>
      {libraryTracks.length === 0 &&
      debouncedSearchTerm === "" &&
      !isLoading ? null : (
        <div
          css={style.paginationCount}
        >{`Showing ${paginationRange[0]}-${paginationRange[1]} of ${paginationOptions.total} results`}</div>
      )}
    </div>
  );
};

export default LibraryTrackTable;
