import axios from "axios";
import { getCreativeAgenciesUrl } from "src/api/creative-agencies-url-helper";
import { ICreativeAgency } from "src/pages/projects/project/components/details/components/creative-agency-row/interfaces";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const getCreativeAgencies = async (
  accessToken: string,
  organisationId: string
): Promise<ICreativeAgency[]> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios
    .get(getCreativeAgenciesUrl(organisationId), options)
    .then(({ data }) => data as ICreativeAgency[]);
};

export default getCreativeAgencies;
