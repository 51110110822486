import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { INameLinkState } from "src/pages/projects/project/interfaces";
import { Operation } from "src/constants";
import mainStyles from "src/pages/projects/project/styles";
import NameLinkRows from "../name-link-rows";

interface IProps {
  fieldName: string;
  items: Array<INameLinkState>;
  setFinalTrackLinks: (link: INameLinkState[]) => void;
}

const FinalTrackLinks = ({
  fieldName,
  items,
  setFinalTrackLinks,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const onPerformOperation = useCallback(
    (operation: Operation, data?: INameLinkState, index?: number) => {
      if (data) {
        const isRemoveOperation = operation === Operation.remove;
        const isUpdateOperation = operation === Operation.update;
        const isValidIndex = index || index === 0;
        const link: INameLinkState = {
          name: data.name,
          link: data.link,
          key: data.key,
          isNew: data.isNew,
        };
        if (isRemoveOperation && isValidIndex) {
          const newArray = [...items];
          newArray.splice(index, 1);
          setFinalTrackLinks(newArray);
        } else if (operation === Operation.add) {
          setFinalTrackLinks([...items, link]);
        } else if (isUpdateOperation && isValidIndex) {
          const newArray = [...items];
          newArray[index] = link;
          setFinalTrackLinks(newArray);
        }
      }
    },
    [items, setFinalTrackLinks]
  );

  return (
    <div css={mainStyles.sectionFormContainer}>
      <Typography.Text css={mainStyles.trackTitleLabel}>
        {t("ProjectsPage##musicSection##Final track links")}
      </Typography.Text>
      <NameLinkRows
        fieldName={fieldName}
        items={items}
        nameHelpText="ProjectsPage##musicSection##finalTrackLinksNameHelpText"
        onPerformOperation={onPerformOperation}
      />
    </div>
  );
};

export default FinalTrackLinks;
