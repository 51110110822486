import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ProjectStatus } from "src/pages/projects/project/interfaces";
import style from "./styles";

interface IProps {
  status: ProjectStatus;
  dataTestId?: string;
}

const styleMap = new Map([
  [ProjectStatus.InProgress, style.onHold],
  [ProjectStatus.NewOpen, style.open],
  [ProjectStatus.InContractInvoiced, style.inContract],
  [ProjectStatus.OnHold, style.onHold],
  [ProjectStatus.Cancelled, style.closed],
  [ProjectStatus.Complete, style.complete],
]);

const StatusPill = ({ status, dataTestId }: IProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <div css={styleMap.get(status)} data-testid={dataTestId}>
      <span className="circle" />
      <span className="label">
        {t(`ProjectsPage##ProjectStatus##${status}`)}
      </span>
    </div>
  );
};

export default StatusPill;
