import { Button } from "antd";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";

interface IProps {
  onClick: () => void;
  ariaLabel: string;
  label: string;
  disabled?: boolean;
}

const ButtonSendNotifications = ({
  onClick,
  ariaLabel,
  label,
  disabled = false,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={[styles.container, disabled && styles.disabledContainer]}>
      <Button
        tabIndex={0}
        aria-label={t(ariaLabel)}
        onClick={onClick}
        style={{
          marginLeft: 0,
        }}
        disabled={disabled}
      >
        <div css={styles.iconContainer}>{t(label)}</div>
      </Button>
    </div>
  );
};

export default ButtonSendNotifications;
