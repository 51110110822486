import axios, { CancelTokenSource } from "axios";
import {
  IOrganisationItem,
  IOrganisationMemberProps,
  IOrganisationMembersResponse,
} from "src/pages/team/interfaces";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { getOrganisationMembersUrl } from "../../organisation-members-url-helper";

let call: CancelTokenSource;

const getOrganisationMembers = async (
  accessToken: string,
  orgID: string,
  currentUserID?: string,
  includeSuperAdmins?: boolean
): Promise<Array<IOrganisationMemberProps>> => {
  call = axios.CancelToken.source();
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    cancelToken: call.token,
  };

  const response: IOrganisationMembersResponse = await axios
    .post(
      getOrganisationMembersUrl(orgID),
      {
        start: 0,
        includeSuperAdmins: includeSuperAdmins ?? false,
        perPage: 1000, // TODO - Pagination when required
        orderBy: "Asc",
      },
      options
    )
    .then(({ data }: { data: IOrganisationMembersResponse }) => data);

  const isCurrentUser = (userIdToCheck: string): boolean => {
    return userIdToCheck === currentUserID;
  };

  const orgMembers: Array<IOrganisationMemberProps> = response.items?.map(
    (orgMember: IOrganisationItem) => {
      const { organisationId } = orgMember;
      const {
        firstName,
        lastName,
        id,
        auth0UserId,
        email,
        phoneNumber,
        jobTitle,
        applications,
        isSuperAdmin,
      } = orgMember;

      const newOrgMember: IOrganisationMemberProps = {
        organisationId,
        isCurrentUser: isCurrentUser(auth0UserId),
        key: id,
        firstName,
        lastName,
        jobTitle,
        phoneNumber,
        id,
        auth0UserId,
        email,
        applications,
        isSuperAdmin,
      };

      return newOrgMember;
    }
  );

  return orgMembers;
};

export default getOrganisationMembers;
