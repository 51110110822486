import axios from "axios";
import {
  ICreateServiceResponse,
  IService,
} from "src/pages/projects/project/interfaces";
import { updateServiceUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const updateService = async (
  accessToken: string,
  projectId: string,
  payload: IService
): Promise<ICreateServiceResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.put(updateServiceUrl(projectId, payload.id), payload, options);
};

export default updateService;
