import React from "react";
import { TFunction } from "react-i18next";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import style from "../styles";

export default (
  isTablet: boolean,
  isMobile: boolean,
  t: TFunction<string>
): Array<IColumnsData> => {
  if (isMobile) {
    return [
      {
        title: (
          <div css={style.headerContainer}>
            <span>{t("manageTeam##invites##Invites pending")}</span>
          </div>
        ),
        dataIndex: "email",
        key: "key",
        width: "100%",
      },
    ];
  }
  if (isTablet) {
    return [
      {
        title: (
          <div css={style.headerContainer}>
            <span>{t("manageTeam##invites##Invites pending")}</span>
          </div>
        ),
        dataIndex: "email",
        key: "key",
        width: "55%",
      },
      {
        title: <div />,
        dataIndex: "status",
        key: "key",
        width: "20%",
        className: "team-invite-align-right",
      },
      {
        title: <div />,
        dataIndex: "resend",
        key: "key",
        width: "15%",
        className: "team-invite-align-right",
      },
      {
        title: <div />,
        dataIndex: "delete",
        key: "key",
        width: "10%",
        className: "team-invite-align-right",
      },
    ];
  }

  return [
    {
      title: (
        <div css={style.titleContainer}>
          <span css={style.titleMembers}>
            {t("manageTeam##invites##Invites pending")}
          </span>
        </div>
      ),
      dataIndex: "email",
      key: "key",
      width: "55%",
    },
    {
      title: <div />,
      dataIndex: "status",
      key: "key",
      width: "20%",
      className: "team-invite-align-right",
    },
    {
      title: <div />,
      dataIndex: "resend",
      key: "key",
      width: "15%",
      className: "team-invite-align-right",
    },
    {
      title: <div />,
      dataIndex: "delete",
      key: "key",
      width: "10%",
      className: "team-invite-align-right",
    },
  ];
};
