import React, { Fragment, ReactElement } from "react";
import { IConfirmContentProps } from "src/components/interfaces";
import MobileDrawer from "src/components/mobile-drawer";
import {
  IOrganisationMemberProps,
  ITableSort,
  OrgMembersSortType,
  SortOrder,
} from "../interfaces";
import style from "../styles";
import generateMemberDrawerContent, { OrgMembersDrawerTypes } from "./drawers";

interface IProps {
  confirmDeleteProps: IConfirmContentProps;
  mobileDrawerVisible: boolean;
  handleDrawerToggle: () => void;
  currentActiveDrawer: OrgMembersDrawerTypes;
  setInvitesLastUpdatedAt: (date: Date) => void;
  selectedOrgMember?: IOrganisationMemberProps;
  handleToggleAdmin: (selectedOrgMember: IOrganisationMemberProps) => void;
  setSortTypeAndOrder: (
    sortType: OrgMembersSortType,
    sortOrder: SortOrder
  ) => void;
  getSortTypeAndOrder: () => ITableSort;
}

const OrgMembersMobileControls = ({
  confirmDeleteProps,
  mobileDrawerVisible,
  handleDrawerToggle,
  currentActiveDrawer,
  setInvitesLastUpdatedAt,
  selectedOrgMember,
  handleToggleAdmin,
  setSortTypeAndOrder,
  getSortTypeAndOrder,
}: IProps): ReactElement => {
  const Drawer = generateMemberDrawerContent(
    currentActiveDrawer,
    handleDrawerToggle,
    setInvitesLastUpdatedAt,
    handleToggleAdmin,
    confirmDeleteProps,
    setSortTypeAndOrder,
    getSortTypeAndOrder,
    selectedOrgMember
  );

  return (
    <Fragment>
      <MobileDrawer
        data-testid="mobile-drawer"
        placement="bottom"
        onClose={handleDrawerToggle}
        open={mobileDrawerVisible}
        key="bottom"
        closable={false}
        destroyOnClose
        css={style.drawer}
      >
        {Drawer}
      </MobileDrawer>
    </Fragment>
  );
};

export default OrgMembersMobileControls;
