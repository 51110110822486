import { IOption } from "src/pages/projects/project/components/account-information/types";
import { Confirm, Signed } from "src/pages/contact/interfaces";
import { ITalentHubTypes } from "src/api/talent-hub/interfaces";
import getFiltersASCByName from "src/utils/getSortedASCByNameFilters";
import { IRegionWithCountries, regionsWithCountries } from "src/constants";
import { AllContactsFiltersValues, IContactsFilters } from "../interfaces";

const getTypesForFilterName = (
  filterName: string,
  talentHubTypes: ITalentHubTypes
): IOption[] => {
  const signedOptions = (type: "CMA" | "NDA") => {
    return Object.entries(Signed).map(([value, label]) => ({
      label: `${type} Signed?: ${label}`,
      value,
    }));
  };
  let typesForFilterName: IOption[] = [];
  switch (filterName) {
    case AllContactsFiltersValues.Location:
      typesForFilterName = talentHubTypes.countryCodes;
      break;
    case AllContactsFiltersValues.Language:
      typesForFilterName = talentHubTypes.languageCodes;
      break;
    case AllContactsFiltersValues.Collaboration:
      typesForFilterName = talentHubTypes.talentCollaborations;
      break;
    case AllContactsFiltersValues.SkillSet:
      typesForFilterName = talentHubTypes.skillSets;
      break;
    case AllContactsFiltersValues.Instrument:
      typesForFilterName = talentHubTypes.talentInstruments;
      break;
    case AllContactsFiltersValues.Genre:
      typesForFilterName = talentHubTypes.musicGenres;
      break;
    case AllContactsFiltersValues.Vocalist:
      typesForFilterName = Object.values(Confirm).map((label) => ({
        value: label === Confirm.Yes ? "true" : "false",
        label: `Is vocalist?: ${label}`,
      }));
      break;
    case AllContactsFiltersValues.ServiceProvider:
      typesForFilterName = Object.values(Confirm).map((label) => ({
        value: label === Confirm.Yes ? "true" : "false",
        label: `Is service provider?: ${label}`,
      }));
      break;
    case AllContactsFiltersValues.RacialCharacteristics:
      typesForFilterName = talentHubTypes.racialCharacteristics;
      break;
    case AllContactsFiltersValues.GenderIdentity:
      typesForFilterName = talentHubTypes.genderIdentities;
      break;
    case AllContactsFiltersValues.NDASigned:
      typesForFilterName = signedOptions("NDA");
      break;
    case AllContactsFiltersValues.CMASigned:
      typesForFilterName = signedOptions("CMA");
      break;
    default:
      break;
  }

  return typesForFilterName;
};

const getFilterOptions = (
  filterName: string,
  availableContactFilters: IContactsFilters,
  talentHubTypes: ITalentHubTypes
): IOption[] => {
  const typesForFilterName = getTypesForFilterName(filterName, talentHubTypes);
  const availableFilterOptions = availableContactFilters[filterName] ?? [];
  const filterOptions: IOption[] = [];
  availableFilterOptions.forEach((filterOption) => {
    const option = typesForFilterName.find(
      (type) => type.value === String(filterOption)
    );
    if (option) {
      filterOptions.push(option);
    }
  });

  return getFiltersASCByName(filterOptions);
};

const getNonNullableCountriesForRegion = <
  T extends { value: string; label: string }
>(
  countriesForRegion: (IOption | null)[]
) => {
  return countriesForRegion.filter(
    (data): data is NonNullable<T> => data !== null
  );
};

export const getAllSelectedFilterValuesForRegion = (
  mappedCountriesForRegion: IOption[],
  allSelectedFilterValues: string[]
): string[] => {
  return mappedCountriesForRegion
    .filter((option) => allSelectedFilterValues.includes(option.value))
    .map((option) => option.value);
};

export const getAllSelectedFilterValuesExceptForSelectedRegion = (
  allSelectedFilterValuesForRegion: string[],
  allSelectedFilterValues: string[]
): string[] => {
  return allSelectedFilterValues.filter(
    (value) => !allSelectedFilterValuesForRegion.includes(value)
  );
};

export const getMappedLocationFilterOptions = (
  locations: string[],
  locationOptions: IOption[]
): IOption[] => {
  const mappedOptions = locations.map((location) => {
    const locationValueAndLabel = locationOptions.find(
      (locationOption) => locationOption.label === location
    );
    if (!locationValueAndLabel?.value) {
      return null;
    }
    return {
      value: locationValueAndLabel.value,
      label: locationValueAndLabel.label,
    };
  });

  return getNonNullableCountriesForRegion(mappedOptions);
};

export const getLocationFilterOptions = (
  selectedLocations: string[]
): IRegionWithCountries => {
  let regionsWithSelectedCountries = {};
  Object.entries(regionsWithCountries).forEach(([region, data]) => {
    const selectedCountries = data.filter((country) =>
      selectedLocations.includes(country)
    );
    regionsWithSelectedCountries = {
      ...regionsWithSelectedCountries,
      [region]: selectedCountries,
    };
  });
  return regionsWithSelectedCountries;
};

export const getFilterValuesFromFilterOptions = (
  selectedContactFilters: IContactsFilters,
  talentHubTypes: ITalentHubTypes
): IOption[] => {
  let selectedOptions: IOption[] = [];
  Object.keys(selectedContactFilters).forEach((filterName) => {
    selectedOptions = selectedOptions.concat(
      getFilterOptions(filterName, selectedContactFilters, talentHubTypes)
    );
  });

  return selectedOptions;
};

export default getFilterOptions;
