import { css } from "@emotion/react";
import theme from "src/theme";

const containerStyles = `button {
  display: flex;
  align-items: center;
  -webkit-box-shadow: none;
  padding-left: 0 !important;
  background: ${theme.colors.white};
  min-height: 100%;
  color: ${theme.colors.primary.blue};
  border-radius: 4px;
  border:none;
  svg {
    margin-right: 0;
    circle {
      stroke: none;
    }
    line {
      stroke: ${theme.colors.primary.blue};
    }
  }

  :hover {
    background: ${theme.colors.white};
    color: ${theme.colors.secondary.navyBlue};
    svg {
      margin-right: 0;
      circle {
        stroke: none;
      }
      line {
        stroke: ${theme.colors.secondary.navyBlue};
      }
    }
  }
  :focus {
    background: ${theme.colors.white};
    color: ${theme.colors.secondary.navyBlue};
    svg {
      margin-right: 0;
      circle {
        stroke: none;
      }
      line {
        stroke: ${theme.colors.secondary.navyBlue};
      }
    }
  }
}`;
export default {
  container: css`
    ${containerStyles}
  `,
  mobileContainer: css`
    ${containerStyles};
    margin-top: 0.5rem;
  `,
  addAnotherButton: css`
    height: 100%;
  `,
  button: css`
    font-weight: 600;
    color: ${theme.colors.primary.blue} !important;
    &:hover {
      color: ${theme.colors.secondary.darkBlue} !important;
      background-color: #fff !important;
    }
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-family: ${theme.fonts.sofiaPro};
  `,
};
