import React from "react";
import { Typography } from "antd";
import Form, { Rule } from "antd/lib/form";
import ReactSelect, { MenuPlacement } from "react-select";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import style, { customStyles } from "../../styles";

interface IProps {
  title: string;
  name: string;
  rules: Rule[];
  options: IOption[];
  id: string;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
  onChange: (data: IOption[]) => void;
}

const ContactSelect = ({
  title,
  name,
  rules,
  options,
  id,
  isMulti = false,
  menuPlacement = "auto",
  onChange,
}: IProps): React.ReactElement => {
  return (
    <div>
      <div css={style.basicInputContainer}>
        <Typography.Text css={style.basicInputLabel}>{title} </Typography.Text>
      </div>
      <div css={style.select}>
        <Form.Item name={name} rules={rules} css={style.formItem}>
          <ReactSelect
            menuPlacement={menuPlacement ?? "auto"}
            isMulti={isMulti}
            isClearable={false}
            styles={customStyles}
            placeholder=""
            options={options}
            onChange={onChange}
            id={id}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default ContactSelect;
