/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getIsMobile } from "@songtradr/spa-common/lib/utils";
import { Form, Select, Tag } from "antd";
import { RuleObject } from "antd/lib/form";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import sendOrganisationInvites from "src/api/organisation-invites/send-org-invites";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import UploadCompleteIcon from "src/app/assets/icons/component-icons/upload-complete";
import useAuth from "src/auth/use-auth";
import Button from "src/components/button";
import FloatingLabel from "src/components/floating-label";
import SecondaryModalButtonLink from "src/components/modals/modal-buttons/secondary-button-link";
import { ErrorToast } from "src/components/toast-notification";
import theme from "src/theme";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import ValidateEmails from "./email-validator";
import styles from "./styles";
import { eApplicationType } from "../../../../../../interfaces/auth";

interface IProps {
  handleToggle: () => void;
  setInvitesLastUpdatedAt: (date: Date) => void;
}

const InviteMembers = ({
  handleToggle,
  setInvitesLastUpdatedAt,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { organisationId, fetchWrapper, isSessionValid } = useAuth();

  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);
  const [emailInviteCount, setEmailInviteCount] = useState<number>(0);
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false);
  const [isValidFormState, setisValidFormState] = useState<boolean>(true);
  const [showSuccessContent, setShowSuccessContent] = useState<boolean>(false);

  const handleChange = (emailInvites: string[]) => {
    setInvitedEmails(emailInvites);
  };

  const handleCancel = () => {
    setInvitedEmails([]);
    setEmailInviteCount(0);
    form.resetFields();
    handleToggle();
  };

  const handleFormSubmit = async () => {
    try {
      const isSession = await isSessionValid();
      if (organisationId && isSession) {
        setShowSuccessContent(true);
        await fetchWrapper(sendOrganisationInvites, organisationId, {
          application: eApplicationType.ProjectsInternal,
          organisationId,
          emails: invitedEmails,
        });

        setIsSubmitPending(false);
        setInvitesLastUpdatedAt(new Date());
        form.resetFields();
      } else {
        setIsSubmitPending(false);
        throw Error("Undefined organisationId");
      }
    } catch (error) {
      log(DataDogLogTypes.ERROR, "Invite members error", error);

      ErrorToast(
        t("inviteMembers##Error inviting members"),
        t(
          "inviteMembers##An error has ocurred when inviting members. Please try again."
        )
      );
    }
  };

  const isMobile = getIsMobile();

  const tagRender = (props: {
    label: any;
    value: any;
    closable: any;
    onClose: any;
  }) => {
    const { label, value, closable, onClose } = props;

    const result = ValidateEmails([value]);

    return (
      <Tag
        color={value}
        closable={closable}
        onClose={onClose}
        css={result.successful ? styles.validTag : styles.errorTag}
        closeIcon={
          <CloseIcon
            fill={
              result.successful
                ? theme.colors.secondary.purple
                : theme.colors.functional.red
            }
            height={9}
            width={9}
            title="inviteMembers##Remove email"
            role="button"
          />
        }
      >
        {label}
      </Tag>
    );
  };

  const getInviteButtonText = (): string => {
    return `${t("inviteMembers##Send invite")}${
      emailInviteCount === 0 ? "" : ` (${emailInviteCount})`
    }`;
  };

  const FIELDS = {
    emailAddresses: ["addEmailAddresses"],
  };

  return (
    <div>
      {!showSuccessContent ? (
        <div data-testid="invite-members">
          <div css={styles.header}>{t("inviteMembers##Invite members")}</div>
          <Form form={form} onFinish={handleFormSubmit} layout="vertical">
            <div css={styles.contentContainer}>
              <FloatingLabel
                label={t("inviteMembers##Add email addresses")}
                value={t("inviteMembers##Add email addresses")}
                name={t("inviteMembers##Add email addresses")}
                css={styles.floatingLabel}
                wrapperStyles={styles.floatingLabelWrapper}
              >
                <Form.Item
                  name={FIELDS.emailAddresses}
                  rules={[
                    {
                      validator: (rule: RuleObject, emails: string[]) => {
                        try {
                          if (emails === undefined || emails.length === 0) {
                            setEmailInviteCount(0);
                            throw new Error(
                              t("inviteMembers##validation##EnterAnEmail")
                            );
                          }

                          if (emails.length > 10) {
                            throw new Error(
                              t("inviteMembers##validation##UpToTenEmails")
                            );
                          }

                          const result = ValidateEmails(emails);
                          setEmailInviteCount(result.validEmailCount);

                          if (!result.successful) {
                            throw new Error(t(result.errorMessageKey));
                          }
                          setisValidFormState(true);
                          return Promise.resolve();
                        } catch (error) {
                          setisValidFormState(false);
                          return Promise.reject(error);
                        }
                      },
                    },
                  ]}
                >
                  <Select
                    value={invitedEmails}
                    mode="tags"
                    css={styles.inviteMembersInput}
                    placeholder=""
                    onChange={handleChange}
                    dropdownStyle={{ display: "none" }}
                    tagRender={tagRender}
                    tokenSeparators={[" ", ","]}
                    onInputKeyDown={(c) => {
                      if (c.code === "Enter") {
                        const emailInput = document.getElementById(
                          FIELDS.emailAddresses[0]
                        );
                        emailInput?.blur();
                        emailInput?.focus();
                        c.preventDefault();
                        c.stopPropagation();
                      }
                    }}
                  >
                    {invitedEmails}
                  </Select>
                </Form.Item>
              </FloatingLabel>
              {isValidFormState && (
                <div css={styles.guidanceText}>
                  {t("inviteMembers##Hit enter to add")}
                </div>
              )}
            </div>
            <div
              css={
                isMobile
                  ? styles.mobileButtonContainer
                  : styles.tabletDesktopButtonContainer
              }
            >
              <SecondaryModalButtonLink
                ariaLabel="Cancel"
                onClick={handleCancel}
                buttonText="Cancel"
                className="inviteMembersCancelButton"
              />
              <Button
                ariaLabel={getInviteButtonText()}
                htmlType="submit"
                css={styles.primaryButton}
                name={getInviteButtonText()}
                loading={isSubmitPending}
                noLabelTranslation
              >
                {getInviteButtonText()}
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <div css={styles.inviteSent}>
          <UploadCompleteIcon
            fill={theme.colors.secondary.cyan}
            height={64}
            width={64}
            title={t("inviteMembers##Remove email")}
            role="img"
          />
          <div css={styles.inviteSentHeader}>
            {t("inviteMembers##Invite sent")}
          </div>
          <Button
            ariaLabel="inviteMembers##Invite another member"
            css={
              isMobile
                ? styles.inviteSentPrimaryButtonMobile
                : styles.inviteSentPrimaryButtonTabletDesktop
            }
            onClick={() => {
              setInvitedEmails([]);
              setEmailInviteCount(0);
              form.resetFields();
              setShowSuccessContent(false);
            }}
          >
            inviteMembers##Invite another member
          </Button>
          {!isMobile && (
            <Button
              type="secondary"
              ariaLabel="Close"
              css={styles.inviteSentSecondaryButton}
              onClick={handleCancel}
            >
              Close
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default InviteMembers;
