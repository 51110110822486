import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { SortIcon } from "src/app/assets/icons/component-icons";
import ControlButton from "../base-button";

interface IProps {
  showLabel?: boolean;
  showTooltip?: boolean;
  className?: string;
  onClick?: () => void;
  tooltipColor?: string;
}

const MobileSort = ({
  showLabel = false,
  showTooltip = false,
  className = "",
  onClick,
  tooltipColor,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <ControlButton
      className={className}
      showLabel={showLabel}
      showTooltip={showTooltip}
      label={t("Sort")}
      data-testid="mobile-sort-button"
      tooltipColor={tooltipColor}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <SortIcon />
    </ControlButton>
  );
};

export default MobileSort;
