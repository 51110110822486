import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ICognitoError } from "src/auth/interfaces";
import {
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
} from "src/app/assets/icons/component-icons";
import styles from "../../styles";

export const PasswordResetMsg = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="custom-error" css={styles.customReset}>
      <div style={{ marginTop: "2px" }}>
        <SuccessIcon css={styles.customInfoIcon} />
      </div>
      <div>
        <span css={styles.customResetMsg}>
          {t("Your password has been reset")}
        </span>
      </div>
    </div>
  );
};

interface IErrorProps {
  error: ICognitoError;
}

export const ErrorMsg = ({ error }: IErrorProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="custom-error" css={styles.customError}>
      <div style={{ marginTop: "2px" }}>
        <ErrorIcon css={styles.customErrorIcon} />
      </div>
      <div>
        <span css={styles.customErrorMsg}>{t(error.error_description)}</span>
      </div>
    </div>
  );
};

export const MigrationMsg = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="custom-error" css={styles.customInfo}>
      <div style={{ marginTop: "2px" }}>
        <InfoIcon css={styles.customInfoIcon} />
      </div>
      <div>
        <span css={styles.customResetMsg}>
          {t(
            "We have made security improvements which require you to reset your password."
          )}
        </span>
      </div>
    </div>
  );
};
