import { UploadFile } from "antd/lib/upload/interface";
import { saveAs } from "file-saver";
import { TFunction } from "i18next";
import downloadFile from "src/api/attachments/download-file";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { IProjectUploadFile } from "src/pages/projects/project/components/details/components/interfaces";
import deleteContactFile from "src/api/talent-hub/delete-file";
import getDownloadFileUrl from "src/api/talent-hub/get-download-file-url";
import verifyFileUpload from "src/api/talent-hub/verify-file-upload";
import { IFileUploadStatus } from "../interfaces";

export const handleDeleteFile = async (
  setFileList: (files: UploadFile[]) => void,
  filesList: UploadFile[],
  setIsModalOpen: (show: boolean) => void,
  t: TFunction,
  index?: number,
  accessToken?: string,
  contactId?: string,
  orgId?: string,
  file?: IProjectUploadFile,
  showToast = true
): Promise<void> => {
  const updatedItems: Array<IProjectUploadFile> = [];

  try {
    if (file && accessToken && contactId && orgId) {
      await deleteContactFile(file.uid, contactId, accessToken, orgId);

      setIsModalOpen(false);

      if (showToast) {
        SuccessToast(t("File Deleted"));
      }

      if (file.request) {
        file.request.abort();
      }
    }
    if (index !== null && index !== undefined) {
      Object.assign(updatedItems, filesList);
      updatedItems.splice(index, 1);
      setFileList(updatedItems);
    }
  } catch {
    if (showToast) {
      ErrorToast(
        `FileUploadErrorToast${Math.random()}`,
        t("There was a problem deleting the file")
      );
    }
  }
};

export const handleVerifyFileUpload = async (
  t: TFunction,
  accessToken?: string,
  contactId?: string,
  orgId?: string,
  file?: IProjectUploadFile,
  showToast = true
): Promise<boolean> => {
  try {
    if (file && accessToken && contactId && orgId) {
      await verifyFileUpload({
        attachmentId: file.uid,
        contactId,
        organisationId: orgId,
        accessToken,
      });

      return true;
    }
  } catch {
    if (showToast) {
      ErrorToast(
        `VerifyFileUploadErrorToast${Math.random()}`,
        t("There was a problem verifying the file upload")
      );
    }
  }

  return false;
};

export const handleDeleteClick = (
  setIsModalOpen: (show: boolean) => void
): void => {
  setIsModalOpen(true);
};

export const handleFileDownload = async (
  accessToken: string,
  file: UploadFile,
  contactId: string,
  organisationId: string,
  isMenuItemDownload = true
): Promise<boolean> => {
  try {
    const { url } = await getDownloadFileUrl(
      file.uid,
      contactId,
      accessToken,
      organisationId
    );
    if (isMenuItemDownload) {
      const fileToSave: Blob = await downloadFile(url, file.type);
      saveAs(fileToSave, file.name);
    } else {
      window.open(url, "_blank");
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const checkFilesCurrentlyUploading = (
  fileList: UploadFile[]
): boolean => {
  const uploadingFiles = fileList.filter(
    (file: UploadFile) => file.status === IFileUploadStatus.uploading
  );

  if (uploadingFiles.length === 0) {
    return false;
  }
  return true;
};
