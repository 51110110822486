import { SerializedStyles, css } from "@emotion/react";

export default {
  loadingContainer: (
    position: "absolute" | "relative",
    pageCentered: boolean,
    darkMode: boolean
  ): SerializedStyles => css`
    position: ${position};
    top: ${pageCentered ? "50%" : 0};
    left: 50%;
    transform: translate(-50%, ${pageCentered ? "-50%" : 0});
    div {
      margin: 0 auto;
    }
    svg path {
      fill: ${darkMode ? "revert" : "revert-layer"};
    }
  `,
};
