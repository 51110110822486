import { Button, Drawer, Form, Switch, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import getCountryPhoneCodeOptions from "src/pages/contact/utils";
import {
  Confirm,
  IContactCreateRequest,
  IPhoneNumber,
  ITalentData,
  Signed,
} from "src/pages/contact/interfaces";
import useAuth from "src/auth/use-auth";
import createContact from "src/api/talent-hub/create-contact";
import { AxiosError } from "axios";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import ContactInput from "src/pages/contact/components/contact-input";
import contactValidation from "src/utils/contactValidation";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import ContactSelect from "src/pages/contact/components/contact-select";
import PhoneNumberWithCountryCode from "src/pages/contact/components/phone-number-with-country-code";
import CurrencyAndPrice from "src/components/currency-and-price";
import { Currencies } from "src/constants";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import { formatCurrency } from "src/utils/currency";
import { areChangesMadeInContactRequiredProperties } from "src/utils/contact-changes-check";
import useConfirm from "src/providers/confirm-context-provider/hooks";
import isValidPriceValue from "src/utils/validate-price";
import styles from "./styles";
import { IOption } from "../account-information/types";
import { IProjectTalents, TalentType } from "../../interfaces";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onContactCreated: (talentData: IProjectTalents) => void;
}

const DEFAULT_TALENT_DATA = {
  name: "",
  email: "",
  phone: { countryCode: "", number: "" },
  baseLocation: "",
  mainLanguage: "",
  signedCMA: null,
  signedNDA: null,
  minimumDemoFee: null,
  connectedToOrgId: "",
  collaborationStatus: null,
  skillSet: [],
  principalInstruments: [],
  keyGenres: [],
  vocalist: null,
  minimumDemoTurnaroundHours: null,
};

const AddTalent: React.FC<IProps> = ({
  isOpen,
  onContactCreated,
  onClose,
}: IProps): React.ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { fetchWrapper, isSessionValid, organisationId } = useAuth();
  const { talentHubTypes } = useGlobalStates();
  const [errorMessage, setErrorMessage] = useState("");
  const [clearError, setClearErrorMessage] = useState("");
  const { isConfirmed } = useConfirm();

  const [talentData, setTalentData] = useState<ITalentData>({
    ...DEFAULT_TALENT_DATA,
    connectedToOrgId: organisationId,
  });

  const countryCodeOptions = useMemo(() => getCountryPhoneCodeOptions(), []);

  const signedOptions = useMemo(() => {
    return Object.entries(Signed).map(([value, label]) => ({
      label,
      value,
    }));
  }, []);

  const confirmOptions = useMemo(() => {
    return Object.entries(Confirm).map(([value, label]) => ({
      label,
      value,
    }));
  }, []);

  const minimumDemoTurnaroundHoursOptions = useMemo(() => {
    return ["6", "12", "24", "36", "48"].map((value) => ({
      label: value,
      value,
    }));
  }, []);

  const findSingleSelectOption = useCallback(
    (options: IOption[], value: string) => {
      return options.find((option) => option.value === value);
    },
    []
  );

  const filterMultipleSelectOptions = useCallback(
    (options: IOption[], values: string[]) => {
      return options.filter((option) => values.includes(option.value));
    },
    []
  );

  const minimumDemoFeeCurrency = useMemo(() => {
    return talentData?.minimumDemoFee?.currency ?? Currencies.USD.code;
  }, [talentData?.minimumDemoFee?.currency]);

  const minimumDemoFeePrice = useMemo(() => {
    return talentData?.minimumDemoFee?.value
      ? formatCurrency(talentData.minimumDemoFee.value)
      : "";
  }, [talentData?.minimumDemoFee?.value]);

  const currencyOptions: IOption[] = useMemo(
    () =>
      Object.values(Currencies).map((value) => {
        return {
          value: value.code,
          label: value.displayText,
        };
      }),
    []
  );

  const getCurrency = useCallback(
    (currentCurrency: string) =>
      currencyOptions.find((option) => option.value === currentCurrency),
    [currencyOptions]
  );

  const clearTalentData = useCallback(() => {
    setTalentData({ ...DEFAULT_TALENT_DATA, connectedToOrgId: organisationId });
    setErrorMessage("");
    setClearErrorMessage("");
    form.setFieldsValue({
      name: DEFAULT_TALENT_DATA.name,
      email: DEFAULT_TALENT_DATA.email,
      phoneCountryCode: DEFAULT_TALENT_DATA.phone?.countryCode,
      phoneNumber: DEFAULT_TALENT_DATA.phone?.number,
      baseLocation: DEFAULT_TALENT_DATA.baseLocation,
      mainLanguage: DEFAULT_TALENT_DATA.mainLanguage,
      signedCMA: DEFAULT_TALENT_DATA?.signedCMA,
      signedNDA: DEFAULT_TALENT_DATA?.signedNDA,
      minimumDemoFeeCurrency: Currencies.USD.code,
      minimumDemoFeePrice: undefined,
      collaborationStatus: DEFAULT_TALENT_DATA.collaborationStatus,
      skillSet: DEFAULT_TALENT_DATA.skillSet,
      principalInstruments: DEFAULT_TALENT_DATA.principalInstruments,
      keyGenres: DEFAULT_TALENT_DATA.keyGenres,
      vocalist: DEFAULT_TALENT_DATA.vocalist,
      minimumDemoTurnaroundHours:
        DEFAULT_TALENT_DATA.minimumDemoTurnaroundHours,
    });
  }, [form, organisationId]);

  const handleClose = useCallback(() => {
    const areChangesMade = areChangesMadeInContactRequiredProperties(
      { ...DEFAULT_TALENT_DATA, connectedToOrgId: organisationId },
      talentData
    );
    if (areChangesMade) {
      void isConfirmed(
        "If you exit now, your contact information won't be saved, and any entered details will be lost. Are you certain you want to exit?",
        "Exit"
      ).then((confirmed) => {
        if (confirmed) {
          clearTalentData();
          onClose();
        }
        return true;
      });
      return false;
    }

    onClose();
    return true;
  }, [clearTalentData, isConfirmed, onClose, organisationId, talentData]);

  const handleUpdateTalentData = useCallback(
    (changes: ITalentData) => {
      setTalentData({ ...talentData, ...changes });
    },
    [talentData]
  );

  const handleFeeChange = useCallback(
    (field: string, updatedFee: IMonetaryValue) => {
      handleUpdateTalentData({
        [field]: updatedFee,
      });
    },
    [handleUpdateTalentData]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleUpdateTalentData({ [event.target.name]: event.target.value });
    },
    [handleUpdateTalentData]
  );

  const onSingleSelectChange = useCallback(
    (property: string, option: IOption) => {
      handleUpdateTalentData({
        [property]: option.value,
      });
    },
    [handleUpdateTalentData]
  );

  const onSingleNumberSelectChange = useCallback(
    (property: string, option: IOption) => {
      handleUpdateTalentData({
        [property]: parseInt(option.value, 10),
      });
    },
    [handleUpdateTalentData]
  );

  const onMultipleSelectChange = useCallback(
    (property: string, options: IOption[]) => {
      handleUpdateTalentData({
        [property]: options.map((option) => option.value),
      });
    },
    [handleUpdateTalentData]
  );

  const getContactDataRequest = useCallback(() => {
    let vocalist = null;
    if (talentData.vocalist === "Yes") {
      vocalist = true;
    } else if (talentData.vocalist === "No") {
      vocalist = false;
    }

    return {
      ...talentData,
      vocalist,
      organisationId,
    };
  }, [organisationId, talentData]);

  const clearErrorMessage = useCallback(
    (currentErrorMessage: string) => {
      setTimeout(() => {
        if (errorMessage === currentErrorMessage) {
          setErrorMessage("");
        }
      }, 2000);
    },
    [errorMessage]
  );

  useEffect(() => {
    if (clearError && errorMessage) {
      clearErrorMessage(clearError);
      setClearErrorMessage("");
    }
  }, [clearError, clearErrorMessage, errorMessage]);

  const saveContactData = useCallback(
    async (request: ReturnType<typeof getContactDataRequest>) => {
      setIsSaving(true);

      try {
        const { id } = await fetchWrapper(
          createContact,
          request as IContactCreateRequest,
          organisationId
        );
        onContactCreated({
          id,
          name: talentData?.name ?? "",
          email: talentData?.email ?? "",
          type: TalentType.TalentHub,
        });
        onClose();
      } catch (reason) {
        const error = reason as AxiosError;
        if (error.response?.status === 409) {
          log(
            DataDogLogTypes.ERROR,
            "Error saving contact: Contact with given email already exists",
            error
          );
          setErrorMessage(
            t("ContactPage##Errors##SaveError##messageContactWithSameEmail")
          );
          setClearErrorMessage(
            t("ContactPage##Errors##SaveError##messageContactWithSameEmail")
          );
        } else {
          log(DataDogLogTypes.ERROR, "Error saving contact", error);
          setErrorMessage(t("ContactPage##Errors##SaveError##message"));
          setClearErrorMessage(t("ContactPage##Errors##SaveError##message"));
        }
      } finally {
        setIsSaving(false);
      }
    },
    [
      fetchWrapper,
      onClose,
      onContactCreated,
      organisationId,
      t,
      talentData?.email,
      talentData?.name,
    ]
  );

  const isContactRequestValid = useCallback(
    async (
      request: ReturnType<typeof getContactDataRequest>,
      showValidationError = true
    ) => {
      let isRequestDataValid = false;
      try {
        await contactValidation.contactSchema.validate(request);
        isRequestDataValid = true;
      } catch (error) {
        if (showValidationError) {
          void form.validateFields([
            "name",
            "email",
            "phoneCountryCode",
            "phoneNumber",
            "baseLocation",
            "mainLanguage",
            "signedCMA",
            "signedNDA",
            "minimumDemoFeeCurrency",
            "minimumDemoFeePrice",
            "collaborationStatus",
            "skillSet",
            "principalInstruments",
            "keyGenres",
            "vocalist",
            "minimumDemoTurnaroundHours",
          ]);
          setErrorMessage(t("ContactPage##Errors##ValidationError##message"));
        }
      }

      return isRequestDataValid;
    },
    [form, t]
  );

  const handleCreateContact = useCallback(async () => {
    const isSession = await isSessionValid();
    if (!isSession) {
      log(DataDogLogTypes.ERROR, "Error saving contact", "error");
      setErrorMessage(t("ContactPage##Errors##SaveError##message"));
      setClearErrorMessage(t("ContactPage##Errors##SaveError##message"));
      return;
    }
    const request = getContactDataRequest();
    const isRequestDataValid = await isContactRequestValid(request);
    if (!isRequestDataValid) {
      return;
    }
    await saveContactData(request);
  }, [
    getContactDataRequest,
    isContactRequestValid,
    isSessionValid,
    saveContactData,
    t,
  ]);

  useEffect(() => {
    form?.setFieldsValue({
      minimumDemoFeePrice: talentData?.minimumDemoFee?.value
        ? formatCurrency(talentData?.minimumDemoFee?.value)
        : "",
    });
  }, [form, talentData?.minimumDemoFee?.value]);

  useEffect(() => {
    const updateErrorMessage = async () => {
      const request = getContactDataRequest();
      const isRequestDataValid = await isContactRequestValid(request, false);
      if (isRequestDataValid) {
        setErrorMessage("");
      }
    };
    void updateErrorMessage();
  }, [getContactDataRequest, isContactRequestValid]);

  return (
    <Drawer
      title={
        <>
          {t("ProjectsPage##TalentPanel##CreateAContact")}
          {isSaving && (
            <Typography.Text
              css={styles.saving}
              data-testid="changes-saving-text"
            >
              {t("ProjectsPage##Saving")}
            </Typography.Text>
          )}
        </>
      }
      placement="right"
      closable
      onClose={handleClose}
      open={isOpen}
      zIndex={1053}
      css={styles.drawerContainer}
      width={512}
      destroyOnClose
      closeIcon={
        <CloseIcon
          fill={theme.colors.black}
          height={13}
          width={14}
          title="Close"
          role="button"
        />
      }
    >
      <Form
        form={form}
        initialValues={{
          name: talentData.name,
          email: talentData.email,
          phoneCountryCode:
            talentData.phone?.countryCode &&
            findSingleSelectOption(
              countryCodeOptions,
              talentData.phone.countryCode
            ),
          phoneNumber: talentData.phone?.number,
          baseLocation:
            talentData.baseLocation &&
            findSingleSelectOption(
              talentHubTypes.countryCodes,
              talentData.baseLocation
            ),
          mainLanguage:
            talentData.mainLanguage &&
            findSingleSelectOption(
              talentHubTypes.languageCodes,
              talentData.mainLanguage
            ),
          billingName: talentData.billingName,
          billingAddress: talentData.billingAddress,
          signedCMA:
            talentData?.signedCMA &&
            findSingleSelectOption(signedOptions, talentData.signedCMA),
          signedNDA:
            talentData?.signedNDA &&
            findSingleSelectOption(signedOptions, talentData.signedNDA),
          minimumDemoFeeCurrency: getCurrency(minimumDemoFeeCurrency),
          minimumDemoFeePrice:
            minimumDemoFeePrice && isValidPriceValue(minimumDemoFeePrice)
              ? formatCurrency(minimumDemoFeePrice)
              : undefined,
          collaborationStatus:
            talentData.collaborationStatus &&
            findSingleSelectOption(
              talentHubTypes.talentCollaborations,
              talentData.collaborationStatus
            ),
          skillSet:
            talentData.skillSet &&
            filterMultipleSelectOptions(
              talentHubTypes.skillSets,
              talentData.skillSet
            ),
          principalInstruments:
            talentData.principalInstruments &&
            filterMultipleSelectOptions(
              talentHubTypes.talentInstruments,
              talentData.principalInstruments
            ),
          keyGenres:
            talentData.keyGenres &&
            filterMultipleSelectOptions(
              talentHubTypes.musicGenres,
              talentData.keyGenres
            ),
          vocalist:
            talentData.vocalist &&
            findSingleSelectOption(confirmOptions, talentData.vocalist),
          minimumDemoTurnaroundHours:
            talentData.minimumDemoTurnaroundHours &&
            findSingleSelectOption(
              minimumDemoTurnaroundHoursOptions,
              talentData.minimumDemoTurnaroundHours.toString()
            ),
        }}
        validateTrigger="onChange"
        scrollToFirstError
        css={styles.formStyle}
      >
        <div id="InfoText">
          <Typography.Text>
            {t("ProjectsPage##TalentPanel##InfoText")}
          </Typography.Text>
        </div>
        <div id="PersonalDetails">
          <Typography.Text>
            {t("ContactPage##Personal Details")}
          </Typography.Text>
          <ContactInput
            title={t("ContactPage##Full Name")}
            name="name"
            rules={contactValidation.requiredFieldValidator()}
            onChange={onInputChange}
          />
          <ContactInput
            title={t("ContactPage##Email")}
            name="email"
            rules={[
              ...contactValidation.emailValidator(),
              ...contactValidation.requiredFieldValidator(),
            ]}
            onChange={onInputChange}
          />
          <PhoneNumberWithCountryCode
            title={t("ContactPage##Phone Number")}
            countryCode={talentData.phone?.countryCode ?? ""}
            phoneNumber={talentData.phone?.number ?? ""}
            countryCodeFormItemName="phoneCountryCode"
            phoneNumberFormItemName="phoneNumber"
            propertyName="phone"
            onChange={(field: string, value: IPhoneNumber) =>
              handleUpdateTalentData({ [field]: value })
            }
          />
          <ContactSelect
            title={t("ContactPage##Base Location")}
            name="baseLocation"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.countryCodes}
            id="base-location-dropdown"
            onChange={(baseLocation: IOption) =>
              onSingleSelectChange("baseLocation", baseLocation)
            }
          />
          <ContactSelect
            title={t("ContactPage##Native Language")}
            name="mainLanguage"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.languageCodes}
            id="main-language-dropdown"
            onChange={(mainLanguage: IOption) => {
              onSingleSelectChange("mainLanguage", mainLanguage);
            }}
          />
        </div>
        <div id="PublishingInformation">
          <Typography.Text>
            {t("ContactPage##Publishing Information")}
          </Typography.Text>
          <ContactSelect
            title={t("ContactPage##CMA Signed?")}
            name="signedCMA"
            rules={contactValidation.requiredFieldValidator()}
            options={signedOptions}
            id="cma-signed-dropdown"
            onChange={(signedCMA: IOption) => {
              onSingleSelectChange("signedCMA", signedCMA);
            }}
          />
          <ContactSelect
            title={t("ContactPage##NDA Signed?")}
            name="signedNDA"
            rules={contactValidation.requiredFieldValidator()}
            options={signedOptions}
            id="nda-signed-dropdown"
            onChange={(signedNDA: IOption) => {
              onSingleSelectChange("signedNDA", signedNDA);
            }}
          />
          <CurrencyAndPrice
            currency={minimumDemoFeeCurrency}
            price={minimumDemoFeePrice}
            currencyFormItemName="minimumDemoFeeCurrency"
            priceFormItemName="minimumDemoFeePrice"
            onChange={handleFeeChange}
            label={t("ContactPage##Minimum Demo Fee")}
            propertyName="minimumDemoFee"
            validateRequiredFields
            testId="minimumDemoFeePrice"
          />
          <div css={styles.switchContainer}>
            <div css={styles.basicInputContainer}>
              <Typography.Text
                css={[
                  styles.basicInputLabel,
                  styles.basicLabelAdditionalStyles,
                ]}
              >
                {t("ContactPage##Share contact with all of Songtradr")}
              </Typography.Text>
            </div>
            <Form.Item css={[styles.formItem, styles.additionalFormItemStyle]}>
              <Switch
                onChange={(toggle: boolean) => {
                  handleUpdateTalentData({
                    connectedToOrgId: toggle ? null : organisationId,
                  });
                }}
                checked={talentData?.connectedToOrgId === null}
              />
            </Form.Item>
          </div>
          <div />
        </div>
        <div id="AAndR">
          <Typography.Text>{t("ContactPage##A&R")}</Typography.Text>
          <ContactSelect
            title={t("ContactPage##Rating")}
            name="collaborationStatus"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.talentCollaborations}
            id="rating-dropdown"
            onChange={(collaborationStatus: IOption) => {
              onSingleSelectChange("collaborationStatus", collaborationStatus);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Skill Set")}
            name="skillSet"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.skillSets}
            id="skill-set-dropdown"
            isMulti
            onChange={(skillSet: IOption[]) => {
              onMultipleSelectChange("skillSet", skillSet);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Principal Instruments")}
            name="principalInstruments"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.talentInstruments}
            id="principal-instruments-dropdown"
            isMulti
            onChange={(principalInstruments: IOption[]) => {
              onMultipleSelectChange(
                "principalInstruments",
                principalInstruments
              );
            }}
          />
          <ContactSelect
            title={t("ContactPage##Key Genres")}
            name="keyGenres"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.musicGenres}
            id="key-genres-dropdown"
            isMulti
            onChange={(keyGenres: IOption[]) => {
              onMultipleSelectChange("keyGenres", keyGenres);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Vocalist")}
            name="vocalist"
            rules={contactValidation.requiredFieldValidator()}
            options={confirmOptions}
            id="vocalist-dropdown"
            onChange={(vocalist: IOption) => {
              onSingleSelectChange("vocalist", vocalist);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Minimum Demo Turnaround")}
            name="minimumDemoTurnaroundHours"
            rules={contactValidation.requiredFieldValidator()}
            options={minimumDemoTurnaroundHoursOptions}
            id="minimum-demo-turnaround-dropdown"
            menuPlacement="top"
            onChange={(minimumDemoTurnaroundHours: IOption) => {
              onSingleNumberSelectChange(
                "minimumDemoTurnaroundHours",
                minimumDemoTurnaroundHours
              );
            }}
          />
        </div>
      </Form>
      <div
        css={[styles.createGroupFooter, isSaving && styles.disabledContainer]}
      >
        <div>
          <div css={styles.errorMessage}>
            {errorMessage && "*"}
            {errorMessage}
          </div>
          <Button
            css={styles.closeButton}
            tabIndex={0}
            aria-label="close"
            onClick={handleClose}
            type="text"
            disabled={isSaving}
          >
            {t("Close")}
          </Button>
          <Button
            css={styles.createButton}
            tabIndex={0}
            aria-label="ok"
            onClick={handleCreateContact}
            type="primary"
            disabled={isSaving}
          >
            {t("Create contact")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default AddTalent;
