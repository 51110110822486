import { isEqual } from "lodash";
import {
  IAAndRData,
  IContactForm,
  INotesAndDocumentsData,
  IPersonalDetailsData,
  IPublishingInformationData,
  ITalentData,
} from "src/pages/contact/interfaces";

const areChangesMadeInContact = (
  contact: IContactForm,
  personalDetails: IPersonalDetailsData,
  publishingInformation: IPublishingInformationData,
  aAndR: IAAndRData,
  notesAndDocuments: INotesAndDocumentsData
): boolean => {
  return (
    contact.name !== personalDetails.name ||
    contact.pronoun !== personalDetails.pronoun ||
    (contact.agencyWritingPartnership ?? "") !==
      (personalDetails.agencyWritingPartnership ?? "") ||
    contact.email !== personalDetails.email ||
    !isEqual(contact.phone, personalDetails.phone) ||
    contact.baseLocation !== personalDetails.baseLocation ||
    !isEqual(contact.socialMediaWebsite, personalDetails.socialMediaWebsite) ||
    !isEqual(contact.showreelUrls, personalDetails.showreelUrls) ||
    contact.mainLanguage !== personalDetails.mainLanguage ||
    !isEqual(contact.otherLanguages, personalDetails.otherLanguages) ||
    contact.billingName !== personalDetails.billingName ||
    contact.billingAddress !== personalDetails.billingAddress ||
    contact.physicalConditionsIllness !==
      personalDetails.physicalConditionsIllness ||
    contact.neurodiverse !== personalDetails.neurodiverse ||
    contact.neurodiverseNote !== personalDetails.neurodiverseNote ||
    contact.racialCharacteristics !== personalDetails.racialCharacteristics ||
    contact.genderIdentity !== personalDetails.genderIdentity ||
    contact.sexualOrientation !== personalDetails.sexualOrientation ||
    contact.signedCMA !== publishingInformation.signedCMA ||
    contact.signedNDA !== publishingInformation.signedNDA ||
    !isEqual(contact.minimumDemoFee, publishingInformation.minimumDemoFee) ||
    contact.performingRightsOrganisationNumber !==
      publishingInformation.performingRightsOrganisationNumber ||
    contact.connectedToOrgId !== publishingInformation.connectedToOrgId ||
    contact.serviceProvider !== publishingInformation.serviceProvider ||
    contact.collaborationStatus !== aAndR.collaborationStatus ||
    !isEqual(contact.skillSet, aAndR.skillSet) ||
    !isEqual(contact.principalInstruments, aAndR.principalInstruments) ||
    !isEqual(contact.additionalInstruments, aAndR.additionalInstruments) ||
    !isEqual(contact.keyGenres, aAndR.keyGenres) ||
    !isEqual(contact.additionalGenres, aAndR.additionalGenres) ||
    contact.vocalist !== aAndR.vocalist ||
    contact.minimumDemoTurnaroundHours !== aAndR.minimumDemoTurnaroundHours ||
    !isEqual(contact.previousWork, aAndR.previousWork) ||
    !isEqual(contact.interestedProjects, aAndR.interestedProjects) ||
    !isEqual(contact.talentNotes, notesAndDocuments.talentNotes) ||
    !isEqual(contact.awardNotes, notesAndDocuments.awardNotes) ||
    !isEqual(contact.internalNotes, notesAndDocuments.internalNotes)
  );
};

export const areChangesMadeInContactRequiredProperties = (
  contact: ITalentData,
  talentData: ITalentData
): boolean => {
  return (
    contact.name !== talentData.name ||
    contact.email !== talentData.email ||
    !isEqual(contact.phone, talentData.phone) ||
    contact.baseLocation !== talentData.baseLocation ||
    contact.mainLanguage !== talentData.mainLanguage ||
    contact.billingName !== talentData.billingName ||
    contact.billingAddress !== talentData.billingAddress ||
    contact.signedCMA !== talentData.signedCMA ||
    contact.signedNDA !== talentData.signedNDA ||
    !isEqual(contact.minimumDemoFee, talentData.minimumDemoFee) ||
    contact.connectedToOrgId !== talentData.connectedToOrgId ||
    contact.collaborationStatus !== talentData.collaborationStatus ||
    !isEqual(contact.skillSet, talentData.skillSet) ||
    !isEqual(contact.principalInstruments, talentData.principalInstruments) ||
    !isEqual(contact.keyGenres, talentData.keyGenres) ||
    contact.vocalist !== talentData.vocalist ||
    contact.minimumDemoTurnaroundHours !== talentData.minimumDemoTurnaroundHours
  );
};

export default areChangesMadeInContact;
