import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  pageContainer: css`
    width: 100%;
  `,
  projectContainer: css`
    @media ${queries.large} {
      margin-top: 5.125rem;
    }
  `,
  stickyHeaderContainer: css`
    display: block;
    z-index: 1300;
    width: 100%;
    height: 51px;
    position: fixed;
    background-color: ${theme.colors.white};
    box-shadow: 0.063rem -0.063rem 0.313rem 0 ${theme.colors.background.darkGray};

    @media ${queries.medium} {
      display: none;
    }

    @media ${queries.large} {
      height: 82px;
      display: block;
    }
  `,
  previewModeContainer: css`
    font-size: 1.25rem;
    color: ${theme.colors.secondary.darkBlue};
    font-weight: 600;

    @media ${queries.large} {
      font-size: 1.5rem;
    }
  `,
  stickyTextContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51px;

    @media ${queries.large} {
      height: 82px;
    }
  `,
  stickyButtonsContainer: css`
    display: none;

    @media ${queries.large} {
      height: 82px;
      position: relative;
      top: -5.125rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 185px;

        svg {
          margin-left: 0.438rem;
        }
      }
    }
  `,
  button: css`
    margin-left: 1.25rem;
  `,
  closeButton: css`
    height: 50px;
    width: 100px !important;
    background-color: transparent;
    border: 0;
    font-size: 1rem;
    color: ${theme.colors.primary.blue};
    font-weight: 700;
    margin-right: 1.875rem;
    margin-left: 1.875rem;
    cursor: pointer;

    svg {
      margin-left: 0.938rem !important;
    }
  `,
};
