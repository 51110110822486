import React, { ReactElement, useEffect, useCallback, useRef } from "react";
import { useWindowSize } from "@songtradr/spa-common";
import { useTranslation } from "react-i18next/";
import STLoadingLogo from "src/components/st-loading-logo";
import useProject from "src/providers/project/hooks";
import { handleFileDownload } from "src/components/file-upload/helpers";
import { SuccessToast, ErrorToast } from "src/components/toast-notification";
import useAuth from "src/auth/use-auth";
import { useHistory, useParams } from "react-router-dom";
import { IProjectUploadFile } from "../projects/project/components/details/components/interfaces";
import { IProjectRouteParams } from "../projects/project/interfaces";

interface IProjectRouteWithAttachmentParams extends IProjectRouteParams {
  attachmentId: string;
}

const AttachmentDownload = (): ReactElement => {
  const { isSessionValid, organisationId, fetchWrapper } = useAuth();
  const { id, attachmentId } = useParams<IProjectRouteWithAttachmentParams>();
  const history = useHistory();
  const { t } = useTranslation();
  const hasFileBeenDownloaded = useRef(false);
  const { project, isLoading } = useProject();
  useWindowSize();

  const showDownloadErrorMessage = useCallback(
    () =>
      ErrorToast(
        `FileUploadErrorToast`,
        t("There was a problem downloading the file")
      ),
    [t]
  );

  const downloadFile = useCallback(
    async (file: IProjectUploadFile, projectId: string) => {
      const isSession = await isSessionValid();
      if (!isSession) return;
      SuccessToast(t("File downloading to your file storage"));

      const downloadFileRequest = await fetchWrapper(
        handleFileDownload,
        file,
        projectId,
        organisationId
      );
      if (!downloadFileRequest) {
        showDownloadErrorMessage();
      }
    },
    [fetchWrapper, isSessionValid, organisationId, showDownloadErrorMessage, t]
  );
  useEffect(() => {
    const prepareFileForDownload = async () => {
      if ((isLoading && !attachmentId) || hasFileBeenDownloaded.current) return;
      try {
        const file = project.attachments.find(
          (wholeFile) => wholeFile.uid === attachmentId
        );
        if (!file) {
          return;
        }

        hasFileBeenDownloaded.current = true;
        await downloadFile(file, id);
        history.push(`/project/${id}`);
      } catch {
        showDownloadErrorMessage();
      }
    };

    void prepareFileForDownload();
  }, [
    attachmentId,
    downloadFile,
    history,
    id,
    isLoading,
    project.attachments,
    showDownloadErrorMessage,
  ]);
  return <STLoadingLogo pageCentered />;
};

export default AttachmentDownload;
