/*
  We need to set correct domain for cognito cookie storage to work correctly.
*/
const generateDevEnv = () => {
  if (window.location.hostname === "host.docker.internal") {
    return "host.docker.internal";
  }

  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "localhost";
  }

  return "vinyl.dev.songtradr.dev";
};

export enum Organisations {
  BigSyncMusicId = "62726e0b9957a241f422c5a3",
  InternalTestOrganisationDevId = "6225e4b38257d45b5bc06068",
  InternalTestOrganisationStagingId = "622b38b209673127f4d48de2",
}

const isCognitoSecure = () =>
  window.location.hostname !== "host.docker.internal";

const config = {
  production: {
    production: true,
    auth0: {
      domain: "identity.songtradr.com",
      clientId: "4jxDJUgAoQ2OLxLjes3kcZI37klj5S6v",
      audience: "songtradr-client-api",
      appName: "vinyl-spa",
      dbConnection: "vinyl-app-connection",
    },
    organizationsService: {
      url: "https://api.songtradr.net/org/organisations",
    },
    projectManagementService: {
      url: "https://api.songtradr.net/project-management",
    },
    userMetaDataService: {
      url: "https://api.songtradr.net/user-metadata",
    },
    cognito: {
      userPoolId: "us-east-1_ohzpKwsbF",
      clientId: "6vt3mig5200iapnr3uume9qrmg",
      domain: "vinyl.prod.songtradr.dev",
      isSecure: isCognitoSecure(),
    },
    webHookUrl:
      "https://webhook-events.api.prod1.songtradr.net/webhooks/events",
    externalResources: {
      // TODO: Placeholder URLs - these need replaced
      termsOfService: "https://www.songtradr.com/legals/termsofuse",
      registrationLearnMore:
        "https://support.songtradr.com/hc/en-us/articles/115005436448-How-do-I-sign-up-",
    },
    dataDogToken: "pub4b37dc80da1a08d288af1ce3daef9fdb",
    // TODO: This may need to be changed depending on final prod url e.g. "/projects" if base url is "www.vinyl.com/projects"
    basePathname: "/",
    heapId: "874137444",
    hubspotBaseUrl: "https://app.hubspot.com/contacts/1803008/deal/",
    regionRequiredFieldForOrganisations: [Organisations.BigSyncMusicId],
    contactNameAndEmailRequiredForOrganisations: [Organisations.BigSyncMusicId],
  },
  staging: {
    staging: true,
    auth0: {
      domain: "identity.staging-2.songtradr.dev",
      clientId: "LdeklvAVP7FAy9GgJJ0uD5cI6uKuH4ty",
      audience: "songtradr-client-api",
      appName: "vinyl-spa",
      dbConnection: "vinyl-app-connection",
    },
    cognito: {
      userPoolId: "us-east-1_WFhdIb0np",
      clientId: "7s9c0t7ps977nvo6b2cv220o8v",
      domain: "vinyl.staging-2.songtradr.dev",
      isSecure: isCognitoSecure(),
    },
    recaptcha: {
      // TODO: temporarily using development key
      key: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    },
    organizationsService: {
      url: "https://api.staging-2.songtradr.dev/org/organisations",
    },
    projectManagementService: {
      url: "https://api.staging-2.songtradr.dev/project-management",
    },
    userMetaDataService: {
      url: "https://api.staging-2.songtradr.dev/user-metadata",
    },
    webHookUrl:
      "https://webhook-events.api.staging-2.songtradr.dev/webhooks/events",
    externalResources: {
      // TODO: Placeholder URLs - these need replaced
      termsOfService: "https://www.songtradr.com/legals/termsofuse",
      registrationLearnMore:
        "https://support.songtradr.com/hc/en-us/articles/115005436448-How-do-I-sign-up-",
    },
    dataDogToken: "pub4b37dc80da1a08d288af1ce3daef9fdb",
    basePathname: "/",
    heapId: "259303604",
    hubspotBaseUrl: "https://app.hubspot.com/contacts/1803008/deal/",
    regionRequiredFieldForOrganisations: [
      Organisations.InternalTestOrganisationStagingId,
    ],
    contactNameAndEmailRequiredForOrganisations: [
      Organisations.InternalTestOrganisationStagingId,
    ],
  },
  development: {
    development: true,
    auth0: {
      domain: "identity.dev.songtradr.dev",
      clientId: "yMC814tPIgSkalL4y8iCnIKvCKaImnUr",
      audience: "songtradr-client-api",
      appName: "vinyl-spa",
      dbConnection: "vinyl-app-connection",
    },
    cognito: {
      userPoolId: "eu-west-1_mTtikScyR",
      clientId: "2p89p2tg8rihs8184cihia47p6",
      domain: generateDevEnv(),
      isSecure: isCognitoSecure(),
    },
    recaptcha: {
      key: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    },
    organizationsService: {
      url: process.env.REACT_APP_LOCAL_SERVICE
        ? "http://localhost:5000/org/organisations"
        : "https://api.dev.songtradr.dev/org/organisations",
    },
    projectManagementService: {
      url: process.env.REACT_APP_LOCAL_SERVICE
        ? "http://localhost:5000/project-management"
        : "https://api.dev.songtradr.dev/project-management",
    },
    userMetaDataService: {
      url: process.env.REACT_APP_LOCAL_SERVICE
        ? "http://localhost:5000/user-metadata"
        : "https://api.dev.songtradr.dev/user-metadata",
    },
    externalResources: {
      // TODO: Placeholder URLs - these need replaced
      termsOfService: "https://www.songtradr.com/legals/termsofuse",
      registrationLearnMore:
        "https://support.songtradr.com/hc/en-us/articles/115005436448-How-do-I-sign-up-",
    },
    webHookUrl: "https://webhook-events.api.dev.songtradr.dev/webhooks/events",
    dataDogToken: "pub4b37dc80da1a08d288af1ce3daef9fdb",
    basePathname: "/",
    heapId: "259303604",
    hubspotBaseUrl: "https://app.hubspot.com/contacts/1803008/deal/",
    regionRequiredFieldForOrganisations: [
      Organisations.InternalTestOrganisationDevId,
    ],
    contactNameAndEmailRequiredForOrganisations: [
      Organisations.InternalTestOrganisationDevId,
    ],
  },
};

export default config[process.env.REACT_APP_SETTINGS] || config.development;
