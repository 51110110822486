import IOrganisationRequest from "@songtradr/vinyl-common/lib/organisation/request/organisation-request.interface";
import axios from "axios";
import config from "../../config";
import getJsonContentHeader from "../headers/get-json-content-header";

function createOrganisationRequest(
  organisationRequest: IOrganisationRequest
): Promise<IOrganisationRequest> {
  const options = {
    headers: { ...getJsonContentHeader() },
  };

  return axios
    .post(
      `${config.organizationsService.url}/organisations/requests/`,
      organisationRequest,
      options
    )
    .then(({ data }) => data as IOrganisationRequest);
}

export default createOrganisationRequest;
