/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect, useRef } from "react";
import { Col, Form, Row, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import useAuth from "src/auth/use-auth";
import styles from "./styles";
import PasswordInput from "../user-profile/components/user-profile-form/components/password-input";

const ResetPassword = (): ReactElement => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useRef(false);
  const [form] = Form.useForm();
  const { changePassword, user, resetPassword } = useAuth();

  const sendEmail = () => {
    if (!user || !user.email) return;
    resetPassword(user.email);
  };

  useEffect(() => {
    if (mountedRef.current) return;
    sendEmail();
    mountedRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetNewPassword = (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    const code = e.target[0].value as string;
    if (!user || !user.email) return;
    if (!isValidPassword) {
      setIsError(true);
      setIsLoading(false);
      return;
    }
    changePassword(code, password, user.email);
    setIsError(false);
    setIsLoading(false);
  };

  return (
    <div css={styles.formContainer}>
      <Form form={form} onSubmitCapture={onSetNewPassword}>
        <Row css={styles.row}>
          <Col span={24}>
            <div css={styles.inputContainer}>
              <h2 css={styles.title}>{t("We sent the code to your email!")}</h2>
              <p>{t("Enter the verification code we sent to you.")}</p>
              <div css={styles.label}>Code</div>
              <Form.Item
                name="code"
                rules={[
                  {
                    min: 6,
                    required: true,
                    message: "Please input your verification code!",
                  },
                ]}
              >
                <Input name="verification-code" css={styles.input} />
              </Form.Item>
            </div>
            <div css={styles.inputContainer}>
              <div css={styles.label}>Password</div>
              <Form.Item name="password">
                <PasswordInput
                  css={styles.input}
                  setPassword={setPassword}
                  isValidPassword={isValidPassword}
                  setIsValidPassword={setIsValidPassword}
                  isError={isError && !isValidPassword}
                  name="password"
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row css={styles.row}>
          <Col span={24}>
            <Button loading={isLoading} htmlType="submit" css={styles.button}>
              {t("Set new password")}
            </Button>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => sendEmail()}
              onClick={() => sendEmail()}
              css={styles.backToSignin}
            >
              {t("Resend code")}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ResetPassword;
