import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TabbedPageContainer from "src/components/tabbed-page-container";
import Button from "src/components/button";
import BaseModal from "src/components/modals/base-modal";
import TeamPage from "./containers/team-page";
import style from "./styles";
import InviteMembers from "./components/org-invites/components/invite-members";

const Team = (): ReactElement => {
  const [invitesLastUpdatedAt, setInvitesLastUpdatedAt] = useState<Date>(
    new Date()
  );
  const { t } = useTranslation();
  const [modalVisible, toggleModal] = useState<boolean>(false);

  const tabs = [
    {
      tabTitle: t("manageTeam##members##Members"),
      content: (
        <TeamPage
          invitesLastUpdatedAt={invitesLastUpdatedAt}
          setInvitesLastUpdatedAt={setInvitesLastUpdatedAt}
        />
      ),
    },
  ];

  const handleModalToggle = useCallback(() => {
    toggleModal((state) => !state);
  }, []);

  const inviteButtonComponent = useMemo(() => {
    const btnLabel = "manageTeam##members##Invite members";
    return (
      <div css={style.customHeaderContentContainer}>
        <Button ariaLabel={btnLabel} onClick={handleModalToggle}>
          {btnLabel}
        </Button>
      </div>
    );
  }, [handleModalToggle]);

  return (
    <>
      <TabbedPageContainer
        title={t("manageTeam##Manage your team")}
        tabs={tabs}
        isTeams
        saveAndCloseComponent={inviteButtonComponent}
      />
      <BaseModal
        buttons={[]}
        open={modalVisible}
        onClose={handleModalToggle}
        content={
          <InviteMembers
            handleToggle={handleModalToggle}
            setInvitesLastUpdatedAt={setInvitesLastUpdatedAt}
          />
        }
      />
    </>
  );
};

export default Team;
