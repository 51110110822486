import axios from "axios";
import config from "src/config";
import { ITalentContactGroupsResponse } from "src/pages/talent-hub/interfaces";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const getGroups = async (
  accessToken: string,
  organisationId: string
): Promise<ITalentContactGroupsResponse[]> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const response = await axios.get(
    `${config.projectManagementService.url}/talent-hub/group?orgId=${organisationId}`,
    options
  );

  return response.data as ITalentContactGroupsResponse[];
};

export default getGroups;
