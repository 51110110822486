import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  contentContainer: css`
    padding: 0 1rem 0 1rem;
    height: 8.7rem;
    @media ${queries.medium} {
      padding: 0;
    }

    label {
      height: 0.938rem;
      color: ${theme.colors.background.darkGray};
      font-size: 0.688rem !important;
      letter-spacing: 0;
      line-height: 0.938rem;
      margin-bottom: 0.3rem;
      margin-top: 0.2rem;
    }

    .ant-select-selection-search {
      margin-top: 0 !important;
    }

    .ant-select-selection-search-input {
      margin-top: 0.4rem !important;
    }

    .ant-form-item-control-input {
      margin-bottom: 3.8rem;
    }

    .ant-form-item-explain {
      height: 0.938rem;
      color: ${theme.colors.functional.red};
      font-family: ${theme.fonts.sofiaPro};
      font-size: 0.75rem;
      letter-spacing: 0;
      line-height: 0.938rem;
      margin-left: 0.7rem;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 0.063rem solid var(--colors-primary-blue);
    }

    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: ${theme.colors.functional.red} !important;
    }

    @media ${queries.large} {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  `,
  header: css`
    color: ${theme.colors.secondary.navyBlue} !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    margin-bottom: 1.5rem !important;
    height: 1.5rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    margin-left: 1rem;

    @media ${queries.medium} {
      margin-left: 0;
    }
    font-family: ${theme.fonts.sofiaPro};

    :first-of-type {
      margin-top: 1.375rem;

      @media ${queries.medium} {
        margin-top: 1rem;
      }
    }
  `,
  tabletDesktopButtonContainer: css`
    height: 3.125rem;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;

    & button {
      box-shadow: none;
    }
  `,
  mobileButtonContainer: css`
    height: 3.125rem;
    background-color: ${theme.colors.white};
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 0.06rem solid ${theme.colors.background.veryLightGray};
  `,
  primaryButton: css`
    flex: 1;
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 0 !important;
    padding: 0;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: bold;
    border: 0;
    color: ${theme.colors.white};
    box-shadow: none;

    &:hover {
      box-shadow: none;
      background-color: ${theme.colors.primary.blue} !important;
      @media ${queries.large} {
        background-color: ${theme.colors.secondary.navyBlue} !important;
      }
    }

    @media ${queries.medium} {
      border-radius: 0.3rem !important;
      min-width: 9.0625rem;
      max-width: 9.0625rem;
      span {
        margin: 0 1rem 0 1rem;
      }
    }
  `,
  inviteMembersInput: css`
    width: 100%;
    box-sizing: border-box;
    background-color: ${theme.colors.white};

    .ant-select-selector {
      background-color: ${theme.colors.white} !important;
      border: 0.063rem solid var(--colors-primary-blue) !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      border-radius: 0.25rem !important;
      padding: 1.4rem 0.5rem 0.5rem 0.7rem !important;
      height: auto !important;
    }

    .close-icon {
      margin: 0 auto;
      display: block;
      margin-left: 1rem;
    }

    .ant-tag {
      display: flex;
      align-items: center;
      color: ${theme.colors.black};
    }

    .ant-select-selection-search {
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
    }

    .ant-select-selection-overflow {
      height: 5rem;
      overflow: auto;
      padding-top: 0.1rem;
      word-break: break-all;
    }
  `,
  validTag: css`
    background: rgba(31, 72, 231, 0.1);
    display: block;
    word-wrap: break-word;
    white-space: normal;
    color: ${theme.colors.black};
    margin-top: 0.28rem;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    border-radius: 0.2rem;
  `,
  errorTag: css`
    background: rgba(204, 0, 0, 0.1);
    display: block;
    word-wrap: break-word;
    white-space: normal;
    color: ${theme.colors.black};
    margin-top: 0.28rem;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    border-radius: 0.2rem;
  `,
  floatingLabelWrapper: css`
    position: relative;
    margin-bottom: 0.4rem;
    color: ${theme.colors.background.darkGray};
    height: 7rem;
    input {
      padding-top: 0.625rem;
    }
  `,
  floatingLabel: css`
    .ant-select label {
      font-size: 0.6rem !important;
      margin-top: 0.1rem;
    }
  `,
  guidanceText: css`
    height: 0.938rem;
    color: ${theme.colors.background.darkGray};
    font-size: 0.688rem;
    letter-spacing: 0;
    line-height: 0.938rem;
    margin-left: 0.7rem;
  `,
  inviteSent: css`
    text-align: center;
    margin-top: 5rem;

    @media ${queries.medium} {
      margin-top: 1.5rem;
    }
  `,
  inviteSentHeader: css`
    height: 1.75rem;
    color: ${theme.colors.secondary.navyBlue};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
  `,
  inviteSentPrimaryButtonMobile: css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 100;
  `,
  inviteSentPrimaryButtonTabletDesktop: css`
    @media ${queries.medium} {
      margin-top: 2rem;
    }
  `,
  inviteSentSecondaryButton: css`
    margin: 0 auto;
    display: block;
    border: none !important;
    color: ${theme.colors.primary.blue} !important;
    margin-top: 0.5rem;
  `,
};
