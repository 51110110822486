import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import containerStyles from "../../styles";
import formStyles from "../registration-form/styles";

const OrganisationExists = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={containerStyles.formWrapper}>
      <Typography.Title css={formStyles.title} level={3}>
        {t("Thank you")}
      </Typography.Title>
      <p>
        {t(
          "Thanks for signing up to Songtradr Projects. Looks like this organisation already exists. Please contact your organisation’s admin to request an invite to join your team and start collaborating!"
        )}
      </p>
    </div>
  );
};

export default OrganisationExists;
