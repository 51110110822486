import React, { useCallback } from "react";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import SortButton, {
  sortTypes,
} from "src/components/control-buttons/sort-button";
import { useTranslation } from "react-i18next";
import theme from "src/theme";
import style from "../styles";
import {
  ILibraryTrackTableSort,
  LibraryTrackSortType,
  SortOrder,
} from "../interfaces";

export default (
  isTablet: boolean,
  handleSort: (params: ILibraryTrackTableSort) => void,
  sortOptions: ILibraryTrackTableSort
): IColumnsData[] => {
  const { t } = useTranslation();

  const sortByColumn = useCallback(
    (column: LibraryTrackSortType) => {
      handleSort({
        sortBy: column,
        order:
          sortOptions.sortBy === column && sortOptions.order === SortOrder.ASC
            ? SortOrder.DESC
            : SortOrder.ASC,
      });
    },
    [handleSort, sortOptions.order, sortOptions.sortBy]
  );

  const generateColumn = useCallback(
    (
      columnsData: {
        title: string;
        key: string;
        sort: boolean;
        fixed?: "left" | "right";
      }[]
    ): IColumnsData[] => {
      return columnsData.map(({ title, key, sort, fixed }) => {
        const columnKey = key as LibraryTrackSortType;
        return {
          title: (
            <div css={style.titleContainer}>
              <span>{title}</span>
              {sort && !isTablet && (
                <SortButton
                  tooltipText={
                    sortOptions.sortBy !== LibraryTrackSortType[columnKey] ||
                    (sortOptions.sortBy === LibraryTrackSortType[columnKey] &&
                      sortOptions.order === SortOrder.ASC)
                      ? t("Sort A-Z")
                      : t("Sort Z-A")
                  }
                  css={style.sortButton}
                  dataTestId="project-name-sort-button"
                  sortType={
                    sortOptions.sortBy !== LibraryTrackSortType[columnKey] ||
                    (sortOptions.sortBy === LibraryTrackSortType[columnKey] &&
                      sortOptions.order === SortOrder.ASC)
                      ? sortTypes.A_Z
                      : sortTypes.Z_A
                  }
                  onClick={() => sortByColumn(LibraryTrackSortType[columnKey])}
                  fillColor={
                    sortOptions.sortBy === LibraryTrackSortType[columnKey]
                      ? undefined
                      : theme.colors.background.lightGray
                  }
                />
              )}
            </div>
          ),
          dataIndex: key,
          key,
          width: 200,
          fixed,
        };
      });
    },
    [isTablet, sortByColumn, sortOptions.order, sortOptions.sortBy, t]
  );

  return generateColumn([
    { title: "Track ID", key: "trackId", sort: true, fixed: "left" },
    { title: "Track Title", key: "trackTitle", sort: true },
    { title: "Track Artist", key: "trackArtist", sort: true },
    { title: "Downloaded by", key: "downloadedBy", sort: true },
    { title: "Declared by", key: "declaredBy", sort: true },
    { title: "Declared Date", key: "createdDate", sort: true },
    { title: "Project Name", key: "projectName", sort: true },
    { title: "Project Description", key: "projectDescription", sort: true },
    { title: "Business Unit/Brand", key: "businessBrand", sort: true },
    { title: "Territories", key: "territories", sort: false },
    { title: "Go live date", key: "goLiveDate", sort: true },
    { title: "Youtube Channel/s", key: "youtubeChannels", sort: false },
    { title: "Facebook Page/s", key: "facebookPages", sort: false },
    { title: "Instagram Page/s", key: "instagramPages", sort: false },
    { title: "TikTok account/s", key: "tikTokAccounts", sort: false },
    { title: "X account/s", key: "twitterAccounts", sort: false },
    { title: "Company website", key: "companyWebsite", sort: false },
    { title: "Other", key: "other", sort: false },
    { title: "Video links", key: "videoLinks", sort: false },
    { title: "", key: "menuDropDown", sort: false, fixed: "right" },
  ]);
};
