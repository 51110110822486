import axios from "axios";
import { IOrganisationUrlParams } from "src/interfaces/organisation-url-params";
import config from "../../config";
import { getJsonContentHeader } from "../headers";

const resendVerificationEmail = ({
  organisationId,
  organisationInviteId,
}: IOrganisationUrlParams): Promise<boolean> => {
  const options = {
    headers: { ...getJsonContentHeader() },
  };
  return axios.put(
    `${config.organizationsService.url}/organisations/${organisationId}/invites/${organisationInviteId}/_resend`,
    options
  );
};

export default resendVerificationEmail;
