import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import mainStyles from "src/pages/projects/project/styles";
import useContactsSearchAndFilters from "src/providers/talent-search/hooks";
import MappedContactsTabs, {
  StaticTabs,
} from "src/utils/getMappedContactsTabs";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import useAuth from "src/auth/use-auth";
import BaseModal from "src/components/modals/base-modal";
import Content from "src/components/modals/confirmation-modal/content";
import { IConfirmContentProps } from "src/components/interfaces";
import { useTranslation } from "react-i18next";
import { ISavedUserColumn } from "src/api/user-filters/interfaces";
import { saveTalentHubColumns } from "src/api/user-filters";
import { ErrorToast } from "src/components/toast-notification";
import ViewColumnsModalContent from "src/components/modals/view-columns-modal";
import { ITalentTab } from "../interfaces";
import TalentHubTabs from "../talent-hub-tabs";
import AddContactToGroup from "../add-contact-to-group";

const TalentHubContent = (): ReactElement => {
  const [areFiltersVisible, setFiltersVisible] = useState(false);
  const [isCreateGroupOpen, setIsCreateGroupOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddContactToGroupOpen, setIsAddContactToGroupOpen] = useState(false);
  const [talentId, setTalentId] = useState<string>("");
  const [isUserGroup, setIsUserGroup] = useState(true);
  const [isGroupTabsClicked, setIsGroupTabsClicked] = useState<boolean | null>(
    null
  );
  const [activeTab, setActiveTab] = useState<StaticTabs | string>(
    StaticTabs.AllContacts
  );
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");
  const [showColumnsModal, setShowColumnsModal] = useState(false);

  const { t } = useTranslation();

  const handleSetShowColumnsModal = useCallback((visibility: boolean) => {
    setShowColumnsModal(visibility);
  }, []);

  const handleCloseOrOpenCreateGroup = useCallback(() => {
    setIsCreateGroupOpen(!isCreateGroupOpen);
  }, [isCreateGroupOpen]);

  const handleCloseOrOpenDeleteGroup = useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
    setSelectedGroupId("");
  }, [isDeleteModalOpen]);

  const { fetchWrapper, organisationId } = useAuth();

  const handleFiltersVisibility = useCallback(() => {
    setFiltersVisible(!areFiltersVisible);
  }, [areFiltersVisible]);

  const contactsSearchAndFilters = useContactsSearchAndFilters();
  const { talentHubTypes } = useGlobalStates();

  const handleDeleteGroupClick = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      groupId: string | null
    ) => {
      e.stopPropagation();
      if (groupId) {
        setIsDeleteModalOpen(!isDeleteModalOpen);
        setSelectedGroupId(groupId);
      }
    },
    [isDeleteModalOpen]
  );

  const myGroupsOrOrgGroups = useMemo(() => {
    return contactsSearchAndFilters.allGroups.filter(
      (group) => group.isUserGroup === isUserGroup
    );
  }, [contactsSearchAndFilters.allGroups, isUserGroup]);

  useEffect(() => {
    if (isGroupTabsClicked === null) {
      return;
    }
    const firstSelectableGroupId = myGroupsOrOrgGroups?.[0]?.id;
    if (firstSelectableGroupId) {
      setActiveTab(firstSelectableGroupId);
      contactsSearchAndFilters.handleSetSelectedGroup({
        id: firstSelectableGroupId,
        isUserGroup,
      });
      contactsSearchAndFilters.handleFetchSpecificGroupContacts(
        firstSelectableGroupId,
        isUserGroup,
        true
      );
    } else {
      contactsSearchAndFilters.handleSetSelectedGroup({
        id: "",
        isUserGroup: false,
      });
      setActiveTab(StaticTabs.AllContacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGroupTabsClicked]);

  const confirmDeleteContentProps: IConfirmContentProps = useMemo(() => {
    return {
      confirmAction: () => {
        contactsSearchAndFilters.handleDeleteGroup(
          selectedGroupId,
          String(isUserGroup)
        );
        setIsDeleteModalOpen(false);
        setActiveTab(StaticTabs.AllContacts);
      },
      onClose: handleCloseOrOpenDeleteGroup,
      primaryButtonLabel: t("Delete group"),
      secondaryButtonLabel: t("Cancel"),
      modalTitle: t("TalentPage##Hold up!"),
      mainContent: (
        <p data-testid="confirmation-content">
          {t("DeleteGroup", {
            group:
              myGroupsOrOrgGroups.find((group) => group.id === selectedGroupId)
                ?.name ?? "",
          })}
        </p>
      ),
    };
  }, [
    contactsSearchAndFilters,
    myGroupsOrOrgGroups,
    handleCloseOrOpenDeleteGroup,
    isUserGroup,
    selectedGroupId,
    t,
  ]);

  const handleChangeIsUserGroup = useCallback(
    (isUserGroupSelected: boolean) => {
      setIsUserGroup(isUserGroupSelected);
    },
    []
  );

  const handleCloseContactToGroup = useCallback(() => {
    setTalentId("");
    setIsAddContactToGroupOpen(!isAddContactToGroupOpen);
  }, [isAddContactToGroupOpen]);

  const handleAddContactToGroup = useCallback(
    (contactId: string) => {
      setTalentId(contactId);
      setIsAddContactToGroupOpen(!isAddContactToGroupOpen);
    },
    [isAddContactToGroupOpen]
  );

  const handleRemoveContactFromGroup = useCallback(
    (groupId: string, contactId: string) => {
      contactsSearchAndFilters.handleRemoveContactFromGroup(
        groupId,
        isUserGroup,
        contactId
      );
    },
    [contactsSearchAndFilters, isUserGroup]
  );

  const tabs: ITalentTab[] = MappedContactsTabs(
    talentHubTypes,
    organisationId,
    myGroupsOrOrgGroups,
    handleRemoveContactFromGroup,
    handleAddContactToGroup,
    handleCloseOrOpenCreateGroup,
    handleDeleteGroupClick,
    handleChangeIsUserGroup,
    contactsSearchAndFilters
  );

  const handleChangeActiveTab = useCallback(
    (newActiveTab: string) => {
      if (newActiveTab === activeTab || newActiveTab === StaticTabs.Header) {
        return;
      }
      if (newActiveTab === StaticTabs.SelectGroups) {
        setIsGroupTabsClicked(!isGroupTabsClicked);
        contactsSearchAndFilters.handleGroupPageChange(1, false);
        contactsSearchAndFilters.handlePageChange(1);
        return;
      }
      setActiveTab(newActiveTab);
      contactsSearchAndFilters.handleGroupPageChange(1, false);
      contactsSearchAndFilters.handlePageChange(1);
      if (
        ![StaticTabs.AllContacts, StaticTabs.Header].includes(
          newActiveTab as StaticTabs
        )
      ) {
        contactsSearchAndFilters.handleSetSelectedGroup({
          id: newActiveTab,
          isUserGroup,
        });
        contactsSearchAndFilters.handleFetchSpecificGroupContacts(
          newActiveTab,
          isUserGroup,
          true
        );
      }
      if (newActiveTab === StaticTabs.AllContacts) {
        contactsSearchAndFilters.handleSetSelectedGroup({
          id: "",
          isUserGroup: false,
        });
      }
    },
    [activeTab, contactsSearchAndFilters, isGroupTabsClicked, isUserGroup]
  );

  const handleCreateContactsGroup = useCallback(
    (isUserCreatedGroup: boolean, groupName: string) => {
      setActiveTab(StaticTabs.AllContacts);
      contactsSearchAndFilters.handleCreateContactsGroup(
        isUserCreatedGroup,
        groupName
      );
    },
    [contactsSearchAndFilters]
  );

  const handleSaveViewColumnsChanges = useCallback(
    async (selectedColumns: ISavedUserColumn[]) => {
      try {
        await fetchWrapper(
          saveTalentHubColumns,
          organisationId,
          selectedColumns
        );
        await contactsSearchAndFilters.updateSavedColumns();
        handleSetShowColumnsModal(false);
      } catch (error) {
        ErrorToast(
          "error-saving-columns",
          t("ViewColumnsModal##SaveError##message"),
          t("ViewColumnsModal##SaveError##description")
        );
      }
    },
    [
      contactsSearchAndFilters,
      fetchWrapper,
      handleSetShowColumnsModal,
      organisationId,
      t,
    ]
  );

  return (
    <div css={mainStyles.projectContainer}>
      <AddContactToGroup
        isOpen={isAddContactToGroupOpen}
        talentId={talentId}
        allGroups={contactsSearchAndFilters.allGroups}
        onClose={handleCloseContactToGroup}
        onAddContactToGroup={contactsSearchAndFilters.handleAddContactToGroup}
      />
      <BaseModal
        open={isDeleteModalOpen}
        content={<Content {...confirmDeleteContentProps} />}
        buttons={[]}
        onClose={handleCloseOrOpenDeleteGroup}
        onCancel={handleCloseOrOpenDeleteGroup}
      />
      <BaseModal
        open={showColumnsModal}
        onClose={() => handleSetShowColumnsModal(false)}
        content={
          <ViewColumnsModalContent
            columnsForTable="talentHub"
            selectedColumns={contactsSearchAndFilters.savedColumns}
            onClose={() => handleSetShowColumnsModal(false)}
            onSaveChanges={handleSaveViewColumnsChanges}
          />
        }
        footer={null}
      />
      <TalentHubTabs
        activeTab={activeTab}
        isUserGroup={isUserGroup}
        areFiltersVisible={areFiltersVisible}
        tabs={tabs}
        isLoading={contactsSearchAndFilters.isLoading}
        organisationId={organisationId}
        isCreateGroupOpen={isCreateGroupOpen}
        defaultFiltersValues={contactsSearchAndFilters.defaultFiltersValues}
        onChangeActiveTab={handleChangeActiveTab}
        onCloseOrOpenCreateGroup={handleCloseOrOpenCreateGroup}
        onFiltersVisibility={handleFiltersVisibility}
        onCreateContactsGroup={handleCreateContactsGroup}
        onShowColumnsModal={handleSetShowColumnsModal}
        savedColumns={contactsSearchAndFilters.savedColumns}
        isLoadingSavedColumns={contactsSearchAndFilters.isLoadingSavedColumns}
      />
    </div>
  );
};

export default TalentHubContent;
