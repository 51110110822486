import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";

const EditIcon = ({
  height = theme.svgSize.large,
  width = theme.svgSize.large,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
      aria-labelledby="edit-button"
    >
      <title id="edit-button">{t("Edit")}</title>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={theme.colors.black}
        strokeWidth="1.5"
        transform="translate(8.5 9)"
      >
        <path
          d="M4.81142893,14.6796699 L6.66878496,17.7457312 L3.2305346,17.7457312 L4.81142893,14.6796699 Z"
          transform="rotate(-135 5 15.587)"
        />
        <line
          x1="4.182"
          x2="17.182"
          y1="19.088"
          y2="19.088"
          strokeLinecap="round"
        />
        <path
          d="M14.1189455,0.75 C14.4641234,0.75 14.7766234,0.889911016 15.002829,1.11611652 C15.2290345,1.34232203 15.3689455,1.65482203 15.3689455,2 L15.3689455,2 L15.3689455,14.25 L10.8660312,14.25 L10.8660312,2 C10.8660312,1.65482203 11.0059423,1.34232203 11.2321478,1.11611652 C11.4583533,0.889911016 11.7708533,0.75 12.1160312,0.75 L12.1160312,0.75 Z"
          transform="rotate(45 13.117 7.5)"
        />
      </g>
    </svg>
  );
};

export default EditIcon;
