import React, { useEffect, useMemo } from "react";
import useConfirm from "src/providers/confirm-context-provider/hooks";
import BaseModal from "../base-modal";
import Content from "../confirmation-modal/content";

const ConfirmModal = (): JSX.Element => {
  const {
    prompt = "",
    isOpen = false,
    okButtonName = "Yes",
    cancelButtonName = "Cancel",
    proceed,
    cancel,
  } = useConfirm();

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (proceed && isOpen && e.key === "Enter") {
        proceed(null);
      }
    };

    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [proceed, isOpen]);

  const confirmDeleteContentProps = useMemo(() => {
    return {
      confirmAction: () => proceed(null),
      onClose: cancel,
      primaryButtonLabel: okButtonName,
      secondaryButtonLabel: cancelButtonName,
      mainContent: <p data-testid="confirmation-content">{prompt}</p>,
    };
  }, [cancel, okButtonName, cancelButtonName, prompt, proceed]);

  return (
    <BaseModal
      open={isOpen}
      content={<Content {...confirmDeleteContentProps} />}
      buttons={[]}
      onClose={cancel}
    />
  );
};
export default ConfirmModal;
