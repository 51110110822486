import { ConfigProvider, Table, Tabs } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import { IContactsResults } from "src/pages/contact/interfaces";
import { ContactsPageSize } from "src/constants";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { StaticTabs } from "src/utils/getMappedContactsTabs";
import STLoadingLogo from "src/components/st-loading-logo";
import { IPaginationOptions } from "src/pages/projects/project-listings/interfaces";
import { ISavedUserColumn } from "src/api/user-filters/interfaces";
import { getIsMobile } from "@songtradr/spa-common";
import styles from "../styles";
import { IContactsFilters, ITalentDataSource, ITalentTab } from "../interfaces";
import TalentTableColumns from "../table-data/get-talent-table-columns";
import NoTalentFound from "../no-talent-found";
import TalentHubActionBar from "../talent-hub-action-bar";
import ViewContact from "../view-contact";
import mapContactDataForViewContact from "../view-contact/utils";
import CreateGroup from "../create-group";

interface IProps {
  tabs: ITalentTab[];
  isUserGroup: boolean;
  areFiltersVisible: boolean;
  isLoading: boolean;
  isCreateGroupOpen: boolean;
  activeTab: string;
  organisationId: string;
  defaultFiltersValues: IContactsFilters;
  savedColumns: ISavedUserColumn[] | null;
  isLoadingSavedColumns: boolean;
  onChangeActiveTab: (activeTab: string) => void;
  onFiltersVisibility: () => void;
  onCloseOrOpenCreateGroup: () => void;
  onCreateContactsGroup: (isUserGroup: boolean, groupName: string) => void;
  onShowColumnsModal: (visibility: boolean) => void;
}

const TalentHubTabs = ({
  tabs,
  areFiltersVisible,
  isUserGroup,
  isLoading,
  isCreateGroupOpen,
  organisationId,
  activeTab,
  defaultFiltersValues,
  savedColumns,
  isLoadingSavedColumns,
  onFiltersVisibility,
  onChangeActiveTab,
  onCloseOrOpenCreateGroup,
  onCreateContactsGroup,
  onShowColumnsModal,
}: IProps): ReactElement => {
  const columns = TalentTableColumns(savedColumns, onShowColumnsModal);
  const isMobile = getIsMobile();
  const [isViewOpen, setIsViewOpen] = useState(false);

  const [
    selectedContact,
    setSelectedContact,
  ] = useState<IContactsResults | null>(null);
  const [paginationRange, setPaginationRange] = useState([
    1,
    tabs[1]?.paginationOptions &&
    tabs[1].paginationOptions.total > 0 &&
    tabs[1].paginationOptions.total < ContactsPageSize
      ? tabs[1].paginationOptions.total
      : ContactsPageSize,
  ]);
  const { talentHubTypes } = useGlobalStates();

  const handleCloseView = useCallback(() => {
    setIsViewOpen(false);
    setSelectedContact(null);
  }, []);

  const handleRowClick = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      record: ITalentDataSource,
      rawData: IContactsResults[]
    ) => {
      event.stopPropagation();
      if (record) {
        const contactDataResponse = rawData.find(
          (item) => item.id === record.id
        );
        if (contactDataResponse) {
          setIsViewOpen(true);
          setSelectedContact(contactDataResponse);
        }
      }
    },
    []
  );

  useEffect(() => {
    const currentlySelectedTab = tabs.find((tab) => tab.tabKey === activeTab);
    const paginationOptions = currentlySelectedTab?.paginationOptions;
    if (paginationOptions && paginationOptions.total > 0) {
      const min = (paginationOptions.currentPage - 1) * ContactsPageSize + 1;
      const max =
        paginationOptions.total <
        ContactsPageSize * paginationOptions.currentPage
          ? paginationOptions.total
          : ContactsPageSize * paginationOptions.currentPage;
      setPaginationRange([min, max]);
    } else {
      setPaginationRange([0, 0]);
    }
  }, [activeTab, tabs]);

  const handlePageChange = (
    page: number,
    paginationOptions?: IPaginationOptions,
    onPageChange?: (page: number) => void
  ) => {
    onPageChange?.(page);
    if (!paginationOptions) {
      return;
    }
    if (page === 1) {
      setPaginationRange([
        1,
        paginationOptions.total < ContactsPageSize
          ? paginationOptions.total
          : ContactsPageSize,
      ]);
      return;
    }
    const min = (page - 1) * ContactsPageSize + 1;
    const max =
      min + ContactsPageSize < paginationOptions.total
        ? min + ContactsPageSize - 1
        : paginationOptions.total;
    setPaginationRange([min, max]);
  };

  const itemRender = useCallback(
    (current: number, type: string, originalElement: ReactElement) => {
      if (type === "prev") {
        return <ArrowLeftOutlined />;
      }
      if (type === "next") {
        return <ArrowRightOutlined />;
      }
      return originalElement;
    },
    []
  );

  // Tabs length is never 0, default value is  1
  const isPageLoading = useMemo(() => tabs.length === 1 && isLoading, [
    isLoading,
    tabs.length,
  ]);

  const defaultEmptyFunc = useCallback(() => {}, []);

  const tabItems = useMemo(() => {
    return tabs.map(
      ({
        tabTitle,
        dataSource,
        tabKey,
        rawData,
        totalResults,
        paginationOptions,
        onPageChange,
        searchText,
        availableContactFilters,
        selectedContactFilters,
        onFetchSelectedFilters,
        onUpdateSearchText,
      }) => {
        return {
          label: tabTitle,
          key: tabKey,
          children: (
            <div css={styles.contentContainer} key={tabKey}>
              <div css={styles.formContainer}>
                <div css={styles.container}>
                  <TalentHubActionBar
                    searchText={searchText ?? ""}
                    areFiltersVisible={areFiltersVisible}
                    numberOfContacts={totalResults}
                    availableContactFilters={
                      availableContactFilters ?? defaultFiltersValues
                    }
                    selectedContactFilters={
                      selectedContactFilters ?? defaultFiltersValues
                    }
                    showAddContactButton={tabKey === StaticTabs.AllContacts}
                    onFiltersVisibility={onFiltersVisibility}
                    onUpdateSearchText={onUpdateSearchText ?? defaultEmptyFunc}
                    onFetchSelectedFilters={
                      onFetchSelectedFilters ?? defaultEmptyFunc
                    }
                  />
                  <div css={styles.contactsTableContainer}>
                    <ConfigProvider
                      renderEmpty={() => {
                        return !isLoading && !isLoadingSavedColumns ? (
                          <NoTalentFound />
                        ) : (
                          <></>
                        );
                      }}
                    >
                      <Table
                        columns={columns}
                        dataSource={dataSource}
                        loading={{
                          indicator: <STLoadingLogo />,
                          spinning: isLoading || isLoadingSavedColumns,
                        }}
                        css={styles.talentTable}
                        scroll={{ x: true }}
                        pagination={
                          paginationOptions && {
                            pageSize: ContactsPageSize,
                            total: paginationOptions.total,
                            current: paginationOptions.currentPage,
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                            showLessItems: true,
                            itemRender,
                            onChange: (page: number) =>
                              handlePageChange(
                                page,
                                paginationOptions,
                                onPageChange
                              ),
                          }
                        }
                        onRow={(record) => ({
                          onClick: (event) =>
                            handleRowClick(event, record, rawData),
                          onDoubleClick: (event) => event.stopPropagation(),
                        })}
                        data-testid="talents-table"
                      />
                    </ConfigProvider>
                  </div>
                  {dataSource.length === 0 && !isLoading ? null : (
                    <div css={styles.paginationCount}>{`Showing ${
                      paginationRange[0]
                    }-${paginationRange[1]} of ${
                      paginationOptions?.total ?? 0
                    } results`}</div>
                  )}
                </div>
              </div>
            </div>
          ),
        };
      }
    );
  }, [
    areFiltersVisible,
    columns,
    defaultEmptyFunc,
    defaultFiltersValues,
    handleRowClick,
    isLoading,
    isLoadingSavedColumns,
    itemRender,
    onFiltersVisibility,
    paginationRange,
    tabs,
  ]);

  return (
    <>
      <ViewContact
        isOpen={isViewOpen}
        onClose={handleCloseView}
        contact={mapContactDataForViewContact(selectedContact, talentHubTypes)}
        organisationId={organisationId}
      />
      <CreateGroup
        isOpen={isCreateGroupOpen}
        isUserGroup={isUserGroup}
        onCreateContactsGroup={onCreateContactsGroup}
        onClose={onCloseOrOpenCreateGroup}
      />
      {isPageLoading ? (
        <STLoadingLogo pageCentered />
      ) : (
        <div css={styles.talentTabsHeader}>
          <Tabs
            destroyInactiveTabPane
            tabPosition={isMobile ? "top" : "left"}
            css={styles.tabsStyles}
            activeKey={activeTab}
            onChange={onChangeActiveTab}
            items={tabItems}
          />
        </div>
      )}
    </>
  );
};

export default TalentHubTabs;
