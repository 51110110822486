import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";

const SortIcon = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox="0 0 24 24"
      aria-labelledby="sort-icon"
    >
      <title id="sort-icon">{t("Sort")}</title>

      <path
        fill="#8058FF"
        fillRule="evenodd"
        d="M13.0396271 7.25020764C13.3131056 7.52368609 13.3131056 7.96708225 13.0396271 8.2405607 12.7934965 8.4866913 12.4097326 8.51130436 12.1360753 8.31439988L12.0492741 8.2405607 9.02973597 5.21926403 9.02926516 12.5444506C9.02926516 12.9312075 8.71573672 13.244736 8.3289798 13.244736 7.94222287 13.244736 7.62869444 12.9312075 7.62869444 12.5444506L7.62873597 5.05126403 4.44019786 8.2405607C4.16671942 8.51403914 3.72332325 8.51403914 3.44984481 8.2405607 3.17636636 7.96708225 3.17636636 7.52368609 3.44984481 7.25020764L7.74137471 2.95867774C7.88013969 2.81991276 8.06265209 2.75155815 8.24451637 2.75355753 8.38982616 2.75176992 8.53596039 2.79514551 8.65977902 2.88374683L8.74809724 2.95867774 13.0396271 7.25020764zM20.5396271 16.7113732C20.8131056 16.4378948 20.8131056 15.9944986 20.5396271 15.7210202 20.2934965 15.4748896 19.9097326 15.4502765 19.6360753 15.647181L19.5492741 15.7210202 16.529736 18.7423168 16.5292652 11.4171302C16.5292652 11.0303733 16.2157367 10.7168449 15.8289798 10.7168449 15.4422229 10.7168449 15.1286944 11.0303733 15.1286944 11.4171302L15.128736 18.9103168 11.9401979 15.7210202C11.6667194 15.4475417 11.2233233 15.4475417 10.9498448 15.7210202 10.6763664 15.9944986 10.6763664 16.4378948 10.9498448 16.7113732L15.2413747 21.0029031C15.3801397 21.1416681 15.5626521 21.2100227 15.7445164 21.2080233 15.8898262 21.2098109 16.0359604 21.1664353 16.159779 21.077834L16.2480972 21.0029031 20.5396271 16.7113732z"
      />
    </svg>
  );
};

export default SortIcon;
