import { initialiseAttachments } from "src/providers/project/state/projectResponseMapper";
import { IProjectForm } from "../../project/interfaces";

export interface INameArray {
  name: string;
}

const formatNameList = (array: INameArray[]): string[] => {
  const list = (array ?? []).map((item, index) => {
    let value = item.name;
    if (array[index + 1]) {
      value += ", ";
    }
    return value;
  });
  return list;
};

export const formatAttachments = (project: IProjectForm): IProjectForm => {
  // eslint-disable-next-line no-param-reassign
  project.licenseAttachments = initialiseAttachments(
    project.attachments,
    "License",
    true
  );

  // eslint-disable-next-line no-param-reassign
  project.invoiceAttachments = initialiseAttachments(
    project.attachments,
    "Invoice",
    true
  );

  // eslint-disable-next-line no-param-reassign
  project.purchaseOrderAttachments = initialiseAttachments(
    project.attachments,
    "PurchaseOrder",
    true
  );

  // eslint-disable-next-line no-param-reassign
  project.estimateAttachments = initialiseAttachments(
    project.attachments,
    "Estimate",
    true
  );

  // eslint-disable-next-line no-param-reassign
  project.miscAttachments = initialiseAttachments(
    project.attachments,
    "Misc",
    true
  );

  // eslint-disable-next-line no-param-reassign
  project.assetAttachments = initialiseAttachments(
    project.attachments,
    "Asset",
    true
  )
    .concat(initialiseAttachments(project.attachments, "MusicAsset"))
    .concat(initialiseAttachments(project.attachments, "VideoAsset"))
    .concat(initialiseAttachments(project.attachments, "Track"));

  // eslint-disable-next-line no-param-reassign
  project.testResultAttachments = initialiseAttachments(
    project.attachments,
    "TestResult",
    true
  );

  // eslint-disable-next-line no-param-reassign
  project.briefAttachments = initialiseAttachments(
    project.attachments,
    "Brief"
  );

  return project;
};

export default formatNameList;
