import axios from "axios";
import config from "src/config";
import getAuthHeader from "../headers/get-auth-header";
import getJsonContentHeader from "../headers/get-json-content-header";

interface IProps {
  attachmentID: string;
  projectId: string;
  accessToken: string;
  orgId: string;
}

async function deleteFile({
  attachmentID,
  projectId,
  accessToken,
  orgId,
}: IProps): Promise<void> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  return axios.delete(
    `${config.projectManagementService.url}/projects/${projectId}/attachments/${attachmentID}?orgId=${orgId}`,
    options
  );
}

export default deleteFile;
