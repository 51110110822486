import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  submit: css`
    margin-top: 1rem;
    margin-bottom: 0;
    button {
      border-radius: 4px;
      background-color: ${theme.colors.primary.blue};
      box-shadow: none;
      font-size: 1rem;
    }
  `,
  input: css`
    input {
      border: 1px solid ${theme.colors.background.lightGray};
      height: 3.125rem;
      border-radius: 4px;
    }
    .password-field {
      border: 1px solid ${theme.colors.background.lightGray} !important;
      border-radius: 4px;
      height: 3.125rem;
    }
    .error {
      border: 1px solid ${theme.colors.functional.red} !important;
      border-radius: 4px;
    }
    .ant-form-item-explain-error div {
      padding-left: 0.8rem !important;
      padding-top: 0.4rem !important;
      font-size: 16px;
      color: ${theme.colors.functional.red} !important;
      span {
        padding-left: 0 !important;
        padding-top: 0 !important;
      }
    }
  `,
  description: css`
    margin-bottom: 2.5rem !important;
  `,
  title: css`
    font-size: 2.75rem !important;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 900;
  `,
};
