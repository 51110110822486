import { IAuthenticatedUser } from "@songtradr/spa-common";
import React from "react";
import { IUserPermissions } from "src/auth/access-token-utils";
import { ISimpleUser } from "src/interfaces/auth";

export interface ICognitoContext {
  handleLogin: (username: string, password: string) => void;
  login: (target?: string) => void;
  logout: (resetState?: boolean) => void;
  isSessionValid: () => Promise<boolean>;
  isAuthenticated: boolean;
  getAccessToken: () => string;
  isLoading: boolean;
  user?: IAuthenticatedUser | null;
  users?: ISimpleUser[];
  superAdmins?: ISimpleUser[];
  isOrgAdmin: boolean;
  organisationId: string;
  userPermissions: IUserPermissions;
  resetPassword: (email: string) => void;
  isPasswordReset: boolean;
  confirmResetVerificationCode: (
    verificationCode: string,
    newPassword: string,
    email: string
  ) => void;
  changePassword: (
    verificationCode: string,
    newPassword: string,
    email: string
  ) => void;
  error?: {
    error: string;
    error_description: string;
  } | null;
  isSuperAdmin: boolean;
  switchOrg: (orgId: string, redirectToDashboard?: boolean) => Promise<void>;
  hasMultipleOrgs: boolean;
  allUsers: ISimpleUser[];
  // We need type any. Unknown type is not an option because of lint errors.
  // Generic T type is not an option because generic async function with arrow function syntax makes that function as an unbound function,
  // which can lead to unexpected behavior
  fetchWrapper: <T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchFunction: (...args: any[]) => Promise<T>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ) => Promise<T>;
}

export default React.createContext<ICognitoContext>({
  login: () => {},
  logout: () => {},
  handleLogin: () => {},
  fetchWrapper: async <T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchFunction: (...args: any[]) => Promise<T>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ): Promise<T> => {
    const result = await fetchFunction(...args);
    return result;
  },
  isSessionValid: () => Promise.resolve(false),
  users: [],
  isAuthenticated: false,
  userPermissions: {
    canAccessProjects: false,
    canAccessTeams: false,
  },
  isLoading: true,
  isOrgAdmin: false,
  resetPassword: () => {},
  isPasswordReset: false,
  confirmResetVerificationCode: () => {},
  changePassword: () => {},
  error: null,
  organisationId: "",
  getAccessToken: () => "",
  isSuperAdmin: false,
  switchOrg: async () => {},
  hasMultipleOrgs: false,
  superAdmins: [],
  allUsers: [],
});
