import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  card: css`
    width: 25.5rem;
    height: 33.125rem;
    border-radius: 0.25rem;
    padding: 2.5rem;
    background-color: ${theme.colors.white};
    box-shadow: 0 0.063rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5rem;
  `,
  image: css`
    height: 200px;
    align-items: center;
    margin: auto;
    display: block;
    margin-bottom: 1.313rem;
  `,
  title: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 2.25rem;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 2.688rem;
    text-align: center;
    margin-bottom: 1.625rem !important;
  `,
  description: css`
    font-family: ${theme.fonts.sofiaPro};
    text-align: left;
    margin-bottom: 2.5rem !important;
    font-size: 16px;
  `,
  button: css`
    margin: auto;
    display: block;
    margin-bottom: 2rem !important;
  `,
};
