import { Button, Drawer, Typography } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import SelectedIcon from "src/app/assets/icons/component-icons/selected-icon";
import styles from "../styles";
import { ITalentContactGroupsResponse } from "../interfaces";
import { GroupType } from "../create-group";

interface IProps {
  isOpen: boolean;
  talentId: string;
  allGroups: ITalentContactGroupsResponse[];
  onClose: () => void;
  onAddContactToGroup: (groupName: string, talentId: string) => void;
}

const AddContactToGroup: React.FC<IProps> = ({
  isOpen,
  talentId,
  allGroups,
  onAddContactToGroup,
  onClose,
}: IProps): React.ReactElement => {
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [activeGroupTab, setActiveGroupTab] = useState(GroupType.MyGroups);

  const handleClose = useCallback(() => {
    setSelectedGroup("");
    setActiveGroupTab(GroupType.MyGroups);
    onClose();
  }, [onClose]);

  const handleCreateContactsGroup = useCallback(() => {
    onAddContactToGroup(selectedGroup, talentId);
    handleClose();
  }, [onAddContactToGroup, selectedGroup, talentId, handleClose]);

  const handleGroupChange = useCallback(
    (groupTab: GroupType) => {
      if (groupTab === activeGroupTab) {
        return;
      }
      setSelectedGroup("");
      setActiveGroupTab(groupTab);
    },
    [activeGroupTab]
  );

  const groupsAvailableForSelectedTab = useMemo(() => {
    return allGroups.filter((group) =>
      activeGroupTab === GroupType.MyGroups
        ? group.isUserGroup
        : !group.isUserGroup
    );
  }, [activeGroupTab, allGroups]);

  const noAvailableGroupsForSelectedTab = useMemo(() => {
    return groupsAvailableForSelectedTab.length === 0;
  }, [groupsAvailableForSelectedTab.length]);

  return (
    <Drawer
      title={t("TalentPage##Add contact to a group")}
      placement="right"
      closable
      onClose={handleClose}
      open={isOpen}
      zIndex={1053}
      css={styles.drawerContainer}
      width={512}
      destroyOnClose
      closeIcon={
        <CloseIcon
          fill={theme.colors.black}
          height={13}
          width={14}
          title="Close"
          role="button"
        />
      }
    >
      <div
        css={[
          styles.activeAndInactiveGroupContainer,
          styles.mainTabContainer,
          styles.tabContainer,
        ]}
      >
        <button
          type="button"
          css={[
            styles.removeButtonStyles,
            activeGroupTab === GroupType.MyGroups && styles.activeGroup,
          ]}
          onClick={() => handleGroupChange(GroupType.MyGroups)}
        >
          My groups
        </button>
        <button
          type="button"
          css={[
            styles.removeButtonStyles,
            activeGroupTab === GroupType.OrgGroups && styles.activeGroup,
            styles.tabSpacing,
          ]}
          onClick={() => handleGroupChange(GroupType.OrgGroups)}
        >
          Org groups
        </button>
      </div>
      <div css={styles.createGroupBodyContainer}>
        {noAvailableGroupsForSelectedTab && (
          <Typography.Text css={styles.basicInputLabel}>
            {t("TalentPage##NoAvailableGroups")}
          </Typography.Text>
        )}
        {groupsAvailableForSelectedTab.map((group) => {
          const isGroupSelectedGroup = selectedGroup === group.id;
          return (
            <div
              key={group.id ?? ""}
              role="button"
              tabIndex={0}
              css={[
                styles.groupSelectBox,
                isGroupSelectedGroup && styles.selectedGroup,
              ]}
              onKeyDown={() => setSelectedGroup(group.id ?? "")}
              onClick={() => setSelectedGroup(group.id ?? "")}
            >
              {group.name}
              {isGroupSelectedGroup && <SelectedIcon />}
            </div>
          );
        })}
      </div>
      <div css={styles.createGroupFooter}>
        <div>
          <div />
          <Button
            css={styles.closeButton}
            tabIndex={0}
            aria-label="close"
            onClick={handleClose}
            type="text"
          >
            {t("Cancel")}
          </Button>
          <Button
            css={styles.manageButton}
            tabIndex={0}
            aria-label="ok"
            disabled={!selectedGroup}
            onClick={handleCreateContactsGroup}
            type="primary"
          >
            {t("Confirm")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default AddContactToGroup;
