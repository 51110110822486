import { Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "@songtradr/spa-common";
import CurrencyAndPrice from "src/components/currency-and-price";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import style from "../../../../styles";

interface IProps {
  costAvoidanceCurrency: string;
  costAvoidancePrice: number | string;
  initialMusicQuoteCurrency: string;
  initialMusicQuotePrice: number | string;
  earningsPrice: number | string;
  earningsCurrency: string;
  musicBudgetCurrency: string;
  musicBudgetPrice: number | string;
  totalAmountCurrency: string;
  totalAmountPrice: number | string;
  onMonetaryValueChange: (field: string, value: IMonetaryValue) => void;
}
const ProjectFeesRow = ({
  costAvoidancePrice,
  costAvoidanceCurrency,
  initialMusicQuoteCurrency,
  initialMusicQuotePrice,
  earningsCurrency,
  earningsPrice,
  musicBudgetCurrency,
  musicBudgetPrice,
  totalAmountCurrency,
  totalAmountPrice,
  onMonetaryValueChange,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();

  return (
    <>
      <div css={style.titleLabelContainer}>
        <Typography.Text css={style.titleLabel}>
          {t("ProjectsPage##ProjectFees")}
        </Typography.Text>
      </div>
      <div css={style.trackColumnContainer}>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <CurrencyAndPrice
            currency={totalAmountCurrency}
            price={totalAmountPrice}
            currencyFormItemName="totalAmountCurrency"
            priceFormItemName="totalAmountPrice"
            onChange={onMonetaryValueChange}
            label={t("ProjectsPage##Total Amount")}
            propertyName="totalAmount"
            validateRequiredFields={false}
          />
          <CurrencyAndPrice
            currency={musicBudgetCurrency}
            price={musicBudgetPrice}
            currencyFormItemName="musicBudgetCurrency"
            priceFormItemName="musicBudgetPrice"
            testId="musicBudget"
            onChange={onMonetaryValueChange}
            label={t("ProjectsPage##Clients music budget")}
            propertyName="musicBudget"
            validateRequiredFields={false}
          />

          <CurrencyAndPrice
            currency={earningsCurrency}
            price={earningsPrice}
            currencyFormItemName="earningsCurrency"
            priceFormItemName="earningsPrice"
            testId="estimatedFee"
            onChange={onMonetaryValueChange}
            label={t("ProjectsPage##Estimated Fees")}
            propertyName="earnings"
            validateRequiredFields={false}
          />
        </div>
        <div
          css={[
            isMobile ? style.mobileSectionContainer : style.clientContainer,
            style.lastOfSectionBottom,
          ]}
        >
          <div>
            <CurrencyAndPrice
              currency={initialMusicQuoteCurrency}
              price={initialMusicQuotePrice}
              currencyFormItemName="initialMusicQuoteCurrency"
              priceFormItemName="initialMusicQuotePrice"
              onChange={onMonetaryValueChange}
              label={t("ProjectsPage##Initial Quote")}
              propertyName="initialMusicQuote"
              validateRequiredFields={false}
            />
          </div>
          <div>
            <CurrencyAndPrice
              currency={costAvoidanceCurrency}
              price={costAvoidancePrice}
              currencyFormItemName="costAvoidanceCurrency"
              priceFormItemName="costAvoidancePrice"
              onChange={onMonetaryValueChange}
              label={t("ProjectsPage##Cost Avoidance")}
              propertyName="costAvoidance"
              validateRequiredFields={false}
            />
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

export default ProjectFeesRow;
