import axios from "axios";
import { IFinalTrackSection } from "src/pages/projects/project/interfaces";
import { deleteTrackUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const deleteTrack = async (
  accessToken: string,
  projectId: string,
  orgId: string,
  trackId: string
): Promise<IFinalTrackSection> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.delete(deleteTrackUrl(projectId, orgId, trackId), options);
};

export default deleteTrack;
