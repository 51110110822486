import produce from "immer";
import {
  IFeeSplitDetailState,
  INameLinkState,
  IProjectForm,
} from "src/pages/projects/project/interfaces";
import * as uuid from "uuid";
import { initialiseAttachments } from "./projectResponseMapper";
import { ProjectActions, ProjectAction } from "./types";

export const projectReducer = produce(
  (draft: IProjectForm, action: ProjectAction) => {
    switch (action.type) {
      case ProjectActions.STORE_PROJECT:
        // eslint-disable-next-line no-param-reassign
        draft = action.value.project;
        return draft;
      case ProjectActions.ADD_NAME_LINK_ROW:
        draft.music[action.value].push({
          name: "",
          link: "",
          isNew: true,
          key: uuid.v4(),
        } as INameLinkState);
        return draft;
      case ProjectActions.ADD_CONTACT_ROW:
        draft.contacts.push({
          name: "",
          email: "",
          contactType: "",
          isNew: true,
          key: uuid.v4(),
        });
        return draft;
      case ProjectActions.ADD_FEE_SPLIT_ROW:
        draft.music.finalTrack[action.value].push({
          name: "",
          email: "",
          split: "0",
          isNew: true,
          key: uuid.v4(),
        } as IFeeSplitDetailState);
        return draft;

      case ProjectActions.STORE_ATTACHMENTS:
        draft.briefAttachments = initialiseAttachments(
          action.value.attachments,
          "Brief"
        );
        draft.purchaseOrderAttachments = initialiseAttachments(
          action.value.attachments,
          "PurchaseOrder",
          true
        );
        draft.licenseAttachments = initialiseAttachments(
          action.value.attachments,
          "License",
          true
        );
        draft.invoiceAttachments = initialiseAttachments(
          action.value.attachments,
          "Invoice",
          true
        );
        draft.estimateAttachments = initialiseAttachments(
          action.value.attachments,
          "Estimate",
          true
        );
        draft.miscAttachments = initialiseAttachments(
          action.value.attachments,
          "Misc",
          true
        );
        draft.assetAttachments = initialiseAttachments(
          action.value.attachments,
          "Asset",
          true
        )
          .concat(initialiseAttachments(action.value.attachments, "MusicAsset"))
          .concat(initialiseAttachments(action.value.attachments, "VideoAsset"))
          .concat(initialiseAttachments(action.value.attachments, "Track"));
        draft.briefAttachments = initialiseAttachments(
          action.value.attachments,
          "Brief"
        );
        draft.testResultAttachments = initialiseAttachments(
          action.value.attachments,
          "TestResult",
          true
        );
        draft.attachments = action.value.attachments;
        draft.version = action.value.projectVersion;

        return draft;
      case ProjectActions.STORE_SERVICES:
        draft.services = action.value.services;
        draft.version = action.value.projectVersion;

        return draft;
      case ProjectActions.STORE_TRACKS:
        draft.tracks = action.value.tracks;
        draft.version = action.value.projectVersion;

        return draft;
      case ProjectActions.STORE_TERMS:
        draft.terms = action.value.terms;
        draft.version = action.value.projectVersion;

        return draft;
      case ProjectActions.STORE_PUBLISHING_TRIGGERED:
        draft.publishingTriggeredBy = action.value.publishingTriggeredBy;
        draft.version = action.value.projectVersion;

        return draft;
      default:
        throw new Error();
    }
  }
);

export const InitialProjectState = {
  version: 0,
  id: "",
  attachments: [],
  leads: [],
  leadsVersion: 0,
  purchaseOrderAttachments: [],
  estimateAttachments: [],
  licenseAttachments: [],
  invoiceAttachments: [],
  miscAttachments: [],
  assetAttachments: [],
  testResultAttachments: [],
  sequenceNo: 0,
  clients: [],
  clientsVersion: 0,
  contacts: [],
  contactsVersion: 0,
  music: {
    finalTrack: {
      masterFeeSplitsVersion: 0,
      masterFeeSplits: [],
      publisherFeeSplitsVersion: 0,
      publisherFeeSplits: [],
      writerFeeSplitsVersion: 0,
      writerFeeSplits: [],
      finalTrackLinksVersion: 0,
      finalTrackLinks: [],
      finalTrackAttachments: [],
    },
    musicAssetsVersion: 0,
    musicAssets: [],
    finalVideoVersion: 0,
    finalVideo: [],
    costAvoidance: { currency: "USD", value: 0 },
    initialMusicQuote: { currency: "USD", value: 0 },
  },
  terms: [],
  services: [],
  campaignName: "",
  crmDealId: "",
  tags: [],
  sharedWithClient: false,
  projectCloseDate: "",
  estimatedProjectClosedDate: "",
  internalCreativeTeamEngaged: false,
  jobListingDate: "",
  isExtension: false,
  relatedProjects: [],
  relatedProjectVersions: 0,
  client: {},
};
