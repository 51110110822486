import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

const sharedStyle = `
  height: 1.75rem;
  line-height: 1.75rem;
  padding-left: 0.5rem;
  width: fit-content;
  padding-right: 8px;
  white-space: nowrap;
  border-radius: 1rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  background: transparent;
  .circle {
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }
  .label {
    display: none;
    vertical-align: middle;
    line-height: normal;
    margin-bottom: 0.375rem;
    @media ${queries.medium} {
      display: inline-block;
    }  
  }`;

export default {
  open: css`
    ${sharedStyle}
    background-color: rgba(0, 204, 204, 0.1);
    td & {
      background-color: transparent;
      @media ${queries.medium} {
        background-color: rgba(0, 204, 204, 0.1);
      }
    }
    .circle {
      background-color: ${theme.colors.secondary.cyan};
    }
  `,
  onHold: css`
    ${sharedStyle}
    background-color: rgba(250, 150, 20, 0.1);
    td & {
      background-color: transparent;
      @media ${queries.medium} {
        background-color: rgba(250, 150, 20, 0.1);
      }
    }
    .circle {
      background-color: ${theme.colors.functional.orange};
    }
  `,
  inContract: css`
    ${sharedStyle}
    background-color: rgba(31, 72, 231, 0.1);
    td & {
      background-color: transparent;
      @media ${queries.medium} {
        background-color: rgba(31, 72, 231, 0.1);
      }
    }
    .circle {
      background-color: ${theme.colors.primary.blue};
    }
  `,
  complete: css`
    ${sharedStyle}
    background-color: rgba(204, 0, 0, 0.1);
    td & {
      background-color: transparent;
      @media ${queries.medium} {
        background-color: rgba(204, 0, 0, 0.1);
      }
    }
    .circle {
      background-color: ${theme.colors.functional.green};
    }
  `,
  closed: css`
    ${sharedStyle}
    background-color: rgba(204, 0, 0, 0.1);
    td & {
      background-color: transparent;
      @media ${queries.medium} {
        background-color: rgba(204, 0, 0, 0.1);
      }
    }
    .circle {
      background-color: ${theme.colors.functional.red};
    }
  `,
};
