import React, { ReactElement, useCallback, useMemo } from "react";
import { Tag, Typography } from "antd";
import { useTranslation } from "react-i18next/";
import { ITalentHubTypes } from "src/api/talent-hub/interfaces";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import {
  AllContactsFiltersValues,
  CheckedLabels,
  IContactsFilters,
} from "../interfaces";
import styles from "../styles";
import { getFilterValuesFromFilterOptions } from "../utils";

interface ISelectedContactsFiltersProps {
  numberOfContacts: number;
  selectedContactFilters: IContactsFilters;
  talentHubTypes: ITalentHubTypes;
  onRemoveSpecificFilterTag: (
    filterKey: string,
    checkedLabels: string[]
  ) => void;
}
const SelectedContactsFilters = ({
  selectedContactFilters,
  numberOfContacts,
  talentHubTypes,
  onRemoveSpecificFilterTag,
}: ISelectedContactsFiltersProps): ReactElement => {
  const { t } = useTranslation();

  const mappedContactFiltersObjectIntoStringArray: IOption[] = useMemo(
    () =>
      getFilterValuesFromFilterOptions(selectedContactFilters, talentHubTypes),
    [selectedContactFilters, talentHubTypes]
  );

  const getFilterKey = useCallback(
    (filterValue: string) => {
      const [key] =
        Object.entries(selectedContactFilters).find(([, value]) =>
          (value as string[]).includes(filterValue)
        ) || [];

      return key;
    },
    [selectedContactFilters]
  );

  const getCheckedLabels = useCallback(
    (label: CheckedLabels, filterValue: string) => {
      let key = getFilterKey(filterValue) ?? "";
      if (!key) {
        const isVocalistKey = [
          CheckedLabels.VocalistNo,
          CheckedLabels.VocalistYes,
        ].includes(label);
        const isServiceProviderKey = [
          CheckedLabels.ServiceProviderNo,
          CheckedLabels.ServiceProviderYes,
        ].includes(label);
        if (isVocalistKey) {
          key = AllContactsFiltersValues.Vocalist;
        }
        if (isServiceProviderKey) {
          key = AllContactsFiltersValues.ServiceProvider;
        }
      }

      return {
        checkedLabels: (selectedContactFilters[
          key as keyof IContactsFilters
        ] as string[]).filter((value: string) => String(value) !== filterValue),
        key,
      };
    },
    [getFilterKey, selectedContactFilters]
  );

  const handleTagCloseClick = useCallback(
    (label: CheckedLabels, filterValue: string) => {
      const { key, checkedLabels } = getCheckedLabels(label, filterValue);
      onRemoveSpecificFilterTag(key, checkedLabels);
    },
    [getCheckedLabels, onRemoveSpecificFilterTag]
  );

  return (
    <>
      <Typography css={styles.tabFavoritesText}>
        {`${numberOfContacts} ${t("TalentPage##contacts found")}:`}
      </Typography>
      {mappedContactFiltersObjectIntoStringArray.map(({ label, value }) => (
        <Tag
          css={styles.contactTag}
          key={`${value}${label}`}
          closable
          onClose={(e) => {
            e.preventDefault();
            handleTagCloseClick(label as CheckedLabels, value);
          }}
        >
          {label}
        </Tag>
      ))}
    </>
  );
};

export default SelectedContactsFilters;
