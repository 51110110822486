import { SerializedStyles } from "@emotion/react";
import { Button } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";

interface IProps {
  onClick: () => void;
  buttonText: string;
  className?: string;
  noLabelTranslation?: boolean;
  ariaLabel: string;
  wrapperStyles?: SerializedStyles;
}

const SecondaryModalButtonLink = ({
  onClick,
  buttonText,
  className,
  noLabelTranslation = false,
  ariaLabel = "",
  wrapperStyles,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Button
      aria-label={ariaLabel}
      className={className}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
      }}
      css={wrapperStyles === undefined ? styles.defaultStyles : wrapperStyles}
    >
      {noLabelTranslation ? buttonText : t(buttonText)}
    </Button>
  );
};

export default SecondaryModalButtonLink;
