import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";

const PlusIcon = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
  fill = theme.colors.secondary.purple,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      aria-labelledby="plus"
    >
      <title id="plus">{t("ProjectsPage##Add another")}</title>
      <g fill="none" fillRule="evenodd">
        <circle cx="19" cy="19" r="11" stroke={fill} strokeWidth="2" />
        <line
          x1="19"
          x2="19"
          y1="14"
          y2="24"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          x1="19"
          x2="19"
          y1="14"
          y2="24"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="2"
          transform="rotate(90 19 19)"
        />
      </g>
    </svg>
  );
};

export default PlusIcon;
