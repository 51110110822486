import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";

const Profile = ({
  height = theme.svgSize.nav,
  width = theme.svgSize.nav,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      aria-labelledby="profile-nav-link"
    >
      <title id="profile-nav-link">{t("Profile Page")}</title>
      <defs>
        <filter id="profile-a">
          <feColorMatrix in="SourceGraphic" />
        </filter>
        <filter id="profile-b">
          <feColorMatrix in="SourceGraphic" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" filter="url(#profile-a)">
        <g transform="translate(16 16)">
          <circle
            cx="12"
            cy="12"
            r="11.25"
            stroke="#8058FF"
            className="white"
            fill="white"
          />
          <g filter="url(#profile-b)">
            <g transform="translate(5.5 5.533)">
              <circle
                cx="6.5"
                cy="3.9"
                r="3.15"
                className={theme.colors.black}
                fill={theme.colors.black}
              />
              <path
                className={theme.colors.black}
                fill={theme.colors.black}
                d="M0,15.8753808 L0,15.8753808 L0,13.8666667 C0,11.4734328 1.8188432,9.53333333 4.0625,9.53333333 L8.9375,9.53333333 C11.1811568,9.53333333 13,11.4734328 13,13.8666667 C13,14.5228004 13,15.178934 13,15.8350677"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Profile;
