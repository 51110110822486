import { css } from "@emotion/react";
import theme from "src/theme";

const buttonStyle = `
margin-left: 0.5rem;
width:fit-content;
border-bottom: 1px solid;
border-radius: unset;
padding-right: 0;
padding-left: 0;
font-weight: 500;
&:hover {
  border-bottom: 1px solid;
  border-bottom-color: ${theme.colors.primary.blue};

}
`;

const tagContainerStyle = ` 
display: flex;
flex-wrap: wrap;
align-items: center;
gap:0.5rem;
.tag-input {
  width: 160px;
  height: 40px;
  vertical-align: top;
}

.ant-tag-close-icon svg {
  fill: ${theme.colors.primary.blue};
}`;
export default {
  tagsContainer: css`
    ${tagContainerStyle}
    > div {
      height: 38px !important;
    }
  `,
  addIconContainer: css`
    display: flex;
    align-items: center;
    width: 38px;
    height: 38px;
    color: #222;
    svg {
      color: #222 !important;
      width: 24px;
      height: 24px;
    }
  `,
  tagText: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: auto;
    overflow: hidden;
  `,
  addTagButton: css`
    ${buttonStyle}
  `,
  addTagButtonWithNoMargin: css`
    ${buttonStyle}
    margin-left:0 !important;
  `,
  tag: css`
    display: flex;
    align-content: center;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    color: ${theme.colors.secondary.blueOnBlack};
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.secondary.lightGrey};
    border-radius: 20px;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 130px !important;
    height: 56px;
    align-items: center;
  `,

  addTag: css`
    background: none;
    color: ${theme.colors.primary.blue};
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    border: none;
    align-self: center;
  `,
};
