import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { InputRef, Input, Tag, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import ControlButton from "../control-buttons/base-button";

interface IProps {
  selectedTags: string[];
  onUpdate: (value: string[]) => void;
}

const Tags = ({ selectedTags, onUpdate }: IProps): ReactElement => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const { t } = useTranslation();

  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = useCallback(
    (removedTag: string) => {
      const newTags = selectedTags.filter((tag) => tag !== removedTag);
      onUpdate(newTags);
    },
    [onUpdate, selectedTags]
  );

  const showInput = useCallback(() => {
    setInputVisible(true);
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    []
  );

  const handleInputConfirm = useCallback(() => {
    if (inputValue && selectedTags.indexOf(inputValue) === -1) {
      onUpdate([...selectedTags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  }, [inputValue, onUpdate, selectedTags]);

  const handleEditInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditInputValue(e.target.value);
    },
    []
  );

  const handleEditInputConfirm = useCallback(() => {
    const newTags = [...selectedTags];
    newTags[editInputIndex] = editInputValue;
    onUpdate(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  }, [editInputIndex, editInputValue, onUpdate, selectedTags]);

  return (
    <div css={styles.tagsContainer}>
      {selectedTags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={tag}
              css={styles.tag}
              className="tag-input"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        const isLongTag: boolean = tag.length > 20;
        const tagElem = (
          <Tag
            key={tag}
            css={styles.tag}
            closable
            onClose={() => handleClose(tag)}
          >
            <span
              css={styles.tagText}
              onDoubleClick={(e) => {
                setEditInputIndex(index);
                setEditInputValue(tag);
                e.preventDefault();
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          css={styles.tag}
          ref={inputRef}
          type="text"
          className="tag-input"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      <div css={styles.addIconContainer}>
        <ControlButton
          showTooltip
          tooltipColor="#222222"
          label={t("Add new")}
          data-testid="add-new-row-button"
          onClick={showInput}
        >
          <PlusOutlined />
        </ControlButton>
      </div>
    </div>
  );
};

export default Tags;
