import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ConfigProvider, Typography, Input, Checkbox, Spin } from "antd";
import { useTranslation } from "react-i18next";
import Button from "src/components/button";
import ProjectsEmpty from "src/app/assets/images/projectsempty.png";
import { getIsMobile, getIsTablet, useWindowSize } from "@songtradr/spa-common";
import Table from "src/components/table";
import { ProjectsPageSize } from "src/constants";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import BaseModal from "src/components/modals/base-modal";
import { ISelectLabel } from "src/components/modals/clear-filter/utils";
import DeleteProjectModalContent from "src/components/modals/delete-project";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import ClearFilter from "src/components/filter-controls";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import useAuth from "src/auth/use-auth";
import Select from "react-select";
import {
  ISavedUserColumn,
  ISavedUserFilter,
} from "src/api/user-filters/interfaces";
import { cloneDeep, debounce } from "lodash";
import ViewColumnsModalContent from "src/components/modals/view-columns-modal";
import { saveUserColumns } from "src/api/user-filters";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { IProjectsDataSource } from "src/components/table/interfaces";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import exportProjects from "src/api/projects/export-project";
import DisclaimerInfoIcon from "src/app/assets/icons/component-icons/disclaimer-info-icon";
import STLoadingLogo from "src/components/st-loading-logo";
import getColumns from "./table-data/get-columns";

import {
  DrawerTypes,
  FilterKeys,
  IActiveLabels,
  IDeleteProjectProps,
  IFiltersResponse,
  IFiltersSearch,
  IMyPresetFilters,
  IProjectProps,
  IProjectTableSort,
  ProjectSortType,
  SortOrder,
} from "./interfaces";
import ProjectsMobileControls from "./mobile-controls";
import {
  getApplyFiltersFromSavedFilters,
  getApplyLabelsFromSavedFilters,
  getApplyPresetFiltersFromSavedFilters,
  getRangeLabel,
  isDateSelectedAsFilter,
  mapCreativeAgencyLabelValues,
  mapEnumLabelValues,
  mapLabelValues,
  mapLeadsLabelValues,
  mapProjectsLabelValues,
  parseFilterKey,
} from "./utils";
import { ProjectStatus } from "../project/interfaces";
import style, { customStyles } from "./styles";

interface INoProjectsProps {
  isMobile: boolean;
  handleCreateProject: (name: string, status: string) => void;
}
interface IFilterObject {
  [key: string]: string[];
}

const NoProjects = ({
  isMobile,
  handleCreateProject,
}: INoProjectsProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={style.container}>
      <div css={style.contentContainer}>
        <div css={style.flexContainer}>
          {!isMobile && (
            <Typography.Title level={1} css={style.heading}>
              {t("Let's get started")}
            </Typography.Title>
          )}
          <img
            css={style.image}
            src={ProjectsEmpty}
            alt={t("ProjectsPage##Empty project")}
          />
          {isMobile && (
            <Typography.Title level={1} css={style.mobileHeading}>
              {t("Let's get started")}
            </Typography.Title>
          )}
        </div>
        <Typography.Text css={style.textContainer}>
          {t(
            "ProjectsPage##Nothing to see here, click create new project to start your music management"
          )}
        </Typography.Text>
        {!isMobile && (
          <Button
            ariaLabel="ProjectsPage##Create new project"
            css={style.button}
            onClick={() =>
              handleCreateProject("Untitled Project", ProjectStatus.NewOpen)
            }
          >
            ProjectsPage##Create new project
          </Button>
        )}
      </div>
    </div>
  );
};

const NoProjectsFound = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div css={style.noProjectSearchContainer}>
      <div css={style.noProjectSearchContentContainer}>
        <Typography.Text css={style.textHeader}>
          {t("ProjectsPage##No projects found")}
        </Typography.Text>
        <Typography.Text css={style.searchTextContainer}>
          {t(
            "ProjectsPage##Try removing some of the applied filters, or use a wider search term"
          )}
        </Typography.Text>
      </div>
    </div>
  );
};

interface IProps {
  loading: boolean;
  projects: Array<IProjectProps>;
  handleSort: (params: IProjectTableSort) => void;
  handlePageChange: (page: number) => void;
  sortOptions: IProjectTableSort;
  paginationOptions: { total: number; currentPage: number };
  mobileDrawerVisible: boolean;
  currentActiveDrawer: DrawerTypes;
  handleDrawerToggle: () => void;
  isModalOpen: boolean;
  onEditProjectClick: (id: string, openInNewTab?: boolean) => void;
  handleCreateProject: (name: string, status: string) => void;
  deleteProjectData?: IDeleteProjectProps;
  setIsModalOpen: (value: boolean) => void;
  handleDeleteProject: (id: string) => void;
  handleMobileDeleteProjectClick: () => void;
  searchText: string;
  updateSearchText: (value: string) => void;
  clearSearch?: () => void;
  debouncedSearchTerm: string;
  appliedFilters: IFiltersSearch;
  availableFilters: IFiltersResponse;
  activeLabels: IActiveLabels;
  handleApplyLabels: (labels: IActiveLabels) => void;
  handleApplyFilters: (
    clients: string[],
    globalBrands: string[],
    statuses: string[],
    leads: string[],
    contacts: string[],
    creativeAgencies: string[],
    holdingCompanies: string[],
    musicTypes: string[],
    projectTypes: string[],
    serviceTypes: string[],
    projectCloseDateFrom: string | null,
    projectCloseDateTo: string | null,
    jobListingDateFrom: string | null,
    jobListingDateTo: string | null,
    projectRegions: string[],
    officeLocations: string[],
    copyrightInfoProcessed: boolean | null,
    publishingRightsSigned: boolean | null,
    publishingTriggered: boolean | null
  ) => void;
  clearFilters: () => void;
  noFiltersSet: boolean;
  myPresetFilters: IMyPresetFilters;
  handleSetMyPresetFilters: (
    myOpenProjects: boolean,
    myAtContractProjects: boolean,
    myClosedProjects: boolean
  ) => void;
  updateSavedFilters: () => Promise<void>;
  savedFilters: ISavedUserFilter[] | null;
  savedColumns: ISavedUserColumn[] | null;
  isLoadingUserFilters: boolean;
  data: IProjectsDataSource[];
}

const ProjectListings = ({
  data,
  loading,
  projects,
  sortOptions,
  paginationOptions,
  mobileDrawerVisible,
  currentActiveDrawer,
  isModalOpen,
  deleteProjectData,
  searchText,
  debouncedSearchTerm,
  appliedFilters,
  availableFilters,
  activeLabels,
  noFiltersSet,
  myPresetFilters,
  savedFilters,
  savedColumns,
  isLoadingUserFilters,
  handleApplyLabels,
  handleApplyFilters,
  setIsModalOpen,
  handleCreateProject,
  handleDrawerToggle,
  handleSort,
  handleDeleteProject,
  handleMobileDeleteProjectClick,
  clearFilters,
  updateSearchText,
  clearSearch,
  handlePageChange,
  handleSetMyPresetFilters,
  updateSavedFilters,
  onEditProjectClick,
}: IProps): ReactElement => {
  const [paginationRange, setPaginationRange] = useState([
    1,
    paginationOptions.total > 0 && paginationOptions.total < ProjectsPageSize
      ? paginationOptions.total
      : ProjectsPageSize,
  ]);
  const { allProjectStatuses, allProjectTypes } = useGlobalStates();
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();
  const { t } = useTranslation();
  const {
    user,
    allUsers,
    fetchWrapper,
    organisationId,
    isSessionValid,
  } = useAuth();
  const [showColumnsModal, setShowColumnsModal] = useState(false);
  const [isLoadingProjectExport, setIsLoadingProjectExport] = useState(false);
  const handleSetShowColumnsModal = useCallback((visibility: boolean) => {
    setShowColumnsModal(visibility);
  }, []);

  const columns = getColumns(
    isTablet,
    handleSort,
    sortOptions,
    savedColumns,
    handleSetShowColumnsModal
  );

  const renderFilters = useCallback(() => {
    const filters: JSX.Element[] = [];

    const handleRemoveFilter = (label: ISelectLabel, filterKey: string) => {
      // The purpose of this function is to find the specific filter object
      // Then to find the specific filter within, remove it
      // And update then update the state store for labels and filters
      // Which then triggers the respective api call to refresh search results

      const clonedLabelState = cloneDeep(activeLabels);
      const activeLabelFilter = clonedLabelState[filterKey];
      const labelIndex = activeLabelFilter.findIndex(
        (x) => x.value === label.value
      );
      activeLabelFilter.splice(labelIndex, 1);
      clonedLabelState[filterKey] = activeLabelFilter;
      const clonedFilterState = cloneDeep(appliedFilters);
      if (
        ![
          "jobListingDate",
          "projectCloseDate",
          "copyrightInfoProcessed",
          "publishingRightsSigned",
          "publishingTriggered",
        ].includes(filterKey)
      ) {
        const activeFilter = clonedFilterState[filterKey as FilterKeys];
        const filterIndex = activeFilter.findIndex((x) => x === label.value);
        activeFilter.splice(filterIndex, 1);
        clonedFilterState[filterKey as FilterKeys] = activeFilter;
      } else {
        if (filterKey === "projectCloseDate") {
          clonedFilterState.projectCloseDateFrom = null;
          clonedFilterState.projectCloseDateTo = null;
        }
        if (filterKey === "jobListingDate") {
          clonedFilterState.jobListingDateFrom = null;
          clonedFilterState.jobListingDateTo = null;
        }
        if (filterKey === "copyrightInfoProcessed") {
          clonedFilterState.copyrightInfoProcessed = null;
        }
        if (filterKey === "publishingRightsSigned") {
          clonedFilterState.publishingRightsSigned = null;
        }
        if (filterKey === "publishingTriggered") {
          clonedFilterState.publishingTriggered = null;
        }
      }
      if (user) {
        if (!clonedFilterState.leads.includes(user.id)) {
          handleSetMyPresetFilters(false, false, false);
        } else {
          const myOpenProjects = clonedFilterState.statuses.includes(
            ProjectStatus.NewOpen
          );
          const myAtContractProjects = clonedFilterState.statuses.includes(
            ProjectStatus.InContractInvoiced
          );
          const myClosedProjects = clonedFilterState.statuses.includes(
            ProjectStatus.Complete
          );
          handleSetMyPresetFilters(
            myOpenProjects,
            myAtContractProjects,
            myClosedProjects
          );
        }
      }
      handleApplyLabels(clonedLabelState);
      handleApplyFilters(
        clonedFilterState.clients,
        clonedFilterState.globalBrands,
        clonedFilterState.statuses,
        clonedFilterState.leads,
        clonedFilterState.contacts,
        clonedFilterState.creativeAgencies,
        clonedFilterState.holdingCompanies,
        clonedFilterState.musicTypes,
        clonedFilterState.projectTypes,
        clonedFilterState.serviceTypes,
        clonedFilterState.projectCloseDateFrom,
        clonedFilterState.projectCloseDateTo,
        clonedFilterState.jobListingDateFrom,
        clonedFilterState.jobListingDateTo,
        clonedFilterState.projectRegions,
        clonedFilterState.officeLocations,
        clonedFilterState.copyrightInfoProcessed,
        clonedFilterState.publishingRightsSigned,
        clonedFilterState.publishingTriggered
      );
    };

    const handleClearFilters = () => {
      handleApplyLabels({
        clients: [],
        globalBrands: [],
        statuses: [],
        leads: [],
        contacts: [],
        creativeAgencies: [],
        holdingCompanies: [],
        musicTypes: [],
        projectTypes: [],
        serviceTypes: [],
        projectCloseDate: [],
        jobListingDate: [],
        projectRegions: [],
        officeLocations: [],
        copyrightInfoProcessed: [],
        publishingRightsSigned: [],
        publishingTriggered: [],
      });
      handleApplyFilters(
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        null,
        null,
        null,
        null,
        [],
        [],
        null,
        null,
        null
      );
      handleSetMyPresetFilters(false, false, false);
    };

    Object.entries(activeLabels).forEach((entry: [string, ISelectLabel[]]) => {
      const [key, value] = entry;
      const filterObject = value;

      filterObject.forEach((label) => {
        filters.push(
          <div
            css={style.badgeContainer}
            data-testid={`applied-filter-badge-${label.label}`}
            key={`applied-filter-badge-${label.label}`}
          >
            {parseFilterKey(key)}: {label.label}
            <button
              id={`remove-filter-${label.label}`}
              type="button"
              onClick={() => handleRemoveFilter(label, key)}
            >
              <CloseIcon width={8} height={8} title="Remove filter" />
            </button>
          </div>
        );
      });
    });

    return (
      <div css={style.filterBadgeContainer}>
        {filters}
        {filters.length > 0 && (
          <div css={style.controls}>
            <button
              type="button"
              css={style.clearText}
              onClick={handleClearFilters}
              data-testid="clear-all-filters-button"
            >
              Clear all
            </button>
          </div>
        )}
      </div>
    );
  }, [
    activeLabels,
    appliedFilters,
    handleApplyFilters,
    handleApplyLabels,
    handleSetMyPresetFilters,
    user,
  ]);

  useEffect(() => {
    if (paginationOptions.total > 0) {
      const min = (paginationOptions.currentPage - 1) * ProjectsPageSize + 1;
      const max =
        paginationOptions.total <
        ProjectsPageSize * paginationOptions.currentPage
          ? paginationOptions.total
          : ProjectsPageSize * paginationOptions.currentPage;
      setPaginationRange([min, max]);
    }
  }, [paginationOptions.total, paginationOptions.currentPage]);

  const onPageChange = (page: number) => {
    handlePageChange(page);
    if (page === 1) {
      setPaginationRange([
        1,
        paginationOptions.total < ProjectsPageSize
          ? paginationOptions.total
          : ProjectsPageSize,
      ]);
      return;
    }
    const min = (page - 1) * ProjectsPageSize + 1;
    const max =
      min + ProjectsPageSize < paginationOptions.total
        ? min + ProjectsPageSize - 1
        : paginationOptions.total;
    setPaginationRange([min, max]);
  };

  function itemRender(
    current: number,
    type: string,
    originalElement: ReactElement
  ) {
    if (type === "prev") {
      return <ArrowLeftOutlined />;
    }
    if (type === "next") {
      return <ArrowRightOutlined />;
    }
    return originalElement;
  }

  const showInput = searchText !== undefined && updateSearchText !== undefined;

  const getMyNewPresetStatus = useCallback(
    (checkedValue: boolean, projectStatus: ProjectStatus) => {
      const myNewPresetFilters = {
        myOpenProjects: myPresetFilters.myOpenProjects,
        myAtContractProjects: myPresetFilters.myAtContractProjects,
        myClosedProjects: myPresetFilters.myClosedProjects,
      };
      if (projectStatus === ProjectStatus.NewOpen) {
        myNewPresetFilters.myOpenProjects = checkedValue;
      } else if (projectStatus === ProjectStatus.InContractInvoiced) {
        myNewPresetFilters.myAtContractProjects = checkedValue;
      } else if (projectStatus === ProjectStatus.Complete) {
        myNewPresetFilters.myClosedProjects = checkedValue;
      }
      const isSetAtLeastOnePresetStatus =
        myNewPresetFilters.myOpenProjects ||
        myNewPresetFilters.myAtContractProjects ||
        myNewPresetFilters.myClosedProjects;

      return {
        myNewPresetFilters,
        isSetAtLeastOnePresetStatus,
      };
    },
    [
      myPresetFilters.myAtContractProjects,
      myPresetFilters.myClosedProjects,
      myPresetFilters.myOpenProjects,
    ]
  );

  const isStatusIncluded = useCallback(
    (statuses: string[], currentStatus: ProjectStatus) => {
      return Boolean(
        statuses.find(
          (status: string) => (status as ProjectStatus) === currentStatus
        )
      );
    },
    []
  );

  const handleExportAsCSV = debounce(async (): Promise<void> => {
    const isSession = await isSessionValid();
    if (!isSession) return;
    try {
      setIsLoadingProjectExport(true);
      const response = await fetchWrapper(
        exportProjects,
        organisationId,
        { by: ProjectSortType.lastUpdated, order: SortOrder.DESC },
        0,
        searchText,
        appliedFilters
      );

      const dataExport = new Blob([response], {
        type: "application/octet-stream",
      });

      saveAs(dataExport, `Projects export.csv`);
      SuccessToast(t("File downloading to your file storage"));
    } catch (error) {
      ErrorToast(
        `FileUploadErrorToast`,
        t("There was a problem downloading the file")
      );
    } finally {
      setIsLoadingProjectExport(false);
    }
  }, 500);

  const handleSetPresetProjectStatusFilter = useCallback(
    (event: CheckboxChangeEvent, projectStatus: ProjectStatus) => {
      const checkedValue = event.target.checked;
      const {
        myNewPresetFilters,
        isSetAtLeastOnePresetStatus,
      } = getMyNewPresetStatus(checkedValue, projectStatus);
      let statuses = [...appliedFilters.statuses];
      let leads = [...appliedFilters.leads];
      if (checkedValue && !statuses.includes(projectStatus)) {
        statuses.push(projectStatus);
      } else if (!checkedValue) {
        statuses = statuses.filter((status) => status !== projectStatus);
      }
      if (user) {
        if (isSetAtLeastOnePresetStatus && !leads.includes(user.id)) {
          leads.push(user.id);
          if (isStatusIncluded(statuses, ProjectStatus.NewOpen)) {
            myNewPresetFilters.myOpenProjects = true;
          }
          if (isStatusIncluded(statuses, ProjectStatus.InContractInvoiced)) {
            myNewPresetFilters.myAtContractProjects = true;
          }
          if (isStatusIncluded(statuses, ProjectStatus.Complete)) {
            myNewPresetFilters.myClosedProjects = true;
          }
        } else if (!isSetAtLeastOnePresetStatus) {
          leads = leads.filter((lead) => lead !== user.id);
          if (!isStatusIncluded(statuses, ProjectStatus.NewOpen)) {
            myNewPresetFilters.myOpenProjects = false;
          }
          if (!isStatusIncluded(statuses, ProjectStatus.InContractInvoiced)) {
            myNewPresetFilters.myAtContractProjects = false;
          }
          if (!isStatusIncluded(statuses, ProjectStatus.Complete)) {
            myNewPresetFilters.myClosedProjects = false;
          }
        }
      }
      handleSetMyPresetFilters(
        myNewPresetFilters.myOpenProjects,
        myNewPresetFilters.myAtContractProjects,
        myNewPresetFilters.myClosedProjects
      );
      handleApplyFilters(
        appliedFilters.clients,
        appliedFilters.globalBrands,
        statuses,
        leads,
        appliedFilters.contacts,
        appliedFilters.creativeAgencies,
        appliedFilters.holdingCompanies,
        appliedFilters.musicTypes,
        appliedFilters.projectTypes,
        appliedFilters.serviceTypes,
        appliedFilters.projectCloseDateFrom,
        appliedFilters.projectCloseDateTo,
        appliedFilters.jobListingDateFrom,
        appliedFilters.jobListingDateTo,
        appliedFilters.projectRegions,
        appliedFilters.officeLocations,
        appliedFilters.copyrightInfoProcessed,
        appliedFilters.publishingRightsSigned,
        appliedFilters.publishingTriggered
      );
      const projectCloseDate = getRangeLabel(
        appliedFilters.projectCloseDateFrom,
        appliedFilters.projectCloseDateTo
      );
      const jobListingDate = getRangeLabel(
        appliedFilters.jobListingDateFrom,
        appliedFilters.jobListingDateTo
      );

      const getAppliedLabelFromBooleanValue = (value: boolean | null) => {
        if (value === null) {
          return [];
        }

        return value
          ? [{ label: "Yes", value: "yes" }]
          : [{ label: "No", value: "no" }];
      };

      handleApplyLabels({
        clients: mapLabelValues(
          availableFilters.clients,
          appliedFilters.clients
        ),
        globalBrands: mapLabelValues(
          availableFilters.globalBrands,
          appliedFilters.globalBrands
        ),
        statuses: mapProjectsLabelValues(statuses, allProjectStatuses),
        leads: mapLeadsLabelValues(availableFilters.leads, leads, allUsers),
        contacts: mapLabelValues(
          availableFilters.contacts,
          appliedFilters.contacts
        ),
        creativeAgencies: mapCreativeAgencyLabelValues(
          availableFilters.creativeAgencies,
          appliedFilters.creativeAgencies
        ),
        holdingCompanies: mapLabelValues(
          availableFilters.holdingCompanies,
          appliedFilters.holdingCompanies
        ),
        musicTypes: mapEnumLabelValues(appliedFilters.musicTypes),
        projectTypes: mapProjectsLabelValues(
          appliedFilters.projectTypes,
          allProjectTypes
        ),
        serviceTypes: mapEnumLabelValues(appliedFilters.serviceTypes),
        projectCloseDate,
        jobListingDate,
        projectRegions: mapEnumLabelValues(appliedFilters.projectRegions),
        officeLocations: mapEnumLabelValues(appliedFilters.officeLocations),
        copyrightInfoProcessed: getAppliedLabelFromBooleanValue(
          appliedFilters.copyrightInfoProcessed
        ),
        publishingRightsSigned: getAppliedLabelFromBooleanValue(
          appliedFilters.publishingRightsSigned
        ),
        publishingTriggered: getAppliedLabelFromBooleanValue(
          appliedFilters.publishingTriggered
        ),
      });
    },
    [
      getMyNewPresetStatus,
      appliedFilters.statuses,
      appliedFilters.leads,
      appliedFilters.clients,
      appliedFilters.globalBrands,
      appliedFilters.contacts,
      appliedFilters.creativeAgencies,
      appliedFilters.holdingCompanies,
      appliedFilters.musicTypes,
      appliedFilters.projectTypes,
      appliedFilters.serviceTypes,
      appliedFilters.projectCloseDateFrom,
      appliedFilters.projectCloseDateTo,
      appliedFilters.jobListingDateFrom,
      appliedFilters.jobListingDateTo,
      appliedFilters.projectRegions,
      appliedFilters.officeLocations,
      appliedFilters.copyrightInfoProcessed,
      appliedFilters.publishingRightsSigned,
      appliedFilters.publishingTriggered,
      user,
      handleSetMyPresetFilters,
      handleApplyFilters,
      handleApplyLabels,
      availableFilters.clients,
      availableFilters.globalBrands,
      availableFilters.leads,
      availableFilters.contacts,
      availableFilters.creativeAgencies,
      availableFilters.holdingCompanies,
      allProjectStatuses,
      allUsers,
      allProjectTypes,
      isStatusIncluded,
    ]
  );

  const getNumberOfActiveFilters = useCallback(() => {
    return (
      appliedFilters.clients.length +
      appliedFilters.leads.length +
      appliedFilters.statuses.length +
      appliedFilters.globalBrands.length +
      appliedFilters.contacts.length +
      appliedFilters.creativeAgencies.length +
      appliedFilters.holdingCompanies.length +
      appliedFilters.musicTypes.length +
      appliedFilters.projectTypes.length +
      appliedFilters.serviceTypes.length +
      isDateSelectedAsFilter(
        appliedFilters.projectCloseDateFrom,
        appliedFilters.projectCloseDateTo
      ) +
      isDateSelectedAsFilter(
        appliedFilters.jobListingDateFrom,
        appliedFilters.jobListingDateTo
      ) +
      appliedFilters.projectRegions.length +
      appliedFilters.officeLocations.length +
      (appliedFilters.copyrightInfoProcessed !== null ? 1 : 0) +
      (appliedFilters.publishingRightsSigned !== null ? 1 : 0) +
      (appliedFilters.publishingTriggered !== null ? 1 : 0)
    );
  }, [
    appliedFilters.clients.length,
    appliedFilters.leads.length,
    appliedFilters.statuses.length,
    appliedFilters.globalBrands.length,
    appliedFilters.contacts.length,
    appliedFilters.creativeAgencies.length,
    appliedFilters.holdingCompanies.length,
    appliedFilters.musicTypes.length,
    appliedFilters.projectTypes.length,
    appliedFilters.serviceTypes.length,
    appliedFilters.projectCloseDateFrom,
    appliedFilters.projectCloseDateTo,
    appliedFilters.jobListingDateFrom,
    appliedFilters.jobListingDateTo,
    appliedFilters.projectRegions.length,
    appliedFilters.officeLocations.length,
    appliedFilters.copyrightInfoProcessed,
    appliedFilters.publishingRightsSigned,
    appliedFilters.publishingTriggered,
  ]);

  const handleSelectSavedFilters = useCallback(
    (selected: ISelectLabel) => {
      const selectedFilter = (savedFilters ?? []).find(
        (filter) => filter.name === selected.value
      );

      if (selectedFilter) {
        handlePageChange(1);
        const applyFilters = getApplyFiltersFromSavedFilters(selectedFilter);
        handleApplyFilters(...applyFilters);
        handleApplyLabels(
          getApplyLabelsFromSavedFilters(
            selectedFilter,
            allProjectStatuses,
            allProjectTypes
          )
        );

        if (user) {
          const applyPresetFilters = getApplyPresetFiltersFromSavedFilters(
            applyFilters,
            user.id
          );
          handleSetMyPresetFilters(...applyPresetFilters);
        }
      }
    },
    [
      savedFilters,
      handlePageChange,
      handleApplyFilters,
      handleApplyLabels,
      allProjectStatuses,
      allProjectTypes,
      user,
      handleSetMyPresetFilters,
    ]
  );

  const savedFiltersOptions = useMemo((): ISelectLabel[] => {
    return (savedFilters ?? []).map(({ name }) => ({
      label: name,
      value: name,
    }));
  }, [savedFilters]);

  const currentValueForSavedFilters = useMemo((): ISelectLabel => {
    const label = `Custom filters (${(savedFilters ?? []).length})`;
    return {
      label,
      value: label,
    };
  }, [savedFilters]);

  const handleSaveViewColumnsChanges = useCallback(
    async (selectedColumns: ISavedUserColumn[]) => {
      try {
        await fetchWrapper(saveUserColumns, organisationId, selectedColumns);
        await updateSavedFilters();
        handleSetShowColumnsModal(false);
      } catch (error) {
        ErrorToast(
          "error-saving-columns",
          t("ViewColumnsModal##SaveError##message"),
          t("ViewColumnsModal##SaveError##description")
        );
      }
    },
    [
      fetchWrapper,
      organisationId,
      handleSetShowColumnsModal,
      t,
      updateSavedFilters,
    ]
  );

  return (
    <div>
      {projects.length === 0 &&
      debouncedSearchTerm === "" &&
      !loading &&
      noFiltersSet ? (
        <NoProjects
          isMobile={isMobile}
          handleCreateProject={handleCreateProject}
        />
      ) : (
        <div
          css={[
            style.tableContainer,
            isLoadingProjectExport && style.reportSpiner,
          ]}
        >
          <div css={style.row}>
            {showInput ? (
              <div css={style.inputAndPresetFiltersContainer}>
                <div css={style.inputContainer}>
                  <Input
                    data-testid="project-search-input"
                    autoFocus
                    css={style.input}
                    value={searchText}
                    placeholder="Search projects"
                    onChange={(e) => {
                      if (updateSearchText) updateSearchText(e.target.value);
                    }}
                    addonAfter={
                      <button
                        css={style.iconButton}
                        type="button"
                        onClick={clearSearch}
                      >
                        <CloseIcon
                          css={style.inputClear}
                          fill={theme.colors.primary.blue}
                          height={11}
                          width={11}
                          title="Close"
                          role="button"
                        />
                      </button>
                    }
                  />
                  <Typography.Text css={style.disclaimerLabel}>
                    <DisclaimerInfoIcon />
                    {t("ProjectsPage##AddQuoteMarksAroundYourQuery")}
                  </Typography.Text>
                </div>
                <Select
                  className="Select"
                  styles={customStyles}
                  css={style.selectContainer}
                  onChange={handleSelectSavedFilters}
                  options={savedFiltersOptions}
                  isMulti={false}
                  value={currentValueForSavedFilters}
                  isSearchable={false}
                />
                <Button
                  ariaLabel={t("ProjectsPage##Export as CSV")}
                  noLabelTranslation
                  css={style.exportButton}
                  onClick={handleExportAsCSV}
                >
                  {t("ProjectsPage##Export as CSV")}
                </Button>
                <div css={style.presetCheckboxFiltersContainer}>
                  <Checkbox
                    checked={myPresetFilters.myOpenProjects}
                    onChange={(event) =>
                      handleSetPresetProjectStatusFilter(
                        event,
                        ProjectStatus.NewOpen
                      )
                    }
                  >
                    {t("PresetFilters##MyOpenProjects")}
                  </Checkbox>
                  <Checkbox
                    checked={myPresetFilters.myAtContractProjects}
                    onChange={(event) =>
                      handleSetPresetProjectStatusFilter(
                        event,
                        ProjectStatus.InContractInvoiced
                      )
                    }
                  >
                    {t("PresetFilters##MyAtContractProjects")}
                  </Checkbox>
                  <Checkbox
                    checked={myPresetFilters.myClosedProjects}
                    onChange={(event) =>
                      handleSetPresetProjectStatusFilter(
                        event,
                        ProjectStatus.Complete
                      )
                    }
                  >
                    {t("PresetFilters##MyClosedProjects")}
                  </Checkbox>
                </div>
              </div>
            ) : null}
            <ClearFilter
              showModal={showFilterModal}
              clearFilters={clearFilters}
              setShowModal={setShowFilterModal}
              handleApplyFilters={handleApplyFilters}
              handleApplyLabels={handleApplyLabels}
              activeFilters={getNumberOfActiveFilters()}
              availableFilters={availableFilters}
              appliedFilters={appliedFilters}
              handleSetMyPresetFilters={handleSetMyPresetFilters}
              currentUserId={user?.id ?? ""}
              allUsers={allUsers}
              savedFilters={savedFilters}
              updateSavedFilters={updateSavedFilters}
            />
          </div>
          {renderFilters()}
          <ConfigProvider
            renderEmpty={() => {
              return !loading && !isLoadingUserFilters ? (
                <NoProjectsFound />
              ) : (
                <></>
              );
            }}
          >
            <Table
              columns={columns}
              dataSource={data}
              loading={{
                indicator: <STLoadingLogo />,
                spinning: loading || isLoadingUserFilters,
              }}
              css={style.projectsTable}
              onRow={(row) => {
                return {
                  onClick: () => {
                    onEditProjectClick(row.key);
                  },
                  onContextMenu: () => {
                    onEditProjectClick(row.key, true);
                  },
                };
              }}
              pagination={{
                pageSize: ProjectsPageSize,
                total: paginationOptions.total,
                current: paginationOptions.currentPage,
                position: ["bottomCenter"],
                onChange: (page) => onPageChange(page),
                showSizeChanger: false,
                showLessItems: true,
                itemRender,
              }}
              scroll={{ x: true }}
            />
          </ConfigProvider>
          {projects.length === 0 && !loading ? null : (
            <div
              css={style.paginationCount}
            >{`Showing ${paginationRange[0]}-${paginationRange[1]} of ${paginationOptions.total} results`}</div>
          )}
          <BaseModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            content={
              <DeleteProjectModalContent
                data={deleteProjectData}
                onClose={() => setIsModalOpen(false)}
                handleDeleteProject={handleDeleteProject}
              />
            }
            footer={null}
          />
          <BaseModal
            open={showColumnsModal}
            onClose={() => handleSetShowColumnsModal(false)}
            content={
              <ViewColumnsModalContent
                columnsForTable="projects"
                selectedColumns={savedColumns}
                onClose={() => handleSetShowColumnsModal(false)}
                onSaveChanges={handleSaveViewColumnsChanges}
              />
            }
            footer={null}
          />
        </div>
      )}
      {(isMobile || isTablet) && (
        <ProjectsMobileControls
          mobileDrawerVisible={mobileDrawerVisible}
          currentActiveDrawer={currentActiveDrawer}
          isMobile={isMobile}
          handleDrawerToggle={handleDrawerToggle}
          handleSort={handleSort}
          sortOptions={sortOptions}
          handleDeleteProject={handleDeleteProject}
          handleMobileDeleteProjectClick={handleMobileDeleteProjectClick}
          handleMobileEditProjectClick={onEditProjectClick}
          handleCreateProject={handleCreateProject}
        />
      )}
      {isLoadingProjectExport && (
        <div css={style.loadingContainer}>
          <Spin size="default" />
        </div>
      )}
    </div>
  );
};

export default ProjectListings;
