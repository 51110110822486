import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

export default {
  heading: css`
    display: block;
    text-align: center;
    font-family: ${theme.fonts.sofiaPro};
    margin-top: auto;
    padding-bottom: 0.3125rem;
    padding-top: 2rem;
    font-size: 2rem !important;
    @media ${queries.medium} {
      font-size: 2.25rem !important;
      margin-bottom: 0.9375rem;
    }

    @media ${queries.large} {
      margin-bottom: 0 !important;
      font-size: 2.75rem !important;
    }
  `,
  textContainer: css`
    display: block;
    padding-top: 0.625rem;
    padding-bottom: 1.25rem;
    text-align: center;
  `,
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3.125rem;
  `,
  contentContainer: css`
    width: 300px;
    align-items: center;
    @media ${queries.medium} {
      width: 410px;
    }
  `,
  image: css`
    height: 180px;
    width: 180px;
    display: block;
    margin: auto;
    align-items: center;
    @media ${queries.medium} {
      width: 230px;
      height: 230px;
    }
  `,
  button: css`
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;
    :focus {
      border-radius: 0;
    }
    width: 100%;

    @media ${queries.medium} {
      position: initial;
      border-radius: 0.3125rem;
      padding: 2.4px 16px;
      display: block;
      margin: auto;
      align-items: center;
      width: auto;
      :focus {
        border-radius: 0.3125rem;
      }
    }
  `,
};
