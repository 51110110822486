import axios from "axios";
import { IOrganisationInvite } from "../../../interfaces/organisation";
import { getJsonContentHeader } from "../../headers";
import { getOrganisationInviteUrl } from "../../organisation-invites-url-helper";

const getOrganisationInvite = (
  organisationId: string,
  organisationInviteId: string
): Promise<IOrganisationInvite> => {
  const options = {
    headers: { ...getJsonContentHeader() },
  };
  return axios
    .get(
      getOrganisationInviteUrl(organisationId, organisationInviteId),
      options
    )
    .then(({ data }) => data as IOrganisationInvite);
};

export default getOrganisationInvite;
