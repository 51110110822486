import axios from "axios";
import {
  IProjectDetailsUpdateRequest,
  IProjectUpdateResponse,
} from "src/pages/projects/project/interfaces";
import { updateProjectUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const updateProject = async (
  accessToken: string,
  projectId: string,
  patchRequest: IProjectDetailsUpdateRequest
): Promise<IProjectUpdateResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const response = await axios.patch(
    updateProjectUrl(projectId),
    patchRequest,
    options
  );
  return response.data as IProjectUpdateResponse;
};

export default updateProject;
