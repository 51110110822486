import { css } from "@emotion/react";
import theme from "src/theme";

const sharedStyles = `
    height: 3rem;
    display: flex;
    align-items: center;
    color: ${theme.colors.white} !important;
    .iconStroke {
      stroke: ${theme.colors.white};
      stroke-width: 1.5;
    }
`;

const sharedLinkStyles = `
  ${sharedStyles}
  margin-right: auto;
  .iconFill {
    fill: ${theme.colors.white};
  }
`;

export default {
  main: css`
    ${sharedStyles}
    background-color: ${theme.colors.secondary.black};
    color: ${theme.colors.secondary.cyan};
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 100;
  `,
  navLink: css`
    ${sharedLinkStyles}
  `,
  profileNavLink: css`
    ${sharedLinkStyles}
    position: absolute !important;
    bottom: 0.5rem !important;
  `,
  dashboardNavLink: css`
    ${sharedStyles}
    margin-right: auto;
    width: 3rem;
    background-color: ${theme.colors.primary.blue} !important;
    .dashboardIcon,
    .iconFill {
      fill: ${theme.colors.white};
    }
    :active {
      background-color: ${theme.colors.primary.blue} !important;
      .dashboardIcon {
        fill: ${theme.colors.secondary.black} !important;
      }
    }
  `,
  burgerButton: css`
    ${sharedStyles}
    margin-left: auto;
    padding: 1rem;
  `,
  title: css`
    margin-bottom: 0 !important;
    font-size: 1rem !important;
    font-weight: bold;
    color: ${theme.colors.secondary.cyan} !important;
  `,
  drawer: css`
    z-index: 1052;
    .ant-drawer-body {
      background-color: ${theme.colors.secondary.black};
      color: ${theme.colors.white};
    }

    .ant-drawer-close {
      color: ${theme.colors.white};
    }

    ul {
      color: ${theme.colors.white};
      list-style-type: none;
      padding-left: 0;
      margin-top: 1.875rem;

      li a {
        color: ${theme.colors.white};
        text-decoration: none;
        font-size: 0.9375rem;
        font-weight: bold;
      }
    }
  `,
};
