import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Typography } from "antd";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common/lib/utils";
import {
  IContactReferences,
  IFinalTrackSection,
  MusicTypes,
} from "src/pages/projects/project/interfaces";
import { startCase } from "lodash";
import Select from "react-select";
import mainStyle from "src/pages/projects/project/styles";
import { FormInstance } from "antd/lib/form";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import { customStyles } from "../../../details/styles";
import { IOption } from "../../../account-information/types";
import TalentHubInput from "../../../talent-hub-input";

interface IProps {
  onSelectedTrackChange: (track?: IFinalTrackSection) => void;
  track?: IFinalTrackSection;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  talentsResponse?: IContactsResponse;
  areTalentsLoading: boolean;
  agenciesAndContacts: IAgencyWithContactsResults[];
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
}

enum UpdateType {
  Artists = "artists",
  Writers = "writers",
}

const FinalTrackDetails = ({
  track,
  form,
  talentsResponse,
  areTalentsLoading,
  agenciesAndContacts,
  onSelectedTrackChange,
  onUpdateNarrowSearchText,
  onScrollTalents,
  onResetNarrowSearch,
}: IProps): ReactElement => {
  useWindowSize();
  const isMobile = getIsMobile();
  const { t } = useTranslation();
  const talentHubArtistsRef = useRef<HTMLDivElement>(null);
  const talentHubWritersRef = useRef<HTMLDivElement>(null);

  const getContacts = useCallback((talents?: IContactReferences[]) => {
    return (talents ?? [])?.map((talent) => {
      return {
        name: talent?.name ?? "",
        id: talent?.id ?? "",
        type: talent.type,
        email: talent?.email ?? "",
        agencyWritingPartnership: talent.agencyWritingPartnership ?? "",
        groupByAgency: talent?.groupByAgency ?? false,
      };
    });
  }, []);

  const [selectedArtists, setSelectedArtists] = useState<IContactReferences[]>(
    getContacts(track?.artistContacts)
  );

  const [selectedWriters, setSelectedWriters] = useState<IContactReferences[]>(
    getContacts(track?.writerContacts)
  );

  useEffect(() => {
    setSelectedArtists(getContacts(track?.artistContacts));
  }, [getContacts, track?.artistContacts]);

  useEffect(() => {
    setSelectedWriters(getContacts(track?.writerContacts));
  }, [getContacts, track?.writerContacts]);

  const musicTypeOptions = Object.values(MusicTypes)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => ({
      value: key,
      label: startCase(key),
    }));

  const handleUpdateTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (track) {
      form.setFieldsValue({ title: value });
      onSelectedTrackChange({ ...track, title: value });
    }
  };
  const handleMusicTypeChange = (data: { label: string; value: string }) => {
    if (track) {
      onSelectedTrackChange({
        ...track,
        musicType: data?.value ? data.value : undefined,
      });
    }
  };

  const selectedMusicType = useMemo(
    () => musicTypeOptions.find((option) => option.value === track?.musicType),
    [musicTypeOptions, track]
  );

  const initialFormValues = useMemo(
    () => ({
      title: track?.title ?? "",
      musicType: track?.musicType,
    }),
    [track?.musicType, track?.title]
  );

  useEffect(() => {
    if (track) {
      form.setFieldsValue({
        title: track.title,
        musicType: selectedMusicType,
      });
    }
  }, [form, selectedMusicType, track]);

  const handleSelectedTalentsChange = useCallback(
    (selectedValues: IContactReferences[], updateType: UpdateType) => {
      if (selectedValues && track) {
        const isWriter = updateType === UpdateType.Writers;

        onSelectedTrackChange({
          ...track,
          ...(isWriter
            ? { writerContacts: selectedValues }
            : { artistContacts: selectedValues }),
        });
      }
    },
    [onSelectedTrackChange, track]
  );

  return (
    <div css={mainStyle.sectionFormContainer}>
      <Form
        form={form}
        initialValues={initialFormValues}
        validateTrigger="onChange"
        scrollToFirstError
      >
        <Typography.Text css={mainStyle.trackTitleLabel}>
          {t("ProjectsPage##musicSection##Track Details")}
        </Typography.Text>
        <div
          css={
            isMobile
              ? mainStyle.mobileSectionContainer
              : mainStyle.clientContainer
          }
        >
          <div>
            <div css={mainStyle.basicInputContainer}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##musicSection##Track title")}
              </Typography.Text>
            </div>
            <Form.Item
              name="title"
              css={mainStyle.formItem}
              rules={[
                () => ({
                  validator(_rule, value?: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error("This is a required field")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                id="final-track-title"
                aria-label={t("ProjectsPage##musicSection##Track title")}
                onBlur={handleUpdateTitle}
                name="title"
                key={track && track.title}
              />
            </Form.Item>
          </div>
          <div ref={talentHubArtistsRef}>
            <div css={mainStyle.basicInputContainer}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##musicSection##Artist/Performer")}
              </Typography.Text>
            </div>
            <TalentHubInput
              id="final-track-artist"
              talenthubwidth={talentHubArtistsRef.current?.clientWidth}
              selectedTalents={selectedArtists}
              areAgenciesIntegrated
              talentsResponse={talentsResponse}
              areTalentsLoading={areTalentsLoading}
              agenciesAndContacts={agenciesAndContacts}
              onScrollTalents={onScrollTalents}
              onSelectedTalentsChange={(talents) =>
                handleSelectedTalentsChange(talents, UpdateType.Artists)
              }
              onUpdateNarrowSearchText={onUpdateNarrowSearchText}
              onResetNarrowSearch={onResetNarrowSearch}
            />
          </div>
          <div ref={talentHubWritersRef}>
            <div css={mainStyle.basicInputContainer}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##musicSection##Writer")}
              </Typography.Text>
            </div>
            <TalentHubInput
              id="final-track-writer"
              talenthubwidth={talentHubWritersRef.current?.clientWidth}
              selectedTalents={selectedWriters}
              areAgenciesIntegrated
              talentsResponse={talentsResponse}
              agenciesAndContacts={agenciesAndContacts}
              areTalentsLoading={areTalentsLoading}
              onScrollTalents={onScrollTalents}
              onSelectedTalentsChange={(talents) =>
                handleSelectedTalentsChange(talents, UpdateType.Writers)
              }
              onUpdateNarrowSearchText={onUpdateNarrowSearchText}
              onResetNarrowSearch={onResetNarrowSearch}
            />
          </div>
          <div>
            <div css={mainStyle.basicInputContainer}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##musicSection##Final music type")}
              </Typography.Text>
            </div>
            <Form.Item
              css={mainStyle.formItem}
              name="musicType"
              rules={[
                () => ({
                  validator(_rule, value?: IOption) {
                    if (!value) {
                      return Promise.reject(
                        new Error("This is a required field")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                name="musicType"
                placeholder=""
                isClearable
                required
                id="musicType"
                aria-label={t("ProjectsPage##musicSection##Final music type")}
                isSearchable
                onChange={handleMusicTypeChange}
                options={musicTypeOptions}
                styles={customStyles}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FinalTrackDetails;
