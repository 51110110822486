import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  heading: css`
    margin-top: 2rem;
    margin-left: 1rem;
    margin-bottom: 4.344rem;

    h1.ant-typography {
      font-family: ${theme.fonts.sofiaPro};
      margin-bottom: 0 !important;
    }

    div.ant-typography {
      font-family: ${theme.fonts.sofiaPro};
    }

    @media ${queries.medium} {
      margin-left: 6.25rem;
    }
  `,
  subHeading: css`
    font-size: 1rem;
  `,
  cardContainer: css`
    display: flex;
    flex-wrap: wrap;
    padding-left: 1rem;
    padding-right: 1rem;

    .card {
      margin-right: 0;
    }

    @media ${queries.medium} {
      padding-left: 3.5rem;

      .card {
        margin-right: 1.5rem;
      }
    }
  `,
};
