import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "./interfaces";

const ErrorIcon = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      aria-labelledby="error-icon"
    >
      <title id="error-icon">{t("notificationIcons##error")}</title>
      <g fill="none" fillRule="evenodd">
        <circle cx="8" cy="8" r="8" fill="#C00" />
        <line
          x1="8"
          x2="8"
          y1="4.182"
          y2="8.682"
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <circle cx="8" cy="11.496" r="1" fill="#FFF" />
      </g>
    </svg>
  );
};

export default ErrorIcon;
