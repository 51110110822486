import React from "react";
import styles from "./styles";

const Logo = (): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="Logo"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      css={styles.logo}
      viewBox="0 0 2308.2 808.3"
    >
      <path
        d="M611.7,455.3h43.6c0.4,19.5,18.7,37.1,46.1,37.1c25.2,0,41.4-15.5,41.4-35c0-17.3-14.8-27.4-35.7-33.2l-31-8.6
	c-53.3-13.7-62.7-45.8-62.7-67.4c0-44,38.6-73.9,84.3-73.9c45.4,0,81.1,28.1,81.1,74.6h-43.6c0-20.5-15.5-35.3-38.6-35.3
	c-21.3,0-38.9,14.4-38.9,33.9c0,6.8,2.2,21.3,30.6,28.8L718,385c38.9,10.8,68.8,31,68.8,70.6c0,45.4-37.8,75.7-86.5,75.7
	C647.4,531.3,611.7,497.4,611.7,455.3z M800.7,442.7c0-53.3,41.4-88.3,89.4-88.3s89.7,35,89.7,88.3c0,53.3-41.8,88.6-89.7,88.6
	S800.7,496,800.7,442.7z M937.7,442.7c0-28.8-21.6-49.4-47.6-49.4s-47.2,20.5-47.2,49.4c0,29.5,21.3,49.7,47.2,49.7
	S937.7,472.2,937.7,442.7z M1162.1,432.2v94.4h-41.8v-91.5c0-27-16.2-41.4-35-41.4c-19.1,0-42.9,11.2-42.9,43.6v89.4h-41.8V358.3
	h41.8V385c8.6-21.3,35-31,52.2-31C1138,354,1162.1,383.2,1162.1,432.2z M1321.7,358.3h41.8v146.3c0,75.7-36.8,108.5-94.4,108.5
	c-34.6,0-64.9-18.7-79.6-49l34.6-15.9c8.3,16.2,26.7,27,45,27c32.4,0,52.6-18.4,52.6-66.7v-4c-11.9,17.3-35.3,26.3-55.9,26.3
	c-44.3,0-82.9-34.9-82.9-88.6c0-54,38.2-88.3,82.5-88.3c21.6,0,44.3,9,56.2,26.3V358.3z M1321,441.9c0-29.5-24.5-49-48.6-49
	c-25.9,0-47.2,20.9-47.2,49c0,28.1,21.3,50.1,47.2,50.1C1297.9,492,1321,471.5,1321,441.9z M1627.1,359.8l-8.3,39.6
	c-9.7-5-18.4-6.1-24.5-6.1c-27.4,0-42.5,27.4-42.5,74.6v58.7h-42.2V358.3h41.8v36c9-28.1,27.4-40.4,48.3-40.4
	C1609.8,354,1620.6,356.2,1627.1,359.8z M1769,358.3h41.8v168.3H1769v-26.3c-11.2,18.4-33.5,30.6-57.7,30.6
	c-45,0-81.1-36.4-81.1-88.6c0-50.8,34.6-88.3,82.2-88.3c23.1,0,45.4,10.1,56.6,27.7V358.3z M1768.3,442.3
	c0-28.8-23.8-49.4-48.6-49.4c-27,0-47.6,22.3-47.6,49.4c0,27,20.5,49.7,47.9,49.7C1746,492,1768.3,470.4,1768.3,442.3z
	 M1970.4,281.7h41.8v244.9h-41.8v-22.3c-11.5,17.7-35.3,26.7-55.8,26.7c-44.3,0-82.9-34.9-82.9-88.6c0-54,38.2-88.3,82.5-88.3
	c21.3,0,44.7,8.6,56.2,26.3V281.7z M1969.7,441.9c0-29.5-24.5-49-48.6-49c-25.9,0-47.2,20.9-47.2,49c0,28.1,21.3,50.1,47.2,50.1
	C1946.6,492,1969.7,471.5,1969.7,441.9z M2159,359.8l-8.3,39.6c-9.7-5-18.4-6.1-24.5-6.1c-27.4,0-42.5,27.4-42.5,74.6v58.7h-42.2
	V358.3h41.8v36c9-28.1,27.4-40.4,48.3-40.4C2141.7,354,2152.5,356.2,2159,359.8z M1469.4,526.6c-47.2,0-65.2-24.1-65.2-71v-64.1
	h-19.8v-33.2h19.8l0-76.7h41.8v76.7h34.9v33.2H1446v62.7c-0.4,19.1,5.8,33.5,27.7,33.5h10.8v38.9H1469.4z M477.6,620.7
	c0,15.6-12.8,28.3-28.3,28.3H187.6c-15.6,0-28.3-12.8-28.3-28.3V446.1c0-1.8,1.5-3.3,3.3-3.3l141.7,0c3.7,0,6.7,3,6.7,6.7V590
	c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V449.5c0-12-9.7-21.7-21.7-21.7H162.5c-1.8,0-3.3-1.5-3.3-3.3l0-236.9
	c0-15.6,12.8-28.3,28.3-28.3h261.7c15.6,0,28.3,12.8,28.3,28.3v172.9c0,1.8-1.5,3.3-3.3,3.3l-141.7,0c-3.7,0-6.7-3-6.7-6.7V216.6
	c0-4.1-3.4-7.5-7.5-7.5c-4.1,0-7.5,3.4-7.5,7.5v140.5c0,12,9.7,21.7,21.7,21.7h141.7c1.8,0,3.3,1.5,3.3,3.3L477.6,620.7z"
      />
    </svg>
  );
};

export default Logo;
