import { IFinalTrackOwnership } from "src/pages/projects/project/components/music/components/final-track-ownership/interfaces";
import {
  IFeeSplitDetailState,
  INameLinkState,
  IProjectAttachmentsUpdate,
  IProjectForm,
  IProjectMusicSection,
  IAdditionalContactState,
  IProjectServicesUpdate,
  IProjectTracksUpdate,
  IProjectTermsUpdate,
  IProjectPublishingTriggeredBy,
} from "src/pages/projects/project/interfaces";
import { PickKeys } from "ts-essentials";

export enum ProjectActions {
  STORE_PROJECT = "STORE_PROJECT",
  ADD_NAME_LINK_ROW = "ADD_NAME_LINK_ROW",
  ADD_CONTACT_ROW = "ADD_CONTACT_ROW",
  ADD_FINAL_TRACK_LINKS_ROW = "ADD_FINAL_TRACK_LINKS_ROW",
  ADD_FEE_SPLIT_ROW = "ADD_FEE_SPLIT_ROW",
  STORE_ATTACHMENTS = "STORE_ATTACHMENTS",
  STORE_SERVICES = "STORE_SERVICES",
  STORE_TRACKS = "STORE_TRACKS",
  STORE_TERMS = "STORE_TERMS",
  STORE_PUBLISHING_TRIGGERED = "STORE_PUBLISHING_TRIGGERED",
}

interface IStoreProject {
  type: ProjectActions.STORE_PROJECT;
  value: {
    project: IProjectForm;
  };
}

interface IAddNameLinkRow {
  type: ProjectActions.ADD_NAME_LINK_ROW;
  value: PickKeys<IProjectMusicSection, Array<INameLinkState>>;
}

interface IAddContactRow {
  type: ProjectActions.ADD_CONTACT_ROW;
  value: Array<IAdditionalContactState>;
}

interface IAddFeeSplitRow {
  type: ProjectActions.ADD_FEE_SPLIT_ROW;
  value: PickKeys<IFinalTrackOwnership, Array<IFeeSplitDetailState>>;
}

interface IStoreAttachments {
  type: ProjectActions.STORE_ATTACHMENTS;
  value: IProjectAttachmentsUpdate;
}

interface IStoreServices {
  type: ProjectActions.STORE_SERVICES;
  value: IProjectServicesUpdate;
}

interface IStoreTracks {
  type: ProjectActions.STORE_TRACKS;
  value: IProjectTracksUpdate;
}

interface IStoreTerms {
  type: ProjectActions.STORE_TERMS;
  value: IProjectTermsUpdate;
}

interface IStorePublishingTriggered {
  type: ProjectActions.STORE_PUBLISHING_TRIGGERED;
  value: IProjectPublishingTriggeredBy;
}

export type ProjectAction =
  | IStoreProject
  | IAddNameLinkRow
  | IAddFeeSplitRow
  | IStoreAttachments
  | IAddContactRow
  | IStoreServices
  | IStoreTracks
  | IStoreTerms
  | IStorePublishingTriggered;
