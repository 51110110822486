import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import theme from "../../../theme";

export default {
  container: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color: ${theme.colors.background.gray};
    font-family: sofia-pro, sans-serif;
  `,
  heading: css`
    font-family: ${theme.fonts.sofiaPro};
    margin-bottom: 0 !important;

    @media ${queries.medium} {
      font-size: 2.25rem !important;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem !important;
    }

    @media ${queries.large} {
      font-size: 2.75rem !important;
    }
  `,
  headerContainer: css`
    display: none;
    width: 100%;
    background-color: ${theme.colors.background.white};
    box-shadow: 0.063rem -0.063rem 0.313rem 0 var(--colors-dark-gray);

    @media ${queries.medium} {
      display: flex;
      font-size: 2.25rem !important;
      margin-bottom: 0 !important;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    @media ${queries.large} {
      padding: 2.325rem 3rem 1rem 3rem;
    }
  `,
  section: css`
    background: transparent;
    display: flex;
    flex-direction: column;
    flex: auto;
    min-height: 0;
  `,
  mainContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: auto;
  `,
  formContainer: css`
    flex: 1;
    margin: 1.875rem 2rem 0 2rem;
    border-radius: 0.25rem;
    padding: 2rem;
    background-color: ${theme.colors.white};
    box-shadow: 0 0.063rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5rem;

    .ant-form {
      max-width: 25rem;
    }

    h1.ant-typography {
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1.5rem !important;
      line-height: 1.5rem !important;
      margin-bottom: 0.313rem !important;
    }
    div.ant-typography {
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1rem !important;
      line-height: 1.875rem !important;
      margin-bottom: 1.2rem !important;
    }

    .ant-form-item {
      margin-bottom: 1.5rem !important;
    }

    .ant-divider {
      max-width: 25rem !important;
      min-width: unset;
    }
    button {
      max-width: 25rem;
    }

    .ant-divider-horizontal {
      margin-bottom: 2rem;
    }
  `,
  resetInput: css`
    color: ${theme.colors.secondary.blueOnBlack};
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    margin-bottom: 36px;
    margin-top: -1.5rem;
    max-width: 500px;
  `,
  logo: css`
    margin-bottom: 5rem;
    margin-top: 3rem;
  `,
  row: css`
    width: 100%;
  `,
  inputContainer: css`
    margin-top: 8px;
  `,
  input: css`
    border-color: ${theme.colors.black} !important;
    font-family: sofia-pro, sans-serif !important;
  `,
  subText: css`
    color: ${theme.colors.black};
    font-size: 14px !important;
    font-weight: 600;
    margin-top: -8px;
    margin-bottom: 48px;
    text-align: right;
    cursor: pointer;
    width: 110px;
    float: right;
  `,
  forgotPassword: css`
    color: ${theme.colors.secondary.eiffelBlue} !important;
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 2rem;
    cursor: pointer;
    text-align: center;
  `,
  backToSignin: css`
    color: ${theme.colors.secondary.eiffelBlue} !important;
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 2rem;
    cursor: pointer;
    text-align: center;
  `,
  label: css`
    font-size: 0.9rem;
    padding-bottom: 0.3rem;
  `,
  title: css`
    font-size: 1.6rem !important;
    font-weight: 700;
  `,
  button: css`
    border-radius: 0.2rem;
    background-color: ${theme.colors.primary.blue};
    border: 0;
    color: ${theme.colors.white};
    width: 100%;
    height: 50px;
    font-size: 1rem;
    cursor: pointer;

    span {
      color: ${theme.colors.white};
    }

    :hover {
      background-color: ${theme.colors.secondary.eiffelBlue};
      color: ${theme.colors.white};
    }

    :focus {
      background-color: ${theme.colors.secondary.eiffelBlue};
      color: ${theme.colors.white};
    }
  `,
  resetButton: css`
    border-radius: 0.2rem;
    background-color: ${theme.colors.secondary.eiffelBlue};
    border: 0;
    color: ${theme.colors.white};
    width: 100%;
    height: 50px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 50px;

    span {
      color: ${theme.colors.white};
    }

    :hover {
      background-color: ${theme.colors.secondary.eiffelBlue};
      color: ${theme.colors.white};
    }

    :focus {
      background-color: ${theme.colors.secondary.eiffelBlue};
      color: ${theme.colors.white};
    }
  `,
  graphicLeft: css`
    position: absolute;
    right: 0;
    top: 75px;
  `,
  graphicRight: css`
    position: absolute;
    bottom: 75px;
  `,
};
