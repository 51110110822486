import { AxiosError } from "axios";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import getProject from "src/api/projects/get-project";
import getPublishingTeamEmails from "src/api/projects/get-publishing-team-emails";
import useAuth from "src/auth/use-auth";
import { IFinalTrackOwnership } from "src/pages/projects/project/components/music/components/final-track-ownership/interfaces";
import {
  IFeeSplitDetailState,
  INameLinkState,
  IProjectAttachmentsUpdate,
  IProjectForm,
  IProjectMusicSection,
  IProjectPublishingTriggeredBy,
  IProjectServicesUpdate,
  IProjectTermsUpdate,
  IProjectTracksUpdate,
} from "src/pages/projects/project/interfaces";
import { PickKeys } from "ts-essentials";
import { useImmerReducer } from "use-immer";
import { ErrorToast } from "src/components/toast-notification";
import selfOrganisations, {
  IOrganisationSearchResponse,
} from "src/api/organisation/self";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import ProjectsContext from "./context";
import mapProjectResponse, {
  initialiseAttachments,
} from "./projectResponseMapper";
import { InitialProjectState, projectReducer } from "./store";
import { ProjectActions } from "./types";

interface IAccountProvider {
  children: ReactNode;
}
const ProjectsProvider = ({ children }: IAccountProvider): ReactElement => {
  const { getAccessToken, isAuthenticated, organisationId } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectFound, setProjectFound] = useState<boolean>(true);
  const [publishingTeamEmails, setPublishingTeamEmails] = useState<string[]>(
    []
  );
  const [allOrganizations, setAllOrganizations] = useState<IOption[]>([]);

  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useImmerReducer(
    projectReducer,
    InitialProjectState
  );

  const storeProject = useCallback(
    (projectToStore: IProjectForm) => {
      // Map attachments
      // eslint-disable-next-line no-param-reassign
      projectToStore.purchaseOrderAttachments = initialiseAttachments(
        projectToStore.attachments,
        "PurchaseOrder",
        true
      );

      // eslint-disable-next-line no-param-reassign
      projectToStore.licenseAttachments = initialiseAttachments(
        projectToStore.attachments,
        "License",
        true
      );

      // eslint-disable-next-line no-param-reassign
      projectToStore.invoiceAttachments = initialiseAttachments(
        projectToStore.attachments,
        "Invoice",
        true
      );

      // eslint-disable-next-line no-param-reassign
      projectToStore.estimateAttachments = initialiseAttachments(
        projectToStore.attachments,
        "Estimate",
        true
      );

      // eslint-disable-next-line no-param-reassign
      projectToStore.miscAttachments = initialiseAttachments(
        projectToStore.attachments,
        "Misc",
        true
      );

      // eslint-disable-next-line no-param-reassign
      projectToStore.assetAttachments = initialiseAttachments(
        projectToStore.attachments,
        "Asset",
        true
      )
        .concat(initialiseAttachments(projectToStore.attachments, "MusicAsset"))
        .concat(initialiseAttachments(projectToStore.attachments, "VideoAsset"))
        .concat(initialiseAttachments(projectToStore.attachments, "Track"));

      // eslint-disable-next-line no-param-reassign
      projectToStore.briefAttachments = initialiseAttachments(
        projectToStore.attachments,
        "Brief"
      );

      // eslint-disable-next-line no-param-reassign
      projectToStore.testResultAttachments = initialiseAttachments(
        projectToStore.attachments,
        "TestResult",
        true
      );

      dispatch({
        type: ProjectActions.STORE_PROJECT,
        value: { project: projectToStore },
      });
    },
    [dispatch]
  );

  const storeAttachments = useCallback(
    (attachmentUpdate: IProjectAttachmentsUpdate) => {
      dispatch({
        type: ProjectActions.STORE_ATTACHMENTS,
        value: attachmentUpdate,
      });
    },
    [dispatch]
  );

  const storeServices = useCallback(
    (servicesUpdate: IProjectServicesUpdate) => {
      dispatch({
        type: ProjectActions.STORE_SERVICES,
        value: servicesUpdate,
      });
    },
    [dispatch]
  );

  const storeTracks = useCallback(
    (tracksUpdate: IProjectTracksUpdate) => {
      dispatch({
        type: ProjectActions.STORE_TRACKS,
        value: tracksUpdate,
      });
    },
    [dispatch]
  );

  const storeTerms = useCallback(
    (termsUpdate: IProjectTermsUpdate) => {
      dispatch({
        type: ProjectActions.STORE_TERMS,
        value: termsUpdate,
      });
    },
    [dispatch]
  );

  const storePublishingTriggered = useCallback(
    (publishingTriggeredBy: IProjectPublishingTriggeredBy) => {
      dispatch({
        type: ProjectActions.STORE_PUBLISHING_TRIGGERED,
        value: publishingTriggeredBy,
      });
    },
    [dispatch]
  );

  const addNameLinkRowForArray = useCallback(
    (arrayName: PickKeys<IProjectMusicSection, Array<INameLinkState>>) => {
      dispatch({
        type: ProjectActions.ADD_NAME_LINK_ROW,
        value: arrayName,
      });
    },
    [dispatch]
  );

  const addFeeRowForArray = useCallback(
    (
      arrayName: PickKeys<IFinalTrackOwnership, Array<IFeeSplitDetailState>>
    ) => {
      dispatch({
        type: ProjectActions.ADD_FEE_SPLIT_ROW,
        value: arrayName,
      });
    },
    [dispatch]
  );
  const fetchOrganizations = useCallback(async () => {
    try {
      const accessToken = getAccessToken();
      if (accessToken) {
        const response = (await selfOrganisations(
          accessToken
        )) as IOrganisationSearchResponse;
        const organisations = response.items.map((org) => {
          return {
            value: org.id,
            label: org.name,
          };
        });
        setAllOrganizations(organisations);
      }
    } catch (e) {
      ErrorToast(
        "profile-page-get-organisations",
        "There was a problem retrieving organisations data. Please try again."
      );
    }
  }, [getAccessToken]);

  useEffect(() => {
    void (async () => {
      if (isAuthenticated) {
        try {
          setIsLoading(true);

          const accessToken = getAccessToken();

          const response = await getProject(accessToken, id, organisationId);

          const responseWithEmails = await getPublishingTeamEmails(
            accessToken,
            id,
            organisationId
          );
          if (responseWithEmails?.publishingTeamEmails) {
            setPublishingTeamEmails(responseWithEmails.publishingTeamEmails);
          }
          if (response) {
            const updatedResponse = mapProjectResponse(response);

            storeProject(updatedResponse);
          }
          await fetchOrganizations();
        } catch (reason) {
          const error = reason as AxiosError;
          if (error.response?.status === 403) {
            history.push("/projects");
          }
          if (error.response?.status === 404) {
            setProjectFound(false);
          }
        } finally {
          setIsLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToken, isAuthenticated, t, id]);

  const resetProjectState = useCallback(() => {
    dispatch({
      type: ProjectActions.STORE_PROJECT,
      value: { project: InitialProjectState },
    });
  }, [dispatch]);
  return (
    <ProjectsContext.Provider
      value={{
        project: state,
        isLoading,
        projectFound,
        allOrganizations,
        storeProject,
        publishingTeamEmails,
        storeAttachments,
        addNameLinkRowForArray,
        resetProjectState,
        addFeeRowForArray,
        storeServices,
        storeTracks,
        storeTerms,
        storePublishingTriggered,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
export default ProjectsProvider;
