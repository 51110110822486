import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    button {
      border: none;
      -webkit-box-shadow: none;
      padding-left: 0 !important;
      background: none;
      border-radius: 0;
      min-height: 3rem;

      :hover {
        background: none;
      }
      :focus {
        background: none;
        color: ${theme.colors.black} !important;
        border: none !important;
      }

      svg {
        margin-right: 0;
      }
    }
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    font-size: 1rem;
    font-family: ${theme.fonts.sofiaPro};
  `,
};
