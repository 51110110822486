import { ReactElement } from "react";

const SuccessIcon = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Icons" transform="translate(-884.000000, -1292.000000)">
        <rect fill="#FFE5E4" x="0" y="0" width="1440" height="2836" />
        <g
          id="Icons/Alert-Error"
          transform="translate(884.000000, 1292.312500)"
        >
          <circle id="Oval" fill="#bbedb4" cx="12" cy="12" r="12" />
          <path
            fill="#ffffff"
            fillRule="evenodd"
            d="M17.6860164,7.22741963 C18.1021543,7.58916279 18.1462499,8.21975998 17.7845068,8.63589788 L10.7979049,16.673064 C10.5936339,16.9080509 10.3036335,17.024405 10.0149295,17.0160406 C9.7130828,17.0323247 9.40811397,16.9109711 9.19899056,16.6617475 L6.20418498,13.0926772 C5.84975952,12.6702894 5.9048539,12.0405578 6.32724171,11.6861324 C6.74962951,11.3317069 7.37936109,11.3868013 7.73378654,11.8091891 L10.0152889,14.5278844 L16.2775381,7.32591003 C16.6392813,6.90977213 17.2698785,6.86567648 17.6860164,7.22741963 Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SuccessIcon;
