import React, { useCallback, useMemo } from "react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import { useTranslation } from "react-i18next";
import { Form, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import DisclaimerInfoIcon from "src/app/assets/icons/component-icons/disclaimer-info-icon";
import style from "../../styles";
import { INotesAndDocumentsData } from "../../interfaces";
import Notes from "../notes";
import Documents from "../documents";

interface IProps {
  notesAndDocumentsData: INotesAndDocumentsData;
  isEditMode?: boolean;
  onNotesAndDocumentsDataChange: (data: INotesAndDocumentsData) => void;
}

const NotesAndDocuments = ({
  notesAndDocumentsData,
  isEditMode,
  onNotesAndDocumentsDataChange,
}: IProps): React.ReactElement => {
  useWindowSize();
  const isMobile = getIsMobile();
  const { t } = useTranslation();
  const [form] = useForm();

  const getEmptyNote = useCallback(
    () => ({ note: "", date: new Date().toISOString() }),
    []
  );

  const talentNotes = useMemo(() => {
    const emptyNote = getEmptyNote();
    let talentNotesData = notesAndDocumentsData?.talentNotes ?? [emptyNote];

    if (talentNotesData.length === 0) {
      talentNotesData = [emptyNote];
    }

    return talentNotesData;
  }, [getEmptyNote, notesAndDocumentsData?.talentNotes]);

  const awardNotes = useMemo(() => {
    const emptyNote = getEmptyNote();
    let awardNotesData = notesAndDocumentsData?.awardNotes ?? [emptyNote];
    if (awardNotesData.length === 0) {
      awardNotesData = [emptyNote];
    }

    return awardNotesData;
  }, [getEmptyNote, notesAndDocumentsData?.awardNotes]);

  const internalNotes = useMemo(() => {
    const emptyNote = getEmptyNote();
    let internalNotesData = notesAndDocumentsData?.internalNotes ?? [emptyNote];

    if (internalNotesData.length === 0) {
      internalNotesData = [emptyNote];
    }

    return internalNotesData;
  }, [getEmptyNote, notesAndDocumentsData?.internalNotes]);

  return (
    <Form form={form} scrollToFirstError>
      <div id="NotesAndDocuments" css={style.sectionContainer}>
        <Typography.Text css={style.tabsTitle}>
          {t("ContactPage##Notes and Documents")}
        </Typography.Text>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <Notes
            title={t("ContactPage##Talent Notes")}
            fieldName="talentNotes"
            items={talentNotes}
            disableActions
            setNotes={(notes) => {
              onNotesAndDocumentsDataChange({ talentNotes: notes });
            }}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <Notes
            title={t("ContactPage##Awards")}
            fieldName="awardNotes"
            items={awardNotes}
            disableActions
            setNotes={(notes) => {
              onNotesAndDocumentsDataChange({ awardNotes: notes });
            }}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <Notes
            title={t("ContactPage##Internal Notes")}
            fieldName="internalNotes"
            items={internalNotes}
            disableActions={false}
            setNotes={(notes) => {
              onNotesAndDocumentsDataChange({ internalNotes: notes });
            }}
          />
        </div>
        {isEditMode && (
          <div
            css={
              isMobile ? style.mobileSectionContainer : style.clientContainer
            }
          >
            <Documents documents={notesAndDocumentsData.documents ?? []} />
          </div>
        )}
        {!isEditMode && (
          <Typography.Text css={style.disclaimerLabel}>
            <DisclaimerInfoIcon />
            {t(
              "ContactPage##Document/files can be uploaded once a contact is created"
            )}
          </Typography.Text>
        )}
      </div>
    </Form>
  );
};

export default NotesAndDocuments;
