import { UploadFile } from "antd/lib/upload/interface";
import { IMonetaryValue } from "src/interfaces/monetary-value";

export interface IContactRouteParams {
  id: string;
}

export interface ITab {
  tabTitle: string;
  content: JSX.Element;
  hashLink?: boolean;
  isDataValid?: boolean;
}

export enum TabsTitles {
  NewContact = "New Contact",
  PersonalDetails = "Personal details",
  PublishingInformation = "Publishing information",
  AAndR = "A&R",
  NotesAndDocuments = "Notes and documents",
  Finished = "Finished",
}

export enum PhysicalCondition {
  Yes = "Yes",
  No = "No",
  PreferNotToSay = "Prefer not to say",
}

export enum Neurodiverse {
  YesDiagnosed = "Yes (diagnosed)",
  YesUnDiagnosed = "Yes (undiagnosed)",
  No = "No",
  PreferNotToSay = "Prefer not to say",
}

export enum Signed {
  Yes = "Yes",
  No = "No",
  Pending = "Pending",
}

export enum Confirm {
  Yes = "Yes",
  No = "No",
}

export interface INote {
  date: string;
  note: string;
  userId?: string;
  userFullname?: string;
}

export interface IPhoneNumber {
  countryCode: string;
  number: string;
  fullNumber?: string;
}

export interface IPersonalDetailsData {
  name?: string;
  pronoun?: string | null;
  agencyWritingPartnership?: string;
  email?: string;
  phone?: IPhoneNumber;
  baseLocation?: string;
  socialMediaWebsite?: string[];
  showreelUrls?: string[];
  showReel?: IContactAttachment[];
  mainLanguage?: string;
  otherLanguages?: string[];
  billingName?: string;
  billingAddress?: string;
  physicalConditionsIllness?: PhysicalCondition | null;
  neurodiverse?: Neurodiverse | null;
  neurodiverseNote?: string;
  racialCharacteristics?: string | null;
  genderIdentity?: string | null;
  sexualOrientation?: string | null;
  ipi?: string;
}

export interface IPublishingInformationData {
  signedCMA?: Signed | null;
  signedNDA?: Signed | null;
  minimumDemoFee?: IMonetaryValue | null;
  performingRightsOrganisationNumber?: string;
  connectedToOrgId?: string | null;
  serviceProvider?: boolean | null;
}

export interface IAAndRData {
  collaborationStatus?: string | null;
  skillSet?: string[];
  principalInstruments?: string[];
  additionalInstruments?: string[];
  keyGenres?: string[];
  additionalGenres?: string[];
  vocalist?: Confirm | null;
  minimumDemoTurnaroundHours?: number | null;
  previousWork?: string[];
  interestedProjects?: string[];
}

export interface INotesAndDocumentsData {
  talentNotes?: INote[];
  awardNotes?: INote[];
  internalNotes?: INote[];
  documents?: IContactAttachment[];
}

export interface ITalentData {
  name?: string;
  email?: string;
  phone?: IPhoneNumber;
  baseLocation?: string;
  mainLanguage?: string;
  billingName?: string;
  billingAddress?: string;
  signedCMA?: Signed | null;
  signedNDA?: Signed | null;
  minimumDemoFee?: IMonetaryValue | null;
  connectedToOrgId?: string | null;
  collaborationStatus?: string | null;
  skillSet?: string[];
  principalInstruments?: string[];
  keyGenres?: string[];
  vocalist?: Confirm | null;
  minimumDemoTurnaroundHours?: number | null;
}

export interface IContactForm {
  id: string;
  name: string;
  pronoun?: string | null;
  agencyWritingPartnership?: string;
  email: string;
  phone: IPhoneNumber;
  baseLocation: string;
  socialMediaWebsite?: string[];
  showreelUrls?: string[];
  mainLanguage: string;
  otherLanguages?: string[];
  billingName: string;
  billingAddress: string;
  physicalConditionsIllness?: PhysicalCondition | null;
  neurodiverse?: Neurodiverse | null;
  neurodiverseNote?: string;
  ipi?: string;
  racialCharacteristics?: string | null;
  genderIdentity?: string | null;
  sexualOrientation?: string | null;
  signedCMA: Signed | null;
  signedNDA: Signed | null;
  minimumDemoFee: IMonetaryValue | null;
  performingRightsOrganisationNumber?: string;
  connectedToOrgId?: string | null;
  serviceProvider?: boolean | null;
  collaborationStatus: string | null;
  skillSet: string[];
  principalInstruments: string[];
  additionalInstruments?: string[];
  keyGenres: string[];
  additionalGenres?: string[];
  vocalist: Confirm | null;
  minimumDemoTurnaroundHours: number | null;
  previousWork?: string[];
  interestedProjects?: string[];
  talentNotes?: INote[];
  awardNotes?: INote[];
  internalNotes?: INote[];
  createdByOrgId?: string;
  showReel: IContactAttachment[];
  documents: IContactAttachment[];
}

export interface IContactAttachment extends UploadFile {
  id: string;
  name: string;
  contentType: string;
  attachmentType: string;
  uploaded: {
    userId: string;
    userFullname: string;
    dateTime: string;
  };
  percent: number;
  type: string;
  uid: string;
  size: number;
  createdByName: string;
}

export interface IContactUploadFile extends UploadFile {
  uploaded?: {
    dateTime?: string;
    userFullname: string;
    userId: string;
  };
  response?: {
    uid: string;
    uploaded: {
      dateTime?: string;
      userFullname: string;
      userId: string;
    };
  };
  createdByName: string;
  public?: boolean;
  request?: XMLHttpRequest;
}
export interface ICombinedAgenciesWithTalents {
  agency?: string;
  id?: string;
  name?: string;
  email?: string;
  agencyWritingPartnership?: string;
  talents?: IContactsResults[];
  groupByAgency?: boolean;
}
export interface IContactsResults {
  id: string;
  name: string;
  pronoun?: string | null;
  agencyWritingPartnership?: string;
  email: string;
  phone: IPhoneNumber;
  baseLocation: string;
  socialMediaWebsite?: string[];
  showreelUrls?: string[];
  mainLanguage: string;
  otherLanguages?: string[];
  billingName: string;
  billingAddress: string;
  physicalConditionsIllness?: string | null;
  neurodiverse?: string | null;
  neurodiverseNote?: string;
  racialCharacteristics?: string;
  genderIdentity?: string | null;
  sexualOrientation?: string | null;
  signedCMA: string | null;
  signedNDA: string | null;
  minimumDemoFee: IMonetaryValue | null;
  performingRightsOrganisationNumber?: string;
  connectedToOrgId?: string | null;
  serviceProvider?: boolean | null;
  collaborationStatus: string | null;
  skillSet: string[];
  principalInstruments: string[];
  additionalInstruments?: string[];
  keyGenres: string[];
  additionalGenres?: string[];
  vocalist: boolean | null;
  minimumDemoTurnaroundHours: number | null;
  previousWork?: string[];
  interestedProjects?: string[];
  talentNotes?: INote[];
  awardNotes?: INote[];
  internalNotes?: INote[];
  createdByOrgId?: string;
  attachments?: IContactAttachment[];
  ipi?: string;
  groupByAgency: boolean;
}

export interface ICreateContactsGroupRequest {
  groupName: string | null;
  isUserGroup: boolean;
}

export interface IContactCreateRequest {
  name: string;
  pronoun?: string | null;
  agencyWritingPartnership?: string;
  email: string;
  phone: IPhoneNumber;
  baseLocation: string;
  socialMediaWebsite?: string[];
  showreelUrls?: string[];
  mainLanguage: string;
  otherLanguages?: string[];
  billingName: string;
  billingAddress: string;
  physicalConditionsIllness?: PhysicalCondition | null;
  neurodiverse?: Neurodiverse | null;
  neurodiverseNote?: string;
  racialCharacteristics?: string;
  genderIdentity?: string | null;
  sexualOrientation?: string | null;
  signedCMA: Signed | null;
  signedNDA: Signed | null;
  minimumDemoFee: IMonetaryValue | null;
  performingRightsOrganisationNumber?: string;
  connectedToOrgId: string | null;
  serviceProvider?: boolean | null;
  collaborationStatus: string | null;
  skillSet: string[];
  principalInstruments: string[];
  additionalInstruments?: string[];
  keyGenres: string[];
  additionalGenres?: string[];
  vocalist: boolean | null;
  minimumDemoTurnaroundHours: number | null;
  previousWork?: string[];
  interestedProjects?: string[];
  talentNotes?: INote[];
  awardNotes?: INote[];
  internalNotes?: INote[];
  organisationId: string;
}
