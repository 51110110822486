import React, { ReactElement, useCallback, useMemo } from "react";
import { Form, Typography, Divider, DatePicker } from "antd";
import Select from "react-select";
import StyledButton from "src/components/button";
import {
  IGlobalBrands,
  IProjectClient,
  IProjectLead,
} from "src/pages/projects/project-listings/interfaces";
import {
  ICreativeAgency,
  OfficeLocation,
  MusicTypes,
  ProjectStatus,
  ServicesTypes,
} from "src/pages/projects/project/interfaces";
import { useTranslation } from "react-i18next";
import { RangeDateDisplayFormat } from "src/constants";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import {
  IContact,
  IHoldingCompany,
} from "src/pages/projects/project/components/account-information/types";
import { IFilterData } from "src/api/user-filters/interfaces";
import { IUserDropdownProps } from "src/pages/team/interfaces";
import dayjs, { Dayjs } from "dayjs";
import { sortBy } from "lodash";
import { getIsMobile } from "@songtradr/spa-common";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import { customStylesForProjectDropdown } from "src/pages/projects/project/components/details/styles";
import {
  getFilterDataPayload,
  ISelectLabel,
  ISelectProjectsLeads,
  parseContactToLabel,
  parseJSONToLabel,
  parseStatusToLabel,
} from "./utils";
import styles, { CustomSelectStyles } from "./styles";

interface IProps {
  clients: IProjectClient[];
  statuses: ProjectStatus[];
  leads: IProjectLead[];
  globalBrands: IGlobalBrands[];
  contacts: IContact[];
  creativeAgencies: ICreativeAgency[];
  holdingCompanies: IHoldingCompany[];
  musicTypes: MusicTypes[];
  projectTypes: string[];
  serviceTypes: ServicesTypes[];
  projectRegions: string[];
  officeLocations: string[];
  selectedClients: ISelectLabel[];
  setSelectedClients: (value: ISelectLabel[]) => void;
  selectedStatus: ISelectLabel[];
  setSelectedStatus: (value: ISelectLabel[]) => void;
  selectedLeads: ISelectProjectsLeads[];
  setSelectedLeads: (value: ISelectProjectsLeads[]) => void;
  selectedGlobalBrands: ISelectLabel[];
  setSelectedGlobalBrands: (values: ISelectLabel[]) => void;
  applyFiltersSubmit: () => void;
  selectedCreativeAgencies: ISelectLabel[];
  setSelectedCreativeAgencies: (value: ISelectLabel[]) => void;
  selectedJobListingDate: (string | null)[];
  setSelectedJobListingDate: (value: (string | null)[]) => void;
  selectedProjectCloseDate: (string | null)[];
  setSelectedProjectCloseDate: (value: (string | null)[]) => void;
  selectedHoldingCompanies: ISelectLabel[];
  setSelectedHoldingCompanies: (value: ISelectLabel[]) => void;
  selectedProjectTypes: ISelectLabel[];
  setSelectedProjectTypes: (value: ISelectLabel[]) => void;
  selectedContacts: ISelectLabel[];
  setSelectedContacts: (value: ISelectLabel[]) => void;
  selectedServiceTypes: ISelectLabel[];
  setSelectedServiceTypes: (value: ISelectLabel[]) => void;
  selectedMusicTypes: ISelectLabel[];
  setSelectedMusicTypes: (value: ISelectLabel[]) => void;
  selectedProjectRegions: ISelectLabel[];
  setSelectedProjectRegions: (value: ISelectLabel[]) => void;
  selectedOfficeLocation: ISelectLabel[];
  setSelectedOfficeLocation: (value: ISelectLabel[]) => void;
  selectedCopyrightInfoProcessed: ISelectLabel;
  setSelectedCopyRightInfoProcessed: (value: ISelectLabel) => void;
  selectedPublishingRightsSigned: ISelectLabel;
  setSelectedPublishingRightsSigned: (value: ISelectLabel) => void;
  selectedPublishingTriggered: ISelectLabel;
  setSelectedPublishingTriggered: (value: ISelectLabel) => void;
  setFilterPayload: (value: IFilterData) => void;
  inlineSaveFilterError: string;
  onShowSetFiltersDialog: () => void;
}

const Content = ({
  clients,
  statuses,
  leads,
  globalBrands,
  contacts,
  creativeAgencies,
  holdingCompanies,
  musicTypes,
  projectTypes,
  serviceTypes,
  projectRegions,
  officeLocations,
  setSelectedClients,
  selectedClients,
  selectedStatus,
  setSelectedStatus,
  setSelectedGlobalBrands,
  selectedGlobalBrands,
  selectedLeads,
  setSelectedLeads,
  applyFiltersSubmit,
  selectedCreativeAgencies,
  setSelectedCreativeAgencies,
  selectedJobListingDate,
  setSelectedJobListingDate,
  selectedProjectCloseDate,
  setSelectedProjectCloseDate,
  selectedHoldingCompanies,
  setSelectedHoldingCompanies,
  selectedProjectTypes,
  setSelectedProjectTypes,
  selectedContacts,
  setSelectedContacts,
  selectedServiceTypes,
  setSelectedServiceTypes,
  selectedMusicTypes,
  setSelectedMusicTypes,
  selectedProjectRegions,
  setSelectedProjectRegions,
  selectedOfficeLocation,
  setSelectedOfficeLocation,
  selectedCopyrightInfoProcessed,
  setSelectedCopyRightInfoProcessed,
  selectedPublishingRightsSigned,
  setSelectedPublishingRightsSigned,
  selectedPublishingTriggered,
  setSelectedPublishingTriggered,
  setFilterPayload,
  inlineSaveFilterError,
  onShowSetFiltersDialog,
}: IProps): ReactElement => {
  const [form] = Form.useForm();
  const isMobile = getIsMobile();
  const {
    allProjectTypes,
    allProjectStatuses,
    allProjectRegions,
  } = useGlobalStates();

  const { t } = useTranslation();
  const parsedLeadOptions = useMemo(
    () =>
      sortBy(
        (leads ?? []).map((user) => {
          return {
            label: user?.name.trim(),
            value: user?.id,
            email: user?.email,
            key: user?.id,
          };
        }),
        [(lead) => lead.label.toLocaleLowerCase()],
        "label"
      ),
    [leads]
  );

  const clientOptions = useMemo(
    () =>
      sortBy(
        parseJSONToLabel(clients),
        [(client) => client.label.toLocaleLowerCase()],
        "label"
      ),
    [clients]
  );

  const globalBrandOptions = useMemo(
    () =>
      sortBy(
        parseJSONToLabel(globalBrands),
        [(globalBrand) => globalBrand.label.toLocaleLowerCase()],
        "label"
      ),
    [globalBrands]
  );

  const contactOptions = useMemo(
    () =>
      sortBy(
        parseContactToLabel(
          contacts.filter((contact) => contact.name && contact.email)
        ),
        [(contact) => contact.label.toLocaleLowerCase()],
        "label"
      ),
    [contacts]
  );
  const creativeAgenciesOptions = useMemo(
    () =>
      sortBy(
        parseJSONToLabel(
          creativeAgencies
            .filter((agency) => agency.name)
            .map((agency) => ({
              id: agency.id.toString(),
              name: agency.name.trim(),
            }))
        ),
        [(agency) => agency.label.toLocaleLowerCase()],
        "label"
      ),
    [creativeAgencies]
  );
  const holdingCompaniesOptions = useMemo(
    () =>
      sortBy(
        parseJSONToLabel(
          holdingCompanies
            .map(({ id = "", name = "" }) => ({ id, name }))
            .filter(({ id, name }) => id && name)
        ),
        [(holdingCompany) => holdingCompany.label.toLocaleLowerCase()],
        "label"
      ),
    [holdingCompanies]
  );
  const musicTypesOptions = useMemo(
    () =>
      sortBy(
        parseStatusToLabel(musicTypes),
        [(musicType) => musicType.label.toLocaleLowerCase()],
        "label"
      ),
    [musicTypes]
  );
  const serviceTypesOptions = useMemo(
    () =>
      sortBy(
        parseStatusToLabel(serviceTypes),
        [(serviceType) => serviceType.label.toLocaleLowerCase()],
        "label"
      ),
    [serviceTypes]
  );

  const officeLocationTypesOptions = useMemo(
    () =>
      sortBy(
        parseStatusToLabel(officeLocations),
        [(officeLocationType) => officeLocationType.label.toLocaleLowerCase()],
        "label"
      ),
    [officeLocations]
  );

  const copyrightInfoProcessedAndPublishingRightsSignedTypesOptions = useMemo(
    () => [
      { label: "None", value: "" },
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    []
  );

  const projectTypesOptions = useMemo(() => {
    return allProjectTypes.filter((type) => projectTypes.includes(type.value));
  }, [allProjectTypes, projectTypes]);

  const projectStatusOptions = useMemo(() => {
    return allProjectStatuses.filter((type) =>
      statuses.includes(type.value as ProjectStatus)
    );
  }, [allProjectStatuses, statuses]);

  const projectRegionOptions = useMemo(() => {
    return allProjectRegions.filter((type) =>
      projectRegions.includes(type.value)
    );
  }, [allProjectRegions, projectRegions]);

  const getFormattedOptionLabelForProjectsLead = useCallback(
    (data: IUserDropdownProps) => {
      const { label, email } = data;
      return (
        <>
          <div>{label}</div>
          <div css={styles.emailLabel}>{email}</div>
        </>
      );
    },
    []
  );

  const getFormattedOptionLabel = useCallback((data: IUserDropdownProps) => {
    const { label } = data;
    return <>{label}</>;
  }, []);

  const getRangeDateSelection = useCallback((value: RangeValue<Dayjs>): (
    | string
    | null
  )[] => {
    if (value === null) {
      return [null, null];
    }
    const [startDate, endDate] = value;
    return [
      startDate ? startDate.startOf("day").toISOString() : startDate,
      endDate ? endDate.endOf("day").toISOString() : endDate,
    ];
  }, []);

  const getInitialDateValue = useCallback((value: (string | null)[]) => {
    return [
      value[0] ? dayjs(value[0]) : undefined,
      value[1] ? dayjs(value[1]) : undefined,
    ];
  }, []);

  const handleShowSaveFilterDialog = useCallback(() => {
    const regions = allProjectRegions.map((region) => region.value);

    const filterData = getFilterDataPayload(
      selectedClients,
      clients,
      selectedGlobalBrands,
      globalBrands,
      selectedCreativeAgencies,
      creativeAgencies,
      selectedHoldingCompanies,
      holdingCompanies,
      selectedStatus,
      statuses,
      selectedProjectTypes,
      projectTypes,
      selectedMusicTypes,
      musicTypes,
      selectedServiceTypes,
      serviceTypes,
      selectedLeads,
      leads,
      selectedContacts,
      contacts,
      selectedProjectCloseDate,
      selectedJobListingDate,
      selectedProjectRegions,
      regions,
      selectedOfficeLocation,
      Object.keys(OfficeLocation),
      selectedCopyrightInfoProcessed,
      selectedPublishingRightsSigned,
      selectedPublishingTriggered
    );
    setFilterPayload(filterData);
  }, [
    allProjectRegions,
    selectedClients,
    clients,
    selectedGlobalBrands,
    globalBrands,
    selectedCreativeAgencies,
    creativeAgencies,
    selectedHoldingCompanies,
    holdingCompanies,
    selectedStatus,
    statuses,
    selectedProjectTypes,
    projectTypes,
    selectedMusicTypes,
    musicTypes,
    selectedServiceTypes,
    serviceTypes,
    selectedLeads,
    leads,
    selectedContacts,
    contacts,
    selectedProjectCloseDate,
    selectedJobListingDate,
    selectedProjectRegions,
    selectedOfficeLocation,
    selectedCopyrightInfoProcessed,
    selectedPublishingRightsSigned,
    selectedPublishingTriggered,
    setFilterPayload,
  ]);

  return (
    <Form
      data-testid="filter-projects-modal"
      form={form}
      name="filter-form"
      css={styles.filterContainer}
      onFinish={applyFiltersSubmit}
    >
      <Typography.Title css={styles.modalTitle}>
        {t("FilterModal##Filters")}
      </Typography.Title>
      <div css={styles.mobileSectionContainer}>
        <Typography.Text css={styles.sectionTitle}>
          {t("FilterModal##Client")}
        </Typography.Text>
        <div
          css={
            isMobile ? styles.mobileSectionContainer : styles.clientContainer
          }
        >
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Client name")}
              </Typography.Text>
            </div>
            <Form.Item
              name="globalName"
              css={styles.formItem}
              initialValue={selectedClients}
            >
              <Select
                styles={CustomSelectStyles}
                isMulti
                placeholder=""
                aria-label="client-name-dropdown"
                value={selectedClients}
                isSearchable
                onChange={(value) => {
                  setSelectedClients(value as ISelectLabel[]);
                }}
                options={clientOptions.map((el) => el)}
                id="client-name-dropdown"
              />
            </Form.Item>
          </div>
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Global brand name")}
              </Typography.Text>
            </div>
            <Form.Item
              name="globalBrands"
              css={styles.formItem}
              initialValue={selectedGlobalBrands}
            >
              <Select
                styles={CustomSelectStyles}
                aria-label="global-brand-dropdown"
                isMulti
                placeholder=""
                value={selectedGlobalBrands}
                isSearchable
                onChange={(value) => {
                  setSelectedGlobalBrands(value as ISelectLabel[]);
                }}
                options={globalBrandOptions}
                id="global-brand-dropdown"
              />
            </Form.Item>
          </div>
        </div>
        <div
          css={
            isMobile ? styles.mobileSectionContainer : styles.clientContainer
          }
        >
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Creative Agency")}
              </Typography.Text>
            </div>
            <Form.Item
              name="creativeAgency"
              css={styles.formItem}
              initialValue={selectedCreativeAgencies}
            >
              <Select
                styles={CustomSelectStyles}
                isMulti
                placeholder=""
                aria-label="creative-agency-dropdown"
                value={selectedCreativeAgencies}
                isSearchable
                onChange={(value) => {
                  setSelectedCreativeAgencies(value as ISelectLabel[]);
                }}
                options={creativeAgenciesOptions}
                id="creative-agency-dropdown"
              />
            </Form.Item>
          </div>
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Holding Company")}
              </Typography.Text>
            </div>
            <Form.Item
              name="holdingCompany"
              css={styles.formItem}
              initialValue={selectedHoldingCompanies}
            >
              <Select
                styles={CustomSelectStyles}
                aria-label="holding-company-dropdown"
                placeholder=""
                isMulti
                value={selectedHoldingCompanies}
                isSearchable
                onChange={(value) => {
                  setSelectedHoldingCompanies(value as ISelectLabel[]);
                }}
                options={holdingCompaniesOptions}
                id="holding-company-dropdown"
              />
            </Form.Item>
          </div>
        </div>
        <Divider css={styles.filterDivider} />
        <Typography.Text css={styles.sectionTitle}>
          {t("FilterModal##Project")}
        </Typography.Text>
        <div
          css={
            isMobile ? styles.mobileSectionContainer : styles.clientContainer
          }
        >
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Project status")}
              </Typography.Text>
            </div>
            <Form.Item
              name="statuses"
              css={styles.formItem}
              initialValue={selectedStatus}
            >
              <Select
                styles={CustomSelectStyles}
                isMulti
                aria-label="project-status-dropdown"
                placeholder=""
                value={selectedStatus}
                isSearchable
                formatOptionLabel={getFormattedOptionLabel}
                onChange={(value) => {
                  setSelectedStatus(value as ISelectLabel[]);
                }}
                options={projectStatusOptions}
                id="status-dropdown"
              />
            </Form.Item>
          </div>
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Project lead")}
              </Typography.Text>
            </div>
            <Form.Item
              name="leads"
              css={styles.formItem}
              initialValue={selectedLeads}
            >
              <Select
                styles={customStylesForProjectDropdown}
                placeholder=""
                aria-label="project-lead-dropdown"
                isMulti
                value={selectedLeads}
                formatOptionLabel={getFormattedOptionLabelForProjectsLead}
                isSearchable
                onChange={(value) => {
                  setSelectedLeads(value as ISelectProjectsLeads[]);
                }}
                options={parsedLeadOptions}
                id="project-lead-dropdown"
              />
            </Form.Item>
          </div>
        </div>
        <div
          css={
            isMobile ? styles.mobileSectionContainer : styles.clientContainer
          }
        >
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Job listing date")}
              </Typography.Text>
            </div>
            <Form.Item
              name="jobListingDate"
              css={styles.formItem}
              initialValue={getInitialDateValue(selectedJobListingDate)}
            >
              <DatePicker.RangePicker
                allowClear
                css={styles.input}
                onChange={(value) =>
                  setSelectedJobListingDate(getRangeDateSelection(value))
                }
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode as HTMLElement;
                }}
                placeholder={["", ""]}
                format={RangeDateDisplayFormat}
                separator={null}
                allowEmpty={[true, true]}
              />
            </Form.Item>
          </div>
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Project close date")}
              </Typography.Text>
            </div>

            <Form.Item
              name="projectCloseDate"
              css={styles.formItem}
              initialValue={getInitialDateValue(selectedProjectCloseDate)}
            >
              <DatePicker.RangePicker
                allowClear
                css={styles.input}
                onChange={(value) =>
                  setSelectedProjectCloseDate(getRangeDateSelection(value))
                }
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode as HTMLElement;
                }}
                placeholder={["", ""]}
                format={RangeDateDisplayFormat}
                separator={null}
                allowEmpty={[true, true]}
              />
            </Form.Item>
          </div>
        </div>
        <div
          css={
            isMobile ? styles.mobileSectionContainer : styles.clientContainer
          }
        >
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Project type")}
              </Typography.Text>
            </div>
            <Form.Item
              name="projectType"
              css={styles.formItem}
              initialValue={selectedProjectTypes}
            >
              <Select
                styles={CustomSelectStyles}
                isMulti
                placeholder=""
                formatOptionLabel={getFormattedOptionLabel}
                menuPlacement="auto"
                aria-label="creative-agency-dropdown"
                value={selectedProjectTypes}
                isSearchable
                onChange={(value) => {
                  setSelectedProjectTypes(value as ISelectLabel[]);
                }}
                options={projectTypesOptions}
                id="project-type-dropdown"
              />
            </Form.Item>
          </div>
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Contacts")}
              </Typography.Text>
            </div>
            <Form.Item
              name="contacts"
              css={styles.formItem}
              initialValue={selectedContacts}
            >
              <Select
                styles={CustomSelectStyles}
                aria-label="contacts-dropdown"
                placeholder=""
                menuPlacement="auto"
                isMulti
                value={selectedContacts}
                isSearchable
                onChange={(value) => {
                  setSelectedContacts(value as ISelectLabel[]);
                }}
                options={contactOptions}
                id="holding-company-dropdown"
              />
            </Form.Item>
          </div>
        </div>
        <div
          css={
            isMobile ? styles.mobileSectionContainer : styles.clientContainer
          }
        >
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Project regions")}
              </Typography.Text>
            </div>
            <Form.Item
              name="projectRegions"
              css={styles.formItem}
              initialValue={selectedProjectRegions}
            >
              <Select
                menuPlacement="auto"
                styles={CustomSelectStyles}
                aria-label="project-regions"
                placeholder=""
                isMulti
                value={selectedProjectRegions}
                isSearchable
                onChange={(value) => {
                  setSelectedProjectRegions(value as ISelectLabel[]);
                }}
                options={projectRegionOptions}
                id="project-regions"
              />
            </Form.Item>
          </div>
          {/* Keep this div empty to maintain the two column design */}
          <div />
        </div>
        <Divider css={styles.filterDivider} />
        <Typography.Text css={styles.sectionTitle}>
          {t("FilterModal##Licensing")}
        </Typography.Text>
        <div
          css={
            isMobile ? styles.mobileSectionContainer : styles.clientContainer
          }
        >
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Service type")}
              </Typography.Text>
            </div>
            <Form.Item
              name="serviceType"
              css={styles.formItem}
              initialValue={selectedServiceTypes}
            >
              <Select
                styles={CustomSelectStyles}
                isMulti
                menuPlacement="auto"
                placeholder=""
                aria-label="service-type-dropdown"
                value={selectedServiceTypes}
                isSearchable
                onChange={(value) => {
                  setSelectedServiceTypes(value as ISelectLabel[]);
                }}
                options={serviceTypesOptions}
                id="service-type-dropdown"
              />
            </Form.Item>
          </div>
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Music type")}
              </Typography.Text>
            </div>
            <Form.Item
              name="musicType"
              css={styles.formItem}
              initialValue={selectedMusicTypes}
            >
              <Select
                menuPlacement="auto"
                styles={CustomSelectStyles}
                aria-label="music-types-dropdown"
                placeholder=""
                isMulti
                value={selectedMusicTypes}
                isSearchable
                onChange={(value) => {
                  setSelectedMusicTypes(value as ISelectLabel[]);
                }}
                options={musicTypesOptions}
                id="music-type-dropdown"
              />
            </Form.Item>
          </div>
        </div>
        <Divider css={styles.filterDivider} />
        <Typography.Text css={styles.sectionTitle}>
          {t("FilterModal##Publishing")}
        </Typography.Text>
        <div
          css={
            isMobile ? styles.mobileSectionContainer : styles.clientContainer
          }
        >
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("OfficeCountry")}
              </Typography.Text>
            </div>
            <Form.Item
              name="officeLocations"
              css={styles.formItem}
              initialValue={selectedOfficeLocation}
            >
              <Select
                styles={CustomSelectStyles}
                isMulti
                menuPlacement="auto"
                placeholder=""
                aria-label="office-location-dropdown"
                value={selectedOfficeLocation}
                isSearchable
                onChange={(value) => {
                  setSelectedOfficeLocation(value as ISelectLabel[]);
                }}
                options={officeLocationTypesOptions}
                id="office-location-type-dropdown"
              />
            </Form.Item>
          </div>
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Copyright info processed")}
              </Typography.Text>
            </div>
            <Form.Item
              name="copyrightInfoProcessed"
              css={styles.formItem}
              initialValue={selectedCopyrightInfoProcessed}
            >
              <Select
                menuPlacement="auto"
                styles={CustomSelectStyles}
                aria-label="copyright-info-processed-types-dropdown"
                placeholder=""
                value={selectedCopyrightInfoProcessed}
                isSearchable
                onChange={(value) => {
                  setSelectedCopyRightInfoProcessed(value as ISelectLabel);
                }}
                options={
                  copyrightInfoProcessedAndPublishingRightsSignedTypesOptions
                }
                id="copyright-info-processed-type-dropdown"
              />
            </Form.Item>
          </div>
        </div>
        <div
          css={
            isMobile ? styles.mobileSectionContainer : styles.clientContainer
          }
        >
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Publishing rights signed")}
              </Typography.Text>
            </div>
            <Form.Item
              name="publishingRightsSigned"
              css={styles.formItem}
              initialValue={selectedPublishingRightsSigned}
            >
              <Select
                menuPlacement="auto"
                styles={CustomSelectStyles}
                aria-label="publishing-rights-signed-types-dropdown"
                placeholder=""
                value={selectedPublishingRightsSigned}
                isSearchable
                onChange={(value) => {
                  setSelectedPublishingRightsSigned(value as ISelectLabel);
                }}
                options={
                  copyrightInfoProcessedAndPublishingRightsSignedTypesOptions
                }
                id="publishing-rights-signed-types-dropdown"
              />
            </Form.Item>
          </div>
          <div>
            <div css={styles.basicInputContainer}>
              <Typography.Text css={styles.basicInputLabel}>
                {t("Publishing notified")}
              </Typography.Text>
            </div>
            <Form.Item
              name="publishingTriggered"
              css={styles.formItem}
              initialValue={selectedPublishingTriggered}
            >
              <Select
                menuPlacement="auto"
                styles={CustomSelectStyles}
                aria-label="publishing-triggered-types-dropdown"
                placeholder=""
                value={selectedPublishingTriggered}
                isSearchable
                onChange={(value) => {
                  setSelectedPublishingTriggered(value as ISelectLabel);
                }}
                options={
                  copyrightInfoProcessedAndPublishingRightsSignedTypesOptions
                }
                id="publishing-triggered-types-dropdown"
              />
            </Form.Item>
          </div>
        </div>
        <div css={styles.controls}>
          <div css={styles.errorMessage}>{inlineSaveFilterError}</div>
          <Form.Item>
            <StyledButton
              css={styles.tertiaryButton}
              type="secondary"
              ariaLabel={t("FilterModal##ViewSavedFilters")}
              onClick={onShowSetFiltersDialog}
              noLabelTranslation
            >
              View saved filters
            </StyledButton>
            <div css={styles.rightControls}>
              <StyledButton
                css={styles.secondaryButton}
                type="secondary"
                ariaLabel={t("FilterModal##SaveFilter")}
                onClick={handleShowSaveFilterDialog}
                noLabelTranslation
              >
                Save filter
              </StyledButton>
              <StyledButton
                css={styles.button}
                htmlType="submit"
                ariaLabel="FilterModal##Apply filters"
                data-testid="delete-creative-agency-button"
              >
                FilterModal##Apply filters
              </StyledButton>
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default Content;
