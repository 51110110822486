import axios from "axios";
import { TalentContactAttachmentType } from "src/constants";
import config from "src/config";
import { getAuthHeader, getJsonContentHeader } from "src/api/headers";
import { IGetTalentHubContactAttachmentResponse } from "../interfaces";

interface IProps {
  name: string;
  contentType: string;
  attachmentType: TalentContactAttachmentType;
  contactId: string;
  organisationId: string;
  accessToken: string;
  createdByName: string;
}

async function uploadFile({
  name,
  contentType,
  attachmentType,
  contactId,
  organisationId,
  accessToken,
  createdByName,
}: IProps): Promise<IGetTalentHubContactAttachmentResponse> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  const data = {
    fileName: name,
    fileExtension: name.split(".").pop(),
    contentType,
    attachmentType,
    createdByName,
  };

  const response = await axios.post(
    `${config.projectManagementService.url}/talent-hub/talent-contact/${contactId}/attachments?orgId=${organisationId}`,
    data,
    options
  );

  return response.data as IGetTalentHubContactAttachmentResponse;
}

export default uploadFile;
