import { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IFormRelatedProject } from "src/pages/projects/project/interfaces";
import { Form, Input, Switch, Typography } from "antd";
import { cloneDeep } from "lodash";
import mainStyle from "../../../../styles";

interface IRelatedProjectRowData {
  relatedProjects?: IFormRelatedProject[];
  isExtension?: boolean;
}
interface IProps {
  projectRowData: IRelatedProjectRowData;
  onUpdateProjectRowData: (projectRowData: IRelatedProjectRowData) => void;
}

const RelatedProjectRows = ({
  projectRowData,
  onUpdateProjectRowData,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const getUpdatedRowItems = useCallback(
    (index: number, relatedProjectRowState: IFormRelatedProject) => {
      const newRowItems = cloneDeep(projectRowData.relatedProjects ?? []);
      if (newRowItems[index]) {
        newRowItems[index] = {
          link: relatedProjectRowState.link,
        };
      } else {
        newRowItems.push({
          link: relatedProjectRowState.link,
        });
      }
      return newRowItems;
    },
    [projectRowData.relatedProjects]
  );

  const updateRowItem = useCallback(
    (index: number, relatedProjectRowState: IFormRelatedProject) => {
      const relatedProjects = getUpdatedRowItems(index, relatedProjectRowState);
      onUpdateProjectRowData({ relatedProjects });
    },
    [getUpdatedRowItems, onUpdateProjectRowData]
  );

  const handleToggleRelatedProjects = useCallback(
    (toggle: boolean) => {
      const relatedProjects = toggle ? getUpdatedRowItems(0, { link: "" }) : [];
      onUpdateProjectRowData({ relatedProjects, isExtension: toggle });
    },
    [getUpdatedRowItems, onUpdateProjectRowData]
  );

  return (
    <div>
      <div css={mainStyle.switchContainer}>
        <div css={mainStyle.basicInputContainer}>
          <Typography.Text css={mainStyle.basicInputLabel}>
            {t("ProjectsPage##Is this an extension of another project")}
          </Typography.Text>
        </div>
        <Form.Item
          css={[mainStyle.formItem, mainStyle.additionalFormItemStyle]}
        >
          <Switch
            onChange={handleToggleRelatedProjects}
            checked={projectRowData.isExtension}
            data-testid="project-extension-toggle"
          />
        </Form.Item>
      </div>
      {projectRowData.isExtension &&
        (projectRowData.relatedProjects ?? []).map((item, index) => {
          return (
            <Form.Item css={mainStyle.formItem} key={item.key}>
              <div css={mainStyle.basicInputContainer}>
                <Typography.Text css={mainStyle.basicInputLabel}>
                  {t("ProjectsPage##Related project URL")}
                </Typography.Text>
              </div>
              <Input
                name="relatedProjects"
                value={item.link}
                onChange={(e) => {
                  updateRowItem(index, {
                    link: e.target.value,
                  });
                }}
                data-testid="related-project-url"
              />
            </Form.Item>
          );
        })}
    </div>
  );
};

export default RelatedProjectRows;
