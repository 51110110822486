const onKeyDownNumberInput = (
  event: React.KeyboardEvent<HTMLInputElement>
): void => {
  const isCommandKeyPressed = event.ctrlKey || event.metaKey;
  if (
    !/^[0-9]?$/.test(event.key) &&
    !["Enter", "Backspace", "."].includes(event.key) &&
    !(isCommandKeyPressed && event.key === "v") &&
    !(isCommandKeyPressed && event.key === "c")
  ) {
    event.preventDefault();
    event.stopPropagation();
  }
};

const onPasteNumberInput = (
  event: React.ClipboardEvent<HTMLInputElement>
): void => {
  const clipboardValue = event.clipboardData.getData("text/plain");
  const valueWithoutComma = clipboardValue.replace(/,/g, "").trim();
  if (!/^\d+(.(?:\d+))?$/.test(valueWithoutComma)) {
    event.preventDefault();
    event.stopPropagation();
  }
  const value = parseInt(valueWithoutComma, 10);
  if (Number.isNaN(value)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

const onPastePhoneNumberInput = (
  event: React.ClipboardEvent<HTMLInputElement>
): void => {
  const clipboardValue = event.clipboardData.getData("text/plain").trim();
  if (!/^(\d+\s+)*\d+$/.test(clipboardValue)) {
    event.preventDefault();
    event.stopPropagation();
  }
  const value = parseInt(clipboardValue, 10);
  if (Number.isNaN(value)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export default {
  onKeyDownNumberInput,
  onPasteNumberInput,
  onPastePhoneNumberInput,
};
