import axios from "axios";
import { IProjectDuplicateResponse } from "src/pages/projects/project/interfaces";
import { getDuplicateProjectUrl } from "../../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const duplicateProject = (
  accessToken: string,
  projectId: string,
  organisationId: string
): Promise<IProjectDuplicateResponse | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios
    .post(getDuplicateProjectUrl(projectId, organisationId), {}, options)
    .then(({ data }) => {
      return data as IProjectDuplicateResponse;
    });
};

export default duplicateProject;
