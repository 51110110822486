import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import { StylesConfig } from "react-select";
import theme from "src/theme";

type IsMulti = false;
interface IOption {
  label: string;
  value: string;
}

export const selectStyle: StylesConfig<IOption, IsMulti> = {
  menu: (provided) => ({
    ...provided,
    fontSize: 16,
    fontWeight: 400,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
    marginTop: -8,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 4,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
    color: `${theme.colors.primary.blue}`,

    ":hover": {
      color: `${theme.colors.secondary.lightGray}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 56,
    fontSize: 16,
    maxHeight: 56,
    fontWeight: 400,
  }),
  control: (provided) => {
    return {
      ...provided,
      minHeight: 56,
      maxHeight: 56,
    };
  },
};

export default {
  container: css`
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
  `,
  typographyText: css`
    font-size: 16px;
  `,
  thirdPartyContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 1.5rem;
    align-items: center;
    margin-top: 30px;
  `,
  collapsibleTableHeaderContainer: css`
    display: flex;
    flex-direction: row;
    padding-top: 1.25rem;
    padding-left: 3rem;
    padding-right: 1.5rem;
    padding-bottom: 1.25rem;
    color: #555;
    font-weight: 800;
    font-size: 1rem;
  `,
  summaryIconContainer: css`
    width: 4.75rem;
    min-width: 4.75rem;
  `,
  collapsibleTableHeaderCell: css`
    width: 9rem !important;
    min-width: 9rem;
    flex-grow: 1;
    @media ${queries.medium} {
      width: 5rem !important;
      min-width: 5rem;
    }
  `,
  collapsibleTableHeaderFirstCell: css`
    width: 12rem !important;
    min-width: 12rem;
    flex-grow: 1;
    @media ${queries.medium} {
      width: 5rem !important;
      min-width: 5rem;
    }
  `,
  serviceFormContainer: css`
    box-shadow: none;
    border: none !important;
    border-radius: 0 !important;
    display: flex;
    row-gap: 1.5rem;
    flex-direction: column;
  `,
  optionRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    :hover {
      background-color: none !important;
    }
  `,
  optionRowDelete: css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    background: none !important;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 32px;
    z-index: 999;

    :hover {
      background: rgba(31, 72, 231, 0.16) !important;
    }
  `,
  headerContainer: css`
    margin-bottom: 1rem;
  `,
  headerText: css`
    font-size: 1.6rem;
    color: ${theme.colors.secondary.navyBlue};
    font-weight: bold;
  `,
  iconContainer: css`
    margin-right: 0.5rem;
    padding-top: 0 !important;
    margin-left: 0.5rem;

    @media ${queries.medium} {
      margin-left: 0;
    }
  `,
  boldText: css`
    font-weight: bold;
    font-size: 1rem;
    margin-right: 1rem;
  `,
  switchContainer: css`
    margin: 16px -8px 20px !important;
    height: 50px;
  `,
  addButton: css`
    margin-left: 6px;
    align-self: center;
    div {
      float: right;
      .ant-btn[disabled],
      .ant-btn[disabled]:hover,
      .ant-btn[disabled]:focus,
      .ant-btn[disabled]:active {
        color: ${theme.colors.background.lightGray};
        background: ${theme.colors.white};
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        svg {
          margin-right: 0;
          circle {
            stroke: none;
          }
          line {
            stroke: ${theme.colors.background.lightGray};
          }
        }
      }
    }
  `,
  addIconContainer: css`
    display: flex;
    align-items: center;
    width: 38px;
    height: 38px;
    color: #222;
    svg {
      color: #222 !important;
      width: 38px;
      padding: 0.5rem;
      height: 38px;
    }
  `,
  addButtonDisabled: css`
    pointer-events: none;
  `,
  submitButton: css`
    float: right;
    &:hover {
      background-color: ${theme.colors.secondary.darkBlue} !important;
    }
  `,
  cancelButton: css`
    height: 50px;
    width: 98px;
    margin-right: 1.0625rem;
    vertical-align: middle;
  `,
  buttonRow: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row nowrap;
  `,
  servicesContainer: css`
    margin-top: 2rem;
  `,
  deleteIconContainer: css`
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: end;
  `,
  summaryContainer: css`
    background-color: unset;
    border-bottom: 1px solid #9999;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
  `,
  totalContainer: css`
    background-color: ${theme.colors.background.white};
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    font-weight: 700;
    margin-top: 2.25rem;
  `,
  summaryColumnContainer: css`
    display: flex;
    width: 9rem;
    min-width: 9rem !important;
    flex-grow: 1;
    flex-direction: column;
    text-align: left;
    @media ${queries.medium} {
      width: 5rem !important;
      min-width: 5rem !important;
    }
  `,
  summaryColumnTitle: css`
    font-size: 0.688rem;
    line-height: 1.25rem;
    color: ${theme.colors.background.darkGray};
  `,
  summaryColumnInformation: css`
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
  `,
  expandButtonCol: css`
    display: flex;
    .anticon {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  `,
  moreInfoButton: css`
    margin-left: 2rem;
  `,
  totalText: css`
    font-size: 1rem;
    line-height: 1.875rem;
    font-weight: bold;
  `,
  totalFee: css`
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: bold;
  `,
};
