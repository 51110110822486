import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  descriptionLink: css`
    margin-top: -0.5rem;
    flex: 1;
    display: flex;
    font-weight: 200 !important;
    font-size: 0.875rem !important;
    justify-content: left;
    height: 100%;
    border-radius: 0;
    padding: 0;
    padding-top: 0.5rem;
    background-color: transparent;
    font-family: ${theme.fonts.sofiaPro};
    border: none;
    letter-spacing: 0;
    line-height: 1.25rem;
    color: ${theme.colors.white};
    cursor: pointer;
    box-shadow: none;
    text-decoration: underline;
    margin-left: 0.4rem !important;
    padding-bottom: 0.2rem;

    :hover {
      background-color: unset !important;
      height: unset;
      text-decoration: underline !important;
    }

    :focus {
      outline-style: unset;
      text-decoration: underline;
      height: unset;
    }

    @media ${queries.large} {
      margin-left: 0 !important;
    }
  `,
};
