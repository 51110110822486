import { ReactElement } from "react";
import { IComponentIconProps } from "./interfaces";

const ArrowRight = (): ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1984 23.8466L10.8209 22.4691C10.6163 22.2645 10.6163 21.9318 10.8209 21.7272L16.1771 16.371C16.3817 16.1664 16.3817 15.8336 16.1771 15.629L10.8202 10.2728C10.6156 10.0682 10.6156 9.7355 10.8202 9.5309L12.1977 8.15345C12.4022 7.94885 12.735 7.94885 12.9396 8.15345L20.4152 15.629C20.6198 15.8336 20.6198 16.1664 20.4152 16.371L12.9396 23.8466C12.735 24.0511 12.4022 24.0511 12.1977 23.8466H12.1984Z"
        fill="#111111"
      />
    </svg>
  );
};
export default ArrowRight;
