import { DatePicker, Divider, Form, Input, Radio, Row, Typography } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "src/components/button";
import { useTranslation } from "react-i18next";
import {
  TermTypes,
  FormModes,
  MediaTypes as MediaTypeEnum,
  UpdateActions,
  Exclusivity,
  LicenseFixedDurations,
  DateDisplayFormat,
  ISODateParseFormat,
  Currencies,
} from "src/constants";
import { RadioChangeEvent } from "antd/lib/radio";
import { useImmer } from "use-immer";
import createTerm from "src/api/terms/create-term";
import useAuth from "src/auth/use-auth";
import {
  ICreateTermRequest,
  IProjectRouteParams,
  IUpdateTermResponse,
  IUpdateTermRequest,
  ITerritory,
  IScript,
  IProjectForm,
} from "src/pages/projects/project/interfaces";
import ReactSelect from "react-select";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import updateTerm from "src/api/terms/update-term";
import useProject from "src/providers/project/hooks";
import { getProject } from "src/api/projects";
import dayjs, { Dayjs } from "dayjs";
import { ErrorToast, InfoToast } from "src/components/toast-notification";
import ScriptModal from "src/components/scripts-remove";
import projectValidation from "src/utils/projectValidation";
import SecondaryModalButtonLink from "src/components/modals/modal-buttons/secondary-button-link";
import CurrencyAndPrice from "src/components/currency-and-price";
import { formatCurrency } from "src/utils/currency";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import DisclaimerInfoIcon from "src/app/assets/icons/component-icons/disclaimer-info-icon";
import isValidPriceValue from "src/utils/validate-price";
import { getIsMobile } from "@songtradr/spa-common";
import styles, { customStyles } from "./styles";
import mainStyle from "../../../../../../styles";
import Territories from "./components/territories";
import MediaTypes from "./components/media-types";
import { IMediaTypeState } from "./components/media-types/interfaces";
import { ITermDetailViewModel } from "../../../../interfaces";
import { getDerivedTermEndDate } from "../../../../helpers";
import Scripts from "./components/scripts";

interface IProps {
  mode: FormModes;
  term?: ITermDetailViewModel;
  termChanged: boolean;
  onChangesMade: (
    updatedTerms: { [termId: string]: boolean },
    isUpdateClicked?: boolean
  ) => void;
  onValidationChange?: (termId: string, isInvalid: boolean) => void;
  onCancelTerm: () => void;
}

interface IOption {
  value: string;
  label: string;
}

interface ITrack {
  id: string;
  title: string;
}

function mapTermToViewModelState(
  mode: FormModes,
  term?: ITermDetailViewModel
): ITermDetailViewModel {
  if (mode === FormModes.Edit && term) {
    return term;
  }

  return {
    type: TermTypes.Term,
  } as ITermDetailViewModel;
}

const parseTrackToDropdownOption = (formTracks: ITrack[]): IOption[] => {
  if (formTracks) {
    return formTracks.map((track) => {
      return {
        label: track.title,
        value: track.id,
      };
    });
  }

  return [];
};

const parseTermTracks = (tracks: IProjectForm["tracks"]): IOption[] => {
  if (tracks) {
    return tracks.map((track) => {
      return {
        label: track.title,
        value: track.id,
      };
    });
  }

  return [];
};

export enum NoteFieldTypes {
  MediaTypeNote = "MediaTypeNote",
  TerritoriesNote = "TerritoriesNote",
  ScriptNotes = "ScriptNotes",
}

const TermForm = ({
  mode,
  term,
  termChanged,
  onChangesMade,
  onValidationChange,
  onCancelTerm,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { fetchWrapper, isSessionValid, organisationId } = useAuth();
  const isMobile = getIsMobile();
  const { id: projectId } = useParams<IProjectRouteParams>();
  const [formData, setTermData] = useImmer<ITermDetailViewModel>(
    mapTermToViewModelState(mode, term)
  );
  const { project, storeTerms } = useProject();
  const getMediaTypes = useCallback(() => {
    const mediaTypeList: IMediaTypeState[] = [];

    Object.entries(MediaTypeEnum).forEach(([key, value]) => {
      const mediaType = {
        name: value,
        isSelected: false,
        key,
        isCustom: false,
      } as IMediaTypeState;

      if (term?.mediaType?.find((x) => x === mediaType.key)) {
        mediaType.isSelected = true;
      }

      mediaTypeList.push(mediaType);
    });
    formData?.customMediaType?.forEach((customMediaType) => {
      mediaTypeList.push({
        name: customMediaType,
        key: customMediaType,
        isSelected: true,
        isCustom: true,
      } as IMediaTypeState);
    });

    return mediaTypeList;
  }, [formData?.customMediaType, term?.mediaType]);

  const [mediaTypes, setMediaTypes] = useImmer<IMediaTypeState[]>(
    getMediaTypes()
  );
  const [territories, setTerritories] = useState<ITerritory | undefined>(
    term?.territories
  );
  const updateTermChanged = useCallback(() => {
    if (!termChanged) {
      onChangesMade({ [term?.id ?? "new"]: false });
    }
  }, [onChangesMade, term?.id, termChanged]);
  const handleSetTerritories = useCallback(
    (updatedTerritories: ITerritory | undefined) => {
      setTerritories(updatedTerritories);
    },
    []
  );
  const [areTerritoriesRequired, setAreTerritoriesRequired] = useState(false);
  const [isDateRequired, setDateRequired] = useState(true);
  const [isSubmitClicked, setSubmitClicked] = useState(false);
  const [scripts, setScripts] = useState<IScript[]>(
    term?.scripts || [
      { name: "", details: "", duration: 0, includesCutdowns: false },
    ]
  );
  const [showScriptsModal, setScriptsModal] = useState<boolean>(false);
  const [showScriptsForm, setShowScriptsForm] = useState<boolean | null>(
    !formData.hasUnlimitedScripts || formData.hasUnlimitedScripts !== undefined
  );

  const [showFinalTracksSelect, setShowFinalTracksSelect] = useState<boolean>(
    formData.appliesToAllFinalTracks
  );
  const getDurationOptions = useCallback((): IOption[] => {
    const durationOptions: IOption[] = [];

    Object.values(LicenseFixedDurations).forEach((enumValue) => {
      durationOptions.push({
        value: enumValue.toString(),
        label: t(`ProjectsPage##licensingSection##${enumValue.toString()}`),
      });
    });

    return durationOptions;
  }, [t]);

  useEffect(() => {
    const formScripts: IScript[] = [];

    if (formData.scripts?.length) {
      formData.scripts?.forEach((script) => {
        formScripts.push({
          name: script.name,
          details: script.details,
          duration: script.duration,
          includesCutdowns: script.includesCutdowns,
        } as IScript);
      });
    }

    setScripts(formScripts);
  }, [formData.scripts]);

  const hasFinalTracks = useMemo(() => {
    return (formData.finalTracks ?? []).length > 0;
  }, [formData.finalTracks]);

  const hasNoFinalTracks = useMemo(() => {
    return (formData.finalTracks ?? []).length === 0;
  }, [formData.finalTracks]);

  const submitForm = useCallback(async (): Promise<void> => {
    setSubmitClicked(true);
    const isSession = await isSessionValid();

    if (!isSession) {
      setSubmitClicked(false);
      return;
    }

    const updatedTerm = {
      organisationId,
      ...formData,
    };

    if (updatedTerm.appliesToAllFinalTracks) {
      updatedTerm.finalTracks = [];
    }

    updatedTerm.mediaType = [];
    updatedTerm.customMediaType = [];

    if (!updatedTerm.name || updatedTerm.name.trim() === "") {
      updatedTerm.name = t("ProjectsPage##licensingSection##Untitled", {
        type:
          formData.type === TermTypes.Term
            ? t("ProjectsPage##licensingSection##Term").toLowerCase()
            : t("ProjectsPage##licensingSection##Option").toLowerCase(),
      });
    }

    mediaTypes
      .filter((mediaType) => mediaType.isSelected)
      .forEach((mediaType) => {
        if (mediaType.isCustom) {
          // Custom media types do not have enums on backend, so can use the string name as value here.
          updatedTerm.customMediaType.push(mediaType.name);
        } else {
          // Use media type key here to match up with backends media type enums on api request.
          updatedTerm.mediaType.push(mediaType.key);
        }
      });

    updatedTerm.territories = territories ?? ({} as ITerritory);
    updatedTerm.scripts = scripts ?? ([] as IScript[]);

    const updateRequest = updatedTerm as IUpdateTermRequest;

    updateRequest.startDate = updatedTerm.startDate?.format(ISODateParseFormat);
    updateRequest.endDate = updatedTerm.endDate?.format(ISODateParseFormat);
    let isValid = true;
    try {
      await projectValidation.termSchemaDefault.validate(updatedTerm);
    } catch (error) {
      ErrorToast(
        "validate-term-error",
        t("ProjectsPage##licensingSection##ValidationError")
      );
      setSubmitClicked(false);
      isValid = false;
    }
    if (hasFinalTracks) {
      try {
        await projectValidation.termSchema.validate(updatedTerm);
      } catch (error) {
        ErrorToast(
          "validate-term-error",
          t("ProjectsPage##licensingSection##ValidationError")
        );
        setSubmitClicked(false);
        isValid = false;
      }
    }
    if (updatedTerm.fixedDuration === LicenseFixedDurations.CustomDuration) {
      updateRequest.fixedDuration = undefined;
    } else {
      updateRequest.endDate = undefined;
    }
    if (!isValid) {
      return;
    }

    try {
      if (mode === FormModes.Add) {
        await fetchWrapper(
          createTerm,
          projectId,
          updatedTerm as ICreateTermRequest
        );
      } else if (term && term.id) {
        const updateResponse: IUpdateTermResponse = await fetchWrapper(
          updateTerm,
          projectId,
          term.id,
          updatedTerm as IUpdateTermRequest
        );
        setTermData((draft) => {
          draft.version = updateResponse.data.version;
        });
        if (
          updateResponse &&
          (updateResponse.data.updateAction ===
            UpdateActions.UpdatedWithVersionConflict ||
            updateResponse.data.updateAction === UpdateActions.Recreated)
        ) {
          InfoToast(t("ProjectsPage##licensingSection##TermConflict"));
        }
      }

      const response = await fetchWrapper(
        getProject,
        projectId,
        organisationId
      );
      if (response) {
        storeTerms({
          terms: response.terms,
          projectVersion: response.version,
        });
      }
      onChangesMade({ [term?.id ?? "new"]: false }, true);
    } catch (e) {
      ErrorToast(
        "save-term-error",
        t("ProjectsPage##licensingSection##SavingError")
      );
    } finally {
      setSubmitClicked(false);
    }
  }, [
    isSessionValid,
    organisationId,
    formData,
    mediaTypes,
    territories,
    scripts,
    fetchWrapper,
    hasFinalTracks,
    t,
    mode,
    term,
    projectId,
    onChangesMade,
    setTermData,
    storeTerms,
  ]);

  const getSubmitButtonText = useCallback((): string => {
    const typeTranslationKey =
      formData.type === TermTypes.Term
        ? "ProjectsPage##licensingSection##Term"
        : "ProjectsPage##licensingSection##Option";

    const modeTranslationKey =
      mode === FormModes.Add
        ? "ProjectsPage##licensingSection##AddType"
        : "ProjectsPage##licensingSection##UpdateType";

    return t(modeTranslationKey, {
      type: t(typeTranslationKey).toLowerCase(),
    });
  }, [formData.type, mode, t]);

  const handleMediaTypeClick = useCallback(
    (mediaType: IMediaTypeState) => {
      const index = mediaTypes.findIndex((x) => x.name === mediaType.name);
      const clone = cloneDeep(mediaTypes);
      clone[index].isSelected = mediaType.isSelected;

      if (mediaType.name === MediaTypeEnum.OnlineFullPackage) {
        const onlinePackageMediaTypes = [
          MediaTypeEnum.UnpaidOnline,
          MediaTypeEnum.PaidSocialMedia,
          MediaTypeEnum.UnpaidSocialMedia,
          MediaTypeEnum.InternalCorporate,
          MediaTypeEnum.TikTok,
          MediaTypeEnum.VideoOnDemand,
          MediaTypeEnum.BroadcasterVideoOnDemand,
          MediaTypeEnum.Meta,
          MediaTypeEnum.Instagram,
          MediaTypeEnum.Spotify,
        ];

        onlinePackageMediaTypes.forEach((type) => {
          const mediaTypeIndex = mediaTypes.findIndex((x) => x.name === type);
          clone[mediaTypeIndex].isSelected = mediaType.isSelected;
        });
      }

      setTermData((draft) => {
        if (mediaType.isCustom) {
          if (mediaType.isSelected) {
            draft.customMediaType = [
              ...(draft.customMediaType ?? []),
              mediaType.name,
            ];
          } else {
            draft.customMediaType = [
              ...(draft.customMediaType ?? []).filter(
                (type) => type !== mediaType.name
              ),
            ];
          }
        } else if (mediaType.isSelected) {
          draft.mediaType = [
            ...(draft.mediaType ?? []).filter(
              (type) => type !== mediaType.name
            ),
          ];
        } else {
          draft.mediaType = [...(draft.mediaType ?? []), mediaType.name];
        }
      });

      setMediaTypes(clone);
      updateTermChanged();
    },
    [mediaTypes, setMediaTypes, setTermData, updateTermChanged]
  );

  const addCustomMediaType = useCallback(
    (mediaType: IMediaTypeState) => {
      const allMediaTypes = cloneDeep(mediaTypes);
      allMediaTypes.push(mediaType);
      setTermData((draft) => {
        draft.customMediaType = [
          ...(draft.customMediaType ?? []),
          mediaType.name,
        ];
      });
      setMediaTypes(allMediaTypes);
      updateTermChanged();
    },
    [mediaTypes, setMediaTypes, setTermData, updateTermChanged]
  );

  const removeCustomMediaType = useCallback(
    (customMediaType: IMediaTypeState) => {
      const filteredMediaTypes = cloneDeep(mediaTypes);

      filteredMediaTypes.splice(
        filteredMediaTypes
          .map((mediaType) => mediaType.name)
          .indexOf(customMediaType.name),
        1
      );

      setTermData((draft) => {
        draft.customMediaType = [
          ...(draft.customMediaType ?? []).filter(
            (type) => type !== customMediaType.name
          ),
        ];
      });
      setMediaTypes(filteredMediaTypes);
      updateTermChanged();
    },
    [mediaTypes, setMediaTypes, setTermData, updateTermChanged]
  );

  const renameCustomMediaType = useCallback(
    (newName: string, oldName: string) => {
      const index = mediaTypes.findIndex((x) => x.name === oldName);

      setTermData((draft) => {
        const customMediaTypeIndex = draft.customMediaType?.findIndex(
          (type) => type === oldName
        );
        if (customMediaTypeIndex > -1) {
          draft.customMediaType[customMediaTypeIndex] = newName;
        }
      });
      setMediaTypes((draft) => {
        draft[index].name = newName;
      });
      updateTermChanged();
    },
    [mediaTypes, setMediaTypes, setTermData, updateTermChanged]
  );

  const FIELDS = {
    type: ["type"],
    name: ["name"],
    duration: ["duration"],
    scriptName: ["scriptName"],
    scriptDetails: ["scriptDetails"],
    scriptNo: ["scriptNo"],
    hasUnlimitedScripts: ["hasUnlimitedScripts"],
    notes: ["notes"],
    version: ["version"],
    exclusivity: ["exclusivity"],
    fixedDuration: ["fixedDuration"],
    startDate: ["startDate"],
    endDate: ["endDate"],
    cutdowns: ["cutdowns"],
    delete: ["delete"],
    appliesToAllFinalTracks: ["appliesToAllFinalTracks"],
    finalTracks: ["finalTracks"],
    lyricChange: ["lyricChange"],
    lyricChangeNotes: ["lyricChangeNotes"],
    optionToExtend: ["optionToExtend"],
    onlineArchival: ["onlineArchival"],
    mediaTypeNote: ["mediaTypeNote"],
    territoriesNote: ["territoriesNote"],
    scriptNotes: ["scriptNotes"],
  };

  const hideEndDate = useMemo(
    () =>
      formData.fixedDuration === LicenseFixedDurations.InPerpetuity ||
      formData.fixedDuration === LicenseFixedDurations.Archival,
    [formData.fixedDuration]
  );

  const resetScriptForm = useCallback(() => {
    form.setFieldsValue({
      [FIELDS.hasUnlimitedScripts[0]]:
        formData.hasUnlimitedScripts === undefined ? undefined : false,
    });
    setShowScriptsForm(true);
    updateTermChanged();
  }, [
    FIELDS.hasUnlimitedScripts,
    form,
    formData.hasUnlimitedScripts,
    updateTermChanged,
  ]);

  const handleUnlimitedScripts = useCallback(
    (value: boolean) => {
      if (value) {
        setTermData((draft) => {
          draft.hasUnlimitedScripts = true;
        });
        if (scripts.length > 0) {
          setScriptsModal(true);
        } else {
          resetScriptForm();
          setShowScriptsForm(false);
        }
      } else {
        setTermData((draft) => {
          draft.hasUnlimitedScripts = false;
        });
        (scripts ?? []).forEach((script, index) => {
          const fName = `${FIELDS.scriptName[0]}-${index}`;
          const fDetails = `${FIELDS.scriptDetails[0]}-${index}`;
          const fDuration = `${FIELDS.duration[0]}-${index}`;
          const fCutdowns = `${FIELDS.cutdowns[0]}-${index}`;
          form.setFieldsValue({
            [fName]: script.name,
            [fDetails]: script.details,
            [fDuration]: script.duration,
            [fCutdowns]: script.includesCutdowns,
          });
        });
        setShowScriptsForm(true);
      }
    },
    [
      FIELDS.cutdowns,
      FIELDS.duration,
      FIELDS.scriptName,
      FIELDS.scriptDetails,
      form,
      scripts,
      setTermData,
      resetScriptForm,
    ]
  );

  const removeAllScripts = useCallback(() => {
    scripts.forEach((_, index) => {
      const fName = `${FIELDS.scriptName[0]}-${index}`;
      const fDetails = `${FIELDS.scriptDetails[0]}-${index}`;
      const fDuration = `${FIELDS.duration[0]}-${index}`;
      const fCutdowns = `${FIELDS.cutdowns[0]}-${index}`;
      form.setFieldsValue({
        [fName]: "",
        [fDetails]: "",
        [fDuration]: 0,
        [fCutdowns]: false,
      });
    });
    setScripts([]);
    setScriptsModal(false);
    setShowScriptsForm(false);
  }, [
    FIELDS.cutdowns,
    FIELDS.duration,
    FIELDS.scriptName,
    FIELDS.scriptDetails,
    form,
    scripts,
  ]);

  const handleApplyToTrackChange = useCallback(
    (value: boolean) => {
      setShowFinalTracksSelect(value);
      setTermData((draft) => {
        draft.appliesToAllFinalTracks = value;
      });
    },
    [setTermData]
  );

  const selectMultiValue = useCallback(
    (data: IOption[]) => {
      const parsedOptions = data?.map((user) => {
        return {
          id: user?.value,
          title: user?.label,
        };
      });

      form.setFieldsValue({ [FIELDS.finalTracks[0]]: parsedOptions });
      setTermData((draft) => {
        draft.finalTracks = parsedOptions;
      });
    },
    [FIELDS.finalTracks, form, setTermData]
  );

  const parsedTermTracks = parseTermTracks(project.tracks);
  const tracksInitialValue = parseTrackToDropdownOption(
    formData.finalTracks as ITrack[]
  );

  useEffect(() => {
    const shouldMarkTerritoriesAsRequired = async () => {
      let areSelected = true;
      try {
        await projectValidation.termSchema.validateSyncAt("territories", {
          territories,
        });
      } catch {
        areSelected = false;
      }
      setAreTerritoriesRequired(hasFinalTracks && !areSelected);
    };

    void shouldMarkTerritoriesAsRequired();
  }, [hasFinalTracks, term?.id, territories]);

  const getStringValidationRule = useCallback(
    () => [
      () => ({
        validator(_rule, value: string) {
          if (hasFinalTracks && !value) {
            return Promise.reject(new Error("This is a required field"));
          }
          return Promise.resolve();
        },
      }),
    ],
    [hasFinalTracks]
  );

  const markAppliesToAllFinalTracksAsRequired = useMemo(() => {
    return hasFinalTracks && formData.appliesToAllFinalTracks === undefined;
  }, [formData.appliesToAllFinalTracks, hasFinalTracks]);

  useEffect(() => {
    if (isSubmitClicked || hasFinalTracks || hasNoFinalTracks) {
      void form.validateFields([
        "name",
        "type",
        "startDate",
        "fixedDuration",
        "endDate",
        "appliesToAllFinalTracks",
      ]);
    }
  }, [form, hasFinalTracks, hasNoFinalTracks, isSubmitClicked]);

  useEffect(() => {
    if (hasFinalTracks) {
      const areMediaTypeRequired =
        (mediaTypes ?? []).filter((type) => type.isSelected).length === 0;

      const isFormInvalid =
        isDateRequired || areMediaTypeRequired || areTerritoriesRequired;

      if (term?.id) {
        onValidationChange?.(term.id, isFormInvalid);
      }
    }
  }, [
    areTerritoriesRequired,
    hasFinalTracks,
    isDateRequired,
    mediaTypes,
    onValidationChange,
    term?.id,
  ]);
  const getStyleForAppliesToAllFieldsRequired = useCallback(() => {
    return {
      border: `1px solid ${
        markAppliesToAllFinalTracksAsRequired ? "red" : "white"
      }`,
      paddingLeft: markAppliesToAllFinalTracksAsRequired ? "20px" : 0,
    };
  }, [markAppliesToAllFinalTracksAsRequired]);

  const currencyOptions: IOption[] = useMemo(
    () =>
      Object.values(Currencies).map((value) => {
        return {
          value: value.code,
          label: value.displayText,
        };
      }),
    []
  );

  const getCurrency = useCallback(
    (currentCurrency: string) =>
      currencyOptions.find((option) => option.value === currentCurrency),
    [currencyOptions]
  );

  const quoteCurrency = useMemo(
    () => formData?.quote?.currency ?? Currencies.USD.code,
    [formData?.quote?.currency]
  );
  const quotePrice = useMemo(() => formData?.quote?.value ?? "", [
    formData?.quote?.value,
  ]);

  const feeCurrency = useMemo(
    () => formData?.fee?.currency ?? Currencies.USD.code,
    [formData?.fee?.currency]
  );
  const feePrice = useMemo(() => formData?.fee?.value ?? "", [
    formData?.fee?.value,
  ]);

  const confirmationOptions = useMemo(
    () => [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    []
  );

  const convertConfirmationBooleanToOption = useCallback(
    (lyricChange: boolean | null) => {
      return lyricChange
        ? { value: "yes", label: "Yes" }
        : { value: "no", label: "No" };
    },
    []
  );

  const convertConfirmationOptionToBooleanOrNull = useCallback(
    (lyricChange: IOption) => {
      return lyricChange.value === "yes";
    },
    []
  );

  const initialFormValues = useMemo(() => {
    return {
      ...formData,
      quoteCurrency: getCurrency(quoteCurrency),
      quotePrice: isValidPriceValue(quotePrice)
        ? formatCurrency(quotePrice)
        : undefined,
      feeCurrency: getCurrency(feeCurrency),
      feePrice: isValidPriceValue(feePrice)
        ? formatCurrency(feePrice)
        : undefined,
      lyricChange: convertConfirmationBooleanToOption(
        formData?.lyricChange?.checked
      ),
      lyricChangeNotes: formData?.lyricChange?.notes ?? "",
      optionToExtend: formData?.optionToExtend ?? "",
      onlineArchival: convertConfirmationBooleanToOption(
        formData?.onlineArchival
      ),
      fixedDuration: formData?.fixedDuration
        ? {
            label: formData?.fixedDuration,
            value: formData?.fixedDuration,
          }
        : undefined,
      mediaTypeNote: formData?.mediaTypeNote ?? "",
      territoriesNote: formData?.territoriesNote ?? "",
      scriptNotes: formData?.scriptNotes ?? "",
    };
  }, [
    convertConfirmationBooleanToOption,
    feeCurrency,
    feePrice,
    formData,
    getCurrency,
    quoteCurrency,
    quotePrice,
  ]);

  const onMonetaryValueChange = useCallback(
    (field: string, updatedFee: IMonetaryValue) => {
      setTermData({ ...formData, [field]: updatedFee });
      form.setFieldsValue({
        [`${field}Currency`]: getCurrency(updatedFee.currency),
        [`${field}Price`]: isValidPriceValue(updatedFee.value)
          ? formatCurrency(updatedFee.value)
          : undefined,
      });
      updateTermChanged();
    },
    [form, formData, getCurrency, setTermData, updateTermChanged]
  );

  const onTermTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      const defaultFee = {
        currency: Currencies.USD.code,
        value: "",
      };
      if (e.target.value !== TermTypes.Option) {
        setTermData((draft) => {
          draft.type = e.target.value as TermTypes;
          delete draft.quote;
          delete draft.fee;
        });
        form.setFieldsValue({
          quoteCurrency: getCurrency(defaultFee.currency),
          quotePrice: isValidPriceValue(defaultFee.value)
            ? formatCurrency(defaultFee.value)
            : undefined,
          feeCurrency: getCurrency(defaultFee.currency),
          feePrice: isValidPriceValue(defaultFee.value)
            ? formatCurrency(defaultFee.value)
            : undefined,
        });
      } else {
        setTermData((draft) => {
          draft.type = e.target.value as TermTypes;
        });
      }
      updateTermChanged();
    },
    [form, getCurrency, setTermData, updateTermChanged]
  );

  const getNoteFields = useCallback(
    (labelName: string, field: string[]) => {
      return (
        <div css={mainStyle.collapsibleSectionContainer} style={{ rowGap: 0 }}>
          <div css={mainStyle.basicInputContainer}>
            <Typography.Text css={mainStyle.basicInputLabel}>
              {t(`ProjectsPage##licensingSection##${labelName}`)}
            </Typography.Text>
          </div>
          <Form.Item
            css={[styles.radioButtonSection, mainStyle.formItem]}
            name={field[0]}
          >
            <Input.TextArea
              aria-label={field[0]}
              onBlur={(e) => {
                setTermData((draft) => {
                  switch (labelName) {
                    case NoteFieldTypes.TerritoriesNote:
                      draft.territoriesNote = e.target.value;
                      break;
                    case NoteFieldTypes.ScriptNotes:
                      draft.scriptNotes = e.target.value;
                      break;
                    case NoteFieldTypes.MediaTypeNote:
                      draft.mediaTypeNote = e.target.value;
                      break;
                    default:
                      break;
                  }
                });
                form.setFieldsValue({
                  [field[0]]: e.target.value,
                });
                updateTermChanged();
              }}
              rows={5}
            />
          </Form.Item>
        </div>
      );
    },
    [form, setTermData, t, updateTermChanged]
  );

  return (
    <div css={mainStyle.projectDetailsContainer}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialFormValues}
        aria-label="form"
        scrollToFirstError
        css={mainStyle.basicInput}
      >
        <div css={mainStyle.collapsibleSectionContainer}>
          <div css={mainStyle.sectionFormContainer}>
            <Typography.Text css={mainStyle.trackTitleLabel}>
              {t("ProjectsPage##licensingSection##Type")}
            </Typography.Text>
            <div css={mainStyle.termTypeAndFeesSection}>
              <Form.Item
                css={[
                  styles.radioButtonSection,
                  mainStyle.formItem,
                  mainStyle.termType,
                ]}
                name={FIELDS.type}
                rules={getStringValidationRule()}
              >
                <Radio.Group onChange={onTermTypeChange}>
                  <Radio value={TermTypes.Term}>
                    {t("ProjectsPage##licensingSection##Term")}
                  </Radio>
                  <Radio value={TermTypes.Option}>
                    {t("ProjectsPage##licensingSection##Option")}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {formData.type === TermTypes.Option && (
                <>
                  <div
                    css={mainStyle.sizeOfQuarterDiv}
                    style={{ marginRight: "1.5rem" }}
                  >
                    <CurrencyAndPrice
                      propertyName="quote"
                      currencyFormItemName="quoteCurrency"
                      priceFormItemName="quotePrice"
                      label={t("ProjectsPage##licensingSection##Quote")}
                      currency={quoteCurrency}
                      price={quotePrice}
                      onChange={onMonetaryValueChange}
                      validateRequiredFields={false}
                    />
                  </div>
                  <div css={mainStyle.sizeOfQuarterDiv}>
                    <CurrencyAndPrice
                      propertyName="fee"
                      currencyFormItemName="feeCurrency"
                      priceFormItemName="feePrice"
                      label={t("ProjectsPage##licensingSection##Fee")}
                      currency={feeCurrency}
                      price={feePrice}
                      onChange={onMonetaryValueChange}
                      validateRequiredFields={false}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <Divider css={mainStyle.collapsibleSectionDivider} />
          <div css={mainStyle.collapsibleSectionContainer}>
            <div css={mainStyle.sectionFormContainer}>
              <Typography.Text css={mainStyle.trackTitleLabel}>
                {t("ProjectsPage##licensingSection##Name")}
              </Typography.Text>
            </div>
            <Form.Item
              name={FIELDS.name}
              rules={getStringValidationRule()}
              css={mainStyle.formItem}
            >
              <Input
                type="text"
                css={styles.standardInput}
                onBlur={(e) => {
                  setTermData((draft) => {
                    draft.name = e.target.value;
                  });
                  updateTermChanged();
                }}
              />
            </Form.Item>
          </div>
          <Divider css={mainStyle.collapsibleSectionDivider} />
          <div css={mainStyle.collapsibleSectionContainer}>
            <div css={mainStyle.sectionFormContainer}>
              <Typography.Text css={mainStyle.trackTitleLabel}>
                {t("ProjectsPage##licensingSection##MediaType")}
              </Typography.Text>
            </div>
            <MediaTypes
              mediaTypes={mediaTypes}
              onMediaTypeClick={handleMediaTypeClick}
              addCustomMediaType={addCustomMediaType}
              onRemoveCustomMediaType={removeCustomMediaType}
              renameCustomMediaType={renameCustomMediaType}
              hasFinalTrackSelected={hasFinalTracks}
            />
            {getNoteFields(NoteFieldTypes.MediaTypeNote, FIELDS.mediaTypeNote)}
          </div>
          <Divider css={mainStyle.collapsibleSectionDivider} />
          <div css={mainStyle.collapsibleSectionContainer}>
            <div css={mainStyle.sectionFormContainer}>
              <Typography.Text css={mainStyle.trackTitleLabel}>
                {t("ProjectsPage##licensingSection##Territories")}
              </Typography.Text>
            </div>
            <Territories
              setTerritories={handleSetTerritories}
              territories={term?.territories}
              markAsRequired={areTerritoriesRequired}
              updateTermChanged={updateTermChanged}
            />
            {getNoteFields(
              NoteFieldTypes.TerritoriesNote,
              FIELDS.territoriesNote
            )}
          </div>
          <Divider css={mainStyle.collapsibleSectionDivider} />
          <div css={mainStyle.collapsibleSectionContainer}>
            <div css={mainStyle.sectionFormContainer}>
              <Typography.Text css={mainStyle.trackTitleLabel}>
                {t("ProjectsPage##licensingSection##License period")}
              </Typography.Text>
            </div>
            <div css={styles.licensingPeriodContainer}>
              <div css={styles.startLicenseDate}>
                <div css={mainStyle.basicInputContainer}>
                  <Typography.Text css={mainStyle.basicInputLabel}>
                    {t("ProjectsPage##licensingSection##Start date")}
                  </Typography.Text>
                </div>
                <Form.Item
                  name={FIELDS.startDate[0]}
                  css={mainStyle.formItem}
                  dependencies={[FIELDS.endDate[0], FIELDS.fixedDuration[0]]}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_rule, value: Dayjs | undefined) {
                        const endDate = getFieldValue(FIELDS.endDate[0]) as
                          | Dayjs
                          | undefined;
                        if (value && endDate && value.isAfter(endDate)) {
                          return Promise.reject(
                            new Error(
                              t(
                                "ProjectsPage##licensingSection##Start date must be before the end date"
                              )
                            )
                          );
                        }
                        if (hasFinalTracks && !value) {
                          return Promise.reject(
                            new Error("This is a required field")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    allowClear
                    format={DateDisplayFormat}
                    className="startDate"
                    onChange={(value: Dayjs | null): void => {
                      if (value) {
                        setTermData((draft) => {
                          draft.startDate = value;
                        });

                        if (
                          formData.fixedDuration &&
                          formData.fixedDuration !==
                            LicenseFixedDurations.CustomDuration
                        ) {
                          const derivedEndDate = getDerivedTermEndDate(
                            value ?? undefined,
                            undefined,
                            formData.fixedDuration as LicenseFixedDurations
                          );

                          setTermData((draft) => {
                            draft.endDate = derivedEndDate;
                          });
                          form.setFieldsValue({ endDate: derivedEndDate });
                        }
                      } else {
                        setTermData((draft) => {
                          draft.startDate = undefined;
                          draft.endDate = undefined;
                        });
                        form.setFieldsValue({ endDate: undefined });
                      }
                      updateTermChanged();
                    }}
                    placeholder=""
                  />
                </Form.Item>
              </div>
              <div css={styles.licensingPeriod(!hideEndDate)}>
                <div>
                  <div css={mainStyle.basicInputContainer}>
                    <Typography.Text css={mainStyle.basicInputLabel}>
                      {t("ProjectsPage##licensingSection##Duration")}
                    </Typography.Text>
                  </div>
                  <Form.Item
                    css={mainStyle.formItem}
                    name={FIELDS.fixedDuration}
                    rules={getStringValidationRule()}
                  >
                    <ReactSelect
                      options={getDurationOptions()}
                      styles={customStyles}
                      isClearable
                      css={[styles.checkBoxText, styles.durationInput]}
                      onChange={async (value?: IOption) => {
                        let derivedTermEndDate = getDerivedTermEndDate(
                          formData.startDate
                            ? dayjs(formData.startDate, ISODateParseFormat)
                            : undefined,
                          undefined,
                          value?.value as LicenseFixedDurations
                        );
                        if (
                          value?.value === LicenseFixedDurations.CustomDuration
                        ) {
                          derivedTermEndDate = undefined;
                        }

                        setTermData((draft) => ({
                          ...draft,
                          endDate: derivedTermEndDate,
                          fixedDuration: value?.value as LicenseFixedDurations,
                        }));
                        form.setFieldsValue({
                          endDate: derivedTermEndDate,
                        });
                        updateTermChanged();
                        await form.validateFields([FIELDS.endDate[0]]);
                      }}
                    />
                  </Form.Item>
                </div>
                {!hideEndDate ? (
                  <div>
                    <div css={mainStyle.basicInputContainer}>
                      <Typography.Text css={mainStyle.basicInputLabel}>
                        {t("ProjectsPage##licensingSection##End date")}
                      </Typography.Text>
                    </div>
                    <Form.Item
                      dependencies={[
                        FIELDS.startDate[0],
                        FIELDS.fixedDuration[0],
                      ]}
                      css={mainStyle.formItem}
                      name={FIELDS.endDate[0]}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_rule, value: Dayjs | undefined) {
                            const startDate = getFieldValue(
                              FIELDS.startDate[0]
                            ) as Dayjs | undefined;
                            const fixedDuration = getFieldValue(
                              FIELDS.fixedDuration[0]
                            ) as string | undefined;
                            if (
                              startDate &&
                              value &&
                              value.isBefore(startDate)
                            ) {
                              setDateRequired(true);

                              return Promise.reject(
                                new Error(
                                  t(
                                    "ProjectsPage##licensingSection##End date must be after the start date"
                                  )
                                )
                              );
                            }
                            if (
                              hasFinalTracks &&
                              fixedDuration?.value ===
                                LicenseFixedDurations.CustomDuration &&
                              !value
                            ) {
                              setDateRequired(true);
                              return Promise.reject(
                                new Error("This is a required field")
                              );
                            }
                            setDateRequired(false);
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        className="endDate"
                        format={DateDisplayFormat}
                        allowClear
                        disabled={
                          formData.fixedDuration !==
                          LicenseFixedDurations.CustomDuration
                        }
                        onChange={(value: Dayjs | null): void => {
                          if (value) {
                            setTermData((draft) => {
                              draft.endDate = value;
                            });
                          } else {
                            setTermData((draft) => {
                              draft.endDate = undefined;
                            });
                          }
                          updateTermChanged();
                        }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <Divider css={mainStyle.collapsibleSectionDivider} />
          <div css={mainStyle.collapsibleSectionContainer}>
            <ScriptModal
              showModal={showScriptsModal}
              setShowModal={setScriptsModal}
              removeAllScripts={removeAllScripts}
              resetScriptForm={resetScriptForm}
            />

            <div css={mainStyle.sectionFormContainer}>
              <Typography.Text css={mainStyle.trackTitleLabel}>
                {t("ProjectsPage##licensingSection##Film/Scripts")}
              </Typography.Text>
            </div>
            <Form.Item
              css={mainStyle.formItem}
              name={FIELDS.hasUnlimitedScripts[0]}
            >
              <Radio.Group
                value={formData.hasUnlimitedScripts}
                css={styles.checkBoxText}
              >
                <Radio
                  onClick={() => {
                    form.setFieldsValue({ [FIELDS.scriptNotes[0]]: "" });
                    setTermData((draft) => {
                      draft.scriptNotes = "";
                    });
                    form.setFieldsValue({
                      [FIELDS.hasUnlimitedScripts[0]]: false,
                    });
                    handleUnlimitedScripts(false);
                    updateTermChanged();
                  }}
                  value={false}
                  data-testid="number-of-scripts-radio-button"
                >
                  {t(
                    "ProjectsPage##licensingSection##I have a number of scripts"
                  )}
                </Radio>
                <Radio
                  onClick={() => {
                    form.setFieldsValue({
                      [FIELDS.hasUnlimitedScripts[0]]: true,
                    });
                    handleUnlimitedScripts(true);
                    updateTermChanged();
                  }}
                  value
                  data-testid="unlimited-scripts-radio-button"
                >
                  {t("ProjectsPage##licensingSection##Unlimited scripts")}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {showScriptsForm ? (
              <Scripts
                form={form}
                hasUnlimitedScripts={formData.hasUnlimitedScripts}
                scripts={scripts}
                handleScripts={(newScripts: IScript[]) => {
                  setScripts(newScripts);
                  updateTermChanged();
                }}
                css={mainStyle.iconsContainer}
                nameField={FIELDS.scriptName[0]}
                noField={FIELDS.scriptNo[0]}
                durationField={FIELDS.duration[0]}
                cutdownsField={FIELDS.cutdowns[0]}
                detailsField={FIELDS.scriptDetails[0]}
              />
            ) : null}
            {formData.hasUnlimitedScripts &&
              getNoteFields(NoteFieldTypes.ScriptNotes, FIELDS.scriptNotes)}
          </div>
          <Divider css={mainStyle.collapsibleSectionDivider} />
          <div css={mainStyle.collapsibleSectionContainer}>
            <div css={mainStyle.sectionFormContainer}>
              <Typography.Text css={mainStyle.trackTitleLabel}>
                {t("ProjectsPage##licensingSection##Exclusivity")}
              </Typography.Text>
            </div>
            <Form.Item
              css={[
                isMobile
                  ? styles.mobileRadioButtonSection
                  : styles.radioButtonSection,
                mainStyle.formItem,
              ]}
              name={FIELDS.exclusivity[0]}
            >
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setTermData((draft) => {
                    draft.exclusivity = e.target.value as Exclusivity;
                  });
                  updateTermChanged();
                }}
              >
                <Radio value={Exclusivity.None}>
                  {t("ProjectsPage##licensingSection##ExclusivityTypes##None")}
                </Radio>
                <Radio value={Exclusivity.Full}>
                  {t("ProjectsPage##licensingSection##ExclusivityTypes##Full")}
                </Radio>
                <Radio value={Exclusivity.Market}>
                  {t(
                    "ProjectsPage##licensingSection##ExclusivityTypes##Market"
                  )}
                </Radio>
                <Radio value={Exclusivity.Category}>
                  {t(
                    "ProjectsPage##licensingSection##ExclusivityTypes##Category"
                  )}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Divider css={mainStyle.collapsibleSectionDivider} />
          <div css={mainStyle.formItemContainer}>
            <div css={mainStyle.collapsibleSectionContainer}>
              <div css={mainStyle.sectionFormContainer}>
                <Typography.Text css={mainStyle.trackTitleLabel}>
                  {t("ProjectsPage##licensingSection##LyricDetails")}
                </Typography.Text>
              </div>
            </div>
            <div
              css={[
                mainStyle.collapsibleSectionContainer,
                !isMobile && mainStyle.sizeOfQuarterDiv,
              ]}
              style={{ rowGap: 0 }}
            >
              <div css={mainStyle.basicInputContainer}>
                <Typography.Text css={mainStyle.basicInputLabel}>
                  {t("ProjectsPage##licensingSection##LyricChange")}
                </Typography.Text>
              </div>
              <Form.Item
                css={[styles.radioButtonSection, mainStyle.formItem]}
                name={FIELDS.lyricChange[0]}
              >
                <ReactSelect
                  menuPlacement="auto"
                  styles={customStyles}
                  aria-label="lyric-change-dropdown"
                  placeholder=""
                  isSearchable
                  onChange={async (value) => {
                    const selectedOption = value as IOption;
                    setTermData((draft) => {
                      draft.lyricChange = {
                        ...draft.lyricChange,
                        checked: convertConfirmationOptionToBooleanOrNull(
                          selectedOption
                        ),
                      };
                    });
                    form.setFieldsValue({
                      [FIELDS.lyricChange[0]]: selectedOption,
                    });
                    await form.validateFields([FIELDS.lyricChangeNotes[0]]);
                    updateTermChanged();
                  }}
                  options={confirmationOptions}
                  id="lyric-change-dropdown"
                />
              </Form.Item>
            </div>
            <div
              css={mainStyle.collapsibleSectionContainer}
              style={{ rowGap: 0 }}
            >
              <div css={mainStyle.basicInputContainer}>
                <Typography.Text css={mainStyle.basicInputLabel}>
                  {t("ProjectsPage##licensingSection##LyricChangeNotes")}
                </Typography.Text>
              </div>
              <Form.Item
                css={[styles.radioButtonSection, mainStyle.formItem]}
                name={FIELDS.lyricChangeNotes[0]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_rule, value: string) {
                      const lyricChange = getFieldValue(
                        FIELDS.lyricChange[0]
                      ) as IOption;
                      if (lyricChange.value === "yes" && !value) {
                        return Promise.reject(
                          new Error("This is a required field")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.TextArea
                  aria-label={FIELDS.lyricChangeNotes[0]}
                  onBlur={(e) => {
                    setTermData((draft) => {
                      draft.lyricChange = {
                        ...draft.lyricChange,
                        notes: e.target.value,
                      };
                    });
                    form.setFieldsValue({
                      [FIELDS.lyricChangeNotes[0]]: e.target.value,
                    });
                    updateTermChanged();
                  }}
                  rows={5}
                />
              </Form.Item>
            </div>
          </div>
          <Divider css={mainStyle.collapsibleSectionDivider} />
          <div css={mainStyle.collapsibleSectionContainer}>
            <div css={styles.licenseNoteContainer}>
              <Typography.Text css={mainStyle.trackTitleLabel}>
                {t("ProjectsPage##licensingSection##Additional notes")}
              </Typography.Text>
              <Typography.Text css={mainStyle.disclaimerLabel}>
                <DisclaimerInfoIcon />
                {t(
                  "ProjectsPage##licensingSection##These notes can be shared with client"
                )}
              </Typography.Text>
            </div>
            <div>
              <div css={mainStyle.basicInputContainer}>
                <Typography.Text css={mainStyle.basicInputLabel}>
                  {t(
                    "ProjectsPage##licensingSection##Comments, special requests etc."
                  )}
                </Typography.Text>
              </div>
              <Form.Item name={FIELDS.notes} css={mainStyle.formItem}>
                <Input.TextArea
                  aria-label={FIELDS.notes[0]}
                  onBlur={(e) => {
                    setTermData((draft) => {
                      draft.notes = e.target.value;
                    });
                    updateTermChanged();
                  }}
                  rows={5}
                />
              </Form.Item>
            </div>
          </div>
          <>
            <div
              css={mainStyle.collapsibleSectionContainer}
              style={{ rowGap: 0 }}
            >
              <div css={mainStyle.basicInputContainer}>
                <Typography.Text css={mainStyle.basicInputLabel}>
                  {t("ProjectsPage##licensingSection##OptionToExtend")}
                </Typography.Text>
              </div>
              <Form.Item
                css={[styles.radioButtonSection, mainStyle.formItem]}
                name={FIELDS.optionToExtend[0]}
              >
                <Input.TextArea
                  aria-label={FIELDS.optionToExtend[0]}
                  onBlur={(e) => {
                    setTermData((draft) => {
                      draft.optionToExtend = e.target.value;
                    });
                    form.setFieldsValue({
                      [FIELDS.optionToExtend[0]]: e.target.value,
                    });
                    updateTermChanged();
                  }}
                  rows={5}
                />
              </Form.Item>
            </div>
            <div
              css={[
                mainStyle.collapsibleSectionContainer,
                !isMobile && mainStyle.sizeOfQuarterDiv,
              ]}
              style={{ rowGap: 0 }}
            >
              <div css={mainStyle.basicInputContainer}>
                <Typography.Text css={mainStyle.basicInputLabel}>
                  {t("ProjectsPage##licensingSection##OnlineArchival")}
                </Typography.Text>
              </div>
              <Form.Item
                css={[styles.radioButtonSection, mainStyle.formItem]}
                name={FIELDS.onlineArchival[0]}
              >
                <ReactSelect
                  menuPlacement="auto"
                  styles={customStyles}
                  aria-label="online-archival-dropdown"
                  placeholder=""
                  isSearchable
                  onChange={(value) => {
                    const selectedOption = value as IOption;
                    setTermData((draft) => {
                      draft.onlineArchival = convertConfirmationOptionToBooleanOrNull(
                        selectedOption
                      );
                    });
                    form.setFieldsValue({
                      [FIELDS.onlineArchival[0]]: selectedOption,
                    });
                    updateTermChanged();
                  }}
                  options={confirmationOptions}
                  id="online-archival-dropdown"
                />
              </Form.Item>
            </div>
          </>
          <Divider css={mainStyle.collapsibleSectionDivider} />
          <div css={mainStyle.collapsibleSectionContainer}>
            <div css={mainStyle.sectionFormContainer}>
              <Typography.Text css={mainStyle.trackTitleLabel}>
                {t("ProjectsPage##licensingSection##Tracks with this license")}
              </Typography.Text>
            </div>
          </div>

          {project?.tracks ? (
            <div css={mainStyle.trackWithLicensesContainer}>
              <Form.Item
                css={[styles.radioButtonSection, mainStyle.formItem]}
                name={FIELDS.appliesToAllFinalTracks[0]}
                style={getStyleForAppliesToAllFieldsRequired()}
              >
                <Radio.Group
                  onChange={(e: RadioChangeEvent) => {
                    handleApplyToTrackChange(e.target.value);
                    updateTermChanged();
                  }}
                  value={showFinalTracksSelect}
                >
                  <div
                    css={
                      isMobile
                        ? styles.mobileRadioGroupContainer
                        : styles.radioGroupContainer
                    }
                  >
                    <Radio
                      value
                      css={styles.radioBtn}
                      onClick={() => {
                        form.setFieldsValue({
                          [FIELDS.appliesToAllFinalTracks[0]]: true,
                          [FIELDS.finalTracks[0]]: [],
                        });
                        setTermData({
                          ...formData,
                          finalTracks: [],
                        });
                      }}
                    >
                      {t(
                        "ProjectsPage##licensingSection##This license applies to all final tracks in this project"
                      )}
                    </Radio>
                    <Radio
                      value={false}
                      onClick={() => {
                        form.setFieldsValue({
                          [FIELDS.appliesToAllFinalTracks[0]]: false,
                        });
                      }}
                      css={styles.radioBtn}
                    >
                      {t(
                        "ProjectsPage##licensingSection##This license applies to specific final tracks in this project"
                      )}
                    </Radio>{" "}
                  </div>
                </Radio.Group>
              </Form.Item>

              {!showFinalTracksSelect && (
                <Form.Item name={FIELDS.finalTracks} css={mainStyle.formItem}>
                  <div css={mainStyle.basicInputContainer}>
                    <Typography.Text css={mainStyle.basicInputLabel}>
                      {t("ProjectsPage##licensingSection##Final tracks")}
                    </Typography.Text>
                  </div>
                  <Row css={styles.row}>
                    <ReactSelect
                      isMulti
                      placeholder=""
                      isClearable={false}
                      styles={customStyles}
                      options={parsedTermTracks}
                      defaultValue={tracksInitialValue}
                      onChange={(data) => {
                        selectMultiValue(data as IOption[]);
                        updateTermChanged();
                      }}
                    />
                  </Row>
                </Form.Item>
              )}
            </div>
          ) : (
            <Typography.Text css={styles.noTrackText}>
              {t("ProjectsPage##licensingSection##No tracks")}
            </Typography.Text>
          )}
          <div css={styles.buttonRow}>
            <SecondaryModalButtonLink
              wrapperStyles={styles.secondaryButton}
              onClick={() => {
                onChangesMade({ [term?.id ?? "new"]: false }, false);
                onCancelTerm();
              }}
              ariaLabel="Cancel"
              buttonText="Cancel"
              css={styles.cancelButton}
            />
            <Button
              noLabelTranslation
              loading={isSubmitClicked}
              ariaLabel="Submit"
              css={styles.submitButton}
              onClick={() => submitForm()}
              data-testid="add-update-service-button"
            >
              {getSubmitButtonText()}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TermForm;
