import { Button } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import PlusIcon from "src/app/assets/icons/component-icons/buttons/plus";
import { getIsMobile } from "@songtradr/spa-common/lib/utils";
import styles from "./styles";

interface IProps {
  onClick: () => void;
  ariaLabel: string;
  label: string;
  dataTestId: string;
  disabled?: boolean;
  isAddAnother?: boolean;
}

const ButtonWithLabelAndPlusIcon = ({
  onClick,
  ariaLabel,
  label,
  dataTestId,
  disabled,
  isAddAnother,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  return (
    <div css={isMobile ? styles.mobileContainer : styles.container}>
      <Button
        css={[styles.button, isAddAnother && styles.addAnotherButton]}
        disabled={disabled}
        tabIndex={0}
        aria-label={t(ariaLabel)}
        data-testid={dataTestId}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <div css={styles.iconContainer}>
          <div>{t(label)}</div>
          <PlusIcon width={38} height={38} />
        </div>
      </Button>
    </div>
  );
};

export default ButtonWithLabelAndPlusIcon;
