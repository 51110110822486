import React, { Fragment, ReactElement, useCallback } from "react";
import { useWindowSize } from "@songtradr/spa-common";
import { DebouncedFunc } from "lodash";
import ProjectDetails from "./components/details";
import FinalTrackInfo from "./components/final-track-info";
import FinalTrackDetails from "./components/final-track-details";
import Documents from "./components/documents";
import Licensing from "./components/licensing";
import Assets from "./components/assets";
import formatNameList, { INameArray } from "./utils";
import Overview from "./components/overview";
import Footer from "./components/footer";
import {
  IProjectAttachment,
  IProjectForm,
  IProjectTalents,
} from "../project/interfaces";
import { IProjectUploadFile } from "../project/components/details/components/interfaces";

interface IProps {
  projectData: IProjectForm;
  downloadFile?: DebouncedFunc<(file: IProjectUploadFile) => Promise<void>>;
  tracks?: IProjectAttachment[];
}

const ShareProjectContainer = ({
  projectData,
  downloadFile,
  tracks,
}: IProps): ReactElement => {
  useWindowSize();

  const handleDownloadTrack = async (data: IProjectAttachment) => {
    if (downloadFile) {
      await downloadFile(data);
    }
  };

  const getMappedTalentsName = useCallback((talents?: IProjectTalents[]) => {
    return talents?.map((talent) => talent.name).join(", ");
  }, []);

  return (
    <Fragment>
      <ProjectDetails
        name={projectData.name}
        leads={projectData.leads}
        product={projectData.product}
      />
      <FinalTrackDetails
        tracks={tracks}
        handleDownloadClick={handleDownloadTrack}
        artists={getMappedTalentsName(
          projectData.music?.finalTrack?.artistContacts
        )}
        title={projectData.music?.finalTrack?.title}
      />
      <FinalTrackInfo
        writers={getMappedTalentsName(
          projectData.music?.finalTrack?.writerContacts
        )}
        publisher={formatNameList(
          projectData?.music?.finalTrack?.publisherFeeSplits as INameArray[]
        )}
        master={formatNameList(
          projectData?.music?.finalTrack?.masterFeeSplits as INameArray[]
        )}
      />
      <Licensing terms={projectData.terms} />
      <Overview
        productName={projectData.product}
        productCategory={projectData.category}
        contractStartDate={projectData.contractStartDate}
        contractEndDate={projectData.contractEndDate}
        projectType={projectData.projectType}
        musicType={projectData.musicType}
        description={projectData.description}
      />
      <Assets
        musicAssets={projectData.music.musicAssets}
        finalVideo={projectData.music.finalVideo}
      />
      <Documents
        purchaseOrders={projectData.purchaseOrderAttachments}
        invoices={projectData.invoiceAttachments}
        licenses={projectData.licenseAttachments}
        estimates={projectData.estimateAttachments}
        miscellaneous={projectData.miscAttachments}
        assets={projectData.assetAttachments}
        downloadFile={downloadFile}
      />
      <Footer />
    </Fragment>
  );
};

export default ShareProjectContainer;
