import axios from "axios";
import { IProjectForm } from "src/pages/projects/project/interfaces";
import { getProjectUrl } from "../../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const getProjectPreviewSummary = (
  accessToken: string,
  projectId: string,
  organisationId: string
): Promise<IProjectForm | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const URL = `${getProjectUrl(projectId, organisationId)}/summary`;

  return axios.get(URL, options).then(({ data }) => {
    return data as IProjectForm;
  });
};

export default getProjectPreviewSummary;
