import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import BaseModal from "src/components/modals/base-modal";
import Content from "src/components/modals/confirmation-modal/content";
import { IConfirmContentProps } from "src/components/interfaces";
import DeleteButton from "src/components/control-buttons/delete-button";
import DuplicateButton from "src/components/control-buttons/duplicate-button/DuplicateButton";
import style from "../../../../../styles";
import { ITermSummaryViewModel } from "../../../interfaces";

interface IProps {
  viewModel: ITermSummaryViewModel;
  onDuplicateTerm: () => void;
  onDeleteClick: () => void;
}
const CollapsibleLicensingHeaderDeleteAndDuplicate = ({
  viewModel,
  onDuplicateTerm,
  onDeleteClick,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnCloseModalClick = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleConfirmDeleteClick = useCallback(() => {
    onDeleteClick();
    setIsModalOpen(false);
  }, [onDeleteClick]);

  const handleDuplicateClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      onDuplicateTerm();
    },
    [onDuplicateTerm]
  );

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setIsModalOpen(true);
    },
    []
  );

  const confirmDeleteContentProps: IConfirmContentProps = useMemo(() => {
    return {
      confirmAction: handleConfirmDeleteClick,
      onClose: handleOnCloseModalClick,
      primaryButtonLabel: t("Delete"),
      secondaryButtonLabel: t("Cancel"),
      mainContent: (
        <p data-testid="confirmation-content">
          <Trans i18nKey="ProjectsPage##licensingSection##deleteLicenseMessage">
            {{ licenseName: viewModel?.name }}
          </Trans>
        </p>
      ),
    };
  }, [handleConfirmDeleteClick, handleOnCloseModalClick, t, viewModel?.name]);

  return (
    <div css={style.switchContainer}>
      <div css={style.addIconContainer}>
        <DuplicateButton onClick={handleDuplicateClick} />
      </div>
      <DeleteButton onClick={handleDeleteClick} />
      <BaseModal
        open={isModalOpen}
        content={<Content {...confirmDeleteContentProps} />}
        buttons={[]}
        onClose={handleOnCloseModalClick}
        onCancel={handleOnCloseModalClick}
      />
    </div>
  );
};

export default CollapsibleLicensingHeaderDeleteAndDuplicate;
