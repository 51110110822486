import { Divider, Form, Input, Typography } from "antd";
import Select from "react-select";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { Currencies } from "src/constants";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import {
  convertToNumber,
  formatCurrency,
  isValidNumber,
  stripCurrencyFormatting,
} from "src/utils/currency";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import { getIsMobile } from "@songtradr/spa-common";
import styles, { customStyles } from "../monetary-value/styles";
import mainStyles from "../../pages/projects/project/styles";
import NumericInputEvents from "./utils";

interface IProps {
  currency: string;
  price: number | string;
  currencyFormItemName: string;
  priceFormItemName: string;
  onChange: (field: string, value: IMonetaryValue) => void;
  label: string;
  testId?: string;
  propertyName: string;
  validateRequiredFields: boolean;
}

const CurrencyAndPrice = ({
  currency,
  price,
  currencyFormItemName,
  priceFormItemName,
  onChange,
  label,
  testId,
  propertyName,
  validateRequiredFields,
}: IProps): ReactElement => {
  const isMobile = getIsMobile();
  const [selectedCurrency, setSelectedCurrency] = useState(currency);
  const handlePrecision = useCallback((valueWithoutPrecision: string) => {
    if (valueWithoutPrecision) {
      const preciseValue = (
        Math.round(
          parseFloat(stripCurrencyFormatting(valueWithoutPrecision)) * 100
        ) / 100
      ).toFixed(2);
      const formattedPreciseValue = formatCurrency(preciseValue);
      return formattedPreciseValue;
    }
    return valueWithoutPrecision;
  }, []);

  const handleValueOnBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const trimmedNewValue = event.target.value.trim();
      const oldValue = String(price);
      const trimmedOldValue = oldValue.trim();

      let newValueToUse: string | number;
      let newValueValid: boolean;

      if (trimmedNewValue !== "" && isValidNumber(trimmedNewValue)) {
        newValueToUse = handlePrecision(trimmedNewValue);
        newValueValid = true;
      } else if (trimmedNewValue === "" && trimmedOldValue !== "") {
        newValueToUse = "0.00";
        newValueValid = true;
      } else {
        newValueToUse = event.target.value;
        newValueValid = false;
      }

      if (newValueValid) {
        const oldValueToUse =
          trimmedOldValue === "" || !isValidNumber(String(price))
            ? oldValue
            : handlePrecision(oldValue);

        if (newValueToUse !== "" && oldValueToUse !== newValueToUse) {
          onChange(propertyName, {
            currency: selectedCurrency,
            value: convertToNumber(newValueToUse),
          });
        }
      }
    },
    [handlePrecision, onChange, price, propertyName, selectedCurrency]
  );

  const handleCurrencyChange = useCallback(
    (currencyChange: IOption) => {
      setSelectedCurrency(currencyChange.value);
      if (String(price).trim() !== "" && isValidNumber(String(price))) {
        onChange(propertyName, {
          currency: currencyChange.value,
          value: convertToNumber(String(price)),
        });
      }
    },
    [onChange, price, propertyName]
  );

  const currencyOptions: IOption[] = useMemo(
    () =>
      Object.values(Currencies).map((value) => {
        return {
          value: value.code,
          label: value.displayText,
        };
      }),
    []
  );

  return (
    <div>
      <div css={mainStyles.basicInputContainer}>
        <Typography.Text css={mainStyles.basicInputLabel}>
          {label}
        </Typography.Text>
      </div>
      <div id={label} css={styles.feeContainer}>
        <div css={mainStyles.feeContainer}>
          <Form.Item
            data-testid={testId}
            name={currencyFormItemName}
            css={mainStyles.formItem}
          >
            <Select
              menuPlacement="auto"
              className={`${testId || ""}Select`}
              styles={customStyles}
              instanceId={testId}
              onChange={(currencyChange) => {
                if (currencyChange && currencyChange.value !== currency) {
                  handleCurrencyChange(currencyChange);
                }
              }}
              options={currencyOptions}
              isMulti={false}
            />
          </Form.Item>
        </div>
        <div css={mainStyles.verticalDividerContainer}>
          <Divider type="vertical" css={mainStyles.verticalDivider} />
        </div>
        <div
          css={[!isMobile && mainStyles.sizeOfHalfDiv, mainStyles.priceInput]}
        >
          <div css={mainStyles.basicInput}>
            <Form.Item
              css={mainStyles.formItem}
              validateTrigger={["onBlur"]}
              rules={[
                () => ({
                  validator(_rule, value?: string) {
                    if (validateRequiredFields && !value) {
                      return Promise.reject(
                        new Error("This is a required field")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              name={priceFormItemName}
            >
              <Input
                data-testid={`${testId || ""}Input`}
                css={styles.valueInput}
                aria-label={label}
                onBlur={handleValueOnBlur}
                onKeyDown={NumericInputEvents.onKeyDownNumberInput}
                onPaste={NumericInputEvents.onPasteNumberInput}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyAndPrice;
