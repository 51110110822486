import { SelectOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { INameLink } from "src/pages/projects/project/interfaces";
import styles from "./styles";

interface IProps {
  writers?: string;
  publisher?: string[];
  master?: string[];
  finalTrackLinks?: INameLink[];
}

const FinalTrackInfo = ({
  writers,
  publisher,
  master,
  finalTrackLinks,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Row css={styles.wrapper} gutter={{ md: 16, lg: 32 }}>
      {writers && (
        <Col xs={24} sm={24} md={8}>
          <Typography css={styles.label}>
            {t("ProjectsPage##musicSection##Writer")}
          </Typography>
          <Typography css={styles.value}>{writers}</Typography>
        </Col>
      )}
      {publisher && publisher.length && (
        <Col xs={24} sm={24} md={8}>
          <Typography css={styles.label}>{t("Publisher")}</Typography>
          <Typography css={styles.value}>{publisher}</Typography>
        </Col>
      )}
      {master && master.length && (
        <Col xs={24} sm={24} md={8}>
          <Typography css={styles.label}>{t("Mastered")}</Typography>
          <Typography css={styles.value}>{master}</Typography>
        </Col>
      )}
      <hr css={styles.horizontalRule} />
      {finalTrackLinks &&
        finalTrackLinks.length &&
        finalTrackLinks
          .filter((track) => {
            if (!track.link || !track.name) {
              return false;
            }
            return true;
          })
          .map((track) => {
            return (
              <Col key={track.name} xs={24} sm={24} md={8}>
                <Typography css={styles.label}>{track.name}</Typography>
                <Typography css={[styles.value, styles.bold]}>
                  <a rel="noreferrer" target="_blank" href={track.link}>
                    {track.link}{" "}
                    <SelectOutlined css={styles.openNewIcon} rotate={90} />
                  </a>
                </Typography>
              </Col>
            );
          })}
    </Row>
  );
};

export default FinalTrackInfo;
