import { Typography } from "antd";
import { useTranslation } from "react-i18next";

type IProps = {
  text: string;
};
const NoData = ({ text }: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography.Text>{t(text)}</Typography.Text>
    </div>
  );
};

export default NoData;
