import axios from "axios";
import config from "src/config";
import getAuthHeader from "../headers/get-auth-header";
import getJsonContentHeader from "../headers/get-json-content-header";
import { IUserFilters } from "./interfaces";

async function getUserFilters(
  accessToken: string,
  organisationId: string
): Promise<IUserFilters> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  return axios
    .get(
      `${config.projectManagementService.url}/user?orgId=${organisationId}`,
      options
    )
    .then(({ data }) => data as IUserFilters);
}

export default getUserFilters;
