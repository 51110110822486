import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import theme from "src/theme";

const styles = {
  column: css`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    margin-left: 1rem;
    margin-bottom: 11rem;
    margin-top: 6rem;
    @media ${queries.medium} {
      justify-content: center;
      align-items: center;
      margin-top: 11rem;
    }
    @media ${queries.large} {
      justify-content: left;
      align-items: start;
      margin-left: auto;
    }
  `,

  formWrapper: css`
    width: 25.625rem;
    .ant-form-item-explain-error {
      font-family: ${theme.fonts.sofiaPro};
      line-height: 0.9375rem;
      margin-top: 0.5rem;
      font-size: 16px;
      color: ${theme.colors.functional.red};
    }
    p {
      overflow-wrap: break-word;
    }
  `,

  completeAccountImage: css`
    display: block;
    max-width: 21.875rem;
  `,

  topMargin: css`
    margin-top: 4rem;
  `,
};

export default styles;
