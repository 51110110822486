import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid ${theme.colors.primary.blue};
      -webkit-box-shadow: none;
      margin-left: -0.4rem;
      background: ${theme.colors.primary.blue};
      min-height: 2.6rem;
      color: white;
      border-radius: 4px;
      font-weight: 600;
      svg {
        margin-right: 0;
        circle {
          stroke: none;
        }
        line {
          stroke: ${theme.colors.white};
        }
      }

      :hover {
        background: ${theme.colors.secondary.navyBlue};
        border: 2px solid ${theme.colors.secondary.navyBlue};
        color: ${theme.colors.white};
        svg {
          margin-right: 0;
          circle {
            stroke: none;
          }
          line {
            stroke: ${theme.colors.white};
          }
        }
      }
      :focus {
        background: ${theme.colors.secondary.navyBlue};
        border: 3px solid ${theme.colors.secondary.navyBlue};
        color: ${theme.colors.white};
        svg {
          margin-right: 0;
          circle {
            stroke: none;
          }
          line {
            stroke: ${theme.colors.white};
          }
        }
      }
    }
  `,
  disabledContainer: css`
    button {
      &:disabled {
        border: 2px solid ${theme.colors.secondary.fadeToGrey} !important;
        background: ${theme.colors.secondary.fadeToGrey} !important;
        color: #888 !important;
        box-shadow: none !important;
      }
    }
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    font-family: ${theme.fonts.sofiaPro};
  `,
};
