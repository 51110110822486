import { Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import Button from "src/components/button";
import styles from "./styles";

interface IProps {
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
}

const Card = ({
  title,
  description,
  buttonText,
  buttonLink,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div css={styles.card} className="card">
        <Typography.Paragraph css={styles.title}>
          {t(title)}
        </Typography.Paragraph>
        <Typography.Paragraph css={styles.description}>
          {t(description)}
        </Typography.Paragraph>
        <Button
          css={styles.button}
          ariaLabel={t(buttonText)}
          noLabelTranslation
          onClick={() => history.push(buttonLink)}
        >
          {t(buttonText)}
        </Button>
      </div>
    </>
  );
};

export default Card;
