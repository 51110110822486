import axios from "axios";
import { IInvitemembersRequest } from "src/interfaces/invite-members";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";
import { sendOrganisationInvitesUrl } from "../../organisation-invites-url-helper";

async function sendOrganisationInvites(
  accessToken: string,
  organisationId: string,
  body: IInvitemembersRequest
): Promise<void> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.post(sendOrganisationInvitesUrl(organisationId), body, options);
}

export default sendOrganisationInvites;
