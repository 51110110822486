import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  error: css`
    align-items: start;
    font-family: ${theme.fonts.sofiaPro};
    display: flex;
    line-height: 0.9375rem;
    font-size: 0.75rem;
    color: ${theme.colors.functional.red};
    img {
      margin: 0 0.5rem 0.2rem 0.5rem;
      width: 1rem;
    }
    padding-left: 0.8rem !important;
    padding-top: 0.4rem !important;
  `,
};
