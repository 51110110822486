import { ReactElement, useCallback, useMemo } from "react";
import {
  INameLinkState,
  IProjectMusicSection,
  IProjectOtherAssetsData,
} from "src/pages/projects/project/interfaces";
import { PickProperties } from "ts-essentials";
import { Operation } from "src/constants";
import { cloneDeep } from "lodash";
import NameLinkRows from "../name-link-rows";

// PickProperties is a generic util where you can specify
// a subset of an interface, filtering that subset on type.
// Used here with keyof to limit the nameLink and nameLinkVersion props
// to only be the defined subset of IProjectMusicSection
// containing Name-Link properties

export interface INameLinkConfig {
  fieldName: string;
  nameLink: keyof PickProperties<IProjectMusicSection, Array<INameLinkState>>;
  nameLinkVersion: keyof PickProperties<IProjectMusicSection, number>;
  testDataId: string;
  sectionHeaderTranslationKey: string;
  nameHelpTextTranslationKey: string;
  buttonLabel?: string;
  additionalText?: string;
  additionalHeader?: string;
  customLinkText?: string;
}

interface IProps {
  config: INameLinkConfig;
  projectOtherAssets: IProjectOtherAssetsData;
  fileUploader?: JSX.Element;
  updateProjectOtherAssets: (
    projeprojectOtherAssetsct: IProjectOtherAssetsData
  ) => void;
}

const NameLinks = ({
  config,
  projectOtherAssets,
  fileUploader,
  updateProjectOtherAssets,
}: IProps): ReactElement => {
  const handlePerformOperation = useCallback(
    (operation: Operation, data: INameLinkState, index: number): void => {
      const newItems = cloneDeep(projectOtherAssets?.[config.nameLink] ?? []);

      switch (operation) {
        case Operation.add:
          newItems.push(data);
          updateProjectOtherAssets({
            ...projectOtherAssets,
            [config.nameLink]: newItems,
          });
          break;
        case Operation.remove:
          newItems.splice(index, 1);
          updateProjectOtherAssets({
            ...projectOtherAssets,
            [config.nameLink]: newItems,
          });
          break;
        case Operation.update:
          newItems[index] = data;
          updateProjectOtherAssets({
            ...projectOtherAssets,
            [config.nameLink]: newItems,
          });
          break;
        default:
          throw new Error("Invalid argument enum for Operation");
      }
    },
    [config.nameLink, projectOtherAssets, updateProjectOtherAssets]
  );

  const assetItems = useMemo(() => {
    return (projectOtherAssets?.[config.nameLink] ?? []).map((item) => ({
      ...item,
      key: `${item?.name || ""}-${item?.link || ""}`,
    }));
  }, [config.nameLink, projectOtherAssets]);

  return (
    <div data-testid={config.testDataId}>
      <NameLinkRows
        customLinkText={config.customLinkText}
        fileUploader={fileUploader}
        fieldName={config.fieldName}
        items={assetItems}
        nameHelpText={config.nameHelpTextTranslationKey}
        onPerformOperation={handlePerformOperation}
      />
    </div>
  );
};

export default NameLinks;
