import { getIsMobile } from "@songtradr/spa-common";
import { memo, ReactElement } from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
  IProjectAttachment,
  IProjectOtherAssetsData,
} from "../../../../interfaces";
import FinalVideo from "../final-video";
import MusicAssets from "../music-assets";
import style from "../../../../styles";

interface IProps {
  projectOtherAssets: IProjectOtherAssetsData;
  musicAssetsAttachments: IProjectAttachment[];
  finalVideoAssetsAttachments: IProjectAttachment[];
  updateProjectOtherAssets: (
    projectOtherAssets: IProjectOtherAssetsData
  ) => void;
}

const OtherAssets = memo(
  ({
    projectOtherAssets,
    updateProjectOtherAssets,
    musicAssetsAttachments,
    finalVideoAssetsAttachments,
  }: IProps): ReactElement => {
    const isMobile = getIsMobile();
    const { t } = useTranslation();

    return (
      <div>
        <div css={style.additionalAssetsContainer}>
          <div css={style.titleLabelContainer}>
            <Typography.Text css={style.titleLabel}>
              {t("ProjectsPage##musicSection##Assets")}
            </Typography.Text>
          </div>
        </div>
        <div
          css={[
            isMobile ? style.mobileSectionContainer : style.clientContainer,
            style.lastOfSectionBottom,
            style.additionalAssetSectionContainerStyles,
          ]}
        >
          <MusicAssets
            musicAssetsAttachments={musicAssetsAttachments}
            projectOtherAssets={projectOtherAssets}
            updateProjectOtherAssets={updateProjectOtherAssets}
          />
          <FinalVideo
            finalVideoAssetsAttachments={finalVideoAssetsAttachments}
            projectOtherAssets={projectOtherAssets}
            updateProjectOtherAssets={updateProjectOtherAssets}
          />
        </div>
      </div>
    );
  }
);

export default OtherAssets;
