import {
  InfiniteLoader,
  InfiniteLoaderChildProps,
  List,
  ListRowProps,
} from "react-virtualized";
import React, { useCallback, useMemo } from "react";
import STLoadingLogo from "src/components/st-loading-logo";
import { useTranslation } from "react-i18next/";
import { MinTalentHubInputDropDownWidth } from "src/constants";
import styles from "../../styles";

const LoadingInterval = 750;
export const MaxAgenciesListHeight = 415;
export const MinAgenciesListHeight = 360;

interface IAgenciesDropDownMenu {
  allAgencies: string[];
  allRows?: number;
  dropDownWidth?: number;
  pageNumber: number;
  isLoading: boolean;
  selectedAgency?: string;
  totalPages: number;
  tags: JSX.Element[];
  onSetPageNumber: (page: number) => void;
  onScrollAgencies: (page: number) => void;
  onSelectedAgencyChange: (agency: string) => void;
}

const AgencyWritingDropDownMenu = ({
  allAgencies,
  pageNumber,
  allRows,
  tags,
  dropDownWidth,
  isLoading,
  totalPages,
  selectedAgency,
  onSetPageNumber,
  onScrollAgencies,
  onSelectedAgencyChange,
}: IAgenciesDropDownMenu): JSX.Element => {
  const { t } = useTranslation();

  const isFinalPage = useMemo(() => {
    return totalPages <= pageNumber;
  }, [pageNumber, totalPages]);

  const rowCount = useMemo(() => allAgencies.length, [allAgencies.length]);

  const loadNextPage = useCallback((): Promise<void> => {
    return new Promise(() => {
      if (!isFinalPage) {
        setTimeout(() => {
          const newPageNumber = pageNumber + 1;
          onSetPageNumber(newPageNumber);
          onScrollAgencies(newPageNumber);
          // To enhance the user experience, we introduced a delay of 750ms before we dismiss the loader,
          // thereby preventing any flickering of the loader
        }, LoadingInterval);
      }
    });
  }, [isFinalPage, onScrollAgencies, onSetPageNumber, pageNumber]);
  const loadMoreRows = useCallback(async () => {
    await loadNextPage();
  }, [loadNextPage]);

  const isRowLoaded = useCallback(
    ({ index }: { index: number }) => {
      return isFinalPage || index < allAgencies.length;
    },
    [allAgencies.length, isFinalPage]
  );

  const rowRenderer = useCallback(
    ({ index, key, style }: ListRowProps) => {
      let content;
      if (!isRowLoaded({ index })) {
        content = (
          <div css={styles.agenciesSearchLoadWrapper}>
            <STLoadingLogo styles={styles.agenciesLoadingContainer} />
          </div>
        );
      } else {
        const agency = allAgencies[index];
        const isAgencySelected = agency === selectedAgency;
        content = (
          <div css={styles.rowWrapper}>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.preventDefault()}
              css={styles.agency(isAgencySelected)}
              onClick={() => onSelectedAgencyChange(agency)}
            >
              {agency}
            </div>
          </div>
        );
      }
      return (
        <div key={key} style={style} id="agency-dropdown">
          <div css={styles.agencyContent}>{content}</div>
        </div>
      );
    },
    [allAgencies, isRowLoaded, onSelectedAgencyChange, selectedAgency]
  );

  const dropdownlistwidth = useMemo(() => {
    if (dropDownWidth && dropDownWidth > MinTalentHubInputDropDownWidth) {
      return dropDownWidth;
    }
    return MinTalentHubInputDropDownWidth;
  }, [dropDownWidth]);

  const emptyAgencyDiv = useMemo(() => {
    return isLoading ? (
      <div>
        <STLoadingLogo />
      </div>
    ) : (
      <div css={styles.emptyAgency}>{t("TalentPage##No agency found")}</div>
    );
  }, [isLoading, t]);

  return (
    <div style={{ width: dropdownlistwidth, height: "100%" }}>
      <div css={styles.contentContainer}>
        {!!tags.length && <div css={styles.bodyContentTags}>{tags}</div>}
        {!allAgencies.length ? (
          emptyAgencyDiv
        ) : (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={() => loadMoreRows()}
            rowCount={allRows}
          >
            {({ onRowsRendered, registerChild }: InfiniteLoaderChildProps) => (
              <List
                width={dropdownlistwidth}
                height={
                  tags.length ? MinAgenciesListHeight : MaxAgenciesListHeight
                }
                rowCount={rowCount}
                rowHeight={55}
                rowRenderer={rowRenderer}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
              />
            )}
          </InfiniteLoader>
        )}
      </div>
    </div>
  );
};

export default AgencyWritingDropDownMenu;
