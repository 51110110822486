import React from "react";
import ReactDOM from "react-dom";
import { initDataDog } from "src/utils/data-dog";
import { BrowserRouter } from "react-router-dom";
import withAuthentication from "./auth/with-authentication";
import App from "./app";
import withOrganisation from "./providers/organisation/with-organisation";
import withInternationalization from "./i18n";

initDataDog();

const AuthenticatedApp = withOrganisation(
  withAuthentication(withInternationalization(App))
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthenticatedApp />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
