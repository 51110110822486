import produce from "immer";
import IGlobalContextProvider, {
  GlobalAction,
  GlobalContextActions,
} from "./types";

export const globalContextReducer = produce(
  (draft: IGlobalContextProvider, action: GlobalAction) => {
    switch (action.type) {
      case GlobalContextActions.STORE_STATUSES:
        draft.allProjectStatuses = action.value;
        return draft;
      case GlobalContextActions.STORE_PROJECT_TYPES:
        draft.allProjectTypes = action.value;
        return draft;
      case GlobalContextActions.STORE_PROJECT_REGIONS:
        draft.allProjectRegions = action.value;
        return draft;
      case GlobalContextActions.SET_LOADING:
        draft.isGlobalDataLoading = action.value;
        return draft;
      case GlobalContextActions.STORE_TALENT_HUB_TYPES:
        draft.talentHubTypes = action.value;
        return draft;
      default:
        throw new Error();
    }
  }
);

export const InitialGlobalState = {
  isGlobalDataLoading: false,
  allProjectStatuses: [],
  allProjectTypes: [],
  allProjectRegions: [],
  talentHubTypes: {
    genderIdentities: [],
    genderPronouns: [],
    musicGenres: [],
    racialCharacteristics: [],
    sexualOrientations: [],
    skillSets: [],
    talentCollaborations: [],
    talentInstruments: [],
    talentWorkTypes: [],
    countryCodes: [],
    languageCodes: [],
  },
};
