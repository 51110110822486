import { css, SerializedStyles } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  treeContainer: css`
    margin-top: 1rem;
    font-weight: 400;
    .ant-tree-title {
      padding-left: 1rem !important;
    }

    .SecondLevel > .ant-tree-node-content-wrapper > .ant-tree-title {
      font-size: 0.8125rem !important;
    }

    @media ${queries.medium} {
      flex-direction: row;
    }

    @media ${queries.extraLarge} {
      flex-direction: row;

      .SecondLevel > .ant-tree-node-content-wrapper > .ant-tree-title {
        font-size: 1rem !important;
      }
    }

    .ant-tree {
      background: ${theme.colors.white};
    }

    .ant-tree-checkbox {
      display: none !important;
    }

    .ant-tree-indent {
      display: none !important;
    }

    .ant-tree-icon__customiz {
      margin-bottom: 1rem important;
      padding-bottom: 0;
    }

    .ant-tree-node-content-wrapper {
      order: 2;
    }

    .ant-tree-switcher {
      order: 3;
    }

    .ant-tree .ant-tree-node-content-wrapper {
      padding-left: 0 !important;
    }

    .FirstLevel {
      font-size: 1.125rem;
      line-height: 1.375rem;
      font-weight: 700;
      padding-bottom: 1.125rem !important;
    }

    .FirstLevel.noSwitcher > .ant-tree-switcher {
      display: none !important;
    }

    .ant-tree-treenode-switcher-close
      > .ant-tree-switcher
      > .switcher-Icon-Open {
      display: none !important;
    }

    .ant-tree-treenode-switcher-open
      > .ant-tree-switcher
      > .switcher-Icon-Close {
      display: none !important;
    }

    .WorldWide {
      padding-bottom: 2rem !important;
    }

    .ant-tree-list-holder-inner > .SecondLevel {
      padding-bottom: 0.9375rem !important;
    }

    .ThirdLevel {
      padding-left: 2.1875rem !important;
      padding-bottom: 1.125rem !important;
    }

    .ant-tree-list-holder-inner
      > .SecondLevel.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last {
      padding-bottom: 2.5rem !important;
    }

    .ant-tree-list-holder-inner > .ThirdLevel.ant-tree-treenode-leaf-last {
      padding-bottom: 1.125rem !important;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner {
      border-color: ${theme.colors.background.lightGray};
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      left: 43%;
      width: 0.75rem;
      height: 0.75rem;
      background-color: ${theme.colors.secondary.lightBlue};
    }

    .ant-select-tree-switcher-icon {
      font-size: 1rem;
    }
  `,
  chevronContainer: css`
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    justify-content: center;
    align-items: center;
  `,
  hideTerritories: css`
    .ant-tree-list-holder-inner > .ant-tree-treenode {
      display: none;
      visibility: hidden;
    }

    .firstTree
      .ant-tree-list-holder-inner
      > .ant-tree-treenode:nth-of-type(-n + 6) {
      display: flex;
      visibility: visible;
    }

    .secondTree,
    .thirdTree {
      .ant-tree-list-holder-inner > .ant-tree-treenode:nth-of-type(-n + 6) {
        display: flex;
        visibility: visible;
      }
    }

    .ant-tree-list-holder-inner > .ThirdLevel {
      display: flex;
      visibility: visible;
    }
  `,

  tree: css`
    flex: 1;
  `,
  showTerritoriesToggle: css`
    color: ${theme.colors.primary.blue};
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  `,

  chevron: css`
    margin-right: -0.3125rem;
  `,

  worldwide: css`
    margin-bottom: 0.875rem;
  `,
};
