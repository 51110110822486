import { Typography, Form, Input, DatePicker } from "antd";
import React, { ReactElement } from "react";
import ReactSelect from "react-select";
import projectValidation from "src/utils/projectValidation";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "@songtradr/spa-common";
import { IProjectDetailsData } from "src/pages/projects/project/interfaces";
import { LabeledValue } from "antd/lib/select";
import dayjs from "dayjs";
import { DateDisplayFormat } from "src/constants";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import { customStyles } from "../../styles";
import style from "../../../../styles";

interface IProps {
  projectDetailsData: IProjectDetailsData;
  onUpdate: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onDatePickerChange: (field: string, value: dayjs.Dayjs | null) => void;
  onProjectStatusChange: (data: LabeledValue) => void;
}

const ProjectDatesSection = ({
  projectDetailsData,
  onUpdate,
  onDatePickerChange,
  onProjectStatusChange,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const { allProjectStatuses } = useGlobalStates();
  return (
    <>
      <div css={style.titleLabelContainer}>
        <Typography.Text css={style.titleLabel}>
          {t("ProjectsPage##ProjectDates")}
        </Typography.Text>
      </div>
      <div
        css={[
          isMobile ? style.mobileSectionContainer : style.clientContainer,
          style.lastOfSectionBottom,
        ]}
      >
        <div css={style.formItemContainer}>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##Project listing date")}
            </Typography.Text>
          </div>

          <Form.Item
            css={style.formItem}
            name="jobListingDate"
            rules={[projectValidation.yupDetailsSchemaSync]}
          >
            <DatePicker
              allowClear={false}
              onChange={(value) => onDatePickerChange("jobListingDate", value)}
              placeholder=""
              data-testid="project-listing-date"
              format={DateDisplayFormat}
            />
          </Form.Item>
        </div>
        <div css={style.formItemContainer}>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##Estimated project close date")}
            </Typography.Text>
          </div>
          <Form.Item css={style.formItem}>
            <DatePicker
              allowClear={false}
              value={
                projectDetailsData.estimatedProjectClosedDate
                  ? dayjs(projectDetailsData.estimatedProjectClosedDate)
                  : null
              }
              onChange={(value) =>
                onDatePickerChange("estimatedProjectClosedDate", value)
              }
              placeholder=""
              data-testid="estimated-project-close-date"
              format={DateDisplayFormat}
            />
          </Form.Item>
        </div>
        <div css={style.formItemContainer}>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##Project status")}
            </Typography.Text>
          </div>
          <Form.Item
            css={style.formItem}
            name="status"
            rules={[projectValidation.yupStatusSync]}
          >
            <ReactSelect
              isSearchable
              data-testid="project-status-dropdown"
              instanceId="project-status-dropdown"
              id="project-status-dropdown"
              options={allProjectStatuses}
              onChange={onProjectStatusChange}
              styles={customStyles}
            />
          </Form.Item>
        </div>
        <div css={style.formItemContainer}>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##Project close date")}
            </Typography.Text>
          </div>
          <Form.Item css={style.formItem}>
            <Input
              disabled
              name="projectCloseDate"
              onChange={onUpdate}
              placeholder=""
              value={projectDetailsData.projectCloseDate}
              data-testid="project-close-date"
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default ProjectDatesSection;
