import { FunctionComponent } from "react";
import OrganisationProvider from "./state/provider";

const withOrganisation = (
  WrappedComponent: FunctionComponent
): FunctionComponent => (props: Record<string, unknown>) => (
  <OrganisationProvider>
    <WrappedComponent {...props} />
  </OrganisationProvider>
);

export default withOrganisation;
