import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  default: css`
    .ant-modal-content {
      border-radius: 4px;
      background-color: ${theme.colors.white};
      height: 80%;
    }

    .confirmationModalCancelButton {
      margin-top: 0.9375rem;
      :hover {
        color: ${theme.colors.secondary.darkBlue} !important;
        background-color: #fff !important;
      }
    }
    .confirmationModalConfirmButton {
      min-width: 9.0625rem;
      max-width: 9.0625rem;
    }
    .ant-modal-footer {
      border-top: none;
    }
    .ant-modal-body {
      padding: 2rem !important;
    }
    .close-icon {
      background-color: #fff !important;
    }
    .ant-modal-close-icon {
      color: ${theme.colors.secondary.purple};
    }
  `,
  centerButton: css`
    justify-content: center;
  `,
  modalTitle: css`
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    line-height: 1.75rem !important;
    color: ${theme.colors.secondary.navyBlue} !important;
    margin-bottom: 2rem !important;
  `,
  footer: css`
    border-top: none;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    flex-flow: row nowrap;
    column-gap: 1.5rem;
    button {
      line-height: 1.375rem;
      &.ant-btn-secondary {
        margin-right: 1.0625rem;
      }
      &.ant-btn-primary {
        box-shadow: none;
      }
    }
  `,
  note: css`
    font-size: 0.75rem;
    color: ${theme.colors.background.darkGray};
    padding-left: 1rem;
    padding-right: 3.5938rem;
    margin-bottom: 1.5rem !important;
  `,
};
