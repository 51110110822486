import { ReactElement } from "react";
import { IConfirmContentProps } from "src/components/interfaces";
import TableSort from "src/pages/team/components/org-members/components/table-sort";
import InviteMembers from "../../components/org-invites/components/invite-members";
import OrgMemberMoreInfo from "../../components/org-members/drawer";
import ConfirmationDrawer from "../../../../components/mobile-drawer/confirmation-drawer";
import {
  IOrganisationMemberProps,
  ITableSort,
  OrgMembersSortType,
  SortOrder,
} from "../../interfaces";

export enum OrgMembersDrawerTypes {
  inviteMembers = "inviteMembers",
  membersMoreInfo = "membersMoreInfo",
  deleteMemberConfirmation = "deleteMemberConfirmation",
  sortMembers = "sortMembers",
}

const generateMemberDrawerContent = (
  key: OrgMembersDrawerTypes,
  handleDrawerToggle: () => void,
  setInvitesLastUpdatedAt: (date: Date) => void,
  handleToggleAdmin: (selectedOrgMember: IOrganisationMemberProps) => void,
  confirmDeleteContentProps: IConfirmContentProps,
  setSortTypeAndOrder: (
    sortType: OrgMembersSortType,
    sortOrder: SortOrder
  ) => void,
  getSortTypeAndOrder: () => ITableSort,
  selectedOrgMember?: IOrganisationMemberProps
): ReactElement => {
  switch (key) {
    case OrgMembersDrawerTypes.inviteMembers:
      return (
        <InviteMembers
          handleToggle={handleDrawerToggle}
          setInvitesLastUpdatedAt={setInvitesLastUpdatedAt}
        />
      );
    case OrgMembersDrawerTypes.membersMoreInfo:
      return (
        <OrgMemberMoreInfo
          selectedOrgMember={selectedOrgMember}
          handleToggleAdmin={handleToggleAdmin}
        />
      );
    case OrgMembersDrawerTypes.deleteMemberConfirmation:
      return <ConfirmationDrawer {...confirmDeleteContentProps} />;

    case OrgMembersDrawerTypes.sortMembers:
      return (
        <TableSort
          setSortTypeAndOrder={setSortTypeAndOrder}
          getSortTypeAndOrder={getSortTypeAndOrder}
        />
      );
    default:
      return <div data-testid="sort-clients-drawer">Unknown Drawer</div>;
  }
};

export default generateMemberDrawerContent;
