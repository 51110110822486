import axios from "axios";
import { getThirdPartyServicesUrl } from "src/api/third-party-services-url-helper";
import { IThirdParty } from "src/pages/projects/project/components/services/types";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const getThirdParties = async (
  accessToken: string,
  organisationId: string
): Promise<IThirdParty[]> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios
    .get(getThirdPartyServicesUrl(organisationId), options)
    .then(({ data }) => data as IThirdParty[]);
};

export default getThirdParties;
