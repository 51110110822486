import React, { ReactElement, useMemo, useState } from "react";
import BaseModal from "src/components/modals/base-modal";
import ButtonWithLabelAndPlusIcon from "src/components/plus-button-with-label";
import CustomMediaModalContent from "./components/custom-media-type-modal";
import MediaType from "./components/media-type";
import styles from "./components/styles";
import { IMediaTypeState } from "./interfaces";

interface IProps {
  hasFinalTrackSelected: boolean;
  mediaTypes: IMediaTypeState[];
  onMediaTypeClick: (mediaType: IMediaTypeState) => void;
  addCustomMediaType: (customMediaType: IMediaTypeState) => void;
  onRemoveCustomMediaType: (customMediaType: IMediaTypeState) => void;
  renameCustomMediaType: (newName: string, oldName: string) => void;
}

const MediaTypes = ({
  mediaTypes,
  hasFinalTrackSelected,
  onMediaTypeClick,
  addCustomMediaType,
  onRemoveCustomMediaType,
  renameCustomMediaType,
}: IProps): ReactElement => {
  const [modalVisible, toggleModal] = useState<boolean>(false);
  const [
    customMediaTypeToRename,
    setCustomMediaTypeToRename,
  ] = useState<IMediaTypeState>();

  const handleModalToggle = () => {
    setCustomMediaTypeToRename(undefined);
    toggleModal((state) => !state);
  };

  const handleAddCustomMediaType = (customMediaType: IMediaTypeState) => {
    addCustomMediaType(customMediaType);
    toggleModal((state) => !state);
  };

  const handleRenameCustomMediaType = (newName: string, oldName: string) => {
    renameCustomMediaType(newName, oldName);
    setCustomMediaTypeToRename(undefined);
    toggleModal((state) => !state);
  };

  const toggleRenameCustomMediaTypeModal = (
    customMediaType: IMediaTypeState
  ) => {
    setCustomMediaTypeToRename(customMediaType);
    toggleModal((state) => !state);
  };

  const markAsRequired = useMemo(() => {
    return (
      hasFinalTrackSelected &&
      (mediaTypes ?? []).filter((type) => type.isSelected).length === 0
    );
  }, [hasFinalTrackSelected, mediaTypes]);

  return (
    <>
      <div
        css={styles.container}
        style={{ border: `1px solid ${markAsRequired ? "red" : "white"}` }}
      >
        {mediaTypes.map((mediaType) => {
          return (
            <MediaType
              mediaType={mediaType}
              onClick={onMediaTypeClick}
              key={mediaType.name}
              onRemoveCustomMediaType={onRemoveCustomMediaType}
              toggleRenameCustomMediaTypeModal={
                toggleRenameCustomMediaTypeModal
              }
            />
          );
        })}
      </div>
      <div>
        <ButtonWithLabelAndPlusIcon
          onClick={handleModalToggle}
          dataTestId="add-new-media-type"
          ariaLabel="ProjectsPage##licensingSection##Add custom media type"
          label="ProjectsPage##licensingSection##Add custom media type"
        />
      </div>
      <BaseModal
        buttons={[]}
        open={modalVisible}
        onClose={handleModalToggle}
        content={
          <CustomMediaModalContent
            handleToggle={handleModalToggle}
            addCustomMediaType={handleAddCustomMediaType}
            renameCustomMediaType={handleRenameCustomMediaType}
            currentMediaTypes={mediaTypes}
            customMediaTypeToRename={customMediaTypeToRename}
          />
        }
      />
    </>
  );
};

export default MediaTypes;
