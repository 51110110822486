import React, { ReactElement } from "react";
import {
  DrawerTypes,
  IProjectProps,
  IProjectTableSort,
} from "../../interfaces";
import ProjectInfoDrawer from "./project-info";
import SortProjectsDrawer from "./sort-projects";
import DeleteProjectDrawer from "./delete-project";

const generateDrawerContent = (
  key: DrawerTypes,
  handleSort: (params: IProjectTableSort) => void,
  sortOptions: IProjectTableSort,
  handleDeleteProject: (id: string) => void,
  handleMobileDeleteProjectClick: () => void,
  handleMobileEditProjectClick: (id: string) => void,
  handleDrawerToggle: () => void,
  activeProject?: IProjectProps
): ReactElement => {
  switch (key) {
    case DrawerTypes.sortProjects:
      return (
        <SortProjectsDrawer handleSort={handleSort} sortOptions={sortOptions} />
      );
    case DrawerTypes.projectInfo:
      return (
        <ProjectInfoDrawer
          activeProject={activeProject}
          handleMobileEditProjectClick={handleMobileEditProjectClick}
          handleMobileDeleteProjectClick={handleMobileDeleteProjectClick}
        />
      );
    case DrawerTypes.deleteProject:
      return (
        <DeleteProjectDrawer
          activeProject={activeProject}
          handleDrawerToggle={handleDrawerToggle}
          removeProject={handleDeleteProject}
        />
      );
    default:
      return <div />;
  }
};

export default generateDrawerContent;
