import React, { useState, ReactElement } from "react";
import { IOrganisation } from "src/interfaces/organisation";
import createOrganisationRequest from "src/api/organisation-request";
import { useMountedRef } from "@songtradr/spa-common/lib/utils";
import { IOrganisationUrlParams } from "src/interfaces/organisation-url-params";
import { AxiosError } from "axios";
import transform from "../utils/transform";
import SignUp from "..";

const SignUpContainer = (): ReactElement => {
  const [data, setData] = useState<IOrganisation>();
  const [isSavePending, setIsSavePending] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [inviteParams, setInviteParams] = useState<
    IOrganisationUrlParams | undefined
  >();
  const [organisationExists, setOrganisationExists] = useState(false);
  const isMountedRef = useMountedRef();

  return (
    <SignUp
      data={data as IOrganisation}
      onChange={(updatedData) => {
        setData(updatedData);
      }}
      onSubmit={async () => {
        setIsSavePending(true);
        try {
          if (!data) return;
          const organisationRequest = await createOrganisationRequest(
            transform({ organisation: data })
          );
          if (isMountedRef.current) setIsRegistered(true);
          const { organisationId, inviteId } = organisationRequest;
          if (organisationId && inviteId) {
            setInviteParams({
              organisationId: String(organisationId),
              organisationInviteId: String(inviteId),
            });
          }
        } catch (error) {
          const statusCode = (error as AxiosError).response?.status;

          if (statusCode === 409) {
            setOrganisationExists(true);
          }

          throw error;
        } finally {
          if (isMountedRef.current) setIsSavePending(false);
        }
      }}
      isSavePending={isSavePending}
      isRegistered={isRegistered}
      inviteParams={inviteParams}
      organisationExists={organisationExists}
    />
  );
};

export default SignUpContainer;
