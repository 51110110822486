import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

const circle = css`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
`;
export default {
  termHeader: css`
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: ${theme.colors.secondary.purple};
    text-transform: uppercase;
    padding-top: 1.5rem;
    padding-bottom: 0.4375rem;
  `,
  term: css`
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.background.veryLightGray};
    border-bottom: none;
    padding-left: 1rem;
    padding-right: 1.125rem;
    margin-bottom: 1rem;
    @media ${queries.medium} {
      border: none;
    }
    @media ${queries.large} {
      flex: 1 1 0;
      padding-left: 2rem;
    }
  `,
  name: css`
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding-bottom: 0.6875rem;
    flex: 1;
  `,
  wrapper: css`
    flex: 1 1 100%;
    @media ${queries.medium} {
      margin-left: 2rem;
      margin-right: 2rem;
    }
    @media ${queries.large} {
      margin-top: 5rem;
      margin-left: 2.875rem;
    }
  `,
  licensingRow: css`
    @media ${queries.medium} {
      background: ${theme.colors.white};
    }
  `,
  subheader: css`
    font-size: 0.6875rem;
    line-height: 1.125rem;
    color: ${theme.colors.background.darkGray};
    padding-bottom: 0.625rem;
  `,
  territoryList: css`
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding-left: 0;
    font-size: 16px;
    flex-wrap: wrap;
  `,
  territory: css`
    border: 1px solid ${theme.colors.background.lightGray};
    box-sizing: border-box;
    border-radius: 0.25rem;
    padding: 0.2813rem 0.5rem;
    font-size: 16px;
    margin-right: 0.5rem;
    margin-bottom: 0.8438rem;
  `,
  startDate: css`
    float: left;
    font-size: 0.75rem;
  `,
  endDate: css`
    float: right;
    font-size: 0.75rem;
  `,
  duration: css`
    text-align: center;
    color: ${theme.colors.background.darkGray};
    margin: 0 0.3125rem 1.125rem;
    @media ${queries.medium} {
      display: inline-block;
      float: right;
      margin-top: 1rem;
    }
  `,
  statusChip: css`
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    div {
      text-align: center;
    }
    @media ${queries.medium} {
      width: 198px;
      display: inline-block;
      margin-top: 1rem;
    }
  `,
  active: css`
    background-color: rgba(227, 249, 236, 1);
    .circle {
      ${circle};
      background-color: ${theme.colors.functional.green};
    }
  `,
  renew: css`
    background: #fff5e8;
    .circle {
      ${circle};
      background-color: ${theme.colors.functional.orange};
    }
  `,
  expired: css`
    background: ${theme.colors.background.veryLightGray};
    .circle {
      ${circle};
      background-color: ${theme.colors.background.darkGray};
    }
  `,
  urgent: css`
    background: #cc00001a;
    .circle {
      ${circle};
      background-color: ${theme.colors.functional.red};
    }
  `,
  flex: css`
    display: flex;
  `,
  viewMoreIcon: css`
    color: ${theme.colors.primary.blue};
    cursor: pointer;
    svg {
      width: 1.0525rem;
      height: 1.0525rem;
    }
  `,
  iconContainer: css`
    font-size: 2rem;
  `,
  showMoreText: css`
    font-size: 16px;
    line-height: 1.125rem;
    color: ${theme.colors.primary.blue};
    text-align: center;
    cursor: pointer;
  `,
  flexContainer: css`
    @media ${queries.large} {
      display: flex;
      margin-right: 5.0625rem;
    }
  `,
  progressContainer: css`
    padding-left: 1rem;
    padding-right: 1.125rem;
    @media ${queries.large} {
      flex: 1 1 0;
      padding-top: 5rem;
    }
  `,
  extraPadding: css`
    @media ${queries.large} {
      padding-left: 4.0625rem;
    }
  `,
  termContainer: css`
    background: white;
    border-bottom: 0.0625rem solid ${theme.colors.background.veryLightGray};
    @media ${queries.medium} {
      border-bottom: none;
    }
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
  `,
  licensingHeader: css`
    padding-top: 2.5rem;
    @media ${queries.medium} {
      padding-top: 3rem;
    }
  `,
  timeRemaining: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `,
};
