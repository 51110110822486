import React, { useCallback } from "react";
import mapColumnsInCheckboxLabels from "src/components/modals/view-columns-modal/utils";
import style from "src/pages/projects/project-listings/styles/";
import { AllTalentHubColumns } from "src/components/modals/view-columns-modal";
import ViewColumnsIcon from "src/app/assets/icons/view-columns";
import { ISavedUserColumn } from "src/api/user-filters/interfaces";
import { IColumnsData } from "../../interfaces";

const TalentTableColumns = (
  savedColumns: ISavedUserColumn[] | null,
  onShowColumnsModal: (visibility: boolean) => void
): Array<IColumnsData> => {
  const getColumnsBySelectedColumnsOrder = useCallback((): IColumnsData[] => {
    const getColumnWidth = (columnKey: string) => {
      switch (columnKey) {
        case AllTalentHubColumns.SocialMediaWebsite:
        case AllTalentHubColumns.OtherLanguages:
        case AllTalentHubColumns.NeurodiverseNote:
        case AllTalentHubColumns.RacialCharacteristics:
        case AllTalentHubColumns.SexualOrientation:
        case AllTalentHubColumns.MinimumDemoFee:
        case AllTalentHubColumns.AdditionalGenres:
        case AllTalentHubColumns.InterestedProjects:
          return 200;
        case AllTalentHubColumns.AgencyWritingPartnership:
        case AllTalentHubColumns.NativeMainLanguage:
        case AllTalentHubColumns.PhysicalConditionIllness:
        case AllTalentHubColumns.AdditionalInstruments:
          return 250;
        case AllTalentHubColumns.MinimumDemoTurnaroundHours:
          return 300;
        case AllTalentHubColumns.PerformingRightsOrganisationNumber:
          return 350;
        default:
          return 150;
      }
    };
    const tableColumns: { [columnKey: string]: IColumnsData } = Object.entries(
      AllTalentHubColumns
    )
      .map(([mainKey, value]) => {
        const key = mainKey[0].toLocaleLowerCase() + mainKey.slice(1);

        return {
          [value]: {
            title: (
              <div css={style.titleContainer}>
                <span>{value}</span>
              </div>
            ),
            dataIndex: key,
            key,
            width: getColumnWidth(value),
          },
        };
      })
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    return mapColumnsInCheckboxLabels(
      savedColumns,
      Object.values(AllTalentHubColumns)
    )
      .filter((columnData) => columnData.enabled)
      .map((columnData) => tableColumns?.[columnData.name] ?? null)
      .filter(
        (columnData): columnData is NonNullable<IColumnsData> =>
          columnData !== null
      );
  }, [savedColumns]);

  return [
    ...getColumnsBySelectedColumnsOrder(),
    {
      title: (
        <div css={[style.titleContainer, style.userMoreContainer]}>
          <div
            role="button"
            css={style.viewColumnsContainer}
            onClick={() => onShowColumnsModal(true)}
            tabIndex={0}
            onKeyDown={(e) => {
              e.persist();
            }}
          >
            <ViewColumnsIcon />
          </div>
        </div>
      ),
      dataIndex: "menuMore",
      key: "menuMore",
      width: 50,
      align: "center",
      fixed: "right",
    },
  ];
};

export default TalentTableColumns;
