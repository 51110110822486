import { Divider, Input, Typography } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "src/components/button";
import { useTranslation } from "react-i18next";
import FilterAttributes from "src/app/assets/icons/component-icons/filter-attributes";
import { useHistory } from "react-router-dom";
import getFilterOptionsWithConvertedValuesToString from "src/utils/getFilterOptionsWithConvertedValuesToString";
import getFilterValuesForContacts from "src/utils/getFilterValuesForContacts";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import styles from "../styles";
import TalentHubFilters from "../talent-hub-filters";
import { AllContactsFiltersValues, IContactsFilters } from "../interfaces";
import SelectedContactsFilters from "../selected-contacts-filters";

interface IProps {
  numberOfContacts: number;
  areFiltersVisible: boolean;
  availableContactFilters: IContactsFilters;
  searchText: string;
  selectedContactFilters: IContactsFilters;
  showAddContactButton: boolean;
  onUpdateSearchText: (searchText: string) => void;
  onFiltersVisibility: () => void;
  onFetchSelectedFilters: (selectedFilters: IContactsFilters) => void;
}

const TalentHubActionBar = ({
  numberOfContacts,
  areFiltersVisible,
  availableContactFilters,
  searchText,
  selectedContactFilters,
  showAddContactButton,
  onUpdateSearchText,
  onFiltersVisibility,
  onFetchSelectedFilters,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { talentHubTypes } = useGlobalStates();

  const getSelectedFilters = useCallback(
    (filters: string[], key: AllContactsFiltersValues) => {
      return (filters ?? []).filter((filterValue) =>
        (selectedContactFilters[
          key as keyof IContactsFilters
        ] as string[])?.includes(filterValue)
      );
    },
    [selectedContactFilters]
  );
  const getSelectedFiltersWithBooleans = useCallback(
    (filters: boolean[], key: AllContactsFiltersValues) => {
      return filters.filter((filterValue) =>
        (selectedContactFilters[
          key as keyof IContactsFilters
        ] as boolean[]).includes(filterValue)
      );
    },
    [selectedContactFilters]
  );
  const [skillSetFilterValues, setSkillSetFilterValues] = useState<string[]>(
    []
  );
  const [collaborationFilterValues, setCollaborationFilterValues] = useState<
    string[]
  >([]);
  const [genreFilterValues, setGenreFilterValues] = useState<string[]>([]);

  const [instrumentFilterValues, setInstrumentFilterValues] = useState<
    string[]
  >([]);

  const [languageFilterValues, setLanguageFilterValues] = useState<string[]>(
    []
  );
  const [locationFilterValues, setLocationFilterValues] = useState<string[]>(
    []
  );
  const [vocalistFilterValues, setVocalistFilterValues] = useState<string[]>(
    []
  );
  const [
    racialCharacteristicFilterValues,
    setRacialCharacteristicFilterValues,
  ] = useState<string[]>([]);
  const [genderIdentityFilterValues, setGenderIdentityFilterValues] = useState<
    string[]
  >([]);
  const [signedNDAFilterValues, setSignedNDAFilterValues] = useState<string[]>(
    []
  );
  const [signedCMAFilterValues, setSignedCMAFilterValues] = useState<string[]>(
    []
  );
  const [
    serviceProviderFilterValues,
    setServiceProviderFilterValues,
  ] = useState<string[]>([]);

  const defaultFilterValues = useMemo(() => {
    return {
      skillSet: skillSetFilterValues,
      collaboration: collaborationFilterValues,
      genre: genreFilterValues,
      instrument: instrumentFilterValues,
      language: languageFilterValues,
      location: locationFilterValues,
      vocalist: vocalistFilterValues.map((isVocalist) => isVocalist === "true"),
      serviceProvider: serviceProviderFilterValues.map(
        (isServiceProvider) => isServiceProvider === "true"
      ),
      racialCharacteristic: racialCharacteristicFilterValues,
      genderIdentity: genderIdentityFilterValues,
      signedNDA: signedNDAFilterValues,
      signedCMA: signedCMAFilterValues,
    };
  }, [
    skillSetFilterValues,
    collaborationFilterValues,
    genreFilterValues,
    instrumentFilterValues,
    languageFilterValues,
    locationFilterValues,
    vocalistFilterValues,
    serviceProviderFilterValues,
    racialCharacteristicFilterValues,
    genderIdentityFilterValues,
    signedNDAFilterValues,
    signedCMAFilterValues,
  ]);

  const selectedSkillSetValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.skillSet,
          AllContactsFiltersValues.SkillSet
        )
      ),
    [selectedContactFilters.skillSet, getSelectedFilters]
  );
  const selectedCollaborationValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.collaboration,
          AllContactsFiltersValues.Collaboration
        )
      ),
    [getSelectedFilters, selectedContactFilters.collaboration]
  );

  const selectedGenreFilterValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.genre,
          AllContactsFiltersValues.Genre
        )
      ),
    [selectedContactFilters.genre, getSelectedFilters]
  );

  const selectedInstrumentFilterValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.instrument,
          AllContactsFiltersValues.Instrument
        )
      ),
    [selectedContactFilters.instrument, getSelectedFilters]
  );

  const selectedLanguageFilterValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.language,
          AllContactsFiltersValues.Language
        )
      ),
    [selectedContactFilters.language, getSelectedFilters]
  );

  const selectedLocationFilterValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.location,
          AllContactsFiltersValues.Location
        )
      ),
    [selectedContactFilters.location, getSelectedFilters]
  );

  const selectedVocalistFilterValues = useMemo(() => {
    return selectedContactFilters.vocalist.every((filter) => filter !== null)
      ? getFilterValuesForContacts(
          getSelectedFiltersWithBooleans(
            selectedContactFilters.vocalist.map(
              (isVocalist) => isVocalist
            ) as boolean[],
            AllContactsFiltersValues.Vocalist
          )
        )
      : [];
  }, [selectedContactFilters, getSelectedFiltersWithBooleans]);

  const selectedServiceProviderFilterValues = useMemo(() => {
    return selectedContactFilters.serviceProvider.every(
      (filter) => filter !== null
    )
      ? getFilterValuesForContacts(
          getSelectedFiltersWithBooleans(
            selectedContactFilters.serviceProvider.map(
              (isServiceProvider) => isServiceProvider
            ) as boolean[],
            AllContactsFiltersValues.ServiceProvider
          )
        )
      : [];
  }, [selectedContactFilters, getSelectedFiltersWithBooleans]);

  const selectedRacialCharacteristicFilterValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.racialCharacteristic,
          AllContactsFiltersValues.RacialCharacteristics
        )
      ),
    [selectedContactFilters.racialCharacteristic, getSelectedFilters]
  );

  const selectedGenderIdentityFilterValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.genderIdentity,
          AllContactsFiltersValues.GenderIdentity
        )
      ),
    [selectedContactFilters.genderIdentity, getSelectedFilters]
  );

  const selectedSignedNDAFilterValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.signedNDA,
          AllContactsFiltersValues.NDASigned
        )
      ),
    [selectedContactFilters.signedNDA, getSelectedFilters]
  );

  const selectedSignedCMAFilterValues = useMemo(
    () =>
      getFilterValuesForContacts(
        getSelectedFilters(
          selectedContactFilters.signedCMA,
          AllContactsFiltersValues.CMASigned
        )
      ),
    [selectedContactFilters.signedCMA, getSelectedFilters]
  );

  useEffect(() => {
    setSkillSetFilterValues(selectedSkillSetValues);
  }, [selectedSkillSetValues]);

  useEffect(() => {
    setCollaborationFilterValues(selectedCollaborationValues);
  }, [selectedCollaborationValues]);

  useEffect(() => {
    setGenreFilterValues(selectedGenreFilterValues);
  }, [selectedGenreFilterValues]);

  useEffect(() => {
    setInstrumentFilterValues(selectedInstrumentFilterValues);
  }, [selectedInstrumentFilterValues]);

  useEffect(() => {
    setLanguageFilterValues(selectedLanguageFilterValues);
  }, [selectedLanguageFilterValues]);

  useEffect(() => {
    setLocationFilterValues(selectedLocationFilterValues);
  }, [selectedLocationFilterValues]);

  useEffect(() => {
    setVocalistFilterValues(selectedVocalistFilterValues);
  }, [selectedVocalistFilterValues]);

  useEffect(() => {
    setServiceProviderFilterValues(selectedServiceProviderFilterValues);
  }, [selectedServiceProviderFilterValues]);

  useEffect(() => {
    setRacialCharacteristicFilterValues(
      selectedRacialCharacteristicFilterValues
    );
  }, [selectedRacialCharacteristicFilterValues]);

  useEffect(() => {
    setGenderIdentityFilterValues(selectedGenderIdentityFilterValues);
  }, [selectedGenderIdentityFilterValues]);

  useEffect(() => {
    setSignedNDAFilterValues(selectedSignedNDAFilterValues);
  }, [selectedSignedNDAFilterValues]);

  useEffect(() => {
    setSignedCMAFilterValues(selectedSignedCMAFilterValues);
  }, [selectedSignedCMAFilterValues]);

  const getFilterOptions = useCallback(
    (filterValue: string, filters: IContactsFilters) => {
      return getFilterOptionsWithConvertedValuesToString(filterValue, filters);
    },
    []
  );

  const handleApplyFilters = useCallback(() => {
    onFetchSelectedFilters(defaultFilterValues);
  }, [defaultFilterValues, onFetchSelectedFilters]);

  // We are handling 3 different situations here
  // 1. Resetting filter values to default
  // 2. Clearing filter values
  // 3. Modifying specific value
  const handleFilterOptionChange = useCallback(
    (
      filterValue: string,
      isResetFilterValues: boolean,
      checkedLabels?: string[]
    ) => {
      const newValues = isResetFilterValues
        ? getFilterOptions(filterValue, selectedContactFilters).map(
            (option) => option.value
          )
        : checkedLabels;
      switch (filterValue) {
        case AllContactsFiltersValues.Collaboration:
          setCollaborationFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.Genre:
          setGenreFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.Instrument:
          setInstrumentFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.Language:
          setLanguageFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.Location:
          setLocationFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.SkillSet:
          setSkillSetFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.Vocalist:
          setVocalistFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.ServiceProvider:
          setServiceProviderFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.RacialCharacteristics:
          setRacialCharacteristicFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.GenderIdentity:
          setGenderIdentityFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.NDASigned:
          setSignedNDAFilterValues(newValues ?? []);
          break;
        case AllContactsFiltersValues.CMASigned:
          setSignedCMAFilterValues(newValues ?? []);
          break;
        default:
          break;
      }
    },
    [getFilterOptions, selectedContactFilters]
  );

  const hasSelectedFilters = useMemo(
    () =>
      Object.values(selectedContactFilters).filter(
        (array) => (array ?? []).length
      ).length > 0,
    [selectedContactFilters]
  );

  const handleRemoveSpecificFilterTag = useCallback(
    (filterKey: string, checkedLabels: string[]) => {
      const selectedFilterValues: IContactsFilters = defaultFilterValues;
      selectedFilterValues[filterKey as keyof IContactsFilters] = checkedLabels;
      onFetchSelectedFilters(selectedFilterValues);
    },
    [defaultFilterValues, onFetchSelectedFilters]
  );

  const handleClearFilters = useCallback(
    (
      filterValue: string,
      isResetFilterValues: boolean,
      checkedLabels?: string[]
    ) => {
      handleFilterOptionChange(filterValue, isResetFilterValues, checkedLabels);
      onFetchSelectedFilters({ ...defaultFilterValues, [filterValue]: [] });
    },
    [defaultFilterValues, handleFilterOptionChange, onFetchSelectedFilters]
  );

  return (
    <>
      <div css={styles.actionBarContainer}>
        <Typography css={styles.tabHeaderText}>
          {t("TalentPage##Contacts")}
        </Typography>
      </div>
      <div css={styles.searchAndButtonsContainer}>
        <div css={styles.inputAndPresetFiltersContainer}>
          <div css={styles.inputContainer}>
            <Input
              autoFocus
              css={styles.input}
              placeholder="Search contacts"
              value={searchText}
              onChange={(e) => onUpdateSearchText(e.target.value)}
              data-testid="search-contact-input"
              addonAfter={
                <button
                  css={styles.iconButton}
                  type="button"
                  data-testid="contacts-filter"
                  onClick={onFiltersVisibility}
                >
                  <FilterAttributes
                    css={styles.inputClear}
                    height={48}
                    width={48}
                    role="button"
                    title="filterAttributes"
                  />
                </button>
              }
            />
          </div>
        </div>
        <div css={styles.actionBarButtonsContainer}>
          {showAddContactButton && (
            <Button
              css={styles.primaryButton}
              ariaLabel="Add contact"
              onClick={() => history.push("/talent-create")}
            >
              Add contact
            </Button>
          )}
        </div>
      </div>
      {areFiltersVisible && (
        <TalentHubFilters
          availableContactFilters={availableContactFilters}
          collaborationFilterValues={collaborationFilterValues}
          genreFilterValues={genreFilterValues}
          instrumentFilterValues={instrumentFilterValues}
          languageFilterValues={languageFilterValues}
          locationFilterValues={locationFilterValues}
          skillSetFilterValues={skillSetFilterValues}
          vocalistFilterValues={vocalistFilterValues}
          serviceProviderFilterValues={serviceProviderFilterValues}
          racialCharacteristicFilterValues={racialCharacteristicFilterValues}
          genderIdentityFilterValues={genderIdentityFilterValues}
          signedNDAFilterValues={signedNDAFilterValues}
          signedCMAFilterValues={signedCMAFilterValues}
          talentHubTypes={talentHubTypes}
          onFilterOptionChange={handleFilterOptionChange}
          onApplyFilters={handleApplyFilters}
          onClearFilters={handleClearFilters}
        />
      )}
      <Divider css={styles.actionBarDivider} />
      <div css={styles.selectedFiltersContainer}>
        {!hasSelectedFilters && (
          <Typography css={styles.tabFavoritesText}>
            {`${t("TalentPage##All contacts")} (${numberOfContacts})`}
          </Typography>
        )}
        {hasSelectedFilters && (
          <SelectedContactsFilters
            numberOfContacts={numberOfContacts}
            selectedContactFilters={selectedContactFilters}
            talentHubTypes={talentHubTypes}
            onRemoveSpecificFilterTag={handleRemoveSpecificFilterTag}
          />
        )}
      </div>
    </>
  );
};

export default TalentHubActionBar;
