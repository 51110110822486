import axios from "axios";
import config from "src/config";
import getAuthHeader from "../headers/get-auth-header";
import getJsonContentHeader from "../headers/get-json-content-header";
import { ISaveUserFilter } from "./interfaces";

async function saveUserFilters(
  accessToken: string,
  organisationId: string,
  payload: ISaveUserFilter
): Promise<void> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  return axios.post(
    `${config.projectManagementService.url}/user/save-projects-filter?orgId=${organisationId}`,
    payload,
    options
  );
}

export default saveUserFilters;
