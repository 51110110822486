import { Form, Switch, Typography } from "antd";
import Creatable from "react-select/creatable";
import { components, SingleValue } from "react-select";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next/";
import useAuth from "src/auth/use-auth";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import DeleteButton from "src/components/control-buttons/delete-button";
import createThirdParty from "src/api/third-party-services/create-third-party-service";
import {
  IContactReferences,
  IThirdParty,
} from "src/pages/projects/project/interfaces";
import { getIsMobile } from "@songtradr/spa-common";
import { PlusOutlined } from "@ant-design/icons";
import ControlButton from "src/components/control-buttons/base-button";
import { FormInstance } from "antd/lib/form";
import CurrencyAndPrice from "src/components/currency-and-price";
import { ErrorToast } from "src/components/toast-notification";
import TalentHubInput from "src/pages/projects/project/components/talent-hub-input";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import { Currencies } from "src/constants";
import { IOption } from "../../../../types";
import styles, { selectStyle } from "../../../../styles";
import mainStyle from "../../../../../../styles";

interface IProps {
  thirdPartyService: IThirdParty;
  thirdPartyOptions: IOption[];
  index: number;
  showAddButton: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  talentsResponse?: IContactsResponse;
  areTalentsLoading: boolean;
  agenciesAndContacts: IAgencyWithContactsResults[];
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onUpdateRow: (index: number, thirdParty: IThirdParty) => void;
  onDeleteRow: (index: number) => void;
  setSelectedOptionToDelete: (option: IOption) => void;
  setThirdPartyOptions: (option: IOption[]) => void;
  setIsModalOpen: (value: boolean) => void;
  onCreateNewRow: () => void;
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
}

enum ThirdPartyRowTypeOfFee {
  ThirdPartyFee = "thirdParty",
  BankFee = "bankFee",
  TaxWithholdingFee = "taxWithholdingFee",
}

const ThirdPartyRow = ({
  thirdPartyService,
  thirdPartyOptions,
  talentsResponse,
  index,
  showAddButton,
  form,
  agenciesAndContacts,
  areTalentsLoading,
  onScrollTalents,
  setSelectedOptionToDelete,
  setIsModalOpen,
  onCreateNewRow,
  onUpdateRow,
  setThirdPartyOptions,
  onDeleteRow,
  onUpdateNarrowSearchText,
  onResetNarrowSearch,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const [isCreating, setIsCreating] = useState(false);
  const talentHubRef = useRef<HTMLDivElement>(null);
  const [thirdPartyServiceValue, setThirdPartyServiceValue] = useState<IOption>(
    {
      label: thirdPartyService?.thirdParty?.name || "",
      value: thirdPartyService?.thirdParty?.id || "",
    }
  );

  const taxWithholdingFeeCurrency = useMemo(
    () => thirdPartyService.taxWithholdingFee?.currency ?? Currencies.USD.code,
    [thirdPartyService.taxWithholdingFee?.currency]
  );

  const taxWithholdingFeePrice = useMemo(
    () => thirdPartyService.taxWithholdingFee?.value ?? 0,
    [thirdPartyService.taxWithholdingFee?.value]
  );

  const bankFeeCurrency = useMemo(
    () => thirdPartyService.bankFee?.currency ?? Currencies.USD.code,
    [thirdPartyService.bankFee?.currency]
  );

  const bankFeePrice = useMemo(() => thirdPartyService.bankFee?.value ?? 0, [
    thirdPartyService.bankFee?.value,
  ]);

  const getThirdPartyContacts = useCallback(() => {
    return (
      thirdPartyService.thirdPartyContacts?.map((talent) => {
        return {
          name: talent?.name ?? "",
          id: talent?.id ?? "",
          type: talent.type,
          email: talent?.email ?? "",
          agencyWritingPartnership: talent.agencyWritingPartnership ?? "",
          groupByAgency: talent.groupByAgency ?? false,
        };
      }) ?? []
    );
  }, [thirdPartyService.thirdPartyContacts]);

  const [selectedTalents, setSelectedTalents] = useState<IContactReferences[]>(
    getThirdPartyContacts()
  );

  useEffect(() => {
    setSelectedTalents(getThirdPartyContacts());
  }, [getThirdPartyContacts]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const thirdPartySelectRef = useRef<any>(null);
  const { organisationId, fetchWrapper } = useAuth();
  const [paythroughToogle, setPaythroughToggle] = useState<boolean | undefined>(
    thirdPartyService?.paythrough
  );

  const handleCreate = useCallback(
    async (inputValue: string) => {
      setIsCreating(true);
      try {
        const response = await fetchWrapper(
          createThirdParty,
          organisationId,
          inputValue
        );
        const newOption = { value: response.id, label: response.name };

        setThirdPartyOptions([...thirdPartyOptions, newOption]);
        setThirdPartyServiceValue(newOption);
        form.setFieldsValue({
          [`thirdParty${index}Service`]: newOption,
        });
        onUpdateRow(index, {
          thirdParty: {
            name: newOption.label,
            id: newOption.value,
          },
          thirdPartyFee: {
            currency: thirdPartyService?.thirdPartyFee?.currency ?? "",
            value: thirdPartyService?.thirdPartyFee?.value ?? 0,
          },
          taxWithholdingFee: {
            currency: taxWithholdingFeeCurrency,
            value: taxWithholdingFeePrice,
          },
          bankFee: {
            currency: bankFeeCurrency,
            value: bankFeePrice,
          },
          paythrough: paythroughToogle,
          thirdPartyContacts: getThirdPartyContacts(),
        });
      } catch (error) {
        ErrorToast(
          "create-third-party-service-error",
          "Failed to create 3rd party service"
        );
      } finally {
        setIsCreating(false);
      }
    },
    [
      fetchWrapper,
      organisationId,
      setThirdPartyOptions,
      thirdPartyOptions,
      form,
      index,
      onUpdateRow,
      thirdPartyService?.thirdPartyFee?.currency,
      thirdPartyService?.thirdPartyFee?.value,
      taxWithholdingFeeCurrency,
      taxWithholdingFeePrice,
      bankFeeCurrency,
      bankFeePrice,
      paythroughToogle,
      getThirdPartyContacts,
    ]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomOption = (props: any) => {
    // The typings aren't being picked up correctly here, despite these values actually existing on the OptionProps type.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { label, value } = props;

    const handleThirdPartyDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      setSelectedOptionToDelete({ label, value } as IOption);
      setIsModalOpen(true);
    };

    return (
      <components.Option {...props}>
        <div css={styles.optionRow}>
          <div>{label}</div>
          <button
            type="button"
            css={styles.optionRowDelete}
            onClick={handleThirdPartyDelete}
          >
            <CloseIcon
              fill={theme.colors.black}
              height={9}
              width={9}
              title="Close"
              role="button"
            />
          </button>
        </div>
      </components.Option>
    );
  };

  const handleChange = useCallback(
    (newValue: SingleValue<IOption>) => {
      if (newValue) {
        onUpdateRow(index, {
          thirdParty: {
            name: newValue.label,
            id: newValue.value,
          },
          thirdPartyFee: {
            currency: thirdPartyService?.thirdPartyFee?.currency ?? "",
            value: thirdPartyService?.thirdPartyFee?.value ?? 0,
          },
          taxWithholdingFee: {
            currency: taxWithholdingFeeCurrency,
            value: taxWithholdingFeePrice,
          },
          bankFee: {
            currency: bankFeeCurrency,
            value: bankFeePrice,
          },
          paythrough: paythroughToogle,
          thirdPartyContacts: getThirdPartyContacts(),
        });
        form.setFieldsValue({
          [`thirdParty${index}Service`]: !newValue.value
            ? null
            : {
                name: newValue.label,
                id: newValue.value,
              },
        });
      }
      const selectedOption = {
        label: newValue?.label || "",
        value: newValue?.value || "",
      };
      setThirdPartyServiceValue(selectedOption);
      form.setFieldsValue({
        [`thirdParty${index}Service`]: !selectedOption.value
          ? null
          : selectedOption,
      });
      onUpdateRow(index, {
        thirdParty: {
          name: selectedOption.label,
          id: selectedOption.value,
        },
        thirdPartyFee: {
          currency: thirdPartyService?.thirdPartyFee?.currency ?? "",
          value: thirdPartyService?.thirdPartyFee?.value ?? 0,
        },
        taxWithholdingFee: {
          currency: taxWithholdingFeeCurrency,
          value: taxWithholdingFeePrice,
        },
        bankFee: {
          currency: bankFeeCurrency,
          value: bankFeePrice,
        },
        paythrough: paythroughToogle,
        thirdPartyContacts: getThirdPartyContacts(),
      });
    },
    [
      form,
      index,
      onUpdateRow,
      thirdPartyService?.thirdPartyFee?.currency,
      thirdPartyService?.thirdPartyFee?.value,
      taxWithholdingFeeCurrency,
      taxWithholdingFeePrice,
      bankFeeCurrency,
      bankFeePrice,
      paythroughToogle,
      getThirdPartyContacts,
    ]
  );

  const handleFeeChange = useCallback(
    (typeOfFee, selectValue: IMonetaryValue) => {
      const isBankFee = typeOfFee === ThirdPartyRowTypeOfFee.BankFee;
      const isTaxwithholdingFee =
        typeOfFee === ThirdPartyRowTypeOfFee.TaxWithholdingFee;
      const isThirdPartyFee =
        typeOfFee === ThirdPartyRowTypeOfFee.ThirdPartyFee;

      if (selectValue && thirdPartyServiceValue) {
        onUpdateRow(index, {
          thirdParty: {
            name: thirdPartyServiceValue.label,
            id: thirdPartyServiceValue.value,
          },
          thirdPartyFee: {
            currency: isThirdPartyFee
              ? selectValue.currency
              : thirdPartyService.thirdPartyFee?.currency ?? "",
            value: isThirdPartyFee
              ? selectValue.value
              : thirdPartyService.thirdPartyFee?.value ?? 0,
          },
          taxWithholdingFee: {
            currency: isTaxwithholdingFee
              ? selectValue.currency
              : taxWithholdingFeeCurrency,
            value: isTaxwithholdingFee
              ? selectValue.value
              : taxWithholdingFeePrice,
          },
          bankFee: {
            currency: isBankFee ? selectValue.currency : bankFeeCurrency,
            value: isBankFee ? selectValue.value : bankFeePrice ?? 0,
          },
          paythrough: paythroughToogle,
          thirdPartyContacts: getThirdPartyContacts(),
        });
      }
    },
    [
      thirdPartyServiceValue,
      onUpdateRow,
      index,
      thirdPartyService.thirdPartyFee?.currency,
      thirdPartyService.thirdPartyFee?.value,
      taxWithholdingFeeCurrency,
      taxWithholdingFeePrice,
      bankFeeCurrency,
      bankFeePrice,
      paythroughToogle,
      getThirdPartyContacts,
    ]
  );

  const handleSelectedTalentsChange = useCallback(
    (selectValues: IContactReferences[]) => {
      if (selectValues) {
        onUpdateRow(index, {
          thirdParty: {
            name: thirdPartyServiceValue.label,
            id: thirdPartyServiceValue.value,
          },
          thirdPartyContacts: selectValues,
          thirdPartyFee: {
            currency: thirdPartyService?.thirdPartyFee?.currency ?? "",
            value: thirdPartyService?.thirdPartyFee?.value ?? 0,
          },
          paythrough: paythroughToogle,
          bankFee: {
            currency: bankFeeCurrency,
            value: bankFeePrice,
          },
          taxWithholdingFee: {
            currency: taxWithholdingFeeCurrency,
            value: taxWithholdingFeePrice,
          },
        });
      }
    },
    [
      onUpdateRow,
      index,
      thirdPartyServiceValue.label,
      thirdPartyServiceValue.value,
      thirdPartyService?.thirdPartyFee?.currency,
      thirdPartyService?.thirdPartyFee?.value,
      paythroughToogle,
      bankFeeCurrency,
      bankFeePrice,
      taxWithholdingFeeCurrency,
      taxWithholdingFeePrice,
    ]
  );

  const handleToggleChange = useCallback(
    (value: boolean) => {
      setPaythroughToggle(value);
      onUpdateRow(index, {
        thirdParty: {
          name: thirdPartyServiceValue.label,
          id: thirdPartyServiceValue.value,
        },
        thirdPartyFee: {
          currency: thirdPartyService?.thirdPartyFee?.currency ?? "",
          value: thirdPartyService?.thirdPartyFee?.value ?? 0,
        },
        paythrough: value,
        bankFee: {
          currency: bankFeeCurrency,
          value: bankFeePrice,
        },
        taxWithholdingFee: {
          currency: taxWithholdingFeeCurrency,
          value: taxWithholdingFeePrice,
        },
        thirdPartyContacts: getThirdPartyContacts(),
      });
    },
    [
      onUpdateRow,
      index,
      thirdPartyServiceValue.label,
      thirdPartyServiceValue.value,
      thirdPartyService?.thirdPartyFee?.currency,
      thirdPartyService?.thirdPartyFee?.value,
      bankFeeCurrency,
      bankFeePrice,
      taxWithholdingFeeCurrency,
      taxWithholdingFeePrice,
      getThirdPartyContacts,
    ]
  );

  const getBankAndTaxWithholdingFee = useCallback(() => {
    return (
      <div
        css={
          isMobile
            ? mainStyle.mobileSectionContainer
            : mainStyle.clientContainer
        }
      >
        <Form.Item css={mainStyle.formItem}>
          <CurrencyAndPrice
            currency={taxWithholdingFeeCurrency}
            price={taxWithholdingFeePrice}
            currencyFormItemName={`taxWithholdingFee${index}Currency`}
            priceFormItemName={`taxWithholdingFee${index}Price`}
            onChange={handleFeeChange}
            label={t("ProjectsPage##servicesSection##Tax withholding fee")}
            propertyName="taxWithholdingFee"
            validateRequiredFields={false}
          />
        </Form.Item>
        <Form.Item css={mainStyle.formItem}>
          <CurrencyAndPrice
            currency={bankFeeCurrency}
            price={bankFeePrice}
            currencyFormItemName={`bankFee${index}Currency`}
            priceFormItemName={`bankFee${index}Price`}
            onChange={handleFeeChange}
            label={t("ProjectsPage##servicesSection##Bank fee")}
            propertyName="bankFee"
            validateRequiredFields={false}
          />
        </Form.Item>
        {/* We need spaces for desktop vertical alignment */}
        {!isMobile && (
          <>
            <div />
            <div />
            <div />
          </>
        )}
      </div>
    );
  }, [
    bankFeeCurrency,
    bankFeePrice,
    handleFeeChange,
    index,
    isMobile,
    t,
    taxWithholdingFeeCurrency,
    taxWithholdingFeePrice,
  ]);

  const handleDelete = useCallback(() => {
    onDeleteRow(index);
    const selectedOption = { label: "", value: "" };
    setThirdPartyServiceValue(selectedOption);
    setSelectedTalents([]);
    form.setFieldsValue({
      [`thirdParty${index}Service`]: null,
    });
  }, [onDeleteRow, form, index]);

  return (
    <div>
      <div
        css={
          isMobile
            ? mainStyle.mobileSectionContainer
            : mainStyle.clientContainer
        }
      >
        <div>
          <div css={mainStyle.basicInputContainer}>
            <Typography.Text css={mainStyle.basicInputLabel}>
              {t("ProjectsPage##servicesSection##3rd party service")}
            </Typography.Text>
          </div>
          <Form.Item
            name={`thirdParty${index}Service`}
            rules={[
              () => ({
                validator(_rule, value?: IOption) {
                  if (!value) {
                    return Promise.reject(
                      new Error("This is a required field")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Creatable
              key={thirdPartyService.thirdParty?.id}
              isClearable
              menuPlacement="auto"
              ref={thirdPartySelectRef}
              components={{ Option: CustomOption }}
              onChange={handleChange}
              createOptionPosition="first"
              onCreateOption={handleCreate}
              options={thirdPartyOptions}
              styles={selectStyle}
              isLoading={isCreating}
              isDisabled={isCreating}
            />
          </Form.Item>
        </div>
        <div>
          <div css={mainStyle.basicInputContainer} ref={talentHubRef}>
            <Typography.Text css={mainStyle.basicInputLabel}>
              {t("Talents")}
            </Typography.Text>
          </div>
          <TalentHubInput
            talenthubwidth={talentHubRef.current?.clientWidth}
            selectedTalents={selectedTalents}
            talentsResponse={talentsResponse}
            areTalentsLoading={areTalentsLoading}
            agenciesAndContacts={agenciesAndContacts}
            onScrollTalents={onScrollTalents}
            onSelectedTalentsChange={handleSelectedTalentsChange}
            onUpdateNarrowSearchText={onUpdateNarrowSearchText}
            onResetNarrowSearch={onResetNarrowSearch}
          />
        </div>
        <div>
          <Form.Item css={mainStyle.formItem}>
            <CurrencyAndPrice
              currency={thirdPartyService.thirdPartyFee?.currency ?? ""}
              price={thirdPartyService.thirdPartyFee?.value ?? ""}
              currencyFormItemName={`thirdParty${index}Currency`}
              priceFormItemName={`thirdParty${index}Price`}
              onChange={handleFeeChange}
              label={t("ProjectsPage##servicesSection##3rd party fee")}
              propertyName="thirdParty"
              validateRequiredFields={false}
            />
          </Form.Item>
        </div>
        <div css={isMobile && mainStyle.playThrough}>
          <div css={!isMobile && mainStyle.basicInputContainer}>
            <Typography.Text css={mainStyle.basicInputLabel}>
              {t("ProjectsPage##servicesSection##Paythrough")}
            </Typography.Text>
          </div>
          <Form.Item css={mainStyle.formItem}>
            <Switch
              onChange={handleToggleChange}
              checked={paythroughToogle}
              css={!isMobile && mainStyle.switchMargin}
            />
          </Form.Item>
        </div>
        <div css={[mainStyle.IconsContainer, mainStyle.alignIconButton]}>
          {showAddButton ? (
            <Form.Item css={[mainStyle.formItem, mainStyle.formItemWidth]}>
              <div css={styles.addIconContainer}>
                <ControlButton
                  showTooltip
                  tooltipColor="#222222"
                  label={t("Add new")}
                  data-testid="add-new-row-button"
                  onClick={onCreateNewRow}
                >
                  <PlusOutlined />
                </ControlButton>
              </div>
            </Form.Item>
          ) : (
            <div css={mainStyle.formItemWidth} />
          )}
          <Form.Item css={mainStyle.formItem}>
            <div css={styles.deleteIconContainer}>
              <DeleteButton onClick={handleDelete} />
            </div>
          </Form.Item>
        </div>
      </div>
      {getBankAndTaxWithholdingFee()}
    </div>
  );
};

export default ThirdPartyRow;
