import { Checkbox, Form, Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { IOrganisationMemberProps } from "src/pages/team/interfaces";
import styles from "./styles";
import { eApplicationType } from "../../../../../interfaces/auth";

interface IProps {
  selectedOrgMember?: IOrganisationMemberProps;
  handleToggleAdmin: (selectedOrgMember: IOrganisationMemberProps) => void;
}

const OrgMemberMoreInfo = ({
  selectedOrgMember,
  handleToggleAdmin,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  if (selectedOrgMember) {
    const isAdmin = selectedOrgMember.applications.includes(
      eApplicationType.TeamManagement
    );
    return (
      <div data-testid="members-more-info">
        <Form form={form} layout="vertical">
          <div css={styles.drawerContentContainer}>
            <div css={styles.drawerInfoContainer}>
              <Typography.Title css={styles.drawerHeader} level={5}>
                {t("manageTeam##members##Name")}
              </Typography.Title>
              <div css={styles.multiLine}>
                <Typography.Text css={styles.name}>
                  {`${selectedOrgMember.firstName} ${selectedOrgMember.lastName}`}
                  {isAdmin && (
                    <span css={styles.teamAdminPill} data-testid="adminPill">
                      {t("manageTeam##members##ADMIN")}
                    </span>
                  )}{" "}
                </Typography.Text>
                <Typography.Text css={styles.subText}>
                  {selectedOrgMember.email}
                </Typography.Text>
              </div>
            </div>
            <div css={styles.teamAdminCol}>
              <Typography.Title css={styles.drawerHeader} level={5}>
                {t("manageTeam##members##Admin")}
              </Typography.Title>
              <Checkbox
                disabled={selectedOrgMember.isCurrentUser}
                checked={isAdmin}
                onClick={() => handleToggleAdmin(selectedOrgMember)}
              />
            </div>
            <div css={styles.drawerInfoContainer}>
              <Typography.Title css={styles.drawerHeader} level={5}>
                {t("manageTeam##members##Job title")}
              </Typography.Title>
              <Typography.Text>
                {selectedOrgMember.jobTitle
                  ? t(`requesterRoles##${selectedOrgMember.jobTitle}`)
                  : selectedOrgMember.jobTitle}
              </Typography.Text>
            </div>

            <div css={styles.drawerInfoContainer}>
              <Typography.Title css={styles.drawerHeader} level={5}>
                {t("manageTeam##members##Phone")}
              </Typography.Title>
              <Typography.Text>{selectedOrgMember.phoneNumber}</Typography.Text>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  return <div>Loading...</div>;
};

export default OrgMemberMoreInfo;
