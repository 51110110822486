import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CopyLinkIcon } from "src/app/assets/icons/component-icons/";
import ControlButton from "../base-button";

interface IProps {
  showLabel?: boolean;
  showTooltip?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tooltipColor?: string;
}

const CopyLinkButton = ({
  showLabel = false,
  showTooltip = true,
  className = "",
  onClick,
  tooltipColor,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <ControlButton
      className={className}
      showLabel={showLabel}
      showTooltip={showTooltip}
      label={t("Copy link")}
      tooltipColor={tooltipColor}
      data-testid="copy-link-button"
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <CopyLinkIcon fill="#8058FF" />
    </ControlButton>
  );
};

export default CopyLinkButton;
