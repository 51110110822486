import React, { Fragment, ReactElement, useCallback } from "react";
import SearchFiltersDropDown from "src/components/search-filters-drop-down";
import { Checkbox, Divider } from "antd";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import getFilterLabel from "src/utils/getLabelFromFilterValue";
import { ITalentHubTypes } from "src/api/talent-hub/interfaces";
import { IRegionWithCountries } from "src/constants";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next/";
import useAuth from "src/auth/use-auth";
import styles from "../styles";
import { AllContactsFiltersValues, IContactsFilters } from "../interfaces";
import getFilterOptions, {
  getAllSelectedFilterValuesExceptForSelectedRegion,
  getAllSelectedFilterValuesForRegion,
  getLocationFilterOptions,
  getMappedLocationFilterOptions,
} from "../utils";

interface ITalentHubFiltersProps {
  availableContactFilters: IContactsFilters;
  collaborationFilterValues: string[];
  genreFilterValues: string[];
  instrumentFilterValues: string[];
  languageFilterValues: string[];
  locationFilterValues: string[];
  skillSetFilterValues: string[];
  vocalistFilterValues: string[];
  serviceProviderFilterValues: string[];
  racialCharacteristicFilterValues: string[];
  genderIdentityFilterValues: string[];
  signedNDAFilterValues: string[];
  signedCMAFilterValues: string[];
  talentHubTypes: ITalentHubTypes;
  onFilterOptionChange: (
    filterValue: string,
    isResetFilterValues: boolean,
    checkedLabels?: string[]
  ) => void;
  onClearFilters: (
    filterValue: string,
    isResetFilterValues: boolean,
    checkedLabels?: string[]
  ) => void;
  onApplyFilters: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const TalentHubFilters = ({
  availableContactFilters,
  collaborationFilterValues,
  genreFilterValues,
  instrumentFilterValues,
  languageFilterValues,
  locationFilterValues,
  skillSetFilterValues,
  vocalistFilterValues,
  serviceProviderFilterValues,
  racialCharacteristicFilterValues,
  genderIdentityFilterValues,
  signedNDAFilterValues,
  signedCMAFilterValues,
  talentHubTypes,
  onFilterOptionChange,
  onApplyFilters,
  onClearFilters,
}: ITalentHubFiltersProps): ReactElement => {
  const { t } = useTranslation();
  const { isSuperAdmin } = useAuth();
  const getDefaultSelectedFilterValues = useCallback(
    (filterName: string) => {
      switch (filterName) {
        case AllContactsFiltersValues.Collaboration:
          return collaborationFilterValues;
        case AllContactsFiltersValues.Genre:
          return genreFilterValues;
        case AllContactsFiltersValues.Instrument:
          return instrumentFilterValues;
        case AllContactsFiltersValues.Language:
          return languageFilterValues;
        case AllContactsFiltersValues.Location:
          return locationFilterValues;
        case AllContactsFiltersValues.SkillSet:
          return skillSetFilterValues;
        case AllContactsFiltersValues.Vocalist:
          return vocalistFilterValues;
        case AllContactsFiltersValues.ServiceProvider:
          return serviceProviderFilterValues;
        case AllContactsFiltersValues.RacialCharacteristics:
          return racialCharacteristicFilterValues;
        case AllContactsFiltersValues.GenderIdentity:
          return genderIdentityFilterValues;
        case AllContactsFiltersValues.NDASigned:
          return signedNDAFilterValues;
        case AllContactsFiltersValues.CMASigned:
          return signedCMAFilterValues;
        default:
          return [];
      }
    },
    [
      collaborationFilterValues,
      genderIdentityFilterValues,
      genreFilterValues,
      instrumentFilterValues,
      languageFilterValues,
      locationFilterValues,
      racialCharacteristicFilterValues,
      serviceProviderFilterValues,
      signedCMAFilterValues,
      signedNDAFilterValues,
      skillSetFilterValues,
      vocalistFilterValues,
    ]
  );

  const getAllAvailableRegionsWithCountriesCheckboxes = useCallback(
    (
      allAvailableRegionsWithCountries: IRegionWithCountries,
      filterOptions: IOption[]
    ) => {
      const allSelectedFilterValues = getDefaultSelectedFilterValues(
        AllContactsFiltersValues.Location
      );

      const allRegionsWithCountriesCheckboxes = Object.entries(
        allAvailableRegionsWithCountries
      ).map(([region, countries]) => {
        const countriesForRegion = getMappedLocationFilterOptions(
          countries,
          filterOptions
        );
        const allSelectedFilterValuesForRegion = getAllSelectedFilterValuesForRegion(
          countriesForRegion,
          allSelectedFilterValues
        );
        const allSelectedFilterValuesExceptForSelectedRegion = getAllSelectedFilterValuesExceptForSelectedRegion(
          allSelectedFilterValuesForRegion,
          allSelectedFilterValues
        );
        const isRegionSelected =
          (countries ?? []).length === allSelectedFilterValuesForRegion?.length;

        const isAnyCountrySelected = !!allSelectedFilterValuesForRegion?.length;
        const countriesForRegionValues = countriesForRegion.map(
          (country) => country.value
        );
        const checkedLabels = isRegionSelected
          ? allSelectedFilterValuesExceptForSelectedRegion
          : [
              ...allSelectedFilterValuesExceptForSelectedRegion,
              ...countriesForRegionValues,
            ];
        const dividedFilters = countries?.length ? (
          <div css={styles.locationCheckboxWrapper} key={region}>
            <Checkbox
              css={styles.regionCheckbox}
              checked={isRegionSelected}
              indeterminate={isAnyCountrySelected && !isRegionSelected}
              onChange={() => {
                onFilterOptionChange(
                  AllContactsFiltersValues.Location,
                  false,
                  checkedLabels
                );
              }}
            >
              {startCase(region)}
            </Checkbox>
            <Checkbox.Group
              css={styles.checkbox}
              options={countriesForRegion}
              value={allSelectedFilterValuesForRegion}
              onChange={(e) => {
                onFilterOptionChange(AllContactsFiltersValues.Location, false, [
                  ...allSelectedFilterValuesExceptForSelectedRegion,
                  ...e,
                ]);
              }}
            />
            <Divider />
          </div>
        ) : null;
        return dividedFilters;
      });
      const isEveryCountrySelected = filterOptions.every((option) =>
        allSelectedFilterValues.includes(option.value)
      );
      const isSomeCountrySelected = filterOptions.some((option) =>
        allSelectedFilterValues.includes(option.value)
      );
      const valuesToUpdate =
        isEveryCountrySelected || isSomeCountrySelected
          ? []
          : filterOptions.map((option) => option.value);
      const worldWideCheckBox = (
        <Fragment key="worldwide">
          <Checkbox
            css={styles.regionCheckbox}
            checked={isEveryCountrySelected}
            indeterminate={isSomeCountrySelected && !isEveryCountrySelected}
            onChange={() => {
              onFilterOptionChange(
                AllContactsFiltersValues.Location,
                false,
                valuesToUpdate
              );
            }}
          >
            {t("Worldwide")}
          </Checkbox>
          <Divider css={styles.dividerCheckbox} />
        </Fragment>
      );
      allRegionsWithCountriesCheckboxes.unshift(worldWideCheckBox);
      return allRegionsWithCountriesCheckboxes;
    },
    [getDefaultSelectedFilterValues, onFilterOptionChange, t]
  );

  const getCheckedFilters = useCallback(
    (filterName: string) => {
      const filterOptions: IOption[] = getFilterOptions(
        filterName,
        availableContactFilters,
        talentHubTypes
      );
      if (filterName === AllContactsFiltersValues.Location) {
        const allAvailableFilterLabels = filterOptions.map(
          (option) => option.label
        );

        const allAvailableRegionsWithCountries = getLocationFilterOptions(
          allAvailableFilterLabels
        );
        return getAllAvailableRegionsWithCountriesCheckboxes(
          allAvailableRegionsWithCountries,
          filterOptions
        );
      }

      return (
        <Checkbox.Group
          css={styles.checkbox}
          options={filterOptions}
          value={getDefaultSelectedFilterValues(filterName)}
          onChange={(e) => {
            onFilterOptionChange(filterName, false, e);
          }}
        />
      );
    },
    [
      availableContactFilters,
      talentHubTypes,
      getDefaultSelectedFilterValues,
      getAllAvailableRegionsWithCountriesCheckboxes,
      onFilterOptionChange,
    ]
  );

  const handleResetFilters = useCallback(
    (filterName: string) => {
      onFilterOptionChange(filterName, true);
    },
    [onFilterOptionChange]
  );

  return (
    <div css={styles.filtersContainers}>
      {Object.keys(availableContactFilters)
        .filter((filterName) => {
          if (
            filterName === AllContactsFiltersValues.ServiceProvider &&
            !isSuperAdmin
          ) {
            return false;
          }
          return true;
        })
        .map((filterName) => {
          return (
            <SearchFiltersDropDown
              key={filterName}
              value={filterName}
              onApplyFilters={onApplyFilters}
              content={getCheckedFilters(filterName)}
              label={getFilterLabel(filterName)}
              onClearAllFilters={onClearFilters}
              onResetFilters={handleResetFilters}
            />
          );
        })}
    </div>
  );
};

export default TalentHubFilters;
