import buildOrganisationsBaseUrl from "./base-url-helper";

const buildOrgInviteBaseUrl = (
  organisationId: string,
  inviteId: string
): string => {
  return `${buildOrganisationsBaseUrl(organisationId)}/app-invites/${inviteId}`;
};

export const getOrganisationInviteUrl = (
  organisationId: string,
  inviteId: string
): string => {
  return `${buildOrgInviteBaseUrl(organisationId, inviteId)}`;
};

export const getOrganisationDeleteInviteUrl = (
  organisationId: string,
  inviteId: string
): string => {
  return `${buildOrgInviteBaseUrl(organisationId, inviteId)}`;
};

export const getOrganisationInvitesUrl = (organisationId: string): string => {
  return `${buildOrganisationsBaseUrl(organisationId)}/app-invites/_search`;
};

export const sendOrganisationInvitesUrl = (organisationId: string): string => {
  return `${buildOrganisationsBaseUrl(organisationId)}/multi-app-invite`;
};

export const resendOrganisationInviteUrl = (organisationId: string): string => {
  return `${buildOrganisationsBaseUrl(
    organisationId
  )}/app-invites/_resend-invite`;
};

export const acceptOrganisationInviteUrl = (
  organisationId: string,
  inviteId: string
): string => {
  return `${buildOrgInviteBaseUrl(organisationId, inviteId)}/_accept`;
};
