import { TalentContactAttachmentType } from "src/constants";
import {
  Confirm,
  IContactAttachment,
  IContactForm,
  IContactsResults,
  Neurodiverse,
  PhysicalCondition,
  Signed,
} from "src/pages/contact/interfaces";

const mapResponseToContactForm = (response: IContactsResults): IContactForm => {
  const {
    id,
    name,
    pronoun,
    agencyWritingPartnership,
    email,
    phone,
    baseLocation,
    socialMediaWebsite,
    showreelUrls,
    mainLanguage,
    otherLanguages,
    billingName,
    billingAddress,
    physicalConditionsIllness,
    neurodiverse,
    neurodiverseNote,
    racialCharacteristics,
    genderIdentity,
    sexualOrientation,
    signedCMA,
    signedNDA,
    minimumDemoFee,
    performingRightsOrganisationNumber,
    connectedToOrgId,
    serviceProvider,
    collaborationStatus,
    skillSet,
    principalInstruments,
    additionalInstruments,
    keyGenres,
    additionalGenres,
    vocalist,
    minimumDemoTurnaroundHours,
    previousWork,
    interestedProjects,
    talentNotes,
    awardNotes,
    internalNotes,
    createdByOrgId,
    ipi,
  } = response;

  let vocalistData: Confirm | null = null;
  if (vocalist) {
    vocalistData = Confirm.Yes;
  } else if (vocalist === false) {
    vocalistData = Confirm.No;
  }

  return {
    id,
    name,
    pronoun,
    agencyWritingPartnership,
    email,
    phone,
    baseLocation,
    socialMediaWebsite,
    showreelUrls,
    mainLanguage,
    otherLanguages,
    billingName,
    billingAddress,
    physicalConditionsIllness:
      (physicalConditionsIllness as PhysicalCondition) || null,
    neurodiverse: (neurodiverse as Neurodiverse) || null,
    neurodiverseNote,
    racialCharacteristics,
    genderIdentity,
    sexualOrientation,
    signedCMA: (signedCMA as Signed) || null,
    signedNDA: (signedNDA as Signed) || null,
    minimumDemoFee,
    performingRightsOrganisationNumber,
    connectedToOrgId: connectedToOrgId || null,
    serviceProvider: serviceProvider || null,
    collaborationStatus,
    skillSet,
    principalInstruments,
    additionalInstruments,
    keyGenres,
    additionalGenres,
    vocalist: vocalistData,
    minimumDemoTurnaroundHours,
    previousWork,
    interestedProjects,
    talentNotes,
    awardNotes,
    internalNotes,
    showReel: [],
    documents: [],
    createdByOrgId,
    ipi,
  };
};

interface IAttachmentsByType {
  showReels: IContactAttachment[];
  documents: IContactAttachment[];
}

export const getAttachmentsByType = (
  attachments: IContactAttachment[]
): IAttachmentsByType => {
  const attachmentsByType: IAttachmentsByType = {
    showReels: [],
    documents: [],
  };

  attachments.forEach((attachment) => {
    const mappedAttachment: IContactAttachment = {
      ...attachment,
      status: "done",
      percent: 100,
      type: attachment.contentType,
      uid: attachment.id,
      size: 0,
    };
    if (
      mappedAttachment.attachmentType === TalentContactAttachmentType.ShowReel
    ) {
      attachmentsByType.showReels.push(mappedAttachment);
    } else {
      attachmentsByType.documents.push(mappedAttachment);
    }
  });

  return attachmentsByType;
};

export default mapResponseToContactForm;
