import { ISelectLabel } from "src/components/modals/clear-filter/utils";
import {
  IContact,
  IHoldingCompany,
} from "../../project/components/account-information/types";
import {
  ICreativeAgency,
  MusicTypes,
  ProjectStatus,
  ServicesTypes,
  IFinalTrackSection,
  IProjectTerm,
} from "../../project/interfaces";

export enum ProjectSortType {
  lastUpdated = "lastUpdated",
  created = "created",
  name = "name",
  campaignName = "campaignName",
  status = "status",
  contractStartDate = "contractStartDate",
  contractEndDate = "contractEndDate",
  termEndDate = "termEndDate",
  jobListingDate = "jobListingDate",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface IProjectTableSort {
  sortBy: ProjectSortType;
  order: SortOrder;
}

export interface IMyPresetFilters {
  myOpenProjects: boolean;
  myAtContractProjects: boolean;
  myClosedProjects: boolean;
}

export interface IFiltersResponse {
  statuses: ProjectStatus[];
  clients: IProjectClient[];
  leads: IProjectLead[];
  globalBrands: IGlobalBrands[];
  contacts: IContact[];
  creativeAgencies: ICreativeAgency[];
  holdingCompanies: IHoldingCompany[];
  musicTypes: MusicTypes[];
  projectTypes: string[];
  serviceTypes: ServicesTypes[];
  projectRegions: string[];
  officeLocations: string[];
  copyrightInfoProcessed: boolean | null;
  publishingRightsSigned: boolean | null;
  publishingTriggered: boolean | null;
}

export type FilterKeys =
  | "statuses"
  | "clients"
  | "leads"
  | "globalBrands"
  | "contacts"
  | "creativeAgencies"
  | "holdingCompanies"
  | "musicTypes"
  | "projectTypes"
  | "serviceTypes"
  | "projectRegions"
  | "officeLocations";

export interface IFiltersSearch {
  statuses: string[];
  clients: string[];
  leads: string[];
  globalBrands: string[];
  contacts: string[];
  creativeAgencies: string[];
  holdingCompanies: string[];
  musicTypes: string[];
  projectTypes: string[];
  serviceTypes: string[];
  projectCloseDateFrom: string | null;
  projectCloseDateTo: string | null;
  jobListingDateFrom: string | null;
  jobListingDateTo: string | null;
  projectRegions: string[];
  officeLocations: string[];
  copyrightInfoProcessed: boolean | null;
  publishingRightsSigned: boolean | null;
  publishingTriggered: boolean | null;
}

export interface IActiveLabels {
  statuses: ISelectLabel[];
  clients: ISelectLabel[];
  leads: ISelectLabel[];
  globalBrands: ISelectLabel[];
  contacts: ISelectLabel[];
  creativeAgencies: ISelectLabel[];
  holdingCompanies: ISelectLabel[];
  musicTypes: ISelectLabel[];
  projectTypes: ISelectLabel[];
  serviceTypes: ISelectLabel[];
  projectCloseDate: ISelectLabel[];
  jobListingDate: ISelectLabel[];
  projectRegions: ISelectLabel[];
  officeLocations: ISelectLabel[];
  copyrightInfoProcessed: ISelectLabel[];
  publishingRightsSigned: ISelectLabel[];
  publishingTriggered: ISelectLabel[];
  [key: string]: ISelectLabel[];
}

export interface IProjectClient {
  id: string;
  name: string;
  holdingCompany?: {
    name?: string;
    id?: string;
  };
}

export interface IGlobalBrands {
  id: string;
  name: string;
}

export interface IProjectLead {
  id: string;
  name: string;
  email: string;
}

export interface IProjectProps {
  id: string;
  sequenceNo: string;
  campaignName: string;
  organisationId: string;
  client: {
    id: string;
    name: string;
    holdingCompany?: IHoldingCompany;
  };
  name: string;
  projectType?: string;
  leads?: IProjectLead[];
  services?: [
    {
      serviceType: string;
      musicType: string;
      asset: undefined;
      thirdPartyInvolved: boolean;
      thirdParties: [];
    }
  ];
  created: string;
  status: ProjectStatus;
  lastUpdated: string;
  lastUpdatedBy: string;
  contractStartDate?: string;
  contractEndDate?: string;
  product?: string;
  jobListingDate?: string;
  tracks: IFinalTrackSection[];
  terms: IProjectTerm[];
  projectRegion: string;
  hasPublishingBeenTriggered: boolean;
}

export interface IProjectSearchResponse {
  totalPages: number;
  totalResults: number;
  currentPage: number;
  results: IProjectProps[];
}

export enum DrawerTypes {
  sortProjects = "sortProjects",
  projectInfo = "projectInfo",
  deleteProject = "deleteProject",
}

export interface IDeleteProjectProps {
  id: string;
  name: string;
}

export interface IPaginationOptions {
  total: number;
  currentPage: number;
}
