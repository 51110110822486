import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import useAuth from "src/auth/use-auth";
import Vinyl404 from "src/app/assets/images/vinyl-404.png";
import StyledButton from "src/components/button";
import style from "./styles";

interface IProps {
  heading?: string;
  description?: string;
  buttonText?: string;
  buttonPath?: string;
}

const NotFound = ({
  heading = "Not found",
  description = "Sorry, we can't find this page.",
  buttonText = "Go to the main page",
  buttonPath = "/",
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  return (
    <div css={style.container}>
      <div css={style.contentContainer}>
        <img css={style.image} src={Vinyl404} alt={t(heading)} />
        <Typography.Title level={1} css={style.heading}>
          {t(heading)}
        </Typography.Title>
        <Typography.Text css={style.textContainer}>
          {t(description)}
        </Typography.Text>
        {isAuthenticated && (
          <StyledButton
            ariaLabel={t(buttonText)}
            css={style.button}
            onClick={() => history.push(buttonPath)}
          >
            {t(buttonText)}
          </StyledButton>
        )}
      </div>
    </div>
  );
};

export default NotFound;
