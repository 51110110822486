import React, { ReactElement, useEffect, useState } from "react";
import { Typography } from "antd";
import * as uuid from "uuid";
import { INameLinkState } from "src/pages/projects/project/interfaces";
import { Operation } from "src/constants";
import { useTranslation } from "react-i18next";
import styles from "src/pages/projects/project/styles";
import NameLinkRow from "./name-link-row";

interface IProps {
  fieldName: string;
  items: Array<INameLinkState>;
  nameHelpText?: string;
  onPerformOperation: (
    operation: Operation,
    data: INameLinkState,
    index: number
  ) => void;
  fileUploader?: JSX.Element;
  additionalText?: string;
  additionalHeader?: string;
  customLinkText?: string;
}

const NameLinkRows = ({
  fieldName,
  items,
  nameHelpText,
  onPerformOperation,
  fileUploader,
  additionalText,
  additionalHeader,
  customLinkText,
}: IProps): ReactElement => {
  const [rowItems, setRowItems] = useState<INameLinkState[]>([]);
  const { t } = useTranslation();

  const addNewRowItem = () => {
    onPerformOperation(
      Operation.add,
      {
        name: "",
        link: "",
        isNew: true,
        key: uuid.v4(),
      },
      items.length
    );
  };

  const handleDeleteRowItem = (index: number) => {
    const itemToDelete = items[index];
    if (itemToDelete) {
      onPerformOperation(Operation.remove, itemToDelete, index);
    }
  };

  const handleUpdateRowItem = (
    index: number,
    nameLinkState: INameLinkState
  ) => {
    onPerformOperation(Operation.update, nameLinkState, index);
  };

  useEffect(() => {
    if (items.length !== 0) {
      setRowItems(items);
    } else {
      setRowItems([{ name: "", link: "", isNew: true, key: uuid.v4() }]);
    }
  }, [items]);

  return (
    <div css={styles.multipleRows}>
      {fileUploader}
      {additionalHeader && (
        <div css={styles.basicInputContainer}>
          <Typography.Text css={styles.basicInputLabel}>
            {t(additionalHeader)}
          </Typography.Text>
        </div>
      )}
      {additionalText && (
        <div css={styles.basicInputContainer}>
          <Typography.Text css={styles.basicInputLabel}>
            {t(additionalText)}
          </Typography.Text>
        </div>
      )}

      {[...rowItems]
        .filter((item) => item)
        .map((nameLink, nameLinkIndex) => {
          return (
            <NameLinkRow
              key={`${fieldName}-nameLinkRow-${nameLink.key}`}
              data={nameLink}
              customLinkText={customLinkText}
              index={nameLinkIndex}
              onDeleteRow={handleDeleteRowItem}
              onUpdateRow={handleUpdateRowItem}
              nameHelpText={nameHelpText}
              totalRows={rowItems.length}
              fieldName={fieldName}
              addNewRowItem={addNewRowItem}
            />
          );
        })}
    </div>
  );
};

export default NameLinkRows;
