import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Switch, Typography } from "antd";
import { getIsMobile } from "@songtradr/spa-common/lib/utils";
import DeleteButton from "src/components/control-buttons/delete-button";
import {
  IContactReferences,
  IFeeSplitDetailState,
} from "src/pages/projects/project/interfaces";
import { PlusOutlined } from "@ant-design/icons";
import ControlButton from "src/components/control-buttons/base-button";
import { getIsValidEmail } from "src/pages/team/components/org-invites/components/invite-members/email-validator";
import CurrencyAndPrice from "src/components/currency-and-price";
import { Currencies } from "src/constants";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import { formatCurrency } from "src/utils/currency";
import { FormInstance } from "antd/lib/form";
import { getCalculatedSplitFee } from "src/pages/projects/project/utils";
import getValuesFromTalent from "src/utils/getValuesFromTalent";
import styles from "./styles";
import { IFeeSplitConfig } from "../final-track-ownership/interfaces";
import mainStyles from "../../../../styles";

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  totalFee: string;
  feeSplitDetail: IFeeSplitDetailState;
  index: number;
  config: IFeeSplitConfig;
  isWritersShareSection: boolean;
  propertyName: string;
  onDeleteRow: (index: number) => void;
  onUpdateRow: (index: number, feeSplitDetail: IFeeSplitDetailState) => void;
  validateRequiredFields: boolean;
  isLastItem: boolean;
  onAddNewRowItem: () => void;
}

export interface IUpdateRowDataType {
  contactReferences?: IContactReferences[];
  split?: string;
  taxWithholdingFee?: IMonetaryValue;
  bankFee?: IMonetaryValue;
  ipi?: string;
  isNew: boolean;
  key: string;
  paythrough?: boolean;
}

export enum ColumnIdentifier {
  email = "email",
  ipi = "ipi",
  split = "split",
  name = "name",
  taxWithholdingFee = "taxWithholdingFee",
  bankFee = "bankFee",
}

export enum ValidateFormItem {
  onBlur = "onBlur",
  onChange = "onChange",
}

const FeeSplitRow = ({
  totalFee,
  feeSplitDetail,
  index,
  config,
  isWritersShareSection,
  propertyName,
  form,
  onDeleteRow,
  onUpdateRow,
  onAddNewRowItem,
  validateRequiredFields,
  isLastItem: showaddbutton,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const [name, setName] = useState<string>(
    feeSplitDetail?.contactReferences?.[0]?.name ?? ""
  );
  const [email, setEmail] = useState<string>(
    feeSplitDetail?.contactReferences?.[0]?.email ?? ""
  );
  const [split, setSplit] = useState<string>(feeSplitDetail.split ?? "");
  const [ipi, setIpi] = useState<string>(
    feeSplitDetail?.contactReferences?.[0]?.ipi ?? ""
  );

  const taxWithholdingFee = useMemo(() => feeSplitDetail?.taxWithholdingFee, [
    feeSplitDetail?.taxWithholdingFee,
  ]);
  const bankFee = useMemo(() => feeSplitDetail?.bankFee, [
    feeSplitDetail?.bankFee,
  ]);

  const taxWithholdingFeePriceName = useMemo(
    () => `${propertyName}${index}TaxWithholdingFeePrice`,
    [index, propertyName]
  );
  const bankFeePriceName = useMemo(
    () => `${propertyName}${index}BankFeePrice`,
    [index, propertyName]
  );

  const handleFeeChange = useCallback(
    (updatedFee: IMonetaryValue, isBankFee: boolean) => {
      const updatedRowData: IUpdateRowDataType = {
        contactReferences: [
          getValuesFromTalent(feeSplitDetail?.contactReferences?.[0]),
        ],
        split: feeSplitDetail.split,
        isNew: feeSplitDetail.isNew,
        key: feeSplitDetail.key,
        taxWithholdingFee: !isBankFee
          ? updatedFee
          : feeSplitDetail.taxWithholdingFee,
        bankFee: isBankFee ? updatedFee : feeSplitDetail.bankFee,
        paythrough: feeSplitDetail.paythrough,
      };
      onUpdateRow(index, updatedRowData);
    },
    [
      feeSplitDetail.bankFee,
      feeSplitDetail?.contactReferences,
      feeSplitDetail.isNew,
      feeSplitDetail.key,
      feeSplitDetail.paythrough,
      feeSplitDetail.split,
      feeSplitDetail.taxWithholdingFee,
      index,
      onUpdateRow,
    ]
  );

  const [validationType, setValidationType] = useState<string | undefined>(
    undefined
  );
  const [fee, setFee] = useState<string>(
    getCalculatedSplitFee(totalFee, feeSplitDetail.split ?? "")
  );
  const [paythroughToogle, setPaythroughToggle] = useState<boolean>(
    feeSplitDetail.paythrough ?? false
  );

  useEffect(() => {
    setFee(getCalculatedSplitFee(totalFee, feeSplitDetail.split ?? ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFee]);

  const handleColumnUpdate = useCallback(
    (
      event: React.FocusEvent<HTMLInputElement>,
      setState: React.Dispatch<React.SetStateAction<string>>,
      columnName: ColumnIdentifier
    ) => {
      const { value } = event.target;
      let state;

      switch (columnName) {
        case ColumnIdentifier.ipi:
          state = ipi;
          break;
        case ColumnIdentifier.email:
          state = email;
          break;
        case ColumnIdentifier.split:
          state = split;
          break;
        default:
          state = name;
      }
      const isNameColumn = columnName === ColumnIdentifier.name;
      const isEmailColumn = columnName === ColumnIdentifier.email;
      const isSplitColumn = columnName === ColumnIdentifier.split;
      const isIpiColumn = columnName === ColumnIdentifier.ipi;

      if (value !== state) {
        setState(value);
        const updatedRowData: IUpdateRowDataType = {
          contactReferences: [
            {
              ...getValuesFromTalent(feeSplitDetail?.contactReferences?.[0]),
              name: isNameColumn
                ? value
                : feeSplitDetail?.contactReferences?.[0]?.name ?? "",
              email: isEmailColumn
                ? value
                : feeSplitDetail?.contactReferences?.[0]?.email ?? "",
            },
          ],
          split: isSplitColumn ? value : feeSplitDetail.split,
          bankFee,
          taxWithholdingFee,
          isNew: feeSplitDetail.isNew,
          key: feeSplitDetail.key,
        };
        if (isWritersShareSection) {
          updatedRowData.ipi = isIpiColumn
            ? value
            : feeSplitDetail?.contactReferences?.[0]?.ipi;
        } else {
          updatedRowData.paythrough = paythroughToogle;
        }
        onUpdateRow(index, updatedRowData);
      }
    },
    [
      ipi,
      email,
      split,
      name,
      feeSplitDetail?.contactReferences,
      feeSplitDetail.split,
      feeSplitDetail.isNew,
      feeSplitDetail.key,
      bankFee,
      taxWithholdingFee,
      isWritersShareSection,
      onUpdateRow,
      index,
      paythroughToogle,
    ]
  );

  const NameColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t(config.nameLabel)}
          </Typography.Text>
        </div>
        <Form.Item
          css={mainStyles.formItem}
          name={`${propertyName}${index}contactReferences0Name`}
          rules={[
            () => ({
              validator(_rule, value?: string) {
                if (validateRequiredFields && !value) {
                  return Promise.reject(new Error("This is a required field"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            id={`${config.nameLabel}-name-${index}`}
            aria-label={t(config.nameLabel)}
            onBlur={(e) =>
              handleColumnUpdate(e, setName, ColumnIdentifier.name)
            }
          />
        </Form.Item>
      </div>
    ),
    [
      config.nameLabel,
      handleColumnUpdate,
      index,
      isMobile,
      propertyName,
      t,
      validateRequiredFields,
    ]
  );

  const EmailColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t(config.emailLabel)}
          </Typography.Text>
        </div>
        <Form.Item
          css={mainStyles.formItem}
          name={`${propertyName}${index}contactReferences0Email`}
          validateTrigger={validationType}
          rules={[
            () => ({
              validator(_rule, value?: string) {
                if (validateRequiredFields && !value) {
                  return Promise.reject(new Error("This is a required field"));
                }
                if (value && validationType !== ValidateFormItem.onChange) {
                  const isEmailValid = getIsValidEmail(value);

                  if (!isEmailValid) {
                    return Promise.reject(new Error("Email format is invalid"));
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            id={`${config.nameLabel}-email-${index}`}
            aria-label={t(config.emailLabel)}
            onChange={() => setValidationType(ValidateFormItem.onChange)}
            onBlur={(e) => {
              setValidationType(ValidateFormItem.onBlur);
              handleColumnUpdate(e, setEmail, ColumnIdentifier.email);
            }}
          />
        </Form.Item>
      </div>
    ),
    [
      config.emailLabel,
      config.nameLabel,
      handleColumnUpdate,
      index,
      isMobile,
      propertyName,
      t,
      validateRequiredFields,
      validationType,
    ]
  );

  const SplitColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t("ProjectsPage##musicSection##Share %")}
          </Typography.Text>
        </div>
        <Form.Item
          name={`${propertyName}${index}Split`}
          css={mainStyles.formItem}
          rules={[
            () => ({
              validator(_rule, value?: string) {
                if (validateRequiredFields && !value) {
                  return Promise.reject(new Error("This is a required field"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            id={`${config.nameLabel}-split-${index}`}
            type="number"
            css={styles.feeSplit}
            aria-label={t("ProjectsPage##musicSection##Share %")}
            onBlur={(e) =>
              handleColumnUpdate(e, setSplit, ColumnIdentifier.split)
            }
          />
        </Form.Item>
      </div>
    ),
    [
      config.nameLabel,
      handleColumnUpdate,
      index,
      isMobile,
      propertyName,
      t,
      validateRequiredFields,
    ]
  );

  const FeeColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t("ProjectsPage##musicSection##Fee amount")}
          </Typography.Text>
        </div>
        <Form.Item css={mainStyles.formItem}>
          <Input
            id={`${config.nameLabel}-fee-${index}`}
            type="string"
            aria-label={t("ProjectsPage##musicSection##Fee amount")}
            value={fee}
            disabled
          />
        </Form.Item>
      </div>
    ),
    [config.nameLabel, fee, index, isMobile, t]
  );

  const handleToggleChange = useCallback(
    (value: boolean) => {
      setPaythroughToggle(value);
      const updatedRowData: IUpdateRowDataType = {
        contactReferences: [
          getValuesFromTalent(feeSplitDetail?.contactReferences?.[0]),
        ],
        split: feeSplitDetail.split,
        isNew: feeSplitDetail.isNew,
        key: feeSplitDetail.key,
        paythrough: value,
        bankFee: feeSplitDetail.bankFee,
        taxWithholdingFee: feeSplitDetail.taxWithholdingFee,
      };
      onUpdateRow(index, updatedRowData);
    },
    [feeSplitDetail, index, onUpdateRow]
  );

  const paythroughToggleColumn = (
    <div css={isMobile && mainStyles.playThrough}>
      <div css={!isMobile && mainStyles.basicInputContainer}>
        <Typography.Text css={mainStyles.basicInputLabel}>
          {t("ProjectsPage##servicesSection##Paythrough")}
        </Typography.Text>
      </div>
      <Form.Item css={mainStyles.formItem}>
        <Switch
          onChange={handleToggleChange}
          checked={paythroughToogle}
          css={!isMobile && mainStyles.switchMargin}
        />
      </Form.Item>
    </div>
  );

  const IPIColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t("ProjectsPage##musicSection##Writer IPI")}
          </Typography.Text>
        </div>
        <Form.Item css={mainStyles.formItem}>
          <Input
            id={`${config.nameLabel}-writerIpi-${index}`}
            type="string"
            aria-label={t("ProjectsPage##musicSection##Writer IPI")}
            onBlur={(e) => handleColumnUpdate(e, setIpi, ColumnIdentifier.ipi)}
            defaultValue={ipi}
          />
        </Form.Item>
      </div>
    ),
    [config.nameLabel, handleColumnUpdate, index, ipi, isMobile, t]
  );

  useEffect(() => {
    form?.setFieldsValue({
      [taxWithholdingFeePriceName]: taxWithholdingFee?.value
        ? formatCurrency(taxWithholdingFee.value)
        : "",
      [bankFeePriceName]: bankFee?.value ? formatCurrency(bankFee.value) : "",
    });
  }, [
    bankFee,
    bankFeePriceName,
    form,
    taxWithholdingFee,
    taxWithholdingFeePriceName,
  ]);

  const TaxWithholdingFeeColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <CurrencyAndPrice
          currency={
            feeSplitDetail.taxWithholdingFee?.currency ?? Currencies.USD.code
          }
          price={taxWithholdingFee?.value ?? ""}
          currencyFormItemName={`${propertyName}${index}TaxWithholdingFeeCurrency`}
          priceFormItemName={`${propertyName}${index}TaxWithholdingFeePrice`}
          onChange={(_, updatedFee) => handleFeeChange(updatedFee, false)}
          label={t("ProjectsPage##musicSection##Tax withholding fee")}
          propertyName={propertyName}
          validateRequiredFields={false}
        />
      </div>
    ),
    [
      feeSplitDetail.taxWithholdingFee?.currency,
      handleFeeChange,
      index,
      isMobile,
      propertyName,
      t,
      taxWithholdingFee?.value,
    ]
  );

  const BankFeeColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <CurrencyAndPrice
          currency={feeSplitDetail.bankFee?.currency ?? Currencies.USD.code}
          price={feeSplitDetail.bankFee?.value ?? ""}
          currencyFormItemName={`${propertyName}${index}BankFeeCurrency`}
          priceFormItemName={`${propertyName}${index}BankFeePrice`}
          onChange={(_, updatedFee) => handleFeeChange(updatedFee, true)}
          label={t("ProjectsPage##musicSection##Bank fee")}
          propertyName={propertyName}
          validateRequiredFields={false}
        />
      </div>
    ),
    [
      feeSplitDetail.bankFee?.currency,
      feeSplitDetail.bankFee?.value,
      handleFeeChange,
      index,
      isMobile,
      propertyName,
      t,
    ]
  );

  const deleteColumn = useCallback(
    (showEmpty: boolean) => (
      <DeleteButton
        css={(index === 0 || showEmpty) && styles.invisibleContainer}
        onClick={() => onDeleteRow(index)}
      />
    ),
    [index, onDeleteRow]
  );

  const AddAndDeleteColumn = useCallback(
    (showEmpty = false) => {
      const visibility = !showaddbutton || showEmpty ? "hidden" : "visible";

      return (
        <div css={mainStyles.iconsContainer}>
          {deleteColumn(showEmpty)}
          <div
            style={{
              visibility,
            }}
          >
            <div css={mainStyles.addIconContainer}>
              <ControlButton
                showTooltip
                tooltipColor="#222222"
                label={t("Add new")}
                data-testid="add-new-row-button"
                onClick={onAddNewRowItem}
              >
                <PlusOutlined />
              </ControlButton>
            </div>
          </div>
        </div>
      );
    },
    [deleteColumn, onAddNewRowItem, showaddbutton, t]
  );

  const emptyColumn = useMemo(
    () => <div css={[!isMobile && mainStyles.sizeOfHalfDiv]} />,
    [isMobile]
  );

  useEffect(() => {
    setFee(getCalculatedSplitFee(totalFee, feeSplitDetail.split ?? ""));
  }, [feeSplitDetail.split, totalFee]);

  if (!isWritersShareSection) {
    return (
      <>
        <div
          css={
            isMobile
              ? mainStyles.mobileSectionContainer
              : mainStyles.clientContainer
          }
        >
          {NameColumn}
          {EmailColumn}
          {SplitColumn}
          {FeeColumn}
          {paythroughToggleColumn}
          {emptyColumn}
          {AddAndDeleteColumn(true)}
        </div>
        <div
          css={
            isMobile
              ? mainStyles.mobileSectionContainer
              : mainStyles.clientContainer
          }
        >
          {TaxWithholdingFeeColumn}
          {BankFeeColumn}
          {emptyColumn}
          {AddAndDeleteColumn()}
        </div>
      </>
    );
  }

  return (
    <div
      css={
        isMobile
          ? mainStyles.mobileSectionContainer
          : mainStyles.clientContainer
      }
    >
      {NameColumn}
      {EmailColumn}
      {IPIColumn}
      {SplitColumn}
      {FeeColumn}
      {AddAndDeleteColumn()}
    </div>
  );
};

export default FeeSplitRow;
