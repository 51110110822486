import { ReactElement } from "react";
import theme from "src/theme";
import IIconComponentProps from "../../interfaces";

const UploadCompleteIcon = ({
  fill = theme.colors.secondary.cyan,
  height = "38",
  width = "38",
  title,
  role = "img",
}: IIconComponentProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
      aria-labelledby="upload-complete"
      role={role}
      className="uploadCompleteIcon"
    >
      <title id="close-icon-title">{title}</title>
      <g fill="none" fillRule="evenodd">
        <circle cx="19" cy="19" r="11.25" stroke={fill} strokeWidth="1.5" />
        <line
          x1="13.704"
          x2="17.204"
          y1="19"
          y2="23.203"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          x1="16.803"
          x2="23.988"
          y1="14.874"
          y2="23.203"
          stroke={fill}
          strokeLinecap="round"
          strokeWidth="1.5"
          transform="matrix(-1 0 0 1 41.192 0)"
        />
      </g>
    </svg>
  );
};

export default UploadCompleteIcon;
