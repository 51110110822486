import React from "react";
import { Form, Switch, Typography } from "antd";
import style from "../../styles";

interface IProps {
  title: string;
  checked: boolean;
  onChange: (toggle: boolean) => void;
}

const ContactSwitch = ({
  title,
  checked,
  onChange,
}: IProps): React.ReactElement => {
  return (
    <div css={style.switchContainer}>
      <div css={style.basicInputContainer}>
        <Typography.Text
          css={[style.basicInputLabel, style.basicLabelAdditionalStyles]}
        >
          {title}
        </Typography.Text>
      </div>
      <Form.Item css={[style.formItem, style.additionalFormItemStyle]}>
        <Switch onChange={onChange} checked={checked} />
      </Form.Item>
    </div>
  );
};

export default ContactSwitch;
