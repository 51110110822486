import axios from "axios";
import { patchOrganisationMemberUrl } from "src/api/organisation-members-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";
import { IOrgMemberPatch } from "./interfaces";

const patchOrganisationMember = (
  accessToken: string,
  orgId: string,
  auth0UserId: string,
  request: IOrgMemberPatch
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.patch(
    `${patchOrganisationMemberUrl(orgId, auth0UserId)}`,
    request,
    options
  );
};

export default patchOrganisationMember;
