import { UploadFile } from "antd/lib/upload/interface";
import { saveAs } from "file-saver";
import { TFunction } from "i18next";
import deleteFile from "src/api/attachments/delete-file";
import downloadFile from "src/api/attachments/download-file";
import getPresignedDownloadUrl from "src/api/attachments/get-presigned-download-url";
import getSharedFileUrl from "src/api/attachments/get-shared-file-url";
import updateAttachment from "src/api/attachments/update-attachment";
import verifyFileUpload from "src/api/attachments/verify-file-upload";
import { getProject } from "src/api/projects";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { IProjectUploadFile } from "src/pages/projects/project/components/details/components/interfaces";
import { IProjectForm } from "src/pages/projects/project/interfaces";
import { IFileUploadStatus } from "../interfaces";

export const handleDeleteFile = async (
  setFileList: (files: UploadFile[]) => void,
  filesList: UploadFile[],
  setIsModalOpen: (show: boolean) => void,
  t: TFunction,
  index?: number,
  accessToken?: string,
  projectId?: string,
  orgId?: string,
  file?: IProjectUploadFile,
  showToast = true
): Promise<void> => {
  const updatedItems: Array<IProjectUploadFile> = [];

  try {
    if (file && accessToken && projectId && orgId) {
      await deleteFile({
        attachmentID: file.uid,
        projectId,
        accessToken,
        orgId,
      });

      setIsModalOpen(false);

      if (showToast) {
        SuccessToast(t("File Deleted"));
      }

      if (file.request) {
        file.request.abort();
      }
    }
    if (index !== null && index !== undefined) {
      Object.assign(updatedItems, filesList);
      updatedItems.splice(index, 1);
      setFileList(updatedItems);
    }
  } catch {
    if (showToast) {
      ErrorToast(
        `FileUploadErrorToast${Math.random()}`,
        t("There was a problem deleting the file")
      );
    }
  }
};

export const handleVerifyFileUpload = async (
  t: TFunction,
  accessToken?: string,
  projectId?: string,
  orgId?: string,
  file?: IProjectUploadFile,
  showToast = true
): Promise<boolean> => {
  try {
    if (file && accessToken && projectId && orgId) {
      await verifyFileUpload({
        attachmentId: file.uid,
        projectId,
        accessToken,
        orgId,
      });

      return true;
    }
  } catch {
    if (showToast) {
      ErrorToast(
        `VerifyFileUploadErrorToast${Math.random()}`,
        t("There was a problem verifying the file upload")
      );
    }
  }

  return false;
};

export const handleDeleteClick = (
  setIsModalOpen: (show: boolean) => void
): void => {
  setIsModalOpen(true);
};

export const handleFileDownload = async (
  accessToken: string,
  file: UploadFile,
  projectId: string,
  organisationId: string,
  isMenuItemDownload = true
): Promise<boolean> => {
  try {
    const getPresignedDownloadURL = await getPresignedDownloadUrl({
      fileName: file.name,
      attachmentId: file.uid,
      projectId,
      accessToken,
      organisationId,
    });
    if (isMenuItemDownload) {
      const fileToSave: Blob = await downloadFile(
        getPresignedDownloadURL.data.url,
        file.type
      );
      saveAs(fileToSave, file.name);
    } else {
      const documentUrl = getPresignedDownloadURL.data.url;
      window.open(documentUrl, "_blank");
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const handlePublicFileDownload = async (
  file: UploadFile,
  shareCode: string,
  projectId: string
): Promise<boolean> => {
  try {
    const getPresignedDownloadURL = await getSharedFileUrl(
      projectId,
      file.uid,
      shareCode
    );

    const fileToSave: Blob = await downloadFile(
      getPresignedDownloadURL.data.url,
      file.type
    );
    saveAs(fileToSave, file.name);

    return true;
  } catch (error) {
    return false;
  }
};

export const checkFilesCurrentlyUploading = (
  fileList: UploadFile[]
): boolean => {
  const uploadingFiles = fileList.filter(
    (file: UploadFile) => file.status === IFileUploadStatus.uploading
  );

  if (uploadingFiles.length === 0) {
    return false;
  }
  return true;
};

export const handleShareToggle = async (
  attachmentId: string,
  accessToken: string,
  projectId: string,
  organisationId: string,
  value: boolean,
  storeProject: (projectToStore: IProjectForm) => void
): Promise<boolean> => {
  try {
    const request = {
      Public: value,
      OrganisationId: organisationId,
    };
    const updateResponse = await updateAttachment({
      attachmentId,
      projectId,
      accessToken,
      request,
    });

    if (updateResponse.status === 200) {
      const projectResponse = await getProject(
        accessToken,
        projectId,
        organisationId
      );

      if (projectResponse) {
        storeProject(projectResponse);
      }
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
