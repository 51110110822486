import React, { Fragment, ReactElement } from "react";
import OrganisationMemberContainer from "../components/org-members/containers/org-members";
import OrganisationInvitesContainer from "../components/org-invites/containers/org-invites";
import style from "../styles";

interface IProps {
  invitesLastUpdatedAt: Date;
  setInvitesLastUpdatedAt: (date: Date) => void;
}

const TeamPage = ({
  invitesLastUpdatedAt,
  setInvitesLastUpdatedAt,
}: IProps): ReactElement => {
  return (
    <Fragment>
      <OrganisationMemberContainer
        setInvitesLastUpdatedAt={setInvitesLastUpdatedAt}
      />
      <div css={style.formContainer}>
        <OrganisationInvitesContainer
          invitesLastUpdatedAt={invitesLastUpdatedAt}
        />
      </div>
    </Fragment>
  );
};

export default TeamPage;
