import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import { StylesConfig } from "react-select";
import theme from "src/theme";

type IsMulti = false;
interface IOption {
  label: string;
  value: string;
}
export const selectStyle: StylesConfig<IOption, IsMulti> = {
  control: (provided) => {
    return {
      ...provided,
      minHeight: 56,
    };
  },
};

export default {
  container: css`
    width: 100%;
  `,
  iconContainer: css`
    margin-top: -0.5rem;
    @media ${queries.medium} {
      margin-top: -1rem;
      margin-bottom: 0.5rem;
    }
  `,
  divider: css`
    margin-top: 0 !important;
    border-top: 0.0625rem solid ${theme.colors.background.lightGray} !important;
  `,
  clientNotListed: css`
    font-weight: 200;
    color: ${theme.colors.background.darkGray};
  `,
  addNewClient: css`
    color: ${theme.colors.primary.blue};
    font-family: ${theme.fonts.sofiaPro};
    border: 0;
    padding: 0;
    background-color: ${theme.colors.white};
    font-weight: bold;
    font-size: 1rem;
    padding-left: 0.5rem;
  `,
  optionRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    :hover {
      background-color: none !important;
    }
  `,
  optionRowDelete: css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    background: none !important;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 32px;
    z-index: 999;
    :hover {
      background: rgba(31, 72, 231, 0.16) !important;
    }
  `,
  closeBtn: css`
    background-color: white !important;
    border: none !important;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      background-color: rgba(31, 72, 231, 0.16) !important;
    }
  `,
  addNewClientButton: css`
    font-family: ${theme.fonts.sofiaPro};
    border: 0;
    padding: 0;
    background-color: ${theme.colors.white};
    cursor: pointer;
  `,
  mobileDelete: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  `,
};
