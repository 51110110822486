import axios, { ResponseType } from "axios";
import { getProjectsReportDownloadUrl } from "../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../headers";

const getProjectsReport = async (
  accessToken: string,
  projectId: string,
  orgId: string,
  reportType: string
): Promise<Blob> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    responseType: "blob" as ResponseType,
    ...getAxiosOptions(),
  };

  return axios
    .get(getProjectsReportDownloadUrl(projectId, orgId, reportType), options)
    .then((response) => response.data as Blob);
};
export default getProjectsReport;
