import { css, SerializedStyles } from "@emotion/react";
import theme from "src/theme";

export default {
  passwordCriteria(hasError: boolean): SerializedStyles {
    return css`
      margin-top: ${hasError ? "28px" : "52px"};
      padding: 1rem;
      height: fit-content;
      font-size: 0.75rem;
      border-radius: 8px;
      background-color: ${theme.colors.secondary.bigSkyBlue};
      line-height: 0.9375rem;
      font-family: ${theme.fonts.sofiaPro};

      ul {
        list-style: none;
        padding-left: 0;
        padding-top: 0.5rem;
        li {
          font-size: 0.875rem;
          padding-bottom: 0.5rem;
        }
      }
    `;
  },
  check: css`
    color: ${theme.colors.functional.green};
  `,
  info: css`
    color: ${theme.colors.primary.blue};
  `,
  validPassword: css`
    border-radius: 8px;
    padding: 1.5rem;
    margin-top: 52px;
    background-color: ${theme.colors.secondary.successGreen};
    font-size: 0.875rem;
    font-family: ${theme.fonts.sofiaPro};
  `,
  subText: css`
    color: ${theme.colors.black};
    font-size: 14px !important;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 48px;
    text-align: right;
    cursor: pointer;
    width: 110px;
    float: right;
  `,
};
