import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  textButton: css`
    color: ${theme.colors.secondary.lightBlueGreen};
    font-weight: 700;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    background-color: ${theme.colors.background.white} !important;
    border-radius: none;
    padding: 0 !important;
    box-shadow: none;
    border: none !important;
    :hover {
      box-shadow: none;
      color: ${theme.colors.primary.blue} !important;
      border: none !important;
      background-color: ${theme.colors.background.white};
    }
    :not(:hover) {
      box-shadow: none;
      border: none !important;
      color: ${theme.colors.secondary.lightBlueGreen};
      background-color: ${theme.colors.background.white};
    }
  `,
  dragAndDropButtonAdditionalText: css`
    font-size: 16px;
  `,
  sizeLimitText: css`
    font-weight: 400;
    font-size: 16px;
  `,
  fileUpload: css`
    position: relative;
    color: ${theme.colors.black};
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;
    .ant-upload {
      color: ${theme.colors.secondary.blueOnBlack};
    }

    .ant-upload-drag-container {
      cursor: pointer;
      outline: 0 !important;
    }

    .ant-upload-drag-icon {
      margin-bottom: -0.5rem;
      padding-top: 2.875rem;
    }

    .ant-upload-drag-icon-no-drag {
      padding-top: 3.375rem;
      path {
        fill: ${theme.colors.primary.blue};
      }
    }

    .ant-upload-text {
      margin-bottom: 0.5rem;
    }

    .ant-upload-btn:focus {
      outline: 0 !important;
    }
  `,
  browseBtn: css`
    color: ${theme.colors.primary.blue};
    background-color: transparent !important;
    border-radius: 0.25rem;
    border-color: ${theme.colors.primary.blue};
    border: 2px solid ${theme.colors.primary.blue} !important;
    height: 2.5rem !important;

    :hover {
      height: 2.5rem !important;
      color: ${theme.colors.black} !important;
      border: 2px solid ${theme.colors.black} !important;
    }
  `,
  container: css`
    border-radius: 0.125rem;
    min-height: 11.25rem;

    .ant-upload-drag-container {
      min-height: 12.5rem;
    }
    outline: 0 !important;
  `,
  containerNoFiles: css`
    :hover {
      border-color: #40a9ff;
    }
    left: 22.45%;
    right: 40.39%;
    top: 82.96%;
    bottom: 8.34%;
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 0.25rem;
  `,
  containerWithFiles: css`
    background-color: ${theme.colors.white};
    border: 0.0625rem solid #d9d9d9;
    padding-top: 0.625rem;
    padding-left: 0.625rem;

    @media ${queries.medium} {
      padding-left: 1.5625rem;
      padding-right: 0.9375rem;
    }

    .ant-upload-drag-container {
      min-height: 9.375rem;
    }

    :hover {
      border-color: ${theme.colors.primary.blue};
    }
  `,
};
