import React, { ReactElement, useCallback } from "react";
import { IFormContacts } from "src/pages/projects/project/interfaces";
import { Operation } from "src/constants";
import { cloneDeep } from "lodash";
import * as uuid from "uuid";
import styles from "./styles";
import ContactEmailRow from "./contact-email-row";

interface IProps {
  propertyName: string;
  items: IFormContacts[];
  isContactNameRequired?: boolean;
  isEmailAddressRequired?: boolean;
  updateContacts: (contacts: IFormContacts[]) => void;
}

const ContactEmailRows = ({
  items,
  propertyName,
  isContactNameRequired = false,
  isEmailAddressRequired = false,
  updateContacts,
}: IProps): ReactElement => {
  const performOperation = useCallback(
    (
      operation: Operation,
      data: { index: number; value?: IFormContacts }
    ): void => {
      const contacts = cloneDeep(items);

      switch (operation) {
        case Operation.add:
          contacts.push({
            email: "",
            name: "",
            contactType: "",
            key: uuid.v4(),
            companyName: "",
          });
          updateContacts(contacts);
          break;
        case Operation.remove:
          contacts.splice(data.index, 1);
          updateContacts(contacts);
          break;
        case Operation.update:
          contacts[data.index] = {
            email: data.value?.email,
            name: data.value?.name,
            contactType: data.value?.contactType,
            key: data.value?.key ?? uuid.v4(),
            companyName: data.value?.companyName,
          };
          updateContacts(contacts);
          break;
        default:
          throw new Error("Invalid argument enum for Operation");
      }
    },
    [items, updateContacts]
  );

  const deleteRowItem = useCallback(
    (index: number) => {
      performOperation(Operation.remove, { index });
    },
    [performOperation]
  );

  const updateRowItem = useCallback(
    (index: number, additionalContactState: IFormContacts) => {
      performOperation(Operation.update, {
        index,
        value: additionalContactState,
      });
    },
    [performOperation]
  );

  return (
    <div css={styles.contactRowContainer}>
      {items.map((additionalContact, additionalContactIndex) => {
        return (
          <ContactEmailRow
            key={`contact-${additionalContact.key ?? ""}`}
            propertyName={`${propertyName}${additionalContactIndex}`}
            data={additionalContact}
            index={additionalContactIndex}
            deleteRow={deleteRowItem}
            updateRow={updateRowItem}
            isContactNameRequired={isContactNameRequired}
            isEmailAddressRequired={isEmailAddressRequired}
          />
        );
      })}
    </div>
  );
};

export default ContactEmailRows;
