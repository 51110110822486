import axios from "axios";
import config from "src/config";
import { getAuthHeader, getJsonContentHeader } from "src/api/headers";
import { IGetTalentHubContactAttachmentResponse } from "../interfaces";

interface IProps {
  attachmentId: string;
  contactId: string;
  organisationId: string;
  accessToken: string;
}

async function verifyFileUpload({
  attachmentId,
  contactId,
  organisationId,
  accessToken,
}: IProps): Promise<void> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  return axios.get(
    `${config.projectManagementService.url}/talent-hub/talent-contact/${contactId}/attachments/${attachmentId}/verify?orgId=${organisationId}`,
    options
  );
}

export default verifyFileUpload;
