import Button from "src/components/button";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import FilterIcon from "src/app/assets/icons/component-icons/buttons/filter";
import {
  IActiveLabels,
  IFiltersResponse,
  IFiltersSearch,
} from "src/pages/projects/project-listings/interfaces";
import { useTranslation } from "react-i18next";
import {
  getRangeLabel,
  mapBooleanValuesIntoLabels,
  mapCreativeAgencyLabelValues,
  mapEnumLabelValues,
  mapLabelContactValues,
  mapLabelValues,
  mapLeadsLabelValues,
  mapProjectsLabelValues,
} from "src/pages/projects/project-listings/utils";
import { ProjectStatus } from "src/pages/projects/project/interfaces";
import { ISimpleUser } from "src/interfaces/auth";
import { IFilterData, ISavedUserFilter } from "src/api/user-filters/interfaces";
import { saveUserFilters } from "src/api/user-filters";
import useAuth from "src/auth/use-auth";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import styles from "./styles";
import BaseModal from "../modals/base-modal";
import Content from "../modals/clear-filter";
import {
  ISelectLabel,
  ISelectProjectsLeads,
} from "../modals/clear-filter/utils";
import SaveFilterContent from "../modals/save-filter";
import { ErrorToast } from "../toast-notification";
import DeleteFiltersContent from "../modals/delete-saved-filters";

interface IClearFilter {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  activeFilters: number;
  appliedFilters: IFiltersSearch;
  availableFilters: IFiltersResponse;
  handleApplyLabels: (labels: IActiveLabels) => void;
  handleApplyFilters: (
    clients: string[],
    globalBrands: string[],
    statuses: string[],
    leads: string[],
    contacts: string[],
    creativeAgencies: string[],
    holdingCompanies: string[],
    musicTypes: string[],
    projectTypes: string[],
    serviceTypes: string[],
    projectCloseDateFrom: string | null,
    projectCloseDateTo: string | null,
    jobListingDateFrom: string | null,
    jobListingDateTo: string | null,
    projectRegions: string[],
    officeLocations: string[],
    copyrightInfoProcessed: null | boolean,
    publishingRightsSigned: null | boolean,
    publishingTriggered: null | boolean
  ) => void;
  clearFilters: () => void;
  handleSetMyPresetFilters: (
    myOpenProjects: boolean,
    myAtContractProjects: boolean,
    myClosedProjects: boolean
  ) => void;
  currentUserId: string;
  allUsers: ISimpleUser[];
  savedFilters: ISavedUserFilter[] | null;
  updateSavedFilters: () => Promise<void>;
}

const ClearFilter = (props: IClearFilter): ReactElement => {
  const {
    setShowModal,
    showModal,
    activeFilters,
    appliedFilters,
    handleApplyFilters,
    availableFilters,
    clearFilters,
    handleApplyLabels,
    handleSetMyPresetFilters,
    currentUserId,
    allUsers,
    savedFilters,
    updateSavedFilters,
  } = props;
  const { allProjectStatuses, allProjectTypes } = useGlobalStates();
  const [selectedClients, setSelectedClients] = useState<ISelectLabel[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<ISelectLabel[]>([]);
  const [selectedLeads, setSelectedLeads] = useState<ISelectProjectsLeads[]>(
    []
  );
  const [selectedGlobalBrands, setSelectedGlobalBrands] = useState<
    ISelectLabel[]
  >([]);
  const [selectedCreativeAgencies, setSelectedCreativeAgencies] = useState<
    ISelectLabel[]
  >([]);
  const [selectedJobListingDate, setSelectedJobListingDate] = useState<
    (string | null)[]
  >([null, null]);
  const [selectedProjectCloseDate, setSelectedProjectCloseDate] = useState<
    (string | null)[]
  >([null, null]);
  const [selectedHoldingCompanies, setSelectedHoldingCompanies] = useState<
    ISelectLabel[]
  >([]);
  const [selectedProjectTypes, setSelectedProjectTypes] = useState<
    ISelectLabel[]
  >([]);
  const [selectedContacts, setSelectedContacts] = useState<ISelectLabel[]>([]);
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<
    ISelectLabel[]
  >([]);
  const [selectedMusicTypes, setSelectedMusicTypes] = useState<ISelectLabel[]>(
    []
  );
  const [selectedProjectRegions, setSelectedProjectRegions] = useState<
    ISelectLabel[]
  >([]);
  const [selectedOfficeLocation, setSelectedOfficeLocation] = useState<
    ISelectLabel[]
  >([]);
  const [
    selectedCopyrightInfoProcessed,
    setSelectedCopyrightInfoProcessed,
  ] = useState<ISelectLabel>({
    label: "None",
    value: "",
  });
  const [
    selectedPublishingRightsSigned,
    setSelectedPublishingRightsSigned,
  ] = useState<ISelectLabel>({
    label: "None",
    value: "",
  });
  const [
    selectedPublishingTriggered,
    setSelectedPublishingTriggered,
  ] = useState<ISelectLabel>({
    label: "None",
    value: "",
  });
  const [filterPayload, setFilterPayload] = useState<IFilterData | null>(null);
  const [inlineSaveFilterError, setInlineSaveFilterError] = useState("");
  const [showSavedFiltersDialog, setShowFiltersDialog] = useState(false);
  const { organisationId, fetchWrapper } = useAuth();
  const { t } = useTranslation();

  const selectedClientsLabels = useMemo(
    () => mapLabelValues(availableFilters.clients, appliedFilters.clients),
    [appliedFilters.clients, availableFilters.clients]
  );

  const selectedGlobalBrandsLabels = useMemo(
    () =>
      mapLabelValues(
        availableFilters.globalBrands,
        appliedFilters.globalBrands
      ),
    [appliedFilters.globalBrands, availableFilters.globalBrands]
  );

  const selectedCreativeAgencyLabels = useMemo(
    () =>
      mapCreativeAgencyLabelValues(
        availableFilters.creativeAgencies,
        appliedFilters.creativeAgencies
      ),
    [appliedFilters.creativeAgencies, availableFilters.creativeAgencies]
  );

  const selectedHoldingCompanyLabels = useMemo(
    () =>
      mapLabelValues(
        availableFilters.holdingCompanies,
        appliedFilters.holdingCompanies
      ),
    [appliedFilters.holdingCompanies, availableFilters.holdingCompanies]
  );
  const selectedStatusLabels = useMemo(
    () => mapProjectsLabelValues(appliedFilters.statuses, allProjectStatuses),
    [allProjectStatuses, appliedFilters.statuses]
  );

  const selectedLeadsLabels = useMemo(
    () =>
      mapLeadsLabelValues(
        availableFilters.leads,
        appliedFilters.leads,
        allUsers
      ),
    [allUsers, appliedFilters.leads, availableFilters.leads]
  );

  useEffect(() => {
    setSelectedClients(selectedClientsLabels);
  }, [appliedFilters.clients, availableFilters.clients, selectedClientsLabels]);

  useEffect(() => {
    setSelectedGlobalBrands(selectedGlobalBrandsLabels);
  }, [
    appliedFilters.globalBrands,
    availableFilters.globalBrands,
    selectedGlobalBrandsLabels,
  ]);

  useEffect(() => {
    setSelectedCreativeAgencies(selectedCreativeAgencyLabels);
  }, [
    appliedFilters.creativeAgencies,
    availableFilters.creativeAgencies,
    selectedCreativeAgencyLabels,
  ]);

  useEffect(() => {
    setSelectedHoldingCompanies(selectedHoldingCompanyLabels);
  }, [
    appliedFilters.holdingCompanies,
    availableFilters.holdingCompanies,
    selectedHoldingCompanyLabels,
  ]);

  useEffect(() => {
    setSelectedStatus(selectedStatusLabels);
  }, [allProjectStatuses, appliedFilters.statuses, selectedStatusLabels]);

  useEffect(() => {
    setSelectedLeads(selectedLeadsLabels);
  }, [
    appliedFilters.leads,
    availableFilters.leads,
    allUsers,
    selectedLeadsLabels,
  ]);

  useEffect(() => {
    setSelectedProjectCloseDate([
      appliedFilters.projectCloseDateFrom,
      appliedFilters.projectCloseDateTo,
    ]);
  }, [appliedFilters.projectCloseDateFrom, appliedFilters.projectCloseDateTo]);

  useEffect(() => {
    setSelectedJobListingDate([
      appliedFilters.jobListingDateFrom,
      appliedFilters.jobListingDateTo,
    ]);
  }, [appliedFilters.jobListingDateFrom, appliedFilters.jobListingDateTo]);

  const selectedProjectTypeLabels = useMemo(
    () => mapProjectsLabelValues(appliedFilters.projectTypes, allProjectTypes),
    [allProjectTypes, appliedFilters.projectTypes]
  );
  useEffect(() => {
    setSelectedProjectTypes(selectedProjectTypeLabels);
  }, [allProjectTypes, appliedFilters.projectTypes, selectedProjectTypeLabels]);

  const selectedContactsLabels = useMemo(
    () =>
      mapLabelContactValues(availableFilters.contacts, appliedFilters.contacts),
    [appliedFilters.contacts, availableFilters.contacts]
  );

  useEffect(() => {
    setSelectedContacts(selectedContactsLabels);
  }, [
    appliedFilters.contacts,
    availableFilters.contacts,
    selectedContactsLabels,
  ]);

  const selectedServiceTypesLabels = useMemo(
    () => mapEnumLabelValues(appliedFilters.serviceTypes),
    [appliedFilters.serviceTypes]
  );

  useEffect(() => {
    setSelectedServiceTypes(selectedServiceTypesLabels);
  }, [appliedFilters.serviceTypes, selectedServiceTypesLabels]);

  const selectedMusicTypesLabels = useMemo(
    () => mapEnumLabelValues(appliedFilters.musicTypes),
    [appliedFilters.musicTypes]
  );

  useEffect(() => {
    setSelectedMusicTypes(selectedMusicTypesLabels);
  }, [appliedFilters.musicTypes, selectedMusicTypesLabels]);

  const selectedProjectRegionLabels = useMemo(
    () => mapEnumLabelValues(appliedFilters.projectRegions),
    [appliedFilters.projectRegions]
  );

  useEffect(() => {
    setSelectedProjectRegions(selectedProjectRegionLabels);
  }, [appliedFilters.projectRegions, selectedProjectRegionLabels]);

  const selectedOfficeLocationLabels = useMemo(
    () => mapEnumLabelValues(appliedFilters.officeLocations),
    [appliedFilters.officeLocations]
  );

  useEffect(() => {
    setSelectedOfficeLocation(selectedOfficeLocationLabels);
  }, [appliedFilters.officeLocations, selectedOfficeLocationLabels]);

  const selectedCopyrightInfoProcessedLabel = useMemo(
    () => mapBooleanValuesIntoLabels(appliedFilters.copyrightInfoProcessed),
    [appliedFilters.copyrightInfoProcessed]
  );

  useEffect(() => {
    setSelectedCopyrightInfoProcessed(selectedCopyrightInfoProcessedLabel);
  }, [
    appliedFilters.copyrightInfoProcessed,
    selectedCopyrightInfoProcessedLabel,
  ]);

  const selectedPublishingRightsSignedLabel = useMemo(
    () => mapBooleanValuesIntoLabels(appliedFilters.publishingRightsSigned),
    [appliedFilters.publishingRightsSigned]
  );

  useEffect(() => {
    setSelectedPublishingRightsSigned(selectedPublishingRightsSignedLabel);
  }, [
    appliedFilters.publishingRightsSigned,
    selectedPublishingRightsSignedLabel,
  ]);

  const selectedPublishingTriggeredLabel = useMemo(
    () => mapBooleanValuesIntoLabels(appliedFilters.publishingTriggered),
    [appliedFilters.publishingTriggered]
  );

  useEffect(() => {
    setSelectedPublishingTriggered(selectedPublishingTriggeredLabel);
  }, [appliedFilters.publishingTriggered, selectedPublishingTriggeredLabel]);

  const handleUpdateClient = useCallback((clients: ISelectLabel[]) => {
    setSelectedClients(clients);
  }, []);

  const handleSetSelectedCreativeAgencies = useCallback(
    (agencies: ISelectLabel[]) => {
      setSelectedCreativeAgencies(agencies);
    },
    []
  );

  const handleSetSelectedJobListingDate = useCallback(
    (value: (string | null)[]) => {
      setSelectedJobListingDate(value);
    },
    []
  );

  const handleSetSelectedProjectCloseDate = useCallback(
    (value: (string | null)[]) => {
      setSelectedProjectCloseDate(value);
    },
    []
  );

  const handleSetSelectedHoldingCompanies = useCallback(
    (companies: ISelectLabel[]) => {
      setSelectedHoldingCompanies(companies);
    },
    []
  );

  const handleSetSelectedProjectTypes = useCallback((types: ISelectLabel[]) => {
    setSelectedProjectTypes(types);
  }, []);

  const handleSetSelectedContacts = useCallback((value: ISelectLabel[]) => {
    setSelectedContacts(value);
  }, []);

  const handleSetSelectedServiceTypes = useCallback((types: ISelectLabel[]) => {
    setSelectedServiceTypes(types);
  }, []);

  const handleSetSelectedMusicTypes = useCallback((types: ISelectLabel[]) => {
    setSelectedMusicTypes(types);
  }, []);

  const handleSetSelectedProjectRegions = useCallback(
    (regions: ISelectLabel[]) => {
      setSelectedProjectRegions(regions);
    },
    []
  );

  const handleSetSelectedOfficeLocation = useCallback(
    (officeLocations: ISelectLabel[]) => {
      setSelectedOfficeLocation(officeLocations);
    },
    []
  );

  const handleSetCopyrightInfoProcessed = useCallback(
    (selected: ISelectLabel) => {
      setSelectedCopyrightInfoProcessed(selected);
    },
    []
  );

  const handleSetPublishingRightsSigned = useCallback(
    (selected: ISelectLabel) => {
      setSelectedPublishingRightsSigned(selected);
    },
    []
  );

  const handleSetPublishingTriggered = useCallback((selected: ISelectLabel) => {
    setSelectedPublishingTriggered(selected);
  }, []);

  const handleSetFilterData = useCallback(
    (payload: IFilterData) => {
      const areAllFiltersEmpty = Object.values(payload).every(
        (filterData) => filterData === null
      );
      if (areAllFiltersEmpty) {
        setInlineSaveFilterError(
          "*Select at least one of the filters before saving"
        );
      } else {
        setInlineSaveFilterError("");
        setShowModal(false);
        setFilterPayload(payload);
      }
    },
    [setShowModal]
  );

  useEffect(() => {
    if (inlineSaveFilterError) {
      const hasSelectedClients = selectedClients.length > 0;
      const hasSelectedGlobalBrands = selectedGlobalBrands.length > 0;
      const hasSelectedStatuses = selectedStatus.length > 0;
      const hasSelectedLeads = selectedLeads.length > 0;
      const hasSelectedContacts = selectedContacts.length > 0;
      const hasSelectedCreativeAgencies = selectedCreativeAgencies.length > 0;
      const hasSelectedHoldingCompanies = selectedHoldingCompanies.length > 0;
      const hasSelectedMusicTypes = selectedMusicTypes.length > 0;
      const hasSelectedProjectTypes = selectedProjectTypes.length > 0;
      const hasSelectedServiceTypes = selectedServiceTypes.length > 0;
      const hasSelectedProjectRegions = selectedProjectRegions.length > 0;
      const hasSelectedOfficeLocation = selectedOfficeLocation.length > 0;
      const hasSelectedCopyrightInfoProcessed =
        selectedCopyrightInfoProcessed.value !== "";
      const hasSelectedPublishingRightsSigned =
        selectedPublishingRightsSigned.value !== "";
      const hasSelectedPublishingTriggered =
        selectedPublishingTriggered.value !== "";

      const isSomeOptionSelected =
        hasSelectedClients ||
        hasSelectedGlobalBrands ||
        hasSelectedStatuses ||
        hasSelectedLeads ||
        hasSelectedContacts ||
        hasSelectedCreativeAgencies ||
        hasSelectedHoldingCompanies ||
        hasSelectedMusicTypes ||
        hasSelectedProjectTypes ||
        hasSelectedServiceTypes ||
        hasSelectedProjectRegions ||
        hasSelectedOfficeLocation ||
        hasSelectedCopyrightInfoProcessed ||
        hasSelectedPublishingRightsSigned ||
        hasSelectedPublishingTriggered;

      if (isSomeOptionSelected) {
        setInlineSaveFilterError("");
      }
    }
  }, [
    inlineSaveFilterError,
    selectedClients.length,
    selectedContacts.length,
    selectedCreativeAgencies.length,
    selectedGlobalBrands.length,
    selectedHoldingCompanies.length,
    selectedLeads.length,
    selectedMusicTypes.length,
    selectedProjectTypes.length,
    selectedServiceTypes.length,
    selectedStatus.length,
    selectedProjectRegions.length,
    selectedOfficeLocation.length,
    selectedCopyrightInfoProcessed,
    selectedPublishingRightsSigned,
    selectedPublishingTriggered,
  ]);

  const getSelectedOptionValue = useCallback((selectedValue: string) => {
    let value = null;
    if (selectedValue === "yes") {
      value = true;
    } else if (selectedValue === "no") {
      value = false;
    }

    return value;
  }, []);

  const applyFiltersSubmit = useCallback(() => {
    const clientIds = selectedClients.map(({ value }) => value);
    const globalBrands = selectedGlobalBrands.map(({ label }) => label);
    const statuses = selectedStatus.map(({ value }) => value);
    const leads = selectedLeads.map((lead) => lead.value);
    const contacts = selectedContacts.map(({ value }) => value);
    const creativeAgencies = selectedCreativeAgencies.map(({ value }) => value);
    const holdingCompanies = selectedHoldingCompanies.map(({ value }) => value);
    const musicTypes = selectedMusicTypes.map(({ value }) => value);
    const projectTypes = selectedProjectTypes.map(({ value }) => value);
    const serviceTypes = selectedServiceTypes.map(({ value }) => value);
    const projectRegions = selectedProjectRegions.map(({ value }) => value);
    const officeLocations = selectedOfficeLocation.map(({ value }) => value);
    const copyrightInfoProcessed = getSelectedOptionValue(
      selectedCopyrightInfoProcessed.value
    );
    const publishingRightsSigned = getSelectedOptionValue(
      selectedPublishingRightsSigned.value
    );
    const publishingTriggered = getSelectedOptionValue(
      selectedPublishingTriggered.value
    );

    const getSingleSelectionAppliedLabel = (selected: ISelectLabel) => {
      return selected.value !== "" ? [selected] : [];
    };

    handleApplyFilters(
      clientIds,
      globalBrands,
      statuses,
      leads,
      contacts,
      creativeAgencies,
      holdingCompanies,
      musicTypes,
      projectTypes,
      serviceTypes,
      selectedProjectCloseDate[0],
      selectedProjectCloseDate[1],
      selectedJobListingDate[0],
      selectedJobListingDate[1],
      projectRegions,
      officeLocations,
      copyrightInfoProcessed,
      publishingRightsSigned,
      publishingTriggered
    );
    const projectCloseDate = getRangeLabel(
      selectedProjectCloseDate[0],
      selectedProjectCloseDate[1]
    );
    const jobListingDate = getRangeLabel(
      selectedJobListingDate[0],
      selectedJobListingDate[1]
    );
    handleApplyLabels({
      clients: selectedClients,
      globalBrands: selectedGlobalBrands,
      statuses: selectedStatus,
      leads: selectedLeads,
      contacts: selectedContacts,
      creativeAgencies: selectedCreativeAgencies,
      holdingCompanies: selectedHoldingCompanies,
      musicTypes: selectedMusicTypes,
      projectTypes: selectedProjectTypes,
      serviceTypes: selectedServiceTypes,
      projectCloseDate,
      jobListingDate,
      projectRegions: selectedProjectRegions,
      officeLocations: selectedOfficeLocation,
      copyrightInfoProcessed: getSingleSelectionAppliedLabel(
        selectedCopyrightInfoProcessed
      ),
      publishingRightsSigned: getSingleSelectionAppliedLabel(
        selectedPublishingRightsSigned
      ),
      publishingTriggered: getSingleSelectionAppliedLabel(
        selectedPublishingTriggered
      ),
    });
    const isMyOpenProjectsStatus = Boolean(
      statuses.find((status) => status === ProjectStatus.NewOpen)
    );
    const isMyAtContractProjectsStatus = Boolean(
      statuses.find((status) => status === ProjectStatus.InContractInvoiced)
    );
    const isMyClosedProjectsStatus = Boolean(
      statuses.find((status) => status === ProjectStatus.Complete)
    );
    const isCurrentUserLead = leads.find((lead) => lead === currentUserId);
    const isPresetStatus =
      isMyOpenProjectsStatus ||
      isMyAtContractProjectsStatus ||
      isMyClosedProjectsStatus;
    if (isCurrentUserLead && isPresetStatus) {
      handleSetMyPresetFilters(
        isMyOpenProjectsStatus,
        isMyAtContractProjectsStatus,
        isMyClosedProjectsStatus
      );
    } else {
      handleSetMyPresetFilters(false, false, false);
    }

    setShowModal(false);
  }, [
    selectedClients,
    selectedGlobalBrands,
    selectedStatus,
    selectedLeads,
    selectedContacts,
    selectedCreativeAgencies,
    selectedHoldingCompanies,
    selectedMusicTypes,
    selectedProjectTypes,
    selectedServiceTypes,
    selectedProjectRegions,
    selectedOfficeLocation,
    getSelectedOptionValue,
    selectedCopyrightInfoProcessed,
    selectedPublishingRightsSigned,
    selectedPublishingTriggered,
    handleApplyFilters,
    selectedProjectCloseDate,
    selectedJobListingDate,
    handleApplyLabels,
    setShowModal,
    currentUserId,
    handleSetMyPresetFilters,
  ]);

  const handleSaveFilter = useCallback(
    async (filterName: string) => {
      const saveFilterPayload = {
        filterName,
        configuration: filterPayload as IFilterData,
      };
      try {
        await fetchWrapper(saveUserFilters, organisationId, saveFilterPayload);
        await updateSavedFilters();
        setFilterPayload(null);
        applyFiltersSubmit();
      } catch (error) {
        ErrorToast(
          "error-saving-filter",
          t("SaveFilterModal##SaveError##message"),
          t("SaveFilterModal##SaveError##description")
        );
      }
    },
    [
      filterPayload,
      fetchWrapper,
      organisationId,
      updateSavedFilters,
      applyFiltersSubmit,
      t,
    ]
  );

  const handleCancelFilter = useCallback(() => {
    setFilterPayload(null);
    setShowModal(true);
  }, [setShowModal]);

  const handleClearFilters = useCallback(() => {
    clearFilters();
    setSelectedClients([]);
    setSelectedStatus([]);
    setSelectedLeads([]);
    setSelectedGlobalBrands([]);
    setSelectedContacts([]);
    setSelectedCreativeAgencies([]);
    setSelectedHoldingCompanies([]);
    setSelectedMusicTypes([]);
    setSelectedProjectTypes([]);
    setSelectedServiceTypes([]);
    setSelectedProjectCloseDate([null, null]);
    setSelectedJobListingDate([null, null]);
    setSelectedProjectRegions([]);
    setSelectedOfficeLocation([]);
    setSelectedCopyrightInfoProcessed({ value: "", label: "None" });
    setSelectedPublishingRightsSigned({ value: "", label: "None" });
    setSelectedPublishingTriggered({ value: "", label: "None" });
  }, [clearFilters]);

  const resetModalFilterValuesToDefaultValues = useCallback(() => {
    setSelectedClients(selectedClientsLabels);
    setSelectedJobListingDate([
      appliedFilters.jobListingDateFrom,
      appliedFilters.jobListingDateTo,
    ]);
    setSelectedProjectCloseDate([
      appliedFilters.projectCloseDateFrom,
      appliedFilters.projectCloseDateTo,
    ]);
    setSelectedLeads(selectedLeadsLabels);
    setSelectedStatus(selectedStatusLabels);
    setSelectedHoldingCompanies(selectedHoldingCompanyLabels);
    setSelectedCreativeAgencies(selectedCreativeAgencyLabels);
    setSelectedGlobalBrands(selectedGlobalBrandsLabels);
    setSelectedProjectRegions(selectedProjectRegionLabels);
    setSelectedMusicTypes(selectedMusicTypesLabels);
    setSelectedServiceTypes(selectedServiceTypesLabels);
    setSelectedContacts(selectedContactsLabels);
    setSelectedProjectTypes(selectedProjectTypeLabels);
    setSelectedOfficeLocation(selectedOfficeLocationLabels);
    setSelectedCopyrightInfoProcessed(selectedCopyrightInfoProcessed);
    setSelectedPublishingRightsSigned(selectedPublishingRightsSignedLabel);
    setSelectedPublishingTriggered(selectedPublishingTriggeredLabel);
  }, [
    appliedFilters.jobListingDateFrom,
    appliedFilters.jobListingDateTo,
    appliedFilters.projectCloseDateFrom,
    appliedFilters.projectCloseDateTo,
    selectedClientsLabels,
    selectedContactsLabels,
    selectedCopyrightInfoProcessed,
    selectedCreativeAgencyLabels,
    selectedGlobalBrandsLabels,
    selectedHoldingCompanyLabels,
    selectedLeadsLabels,
    selectedOfficeLocationLabels,
    selectedMusicTypesLabels,
    selectedProjectRegionLabels,
    selectedProjectTypeLabels,
    selectedPublishingRightsSignedLabel,
    selectedPublishingTriggeredLabel,
    selectedServiceTypesLabels,
    selectedStatusLabels,
  ]);

  const handleCloseFiltersModal = useCallback(() => {
    resetModalFilterValuesToDefaultValues();
    setShowModal(false);
  }, [resetModalFilterValuesToDefaultValues, setShowModal]);

  const handleCloseSavedFilters = useCallback(() => {
    setShowFiltersDialog(false);
    setShowModal(true);
  }, [setShowModal]);

  const handleShowSavedFilters = useCallback(() => {
    setShowFiltersDialog(true);
    setShowModal(false);
  }, [setShowModal]);

  const handleDeleteSavedFilterByName = useCallback(
    async (filterName: string) => {
      if (filterName) {
        const deleteFilterPayload = {
          filterName,
          configuration: null,
        };
        try {
          await fetchWrapper(
            saveUserFilters,
            organisationId,
            deleteFilterPayload
          );
          await updateSavedFilters();
          setFilterPayload(null);
          applyFiltersSubmit();
        } catch (error) {
          ErrorToast(
            "error-saving-filter",
            t("DeleteFilterModal##DeleteError##message"),
            t("DeleteFilterModal##DeleteError##description")
          );
        }
      }
    },
    [applyFiltersSubmit, fetchWrapper, organisationId, t, updateSavedFilters]
  );

  return (
    <div css={styles.row}>
      <span
        aria-hidden="true"
        css={styles.clearText}
        onClick={handleClearFilters}
        aria-label={t("FilterModal##Clear all filters")}
      />
      <Button
        css={styles.button}
        type="secondary"
        ariaLabel={t("FilterModal##Filters")}
        onClick={() => setShowModal(true)}
        noLabelTranslation
      >
        <div css={styles.buttonRow}>
          <FilterIcon width={18} height={18} />
          <span css={styles.text}>{t("FilterModal##Filters")}</span>
          <span css={styles.text} data-testid="active-filter-count">
            ({activeFilters})
          </span>
        </div>
      </Button>
      <BaseModal
        open={showModal}
        width={900}
        content={
          <Content
            selectedClients={selectedClients}
            setSelectedClients={handleUpdateClient}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedLeads={selectedLeads}
            setSelectedLeads={setSelectedLeads}
            selectedGlobalBrands={selectedGlobalBrands}
            setSelectedGlobalBrands={setSelectedGlobalBrands}
            clients={availableFilters.clients}
            statuses={availableFilters.statuses}
            leads={availableFilters.leads}
            globalBrands={availableFilters.globalBrands}
            contacts={availableFilters.contacts}
            creativeAgencies={availableFilters.creativeAgencies}
            holdingCompanies={availableFilters.holdingCompanies}
            musicTypes={availableFilters.musicTypes}
            projectTypes={availableFilters.projectTypes}
            serviceTypes={availableFilters.serviceTypes}
            projectRegions={availableFilters.projectRegions}
            officeLocations={availableFilters.officeLocations}
            applyFiltersSubmit={applyFiltersSubmit}
            selectedCreativeAgencies={selectedCreativeAgencies}
            setSelectedCreativeAgencies={handleSetSelectedCreativeAgencies}
            selectedJobListingDate={selectedJobListingDate}
            setSelectedJobListingDate={handleSetSelectedJobListingDate}
            selectedProjectCloseDate={selectedProjectCloseDate}
            setSelectedProjectCloseDate={handleSetSelectedProjectCloseDate}
            selectedHoldingCompanies={selectedHoldingCompanies}
            setSelectedHoldingCompanies={handleSetSelectedHoldingCompanies}
            selectedProjectTypes={selectedProjectTypes}
            setSelectedProjectTypes={handleSetSelectedProjectTypes}
            selectedContacts={selectedContacts}
            setSelectedContacts={handleSetSelectedContacts}
            selectedServiceTypes={selectedServiceTypes}
            setSelectedServiceTypes={handleSetSelectedServiceTypes}
            selectedMusicTypes={selectedMusicTypes}
            setSelectedMusicTypes={handleSetSelectedMusicTypes}
            selectedProjectRegions={selectedProjectRegions}
            setSelectedProjectRegions={handleSetSelectedProjectRegions}
            selectedOfficeLocation={selectedOfficeLocation}
            setSelectedOfficeLocation={handleSetSelectedOfficeLocation}
            selectedCopyrightInfoProcessed={selectedCopyrightInfoProcessed}
            setSelectedCopyRightInfoProcessed={handleSetCopyrightInfoProcessed}
            selectedPublishingRightsSigned={selectedPublishingRightsSigned}
            setSelectedPublishingRightsSigned={handleSetPublishingRightsSigned}
            selectedPublishingTriggered={selectedPublishingTriggered}
            setSelectedPublishingTriggered={handleSetPublishingTriggered}
            setFilterPayload={handleSetFilterData}
            inlineSaveFilterError={inlineSaveFilterError}
            onShowSetFiltersDialog={handleShowSavedFilters}
          />
        }
        buttons={[]}
        onClose={handleCloseFiltersModal}
      />
      <BaseModal
        open={Boolean(filterPayload)}
        width={520}
        content={
          <SaveFilterContent
            onSaveFilter={handleSaveFilter}
            onCancelFilter={handleCancelFilter}
          />
        }
        buttons={[]}
        onClose={handleCancelFilter}
      />
      <BaseModal
        open={showSavedFiltersDialog}
        width={520}
        content={
          <DeleteFiltersContent
            onClose={handleCloseSavedFilters}
            savedFilters={savedFilters}
            onDeleteFilter={handleDeleteSavedFilterByName}
          />
        }
        buttons={[]}
        onClose={handleCloseSavedFilters}
      />
    </div>
  );
};

export default ClearFilter;
