import { Collapse, Typography } from "antd";
import {
  useState,
  Fragment,
  ReactElement,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getProject } from "src/api/projects";
import createService from "src/api/services/create-service";
import deleteService from "src/api/services/delete-service";
import updateService from "src/api/services/update-service";
import useAuth from "src/auth/use-auth";
import ButtonWithLabelAndPlusIcon from "src/components/plus-button-with-label";
import useProject from "src/providers/project/hooks";
import parseCurrencyCode from "src/utils/currency";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import { IProjectRouteParams, IService } from "../../interfaces";
import ServicesForm from "./components/services-form";
import styles from "./styles";
import { ServiceFormType } from "./types";
import mainStyles from "../../styles";
import ServicesFormHeader from "./components/services-form-header";
import ServiceCollapsibleHeader from "./components/service-collapsible-header/ServiceCollapsibleHeader";

interface IProps {
  projectServices: IService[];
  servicesChanged: { [serviceId: string]: boolean };
  talentsResponse?: IContactsResponse;
  areTalentsLoading: boolean;
  agenciesAndContacts: IAgencyWithContactsResults[];
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onChangesMade: (updatedServices: { [serviceId: string]: boolean }) => void;
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
}

const Services = memo(
  ({
    projectServices,
    servicesChanged,
    talentsResponse,
    areTalentsLoading,
    agenciesAndContacts,
    onScrollTalents,
    onChangesMade,
    onUpdateNarrowSearchText,
    onResetNarrowSearch,
  }: IProps): ReactElement => {
    const { t } = useTranslation();
    const [services, setServices] = useState<IService[]>([]);
    const [isServicesLoading, setServicesLoading] = useState(false);
    const [selectedService, setSelectedService] = useState<IService>();
    const [showForm, setShowForm] = useState<boolean>(false);
    const {
      getAccessToken,
      isSessionValid,
      fetchWrapper,
      organisationId,
    } = useAuth();
    const { id: projectId } = useParams<IProjectRouteParams>();
    const { storeServices } = useProject();
    const { Panel } = Collapse;
    useEffect(() => {
      if (projectServices) {
        setServices(projectServices);
      }
    }, [projectServices]);

    const handleFormSubmit = useCallback(
      async (service: IService, action: ServiceFormType) => {
        setServicesLoading(true);
        const serviceWithOrgId = {
          organisationId,
          ...service,
        };
        const isSession = await isSessionValid();
        if (!isSession) {
          setServicesLoading(false);
          setShowForm(false);
          return;
        }

        if (action === ServiceFormType.Add) {
          await fetchWrapper(createService, projectId, serviceWithOrgId);
        }

        if (action === ServiceFormType.Edit) {
          await fetchWrapper(updateService, projectId, serviceWithOrgId);
        }

        if (action === ServiceFormType.Delete) {
          await fetchWrapper(deleteService, projectId, organisationId, service);
        }
        const accessToken: string = getAccessToken();
        onChangesMade({ [service.id || "new"]: false });

        const response = await getProject(
          accessToken,
          projectId,
          organisationId
        );

        if (response) {
          if (typeof response.services === "undefined") {
            // We'll need to manually set this to an empty array to trigger a re-render on this component if there's no services on the response object.
            response.services = [];
          }
          storeServices({
            services: response.services,
            projectVersion: response.version,
          });
        }
        setServicesLoading(false);
        setShowForm(false);
        if (action === ServiceFormType.Edit) {
          setSelectedService(undefined);
        }
      },
      [
        getAccessToken,
        fetchWrapper,
        isSessionValid,
        onChangesMade,
        organisationId,
        projectId,
        storeServices,
      ]
    );

    const handleDuplicateServiceClick = useCallback(
      async (service: IService) => {
        const serviceWithOrgId = {
          organisationId,
          ...service,
        };

        await fetchWrapper(createService, projectId, serviceWithOrgId);
        const accessToken: string = getAccessToken();

        const response = await getProject(
          accessToken,
          projectId,
          organisationId
        );

        if (response) {
          storeServices({
            services: response.services,
            projectVersion: response.version,
          });
        }
      },
      [fetchWrapper, getAccessToken, organisationId, projectId, storeServices]
    );
    const handleFormCancel = useCallback(
      (serviceId: string) => {
        setShowForm(false);
        onChangesMade({ [serviceId]: false });
      },
      [onChangesMade]
    );
    // TODO - In the future we will need to support multiple currencies across projects.
    // How do we display a total fee if two projects have different currencies (Dollars and Pounds)
    // For now, we take the default currency for the first service to use as the total.
    const calculateTotalFee = () => {
      let total = 0;

      services?.forEach((service) => {
        total += service?.feeAmount?.value ?? 0;
        total += service?.taxWithholdingFee?.value ?? 0;
        total += service?.bankFee?.value ?? 0;

        const findSumThirdParties = () => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const amounts: any[] = [];
          if (service.thirdParties) {
            service.thirdParties?.forEach((item) => {
              amounts.push(item.thirdPartyFee?.value);
            });

            if (amounts.length > 0) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const sum: number = amounts.reduce(
                (previousValue: number, currentValue: number) => {
                  return previousValue + currentValue;
                }
              );
              return sum;
            }
          }
          return 0;
        };
        total += findSumThirdParties();
      });

      return total.toFixed(2);
    };

    const handleArrowClick = useCallback(
      (keys?: string | string[]) => {
        const key = typeof keys === "string" ? keys : keys?.[0];
        const service = services.find((s) => s.id === key);
        setSelectedService(
          selectedService?.id !== service?.id ? service : undefined
        );
        const updatedServices: { [serviceId: string]: boolean } = {};
        if (selectedService?.id) {
          updatedServices[selectedService.id] = false;
        }
        if (service?.id) {
          updatedServices[service.id] = true;
        }
        onChangesMade(updatedServices);
      },
      [onChangesMade, selectedService?.id, services]
    );

    return (
      <div css={styles.container} data-testid="services-header">
        <div css={[mainStyles.titleLabelContainer, mainStyles.switchContainer]}>
          <div>
            <Typography.Text css={mainStyles.titleLabel}>
              {t("ProjectsPage##Services")}
            </Typography.Text>
          </div>
          <div css={[styles.addButton, showForm && styles.addButtonDisabled]}>
            <ButtonWithLabelAndPlusIcon
              disabled={showForm}
              dataTestId="add-new-row-button"
              onClick={() => {
                onChangesMade({ new: true });
                setShowForm(true);
              }}
              ariaLabel="ProjectsPage##servicesSection##Add a service"
              label="ProjectsPage##servicesSection##Add a service"
            />
          </div>
        </div>
        {showForm ? (
          <ServicesForm
            talentsResponse={talentsResponse}
            type={ServiceFormType.Add}
            isServicesLoading={isServicesLoading}
            serviceChanged={servicesChanged.new ?? false}
            areTalentsLoading={areTalentsLoading}
            agenciesAndContacts={agenciesAndContacts}
            onScrollTalents={onScrollTalents}
            onFormCancel={handleFormCancel}
            onFormSubmit={handleFormSubmit}
            onChangesMade={onChangesMade}
            onUpdateNarrowSearchText={onUpdateNarrowSearchText}
            onResetNarrowSearch={onResetNarrowSearch}
          />
        ) : (
          <Collapse
            accordion
            destroyInactivePanel
            activeKey={selectedService?.id}
            onChange={handleArrowClick}
            css={mainStyles.collapseContainer}
          >
            {!!services?.length && (
              <Panel
                key="1"
                css={mainStyles.headerPanelContainer}
                showArrow={false}
                header={<ServiceCollapsibleHeader />}
              />
            )}
            {services.map((service) => {
              return (
                <Panel
                  key={service?.id}
                  css={mainStyles.panelContainer}
                  header={
                    <ServicesFormHeader
                      service={service}
                      onFormSubmit={handleFormSubmit}
                      onDuplicateClick={handleDuplicateServiceClick}
                    />
                  }
                >
                  <div css={mainStyles.editCollapseContainer}>
                    <ServicesForm
                      talentsResponse={talentsResponse}
                      projectService={service}
                      type={ServiceFormType.Edit}
                      isServicesLoading={isServicesLoading}
                      serviceChanged={servicesChanged.new ?? false}
                      areTalentsLoading={areTalentsLoading}
                      agenciesAndContacts={agenciesAndContacts}
                      onFormCancel={() => {
                        if (service?.id) {
                          handleArrowClick(service.id);
                        } else {
                          handleFormCancel("new");
                        }
                      }}
                      onScrollTalents={onScrollTalents}
                      onFormSubmit={handleFormSubmit}
                      onChangesMade={onChangesMade}
                      onUpdateNarrowSearchText={onUpdateNarrowSearchText}
                      onResetNarrowSearch={onResetNarrowSearch}
                    />
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        )}
        {services.length >= 1 && (
          <div css={styles.totalContainer}>
            <div css={styles.totalText}>
              {t("ProjectsPage##servicesSection##Service total")}
            </div>

            <div css={styles.totalFee}>
              {projectServices?.length > 0 &&
                parseCurrencyCode(
                  projectServices[0]?.feeAmount?.currency ?? "USD"
                )}
              {projectServices?.length > 0 && calculateTotalFee()}
            </div>
          </div>
        )}
        {services.length < 1 && !showForm && (
          <Fragment>
            <div>
              <Typography.Text css={styles.typographyText}>
                {t("ProjectsPage##servicesSection##No Services")}
              </Typography.Text>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
);

export default Services;
