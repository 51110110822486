import { Dropdown } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import MoreInfoButton from "src/components/control-buttons/more-info-button";
import MenuItem from "src/components/menu-item";
import { IMediaTypeState } from "../../interfaces";
import styles from "./styles";

interface IProps {
  mediaType: IMediaTypeState;
  onClick: (mediaType: IMediaTypeState) => void;
  onRemoveCustomMediaType: (customMediaType: IMediaTypeState) => void;
  toggleRenameCustomMediaTypeModal: (customMediaType: IMediaTypeState) => void;
}

const MediaType = ({
  mediaType,
  onClick,
  onRemoveCustomMediaType,
  toggleRenameCustomMediaTypeModal,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const MoreInfoWithDropdownMenu = () => {
    const items = [
      {
        label: (
          <MenuItem
            ariaLabel="Rename"
            buttonLabel="Rename"
            customStyles={styles.actionButton}
            onClick={() => {
              toggleRenameCustomMediaTypeModal(mediaType);
            }}
          />
        ),
        key: "Rename",
      },
      {
        label: (
          <MenuItem
            ariaLabel="Delete"
            buttonLabel="Delete"
            customStyles={styles.actionButton}
            onClick={() => {
              onRemoveCustomMediaType(mediaType);
            }}
          />
        ),
        key: "Delete",
      },
    ];

    return (
      <Dropdown
        css={styles.dropdownMenu}
        menu={{ items }}
        trigger={["click"]}
        getPopupContainer={(triggerNode) => triggerNode}
        placement="bottomRight"
      >
        <MoreInfoButton
          onClick={(e) => {
            e.stopPropagation();
          }}
          showTooltip
          className="customMediaButton"
          customStyles={styles.moreInfo}
        />
      </Dropdown>
    );
  };

  return (
    <div
      role="option"
      tabIndex={0}
      aria-selected={mediaType.isSelected}
      css={mediaType.isSelected ? styles.selected : styles.unSelected}
      onClick={() =>
        onClick({
          name: mediaType.name,
          key: mediaType.key,
          isSelected: !mediaType.isSelected,
          isCustom: mediaType.isCustom,
        })
      }
      onKeyDown={() =>
        onClick({
          name: mediaType.name,
          key: mediaType.key,
          isSelected: !mediaType.isSelected,
          isCustom: mediaType.isCustom,
        })
      }
      aria-label={
        !mediaType.isCustom
          ? t(`ProjectsPage##licensingSection##MediaTypes##${mediaType.name}`)
          : mediaType.name
      }
    >
      <div css={styles.name}>
        {!mediaType.isCustom
          ? t(`ProjectsPage##licensingSection##MediaTypes##${mediaType.name}`)
          : mediaType.name}
      </div>
      {mediaType.isCustom && <MoreInfoWithDropdownMenu />}
    </div>
  );
};

export default MediaType;
