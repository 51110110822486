import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { TalentContactAttachmentType } from "src/constants";
import mainStyles from "src/pages/projects/project/styles";
import { DocumentsTableColumns } from "src/pages/projects/project/components/documents/table-data";
import { useParams } from "react-router-dom";
import { FileDrawerTypes } from "src/pages/projects/project/components/documents/components/mobile-drawers";
import { getIsMobile } from "@songtradr/spa-common";
import useAuth from "src/auth/use-auth";
import MobileDrawer from "src/components/mobile-drawer";
import { cloneDeep } from "lodash";
import FileUploader from "../file-uploader";
import { IDeleteSelectedFile } from "../file-uploader/interfaces";
import DocumentsTableData from "../file-uploader/table-data";
import { IContactUploadFile } from "../../interfaces";
import style from "../showreels/styles";
import { generateDrawer } from "../file-uploader/components/mobile-drawer";
import { checkFilesCurrentlyUploading } from "../file-uploader/helpers";

interface IProps {
  documents: IContactUploadFile[];
}

const Documents = ({ documents }: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const [
    selectedFileToDelete,
    setSelectedFileToDelete,
  ] = useState<IDeleteSelectedFile>();
  const { id: contactId } = useParams<{ id: string }>();
  const { getAccessToken, organisationId } = useAuth();
  const [documentsFileList, setDocumentsFileList] = useState<
    IContactUploadFile[]
  >([]);
  const handleSetDocumentsFileList = useCallback(
    (files: IContactUploadFile[]) => {
      setDocumentsFileList(files);
    },
    []
  );
  const accessToken = useMemo(() => getAccessToken(), [getAccessToken]);
  const [showFileUploadDeleteModal, setShowFileUploadDeleteModal] = useState(
    false
  );
  const [activeDrawer, setActiveDrawer] = useState<FileDrawerTypes>(
    FileDrawerTypes.FileInfoDrawer
  );
  const [activeFile, setActiveFile] = useState<IContactUploadFile>();
  const [isDrawerVisible, setDrawerVisible] = useState<boolean>(false);

  const handleSetSelectedFileToDelete = useCallback(
    (file: IDeleteSelectedFile | undefined) => {
      setSelectedFileToDelete(file);
    },
    []
  );

  const handleShowFileUploadDeleteModal = useCallback((show: boolean) => {
    setShowFileUploadDeleteModal(show);
  }, []);

  const handleSetActiveDrawer = useCallback((drawer: FileDrawerTypes) => {
    setActiveDrawer(drawer);
  }, []);

  const handleSetActiveFile = useCallback(
    (file: IContactUploadFile | undefined) => {
      setActiveFile(file);
    },
    []
  );

  const toggleDrawer = useCallback(() => {
    setDrawerVisible((state) => !state);
  }, []);

  useEffect(() => {
    if (!checkFilesCurrentlyUploading(documents)) {
      setDocumentsFileList(cloneDeep(documents));
    }
  }, [documents]);

  return (
    <div css={mainStyles.sectionFormContainer}>
      <Typography.Text css={mainStyles.basicInputLabel}>
        {t("ContactPage##Documents")}
      </Typography.Text>
      <div css={style.documentsUploadContainer}>
        <FileUploader
          attachmentType={TalentContactAttachmentType.Document}
          invalidFileSizeErrorMessage={t(
            "File size exceeds maximum limit of 2GB"
          )}
          existingFiles={documentsFileList}
          setFileList={handleSetDocumentsFileList}
          tableData={DocumentsTableData({
            filesList: documentsFileList,
            accessToken,
            contactId,
            organisationId,
            setShowModal: handleShowFileUploadDeleteModal,
            setSelectedFileToDelete: handleSetSelectedFileToDelete,
            toggleDrawer,
            setActiveDrawer: handleSetActiveDrawer,
            setActiveFile: handleSetActiveFile,
          })}
          selectedFileToDelete={selectedFileToDelete}
          tableColumns={DocumentsTableColumns(isMobile, t)}
          showModal={showFileUploadDeleteModal}
          setShowModal={handleShowFileUploadDeleteModal}
          additionalContainerStyles={style.documentsFileUploader}
        />
      </div>
      {isMobile && (
        <MobileDrawer
          placement="bottom"
          onClose={toggleDrawer}
          open={isDrawerVisible}
          key="bottom"
          closable={false}
          data-testid="mobile-drawer-container"
          className="documents-drawer-container"
        >
          {generateDrawer(
            activeDrawer,
            handleSetActiveDrawer,
            toggleDrawer,
            accessToken,
            contactId,
            organisationId,
            handleSetDocumentsFileList,
            documentsFileList,
            handleShowFileUploadDeleteModal,
            selectedFileToDelete,
            activeFile
          )}
        </MobileDrawer>
      )}
    </div>
  );
};

export default Documents;
