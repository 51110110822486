import { ReactElement } from "react";
import theme from "src/theme";
import IIconComponentProps from "../../interfaces";

const ValidationErrorIcon = ({
  fill = theme.colors.functional.red,
  height = "38",
  width = "38",
  title,
  role = "img",
}: IIconComponentProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    role={role}
    className="validationErrorIcon"
    aria-labelledby="alert-error-title"
  >
    <g fill="none" fillRule="evenodd">
      <title id="alert-error-title">{title}</title>
      <circle cx="8" cy="8" r="8" fill={fill} />
      <line
        x1="8"
        x2="8"
        y1="4.182"
        y2="8.682"
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <circle cx="8" cy="11.496" r="1" fill="#FFF" />
    </g>
  </svg>
);

export default ValidationErrorIcon;
