import axios from "axios";
import {
  SortOrder,
  ProjectSortType,
  IFiltersSearch,
} from "src/pages/projects/project-listings/interfaces";
import { exportProjectsUrl } from "../../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const exportProject = (
  accessToken: string,
  organisationId: string,
  sort: { by: ProjectSortType; order: SortOrder },
  from = 0,
  searchText = "",
  filters?: IFiltersSearch
): Promise<Blob> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    responseType: "blob",
    ...getAxiosOptions(),
  } as const;
  const body = {
    from,
    sort,
    searchText,
    filters,
    organisationId,
  };
  return axios
    .post(exportProjectsUrl(), body, options)
    .then((response) => response.data as Blob);
};

export default exportProject;
