import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common";

export default {
  main: css`
    .ant-table {
      border-radius: 0 !important;
    }
    .ant-table-tbody > tr > td {
      padding: 8px 0;
      min-width: 150px;
      &:last-child {
        min-width: 50px;
      }
    }

    thead .ant-table-cell {
      font-weight: bold;
      padding: 0;
      color: ${theme.colors.secondary.navyBlue};
      padding-bottom: 0.625rem;
      padding-top: 1.25rem;
      background: none !important;

      ::before {
        content: none !important;
      }
    }

    .ant-table table {
      color: ${theme.colors.black} !important;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0;
    }

    .ant-table-tbody {
      border-radius: 0;
    }

    .ellipsis-button {
      text-align: right;
    }

    @media ${queries.large} {
      margin-bottom: 0;
    }
  `,
};
