import { Col, Row, Typography } from "antd";
import React, { ReactElement, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { DashboardIcon } from "src/app/assets/icons/component-icons";
import styles from "./styles";

interface IProps {
  name?: string;
  leads?: {
    id: string;
    name: string;
    email?: string;
  }[];
  product?: string;
}

const ProjectDetails = ({ name, leads, product }: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div css={styles.dashboardIcon}>
        <DashboardIcon />
      </div>
      <Row>
        <Col css={styles.column1} flex="auto" xs={24} sm={24} md={12}>
          <Typography css={styles.sectionHeader}>{t("Project")}</Typography>
          <Typography css={styles.projectTitle}>{name}</Typography>
          <Typography.Paragraph css={styles.brandList}>
            {product}{" "}
          </Typography.Paragraph>
        </Col>
        <Col css={styles.column2} flex="auto" xs={24} sm={24} md={12}>
          <div css={styles.overlay}>
            {leads && (
              <React.Fragment>
                <Typography css={styles.subHeader}>
                  {t("ProjectsPage##Project lead")}
                </Typography>
                <Typography css={styles.projectLead}>
                  {leads.map((lead) => (
                    <span key={lead.id}>{lead.name}</span>
                  ))}
                </Typography>
                <Typography css={styles.email}>
                  leon@bigsyncmusic.com
                </Typography>
              </React.Fragment>
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ProjectDetails;
