import axios from "axios";
import { IPresignedDownloadUrlResponse } from "src/pages/projects/project/interfaces";
import { attachmentDownloadUrl } from "../project-url-helper";
import getAuthHeader from "../headers/get-auth-header";
import getJsonContentHeader from "../headers/get-json-content-header";

interface IProps {
  fileName: string;
  attachmentId: string;
  projectId: string;
  accessToken: string;
  organisationId: string;
}

async function getPresignedDownloadUrl({
  fileName,
  attachmentId,
  projectId,
  accessToken,
  organisationId,
}: IProps): Promise<IPresignedDownloadUrlResponse> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  const data = {
    fileName,
    organisationId,
  };

  return axios.post(
    attachmentDownloadUrl(projectId, attachmentId),
    data,
    options
  );
}

export default getPresignedDownloadUrl;
