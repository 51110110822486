import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../services/styles";

const TrackFormTableHeader = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={styles.collapsibleTableHeaderContainer}>
      <div css={styles.collapsibleTableHeaderFirstCell}>
        {t("ProjectsPage##musicSection##Track title")}
      </div>
      <div css={styles.collapsibleTableHeaderCell}>
        {t("ProjectsPage##musicSection##Artist/Performer name")}
      </div>
      <div css={styles.collapsibleTableHeaderCell}>
        {t("ProjectsPage##musicSection##Writer")}
      </div>
      <div css={styles.collapsibleTableHeaderCell}>
        {t("ProjectsPage##musicSection##Final music type")}
      </div>
      <div css={styles.summaryIconContainer} />
    </div>
  );
};

export default TrackFormTableHeader;
