import { debounce } from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import getPresignedDownloadUrl from "src/api/attachments/get-presigned-download-url";
import getProjectPreviewSummary from "src/api/projects/get-project-preview-summary";
import useAuth from "src/auth/use-auth";
import { handleFileDownload } from "src/components/file-upload/helpers";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { IProjectUploadFile } from "src/pages/projects/project/components/details/components/interfaces";
import {
  IProjectAttachment,
  IProjectForm,
  IProjectRouteParams,
} from "src/pages/projects/project/interfaces";
import Share from "..";
import { formatAttachments } from "../../utils";

const ShareContainer = (): ReactElement => {
  const { getAccessToken, organisationId, fetchWrapper } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams<IProjectRouteParams>();
  const [projectData, setProjectData] = useState<IProjectForm>();
  const [accessToken, setAccessToken] = useState<string>();
  const [tracks, setTracks] = useState<IProjectAttachment[]>([]);

  const previewDownloadFile = debounce(
    async (file: IProjectUploadFile): Promise<void> => {
      SuccessToast(t("File downloading to your file storage"));

      if (accessToken) {
        const downloadFileRequest = await handleFileDownload(
          accessToken,
          file,
          id,
          organisationId
        );

        if (!downloadFileRequest) {
          ErrorToast(
            `FileUploadErrorToast${file.uid}`,
            t("There was a problem downloading the file")
          );
        }
      }
    },
    500
  );

  useEffect(() => {
    async function fetchProjectPreview() {
      const response = await fetchWrapper(
        getProjectPreviewSummary,
        id,
        organisationId
      );
      const token: string = getAccessToken();
      setAccessToken(token);

      if (response) {
        // Seperating the attachments into different categories for presenting on the share page.
        const project = formatAttachments(response);
        setProjectData(project);

        if (project.attachments) {
          const trackData = project.attachments.filter(
            (track) => track.attachmentType === "FinalTrack"
          );

          trackData.map(async (track) => {
            const presignedResponse = await getPresignedDownloadUrl({
              fileName: track.name,
              attachmentId: track.id,
              projectId: id,
              accessToken: token,
              organisationId,
            });

            const value = {
              ...track,
              url: presignedResponse.data.url,
            };
            setTracks((previous) => [...previous, value]);
          });
        }
      }
    }

    void fetchProjectPreview();
  }, [getAccessToken, fetchWrapper, id, organisationId]);

  return (
    <Share
      projectData={projectData}
      previewDownloadFile={previewDownloadFile}
      tracks={tracks}
    />
  );
};

export default ShareContainer;
