import axios, { ResponseType } from "axios";

const downloadFile = async (
  presignedUrl: string,
  contentType: string
): Promise<Blob> => {
  const options = {
    headers: { "Content-Type": contentType },
    responseType: "blob" as ResponseType,
  };

  return axios
    .get(`${presignedUrl}`, options)
    .then((response) => response.data as Blob);
};

export default downloadFile;
