import { getIsMobile } from "@songtradr/spa-common/lib/utils";
import { ArgsProps } from "antd/lib/notification";
import {
  CloseIcon,
  CopyLinkIcon,
  DownloadIcon,
} from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import { notification } from "antd";
import React from "react";
import InfoIcon from "src/app/assets/icons/component-icons/info-icon";
import ErrorIcon from "src/app/assets/icons/component-icons/important-error";
import ReloadLink from "./components/reload-lnk";
import SomeoneElseIsEditing from "./components/editing-message";
import styles from "./styles";
import NotificationText from "./components/notification-text";

const isMobile = getIsMobile();
const duration = () => {
  if (isMobile) return 2.5;
  return 3.5;
};

notification.config({
  placement: "bottomRight",
  duration: duration(),
  closeIcon: (
    <CloseIcon
      fill={theme.colors.white}
      height={13}
      width={14}
      title="Close"
      role="button"
    />
  ),
});

const SuccessToast = (
  message: string | JSX.Element,
  description?: string | JSX.Element | null
): void => {
  const args: ArgsProps = {
    className: "success-toast-notification",
    message: <div css={styles.messageTitle}>{message}</div>,
    description: <div css={styles.description}>{description}</div>,
    icon: <span data-testid="success-toast" />,
  };

  notification.config({ duration: duration() });
  notification.success(args);
};

const ErrorToast = (
  key: string,
  message: string | JSX.Element,
  description?: string | JSX.Element
): void => {
  notification.destroy(key);

  const args: ArgsProps = {
    key,
    message: <div css={styles.messageTitle}>{message}</div>,
    description: <div css={styles.description}>{description}</div>,
    className: "error-toast-notification",
    icon: <ErrorIcon data-testid="error-toast" />,
    closeIcon: (
      <CloseIcon
        fill={theme.colors.white}
        height={12}
        width={12}
        title="Close"
        role="button"
      />
    ),
  };

  notification.error(args);
};

const InfoToast = (message: string): void => {
  const args: ArgsProps = {
    message: <div css={styles.messageTitle}>{message}</div>,
    icon: (
      <div css={styles.iconWrapper} data-testid="info-toast">
        <InfoIcon height={theme.svgSize.large} width={theme.svgSize.large} />
      </div>
    ),
  };
  notification.success(args);
};

const ReloadInfoToast = (section: string): void => {
  const args: ArgsProps = {
    className: "reload-info-toast",
    message: (
      <SomeoneElseIsEditing
        section={section}
        customStyles={styles.messageTitle}
      />
    ),
    description: <ReloadLink notificationApi={notification} />,
    icon: <InfoIcon />,
    closeIcon: (
      <CloseIcon
        fill={theme.colors.white}
        height={12}
        width={12}
        title="Close"
        role="button"
      />
    ),
  };
  notification.config({ duration: 0 });
  notification.info(args);
};

const CopyProjectLinkToast = (): void => {
  const args: ArgsProps = {
    message: (
      <div css={styles.messageTitle}>
        <NotificationText
          testid="copy-link-toast-notification-text"
          text="ProjectsPage##Share link copied to clipboard"
          customStyles={styles.messageTitle}
        />
      </div>
    ),
    icon: <CopyLinkIcon />,
    className: "copy-link-toast",
  };
  notification.success(args);
};

const DownloadTrackToast = (): void => {
  const args: ArgsProps = {
    message: (
      <div css={styles.downloadTrackMessageTitle}>
        <NotificationText
          testid="download-toast-notification-text"
          text="ProjectsPage##Track downloading to your file storage"
          customStyles={styles.messageTitle}
        />
      </div>
    ),
    icon: <DownloadIcon fill={theme.colors.white} />,
    className: "download-track-toast",
  };
  notification.success(args);
};

const ErrorSavingProjectToast = (): void => {
  const args: ArgsProps = {
    message: (
      <NotificationText
        testid="error-message"
        text="ProjectsPage##Errors##SaveError##message"
        customStyles={styles.messageTitle}
      />
    ),
    description: (
      <NotificationText
        testid="error-message"
        text="ProjectsPage##Errors##SaveError##description"
        customStyles={styles.description}
      />
    ),
    icon: <ErrorIcon />,
    closeIcon: (
      <CloseIcon
        fill={theme.colors.white}
        height={12}
        width={12}
        title="Close"
        role="button"
      />
    ),
  };
  notification.config({ duration: 0 });
  notification.info(args);
};

const ErrorFetchingProjectToast = (): void => {
  const args: ArgsProps = {
    message: (
      <NotificationText
        testid="error-message"
        text="ProjectsPage##Errors##FetchError##message"
        customStyles={styles.messageTitle}
      />
    ),
    description: (
      <NotificationText
        testid="error-message"
        text="ProjectsPage##Errors##FetchError##description"
        customStyles={styles.description}
      />
    ),
    icon: <ErrorIcon />,
    closeIcon: (
      <CloseIcon
        fill={theme.colors.white}
        height={12}
        width={12}
        title="Close"
        role="button"
      />
    ),
  };
  notification.config({ duration: 0 });
  notification.info(args);
};

export {
  ErrorToast,
  SuccessToast,
  InfoToast,
  ReloadInfoToast,
  ErrorSavingProjectToast,
  ErrorFetchingProjectToast,
  CopyProjectLinkToast,
  DownloadTrackToast,
};
