import React, { ComponentType, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { IStyledButtonProps } from "./interfaces";
import styles from "./styles";

/*
    This allows us to wrap AntDesign's button component and inject our
    own custom props and styling when needed - useful for supplying custom type
    props for the various buttons within the application's design language.
*/
const withButtonStyles = <P extends unknown>(Component: ComponentType<P>) => {
  return ({
    type,
    className,
    children,
    ariaLabel,
    noLabelTranslation = false,
    ...rest
  }: IStyledButtonProps): ReactElement => {
    const { t } = useTranslation();

    return (
      <Component
        {...(rest as P)}
        css={styles.main}
        className={className}
        type={type}
        aria-label={noLabelTranslation ? ariaLabel : t(ariaLabel)}
      >
        {noLabelTranslation ? children : t(children as string)}
      </Component>
    );
  };
};

export default withButtonStyles(Button);
