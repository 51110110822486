import { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "./interfaces";

const CrossIcon = ({
  height = 24,
  width = 24,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3246 6.8393L17.1607 8.67541C17.3302 8.84495 17.3296 9.11853 17.1607 9.28745L14.4481 12L17.1607 14.7125C17.3302 14.8821 17.3296 15.1557 17.1607 15.3246L15.3246 17.1607C15.1557 17.3296 14.8821 17.3302 14.7125 17.1607L12 14.4481L9.28744 17.1607C9.11852 17.3296 8.84494 17.3302 8.67541 17.1607L6.8393 15.3246C6.67037 15.1557 6.67037 14.8815 6.8393 14.7125L9.55184 12L6.8393 9.28745C6.67037 9.11853 6.67037 8.84434 6.83929 8.67541L8.67541 6.8393C8.84433 6.67038 9.11852 6.67038 9.28744 6.8393L12 9.55185L14.7125 6.8393C14.8815 6.67038 15.1557 6.67038 15.3246 6.8393Z"
        fill={theme.colors.black}
      />
    </svg>
  );
};
export default CrossIcon;
