import axios from "axios";
import {
  ICreateServiceResponse,
  IService,
} from "src/pages/projects/project/interfaces";
import { deleteServiceUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const deleteService = async (
  accessToken: string,
  projectId: string,
  organisationId: string,
  payload: IService
): Promise<ICreateServiceResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.delete(
    deleteServiceUrl(projectId, payload.id, organisationId),
    options
  );
};

export default deleteService;
