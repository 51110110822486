import axios from "axios";
import {
  IFinalTrackSection,
  IFinalTrackSectionWithOrgId,
} from "src/pages/projects/project/interfaces";
import { updateTrackUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const createTrack = async (
  accessToken: string,
  projectId: string,
  trackId: string,
  data: IFinalTrackSectionWithOrgId
): Promise<IFinalTrackSection> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.put(updateTrackUrl(projectId, trackId), data, options);
};

export default createTrack;
