import React, { ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Typography, Form } from "antd";
import { OrganisationTypes } from "@songtradr/vinyl-common/lib/organisation";
import ControlButton from "src/components/control-buttons/base-button";
import resendVerificationEmail from "src/api/resend-verification-email";
import { IOrganisationUrlParams } from "src/interfaces/organisation-url-params";
import { debounce } from "lodash";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import containerStyles from "../../styles";
import formStyles from "../registration-form/styles";

const automaticallyAcceptedOrganisationTypes = [
  OrganisationTypes.MusicSupervisionAgency,
  OrganisationTypes.MusicSupervisionIndependent,
];

interface IProps {
  organisationType: OrganisationTypes;
  requesterEmailAddress: string;
  inviteParams?: IOrganisationUrlParams;
}

const ThankYou = ({
  organisationType,
  requesterEmailAddress,
  inviteParams,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isAutomaticallyAccepted = automaticallyAcceptedOrganisationTypes.includes(
    organisationType
  );

  const errorNotification = () => {
    ErrorToast(
      "resend-verification-email-error",
      t("Error"),
      t(
        "An error occurred when sending the verification email. Please try again."
      )
    );
  };

  const resendVerification = () => {
    if (inviteParams) {
      resendVerificationEmail(inviteParams)
        .then((response) => {
          if (response) {
            SuccessToast(t("Verification email sent."));
          }
        })
        .catch((e) => {
          log(DataDogLogTypes.ERROR, "Error sending verification email", e);
          errorNotification();
        });
    } else {
      errorNotification();
    }
  };

  return (
    <div css={containerStyles.formWrapper}>
      {isAutomaticallyAccepted ? (
        <div>
          <Typography.Title css={formStyles.title} level={3}>
            {t("We sent you an email")}
          </Typography.Title>
          <p>
            <Trans i18nKey="signUpThankYou">{{ requesterEmailAddress }}</Trans>
          </p>
          <p css={containerStyles.topMargin}>{t("Didn't recieve an email?")}</p>

          <Form.Item css={formStyles.submit}>
            <ControlButton
              label={t("Resend verification email")}
              onClick={debounce(() => {
                resendVerification();
              }, 500)}
            >
              {t("Resend verification email")}
            </ControlButton>
          </Form.Item>
        </div>
      ) : (
        <div>
          <Typography.Title css={formStyles.title} level={3}>
            {t("Thank you")}
          </Typography.Title>
          <p>
            {t(
              "Thanks for signing up to Songtradr Projects. Your sign up request has been submitted. We’ll be in touch soon via the email you provided."
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default ThankYou;
