import { css } from "@emotion/react";

export default {
  additionalFormItemStyle: css`
    .ant-form-item-control-input-content {
      display: flex !important;
      justify-content: flex-start !important;
    }
  `,
  basicLabelAdditionalStyles: css`
    margin-right: 10px;
    line-height: 32px;
  `,
  publishingSectionContainer: css`
    row-gap: 0;
  `,
  container: css`
    display: flex;
    flex-direction: column;
  `,
};
