/* eslint-disable @typescript-eslint/no-explicit-any */
import { eAuthType } from "@songtradr/spa-common";
import { CognitoIdToken } from "amazon-cognito-identity-js";
import { ICognitoUser } from "../interfaces";

export const transformCognitoUser = (token: CognitoIdToken): any => {
  const payload = token.decodePayload() as ICognitoUser;

  return {
    id: payload["custom:exUserId"] || payload.sub,
    type: eAuthType.App,
    name: payload.name,
    nickName: payload.nickname,
    picture: "",
    updatedAt: String(payload.auth_time),
    email: payload.email,
    isVerifiedEmail: payload.email_verified,
  };
};

export default transformCognitoUser;
