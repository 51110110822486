const MINIMUM_PASSWORD_LENGTH = 8;

const numberCheck = (value: string): boolean => {
  return new RegExp(/[0-9]/).test(value);
};
const hasLowercase = (value: string): boolean => {
  return new RegExp(/[a-z]/).test(value);
};
const hasUppercase = (value: string): boolean => {
  return new RegExp(/[A-Z]/).test(value);
};
const hasMixedcase = (value: string): boolean => {
  return hasLowercase(value) && hasUppercase(value);
};
const hasSpecial = (value: string): boolean => {
  return new RegExp(/[!?£#@$%^&*)(+=._-]/).test(value);
};

export {
  MINIMUM_PASSWORD_LENGTH,
  numberCheck,
  hasLowercase,
  hasUppercase,
  hasMixedcase,
  hasSpecial,
};
