import { css } from "@emotion/react";
import theme from "src/theme";

const defaultBtn = `
  height: 50px;
  background-color: ${theme.colors.primary.blue};
  color: ${theme.colors.white};
  font-family: ${theme.fonts.sofiaPro};
  border: none;
  line-height: 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  border: none;
`;

const primaryBtn = {
  color: `${theme.colors.white}`,
  backgroundColor: `${theme.colors.primary.blue}`,
};

const secondaryBtn = {
  color: theme.colors.primary.blue,
  border: "none",
  backgroundColor: theme.colors.white,
};

const tertiaryPurpleBtn = {
  color: theme.colors.secondary.purple,
  border: theme.colors.secondary.purple,
  backgroundColor: theme.colors.white,
};

const defaultBtnStyles = css`
  box-shadow: none;
  opacity: 0.9;
  ${defaultBtn}
`;

export default {
  main: css`
    ${defaultBtn}

    &:focus {
      ${defaultBtn}
      box-sizing: border-box;
      box-shadow: inset 0 0 0 3px ${theme.colors.secondary.navyBlue};
    }

    &:active {
      ${defaultBtn}
    }

    &:hover {
      ${defaultBtn}
      background-color: ${theme.colors.secondary.navyBlue};
    }

    .ant-btn-loading-icon {
      display: none;
    }

    &.ant-btn-loading {
      &::before {
        display: none;
      }

      .ant-btn-loading-icon {
        position: absolute;
        display: unset;
        left: 45%;

        .anticon-loading {
          padding-right: 0;
        }
      }

      > span:nth-of-type(2) {
        visibility: hidden;
      }
    }

    &.ant-btn-primary {
      ${primaryBtn}

      &:hover {
        ${primaryBtn}
        background-color: #ffe466;
      }

      &:focus {
        ${primaryBtn}
        box-shadow: 0 0 0 2px #191716, 4px 4px 6px 0 rgba(0, 0, 0, 0.3);
      }

      &:active {
        ${primaryBtn}
        transform: translateY(1px);
      }
    }

    &.ant-btn-secondary {
      ${secondaryBtn}

      &:hover {
        ${secondaryBtn}
        background-color: ${theme.colors.background.veryLightGray};
      }

      &:active {
        ${secondaryBtn}
        transform: translateY(1px);
      }
    }

    &.ant-btn-tertiary-purple {
      ${tertiaryPurpleBtn}
      border-style: solid;
      border-width: 0.094rem;

      &:hover {
        ${tertiaryPurpleBtn}
        color: ${theme.colors.secondary.darkPurple};
        box-sizing: border-box;
        box-shadow: inset 0 0 0 0.063rem ${theme.colors.secondary.darkPurple};
      }

      &:active {
        ${tertiaryPurpleBtn}
        border-style: solid;
        border-width: 0.094rem;
      }

      &:focus {
        ${tertiaryPurpleBtn}
        box-sizing: border-box;
        box-shadow: inset 0 0 0 0.188rem ${theme.colors.secondary.darkPurple};
      }
    }

    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active {
      ${defaultBtnStyles}
    }
  `,
};
