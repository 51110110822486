import React, { ReactElement } from "react";
import { Typography } from "antd";
import { ProjectStatus } from "src/pages/projects/project/interfaces";
import styles from "./styles";
import Button from "../button";
import projectStyles from "../tabbed-page-container/styles";

interface IProps {
  title: string;
  Content: React.ReactNode;
  onCreateProject: (projectName: string, projectOpen: string) => void;
}

const PageContainer = ({
  title,
  Content,
  onCreateProject,
}: IProps): ReactElement => {
  return (
    <div css={styles.container}>
      <div css={styles.projectUpperHeaderBoxShadowing}>
        <div css={[styles.headerContainer, projectStyles.projectUpperHeader]}>
          <Typography.Title css={styles.typographyTitle}>
            {title}
          </Typography.Title>
          <Button
            onClick={() =>
              onCreateProject("Untitled Project", ProjectStatus.NewOpen)
            }
            ariaLabel="ProjectsPage##Create new project"
          >
            ProjectsPage##Create new project
          </Button>
        </div>
      </div>
      {Content}
    </div>
  );
};

export default PageContainer;
