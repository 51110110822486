import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { IFormContactsInvoices } from "src/pages/projects/project/interfaces";
import style from "src/pages/projects/project/styles/index";
import { v4 } from "uuid";
import { Operation } from "src/constants";
import ContactInvoice from "../contact-invoice";

type IInvoiceContacts = {
  contactsInvoices: IFormContactsInvoices[];
  propertyName: string;
  onDeleteRowItem: (index: number) => void;
  onPerformOperation: (
    operation: Operation,
    invoiceContact: IFormContactsInvoices,
    index: number
  ) => void;
  onAddNewRowItem: () => void;
};

const InvoiceContactsRow = ({
  propertyName,
  contactsInvoices,
  onPerformOperation,
  onDeleteRowItem,
  onAddNewRowItem,
}: IInvoiceContacts): ReactElement => {
  const [rowItems, setRowItems] = useState<IFormContactsInvoices[]>([]);

  useEffect(() => {
    if (contactsInvoices.length !== 0) {
      setRowItems(contactsInvoices);
    } else {
      setRowItems([
        {
          name: "",
          email: "",
          key: v4(),
        },
      ]);
    }
  }, [contactsInvoices]);

  const handleUpdateRowItem = useCallback(
    (index: number, invoiceContact: IFormContactsInvoices) => {
      onPerformOperation(Operation.update, invoiceContact, index);
    },
    [onPerformOperation]
  );
  return (
    <div css={style.contactInvoicesContainer}>
      {rowItems.map((invoice, index) => (
        <ContactInvoice
          key={invoice.key}
          index={index}
          isLastItem={index === rowItems.length - 1}
          contactInvoice={invoice}
          propertyName={`${propertyName}${index}`}
          onUpdateRow={handleUpdateRowItem}
          onDeleteRowItem={onDeleteRowItem}
          onAddNewRowItem={onAddNewRowItem}
        />
      ))}
    </div>
  );
};

export default InvoiceContactsRow;
