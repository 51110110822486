import { buildVinylContactsManagementUrl } from "./base-url-helper";

const getContactsFiltersUrl = (
  organisationId: string,
  groupId = "",
  isUserGroup?: boolean
): string => {
  let url = `${buildVinylContactsManagementUrl()}/_filter-options?orgId=${organisationId}`;

  if (groupId) {
    url += `&groupId=${groupId}`;
    if (isUserGroup !== undefined) {
      url += `&isUserGroup=${isUserGroup.toString()}`;
    }
  }
  return url;
};

export const getContactsUrl = (): string => {
  return `${buildVinylContactsManagementUrl()}/_search`;
};

export default getContactsFiltersUrl;
