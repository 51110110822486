import { Col, Row, Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ProjectsLogo from "src/app/assets/images/projects_logo.svg";
import styles from "./styles";

const Footer = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <Row css={styles.footer}>
      <Col css={styles.logo} xs={24} sm={24} md={4} lg={24}>
        <img alt={t("Songtradr Projects")} src={ProjectsLogo} />
      </Col>
      <Col
        sm={{ span: 24, offset: 0 }}
        md={{ span: 4, offset: 4 }}
        css={styles.footerColumn}
      >
        <Typography css={styles.label}>About</Typography>
        <Typography css={styles.link}>Company</Typography>
      </Col>
      <Col xs={24} sm={24} md={4} css={styles.footerColumn}>
        <Typography css={styles.label}>Support</Typography>
        <Typography css={styles.link}>Contact us</Typography>
      </Col>
      <Col xs={24} sm={24} md={4} css={styles.footerColumn}>
        <Typography css={styles.label}>Legal</Typography>
        <Typography css={styles.link}>Terms &amp; privacy</Typography>
      </Col>
    </Row>
  );
};

export default Footer;
