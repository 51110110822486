import { IContactAttachment, IContactForm } from "src/pages/contact/interfaces";

export enum ContactActions {
  STORE_CONTACT = "STORE_CONTACT",
  STORE_SHOW_REEL = "STORE_SHOW_REEL",
  STORE_DOCUMENTS = "STORE_DOCUMENTS",
}

interface IStoreShowReel {
  type: ContactActions.STORE_SHOW_REEL;
  value: {
    attachments: IContactAttachment[];
  };
}

interface IStoreDocuments {
  type: ContactActions.STORE_DOCUMENTS;
  value: {
    attachments: IContactAttachment[];
  };
}

interface IStoreContact {
  type: ContactActions.STORE_CONTACT;
  value: {
    contact: IContactForm;
  };
}

export type ContactAction = IStoreContact | IStoreShowReel | IStoreDocuments;
