import React, { ReactElement, useEffect } from "react";
import { Col, Row, Skeleton } from "antd";
import IUserProfileProps from "src/interfaces/user-profile";
import ProfileForm from "./components/user-profile-form";
import PageFlourish from "../../components/page-flourish";
import styles from "./styles";

const UserProfile = (props: IUserProfileProps): ReactElement | null => {
  useEffect(() => {
    const { getData } = props;
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { organisationInvite } = props;
  const isLoading = !organisationInvite;
  if (isLoading) return <Skeleton active />;

  const { onSubmit, isSavePending, getData } = props;
  return (
    <Row>
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 24, order: 2 }}
        lg={{ span: 12, order: 1 }}
        xl={{ span: 12, order: 1 }}
      >
        <PageFlourish />
      </Col>
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 24, order: 1 }}
        lg={{ span: 10, offset: 2, order: 2 }}
        xl={{ span: 10, offset: 2, order: 2 }}
        css={styles.column}
      >
        <div css={styles.formWrapper}>
          <ProfileForm
            organisationInvite={organisationInvite}
            getData={getData}
            onSubmit={onSubmit}
            isSavePending={isSavePending}
          />
        </div>
      </Col>
    </Row>
  );
};

export default UserProfile;
