import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";

export default {
  tableDataText: css`
    white-space: nowrap;
    width: 100%;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media ${queries.medium} {
      width: 90%;
    }
  `,
};
