import { Typography } from "antd";
import React, {
  useState,
  Fragment,
  ReactElement,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getProject } from "src/api/projects";
import useAuth from "src/auth/use-auth";
import ButtonWithLabelAndPlusIcon from "src/components/plus-button-with-label";
import useProject from "src/providers/project/hooks";
import { UploadFile } from "antd/lib/upload/interface";
import { checkFilesCurrentlyUploading } from "src/components/file-upload/helpers";
import { cloneDeep } from "lodash";
import { AttachmentType } from "src/constants";
import createTrack from "src/api/tracks/create-track";
import updateTrack from "src/api/tracks/update-track";
import { ErrorToast } from "src/components/toast-notification";
import projectValidation from "src/utils/projectValidation";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import { IFinalTrackSection, IProjectRouteParams } from "../../interfaces";
import mainStyles from "../../styles";
import styles from "../services/styles";
import TracksSummary from "./components/tracks-summary";

interface IProps {
  trackChanged: boolean;
  internalCreativeTeamEngaged?: boolean;
  validateTrackOwnership: boolean;
  talentsResponse?: IContactsResponse;
  areTalentsLoading: boolean;
  agenciesAndContacts: IAgencyWithContactsResults[];
  onChangesMade: (changed: boolean) => void;
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
}

const MultiTracks = memo(
  ({
    trackChanged,
    internalCreativeTeamEngaged = false,
    validateTrackOwnership,
    talentsResponse,
    areTalentsLoading,
    agenciesAndContacts,
    onResetNarrowSearch,
    onUpdateNarrowSearchText,
    onChangesMade,
    onScrollTalents,
  }: IProps): ReactElement => {
    const { t } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const {
      getAccessToken,
      fetchWrapper,
      isSessionValid,
      organisationId,
    } = useAuth();
    const { id: projectId } = useParams<IProjectRouteParams>();
    const { project, storeTracks } = useProject();
    const [selectedTrack, setSelectedTrack] = useState<IFinalTrackSection>();
    const [tracksValidity, setTracksValidity] = useState<
      { trackId: string; isValid: boolean }[]
    >([]);
    const [isNewTrack, setIsNewTrack] = useState(false);

    const handleUpdateIsNewTrack = useCallback((newTrack: boolean) => {
      setIsNewTrack(newTrack);
    }, []);

    const handleSetShowEditForm = useCallback((show: boolean) => {
      setShowForm(show);
    }, []);

    const handleSetSelectedTrack = useCallback((track?: IFinalTrackSection) => {
      setSelectedTrack(track);
    }, []);

    const refreshProject = useCallback(() => {
      fetchWrapper(getProject, projectId, organisationId)
        .then((projectResponse) => {
          if (projectResponse) {
            storeTracks({
              tracks: projectResponse.tracks ?? [],
              projectVersion: projectResponse.version,
            });
          }
        })
        .catch(() => {
          ErrorToast("GetProjectError", "Error refreshing project details");
        });
    }, [fetchWrapper, organisationId, projectId, storeTracks]);

    const [finalTrackFilesList, setFinalTrackFilesList] = useState<
      Array<UploadFile>
    >([]);

    useEffect(() => {
      void (() => {
        if (!checkFilesCurrentlyUploading(finalTrackFilesList)) {
          if (project.attachments && project.attachments.length > 0) {
            const attachments = project.attachments.filter(
              (attachment) => attachment.attachmentType === AttachmentType.Track
            ) as UploadFile[];
            setFinalTrackFilesList(cloneDeep(attachments));
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.attachments]);

    const handleSubmit = useCallback(async () => {
      const isSession = await isSessionValid();
      if (selectedTrack && selectedTrack.id && isSession) {
        const updateTrackRequest = {
          organisationId,
          ...selectedTrack,
        };
        let isRequestDataValid = false;
        try {
          await projectValidation.trackSchema.validate(selectedTrack);
          if (validateTrackOwnership) {
            if (internalCreativeTeamEngaged) {
              await projectValidation.trackSchemaWithWriterFee.validate(
                selectedTrack
              );
            } else {
              await projectValidation.trackSchemaWithoutWriterFee.validate(
                selectedTrack
              );
            }
          }
          isRequestDataValid = true;
        } catch (error) {
          ErrorToast("ValidateTrackError", "Fill the required track fields!");
        }
        if (!isRequestDataValid) {
          return;
        }
        // Check the master publishing and writers shares to match 100%
        const areSharesValid = projectValidation.checkSharesTotalPercentage(
          selectedTrack
        );
        if (!areSharesValid) {
          ErrorToast("shares-not-valid", "Shares must equal 100%");
          return;
        }
        try {
          await fetchWrapper(
            updateTrack,
            project.id,
            selectedTrack.id,
            updateTrackRequest
          );
          setIsNewTrack(false);
          setShowForm(false);
          setSelectedTrack(undefined);
          onChangesMade(false);
          refreshProject();
        } catch (error) {
          ErrorToast("SaveTrackError", "Error saving track. Please try again");
        }
      }
    }, [
      isSessionValid,
      fetchWrapper,
      onChangesMade,
      organisationId,
      project.id,
      refreshProject,
      selectedTrack,
      internalCreativeTeamEngaged,
      validateTrackOwnership,
    ]);

    const handleSetTrackDataChanged = useCallback(() => {
      if (!trackChanged) {
        onChangesMade(true);
      }
    }, [onChangesMade, trackChanged]);

    const addNewTrack = useCallback(async () => {
      try {
        const response = await fetchWrapper(
          createTrack,
          organisationId,
          project.id
        );
        const accessToken = getAccessToken();
        const projectResponse = await getProject(
          accessToken,
          project.id,
          organisationId
        );
        if (projectResponse) {
          const currentTrack = (projectResponse?.tracks ?? []).find(
            (track) => track.id === response.id
          );
          if (currentTrack) {
            storeTracks({
              tracks: projectResponse?.tracks ?? [],
              projectVersion: projectResponse.version,
            });
            handleSetSelectedTrack(currentTrack);
            setShowForm(true);
            setIsNewTrack(true);
            handleSetTrackDataChanged();
          }
        }
      } catch (error) {
        ErrorToast(
          "AddNewTrackError",
          "Error creating track. Please try again"
        );
      }
    }, [
      fetchWrapper,
      getAccessToken,
      handleSetSelectedTrack,
      handleSetTrackDataChanged,
      organisationId,
      project.id,
      storeTracks,
    ]);

    const handleAddNewTrack = useCallback(async () => {
      try {
        await fetchWrapper(addNewTrack);
      } catch {
        ErrorToast(
          "AddNewTrackError",
          "Error creating track. Please try again"
        );
      }
    }, [addNewTrack, fetchWrapper]);

    useEffect(() => {
      const updateTracksValidity = async () => {
        const tracksData = await Promise.all(
          (project.tracks ?? []).map(async (track) => {
            if (!validateTrackOwnership) {
              return projectValidation.trackSchema
                .validate(track)
                .catch(() => false);
            }
            if (internalCreativeTeamEngaged) {
              return projectValidation.trackSchemaWithWriterFee
                .validate(track)
                .catch(() => false);
            }
            return projectValidation.trackSchemaWithoutWriterFee
              .validate(track)
              .catch(() => false);
          })
        );
        const validity = (project.tracks ?? []).map((track, index) => {
          return {
            trackId: track.id,
            isValid: Boolean(tracksData[index]),
          };
        });
        setTracksValidity(validity);
      };

      void updateTracksValidity();
    }, [project.tracks, internalCreativeTeamEngaged, validateTrackOwnership]);

    return (
      <div id="Music" data-testid="tracks-header" css={styles.container}>
        <div css={[mainStyles.titleLabelContainer, mainStyles.switchContainer]}>
          <div>
            <Typography.Text css={mainStyles.titleLabel}>
              {t("ProjectsPage##Tracks")}
            </Typography.Text>
          </div>
          <div css={[styles.addButton]}>
            <ButtonWithLabelAndPlusIcon
              disabled={showForm && !!selectedTrack}
              dataTestId="add-new-track-button"
              onClick={handleAddNewTrack}
              ariaLabel="ProjectsPage##musicSection##Add a final track"
              label="ProjectsPage##musicSection##Add a final track"
            />
          </div>
        </div>

        <TracksSummary
          finalTracks={project.tracks}
          tracksValidity={tracksValidity}
          projectId={projectId}
          isNewTrack={isNewTrack}
          validateWriterFeeFields={internalCreativeTeamEngaged}
          validateTrackOwnership={validateTrackOwnership}
          attachments={finalTrackFilesList}
          selectedTrack={selectedTrack}
          talentsResponse={talentsResponse}
          areTalentsLoading={areTalentsLoading}
          agenciesAndContacts={agenciesAndContacts}
          onSelectedTrackChange={handleSetSelectedTrack}
          onFormSubmit={handleSubmit}
          onShowEditForm={handleSetShowEditForm}
          onTrackDataChanged={handleSetTrackDataChanged}
          onChangesMade={onChangesMade}
          onUpdateIsNewTrack={handleUpdateIsNewTrack}
          storeTracks={storeTracks}
          onScrollTalents={onScrollTalents}
          onUpdateNarrowSearchText={onUpdateNarrowSearchText}
          onResetNarrowSearch={onResetNarrowSearch}
        />
        {project.tracks && project.tracks.length < 1 && !showForm && (
          <Fragment>
            <div>
              <Typography.Text>
                {t("ProjectsPage##musicSection##No tracks")}
              </Typography.Text>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
);

export default MultiTracks;
