import React, { FC, ReactElement } from "react";
import { Modal, Typography } from "antd";
import StyledButton from "src/components/button";
import { ModalProps } from "antd/lib/modal";
import theme from "src/theme";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import { IBaseModalProps } from "../interfaces";
import styles from "../styles";

export enum ModalTypes {
  deleteOrganisationMember = "deleteOrganisationMember",
  deleteOrganisationInvite = "deleteOrganisationInvite",
  deleteCreativeAgency = "deleteCreativeAgency",
  addCreativeAgency = "addCreativeAgency",
}

const BaseModal: FC<IBaseModalProps> = ({
  title,
  content,
  buttons,
  onOk = () => {},
  onCancel = () => {},
  onClose = () => {},
  emotionCss,
  ...rest
}): ReactElement => {
  const renderButtons = () => {
    if (!buttons || !buttons.length) return null;
    return (
      <div css={buttons.length <= 1 ? styles.centerButton : null}>
        {buttons.map((button) => (
          <StyledButton
            ariaLabel="modal-button"
            key={button.key || ""}
            type={button.type}
            onClick={button.onClick}
            disabled={button.disabled}
          >
            {button.text}
          </StyledButton>
        ))}
      </div>
    );
  };

  let localProps: ModalProps = {
    title,
    centered: true,
    destroyOnClose: true,
    afterClose: () => {
      /* TODO
      Fix when AntDesign patches the issue. This is a manual clean up here due to a bug currently in
      Ant Design that does not clean up styling properly if a drawer and modal are open at the same time.
      Link - https://github.com/ant-design/ant-design/issues/25972
      */

      const body = document.getElementsByTagName("body")[0];
      body.style.removeProperty("overflow-x");
      body.style.removeProperty("overflow-y");
      body.style.removeProperty("position");
      body.style.removeProperty("width");

      if (body.classList.contains("ant-scrolling-effect")) {
        body.classList.remove("ant-scrolling-effect");
      }
    },
    onOk: (e) => {
      onOk(e);
      onClose(true);
    },
    onCancel: (e) => {
      e.stopPropagation();
      onCancel(e);
      onClose(false);
    },
  };

  if (buttons) {
    localProps = { ...localProps, footer: renderButtons() };
  }

  return (
    <Modal
      data-testid="popup-modal"
      css={[styles.default, emotionCss]}
      closeIcon={
        <CloseIcon
          fill={theme.colors.secondary.purple}
          height={14}
          width={13}
          title="Close"
          role="button"
        />
      }
      {...rest}
      {...localProps}
    >
      <Typography.Text>{content}</Typography.Text>
    </Modal>
  );
};

export default BaseModal;
