/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useCallback, useState } from "react";

export interface IConfirmData {
  prompt: string;
  isOpen: boolean;
  okButtonName: string;
  cancelButtonName: string;
  proceed: (value: unknown) => void;
  cancel: (reason?: any) => void;
}

export const ConfirmContext = createContext({
  confirm: {
    prompt: "",
    isOpen: false,
    okButtonName: "Yes",
    cancelButtonName: "Cancel",
    proceed: (value: unknown) => {},
    cancel: (reason?: any) => {},
  },
  handleSetConfirm: (data: IConfirmData) => {},
});

const ConfirmContextProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [confirm, setConfirm] = useState<IConfirmData>({
    prompt: "",
    isOpen: false,
    okButtonName: "Yes",
    cancelButtonName: "Cancel",
    proceed: (value: unknown) => {},
    cancel: (reason?: any) => {},
  });

  const handleSetConfirm = useCallback((data: IConfirmData) => {
    setConfirm(data);
  }, []);

  return (
    <ConfirmContext.Provider value={{ confirm, handleSetConfirm }}>
      {children}
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextProvider;
