import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme/index";

export default {
  flourish: css`
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #010718;
    padding-left: 1rem;
    @media ${queries.medium} {
      padding-left: 7.5rem;
      padding-right: 7.5rem;
    }
    padding-top: 5.5rem;
  `,

  group: css`
    transition: opacity 0.5s;
  `,

  groupHidden: css`
    opacity: 0;
  `,

  groupShown: css`
    opacity: 1;
  `,

  imageWrapper: css`
    align-self: flex-end;
    padding-top: 4.4375rem;
    margin-bottom: 2.3125rem;
  `,

  completeImage: css`
    display: block;
    max-width: 21.875rem;
  `,
  createImage: css`
    display: block;
    max-width: 34.375rem;
  `,
  tagLine: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.25;
    margin-top: 4rem;
    max-width: 28.125rem;
    text-align: center;
    width: 100%;
  `,
  title: css`
    color: ${theme.colors.background.white} !important;
    font-size: 44px !important;
    font-family: ${theme.fonts.sofiaPro};
    margin-top: 3rem;
    margin-bottom: 0 !important;
    letter-spacing: 0;
    line-height: 3.3125rem !important;
    font-weight: 900;
    max-width: 37rem;
  `,
  description: css`
    color: ${theme.colors.background.white};
    font-size: 1rem !important;
    font-family: ${theme.fonts.sofiaPro};
    margin-top: 1.5rem;
    width: 33.875rem;
    letter-spacing: 0;
    line-height: 22px;
  `,
  logoWrapper: css`
    display: block;
    justify-content: flex-end;
  `,
  learnMoreLink: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.secondary.cyan} !important;
    font-weight: bold;
  `,
  arrowIcon: css`
    border: 2px solid ${theme.colors.secondary.cyan};
    border-radius: 50%;
    padding: 0.3125rem;
    margin-right: 0.4375rem;
  `,
};
