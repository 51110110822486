import config from "../config/config";

export const getCreativeAgenciesUrl = (organisationId: string): string => {
  return `${config.projectManagementService.url}/creative-agencies?orgId=${organisationId}`;
};

export const createCreativeAgenciesUrl = (): string => {
  return `${config.projectManagementService.url}/creative-agencies`;
};

export const getCreativeAgenciesDeleteUrl = (
  creativeAgencyId: string,
  organisationId: string
): string => {
  return `${config.projectManagementService.url}/creative-agencies/${creativeAgencyId}?orgId=${organisationId}`;
};
