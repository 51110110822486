import { SelectOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { INameLinkState } from "src/pages/projects/project/interfaces";
import styles from "./styles";

interface IProps {
  musicAssets: INameLinkState[];
  finalVideo: INameLinkState[];
}

const Assets = ({ musicAssets, finalVideo }: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={styles.overviewContainer}>
      <div css={styles.verticalTitleContainer}>
        <Typography.Title css={styles.verticalTitle}>
          {t("ProjectsPage##Assets")}
        </Typography.Title>
      </div>
      <div css={styles.overviewContentContainer}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Typography.Title css={styles.sectionTitle}>
              {t("ProjectsPage##musicSection##Music assets")}
            </Typography.Title>
            {musicAssets.map((data) => {
              return (
                <div css={styles.linkWrapper} key={data.name}>
                  <Typography.Text css={styles.overviewTitle}>
                    {data.name}
                  </Typography.Text>
                  <a href={data.link} css={styles.assetLink}>
                    <span css={styles.assetLinkText}>{data.link}</span>
                    <SelectOutlined css={styles.openNewIcon} rotate={90} />
                  </a>
                </div>
              );
            })}
          </Col>
          <hr css={styles.horizontalRule} />
          <Col xs={24} sm={24} md={8} lg={8}>
            <Typography.Title css={styles.sectionTitle}>
              {t("ProjectsPage##musicSection##Final video")}
            </Typography.Title>
            {finalVideo.map((data) => {
              return (
                <div css={styles.linkWrapper} key={data.name}>
                  <Typography.Text css={styles.overviewTitle}>
                    {data.name}
                  </Typography.Text>
                  <a href={data.link} css={styles.assetLink}>
                    <span css={styles.assetLinkText}>{data.link}</span>
                    <SelectOutlined css={styles.openNewIcon} rotate={90} />
                  </a>
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Assets;
