import { SerializedStyles } from "@emotion/react";
import React, { ReactElement } from "react";
import { ShareIcon } from "src/app/assets/icons/component-icons";
import ControlButton from "../base-button";

interface IProps {
  showLabel?: boolean;
  showTooltip?: boolean;
  className?: string;
  onClick?:
    | (() => void)
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  tooltipColor?: string;
  customStyles?: SerializedStyles;
  label: string;
}

const ShareButton = ({
  showLabel = false,
  showTooltip = true,
  className = "",
  onClick,
  tooltipColor,
  customStyles,
  label,
}: IProps): ReactElement => {
  return (
    <ControlButton
      css={customStyles}
      className={className}
      showLabel={showLabel}
      showTooltip={showTooltip}
      label={label}
      data-testid="share-button"
      tooltipColor={tooltipColor}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <ShareIcon />
    </ControlButton>
  );
};

export default ShareButton;
