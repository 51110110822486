import { css } from "@emotion/react";
import { StylesConfig } from "react-select";
import theme from "src/theme";

const sharedButtonStyle = `
  flex: 1;
  display: flex;
  font-size: 0.9rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 0;
  padding: 0;
  background-color: ${theme.colors.white};
  font-family: ${theme.fonts.sofiaPro};
  border: 0;

  :first-of-type {
    border-right: 1px solid ${theme.colors.background.veryLightGray};
  }
`;

const sharedDropdownStyle = `
border-radius: 0.2rem;
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  padding: 0;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin-bottom: 0;
  padding-right: 1rem;
  padding-left: 0.5rem;
  font-family: ${theme.fonts.sofiaPro};
  background-color: ${theme.colors.background.gray};

  li {
    padding: 0.2rem;
    cursor: pointer;

    :hover {
      font-weight: bold;
    }
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: ${theme.colors.background.gray};
}
`;

type MyOptionType = {
  label: string;
  value: string;
};

export const customStyles: StylesConfig<MyOptionType> = {
  menu: (provided) => ({
    ...provided,
    fontSize: 16,
    fontWeight: 400,
  }),
  input: (provided) => ({
    ...provided,
    fontSize: 16,
    fontFamily: `${theme.fonts.sofiaPro}`,
    marginTop: -8,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 4,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
    color: `${theme.colors.primary.blue}`,

    ":hover": {
      color: `${theme.colors.secondary.lightGray}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 56,
    fontSize: 16,
    maxHeight: 56,
    fontWeight: 400,
    overflow: "overlay",
  }),
};

export const customStylesForProjectDropdown: StylesConfig<MyOptionType> = {
  menu: (provided) => ({
    ...provided,
    fontSize: 16,
  }),
  input: (provided) => ({
    ...provided,
    fontSize: 16,
    fontFamily: `${theme.fonts.sofiaPro}`,
    marginTop: -8,
  }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: 16,
    borderRadius: 4,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
    color: `${theme.colors.primary.blue}`,

    ":hover": {
      color: `${theme.colors.secondary.lightGray}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: "auto",
    height: 56,
  }),
};

export default {
  briefUploadMoreInfo: css`
    pointer-events: visible;
    cursor: pointer;

    .ant-popover {
      top: 3.4375rem !important;
      background-color: ${theme.colors.white} !important;
    }

    .ant-popover-inner {
      border-radius: 0.3rem;
      background-color: ${theme.colors.white} !important;
    }

    .ant-popover-placement-bottomRight {
      padding-top: 0 !important;
    }

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-content {
      width: 140px;
    }
  `,
  multiSelectsStyles: css`
    .ant-select-selector {
      border-radius: 0.3rem !important;
      border: 1px solid ${theme.colors.background.lightGray} !important;
      background-color: ${theme.colors.white} !important;
      :hover {
        border: 1px solid ${theme.colors.primary.blue} !important;
      }
    }
    .ant-select-selection-overflow {
      align-items: center;
    }
    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.85) !important;
    }
    .ant-select-selection-item {
      border-radius: 0.1rem !important;
      padding-top: 0 !important;
      align-items: center;
      margin-left: 0.25rem;
    }
  `,
  actionButton: css`
    display: inline-block;
    border: none;
    font-size: 16px;
    margin: 0;
    padding: 0.375rem 1.125rem;
    text-decoration: none;
    background-color: ${theme.colors.background.gray};
    cursor: pointer;
    width: 100%;
    text-align: left;

    :hover {
      font-weight: bold;
    }
  `,
  moreInfoButton: css`
    pointer-events: visible;

    :hover {
      background-color: rgba(128, 88, 255, 0.15);
      border-radius: 1.5625rem;
      box-sizing: border-box;
    }
  `,
  dropdownMenuBrief: css`
    ${sharedDropdownStyle}
    box-shadow: 0 0 0.313rem 0.125rem rgb(0 0 0 / 12%),
  0 0.375rem 1rem 0 rgb(0 0 0 / 8%), 0 0.563rem 1.75rem rgb(0 0 0 / 5%);
    min-width: 140px;
  `,
  dropdownMenu: css`
    ${sharedDropdownStyle}
    max-width: 175px;
  `,
  popoverButton: css`
    border: 0;
  `,
  drawerContentContainer: css`
    padding: 1rem 1rem 0 1rem;
    min-height: 170px;

    .ant-form-item-explain.ant-form-item-explain-error {
      padding-top: 0.4375rem;
      padding-left: 0.125rem;
      font-size: 16px;
    }
  `,
  drawerInfoContainer: css`
    margin-bottom: 1.5rem;
  `,
  drawerHeader: css`
    color: ${theme.colors.secondary.navyBlue} !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;

    :first-of-type {
      margin-top: 1rem;
    }
  `,
  drawerHeaderText: css`
    color: ${theme.colors.secondary.navyBlue} !important;
    font-size: 1.2rem !important;
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
  `,
  drawerButtonContainer: css`
    height: 50px;
    margin-left: -1rem;
    margin-right: -1rem;
    background-color: ${theme.colors.white};
    display: flex;
    border-top: 1px solid ${theme.colors.background.veryLightGray};
  `,
  mobileButtonContainer: css`
    ${sharedButtonStyle}
  `,
  mobileButtonContainerPrimary: css`
    ${sharedButtonStyle}
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary.blue};
  `,
  mobileButtonContainerCancel: css`
    ${sharedButtonStyle}
    color: ${theme.colors.primary.blue};
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;

    button {
      padding: 0 !important;
      margin: 0 !important;
    }
  `,
  fileUploadMobile: css`
    h1 {
      font-size: 1rem;
      color: ${theme.colors.secondary.navyBlue};
    }
    font-size: 1rem;
    text-align: left;
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    .attachment-menu-button {
      display: inline-block;
      float: right;
    }
  `,
  mobileMenuContainer: css`
    top: -5.5rem;
    position: relative;
  `,
  mobileShareButtonContainer: css`
    display: block;
    button {
      padding: 0;
    }
  `,
};
