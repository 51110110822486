import { AxiosError } from "axios";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import useAuth from "src/auth/use-auth";
import { ErrorToast } from "src/components/toast-notification";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import { useTranslation } from "react-i18next";
import useConfirm from "src/providers/confirm-context-provider/hooks";
import * as H from "history";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import contactValidation from "src/utils/contactValidation";
import createContact from "src/api/talent-hub/create-contact";
import areChangesMadeInContact from "src/utils/contact-changes-check";
import Contact from "..";
import {
  IAAndRData,
  IContactCreateRequest,
  IContactForm,
  INotesAndDocumentsData,
  IPersonalDetailsData,
  IPublishingInformationData,
  ITab,
  TabsTitles,
} from "../interfaces";
import PersonalDetails from "../components/personal-details";
import PublishingInformation from "../components/publishing-information";
import AAndR from "../components/a-and-r";
import NotesAndDocuments from "../components/notes-and-documents";
import ContactSuccessfullyCreatedOrUpdated from "../components/contact-created-updated";
import { filterEmptyNotes, filterShowReelUrls } from "../utils";

const DEFAULT_CONTACT_DATA: IContactForm = {
  name: "",
  pronoun: null,
  agencyWritingPartnership: "",
  email: "",
  phone: { countryCode: "", number: "" },
  baseLocation: "",
  ipi: "",
  socialMediaWebsite: [],
  showreelUrls: [],
  mainLanguage: "",
  otherLanguages: [],
  billingName: "",
  billingAddress: "",
  physicalConditionsIllness: null,
  neurodiverse: null,
  neurodiverseNote: "",
  racialCharacteristics: null,
  genderIdentity: null,
  sexualOrientation: null,
  signedCMA: null,
  signedNDA: null,
  minimumDemoFee: null,
  performingRightsOrganisationNumber: "",
  connectedToOrgId: null,
  serviceProvider: null,
  collaborationStatus: null,
  skillSet: [],
  principalInstruments: [],
  additionalInstruments: [],
  keyGenres: [],
  additionalGenres: [],
  vocalist: null,
  minimumDemoTurnaroundHours: null,
  previousWork: [],
  interestedProjects: [],
  talentNotes: [],
  internalNotes: [],
  awardNotes: [],
  showReel: [],
  documents: [],
  id: "",
};

const ContactContainer = (): ReactElement => {
  const { isConfirmed } = useConfirm();
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const { fetchWrapper, isSessionValid, organisationId } = useAuth();
  const [activeTab, setActiveTab] = useState<ITab>();
  const [isWaitingForElasticSearch, setIsWaitingForElasticSearch] = useState(
    false
  );
  const history = useHistory();
  const { talentHubTypes } = useGlobalStates();
  const [contactId, setContactId] = useState<string>("");
  const defaultContactData = useMemo(
    () => ({ ...DEFAULT_CONTACT_DATA, connectedToOrgId: organisationId }),
    [organisationId]
  );

  const [
    personalDetailsData,
    setPersonalDetailsData,
  ] = useState<IPersonalDetailsData>({
    name: "",
    pronoun: null,
    agencyWritingPartnership: "",
    email: "",
    phone: { countryCode: "", number: "" },
    baseLocation: "",
    socialMediaWebsite: [],
    showreelUrls: [],
    mainLanguage: "",
    otherLanguages: [],
    billingName: "",
    billingAddress: "",
    physicalConditionsIllness: null,
    neurodiverse: null,
    neurodiverseNote: "",
    ipi: "",
    racialCharacteristics: null,
    genderIdentity: null,
    sexualOrientation: null,
  });
  const [
    publishingInformationData,
    setPublishingInformationData,
  ] = useState<IPublishingInformationData>({
    signedCMA: null,
    signedNDA: null,
    minimumDemoFee: null,
    performingRightsOrganisationNumber: "",
    connectedToOrgId: organisationId,
    serviceProvider: null,
  });
  const [aAndRData, setAAndRData] = useState<IAAndRData>({
    collaborationStatus: null,
    skillSet: [],
    principalInstruments: [],
    additionalInstruments: [],
    keyGenres: [],
    additionalGenres: [],
    vocalist: null,
    minimumDemoTurnaroundHours: null,
    previousWork: [],
    interestedProjects: [],
  });
  const [
    notesAndDocumentsData,
    setNotesAndDocumentsData,
  ] = useState<INotesAndDocumentsData>({
    talentNotes: [],
    internalNotes: [],
    awardNotes: [],
  });
  const [saveRouteChange, setSaveRouteChange] = useState(false);
  const unmountedChangeRoute = useRef(false);
  const [arePersonalDetailsValid, setPersonalDetailsValid] = useState(true);
  const [
    isPublishingInformationValid,
    setPublishingInformationValid,
  ] = useState(true);
  const [isAAndRValid, setAAndRValid] = useState(true);
  const [
    validatePersonalDetailsTabFields,
    setValidatePersonalDetailsTabFields,
  ] = useState(0);
  const [
    validatePublishingInformationTabFields,
    setValidatePublishingInformationTabFields,
  ] = useState(0);
  const [validateAAndRTabFields, setValidateAAndRTabFields] = useState(0);

  const handleUpdatePersonalDetailsData = useCallback(
    (changes: IPersonalDetailsData) => {
      setPersonalDetailsData({ ...personalDetailsData, ...changes });
    },
    [personalDetailsData]
  );

  const handleUpdatePublishingInformationData = useCallback(
    (changes: IPublishingInformationData) => {
      setPublishingInformationData({
        ...publishingInformationData,
        ...changes,
      });
    },
    [publishingInformationData]
  );

  const handleUpdateAAndRData = useCallback(
    (changes: IAAndRData) => {
      setAAndRData({ ...aAndRData, ...changes });
    },
    [aAndRData]
  );

  const handleUpdateNotesAndDocumentsData = useCallback(
    (changes: INotesAndDocumentsData) => {
      setNotesAndDocumentsData({ ...notesAndDocumentsData, ...changes });
    },
    [notesAndDocumentsData]
  );

  const getContactDataRequest = useCallback(() => {
    let vocalist = null;
    if (aAndRData.vocalist === "Yes") {
      vocalist = true;
    } else if (aAndRData.vocalist === "No") {
      vocalist = false;
    }
    const showreelUrls = filterShowReelUrls(personalDetailsData.showreelUrls);
    const talentNotes = filterEmptyNotes(notesAndDocumentsData.talentNotes);
    const internalNotes = filterEmptyNotes(notesAndDocumentsData.internalNotes);
    const awardNotes = filterEmptyNotes(notesAndDocumentsData.awardNotes);

    return {
      ...personalDetailsData,
      ...publishingInformationData,
      ...aAndRData,
      vocalist,
      ...notesAndDocumentsData,
      organisationId,
      showreelUrls,
      talentNotes,
      internalNotes,
      awardNotes,
    };
  }, [
    aAndRData,
    notesAndDocumentsData,
    organisationId,
    personalDetailsData,
    publishingInformationData,
  ]);

  useEffect(() => {
    const validatePersonalDetailsTab = async () => {
      let isDataValid = true;
      try {
        await contactValidation.personalDetailsSchema.validate(
          personalDetailsData
        );
      } catch (error) {
        isDataValid = false;
      }
      setPersonalDetailsValid(isDataValid);
    };
    void validatePersonalDetailsTab();
  }, [personalDetailsData]);

  useEffect(() => {
    const validatePublishingInformationTab = async () => {
      let isDataValid = true;
      try {
        await contactValidation.publishingInformationSchema.validate(
          publishingInformationData
        );
      } catch (error) {
        isDataValid = false;
      }
      setPublishingInformationValid(isDataValid);
    };
    void validatePublishingInformationTab();
  }, [publishingInformationData]);

  useEffect(() => {
    const validateAAndRTab = async () => {
      let isDataValid = true;
      try {
        await contactValidation.aAndRSchema.validate(aAndRData);
      } catch (error) {
        isDataValid = false;
      }
      setAAndRValid(isDataValid);
    };
    void validateAAndRTab();
  }, [aAndRData]);

  const handleTabChange = useCallback((tab: ITab) => {
    setActiveTab(tab);
  }, []);

  const areChangesMade = useCallback(() => {
    return areChangesMadeInContact(
      defaultContactData,
      personalDetailsData,
      publishingInformationData,
      aAndRData,
      notesAndDocumentsData
    );
  }, [
    aAndRData,
    defaultContactData,
    notesAndDocumentsData,
    personalDetailsData,
    publishingInformationData,
  ]);

  useEffect(() => {
    const blocker = (location: H.Location, action: H.Action): boolean => {
      if (saveRouteChange || unmountedChangeRoute.current) {
        return true;
      }
      if (areChangesMade()) {
        void isConfirmed(
          "Your changes will be lost if you don't save them. Do you want to continue?"
        ).then((confirmed) => {
          if (confirmed) {
            unmountedChangeRoute.current = true;
            switch (action) {
              case H.Action.Push:
                history.push(location.pathname);
                break;
              case H.Action.Pop:
              case H.Action.Replace:
              default:
                history.replace(location.pathname);
                break;
            }
          }
          if (!confirmed && action === H.Action.Pop) {
            unmountedChangeRoute.current = true;
            const newLocationPathname = `talent-create`;
            const currentLocationPathname = window.location.pathname;
            const historyAction =
              currentLocationPathname !== newLocationPathname
                ? "push"
                : "replace";
            history[historyAction](newLocationPathname);
          }
          return true;
        });
        return false;
      }
      return true;
    };
    const unblock = history.block((...args) => {
      const [location, action] = args as unknown[];
      return blocker(location as H.Location, action as H.Action);
    });
    return () => {
      unblock();
      unmountedChangeRoute.current = false;
    };
  }, [areChangesMade, history, isConfirmed, saveRouteChange]);

  const isContactRequestValid = useCallback(
    async (request: ReturnType<typeof getContactDataRequest>) => {
      let isRequestDataValid = false;
      try {
        await contactValidation.contactSchema.validate(request);
        isRequestDataValid = true;
      } catch (error) {
        ErrorToast(
          "contact-validation-error",
          t("ContactPage##Errors##ValidationError##message")
        );
      }

      return isRequestDataValid;
    },
    [t]
  );

  const saveContactData = useCallback(
    async (
      request: ReturnType<typeof getContactDataRequest>,
      finishAfterSave: () => void
    ) => {
      setIsSaving(true);

      try {
        const { id } = await fetchWrapper(
          createContact,
          request as IContactCreateRequest,
          organisationId
        );
        setContactId(id);
        setSaveRouteChange(true);
        finishAfterSave();
      } catch (reason) {
        const error = reason as AxiosError;
        if (error.response?.status === 409) {
          log(
            DataDogLogTypes.ERROR,
            "Error saving contact: Contact with given email already exists",
            error
          );
          ErrorToast(
            "contact-save-error",
            t("ContactPage##Errors##SaveError##messageContactWithSameEmail")
          );
        } else {
          log(DataDogLogTypes.ERROR, "Error saving contact", error);
          ErrorToast(
            "contact-save-error",
            t("ContactPage##Errors##SaveError##message")
          );
        }
      } finally {
        setIsSaving(false);
      }
    },
    [fetchWrapper, organisationId, t]
  );

  const handleSetWaitingForElasticSearch = useCallback(() => {
    setIsWaitingForElasticSearch(!isWaitingForElasticSearch);
  }, [isWaitingForElasticSearch]);

  const handleSaveContact = useCallback(
    async (finishAfterSave: () => void) => {
      const isSession = await isSessionValid();
      if (!isSession) {
        log(DataDogLogTypes.ERROR, "Error saving contact", "error");
        ErrorToast(
          "contact-save-error",
          t("ContactPage##Errors##SaveError##message")
        );
        return;
      }
      const request = getContactDataRequest();
      const isRequestDataValid = await isContactRequestValid(request);
      if (!isRequestDataValid) {
        return;
      }
      await saveContactData(request, finishAfterSave);
    },
    [
      getContactDataRequest,
      isContactRequestValid,
      isSessionValid,
      saveContactData,
      t,
    ]
  );

  const handleValidateTabFields = useCallback(
    (tabTitle: string) => {
      switch (tabTitle) {
        case TabsTitles.PersonalDetails:
          setValidatePersonalDetailsTabFields(
            validatePersonalDetailsTabFields + 1
          );
          break;
        case TabsTitles.PublishingInformation:
          setValidatePublishingInformationTabFields(
            validatePublishingInformationTabFields + 1
          );
          break;
        case TabsTitles.AAndR:
          setValidateAAndRTabFields(validateAAndRTabFields + 1);
          break;
        case TabsTitles.NotesAndDocuments:
        default:
          break;
      }
    },
    [
      validateAAndRTabFields,
      validatePersonalDetailsTabFields,
      validatePublishingInformationTabFields,
    ]
  );

  return (
    <Contact
      isSaving={isSaving}
      isLoading={false}
      activeTab={activeTab}
      setActiveTab={handleTabChange}
      onSaveContact={handleSaveContact}
      validation={{
        personalDetails: arePersonalDetailsValid,
        publishingInformation: isPublishingInformationValid,
        aAndR: isAAndRValid,
        notesAndDocuments: true,
      }}
      disabledTabsForClick
      validateTabFields={handleValidateTabFields}
    >
      {{
        personalDetails: (
          <PersonalDetails
            personalDetailsData={personalDetailsData}
            onPersonalDetailsDataChange={handleUpdatePersonalDetailsData}
            talentHubTypes={talentHubTypes}
            validateTabFields={validatePersonalDetailsTabFields}
          />
        ),
        publishingInformation: (
          <PublishingInformation
            publishingInformationData={publishingInformationData}
            onPublishingInformationDataChange={
              handleUpdatePublishingInformationData
            }
            validateTabFields={validatePublishingInformationTabFields}
          />
        ),
        aAndR: (
          <AAndR
            aAndRData={aAndRData}
            onAAndRDataChange={handleUpdateAAndRData}
            talentHubTypes={talentHubTypes}
            validateTabFields={validateAAndRTabFields}
          />
        ),
        notesAndDocuments: (
          <NotesAndDocuments
            notesAndDocumentsData={notesAndDocumentsData}
            onNotesAndDocumentsDataChange={handleUpdateNotesAndDocumentsData}
          />
        ),
        contactCreatedOrUpdated: (
          <ContactSuccessfullyCreatedOrUpdated
            onSetWaitingForElasticSearch={handleSetWaitingForElasticSearch}
            contactId={contactId}
          />
        ),
      }}
    </Contact>
  );
};

export default ContactContainer;
