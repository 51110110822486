import {
  getIsDesktop,
  getIsMobile,
  getIsTablet,
  useWindowSize,
} from "@songtradr/spa-common/lib/utils";
import React, { Fragment, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IConfirmContentProps } from "src/components/interfaces";
import MobileDrawer from "src/components/mobile-drawer";
import ConfirmationDrawer from "src/components/mobile-drawer/confirmation-drawer";
import BaseModal, { ModalTypes } from "src/components/modals/base-modal";
import Content from "src/components/modals/confirmation-modal/content";
import Table from "src/components/table";
import { IMemberInvite } from "../../interfaces";
import style from "../../styles";
import orgInvitesColumns from "../../table-data/org-invites-columns";
import orgInvitesData from "../../table-data/org-invites-data";

const NoOrganisationInvites = (): ReactElement => {
  return <div />;
};

interface IProps {
  getData: () => void;
  orgInvites: Array<IMemberInvite>;
  handleResendInvite: (inviteId: string) => void;
  handleDeleteInvite: (inviteId: string) => void;
  handleConfirmDeleteInviteClick: () => void;
  handleOnCloseModal: () => void;
  openModalType?: ModalTypes;
  invitesLastUpdatedAt: Date;
  isModalOpen: boolean;
  confirmDeleteContentProps: IConfirmContentProps;
}

const OrganisationInvitesTable = ({
  getData,
  orgInvites,
  isModalOpen,
  openModalType,
  confirmDeleteContentProps,
  handleResendInvite,
  handleDeleteInvite,
  handleOnCloseModal,
  invitesLastUpdatedAt,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitesLastUpdatedAt]);

  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();
  const isDesktop = getIsDesktop();

  if (orgInvites?.length === 0) {
    return <NoOrganisationInvites />;
  }
  const columns = orgInvitesColumns(isTablet, isMobile, t);

  const dataSource = orgInvitesData(
    orgInvites,
    isTablet,
    isMobile,
    handleResendInvite,
    handleDeleteInvite,
    t
  );

  return (
    <Fragment>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        css={style.table}
        data-testid="team-invites-table"
      />

      {isDesktop &&
        openModalType === ModalTypes.deleteOrganisationInvite &&
        confirmDeleteContentProps && (
          <BaseModal
            open={isModalOpen}
            content={<Content {...confirmDeleteContentProps} />}
            buttons={[]}
            onClose={handleOnCloseModal}
          />
        )}

      {(isMobile || isTablet) &&
        openModalType === ModalTypes.deleteOrganisationInvite &&
        confirmDeleteContentProps && (
          <MobileDrawer
            data-testid="mobile-drawer"
            placement="bottom"
            onClose={handleOnCloseModal}
            open={isModalOpen}
            key="bottom"
            closable={false}
            destroyOnClose
            css={style.drawer}
          >
            <ConfirmationDrawer {...confirmDeleteContentProps} />
          </MobileDrawer>
        )}
    </Fragment>
  );
};

export default OrganisationInvitesTable;
