import { Badge, Tabs, Typography, Space, Divider, Button } from "antd";
import React, {
  ReactElement,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { scrollToHash } from "src/components/tabbed-page-container/helpers";
import {
  IBadgeCounter,
  ITab,
  TabsTitles,
} from "src/pages/projects/project/interfaces";
import { IProjectHeaderAndSubHeader } from "src/pages/projects/project/components/product-information/types";
import { useTranslation } from "react-i18next";
import {
  getIsDesktop,
  getIsMobile,
  queries,
} from "@songtradr/spa-common/lib/utils";
import { css } from "@emotion/react";
import config from "src/config";
import HubspotIcon from "src/app/assets/icons/component-icons/buttons/hubspot";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import useAuth from "src/auth/use-auth";
import selfOrganisations, {
  IOrganisationSearchResponse,
} from "src/api/organisation/self";
import styles from "./styles";
import BaseModal from "../modals/base-modal";
import Content from "../modals/info-modal";

interface IProps {
  title?: string | ReactElement;
  isSaving?: boolean;
  projectOrgId?: string;
  tabs: Array<ITab>;
  activeTab?: ITab;
  setActiveTab?: (activeTab: ITab) => void;
  sharedWithClient?: JSX.Element;
  saveAndCloseComponent?: JSX.Element;
  validation?: {
    projectDetails: boolean;
    music: boolean;
    licensing: boolean;
    subscription: boolean;
    contacts: boolean;
  };
  isTeams?: boolean;
  projectHeaderAndSubHeader?: IProjectHeaderAndSubHeader;
  badgeCounter?: IBadgeCounter;
}
const spacingNumber = 40;
const TabbedPageContainer = ({
  title,
  isSaving,
  tabs,
  activeTab,
  projectOrgId,
  setActiveTab,
  projectHeaderAndSubHeader,
  sharedWithClient,
  saveAndCloseComponent,
  validation,
  isTeams = false,
  badgeCounter,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const { organisationId, switchOrg, fetchWrapper } = useAuth();
  const isDesktop = getIsDesktop();
  const isMobile = getIsMobile();
  const [localActiveTab, setLocalActiveTab] = useState<ITab>();
  const [showCannotSwitchOrgModal, setShowCannotSwitchOrgModal] = useState(
    false
  );
  const [
    organisations,
    setOrganisations,
  ] = useState<IOrganisationSearchResponse | null>(null);
  const { allProjectStatuses } = useGlobalStates();
  const isProjectInSelectedOrganisation = useMemo(
    () => organisationId === projectOrgId,
    [organisationId, projectOrgId]
  );

  useEffect(() => {
    if (activeTab !== localActiveTab && activeTab) {
      setLocalActiveTab(activeTab);
    }
  }, [activeTab, localActiveTab]);

  useEffect(() => {
    const getOrganisations = async () => {
      const response = await fetchWrapper<IOrganisationSearchResponse | null>(
        selfOrganisations
      );
      setOrganisations(response);
    };

    if (!isTeams && !isProjectInSelectedOrganisation) {
      void getOrganisations();
    }
  }, [fetchWrapper, isProjectInSelectedOrganisation, isTeams]);

  const handleChangeOrganisation = useCallback(
    async (orgId: string) => {
      const org = organisations?.items.find((item) => item.id === orgId);

      if (!org) {
        setShowCannotSwitchOrgModal(true);
      } else {
        await switchOrg(orgId, false);
      }
    },
    [organisations?.items, switchOrg]
  );

  const handleTabChange = useCallback(
    (activeKey: string) => {
      const currentTab = tabs.find((tab) => tab.tabTitle === activeKey);
      if (currentTab) {
        if (setActiveTab) {
          if (activeTab?.tabTitle !== activeKey) setActiveTab(currentTab);
        } else {
          setLocalActiveTab(currentTab);
        }

        if (currentTab.hashLink) {
          scrollToHash(currentTab.tabTitle, 180);
        }
      }
    },
    [activeTab?.tabTitle, setActiveTab, tabs]
  );
  const getTabErrorAndBadgeCount = useCallback(
    (tabTitle: string) => {
      switch (tabTitle) {
        case TabsTitles.ProjectDetails:
          return {
            isError: !(validation?.projectDetails ?? true),
            count: 0,
          };
        case TabsTitles.Subscription:
          return {
            isError: !(validation?.subscription ?? true),
            count: 0,
          };
        case TabsTitles.Contacts:
          return {
            isError: !(validation?.contacts ?? true),
            count: 0,
          };
        case TabsTitles.Documents:
          return {
            count: badgeCounter?.numberOfDocuments ?? 0,
            showZero: true,
          };
        case TabsTitles.Music:
          return {
            isError: !(validation?.music ?? true),
            count: badgeCounter?.numberOfTracks ?? 0,
          };
        case TabsTitles.Licenses:
          return {
            isError: !(validation?.licensing ?? true),
            count: badgeCounter?.numberOfLicenses ?? 0,
          };
        case TabsTitles.Services:
          return {
            isError: false,
            count: badgeCounter?.numberOfServices ?? 0,
          };
        case TabsTitles.Tags:
          return { isError: false, count: 0 };
        default:
          return { isError: false, count: 0 };
      }
    },
    [
      badgeCounter?.numberOfDocuments,
      badgeCounter?.numberOfLicenses,
      badgeCounter?.numberOfServices,
      badgeCounter?.numberOfTracks,
      validation?.contacts,
      validation?.licensing,
      validation?.music,
      validation?.projectDetails,
      validation?.subscription,
    ]
  );

  const getTabPaddingRight = useCallback(
    (tabTitle: string) => {
      const { isError, count } = getTabErrorAndBadgeCount(tabTitle);
      let paddingRight = 0;

      switch (tabTitle) {
        case TabsTitles.ProjectDetails:
          paddingRight = isError ? 15 : 0;
          break;
        case TabsTitles.Documents:
          paddingRight = count ? 30 : 0;
          break;
        case TabsTitles.Music:
        case TabsTitles.Licenses:
        case TabsTitles.Services:
          if (count) {
            paddingRight = 30;
          }
          if (isError) {
            paddingRight += 10;
          }
          break;
        case TabsTitles.Contacts:
        case TabsTitles.Tags:
        default:
          break;
      }

      return css`
        .ant-tabs-tab {
          margin: 0;
          @media ${queries.large} {
            margin: 0 3rem 0 0;
            margin-right: calc(4rem - ${paddingRight}px);
            padding-right: ${paddingRight}px !important;
          }
        }
      `;
    },
    [getTabErrorAndBadgeCount]
  );

  const getTabs = useCallback(
    (tabTitle: string) => {
      const { isError, count } = getTabErrorAndBadgeCount(tabTitle);
      return (
        <Badge
          css={styles.badgeIcon}
          count={count}
          offset={[15, 0]}
          size="small"
        >
          <Badge
            dot={isError}
            css={styles.badgeIcon}
            offset={[count ? 35 : 5, 0]}
            size="small"
          >
            {tabTitle}
          </Badge>
        </Badge>
      );
    },
    [getTabErrorAndBadgeCount]
  );

  const subHeaderInfo = useMemo(() => {
    const status =
      allProjectStatuses.find(
        (statusOrType) =>
          statusOrType.value === projectHeaderAndSubHeader?.status
      )?.label ?? "";
    return (
      <>
        <BaseModal
          open={showCannotSwitchOrgModal}
          content={
            <Content
              mainContent={
                <p>
                  {t(
                    "ProjectsPage##You have no access to that organisation and you cannot edit this project"
                  )}
                </p>
              }
              onButtonClick={() => setShowCannotSwitchOrgModal(false)}
            />
          }
          buttons={[]}
          onClose={() => setShowCannotSwitchOrgModal(false)}
        />
        <div>
          {`${t("ProjectsPage##Projects ID")}: `}
          <span css={styles.subHeaderText}>{`${
            projectHeaderAndSubHeader?.projectId || "N/A"
          }`}</span>
        </div>
        <div>
          {`${t("ProjectsPage##Hubspot Deal ID")}: `}
          <span css={styles.subHeaderText}>{`${
            projectHeaderAndSubHeader?.dealId || "N/A"
          }`}</span>
        </div>
        <div>
          {`${t("ProjectsPage##Client")}: `}
          <span css={styles.subHeaderText}>{`${
            projectHeaderAndSubHeader?.client || "N/A"
          }`}</span>
        </div>
        <div>
          {`${t("ProjectsPage##Status")}: `}
          <span css={styles.subHeaderText}>{`${
            projectHeaderAndSubHeader?.status ? status : "N/A"
          }`}</span>
        </div>
        <div>
          {`${t("ProjectsPage##Org")}: `}
          <span css={styles.subHeaderText}>{`${
            projectHeaderAndSubHeader?.organizationName
              ? projectHeaderAndSubHeader.organizationName
              : "N/A"
          }`}</span>
        </div>
      </>
    );
  }, [
    allProjectStatuses,
    projectHeaderAndSubHeader?.client,
    projectHeaderAndSubHeader?.dealId,
    projectHeaderAndSubHeader?.organizationName,
    projectHeaderAndSubHeader?.projectId,
    projectHeaderAndSubHeader?.status,
    showCannotSwitchOrgModal,
    t,
  ]);

  const headerTitle = useMemo(() => {
    if (projectHeaderAndSubHeader?.projectName) {
      return (
        <Typography.Title css={styles.typographyTitle}>
          {projectHeaderAndSubHeader.projectName}
        </Typography.Title>
      );
    }
    if (title) {
      return (
        <Typography.Title css={styles.typographyTitle}>
          {title}
        </Typography.Title>
      );
    }

    return null;
  }, [projectHeaderAndSubHeader?.projectName, title]);

  const tabsItems = useMemo(() => {
    return tabs.map(({ tabTitle, content }) => {
      return {
        label: getTabs(tabTitle),
        key: tabTitle,
        children: <div css={styles.contentContainer}>{content}</div>,
      };
    });
  }, [getTabs, tabs]);

  return (
    <div css={styles.projectContainer}>
      <div css={styles.projectStickyHeader}>
        <div
          css={[
            isTeams && styles.projectUpperHeaderBoxShadowing,
            styles.projectUpperHeader,
          ]}
        >
          <div css={styles.upperHeaderContainer}>
            <div css={styles.headerTitleWithSaving}>
              {headerTitle}
              {isSaving && (
                <Typography.Text
                  css={styles.saving}
                  data-testid="changes-saving-text"
                >
                  {t("ProjectsPage##Saving")}
                </Typography.Text>
              )}
            </div>
          </div>
          <div css={styles.saveAndCloseWithHubspot}>
            {Boolean(projectHeaderAndSubHeader?.dealId) && (
              <Button
                type="default"
                css={styles.hubspotButton}
                aria-label="Hubspot"
                onClick={() => {
                  window.open(
                    `${config.hubspotBaseUrl}${
                      projectHeaderAndSubHeader?.dealId ?? ""
                    }`,
                    "_blank"
                  );
                }}
              >
                <div css={styles.buttonRow}>
                  <span css={styles.hubspotText}>Hubspot</span>
                  <HubspotIcon />
                </div>
              </Button>
            )}
            <div css={styles.saveAndMoreMenu}>{saveAndCloseComponent}</div>
          </div>
        </div>
        {!isTeams && (
          <>
            <Divider css={styles.headerDivider} />
            <div css={styles.subHeaderContainer}>
              {isDesktop && !title && (
                <Space css={styles.projectSubHeader} size={spacingNumber}>
                  {subHeaderInfo}
                </Space>
              )}
              {sharedWithClient}
            </div>
          </>
        )}
        {!isTeams && !isProjectInSelectedOrganisation && (
          <div css={styles.warningSubheader}>
            <span>
              {t(
                "The project is not within your selected organisation. To manage current project"
              )}
              <button
                type="button"
                css={styles.changeOrganisationButton}
                onClick={() => handleChangeOrganisation(projectOrgId as string)}
              >
                {t("click here")}
              </button>
            </span>
          </div>
        )}
      </div>
      <div
        css={[
          tabs.length === 1 && styles.projectDetailsWithoutProjectTypeActive,
          getTabPaddingRight(localActiveTab?.tabTitle ?? ""),
          styles.projectTabsHeader,
        ]}
      >
        <Tabs
          activeKey={localActiveTab?.tabTitle}
          onChange={handleTabChange}
          destroyInactiveTabPane
          tabPosition={isMobile ? "top" : "left"}
          css={styles.tabsStyles}
          items={tabsItems}
        />
      </div>
    </div>
  );
};

export default TabbedPageContainer;
