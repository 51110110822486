import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useImmer } from "use-immer";
import ButtonWithLabelAndPlusIcon from "src/components/plus-button-with-label";
import { getIsMobile, getIsTablet, useWindowSize } from "@songtradr/spa-common";
import { getI18n, useTranslation } from "react-i18next";
import useProject from "src/providers/project/hooks";
import { Collapse, Typography } from "antd";
import { FormModes } from "src/constants";
import Term from "./components/term";
import { ILicensingViewModel } from "./interfaces";
import { maptoViewModel } from "./view-model-mapper";
import mainStyles from "../../styles";
import LicensingFormHeader from "./components/term/licensing-form-header";
import TermForm from "./components/term/components/term-form";
import styles from "../services/styles";
import LicensesCollapsibleHeader from "./components/licenses-collapsible-header/LicensesCollapsibleHeader";

interface IProps {
  termsChanged: { [termId: string]: boolean };
  onChangesMade: (updatedTerms: { [termId: string]: boolean }) => void;
}

const Licensing = memo(
  ({ termsChanged, onChangesMade }: IProps): ReactElement => {
    useWindowSize();
    const { project } = useProject();
    const isTablet = getIsTablet();
    const isMobile = getIsMobile();
    const { t } = useTranslation();
    const { language } = getI18n();
    const { Panel } = Collapse;
    const [viewModel, setViewModelState] = useImmer<ILicensingViewModel>(
      maptoViewModel(project.terms, isTablet, isMobile, t, language)
    );
    const [activeTerm, setActiveTerm] = useState<string>();
    const [isTermValid, setIsTermValid] = useState<{
      [termId: string]: boolean;
    }>({});
    useEffect(() => {
      setViewModelState(
        maptoViewModel(project.terms, isTablet, isMobile, t, language)
      );
    }, [project.terms, isTablet, isMobile, t, setViewModelState, language]);

    const addAnotherTerm = useCallback(() => {
      onChangesMade({ new: true });
      setViewModelState((draft) => {
        draft.showAddNewTerm = true;
        draft.showAddAnotherButton = false;
      });
    }, [setViewModelState, onChangesMade]);

    const handleCancelTerm = useCallback(() => {
      setViewModelState((draft) => {
        draft.showAddNewTerm = false;
        draft.showAddAnotherButton = true;
      });
      setActiveTerm(undefined);
    }, [setViewModelState]);

    const handleValidationChange = useCallback(
      (termId: string, isInvalid: boolean) => {
        setIsTermValid((prev) => ({ ...prev, [termId]: isInvalid }));
      },
      []
    );
    const handleChangesMade = useCallback(
      (
        updatedTerms: { [termId: string]: boolean },
        isUpdateClicked?: boolean
      ) => {
        onChangesMade(updatedTerms);
        if (isUpdateClicked) {
          setActiveTerm(undefined);
        }
      },
      [onChangesMade]
    );

    const hasTerms = useMemo(() => (viewModel.terms ?? []).length > 0, [
      viewModel.terms,
    ]);

    const showNoTermsAdded = useMemo(
      () => !hasTerms && viewModel.showAddAnotherButton,
      [hasTerms, viewModel.showAddAnotherButton]
    );

    const showTermsCollapsableList = useMemo(
      () => hasTerms && viewModel.showAddAnotherButton,
      [hasTerms, viewModel.showAddAnotherButton]
    );

    return (
      <div id="Licensing" data-testid="licensing" css={styles.container}>
        <div css={[mainStyles.titleLabelContainer, mainStyles.switchContainer]}>
          <div>
            <Typography.Text css={mainStyles.titleLabel}>
              {t("Licenses")}
            </Typography.Text>
          </div>
          <div
            css={[
              styles.addButton,
              !viewModel.showAddAnotherButton && styles.addButtonDisabled,
            ]}
          >
            <ButtonWithLabelAndPlusIcon
              disabled={!viewModel.showAddAnotherButton}
              onClick={addAnotherTerm}
              dataTestId="add-new-row-button"
              ariaLabel="ProjectsPage##licensingSection##AddAnother"
              label="ProjectsPage##licensingSection##AddAnother"
            />
          </div>
        </div>
        {showNoTermsAdded && (
          <div>
            <Typography.Text>
              {t("ProjectsPage##licensingSection##No terms")}
            </Typography.Text>
          </div>
        )}
        {!viewModel.showAddAnotherButton && (
          <div id="add-term" data-testid="add-term-section">
            <TermForm
              mode={FormModes.Add}
              onChangesMade={onChangesMade}
              termChanged={termsChanged.new ?? false}
              onCancelTerm={handleCancelTerm}
            />
          </div>
        )}
        {showTermsCollapsableList && (
          <Collapse
            accordion
            css={mainStyles.collapseContainer}
            activeKey={activeTerm}
            destroyInactivePanel
            onChange={(e) => {
              const selectedTermKey = typeof e === "string" ? e : e?.[0];
              const updatedTerms: { [termId: string]: boolean } = {};
              if (activeTerm) {
                updatedTerms[activeTerm] = false;
              }
              if (selectedTermKey) {
                updatedTerms[selectedTermKey] = true;
              }
              onChangesMade(updatedTerms);
              setActiveTerm(e as string);
            }}
          >
            <Panel
              key="1"
              css={mainStyles.headerPanelContainer}
              showArrow={false}
              header={<LicensesCollapsibleHeader />}
            />
            {viewModel.terms.map((termVm) => {
              return (
                <Panel
                  header={
                    <LicensingFormHeader
                      isValidationInvalid={isTermValid[termVm.termDetails.id]}
                      termVm={termVm}
                      onDelete={() =>
                        onChangesMade({ [termVm.termDetails.id]: false })
                      }
                    />
                  }
                  key={termVm.termDetails.id}
                  css={mainStyles.panelContainer}
                >
                  <div css={mainStyles.editCollapseContainer}>
                    <Term
                      onValidationChange={handleValidationChange}
                      key={termVm.termDetails.id}
                      viewModel={termVm}
                      onChangesMade={handleChangesMade}
                      termsChanged={termsChanged}
                      onCancelTerm={handleCancelTerm}
                    />
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        )}
      </div>
    );
  }
);

export default Licensing;
