import React, { useCallback, useMemo } from "react";
import STLoadingLogo from "src/components/st-loading-logo";
import { Divider, Button } from "antd";
import {
  ICombinedAgenciesWithTalents,
  IContactsResults,
} from "src/pages/contact/interfaces";
import { useTranslation } from "react-i18next/";
import {
  IContactReferences,
  TalentType,
} from "src/pages/projects/project/interfaces";
import { MinTalentHubInputDropDownWidth } from "src/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "../../styles";
import AddNewCustomData from "../add-new-custom-data";

const minlistheight = 337;
const maxlistheight = 404;
const LoadingInterval = 750;

interface ITalentHubDropDownMenu {
  tags: JSX.Element[];
  searchValue: string;
  dropDownWidth?: number;
  pageNumber: number;
  isLoading: boolean;
  selectedTalents: IContactReferences[];
  totalPages: number;
  isMultiSelect: boolean;
  isWritersShareSection: boolean;
  areAgenciesIntegrated: boolean;
  isSearchTextLoading: boolean;
  isServiceProvider: boolean;
  isAddForm: boolean;
  id?: string;
  combinedAgenciesWithTalent: ICombinedAgenciesWithTalents[];
  isAddGuestWithEmail: boolean;
  onAddNewGuest: (name: string) => void;
  onAddForm: (isFormOpen: boolean) => void;
  onAddGuestWithEmail: (guest: IContactReferences) => void;
  onAddNewTalent: () => void;
  onSetPageNumber: (page: number) => void;
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onSelectedTalentsChange: (selectValues: IContactReferences[]) => void;
}

const TalentHubDropDownMenu = ({
  tags,
  searchValue,
  pageNumber,
  dropDownWidth,
  isLoading,
  totalPages,
  isMultiSelect,
  isServiceProvider,
  areAgenciesIntegrated,
  isSearchTextLoading,
  selectedTalents,
  isAddGuestWithEmail,
  combinedAgenciesWithTalent,
  isWritersShareSection,
  isAddForm,
  id,
  onAddForm,
  onAddNewTalent,
  onSetPageNumber,
  onScrollTalents,
  onAddGuestWithEmail,
  onAddNewGuest,
  onSelectedTalentsChange,
}: ITalentHubDropDownMenu): JSX.Element => {
  const { t } = useTranslation();

  const isFinalPage = useMemo(() => {
    return totalPages > pageNumber;
  }, [pageNumber, totalPages]);

  const getFilteredTalents = useCallback((talents: IContactReferences[]) => {
    return talents.filter((talent) => talent?.name);
  }, []);

  const getAllNewSelectedTalents = useCallback(
    (newTalents: IContactReferences[]) => {
      const newSelectedTalents = newTalents.reduce((prev, currTalent) => {
        const isTalentAlreadySelected = prev.find(
          (selectedTalent) => selectedTalent.id === currTalent.id
        );
        if (isTalentAlreadySelected) {
          return prev.filter(
            (selectedTalent) => selectedTalent.id !== currTalent.id
          );
        }
        return [...prev, currTalent];
      }, selectedTalents);
      return newSelectedTalents;
    },
    [selectedTalents]
  );
  const handleTalentChange = useCallback(
    (
      talents: IContactsResults[],
      isAgencySelected?: boolean,
      agency?: string,
      isTalentSelected?: boolean
    ) => {
      const newTalents: IContactReferences[] = talents.map((talent) => {
        const newTalent: IContactReferences = {
          id: talent.id,
          type: TalentType.TalentHub,
          name: talent.name,
          email: talent.email,
          agencyWritingPartnership: talent?.agencyWritingPartnership ?? "",
          groupByAgency: false,
        };
        if (talent?.ipi) {
          newTalent.ipi = talent.ipi;
        }
        return newTalent;
      });

      if (!isMultiSelect) {
        onSelectedTalentsChange(newTalents);
        return;
      }

      let allNewSelectedTalents: IContactReferences[] = [];

      if (agency) {
        if (isAgencySelected) {
          allNewSelectedTalents = selectedTalents.filter(
            (talent) => talent.agencyWritingPartnership !== agency
          );
        } else {
          const { agencyWritingPartnership } = talents[0];
          const filteredTalents = selectedTalents.filter(
            (talent) =>
              talent.agencyWritingPartnership !== agencyWritingPartnership
          );
          allNewSelectedTalents = [
            ...filteredTalents,
            ...talents.map((talent) => {
              return {
                id: talent.id,
                type: TalentType.TalentHub,
                name: talent.name,
                email: talent.email,
                agencyWritingPartnership:
                  talent?.agencyWritingPartnership ?? "",
                groupByAgency: true,
              };
            }),
          ];
        }
      } else if (isAgencySelected) {
        const { id: talentId } = talents[0];
        const filteredTalents = selectedTalents.filter(
          (talent) => talent.id !== talentId
        );
        allNewSelectedTalents = [
          ...filteredTalents,
          ...(isTalentSelected
            ? []
            : talents.map((talent) => {
                return {
                  id: talent.id,
                  type: TalentType.TalentHub,
                  name: talent.name,
                  email: talent.email,
                  agencyWritingPartnership:
                    talent?.agencyWritingPartnership ?? "",
                  groupByAgency: false,
                };
              })),
        ];
      } else {
        allNewSelectedTalents = getAllNewSelectedTalents(newTalents);
      }

      const filteredNewSelectedTalents = getFilteredTalents(
        allNewSelectedTalents
      );
      onSelectedTalentsChange(filteredNewSelectedTalents);
    },
    [
      getAllNewSelectedTalents,
      getFilteredTalents,
      isMultiSelect,
      onSelectedTalentsChange,
      selectedTalents,
    ]
  );

  const rowCount = useMemo(() => {
    return combinedAgenciesWithTalent.length;
  }, [combinedAgenciesWithTalent.length]);

  const loadNextPage = useCallback((): Promise<void> => {
    if (totalPages > pageNumber) {
      return new Promise(() => {
        setTimeout(() => {
          const newPageNumber = pageNumber + 1;
          onSetPageNumber(newPageNumber);
          onScrollTalents(
            newPageNumber,
            searchValue,
            areAgenciesIntegrated,
            isServiceProvider
          );
          // To enhance the user experience, we introduced a delay of 750ms before we dismiss the loader,
          // thereby preventing any flickering of the loader
        }, LoadingInterval);
      });
    }
    return Promise.resolve();
  }, [
    areAgenciesIntegrated,
    isServiceProvider,
    onScrollTalents,
    onSetPageNumber,
    pageNumber,
    searchValue,
    totalPages,
  ]);

  const getTalentDivElement = useCallback((talent: IContactsResults) => {
    return (
      <div css={styles.talentsOrAgencyWrapper}>
        <div css={styles.talentMenuListMainText}>{talent.name}</div>
        <div
          id="talent-hub-talents-dropdown"
          css={styles.talentMenuListSubText}
        >
          {talent.email}
        </div>
      </div>
    );
  }, []);

  const getNumberOfSelectedTalentsUnderAgency = useCallback(
    (agencyOrTalent: ICombinedAgenciesWithTalents) => {
      const numberOfTalentsSelected = selectedTalents?.reduce((prev, curr) => {
        if (
          curr.agencyWritingPartnership === agencyOrTalent.agency &&
          curr.groupByAgency
        ) {
          return prev + 1;
        }
        return prev;
      }, 0);
      return numberOfTalentsSelected;
    },
    [selectedTalents]
  );

  const handleAgencyChange = useCallback(
    (
      agencyOrTalent: ICombinedAgenciesWithTalents,
      isAgencySelected: boolean
    ) => {
      const newTalents: IContactsResults[] =
        agencyOrTalent.talents?.map((contact) => {
          return { ...contact, groupByAgency: !isAgencySelected };
        }) ?? [];

      handleTalentChange(newTalents, isAgencySelected, agencyOrTalent.agency);
    },
    [handleTalentChange]
  );

  const getIsTalentSelected = useCallback(
    (talent: IContactsResults) => {
      return !!selectedTalents?.find(
        (selectedTalent) =>
          talent.id === selectedTalent.id && !selectedTalent.groupByAgency
      );
    },
    [selectedTalents]
  );
  const getTalentsOrAgencies = useCallback(() => {
    if (isAddForm) {
      return <></>;
    }
    const content = combinedAgenciesWithTalent.map((agencyOrTalent) => {
      if (agencyOrTalent?.agency) {
        const isAgencySelected =
          getNumberOfSelectedTalentsUnderAgency(agencyOrTalent) ===
          agencyOrTalent.talents?.length;

        const allTalentsUnderAgency = agencyOrTalent.talents?.map(
          (talent, index) => {
            const isTalentSelected = getIsTalentSelected(talent);
            return (
              <div
                key={`${agencyOrTalent?.agency ?? ""}${
                  agencyOrTalent.talents?.length ?? ""
                }${agencyOrTalent?.talents?.[index].id ?? ""}`}
                css={[
                  styles.talentsUnderAgencyWrapper,
                  isTalentSelected && styles.selected,
                ]}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={() =>
                  handleTalentChange(
                    [{ ...talent, groupByAgency: false }],
                    true,
                    "",
                    isTalentSelected
                  )
                }
                data-testid={id}
              >
                <div css={styles.talentUnderAgency}>
                  {getTalentDivElement((talent as unknown) as IContactsResults)}
                </div>
              </div>
            );
          }
        );
        return (
          <>
            <div
              key={`${agencyOrTalent?.agency}${agencyOrTalent?.id ?? ""}${
                agencyOrTalent?.talents?.length ?? ""
              }`}
              css={[
                styles.talentsOrAgencyWrapper,
                isAgencySelected && styles.selected,
              ]}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={() =>
                handleAgencyChange(agencyOrTalent, isAgencySelected)
              }
              data-testid={id}
            >
              <div css={styles.talentMenuListMainText}>
                {agencyOrTalent.agency}
              </div>
              <div css={styles.talentMenuListSubText}>{`${
                agencyOrTalent?.talents?.length ?? 0
              } members`}</div>
            </div>
            {allTalentsUnderAgency}
          </>
        );
      }
      const isTalentSelected = getIsTalentSelected(
        agencyOrTalent as IContactsResults
      );
      return (
        <div
          key={`${agencyOrTalent?.id ?? ""}`}
          css={[styles.talentInputWrapper, isTalentSelected && styles.selected]}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={() =>
            handleTalentChange([
              { ...(agencyOrTalent as IContactsResults), groupByAgency: false },
            ])
          }
          data-testid={id}
        >
          {getTalentDivElement(agencyOrTalent as IContactsResults)}
        </div>
      );
    });

    return content;
  }, [
    combinedAgenciesWithTalent,
    getIsTalentSelected,
    getNumberOfSelectedTalentsUnderAgency,
    getTalentDivElement,
    handleAgencyChange,
    handleTalentChange,
    id,
    isAddForm,
  ]);

  const dropdownlistwidth = useMemo(() => {
    if (dropDownWidth && dropDownWidth > MinTalentHubInputDropDownWidth) {
      return dropDownWidth;
    }
    return MinTalentHubInputDropDownWidth;
  }, [dropDownWidth]);

  const emptyTalentsDiv = useMemo(() => {
    return isLoading || isSearchTextLoading ? (
      <div css={styles.talentHubSearchLoadWrapper}>
        <STLoadingLogo styles={styles.talentHubLoadingContainer} />
      </div>
    ) : (
      <div css={styles.emptyTalents}>{t("TalentPage##No contact found")}</div>
    );
  }, [isLoading, isSearchTextLoading, t]);

  const handleAddNewContact = useCallback(() => {
    onAddForm(false);
    onAddNewTalent();
  }, [onAddForm, onAddNewTalent]);

  return (
    <div
      css={styles.bodyAndFooterContainer}
      style={{ width: dropdownlistwidth }}
    >
      <div css={styles.contentContainer}>
        {!!tags.length && <div css={styles.bodyContentTags}>{tags}</div>}
        {(!combinedAgenciesWithTalent.length || isSearchTextLoading) &&
        !isAddForm ? (
          emptyTalentsDiv
        ) : (
          <div
            id="scrollableDiv"
            style={{
              height: tags.length ? minlistheight : maxlistheight,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InfiniteScroll
              dataLength={isAddForm ? 0 : rowCount}
              next={loadNextPage}
              endMessage={
                isLoading ? (
                  <div css={styles.talentHubSearchLoadWrapper}>
                    <STLoadingLogo styles={styles.talentHubLoadingContainer} />
                  </div>
                ) : (
                  <AddNewCustomData
                    isAddForm={isAddForm}
                    addConfirmTextButton={t("Add guest")}
                    isWritersShareSection={isWritersShareSection}
                    onAddForm={onAddForm}
                    onAddNewWithEmail={onAddGuestWithEmail}
                    onAddNew={onAddNewGuest}
                  />
                )
              }
              style={{ display: "flex", flexDirection: "column" }}
              hasMore={isAddForm ? false : isFinalPage}
              loader={
                <div css={styles.talentHubSearchLoadWrapper}>
                  <STLoadingLogo styles={styles.talentHubLoadingContainer} />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              {getTalentsOrAgencies()}
            </InfiniteScroll>
          </div>
        )}
      </div>
      {!isServiceProvider && (
        <div>
          <Divider css={styles.buttonDivider} />
          <div css={styles.dropDownFooter} style={{ flexDirection: "column" }}>
            <div>{t("Not listed in Talent Hub?")}</div>
            <div css={styles.footerButtons}>
              <div css={styles.buttonContainer}>
                <Button tabIndex={0} onClick={handleAddNewContact}>
                  {t("Add Contact")}
                </Button>
              </div>
              <div css={styles.buttonContainer}>
                <Button
                  tabIndex={0}
                  data-testid="add-talent-hub-guest"
                  onClick={() => {
                    if (isAddGuestWithEmail) {
                      onAddForm(!isAddForm);
                    } else {
                      onAddNewGuest(searchValue);
                    }
                  }}
                >
                  {t("Add Guest")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TalentHubDropDownMenu;
