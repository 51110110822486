import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${theme.colors.background.white};
    font-family: ${theme.fonts.sofiaPro};
    width: 100%;
  `,
  section: css`
    background: transparent;
    display: flex;
    flex-direction: column;
    flex: auto;
    min-height: 0;
  `,
  mainContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: auto;
  `,
  loginContainer: css`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    align-self: center;
    overflow: hidden;
    margin: 20px auto;
    margin-top: 0;
    min-height: 700px;
    width: 90vw;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.secondary.lightGray};
    box-shadow: 0 4px 48px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 3rem;
  `,
  logo: css`
    margin-bottom: 5rem;
    margin-top: 3rem;
  `,
  row: css`
    width: 100%;
  `,
  inputContainer: css`
    margin-top: 2rem;
  `,
  input: css`
    border-color: ${theme.colors.black} !important;
    font-family: sofia-pro, sans-serif !important;
  `,
  subText: css`
    color: ${theme.colors.black};
    font-size: 14px !important;
    font-weight: 600;
    margin-top: -8px;
    margin-bottom: 48px;
    text-align: right;
    cursor: pointer;
    width: 110px;
    float: right;
  `,
  forgotPassword: css`
    color: ${theme.colors.secondary.eiffelBlue} !important;
    font-size: 16px !important;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 600;
    margin-top: 2rem;
    cursor: pointer;
    text-align: center;
  `,
  backToSignin: css`
    color: ${theme.colors.secondary.eiffelBlue} !important;
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 2rem;
    cursor: pointer;
    text-align: center;
  `,
  label: css`
    font-size: 0.9rem;
    padding-bottom: 0.3rem;
  `,
  title: css`
    font-size: 1.6rem !important;
    font-weight: 700;
  `,
  button: css`
    border-radius: 0.2rem;
    background-color: ${theme.colors.secondary.eiffelBlue};
    border: 0;
    color: ${theme.colors.white};
    width: 100%;
    height: 50px;
    font-size: 1rem;
    cursor: pointer;

    span {
      color: ${theme.colors.white};
    }

    :hover {
      background-color: ${theme.colors.secondary.eiffelBlue};
      color: ${theme.colors.white};
    }

    :focus {
      background-color: ${theme.colors.secondary.eiffelBlue};
      color: ${theme.colors.white};
    }
  `,
  resetButton: css`
    border-radius: 0.2rem;
    background-color: ${theme.colors.secondary.eiffelBlue};
    border: 0;
    color: ${theme.colors.white};
    width: 100%;
    height: 50px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 50px;

    span {
      color: ${theme.colors.white};
    }

    :hover {
      background-color: ${theme.colors.secondary.eiffelBlue};
      color: ${theme.colors.white};
    }

    :focus {
      background-color: ${theme.colors.secondary.eiffelBlue};
      color: ${theme.colors.white};
    }
  `,
  customError: css`
    margin: 0 0 11px 0;
    padding: 15px 0 15px 17.5px !important;
    box-sizing: border-box !important;
    width: 100%;
    background-color: ${theme.colors.secondary.lightAlertRed};
    display: flex;
  `,
  customErrorIcon: css`
    background-color: ${theme.colors.secondary.alertRed} !important;
    border-radius: 50%;
    margin-right: 10px;
    color: white;
  `,
  customInfo: css`
    padding: 15px 15px 15px 17.5px !important;
    box-sizing: border-box !important;
    width: 100%;
    margin-bottom: 40px;
    background-color: ${theme.colors.secondary.infoBlueLight};
    display: flex;
  `,
  customInfoIcon: css`
    background-color: ${theme.colors.secondary.infoBlue} !important;
    border-radius: 50%;
    margin-right: 10px;
    color: white;
  `,
  customReset: css`
    margin: 0 0 11px 0;
    padding: 15px 0 15px 17.5px !important;
    box-sizing: border-box !important;
    width: 100%;
    background-color: ${theme.colors.secondary.successGreen};
    display: flex;
  `,
  customResetIcon: css`
    background-color: ${theme.colors.secondary.successGreen} !important;
    border-radius: 50%;
    margin-right: 10px;
    color: white;
  `,
  customResetMsg: css`
    font-weight: 700;
    line-height: 18px;
    margin-left: 5px;
  `,
  customErrorMsg: css`
    font-weight: 700;
    line-height: 18px;
    margin-left: 5px;
  `,
  graphicLeft: css`
    position: absolute;
    right: 0;
    top: 75px;
  `,
  graphicRight: css`
    position: absolute;
    bottom: 75px;
  `,
};
