import {
  IFeeSplitDetailState,
  INameLinkState,
  IProjectAttachmentsUpdate,
  IProjectForm,
  IProjectMusicSection,
  IProjectPublishingTriggeredBy,
  IProjectServicesUpdate,
  IProjectTermsUpdate,
  IProjectTracksUpdate,
} from "src/pages/projects/project/interfaces";
import { createContext } from "react";
import { PickKeys } from "ts-essentials";
import { IFinalTrackOwnership } from "src/pages/projects/project/components/music/components/final-track-ownership/interfaces";
import { IOption } from "src/pages/projects/project/components/account-information/types";

export interface IProjectProviderContext {
  isLoading: boolean;
  projectFound: boolean;
  allOrganizations: IOption[];
  publishingTeamEmails: string[];
  project: IProjectForm;
  storeProject: (project: IProjectForm) => void;
  storeAttachments: (attachmentUpdate: IProjectAttachmentsUpdate) => void;
  addNameLinkRowForArray: (
    arrayName: PickKeys<IProjectMusicSection, Array<INameLinkState>>
  ) => void;
  addFeeRowForArray: (
    arrayName: PickKeys<IFinalTrackOwnership, Array<IFeeSplitDetailState>>
  ) => void;
  storeServices: (servicesUpdate: IProjectServicesUpdate) => void;
  storeTracks: (tracksUpdate: IProjectTracksUpdate) => void;
  storeTerms: (termsUpdate: IProjectTermsUpdate) => void;
  resetProjectState: () => void;
  storePublishingTriggered: (
    publishingTriggeredBy: IProjectPublishingTriggeredBy
  ) => void;
}

export default createContext<IProjectProviderContext>({
  isLoading: true,
  projectFound: true,
  allOrganizations: [],
  storeProject: () => {},
  publishingTeamEmails: [],
  storeAttachments: () => {},
  addNameLinkRowForArray: () => {},
  addFeeRowForArray: () => {},
  storeServices: () => {},
  storeTracks: () => {},
  storeTerms: () => {},
  resetProjectState: () => {},
  storePublishingTriggered: () => {},
  project: {
    name: "",
    campaignName: "",
    crmDealId: "",
    id: "",
    projectCloseDate: "",
    leads: [],
    leadsVersion: 0,
    internalCreativeTeamEngaged: false,
    creativeAgencyInvolved: false,
    creativeAgencyId: 0,
    jobListingDate: "",
    isExtension: false,
    relatedProjects: [],
    relatedProjectVersions: 0,
    attachments: [],
    purchaseOrderAttachments: [],
    estimatedProjectClosedDate: "",
    estimateAttachments: [],
    licenseAttachments: [],
    invoiceAttachments: [],
    miscAttachments: [],
    assetAttachments: [],
    testResultAssets: [],
    clientsVersion: 0,
    sequenceNo: 0,
    contacts: [],
    curators: [],
    originators: [],
    contactsVersion: 0,
    costAvoidance: { currency: "USD", value: 0 },
    initialMusicQuote: { currency: "USD", value: 0 },
    music: {
      finalTrack: {
        masterFee: { currency: "", value: 0 },
        masterFeeSplitsVersion: 0,
        masterFeeSplits: [],
        publisherFee: { currency: "", value: 0 },
        publisherFeeSplitsVersion: 0,
        publisherFeeSplits: [],
        finalTrackLinksVersion: 0,
        finalTrackLinks: [],
        finalTrackAttachments: [],
        writerFee: { currency: "", value: 0 },
        writerFeeSplitsVersion: 0,
        writerFeeSplits: [],
      },
      musicAssetsVersion: 0,
      musicAssets: [],
      finalVideoVersion: 0,
      finalVideo: [],
    },
    version: 0,
    terms: [],
    services: [],
    client: {},
    libraryTracks: [],
    contractStartDate: "",
    contractEndDate: "",
    description: "",
  },
});
