import React, { useEffect } from "react";

export interface IUseOutsideClickProps {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  onOutsideClick: (event: MouseEvent) => void;
}

export const useOutsideClick = (
  ref: IUseOutsideClickProps["ref"],
  onOutsideClick: IUseOutsideClickProps["onOutsideClick"]
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick(event);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onOutsideClick, ref]);
};
