import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  footer: css`
    background: ${theme.colors.black};
    padding-left: 1.6875rem;
    @media ${queries.medium} {
      margin: 0 auto;
    }
    padding-top: 4.125rem;
    padding-bottom: 4.0625rem;
    align-items: center;
    @media ${queries.large} {
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  `,
  logo: css`
    @media ${queries.large} {
      margin-left: none;
    }
  `,
  footerColumn: css`
    article {
      color: ${theme.colors.white};
    }
    @media ${queries.large} {
      display: none;
    }
  `,
  label: css`
    color: ${theme.colors.white};
    font-size: 0.75rem;
    line-height: 0.9375rem;
    margin-bottom: 0.3125rem;
    padding-top: 1.8125rem;
    opacity: 0.7;
    @media ${queries.medium} {
      padding-top: 0;
      opacity: 1;
    }
  `,
  link: css`
    font-size: 1rem;
    line-height: 1.5rem;
  `,
};
