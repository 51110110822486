import { ITalentHubTypes } from "src/api/talent-hub/interfaces";
import { IOption } from "src/pages/projects/project/components/account-information/types";

export enum GlobalContextActions {
  STORE_STATUSES = "STORE_STATUSES",
  STORE_PROJECT_TYPES = "STORE_PROJECT_TYPES",
  STORE_PROJECT_REGIONS = "STORE_PROJECT_REGIONS",
  STORE_TALENT_HUB_TYPES = "STORE_TALENT_HUB_TYPES",
  STORE_TALENT_HUB_ETAG = "STORE_TALENT_HUB_ETAG",
  SET_LOADING = "SET_LOADING",
}

interface IStoreStatusesRegionsOrTypes {
  type:
    | GlobalContextActions.STORE_PROJECT_REGIONS
    | GlobalContextActions.STORE_STATUSES
    | GlobalContextActions.STORE_PROJECT_TYPES;
  value: IOption[];
}

interface ISetLoading {
  type: GlobalContextActions.SET_LOADING;
  value: boolean;
}

interface IStoreTalentHubTypes {
  type: GlobalContextActions.STORE_TALENT_HUB_TYPES;
  value: ITalentHubTypes;
}

export type GlobalAction =
  | IStoreStatusesRegionsOrTypes
  | IStoreTalentHubTypes
  | ISetLoading;

export default interface IGlobalContextProvider {
  isGlobalDataLoading: boolean;
  allProjectTypes: IOption[];
  allProjectStatuses: IOption[];
  allProjectRegions: IOption[];
  talentHubTypes: ITalentHubTypes;
}
