import { Typography, Input } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "@songtradr/spa-common";
import { IProjectProductAndAccountInformationData } from "src/pages/projects/project/interfaces";

import style from "../../../../../styles";

interface IProps {
  projectProductAndAccountInformationData: IProjectProductAndAccountInformationData;
}

const PrimaryContactRow = ({
  projectProductAndAccountInformationData,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  return (
    <div>
      <div css={style.titleLabelContainer}>
        <Typography.Text css={style.titleLabel}>
          Primary Contact
        </Typography.Text>
      </div>
      <div
        css={[
          isMobile ? style.mobileSectionContainer : style.clientContainer,
          style.lastOfSectionBottom,
        ]}
      >
        <div>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##Holding company")}
            </Typography.Text>
          </div>

          <Input
            name="holdingCompany"
            disabled
            value={
              projectProductAndAccountInformationData?.client?.holdingCompany
                ?.name ?? ""
            }
            data-testid="holding-company-input"
          />
        </div>

        <div>
          <div css={style.basicInputContainer} className="primary-contact">
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##Contact email")}
            </Typography.Text>
          </div>
          <Input
            name="contactEmail"
            disabled
            value={
              projectProductAndAccountInformationData?.clientContact?.email ??
              ""
            }
            data-testid="client-contact-email-input"
          />
        </div>
      </div>
    </div>
  );
};

export default PrimaryContactRow;
