import { ReactElement } from "react";
import IIconComponentProps from "../../interfaces";

const FilterAttributes = ({
  height = "24",
  width = "24",
}: IIconComponentProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.514 22.8264H17.812C18.0499 22.8264 18.2446 22.6317 18.2446 22.3937V14.4327C18.2446 14.1947 18.0499 14 17.812 14H16.514C16.276 14 16.0813 14.1947 16.0813 14.4327V22.3937C16.0813 22.6317 16.276 22.8264 16.514 22.8264ZM19.6833 24.8707H14.6319C14.3939 24.8707 14.1992 25.0654 14.1992 25.3034V26.6014C14.1992 26.8394 14.3939 27.0341 14.6319 27.0341H16.0705V33.5673C16.0705 33.8053 16.2652 34 16.5032 34H17.8012C18.0391 34 18.2338 33.8053 18.2338 33.5673V27.0341H19.6724C19.9104 27.0341 20.1051 26.8394 20.1051 26.6014V25.3034C20.1051 25.0654 19.9104 24.8707 19.6724 24.8707H19.6833ZM23.3609 17.4181H24.6589C24.8969 17.4181 25.0916 17.2234 25.0916 16.9854V14.4327C25.0916 14.1947 24.8969 14 24.6589 14H23.3609C23.123 14 22.9283 14.1947 22.9283 14.4327V16.9854C22.9283 17.2234 23.123 17.4181 23.3609 17.4181ZM26.5302 19.4624H21.4788C21.2409 19.4624 21.0462 19.6571 21.0462 19.8951V21.1931C21.0462 21.431 21.2409 21.6257 21.4788 21.6257H22.9174V33.5673C22.9174 33.8053 23.1121 34 23.3501 34H24.6481C24.8861 34 25.0808 33.8053 25.0808 33.5673V21.6257H26.5194C26.7574 21.6257 26.9521 21.431 26.9521 21.1931V19.8951C26.9521 19.6571 26.7574 19.4624 26.5194 19.4624H26.5302ZM30.2079 26.0498H31.5059C31.7438 26.0498 31.9385 25.8551 31.9385 25.6171V14.4327C31.9385 14.1947 31.7438 14 31.5059 14H30.2079C29.9699 14 29.7752 14.1947 29.7752 14.4327V25.6171C29.7752 25.8551 29.9699 26.0498 30.2079 26.0498ZM33.3772 28.0941H28.3258C28.0878 28.0941 27.8931 28.2888 27.8931 28.5268V29.8248C27.8931 30.0627 28.0878 30.2574 28.3258 30.2574H29.7644V33.5673C29.7644 33.8053 29.9591 34 30.1971 34H31.4951C31.733 34 31.9277 33.8053 31.9277 33.5673V30.2574H33.3663C33.6043 30.2574 33.799 30.0627 33.799 29.8248V28.5268C33.799 28.2888 33.6043 28.0941 33.3663 28.0941H33.3772Z"
        fill="#111111"
      />
    </svg>
  );
};

export default FilterAttributes;
