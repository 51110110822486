import { CheckOutlined } from "@ant-design/icons";
import React, { ReactElement, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  SortOrder,
  ProjectSortType,
  IProjectTableSort,
} from "../../interfaces";
import style from "../../styles";

interface IProps {
  handleSort: ({ sortBy, order }: IProjectTableSort) => void;
  sortOptions: IProjectTableSort;
}

const SortProjectsDrawer = ({
  handleSort,
  sortOptions,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const drawerLabels = new Map([
    [ProjectSortType.lastUpdated, t("ProjectsPage##Last updated")],
    [ProjectSortType.name, t("ProjectsPage##Project name")],
    [ProjectSortType.status, t("ProjectsPage##Project status")],
    [ProjectSortType.contractStartDate, t("ProjectsPage##Contract start")],
    [ProjectSortType.contractEndDate, t("ProjectsPage##Contract end")],
  ]);

  return (
    <div css={style.sortProjectsDrawer} data-testid="sort-projects-drawer">
      <ul>
        {Object.keys(ProjectSortType).map((key) => {
          if (key === "termEndDate") {
            return null;
          }

          return (
            <Fragment key={key}>
              <li key={`${key}-asc`}>
                <button
                  type="button"
                  onClick={() =>
                    handleSort({
                      sortBy: key as ProjectSortType,
                      order: SortOrder.ASC,
                    })
                  }
                >
                  {drawerLabels.get(key as ProjectSortType)}{" "}
                  {key === "name" || key === "status" ? "(A-Z)" : "(ascending)"}
                  {sortOptions.sortBy === key &&
                    sortOptions.order === SortOrder.ASC && (
                      <CheckOutlined css={style.checkIcon} />
                    )}
                </button>
              </li>
              <li key={`${key}-desc`}>
                <button
                  type="button"
                  onClick={() =>
                    handleSort({
                      sortBy: key as ProjectSortType,
                      order: SortOrder.DESC,
                    })
                  }
                >
                  {drawerLabels.get(key as ProjectSortType)}{" "}
                  {key === "name" || key === "status"
                    ? "(Z-A)"
                    : "(descending)"}
                  {sortOptions.sortBy === key &&
                    sortOptions.order === SortOrder.DESC && (
                      <CheckOutlined css={style.checkIcon} />
                    )}
                </button>
              </li>
            </Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default SortProjectsDrawer;
