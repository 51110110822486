import { ReactElement } from "react";
import { IComponentIconProps } from "./interfaces";

const Mark = (): ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0046 22.4653L8.14735 17.6081C7.95088 17.4116 7.95088 17.0922 8.14735 16.8957L10.2843 14.7588C10.4808 14.5623 10.8002 14.5623 10.9966 14.7588L13.0046 16.7668C13.2011 16.9632 13.5205 16.9632 13.7169 16.7668L21.0034 9.48036C21.1998 9.28389 21.5192 9.28389 21.7157 9.48036L23.8526 11.6173C24.0491 11.8138 24.0491 12.1332 23.8526 12.3296L13.7169 22.4653C13.5205 22.6618 13.2011 22.6618 13.0046 22.4653Z"
        fill="#77ACBA"
      />
    </svg>
  );
};
export default Mark;
