import { Col, Row, Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import insertSpaces from "src/utils/string";
import DateStringYYYYMMDDToMoment from "src/utils/time";
import styles from "./styles";

interface IProps {
  productName?: string;
  productCategory?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  projectType?: string;
  musicType?: string;
  description?: string;
}

const Overview = ({
  productName,
  productCategory,
  contractStartDate,
  contractEndDate,
  projectType,
  musicType,
  description,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={styles.overviewContainer}>
      <div css={styles.verticalTitleContainer}>
        <Typography.Title css={styles.verticalTitle}>
          {t("ProjectsPage##Overview")}
        </Typography.Title>
      </div>
      <div css={styles.overviewContentContainer}>
        <Typography.Title css={styles.sectionTitle}>
          {t("ProjectsPage##Project details")}
        </Typography.Title>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} css={styles.overviewCol}>
            <Typography.Text css={styles.overviewTitle}>
              {t("ProjectsPage##Product name")}
            </Typography.Text>
            <Typography.Text css={styles.overviewText}>
              {productName}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} css={styles.overviewCol}>
            <Typography.Text css={styles.overviewTitle}>
              {t("ProjectsPage##Category")}
            </Typography.Text>
            <Typography.Text css={styles.overviewText}>
              {productCategory}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} css={styles.overviewCol}>
            <Typography.Text css={styles.overviewTitle}>
              {t("ProjectsPage##Contract start")}
            </Typography.Text>
            <Typography.Text css={styles.overviewText}>
              {DateStringYYYYMMDDToMoment(contractStartDate)
                .format("MMM D, YYYY")
                .toString()}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} css={styles.overviewCol}>
            <Typography.Text css={styles.overviewTitle}>
              {t("ProjectsPage##Project type")}
            </Typography.Text>
            <Typography.Text css={styles.overviewText}>
              {insertSpaces(projectType)}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} css={styles.overviewCol}>
            <Typography.Text css={styles.overviewTitle}>
              {t("ProjectsPage##Music type")}
            </Typography.Text>
            <Typography.Text css={styles.overviewText}>
              {insertSpaces(musicType)}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} css={styles.overviewCol}>
            <Typography.Text css={styles.overviewTitle}>
              {t("ProjectsPage##Contract end")}
            </Typography.Text>
            <Typography.Text css={styles.overviewText}>
              {DateStringYYYYMMDDToMoment(contractEndDate)
                .format("MMM D, YYYY")
                .toString()}
            </Typography.Text>
          </Col>
          <hr css={styles.horizontalRule} />
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={24}
            css={styles.overviewDescriptionCol}
          >
            <Typography.Text css={styles.overviewTitle}>
              {t("ProjectsPage##Project Description/Brief")}
            </Typography.Text>
            <Typography.Text css={styles.overviewText}>
              {description}
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Overview;
