import {
  getIsDesktop,
  getIsMobile,
  getIsTablet,
  useWindowSize,
} from "@songtradr/spa-common/lib/utils";
import { Divider, Form, Typography } from "antd";
import ReactSelect from "react-select";
import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useAuth from "src/auth/use-auth";
import MobileDrawer from "src/components/mobile-drawer";
import ConfirmationDrawer from "src/components/mobile-drawer/confirmation-drawer";
import Content from "src/components/modals/confirmation-modal/content";
import BaseModal from "src/components/modals/base-modal";
import { IConfirmContentProps } from "src/components/interfaces";
import getServicedOrgs from "src/api/organisation/get-orgs-by-serviceOrgId";
import getOrganisationMembers from "src/api/organisation-members/get-org-members";
import projectValidation from "src/utils/projectValidation";
import { IProjectProductAndAccountInformationData } from "../../interfaces";
import style from "../../styles";
import { customStyles } from "../details/styles";
import {
  IHoldingCompany,
  IMember,
  IOption,
  IClient,
} from "../account-information/types";

type ProjectProductAndAccountInformationField =
  | "regionalBrand"
  | "productDivision"
  | "globalBrand"
  | "client"
  | "clientContact"
  | "product";

interface IProps {
  projectProductAndAccountInformationData: IProjectProductAndAccountInformationData;
  updateProjectProductAndAccountInformationData: (
    projectProductAndAccountInformationData: IProjectProductAndAccountInformationData
  ) => void;
  setHoldingCompany: (data: IHoldingCompany) => void;
}

const LibraryTrackAccountInformation = memo(
  ({
    projectProductAndAccountInformationData,
    updateProjectProductAndAccountInformationData,
    setHoldingCompany,
  }: IProps): ReactElement => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { fetchWrapper, organisationId } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [servicedOrgOptions, setServicedOrgOptions] = useState<IClient[]>([]);
    const [clientOptions, setClientOptions] = useState<IOption[]>([]);
    const [client, setClient] = useState(
      projectProductAndAccountInformationData.client
        ? {
            label: projectProductAndAccountInformationData.client.name,
            value: projectProductAndAccountInformationData.client.id,
          }
        : undefined
    );
    const [selectedClient, setSelectedClient] = useState<IClient>();
    const [formHoldingCompany, setFormHoldingCompany] = useState<
      IHoldingCompany | undefined
    >(
      projectProductAndAccountInformationData.client?.holdingCompany
        ? {
            id:
              projectProductAndAccountInformationData?.client?.holdingCompany
                .id,
            name:
              projectProductAndAccountInformationData?.client?.holdingCompany
                .name,
          }
        : undefined
    );
    const [contactOptions, setContactOptions] = useState<IOption[]>([]);
    const [contact, setContact] = useState<IOption | undefined>(
      projectProductAndAccountInformationData.clientContact
        ? {
            label: projectProductAndAccountInformationData.clientContact.name,
            value: projectProductAndAccountInformationData.clientContact.id,
          }
        : undefined
    );
    const [clientMembers, setClientMembers] = useState<IMember[]>([]);

    useWindowSize();

    const isMobile = getIsMobile();
    const isTablet = getIsTablet();
    const isDesktop = getIsDesktop();

    useEffect(() => {
      const fetchServicedOrgs = async () => {
        if (organisationId) {
          const clientOrgs = await fetchWrapper(
            getServicedOrgs,
            organisationId
          );
          if (clientOrgs) {
            const clients = Object.values(clientOrgs).map((org) => {
              return {
                value: org.id,
                label: org.name,
                holdingCompany: {
                  name: org.holdingCompany?.name,
                  id: org.holdingCompany?.id,
                },
              };
            });
            setServicedOrgOptions(clients);
          }
        }
      };
      void fetchServicedOrgs();
    }, [fetchWrapper, organisationId]);

    const getHoldingCompany = useCallback(
      (value: string) => {
        const clientItem = servicedOrgOptions.find(
          (option) => option.value === value
        );
        setFormHoldingCompany(clientItem?.holdingCompany);
        setHoldingCompany({
          id: clientItem?.holdingCompany?.id,
          name: clientItem?.holdingCompany?.name,
        });
      },
      [servicedOrgOptions, setHoldingCompany]
    );

    useEffect(() => {
      if (servicedOrgOptions) {
        const clients = Object.values(servicedOrgOptions)
          .map((org) => {
            return {
              value: org.value,
              label: org.label,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label));
        setClientOptions(clients);
      }
    }, [servicedOrgOptions]);

    useEffect(() => {
      void (async () => {
        if (client) {
          const selectedOrg = servicedOrgOptions.find(
            (org) => org.value === client?.value
          );
          if (selectedOrg) {
            const membersResponse = await fetchWrapper(
              getOrganisationMembers,
              selectedOrg.value
            );
            setClientMembers(membersResponse);
            const contacts = membersResponse
              .map((member) => {
                return {
                  value: member.id,
                  label: `${member.firstName} ${member.lastName}`,
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label));
            setContactOptions(contacts);
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, servicedOrgOptions]);

    const handleClientDropdownChange = useCallback(
      (field: string, data: IOption) => {
        const { label, value } = data;
        const holdingCompany = servicedOrgOptions.find(
          (option) => option.value === data.value
        );
        setSelectedClient({
          label: data.label,
          value: data.value,
          holdingCompany: {
            id: holdingCompany?.holdingCompany?.id,
            name: holdingCompany?.holdingCompany?.name,
          },
        });

        if (
          (holdingCompany?.holdingCompany?.id !== formHoldingCompany?.id &&
            formHoldingCompany) ||
          (contact?.value && contact.value !== undefined)
        ) {
          setIsModalOpen(true);
        } else if (
          projectProductAndAccountInformationData[
            field as ProjectProductAndAccountInformationField
          ] !== data.value
        ) {
          updateProjectProductAndAccountInformationData({
            [field]: {
              id: value,
              name: label,
            },
          });
          getHoldingCompany(value);
          setClient(data);
        }
      },
      [
        contact,
        formHoldingCompany,
        getHoldingCompany,
        projectProductAndAccountInformationData,
        servicedOrgOptions,
        updateProjectProductAndAccountInformationData,
      ]
    );

    const handleContactDropdownChange = useCallback(
      (field: string, data: IOption) => {
        setContact({ label: data.label, value: data.value });
        form.setFieldsValue({
          clientContact: data,
        });

        const contactDetails = clientMembers.find(
          (member) => member.id === data.value
        );
        if (
          projectProductAndAccountInformationData[
            field as ProjectProductAndAccountInformationField
          ] !== data.value &&
          contactDetails
        ) {
          const updatedContact = {
            id: contactDetails.id,
            name: `${contactDetails.firstName} ${contactDetails.lastName}`,
            email: contactDetails.email,
          };
          updateProjectProductAndAccountInformationData({
            [field]: updatedContact,
          });
        }
      },
      [
        clientMembers,
        projectProductAndAccountInformationData,
        updateProjectProductAndAccountInformationData,
        form,
      ]
    );

    const handleConfirmClientClick = useCallback(async () => {
      if (selectedClient !== undefined) {
        updateProjectProductAndAccountInformationData({
          client: {
            id: selectedClient.value,
            name: selectedClient.label,
            holdingCompany: {
              id: selectedClient?.holdingCompany?.id,
              name: selectedClient?.holdingCompany?.name,
            },
          },
          clientContact: {
            id: "",
            name: "",
            email: "",
          },
          globalBrand: {
            id: "",
            name: "",
          },
          regionalBrand: {
            id: "",
            name: "",
          },
          productDivision: {
            id: "",
            name: "",
          },
          product: "",
        });

        setIsModalOpen(false);

        getHoldingCompany(selectedClient.value);
        setClient(selectedClient);
        setContact({ label: "", value: "" });
        form.setFieldsValue({
          client: selectedClient,
          clientContact: { label: "", value: "" },
        });
        await form.validateFields(["client", "clientContact"]);
      }
    }, [
      getHoldingCompany,
      selectedClient,
      updateProjectProductAndAccountInformationData,
      form,
    ]);

    const confirmClientChangeContentProps: IConfirmContentProps = useMemo(
      () => ({
        confirmAction: handleConfirmClientClick,
        onClose: () => setIsModalOpen(false),
        primaryButtonLabel: t("Ok"),
        secondaryButtonLabel: t("Cancel"),
        mainContent: (
          <p data-testid="confirmation-content">
            {t(
              "ProjectsPage##Changing clients will clear any information already added in the Product Information section"
            )}
          </p>
        ),
      }),
      [handleConfirmClientClick, t]
    );

    useEffect(() => {
      void form.validateFields(["client", "clientContact"]);
    }, [form]);

    return (
      <>
        <Divider css={style.sectionDivider} />
        <div css={style.sectionContainer}>
          <Form
            form={form}
            initialValues={{
              client: client as IOption,
              clientContact: contact as IOption,
            }}
            scrollToFirstError
          >
            <div id="Details">
              <div
                css={[
                  isMobile
                    ? style.mobileSectionContainer
                    : style.clientContainer,
                  style.lastOfSectionBottom,
                ]}
              >
                <div>
                  <div css={[style.basicInputContainer, style.basicInput]}>
                    <Typography.Text css={style.basicInputLabel}>
                      {t("ProjectsPage##Client")}
                    </Typography.Text>
                  </div>
                  <Form.Item
                    name="client"
                    css={style.formItem}
                    rules={[
                      projectValidation.yupLibraryTrackAdditionalDetailsSync,
                    ]}
                  >
                    <ReactSelect
                      placeholder=""
                      isSearchable
                      name="client"
                      options={clientOptions}
                      onChange={(data) =>
                        handleClientDropdownChange("client", data as IOption)
                      }
                      styles={customStyles}
                      id="client-dropdown"
                      data-testid="client-dropdown"
                    />
                  </Form.Item>
                </div>
                <div>
                  <div css={[style.basicInputContainer, style.basicInput]}>
                    <Typography.Text css={style.basicInputLabel}>
                      {t("ProjectsPage##Contact details")}
                    </Typography.Text>
                  </div>
                  <Form.Item
                    css={style.formItem}
                    name="clientContact"
                    rules={[
                      projectValidation.yupLibraryTrackAdditionalDetailsSync,
                    ]}
                  >
                    <ReactSelect
                      isClearable={false}
                      isSearchable
                      name="contact"
                      placeholder=""
                      options={contactOptions}
                      onChange={(data) =>
                        handleContactDropdownChange(
                          "clientContact",
                          data as IOption
                        )
                      }
                      styles={customStyles}
                      id="client-contact-dropdown"
                      data-testid="client-contact-dropdown"
                    />
                  </Form.Item>
                </div>
                {isDesktop && (
                  <BaseModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    content={<Content {...confirmClientChangeContentProps} />}
                    footer={null}
                  />
                )}
                {(isMobile || isTablet) && (
                  <MobileDrawer
                    data-testid="mobile-drawer"
                    placement="bottom"
                    onClose={() => setIsModalOpen(false)}
                    open={isModalOpen}
                    key="bottom"
                    closable={false}
                    destroyOnClose
                    css={style.drawer}
                  >
                    <ConfirmationDrawer {...confirmClientChangeContentProps} />
                  </MobileDrawer>
                )}
              </div>
            </div>
          </Form>
        </div>
      </>
    );
  }
);

export default LibraryTrackAccountInformation;
