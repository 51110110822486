import axios from "axios";
import { IContactsFilters } from "src/pages/talent-hub/interfaces";
import {
  getAuthHeader,
  getJsonContentHeader,
  getAxiosOptions,
} from "../../headers";
import getContactsFiltersUrl from "../../talent-url-helper";

const getContactsFilters = async (
  accessToken: string,
  organisationId: string,
  groupId?: string,
  isUserGroup?: boolean
): Promise<IContactsFilters | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios
    .get(getContactsFiltersUrl(organisationId, groupId, isUserGroup), options)
    .then(({ data }) => {
      return data as IContactsFilters;
    });
};

export default getContactsFilters;
