import axios from "axios";
import { getAuthHeader, getJsonContentHeader } from "src/api/headers";
import config from "src/config";
import { IGetTalentHubContactAttachmentResponse } from "../interfaces";

async function getDownloadFileUrl(
  attachmentId: string,
  contactId: string,
  accessToken: string,
  orgId: string
): Promise<IGetTalentHubContactAttachmentResponse> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  const response = await axios.get(
    `${config.projectManagementService.url}/talent-hub/talent-contact/${contactId}/attachments/${attachmentId}/download?orgId=${orgId}`,
    options
  );

  return response.data as IGetTalentHubContactAttachmentResponse;
}

export default getDownloadFileUrl;
