import { Typography, Form, Input } from "antd";
import React, { ReactElement } from "react";
import projectValidation from "src/utils/projectValidation";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "@songtradr/spa-common";
import { IProjectDetailsData } from "src/pages/projects/project/interfaces";
import style from "../../../../styles";

interface IProps {
  projectDetailsData: IProjectDetailsData;
  onUpdate: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const ProjectAndCampaignNameWithDealIdRow = ({
  projectDetailsData,
  onUpdate,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  return (
    <div css={isMobile ? style.mobileSectionContainer : style.clientContainer}>
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##Project name")}
          </Typography.Text>
        </div>
        <Form.Item
          css={style.formItem}
          name="name"
          rules={[projectValidation.yupDetailsSchemaSync]}
        >
          <Input name="name" onChange={onUpdate} />
        </Form.Item>
      </div>
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##Campaign name")}
          </Typography.Text>
        </div>
        <Form.Item css={style.formItem}>
          <Input
            name="campaignName"
            onChange={onUpdate}
            value={projectDetailsData.campaignName}
          />
        </Form.Item>
      </div>
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##Hubspot Deal ID #")}
          </Typography.Text>
        </div>
        <Form.Item css={style.formItem}>
          <Input
            name="crmDealId"
            onChange={onUpdate}
            value={projectDetailsData.crmDealId}
            data-testid="crm-deal-id-textbox"
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default ProjectAndCampaignNameWithDealIdRow;
