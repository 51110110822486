import axios from "axios";
import { IOrganisationInvite } from "src/interfaces/organisation";
import getJsonContentHeader from "../../headers/get-json-content-header";
import { acceptOrganisationInviteUrl } from "../../organisation-invites-url-helper";

async function acceptOrganisationInvite(
  organisationId: string,
  organisationInviteId: string,
  request: IOrganisationInvite
): Promise<void> {
  const options = {
    headers: { ...getJsonContentHeader() },
  };

  return axios.post(
    acceptOrganisationInviteUrl(organisationId, organisationInviteId),
    request,
    options
  );
}

export default acceptOrganisationInvite;
