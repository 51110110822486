import { ReactElement } from "react";
import { IComponentIconProps } from "./interfaces";

const AddGuest = (): ReactElement => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.0007"
        cy="10.9999"
        r="9.66667"
        stroke="red"
        strokeWidth="2"
      />
      <path
        d="M16.3605 10.2267H12.0472C11.9005 10.2267 11.7739 10.1067 11.7739 9.95337V5.64003C11.7739 5.49337 11.6539 5.3667 11.5005 5.3667H10.4939C10.3472 5.3667 10.2205 5.4867 10.2205 5.64003V9.95337C10.2205 10.1 10.1005 10.2267 9.94719 10.2267H5.64052C5.49385 10.2267 5.36719 10.3467 5.36719 10.5V11.5067C5.36719 11.6534 5.48719 11.78 5.64052 11.78H9.95385C10.1005 11.78 10.2272 11.9 10.2272 12.0534V16.3667C10.2272 16.5134 10.3472 16.64 10.5005 16.64H11.5072C11.6539 16.64 11.7805 16.52 11.7805 16.3667V12.0534C11.7805 11.9067 11.9005 11.78 12.0539 11.78H16.3672C16.5139 11.78 16.6405 11.66 16.6405 11.5067V10.5C16.6405 10.3534 16.5205 10.2267 16.3672 10.2267H16.3605Z"
        fill="#111111"
      />
    </svg>
  );
};
export default AddGuest;
