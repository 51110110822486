import { Input, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import mainStyles from "src/pages/projects/project/styles/index";
import { useTranslation } from "react-i18next/";
import CustomButton from "src/components/button";
import {
  IContactReferences,
  TalentType,
} from "src/pages/projects/project/interfaces";
import { getIsValidEmail } from "src/pages/team/components/org-invites/components/invite-members/email-validator";
import styles from "../../styles";

interface IAddNewCustomData {
  addConfirmTextButton: string;
  isAddForm: boolean;
  isWritersShareSection: boolean;
  isAgency?: boolean;
  onAddNew: (searchValue: string) => void;
  onAddNewWithEmail?: (guest: IContactReferences) => void;
  onAddForm: (isFromOpen: boolean) => void;
}
const AddNewCustomData = ({
  isAddForm,
  addConfirmTextButton,
  isWritersShareSection,
  isAgency = false,
  onAddNew,
  onAddNewWithEmail,
  onAddForm,
}: IAddNewCustomData): JSX.Element => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [writerIpi, setWriterIpi] = useState<string>();

  const [isEmailFormatValid, setEmailFormatValid] = useState(true);

  useEffect(() => {
    return () => {
      setEmail(undefined);
      setName(undefined);
    };
  }, [onAddForm]);

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    []
  );

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    []
  );

  const handleChangeWriterIPI = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setWriterIpi(e.target.value);
    },
    []
  );

  const handleValidateEmail = useCallback(() => {
    if (email) {
      const isEmailValid = getIsValidEmail(email);
      setEmailFormatValid(isEmailValid);
    }
  }, [email]);

  const isButtonDisabled = useMemo(() => {
    return !isAgency ? !name || !email : !name;
  }, [email, isAgency, name]);

  const handleCheckForm = useCallback(() => {
    if (email && name) {
      const isEmailValid = getIsValidEmail(email);

      if (isEmailValid || isAgency) {
        const newData: IContactReferences = {
          type: TalentType.OpenForm,
          email,
          name,
          groupByAgency: false,
          agencyWritingPartnership: "",
        };
        if (isWritersShareSection) {
          newData.ipi = writerIpi;
        }
        onAddNewWithEmail?.(newData);
      }
    } else if (name && isAgency) {
      onAddNew(name);
    }
  }, [
    email,
    isAgency,
    isWritersShareSection,
    name,
    onAddNew,
    onAddNewWithEmail,
    writerIpi,
  ]);

  return (
    <>
      {isAddForm && (
        <div css={styles.addFormContainer}>
          <div css={styles.addFormWithIpi}>
            <div>
              <div css={mainStyles.basicInputContainer}>
                <Typography.Text css={mainStyles.basicInputLabel}>
                  {`${t("Name")}*`}
                </Typography.Text>
              </div>
              <Input value={name} required onChange={handleChangeName} />
            </div>

            {isWritersShareSection && (
              <div>
                <div css={mainStyles.basicInputContainer}>
                  <Typography.Text css={mainStyles.basicInputLabel}>
                    {`${t("Writer IPI")}`}
                  </Typography.Text>
                </div>
                <Input
                  value={writerIpi}
                  required
                  onChange={handleChangeWriterIPI}
                />
              </div>
            )}
          </div>
          {!isAgency && (
            <div>
              <div css={mainStyles.basicInputContainer}>
                <Typography.Text css={mainStyles.basicInputLabel}>
                  {`${t("Email")}*`}
                </Typography.Text>
              </div>
              <Input
                value={email}
                required
                onChange={handleChangeEmail}
                onBlur={handleValidateEmail}
              />
              <div
                css={[
                  styles.emailError,
                  isEmailFormatValid && styles.emailErrorVisibility,
                ]}
              >
                {t("Please enter a valid email!")}
              </div>
            </div>
          )}

          <CustomButton
            disabled={isButtonDisabled}
            css={styles.customButton}
            ariaLabel="Submit"
            onClick={handleCheckForm}
            data-testid="add-update-service-button"
          >
            {addConfirmTextButton}
          </CustomButton>
        </div>
      )}
    </>
  );
};

export default AddNewCustomData;
