import { DebouncedFunc } from "lodash";
import React, { ReactElement } from "react";
import STLoadingLogo from "src/components/st-loading-logo";
import ShareProject from "..";
import { IProjectUploadFile } from "../../project/components/details/components/interfaces";
import { IProjectAttachment, IProjectForm } from "../../project/interfaces";
import styles from "../styles";

interface IProps {
  projectData: IProjectForm | undefined;
  publicDownloadFile: DebouncedFunc<
    (file: IProjectUploadFile) => Promise<void>
  >;
  tracks: IProjectAttachment[];
}

const PublicShareProject = ({
  projectData,
  publicDownloadFile,
  tracks,
}: IProps): ReactElement => {
  return (
    <div css={styles.container}>
      {projectData ? (
        <ShareProject
          projectData={projectData}
          downloadFile={publicDownloadFile}
          tracks={tracks}
        />
      ) : (
        <STLoadingLogo />
      )}
    </div>
  );
};

export default PublicShareProject;
