import axios from "axios";
import { getAuthHeader, getJsonContentHeader } from "src/api/headers";
import config from "src/config";

async function deleteContactFile(
  attachmentId: string,
  contactId: string,
  accessToken: string,
  orgId: string
): Promise<void> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  return axios.delete(
    `${config.projectManagementService.url}/talent-hub/talent-contact/${contactId}/attachments/${attachmentId}?orgId=${orgId}`,
    options
  );
}

export default deleteContactFile;
