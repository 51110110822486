import { css } from "@emotion/react";
import theme from "src/theme";

const sharedMenuItemStyle = `
  padding: 0 !important;
  height: 56px !important;
  margin-top: 0 !important;
  display: flex;
  flex-direction: column;
`;

export default {
  dashboardMenuItem: css`
    ${sharedMenuItemStyle}
    margin-bottom: 0.5rem !important;
    background-color: ${theme.colors.primary.blue} !important;
    :hover {
      background-color: ${theme.colors.primary.blue} !important;
    }
  `,
  logo: css`
    margin-left: 0.1rem;
  `,
  menuItem: css`
    ${sharedMenuItemStyle}
    background-color: ${theme.colors.secondary.black} !important;
    margin-bottom: 0 !important;
  `,
  profileMenuItem: css`
    background-color: ${theme.colors.secondary.black} !important;
    position: absolute !important;
    bottom: 0 !important;
    padding: 0 !important;
    height: 56px !important;
    width: 56px;
    :hover {
      .iconFill {
        fill: ${theme.colors.secondary.cyan} !important;
      }
      .iconStroke {
        stroke: ${theme.colors.secondary.cyan} !important;
      }
    }
  `,
  logout: css`
    font-size: 2rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    :active {
      background-color: transparent;
    }

    button {
      background-color: transparent;
      cursor: pointer;
      border: 0;
      padding-right: 0;

      :focus {
        outline: 0;
      }
    }
  `,
  navLink: css`
    position: relative !important;
    display: inline-block;
    color: ${theme.colors.white} !important;

    .dashboardIcon,
    .iconFill {
      fill: ${theme.colors.white};
    }
    .iconStroke {
      stroke: ${theme.colors.white};
      stroke-width: 1.5;
    }

    :hover {
      background-color: ${theme.colors.secondary.navyPurple};
    }
  `,
  tooltip: css`
    z-index: 2150;
    height: 56px;
  `,
};
