import React, { FunctionComponent } from "react";
import config from "../config";
import CognitoProvider from "./provider";

const withAuthentication = (
  WrappedComponent: FunctionComponent
): FunctionComponent => (props: Record<string, unknown>) => (
  <CognitoProvider
    userPoolId={config.cognito.userPoolId}
    clientId={config.cognito.clientId}
  >
    <WrappedComponent {...props} />
  </CognitoProvider>
);

export default withAuthentication;
