import axios, { AxiosResponse } from "axios";
import { getAuthHeader, getAxiosOptions } from "../../headers";
import getJsonContentHeader from "../../headers/get-json-content-header";
import { getThirdPartyServicesDeleteUrl } from "../../third-party-services-url-helper";

const deleteThirdPartyService = async (
  accessToken: string,
  organisationId: string,
  thirdPartyServiceId: string
): Promise<boolean | AxiosResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  return axios.delete(
    getThirdPartyServicesDeleteUrl(organisationId, thirdPartyServiceId),
    options
  );
};
export default deleteThirdPartyService;
