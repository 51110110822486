import { cloneDeep } from "lodash";
import { TFunction } from "react-i18next/";
import {
  DateDisplayFormat,
  LicenseFixedDurations,
  Regions,
  TermTypes,
} from "src/constants";
import { getDerivedTermEndDate } from "src/pages/projects/project/components/licensing/helpers";
import DateStringYYYYMMDDToMoment from "src/utils/time";
import countries from "i18n-iso-countries";
import { IProjectTerm } from "../../interfaces";
import {
  ITermDetailViewModel,
  ITermSummaryViewModel,
  ITermViewModel,
  ILicensingViewModel,
} from "./interfaces";

export function formatDate(unformattedDate?: string): string {
  if (!unformattedDate) return "";

  return DateStringYYYYMMDDToMoment(unformattedDate).format(DateDisplayFormat);
}

function determineEndDate(
  t: TFunction<string>,
  startDate?: string,
  endDate?: string,
  fixedDuration?: LicenseFixedDurations
): string {
  if (fixedDuration === LicenseFixedDurations.InPerpetuity) {
    return t("ProjectsPage##licensingSection##In perpetuity");
  }

  const derivedEndDate = getDerivedTermEndDate(
    startDate ? DateStringYYYYMMDDToMoment(startDate) : undefined,
    endDate,
    fixedDuration
  );

  if (derivedEndDate) {
    return derivedEndDate.format(DateDisplayFormat);
  }
  return "";
}

const getMediaTypes = (
  mediaTypes: string[] | undefined,
  customMediaTypes: string[]
): string => {
  if (mediaTypes) {
    return mediaTypes?.concat(customMediaTypes).join(", ");
  }

  return customMediaTypes.join(", ");
};

export function mapCountries(
  countryCodes: string[],
  language: string
): string[] {
  return countryCodes.map((code) => {
    const mappedCountry = countries.getName(code, language);
    return mappedCountry || code;
  });
}

export function maptoTermSummaryViewModel(
  term: IProjectTerm,
  isTablet: boolean,
  isMobile: boolean,
  t: TFunction<string>,
  language: string
): ITermSummaryViewModel {
  const buttonColumnWidth = 1;
  const mediaTypes = getMediaTypes(term.mediaType, term.customMediaType);

  let selectTracks = "";
  if (term.finalTracks.length > 0) {
    selectTracks = term.finalTracks
      .map((track) => {
        return track.title;
      })
      .join(", ");
  }
  if (term.appliesToAllFinalTracks) {
    selectTracks = "All final tracks in the project";
  }

  let territories = "";
  if (term.territories?.worldwide) {
    territories = Regions.Worldwide;
    if (term.territories?.excludedCountries.length > 0) {
      const mappedCountries = mapCountries(
        term.territories?.excludedCountries,
        language
      );

      territories = `${territories} (excl ${mappedCountries.join(", ")})`;
    }
  }
  if (term.territories?.includedCountries.length > 0) {
    const mappedCountries = mapCountries(
      term.territories?.includedCountries,
      language
    );
    territories = mappedCountries.join(", ");
  }

  let nameTypeColSize = 4;
  let trackColSize = 6;

  if (isTablet) {
    nameTypeColSize = 6;
  } else if (isMobile) {
    nameTypeColSize = 11;
    trackColSize = 11;
  }

  return {
    type: term.type.toUpperCase(),
    typeStyle:
      term.type === TermTypes.Term
        ? "termSummaryStyles.termType"
        : "termSummaryStyles.optionType",
    name: term.name,
    nameTypeColSize,
    mediaType: mediaTypes,
    mediaTypesShowPlaceholder: mediaTypes.length === 0,
    mediaTypesShowColumn: !isMobile,
    mediaTypesColSize: isTablet ? 5 : 4,
    territories,
    territoriesShowPlaceholder: !territories,
    territoriesShowColumn: !isMobile,
    territoriesColSize: isTablet ? 5 : 4,
    duration: formatDate(term.startDate).concat(
      " - ",
      determineEndDate(t, term.startDate, term.endDate, term.fixedDuration)
    ),
    durationShowPlaceholder:
      term.startDate === undefined || term.startDate.length === 0,
    durationShowColumn: !isTablet && !isMobile,
    durationColSize: isTablet ? 5 : 4,
    buttonColSize: buttonColumnWidth,
    more: {
      deleteConfirmationI18nKey:
        "ProjectsPage##licensingSection##DeleteTermConfirmation",
      deleteConfirmationVariable: term.name,
      deleteButtonI18nKey: "ProjectsPage##licensingSection##DeleteTermType",
      deleteButtonVariable: term.type.toLowerCase(),
    },
    trackColSize,
    showTracks: !selectTracks,
    tracks: selectTracks,
  };
}

export function mapTermsToViewModels(
  termsForMap: IProjectTerm[],
  isTablet: boolean,
  isMobile: boolean,
  t: TFunction<string>,
  language: string
): ITermViewModel[] {
  const termViewModels: ITermViewModel[] = [];
  const orderedTermsForMap = cloneDeep(termsForMap).sort((a, b) => {
    return b.lastUpdated.localeCompare(a.lastUpdated);
  });

  orderedTermsForMap.forEach((term) => {
    const termDetails: ITermDetailViewModel = {
      id: term.id,
      type: term.type as TermTypes,
      name: term.name,
      mediaType: term.mediaType,
      customMediaType: term.customMediaType,
      scripts: term.scripts,
      territories: term.territories,
      startDate: term.startDate
        ? DateStringYYYYMMDDToMoment(term.startDate)
        : undefined,
      endDate: getDerivedTermEndDate(
        term.startDate ? DateStringYYYYMMDDToMoment(term.startDate) : undefined,
        term.endDate,
        term.fixedDuration
      ),
      fixedDuration: term.fixedDuration
        ? term.fixedDuration
        : "Custom duration",
      exclusivity: term.exclusivity,
      notes: term.notes,
      hasUnlimitedScripts:
        term.hasUnlimitedScripts === undefined
          ? false
          : term.hasUnlimitedScripts,
      version: term.version,
      appliesToAllFinalTracks: term.appliesToAllFinalTracks,
      finalTracks: term.finalTracks,
      fee: term.fee,
      quote: term.quote,
      lyricChange: term.lyricChange,
      optionToExtend: term.optionToExtend,
      onlineArchival: term.onlineArchival,
      mediaTypeNote: term.mediaTypeNote,
      territoriesNote: term.territoriesNote,
      scriptNotes: term.scriptNotes,
    };
    const termSummary: ITermSummaryViewModel = maptoTermSummaryViewModel(
      term,
      isTablet,
      isMobile,
      t,
      language
    );

    termViewModels.push({
      termDetails,
      termSummary,
      showTermSummary: true,
      showTermForm: false,
    });
  });

  return termViewModels;
}

export function maptoViewModel(
  termsSectionForMap: IProjectTerm[],
  isTablet: boolean,
  isMobile: boolean,
  t: TFunction<string>,
  language: string
): ILicensingViewModel {
  const termViewModel: ILicensingViewModel = {
    terms: termsSectionForMap
      ? mapTermsToViewModels(
          termsSectionForMap,
          isTablet,
          isMobile,
          t,
          language
        )
      : [],
    showTerms: termsSectionForMap ? termsSectionForMap.length > 0 : false,
    showAddNewTerm: termsSectionForMap ? termsSectionForMap.length === 0 : true,
    showAddAnotherButton: true,
  };
  return termViewModel;
}
