import React, { ReactElement, useCallback, useState } from "react";
import { Form, Typography, Space, Row, Input } from "antd";
import StyledButton from "src/components/button";
import { useTranslation } from "react-i18next";
import FloatingLabel from "src/components/floating-label";
import styles from "./styles";

interface IProps {
  onSaveFilter: (filterName: string) => void;
  onCancelFilter: () => void;
}

const SaveFilterContent = ({
  onSaveFilter,
  onCancelFilter,
}: IProps): ReactElement => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [filterName, setFilterName] = useState("");

  const handleSaveFilter = useCallback(async () => {
    if (filterName) {
      onSaveFilter(filterName);
    } else {
      await form.validateFields(["filterName"]);
    }
  }, [filterName, onSaveFilter, form]);

  const handleSetFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterName(event.target.value);
    },
    []
  );

  return (
    <div>
      <Typography.Title css={styles.modalTitle}>
        {t("SaveFilterModal##SaveYourCustomFilter")}
      </Typography.Title>
      <Space />
      <Form
        form={form}
        name="filter-form"
        onFinish={handleSaveFilter}
        initialValues={[filterName]}
      >
        <Row gutter={[16, 16]} css={styles.row}>
          <FloatingLabel
            label={t("SaveFilterModal##FilterName")}
            name="filterName"
            value={filterName}
          >
            <Form.Item
              name="filterName"
              css={styles.input}
              initialValue={filterName}
              rules={[
                () => ({
                  validator(_rule, value?: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error("Please give your custom filter a name")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input name="filterName" onChange={handleSetFilterName} />
            </Form.Item>
          </FloatingLabel>
        </Row>
        <div css={styles.controls}>
          <Form.Item>
            <div css={styles.rightControls}>
              <StyledButton
                css={styles.secondaryButton}
                type="secondary"
                ariaLabel={t("SaveFilterModal##Cancel")}
                onClick={onCancelFilter}
              >
                SaveFilterModal##Cancel
              </StyledButton>
              <StyledButton
                css={styles.button}
                htmlType="submit"
                ariaLabel={t("SaveFilterModal##SaveFilter")}
              >
                SaveFilterModal##SaveFilter
              </StyledButton>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default SaveFilterContent;
