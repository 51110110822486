import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOutsideClick } from "src/utils/outside-click/useOutsideClick";
import { IAgencyResults } from "src/api/talent-hub/interfaces";
import styles from "src/pages/projects/project/components/talent-hub-input/styles/index";
import CustomDropDownInput from "src/pages/projects/project/components/talent-hub-input/components/custom-drop-down-input";
import { Button, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next/";
import mainStyles from "src/pages/projects/project/styles/index";
import { MinDropdownTagLength, MaxDropDownTagLength } from "src/constants";
import AddGuest from "src/app/assets/icons/component-icons/add_guest";
import AgencyWritingDropDownMenu, {
  MaxAgenciesListHeight,
  MinAgenciesListHeight,
} from "../agency-writing-dropdown-menu";

interface IAgenciesInput {
  agencies: IAgencyResults[];
  selectedAgency?: string;
  areAgenciesLoading: boolean;
  onScrollAgencies: (page: number) => void;
  onUpdateNarrowSearchText: (searchValue: string) => void;
  onSelectedAgencyChange: (agency?: string) => void;
  onResetNarrowSearch: () => void;
}
const AgencyWritingInput = ({
  agencies,
  selectedAgency,
  areAgenciesLoading,
  onScrollAgencies,
  onSelectedAgencyChange,
  onUpdateNarrowSearchText,
  onResetNarrowSearch,
}: IAgenciesInput): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const agencyRef = useRef<HTMLDivElement>(null);

  const dropDownButton = useRef<HTMLButtonElement>(null);
  const getMappedAgencies = useCallback(
    () => agencies.map((agencyEntity) => agencyEntity.agency),

    [agencies]
  );

  const [allAgencies, setAllAgencies] = useState<string[]>(getMappedAgencies());
  useEffect(() => {
    setAllAgencies(getMappedAgencies());
  }, [getMappedAgencies]);

  const [widthOffSet, setWidthOffSet] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isAgencyInputFocused, setAgencyInputFocused] = useState(false);

  useEffect(() => {
    setIsLoading(areAgenciesLoading);
  }, [areAgenciesLoading]);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleResetDropDownValues = useCallback(() => {
    handleCloseModal();
    onResetNarrowSearch();
    setAllAgencies([]);
    setPageNumber(0);
    setSearchValue("");
    setAgencyInputFocused(false);
    onResetNarrowSearch();
  }, [handleCloseModal, onResetNarrowSearch]);

  const handleSetPageNumber = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  useOutsideClick(dropDownRef, (event) => {
    if (
      dropDownButton?.current &&
      !dropDownButton.current.contains(event.target as Node)
    ) {
      handleResetDropDownValues();
    }
  });

  useEffect(() => {
    setPageNumber(0);
  }, []);

  useEffect(() => {
    if (isAgencyInputFocused) {
      setAllAgencies(getMappedAgencies);
    }
  }, [getMappedAgencies, isAgencyInputFocused]);

  useEffect(() => {
    if (isOpen && dropDownRef?.current) {
      const rect = dropDownRef.current.getBoundingClientRect();

      if (rect.right >= 0 && rect.right >= window.innerWidth) {
        const newWidthOffSet = Math.round(rect.right - window.innerWidth);
        setWidthOffSet(newWidthOffSet);
      }
    } else {
      setWidthOffSet(0);
    }
  }, [isOpen]);

  const handleAgencyInputFocusChange = useCallback(() => {
    onUpdateNarrowSearchText(searchValue);
  }, [onUpdateNarrowSearchText, searchValue]);

  const handleMainButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (
        dropDownButton?.current &&
        dropDownButton.current.contains(event.target as Node)
      ) {
        event.stopPropagation();
        event.preventDefault();
        if (!isOpen && !isAgencyInputFocused) {
          setIsOpen(!isOpen);
          setAgencyInputFocused(!isAgencyInputFocused);
          onUpdateNarrowSearchText(searchValue);
        } else {
          onUpdateNarrowSearchText(searchValue);
        }
      }
    },
    [isOpen, isAgencyInputFocused, onUpdateNarrowSearchText, searchValue]
  );

  const handleAgencyInputChange = useCallback(
    (searchText: string) => {
      onUpdateNarrowSearchText(searchText);
      setSearchValue(searchText);
    },
    [onUpdateNarrowSearchText]
  );

  const handleRemoveTag = useCallback(() => {
    onSelectedAgencyChange();
  }, [onSelectedAgencyChange]);

  const tag = useMemo(() => {
    const isLongTag: boolean =
      (selectedAgency?.length ?? 0) > MinDropdownTagLength;

    return (
      <Tag
        key={selectedAgency ?? ""}
        css={styles.tag(false)}
        onClose={handleRemoveTag}
        closable
      >
        <span>
          {isLongTag
            ? `${selectedAgency?.slice(0, MaxDropDownTagLength) ?? ""}...`
            : selectedAgency}
        </span>
      </Tag>
    );
  }, [handleRemoveTag, selectedAgency]);

  const tags = useMemo(() => (selectedAgency ? [tag] : []), [
    selectedAgency,
    tag,
  ]);

  const width = agencyRef.current?.clientWidth;

  const handleAddNewAgency = useCallback(() => {
    onSelectedAgencyChange(searchValue);
  }, [onSelectedAgencyChange, searchValue]);

  return (
    <div>
      <div css={mainStyles.basicInputContainer} ref={agencyRef}>
        <Typography.Text css={mainStyles.basicInputLabel}>
          {t("ViewContact##Agency/Writing Partnership")}
        </Typography.Text>
      </div>
      <div css={styles.dropDownWrapper} style={{ width }}>
        <button
          id="agency-writing-partnership"
          type="button"
          css={styles.mainDropDownContainer}
          ref={dropDownButton}
          onClick={handleMainButtonClick}
        >
          <CustomDropDownInput
            tags={tags}
            isOpen={isOpen}
            placeholder="Search or add an agency"
            onInputChange={handleAgencyInputChange}
            onInputFocusChange={handleAgencyInputFocusChange}
          />
        </button>
        {isOpen && (
          <div
            css={styles.mainDropDownBody(
              widthOffSet,
              tags ? MaxAgenciesListHeight : MinAgenciesListHeight
            )}
            ref={dropDownRef}
          >
            <AgencyWritingDropDownMenu
              totalPages={1}
              tags={tags}
              selectedAgency={selectedAgency}
              pageNumber={pageNumber}
              dropDownWidth={width}
              isLoading={isLoading}
              allAgencies={allAgencies}
              allRows={1000}
              onScrollAgencies={onScrollAgencies}
              onSetPageNumber={handleSetPageNumber}
              onSelectedAgencyChange={onSelectedAgencyChange}
            />

            <div css={styles.addContainer}>
              <div css={styles.buttonContainer}>
                <Button
                  data-testid="add-talent-hub-guest"
                  css={styles.addButton}
                  onClick={handleAddNewAgency}
                >
                  <div css={styles.iconContainer}>
                    <AddGuest />
                    <div css={styles.addButtonText}>{t("Add new agency")}</div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgencyWritingInput;
