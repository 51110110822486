import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

const sharedHeaderStyling = css`
  width: 100%;
  font-family: ${theme.fonts.sofiaPro};

  .ant-tabs-tab {
    margin: 0 3rem 0 0;
  }
`;
const upperHeader = css`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-between;
  padding: 1rem 2rem;
`;
export default {
  buttonsContainer: css`
    display: flex;
  `,
  buttonContainer: css`
    display: inline-block;
    margin-right: 16px;
  `,
  hubspotText: css`
    width: 62px !important;
    height: 24px !important;
    color: ${theme.colors.black};
  `,
  projectUpperHeaderBoxShadowing: css`
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
  `,
  changeOrganisationButton: css`
    background: transparent;
    border: none;
    font-size: 16px;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: ${theme.colors.primary.blue};
    }
  `,
  warningSubheader: css`
    display: flex;
    flex-direction: row;
    font-size: 16px;
    background-color: ${theme.colors.functional.orange};
    justify-content: space-between;
    height: 3rem;
    align-items: center;
    z-index: 2000;
    padding: 0.7rem 2rem;
  `,

  headerTitleWithSaving: css`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    white-space: nowrap;
  `,
  hubspotButton: css`
    display: flex;
    font-size: 16px;
    align-items: center;
    border: 2px solid ${theme.colors.black} !important;
    -webkit-box-shadow: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background: ${theme.colors.white};
    min-height: 100%;
    color: white;
    border-radius: 0.5rem !important;
    width: 142px !important;
    height: 3.5rem !important;
    justify-content: center;
    svg {
      color: ${theme.colors.white};
      margin-right: 0;
      circle {
        stroke: none;
      }
      line {
        stroke: ${theme.colors.white};
      }
    }
    :hover {
      background: ${theme.colors.secondary.lightGray} !important;
      border: 2px solid ${theme.colors.secondary.darkGray} !important;
      color: ${theme.colors.white};
      svg {
        margin-right: 0;
        circle {
          stroke: none;
        }
        line {
          stroke: ${theme.colors.white};
        }
      }
    }
    .ant-btn {
      height: 100%;
      border: none;
      border-radius: 0;
      background: ${theme.colors.white};
      color: ${theme.colors.white};
      :hover {
        background: ${theme.colors.black};
      }
    }
  `,
  saveAndCloseWithHubspot: css`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
  `,
  buttonRow: css`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: center;
    svg {
      margin-left: 8px;
    }
  `,
  badgeIcon: css`
    .ant-badge-count {
      background-color: green;
    }
  `,
  projectStickyHeader: css`
    position: sticky;
    top: 48px;
    z-index: ${theme.zIndex.pageStickyHeader};
    background-color: ${theme.colors.background.white};
    @media ${queries.large} {
      top: 0;
    }
  `,

  subHeaderText: css`
    font-weight: bold;
  `,
  headerDivider: css`
    margin: 0;
  `,
  subHeaderContainer: css`
    display: flex;
    flex-direction: row;
    font-size: 16px;
    justify-content: space-between;
    height: 3rem;
    align-items: center;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
    z-index: 2000;
  `,
  typographyTitle: css`
    margin-bottom: 0 !important;
    font-weight: 700 !important;
    font-size: 32px !important;
    align-self: center;
    font-family: ${theme.fonts.sofiaPro};
    position: relative;
    overflow: hidden;
    text-wrap: nowrap;
    line-height: 1.4 !important;
    min-width: 0;
    text-overflow: ellipsis;
    margin-top: 0;
  `,
  typographyTitleWithoutSubtitle: css`
    margin: 1rem 0 !important;
  `,
  upperHeaderContainer: css`
    display: flex;
    flex-direction: row;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-content: space-between;
    height: fit-content;
    min-width: 0;
  `,
  projectHeaderAndSubHeader: css`
    display: flex;
    flex-direction: column;
  `,
  saving: css`
    margin-left: 1rem;
    font-size: 0.875rem;
    color: ${theme.colors.primary.blue};
  `,

  projectContainer: css`
    width: 100%;
    background-color: ${theme.colors.white};
    ${sharedHeaderStyling}
    display:flex;
    flex-direction: column;
    position: sticky;
    overflow: visible !important;
    top: 0;
  `,
  tabsStyles: css`
    overflow: visible;
    .ant-tabs-nav {
      margin: 0 16px;
      .ant-tabs-nav-operations {
        display: none;
      }
      @media ${queries.large} {
        margin: 0;
        position: fixed;
        z-index: 2000;
        height: 100%;
      }
    }
  `,
  projectSaveHeader: css`
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem !important;
  `,
  projectUpperHeader: css`
    ${upperHeader}
    height: 88px;
  `,

  teamsUpperHeader: css`
    ${upperHeader}
    height: 2.5rem;
  `,
  projectHeaderAndSave: css`
    display: flex;
    align-items: center;
    padding-left: 1rem !important;
  `,
  saveAndMoreMenu: css`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  `,
  projectSubHeader: css`
    width: 100%;
    display: flex;
    padding: 0.7rem 2rem;
    flex-grow: 1;
  `,
  projectTabsHeader: css`
    margin-top: 2rem;
    margin-bottom: 48px;
    .ant-tabs-nav {
      border-color: ${theme.colors.background.veryLightGray};
      border-width: 0.15rem;
      margin-top: 0.5rem;
      .ant-tabs-tab-btn .ant-badge {
        font-size: 24px;
        font-weight: 300;
        color: ${theme.colors.secondary.lightGray};
        @media ${queries.large} {
          font-size: 1.5rem;
        }
      }
      .ant-tabs-tab-active .ant-tabs-tab-btn .ant-badge {
        color: ${theme.colors.black};
      }
    }
    .ant-tabs-nav-wrap {
      box-shadow: none;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      height: 100%;
      @media ${queries.large} {
        border-right: 1px solid #f0f0f0;
      }
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
      padding: 0.5rem 0.5rem;
      margin: 0 !important;
      :first-of-type {
        padding-top: 0.5rem;
      }
      padding-bottom: 1rem;
      @media ${queries.large} {
        width: 16rem;
        padding: 1rem 2rem;
      }
      color: ${theme.colors.secondary.lightGray};
    }
    @media ${queries.large} {
      margin-bottom: 0;
    }
  `,
  projectDetailsWithoutProjectTypeActive: css`
    .ant-tabs-tabpane {
      min-height: 600px;
    }
  `,
  heading: css`
    font-family: ${theme.fonts.sofiaPro};
    margin-bottom: 0 !important;

    @media ${queries.medium} {
      font-size: 2.25rem !important;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem !important;
    }

    @media ${queries.large} {
      font-size: 2.75rem !important;
    }
  `,
  headerContainer: css`
    display: none;
    width: 100%;
    background-color: ${theme.colors.background.white};
  `,
  stickyHeaderContainer: css`
    ${sharedHeaderStyling}
    position: fixed;
    z-index: 1051;
    @media ${queries.large} {
      margin-top: 0;
    }
  `,
  container: css`
    ${sharedHeaderStyling}
  `,
  contentContainer: css`
    border-radius: 0;
    padding-left: 0 !important;
    padding-top: 1rem;
    :empty {
      padding: 0.125rem 0 0 0 !important;
    }
  `,
  tabLink: css`
    color: ${theme.colors.black};

    :hover {
      color: ${theme.colors.black};
      text-decoration: none;
    }

    :visited {
      color: ${theme.colors.black};
    }
  `,
};
