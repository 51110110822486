import { ReactElement } from "react";
import { IComponentIconProps } from "./interfaces";

const ArrowUp = ({
  height = 48,
  width = 48,
  fill = "#D5D5D5",
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.11508 14.2777L7.14818 15.3107C7.30162 15.4642 7.55118 15.4642 7.70463 15.3107L11.7218 11.2936C11.8752 11.1402 12.1248 11.1402 12.2782 11.2936L16.2954 15.3113C16.4488 15.4648 16.6984 15.4648 16.8518 15.3113L17.8849 14.2782C18.0384 14.1248 18.0384 13.8752 17.8849 13.7218L12.2782 8.11507C12.1248 7.96163 11.8752 7.96163 11.7218 8.11507L6.11508 13.7218C5.96164 13.8752 5.96164 14.1248 6.11508 14.2782L6.11508 14.2777Z"
        fill={fill}
      />
    </svg>
  );
};
export default ArrowUp;
