import React, { ReactElement } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Layout, Menu, Tooltip } from "antd";
import { DashboardIcon } from "src/app/assets/icons/component-icons";
import { useWindowSize } from "@songtradr/spa-common/lib/utils";
import { ISideNavProp } from "src/pages/navigation";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import styles from "./styles";

interface IProps {
  navLinks: Array<ISideNavProp>;
}

const SideNavigation = ({ navLinks }: IProps): ReactElement => {
  useWindowSize();
  const { Sider } = Layout;
  const location = useLocation();
  const items = [
    {
      key: "/dashboard",
      icon: (
        <div css={styles.dashboardMenuItem}>
          <NavLink
            key="/dashboard"
            css={styles.navLink}
            to="/dashboard"
            data-testid="dashboard-nav-link"
          >
            <DashboardIcon css={styles.logo} />
          </NavLink>
        </div>
      ),
    },

    ...navLinks.map(
      ({
        visible,
        icon,
        key,
        to,
        dataTestId,
        title: translation,
        customStyles,
      }: ISideNavProp): ItemType => {
        const isLinkActive = to === window.location.pathname;

        return visible
          ? {
              key,
              icon: (
                <div css={customStyles}>
                  <Tooltip
                    placement="right"
                    title={translation}
                    overlayClassName={
                      isLinkActive
                        ? "custom-tooltip-active custom-tooltip"
                        : "custom-tooltip"
                    }
                    css={styles.tooltip}
                  >
                    <NavLink
                      css={styles.navLink}
                      to={to}
                      data-testid={dataTestId}
                    >
                      {icon(isLinkActive)}
                    </NavLink>
                  </Tooltip>
                </div>
              ),
            }
          : { key };
      }
    ),
  ];
  return (
    <Sider width="56" data-testid="side-navigation-container">
      <Menu
        theme="dark"
        defaultSelectedKeys={["/dashboard"]}
        mode="inline"
        selectedKeys={[location.pathname]}
        items={items}
      />
    </Sider>
  );
};

export default SideNavigation;
