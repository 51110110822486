import { ReactElement } from "react";

const EditGroups = (): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 20.6641V14.8145C3 14.6277 3.15157 14.4766 3.3379 14.4766H4.59297C4.77979 14.4766 4.93088 14.6281 4.93088 14.8145V18.7327C4.93088 18.9195 5.08197 19.0706 5.26878 19.0706H10.1476C10.3344 19.0706 10.4855 19.2217 10.4855 19.4085V20.6636C10.4855 20.8504 10.3344 21.0015 10.1476 21.0015H3.3379C3.15109 21.0015 3 20.8504 3 20.6636V20.6641Z"
      fill="#111111"
    />
    <path
      d="M20.9019 6.82495L17.1758 3.09884C17.044 2.96705 16.8297 2.96705 16.6979 3.09884L8.62926 11.1675C8.58099 11.2158 8.54865 11.2771 8.5361 11.3442L7.67493 15.9319C7.631 16.1656 7.83567 16.3702 8.06931 16.3263L12.6571 15.4651C12.7242 15.4526 12.7855 15.4202 12.8337 15.372L20.9024 7.30332C21.0342 7.17154 21.0342 6.95721 20.9024 6.82543L20.9019 6.82495ZM11.89 13.584C11.8418 13.6323 11.7805 13.6646 11.7134 13.6771L10.4877 13.9074C10.2541 13.9513 10.0494 13.7467 10.0934 13.513L10.3236 12.2874C10.3362 12.2203 10.3685 12.159 10.4168 12.1107L14.2409 8.28662C14.3727 8.15484 14.587 8.15484 14.7188 8.28662L15.7141 9.28199C15.8459 9.41377 15.8459 9.6281 15.7141 9.75988L11.89 13.584ZM18.1712 7.30284L17.5576 7.91638C17.4259 8.04816 17.2115 8.04816 17.0797 7.91638L16.0844 6.92101C15.9526 6.78923 15.9526 6.5749 16.0844 6.44312L16.6979 5.82958C16.8297 5.6978 17.044 5.6978 17.1758 5.82958L18.1712 6.82495C18.303 6.95673 18.303 7.17106 18.1712 7.30284Z"
      fill="#111111"
    />
  </svg>
);

export default EditGroups;
