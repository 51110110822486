import React, { ReactElement, ReactNode, useCallback, useState } from "react";
import { ErrorToast } from "src/components/toast-notification";
import useAuth from "src/auth/use-auth";
import {
  IAgencyResponse,
  IAgencyResults,
  IAgencySearch,
  IAgencyWithContactsResults,
  IContactsResponse,
  IProjectContactsSearchRequest,
} from "src/api/talent-hub/interfaces";
import getContacts from "src/api/talent-hub/get-talent-hub-contacts";
import { ContactsPageSize } from "src/constants";
import { cloneDeep } from "lodash";
import getAgencies from "src/api/talent-hub/get-agencies";
import { log } from "src/utils/data-dog";
import { IContactsResults } from "src/pages/contact/interfaces";
import ProjectTalentsSearchContext from "./context";

interface IOrganisationProvider {
  children: ReactNode;
}
export const isAgencyResults = (
  data: IContactsResults | IAgencyResults
): data is IAgencyResults => {
  return "agency" in data;
};
const ProjectTalentSearchProvider = ({
  children,
}: IOrganisationProvider): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contactsResponse, setContactsResponse] = useState<IContactsResponse>();
  const [contactsAndAgenciesResults, setContactsAndAgenciesResults] = useState<
    IAgencyWithContactsResults[]
  >();

  const [agenciesNextPage, setAgenciesNextPage] = useState("");

  const { organisationId, fetchWrapper } = useAuth();

  const fetchContacts = useCallback(
    async (
      searchText: string,
      pageNumber: number,
      isServiceProvider: boolean
    ) => {
      const searchTextQuery: string = searchText.trim();
      let from = 0;
      if (pageNumber) {
        from = (Number(pageNumber) - 1) * ContactsPageSize;
      }

      const requestData: IProjectContactsSearchRequest = {
        organisationId,
        from,
        sort: {
          by: "Name",
          order: "ASC",
        },
        perPage: ContactsPageSize,
        narrowSearchText: searchTextQuery,
        filters: {
          serviceProvider: isServiceProvider || null,
        },
      };
      const contacts = (await fetchWrapper(
        getContacts,
        requestData,
        organisationId
      )) as IContactsResponse;
      return contacts;
    },
    [fetchWrapper, organisationId]
  );

  const getAgenciesOrContactsResults = useCallback(
    (
      contacts: IAgencyWithContactsResults[],
      isReset: boolean,
      previousResults?: IAgencyWithContactsResults[]
    ): IAgencyWithContactsResults[] => {
      let contactResponse = cloneDeep(contacts);
      let combinedPrevAndNewTalent;
      if (previousResults) {
        combinedPrevAndNewTalent = contacts.reduce(
          (allTalentsAndAgencies, currentTalentOrAgency) => {
            if (isAgencyResults(currentTalentOrAgency)) {
              const isAgencyAlreadySet = previousResults?.find(
                (prevAgency) =>
                  prevAgency.agency === currentTalentOrAgency.agency
              );
              if (!isAgencyAlreadySet) {
                allTalentsAndAgencies.push(currentTalentOrAgency);
              }
            } else {
              const currentTalent = currentTalentOrAgency as IAgencyWithContactsResults;
              const isTalentAlreadySet = previousResults?.find(
                (prevTalent) => prevTalent?.id === currentTalent?.id
              );

              if (!isTalentAlreadySet) {
                allTalentsAndAgencies.push({
                  ...currentTalent,
                  groupByAgency: false,
                });
              }
            }

            return allTalentsAndAgencies;
          },
          [...previousResults]
        );
        if (!isReset) {
          contactResponse = combinedPrevAndNewTalent;
        }
      } else {
        contactResponse = contacts.map((contact) => {
          return { ...contact, groupByAgency: false };
        });
      }
      return contactResponse;
    },
    []
  );

  const handleSetTalentsAndAgencies = useCallback(
    async (
      searchValue: string,
      page: number,
      isReset: boolean,
      agencyNextPage: string,
      areAgenciesIntegrated: boolean,
      isServiceProvider: boolean
    ) => {
      try {
        setIsLoading(true);
        const searchTextQuery: string = searchValue.trim();
        const contactsPromise = fetchContacts(
          searchValue,
          page,
          isServiceProvider
        );

        const agencyBody: IAgencySearch = {
          organisationId,
          includeTalents: true,
          searchText: searchTextQuery,
          pagination: {
            perPage: 10,
            startAfterAgency: agencyNextPage,
          },
        };

        let agenciesPromise: Promise<IAgencyResponse> | undefined;

        if (areAgenciesIntegrated) {
          agenciesPromise = fetchWrapper<IAgencyResponse>(
            getAgencies,
            agencyBody,
            organisationId
          );
        }

        await Promise.all([contactsPromise, agenciesPromise]).then(
          ([contacts, agencies]) => {
            if (contacts) {
              const newContactsAndAgencies = getAgenciesOrContactsResults(
                [
                  ...((contacts.results ?? []) as IAgencyWithContactsResults[]),
                  ...(areAgenciesIntegrated && agencies
                    ? ((agencies.results ?? []) as IAgencyWithContactsResults[])
                    : []),
                ],
                isReset,
                contactsAndAgenciesResults
              );
              setContactsAndAgenciesResults(newContactsAndAgencies);
              setAgenciesNextPage(agencies?.nextPaginationToken ?? "");
              setContactsResponse(contacts);
            }
          }
        );
      } catch (e) {
        log("error", "Failed to fetch contacts", e);
        ErrorToast(
          "contacts-filter-error",
          "There was a problem retrieving contacts. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      contactsAndAgenciesResults,
      fetchContacts,
      fetchWrapper,
      getAgenciesOrContactsResults,
      organisationId,
    ]
  );

  const handlePageChange = useCallback(
    async (
      page: number,
      searchText: string,
      areAgenciesIntegrated: boolean,
      isServiceProvider: boolean
    ) => {
      await handleSetTalentsAndAgencies(
        searchText,
        page,
        false,
        agenciesNextPage,
        areAgenciesIntegrated,
        isServiceProvider
      );
    },
    [agenciesNextPage, handleSetTalentsAndAgencies]
  );

  const handleUpdateNarrowSearchText = useCallback(
    async (
      searchValue: string,
      areAgenciesIntegrated: boolean,
      isServiceProvider: boolean
    ) => {
      await handleSetTalentsAndAgencies(
        searchValue,
        0,
        true,
        "",
        areAgenciesIntegrated,
        isServiceProvider
      );
    },
    [handleSetTalentsAndAgencies]
  );

  const handleResetNarrowSearch = useCallback(() => {
    setContactsAndAgenciesResults(undefined);
    setContactsResponse(undefined);
    setAgenciesNextPage("");
  }, []);

  return (
    <ProjectTalentsSearchContext.Provider
      value={{
        isLoading,
        contactsResponse,
        agenciesAndContacts: contactsAndAgenciesResults ?? [],
        handleUpdateNarrowSearchText,
        handlePageChange,
        handleResetNarrowSearch,
      }}
    >
      {children}
    </ProjectTalentsSearchContext.Provider>
  );
};

export default ProjectTalentSearchProvider;
