import axios from "axios";
import config from "src/config";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";
import { IAgencyResponse, IAgencySearch } from "../interfaces";

const getAgencies = async (
  accessToken: string,
  body: IAgencySearch
): Promise<IAgencyResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const response = await axios.post(
    `${config.projectManagementService.url}/talent-hub/agency/_search`,
    body,
    options
  );
  return response.data as IAgencyResponse;
};

export default getAgencies;
