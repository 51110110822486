import React, { ReactElement, ReactNode, forwardRef } from "react";
import { Tooltip } from "antd";
import { getIsDesktop } from "@songtradr/spa-common/lib/utils";
import { SerializedStyles } from "@emotion/react";
import styles from "./styles";

interface IProps {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  showTooltip?: boolean;
  showLabel?: boolean;
  tooltipColor?: string;
  "data-testid"?: string;
  additionalStyles?: SerializedStyles;
}

const isDesktop = getIsDesktop();

const ControlButton = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      label,
      onClick,
      disabled,
      className = "",
      children,
      showTooltip = false,
      showLabel = false,
      tooltipColor = "#000",
      "data-testid": dataTestId,
      additionalStyles,
    }: IProps,
    ref
  ): ReactElement => (
    <Tooltip
      placement="right"
      autoAdjustOverflow
      title={label}
      color={tooltipColor}
      getPopupContainer={(triggerNode) => triggerNode}
      {...(showTooltip && isDesktop ? {} : { open: false })}
    >
      <button
        ref={ref}
        aria-label={label}
        css={[
          styles.control,
          showTooltip && isDesktop ? styles.controlWithTooltip : null,
          additionalStyles,
        ]}
        className={className}
        onClick={(e) => onClick(e)}
        disabled={disabled}
        type="button"
        data-testid={dataTestId}
      >
        {children}
        {showLabel && <span css={styles.label}>{label}</span>}
      </button>
    </Tooltip>
  )
);

export default ControlButton;
