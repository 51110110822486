import { Col, Row, Tree } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Key } from "antd/lib/table/interface";
import { AntdTreeNodeAttribute } from "antd/lib/tree";
import { cloneDeep } from "lodash";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import ArrowDown from "src/app/assets/icons/component-icons/arrow_down";
import ArrowUp from "src/app/assets/icons/component-icons/arrow_up";
import Chevron from "src/app/assets/icons/component-icons/chevron";
import { Regions } from "src/constants";
import { ITerritory } from "src/pages/projects/project/interfaces";
import theme from "src/theme";
import { useImmer } from "use-immer";
import styles from "./styles";
import {
  firstTreeData,
  ITreeDataNode,
  secondTreeData,
  thirdTreeData,
} from "./tree-data";

interface ICheckInfo {
  checked: boolean;
  checkedNodes: ITreeDataNode[];
  node: ITreeDataNode;
  halfCheckedKeys: Key[];
}

interface IProps {
  setTerritories: (updatedTerritories: ITerritory | undefined) => void;
  territories: ITerritory | undefined;
  markAsRequired: boolean;
  updateTermChanged: () => void;
}

const defaultTreeState = {
  data: [],
  checkedCountryKeys: [],
  checkedCountryCodes: [],
  uncheckedCountryCodes: [],
  allCountryKeys: [],
} as ITreeState;

interface ITreeState {
  data: ITreeDataNode[];
  checkedCountryKeys: (string | undefined)[];
  checkedCountryCodes: (string | undefined)[];
  uncheckedCountryCodes: (string | undefined)[];
  allCountryKeys: string[];
}

const getCountryKeys = (treeData: ITreeDataNode[]): string[] => {
  let treeKeys: string[] = [];
  Object.values(treeData).forEach((node) => {
    if (node.code && !node.isParentGrouping) {
      treeKeys.push(node.key);
    }

    if (node.children) {
      const childData = getCountryKeys(node.children);
      treeKeys = [...treeKeys, ...childData];
    }
  });

  return treeKeys;
};

const getCountryKeysByCodes = (
  treeData: ITreeDataNode[],
  codes: string[]
): string[] => {
  let treeKeys: string[] = [];
  Object.values(treeData).forEach((node) => {
    if (!node.isParentGrouping && node.code && codes.includes(node.code)) {
      treeKeys.push(node.key);
    }

    if (node.children) {
      const childData = getCountryKeysByCodes(node.children, codes);
      treeKeys = [...treeKeys, ...childData];
    }
  });

  return treeKeys;
};

const getCountryCodesForTerritory = (
  code: string,
  allTreeData: ITreeDataNode[]
): string[] => {
  let treeKeys: string[] = [];
  Object.values(allTreeData).forEach((node) => {
    if (
      !node.isParentGrouping &&
      code !== node.key &&
      node.parents?.includes(code)
    ) {
      if (node.code) {
        treeKeys.push(node.code);
      }
    }

    if (node.children) {
      const childData = getCountryCodesForTerritory(code, node.children);
      treeKeys = [...treeKeys, ...childData];
    }
  });

  return treeKeys;
};

const getCheckedUncheckedCountryCodes = (
  checkedCountryKeys: (string | undefined)[],
  allTreeData: ITreeDataNode[]
): { checkedCountryCodes: string[]; uncheckedCountryCodes: string[] } => {
  let checkedCountryCodes: string[] = [];
  let uncheckedCountryCodes: string[] = [];
  Object.values(allTreeData).forEach((node) => {
    if (!node.isParentGrouping && node.code) {
      if (checkedCountryKeys.includes(node.key)) {
        checkedCountryCodes.push(node.code);
      } else {
        uncheckedCountryCodes.push(node.code);
      }
    }

    if (node.children) {
      const childData = getCheckedUncheckedCountryCodes(
        checkedCountryKeys,
        node.children
      );
      checkedCountryCodes = [
        ...checkedCountryCodes,
        ...childData.checkedCountryCodes,
      ];

      uncheckedCountryCodes = [
        ...uncheckedCountryCodes,
        ...childData.uncheckedCountryCodes,
      ];
    }
  });

  const uniqueCheckedCountryCodes = checkedCountryCodes.filter(
    (value, index) => checkedCountryCodes.indexOf(value) === index
  );

  const uniqueUncheckedCountryCodes = uncheckedCountryCodes.filter(
    (value, index) => uncheckedCountryCodes.indexOf(value) === index
  );

  return {
    checkedCountryCodes: uniqueCheckedCountryCodes,
    uncheckedCountryCodes: uniqueUncheckedCountryCodes,
  };
};

const getRemainingCountryKeys = (
  exlcudedCountryCodes: (string | undefined)[],
  allTreeData: ITreeDataNode[]
): string[] => {
  let checkedCountryKeys: string[] = [];
  Object.values(allTreeData).forEach((node) => {
    if (!node.isParentGrouping && node.code) {
      if (!exlcudedCountryCodes.includes(node.code)) {
        checkedCountryKeys.push(node.key);
      }
    }

    if (node.children) {
      const childData = getRemainingCountryKeys(
        exlcudedCountryCodes,
        node.children
      );
      checkedCountryKeys = [...checkedCountryKeys, ...childData];
    }
  });

  return checkedCountryKeys;
};

const getCountryCodesForTree = (
  countryCodes: (string | undefined)[],
  allTreeData: ITreeDataNode[]
): string[] => {
  let countryCodesInTree: string[] = [];
  Object.values(allTreeData).forEach((node) => {
    if (!node.isParentGrouping && node.code) {
      if (countryCodes.includes(node.code)) {
        countryCodesInTree.push(node.code);
      }
    }

    if (node.children) {
      const childCountryCodes = getCountryCodesForTree(
        countryCodes,
        node.children
      );

      countryCodesInTree = [...countryCodesInTree, ...childCountryCodes];
    }
  });

  const uniqueCountryCodes = countryCodesInTree.filter(
    (value, index) => countryCodesInTree.indexOf(value) === index
  );

  return uniqueCountryCodes;
};

const handleDuplicatesOnAnotherTree = (
  checkedCountryCodes: string[],
  uncheckedCountryCodes: string[],
  currentState: ITreeState,
  children?: ITreeDataNode[]
): ITreeState => {
  let updatedState = cloneDeep(currentState);

  const treeData = children ?? updatedState.data;
  Object.values(treeData).forEach((node) => {
    if (!node.isParentGrouping && node.code) {
      if (
        checkedCountryCodes.includes(node.code) &&
        !updatedState.checkedCountryKeys.includes(node.key)
      ) {
        updatedState.checkedCountryCodes = [
          ...updatedState.checkedCountryCodes,
          node.code,
        ];

        const updatedUncheckedCodes = [
          ...updatedState.uncheckedCountryCodes,
        ].filter((code) => code !== node.code);
        updatedState.uncheckedCountryCodes = updatedUncheckedCodes;
        updatedState.checkedCountryKeys = [
          ...updatedState.checkedCountryKeys,
          node.key,
        ];
      } else if (
        uncheckedCountryCodes.includes(node.code) &&
        updatedState.checkedCountryKeys.includes(node.key)
      ) {
        updatedState.uncheckedCountryCodes = [
          ...updatedState.uncheckedCountryCodes,
          node.code,
        ];

        const updatedCheckedCodes = [
          ...updatedState.checkedCountryCodes,
        ].filter((code) => code !== node.code);
        updatedState.checkedCountryCodes = updatedCheckedCodes;
        updatedState.checkedCountryKeys = [
          ...updatedState.checkedCountryKeys,
        ].filter((key) => key !== node.key);
      }
    }

    if (node.children) {
      updatedState = handleDuplicatesOnAnotherTree(
        checkedCountryCodes,
        uncheckedCountryCodes,
        updatedState,
        node.children
      );
    }
  });

  return updatedState;
};

const alphabetiseTreeCountryData = (treeData: ITreeDataNode[]) => {
  Object.values(treeData).forEach((node) => {
    if (node.isCountryGrouping && node.children) {
      node.children.sort((a, b) => {
        if (a.title && b.title) {
          return a.title?.localeCompare(b.title);
        }
        return 0;
      });
    }

    if (node.children) {
      alphabetiseTreeCountryData(node.children);
    }
  });
};

const Territories = ({
  setTerritories,
  territories,
  markAsRequired,
  updateTermChanged,
}: IProps): ReactElement => {
  const { language } = getI18n();
  const { t } = useTranslation();
  const [allCountries, setAllCountries] = useState<string[]>([]);
  const [showAllTerritories, setShowAllTerritories] = useState(false);
  const [worldwideState, setWorldwideState] = useState({
    isWorldWide: territories?.worldwide,
    isChecked:
      territories &&
      territories?.worldwide &&
      territories?.excludedCountries.length === 0,
    isHalfChecked:
      territories &&
      territories?.worldwide &&
      territories?.excludedCountries.length > 0,
  });

  const [firstTreeState, setFirstTreeState] = useImmer<ITreeState>({
    ...defaultTreeState,
  });
  const [secondTreeState, setSecondTreeState] = useImmer<ITreeState>({
    ...defaultTreeState,
  });
  const [thirdTreeState, setThirdTreeState] = useImmer<ITreeState>({
    ...defaultTreeState,
  });

  const handleCheckUncheck = (
    event: ICheckInfo,
    checkedCountryKeys: (string | undefined)[],
    allTreeData: ITreeDataNode[]
  ) => {
    let countryKeys: string[] = [];
    if (event.node.code) {
      if (event.node.isParentGrouping) {
        const countryCodesForTerritory = getCountryCodesForTerritory(
          event.node.code,
          allTreeData
        );

        countryKeys = getCountryKeysByCodes(
          allTreeData,
          countryCodesForTerritory
        );
      } else {
        countryKeys = getCountryKeysByCodes(allTreeData, [event.node.code]);
      }

      if (countryKeys.length > 1) {
        if (event.checked) {
          countryKeys.forEach((countryKey) => {
            if (!checkedCountryKeys.includes(countryKey)) {
              checkedCountryKeys.push(countryKey);
            }
          });
        } else {
          countryKeys.forEach((countryKey) => {
            if (checkedCountryKeys.includes(countryKey)) {
              // eslint-disable-next-line no-param-reassign
              checkedCountryKeys = checkedCountryKeys.filter(
                (checkedCountryKey) => checkedCountryKey !== countryKey
              );
            }
          });
        }
      }
    }
    return checkedCountryKeys;
  };

  const onCheck = (
    _checkedKeys:
      | Key[]
      | {
          checked: Key[];
          halfChecked: Key[];
        },
    info: unknown
  ) => {
    const event = info as ICheckInfo;
    if (event.node.key === Regions.Worldwide) {
      if (event.checked) {
        setFirstTreeState((draft) => {
          const countryCodes = getCheckedUncheckedCountryCodes(
            firstTreeState.allCountryKeys,
            firstTreeState.data
          );
          draft.checkedCountryKeys = firstTreeState.allCountryKeys.concat([
            Regions.Worldwide,
          ]);
          draft.checkedCountryCodes = countryCodes.checkedCountryCodes;
          draft.uncheckedCountryCodes = countryCodes.uncheckedCountryCodes;
        });
        setSecondTreeState((draft) => {
          const countryCodes = getCheckedUncheckedCountryCodes(
            secondTreeState.allCountryKeys,
            secondTreeState.data
          );
          draft.checkedCountryKeys = secondTreeState.allCountryKeys;
          draft.checkedCountryCodes = countryCodes.checkedCountryCodes;
          draft.uncheckedCountryCodes = countryCodes.uncheckedCountryCodes;
        });
        setThirdTreeState((draft) => {
          const countryCodes = getCheckedUncheckedCountryCodes(
            thirdTreeState.allCountryKeys,
            thirdTreeState.data
          );
          draft.checkedCountryKeys = thirdTreeState.allCountryKeys;
          draft.checkedCountryCodes = countryCodes.checkedCountryCodes;
          draft.uncheckedCountryCodes = countryCodes.uncheckedCountryCodes;
        });
        setWorldwideState({
          isWorldWide: true,
          isChecked: true,
          isHalfChecked: false,
        });
      } else {
        setFirstTreeState((draft) => {
          draft.checkedCountryKeys = [];
          draft.uncheckedCountryCodes = [];
          draft.checkedCountryCodes = [];
        });
        setSecondTreeState((draft) => {
          draft.checkedCountryKeys = [];
          draft.uncheckedCountryCodes = [];
          draft.checkedCountryCodes = [];
        });
        setThirdTreeState((draft) => {
          draft.checkedCountryKeys = [];
          draft.uncheckedCountryCodes = [];
          draft.checkedCountryCodes = [];
        });
        setWorldwideState({
          isWorldWide: false,
          isChecked: false,
          isHalfChecked: false,
        });
      }
    } else {
      let checkedCountryKeys = event.checkedNodes
        .map((groupingNode) => {
          if (!groupingNode.isParentGrouping) {
            return groupingNode.key;
          }
          return undefined;
        })
        .filter((countryKey) => {
          return countryKey != null;
        });

      switch (event.node.treeNumber) {
        case 1:
          {
            checkedCountryKeys = handleCheckUncheck(
              event,
              checkedCountryKeys,
              firstTreeState.data
            );
            const countryCodes = getCheckedUncheckedCountryCodes(
              checkedCountryKeys,
              firstTreeState.data
            );

            setFirstTreeState((draft) => {
              draft.checkedCountryKeys = checkedCountryKeys;
              draft.checkedCountryCodes = countryCodes.checkedCountryCodes;
              draft.uncheckedCountryCodes = countryCodes.uncheckedCountryCodes;
            });

            setSecondTreeState(
              handleDuplicatesOnAnotherTree(
                countryCodes.checkedCountryCodes,
                countryCodes.uncheckedCountryCodes,
                secondTreeState
              )
            );
            setThirdTreeState(
              handleDuplicatesOnAnotherTree(
                countryCodes.checkedCountryCodes,
                countryCodes.uncheckedCountryCodes,
                thirdTreeState
              )
            );
          }
          break;

        case 2:
          {
            checkedCountryKeys = handleCheckUncheck(
              event,
              checkedCountryKeys,
              secondTreeState.data
            );

            const countryCodes = getCheckedUncheckedCountryCodes(
              checkedCountryKeys,
              secondTreeState.data
            );

            setSecondTreeState((draft) => {
              draft.checkedCountryKeys = checkedCountryKeys;
              draft.checkedCountryCodes = countryCodes.checkedCountryCodes;
              draft.uncheckedCountryCodes = countryCodes.uncheckedCountryCodes;
            });

            setFirstTreeState(
              handleDuplicatesOnAnotherTree(
                countryCodes.checkedCountryCodes,
                countryCodes.uncheckedCountryCodes,
                firstTreeState
              )
            );
            setThirdTreeState(
              handleDuplicatesOnAnotherTree(
                countryCodes.checkedCountryCodes,
                countryCodes.uncheckedCountryCodes,
                thirdTreeState
              )
            );
          }
          break;
        case 3: {
          checkedCountryKeys = handleCheckUncheck(
            event,
            checkedCountryKeys,
            thirdTreeState.data
          );

          const countryCodes = getCheckedUncheckedCountryCodes(
            checkedCountryKeys,
            thirdTreeState.data
          );

          setThirdTreeState((draft) => {
            draft.checkedCountryKeys = checkedCountryKeys;
            draft.checkedCountryCodes = countryCodes.checkedCountryCodes;
            draft.uncheckedCountryCodes = countryCodes.uncheckedCountryCodes;
          });

          setFirstTreeState(
            handleDuplicatesOnAnotherTree(
              countryCodes.checkedCountryCodes,
              countryCodes.uncheckedCountryCodes,
              firstTreeState
            )
          );
          setSecondTreeState(
            handleDuplicatesOnAnotherTree(
              countryCodes.checkedCountryCodes,
              countryCodes.uncheckedCountryCodes,
              secondTreeState
            )
          );
          break;
        }
        default:
          throw new Error("Invalid tree number for Territories");
      }
    }
    updateTermChanged();
  };

  const renderIcon = (nodeProps: AntdTreeNodeAttribute) => {
    const getWorldwideCheckedState = () => {
      if (worldwideState.isWorldWide && worldwideState.isChecked) {
        return true;
      }
      return false;
    };

    return (
      <Checkbox
        checked={
          nodeProps.eventKey === Regions.Worldwide
            ? getWorldwideCheckedState()
            : nodeProps.checked
        }
        indeterminate={
          nodeProps.eventKey === Regions.Worldwide &&
          worldwideState.isHalfChecked
            ? true
            : nodeProps.halfChecked
        }
        data-testid={nodeProps.eventKey}
      />
    );
  };

  const renderSwitcherIcon = (): ReactElement => {
    return (
      <Fragment>
        <div className="switcher-Icon-Open">
          <Chevron
            width={theme.svgSize.small}
            height={theme.svgSize.small}
            viewBox={`0 0 ${38} ${22}`}
            isChevronPointingDown={false}
          />
        </div>
        <div className="switcher-Icon-Close">
          <Chevron
            width={theme.svgSize.small}
            height={theme.svgSize.small}
            viewBox={`0 0 ${38} ${22}`}
            isChevronPointingDown
          />
        </div>
      </Fragment>
    );
  };

  useEffect(() => {
    const allcheckedCountryKeys = firstTreeState.checkedCountryKeys
      .concat(secondTreeState.checkedCountryKeys)
      .concat(thirdTreeState.checkedCountryKeys);

    if (allCountries.length > 0) {
      const allCountriesSelected = allCountries.every((countryKey) =>
        allcheckedCountryKeys.includes(countryKey)
      );

      const includedCountries = firstTreeState.checkedCountryCodes
        .concat(secondTreeState.checkedCountryCodes)
        .concat(thirdTreeState.checkedCountryCodes);

      const excludedCountries = firstTreeState.uncheckedCountryCodes
        .concat(secondTreeState.uncheckedCountryCodes)
        .concat(thirdTreeState.uncheckedCountryCodes);

      if (allCountriesSelected) {
        setTerritories({
          worldwide: true,
          excludedCountries: [],
          includedCountries: [],
        } as ITerritory);

        setWorldwideState({
          isWorldWide: true,
          isChecked: true,
          isHalfChecked: false,
        });
      } else if (worldwideState.isWorldWide) {
        setTerritories({
          worldwide: true,
          excludedCountries,
          includedCountries: [],
        } as ITerritory);

        setWorldwideState({
          ...worldwideState,
          isHalfChecked: true,
          isChecked: false,
        });
      } else {
        setTerritories({
          worldwide: false,
          excludedCountries: [],
          includedCountries,
        } as ITerritory);

        setWorldwideState({
          isWorldWide: false,
          isHalfChecked: false,
          isChecked: false,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTreeState, secondTreeState, thirdTreeState, allCountries]);

  useEffect(() => {
    const firstTreeNodes = firstTreeData(language, t);
    const secondTreeNodes = secondTreeData(language, t);
    const thirdTreeNodes = thirdTreeData(language, t);

    alphabetiseTreeCountryData(firstTreeNodes);
    alphabetiseTreeCountryData(secondTreeNodes);
    alphabetiseTreeCountryData(thirdTreeNodes);

    const firstTreeCountryKeys = getCountryKeys(firstTreeNodes);
    const secondTreeCountryKeys = getCountryKeys(secondTreeNodes);
    const thirdTreeCountryKeys = getCountryKeys(thirdTreeNodes);

    setAllCountries(
      firstTreeCountryKeys
        .concat(secondTreeCountryKeys)
        .concat(thirdTreeCountryKeys)
    );

    setFirstTreeState((draft) => {
      draft.data = firstTreeNodes;
      draft.allCountryKeys = firstTreeCountryKeys;
      draft.checkedCountryCodes = territories?.includedCountries
        ? getCountryCodesForTree(territories?.includedCountries, firstTreeNodes)
        : [];
      draft.uncheckedCountryCodes = territories?.excludedCountries
        ? getCountryCodesForTree(territories?.excludedCountries, firstTreeNodes)
        : [];
      // eslint-disable-next-line no-nested-ternary
      draft.checkedCountryKeys = !territories?.worldwide
        ? getCountryKeysByCodes(
            firstTreeNodes,
            territories?.includedCountries ?? []
          )
        : territories?.excludedCountries.length === 0
        ? firstTreeCountryKeys
        : getRemainingCountryKeys(
            territories?.excludedCountries ?? [],
            firstTreeNodes
          ).concat([Regions.Worldwide]);
    });
    setSecondTreeState((draft) => {
      draft.data = secondTreeNodes;
      draft.allCountryKeys = secondTreeCountryKeys;
      draft.checkedCountryCodes = territories?.includedCountries
        ? getCountryCodesForTree(
            territories?.includedCountries,
            secondTreeNodes
          )
        : [];
      draft.uncheckedCountryCodes = territories?.excludedCountries
        ? getCountryCodesForTree(
            territories?.excludedCountries,
            secondTreeNodes
          )
        : [];
      // eslint-disable-next-line no-nested-ternary
      draft.checkedCountryKeys = !territories?.worldwide
        ? getCountryKeysByCodes(
            secondTreeNodes,
            territories?.includedCountries ?? []
          )
        : territories?.excludedCountries.length === 0
        ? secondTreeCountryKeys
        : getRemainingCountryKeys(
            territories?.excludedCountries ?? [],
            secondTreeNodes
          );
    });
    setThirdTreeState((draft) => {
      draft.data = thirdTreeNodes;
      draft.allCountryKeys = thirdTreeCountryKeys;
      draft.checkedCountryCodes = territories?.includedCountries
        ? getCountryCodesForTree(territories?.includedCountries, thirdTreeNodes)
        : [];
      draft.uncheckedCountryCodes = territories?.excludedCountries
        ? getCountryCodesForTree(territories?.excludedCountries, thirdTreeNodes)
        : [];
      // eslint-disable-next-line no-nested-ternary
      draft.checkedCountryKeys = !territories?.worldwide
        ? getCountryKeysByCodes(
            thirdTreeNodes,
            territories?.includedCountries ?? []
          )
        : territories?.excludedCountries.length === 0
        ? thirdTreeCountryKeys
        : getRemainingCountryKeys(
            territories?.excludedCountries ?? [],
            thirdTreeNodes
          );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <div>
      <div
        css={
          showAllTerritories
            ? [styles.treeContainer]
            : [styles.treeContainer, styles.hideTerritories]
        }
        style={{ border: `1px solid ${markAsRequired ? "red" : "white"}` }}
      >
        <Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col xs={24} sm={12} md={8}>
                  <Tree
                    css={styles.tree}
                    checkable
                    className="firstTree"
                    key={JSON.stringify(firstTreeState.data)}
                    selectable={false}
                    defaultExpandedKeys={[
                      Regions.Africa,
                      Regions.Americas,
                      Regions.Asia,
                    ]}
                    onCheck={onCheck}
                    treeData={firstTreeState.data}
                    checkedKeys={firstTreeState.checkedCountryKeys as Key[]}
                    icon={renderIcon}
                    switcherIcon={renderSwitcherIcon()}
                    showIcon
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Tree
                    css={styles.tree}
                    checkable
                    className="secondTree"
                    selectable={false}
                    defaultExpandedKeys={[
                      Regions.Europe,
                      Regions.MiddleEast,
                      Regions.Namet,
                      Regions.Rub,
                    ]}
                    onCheck={onCheck}
                    key={JSON.stringify(secondTreeState.data)}
                    treeData={secondTreeState.data}
                    checkedKeys={secondTreeState.checkedCountryKeys as Key[]}
                    icon={renderIcon}
                    showIcon
                    switcherIcon={renderSwitcherIcon()}
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Tree
                    css={styles.tree}
                    checkable
                    className="thirdTree"
                    selectable={false}
                    defaultExpandedKeys={[Regions.Oceania]}
                    onCheck={onCheck}
                    key={JSON.stringify(thirdTreeState.data)}
                    treeData={thirdTreeState.data}
                    checkedKeys={thirdTreeState.checkedCountryKeys as Key[]}
                    icon={renderIcon}
                    showIcon
                    switcherIcon={renderSwitcherIcon()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </div>
      <div css={styles.showTerritoriesToggle}>
        <div
          css={styles.chevronContainer}
          role="button"
          tabIndex={0}
          onClick={() => {
            setShowAllTerritories(!showAllTerritories);
          }}
          onKeyDown={() => {
            setShowAllTerritories(!showAllTerritories);
          }}
        >
          {showAllTerritories ? (
            <div css={styles.chevronContainer}>
              <ArrowUp
                height={28}
                width={28}
                fill={theme.colors.primary.blue}
              />
              {t("ProjectsPage##licensingSection##Show less territories")}
            </div>
          ) : (
            <div css={styles.chevronContainer}>
              <ArrowDown
                height={28}
                width={28}
                fill={theme.colors.primary.blue}
              />
              {t("ProjectsPage##licensingSection##Show all territories")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Territories;
