import React from "react";
import { Form, Typography } from "antd";
import StyledButton from "src/components/button";
import styles from "src/components/modals/styles";
import { useTranslation } from "react-i18next";

export interface IProps {
  modalTitle?: string;
  mainContent: string | JSX.Element | HTMLElement;
  primaryButtonLabel?: string;
  onButtonClick: () => void;
}

const Content = ({
  modalTitle = "",
  mainContent,
  primaryButtonLabel = "Close",
  onButtonClick,
}: IProps): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  return (
    <div>
      {modalTitle && (
        <Typography.Title data-testid="modal-Title" css={styles.modalTitle}>
          {t(modalTitle)}
        </Typography.Title>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={onButtonClick}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {mainContent}
        <div css={styles.footer}>
          <StyledButton
            ariaLabel={primaryButtonLabel}
            type="primary"
            htmlType="submit"
            className="confirmationModalConfirmButton"
            data-testid="confirm-button"
          >
            {primaryButtonLabel}
          </StyledButton>
        </div>
      </Form>
    </div>
  );
};

export default Content;
