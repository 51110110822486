import { ReactElement } from "react";
import { Form } from "antd";
import Button from "src/components/button";
import { useTranslation } from "react-i18next";
import { IConfirmContentProps } from "../../interfaces";
import styles from "./styles";

const ConfirmationDrawer = ({
  modalTitle = "confirmation##defaultTitle",
  primaryButtonLabel = "defaultPrimaryButton",
  secondaryButtonLabel = "defaultSecondaryButton",
  mainContent,
  confirmAction,
  onClose,
}: IConfirmContentProps): ReactElement => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  return (
    <div data-testid="confirmation-drawer">
      <div css={styles.header}>{t(modalTitle)}</div>
      <Form form={form} layout="vertical">
        <div css={styles.contentContainer}>{mainContent}</div>
        <div css={styles.mobileButtonContainer}>
          <Button
            ariaLabel={secondaryButtonLabel}
            onClick={onClose}
            css={styles.secondaryButton}
            type="secondary"
          >
            {secondaryButtonLabel}
          </Button>
          <Button
            ariaLabel={primaryButtonLabel}
            css={styles.primaryButton}
            onClick={confirmAction}
          >
            {primaryButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ConfirmationDrawer;
