import { ReactElement } from "react";

const GraphicRight = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
    >
      <mask
        id="mask0_404_2663"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="66"
        height="66"
      >
        <rect width="66" height="66" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_404_2663)">
        <path
          d="M-1000 6.2487H57.0017C57.6849 6.24972 58.3397 6.52132 58.8232 7.00406C59.3068 7.4868 59.5793 8.1414 59.5814 8.8246V63.1036C59.5814 63.8717 59.8866 64.6085 60.4298 65.1517C60.973 65.6948 61.7096 66 62.4778 66C63.246 66 63.9827 65.6948 64.5259 65.1517C65.0691 64.6085 65.3744 63.8717 65.3744 63.1036V8.8246C65.3713 6.60535 64.4879 4.47791 62.9183 2.90903C61.3488 1.34014 59.2209 0.457901 57.0017 0.455856H-1000V6.2487ZM1124.98 -24.2603H67.9734C67.2899 -24.2613 66.6345 -24.5336 66.1516 -25.0172C65.6687 -25.5009 65.3975 -26.1565 65.3975 -26.84V-81.1036C65.3975 -81.8718 65.0923 -82.6085 64.5491 -83.1517C64.0059 -83.6948 63.269 -84 62.5009 -84C61.7327 -84 60.9961 -83.6948 60.4529 -83.1517C59.9097 -82.6085 59.6045 -81.8718 59.6045 -81.1036V-26.8323C59.6066 -24.6131 60.4889 -22.4852 62.0577 -20.9156C63.6266 -19.346 65.7542 -18.4628 67.9734 -18.4597H1124.98V-24.2603Z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};

export default GraphicRight;
