import React, { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, notification, Typography } from "antd";
import FloatingLabel from "src/components/floating-label";
import IUserProfileProps from "src/interfaces/user-profile";
import signUpStyles from "src/pages/sign-up/styles";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import Select from "react-select";
import countries from "i18n-iso-countries";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import { OrganisationRequesterRoles } from "@songtradr/vinyl-common";
import { selectStyles } from "src/pages/profile/styles";
import ValidationError from "../../../../components/validation-error";
import styles from "./styles";
import PasswordInput from "./components/password-input";

const FIELDS = {
  firstName: "firstName",
  lastName: "lastName",
  country: "country",
  jobTitle: "jobTitle",
  password: "password",
};

const UserProfileForm = ({
  onSubmit,
  isSavePending,
}: IUserProfileProps): ReactElement => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [country, setCountry] = useState<IOption | null>(null);
  const [jobTitle, setJobTitle] = useState<IOption | null>(null);

  const onFormSubmit = async () => {
    if (!isValidPassword) {
      setIsError(true);
      return false;
    }
    setIsError(false);
    try {
      await onSubmit({
        firstName,
        lastName,
        jobTitle: jobTitle?.value,
        country: country?.value,
        password,
      });
      return true;
    } catch (e) {
      log(DataDogLogTypes.ERROR, "Error creating profile", e);
      notification.error({
        message: t("userProfile##Error Creating Profile"),
        description: t(
          "userProfile##An error has ocurred when creating your profile. Please try again."
        ),
        duration: 0,
      });
      return false;
    }
  };

  const countryOptions = useMemo(
    () =>
      Object.values(countries.getNames("en", { select: "official" })).map(
        (countryName) => {
          return {
            value: countryName,
            label: countryName,
          };
        }
      ),
    []
  );

  const jobTitleOptions: IOption[] = Object.keys(
    OrganisationRequesterRoles
  ).map((enumKey) => {
    return {
      value: enumKey.toString(),
      label: t(`requesterRoles##${enumKey}`),
    };
  });

  return (
    <div css={signUpStyles.formWrapper}>
      <Typography.Title css={styles.title} level={3}>
        {t("userProfile##Welcome")}
      </Typography.Title>
      <Typography.Paragraph css={styles.description}>
        {t("userProfile##Complete your account and we'll get you started.")}
      </Typography.Paragraph>
      <Form layout="vertical" onFinish={onFormSubmit}>
        <FloatingLabel
          label={t("First name")}
          name={FIELDS.firstName}
          value={firstName}
        >
          <Form.Item
            name={FIELDS.firstName}
            css={styles.input}
            rules={[
              {
                required: true,
                whitespace: true,
                message: (
                  <ValidationError>
                    {t(
                      "userProfile##validation##Please provide your first name"
                    )}
                  </ValidationError>
                ),
              },
            ]}
          >
            <Input
              name={FIELDS.firstName}
              value={firstName}
              onBlur={(e) => setFirstName(e.target.value)}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
        </FloatingLabel>

        <FloatingLabel
          label={t("Last name")}
          name={FIELDS.lastName}
          value={lastName}
        >
          <Form.Item
            name={FIELDS.lastName}
            css={styles.input}
            rules={[
              {
                required: true,
                whitespace: true,
                message: (
                  <ValidationError>
                    {t(
                      "userProfile##validation##Please provide your last name"
                    )}
                  </ValidationError>
                ),
              },
            ]}
          >
            <Input
              name={FIELDS.lastName}
              value={lastName}
              onBlur={(e) => setLastName(e.target.value)}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
        </FloatingLabel>

        <Form.Item
          name={FIELDS.jobTitle}
          css={styles.input}
          validateTrigger="onSubmit"
          rules={[
            () => ({
              validator(_rule, option?: IOption) {
                if (!option?.value) {
                  return Promise.reject(
                    new Error(
                      t(
                        "userProfile##validation##Please provide your job title"
                      )
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            id="job-title-select"
            data-testid="job-title-select"
            placeholder="Job Title"
            classNamePrefix="job-title-select"
            styles={selectStyles}
            onChange={(data) => {
              setJobTitle(data as IOption);
            }}
            options={jobTitleOptions}
            value={jobTitle}
          />
        </Form.Item>

        <Form.Item
          name={FIELDS.country}
          css={styles.input}
          validateTrigger="onSubmit"
          rules={[
            () => ({
              validator(_rule, option?: IOption) {
                if (!option?.value) {
                  return Promise.reject(
                    new Error(
                      t("userProfile##validation##Please provide your country")
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            id="country-select"
            data-testid="country-select"
            placeholder="Country"
            classNamePrefix="profile-select"
            styles={selectStyles}
            onChange={(data) => {
              setCountry(data as IOption);
            }}
            options={countryOptions}
            value={country}
          />
        </Form.Item>

        <FloatingLabel
          label={t("Create password")}
          name={FIELDS.password}
          value={password}
        >
          <Form.Item name={FIELDS.password} css={styles.input}>
            <PasswordInput
              name={FIELDS.password}
              setPassword={setPassword}
              isValidPassword={isValidPassword}
              setIsValidPassword={setIsValidPassword}
              isError={isError}
            />
          </Form.Item>
        </FloatingLabel>
        {isError && !isValidPassword && (
          <ValidationError>
            {t("userProfile##validation##Password does not meet criteria.")}
          </ValidationError>
        )}

        <Form.Item css={styles.submit}>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            aria-label={t("userProfile##Get started")}
            loading={isSavePending}
            data-testid="submit-profile-button"
          >
            {t("userProfile##Get started")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserProfileForm;
