import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";
import { StylesConfig } from "react-select";
import { IOption } from "src/pages/projects/project/components/account-information/types";

const sharedHeaderStyling = css`
  width: 100%;
  font-family: ${theme.fonts.sofiaPro};

  .ant-tabs-tab {
    margin: 0 3rem 0 0;
  }
`;
const sharedDropdownStyle = css`
  border-radius: 0.2rem;
  box-shadow: 0 0 0.313rem 0.125rem rgb(0 0 0 / 12%),
    0 0.375rem 1rem 0 rgb(0 0 0 / 8%), 0 0.563rem 1.75rem rgb(0 0 0 / 5%);

  .ant-menu-item,
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 0;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin-bottom: 0;
    padding-right: 1rem;
    padding-left: 0.5rem;
    font-family: ${theme.fonts.sofiaPro};
    background-color: ${theme.colors.background.gray};

    li {
      padding: 0.2rem;
      cursor: pointer;

      :hover {
        font-weight: bold;
      }
    }
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: ${theme.colors.background.gray};
  }
`;
export const customStyles: StylesConfig<IOption> = {
  menu: (provided) => ({
    ...provided,
    minHeight: 56,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 56,
    maxHeight: 56,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
};
export default {
  mainTabContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: ${theme.colors.black} !important;
    .ant-tabs-tab-btn:focus {
      color: ${theme.colors.black} !important;
    }
  `,
  dropdownWrapper: css`
    cursor: pointer;
    .ant-dropdown {
      .ant-dropdown-menu {
        padding: 4px 0;
      }
    }
  `,
  dividerCheckbox: css`
    margin: 0;
    margin-bottom: 1rem;
  `,
  dropdownButton: css`
    border: none;
    background: none;
    font-size: 0.875rem;
    font-weight: 600;
    font-family: sofia-pro, sans-serif;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    cursor: pointer;

    :hover {
      background-color: var(--colors-big-sky);
    }
  `,
  locationCheckboxWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  tabContainer: css`
    justify-content: inherit !important;
  `,
  activeEditGroup: css`
    background-color: ${theme.colors.secondary.bigSkyBlue};
  `,
  regionCheckbox: css`
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 0.5rem;
    .ant-checkbox-inner {
      width: 1.5rem;
      height: 1.5rem;
      border-color: ${theme.colors.background.lightGray} !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      left: 43%;
      width: 0.75rem;
      height: 0.75rem;
      background-color: ${theme.colors.secondary.lightBlue};
    }
    .ant-checkbox-input:focus {
      border-color: ${theme.colors.background.gray};
    }
  `,
  mainTabIcons: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  sectionMarginTop: css`
    margin-top: 1rem;
  `,
  disclaimerLabel: css`
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    align-items: center;
    display: flex;
    svg {
      margin-right: 0.5rem;
    }
  `,
  selectGroupRow: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  selectGroupBodyContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 3.75rem;
  `,
  removeButtonStyles: css`
    background-color: ${theme.colors.white};
    font-size: 16px;
    border: none;
    :hover {
      cursor: pointer;
      font-weight: bold;
    }
  `,
  tabSpacing: css`
    margin-left: 1rem;
  `,
  groupSelectBox: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    border-radius: 4px;
    border-bottom: none;
    padding: 8px;
    :hover {
      cursor: pointer;
      background-color: #fbfbfb;
    }
  `,
  selectedGroup: css`
    background-color: #fbfbfb;
    width: 100%;
  `,
  groupContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-end;
    margin-bottom: -1rem;
  `,

  createGroupBodyContainer: css`
    margin-top: 1rem;
    margin-bottom: 3.75rem;
  `,
  createGroupFooter: css`
    display: flex;
    padding: 1rem;
    background-color: ${theme.colors.background.white};
    border-top: 1px solid #f0f0f0;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    width: -webkit-fill-available;
    div {
      display: flex;
      flex: 1;
      button {
        display: flex;
        align-items: center;
        -webkit-box-shadow: none;
        justify-content: flex-end;
        min-height: 2.6rem;
      }
    }
  `,
  activeGroup: css`
    border-bottom: 2px solid #77acba;
  `,
  activeAndInactiveGroupContainer: css`
    border-bottom: 1px solid #f0f0f0;
  `,
  removeMarginRight: css`
    margin-right: 0 !important;
  `,
  contactTag: css`
    height: 32px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    color: ${theme.colors.white};
    border-color: ${theme.colors.white};
    background: #5495a6;
    margin-right: 0;
    .ant-tag-close-icon {
      color: ${theme.colors.white};
    }
  `,
  selectedFiltersContainer: css`
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    flex-wrap: wrap;
    align-items: center;
  `,
  checkbox: css`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 0.5rem;
    font-size: 16px;

    .ant-checkbox-inner {
      width: 1.5rem;
      height: 1.5rem;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #77acba;
    }
    .ant-checkbox-wrapper {
      font-size: 16px;
      flex-wrap: wrap;

      @media ${queries.large} {
        flex-wrap: nowrap;
      }
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
      background-color: #77acba;
    }
  `,
  flexContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${queries.medium} {
      width: 410px;
      flex-direction: initial;
    }
  `,
  filtersContainers: css`
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  `,
  selectContainer: css`
    width: fit-content;
    height: 58px;
    background: ${theme.colors.background.white};
    border: 1px solid #c4c4c4;
    border-radius: 4px;
  `,
  noContactContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3.125rem;
  `,
  heading: css`
    display: none;
    @media ${queries.medium} {
      font-family: ${theme.fonts.sofiaPro};
      display: initial;
      margin-top: auto;
      padding-bottom: 0.3125rem;
      font-size: 2rem !important;
      font-size: 2.25rem !important;
      margin-bottom: 0.9375rem;
    }

    @media ${queries.large} {
      margin-bottom: 0 !important;
      font-size: 2.75rem !important;
    }
  `,
  image: css`
    height: 180px;
    width: 180px;

    @media ${queries.medium} {
      width: 230px;
      height: 230px;
    }
  `,
  button: css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;

    :focus {
      border-radius: 0;
    }

    @media ${queries.medium} {
      position: initial;
      border-radius: 0.3125rem;

      :focus {
        border-radius: 0.3125rem;
      }
    }
  `,
  textContainer: css`
    display: block;
    padding-top: 0.625rem;
    padding-bottom: 1.25rem;
    text-align: center;

    @media ${queries.medium} {
      text-align: left;
    }
  `,
  moreInfoButton: css`
    pointer-events: visible;
    :hover {
      background-color: rgba(128, 88, 255, 0.15);
      border-radius: 1.5625rem;
      box-sizing: border-box;
    }
  `,
  searchAndButtonsContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5rem;
  `,
  secondaryButton: css`
    border: 1.2px solid ${theme.colors.primary.blue} !important;
    color: ${theme.colors.primary.blue};
    background: #e5e5e5;
    width: 144px;
    min-height: 56px;
  `,
  actionBarDivider: css`
    margin-top: 0.5rem;
  `,
  actionBarButtonsContainer: css`
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    justify-content: space-between;
  `,
  iconButton: css`
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  `,
  primaryButton: css`
    width: 144px;
    min-height: 56px;
  `,
  inputContainer: css`
    margin-bottom: 16px;
    margin-right: 32px;
    width: 100%;
  `,
  inputAndPresetFiltersContainer: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  `,
  input: css`
    width: 100%;

    @media ${queries.large} {
      width: 680px;
    }
    height: 56px;
    background: ${theme.colors.background.white};
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    .ant-input-group-addon:last-child {
      border: unset !important;
      background-color: transparent;
      background-repeat: no-repeat;
      cursor: pointer;
      overflow: hidden;
      outline: none;
    }

    .ant-input {
      font-size: 16px !important;
      height: 54px !important;
      border: unset !important;
    }

    .ant-input::placeholder {
      font-size: 16px;
      color: ${theme.colors.secondary.sandstoneGrey};
      opacity: 1; /* Firefox */
    }
  `,
  inputClear: css`
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  `,
  tabFavoritesText: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
  `,
  addIconContainer: css`
    display: flex;
    width: 38px;
    height: 38px;
    color: #222;
    svg {
      color: #222 !important;
      width: 34px;
      padding: 0.3rem;
      height: 34px;
    }
  `,
  tabTitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    padding-top: 3px;
  `,
  tabSubTitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  `,
  actionBarContainer: css`
    display: flex;
    flex-direction: row;
  `,
  actionBarRightSide: css`
    margin-left: 17.5rem;
    margin-right: 1rem;
  `,
  actionBarLeftSide: css`
    display: flex;
    flex-direction: row;
    width: 16rem;
    justify-content: space-between;
    padding-left: 32px;
    margin-top: 36px;
  `,
  tabHeaderText: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: left;
  `,
  dropdownMenuBrief: css`
    ${sharedDropdownStyle}
    min-width: 140px;
  `,
  noTalentSearchContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3.125rem;
    height: 60vh;
  `,
  noTalentSearchContentContainer: css`
    width: 516px;
    height: 128px;
    background: ${theme.colors.secondary.smokeyWhite};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  textHeader: css`
    display: block;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.secondary.blueOnBlack};
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    padding-bottom: 16px;
  `,
  searchTextContainer: css`
    display: block;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: ${theme.colors.secondary.sandstoneGrey};
  `,
  dropdownMenu: css`
    ${sharedDropdownStyle}
    min-width: 125px;
    max-width: 125px;
    text-align: center;
    button {
      border: none;
      background: none;
      font-size: 0.875rem;
      font-weight: 600;
      font-family: ${theme.fonts.sofiaPro};
      display: flex;
      cursor: pointer;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      svg {
        margin-right: 0.5rem;
      }

      :hover {
        background-color: ${theme.colors.secondary.bigSkyBlue};
        cursor: pointer;
      }
    }
  `,
  paginationCount: css`
    font-size: 0.875rem;
    text-align: center;
    margin-bottom: 3.5rem;
  `,
  talentTable: css`
    table {
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      font-size: 16px;
    }
    tr:hover > td {
      cursor: pointer;
      background-color: ${theme.colors.secondary.fadeToGrey} !important;
    }
    .ant-table-tbody {
      tr {
        td {
          padding: 8px;
          line-height: 26px !important;
        }
        td:last-child {
          align-items: center;
        }
      }
    }
    .ant-table-thead {
      tr > th {
        background: none;
        padding: 8px;
        line-height: 44px !important;
        ::before {
          content: none !important;
        }
      }
    }
    .ant-dropdown-placement-bottomRight {
      margin-top: 0;
    }
    .ant-dropdown-placement-topRight {
      margin-top: 15px;
    }
  `,
  talentHubDivider: css`
    margin: 0 !important;
  `,
  talentStickyHeader: css`
    position: sticky;
    z-index: 1052;
    top: 48px;
    background-color: ${theme.colors.background.white};
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);

    @media ${queries.large} {
      top: 0;
    }
  `,
  subHeaderContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3rem;
    align-items: center;
    z-index: 2000;
  `,
  talentUpperHeader: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem;
    height: 88px;
  `,
  typographyTitle: css`
    margin-bottom: 0 !important;
    font-weight: 700 !important;
    font-size: 32px !important;
    align-self: center;
    font-family: ${theme.fonts.sofiaPro};
    margin-top: 0;
  `,
  tabsStyles: css`
    overflow: visible;

    .ant-tabs-nav {
      margin: 0 16px;
      .ant-tabs-nav-operations {
        display: none;
      }
      @media ${queries.large} {
        position: fixed;
        z-index: 1051;
        height: 100%;
        margin: 0;
      }
    }
  `,
  talentTabsHeader: css`
    margin-top: 0.5rem;
    width: 100%;

    .ant-tabs-nav {
      border-color: ${theme.colors.background.veryLightGray};
      border-width: 0.15rem;
    }
    .ant-tabs-nav-wrap {
      box-shadow: none;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      border-right: 1px solid #f0f0f0;
      @media ${queries.large} {
        height: 100vh;
        overflow: auto;
      }
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list::-webkit-scrollbar {
      width: 3px;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list::-webkit-scrollbar-thumb {
      background-color: #888;
    }

    .ant-tabs
      > .ant-tabs-nav
      .ant-tabs-nav-list::-webkit-scrollbar-thumb:vertical {
      border-radius: 10px;
    }

    .ant-tabs
      > .ant-tabs-nav
      .ant-tabs-nav-list::-webkit-scrollbar-thumb:vertical:active {
      border-radius: 10px;
    }

    .ant-tabs
      > .ant-tabs-nav
      .ant-tabs-nav-list::-webkit-scrollbar-thumb:vertical:hover {
      border-radius: 10px;
    }

    .ant-tabs-left > .ant-tabs-nav {
      margin-top: 0.5rem;
      .ant-tabs-tab-btn:first-of-type {
        width: 100%;
        padding-right: 0 !important;
      }
      .ant-tabs-tab-btn .ant-badge {
        font-size: 1.5rem;
        font-weight: 300;
        color: ${theme.colors.secondary.lightGray};
      }
      .ant-tabs-tab-active {
        background-color: #f6f8ff !important;
        border-radius: 8px;
      }
      .ant-tabs-tab-active .ant-tabs-tab-btn .ant-badge {
        color: ${theme.colors.black};
      }
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
      margin: 0;
      .ant-tabs-tab-btn:first-of-type {
        padding-right: 0 !important;
      }
      text-align: left !important;
      margin-left: 28px;
      margin-right: 14px;
      :nth-of-type(2) {
        position: sticky;
        top: 62px;
        z-index: 2000;
        padding-bottom: 1rem !important;
        background-color: ${theme.colors.white};
      }
      :nth-of-type(3) {
        position: sticky;
        top: 132px;
        z-index: 2000;
        background-color: ${theme.colors.white} !important;
      }
      :first-of-type {
        padding-top: 0.5rem;
        position: sticky;
        top: 0;
        z-index: 2000;
        color: ${theme.colors.black};
        background-color: ${theme.colors.white} !important;
        padding-right: 0 !important;
        padding-bottom: 1rem !important;
        :hover {
          cursor: default;
          text-decoration: none;
        }
      }
      padding-bottom: 2.1rem;
      width: 16rem;
      color: ${theme.colors.secondary.lightGray};
    }
  `,
  talentContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  `,
  mobileSectionContainer: css`
    display: flex;
    flex-direction: column;
  `,
  sectionContainer: css`
    display: flex;
    flex-direction: row;
    > div {
      flex: 1 1 0;
      width: 0;
    }
    column-gap: 1.5rem;
    width: 100%;
  `,
  formItem: css`
    margin-bottom: 0 !important;
  `,
  basicInputContainer: css`
    margin-bottom: 0.5rem;
  `,
  basicInputLabel: css`
    font-size: 0.875rem;
    line-height: 1.55rem;
    font-weight: 400;
  `,
  headerContainer: css`
    display: none;
    width: 100%;
    background-color: ${theme.colors.background.white};
    box-shadow: 0.063rem -0.063rem 0.313rem 0 var(--colors-dark-gray);
    padding: 1rem 0;

    @media ${queries.medium} {
      display: flex;
      font-size: 2.25rem !important;
      margin-bottom: 0 !important;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  `,
  stickyHeaderContainer: css`
    ${sharedHeaderStyling}
    position: fixed;
    z-index: 1051;
    @media ${queries.large} {
      margin-top: 0;
    }
  `,
  container: css`
    ${sharedHeaderStyling}
  `,
  contentContainer: css`
    padding-top: 1.5rem;
    border-radius: 0;

    :empty {
      padding: 0.125rem 0 0 0 !important;
    }

    @media ${queries.medium} {
      border-radius: 5px;
      padding: 1.5rem 3.125rem;
    }
  `,
  tabs: css`
    .ant-tabs-nav-wrap {
      padding-left: 1.25rem;
    }

    @media ${queries.medium} {
      .ant-tabs-tabpane {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
      }

      .ant-tabs-tab {
        padding-top: 0.3125rem !important;
      }
    }

    @media ${queries.large} {
      .ant-tabs-nav-wrap {
        padding-left: 3rem !important;
      }

      .ant-tabs-tabpane {
        padding-left: 3.125rem !important;
        padding-right: 3.125rem !important;
      }

      .ant-tabs-top {
        padding-top: 0.625rem !important;
      }
    }
  `,
  tabLink: css`
    color: ${theme.colors.black};
    :hover {
      color: ${theme.colors.black};
      text-decoration: none;
    }

    :visited {
      color: ${theme.colors.black};
    }
  `,
  formContainer: css`
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 5rem;
    @media ${queries.large} {
      margin-left: 18.225rem;
    }
  `,
  submit: css`
    margin-bottom: 0;
    button {
      width: 100%;
      border-radius: 4px;
      background-color: ${theme.colors.primary.blue};
      font-weight: 600;
      box-shadow: none;
      font-size: 1rem;
    }
  `,
  link: css`
    color: ${theme.colors.primary.blue};
    background-color: unset;
    font-weight: bold;
    padding: 0 !important;

    &:hover {
      color: ${theme.colors.primary.blue};
      background-color: unset;
      padding: 0 !important;
      text-decoration: underline;
    }
  `,
  select: css`
    height: 50px;
  `,
  drawerContainer: css`
    .ant-drawer-header {
      background-color: ${theme.colors.background.white};
      border-bottom: 1px solid #f0f0f0;
      border-radius: 0;
      .ant-drawer-title {
        font-size: 24px !important;
      }
    }
    .ant-drawer-body {
      background-color: ${theme.colors.background.white};
      padding-bottom: 5rem;
    }
  `,
  propertyContent: css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 16px;
    margin-bottom: 1rem;
    span {
      word-break: break-word;
    }
    > span:first-child {
      font-weight: 600;
    }
  `,
  notesContainer: css`
    display: flex;
    flex-direction: column;
  `,
  notesMarginBottom: css`
    margin-bottom: 1rem;
  `,
  noteDate: css`
    font-size: 14px;
    color: ${theme.colors.secondary.sandstoneGrey};
  `,
  viewContactFooter: css`
    display: flex;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    background-color: ${theme.colors.background.white};
    border-top: 1px solid #f0f0f0;
    margin-left: -1.5rem;
    width: -webkit-fill-available;
    div {
      display: flex;
      flex: 1;
      button {
        display: flex;
        align-items: center;
        -webkit-box-shadow: none;
        justify-content: flex-end;
        min-height: 2.6rem;
      }
    }
  `,
  closeButton: css`
    display: flex;
    justify-content: flex-start !important;
    border: none;
    background: none !important;
    border-radius: 4px;
    :hover {
      border: none;
      background: none;
    }
    :focus {
      border: none;
      background: none;
    }
    margin-right: 1rem;
  `,
  manageButton: css`
    border: 2px solid ${theme.colors.primary.blue};
    background: ${theme.colors.primary.blue};
    color: white;
    border-radius: 4px;
    :hover {
      background: ${theme.colors.secondary.navyBlue};
      border: 2px solid ${theme.colors.secondary.navyBlue};
      color: ${theme.colors.white};
    }
    :focus {
      background: ${theme.colors.secondary.navyBlue};
      border: 3px solid ${theme.colors.secondary.navyBlue};
      color: ${theme.colors.white};
    }
    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active {
      background-color: ${theme.colors.background.lightGray} !important;
      border-color: ${theme.colors.background.lightGray};
    }
    align-self: flex-end;
  `,
  contactsTableContainer: css`
    .ant-pagination {
      margin-bottom: 1.375rem;
      .ant-pagination-item-link {
        border: none;
      }
      .ant-pagination-item-active {
        background-color: ${theme.colors.secondary.navyBlue};
        a {
          color: ${theme.colors.white};
          padding-top: 0.0625rem;
        }
      }
      .ant-pagination-item {
        border-radius: 1rem;
        border: none;
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        color: ${theme.colors.secondary.purple};
      }
      .ant-pagination-disabled {
        color: ${theme.colors.background.lightGray};
      }
    }
    .ant-table-container .ant-table-content table {
      thead,
      tbody {
        tr {
          th,
          td {
            padding-left: 16px !important;
            padding-right: 16px !important;
            max-width: 300px;
          }
          th {
            background-color: ${theme.colors.background.white};
          }
        }
      }
    }
    .ant-dropdown {
      right: 70px !important;
    }
  `,
  tableDataValue: css`
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  `,
};
