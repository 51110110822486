import { useEffect } from "react";
import addScriptTagToBody from "src/utils/addScriptTagToBody";

const scriptUrl =
  "https://songtradr.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/6i46lu/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=46dfa63b";

/**
 * We could paste script into index.html with the defer param, but we can
 * somewhat obfuscate the script url by hiding it in the JS bundle
 */
const JiraIssueCollector = (): null => {
  useEffect(() => {
    // We can't remove the script on unmount because the
    // script has side effects IE it creates elements etc
    addScriptTagToBody(scriptUrl);
  }, []);

  return null;
};

export default JiraIssueCollector;
