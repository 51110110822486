import { Progress } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React, { Fragment, ReactElement, useEffect } from "react";
import ValidationErrorIcon from "src/app/assets/icons/component-icons/alert-error";
import UploadCompleteIcon from "src/app/assets/icons/component-icons/upload-complete";
import UploadDeleteIcon from "src/app/assets/icons/component-icons/upload-delete";
import { scrollToHash } from "src/components/tabbed-page-container/helpers";
import theme from "src/theme";
import { IFileUploadStatus } from "../../interfaces";
import styles from "./styles";

interface IProps {
  filesList: UploadFile[];
  uploaderId: string;
  showDragAndDrop: boolean;
  dragAndDropButton: JSX.Element;
  onDeleteFile: (index: number, file?: UploadFile) => Promise<void>;
}

const FileUploadProgress = ({
  filesList,
  uploaderId,
  showDragAndDrop,
  dragAndDropButton,
  onDeleteFile,
}: IProps): ReactElement => {
  useEffect(() => {
    void (() => {
      scrollToHash(uploaderId, 180);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={styles.progressBarContainer}>
      {filesList.map((file: UploadFile, index: number) => {
        const isInvalid: boolean = file.status === IFileUploadStatus.error;

        return (
          <Fragment key={`fileRow${file.uid}`}>
            {file.status !== IFileUploadStatus.done && (
              <div css={styles.fileRow(isInvalid)}>
                <div css={styles.fileName}>{file.name}</div>
                {file.status !== IFileUploadStatus.error && (
                  <div>
                    <Progress
                      key={`progressBar${file.uid}`}
                      css={styles.progressBar}
                      percent={file.percent}
                      strokeColor={`${theme.colors.secondary.cyan}`}
                      showInfo={false}
                    />

                    {file.status === IFileUploadStatus.uploading ? (
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={async (e) => {
                          e.stopPropagation();
                          await onDeleteFile(index, file);
                        }}
                        onKeyDown={async (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            await onDeleteFile(index, file);
                          }
                        }}
                      >
                        <UploadDeleteIcon
                          width="38"
                          height="38"
                          fill={theme.colors.white}
                          stroke={theme.colors.secondary.purple}
                          title="UploadDelete"
                          className="uploadDeleteIconValid"
                          key={`uploadDeleteIconValid${file.uid}`}
                        />
                      </span>
                    ) : (
                      <UploadCompleteIcon
                        width="38"
                        height="38"
                        fill={theme.colors.secondary.cyan}
                        title="UploadComplete"
                        key={`uploadDeleteIconInValid${file.uid}`}
                      />
                    )}
                  </div>
                )}
                {file.status === IFileUploadStatus.error && (
                  <Fragment>
                    <p css={styles.fileUploadErrorMessage}>
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={async (e) => {
                          e.stopPropagation();
                          await onDeleteFile(index);
                        }}
                        onKeyDown={async (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            await onDeleteFile(index);
                          }
                        }}
                      >
                        <ValidationErrorIcon
                          width="16"
                          height="16"
                          fill={theme.colors.functional.red}
                          title="AlertError"
                        />
                        {file.error ? `${String(file.error)}` : ""}
                        <UploadDeleteIcon
                          width="38"
                          height="38"
                          fill={theme.colors.white}
                          stroke={theme.colors.functional.red}
                          title="UploadDelete"
                          className="uploadDeleteIconInValid"
                        />
                      </span>
                    </p>
                  </Fragment>
                )}
              </div>
            )}
          </Fragment>
        );
      })}
      <p css={styles.fileUploadText}>
        {showDragAndDrop ? dragAndDropButton : null}
      </p>
    </div>
  );
};

export default FileUploadProgress;
