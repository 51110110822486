/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { css } from "@emotion/react";
import theme from "src/theme";

export const CustomSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 400,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    flexWrap: "nowrap",
  }),
};

export default {
  modalTitle: css`
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    line-height: 1.75rem !important;
    color: ${theme.colors.secondary.black} !important;
    margin-bottom: 2rem !important;
  `,
  row: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0.1rem !important;

    .floatingLabel {
      margin-bottom: 0;
      label {
        top: 0.5rem;
        color: hsl(0, 0%, 50%);
      }
      .active-focus {
        top: 4px;
      }
    }
  `,
  input: css`
    width: 465px;
    margin-bottom: 0;

    .ant-picker-range {
      height: 40px;
    }
    .ant-picker-dropdown {
      z-index: 1056;
    }
  `,
  button: css`
    float: right;
  `,
  secondaryButton: css`
    border: 1px solid ${theme.colors.primary.blue} !important;
    margin-right: 16px;
  `,
  rightControls: css`
    height: 15px;
    float: right;
  `,
  controls: css`
    .ant-row {
      margin-top: 32px;
    }
  `,
};
