import { Button } from "antd";
import _ from "lodash";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SaveAndCloseIcon from "src/app/assets/icons/component-icons/buttons/save-and-close";
import styles from "./styles";

interface IProps {
  ariaLabel: string;
  label: string;
  dataTestId: string;
  onSaveProject: () => Promise<void>;
}

const SaveAndCloseButton = ({
  ariaLabel,
  label,
  dataTestId,
  onSaveProject,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const debounceProjectRedirect = _.debounce(
    async (e: React.MouseEvent<unknown, MouseEvent>) => {
      const leftMouseButton = 0;
      if (e.button === leftMouseButton) {
        await onSaveProject();
      }
    },
    300
  );
  return (
    <div css={styles.container}>
      <Button
        aria-label={t(ariaLabel)}
        data-testid={dataTestId}
        onMouseDown={(e) => debounceProjectRedirect(e)}
      >
        <div css={styles.iconContainer}>
          {t(label)} <SaveAndCloseIcon width={38} height={38} />
        </div>
      </Button>
    </div>
  );
};

export default SaveAndCloseButton;
