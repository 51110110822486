import React, { Fragment } from "react";
import { TFunction } from "react-i18next";
import MoreInfoButton from "src/components/control-buttons/more-info-button";
import { IOrganisationMemberDataSource } from "src/components/table/interfaces";
import { Checkbox } from "antd";
import style from "../styles";
import {
  IOrganisationMemberIdentifierProps,
  IOrganisationMemberProps,
} from "../interfaces";
import { eApplicationType } from "../../../interfaces/auth";

export default (
  teamMembers: Array<IOrganisationMemberProps>,
  isTablet: boolean,
  isMobile: boolean,
  handleMobileMoreInfoClick: (
    selectedOrgMember: IOrganisationMemberIdentifierProps
  ) => void,
  handleToggleAdmin: (selectedOrgMember: IOrganisationMemberProps) => void,
  t: TFunction<string>
): Array<IOrganisationMemberDataSource> => {
  if (isMobile) {
    return teamMembers?.map((member: IOrganisationMemberProps) => {
      const isAdmin = member.applications.includes(
        eApplicationType.TeamManagement
      );
      return {
        key: member.key,
        id: member.id,
        name: (
          <Fragment>
            <div css={style.teamName}>
              {member.firstName} {member.lastName}
              {isAdmin && (
                <span css={style.teamAdminPill}>
                  {t("manageTeam##members##ADMIN")}
                </span>
              )}
            </div>
            <div css={style.teamEmail}>{member.email}</div>
          </Fragment>
        ),
        ellipsis: (
          <div css={style.moreInfo}>
            <MoreInfoButton onClick={() => handleMobileMoreInfoClick(member)} />
          </div>
        ),
      };
    });
  }

  if (isTablet) {
    return teamMembers?.map((orgMember: IOrganisationMemberProps) => {
      const {
        key,
        id,
        firstName,
        lastName,
        email,
        jobTitle,
        phoneNumber,
        isCurrentUser,
      } = orgMember;
      const isAdmin = orgMember.applications.includes(
        eApplicationType.TeamManagement
      );
      return {
        key,
        id,
        name: (
          <Fragment>
            <div css={style.teamName}>
              {firstName} {lastName}
              {isAdmin && (
                <span css={style.teamAdminPill}>
                  {t("manageTeam##members##ADMIN")}
                </span>
              )}
            </div>
            <div css={style.teamEmail}>{email}</div>
          </Fragment>
        ),
        jobTitle: (
          <Fragment>
            {jobTitle ? t(`requesterRoles##${jobTitle}`) : jobTitle}
          </Fragment>
        ),
        phoneNumber,
        isAdmin: (
          <div css={style.teamAdminCol}>
            <Checkbox
              disabled={isCurrentUser}
              checked={isAdmin}
              onClick={() => handleToggleAdmin(orgMember)}
            />
          </div>
        ),
        ellipsis: (
          <div css={style.moreInfo}>
            <MoreInfoButton
              onClick={() => handleMobileMoreInfoClick(orgMember)}
            />
          </div>
        ),
      };
    });
  }

  return teamMembers?.map((orgMember: IOrganisationMemberProps) => {
    const {
      key,
      id,
      firstName,
      lastName,
      email,
      isCurrentUser,
      jobTitle,
      phoneNumber,
    } = orgMember;
    const isAdmin = orgMember.applications.includes(
      eApplicationType.TeamManagement
    );
    return {
      key,
      id,
      name: (
        <Fragment>
          <div css={style.teamName}>
            {firstName} {lastName}
            {isAdmin && (
              <span css={style.teamAdminPill}>
                {t("manageTeam##members##ADMIN")}
              </span>
            )}
          </div>
          <div css={style.teamEmail}>{email}</div>
        </Fragment>
      ),
      jobTitle: (
        <Fragment>
          {jobTitle ? t(`requesterRoles##${jobTitle}`) : jobTitle}
        </Fragment>
      ),
      phoneNumber,
      isAdmin: (
        <div css={style.teamAdminCol}>
          <Checkbox
            disabled={isCurrentUser}
            checked={isAdmin}
            onClick={() => handleToggleAdmin(orgMember)}
            data-testid="admin-checkbox"
          />
        </div>
      ),
    };
  });
};
