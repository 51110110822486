import { Progress, Typography } from "antd";
import {
  add,
  format,
  formatDistanceStrict,
  getUnixTime,
  intervalToDuration,
  parseISO,
} from "date-fns";
import React, { ReactElement, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { LicenseFixedDurations } from "src/constants";
import { IProjectTerm } from "src/pages/projects/project/interfaces";
import theme from "src/theme";
import styles from "../../styles";

interface IProps {
  term: IProjectTerm;
}

enum TermStatus {
  ACTIVE = "ACTIVE",
  RENEW = "RENEW",
  URGENT = "URGENT",
  EXPIRED = "EXPIRED",
}

const styleMap = new Map([
  [TermStatus.ACTIVE, styles.active],
  [TermStatus.RENEW, styles.renew],
  [TermStatus.URGENT, styles.urgent],
  [TermStatus.EXPIRED, styles.expired],
]);

const determineTermStatus = (endDate?: string) => {
  const now = new Date();
  if (!endDate) {
    return TermStatus.EXPIRED;
  }
  if (parseISO(endDate) < now) {
    return TermStatus.EXPIRED;
  }
  const durationRemaining = intervalToDuration({
    start: now,
    end: parseISO(endDate),
  });
  if (durationRemaining.years || durationRemaining.months) {
    return TermStatus.ACTIVE;
  }
  if (durationRemaining.days && durationRemaining.days > 7) {
    return TermStatus.RENEW;
  }
  return TermStatus.URGENT;
};

const strokeColor = (status: TermStatus) => {
  switch (status) {
    case TermStatus.ACTIVE:
      return theme.colors.functional.green;
    case TermStatus.EXPIRED:
      return theme.colors.background.lightGray;
    case TermStatus.RENEW:
      return theme.colors.functional.orange;
    case TermStatus.URGENT:
      return theme.colors.functional.red;
    default:
      return theme.colors.background.veryLightGray;
  }
};

const trailColor = (status: TermStatus) => {
  switch (status) {
    case TermStatus.ACTIVE:
      return "#DCFDD8";
    case TermStatus.EXPIRED:
      return "#C4C4C4";
    case TermStatus.RENEW:
      return "#FA961433";
    case TermStatus.URGENT:
      return "#CC00001A";
    default:
      return theme.colors.background.veryLightGray;
  }
};

const calculateEndDate = (
  startDate: string,
  duration: LicenseFixedDurations
) => {
  switch (duration) {
    case LicenseFixedDurations.ThreeMonths:
      return format(add(parseISO(startDate), { months: 3 }), "MMM dd, yyyy");
    case LicenseFixedDurations.SixMonths:
      return format(add(parseISO(startDate), { months: 6 }), "MMM dd, yyyy");
    case LicenseFixedDurations.OneYear:
      return format(add(parseISO(startDate), { years: 1 }), "MMM dd, yyyy");
    case LicenseFixedDurations.EighteenMonths:
      return format(add(parseISO(startDate), { months: 18 }), "MMM dd, yyyy");
    default:
      return "";
  }
};

const TermProgress = ({ term }: IProps): ReactElement => {
  const { t } = useTranslation();
  const now = new Date();
  const unixStart =
    term.startDate && getUnixTime(parseISO(term.startDate)) * 1000;

  const fixedDurationEndDate =
    term.startDate &&
    term.fixedDuration &&
    calculateEndDate(term.startDate, term.fixedDuration);

  const unixEnd = term.endDate
    ? getUnixTime(parseISO(term.endDate)) * 1000
    : fixedDurationEndDate &&
      getUnixTime(parseISO(new Date(fixedDurationEndDate).toISOString())) *
        1000;

  const total = unixEnd && unixStart && unixEnd - unixStart;
  const current = unixStart && now.getTime() - unixStart;
  const percentage = current && total && Math.round((current / total) * 100);

  let timeRemaining = term.endDate
    ? formatDistanceStrict(parseISO(term.endDate), now)
    : fixedDurationEndDate &&
      formatDistanceStrict(new Date(fixedDurationEndDate), now);

  const status = determineTermStatus(
    term.endDate
      ? term.endDate
      : fixedDurationEndDate && new Date(fixedDurationEndDate).toISOString()
  );

  if (status === TermStatus.EXPIRED) {
    timeRemaining = "0 days";
  }

  if (term.fixedDuration === LicenseFixedDurations.InPerpetuity) {
    return (
      <Fragment>
        <Typography css={styles.startDate}>
          {term.startDate && format(parseISO(term.startDate), "MMM dd, yyyy")}
        </Typography>
        <Progress
          strokeColor={theme.colors.secondary.lightBlue}
          trailColor={theme.colors.secondary.lightBlue}
          percent={percentage || 0}
          showInfo={false}
        />
        <Typography css={styles.duration}>{term.fixedDuration}</Typography>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Typography css={styles.startDate}>
        {term.startDate && format(parseISO(term.startDate), "MMM dd, yyyy")}
      </Typography>
      <Typography css={styles.endDate}>
        {term.endDate
          ? format(parseISO(term.endDate), "MMM dd, yyyy")
          : fixedDurationEndDate}
      </Typography>
      <Progress
        strokeColor={status && strokeColor(status)}
        trailColor={status && trailColor(status)}
        percent={percentage || 0}
        showInfo={false}
      />
      <Typography css={styles.duration}>{term.fixedDuration}</Typography>
      <div css={[styles.statusChip, status && styleMap.get(status)]}>
        <div css={styles.timeRemaining}>
          <span className="circle" />
          <div>
            {status} <strong>{timeRemaining} </strong>{" "}
            {t("ProjectsPage##remaining")}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TermProgress;
