import { css, SerializedStyles } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import { StylesConfig } from "react-select";
import theme from "src/theme";

type MyOptionType = {
  label: string;
  value: string;
};

export default {
  buttonRow: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row nowrap;
  `,
  standardInput: css`
    max-width: 25.625rem;
  `,
  mobileRadioGroupContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  `,

  scriptNameInput: css`
    margin-bottom: 1rem;
    width: 345px;
  `,
  filmOrScriptsContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    @media ${queries.large} {
      flex-direction: row;
      column-gap: 1.5rem;
    }
  `,
  checkBoxText: css`
    display: flex;
    flex-direction: row;
    align-content: items;
    font-weight: 400;
    @media ${queries.large} {
      display: inline-box;
    }
  `,
  durationInput: css`
    max-width: 150px !important;
  `,
  duration: css`
    margin-left: 16px;
  `,
  submitButton: css`
    float: right;
  `,
  licenseNoteContainer: css`
    display: flex;
    column-gap: 0.8rem;
    flex-direction: column;
    @media ${queries.large} {
      flex-direction: row;
      align-items: flex-end;
    }
  `,
  secondaryButton: css`
    font-size: 1rem;
    align-self: center;
    justify-content: center;
    height: 100%;
    border-radius: 0;
    padding: 0;
    background-color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-weight: bold;
    border: none;
    color: ${theme.colors.primary.blue};
    cursor: pointer;
    box-shadow: none;

    :hover {
      color: #0d2481;
      font-weight: bold;
      background-color: unset;
    }

    :focus {
      outline-style: unset;
    }
  `,
  cancelButton: css`
    height: 50px;
    width: 98px;
    margin-right: 1.0625rem;
    vertical-align: middle;
    background-color: #fff !important;
    :hover {
      color: ${theme.colors.secondary.darkBlue} !important;
    }
  `,
  termSubHeader: css`
    margin-bottom: 0.9rem;
    margin-top: 0.5rem;
    margin-left: -0.1rem;

    span {
      font-size: 1rem;
      color: ${theme.colors.secondary.darkBlue};
      font-weight: bold;
    }
  `,
  mobileRadioButtonSection: css`
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .ant-radio-wrapper {
      font-size: 16px;
      font-weight: 400;
    }
  `,
  radioButtonSection: css`
    margin-left: 0.2rem;
    .ant-radio {
      margin-right: 0.5rem;
    }
    .ant-radio-wrapper {
      margin-right: 2rem;
      font-size: 16px;
      font-weight: 400;
    }
  `,

  filmsScripts: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    .ant-select-arrow {
      width: auto;
    }
  `,
  includeCutdown: css`
    margin-left: 1.5rem;
    margin-top: 7.5px;
  `,
  footer: css`
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    flex-flow: row nowrap;
    float: right;
  `,
  radioGroupContainer: css`
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;
  `,
  row: css`
    width: 100%;
  `,
  radioBtn: css`
    .ant-radio {
      margin-bottom: 4px;
      margin-right: 6px;
    }
  `,
  dropdownFloatingLabel: css`
    width: 100%;
  `,
  noTrackText: css`
    color: #888491 !important;
  `,
  startLicenseDate: css`
    .startDate {
      width: 12.5rem !important;
      margin-right: 1.5rem;
    }
  `,
  licensingPeriodContainer: css`
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    flex-wrap: wrap;
  `,
  licensingPeriod(showEndDate: boolean): SerializedStyles {
    return css`
      display: flex;
      flex: 1;
      width: 100%;
      height: 56px !important;
      .ant-select-selector {
        ${showEndDate
          ? "border-radius: 0.25rem 0 0 0.25rem !important;"
          : "unset;"}
      }
      .ant-input,
      .ant-picker,
      .ant-input-password,
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 56px !important;
      }
      .ant-picker {
        &.ant-picker-disabled {
          border: 0.0625rem solid var(--colors-light-gray);
        }

        &.endDate {
          margin-left: -0.063rem;

          &:focus {
            z-index: 1000;
          }

          &:hover {
            border-left: 1rem;
          }

          @media ${queries.medium} {
            width: 12.5rem !important;
          }
        }
      }

      .ant-form-item-explain {
        margin-top: 0.3rem;
        height: 0.938rem;
        color: ${theme.colors.functional.red};
        font-family: ${theme.fonts.sofiaPro};
        letter-spacing: 0;
        line-height: 0.938rem;
      }

      .ant-form-item-has-error .ant-input-number,
      .ant-form-item-has-error .ant-picker {
        border-color: ${theme.colors.functional.red} !important;
      }

      .duration {
        &:hover,
        &:focus,
        &:active {
          z-index: 2000;
        }
      }

      .ant-picker-input > input[disabled] {
        color: ${theme.colors.black};
      }
    `;
  },
  durationEndDate: css`
    display: flex;
  `,
  iconContainer(disable: boolean): SerializedStyles {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      ${disable ? "pointer-events: none;" : ""}
    `;
  },
  iconContainerPointer(disable: boolean): SerializedStyles {
    return css`
      cursor: ${disable ? "not-allowed" : "pointer"};
    `;
  },
  addScriptButton: css`
    display: flex;
    align-items: center;
    justify-content: left;
    margin-right: 0.5rem;
  `,
  deleteButton: css`
    padding-top: 40px;
    margin-left: 1.5rem;
  `,
  scriptNo: css`
    width: 138px;
    margin-left: 112px;
  `,
};

export const customStyles: StylesConfig<MyOptionType> = {
  menu: (provided) => ({
    ...provided,
    fontWeight: 400,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
    marginTop: -8,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 4,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
    color: `${theme.colors.primary.blue}`,

    ":hover": {
      color: `${theme.colors.secondary.lightGray}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 36,
    fontWeight: 400,
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    height: 56,
    maxHeight: 56,
  }),
};
