import { Input, Typography } from "antd";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "@songtradr/spa-common";
import style from "src/pages/projects/project/styles/index";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import ReactSelect from "react-select";
import { customStyles } from "src/pages/projects/project/components/details/styles/index";
import { IFormInvoicee } from "src/pages/projects/project/interfaces";
import { IOption } from "../../../services/types";

interface IContactInvoiceDetails {
  invoicee: IFormInvoicee;
  onUpdateInvoicee: (propertyName: string, value: string) => void;
}

const ContactInvoiceDetails = ({
  invoicee,
  onUpdateInvoicee,
}: IContactInvoiceDetails): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const { talentHubTypes } = useGlobalStates();
  const [invoiceeCountry, setInvoiceeCountry] = useState<IOption>();

  const getMappedInvoiceCountry = useCallback(
    (country: string) => {
      return talentHubTypes.countryCodes.find(
        (countryCode) => countryCode.value === country
      );
    },
    [talentHubTypes.countryCodes]
  );

  useEffect(() => {
    setInvoiceeCountry(getMappedInvoiceCountry(invoicee?.country));
  }, [getMappedInvoiceCountry, invoicee?.country]);

  const handleChangeInvoice = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onUpdateInvoicee(event.target.name, event.target.value);
    },
    [onUpdateInvoicee]
  );

  const handleChangeInvoiceeCountry = useCallback(
    (data: IOption) => {
      onUpdateInvoicee("country", data?.value ?? null);
    },
    [onUpdateInvoicee]
  );
  return (
    <div css={style.rowGap}>
      <div
        css={isMobile ? style.mobileSectionContainer : style.clientContainer}
      >
        <div>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##contactsSection##Invoicee")}
            </Typography.Text>
          </div>
          <Input
            type="text"
            name="name"
            onChange={handleChangeInvoice}
            value={invoicee?.name ?? ""}
          />
        </div>
        <div>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##contactsSection##Invoicee Address")}
            </Typography.Text>
          </div>
          <Input
            type="text"
            name="address"
            onChange={handleChangeInvoice}
            value={invoicee?.address ?? ""}
          />
        </div>
        <div>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##contactsSection##Invoicee Country")}
            </Typography.Text>
          </div>
          <ReactSelect
            isClearable
            isSearchable
            value={invoiceeCountry}
            name="country"
            placeholder=""
            options={talentHubTypes.countryCodes}
            onChange={(data) => handleChangeInvoiceeCountry(data as IOption)}
            styles={customStyles}
          />
        </div>
      </div>
      <div
        css={[
          isMobile ? style.mobileSectionContainer : style.clientContainer,
          style.sizeOfTwoThirdsDiv,
        ]}
      >
        <div>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##contactsSection##Invoicee PO")}
            </Typography.Text>
          </div>
          <Input
            type="text"
            name="po"
            onChange={handleChangeInvoice}
            value={invoicee?.po ?? ""}
          />
        </div>
        <div>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##contactsSection##Invoicee Job No")}
            </Typography.Text>
          </div>
          <Input
            name="jobNo"
            type="text"
            onChange={handleChangeInvoice}
            value={invoicee?.jobNo ?? ""}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactInvoiceDetails;
