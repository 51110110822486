import React, { ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Typography } from "antd";
import Logo from "src/components/logo";
import styles from "./styles";

const PageFlourish = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div css={styles.flourish}>
      <div css={styles.logoWrapper}>
        <Logo />
      </div>
      <Typography.Title css={styles.title}>
        {t("Smart music management starts here")}
      </Typography.Title>
      <Typography.Paragraph css={styles.description}>
        <Trans i18nKey="signUpText" />
      </Typography.Paragraph>
    </div>
  );
};

export default PageFlourish;
