import { Col, Row, Typography } from "antd";
import { DebouncedFunc } from "lodash";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "src/app/assets/icons/component-icons";
import { IProjectUploadFile } from "src/pages/projects/project/components/details/components/interfaces";
import { IProjectAttachment } from "src/pages/projects/project/interfaces";
import theme from "src/theme";
import styles from "./styles";

interface IProps {
  purchaseOrders: IProjectAttachment[];
  invoices: IProjectAttachment[];
  licenses: IProjectAttachment[];
  estimates: IProjectAttachment[];
  miscellaneous: IProjectAttachment[];
  assets: IProjectAttachment[];
  downloadFile?: DebouncedFunc<(file: IProjectUploadFile) => Promise<void>>;
}

const Documents = ({
  purchaseOrders = [],
  invoices = [],
  licenses = [],
  estimates = [],
  miscellaneous = [],
  assets = [],
  downloadFile,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const handleDownloadFile = async (data: IProjectAttachment) => {
    if (downloadFile) {
      await downloadFile(data);
    }
  };

  return (
    <div css={styles.documentsContainer}>
      <div css={styles.verticalTitleContainer}>
        <Typography.Title css={styles.verticalTitle}>
          {t("ProjectsPage##Documents")}
        </Typography.Title>
      </div>
      <div css={styles.documentsContentContainer}>
        <Row gutter={[8, 16]}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Typography.Text css={styles.documentsTitle}>
              {t("ProjectsPage##Purchase orders")}
            </Typography.Text>
            {purchaseOrders.map((data) => {
              return (
                <div css={styles.linkWrapper} key={data.id}>
                  <button
                    onClick={() => handleDownloadFile(data)}
                    css={styles.assetLink}
                    type="button"
                  >
                    {data.name}
                    <DownloadIcon
                      css={styles.openNewIcon}
                      fill={theme.colors.primary.blue}
                    />
                  </button>
                </div>
              );
            })}
          </Col>
          <hr css={styles.horizontalRule} />
          <Col xs={24} sm={24} md={8} lg={8}>
            <Typography.Text css={styles.documentsTitle}>
              {t("ProjectsPage##Invoices")}
            </Typography.Text>
            {invoices.map((data) => {
              return (
                <div css={styles.linkWrapper} key={data.id}>
                  <button
                    onClick={() => handleDownloadFile(data)}
                    css={styles.assetLink}
                    type="button"
                  >
                    {data.name}
                    <DownloadIcon
                      css={styles.openNewIcon}
                      fill={theme.colors.primary.blue}
                    />
                  </button>
                </div>
              );
            })}
          </Col>
          <hr css={styles.horizontalRule} />
          <Col xs={24} sm={24} md={8} lg={8}>
            <Typography.Text css={styles.documentsTitle}>
              {t("ProjectsPage##License")}
            </Typography.Text>
            {licenses.map((data) => {
              return (
                <div css={styles.linkWrapper} key={data.id}>
                  <button
                    onClick={() => handleDownloadFile(data)}
                    css={styles.assetLink}
                    type="button"
                  >
                    {data.name}
                    <DownloadIcon
                      css={styles.openNewIcon}
                      fill={theme.colors.primary.blue}
                    />
                  </button>
                </div>
              );
            })}
          </Col>
          <hr css={styles.tabletHorizontalRule} />
          <hr css={styles.horizontalRule} />
          <Col xs={24} sm={24} md={8} lg={8} css={styles.columnWrapper}>
            <Typography.Text css={styles.documentsTitle}>
              {t("ProjectsPage##Estimates")}
            </Typography.Text>
            {estimates.map((data) => {
              return (
                <div css={styles.linkWrapper} key={data.id}>
                  <button
                    onClick={() => handleDownloadFile(data)}
                    css={styles.assetLink}
                    type="button"
                  >
                    {data.name}
                    <DownloadIcon
                      css={styles.openNewIcon}
                      fill={theme.colors.primary.blue}
                    />
                  </button>
                </div>
              );
            })}
          </Col>
          <hr css={styles.horizontalRule} />
          <Col xs={24} sm={24} md={8} lg={8} css={styles.columnWrapper}>
            <Typography.Text css={styles.documentsTitle}>
              {t("ProjectsPage##Miscellaneous")}
            </Typography.Text>
            {miscellaneous.map((data) => {
              return (
                <div css={styles.linkWrapper} key={data.id}>
                  <button
                    onClick={() => handleDownloadFile(data)}
                    css={styles.assetLink}
                    type="button"
                  >
                    {data.name}
                    <DownloadIcon
                      css={styles.openNewIcon}
                      fill={theme.colors.primary.blue}
                    />
                  </button>
                </div>
              );
            })}
          </Col>
          <hr css={styles.horizontalRule} />
          <Col xs={24} sm={24} md={8} lg={8} css={styles.columnWrapper}>
            <Typography.Text css={styles.documentsTitle}>
              {t("ProjectsPage##Assets")}
            </Typography.Text>
            {assets.map((data) => {
              return (
                <div css={styles.linkWrapper} key={data.id}>
                  <button
                    onClick={() => handleDownloadFile(data)}
                    css={styles.assetLink}
                    type="button"
                  >
                    {data.name}
                    <DownloadIcon
                      css={styles.openNewIcon}
                      fill={theme.colors.primary.blue}
                    />
                  </button>
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Documents;
