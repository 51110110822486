import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  floatingLabelUrl: css`
    width: 100%;
    position: relative;
    color: ${theme.colors.background.darkGray};
    input,
    textarea {
      padding-top: 1rem;
    }
  `,
  invisibleContainer: css`
    visibility: hidden;
  `,
  container: css`
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .floatingLabel {
      margin-bottom: 0 !important;
      @media ${queries.medium} {
        margin-bottom: 0.781rem !important;
      }
    }

    @media ${queries.medium} {
      padding-left: 0.9rem !important;
      padding-right: 0.9rem !important;
    }

    .ant-divider {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 1rem !important;
      border-top: 0 !important;

      ::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid #c4c4c4;
      }
    }

    .ant-row {
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;

      & .ant-divider {
        margin-top: 0 !important;
      }
      :nth-of-type(1) {
        & .ant-divider {
          margin-top: 1rem !important;
          margin-bottom: 1.8rem !important;
        }
      }
    }
  `,
  secondaryFormSectionContainer: css`
    margin-bottom: 0.5rem;
  `,
  secondaryFormSectionHeader: css`
    font-size: 1rem;
    color: ${theme.colors.secondary.blueOnBlack};
    font-weight: bold;
    @media ${queries.medium} {
      font-size: 1rem;
    }
  `,
  additionalContainer: css`
    @media ${queries.large} {
      margin-left: 0.8rem !important;
      margin-bottom: 1rem !important;
    }

    @media ${queries.medium} {
      margin-left: 0.8rem !important;
      margin-bottom: 1rem !important;
    }

    @media ${queries.extraSmall} {
      margin-left: 0.1rem;
      margin-bottom: 1.5rem;
    }
  `,
  additionalText: css`
    font-size: 1rem;
    line-height: 24px;
    color: ${theme.colors.secondary.sandstoneGrey};
  `,

  input: css`
    input {
      border: 1px solid ${theme.colors.background.lightGray};
      height: 3.125rem;
      border-radius: 0.25rem;
    }
  `,
  deleteIconContainer: css`
    padding-top: 0 !important;
    justify-content: end;
    display: flex;
    flex-direction: column;

    @media ${queries.medium} {
      padding-top: 0.7rem;
    }
  `,
  addIconContainer: css`
    justify-content: end;
    display: flex;
    flex-direction: column;
    > div {
      height: 38px;
    }
    svg {
      color: #222 !important;
      width: 34px;
      padding: 0.5rem;
      height: 34px;
    }
  `,
  iconContainer: css`
    margin-right: 0.5rem;
    margin-left: 0.4rem;
    margin-top: 0.5rem;
    padding-top: 0 !important;

    @media ${queries.medium} {
      margin-left: 0.4rem;
      margin-bottom: 1rem;
      margin-top: 0;
    }
  `,
  mobileDelete: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  `,
  musicSectionContainer: css`
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    align-items: flex-end;
    flex-direction: row;
  `,
  mobileMusicSectionContainer: css`
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    width: 100% !important;
    flex-direction: column;
  `,
  iconsContainer: css`
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-self: center;
  `,
};
