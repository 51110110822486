import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common";

export default {
  wrapper: css`
    padding-left: 1.0625rem;
    padding-top: 2rem;
    padding-right: 1rem;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background: #0c142a;
    @media ${queries.medium} {
      padding-left: 2.1875rem;
      padding-right: 2rem;
      padding-top: 1rem;
    }
    @media ${queries.large} {
      padding-left: 8.125rem;
      padding-right: 5.1875rem;
    }
  `,
  label: css`
    color: ${theme.colors.white};
    opacity: 0.7;
    font-size: 0.875rem;
    padding-bottom: 0.9375rem;
    @media ${queries.medium} {
      padding-top: 2rem;
    }
  `,
  value: css`
    color: ${theme.colors.white};
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 1.5rem;
    a {
      color: ${theme.colors.white};
      &:hover {
        color: ${theme.colors.white};
      }
    }
  `,
  horizontalRule: css`
    border: 0.0625rem solid ${theme.colors.white};
    opacity: 18%;
    width: 100%;
    @media ${queries.large} {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  `,
  bold: css`
    font-weight: 700;
    @media ${queries.medium} {
      padding-bottom: 2.6875rem;
    }
    @media ${queries.large} {
      padding-bottom: 5rem;
    }
  `,
  openNewIcon: css`
    padding-left: 0.5rem;
  `,
};
