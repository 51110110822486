import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import { Dropdown, Typography } from "antd";
import { debounce } from "lodash";
import React, { Fragment, ReactElement, useCallback } from "react";
import { TFunction, useTranslation } from "react-i18next";
import styles from "src/pages/projects/project/components/details/styles";
import {
  handleFileDownload,
  handleDeleteClick,
  handleShareToggle,
} from "src/components/file-upload/helpers";
import dayjs from "dayjs";
import MenuItem from "src/components/menu-item";
import { IDeleteSelectedFile } from "src/components/file-upload/interfaces";
import { SuccessToast, ErrorToast } from "src/components/toast-notification";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import MoreInfoButton from "src/components/control-buttons/more-info-button";
import ShareButton from "src/components/control-buttons/share-button";
import useProject from "src/providers/project/hooks";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import useAuth from "src/auth/use-auth";
import copy from "copy-to-clipboard";
import config from "src/config";
import { FileDrawerTypes } from "../components/mobile-drawers";
import { IProjectUploadFile } from "../../details/components/interfaces";

export interface IFileDataSource {
  key: string;
  name?: string;
  date?: string;
  uploader?: string;
  fileInfo?: JSX.Element;
  share?: ReactElement;
  menu: string | JSX.Element;
  handleRowDownloadClick?: () => Promise<void> | undefined;
}

export const DocumentsTableColumns = (
  isMobile: boolean,
  t: TFunction<string>
): Array<IColumnsData> => {
  if (isMobile) {
    return [
      {
        title: <Fragment />,
        dataIndex: "fileInfo",
        key: "key",
        width: "80%",
      },
      {
        title: <Fragment />,
        dataIndex: "menu",
        key: "key",
        width: "20%",
      },
    ];
  }

  return [
    {
      title: <strong>{t("ProjectsPage##Name")}</strong>,
      dataIndex: "name",
      key: "key",
      width: "35%",
    },
    {
      title: <strong>{t("ProjectsPage##Uploader")}</strong>,
      dataIndex: "uploader",
      key: "key",
      width: "20%",
    },
    {
      title: <strong>{t("ProjectsPage##Date uploaded")}</strong>,
      dataIndex: "date",
      key: "key",
      width: "40%",
    },
    {
      title: <Fragment />,
      dataIndex: "share",
      key: "key",
      width: "5%",
    },
    {
      title: <Fragment />,
      dataIndex: "menu",
      key: "key",
      width: "5%",
    },
  ];
};

interface IProps {
  filesList: IProjectUploadFile[];
  accessToken: string;
  projectId: string;
  organisationId: string;
  deleteAvailableForAttachmentType?: string;
  setShowModal: (show: boolean) => void;
  setSelectedFileToDelete: (file: IDeleteSelectedFile | undefined) => void;
  toggleDrawer: () => void;
  setActiveDrawer: (drawerType: FileDrawerTypes) => void;
  setActiveFile: (file: IProjectUploadFile | undefined) => void;
}

export const DocumentsTableData = ({
  filesList,
  accessToken,
  projectId,
  organisationId,
  deleteAvailableForAttachmentType,
  setShowModal,
  setSelectedFileToDelete,
  toggleDrawer,
  setActiveDrawer,
  setActiveFile,
}: IProps): Array<IFileDataSource> => {
  const { t } = useTranslation();
  const { storeProject } = useProject();
  const { fetchWrapper, isSessionValid } = useAuth();
  const isMobile = getIsMobile();
  useWindowSize();
  const downloadFile = debounce(
    async (
      file: IProjectUploadFile,
      isMenuItemDownload = true
    ): Promise<void> => {
      if (isMenuItemDownload) {
        SuccessToast(t("File downloading to your file storage"));
      }
      const isSession = await isSessionValid();
      if (!isSession) return;
      const downloadFileRequest = await fetchWrapper(
        handleFileDownload,
        file,
        projectId,
        organisationId,
        isMenuItemDownload
      );

      if (!downloadFileRequest) {
        ErrorToast(
          `FileUploadErrorToast${file.uid}`,
          t("There was a problem downloading the file")
        );
      }
    },
    500
  );

  const toggleShareStatus = useCallback(
    (attachmentId: string, value: boolean) => {
      handleShareToggle(
        attachmentId,
        accessToken,
        projectId,
        organisationId,
        value,
        storeProject
      ).catch((e) => {
        log(DataDogLogTypes.ERROR, `Error sharing file: ${attachmentId}`, e);
        ErrorToast(
          `FileShareErrorToast${attachmentId}`,
          t("There was a problem sharing this file")
        );
      });
    },
    [accessToken, organisationId, projectId, storeProject, t]
  );

  const handleCopyLinkToClipboard = useCallback(
    (attachmentId: string) => {
      const attachmentGenerateUrl = `${config.projectManagementService.url}/projects/${projectId}/attachments/${attachmentId}/download`;
      copy(attachmentGenerateUrl);
      SuccessToast(t("Copied link to clipboard"));
    },
    [projectId, t]
  );

  const handleCopyDownloadLinkToClipboard = useCallback(
    (attachmentId: string) => {
      const locationOrigin = window.location.origin;
      const pathName = window.location.pathname;
      const fullUrl = `${locationOrigin}${pathName}/file/${attachmentId}`;
      copy(fullUrl);
      SuccessToast(t("Copied download link to clipboard"));
    },
    [t]
  );

  const getFileShareLabel = useCallback(
    (isPublic?: boolean) =>
      isPublic ? "Unshare with client" : "Share with client",
    []
  );
  return filesList.map(
    (file: IProjectUploadFile, index: number): IFileDataSource => {
      const isDeleteFileAvailable =
        (deleteAvailableForAttachmentType &&
          file.attachmentType === deleteAvailableForAttachmentType) ||
        !deleteAvailableForAttachmentType;
      const uploadedDate = dayjs(file.uploaded?.dateTime)
        .format("MMM D, YYYY [at] h:mma")
        .toString();

      const DropdownMenu = () => {
        const items = [
          {
            key: "File share",
            label: (
              <MenuItem
                ariaLabel={getFileShareLabel(file.public)}
                buttonLabel={getFileShareLabel(file.public)}
                customStyles={styles.actionButton}
                onClick={() => {
                  SuccessToast(
                    file.public ? t("File unshared") : t("File shared")
                  );
                  toggleShareStatus(file.uid, !file.public);
                }}
              />
            ),
          },
          {
            key: "Download",
            label: (
              <MenuItem
                ariaLabel="Download"
                buttonLabel="Download"
                customStyles={styles.actionButton}
                onClick={async () => {
                  await downloadFile(file);
                }}
              />
            ),
          },
          {
            key: "Copy link",
            label: (
              <MenuItem
                ariaLabel="Copy link"
                buttonLabel="Copy link"
                customStyles={styles.actionButton}
                onClick={() => {
                  handleCopyLinkToClipboard(file.uid);
                }}
              />
            ),
          },
          {
            key: "Copy download link",
            label: (
              <MenuItem
                ariaLabel="Copy download link"
                buttonLabel="Copy download link"
                customStyles={styles.actionButton}
                onClick={() => {
                  handleCopyDownloadLinkToClipboard(file.uid);
                }}
              />
            ),
          },
        ];
        if (isDeleteFileAvailable) {
          const dropdownDeleteItem = {
            label: (
              <MenuItem
                ariaLabel="Delete"
                buttonLabel="Delete"
                customStyles={styles.actionButton}
                onClick={() => {
                  setSelectedFileToDelete({ index, file });
                  handleDeleteClick(setShowModal);
                }}
              />
            ),
            key: "Delete",
          };
          items.push(dropdownDeleteItem);
        }

        return (
          <Dropdown
            menu={{ items }}
            css={styles.dropdownMenu}
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode}
            placement="bottomRight"
          >
            <MoreInfoButton
              showTooltip
              customStyles={styles.moreInfoButton}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        );
      };

      if (isMobile) {
        return {
          key: `uploadTableRow${file.uid}`,
          fileInfo: (
            <div css={styles.fileUploadMobile}>
              <Typography.Title>{t("ProjectsPage##Name")}</Typography.Title>
              <Typography.Text>{file.name}</Typography.Text>
              <Typography.Title>
                {t("ProjectsPage##Uploaded by")}
              </Typography.Title>
              <Typography.Text>{file.uploaded?.userFullname}</Typography.Text>
              <Typography.Title>
                {t("ProjectsPage##Date uploaded")}
              </Typography.Title>
              <Typography.Text>{uploadedDate}</Typography.Text>
              {file.public && (
                <div css={styles.mobileShareButtonContainer}>
                  <ShareButton
                    customStyles={styles.moreInfoButton}
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      toggleShareStatus(file.uid, false);
                    }}
                    label={t("Unshare with client")}
                  />
                </div>
              )}
            </div>
          ),
          menu: (
            <div css={styles.mobileMenuContainer}>
              <MoreInfoButton
                showTooltip
                customStyles={styles.moreInfoButton}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  setActiveDrawer(FileDrawerTypes.FileInfoDrawer);
                  setSelectedFileToDelete({ index, file });
                  setActiveFile(file);
                  toggleDrawer();
                }}
              />
            </div>
          ),
        };
      }

      return {
        key: `uploadTableRow${file.uid}`,
        name: file.name,
        uploader: file.uploaded?.userFullname || "",
        date: uploadedDate || "",
        share: file.public ? (
          <ShareButton
            customStyles={styles.moreInfoButton}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              toggleShareStatus(file.uid, false);
              SuccessToast(t("File unshared"));
            }}
            label={t("Unshare with client")}
          />
        ) : undefined,
        menu: <DropdownMenu />,
        handleRowDownloadClick: () => downloadFile(file, false),
      };
    }
  );
};
