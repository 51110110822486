import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common/lib/utils";
import DeleteButton from "src/components/control-buttons/delete-button";
import ControlButton from "src/components/control-buttons/base-button";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./styles";
import mainStyles from "../../../styles";

interface IProps {
  data: string;
  index: number;
  totalRows: number;
  fieldName: string;
  onDeleteRow: (index: number) => void;
  onUpdateRow: (index: number, value: string) => void;
  onAddNewRow: () => void;
}

const ShowReelLinkRow = ({
  data,
  index,
  totalRows,
  fieldName,
  onAddNewRow,
  onDeleteRow,
  onUpdateRow,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  useWindowSize();
  const isMobile = getIsMobile();

  const showDeleteButton = useMemo(
    () => index !== 0 || (totalRows - 1 > 0 && index === 0),
    [index, totalRows]
  );

  return (
    <div
      css={
        isMobile
          ? styles.mobileMusicSectionContainer
          : styles.musicSectionContainer
      }
    >
      <div css={!isMobile && mainStyles.sizeNinetyPercent}>
        <Input
          autoComplete="off"
          id={`${fieldName}-link-${index}`}
          aria-label={t("ProjectsPage##Link")}
          onBlur={(e) => {
            if (e.target.value !== data) {
              onUpdateRow(index, e.target.value);
            }
          }}
          defaultValue={data}
        />
      </div>
      <div css={styles.iconsContainer}>
        <div
          css={
            showDeleteButton
              ? styles.deleteIconContainer
              : styles.invisibleContainer
          }
        >
          <DeleteButton onClick={() => onDeleteRow(index)} />
        </div>
        <div
          css={
            index === totalRows - 1
              ? styles.addIconContainer
              : styles.invisibleContainer
          }
        >
          <div css={styles.addIconContainer}>
            <ControlButton
              showTooltip
              tooltipColor="#222222"
              label={t("Add new")}
              onClick={onAddNewRow}
            >
              <PlusOutlined />
            </ControlButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowReelLinkRow;
