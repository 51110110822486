import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";

const DeleteIcon = ({
  height = theme.svgSize.large,
  width = theme.svgSize.large,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
      aria-labelledby="delete-button"
    >
      <title id="delete-button">{t("Delete")}</title>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#222222"
          strokeWidth="1.5"
          d="M25.15 13.620056L25.15 26.3996997C25.15 26.7448776 25.010089 27.0573776 24.7838835 27.2835831 24.557678 27.5097887 24.245178 27.6496997 23.9 27.6496997L23.9 27.6496997 14.1 27.6496997C13.754822 27.6496997 13.442322 27.5097887 13.2161165 27.2835831 12.989911 27.0573776 12.85 26.7448776 12.85 26.3996997L12.85 26.3996997 12.85 13.620056 25.15 13.620056zM20.5 9.75C20.845178 9.75 21.157678 9.88991102 21.3838835 10.1161165 21.610089 10.342322 21.75 10.654822 21.75 11L21.75 11 21.75 13.6159463 16.25 13.6159463 16.25 11C16.25 10.654822 16.389911 10.342322 16.6161165 10.1161165 16.842322 9.88991102 17.154822 9.75 17.5 9.75L17.5 9.75z"
        />
        <line
          x1="17.392"
          x2="17.392"
          y1="17.635"
          y2="23.635"
          stroke="#222222"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          x1="20.608"
          x2="20.608"
          y1="17.635"
          y2="23.635"
          stroke="#222222"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          x1="11"
          x2="27"
          y1="13.583"
          y2="13.583"
          stroke="#222222"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
