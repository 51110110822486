import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PageContainer from "src/components/page-container";
import { useHistory } from "react-router-dom";
import useProjectSearch from "src/providers/projects-search/hooks";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import { DrawerTypes, IDeleteProjectProps } from "../interfaces";
import ProjectListings from "..";
import getDataSource from "../table-data/get-data-source";

const ProjectsContainer = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState<boolean>(
    false
  );
  const [currentActiveDrawer, setCurrentActiveDrawer] = useState<DrawerTypes>(
    DrawerTypes.sortProjects
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteProjectData, setDeleteProjectData] = useState<
    IDeleteProjectProps | undefined
  >();
  const projectSearch = useProjectSearch();
  const { allProjectTypes, isGlobalDataLoading } = useGlobalStates();

  const handleDrawerToggle = useCallback(() => {
    setMobileDrawerVisible((state) => !state);
  }, []);

  const openDeleteModal = useCallback(({ id, name }: IDeleteProjectProps) => {
    setIsModalOpen(true);
    setDeleteProjectData({ id, name });
  }, []);

  const handleMobileDeleteProjectClick = useCallback(() => {
    if (currentActiveDrawer !== DrawerTypes.deleteProject) {
      setCurrentActiveDrawer(DrawerTypes.deleteProject);
    }
  }, [currentActiveDrawer]);

  const handleEditProject = useCallback(
    (id: string, openInNewTab?: boolean) => {
      return openInNewTab
        ? window.open(`${window.location.origin}/project/${id}`, "_blank")
        : history.push(`/project/${id}`);
    },
    [history]
  );

  const onDuplicateProject = useCallback(
    (id: string) => projectSearch.handleDuplicateProject(id),
    [projectSearch]
  );

  const onDeleteProject = useCallback(
    (id: string) => {
      projectSearch.handleDeleteProject(id);
      setIsModalOpen(false);
    },
    [projectSearch]
  );
  const dataSource = getDataSource(
    projectSearch.projects,
    allProjectTypes,
    projectSearch.isDuplicatingProject,
    openDeleteModal,
    handleEditProject,
    onDuplicateProject
  );
  return (
    <PageContainer
      title={t("Projects")}
      onCreateProject={projectSearch.handleCreateProject}
      Content={
        <ProjectListings
          data={dataSource}
          loading={projectSearch.isLoading || isGlobalDataLoading}
          projects={projectSearch.projects}
          sortOptions={projectSearch.sortOptions}
          searchText={projectSearch.searchText}
          debouncedSearchTerm={projectSearch.debouncedSearchTerm}
          paginationOptions={projectSearch.paginationOptions}
          mobileDrawerVisible={mobileDrawerVisible}
          currentActiveDrawer={currentActiveDrawer}
          isModalOpen={isModalOpen}
          deleteProjectData={deleteProjectData}
          appliedFilters={projectSearch.appliedFilters}
          availableFilters={projectSearch.availableFilters}
          activeLabels={projectSearch.activeLabels}
          noFiltersSet={projectSearch.noFiltersSet}
          myPresetFilters={projectSearch.myPresetFilters}
          savedFilters={projectSearch.savedFilters}
          savedColumns={projectSearch.savedColumns}
          isLoadingUserFilters={projectSearch.isLoadingUserFilters}
          onEditProjectClick={handleEditProject}
          handleApplyLabels={projectSearch.handleApplyLabels}
          handleSetMyPresetFilters={projectSearch.handleSetMyPresetFilters}
          handleApplyFilters={projectSearch.handleApplyFilters}
          updateSavedFilters={projectSearch.updateSavedFilters}
          clearFilters={projectSearch.clearFilters}
          handleDeleteProject={onDeleteProject}
          setIsModalOpen={setIsModalOpen}
          handleDrawerToggle={handleDrawerToggle}
          clearSearch={projectSearch.clearSearch}
          updateSearchText={projectSearch.updateSearchText}
          handleSort={projectSearch.handleSort}
          handlePageChange={projectSearch.handlePageChange}
          handleCreateProject={projectSearch.handleCreateProject}
          handleMobileDeleteProjectClick={handleMobileDeleteProjectClick}
        />
      }
    />
  );
};

export default ProjectsContainer;
