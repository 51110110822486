import { CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  ITableSort,
  OrgMembersSortType,
  SortOrder,
} from "src/pages/team/interfaces";
import styles from "./styles";

interface IProps {
  setSortTypeAndOrder: (
    sortType: OrgMembersSortType,
    sortOrder: SortOrder
  ) => void;
  getSortTypeAndOrder: () => ITableSort;
}

const TableSort = ({
  setSortTypeAndOrder,
  getSortTypeAndOrder,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const currentTableSort = getSortTypeAndOrder();

  return (
    <div data-testid="table-sort" css={styles.mobileSortContainer}>
      <Button
        onClick={() =>
          setSortTypeAndOrder(OrgMembersSortType.Name, SortOrder.Ascending)
        }
      >
        {t("manageTeam##members##sort##Name A-Z")}
        {currentTableSort.sortType === OrgMembersSortType.Name &&
          currentTableSort.sortOrder === SortOrder.Ascending && (
            <CheckOutlined title="check" />
          )}
      </Button>
      <Button
        onClick={() =>
          setSortTypeAndOrder(OrgMembersSortType.Name, SortOrder.Descending)
        }
      >
        {t("manageTeam##members##sort##Name Z-A")}
        {currentTableSort.sortType === OrgMembersSortType.Name &&
          currentTableSort.sortOrder === SortOrder.Descending && (
            <CheckOutlined title="check" />
          )}
      </Button>
      <Button
        onClick={() =>
          setSortTypeAndOrder(OrgMembersSortType.JobTitle, SortOrder.Ascending)
        }
      >
        {t("manageTeam##members##sort##Job title A-Z")}
        {currentTableSort.sortType === OrgMembersSortType.JobTitle &&
          currentTableSort.sortOrder === SortOrder.Ascending && (
            <CheckOutlined title="check" />
          )}
      </Button>
      <Button
        onClick={() =>
          setSortTypeAndOrder(OrgMembersSortType.JobTitle, SortOrder.Descending)
        }
      >
        {t("manageTeam##members##sort##Job title Z-A")}
        {currentTableSort.sortType === OrgMembersSortType.JobTitle &&
          currentTableSort.sortOrder === SortOrder.Descending && (
            <CheckOutlined title="check" />
          )}
      </Button>
    </div>
  );
};

export default TableSort;
