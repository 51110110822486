import React, { useMemo } from "react";
import { Input } from "antd";
import ArrowUp from "src/app/assets/icons/component-icons/arrow_up";
import ArrowDown from "src/app/assets/icons/component-icons/arrow_down";
import { debounce } from "lodash";
import styles from "../../styles";

interface ICustomDropDownInput {
  tags: JSX.Element[];
  isOpen: boolean;
  id?: string;
  placeholder: string;
  onInputChange: (search: string) => void;
  onInputFocusChange: () => void;
}
const debounceWait = 300;

const CustomDropDownInput = ({
  id,
  tags,
  isOpen,
  placeholder,
  onInputChange,
  onInputFocusChange,
}: ICustomDropDownInput): JSX.Element => {
  const closedInputVisualContent = useMemo(() => {
    return tags.length > 0 ? (
      <div css={styles.tagWrapper}>
        {tags[0]}
        {tags.length > 1 && `+ ${tags.length - 1}`}
      </div>
    ) : (
      <Input
        css={styles.customInput}
        id={id}
        placeholder={placeholder}
        value=""
      />
    );
  }, [tags, id, placeholder]);

  const handleInputChange = debounce((text: string) => {
    onInputChange(text);
  }, debounceWait);

  const openedAndClosedInputForSearching = useMemo(() => {
    return !isOpen ? (
      closedInputVisualContent
    ) : (
      <Input
        id={id}
        css={styles.customInput}
        placeholder={placeholder}
        onChange={(e) => {
          handleInputChange(e.target.value);
        }}
      />
    );
  }, [isOpen, closedInputVisualContent, id, placeholder, handleInputChange]);

  const showArrow = useMemo(() => {
    return (
      <>
        {isOpen ? (
          <div
            css={styles.arrowWrapper}
            tabIndex={0}
            role="button"
            onClick={() => {}}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          >
            <ArrowUp height={20} width={20} />
          </div>
        ) : (
          <div
            css={styles.arrowWrapper}
            tabIndex={0}
            role="button"
            onClick={onInputFocusChange}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          >
            <ArrowDown height={20} width={20} />
          </div>
        )}
      </>
    );
  }, [isOpen, onInputFocusChange]);

  return (
    <div css={styles.buttonContent}>
      {openedAndClosedInputForSearching}
      {showArrow}
    </div>
  );
};

export default CustomDropDownInput;
