import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Divider, Form } from "antd";
import { useWindowSize } from "@songtradr/spa-common";
import { cloneDeep } from "lodash";
import { v4 } from "uuid";
import { isEnterpriseLibraryProject } from "src/pages/projects/project/utils";
import {
  IFormContacts,
  IFormContactsInvoices,
  IFormInvoicee,
  IProjectProductAndAccountInformationData,
} from "../../interfaces";
import mainStyles from "../../styles";
import PrimaryContactRow from "./components/contact-email-rows/primary-contact-row/PrimaryContactRow";
import OtherContactsRow from "./components/contact-email-rows/other-contacts-row/OtherContactsRow";
import ContactInvoices from "./components/contact-invoices";

interface IProps {
  contactsSection?: IFormContacts[];
  projectProductAndAccountInformationData: IProjectProductAndAccountInformationData;
  projectType?: string;
  contactsInvoices: IFormContactsInvoices[];
  invoicee: IFormInvoicee;
  isContactNameRequired?: boolean;
  isEmailAddressRequired?: boolean;
  onUpdateContacts: (contacts: IFormContacts[]) => void;
  onUpdateInvoicee: (propertyName: string, value: string) => void;
  onUpdateContactsInvoicee: (invoiceeContacts: IFormContactsInvoices[]) => void;
}
const OTHER_CONTACTS = "otherContacts";
const INVOICEE_CONTACTS = "invoiceContacts";
const Contacts = memo(
  ({
    contactsSection,
    projectProductAndAccountInformationData,
    projectType,
    contactsInvoices,
    invoicee,
    onUpdateInvoicee,
    isContactNameRequired = false,
    isEmailAddressRequired = false,
    onUpdateContacts,
    onUpdateContactsInvoicee,
  }: IProps): ReactElement => {
    useWindowSize();
    const [form] = Form.useForm();

    const addKeys = (invoices?: IFormContactsInvoices[]) => {
      if (!invoices || invoices.length < 1) {
        return [{ key: v4() }];
      }
      const withKeys = invoices.map((invoice) => {
        return { ...invoice, key: v4() };
      });
      return withKeys;
    };
    const [formContactsInvoices, setFormContactsInvoices] = useState<
      IFormContactsInvoices[] | []
    >(addKeys(contactsInvoices));

    const handleCreateContactRow = useCallback(() => {
      const contacts = cloneDeep(contactsSection ?? []);
      const blankRow: IFormContacts = {
        name: "",
        email: "",
        contactType: "",
        key: v4(),
      };

      contacts.push(blankRow);
      onUpdateContacts(contacts);
    }, [contactsSection, onUpdateContacts]);

    const initialFormItemFieldNames = useMemo(() => {
      const generateRowKeys = (fieldName: string, index: number) => {
        return [`${fieldName}${index}Email`, `${fieldName}${index}Name`];
      };
      const generateContactsKeys = (
        fieldName: string,
        contacts: IFormContacts[]
      ) => {
        let keys: string[] = [];
        if ((contacts ?? []).length === 0) {
          keys = [...generateRowKeys(fieldName, 0)];
        } else {
          contacts.forEach((_, index) => {
            keys = [...keys, ...generateRowKeys(fieldName, index)];
            return keys;
          });
        }
        return keys;
      };

      return generateContactsKeys(OTHER_CONTACTS, contactsSection ?? []);
    }, [contactsSection]);

    const handleFormContactInvoicesChange = useCallback(
      (invoices: IFormContactsInvoices[]) => {
        setFormContactsInvoices(invoices);
        onUpdateContactsInvoicee(invoices);
      },
      [onUpdateContactsInvoicee]
    );

    const initialContactsFormValues = useMemo(() => {
      const generateRowValues = (
        fieldName: string,
        contact: IFormContacts,
        index: number
      ) => {
        return {
          [`${fieldName}${index}ContactType`]: contact.contactType || "",
          [`${fieldName}${index}Email`]: contact.email || "",
          [`${fieldName}${index}Name`]: contact.name || "",
          [`${fieldName}${index}CompanyName`]: contact.companyName || "",
        };
      };
      const defaultFormContact: IFormContacts = {
        contactType: "",
        email: "",
        name: "",
        companyName: "",
      };
      const generateContactsValues = (
        fieldName: string,
        contacts: IFormContacts[]
      ) => {
        let values = {};
        (contacts ?? [defaultFormContact]).forEach((contact, index) => {
          values = {
            ...values,
            ...generateRowValues(fieldName, contact, index),
          };
        });
        return values;
      };

      return generateContactsValues(OTHER_CONTACTS, contactsSection ?? []);
    }, [contactsSection]);

    useEffect(() => {
      form.setFieldsValue(initialContactsFormValues);
      void form.validateFields(initialFormItemFieldNames);
    }, [form, initialContactsFormValues, initialFormItemFieldNames]);

    return (
      <Form
        form={form}
        initialValues={initialContactsFormValues}
        validateTrigger="onBlur"
        scrollToFirstError
      >
        <div
          id="Additional Contacts"
          css={mainStyles.additionalSectionContainerStyles}
        >
          <PrimaryContactRow
            projectProductAndAccountInformationData={
              projectProductAndAccountInformationData
            }
          />
        </div>
        {!isEnterpriseLibraryProject(projectType ?? "") && (
          <>
            <Divider css={mainStyles.sectionDivider} />
            <div css={mainStyles.additionalSectionContainerStyles}>
              <OtherContactsRow
                propertyName={OTHER_CONTACTS}
                contactsSection={contactsSection}
                onUpdateContacts={onUpdateContacts}
                onCreateContactRow={handleCreateContactRow}
                isContactNameRequired={isContactNameRequired}
                isEmailAddressRequired={isEmailAddressRequired}
              />
            </div>
            <Divider css={mainStyles.sectionDivider} />
            <ContactInvoices
              contactsInvoices={formContactsInvoices}
              propertyName={INVOICEE_CONTACTS}
              invoicee={invoicee}
              onUpdateInvoicee={onUpdateInvoicee}
              onFormContactInvoicesChange={handleFormContactInvoicesChange}
            />
          </>
        )}
      </Form>
    );
  }
);
export default Contacts;
