import { Menu, Typography } from "antd";
import React, { memo, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ShareIcon,
  DownloadIcon,
  DeleteIcon,
} from "src/app/assets/icons/component-icons";
import STLoadingLogo from "src/components/st-loading-logo";
import { debounce } from "lodash";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import deleteDrawerStyles from "src/pages/projects/project/components/details/styles";
import useProject from "src/providers/project/hooks";
import {
  handleDeleteFile,
  handleFileDownload,
  handleShareToggle,
} from "src/components/file-upload/helpers";
import { IDeleteSelectedFile } from "src/components/file-upload/interfaces";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import { IProjectUploadFile } from "../../../details/components/interfaces";
import styles from "./styles";

export enum FileDrawerTypes {
  FileInfoDrawer = "FileInfoDrawer",
  FileDeleteDrawer = "FileDeleteDrawer",
}
interface IFileInfoDrawer {
  setActiveDrawer: (drawerType: FileDrawerTypes) => void;
  activeFile?: IProjectUploadFile;
  toggleDrawer: () => void;
  accessToken: string;
  projectId: string;
  organisationId: string;
  deleteAvailableForAttachmentType?: string;
}

interface IDeleteFileDrawer {
  activeFile?: IProjectUploadFile;
  toggleDrawer: () => void;
  setFileList: (files: IProjectUploadFile[]) => void;
  fileList: IProjectUploadFile[];
  setShowFileUploadDeleteModal: (show: boolean) => void;
  accessToken: string;
  projectId: string;
  organisationId: string;
  selectedFileToDelete?: IDeleteSelectedFile;
}

const FileInfoDrawer = memo(
  ({
    setActiveDrawer,
    activeFile,
    toggleDrawer,
    accessToken,
    projectId,
    organisationId,
    deleteAvailableForAttachmentType,
  }: IFileInfoDrawer) => {
    const { t } = useTranslation();
    const { storeProject } = useProject();

    const setDeleteDrawer = () => {
      setActiveDrawer(FileDrawerTypes.FileDeleteDrawer);
    };

    const downloadFile = debounce(async (): Promise<void> => {
      if (activeFile) {
        SuccessToast(t("File downloading to your file storage"));
        toggleDrawer();

        const downloadFileRequest = await handleFileDownload(
          accessToken,
          activeFile,
          projectId,
          organisationId
        );

        if (!downloadFileRequest) {
          ErrorToast(
            `FileUploadErrorToast${activeFile.uid}`,
            t("There was a problem downloading the file")
          );
        }
      }
    }, 500);

    const toggleFilePrivacy = debounce(async (): Promise<void> => {
      if (activeFile) {
        await handleShareToggle(
          activeFile.uid,
          accessToken,
          projectId,
          organisationId,
          !activeFile.public,
          storeProject
        ).catch((e) => {
          log(
            DataDogLogTypes.ERROR,
            `Error sharing file: ${activeFile.uid}`,
            e
          );
          return ErrorToast(
            `FileShareErrorToast${activeFile.uid}`,
            t("There was a problem sharing this file")
          );
        });

        toggleDrawer();

        if (activeFile.public) {
          SuccessToast(t("File unshared"));
        } else {
          SuccessToast(t("File shared"));
        }
      }
    }, 500);

    if (activeFile) {
      const isDeleteFileAvailable =
        (deleteAvailableForAttachmentType &&
          activeFile.attachmentType === deleteAvailableForAttachmentType) ||
        !deleteAvailableForAttachmentType;

      return (
        <Menu css={styles.infoDrawerContainer}>
          <Menu.Item key="0">
            <button
              onClick={toggleFilePrivacy}
              type="button"
              aria-label={
                activeFile.public
                  ? t("Unshare with client")
                  : t("Share with client")
              }
            >
              <ShareIcon />
              <span>
                {activeFile.public
                  ? t("Unshare with client")
                  : t("Share with client")}
              </span>
            </button>
          </Menu.Item>
          <Menu.Item key="1">
            <button
              onClick={downloadFile}
              type="button"
              aria-label={t("Download")}
            >
              <DownloadIcon />
              <span>{t("Download")}</span>
            </button>
          </Menu.Item>
          {isDeleteFileAvailable && (
            <Menu.Item key="2">
              <button
                onClick={setDeleteDrawer}
                type="button"
                aria-label={t("Delete")}
              >
                <DeleteIcon />
                <span>{t("Delete")}</span>
              </button>
            </Menu.Item>
          )}
        </Menu>
      );
    }

    return <STLoadingLogo />;
  }
);

const DeleteFileDrawer = memo(
  ({
    activeFile,
    toggleDrawer,
    setFileList,
    fileList,
    setShowFileUploadDeleteModal,
    accessToken,
    projectId,
    organisationId,
    selectedFileToDelete,
  }: IDeleteFileDrawer) => {
    const { t } = useTranslation();

    const handleDelete = useCallback(async () => {
      if (selectedFileToDelete) {
        toggleDrawer();
        await handleDeleteFile(
          setFileList,
          fileList,
          setShowFileUploadDeleteModal,
          t,
          selectedFileToDelete.index,
          accessToken,
          projectId,
          organisationId,
          activeFile
        );
      }
    }, [
      accessToken,
      activeFile,
      fileList,
      organisationId,
      projectId,
      selectedFileToDelete,
      setFileList,
      setShowFileUploadDeleteModal,
      t,
      toggleDrawer,
    ]);

    if (activeFile) {
      return (
        <div data-testid="delete-attachment-drawer">
          <div css={deleteDrawerStyles.drawerContentContainer}>
            <Typography.Title css={deleteDrawerStyles.drawerHeaderText}>
              {t("deleteClient##Are you sure?")}
            </Typography.Title>
            <div>
              <Typography.Text>
                <Trans i18nKey="ProjectsPage##confirmationPrompt">
                  {{ attachmentName: activeFile.name }}
                </Trans>
              </Typography.Text>
            </div>
          </div>
          <div css={deleteDrawerStyles.drawerButtonContainer}>
            <button
              type="button"
              css={deleteDrawerStyles.mobileButtonContainerCancel}
              onClick={toggleDrawer}
              aria-label={t("Cancel")}
            >
              <div css={deleteDrawerStyles.iconContainer}>{t("Cancel")}</div>
            </button>
            <button
              aria-label={t("Delete")}
              type="button"
              css={deleteDrawerStyles.mobileButtonContainerPrimary}
              onClick={handleDelete}
              data-testid="delete-attachment-drawer-button"
            >
              <div css={deleteDrawerStyles.iconContainer}>{t("Delete")}</div>
            </button>
          </div>
        </div>
      );
    }

    return <STLoadingLogo />;
  }
);

export const generateDrawer = (
  drawerType: FileDrawerTypes,
  setActiveDrawer: (drawerType: FileDrawerTypes) => void,
  toggleDrawer: () => void,
  accessToken: string,
  projectId: string,
  organisationId: string,
  setFileList: (files: IProjectUploadFile[]) => void,
  fileList: IProjectUploadFile[],
  setShowFileUploadDeleteModal: (show: boolean) => void,
  selectedFileToDelete?: IDeleteSelectedFile,
  activeFile?: IProjectUploadFile,
  deleteAvailableForAttachmentType?: string
): JSX.Element => {
  switch (drawerType) {
    case FileDrawerTypes.FileInfoDrawer:
      return (
        <FileInfoDrawer
          setActiveDrawer={setActiveDrawer}
          activeFile={activeFile}
          toggleDrawer={toggleDrawer}
          accessToken={accessToken}
          projectId={projectId}
          organisationId={organisationId}
          deleteAvailableForAttachmentType={deleteAvailableForAttachmentType}
        />
      );
    case FileDrawerTypes.FileDeleteDrawer:
      return (
        <DeleteFileDrawer
          activeFile={activeFile}
          toggleDrawer={toggleDrawer}
          setFileList={setFileList}
          fileList={fileList}
          setShowFileUploadDeleteModal={setShowFileUploadDeleteModal}
          accessToken={accessToken}
          projectId={projectId}
          organisationId={organisationId}
          selectedFileToDelete={selectedFileToDelete}
        />
      );
    default:
      return (
        <FileInfoDrawer
          setActiveDrawer={setActiveDrawer}
          activeFile={activeFile}
          toggleDrawer={toggleDrawer}
          accessToken={accessToken}
          projectId={projectId}
          organisationId={organisationId}
        />
      );
  }
};
