/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogLogs } from "@datadog/browser-logs";
import config from "../config";

enum DataDogLogTypes {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

const getEnvironment = () => {
  if (config.production) return "production";
  if (config.staging) return "staging";
  return "development";
};

const log = (
  type: "info" | "warn" | "error",
  message: string,
  data: any
): void => {
  if (getEnvironment() === "development") {
    // eslint-disable-next-line no-console
    console.log(message, data, type);
  } else {
    datadogLogs.logger.log(message, data, type);
  }
};

const initDataDog = (): void => {
  const env = getEnvironment();
  if (env === "development") return;

  datadogLogs.init({
    clientToken: config.dataDogToken,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: "vinyl-spa",
    env,
    version: process.env.REACT_APP_SHA ? process.env.REACT_APP_SHA : "local",
  });
};

export { DataDogLogTypes, log, initDataDog };
