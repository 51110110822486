import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

const musicSectionContainerStyle = `
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
`;
export default {
  container: css`
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    .floatingLabel {
      margin-bottom: 0 !important;
    }
    .ant-select {
      width: -webkit-fill-available;
    }

    @media ${queries.medium} {
      padding-left: 0.9rem !important;
      padding-right: 0.9rem !important;
    }

    .ant-row {
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  `,
  monetaryValueContainer: css`
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  `,
  musicSectionDivider: css`
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  `,
  floatingLabel: css`
    position: relative;
    color: ${theme.colors.background.darkGray};
    width: 100%;
    input {
      height: 38px;
    }
    textarea {
      padding-top: 1rem;
    }
  `,
  sharesContainer: css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
  sectionHeader: css`
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: ${theme.colors.secondary.blueOnBlack};
    font-weight: bold;
    padding-left: 1rem !important;
    padding-top: 0.5rem !important;
  `,
  musicSectionContainer: css`
    ${musicSectionContainerStyle}
    align-items: flex-end;
    flex-direction: row;
  `,
  musicSectionWithColumnsContainer: css`
    ${musicSectionContainerStyle}
    flex-direction: column;
    gap: 2.25rem;
  `,
  finalTrackHeaderContainer: css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1;
    > div:last-child {
      width: 100%;
    }
    width: 100%;
    @media ${queries.large} {
      width: calc((100% - 76px) / 5 - 0.5rem);
    }
  `,
  finalTrackMobileHeaderContainer: css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    > div:last-child {
      height: auto !important;
    }
    width: 100%;
    @media ${queries.medium} {
      > div:last-child {
        height: 38px !important;
      }
    }
    @media ${queries.large} {
      width: calc((100% - 76px) / 5 - 0.5rem);
    }
  `,
  mobileMusicSectionContainer: css`
    ${musicSectionContainerStyle}
    width:100% !important;
    flex-direction: column;
  `,
};
