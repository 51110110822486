import React, { memo, ReactElement, useCallback, useState } from "react";
import { Collapse, Spin, Typography } from "antd";
import DocumentsIconSmall from "src/app/assets/icons/component-icons/documents-icon";
import useProject from "src/providers/project/hooks";
import { useTranslation } from "react-i18next";
import CreateReportButton, {
  ReportFileName,
  ReportLabelName,
  ReportType,
} from "src/components/create-report-button";
import { getIsMobile } from "@songtradr/spa-common";
import PurchaseOrders from "./components/purchase-orders";
import Invoices from "./components/invoices";
import License from "./components/license";
import Estimates from "./components/estimates";
import Miscellaneous from "./components/miscellaneous";
import Assets from "./components/assets";
import LoopsResults from "./components/loops-results";
import { IProjectAttachment } from "../../interfaces";
import BriefAttachments from "./components/brief-attachments";
import mainStyles from "../../styles";

interface IProps {
  purchaseOrderAttachments: IProjectAttachment[];
  licenseAttachments: IProjectAttachment[];
  estimateAttachments: IProjectAttachment[];
  invoiceAttachments: IProjectAttachment[];
  miscAttachments: IProjectAttachment[];
  assetAttachments: IProjectAttachment[];
  testResultAttachments: IProjectAttachment[];
  briefAttachments: IProjectAttachment[];
}

export const BetterAntdBadge: React.FC<{
  color?: string;
  backgroundcolor: string;
  count: number;
}> = ({ backgroundcolor, color = "white", count }) => {
  if (!count) return null;
  return (
    <div
      css={mainStyles.badgeContainer}
      style={{
        backgroundColor: backgroundcolor,
        color,
      }}
    >
      {count}
    </div>
  );
};

export const AttachmentsCountBadge = ({
  count = 0,
}: {
  count?: number;
}): JSX.Element => {
  return <BetterAntdBadge backgroundcolor="#3F556D" count={count} />;
};

const Documents = memo(
  (): ReactElement => {
    const { Panel } = Collapse;
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const isMobile = getIsMobile();
    const {
      project: {
        purchaseOrderAttachments,
        licenseAttachments,
        estimateAttachments,
        invoiceAttachments,
        miscAttachments,
        assetAttachments,
        testResultAttachments,
        briefAttachments = [],
      },
    } = useProject();
    const { t } = useTranslation();

    const handleIsLoadingReport = useCallback((isLoading: boolean) => {
      setIsLoadingReport(isLoading);
    }, []);

    return (
      <div
        css={[mainStyles.container, isLoadingReport && mainStyles.reportSpiner]}
      >
        <div css={[mainStyles.switchContainer, mainStyles.titleLabelContainer]}>
          <Typography.Text css={mainStyles.titleLabel}>
            {t("ProjectsPage##Documents")}
          </Typography.Text>
          <CreateReportButton
            dataTestId="download-estimate-music-report"
            onIsLoadingReport={handleIsLoadingReport}
            ariaLabel={ReportLabelName.MusicEstimate}
            label={isMobile ? "Create report" : ReportLabelName.MusicEstimate}
            reportType={ReportType.MusicEstimate}
            reportFileName={ReportFileName.MusicEstimate}
          />
        </div>

        <Collapse accordion css={mainStyles.collapseContainer}>
          <Panel
            css={mainStyles.documentPanelContainer}
            header={
              <div css={mainStyles.documentsPanel}>
                <div css={mainStyles.documentsIconAndTitle}>
                  <DocumentsIconSmall
                    width={24}
                    height={24}
                    title="Purchase orders"
                  />
                  {t("ProjectsPage##Purchase orders")}
                </div>

                <AttachmentsCountBadge
                  count={purchaseOrderAttachments.length}
                />
              </div>
            }
            key="purchase-orders"
          >
            <PurchaseOrders attachments={purchaseOrderAttachments} />
          </Panel>
          <Panel
            css={mainStyles.documentPanelContainer}
            header={
              <div css={mainStyles.documentsPanel}>
                <div css={mainStyles.documentsIconAndTitle}>
                  <DocumentsIconSmall width={24} height={24} title="Invoices" />
                  {t("ProjectsPage##Invoices")}
                </div>
                <AttachmentsCountBadge count={invoiceAttachments.length} />
              </div>
            }
            key="invoice"
          >
            <Invoices attachments={invoiceAttachments} />
          </Panel>
          <Panel
            css={mainStyles.documentPanelContainer}
            header={
              <div css={mainStyles.documentsPanel}>
                <div css={mainStyles.documentsIconAndTitle}>
                  <DocumentsIconSmall width={24} height={24} title="license" />
                  {t("ProjectsPage##License")}
                </div>
                <AttachmentsCountBadge count={licenseAttachments.length} />
              </div>
            }
            key="license"
          >
            <License
              attachments={licenseAttachments}
              onIsLoadingReport={handleIsLoadingReport}
            />
          </Panel>
          <Panel
            css={mainStyles.documentPanelContainer}
            header={
              <div css={mainStyles.documentsPanel}>
                <div css={mainStyles.documentsIconAndTitle}>
                  <DocumentsIconSmall
                    width={24}
                    height={24}
                    title="Estimates"
                  />

                  {t("ProjectsPage##Estimates")}
                </div>
                <AttachmentsCountBadge count={estimateAttachments.length} />
              </div>
            }
            key="estimates"
          >
            <Estimates attachments={estimateAttachments} />
          </Panel>
          <Panel
            css={mainStyles.documentPanelContainer}
            header={
              <div css={mainStyles.documentsPanel}>
                <div css={mainStyles.documentsIconAndTitle}>
                  <DocumentsIconSmall
                    width={24}
                    height={24}
                    title="Miscellaneous"
                  />
                  {t("ProjectsPage##Miscellaneous")}
                </div>
                <AttachmentsCountBadge count={miscAttachments.length} />
              </div>
            }
            key="miscellaneous"
          >
            <Miscellaneous attachments={miscAttachments} />
          </Panel>
          <Panel
            css={mainStyles.documentPanelContainer}
            header={
              <div css={mainStyles.documentsPanel}>
                <div css={mainStyles.documentsIconAndTitle}>
                  <DocumentsIconSmall width={24} height={24} title="Assets" />
                  {t("ProjectsPage##Assets")}
                </div>
                <AttachmentsCountBadge count={assetAttachments.length} />
              </div>
            }
            key="assets"
          >
            <Assets attachments={assetAttachments} />
          </Panel>
          <Panel
            css={mainStyles.documentPanelContainer}
            header={
              <div css={mainStyles.documentsPanel}>
                <div css={mainStyles.documentsIconAndTitle}>
                  <DocumentsIconSmall
                    width={24}
                    height={24}
                    title="Test Result"
                  />
                  {t("ProjectsPage##Test Result")}
                </div>
                <AttachmentsCountBadge count={testResultAttachments.length} />{" "}
              </div>
            }
            key="test-result"
          >
            <LoopsResults attachments={testResultAttachments} />
          </Panel>
          <Panel
            css={mainStyles.documentPanelContainer}
            header={
              <div css={mainStyles.documentsPanel}>
                <div css={mainStyles.documentsIconAndTitle}>
                  <DocumentsIconSmall width={24} height={24} title="Brief" />
                  {t("ProjectsPage##Brief")}
                </div>
                <AttachmentsCountBadge count={briefAttachments.length} />
              </div>
            }
            key="brief"
          >
            <BriefAttachments attachments={briefAttachments} />
          </Panel>
        </Collapse>
        {isLoadingReport && (
          <div css={mainStyles.loadingContainer}>
            <Spin size="large" css={mainStyles.reportSpiner} />
          </div>
        )}
      </div>
    );
  }
);

export default Documents;
