import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import "../../theme/index.css";
import theme from "src/theme";

export default {
  app: css`
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    @media ${queries.large} {
      background: transparent;
      padding-bottom: 0;
    }
  `,
  navContainer: css`
    background-color: ${theme.colors.background.white};
    margin-top: 3rem;
    width: 100%;

    &:has(div.contact-container) {
      flex: 1;
    }

    @media ${queries.large} {
      margin-top: 0;
      width: calc(100% - 3.5rem);
    }
  `,
  pageContainer: css`
    @media ${queries.large} {
      margin-top: 0;
    }
  `,
};
