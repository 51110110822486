import { IUserDropdownProps } from "src/pages/team/interfaces";
import { IProjectForm } from "../../../interfaces";

interface IColumnConfig {
  projectName: number;
  campaignName: number;
  crmDealId: number;
  projectLead: number;
  client: number;
  contactDetails: number;
  clientReferenceNo: number;
  projectStatus: number;
  productName?: number;
  productCategory?: number;
  projectEarnings?: number;
  contractStartDate?: number;
  contractEndDate?: number;
  projectEstCloseDate?: number;
  projectType?: number;
  musicType?: number;
  musicBudget?: number;
  brief?: number;
  description?: number;
  projectBriefAttachments?: number;
  clients: number;
  projectCloseDate?: number;
  projectRegion?: number;
  officeLocation?: number;
  jobListingDate?: number;
  relatedProjectUrl?: number;
  productCategoryToggle?: number;
  productCategoryButton?: number;
}

export const columnConfig = (
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean
): IColumnConfig => {
  if (isMobile) {
    return {
      projectName: 24,
      campaignName: 24,
      crmDealId: 24,
      projectLead: 24,
      client: 24,
      contactDetails: 24,
      clientReferenceNo: 24,
      projectStatus: 24,
      productName: 24,
      productCategory: 8,
      projectEarnings: 24,
      contractStartDate: 24,
      contractEndDate: 24,
      projectCloseDate: 24,
      projectEstCloseDate: 24,
      projectRegion: 24,
      officeLocation: 24,
      jobListingDate: 24,
      projectType: 24,
      musicType: 24,
      brief: 24,
      description: 24,
      projectBriefAttachments: 24,
      clients: 24,
      relatedProjectUrl: 14,
      productCategoryToggle: 2,
      productCategoryButton: 14,
      musicBudget: 24,
    };
  }

  if (isTablet) {
    return {
      projectName: 8,
      campaignName: 8,
      crmDealId: 8,
      projectLead: 8,
      client: 8,
      contactDetails: 8,
      clientReferenceNo: 8,
      projectStatus: 6,
      productName: 8,
      productCategory: 8,
      projectEarnings: 8,
      contractStartDate: 8,
      projectEstCloseDate: 6,
      contractEndDate: 8,
      musicBudget: 8,
      projectCloseDate: 6,
      projectRegion: 8,
      officeLocation: 8,
      jobListingDate: 6,
      projectType: 8,
      musicType: 8,
      brief: 24,
      description: 24,
      projectBriefAttachments: 24,
      clients: 8,
      relatedProjectUrl: 14,
      productCategoryToggle: 2,
      productCategoryButton: 14,
    };
  }

  if (isDesktop) {
    return {
      projectName: 8,
      campaignName: 8,
      crmDealId: 8,
      projectLead: 8,
      client: 8,
      contactDetails: 8,
      clientReferenceNo: 8,
      projectStatus: 6,
      productName: 8,
      productCategory: 8,
      projectEarnings: 8,
      contractStartDate: 8,
      projectEstCloseDate: 6,
      contractEndDate: 8,
      musicBudget: 8,
      projectCloseDate: 6,
      projectRegion: 8,
      officeLocation: 8,
      jobListingDate: 6,
      projectType: 8,
      musicType: 8,
      brief: 24,
      description: 24,
      projectBriefAttachments: 24,
      clients: 8,
      relatedProjectUrl: 14,
      productCategoryToggle: 2,
      productCategoryButton: 14,
    };
  }

  return {
    projectName: 8,
    campaignName: 8,
    crmDealId: 8,
    projectLead: 8,
    client: 8,
    contactDetails: 8,
    clientReferenceNo: 8,
    projectStatus: 6,
    productName: 8,
    productCategory: 8,
    projectEarnings: 8,
    contractStartDate: 8,
    projectEstCloseDate: 6,
    contractEndDate: 8,
    musicBudget: 8,
    projectCloseDate: 6,
    projectRegion: 8,
    officeLocation: 8,
    jobListingDate: 6,
    projectType: 8,
    musicType: 8,
    brief: 24,
    description: 24,
    projectBriefAttachments: 24,
    clients: 8,
    relatedProjectUrl: 14,
    productCategoryToggle: 2,
    productCategoryButton: 14,
  };
};

export const parseProjectLeads = (
  leads: IProjectForm["leads"]
): IUserDropdownProps[] => {
  if (leads) {
    return leads.map((lead) => {
      return {
        label: lead?.name,
        value: lead?.id,
        email: lead?.email,
      };
    });
  }

  return [];
};

export default { columnConfig };
