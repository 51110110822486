import axios from "axios";
import { eApplicationType } from "src/interfaces/auth";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { applicationsUrl } from "../../organisation-members-url-helper";

const addApplications = (
  accessToken: string,
  organisationId: string,
  userId: string,
  applications: eApplicationType[]
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.post(
    applicationsUrl(organisationId, userId),
    {
      applications,
    },
    options
  );
};

export default addApplications;
