import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "./interfaces";

const InfoIcon = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      aria-labelledby="information-icon-title"
    >
      <title id="information-icon-title">
        {t("notificationIcons##information")}
      </title>
      <g fill="none" fillRule="evenodd">
        <circle cx="8" cy="8" r="8" fill="#1890FF" />
        <line
          x1="8"
          x2="8"
          y1="6.962"
          y2="11.462"
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth="1.5"
          transform="matrix(-1 0 0 1 16 0)"
        />
        <circle cx="8" cy="4.277" r="1" fill="#FFF" />
      </g>
    </svg>
  );
};

export default InfoIcon;
