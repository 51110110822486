import axios, { AxiosResponse } from "axios";
import { shareProjectsUrl } from "../../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

interface IProjectShareResponse {
  shareCodeUrl: string;
}
const shareProject = (
  accessToken: string,
  projectId: string,
  organisationId: string
): Promise<IProjectShareResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  return axios
    .put(shareProjectsUrl(projectId, organisationId), {}, options)
    .then(({ data }) => data as IProjectShareResponse);
};

export default shareProject;
