import React, { useCallback, useEffect, useMemo } from "react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import { ITalentHubTypes } from "src/api/talent-hub/interfaces";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import { useTranslation } from "react-i18next";
import { Form, Typography } from "antd";
import contactValidation from "src/utils/contactValidation";
import { useForm } from "antd/lib/form/Form";
import ContactSelect from "../contact-select";
import style from "../../styles";
import { Confirm, IAAndRData } from "../../interfaces";

interface IProps {
  aAndRData: IAAndRData;
  talentHubTypes: ITalentHubTypes;
  validateTabFields: number;
  onAAndRDataChange: (data: IAAndRData) => void;
}

const AAndR = ({
  aAndRData,
  talentHubTypes,
  validateTabFields,
  onAAndRDataChange,
}: IProps): React.ReactElement => {
  useWindowSize();
  const isMobile = getIsMobile();
  const { t } = useTranslation();
  const [form] = useForm();

  const onSingleSelectChange = useCallback(
    (property: string, option: IOption) => {
      onAAndRDataChange({
        [property]: option.value,
      });
    },
    [onAAndRDataChange]
  );

  const onSingleNumberSelectChange = useCallback(
    (property: string, option: IOption) => {
      onAAndRDataChange({
        [property]: parseInt(option.value, 10),
      });
    },
    [onAAndRDataChange]
  );

  const onMultipleSelectChange = useCallback(
    (property: string, options: IOption[]) => {
      onAAndRDataChange({
        [property]: options.map((option) => option.value),
      });
    },
    [onAAndRDataChange]
  );

  const confirmOptions = useMemo(() => {
    return Object.entries(Confirm).map(([value, label]) => ({
      label,
      value,
    }));
  }, []);

  const minimumDemoTurnaroundHoursOptions = useMemo(() => {
    return ["6", "12", "24", "36", "48"].map((value) => ({
      label: value,
      value,
    }));
  }, []);

  useEffect(() => {
    if (validateTabFields) {
      void form.validateFields([
        "collaborationStatus",
        "skillSet",
        "principalInstruments",
        "keyGenres",
        "vocalist",
        "minimumDemoTurnaroundHours",
      ]);
    }
  }, [form, validateTabFields]);

  const findSingleSelectOption = useCallback(
    (options: IOption[], value: string) => {
      return options.find((option) => option.value === value);
    },
    []
  );

  const filterMultipleSelectOptions = useCallback(
    (options: IOption[], values: string[]) => {
      return options.filter((option) => values.includes(option.value));
    },
    []
  );

  return (
    <Form
      form={form}
      initialValues={{
        collaborationStatus:
          aAndRData.collaborationStatus &&
          findSingleSelectOption(
            talentHubTypes.talentCollaborations,
            aAndRData.collaborationStatus
          ),
        skillSet:
          aAndRData.skillSet &&
          filterMultipleSelectOptions(
            talentHubTypes.skillSets,
            aAndRData.skillSet
          ),
        principalInstruments:
          aAndRData.principalInstruments &&
          filterMultipleSelectOptions(
            talentHubTypes.talentInstruments,
            aAndRData.principalInstruments
          ),
        additionalInstruments:
          aAndRData.additionalInstruments &&
          filterMultipleSelectOptions(
            talentHubTypes.talentInstruments,
            aAndRData.additionalInstruments
          ),
        keyGenres:
          aAndRData.keyGenres &&
          filterMultipleSelectOptions(
            talentHubTypes.musicGenres,
            aAndRData.keyGenres
          ),
        additionalGenres:
          aAndRData.additionalGenres &&
          filterMultipleSelectOptions(
            talentHubTypes.musicGenres,
            aAndRData.additionalGenres
          ),
        vocalist:
          aAndRData.vocalist &&
          findSingleSelectOption(confirmOptions, aAndRData.vocalist),
        minimumDemoTurnaroundHours:
          aAndRData.minimumDemoTurnaroundHours &&
          findSingleSelectOption(
            minimumDemoTurnaroundHoursOptions,
            aAndRData.minimumDemoTurnaroundHours.toString()
          ),
        previousWork:
          aAndRData.previousWork &&
          filterMultipleSelectOptions(
            talentHubTypes.talentWorkTypes,
            aAndRData.previousWork
          ),
        interestedProjects:
          aAndRData.interestedProjects &&
          filterMultipleSelectOptions(
            talentHubTypes.talentWorkTypes,
            aAndRData.interestedProjects
          ),
      }}
      scrollToFirstError
    >
      <div id="AAndR" css={style.sectionContainer}>
        <Typography.Text css={style.tabsTitle}>
          {t("ContactPage##A&R")}
        </Typography.Text>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##Rating")}
            name="collaborationStatus"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.talentCollaborations}
            id="rating-dropdown"
            onChange={(collaborationStatus: IOption) => {
              onSingleSelectChange("collaborationStatus", collaborationStatus);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Skill Set")}
            name="skillSet"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.skillSets}
            id="skill-set-dropdown"
            isMulti
            onChange={(skillSet: IOption[]) => {
              onMultipleSelectChange("skillSet", skillSet);
            }}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##Principal Instruments")}
            name="principalInstruments"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.talentInstruments}
            id="principal-instruments-dropdown"
            isMulti
            onChange={(principalInstruments: IOption[]) => {
              onMultipleSelectChange(
                "principalInstruments",
                principalInstruments
              );
            }}
          />
          <ContactSelect
            title={t("ContactPage##Additional Instruments")}
            name="additionalInstruments"
            rules={[]}
            options={talentHubTypes.talentInstruments}
            id="additional-instruments-dropdown"
            isMulti
            onChange={(additionalInstruments: IOption[]) => {
              onMultipleSelectChange(
                "additionalInstruments",
                additionalInstruments
              );
            }}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##Key Genres")}
            name="keyGenres"
            rules={contactValidation.requiredFieldValidator()}
            options={talentHubTypes.musicGenres}
            id="key-genres-dropdown"
            isMulti
            onChange={(keyGenres: IOption[]) => {
              onMultipleSelectChange("keyGenres", keyGenres);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Additional Genres")}
            name="additionalGenres"
            rules={[]}
            options={talentHubTypes.musicGenres}
            id="additional-genres-dropdown"
            isMulti
            onChange={(additionalGenres: IOption[]) => {
              onMultipleSelectChange("additionalGenres", additionalGenres);
            }}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##Vocalist")}
            name="vocalist"
            rules={contactValidation.requiredFieldValidator()}
            options={confirmOptions}
            id="vocalist-dropdown"
            onChange={(vocalist: IOption) => {
              onSingleSelectChange("vocalist", vocalist);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Minimum Demo Turnaround")}
            name="minimumDemoTurnaroundHours"
            rules={contactValidation.requiredFieldValidator()}
            options={minimumDemoTurnaroundHoursOptions}
            id="minimum-demo-turnaround-dropdown"
            menuPlacement="top"
            onChange={(minimumDemoTurnaroundHours: IOption) => {
              onSingleNumberSelectChange(
                "minimumDemoTurnaroundHours",
                minimumDemoTurnaroundHours
              );
            }}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##Previous Work")}
            name="previousWork"
            rules={[]}
            options={talentHubTypes.talentWorkTypes}
            id="previous-work-dropdown"
            isMulti
            onChange={(previousWork: IOption[]) => {
              onMultipleSelectChange("previousWork", previousWork);
            }}
          />
          <ContactSelect
            title={t("ContactPage##Interested Projects")}
            name="interestedProjects"
            rules={[]}
            options={talentHubTypes.talentWorkTypes}
            id="interested-projects-dropdown"
            isMulti
            onChange={(interestedProjects: IOption[]) => {
              onMultipleSelectChange("interestedProjects", interestedProjects);
            }}
          />
        </div>
      </div>
    </Form>
  );
};

export default AAndR;
