import SelectedIcon from "src/app/assets/icons/component-icons/selected-icon";
import { GroupType } from "src/pages/talent-hub/create-group";
import styles from "src/pages/talent-hub/styles/index";

interface IProps {
  selectedGroup: string;
  groupType: GroupType;
  label: string;
  onSelectedGroupChange: (groupType: GroupType) => void;
}
const SelectedGroupButton = ({
  selectedGroup,
  groupType,
  label,
  onSelectedGroupChange,
}: IProps): JSX.Element => {
  return (
    <div
      role="button"
      tabIndex={0}
      css={[
        styles.groupSelectBox,
        selectedGroup === groupType && styles.selectedGroup,
      ]}
      onKeyDown={() => onSelectedGroupChange(groupType)}
      onClick={() => onSelectedGroupChange(groupType)}
    >
      {label}
      {selectedGroup === groupType && <SelectedIcon />}
    </div>
  );
};

export default SelectedGroupButton;
