import { getIsMobile, getIsTablet, useWindowSize } from "@songtradr/spa-common";
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import deleteOrganisationMember from "src/api/organisation-members/delete-organisation-member";
import getOrganisationMembers from "src/api/organisation-members/get-org-members";
import useAuth from "src/auth/use-auth";
import { IConfirmContentProps } from "src/components/interfaces";
import { ModalTypes } from "src/components/modals/base-modal";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { OrgMembersDrawerTypes } from "src/pages/team/mobile-controls/drawers";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import OrgMembersTable from "..";
import {
  IOrganisationMemberIdentifierProps,
  IOrganisationMemberProps,
  ITableSort,
  OrgMembersSortType,
  SortOrder,
} from "../../../interfaces";
import OrgMembersMobileControls from "../../../mobile-controls";
import DeleteTeamMemberModalMainContent from "../components/delete-team-member";
import style from "./style";
import { eApplicationType } from "../../../../../interfaces/auth";
import removeApplications from "../../../../../api/organisation-members/remove-applications";
import addApplications from "../../../../../api/organisation-members/add-applications";

interface IProps {
  setInvitesLastUpdatedAt: (date: Date) => void;
}

const OrganisationMemberContainer = ({
  setInvitesLastUpdatedAt,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [orgMembersData, setOrgMembersData] = useState<
    IOrganisationMemberProps[] | []
  >([]);
  const [nameSortAscending, setNameSort] = useState<boolean>(true);
  const [jobTitleSortAscending, setJobTitleSort] = useState<boolean>(true);

  const [mobileDrawerVisible, toggleMobileDrawer] = useState<boolean>(false);

  const [
    currentActiveDrawer,
    changeActiveDrawer,
  ] = useState<OrgMembersDrawerTypes>(OrgMembersDrawerTypes.inviteMembers);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    selectedOrgMember,
    setSelectedOrgMember,
  ] = useState<IOrganisationMemberProps>();
  const [currentSortType, setCurrentSort] = useState<OrgMembersSortType>(
    OrgMembersSortType.LastLogin
  );
  const [openModalType, setOpenModalType] = useState<ModalTypes>(
    ModalTypes.deleteOrganisationMember
  );

  useWindowSize();
  const isMobile = getIsMobile();
  const isTablet = getIsTablet();

  const { fetchWrapper, organisationId, user } = useAuth();

  const getData = useCallback(async (): Promise<void> => {
    if (organisationId) {
      const response = await fetchWrapper<IOrganisationMemberProps[]>(
        getOrganisationMembers,
        organisationId,
        user?.id
      );
      setOrgMembersData(response);
    }
  }, [fetchWrapper, organisationId, user?.id]);

  const handleNameSort = () => {
    setCurrentSort(OrgMembersSortType.Name);
    setNameSort((state) => !state);
  };

  const sortMembers = () => {
    switch (currentSortType) {
      case OrgMembersSortType.Name: {
        const sortedData = [...orgMembersData].sort((a, b) => {
          const fullNameA = `${a.firstName} ${a.lastName}`;
          const fullNameB = `${b.firstName} ${b.lastName}`;

          if (nameSortAscending) {
            return fullNameA.localeCompare(fullNameB);
          }
          return fullNameB.localeCompare(fullNameA);
        });

        setOrgMembersData(sortedData);
        break;
      }
      case OrgMembersSortType.JobTitle: {
        setOrgMembersData(
          [...orgMembersData].sort(
            ({ jobTitle: job = "" }, { jobTitle: jobToCompare = "" }) => {
              if (jobTitleSortAscending) {
                return job.localeCompare(jobToCompare);
              }

              return jobToCompare.localeCompare(job);
            }
          )
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const setSortTypeAndOrder = (
    sortType: OrgMembersSortType,
    sortOrder: SortOrder
  ) => {
    setCurrentSort(sortType);

    const sortIsAscending = sortOrder === SortOrder.Ascending;

    switch (sortType) {
      case OrgMembersSortType.Name: {
        setNameSort(sortIsAscending);
        break;
      }
      case OrgMembersSortType.JobTitle: {
        setJobTitleSort(sortIsAscending);
        break;
      }
      default: {
        break;
      }
    }
  };

  const getSortTypeAndOrder = (): ITableSort => {
    switch (currentSortType) {
      case OrgMembersSortType.Name: {
        return {
          sortType: currentSortType,
          sortOrder: nameSortAscending
            ? SortOrder.Ascending
            : SortOrder.Descending,
        };
      }
      default: {
        return {
          sortType: currentSortType,
          sortOrder: nameSortAscending
            ? SortOrder.Ascending
            : SortOrder.Descending,
        };
      }
    }
  };

  const handleDrawerToggle = () => {
    toggleMobileDrawer((state) => !state);
  };

  const setSelectedUserAdminStatusState = useCallback(
    (orgMember: IOrganisationMemberProps): void => {
      const orgMemberData = (orgMembersData as IOrganisationMemberProps[]).map(
        (member: IOrganisationMemberProps) => {
          const newMember = member;
          if (member.id === orgMember.id) {
            // If mobile or tablet
            if (mobileDrawerVisible) {
              setSelectedOrgMember(newMember);
            }
            return orgMember;
          }
          return newMember;
        }
      );

      setOrgMembersData(orgMemberData);
    },
    [mobileDrawerVisible, orgMembersData]
  );

  const handleToggleAdmin = useCallback(
    async (orgMember: IOrganisationMemberProps) => {
      if (organisationId)
        try {
          // As this can take some time first set the status and remove it on fail if needed, this will eliminate the checkbox delay
          const isAdmin = orgMember.applications.includes(
            eApplicationType.TeamManagement
          );

          if (isAdmin) {
            const updatedOrgMember = {
              ...orgMember,
              applications: orgMember.applications.filter(
                (x) => x !== eApplicationType.TeamManagement
              ),
            };
            setSelectedUserAdminStatusState(updatedOrgMember);
            await fetchWrapper(
              removeApplications,
              organisationId,
              orgMember.auth0UserId,
              [eApplicationType.TeamManagement]
            );
            SuccessToast(t("manageTeam##members##AdminRemoved"));
          } else {
            const updatedOrgMember = {
              ...orgMember,
              applications: orgMember.applications.concat([
                eApplicationType.TeamManagement,
              ]),
            };
            setSelectedUserAdminStatusState(updatedOrgMember);
            await fetchWrapper(
              addApplications,
              organisationId,
              orgMember.auth0UserId,
              [eApplicationType.TeamManagement]
            );
            SuccessToast(t("manageTeam##members##AdminAdded"));
          }
        } catch (e) {
          // Return user to orignal state
          setSelectedUserAdminStatusState(orgMember);
          log(DataDogLogTypes.ERROR, "Error toggling admin permissions", e);

          ErrorToast(
            "member-delete-error",
            t("manageTeam##members##AdminToggleError")
          );
        }
    },
    [fetchWrapper, organisationId, setSelectedUserAdminStatusState, t]
  );

  const handleDeleteMemberClick = (
    orgMember: IOrganisationMemberIdentifierProps
  ) => {
    setIsModalOpen(true);
    setOpenModalType(ModalTypes.deleteOrganisationMember);
    const orgMemberData = orgMembersData.find((member) => {
      return member.key === orgMember.key;
    });
    setSelectedOrgMember(orgMemberData);
  };

  const handleJobTitleSort = () => {
    setCurrentSort(OrgMembersSortType.JobTitle);
    setJobTitleSort((state) => !state);
  };

  const handleConfirmDeleteMemberClick = useCallback(async () => {
    if (selectedOrgMember) {
      setIsModalOpen(false);

      try {
        await fetchWrapper(
          deleteOrganisationMember,
          selectedOrgMember.organisationId,
          selectedOrgMember.id
        );
        const orgMemberData = orgMembersData.find((member) => {
          return member.key === selectedOrgMember.key;
        });
        setSelectedOrgMember(orgMemberData);
        await getData();
        SuccessToast(t("manageTeam##members##Team member deleted"));
      } catch (e) {
        log(DataDogLogTypes.ERROR, "Error deleting team member", e);
        ErrorToast(
          "member-delete-error",
          t("manageTeam##members##An error occurred deleting this team member")
        );
      }
      handleDrawerToggle();
    }
  }, [fetchWrapper, getData, orgMembersData, selectedOrgMember, t]);

  const handleMobileSortClick = useCallback(() => {
    if (currentActiveDrawer !== OrgMembersDrawerTypes.sortMembers) {
      changeActiveDrawer(OrgMembersDrawerTypes.sortMembers);
    }

    handleDrawerToggle();
  }, [currentActiveDrawer]);

  const handleMobileMoreInfoClick = useCallback(
    (orgMember: IOrganisationMemberIdentifierProps) => {
      const orgMemberData = orgMembersData.find((member) => {
        return member.key === orgMember.key;
      });

      setSelectedOrgMember(orgMemberData);
      if (currentActiveDrawer !== OrgMembersDrawerTypes.membersMoreInfo) {
        changeActiveDrawer(OrgMembersDrawerTypes.membersMoreInfo);
      }
      handleDrawerToggle();
    },
    [currentActiveDrawer, orgMembersData]
  );

  const handleOnCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const orgMemberName = selectedOrgMember
    ? `${selectedOrgMember.firstName} ${selectedOrgMember?.lastName}`
    : "";

  const confirmDeleteContentProps: IConfirmContentProps = useMemo(() => {
    return {
      confirmAction: handleConfirmDeleteMemberClick,
      onClose: () => setIsModalOpen(false),
      primaryButtonLabel: "manageTeam##members##Delete member",
      secondaryButtonLabel: "Cancel",
      mainContent: (
        <DeleteTeamMemberModalMainContent memberName={orgMemberName} />
      ),
    };
  }, [handleConfirmDeleteMemberClick, orgMemberName]);

  const confirmMobileContentProps = { ...confirmDeleteContentProps };
  confirmMobileContentProps.onClose = () => handleDrawerToggle();
  confirmMobileContentProps.primaryButtonLabel = "Delete";

  useEffect(() => {
    sortMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameSortAscending, jobTitleSortAscending]);

  return (
    <Fragment>
      <div css={style.formContainer}>
        <OrgMembersTable
          orgMembers={orgMembersData}
          getData={getData}
          handleNameSort={handleNameSort}
          nameSortAscending={nameSortAscending}
          currentSortType={currentSortType}
          handleMobileSortClick={handleMobileSortClick}
          isModalOpen={isModalOpen}
          jobTitleSortAscending={jobTitleSortAscending}
          handleJobTitleSort={handleJobTitleSort}
          setIsModalOpen={(value) => setIsModalOpen(value)}
          selectedOrgMember={selectedOrgMember}
          handleDeleteOrgMemberClick={handleDeleteMemberClick}
          handleMobileMoreInfoClick={handleMobileMoreInfoClick}
          openModalType={openModalType}
          confirmDeleteContentProps={confirmDeleteContentProps}
          handleOnCloseModal={handleOnCloseModal}
          setSortTypeAndOrder={setSortTypeAndOrder}
          handleToggleAdmin={handleToggleAdmin}
        />
      </div>
      {(isMobile || isTablet) && (
        <OrgMembersMobileControls
          confirmDeleteProps={confirmMobileContentProps}
          mobileDrawerVisible={mobileDrawerVisible}
          handleDrawerToggle={handleDrawerToggle}
          currentActiveDrawer={currentActiveDrawer}
          setInvitesLastUpdatedAt={setInvitesLastUpdatedAt}
          selectedOrgMember={selectedOrgMember}
          handleToggleAdmin={handleToggleAdmin}
          setSortTypeAndOrder={setSortTypeAndOrder}
          getSortTypeAndOrder={getSortTypeAndOrder}
        />
      )}
    </Fragment>
  );
};

export default OrganisationMemberContainer;
