/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useOrg from "src/providers/organisation/hooks";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage,
} from "amazon-cognito-identity-js";
import { ICognitoError } from "src/auth/interfaces";
import { IAuthenticatedUser } from "@songtradr/spa-common";
import {
  getUserPermissions,
  IUserPermissions,
} from "src/auth/access-token-utils";
import { useHistory } from "react-router-dom";
import getOrganisationMembers from "src/api/organisation-members/get-org-members";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { useTranslation } from "react-i18next";
import { ISimpleUser } from "src/interfaces/auth";
import CognitoContext from "./context";
import { transformCognitoUser } from "./utils/transform-cognito-user";
import { DataDogLogTypes, log } from "../utils/data-dog";
import config from "../config";
import getOrgMember from "../api/organisation-members/get-org-member";

interface IProps {
  children: ReactElement;
  userPoolId: string;
  clientId: string;
}

interface IGetUsersResponse {
  orgMemberUsers: ISimpleUser[];
  superAdminUsers: ISimpleUser[];
}

const cookieStorage = new CookieStorage({
  domain: config.cognito.domain,
  secure: config.cognito.isSecure,
});

const CognitoProvider = ({
  children,
  userPoolId,
  clientId,
}: IProps): ReactElement => {
  const isSessionCheckInProgress = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [user, setUser] = useState<IAuthenticatedUser>();
  const [error, setError] = useState<ICognitoError | null>(null);
  const [organisationId, setOrganisationId] = useState<string>("");
  const [hasMultipleOrgs, setHasMultipleOrgs] = useState(false);
  const [userPermissions, setUserPermissions] = useState<IUserPermissions>({
    canAccessTeams: false,
    canAccessProjects: false,
  });
  const [users, setUsers] = useState<ISimpleUser[]>();
  const [superAdmins, setSuperAdmins] = useState<ISimpleUser[]>();
  const [allUsers, setAllUsers] = useState<ISimpleUser[]>();

  const { getOrg } = useOrg();
  const { t } = useTranslation();

  const history = useHistory();

  const userPool = useMemo(() => {
    return new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
      Storage: cookieStorage,
    });
  }, [clientId, userPoolId]);

  const cognitoUser = userPool.getCurrentUser();

  const resetStates = useCallback(
    (redirect: boolean) => {
      setIsLoading(false);
      setUser(undefined);
      setIsAuthenticated(false);
      setOrganisationId("");
      if (redirect) {
        history.push("/login");
      }
    },
    [history]
  );

  const logout = useCallback((redirect = true) => {
    const userToSetup = userPool.getCurrentUser();
    if (userToSetup) {
      userToSetup.signOut(() => {
        cookieStorage.removeItem("projects-organisationId");
        resetStates(redirect);
      });
    } else {
      resetStates(redirect);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOrg = useCallback(() => {
    history.push("/select-org");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrgIdsFromDecodedToken = (decodedToken: { [id: string]: any }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const cognitoGroups: string[] = decodedToken["cognito:groups"];
    if (cognitoGroups) {
      if (cognitoGroups.includes("superadmin")) {
        return [];
      }

      const orgMemberCognitoGroups = cognitoGroups.filter((g) =>
        g.startsWith("org:member:")
      );
      if (orgMemberCognitoGroups?.length > 0) {
        return orgMemberCognitoGroups.map((g) => g.replace("org:member:", ""));
      }

      log(
        DataDogLogTypes.ERROR,
        "User tried to login without being a member of an organisation",
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `Cognito username: ${decodedToken.username}`
      );
    } else {
      log(
        DataDogLogTypes.ERROR,
        "User tried to login without cognito groups",
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `Cognito username: ${decodedToken.username}`
      );
    }
    return [];
  };

  const hasSuperAdminRole = (decodedToken: { [id: string]: any }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const cognitoGroups: string[] = decodedToken["cognito:groups"];
    return cognitoGroups.includes("superadmin");
  };

  const getUsers = async (
    jwt: string,
    orgId: string,
    userId?: string,
    includeSuperAdmins?: boolean
  ): Promise<IGetUsersResponse> => {
    const orgMemberUsers: ISimpleUser[] = [];
    const superAdminUsers: ISimpleUser[] = [];

    const usersResponse = await getOrganisationMembers(
      jwt,
      orgId,
      userId,
      includeSuperAdmins
    );

    if (usersResponse) {
      usersResponse.forEach((orgUser) => {
        const simpleOrgUser = {
          name: `${orgUser.firstName} ${orgUser.lastName}`,
          id: orgUser.auth0UserId,
          email: orgUser.email,
        };

        if (orgUser.isSuperAdmin) {
          superAdminUsers.push(simpleOrgUser);
        } else {
          orgMemberUsers.push(simpleOrgUser);
        }
      });
    }

    return {
      orgMemberUsers,
      superAdminUsers,
    };
  };

  const setSession = useCallback(
    async (session: CognitoUserSession) => {
      if (session) {
        setIsLoading(true);
        const isSessionValid = session.isValid();
        setIsAuthenticated(true);
        if (isSessionValid) {
          const token = session.getIdToken();
          const accessToken = session.getAccessToken();
          const jwt = accessToken.getJwtToken();
          const decodedToken = accessToken.decodePayload();

          // Get organisation
          const orgIds = getOrgIdsFromDecodedToken(decodedToken);

          let orgId = orgIds.length === 1 ? orgIds[0] : null;
          if (hasSuperAdminRole(decodedToken)) {
            setIsSuperAdmin(true);
            const previousOrgId = cookieStorage.getItem(
              "projects-organisationId"
            );
            if (previousOrgId) {
              orgId = previousOrgId;
            }
          }

          setHasMultipleOrgs(
            orgIds.length > 1 || hasSuperAdminRole(decodedToken)
          );

          if (orgId) {
            await getOrg(orgId, jwt);
            setOrganisationId(orgId);
            if (hasSuperAdminRole(decodedToken)) {
              cookieStorage.setItem("projects-organisationId", orgId);
            }

            // Get profile
            // Legacy users will have auth0 userIds, so we check if they have those first.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const sub = token.payload["custom:exUserId"] || decodedToken.sub;
            const orgMember = await getOrgMember(jwt, orgId, sub);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const transformedUser = transformCognitoUser(token);
            setUser(transformedUser);
            const permissions = getUserPermissions(
              hasSuperAdminRole(decodedToken),
              orgMember.applications
            );

            setIsOrgAdmin(permissions.canAccessTeams);
            if (!permissions.canAccessProjects) {
              log(
                DataDogLogTypes.ERROR,
                "User tried to login without a projects starter subscription",
                orgMember
              );

              logout();
              return;
            }
            setUserPermissions(permissions);
            if (!users || !superAdmins) {
              const { orgMemberUsers, superAdminUsers } = await getUsers(
                jwt,
                orgId,
                user?.id,
                true
              );

              setUsers(orgMemberUsers);
              setSuperAdmins(superAdminUsers);
              setAllUsers([...orgMemberUsers, ...superAdminUsers]);
            }
          } else if (orgIds.length > 1 || hasSuperAdminRole(decodedToken)) {
            selectOrg();
          } else {
            // unable to get org id from jwt so user should be logged out
            logout();
          }
        } else {
          logout();
        }
        setIsLoading(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [getOrg, history, user, userPool, users, superAdmins]
  );

  const setupUserSession = useCallback(async () => {
    const userToSetup = userPool.getCurrentUser();

    const getUserSession = async () => {
      return new Promise<void>((resolve) => {
        userToSetup?.getSession(
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          async (err: any, session: CognitoUserSession) => {
            if (err) {
              logout();
            }

            await setSession(session);
            setIsLoading(false);
            resolve();
          }
        );
      }).catch((cognitoError) => {
        if (cognitoError) {
          log(
            DataDogLogTypes.ERROR,
            "Error getting cognito session",
            cognitoError
          );
          logout();
        }
      });
    };
    await getUserSession();
    setIsLoading(false);
  }, [userPool, setSession, logout]);

  /**
   * Validates the current session with access & id tokens in local stroage
   * @returns boolean representation of session validity
   */
  const isSessionValid = useCallback(async (): Promise<boolean> => {
    let isValid = false;

    const getValidatedSession = () => {
      return new Promise<boolean>((resolve) => {
        if (cognitoUser) {
          const signInUserSession = cognitoUser.getSignInUserSession();
          if (signInUserSession && !signInUserSession.isValid()) {
            setIsLoading(true);
            cognitoUser.refreshSession(
              signInUserSession.getRefreshToken(),
              (err: any, session: CognitoUserSession): void => {
                if (err) {
                  resolve(false);
                  return;
                }
                if (session.isValid()) {
                  void setSession(session);
                  resolve(session.isValid());
                  return;
                }
                resolve(false);
              }
            );
            setIsLoading(false);
          } else {
            cognitoUser?.getSession(
              (err: any, session: CognitoUserSession): void => {
                if (err) {
                  resolve(false);
                  return;
                }
                if (session.isValid()) {
                  resolve(session.isValid());
                  return;
                }
                resolve(false);
              }
            );
          }
        } else {
          resolve(false);
        }
      });
    };
    isValid = await getValidatedSession();

    if (!isValid) {
      logout(false);
    }
    return isValid || false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser]);
  const getAccessToken = useCallback((): string => {
    let jwt;

    const userWithAccessToken = userPool.getCurrentUser();
    if (userWithAccessToken) {
      userWithAccessToken.getSession(
        (err: any, session: CognitoUserSession) => {
          if (err) {
            logout();
          }
          if (session) {
            const token = session.getAccessToken();
            jwt = token.getJwtToken();
          }
        }
      );
    } else {
      logout();
    }

    return jwt || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPool]);

  const login = (target?: string) => {
    if (target) {
      history.push(target);
    } else {
      history.push("/");
    }
  };

  /*
    The purpose of this useEffect hook is to check for session validity on SPA load.
    If the cognito token is valid, we can allow the user to access the app.
    Ideally this is run once, on SPA load. 
    So we setup the user in the provider plus authorization permisions for the app here.
  */
  useEffect(() => {
    const checkValidity = async () => {
      const validity = await isSessionValid();
      if (validity) {
        await setupUserSession();
      } else {
        logout(false);
      }
    };

    if (!isSessionCheckInProgress.current) {
      try {
        isSessionCheckInProgress.current = true;
        void checkValidity();
      } catch (cognitoError) {
        if (cognitoError) {
          log(
            DataDogLogTypes.ERROR,
            "Error getting cognito session",
            cognitoError
          );
          logout();
        }
      } finally {
        isSessionCheckInProgress.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPassword = (email: string) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const userToUpdate = new CognitoUser(userData);
    userToUpdate?.forgotPassword({
      onSuccess() {
        // successfully initiated reset password request
        if (isPasswordReset) setIsPasswordReset(false);
      },
      onFailure(e) {
        log(DataDogLogTypes.ERROR, "Forgot password error", e);
        if (isPasswordReset) setIsPasswordReset(false);
        if (e.toString().includes("LimitExceededException")) {
          ErrorToast(
            "reset-limit-reached",
            t("Reset code limit reached, try again later")
          );
        }
      },
    });
  };

  const confirmResetVerificationCode = (
    verificationCode: string,
    newPassword: string,
    email: string
  ) => {
    setIsLoading(true);
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const userToUpdate = new CognitoUser(userData);
    userToUpdate?.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        setIsLoading(false);
        setIsPasswordReset(true);
        setError(null);
      },
      onFailure(e) {
        log(DataDogLogTypes.ERROR, "Password reset fail", e);
        setError({
          error: "password reset fail",
          error_description:
            "Invalid verification code provided, please try again.",
        });
        setIsLoading(false);
        if (isPasswordReset) setIsPasswordReset(false);
      },
    });
  };

  const changePassword = (
    verificationCode: string,
    newPassword: string,
    email: string
  ) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const userToUpdate = new CognitoUser(userData);
    userToUpdate?.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        SuccessToast(t("Your password has been updated"));
        history.push("/dashboard");
      },
      onFailure(e) {
        log(DataDogLogTypes.ERROR, "Failed to change password", e);
        ErrorToast(
          "error-change-password",
          t("Failed to change password, please check that the code is correct")
        );
      },
    });
  };

  const handleLogin = (username: string, password: string) => {
    setIsLoading(true);
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const userData = {
      Username: username,
      Pool: userPool,
      Storage: cookieStorage,
    };

    const userLogin = new CognitoUser(userData);

    function onLoginFailure(result: string) {
      if (isPasswordReset) setIsPasswordReset(false);
      setError({
        error: "login failed",
        error_description: "Incorrect email address or password provided",
      });
      setIsLoading(false);
      log(DataDogLogTypes.ERROR, "Login failure", result);
    }

    async function onLoginSuccess() {
      if (isPasswordReset) setIsPasswordReset(false);
      await setupUserSession();

      setError(null);
      setIsLoading(false);
    }

    userLogin.authenticateUser(authenticationDetails, {
      onSuccess: onLoginSuccess,
      onFailure: onLoginFailure,
    });
  };

  const switchOrg = async (orgId: string, redirectToDashboard = true) => {
    const userToSetup = userPool.getCurrentUser();

    const getUserSession = async () => {
      return new Promise<void>((resolve) => {
        userToSetup?.getSession(
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          async (err: any, session: CognitoUserSession) => {
            if (err) {
              logout();
            }

            if (session) {
              const userIsAuthenticated = session.isValid();
              setIsAuthenticated(true);
              setIsLoading(true);

              if (userIsAuthenticated) {
                const token = session.getIdToken();
                const accessToken = session.getAccessToken();
                const jwt = accessToken.getJwtToken();
                const decodedToken = accessToken.decodePayload();
                setIsSuperAdmin(hasSuperAdminRole(decodedToken));

                if (orgId) {
                  await getOrg(orgId, jwt);
                  setOrganisationId(orgId);

                  // Get profile
                  // Legacy users will have auth0 userIds, so we check if they have those first.
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const sub =
                    token.payload["custom:exUserId"] || decodedToken.sub;
                  const orgMember = await getOrgMember(jwt, orgId, sub);

                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const transformedUser = transformCognitoUser(token);
                  setUser(transformedUser);
                  const permissions = getUserPermissions(
                    hasSuperAdminRole(decodedToken),
                    orgMember.applications
                  );

                  setIsOrgAdmin(permissions.canAccessTeams);

                  if (!permissions.canAccessProjects) {
                    /* User does not have a projects starter subscription and should be logged out */
                    log(
                      DataDogLogTypes.ERROR,
                      "User tried to login without a projects starter subscription",
                      orgMember
                    );
                    logout();
                  } else {
                    setUserPermissions(permissions);

                    cookieStorage.setItem("projects-organisationId", orgId);

                    if (!users || !superAdmins) {
                      const {
                        orgMemberUsers,
                        superAdminUsers,
                      } = await getUsers(jwt, orgId, user?.id, true);

                      setUsers(orgMemberUsers);
                      setSuperAdmins(superAdminUsers);
                    }
                  }
                } else if (hasSuperAdminRole(decodedToken)) {
                  selectOrg();
                } else {
                  // unable to get org id from jwt so user should be logged out
                  logout();
                }
              } else {
                setIsLoading(false);
                setIsAuthenticated(false);
              }
            }
            setIsLoading(false);
            resolve();
          }
        );
      }).catch((cognitoError) => {
        if (cognitoError) {
          log(
            DataDogLogTypes.ERROR,
            "Error getting cognito session",
            cognitoError
          );
          logout();
        }
      });
    };
    await getUserSession();
    setIsLoading(false);
    if (redirectToDashboard) {
      history.push("/dashboard");
    }
  };
  // We need type any. Unknown type is not an option.
  // Generic T type is not an option because generic async function with arrow function syntax makes that function as an unbound function,
  // which can lead to unexpected behavior
  async function fetchWrapper<T>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    fetchFunction: (...args: any[]) => Promise<T>,
    ...args: any[]
  ): Promise<T> {
    const isSession = await isSessionValid();
    if (!isSession) {
      throw new Error("Session has expired. Please login again.");
    }
    const accessToken = getAccessToken();
    const result = await fetchFunction(accessToken, ...args);
    return result;
  }

  return (
    <CognitoContext.Provider
      value={{
        login,
        handleLogin,
        isSessionValid,
        fetchWrapper,
        getAccessToken,
        logout,
        user,
        users,
        superAdmins,
        isOrgAdmin,
        userPermissions,
        error,
        organisationId,
        isAuthenticated,
        isLoading,
        resetPassword,
        changePassword,
        isPasswordReset,
        confirmResetVerificationCode,
        isSuperAdmin,
        switchOrg,
        hasMultipleOrgs,
        allUsers,
      }}
    >
      {children}
    </CognitoContext.Provider>
  );
};

export default CognitoProvider;
