import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

const sharedStyles = `
  width: 100%;
  margin: 0.5rem 0.5rem;
  padding-left: 1.5rem;
  font-weight: 400;
  padding-right: 1.5rem;
  height: 6.25rem;
  background: ${theme.colors.background.white};;
  border: 0.063rem solid ${theme.colors.background.lightGray};
  box-sizing: border-box;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  .customMediaButton {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;

    :hover {
      background-color: ${theme.colors.secondary.lightPurple};
    }
  }

  :hover {
    cursor: pointer;  
  }

  @media ${queries.large} {
    width: 11.25rem;
    margin: 0.5rem 1.5rem 1.5rem 0;
  }
`;

export default {
  selected: css`
    ${sharedStyles}
    background: rgba(34, 179, 242, 0.1);
    border: 0.063rem solid ${theme.colors.secondary.lightBlue};
  `,

  unSelected: css`
    ${sharedStyles}
    :hover {
      border: 0.063rem solid ${theme.colors.secondary.lightBlue};
    }
  `,

  name: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  `,

  moreInfo: css`
    position: absolute;
    right: 0;
    bottom: 0;
  `,

  dropdownMenu: css`
    border-radius: 0.2rem;
    width: 5.5rem;
    background-color: ${theme.colors.white};
    box-shadow: 0 0 0.313rem 0.125rem rgb(0 0 0 / 12%),
      0 0.375rem 1rem 0 rgb(0 0 0 / 8%), 0 0.563rem 1.75rem rgb(0 0 0 / 5%);

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: ${theme.colors.white};
    }
  `,

  actionButton: css`
    display: inline-block;
    border: none;
    margin-left: -0.5rem;
    text-decoration: none;
    background-color: ${theme.colors.white};
    cursor: pointer;
    font-family: ${theme.fonts.sofiaPro};

    :hover {
      font-weight: bold;
      background-color: ${theme.colors.white};
    }
  `,
};
