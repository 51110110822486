import dayjs, { Dayjs } from "dayjs";
import { LicenseFixedDurations, ISODateParseFormat } from "src/constants";
import DateStringYYYYMMDDToMoment from "src/utils/time";

export const getDerivedTermEndDate = (
  startDate: Dayjs | undefined,
  endDate?: string,
  fixedDuration?: LicenseFixedDurations
): Dayjs | undefined => {
  if (endDate) {
    return DateStringYYYYMMDDToMoment(endDate);
  }

  if (startDate && fixedDuration) {
    const momentStartDate = dayjs(startDate, ISODateParseFormat);
    switch (fixedDuration) {
      case LicenseFixedDurations.ThreeMonths:
        return momentStartDate.add(3, "months").subtract(1, "days");

      case LicenseFixedDurations.SixMonths:
        return momentStartDate.add(6, "months").subtract(1, "days");

      case LicenseFixedDurations.OneYear:
        return momentStartDate.add(1, "year").subtract(1, "days");

      case LicenseFixedDurations.EighteenMonths:
        return momentStartDate.add(18, "months").subtract(1, "days");

      case LicenseFixedDurations.TwoYears:
        return momentStartDate.add(2, "year").subtract(1, "days");

      case LicenseFixedDurations.ThreeYears:
        return momentStartDate.add(3, "year").subtract(1, "days");

      case LicenseFixedDurations.FourYears:
        return momentStartDate.add(4, "year").subtract(1, "days");

      case LicenseFixedDurations.FiveYears:
        return momentStartDate.add(5, "year").subtract(1, "days");

      default:
        return undefined;
    }
  }

  return undefined;
};

export default getDerivedTermEndDate;
