import { BlockedEmailProviders } from "src/constants";
import { IValidationResult } from "src/interfaces/organisation-invite";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ValidateEmails = (emails: string[]): IValidationResult => {
  let successful = true;
  let validEmailCount = 0;
  let errorMessageKey = "";

  emails.forEach((email: string) => {
    if (!emailRegex.test(email)) {
      successful = false;
      if (!errorMessageKey) {
        errorMessageKey = "inviteMembers##validation##OneOrMoreInvalidEmails";
      }
    } else if (
      email.includes("@") &&
      BlockedEmailProviders.includes(email.split("@")[1])
    ) {
      successful = false;
      if (!errorMessageKey) {
        errorMessageKey =
          "inviteMembers##validation##OneOrMoreEmailAddressPersonal";
      }
    } else {
      validEmailCount += 1;
    }
  });

  return { successful, validEmailCount, errorMessageKey } as IValidationResult;
};

export const getIsValidEmail = (email: string): boolean => {
  return emailRegex.test(email);
};

export default ValidateEmails;
