import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";

export default {
  documentsUploadContainer: css`
    margin: 0;
  `,
  documentsFileUploader: css`
    border: none;
    @media ${queries.medium} {
      &:hover {
        border: 0.0625rem solid #d9d9d9 !important;
      }
      margin-left: 0 !important;
      margin-right: 0 !important;
      border: 0.0625rem solid #d9d9d9;
      padding: 0;
      padding-top: 0.625rem;
      .ant-table {
        .ant-table-thead > tr {
          th {
            padding-bottom: 0.5rem;
          }
          th:nth-of-type(1) {
            padding-left: 1.5625rem;
          }
          th:nth-of-type(5) {
            padding-right: 0.9375rem;
          }
        }
        .ant-table-tbody > tr {
          td:nth-of-type(1) {
            padding-left: 1.5625rem;
          }
          td:nth-of-type(5) {
            padding-right: 0.9375rem;
          }
        }
      }
      .ant-table .ant-table-tbody > tr:hover {
        > td {
          background: #f8f6ff;
        }
      }
    }
  `,
};
