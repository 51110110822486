import { Typography } from "antd";
import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  IFormContactsInvoices,
  IFormInvoicee,
} from "src/pages/projects/project/interfaces";
import style from "src/pages/projects/project/styles/index";
import { Operation } from "src/constants";
import { v4 } from "uuid";
import ContactInvoiceDetails from "../contact-invoice-details";
import InvoiceContactsRow from "../invoice-contacts-row";

type IContactInvoices = {
  contactsInvoices: IFormContactsInvoices[];
  propertyName: string;
  invoicee: IFormInvoicee;
  onFormContactInvoicesChange: (invoices: IFormContactsInvoices[]) => void;
  onUpdateInvoicee: (propertyName: string, value: string) => void;
};
const ContactInvoices = ({
  contactsInvoices,
  propertyName,
  invoicee,
  onUpdateInvoicee,
  onFormContactInvoicesChange,
}: IContactInvoices): ReactElement => {
  const { t } = useTranslation();

  const handlePerformOperation = useCallback(
    (
      operation: Operation,
      contactInvoice?: IFormContactsInvoices,
      index?: number
    ) => {
      if (contactInvoice) {
        const isRemoveOperation = operation === Operation.remove;
        const isUpdateOperation = operation === Operation.update;
        const isValidIndex = index || index === 0;
        const invoice: IFormContactsInvoices = {
          name: contactInvoice.name,
          email: contactInvoice.email,
          key: contactInvoice.key,
        };
        if (isRemoveOperation && isValidIndex) {
          const newArray = [...contactsInvoices];
          newArray.splice(index, 1);
          onFormContactInvoicesChange(newArray);
        } else if (operation === Operation.add) {
          onFormContactInvoicesChange([...contactsInvoices, invoice]);
        } else if (isUpdateOperation && isValidIndex) {
          const newArray = [...contactsInvoices];
          newArray[index] = invoice;
          onFormContactInvoicesChange(newArray);
        }
      }
    },
    [contactsInvoices, onFormContactInvoicesChange]
  );
  const handleDeleteRowItem = useCallback(
    (index: number) => {
      handlePerformOperation(Operation.remove, {}, index);
    },
    [handlePerformOperation]
  );

  const handleAddNewRowItem = useCallback(() => {
    handlePerformOperation(
      Operation.add,
      {
        name: "",
        email: "",
        key: v4(),
      },
      contactsInvoices.length
    );
  }, [contactsInvoices.length, handlePerformOperation]);

  return (
    <div css={style.additionalSectionContainerStyles}>
      <div css={style.titleLabelContainer}>
        <Typography.Text css={style.titleLabel}>
          {t("ProjectsPage##contactsSection##Invoice Details")}
        </Typography.Text>
      </div>
      <div>
        <ContactInvoiceDetails
          onUpdateInvoicee={onUpdateInvoicee}
          invoicee={invoicee}
        />
        <InvoiceContactsRow
          contactsInvoices={contactsInvoices}
          propertyName={propertyName}
          onPerformOperation={handlePerformOperation}
          onDeleteRowItem={handleDeleteRowItem}
          onAddNewRowItem={handleAddNewRowItem}
        />
      </div>
    </div>
  );
};

export default ContactInvoices;
