import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

const sharedButtonStyle = `
  flex: 1;
  display: flex;
  font-size: 0.9rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 0;
  padding: 0;
  background-color: ${theme.colors.white};
  font-family: ${theme.fonts.sofiaPro};
  font-weight: bold;
  border: 0;
  :first-of-type {
    border-right: 1px solid ${theme.colors.background.veryLightGray};
  }
`;

const iconsStyles = `
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-self: center;
`;
const sharedContainerStyle = `
  display: flex;
  flex-direction: column;
  border-radius: 0;
`;

const sectionContainer = `
padding-left: 1.5rem;
padding-right: 1.5rem;
display: flex;
border-left: 0.25rem solid #0b6dc7;
`;

const termShared = `
background-color: ${theme.colors.white};
border-bottom: 1px solid #9999;
display: flex;
flex-direction: column;
margin-bottom: 1.5rem;
border-radius: 0;
`;
export default {
  monetaryContainer: css`
    align-self: self-end;
  `,

  formItemContainer: css`
    width: 100%;
  `,
  documentSectionContainer: css`
    padding-right: 1rem;
    padding-left: 1rem;
  `,
  playThrough: css`
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
    justify-content: start;
    margin-top: 8px;
  `,
  rowGap: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  `,
  reportSpiner: css`
    opacity: 0.5;
  `,
  deleteIconContainer: css`
    padding-top: 0 !important;
    justify-content: end;
    display: flex;
    flex-direction: column;

    @media ${queries.medium} {
      padding-top: 0.7rem;
    }
  `,
  loadingContainer: css`
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
  `,
  addButton: css`
    margin-left: 6px;
    div {
      float: right;
      .ant-btn[disabled],
      .ant-btn[disabled]:hover,
      .ant-btn[disabled]:focus,
      .ant-btn[disabled]:active {
        color: ${theme.colors.background.lightGray};
        background: ${theme.colors.white};
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        svg {
          margin-right: 0;
          circle {
            stroke: none;
          }
          line {
            stroke: ${theme.colors.background.lightGray};
          }
        }
      }
    }
  `,
  projectContainer: css`
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    .ant-picker-suffix {
      color: ${theme.colors.secondary.black} !important;
    }
    zoom: 90%;
  `,
  contactInvoicesContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    margin-top: 1.5rem;
  `,
  addIconContainer: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 38px;
    height: 38px;
    color: #222;
    svg {
      color: #222 !important;
      width: 34px;
      padding: 0.5rem;
      height: 34px;
    }
  `,
  projectDescriptionSectionContainer: css`
    ${sectionContainer}
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    > div {
      :not(:first-of-type) {
        row-gap: 2.25rem;
      }
    }
  `,
  collapsibleSectionDivider: css`
    margin-top: 1.5rem;
    min-width: auto;
    width: auto;
  `,
  IconsContainer: css`
    ${iconsStyles}
    padding-top:1.05rem;
  `,
  collapsibleSectionContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;

    .ant-form-item-explain-error {
      font-weight: 400;
      font-size: 16px;
    }
  `,
  projectDescriptionSection: css`
    width: 100%;
  `,
  feeContainer: css`
    width: fit-content;
  `,
  alignIconButton: css`
    margin-top: -0.3rem;
  `,
  documentsIconAndTitle: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  sectionFormContainer: css`
    box-shadow: none;
    border: none !important;
    border-radius: 0 !important;
    display: flex;
    row-gap: 0.8rem;
    flex-direction: column;
  `,

  documentsPanel: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `,
  verticalDividerContainer: css`
    display: flex;
    border-top: 0.0625rem solid ${theme.colors.background.lightGray};
    border-width: 1px;
    border-bottom: 0.0625rem solid ${theme.colors.background.lightGray};
  `,
  formItemWidth: css`
    width: 40px;
    max-width: 40px;
  `,
  additionalFormItemStyle: css`
    .ant-form-item-control-input-content {
      display: flex !important;
      justify-content: flex-end !important;
    }
  `,
  iconsContainer: css`
    ${iconsStyles}
    @media ${queries.large} {
      padding-top: 1.75rem;
    }
  `,
  additionalProductInformationContainer: css`
    align-items: flex-start !important;
  `,
  additionalAssetsContainer: css`
    ${sectionContainer};
    align-items: flex-start !important;
  `,
  multipleRows: css`
    display: flex;
    overflow: auto hidden;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  `,
  rightSideDescriptionContainer: css`
    display: flex;
    flex-direction: column;
    padding-top: 1.75rem;
    row-gap: 1.5rem;
  `,
  trackWithLicensesContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  `,
  formItemTop: css`
    padding-top: 0;
  `,
  trackTitleLabel: css`
    font-size: 1.1rem;
    font-weight: 300;
  `,
  leftSideDescriptionContainer: css`
    display: flex;
    width: 100%;
    align-self: flex-start;
    flex-direction: column;
  `,
  switchContainer: css`
    display: flex;
    justify-content: space-between;
  `,
  verticalDivider: css`
    display: flex;
    margin: 0;
    align-self: center;
    height: 60%;
  `,
  switchWithClientText: css`
    font-weight: 400;
    font-size: 1rem;
  `,
  lastOfSectionBottom: css`
    padding-bottom: 0.5rem;
  `,

  switchWithClientTextAlign: css`
    align-self: center;
  `,

  additionalSectionContainerStyles: css`
    ${sectionContainer}
    flex-direction: column;
    > div {
      :not(:first-of-type) {
        align-items: baseline;
        row-gap: 2.25rem;
      }
    }
  `,
  additionalAssetSectionContainerStyles: css`
    ${sectionContainer};
    align-items: flex-start;
    > div {
      :not(:first-of-type) {
        row-gap: 2.25rem;
      }
    }
  `,
  sectionContainer: css`
    ${sectionContainer}
    flex-direction: column;
    row-gap: 2.25rem;
  `,
  assetsContainer: css`
    ${sectionContainer}
    align-items: flex-start;
    flex-direction: column;
    > div {
      :not(:first-of-type) {
        row-gap: 2.25rem;
      }
    }
  `,
  assetSectionContainer: css`
    ${sectionContainer}
    width:100%;
    flex-direction: row;
    column-gap: 2.25rem;
  `,

  basicInputContainer: css`
    margin-bottom: 0.5rem;
  `,
  drawer: css`
    .ant-drawer-content {
      min-height: 18.75rem !important;
    }
  `,
  emailLabel: css`
    color: ${theme.colors.background.lightGray};
  `,
  formItem: css`
    margin-bottom: 0 !important;
    input {
      font-size: 16px;
    }
  `,
  termTypeAndFeesSection: css`
    display: flex !important;
    flex-direction: row;
    align-items: center;
    .ant-row {
      flex: unset;
    }
  `,
  termType: css`
    .ant-form-item-control-input-content {
      display: flex !important;
      flex-direction: row;
      align-items: center;
    }
  `,
  clientContainer: css`
    display: flex;
    flex-direction: row;
    > div {
      flex: 1 1 0;
      width: 0;
    }
    column-gap: 1.5rem;
    width: 100%;
  `,
  sectionBottom: css`
    padding-bottom: 2.25rem;
  `,
  projectsContainer: css`
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    .ant-picker-suffix {
      color: ${theme.colors.secondary.black} !important;
    }
  `,
  titleLabelContainer: css`
    margin-bottom: 1.5rem;
  `,
  titleLabel: css`
    font-size: 1.5rem;
    font-weight: 300;
  `,
  sectionDivider: css`
    margin-left: 1.75rem;
    margin-right: 1.75rem;
    margin-top: 1.5rem;
    min-width: auto;
    width: auto;
  `,
  sectionHeader: css`
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: ${theme.colors.secondary.blueOnBlack};
    font-weight: bold;
    padding-left: 1rem !important;
    padding-top: 0.5rem !important;
  `,
  formContainer: css`
    margin: 1rem;
    @media ${queries.large} {
      margin-left: 19rem;
      margin-bottom: 5rem;
    }
  `,
  childFormContainer: css`
    ${sharedContainerStyle}
    background-color: ${theme.colors.white};
  `,
  documentsContainer: css`
    ${sharedContainerStyle}
    margin: 0 0.5rem;
    background: ${theme.colors.white};
    @media ${queries.medium} {
      background: transparent;
    }
  `,
  tagsSectionBottom: css`
    margin-bottom: 0.5rem;
  `,
  childFormSectionContainer: css`
    margin: 0 !important;
    padding: 0 !important;
  `,
  primaryFormSectionHeader: css`
    margin-bottom: 1.2rem;
    font-size: 1.25rem;
    color: ${theme.colors.secondary.navyBlue};
    font-weight: bold;
    @media ${queries.medium} {
      margin-bottom: 1.2rem;
      margin-left: 1.5rem !important;
      font-size: 1.5rem;
    }
  `,
  customHeaderContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    @media ${queries.large} {
      margin-top: -0.625rem;
      margin-bottom: 0;
    }
  `,
  moreInfoButton: css`
    background-color: rgba(128, 88, 255, 0.08);
    border-radius: 30px;
    min-width: 2rem !important;
    width: 2rem;
    height: 2rem;
    float: right;
  `,
  divider: css`
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: 95% !important;
    min-width: 95% !important;
  `,
  dropdownMenuText: css`
    font-size: 1rem;
  `,
  switchWithClient: css`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    margin: 0.5rem 2rem;
    white-space: nowrap;
  `,
  dropdownMenu: css`
    width: 14rem;
    background-color: ${theme.colors.white};
    border-radius: 0.2rem;
    box-shadow: 0 0 0.313rem 0.125rem rgb(0 0 0 / 12%),
      0 0.375rem 1rem 0 rgb(0 0 0 / 8%), 0 0.563rem 1.75rem rgb(0 0 0 / 5%);

    button {
      border: none;
      background-color: ${theme.colors.white};
    }
    .ant-switch {
      background-color: ${theme.colors.secondary.lightGray};
      height: 2rem;
      width: 3.5rem;
    }
    .ant-switch-handle::before {
      border-radius: 0.8125rem;
      top: 0.0625rem;
      left: 0.0625rem;
      height: 1.625rem;
      width: 1.625rem;
    }
    .ant-switch-checked {
      background-color: ${theme.colors.primary.blue};
      .ant-switch-handle::before {
        left: -0.5625rem;
      }
    }
    .ant-dropdown-menu-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      :hover {
        background-color: ${theme.colors.white};
      }
    }
  `,
  mobileHeaderContainer: css`
    position: fixed;
    width: 100%;
    display: flex;
    box-shadow: 0 -0.188rem 0.375rem 0.063rem #777;
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.header};
  `,
  mobileTextContainer: css`
    display: flex;
    flex-direction: column;
    padding: 0.625rem;
    width: 100%;
  `,
  saveIconContainer: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0.625rem;
  `,
  savedChanges: css`
    font-size: 0.875rem;
    color: ${theme.colors.background.darkGray};
  `,
  saving: css`
    font-size: 0.875rem;
    color: ${theme.colors.primary.blue};
  `,
  projectID: css`
    font-size: 1rem;
    color: ${theme.colors.secondary.darkBlue};
    font-weight: bold;
    @media ${queries.medium} {
      margin-top: 0.625rem;
      margin-bottom: 0.313rem;
      font-size: 1.5rem;
    }
  `,
  projectClosure: css`
    .ant-picker-disabled {
      border-color: ${theme.colors.secondary.lightGray} !important;
    }
  `,
  helperText: css`
    color: #888491;
  `,
  saveContainer: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.563rem;
    margin-top: 0.625rem;
    cursor: pointer;
    @media ${queries.medium} {
      margin-top: 0.4rem;
      margin-bottom: 1rem;
    }
    @media ${queries.large} {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  `,
  saveAndClose: css`
    font-weight: bold;
    margin-right: 0.313rem;
  `,
  headerButtonsContainer: css`
    display: none;
    z-index: ${theme.zIndex.headerButtons};

    @media ${queries.medium} {
      display: initial;
      position: fixed;
      right: 1.3rem;
      top: 3.5rem;
    }
    @media ${queries.large} {
      right: 3.1rem;
      top: 0.5rem;
    }
  `,
  viewProjectButton: css`
    border: 0.125rem solid ${theme.colors.primary.blue} !important;
    margin-right: 1.875rem;
  `,
  drawerButtonContainer: css`
    height: 50px;
    background-color: ${theme.colors.white};
    display: flex;
    border-top: 0.063rem solid ${theme.colors.background.veryLightGray};
    position: fixed;
    bottom: 0;
    width: 100%;
  `,
  buttonTextContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  `,
  mobileButtonViewProject: css`
    ${sharedButtonStyle}
    color: ${theme.colors.primary.blue};
  `,
  mobileButtonContainerPrimary: css`
    ${sharedButtonStyle}
    background-color: ${theme.colors.primary.blue};
    color: ${theme.colors.white};
  `,
  mobileSectionContainer: css`
    display: flex;
    width: 100%;
    flex-direction: column;
  `,
  briefContainer: css`
    @media ${queries.medium} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  `,
  secondaryHeader: css`
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.55rem;
    color: ${theme.colors.secondary.blueOnBlack};
    font-weight: 600;
  `,
  secondaryHeaderDivider: css`
    margin: 0 0 0.5rem 0;
  `,
  projectDetailsContainer: css`
    display: flex;
    flex-direction: column;
  `,
  secondaryProjectHeader: css`
    margin-bottom: 1.2rem;
    font-size: 1rem;
    line-height: 1.55rem;
    color: ${theme.colors.secondary.blueOnBlack};
    font-weight: 600;
  `,
  secondaryFormSectionHeader: css`
    margin-bottom: 0.2rem;
    font-size: 1rem;
    color: ${theme.colors.secondary.blueOnBlack};
    font-weight: bold;
  `,
  textAndDescription: css`
    display: flex;
    flex-direction: column;
  `,

  sectionDescription: css`
    margin-bottom: 1.2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.secondary.sandstoneGrey};

    @media ${queries.medium} {
      margin-bottom: 1.2rem;
      margin-left: 1.5rem !important;
    }
  `,
  budgetInputSpacer: css`
    width: 120px;
  `,
  budgetSubText: css`
    font-size: 1rem;
    line-height: 24px;
    color: ${theme.colors.secondary.sandstoneGrey};
  `,
  licensingHeader: css`
    margin-left: 1rem !important;
    margin-bottom: 1.5rem !important;
    @media ${queries.medium} {
      margin-left: -1.5rem !important;
    }
  `,
  documentsUploadContainer: css`
    margin: 0;
  `,
  documentsUploadHeader: css`
    @media ${queries.medium} {
      margin-left: -1.5rem !important;
    }
  `,
  documentsFileUploader: css`
    padding: 1rem;
    border: none;
    @media ${queries.medium} {
      &:hover {
        border: 0.0625rem solid #d9d9d9 !important;
      }
      border: 0.0625rem solid #d9d9d9;
      padding-top: 0.625rem;
      .ant-table {
        .ant-table-thead > tr {
          th {
            padding-bottom: 0.5rem;
          }
          th:nth-of-type(1) {
            padding-left: 1.5625rem;
          }
          th:nth-of-type(5) {
            padding-right: 0.9375rem;
          }
        }
        .ant-table-tbody > tr {
          td:nth-of-type(1) {
            padding-left: 1.5625rem;
          }
          td:nth-of-type(5) {
            padding-right: 0.9375rem;
          }
        }
      }
      .ant-table .ant-table-tbody > tr:hover {
        > td {
          background: #f8f6ff;
        }
      }
    }
  `,
  termSummary: css`
    ${termShared}
    height: 50px;
    background-color: unset;
    overflow: hidden;
  `,
  termForm: css`
    ${termShared}
    padding: 1rem 1.5rem;
    height: auto;
  `,
  sendNotificationsParagraph: css`
    margin-bottom: 4rem;
  `,

  tabsHeader: css`
    .ant-tabs-nav-wrap {
      visibility: hidden;
      display: none !important;
    }
  `,

  badgeContainer: css`
    border-radius: 50%;
    width: 25px;
    text-align: center;
    display: inline-block;
    margin-right: 6px;
  `,
  collapseContainer: css`
    box-shadow: none;
    border: none !important;
    border-radius: 0 !important;
    overflow: auto;
    background-color: ${theme.colors.white};
    .ant-collapse-item {
      border-bottom: 0.5px solid ${theme.colors.background.boxShadowGray};
    }
    .ant-collapse-item-active {
      font-weight: bold !important;
      color: ${theme.colors.black} !important;
    }
    .ant-collapse-content {
      border-top: 0 !important;
      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
    .ant-collapse-item:last-child {
      border-radius: 0 !important;
      .ant-collapse-content {
        border-radius: 0 !important;
      }
      .ant-collapse-header {
        border-radius: 0 !important;
      }
    }
    @media ${queries.large} {
      overflow: unset;
    }
  `,
  sizeOfQuarterDiv: css`
    width: 25% !important;
  `,
  sizeOfHalfDiv: css`
    width: 50% !important;
  `,
  sizeOfTwoThirdsDiv: css`
    width: 66% !important;
  `,
  priceInput: css`
    flex: auto;
  `,
  sizeOfFullDiv: css`
    width: 85% !important;
  `,
  basicInputLabel: css`
    font-size: 0.875rem;
    line-height: 1.55rem;
    font-weight: 400;
  `,
  disclaimerLabel: css`
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    align-items: center;
    display: flex;
    svg {
      margin-right: 0.5rem;
    }
  `,
  marginBeforeTitle: css`
    margin-top: 1rem !important;
  `,
  basicInput: css`
    position: relative;
    .floatingLabel {
      margin-bottom: 0;
      label {
        top: 0.5rem;
      }
      label.active-focus {
        top: 0 !important;
      }
    }
    .name-link-row,
    .music-track-details,
    .primary-contact {
      input {
        height: 56px !important;
      }
    }
    .ant-form-item-explain-error {
      font-weight: 400;
      font-size: 16px;
    }
    .ant-row {
      label {
        top: 0.5rem;
      }
      label.active-focus {
        top: 0 !important;
      }
      .ant-select {
        .ant-select-selector {
          .ant-select-selection-item {
            padding-top: 0;
          }
        }
      }
    }
  `,
  editCollapseContainer: css`
    background-color: ${theme.colors.white};
    border: none !important;
    padding-right: 1rem;
  `,

  headerPanelContainer: css`
    border-radius: 0 !important;
    background-color: #fff !important;
    .ant-collapse-item {
      border-bottom: none !important;
    }
    .ant-collapse {
      border-top: 0.5px solid ${theme.colors.background.boxShadowGray};
    }
    .ant-collapse-content {
      border: none !important;
      .ant-collapse-content-box {
        padding: 0 !important;
        background-color: ${theme.colors.white};
      }
    }
    .ant-collapse-header {
      border-radius: 0;
      border: none !important;
      padding: 0 !important;
    }
  `,
  panelContainer: css`
    border-radius: 0 !important;
    background-color: #fff !important;
    .ant-collapse-item {
      border-bottom: none !important;
    }
    .ant-collapse {
      border-top: 0.5px solid ${theme.colors.background.boxShadowGray};
    }
    .ant-collapse-content {
      border: none !important;
      .ant-collapse-content-box {
        padding: 16px !important;
        background-color: ${theme.colors.white};
      }
    }
    .ant-collapse-header {
      border-radius: 0;
      border: none !important;
      padding-right: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      .ant-collapse-arrow {
        margin-right: 1.5rem !important;
      }
    }
  `,
  trackColumnContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  `,
  collapseSummaryContainer: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 0.2rem;
  `,
  collapseButtonRow: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  `,

  switchMargin: css`
    margin-top: 1rem;
  `,

  documentPanelContainer: css`
    border-radius: 0 !important;
    background-color: #fff !important;
    .ant-collapse-item {
      border-bottom: none !important;
    }
    .ant-collapse {
      border-top: 0.5px solid ${theme.colors.background.boxShadowGray};
    }
    .ant-collapse-content {
      border: none !important;
      .ant-collapse-content-box {
        padding: 16px !important;
        background-color: ${theme.colors.white};
      }
    }
    .ant-collapse-header {
      border-radius: 0;
      border: none !important;
      padding-right: 0 !important;
      align-items: center !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  `,
};
