import axios, { AxiosResponse } from "axios";
import { deleteTermUrl } from "../../project-url-helper";
import { getAuthHeader, getAxiosOptions } from "../../headers";
import getJsonContentHeader from "../../headers/get-json-content-header";

async function deleteTerm(
  accessToken: string,
  projectId: string,
  organisationId: string,
  termId: string
): Promise<number> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  const response: AxiosResponse = await axios.delete(
    deleteTermUrl(projectId, organisationId, termId),
    options
  );
  if (response.status !== 204 && response.status !== 404) {
    throw new Error(
      `Error deleting Term. ${response.status}: ${response.statusText}`
    );
  }

  return response.status;
}

export default deleteTerm;
