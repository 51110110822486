import { createContext } from "react";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";

export interface IProjectTalentsSearchContext {
  isLoading: boolean;
  contactsResponse?: IContactsResponse;
  agenciesAndContacts: IAgencyWithContactsResults[];
  handleUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  handlePageChange: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  handleResetNarrowSearch: () => void;
}

export default createContext<IProjectTalentsSearchContext>({
  isLoading: false,
  agenciesAndContacts: [],
  contactsResponse: {
    totalPages: 0,
    totalResults: 0,
    currentPage: 0,
    results: [],
  },
  handleResetNarrowSearch: () => {},
  handleUpdateNarrowSearchText: () => {},
  handlePageChange: () => {},
});
