import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  contentContainer: css`
    padding: 0 1rem 0 1rem;

    label {
      height: 0.938rem;
      color: ${theme.colors.background.darkGray};
      font-size: 0.688rem !important;
      letter-spacing: 0;
      line-height: 0.938rem;
      margin-bottom: 0.3rem;
      margin-top: 0.2rem;
    }

    .ant-select-selection-search {
      margin-top: 0.562rem;
    }

    @media ${queries.large} {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  `,
  header: css`
    color: ${theme.colors.secondary.navyBlue} !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    margin-bottom: 1.5rem !important;
    height: 1.5rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    margin-left: 1rem;
    font-family: ${theme.fonts.sofiaPro};

    :first-of-type {
      margin-top: 1.6rem;

      @media ${queries.large} {
        margin-top: 0;
      }
    }
  `,
  mobileButtonContainer: css`
    height: 3.125rem;
    background-color: ${theme.colors.white};
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 0.06rem solid ${theme.colors.background.veryLightGray};
    justify-content: flex-end;
    flex-flow: row nowrap;
  `,
  tabletDesktopButtonContainer: css`
    height: 3.125rem;
    display: flex;
    margin-left: 12rem;
    margin-right: 1rem;
  `,

  secondaryButton: css`
    flex: 1;
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 0;
    padding: 0;
    background-color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-weight: bold;
    border: 0;
    letter-spacing: 0;
    line-height: 1.25rem;
    color: ${theme.colors.primary.blue};

    :first-of-type {
      border-right: 1px solid ${theme.colors.background.veryLightGray};

      @media ${queries.large} {
        border-right: 0;
        margin-left: 14rem;
      }
    }
  `,
  primaryButton: css`
    flex: 1;
    display: flex;
    font-size: 0.9rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 0 !important;
    padding: 0;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: bold;
    border: 0;
    color: ${theme.colors.white};
    &:hover {
      box-shadow: none;
      background-color: ${theme.colors.primary.blue} !important;
      @media ${queries.large} {
        background-color: ${theme.colors.secondary.navyBlue} !important;
      }
    }
    @media ${queries.large} {
      border-radius: 0.3rem !important;
    }
  `,
};
