import React, { ReactElement, ReactNode } from "react";
import { Popover } from "antd";
import styles from "./styles";

interface IProps {
  title: ReactNode;
  content: string;
}

const PopOverText = ({ title, content }: IProps): ReactElement => {
  return (
    <Popover placement="topLeft" title={title} trigger="hover">
      <div css={styles.tableDataText} data-testid="project-name">
        {content}
      </div>
    </Popover>
  );
};

export default PopOverText;
