import { AxiosError } from "axios";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import useAuth from "src/auth/use-auth";
import { ErrorToast } from "src/components/toast-notification";
import useContact from "src/providers/contact/hooks";
import NotFound from "src/pages/not-found";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import { useTranslation } from "react-i18next";
import useConfirm from "src/providers/confirm-context-provider/hooks";
import * as H from "history";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import contactValidation from "src/utils/contactValidation";
import areChangesMadeInContact from "src/utils/contact-changes-check";
import updateContact from "src/api/talent-hub/update-contact";
import Contact from "..";
import {
  IAAndRData,
  IContactCreateRequest,
  INotesAndDocumentsData,
  IPersonalDetailsData,
  IPublishingInformationData,
  ITab,
  TabsTitles,
} from "../interfaces";
import PersonalDetails from "../components/personal-details";
import PublishingInformation from "../components/publishing-information";
import AAndR from "../components/a-and-r";
import NotesAndDocuments from "../components/notes-and-documents";
import ContactSuccessfullyCreatedOrUpdated from "../components/contact-created-updated";
import { filterEmptyNotes, filterShowReelUrls } from "../utils";

const ContactContainer = (): ReactElement => {
  const { isConfirmed } = useConfirm();
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const { fetchWrapper, isSessionValid, organisationId } = useAuth();
  const [activeTab, setActiveTab] = useState<ITab>();
  const history = useHistory();
  const { contact, isLoading, contactFound } = useContact();
  const { talentHubTypes } = useGlobalStates();
  const [isWaitingForElasticSearch, setIsWaitingForElasticSearch] = useState(
    false
  );
  const [personalDetailsData, setPersonalDetailsData] = useState({
    name: contact.name,
    pronoun: contact.pronoun,
    agencyWritingPartnership: contact.agencyWritingPartnership,
    email: contact.email,
    phone: contact.phone,
    baseLocation: contact.baseLocation,
    socialMediaWebsite: contact.socialMediaWebsite,
    showreelUrls: contact.showreelUrls,
    showReel: contact.showReel,
    mainLanguage: contact.mainLanguage,
    otherLanguages: contact.otherLanguages,
    billingName: contact.billingName,
    billingAddress: contact.billingAddress,
    physicalConditionsIllness: contact.physicalConditionsIllness,
    neurodiverse: contact.neurodiverse,
    neurodiverseNote: contact.neurodiverseNote,
    racialCharacteristics: contact.racialCharacteristics,
    genderIdentity: contact.genderIdentity,
    sexualOrientation: contact.sexualOrientation,
    ipi: contact.ipi,
  });
  const [publishingInformationData, setPublishingInformationData] = useState({
    signedCMA: contact.signedCMA,
    signedNDA: contact.signedNDA,
    minimumDemoFee: contact.minimumDemoFee,
    performingRightsOrganisationNumber:
      contact.performingRightsOrganisationNumber,
    connectedToOrgId: contact.connectedToOrgId,
    serviceProvider: contact.serviceProvider,
  });

  const [aAndRData, setAAndRData] = useState({
    collaborationStatus: contact.collaborationStatus,
    skillSet: contact.skillSet,
    principalInstruments: contact.principalInstruments,
    additionalInstruments: contact.additionalInstruments,
    keyGenres: contact.keyGenres,
    additionalGenres: contact.additionalGenres,
    vocalist: contact.vocalist,
    minimumDemoTurnaroundHours: contact.minimumDemoTurnaroundHours,
    previousWork: contact.previousWork,
    interestedProjects: contact.interestedProjects,
  });
  const [notesAndDocumentsData, setNotesAndDocumentsData] = useState({
    talentNotes: contact.talentNotes,
    internalNotes: contact.internalNotes,
    awardNotes: contact.awardNotes,
    documents: contact.documents,
  });
  const [saveRouteChange, setSaveRouteChange] = useState(false);
  const unmountedChangeRoute = useRef(false);
  const [arePersonalDetailsValid, setPersonalDetailsValid] = useState(true);
  const [
    isPublishingInformationValid,
    setPublishingInformationValid,
  ] = useState(true);
  const [isAAndRValid, setAAndRValid] = useState(true);
  const [
    validatePersonalDetailsTabFields,
    setValidatePersonalDetailsTabFields,
  ] = useState(0);
  const [
    validatePublishingInformationTabFields,
    setValidatePublishingInformationTabFields,
  ] = useState(0);
  const [validateAAndRTabFields, setValidateAAndRTabFields] = useState(0);

  useEffect(
    () =>
      setPersonalDetailsData({
        name: contact.name,
        pronoun: contact.pronoun,
        agencyWritingPartnership: contact.agencyWritingPartnership,
        email: contact.email,
        phone: contact.phone,
        baseLocation: contact.baseLocation,
        socialMediaWebsite: contact.socialMediaWebsite,
        showreelUrls: contact.showreelUrls,
        showReel: contact.showReel,
        mainLanguage: contact.mainLanguage,
        otherLanguages: contact.otherLanguages,
        billingName: contact.billingName,
        billingAddress: contact.billingAddress,
        physicalConditionsIllness: contact.physicalConditionsIllness,
        neurodiverse: contact.neurodiverse,
        neurodiverseNote: contact.neurodiverseNote,
        racialCharacteristics: contact.racialCharacteristics,
        genderIdentity: contact.genderIdentity,
        sexualOrientation: contact.sexualOrientation,
        ipi: contact.ipi,
      }),
    [
      contact.baseLocation,
      contact.billingAddress,
      contact.billingName,
      contact.email,
      contact.genderIdentity,
      contact.pronoun,
      contact.name,
      contact.mainLanguage,
      contact.neurodiverse,
      contact.otherLanguages,
      contact.phone,
      contact.physicalConditionsIllness,
      contact.racialCharacteristics,
      contact.sexualOrientation,
      contact.socialMediaWebsite,
      contact.agencyWritingPartnership,
      contact.neurodiverseNote,
      contact.showreelUrls,
      contact.showReel,
      contact.ipi,
    ]
  );

  useEffect(
    () =>
      setPublishingInformationData({
        signedCMA: contact.signedCMA,
        signedNDA: contact.signedNDA,
        minimumDemoFee: contact.minimumDemoFee,
        performingRightsOrganisationNumber:
          contact.performingRightsOrganisationNumber,
        connectedToOrgId: contact.connectedToOrgId,
        serviceProvider: contact.serviceProvider,
      }),
    [
      contact.signedCMA,
      contact.minimumDemoFee,
      contact.signedNDA,
      contact.connectedToOrgId,
      contact.performingRightsOrganisationNumber,
      contact.serviceProvider,
    ]
  );

  useEffect(
    () =>
      setAAndRData({
        collaborationStatus: contact.collaborationStatus,
        skillSet: contact.skillSet,
        principalInstruments: contact.principalInstruments,
        additionalInstruments: contact.additionalInstruments,
        keyGenres: contact.keyGenres,
        additionalGenres: contact.additionalGenres,
        vocalist: contact.vocalist,
        minimumDemoTurnaroundHours: contact.minimumDemoTurnaroundHours,
        previousWork: contact.previousWork,
        interestedProjects: contact.interestedProjects,
      }),
    [
      contact.additionalGenres,
      contact.additionalInstruments,
      contact.interestedProjects,
      contact.keyGenres,
      contact.minimumDemoTurnaroundHours,
      contact.previousWork,
      contact.principalInstruments,
      contact.collaborationStatus,
      contact.skillSet,
      contact.vocalist,
    ]
  );

  useEffect(
    () =>
      setNotesAndDocumentsData({
        talentNotes: contact.talentNotes,
        internalNotes: contact.internalNotes,
        awardNotes: contact.awardNotes,
        documents: contact.documents,
      }),
    [
      contact.talentNotes,
      contact.internalNotes,
      contact.documents,
      contact.awardNotes,
    ]
  );

  const handleUpdatePersonalDetailsData = useCallback(
    (changes: IPersonalDetailsData) => {
      setPersonalDetailsData({ ...personalDetailsData, ...changes });
    },
    [personalDetailsData]
  );

  const handleUpdatePublishingInformationData = useCallback(
    (changes: IPublishingInformationData) => {
      setPublishingInformationData({
        ...publishingInformationData,
        ...changes,
      });
    },
    [publishingInformationData]
  );

  const handleUpdateAAndRData = useCallback(
    (changes: IAAndRData) => {
      setAAndRData({ ...aAndRData, ...changes });
    },
    [aAndRData]
  );

  const handleUpdateNotesAndDocumentsData = useCallback(
    (changes: INotesAndDocumentsData) => {
      setNotesAndDocumentsData({ ...notesAndDocumentsData, ...changes });
    },
    [notesAndDocumentsData]
  );

  const getContactDataRequest = useCallback(() => {
    let vocalist = null;
    if (aAndRData.vocalist === "Yes") {
      vocalist = true;
    } else if (aAndRData.vocalist === "No") {
      vocalist = false;
    }
    const showreelUrls = filterShowReelUrls(personalDetailsData.showreelUrls);
    const talentNotes = filterEmptyNotes(notesAndDocumentsData.talentNotes);
    const internalNotes = filterEmptyNotes(notesAndDocumentsData.internalNotes);
    const awardNotes = filterEmptyNotes(notesAndDocumentsData.awardNotes);

    return {
      ...personalDetailsData,
      ...publishingInformationData,
      ...aAndRData,
      vocalist,
      ...notesAndDocumentsData,
      organisationId,
      showreelUrls,
      talentNotes,
      awardNotes,
      internalNotes,
    };
  }, [
    aAndRData,
    notesAndDocumentsData,
    organisationId,
    personalDetailsData,
    publishingInformationData,
  ]);

  useEffect(() => {
    const validatePersonalDetailsTab = async () => {
      let isDataValid = true;
      try {
        await contactValidation.personalDetailsSchema.validate(
          personalDetailsData
        );
      } catch (error) {
        isDataValid = false;
      }
      setPersonalDetailsValid(isDataValid);
    };
    void validatePersonalDetailsTab();
  }, [personalDetailsData]);

  useEffect(() => {
    const validatePublishingInformationTab = async () => {
      let isDataValid = true;
      try {
        await contactValidation.publishingInformationSchema.validate(
          publishingInformationData
        );
      } catch (error) {
        isDataValid = false;
      }
      setPublishingInformationValid(isDataValid);
    };
    void validatePublishingInformationTab();
  }, [publishingInformationData]);

  useEffect(() => {
    const validateAAndRTab = async () => {
      let isDataValid = true;
      try {
        await contactValidation.aAndRSchema.validate(aAndRData);
      } catch (error) {
        isDataValid = false;
      }
      setAAndRValid(isDataValid);
    };
    void validateAAndRTab();
  }, [aAndRData]);

  const handleTabChange = useCallback((tab: ITab) => {
    setActiveTab(tab);
  }, []);

  const areChangesMade = useCallback(() => {
    return areChangesMadeInContact(
      contact,
      personalDetailsData,
      publishingInformationData,
      aAndRData,
      notesAndDocumentsData
    );
  }, [
    aAndRData,
    contact,
    notesAndDocumentsData,
    personalDetailsData,
    publishingInformationData,
  ]);

  useEffect(() => {
    const blocker = (location: H.Location, action: H.Action): boolean => {
      if (saveRouteChange || unmountedChangeRoute.current) {
        return true;
      }
      if (areChangesMade()) {
        void isConfirmed(
          "Your changes will be lost if you don't save them. Do you want to continue?"
        ).then((confirmed) => {
          if (confirmed) {
            unmountedChangeRoute.current = true;
            switch (action) {
              case H.Action.Push:
                history.push(location.pathname);
                break;
              case H.Action.Pop:
              case H.Action.Replace:
              default:
                history.replace(location.pathname);
                break;
            }
          }
          if (!confirmed && action === H.Action.Pop) {
            unmountedChangeRoute.current = true;
            const newLocationPathname = `/talent/${contact.id}`;
            const currentLocationPathname = window.location.pathname;
            const historyAction =
              currentLocationPathname !== newLocationPathname
                ? "push"
                : "replace";
            history[historyAction](newLocationPathname);
          }
          return true;
        });
        return false;
      }
      return true;
    };
    const unblock = history.block((...args) => {
      const [location, action] = args as unknown[];
      return blocker(location as H.Location, action as H.Action);
    });
    return () => {
      unblock();
      unmountedChangeRoute.current = false;
    };
  }, [areChangesMade, history, isConfirmed, contact.id, saveRouteChange]);

  const isContactRequestValid = useCallback(
    async (request: ReturnType<typeof getContactDataRequest>) => {
      let isRequestDataValid = false;
      try {
        await contactValidation.contactSchema.validate(request);
        isRequestDataValid = true;
      } catch (error) {
        ErrorToast(
          "contact-validation-error",
          t("ContactPage##Errors##ValidationError##message")
        );
      }

      return isRequestDataValid;
    },
    [t]
  );

  const updateContactData = useCallback(
    async (
      request: ReturnType<typeof getContactDataRequest>,
      finishAfterSave: () => void
    ) => {
      setIsSaving(true);

      try {
        await fetchWrapper(
          updateContact,
          contact.id,
          request as IContactCreateRequest,
          organisationId
        );
        setSaveRouteChange(true);
        finishAfterSave();
      } catch (reason) {
        const error = reason as AxiosError;
        if (error.response?.status === 409) {
          log(
            DataDogLogTypes.ERROR,
            "Error saving contact: Contact with given email already exists",
            error
          );
          ErrorToast(
            "contact-save-error",
            t("ContactPage##Errors##SaveError##messageContactWithSameEmail")
          );
        } else {
          log(DataDogLogTypes.ERROR, "Error saving contact", error);
          ErrorToast(
            "contact-save-error",
            t("ContactPage##Errors##SaveError##message")
          );
        }
      } finally {
        setIsSaving(false);
      }
    },
    [contact.id, fetchWrapper, organisationId, t]
  );

  const handleUpdateContact = useCallback(
    async (finishAfterSave: () => void) => {
      const isSession = await isSessionValid();
      if (!isSession) {
        log(DataDogLogTypes.ERROR, "Error updating contact", "error");
        ErrorToast(
          "contact-update-error",
          t("ContactPage##Errors##SaveError##message")
        );
        return;
      }
      const request = getContactDataRequest();
      const isRequestDataValid = await isContactRequestValid(request);
      if (!isRequestDataValid) {
        return;
      }
      await updateContactData(request, finishAfterSave);
    },
    [
      getContactDataRequest,
      isContactRequestValid,
      isSessionValid,
      updateContactData,
      t,
    ]
  );

  const handleSetWaitingForElasticSearch = useCallback(() => {
    setIsWaitingForElasticSearch(!isWaitingForElasticSearch);
  }, [isWaitingForElasticSearch]);

  const handleValidateTabFields = useCallback(
    (tabTitle: string) => {
      switch (tabTitle) {
        case TabsTitles.PersonalDetails:
          setValidatePersonalDetailsTabFields(
            validatePersonalDetailsTabFields + 1
          );
          break;
        case TabsTitles.PublishingInformation:
          setValidatePublishingInformationTabFields(
            validatePublishingInformationTabFields + 1
          );
          break;
        case TabsTitles.AAndR:
          setValidateAAndRTabFields(validateAAndRTabFields + 1);
          break;
        case TabsTitles.NotesAndDocuments:
        default:
          break;
      }
    },
    [
      validateAAndRTabFields,
      validatePersonalDetailsTabFields,
      validatePublishingInformationTabFields,
    ]
  );

  if (!contactFound) {
    return (
      <NotFound
        heading="ContactPage##contactNotFound##heading"
        description="ContactPage##contactNotFound##description"
        buttonText="ContactPage##contactNotFound##buttonText"
        buttonPath="/talents"
      />
    );
  }

  return (
    <Contact
      isSaving={isSaving}
      isLoading={isLoading || isWaitingForElasticSearch}
      activeTab={activeTab}
      setActiveTab={handleTabChange}
      onSaveContact={handleUpdateContact}
      validation={{
        personalDetails: arePersonalDetailsValid,
        publishingInformation: isPublishingInformationValid,
        aAndR: isAAndRValid,
        notesAndDocuments: true,
      }}
      disabledTabsForClick={false}
      validateTabFields={handleValidateTabFields}
      isUpdateContactButtonVisible={organisationId === contact.createdByOrgId}
    >
      {{
        personalDetails: (
          <PersonalDetails
            isEditMode
            personalDetailsData={personalDetailsData}
            onPersonalDetailsDataChange={handleUpdatePersonalDetailsData}
            talentHubTypes={talentHubTypes}
            validateTabFields={validatePersonalDetailsTabFields}
          />
        ),
        publishingInformation: (
          <PublishingInformation
            publishingInformationData={publishingInformationData}
            onPublishingInformationDataChange={
              handleUpdatePublishingInformationData
            }
            validateTabFields={validatePublishingInformationTabFields}
          />
        ),
        aAndR: (
          <AAndR
            aAndRData={aAndRData}
            onAAndRDataChange={handleUpdateAAndRData}
            talentHubTypes={talentHubTypes}
            validateTabFields={validateAAndRTabFields}
          />
        ),
        notesAndDocuments: (
          <NotesAndDocuments
            notesAndDocumentsData={notesAndDocumentsData}
            onNotesAndDocumentsDataChange={handleUpdateNotesAndDocumentsData}
            isEditMode
          />
        ),
        contactCreatedOrUpdated: (
          <ContactSuccessfullyCreatedOrUpdated
            updated
            contactId={contact.id}
            onSetWaitingForElasticSearch={handleSetWaitingForElasticSearch}
            onContactUpdated={() => setActiveTab(undefined)}
          />
        ),
      }}
    </Contact>
  );
};

export default ContactContainer;
