import { UploadFile } from "antd/lib/upload/interface";
import copy from "copy-to-clipboard";
import { TFunction } from "i18next";
import { debounce } from "lodash";
import React from "react";
import { attachmentDownloadUrl } from "src/api/project-url-helper";
import useAuth from "src/auth/use-auth";
import CopyLinkButton from "src/components/control-buttons/copy-link-button";
import DeleteButton from "src/components/control-buttons/delete-button";
import DownloadButton from "src/components/control-buttons/download-button";
import {
  handleFileDownload,
  handleDeleteClick,
} from "src/components/file-upload/helpers";
import { IDeleteSelectedFile } from "src/components/file-upload/interfaces";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import { IFinalTrackFilesDataSource } from "./interfaces";
import styles from "./styles";

export const finalTrackAttachmentsTableColumns = (): Array<IColumnsData> => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "key",
      width: "85%",
    },
    {
      title: <div />,
      dataIndex: "copyLink",
      key: "key",
      width: "5%",
    },
    {
      title: <div />,
      dataIndex: "download",
      key: "key",
      width: "5%",
    },
    {
      title: <div />,
      dataIndex: "delete",
      key: "key",
      width: "5%",
    },
  ];
};

interface IProps {
  filesList: UploadFile[];
  setFileList: (file: UploadFile[]) => void;
  projectId: string;
  t: TFunction;
  setShowModal: (show: boolean) => void;
  setSelectedFileToDelete: (file: IDeleteSelectedFile | undefined) => void;
}

export const FinalTrackAttachmentsTableData = ({
  filesList,
  projectId,
  t,
  setShowModal,
  setSelectedFileToDelete,
}: IProps): Array<IFinalTrackFilesDataSource> => {
  const { fetchWrapper, organisationId } = useAuth();
  const downloadFile = debounce(
    async (file: UploadFile, isMenuItemDownload = true): Promise<void> => {
      if (isMenuItemDownload) {
        SuccessToast(t("File downloading to your file storage"));
      }
      const downloadFileRequest = await fetchWrapper(
        handleFileDownload,
        file,
        projectId,
        organisationId,
        isMenuItemDownload
      );

      if (!downloadFileRequest) {
        ErrorToast(
          `FileUploadErrorToast${file.uid}`,
          t("There was a problem downloading the file")
        );
      }
    },
    500
  );
  const copyTrackLink = (attachmentId: string) => {
    const copySuccess = copy(attachmentDownloadUrl(projectId, attachmentId));
    if (copySuccess) {
      SuccessToast("Track link copied");
    }
  };

  return filesList.map(
    (file: UploadFile, index: number): IFinalTrackFilesDataSource => {
      return {
        key: `uploadTableRow${file.uid}`,
        name: file.name,
        copyLink: (
          <CopyLinkButton
            showTooltip
            css={styles.downloadButton}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();

              copyTrackLink(file.uid);
            }}
          />
        ),
        download: (
          <DownloadButton
            showTooltip
            css={styles.downloadButton}
            onClick={async (
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              e.stopPropagation();

              await downloadFile(file);
            }}
          />
        ),
        delete: (
          <DeleteButton
            css={styles.deleteButton}
            showTooltip
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();

              setSelectedFileToDelete({ index, file });

              handleDeleteClick(setShowModal);
            }}
          />
        ),
        handleRowDownloadClick: () => downloadFile(file, false),
      };
    }
  );
};
