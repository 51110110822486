import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";
import { StylesConfig } from "react-select";

const sharedDropdownStyle = `
border-radius: 0.2rem;
box-shadow: 0 0 0.313rem 0.125rem rgb(0 0 0 / 12%),
  0 0.375rem 1rem 0 rgb(0 0 0 / 8%), 0 0.563rem 1.75rem rgb(0 0 0 / 5%);

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  padding: 0;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin-bottom: 0;
  padding-right: 1rem;
  padding-left: 0.5rem;
  font-family: ${theme.fonts.sofiaPro};
  background-color: ${theme.colors.background.gray};

  li {
    padding: 0.2rem;
    cursor: pointer;

    :hover {
      font-weight: bold;
    }
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: ${theme.colors.background.gray};
}
`;

type MyOptionType = {
  label: string;
  value: string;
};

export const customStyles: StylesConfig<MyOptionType> = {
  menu: (provided) => ({
    ...provided,
    minHeight: 56,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 56,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
};

export default {
  libraryProjectsTable: css`
    .ant-table {
      table {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    tr:hover > td {
      cursor: default;
      background-color: ${theme.colors.secondary.fadeToGrey} !important;
    }
  `,
  customHeaderContentContainer: css`
    display: none;

    @media ${queries.medium} {
      display: initial;
      position: absolute;
      right: 1.3rem;
      top: 4.5rem;
    }

    @media ${queries.large} {
      right: 3.1rem;
      top: 3rem;
    }
  `,
  heading: css`
    display: none;

    @media ${queries.medium} {
      font-family: ${theme.fonts.sofiaPro};
      display: initial;
      margin-top: auto;
      padding-bottom: 0.3125rem;
      font-size: 2rem !important;
      font-size: 2.25rem !important;
      margin-bottom: 0.9375rem;
    }

    @media ${queries.large} {
      margin-bottom: 0 !important;
      font-size: 2.75rem !important;
    }
  `,
  mobileHeading: css`
    font-family: ${theme.fonts.sofiaPro};
    margin-top: auto;
    padding-bottom: 0.3125rem;
    padding-top: 2rem;
    font-size: 2rem !important;

    @media ${queries.medium} {
      display: none;
    }
  `,
  textContainer: css`
    display: block;
    padding-top: 0.625rem;
    padding-bottom: 1.25rem;
    text-align: center;

    @media ${queries.medium} {
      text-align: left;
    }
  `,
  searchTextContainer: css`
    display: block;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: ${theme.colors.secondary.sandstoneGrey};
  `,
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3.125rem;
  `,
  contentContainer: css`
    width: 300px;

    @media ${queries.medium} {
      width: 410px;
    }
  `,
  noProjectSearchContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3.125rem;
    height: 60vh;
  `,
  noProjectSearchContentContainer: css`
    width: 516px;
    height: 128px;
    background: ${theme.colors.secondary.smokeyWhite};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  textHeader: css`
    display: block;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.secondary.blueOnBlack};
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    padding-bottom: 16px;
  `,
  libraryTrackTableContainer: css`
    @media ${queries.medium} {
      padding-top: 1.5rem;
    }
    .ant-pagination {
      margin-bottom: 1.375rem;
      .ant-pagination-item-link {
        border: none;
      }
      .ant-pagination-item-active {
        background-color: ${theme.colors.secondary.navyBlue};
        a {
          color: ${theme.colors.white};
          padding-top: 0.0625rem;
        }
      }
      .ant-pagination-item {
        border: none;
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        color: ${theme.colors.secondary.purple};
      }
      .ant-pagination-disabled {
        color: ${theme.colors.background.lightGray};
      }
    }
    .ant-table-container .ant-table-content table {
      position: relative;
      thead,
      tbody {
        tr {
          th {
            background-color: ${theme.colors.background.white} !important;
          }
          th,
          td {
            padding-left: 16px !important;
            padding-right: 16px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            div {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &:first-child {
              border-right: 3px solid ${theme.colors.background.lightGray} !important;
            }
            &:last-child {
              border-left: 3px solid ${theme.colors.background.lightGray} !important;
            }
          }
          td:last-child {
            overflow: visible;
          }
        }
      }
    }
    .ant-dropdown {
      right: 68px !important;
      width: 190px !important;
      .ant-dropdown-menu,
      .ant-dropdown-menu-item {
        width: 190px !important;
        background-color: ${theme.colors.background.white};
      }
    }
  `,
  flexContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${queries.medium} {
      width: 410px;
      flex-direction: initial;
    }
  `,
  image: css`
    height: 180px;
    width: 180px;

    @media ${queries.medium} {
      width: 230px;
      height: 230px;
    }
  `,
  button: css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;

    :focus {
      border-radius: 0;
    }

    @media ${queries.medium} {
      position: initial;
      border-radius: 0.3125rem;

      :focus {
        border-radius: 0.3125rem;
      }
    }
  `,
  moreInfoButton: css`
    pointer-events: visible;

    :hover {
      background-color: rgba(128, 88, 255, 0.15);
      border-radius: 1.5625rem;
      box-sizing: border-box;
    }
  `,
  dropdownMenuBrief: css`
    ${sharedDropdownStyle}
    min-width: 140px;
  `,
  dropdownMenu: css`
    ${sharedDropdownStyle}
    max-width: 125px;
    border-radius: 0;
    box-shadow: none;
    button {
      border: none;
      background: none;
      font-size: 0.875rem;
      cursor: pointer;
      font-weight: 600;
      font-family: ${theme.fonts.sofiaPro};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      :hover {
        background-color: ${theme.colors.secondary.bigSkyBlue};
      }
    }
  `,
  actionButton: css`
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0.375rem 1.125rem;
    text-decoration: none;
    background-color: ${theme.colors.background.gray};
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 12px;

    :hover {
      font-weight: bold;
    }
  `,
  mr5: css`
    margin-right: 5px;
  `,
  moreButton: css`
    height: 48px;
    display: flex;
    margin-right: 0;
    &:hover {
      background-color: transparent;
    }
    &:focus-visible {
      outline: none;
    }
  `,
  iconButton: css`
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  `,
  stickyFooterButton: css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;
    @media ${queries.medium} {
      display: none;
    }
  `,
  paginationCount: css`
    font-size: 0.875rem;
    text-align: center;
    margin-bottom: 3.5rem;
  `,
  sortProjectsDrawer: css`
    ul {
      list-style-type: none;
      margin: 1rem;
      padding: 0;
      li {
        background-color: ${theme.colors.background.gray};
        padding: 0.6875rem 1.063rem 0.6875rem 1.3438rem;
        margin-top: 0.25rem;
        button {
          background: transparent;
          border: none;
          padding: 0;
          color: black;
          width: 100%;
          text-align: left;
          cursor: pointer;
        }
      }
    }
  `,
  checkIcon: css`
    float: right;
    padding-top: 0.3125rem;
    color: ${theme.colors.secondary.purple};
  `,
  projectInfoDrawer: css`
    padding-top: 0;
    > div {
      min-height: 2.8125rem;
      .label {
        display: inline-block;
      }
    }
    .secondary {
      font-size: 0.875rem;
      color: ${theme.colors.background.darkGray};
      display: block;
    }
  `,
  inputContainer: css`
    margin-bottom: 16px;
    margin-right: 32px;
  `,
  inputAndPresetFiltersContainer: css`
    display: flex;
    margin-bottom: 48px;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  presetCheckboxFiltersContainer: css`
    margin-left: 0 !important;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  `,
  verticalFiltersDivider: css`
    display: flex;
    align-self: center;
    margin: 0 16px 16px 16px;
  `,
  selectContainer: css`
    min-width: 184px;
    height: 56px;
    background: ${theme.colors.background.white};
    border: 1px solid #c4c4c4;
    border-radius: 4px;
  `,
  input: css`
    max-width: 680px;
    background: ${theme.colors.background.white};
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    .ant-input-group-addon:last-child {
      border: unset !important;
      background-color: transparent;
      background-repeat: no-repeat;
      cursor: pointer;
      overflow: hidden;
      outline: none;
    }

    .ant-input {
      border: unset !important;
    }

    .ant-input::placeholder {
      color: ${theme.colors.secondary.sandstoneGrey};
      opacity: 1; /* Firefox */
    }
  `,
  inputClear: css`
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  `,
  tableDataText: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  tableDataTextMaxWidth: css`
    max-width: 250px;
  `,
  typeContainer: css`
    display: flex;
    flex-direction: column;
  `,
  tableDataSubText: css`
    font-size: 0.875rem;
    color: ${theme.colors.background.darkGray};
    margin-top: -0.3125rem;
  `,
  titleContainer: css`
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-weight: bold;
    margin-right: 1rem;
  `,
  viewColumnsContainer: css`
    display: flex;
    cursor: pointer;
  `,
  sortContainer: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};
    position: relative;
    left: 1rem;
  `,
  sortSpan: css`
    left: 0.4rem;
    position: relative;
    font-size: 1rem;
    font-weight: bold;
  `,
  sortButton: css`
    padding: 0;
    min-width: auto;
    height: auto;
    margin-left: 0.3125rem;
  `,
  row: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    div:last-of-type {
      margin-left: auto;
    }
  `,
  controls: css`
    height: 15px;
    float: right;
  `,
  clearText: css`
    display: inline-block;
    color: ${theme.colors.primary.blue};
    margin-right: 16px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    height: 40px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  `,
  filterBadgeContainer: css`
    margin-top: -2.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
  `,
  badgeContainer: css`
    height: 22px;
    font-size: 0.8rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: #1f48e71a;
    border-radius: 0.2rem;
    color: ${theme.colors.black};
    margin: 0.3rem;

    button {
      background-color: transparent;
      border: none;
      margin-right: -0.5rem;
    }

    svg {
      cursor: pointer;
      margin-left: 0.3rem;
    }
  `,
  actionsContainer: css`
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  `,
  exportButton: css`
    height: 56px !important;
  `,
};
