import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Switch, Typography } from "antd";
import { getIsMobile } from "@songtradr/spa-common/lib/utils";
import DeleteButton from "src/components/control-buttons/delete-button";
import {
  IContactReferences,
  IFeeSplitDetailState,
} from "src/pages/projects/project/interfaces";
import { PlusOutlined } from "@ant-design/icons";
import ControlButton from "src/components/control-buttons/base-button";
import CurrencyAndPrice from "src/components/currency-and-price";
import { Currencies } from "src/constants";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import { formatCurrency } from "src/utils/currency";
import { FormInstance } from "antd/lib/form";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import { getCalculatedSplitFee } from "src/pages/projects/project/utils";
import getValuesFromTalent from "src/utils/getValuesFromTalent";
import styles from "../fee-split/styles";
import { IFeeSplitConfig } from "../final-track-ownership/interfaces";
import mainStyles from "../../../../styles";
import TalentHubInput from "../../../talent-hub-input";
import { ColumnIdentifier, IUpdateRowDataType } from "../fee-split/fee-split";

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  totalFee: string;
  feeSplitDetail: IFeeSplitDetailState;
  index: number;
  config: IFeeSplitConfig;
  propertyName: string;
  validateRequiredFields: boolean;
  talentsResponse?: IContactsResponse;
  isLastItem: boolean;
  areTalentsLoading: boolean;
  isWritersShareSection: boolean;
  agenciesAndContacts: IAgencyWithContactsResults[];
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onDeleteRow: (index: number) => void;
  onUpdateRow: (index: number, feeSplitDetail: IFeeSplitDetailState) => void;
  onAddNewRowItem: () => void;
}

const FeeSplitRowWithTalent = ({
  totalFee,
  feeSplitDetail,
  index,
  config,
  propertyName,
  form,
  validateRequiredFields,
  isLastItem,
  talentsResponse,
  areTalentsLoading,
  isWritersShareSection,
  agenciesAndContacts,
  onResetNarrowSearch,
  onUpdateNarrowSearchText,
  onDeleteRow,
  onScrollTalents,
  onUpdateRow,
  onAddNewRowItem,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const [name, setName] = useState<string | undefined>(
    feeSplitDetail?.contactReferences?.[0]?.name
  );
  const [email, setEmail] = useState<string | undefined>(
    feeSplitDetail?.contactReferences?.[0]?.email
  );
  const [split, setSplit] = useState<string>(feeSplitDetail.split ?? "");
  const [ipi, setIpi] = useState<string>(
    feeSplitDetail.contactReferences?.[0]?.ipi ?? ""
  );
  const talentHubRef = useRef<HTMLDivElement>(null);
  const taxWithholdingFee = useMemo(() => feeSplitDetail?.taxWithholdingFee, [
    feeSplitDetail?.taxWithholdingFee,
  ]);
  const bankFee = useMemo(() => feeSplitDetail?.bankFee, [
    feeSplitDetail?.bankFee,
  ]);

  const taxWithholdingFeePriceName = useMemo(
    () => `${propertyName}${index}TaxWithholdingFeePrice`,
    [index, propertyName]
  );
  const bankFeePriceName = useMemo(
    () => `${propertyName}${index}BankFeePrice`,
    [index, propertyName]
  );
  const getSelectedTalents = useCallback(() => {
    if (
      feeSplitDetail?.contactReferences?.[0]?.name &&
      feeSplitDetail?.contactReferences?.[0]?.email
    ) {
      return getValuesFromTalent(feeSplitDetail?.contactReferences?.[0]);
    }
    return undefined;
  }, [feeSplitDetail.contactReferences]);

  const [selectedTalents, setSelectedTalents] = useState<
    IContactReferences | undefined
  >(getSelectedTalents());

  const handleTaxWithholdingFeeChange = useCallback(
    (_: string, updatedFee: IMonetaryValue) => {
      const updatedRowData: IUpdateRowDataType = {
        contactReferences: [
          getValuesFromTalent(feeSplitDetail?.contactReferences?.[0]),
        ],
        split: feeSplitDetail.split,
        isNew: feeSplitDetail.isNew,
        key: feeSplitDetail.key,
        taxWithholdingFee: updatedFee,
        bankFee: feeSplitDetail.bankFee,
        paythrough: feeSplitDetail.paythrough,
      };
      onUpdateRow(index, updatedRowData);
    },
    [
      feeSplitDetail.bankFee,
      feeSplitDetail.contactReferences,
      feeSplitDetail.isNew,
      feeSplitDetail.key,
      feeSplitDetail.paythrough,
      feeSplitDetail.split,
      index,
      onUpdateRow,
    ]
  );

  const handleBankFeeChange = useCallback(
    (_: string, updatedFee: IMonetaryValue) => {
      const updatedRowData: IUpdateRowDataType = {
        contactReferences: [
          getValuesFromTalent(feeSplitDetail?.contactReferences?.[0]),
        ],
        split: feeSplitDetail.split,
        isNew: feeSplitDetail.isNew,
        key: feeSplitDetail.key,
        taxWithholdingFee: feeSplitDetail.taxWithholdingFee,
        bankFee: updatedFee,
        paythrough: feeSplitDetail.paythrough,
      };
      onUpdateRow(index, updatedRowData);
    },
    [
      feeSplitDetail.contactReferences,
      feeSplitDetail.split,
      feeSplitDetail.isNew,
      feeSplitDetail.key,
      feeSplitDetail.taxWithholdingFee,
      feeSplitDetail.paythrough,
      onUpdateRow,
      index,
    ]
  );

  const [fee, setFee] = useState<string>(
    getCalculatedSplitFee(totalFee, feeSplitDetail.split ?? "")
  );
  const [paythroughToogle, setPaythroughToggle] = useState<boolean>(
    feeSplitDetail.paythrough ?? false
  );

  useEffect(() => {
    setFee(getCalculatedSplitFee(totalFee, feeSplitDetail.split ?? ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFee]);

  const handleColumnUpdate = useCallback(
    (
      event: React.FocusEvent<HTMLInputElement>,
      setState: React.Dispatch<React.SetStateAction<string>>,
      columnName: ColumnIdentifier
    ) => {
      const { value } = event.target;
      let state;

      switch (columnName) {
        case ColumnIdentifier.email:
          state = email;
          break;
        case ColumnIdentifier.split:
          state = split;
          break;
        default:
          state = name;
      }
      const isNameColumn = columnName === ColumnIdentifier.name;
      const isEmailColumn = columnName === ColumnIdentifier.email;
      const isSplitColumn = columnName === ColumnIdentifier.split;
      const isIpiColumn = columnName === ColumnIdentifier.ipi;

      if (value !== state) {
        setState(value);
        const updatedRowData: IUpdateRowDataType = {
          contactReferences: [
            {
              ...getValuesFromTalent(feeSplitDetail?.contactReferences?.[0]),
              name: isNameColumn
                ? value
                : feeSplitDetail?.contactReferences?.[0]?.name ?? "",
              email: isEmailColumn
                ? value
                : feeSplitDetail?.contactReferences?.[0]?.email ?? "",
            },
          ],
          split: isSplitColumn ? value : feeSplitDetail.split,
          bankFee,
          taxWithholdingFee,
          isNew: feeSplitDetail.isNew,
          key: feeSplitDetail.key,
        };
        if (isIpiColumn && updatedRowData.contactReferences?.[0]?.ipi) {
          updatedRowData.contactReferences[0].ipi = value;
        }
        updatedRowData.paythrough = paythroughToogle;
        onUpdateRow(index, updatedRowData);
      }
    },
    [
      email,
      split,
      name,
      feeSplitDetail?.contactReferences,
      feeSplitDetail.split,
      feeSplitDetail.isNew,
      feeSplitDetail.key,
      bankFee,
      taxWithholdingFee,
      paythroughToogle,
      onUpdateRow,
      index,
    ]
  );

  const handleSelectedTalentsChange = useCallback(
    (selectedValues: IContactReferences[]) => {
      // We will always have only one value
      const selectedValue = selectedValues[0];
      setName(selectedValue?.name);
      setEmail(selectedValue?.email);
      setSelectedTalents(selectedValue);
      const updatedRowData: IUpdateRowDataType = {
        contactReferences: [getValuesFromTalent(selectedValue)],
        split: feeSplitDetail.split,
        bankFee,
        taxWithholdingFee,
        isNew: feeSplitDetail.isNew,
        key: feeSplitDetail.key,
      };
      updatedRowData.paythrough = paythroughToogle;
      onUpdateRow(index, updatedRowData);

      form?.setFieldsValue({
        [`${propertyName}${index}contactReferences0Name`]: selectedValue?.name,
      });
      form?.setFieldsValue({
        [`${propertyName}${index}contactReferences0Email`]: selectedValue?.email,
      });
      if (isWritersShareSection) {
        setIpi(selectedValue?.ipi ?? "");
        form?.setFieldsValue({
          [`${propertyName}${index}contactReferences0ipi`]:
            selectedValue?.ipi ?? "",
        });
      }
    },
    [
      bankFee,
      feeSplitDetail.isNew,
      feeSplitDetail.key,
      feeSplitDetail.split,
      form,
      index,
      isWritersShareSection,
      onUpdateRow,
      paythroughToogle,
      propertyName,
      taxWithholdingFee,
    ]
  );
  const NameColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer} ref={talentHubRef}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t(config.nameLabel)}
          </Typography.Text>
        </div>
        <Form.Item
          css={mainStyles.formItem}
          name={`${propertyName}${index}contactReferences0Name`}
          rules={[
            () => ({
              validator(_rule, value?: string) {
                if (validateRequiredFields && !value) {
                  return Promise.reject(new Error("This is a required field"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <TalentHubInput
            talenthubwidth={talentHubRef.current?.clientWidth}
            selectedTalents={selectedTalents ? [selectedTalents] : []}
            talentsResponse={talentsResponse}
            agenciesAndContacts={agenciesAndContacts}
            areTalentsLoading={areTalentsLoading}
            isMultiSelect={false}
            isWritersShareSection={isWritersShareSection}
            onAddGuestWithEmail={(guest) =>
              handleSelectedTalentsChange([guest])
            }
            onScrollTalents={onScrollTalents}
            onSelectedTalentsChange={handleSelectedTalentsChange}
            onUpdateNarrowSearchText={onUpdateNarrowSearchText}
            onResetNarrowSearch={onResetNarrowSearch}
          />
        </Form.Item>
      </div>
    ),
    [
      areTalentsLoading,
      agenciesAndContacts,
      config.nameLabel,
      handleSelectedTalentsChange,
      index,
      isMobile,
      isWritersShareSection,
      onResetNarrowSearch,
      onScrollTalents,
      onUpdateNarrowSearchText,
      propertyName,
      selectedTalents,
      t,
      talentsResponse,
      validateRequiredFields,
    ]
  );

  const handleToggleChange = useCallback(
    (value: boolean) => {
      setPaythroughToggle(value);
      const updatedRowData: IUpdateRowDataType = {
        contactReferences: [
          getValuesFromTalent(feeSplitDetail.contactReferences?.[0]),
        ],
        split: feeSplitDetail.split,
        isNew: feeSplitDetail.isNew,
        key: feeSplitDetail.key,
        paythrough: value,
        bankFee: feeSplitDetail.bankFee,
        taxWithholdingFee: feeSplitDetail.taxWithholdingFee,
      };
      onUpdateRow(index, updatedRowData);
    },
    [feeSplitDetail, index, onUpdateRow]
  );

  useEffect(() => {
    form?.setFieldsValue({
      [taxWithholdingFeePriceName]: taxWithholdingFee?.value
        ? formatCurrency(taxWithholdingFee.value)
        : "",
      [bankFeePriceName]: bankFee?.value ? formatCurrency(bankFee.value) : "",
    });
  }, [
    bankFee,
    bankFeePriceName,
    form,
    taxWithholdingFee,
    taxWithholdingFeePriceName,
  ]);

  const deleteColumn = useCallback(
    (showEmpty: boolean) => (
      <DeleteButton
        css={(index === 0 || showEmpty) && styles.invisibleContainer}
        onClick={() => onDeleteRow(index)}
      />
    ),
    [index, onDeleteRow]
  );

  const AddAndDeleteColumn = useCallback(
    (showEmpty = false) => {
      const visibility = !isLastItem || showEmpty ? "hidden" : "visible";

      return (
        <div css={mainStyles.iconsContainer}>
          {deleteColumn(showEmpty)}
          <div
            style={{
              visibility,
            }}
          >
            <div css={mainStyles.addIconContainer}>
              <ControlButton
                showTooltip
                tooltipColor="#222222"
                label={t("Add new")}
                data-testid="add-new-row-button"
                onClick={onAddNewRowItem}
              >
                <PlusOutlined />
              </ControlButton>
            </div>
          </div>
        </div>
      );
    },
    [deleteColumn, onAddNewRowItem, isLastItem, t]
  );
  useEffect(() => {
    setFee(getCalculatedSplitFee(totalFee, feeSplitDetail.split ?? ""));
  }, [feeSplitDetail.split, totalFee]);

  const emptyColumn = useMemo(
    () => <div css={[!isMobile && mainStyles.sizeOfHalfDiv]} />,
    [isMobile]
  );

  const EmailColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t(config.emailLabel)}
          </Typography.Text>
        </div>
        <Form.Item
          css={mainStyles.formItem}
          name={`${propertyName}${index}contactReferences0Email`}
        >
          <Input
            id={`${config.nameLabel}-email-${index}`}
            aria-label={t(config.emailLabel)}
            disabled
          />
        </Form.Item>
      </div>
    ),
    [config.emailLabel, config.nameLabel, index, isMobile, propertyName, t]
  );

  const SplitColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t("ProjectsPage##musicSection##Share %")}
          </Typography.Text>
        </div>
        <Form.Item
          name={`${propertyName}${index}Split`}
          css={mainStyles.formItem}
          rules={[
            () => ({
              validator(_rule, value?: string) {
                if (validateRequiredFields && !value) {
                  return Promise.reject(new Error("This is a required field"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            id={`${config.nameLabel}-split-${index}`}
            type="number"
            css={styles.feeSplit}
            aria-label={t("ProjectsPage##musicSection##Share %")}
            onBlur={(e) =>
              handleColumnUpdate(e, setSplit, ColumnIdentifier.split)
            }
          />
        </Form.Item>
      </div>
    ),
    [
      config.nameLabel,
      handleColumnUpdate,
      index,
      isMobile,
      propertyName,
      t,
      validateRequiredFields,
    ]
  );

  const FeeColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t("ProjectsPage##musicSection##Fee amount")}
          </Typography.Text>
        </div>
        <Form.Item css={mainStyles.formItem}>
          <Input
            id={`${config.nameLabel}-fee-${index}`}
            type="string"
            aria-label={t("ProjectsPage##musicSection##Fee amount")}
            value={fee}
            disabled
          />
        </Form.Item>
      </div>
    ),
    [config.nameLabel, fee, index, isMobile, t]
  );

  const paythroughToggleColumn = (
    <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
      <div css={mainStyles.basicInputContainer}>
        <Typography.Text css={mainStyles.basicInputLabel}>
          {t("ProjectsPage##servicesSection##Paythrough")}
        </Typography.Text>
      </div>
      <Form.Item css={mainStyles.formItem}>
        <Switch
          onChange={handleToggleChange}
          checked={paythroughToogle}
          css={mainStyles.switchMargin}
        />
      </Form.Item>
    </div>
  );

  const IPIColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t("ProjectsPage##musicSection##Writer IPI")}
          </Typography.Text>
        </div>
        <Form.Item
          css={mainStyles.formItem}
          name={`${propertyName}${index}contactReferences0ipi`}
        >
          <Input
            name={`${propertyName}${index}contactReferences0ipi`}
            id={`${config.nameLabel}-writerIpi-${index}`}
            type="string"
            aria-label={t("ProjectsPage##musicSection##Writer IPI")}
            value={ipi}
            disabled
          />
        </Form.Item>
      </div>
    ),
    [config.nameLabel, index, ipi, isMobile, propertyName, t]
  );
  const TaxWithholdingFeeColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <CurrencyAndPrice
          currency={
            feeSplitDetail.taxWithholdingFee?.currency ?? Currencies.USD.code
          }
          price={taxWithholdingFee?.value ?? ""}
          currencyFormItemName={`${propertyName}${index}TaxWithholdingFeeCurrency`}
          priceFormItemName={`${propertyName}${index}TaxWithholdingFeePrice`}
          onChange={handleTaxWithholdingFeeChange}
          label={t("ProjectsPage##musicSection##Tax withholding fee")}
          propertyName={propertyName}
          validateRequiredFields={false}
        />
      </div>
    ),
    [
      feeSplitDetail.taxWithholdingFee?.currency,
      handleTaxWithholdingFeeChange,
      index,
      isMobile,
      propertyName,
      t,
      taxWithholdingFee?.value,
    ]
  );

  const BankFeeColumn = useMemo(
    () => (
      <div css={[!isMobile && mainStyles.sizeOfHalfDiv]}>
        <CurrencyAndPrice
          currency={feeSplitDetail.bankFee?.currency ?? Currencies.USD.code}
          price={feeSplitDetail.bankFee?.value ?? ""}
          currencyFormItemName={`${propertyName}${index}BankFeeCurrency`}
          priceFormItemName={`${propertyName}${index}BankFeePrice`}
          onChange={handleBankFeeChange}
          label={t("ProjectsPage##musicSection##Bank fee")}
          propertyName={propertyName}
          validateRequiredFields={false}
        />
      </div>
    ),
    [
      feeSplitDetail.bankFee?.currency,
      feeSplitDetail.bankFee?.value,
      handleBankFeeChange,
      index,
      isMobile,
      propertyName,
      t,
    ]
  );
  const writerColumns = (
    <>
      {IPIColumn}
      {SplitColumn}
      {FeeColumn}
      {AddAndDeleteColumn()}
    </>
  );

  const masterColumns = (
    <>
      {SplitColumn}
      {FeeColumn}
      {paythroughToggleColumn}
      {emptyColumn}
      {AddAndDeleteColumn(true)}
    </>
  );

  const styledContainer = isMobile
    ? mainStyles.mobileSectionContainer
    : mainStyles.clientContainer;

  return (
    <>
      <div css={styledContainer}>
        {NameColumn}
        {EmailColumn}
        {isWritersShareSection ? writerColumns : masterColumns}
      </div>
      {!isWritersShareSection && (
        <div css={styledContainer}>
          {TaxWithholdingFeeColumn}
          {BankFeeColumn}
          {emptyColumn}
          {AddAndDeleteColumn()}
        </div>
      )}
    </>
  );
};

export default FeeSplitRowWithTalent;
