import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    display: flex;
    align-items: center;
    border: 2px solid ${theme.colors.primary.blue};
    -webkit-box-shadow: none;
    padding-left: 0 !important;
    background: ${theme.colors.primary.blue};
    min-height: 100%;
    color: white;
    border-radius: 0.5rem;
    height: 3.5rem !important;
    svg {
      color: ${theme.colors.white};
      margin-right: 0;
      circle {
        stroke: none;
      }
      line {
        stroke: ${theme.colors.white};
      }
    }
    :hover {
      background: ${theme.colors.secondary.navyBlue};
      border: 2px solid ${theme.colors.secondary.navyBlue};
      color: ${theme.colors.white};
      svg {
        margin-right: 0;
        circle {
          stroke: none;
        }
        line {
          stroke: ${theme.colors.white};
        }
      }
    }
    :focus {
      background: ${theme.colors.secondary.navyBlue};
      border: 3px solid ${theme.colors.secondary.navyBlue};
      color: ${theme.colors.white};
      svg {
        margin-right: 0;
        circle {
          stroke: none;
        }
        line {
          stroke: ${theme.colors.white};
        }
      }
    }
    .ant-btn {
      height: 100%;
      border: none;
      border-radius: 0;
      background: ${theme.colors.primary.blue};
      color: ${theme.colors.white};
      :hover {
        background: ${theme.colors.secondary.navyBlue};
      }
    }
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.white};
    justify-content: center;
    margin-left: 1rem;
    font-size: 1rem;
    font-family: ${theme.fonts.sofiaPro};
  `,
};
