const parseCurrencyCode = (code: string): string => {
  switch (code) {
    case "GBP":
      return "£";
    case "USD":
    case "MXN":
    case "CAD":
      return "$";
    case "SGD":
      return "$";
    case "EUR":
      return "€";
    case "JPY":
      return "¥";
    case "CNY":
      return "¥";
    case "THB":
      return "฿";
    case "HKD":
      return "$";
    case "PHP":
      return "₱";
    case "ZAR":
      return "R";
    case "IDR":
      return "Rp";
    case "AUD":
      return "$";
    case "BRL":
      return "R$";
    case "TRY":
      return "₺";
    case "MMK":
      return "K";
    case "ARS":
      return "$";
    case "PLN":
      return "zł";
    case "SEK":
      return "kr";
    case "AED":
      return "د.إ";
    default:
      return "";
  }
};

export const stripCurrencyFormatting = (value: string | number): string => {
  return value.toString().replace(/[,]/g, "");
};

export const convertToNumber = (value: string | number): number => {
  return Number(stripCurrencyFormatting(value));
};

export const isValidNumber = (value: string | number): boolean => {
  return !Number.isNaN(convertToNumber(value));
};

export const formatCurrency = (number: string | number): string => {
  const numberToFormat = stripCurrencyFormatting(number.toString());
  const formattedNum = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format((numberToFormat as unknown) as number);
  return formattedNum !== "NaN" ? formattedNum : number.toString();
};

export const formatPriceWithCurrency = (
  number: string | number,
  currency: string
): string => {
  const numberToFormat = stripCurrencyFormatting(number.toString());
  const formattedNum = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format((numberToFormat as unknown) as number);
  return formattedNum !== "NaN" ? formattedNum : number.toString();
};

export default parseCurrencyCode;
