import { LicenseFixedDurations, MediaTypes } from "src/constants";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import {
  IFeeSplitDetailState,
  IFinalTrackSection,
  IFormContacts,
} from "src/pages/projects/project/interfaces";
import { getIsValidEmail } from "src/pages/team/components/org-invites/components/invite-members/email-validator";
import { array, object, string, number, InferType, boolean } from "yup";

const requiredFieldMessage = "This is a required field";
const invalidEmailMessage = "Email format is invalid";

const feeObjectWithEmail = object().shape({
  email: string().email("invalidEmailMessage"),
});
const feeObjectContactReferencesWithEmailOnly = object().shape({
  contactReferences: array().of(feeObjectWithEmail),
});
const feeObject = object().shape({
  name: string().required(requiredFieldMessage),
  email: string().required(requiredFieldMessage).email(invalidEmailMessage),
});
const feeObjectContactReferences = object().shape({
  contactReferences: array().of(feeObject),
  split: string().required(requiredFieldMessage),
});

const trackSchema = object().shape({
  title: string().required(requiredFieldMessage),
  musicType: string().required(requiredFieldMessage),
  masterFeeSplits: array().of(feeObjectContactReferencesWithEmailOnly),
  publisherFeeSplits: array().of(feeObjectContactReferencesWithEmailOnly),
  writerFeeSplits: array().of(feeObjectContactReferencesWithEmailOnly),
});

const trackSchemaWithoutWriterFee = object().shape({
  title: string().required(requiredFieldMessage),
  musicType: string().required(requiredFieldMessage),
  masterFee: object().shape({
    currency: string().required(requiredFieldMessage),
    value: number().required(requiredFieldMessage),
  }),
  masterFeeSplits: array().of(feeObjectContactReferences),
  publisherFee: object().shape({
    currency: string().required(requiredFieldMessage),
    value: number().required(requiredFieldMessage),
  }),
  publisherFeeSplits: array().of(feeObjectContactReferences),
});

const trackSchemaWithWriterFee = object().shape({
  title: string().required(requiredFieldMessage),
  musicType: string().required(requiredFieldMessage),
  masterFee: object().shape({
    currency: string().required(requiredFieldMessage),
    value: number().required(requiredFieldMessage),
  }),
  masterFeeSplits: array().of(feeObjectContactReferences),
  publisherFee: object().shape({
    currency: string().required(requiredFieldMessage),
    value: number().required(requiredFieldMessage),
  }),
  publisherFeeSplits: array().of(feeObjectContactReferences),
  writerFeeSplits: array().of(feeObjectContactReferences),
});

const trackSchemaArray = array().of(trackSchema);
const trackSchemaArrayWithWriterFee = array().of(trackSchemaWithWriterFee);
const trackSchemaArrayWithoutWriterFee = array().of(
  trackSchemaWithoutWriterFee
);

type TermsSchema = {
  startDate: string;
  endDate?: string | null | undefined;
  name: string;
  type: string;
  mediaType: MediaTypes[];
  customMediaType: string[];
  fixedDuration: string;
  lyricChange?: {
    checked: boolean | null;
    notes: string;
  };
};

const endDateValidation = () => {
  return string()
    .nullable()
    .test("Is end date required", requiredFieldMessage, (date, context) => {
      const { fixedDuration } = context.parent as TermsSchema;
      const isCustomDuration =
        fixedDuration === LicenseFixedDurations.CustomDuration;
      if (isCustomDuration && !date) {
        context.createError({
          path: context.path,
          message: requiredFieldMessage,
        });

        return isCustomDuration && !date;
      }

      return true;
    });
};

const checkTerritories = () => {
  return object()
    .shape({
      worldwide: boolean(),
      excludedCountries: array().of(string()),
      includedCountries: array().of(string()),
    })
    .test(
      "Territories required",
      requiredFieldMessage,
      (territories, context) => {
        const isWorldwide = territories.worldwide ?? false;
        const excluded = territories.excludedCountries ?? [];
        const included = territories.includedCountries ?? [];

        const territoriesEntered =
          (isWorldwide && excluded.length >= 0 && included.length === 0) ||
          (!isWorldwide && excluded.length === 0 && included.length > 0);
        if (!territoriesEntered) {
          context.createError({
            path: context.path,
            message: requiredFieldMessage,
          });

          return !!territoriesEntered;
        }

        return true;
      }
    );
};

const checkLyricChangeNotes = () => {
  return string().test(
    "Lyric change notes required",
    requiredFieldMessage,
    (lyricChangeNotes, context) => {
      const { checked } = context.parent as TermsSchema["lyricChange"];
      if (checked && !lyricChangeNotes) {
        context.createError({
          path: context.path,
          message: requiredFieldMessage,
        });

        return false;
      }

      return true;
    }
  );
};

const checkMediaType = () => {
  return array()
    .of(string())
    .test("Media type required", requiredFieldMessage, (mediaType, context) => {
      const { customMediaType } = context.parent as TermsSchema;
      const allMediaTypes = [...(mediaType ?? []), ...(customMediaType ?? [])];

      if ((allMediaTypes ?? []).length === 0) {
        context.createError({
          path: context.path,
          message: requiredFieldMessage,
        });

        return false;
      }

      return true;
    });
};

const checkCustomMediaType = () => {
  return array()
    .of(string())
    .test(
      "Media type required",
      requiredFieldMessage,
      (customMediaType, context) => {
        const { mediaType } = context.parent as TermsSchema;
        const allMediaTypes = [
          ...(mediaType ?? []),
          ...(customMediaType ?? []),
        ];

        if ((allMediaTypes ?? []).length === 0) {
          context.createError({
            path: context.path,
            message: requiredFieldMessage,
          });

          return false;
        }

        return true;
      }
    );
};

const termSchema = object().shape({
  name: string().required(requiredFieldMessage),
  type: string().required(requiredFieldMessage),
  mediaType: checkMediaType(),
  customMediaType: checkCustomMediaType(),
  territories: checkTerritories(),
  fixedDuration: string().required(requiredFieldMessage),
  endDate: endDateValidation(),
  startDate: string().required(requiredFieldMessage),
});

const termSchemaDefault = object().shape({
  lyricChange: object().shape({
    notes: checkLyricChangeNotes(),
  }),
});

const termSchemaArray = array().of(termSchema);
const termSchemaDefaultArray = array().of(termSchemaDefault);

const projectDetailsSchema = object().shape({
  format: string().required(requiredFieldMessage),
  name: string().required(requiredFieldMessage),
  leads: array().min(1, requiredFieldMessage).required(requiredFieldMessage),
  jobListingDate: string().required(requiredFieldMessage),
  status: string().required(requiredFieldMessage),
});

const regionProjectDetailsSchema = object().shape({
  projectRegion: string().required(requiredFieldMessage),
});

const projectSchemaWithoutWriterFee = object().shape({
  format: string().required(requiredFieldMessage),
  name: string().required(requiredFieldMessage),
  leads: array().min(1, requiredFieldMessage).required(requiredFieldMessage),
  jobListingDate: string().required(requiredFieldMessage),
  status: string().required(requiredFieldMessage),
  tracks: array().of(trackSchemaWithoutWriterFee),
  terms: array().of(termSchema),
});

const projectSchemaWithWriterFee = object().shape({
  format: string().required(requiredFieldMessage),
  name: string().required(requiredFieldMessage),
  leads: array().min(1, requiredFieldMessage).required(requiredFieldMessage),
  jobListingDate: string().required(requiredFieldMessage),
  status: string().required(requiredFieldMessage),
  tracks: array().of(trackSchemaWithWriterFee),
  terms: array().of(termSchema),
});

const enterpriseLibraryProjectSchema = object().shape({
  contractStartDate: string().required(requiredFieldMessage),
  contractEndDate: string().required(requiredFieldMessage),
  client: object({
    id: string().min(1, requiredFieldMessage).required(requiredFieldMessage),
  }).required(requiredFieldMessage),
  clientContact: object({
    id: string().min(1, requiredFieldMessage).required(requiredFieldMessage),
  }).required(requiredFieldMessage),
});

const enterpriseLibraryProjectDetailsTabSchema = object().shape({
  client: object({
    id: string().min(1, requiredFieldMessage).required(requiredFieldMessage),
  }).required(requiredFieldMessage),
  clientContact: object({
    id: string().min(1, requiredFieldMessage).required(requiredFieldMessage),
  }).required(requiredFieldMessage),
});

const enterpriseLibraryProjectSubscriptionTabSchema = object().shape({
  contractStartDate: string().required(requiredFieldMessage),
  contractEndDate: string().required(requiredFieldMessage),
});

const contactData = object().shape({
  email: string().email(invalidEmailMessage).required(requiredFieldMessage),
  name: string().required(requiredFieldMessage),
});
const contactsSchemaWithRequiredDataArray = object().shape({
  contacts: array().of(contactData),
});
const contactsSchemaArray = object().shape({
  contacts: array().of(feeObjectWithEmail),
  contactsInvoicees: array().of(feeObjectWithEmail),
});

const statusDropdownSchema = object().shape({
  status: object()
    .shape({
      value: string(),
      label: string(),
    })
    .required(requiredFieldMessage),
});

const yupDetailsSchemaSync = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async validator({ field }: any, value: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    await projectDetailsSchema.validateSyncAt(field, { [field]: value });
  },
};

const yupStatusSync = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async validator({ field }: any, value: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    await statusDropdownSchema.validateSyncAt(field, { [field]: value });
  },
};

const stringValidationRequired = [
  { required: true, message: requiredFieldMessage },
];

const calculateSplitSums = (splits: IFeeSplitDetailState[]) => {
  if ((splits ?? []).length === 0) {
    return true;
  }

  const splitsSum = splits.reduce((sum, currentSplit) => {
    if (currentSplit.split) {
      return sum + Number(currentSplit.split);
    }
    return sum;
  }, 0);

  return splitsSum === 100;
};

const checkSharesTotalPercentage = (track: IFinalTrackSection): boolean => {
  const isMasterFeeSplitsValid = calculateSplitSums(track.masterFeeSplits);
  const isWriterFeeSplitsValid = calculateSplitSums(track.writerFeeSplits);
  const isPublisherFeeSplitsValid = calculateSplitSums(
    track.publisherFeeSplits
  );

  return (
    isMasterFeeSplitsValid &&
    isWriterFeeSplitsValid &&
    isPublisherFeeSplitsValid
  );
};

const areContactNameAndEmailFilled = (contacts: IFormContacts[]): boolean => {
  return contacts.every(
    (element) => element.email !== "" && element.name !== ""
  );
};

const areAllEmailInContactsValid = (contacts: IFormContacts[]): boolean => {
  const allEmails = contacts.reduce((prev, curr) => {
    const newArray = prev;
    if (curr.email) {
      newArray.push(curr.email);
    }
    return newArray;
  }, [] as string[]);
  return allEmails.every((email) => getIsValidEmail(email));
};
const checkSharesTotalPercentageInAllTracks = (
  tracks?: IFinalTrackSection[]
): boolean => {
  const allTracks = tracks ?? [];
  if (allTracks.length > 0) {
    return allTracks
      .map((track) => checkSharesTotalPercentage(track))
      .some((isValid) => isValid);
  }

  return true;
};

const libraryTrackSubscriptionSchema = object().shape({
  contractStartDate: string().required(requiredFieldMessage),
  contractEndDate: string().required(requiredFieldMessage),
});

const libraryTrackAdditionalDetailsSchema = object().shape({
  client: object({
    value: string().min(1, requiredFieldMessage).required(requiredFieldMessage),
  }).required(requiredFieldMessage),
  clientContact: object({
    value: string().min(1, requiredFieldMessage).required(requiredFieldMessage),
  }).required(requiredFieldMessage),
});

const yupLibraryTrackAdditionalDetailsSync = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async validator({ field }: any, value: any) {
    await libraryTrackAdditionalDetailsSchema.validateSyncAt(field, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [field]: value,
    });
  },
};

const yupLibraryTrackSubscriptionSync = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async validator({ field }: any, value: any) {
    await libraryTrackSubscriptionSchema.validateSyncAt(field, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [field]: value,
    });
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const requiredFieldValidator = (validate = true) => {
  if (!validate) {
    return [];
  }
  return [
    () => ({
      validator(_, value?: string) {
        if (!value || value.length === 0) {
          return Promise.reject(new Error(requiredFieldMessage));
        }
        return Promise.resolve();
      },
    }),
  ];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const requiredOptionFieldValidator = (validate = true) => {
  if (!validate) {
    return [];
  }
  return [
    () => ({
      validator(_, value?: IOption) {
        if (!value || !value?.value) {
          return Promise.reject(new Error(requiredFieldMessage));
        }
        return Promise.resolve();
      },
    }),
  ];
};

export default {
  trackSchema,
  trackSchemaWithWriterFee,
  trackSchemaWithoutWriterFee,
  trackSchemaArray,
  areAllEmailInContactsValid,
  areContactNameAndEmailFilled,
  contactsSchemaArray,
  contactsSchemaWithRequiredDataArray,
  trackSchemaArrayWithWriterFee,
  trackSchemaArrayWithoutWriterFee,
  termSchema,
  termSchemaDefault,
  termSchemaArray,
  termSchemaDefaultArray,
  projectDetailsSchema,
  projectSchemaWithWriterFee,
  projectSchemaWithoutWriterFee,
  yupDetailsSchemaSync,
  yupStatusSync,
  stringValidationRequired,
  checkSharesTotalPercentage,
  checkSharesTotalPercentageInAllTracks,
  yupLibraryTrackAdditionalDetailsSync,
  enterpriseLibraryProjectSchema,
  enterpriseLibraryProjectDetailsTabSchema,
  enterpriseLibraryProjectSubscriptionTabSchema,
  yupLibraryTrackSubscriptionSync,
  requiredFieldValidator,
  regionProjectDetailsSchema,
  requiredOptionFieldValidator,
};
