import axios from "axios";
import { IProjectForm } from "src/pages/projects/project/interfaces";
import { getProjectUrl } from "../../project-url-helper";
import { getAxiosOptions, getJsonContentHeader } from "../../headers";

const getPublicProjectSummary = (
  shareCode: string,
  projectId: string,
  organisationId: string
): Promise<IProjectForm | null> => {
  const options = {
    headers: { ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const URL = `${getProjectUrl(
    projectId,
    organisationId
  )}/sharing/summary/?sharecode=${shareCode}`;

  return axios.get(URL, options).then(({ data }) => {
    return data as IProjectForm;
  });
};

export default getPublicProjectSummary;
