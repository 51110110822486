import React, { Fragment, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table from "src/components/table";
import {
  getIsMobile,
  getIsTablet,
  useWindowSize,
} from "@songtradr/spa-common/lib/utils";
import getColumns from "src/pages/team/table-data/org-members-columns";
import BaseModal, { ModalTypes } from "src/components/modals/base-modal";
import { IConfirmContentProps } from "src/components/interfaces";
import Content from "src/components/modals/confirmation-modal/content";
import style from "../../styles";
import {
  IOrganisationMemberIdentifierProps,
  IOrganisationMemberProps,
  OrgMembersSortType,
  SortOrder,
} from "../../interfaces";
import teamMemberData from "../../table-data/org-member-data";

const NoOrgMembers = (): ReactElement => {
  return <div />;
};

interface IProps {
  getData: () => Promise<void>;
  orgMembers: Array<IOrganisationMemberProps>;
  nameSortAscending: boolean;
  handleNameSort: () => void;
  handleJobTitleSort: () => void;
  jobTitleSortAscending: boolean;
  handleMobileSortClick: () => void;
  handleToggleAdmin: (selectedOrgMember: IOrganisationMemberProps) => void;
  currentSortType: OrgMembersSortType;
  isModalOpen: boolean;
  openModalType?: ModalTypes;
  setIsModalOpen: (value: boolean) => void;
  selectedOrgMember: IOrganisationMemberProps | undefined;
  handleDeleteOrgMemberClick: (
    selectedOrgMember: IOrganisationMemberIdentifierProps
  ) => void;
  handleOnCloseModal: () => void;
  handleMobileMoreInfoClick: (
    selectedOrgMember: IOrganisationMemberIdentifierProps
  ) => void;
  setSortTypeAndOrder: (
    sortType: OrgMembersSortType,
    sortOrder: SortOrder
  ) => void;
  confirmDeleteContentProps: IConfirmContentProps;
}

const OrgMembersTable = ({
  getData,
  orgMembers,
  nameSortAscending,
  handleNameSort,
  handleMobileSortClick,
  jobTitleSortAscending,
  handleJobTitleSort,
  handleToggleAdmin,
  currentSortType,
  openModalType,
  isModalOpen,
  handleMobileMoreInfoClick,
  handleOnCloseModal,
  setSortTypeAndOrder,
  confirmDeleteContentProps,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  useEffect(() => {
    void (async () => {
      await getData();
      setSortTypeAndOrder(OrgMembersSortType.LastLogin, SortOrder.Descending);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();

  if (orgMembers?.length === 0) {
    return <NoOrgMembers />;
  }

  const dataSource = teamMemberData(
    orgMembers,
    isTablet,
    isMobile,
    handleMobileMoreInfoClick,
    handleToggleAdmin,
    t
  );

  const columns = getColumns(
    isTablet,
    isMobile,
    nameSortAscending,
    jobTitleSortAscending,
    handleNameSort,
    handleJobTitleSort,
    currentSortType,
    t,
    handleMobileSortClick
  );

  return (
    <Fragment>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        css={style.table}
        data-testid="team-members-table"
      />
      {openModalType === ModalTypes.deleteOrganisationMember &&
        confirmDeleteContentProps && (
          <BaseModal
            open={isModalOpen}
            content={<Content {...confirmDeleteContentProps} />}
            buttons={[]}
            onClose={handleOnCloseModal}
          />
        )}
    </Fragment>
  );
};

export default OrgMembersTable;
