import axios from "axios";
import getAuthHeader from "../headers/get-auth-header";
import getJsonContentHeader from "../headers/get-json-content-header";
import { attachmentVerifyUploadUrl } from "../project-url-helper";

interface IProps {
  attachmentId: string;
  projectId: string;
  accessToken: string;
  orgId: string;
}

async function verifyFileUpload({
  attachmentId,
  projectId,
  accessToken,
  orgId,
}: IProps): Promise<void> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  return axios.get(
    attachmentVerifyUploadUrl(projectId, attachmentId, orgId),
    options
  );
}

export default verifyFileUpload;
