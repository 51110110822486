import { DatePicker, Form, Input, Typography } from "antd";
import { memo, ReactElement, useCallback, useEffect } from "react";
import { getIsMobile } from "@songtradr/spa-common/lib/utils";
import { DateDisplayFormat } from "src/constants";
import dayjs from "dayjs";
import projectValidation from "src/utils/projectValidation";
import { useTranslation } from "react-i18next";
import style from "../../styles";
import { IProjectSubscriptionDetails } from "../../interfaces";

interface IProps {
  projectSubscriptionDetails: IProjectSubscriptionDetails;
  updateProjectSubscriptionDetails: (
    details: IProjectSubscriptionDetails
  ) => void;
}

const SubscriptionDetails = memo(
  ({
    projectSubscriptionDetails,
    updateProjectSubscriptionDetails,
  }: IProps): ReactElement => {
    const [form] = Form.useForm();
    const isMobile = getIsMobile();
    const { t } = useTranslation();
    const handleDatePickerChange = useCallback(
      (field: string, value: dayjs.Dayjs | null) => {
        if (value) {
          updateProjectSubscriptionDetails({ [field]: value.toISOString() });
        }
      },
      [updateProjectSubscriptionDetails]
    );

    useEffect(() => {
      void form.validateFields(["contractStartDate", "contractEndDate"]);
    }, [
      form,
      projectSubscriptionDetails?.contractEndDate,
      projectSubscriptionDetails?.contractStartDate,
    ]);

    return (
      <Form
        form={form}
        initialValues={{
          contractStartDate: projectSubscriptionDetails.contractStartDate
            ? dayjs(projectSubscriptionDetails.contractStartDate)
            : undefined,
          contractEndDate: projectSubscriptionDetails.contractEndDate
            ? dayjs(projectSubscriptionDetails.contractEndDate)
            : undefined,
        }}
        validateTrigger="onChange"
        scrollToFirstError
      >
        <div css={style.titleLabelContainer}>
          <Typography.Text css={style.titleLabel}>
            {t("ProjectsPage##Subscription")}
          </Typography.Text>
        </div>
        <div css={style.sectionContainer}>
          <div
            css={
              isMobile ? style.mobileSectionContainer : style.clientContainer
            }
          >
            <div>
              <div css={style.basicInputContainer}>
                <Typography.Text css={style.basicInputLabel}>
                  Start date
                </Typography.Text>
              </div>
              <Form.Item
                name="contractStartDate"
                css={style.formItem}
                rules={[projectValidation.yupLibraryTrackSubscriptionSync]}
              >
                <DatePicker
                  allowClear={false}
                  onChange={(value) => {
                    handleDatePickerChange("contractStartDate", value);
                  }}
                  placeholder=""
                  format={DateDisplayFormat}
                />
              </Form.Item>
            </div>
            <div>
              <div css={style.basicInputContainer}>
                <Typography.Text css={style.basicInputLabel}>
                  End date
                </Typography.Text>
              </div>
              <Form.Item
                css={style.formItem}
                name="contractEndDate"
                rules={[projectValidation.yupLibraryTrackSubscriptionSync]}
              >
                <DatePicker
                  allowClear={false}
                  onChange={(value) => {
                    handleDatePickerChange("contractEndDate", value);
                  }}
                  placeholder=""
                  format={DateDisplayFormat}
                />
              </Form.Item>
            </div>
          </div>
          <div css={style.lastOfSectionBottom}>
            <div css={style.basicInputContainer}>
              <Typography.Text css={style.basicInputLabel}>
                Notes
              </Typography.Text>
            </div>
            <Input.TextArea
              onChange={(event) => {
                updateProjectSubscriptionDetails({
                  description: event.target.value,
                });
              }}
              rows={3}
              name="description"
              value={projectSubscriptionDetails.description}
            />
          </div>
        </div>
      </Form>
    );
  }
);
export default SubscriptionDetails;
