import { Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "@songtradr/spa-common";
import { IFormContacts } from "src/pages/projects/project/interfaces";

import ButtonWithLabelAndPlusIcon from "src/components/plus-button-with-label";
import style from "../../../../../styles";
import ContactEmailRows from "..";

interface IAdditionalContactsConfig {
  fieldName: string;
  testDataId: string;
  sectionHeaderTranslationKey: string;
  nameHelpTextTranslationKey: string;
}

interface IProps {
  contactsSection?: IFormContacts[];
  propertyName: string;
  isContactNameRequired?: boolean;
  isEmailAddressRequired?: boolean;
  onCreateContactRow: () => void;
  onUpdateContacts: (contacts: IFormContacts[]) => void;
}

const OtherContactsRow = ({
  contactsSection,
  propertyName,
  isContactNameRequired = false,
  isEmailAddressRequired = false,
  onCreateContactRow,
  onUpdateContacts,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const config: IAdditionalContactsConfig = {
    fieldName: "contacts",
    testDataId: "contacts",
    nameHelpTextTranslationKey:
      "ProjectsPage##contactsSection##contactsSectionNameHelpText",
    sectionHeaderTranslationKey:
      "ProjectsPage##contactsSection##AdditionalContractInformation",
  };

  return (
    <div css={style.mobileSectionContainer}>
      <div css={style.titleLabelContainer}>
        <Typography.Text css={style.titleLabel}>
          {t("ProjectsPage##contactsSection##OtherContacts")}
        </Typography.Text>
      </div>
      <div
        css={isMobile ? style.mobileSectionContainer : style.clientContainer}
      >
        {contactsSection?.length !== 0 ? (
          <ContactEmailRows
            propertyName={propertyName}
            fieldName={config.fieldName}
            items={contactsSection ?? []}
            updateContacts={onUpdateContacts}
            isContactNameRequired={isContactNameRequired}
            isEmailAddressRequired={isEmailAddressRequired}
          />
        ) : (
          <>
            <Typography.Text>
              {t("ProjectsPage##contactsSection##noAdditionalContacts")}
            </Typography.Text>
          </>
        )}
      </div>
      <div css={style.lastOfSectionBottom} style={{ marginTop: "2rem" }}>
        <ButtonWithLabelAndPlusIcon
          onClick={onCreateContactRow}
          dataTestId="add-new-row-button"
          ariaLabel="ProjectsPage##contactsSection##Add additional contact information"
          label="ProjectsPage##contactsSection##Add additional contact information"
        />
      </div>
    </div>
  );
};

export default OtherContactsRow;
