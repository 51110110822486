import React, {
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import STLoadingLogo from "src/components/st-loading-logo";
import ConfirmModal from "src/components/modals/confirm-modal";
import PageHeader from "src/components/page-header";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getIsMobile } from "@songtradr/spa-common";
import { ITab, TabsTitles } from "./interfaces";
import style from "./styles";
import ContactPageTabs from "./components/contact-page-tabs";
import ContactFooter from "./components/contact-footer";

interface IChildrenProps {
  personalDetails: ReactNode;
  publishingInformation: ReactNode;
  aAndR: ReactNode;
  notesAndDocuments: ReactNode;
  contactCreatedOrUpdated: ReactNode;
}
interface IProps {
  isSaving: boolean;
  isLoading: boolean;
  children: IChildrenProps;
  activeTab?: ITab;
  setActiveTab: (activeTab: ITab) => void;
  onSaveContact: (finishAfterSave: () => void) => Promise<void>;
  validation: {
    personalDetails: boolean;
    publishingInformation: boolean;
    aAndR: boolean;
    notesAndDocuments: boolean;
  };
  validateTabFields: (tabTitle: string) => void;
  disabledTabsForClick: boolean;
  isUpdateContactButtonVisible?: boolean;
}
const Contact = memo(
  ({
    isSaving,
    validation,
    isLoading,
    children,
    activeTab,
    disabledTabsForClick,
    isUpdateContactButtonVisible = true,
    setActiveTab,
    onSaveContact,
    validateTabFields,
  }: IProps): ReactElement => {
    const {
      personalDetails,
      publishingInformation,
      aAndR,
      notesAndDocuments,
      contactCreatedOrUpdated,
    } = children;
    const { t } = useTranslation();
    const history = useHistory();
    const isMobile = getIsMobile();

    const PersonalDetailsTab = useMemo(() => {
      return <div>{personalDetails}</div>;
    }, [personalDetails]);

    const PublishingInformationTab = useMemo(() => {
      return <div>{publishingInformation}</div>;
    }, [publishingInformation]);

    const AAndRTab = useMemo(() => {
      return <div>{aAndR}</div>;
    }, [aAndR]);

    const NotesAndDocumentsTab = useMemo(() => {
      return <div>{notesAndDocuments}</div>;
    }, [notesAndDocuments]);

    const ContactCreatedOrUpdated = useMemo(() => {
      return <div>{contactCreatedOrUpdated}</div>;
    }, [contactCreatedOrUpdated]);

    const contactTabs = useMemo(() => {
      return [
        ...(!isMobile
          ? [
              {
                tabTitle: TabsTitles.NewContact,
                content: <></>,
              },
            ]
          : []),
        {
          tabTitle: TabsTitles.PersonalDetails,
          content: PersonalDetailsTab,
        },
        {
          tabTitle: TabsTitles.PublishingInformation,
          content: PublishingInformationTab,
        },
        {
          tabTitle: TabsTitles.AAndR,
          content: AAndRTab,
        },
        {
          tabTitle: TabsTitles.NotesAndDocuments,
          content: NotesAndDocumentsTab,
        },
        {
          tabTitle: TabsTitles.Finished,
          content: ContactCreatedOrUpdated,
        },
      ];
    }, [
      isMobile,
      PersonalDetailsTab,
      PublishingInformationTab,
      AAndRTab,
      NotesAndDocumentsTab,
      ContactCreatedOrUpdated,
    ]);

    const activeTabIndex = useMemo(
      () =>
        contactTabs.findIndex((tab) => tab.tabTitle === activeTab?.tabTitle),
      [activeTab, contactTabs]
    );

    const handleBackButtonClick = useCallback(() => {
      if (activeTabIndex > 1) {
        setActiveTab(contactTabs[activeTabIndex - 1]);
      }
    }, [activeTabIndex, contactTabs, setActiveTab]);

    const isFinishedTab = useMemo(
      () => activeTab?.tabTitle === TabsTitles.NotesAndDocuments,
      [activeTab]
    );

    const onButtonText = useMemo(() => {
      if (!disabledTabsForClick) {
        return "Update Contact";
      }
      return isFinishedTab ? "Submit" : "Continue";
    }, [disabledTabsForClick, isFinishedTab]);

    const isTabDataValid = useCallback(
      (tabTitle: string) => {
        switch (tabTitle) {
          case TabsTitles.PersonalDetails:
            return validation.personalDetails;
          case TabsTitles.PublishingInformation:
            return validation.publishingInformation;
          case TabsTitles.AAndR:
            return validation.aAndR;
          case TabsTitles.NotesAndDocuments:
            return validation.notesAndDocuments;
          default:
            return validation.personalDetails;
        }
      },
      [
        validation.aAndR,
        validation.notesAndDocuments,
        validation.personalDetails,
        validation.publishingInformation,
      ]
    );

    const handleOkButtonClick = useCallback(async () => {
      const activeTabTitle = activeTab?.tabTitle ?? contactTabs[1].tabTitle;
      if (!isTabDataValid(activeTabTitle)) {
        validateTabFields(activeTabTitle);
        return;
      }
      if (disabledTabsForClick) {
        if (isFinishedTab) {
          const finishAfterSave = () => {
            setActiveTab(contactTabs[activeTabIndex + 1]);
          };
          await onSaveContact(finishAfterSave);
        } else if (activeTabIndex === -1) {
          setActiveTab(contactTabs[2]);
        } else if (activeTabIndex < contactTabs.length - 1) {
          setActiveTab(contactTabs[activeTabIndex + 1]);
        }
      } else {
        const finishAfterSave = () => {
          setActiveTab(contactTabs[5]);
        };
        await onSaveContact(finishAfterSave);
      }
    }, [
      activeTab?.tabTitle,
      contactTabs,
      isTabDataValid,
      disabledTabsForClick,
      isFinishedTab,
      activeTabIndex,
      validateTabFields,
      onSaveContact,
      setActiveTab,
    ]);

    return (
      <div className="contact-container" css={style.contactContainer}>
        {isLoading ? (
          <STLoadingLogo pageCentered />
        ) : (
          <>
            {isSaving && <STLoadingLogo pageCentered />}
            <ConfirmModal />
            <PageHeader title={t("Talent Hub")} />
            <div
              css={[style.mainContainer, isSaving && style.blurMainContainer]}
            >
              <ContactPageTabs
                tabs={contactTabs}
                activeTab={activeTab ?? contactTabs[1]}
                setActiveTab={setActiveTab}
                disabledTabsForClick={disabledTabsForClick}
              />
            </div>
            {activeTab?.tabTitle !== TabsTitles.Finished && (
              <div css={[isSaving && style.footerNoActionsAllowed]}>
                <ContactFooter
                  cancelButtonText="Cancel"
                  backButtonText={disabledTabsForClick ? "Previous" : undefined}
                  okButtonText={onButtonText}
                  onCancelButtonClick={() => history.replace("/talents")}
                  onBackButtonClick={
                    disabledTabsForClick ? handleBackButtonClick : undefined
                  }
                  onOkButtonClick={handleOkButtonClick}
                  isPreviousButtonVisible={
                    !!activeTab?.tabTitle &&
                    activeTab?.tabTitle !== TabsTitles.PersonalDetails
                  }
                  isOKButtonVisible={isUpdateContactButtonVisible}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

export default Contact;
