import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  mobileSortContainer: css`
    padding: 1rem;
    overflow: auto;
    margin-bottom: 1rem;
    button {
      width: 100%;
      height: 2.75rem;
      line-height: 1rem;
      border: none;
      margin-top: 0.4rem;
      border-radius: 0.25rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      text-align: left;
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1rem;
      letter-spacing: 0;
      font-weight: normal;

      :first-of-type {
        margin-top: 0;
      }

      &:active {
        color: ${theme.colors.black} !important;
      }

      &:hover {
        color: ${theme.colors.black} !important;
      }

      &:focus {
        color: ${theme.colors.black} !important;
      }

      > span {
        margin-top: 0.4rem;
        :first-of-type {
          margin-top: 0.4rem;
        }
      }

      > .anticon {
        float: right;
        margin-top: 0;

        & svg {
          fill: ${theme.colors.secondary.purple};
          width: 0.875rem;
          margin-top: 0.3rem;
        }
      }
    }
  `,
};
