import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  floatLabel: css`
    position: relative;
    color: ${theme.colors.background.darkGray};
    input,
    textarea {
      padding-top: 1rem;
    }
  `,
  label: css`
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0.75rem;
    top: 0.75rem;
    transition: 0.2s ease all;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  labelFloat: css`
    top: 4px;
    font-size: 0.75rem !important;
  `,
};
