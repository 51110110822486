import { ISavedUserFilter } from "src/api/user-filters/interfaces";
import dayjs from "dayjs";
import parseJSONToLabel, {
  ISelectLabel,
  ISelectProjectsLeads,
  parseContactToLabel,
  parseJSONToEmailValue,
  parseJSONToIdValue,
  parseStatusToLabel,
} from "src/components/modals/clear-filter/utils";
import { RangeDateDisplayFormat } from "src/constants";
import { ISimpleUser } from "src/interfaces/auth";
import { startCase } from "lodash";
import {
  IHoldingCompany,
  IOption,
} from "../../project/components/account-information/types";
import {
  IClientContact,
  ICreativeAgency,
  ProjectStatus,
} from "../../project/interfaces";
import {
  IActiveLabels,
  IGlobalBrands,
  IProjectClient,
  IProjectLead,
} from "../interfaces";

const formatClientList = (clients: IProjectClient[]): string[] => {
  let list = [];

  list = clients.map((client, index) => {
    let value = client.name;
    if (clients[index + 1]) {
      value += ", ";
    }
    return value;
  });

  // Add brackets to beginning and end of client list
  list.unshift("(");
  list.push(")");

  return list;
};

export const parseFilterKey = (filterKey: string): string => {
  switch (filterKey) {
    case "clients":
      return "Client name";
    case "globalBrands":
      return "Global brand";
    case "statuses":
      return "Status";
    case "leads":
      return "Project lead";
    case "contacts":
      return "Contact";
    case "creativeAgencies":
      return "Creative agency";
    case "holdingCompanies":
      return "Holding company";
    case "musicTypes":
      return "Music type";
    case "projectTypes":
      return "Project type";
    case "serviceTypes":
      return "Service type";
    case "projectCloseDate":
      return "Project close date";
    case "jobListingDate":
      return "Job listing date";
    case "projectRegions":
      return "Project region";
    case "officeLocations":
      return "Office/Country";
    case "copyrightInfoProcessed":
      return "Copyright info processed";
    case "publishingRightsSigned":
      return "Publishing rights signed";
    case "publishingTriggered":
      return "Publishing notified";
    default:
      return "";
  }
};

export const formatProjectLeads = (leads: IProjectLead[]): string => {
  return leads.map((lead) => lead.name).join(", ");
};

export const mapProjectsLabelValues = (
  values: string[],
  allProjectTypesOrStatuses: IOption[]
): ISelectLabel[] => {
  return values.map((value) => ({
    label:
      allProjectTypesOrStatuses.find(
        (statusOrType) => statusOrType.value === value
      )?.label ?? value,
    value,
  }));
};
export const mapLeadsLabelValues = (
  filterOptions: IProjectLead[],
  items: string[],
  allUsers: ISimpleUser[]
): ISelectProjectsLeads[] => {
  return items.map((id: string) => {
    const lead = filterOptions.find((item) => item.id === id);
    let label = lead?.name;
    if (!lead?.name) {
      label = allUsers.find((item) => item.id === id)?.name;
    }
    return { value: id, label: label ?? id, email: lead?.email ?? "" };
  });
};

export const mapLabelValues = (
  filterOptions: IProjectClient[] | IGlobalBrands[] | IHoldingCompany[],
  items: string[]
): ISelectLabel[] => {
  return items.map((id: string) => {
    const label =
      filterOptions.find(({ id: itemId }) => itemId?.toString() === id)?.name ??
      id;

    return { value: id, label };
  });
};

export const mapLabelContactValues = (
  filterOptions: IClientContact[],
  items: string[]
): ISelectLabel[] => {
  return items.map((email: string) => {
    const label: string =
      filterOptions.find(({ email: contactEmail }) => email === contactEmail)
        ?.name ?? email;

    return { value: email, label };
  });
};

export const mapCreativeAgencyLabelValues = (
  filterOptions: ICreativeAgency[],
  items: string[]
): ISelectLabel[] => {
  return items.map((id: string) => {
    const label =
      filterOptions.find(({ id: itemId }) => itemId.toString() === id)?.name ??
      id;

    return { value: id, label };
  });
};

export const mapEnumLabelValues = (items: string[]): ISelectLabel[] => {
  return items.map((value: string) => {
    return { value, label: startCase(value) };
  });
};

const getDateStringValue = (value: string | null): string => {
  return value ? dayjs(value).format(RangeDateDisplayFormat) : "";
};

export const getRangeLabel = (
  startValue: string | null,
  endValue: string | null
): ISelectLabel[] => {
  let date: ISelectLabel[] = [];
  if (startValue || endValue) {
    const dateLabel = `${getDateStringValue(startValue)} - ${getDateStringValue(
      endValue
    )}`;
    date = [
      {
        label: dateLabel,
        value: dateLabel,
      },
    ];
  }
  return date;
};

export const isDateSelectedAsFilter = (
  startValue: string | null,
  endValue: string | null
): number => {
  return startValue || endValue ? 1 : 0;
};

const getLabelsFromEnum = (values: IOption[], selectedValues: string[]) => {
  return values.filter((value) => selectedValues.includes(value.value));
};

export const mapBooleanValuesIntoLabels = (
  value: boolean | null
): ISelectLabel => {
  if (value === null || value === undefined) {
    return { label: "None", value: "" };
  }

  return value ? { label: "Yes", value: "yes" } : { label: "No", value: "no" };
};

const getBooleanValueLabels = (value: boolean | null): ISelectLabel[] => {
  return [mapBooleanValuesIntoLabels(value)].filter(
    (label) => label.value !== ""
  );
};

export const getApplyLabelsFromSavedFilters = (
  selectedFilter: ISavedUserFilter,
  allProjectStatuses: IOption[],
  allProjectTypes: IOption[]
): IActiveLabels => {
  return {
    clients: parseJSONToLabel(selectedFilter.filter?.clients ?? []),
    globalBrands: parseJSONToLabel(selectedFilter.filter?.globalBrands ?? []),
    statuses: getLabelsFromEnum(
      allProjectStatuses,
      selectedFilter.filter?.statuses ?? []
    ),
    leads: parseJSONToLabel(selectedFilter.filter?.leads ?? []),
    contacts: parseContactToLabel(selectedFilter.filter?.contacts ?? []),
    creativeAgencies: parseJSONToLabel(
      selectedFilter.filter?.creativeAgencies ?? []
    ),
    holdingCompanies: parseJSONToLabel(
      selectedFilter.filter?.holdingCompanies ?? []
    ),
    musicTypes: parseStatusToLabel(selectedFilter.filter?.musicTypes ?? []),
    projectTypes: getLabelsFromEnum(
      allProjectTypes,
      selectedFilter.filter?.projectTypes ?? []
    ),
    serviceTypes: parseStatusToLabel(selectedFilter.filter?.serviceTypes ?? []),
    projectCloseDate: getRangeLabel(
      selectedFilter.filter?.projectCloseDateFrom ?? null,
      selectedFilter.filter?.projectCloseDateTo ?? null
    ),
    jobListingDate: getRangeLabel(
      selectedFilter.filter?.jobListingDateFrom ?? null,
      selectedFilter.filter?.jobListingDateTo ?? null
    ),
    projectRegions: parseStatusToLabel(
      selectedFilter.filter?.projectRegions ?? []
    ),
    officeLocations: parseStatusToLabel(
      selectedFilter.filter?.officeLocations ?? []
    ),
    copyrightInfoProcessed: getBooleanValueLabels(
      selectedFilter.filter?.copyrightInfoProcessed
    ),
    publishingRightsSigned: getBooleanValueLabels(
      selectedFilter.filter?.publishingRightsSigned
    ),
    publishingTriggered: getBooleanValueLabels(
      selectedFilter.filter?.publishingTriggered
    ),
  };
};

type ApplyFiltersFromSavedFilters = [
  string[],
  string[],
  string[],
  string[],
  string[],
  string[],
  string[],
  string[],
  string[],
  string[],
  string | null,
  string | null,
  string | null,
  string | null,
  string[],
  string[],
  boolean | null,
  boolean | null,
  boolean | null
];

export const getApplyFiltersFromSavedFilters = (
  selectedFilter: ISavedUserFilter
): ApplyFiltersFromSavedFilters => {
  return [
    parseJSONToIdValue(selectedFilter.filter?.clients ?? []),
    parseJSONToIdValue(selectedFilter.filter?.globalBrands ?? []),
    selectedFilter.filter?.statuses ?? [],
    parseJSONToIdValue(selectedFilter.filter?.leads ?? []),
    parseJSONToEmailValue(selectedFilter.filter?.contacts ?? []),
    parseJSONToIdValue(selectedFilter.filter?.creativeAgencies ?? []),
    parseJSONToIdValue(selectedFilter.filter?.holdingCompanies ?? []),
    selectedFilter.filter?.musicTypes ?? [],
    selectedFilter.filter?.projectTypes ?? [],
    selectedFilter.filter?.serviceTypes ?? [],
    selectedFilter.filter?.projectCloseDateFrom ?? null,
    selectedFilter.filter?.projectCloseDateTo ?? null,
    selectedFilter.filter?.jobListingDateFrom ?? null,
    selectedFilter.filter?.jobListingDateTo ?? null,
    selectedFilter.filter?.projectRegions ?? [],
    selectedFilter.filter?.officeLocations ?? [],
    selectedFilter.filter?.copyrightInfoProcessed ?? null,
    selectedFilter.filter?.publishingRightsSigned ?? null,
    selectedFilter.filter?.publishingTriggered ?? null,
  ];
};

export const getApplyPresetFiltersFromSavedFilters = (
  applyFilters: ApplyFiltersFromSavedFilters,
  userId: string
): [boolean, boolean, boolean] => {
  const selectedFilterLeads = applyFilters[3];
  if (!selectedFilterLeads.includes(userId)) {
    return [false, false, false];
  }
  const selectedFilterStatuses = applyFilters[2];
  const myOpenProjects = selectedFilterStatuses.includes(ProjectStatus.NewOpen);
  const myAtContractProjects = selectedFilterStatuses.includes(
    ProjectStatus.InContractInvoiced
  );
  const myClosedProjects = selectedFilterStatuses.includes(
    ProjectStatus.Complete
  );
  return [myOpenProjects, myAtContractProjects, myClosedProjects];
};

export default formatClientList;
