import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  logo: css`
    fill: ${theme.colors.background.white};
    height: 210px;
    margin-left: -2.5rem;
    margin-bottom: -3rem;
  `,
};
