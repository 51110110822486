import { UploadFile } from "antd/lib/upload/interface";
import { Exclusivity, LicenseFixedDurations } from "src/constants";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import { IFinalTrackOwnership } from "../components/music/components/final-track-ownership/interfaces";

export enum ProjectKeys {
  name = "name",
  status = "status",
  projectType = "projectType",
}
export interface IProjectDuplicateResponse {
  name: string;
  campaignName: string;
  jobListingDate: string;
  status: ProjectStatus;
  sequenceNo: string;
  id: string;
}

export enum ServicesTypes {
  MusicSearch = "MusicSearch",
  License = "License",
  LicenseRenewal = "LicenseRenewal",
  Demo = "Demo",
  Finalization = "Finalization",
  Strategy = "Strategy",
  ArtistPartnership = "ArtistPartnership",
  Other = "Other",
  BrandActivation = "BrandActivation",
  MusicManagement = "MusicManagement",
  SonicBranding = "SonicBranding",
  Adaptation = "Adaptation",
  ChannelBranding = "ChannelBranding",
  MusicValidation = "MusicValidation",
}

export enum TalentType {
  OpenForm = "OpenForm",
  TalentHub = "TalentHub",
}
export enum Contributor {
  CURATOR = "curator",
  ORIGINATOR = "originator",
  LEAD = "lead",
}

export enum TabsTitles {
  ProjectDetails = "Project Details",
  Services = "Services",
  Music = "Music",
  Licenses = "Licenses",
  Documents = "Documents",
  Contacts = "Contacts",
  Tags = "Tags",
  Subscription = "Subscription",
  LibraryTrack = "Declared Tracks",
  Publishing = "Publishing",
}

export enum MusicTypes {
  BespokeReRecord = "BespokeRerecord",
  CommercialMusic = "CommercialMusic",
  Cover = "Cover",
  CustomMusicComposition = "CustomMusicComposition",
  Enterprise = "Enterprise",
  ProductionMusic = "ProductionMusic",
  Remix = "Remix",
  SongtradrMarketplace = "SongtradrMarketplace",
}

export enum ProjectStatus {
  InProgress = "InProgress",
  NewOpen = "NewOpen",
  InContractInvoiced = "InContractInvoiced",
  OnHold = "OnHold",
  Complete = "Complete",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export enum OfficeLocation {
  Argentina = "Argentina",
  Australia = "Australia",
  Brazil = "Brazil",
  Canada = "Canada",
  France = "France",
  Germany = "Germany",
  Japan = "Japan",
  Netherlands = "Netherlands",
  Poland = "Poland",
  Singapore = "Singapore",
  Spain = "Spain",
  UnitedArabEmirates = "United Arab Emirates",
  UnitedKingdom = "United Kingdom",
  UnitedStates = "United States",
}

export enum DocumentCategories {
  PurchaseOrder = "PurchaseOrder",
  Invoice = "Invoice",
  License = "License",
  Estimate = "Estimate",
  Misc = "Misc",
  Asset = "Asset",
  TestResult = "TestResult",
  Brief = "Brief",
}

export interface ILeadDropdownProps {
  label?: string;
  value?: string;
  email?: string;
}

export interface IFinalTrackSection extends IFinalTrackOwnership {
  id: string;
  title: string;
  artistContacts?: IContactReferences[];
  writerContacts?: IContactReferences[];
  musicType?: string;
  assets?: INameLinkState[];
  finalTrackAttachments?: IProjectAttachment[];
  finalTrackLinksVersion?: number;
}

export interface IFinalTrackSectionWithOrgId extends IFinalTrackSection {
  organisationId: string;
}

export interface IProjectMusicSection {
  finalTrack: IFinalTrackSection;
  musicAssets: INameLinkState[];
  finalVideo: INameLinkState[];
  musicAssetsVersion: number;
  finalVideoVersion: number;
}

export interface IBadgeCounter {
  numberOfTracks?: number;
  numberOfServices?: number;
  numberOfDocuments?: number;
  numberOfContacts?: number;
  numberOfLicenses?: number;
}

export interface IProjectClient {
  id?: string;
  name?: string;
  holdingCompany?: {
    name?: string;
    id?: string;
  };
}

export interface IProjectLead {
  id: string;
  name: string;
  email?: string;
}

export interface IProjectDetailsSection {
  name?: string;
  leads?: IProjectLead[];
  status?: ProjectStatus;
  product?: string;
  category?: string;
  earnings?: IMonetaryValue;
  contractEndDate?: string;
  projectType?: ServicesTypes;
  musicType?: string;
  musicBudget?: IMonetaryValue;
  contractStartDate?: string;
  brief?: string;
  description?: string;
  briefAttachments?: IProjectAttachment[];
  campaignName?: string;
  crmDealId?: string;
  projectCloseDate?: string;
  estimatedProjectClosedDate?: string;
  internalCreativeTeamEngaged?: boolean;
  jobListingDate?: string;
  isExtension?: boolean;
  relatedProjects?: IFormRelatedProject[];
  relatedProjectsVersion?: number;
  creativeAgencyInvolved?: boolean;
  creativeAgencyId?: string | number;
  creativeAgencyName?: string;
  client?: IProjectClient;
  clientReferenceNo?: string;
  projectRegion?: string;
}

export interface IProjectTerm {
  id: string;
  name: string;
  type: string;
  mediaType: string[];
  customMediaType: string[];
  territories: ITerritory;
  startDate?: string;
  endDate?: string;
  fixedDuration?: LicenseFixedDurations;
  exclusivity?: Exclusivity;
  notes: string;
  lastUpdated: string;
  lastUpdatedBy: string;
  version: number;
  scripts: IScript[];
  hasUnlimitedScripts: boolean;
  finalTracks: IFinalTracks[];
  appliesToAllFinalTracks: boolean;
  fee: IMonetaryValue;
  quote: IMonetaryValue;
  lyricChange: {
    checked: boolean | null;
    notes: string;
  };
  optionToExtend: string;
  onlineArchival: boolean | null;
  mediaTypeNote: string;
  territoriesNote: string;
  scriptNotes: string;
}
export interface IFinalTracks {
  id: string;
  title: string;
}
export interface IScript {
  name: string;
  details: string;
  duration: number;
  includesCutdowns: boolean;
}

interface IPublishingAndCopyrightData {
  checked: boolean;
  notes: string;
}

export interface IPublishingTriggeredBy {
  id: string;
  name: string;
  email: string;
  atDateTime: string;
}

export interface IProjectForm {
  [key: string]:
    | string
    | ProjectStatus
    | number
    | IFinalTrackSection
    | IProjectDetailsSection
    | IProjectMusicSection
    | IMonetaryValue
    | IProjectAttachment[]
    | IService[]
    | IProjectTerm[]
    | IFormRelatedProject[]
    | IFormContacts[]
    | IClientContact
    | IProjectTag[]
    | IFinalTrackSection[]
    | IFormContactsInvoices[]
    | IPublishingAndCopyrightData
    | IPublishingTriggeredBy
    | boolean
    | string[]
    | undefined;
  name?: string;
  leadsVersion: number;
  leads?: {
    id: string;
    name: string;
    email?: string;
  }[];
  id: string;
  originators?: string[];
  curators?: string[];
  status?: ProjectStatus;
  clientsVersion: number;
  product?: string;
  regionalBrand?: IRegionalBrand;
  productDivision?: IProductDivision;
  globalBrand?: IGlobalBrand;
  category?: string;
  earnings?: IMonetaryValue;
  musicType?: string;
  musicBudget?: IMonetaryValue;
  contractStartDate?: string;
  estimatedProjectClosedDate?: string;
  contractEndDate?: string;
  version: number;
  projectType: string;
  music: IProjectMusicSection;
  contacts: IFormContacts[];
  invoiceeContacts: IFormContactsInvoices[];
  invoicee: IFormInvoicee;
  contactsVersion: number;
  sequenceNo: number;
  organisationId: string;
  attachments: IProjectAttachment[];
  brief?: string;
  briefAttachments?: IProjectAttachment[];
  purchaseOrderAttachments: IProjectAttachment[];
  estimateAttachments: IProjectAttachment[];
  licenseAttachments: IProjectAttachment[];
  invoiceAttachments: IProjectAttachment[];
  miscAttachments: IProjectAttachment[];
  assetAttachments: IProjectAttachment[];
  testResultAttachments: IProjectAttachment[];
  description?: string;
  services: IService[];
  terms: IProjectTerm[];
  sharedWithClient?: boolean;
  campaignName: string;
  crmDealId: string;
  projectCloseDate: string;
  internalCreativeTeamEngaged: boolean;
  jobListingDate: string;
  isExtension: boolean;
  relatedProjects: IFormRelatedProject[];
  relatedProjectsVersion?: number;
  creativeAgencyInvolved?: boolean;
  creativeAgencyId?: string | number;
  creativeAgencyName?: string;
  client?: IProjectClient;
  clientReferenceNo?: string;
  clientContact?: IClientContact;
  projectRegion?: string;
  initialMusicQuote?: IMonetaryValue;
  costAvoidance?: IMonetaryValue;
  tags?: IProjectTag[];
  tagVersion?: number;
  tracks?: IFinalTrackSection[];
  libraryTracks?: IProjectLibraryTrackDetails[];
  officeLocation?: string;
  totalAmount?: IMonetaryValue;
  publishing?: IPublishingAndCopyrightData;
  copyright?: IPublishingAndCopyrightData;
  format: string;
  publishingTriggeredBy?: IPublishingTriggeredBy;
}

export interface IProjectHeaderData {
  sharedWithClient?: boolean;
  sequenceNo?: number;
}

export interface IProjectPublishingData {
  internalCreativeTeamEngaged?: boolean;
  publishing?: IPublishingAndCopyrightData;
  copyright?: IPublishingAndCopyrightData;
}

export interface ILeads {
  id: string;
  name: string;
  email?: string;
}
export interface IProjectDetailsData {
  name?: string;
  leads?: ILeads[];
  status?: ProjectStatus;
  earnings?: IMonetaryValue;
  musicBudget?: IMonetaryValue;
  estimatedProjectClosedDate?: string;
  description?: string;
  campaignName?: string;
  crmDealId?: string;
  projectCloseDate?: string;
  jobListingDate?: string;
  isExtension?: boolean;
  relatedProjects?: IFormRelatedProject[];
  creativeAgencyInvolved?: boolean;
  creativeAgencyId?: string | number;
  creativeAgencyName?: string;
  creativeAgencyOffice?: string;
  clientReferenceNo?: string;
  projectRegion?: string;
  costAvoidance?: IMonetaryValue;
  initialMusicQuote?: IMonetaryValue;
  briefAttachments?: IProjectAttachment[];
  totalAmount?: IMonetaryValue;
  officeLocation?: string;
  format: string;
  curators?: ILeads[];
  originators?: ILeads[];
}

export interface IProjectSubscriptionDetails {
  contractStartDate?: string;
  contractEndDate?: string;
  description?: string;
}

export interface ILibraryTrackDetailsChannel {
  type: string;
  usageLinks: string[];
  id: string;
  name: string;
  url: string;
}

interface ILibraryTrackUserData {
  id: string;
  email: string;
  name: string;
}

export interface IProjectLibraryTrackDetails {
  id: string;
  projectId: string;
  trackId: string;
  trackTitle: string;
  trackArtist: string;
  downloadedByUser: ILibraryTrackUserData;
  declaredByUser: ILibraryTrackUserData;
  goLiveDate: string;
  projectName: string;
  projectDescription: string;
  businessBrand: string;
  regions: string[];
  channels: ILibraryTrackDetailsChannel[];
  createdDate: string;
}

export interface IProjectProductAndAccountInformationData {
  regionalBrand?: IRegionalBrand;
  productDivision?: IProductDivision;
  globalBrand?: IGlobalBrand;
  client?: IProjectClient;
  clientContact?: IClientContact;
  product?: string;
}

export interface IProjectOtherAssetsData {
  finalTrack?: IFinalTrackSection;
  musicAssets?: INameLinkState[];
  finalVideo?: INameLinkState[];
  musicAssetsVersion?: number;
  finalVideoVersion?: number;
}

export interface IProjectTag {
  tagName: string;
}

export interface ITrackOwnershipLabelConfig {
  totalFeeLabel: string;
  nameLabel: string;
  emailLabel: string;
}

export interface IFormRelatedProject {
  isNew?: boolean;
  key?: string;
  index?: string;
  operation?: string;
  link: string;
}

export interface ICreativeAgency {
  id: string | number;
  name: string;
  organisationId?: string;
}

export interface ISelectValue {
  id: number;
  name: string;
}

export interface IGlobalBrand {
  id: number | string;
  name: string;
}

export interface IProductDivision {
  id: number | string;
  name: string;
}

export interface IRegionalBrand {
  id: number | string;
  name: string;
}

export interface IRelatedProjects {
  link: string;
  operation?: string;
  index?: number;
}

export interface IRelatedProjectsState extends IRelatedProjects {
  isNew: boolean;
  key: string;
}

export interface IFormContacts {
  key?: string;
  index?: string;
  operation?: string;
  name?: string;
  email?: string;
  contactType?: string;
  companyName?: string;
}

export interface IFormContactsInvoices {
  key?: string;
  index?: string;
  operation?: string;
  name?: string;
  email?: string;
}

export interface IFormInvoicee {
  name: string;
  address: string;
  country: string;
  po: string;
  jobNo: string;
}

export interface IClient {
  isNew?: boolean;
  key?: string;
  index?: string;
  operation?: string;
  name?: string;
  id?: string;
  holdingCompany?: {
    name?: string;
    id?: string;
  };
}

export interface IClientContact {
  id: string;
  name: string;
  email: string;
}
export interface IContactReferences extends IProjectTalents {
  agencyWritingPartnership: string;
  groupByAgency: boolean;
  ipi?: string;
}
export interface IFeeSplitDetail {
  contactReferences?: IContactReferences[];
  split?: string;
  paythrough?: boolean;
  taxWithholdingFee?: IMonetaryValue;
  bankFee?: IMonetaryValue;
}

export interface IFeeSplitDetailState extends IFeeSplitDetail {
  isNew: boolean;
  key: string;
}

export interface INameLink {
  name?: string;
  link?: string;
}

export interface INameLinkState extends INameLink {
  isNew: boolean;
  key: string;
}

export interface IAdditionalContact {
  name?: string;
  email?: string;
  contactType?: string;
}

export interface IAdditionalContactState extends IAdditionalContact {
  isNew: boolean;
  key: string;
}

export interface IProjectRouteParams {
  id: string;
}

export interface IProjectSharePublicParams {
  shareId: string;
  projectId: string;
}

export interface IProjectUpdateResponse {
  version: number;
}

export type ProjectUpdateObject = {
  [key in ProjectKeys]?: string;
};

export interface IProjectDetailsUpdateRequest extends ProjectUpdateObject {
  version: number;
  organisationId: string;
}

export interface INameLinkPatchRequest extends INameLink {
  operation: string;
  index: number;
}

export interface IProjectAttachment extends UploadFile {
  id: string;
  name: string;
  contentType: string;
  attachmentType: string;
  documentCategory?: string;
  uploaded: {
    userId: string;
    userFullname: string;
    dateTime: string;
  };
  percent: number;
  type: string;
  uid: string;
  size: number;
}

export interface IPresignedDownloadUrlResponse {
  data: { url: string };
  status: number;
}
export interface IProjectCreateRequest {
  name: string;
  status: string;
  organisationId: string;
}
export interface IProjectCreateResponse {
  id: string;
  name: string;
  status: string;
  sequenceNo: string;
}

export interface IProjectEmails {
  publishingTeamEmails: string[];
}

export interface IProjectAttachmentsUpdate {
  attachments: IProjectAttachment[];
  projectVersion: number;
}

export interface IProjectServicesUpdate {
  services: IService[];
  projectVersion: number;
}

export interface IProjectTracksUpdate {
  tracks: IFinalTrackSection[];
  projectVersion: number;
}

export interface IProjectTermsUpdate {
  terms: IProjectTerm[];
  projectVersion: number;
}

export interface IProjectPublishingTriggeredBy {
  publishingTriggeredBy?: IPublishingTriggeredBy;
  projectVersion: number;
}

export interface ITerritory {
  worldwide: boolean;
  excludedCountries: string[];
  includedCountries: string[];
}

export interface ICreateTermRequest {
  name: string;
  type: string;
  mediaType?: string[];
  customMediaType?: string[];
  territories?: ITerritory;
  startDate?: string;
  endDate?: string;
  fixedDuration?: string;
  hasUnlimitedScripts: boolean;
  scripts: IScript[];
  exclusivity?: string;
  notes?: string;
  organisationId: string;
}

export interface ICreateTermResponse {
  id: string;
  version: number;
}

export interface IUpdateTermRequest extends ICreateTermRequest {
  id: string;
  version: number;
  organisationId: string;
}

export interface IUpdateTermResponse {
  data: {
    id: string;
    version: number;
    updateAction: string;
  };
  status: number;
}

export interface ITab {
  tabTitle: string;
  content: JSX.Element;
  hashLink?: boolean;
}

export interface IProjectTalents {
  type: TalentType;
  id?: string;
  name: string;
  email?: string;
}
export interface IThirdParty {
  thirdParty?: {
    id: string;
    name: string;
  };
  thirdPartyContacts?: IContactReferences[];
  thirdPartyFee?: IMonetaryValue;
  paythrough?: boolean;
  taxWithholdingFee?: IMonetaryValue;
  bankFee?: IMonetaryValue;
}

export interface IService {
  created?: string;
  createdBy?: string;
  feeAmount?: IMonetaryValue;
  taxWithholdingFee?: IMonetaryValue;
  bankFee?: IMonetaryValue;
  asset?: {
    name?: string;
    link?: string;
  };
  thirdPartyInvolved?: boolean;
  thirdParties?: IThirdParty[];
  id: string;
  lastUpdated?: string;
  lastUpdatedBy?: string;
  musicType?: string;
  serviceType?: string;
  version?: number;
  serviceProvider?: IContactReferences;
}

export interface ICreateServiceRequest {
  musicType?: string;
  serviceType?: string;
  serviceName?: string;
  serviceUrl?: string;
  thirdPartyInvolved?: boolean;
  feeAmount?: IMonetaryValue;
  taxWithholdingFee?: IMonetaryValue;
  bankFee?: IMonetaryValue;
  organisationId: string;
}

export interface ICreateServiceResponse {
  id: string;
  version: number;
}
