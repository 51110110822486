import { getIsMobile } from "@songtradr/spa-common";
import { Typography } from "antd";
import { cloneDeep } from "lodash";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAuth from "src/auth/use-auth";
import FileUploader from "src/components/file-upload";
import { checkFilesCurrentlyUploading } from "src/components/file-upload/helpers";
import { IDeleteSelectedFile } from "src/components/file-upload/interfaces";
import MobileDrawer from "src/components/mobile-drawer";
import { AttachmentType } from "src/constants";
import {
  IProjectAttachment,
  IProjectOtherAssetsData,
  IProjectRouteParams,
} from "src/pages/projects/project/interfaces";
import styles from "src/pages/projects/project/styles";
import { IProjectUploadFile } from "../../../details/components/interfaces";
import {
  FileDrawerTypes,
  generateDrawer,
} from "../../../documents/components/mobile-drawers";
import {
  DocumentsTableData,
  DocumentsTableColumns,
} from "../../../documents/table-data";
import NameLinks, { INameLinkConfig } from "../name-links";

interface IProps {
  projectOtherAssets: IProjectOtherAssetsData;
  updateProjectOtherAssets: (
    projectOtherAssets: IProjectOtherAssetsData
  ) => void;
  finalVideoAssetsAttachments: IProjectAttachment[];
}

const FinalVideo = ({
  projectOtherAssets,
  finalVideoAssetsAttachments,
  updateProjectOtherAssets,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const { getAccessToken, organisationId } = useAuth();
  const [finalVideoAssetsFileList, setFinalVideoAssetsFileList] = useState<
    IProjectUploadFile[]
  >([]);

  const handleSetFinalVideoAssetsFileList = useCallback(
    (list: IProjectUploadFile[]) => {
      setFinalVideoAssetsFileList(list);
    },
    []
  );

  const [accessToken, setAccessToken] = useState("");
  const { id: projectId } = useParams<IProjectRouteParams>();
  const [
    selectedFileToDelete,
    setSelectedFileToDelete,
  ] = useState<IDeleteSelectedFile>();

  const handleSetSelectedFileToDelete = useCallback(
    (file: IDeleteSelectedFile | undefined) => {
      setSelectedFileToDelete(file);
    },
    []
  );
  const [showFileUploadDeleteModal, setShowFileUploadDeleteModal] = useState(
    false
  );
  const handleShowFileUploadDeleteModal = useCallback((show: boolean) => {
    setShowFileUploadDeleteModal(show);
  }, []);

  const [isDrawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [activeFile, setActiveFile] = useState<IProjectUploadFile>();
  const handleSetActiveFile = useCallback(
    (file: IProjectUploadFile | undefined) => {
      setActiveFile(file);
    },
    []
  );
  const [activeDrawer, setActiveDrawer] = useState<FileDrawerTypes>(
    FileDrawerTypes.FileInfoDrawer
  );
  const handleSetActiveDrawer = useCallback((drawerType: FileDrawerTypes) => {
    setActiveDrawer(drawerType);
  }, []);

  const toggleDrawer = useCallback(() => {
    setDrawerVisible((state) => !state);
  }, []);

  useEffect(() => {
    if (!checkFilesCurrentlyUploading(finalVideoAssetsFileList)) {
      setFinalVideoAssetsFileList(cloneDeep(finalVideoAssetsAttachments));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void (() => {
      const userAccessToken: string = getAccessToken();
      setAccessToken(userAccessToken);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileUploader = (
    <FileUploader
      attachmentType={AttachmentType.VideoAsset}
      invalidFileSizeErrorMessage={t("File size exceeds maximum limit of 2GB")}
      existingFiles={finalVideoAssetsFileList}
      setFileList={handleSetFinalVideoAssetsFileList}
      tableData={DocumentsTableData({
        filesList: finalVideoAssetsFileList,
        accessToken,
        projectId,
        organisationId,
        setShowModal: handleShowFileUploadDeleteModal,
        setSelectedFileToDelete: handleSetSelectedFileToDelete,
        toggleDrawer,
        setActiveDrawer: handleSetActiveDrawer,
        setActiveFile: handleSetActiveFile,
      })}
      selectedFileToDelete={selectedFileToDelete}
      tableColumns={DocumentsTableColumns(isMobile, t)}
      showModal={showFileUploadDeleteModal}
      setShowModal={handleShowFileUploadDeleteModal}
    />
  );

  const config: INameLinkConfig = {
    fieldName: "finalVideo",
    nameLink: "finalVideo",
    nameLinkVersion: "finalVideoVersion",
    testDataId: "final-video",
    nameHelpTextTranslationKey: "ProjectsPage##musicSection##Final video name",
    sectionHeaderTranslationKey:
      "ProjectsPage##musicSection##Final video asset",
    buttonLabel: "ProjectsPage##musicSection##addAnotherFinalVideoURL",
    additionalText:
      "ProjectsPage##musicSection##If you don't have a local copy of the asset and it exists on digital platform, you can paste the link below",
    additionalHeader: "ProjectsPage##musicSection##Final video asset URL",
    customLinkText: "ProjectsPage##musicSection##Final video URL",
  };

  return (
    <div css={styles.formItemContainer}>
      <div>
        <div css={styles.basicInputContainer}>
          <Typography.Text css={styles.basicInputLabel}>
            {t("ProjectsPage##musicSection##Video Assets")}
          </Typography.Text>
        </div>
        <NameLinks
          fileUploader={fileUploader}
          config={config}
          projectOtherAssets={projectOtherAssets}
          updateProjectOtherAssets={updateProjectOtherAssets}
        />
      </div>
      {isMobile && (
        <MobileDrawer
          placement="bottom"
          onClose={toggleDrawer}
          open={isDrawerVisible}
          key="bottom"
          closable={false}
          data-testid="mobile-drawer-container"
          className="documents-drawer-container"
        >
          {generateDrawer(
            activeDrawer,
            handleSetActiveDrawer,
            toggleDrawer,
            accessToken,
            projectId,
            organisationId,
            handleSetFinalVideoAssetsFileList,
            finalVideoAssetsFileList,
            handleShowFileUploadDeleteModal,
            selectedFileToDelete,
            activeFile
          )}
        </MobileDrawer>
      )}
    </div>
  );
};

export default FinalVideo;
