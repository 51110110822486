import { Menu, Typography } from "antd";
import React, { memo, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DownloadIcon, DeleteIcon } from "src/app/assets/icons/component-icons";
import STLoadingLogo from "src/components/st-loading-logo";
import { debounce } from "lodash";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import deleteDrawerStyles from "src/pages/projects/project/components/details/styles";
import { IDeleteSelectedFile } from "src/components/file-upload/interfaces";
import { IContactUploadFile } from "src/pages/contact/interfaces";
import { handleDeleteFile, handleFileDownload } from "../../helpers";
import styles from "./styles";

export enum FileDrawerTypes {
  FileInfoDrawer = "FileInfoDrawer",
  FileDeleteDrawer = "FileDeleteDrawer",
}
interface IFileInfoDrawer {
  setActiveDrawer: (drawerType: FileDrawerTypes) => void;
  activeFile?: IContactUploadFile;
  toggleDrawer: () => void;
  accessToken: string;
  contactId: string;
  organisationId: string;
}

interface IDeleteFileDrawer {
  activeFile?: IContactUploadFile;
  toggleDrawer: () => void;
  setFileList: (files: IContactUploadFile[]) => void;
  fileList: IContactUploadFile[];
  setShowFileUploadDeleteModal: (show: boolean) => void;
  accessToken: string;
  contactId: string;
  organisationId: string;
  selectedFileToDelete?: IDeleteSelectedFile;
}

const FileInfoDrawer = memo(
  ({
    setActiveDrawer,
    activeFile,
    toggleDrawer,
    accessToken,
    contactId,
    organisationId,
  }: IFileInfoDrawer) => {
    const { t } = useTranslation();

    const setDeleteDrawer = () => {
      setActiveDrawer(FileDrawerTypes.FileDeleteDrawer);
    };

    const downloadFile = debounce(async (): Promise<void> => {
      if (activeFile) {
        SuccessToast(t("File downloading to your file storage"));
        toggleDrawer();

        const downloadFileRequest = await handleFileDownload(
          accessToken,
          activeFile,
          contactId,
          organisationId
        );

        if (!downloadFileRequest) {
          ErrorToast(
            `FileUploadErrorToast${activeFile.uid}`,
            t("There was a problem downloading the file")
          );
        }
      }
    }, 500);

    if (activeFile) {
      return (
        <Menu css={styles.infoDrawerContainer}>
          <Menu.Item key="o">
            <button
              onClick={downloadFile}
              type="button"
              aria-label={t("Download")}
            >
              <DownloadIcon />
              <span>{t("Download")}</span>
            </button>
          </Menu.Item>
          <Menu.Item key="1">
            <button
              onClick={setDeleteDrawer}
              type="button"
              aria-label={t("Delete")}
            >
              <DeleteIcon />
              <span>{t("Delete")}</span>
            </button>
          </Menu.Item>
        </Menu>
      );
    }

    return <STLoadingLogo />;
  }
);

const DeleteFileDrawer = memo(
  ({
    activeFile,
    toggleDrawer,
    fileList,
    setShowFileUploadDeleteModal,
    accessToken,
    contactId,
    organisationId,
    setFileList,
    selectedFileToDelete,
  }: IDeleteFileDrawer) => {
    const { t } = useTranslation();

    const handleDelete = useCallback(async () => {
      if (selectedFileToDelete) {
        toggleDrawer();
        await handleDeleteFile(
          setFileList,
          fileList,
          setShowFileUploadDeleteModal,
          t,
          selectedFileToDelete.index,
          accessToken,
          contactId,
          organisationId,
          activeFile
        );
      }
    }, [
      selectedFileToDelete,
      toggleDrawer,
      setFileList,
      fileList,
      setShowFileUploadDeleteModal,
      t,
      accessToken,
      contactId,
      organisationId,
      activeFile,
    ]);

    if (activeFile) {
      return (
        <div data-testid="delete-attachment-drawer">
          <div css={deleteDrawerStyles.drawerContentContainer}>
            <Typography.Title css={deleteDrawerStyles.drawerHeaderText}>
              {t("deleteClient##Are you sure?")}
            </Typography.Title>
            <div>
              <Typography.Text>
                <Trans i18nKey="ProjectsPage##confirmationPrompt">
                  {{ attachmentName: activeFile.name }}
                </Trans>
              </Typography.Text>
            </div>
          </div>
          <div css={deleteDrawerStyles.drawerButtonContainer}>
            <button
              type="button"
              css={deleteDrawerStyles.mobileButtonContainerCancel}
              onClick={toggleDrawer}
              aria-label={t("Cancel")}
            >
              <div css={deleteDrawerStyles.iconContainer}>{t("Cancel")}</div>
            </button>
            <button
              aria-label={t("Delete")}
              type="button"
              css={deleteDrawerStyles.mobileButtonContainerPrimary}
              onClick={handleDelete}
              data-testid="delete-attachment-drawer-button"
            >
              <div css={deleteDrawerStyles.iconContainer}>{t("Delete")}</div>
            </button>
          </div>
        </div>
      );
    }

    return <STLoadingLogo />;
  }
);

export const generateDrawer = (
  drawerType: FileDrawerTypes,
  setActiveDrawer: (drawerType: FileDrawerTypes) => void,
  toggleDrawer: () => void,
  accessToken: string,
  contactId: string,
  organisationId: string,
  setFileList: (files: IContactUploadFile[]) => void,
  fileList: IContactUploadFile[],
  setShowFileUploadDeleteModal: (show: boolean) => void,
  selectedFileToDelete?: IDeleteSelectedFile,
  activeFile?: IContactUploadFile
): JSX.Element => {
  switch (drawerType) {
    case FileDrawerTypes.FileDeleteDrawer:
      return (
        <DeleteFileDrawer
          activeFile={activeFile}
          toggleDrawer={toggleDrawer}
          setFileList={setFileList}
          fileList={fileList}
          setShowFileUploadDeleteModal={setShowFileUploadDeleteModal}
          accessToken={accessToken}
          contactId={contactId}
          organisationId={organisationId}
          selectedFileToDelete={selectedFileToDelete}
        />
      );
    case FileDrawerTypes.FileInfoDrawer:
    default:
      return (
        <FileInfoDrawer
          setActiveDrawer={setActiveDrawer}
          activeFile={activeFile}
          toggleDrawer={toggleDrawer}
          accessToken={accessToken}
          contactId={contactId}
          organisationId={organisationId}
        />
      );
  }
};
