import axios, { AxiosResponse } from "axios";
import config from "src/config";
import getAuthHeader from "../headers/get-auth-header";
import getJsonContentHeader from "../headers/get-json-content-header";
import { IUpdateAttachmentRequest } from "./interfaces";

interface IProps {
  attachmentId: string;
  projectId: string;
  accessToken: string;
  request: IUpdateAttachmentRequest;
}

async function updateAttachment({
  attachmentId,
  projectId,
  accessToken,
  request,
}: IProps): Promise<AxiosResponse> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  return axios.patch(
    `${config.projectManagementService.url}/projects/${projectId}/attachments/${attachmentId}`,
    request,
    options
  );
}

export default updateAttachment;
