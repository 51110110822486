import React, { ReactElement, useEffect, useState } from "react";
import styles from "src/pages/select-org/styles";
import {
  GraphicLeft,
  GraphicRight,
} from "src/app/assets/icons/component-icons";
import useAuth from "src/auth/use-auth";
import selfOrganisations, {
  IOrganisationSearchResponse,
} from "src/api/organisation/self";
import { ErrorToast } from "src/components/toast-notification";
import { Select } from "antd";
import Logo from "../login/assets/images/login-logo.svg";

interface IOption {
  value: string;
  label: string;
}

const SelectOrg = (): ReactElement => {
  const [allOrganisations, setAllOrganisations] = useState<IOption[] | []>([]);
  const [isLoadingOrgs, setIsLoadingOrgs] = useState<boolean>(true);
  const { fetchWrapper, organisationId, switchOrg } = useAuth();

  useEffect(() => {
    const fetchOrganisations = async () => {
      const response = await fetchWrapper<IOrganisationSearchResponse | null>(
        selfOrganisations
      );
      return response;
    };

    fetchOrganisations()
      .then((response) => {
        setIsLoadingOrgs(false);
        if (response && response.items) {
          setAllOrganisations(
            response.items.map((org) => {
              return {
                value: org.id,
                label: org.name,
              };
            })
          );
        }
      })
      .catch(() => {
        setIsLoadingOrgs(false);
        ErrorToast(
          "select-org-fetch-org-error",
          "There was a problem retrieving organisations data. Please try again."
        );
      });
  }, [fetchWrapper]);

  return (
    <div css={styles.container}>
      <div css={styles.graphicLeft}>
        <GraphicLeft />
      </div>
      <div css={styles.mainContainer}>
        <div css={styles.loginContainer}>
          <img css={styles.logo} src={Logo} alt="Songtradr Logo" />
          <h2 css={styles.title}>Select organisation</h2>
          <Select
            showSearch
            filterOption={(input: string, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            value={isLoadingOrgs ? "" : organisationId}
            loading={isLoadingOrgs}
            data-testid="org-select"
            placeholder="Select"
            css={styles.selectionInput}
            onChange={async (data: string) => {
              await switchOrg(data);
            }}
            options={allOrganisations}
          />

          <div>
            Your user is permitted to login to multiple organisations, select
            one from the list above. If you change your mind at a later time you
            can switch the current organisation from the Profile page.
          </div>
        </div>
      </div>
      <div css={styles.graphicRight}>
        <GraphicRight />
      </div>
    </div>
  );
};

export default SelectOrg;
