import { SerializedStyles, css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  dropDownWrapper: css`
    position: relative;
  `,
  mainDropDownContainer: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.primary};
    margin-bottom: 0.5rem;
    padding: 1rem 0.6rem 1rem 1rem;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    height: 56px;
    max-width: 100%;
    outline: none;
    white-space: nowrap;
    background-color: ${theme.colors.white};
    position: relative;
    cursor: pointer;
  `,
  iconButton: css`
    background-color: ${theme.colors.white};
    border: none;
    :hover {
      cursor: pointer;
    }
  `,
  buttonDivider: css`
    margin: 1rem 0;
    width: 100%;
  `,
  bodyAndFooterContainer: css`
    display: flex;
    flex-direction: column;
    height: 90%;
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    font-size: 16px;
    :hover {
      font-weight: bold;
      cursor: pointer;
    }
    button {
      padding: 0 !important;
      margin: 0 !important;
    }
  `,
  buttonContainerClearAll: css`
    display: flex;
    font-size: 0.9rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 0;
    padding: 0;
    background-color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    border: 0;
    color: ${theme.colors.primary.blue};
  `,
  mainDropDownBody(width: number): SerializedStyles {
    return css`
      position: absolute;
      width: 300px;
      background: ${theme.colors.white};
      z-index: 3000;
      max-width: 470px !important;
      transform: translate(-${width}px, 0);
      height: 353px;
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0 16px 32px rgba(0, 0, 0, 0.5);
      @media ${queries.large} {
        width: 470px;
      }
    `;
  },

  gradientContainer: css`
    display: flex;
    padding: 1rem;
    justify-content: flex-end;
    height: 30px;
  `,
  dropDownFooter: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    column-gap: 1rem;
  `,
  labelContainer: css`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    color: #111;
    font-size: 16px;
    font-family: ${theme.fonts.sofiaPro};
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  contentContainer: css`
    overflow: auto;
    flex: 1;
    padding: 1rem;
  `,
};
