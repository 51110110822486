import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import { Dropdown, Typography } from "antd";
import { debounce } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "src/pages/projects/project/components/details/styles";
import MenuItem from "src/components/menu-item";
import dayjs from "dayjs";
import { IDeleteSelectedFile } from "src/components/file-upload/interfaces";
import { SuccessToast, ErrorToast } from "src/components/toast-notification";
import MoreInfoButton from "src/components/control-buttons/more-info-button";
import useAuth from "src/auth/use-auth";
import { IContactUploadFile } from "src/pages/contact/interfaces";
import { FileDrawerTypes } from "src/pages/projects/project/components/documents/components/mobile-drawers";
import { IFileDataSource } from "src/pages/projects/project/components/documents/table-data";
import { handleFileDownload, handleDeleteClick } from "../helpers";

interface IProps {
  filesList: IContactUploadFile[];
  accessToken: string;
  contactId: string;
  organisationId: string;
  setShowModal: (show: boolean) => void;
  setSelectedFileToDelete: (file: IDeleteSelectedFile | undefined) => void;
  toggleDrawer: () => void;
  setActiveDrawer: (drawerType: FileDrawerTypes) => void;
  setActiveFile: (file: IContactUploadFile | undefined) => void;
}

const DocumentsTableData = ({
  filesList,
  accessToken,
  contactId,
  organisationId,
  setShowModal,
  setSelectedFileToDelete,
  toggleDrawer,
  setActiveDrawer,
  setActiveFile,
}: IProps): Array<IFileDataSource> => {
  const { t } = useTranslation();
  const { isSessionValid } = useAuth();
  const isMobile = getIsMobile();
  useWindowSize();
  const downloadFile = debounce(
    async (
      file: IContactUploadFile,
      isMenuItemDownload = true
    ): Promise<void> => {
      if (isMenuItemDownload) {
        SuccessToast(t("File downloading to your file storage"));
      }
      const isSession = await isSessionValid();
      if (!isSession) return;
      const downloadFileRequest = await handleFileDownload(
        accessToken,
        file,
        contactId,
        organisationId,
        isMenuItemDownload
      );

      if (!downloadFileRequest) {
        ErrorToast(
          `FileUploadErrorToast${file.uid}`,
          t("There was a problem downloading the file")
        );
      }
    },
    500
  );

  return filesList.map(
    (file: IContactUploadFile, index: number): IFileDataSource => {
      const uploadedDate = dayjs(file.uploaded?.dateTime)
        .format("MMM D, YYYY [at] h:mma")
        .toString();

      const DropdownMenu = () => {
        const items = [
          {
            label: (
              <MenuItem
                ariaLabel="Download"
                buttonLabel="Download"
                customStyles={styles.actionButton}
                onClick={async () => {
                  await downloadFile(file);
                }}
              />
            ),
            key: "download",
          },
          {
            label: (
              <MenuItem
                ariaLabel="Delete"
                buttonLabel="Delete"
                customStyles={styles.actionButton}
                onClick={() => {
                  setSelectedFileToDelete({ index, file });
                  handleDeleteClick(setShowModal);
                }}
              />
            ),
            key: "delete",
          },
        ];

        return (
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode}
            placement="bottomRight"
          >
            <MoreInfoButton
              showTooltip
              customStyles={styles.moreInfoButton}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        );
      };

      if (isMobile) {
        return {
          key: `uploadTableRow${file.uid}`,
          fileInfo: (
            <div css={styles.fileUploadMobile}>
              <Typography.Title>{t("ProjectsPage##Name")}</Typography.Title>
              <Typography.Text>{file.name}</Typography.Text>
              <Typography.Title>
                {t("ProjectsPage##Uploaded by")}
              </Typography.Title>
              <Typography.Text>{file.createdByName}</Typography.Text>
              <Typography.Title>
                {t("ProjectsPage##Date uploaded")}
              </Typography.Title>
              <Typography.Text>{uploadedDate}</Typography.Text>
            </div>
          ),
          menu: (
            <div css={styles.mobileMenuContainer}>
              <MoreInfoButton
                showTooltip
                customStyles={styles.moreInfoButton}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  setActiveDrawer(FileDrawerTypes.FileInfoDrawer);
                  setSelectedFileToDelete({ index, file });
                  setActiveFile(file);
                  toggleDrawer();
                }}
              />
            </div>
          ),
        };
      }

      return {
        key: `uploadTableRow${file.uid}`,
        name: file.name,
        uploader: file.createdByName || "",
        date: uploadedDate || "",
        menu: <DropdownMenu />,
        handleRowDownloadClick: () => downloadFile(file, false),
      };
    }
  );
};

export default DocumentsTableData;
