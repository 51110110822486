import React, { ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Form, Typography } from "antd";
import StyledButton from "src/components/button";
import styles from "src/components/modals/styles";
import { IOption } from "src/pages/projects/project/components/details/components/creative-agency-row";

interface IProps {
  handleDeleteCreativeAgency: (option: IOption) => void;
  setIsModalOpen: (boolean: boolean) => void;
  creativeAgencyOption?: IOption;
}

const Content = ({
  creativeAgencyOption,
  handleDeleteCreativeAgency,
  setIsModalOpen,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    if (creativeAgencyOption) {
      handleDeleteCreativeAgency(creativeAgencyOption);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const labelTranslationKey = "deleteCreativeAgency##Delete creative agency";
  return (
    <div>
      <Typography.Title css={styles.modalTitle}>
        {t("deleteCreativeAgency##Are you sure")}
      </Typography.Title>
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <p>
          <Trans i18nKey="deleteCreativeAgency##confirmationPrompt">
            {{ creativeAgency: creativeAgencyOption?.label }}
          </Trans>
        </p>
        <div css={styles.footer}>
          <StyledButton
            ariaLabel={t("Cancel")}
            type="secondary"
            onClick={closeModal}
          >
            {t("Cancel")}
          </StyledButton>
          <StyledButton
            ariaLabel={labelTranslationKey}
            htmlType="submit"
            data-testid="delete-creative-agency-button"
          >
            {labelTranslationKey}
          </StyledButton>
        </div>
      </Form>
    </div>
  );
};

export default Content;
