import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Input, Typography, Divider } from "antd";
import Select from "react-select";
import getProductInformation from "src/api/product-information/get-production-information";
import { useTranslation } from "react-i18next";
import useAuth from "src/auth/use-auth";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import {
  parseJSONToLabel,
  parseGlobalBrands,
  parseProductDivisions,
} from "./util";
import { ILabel, IParsedBrands } from "./types";
import {
  IGlobalBrand,
  IProductDivision,
  IProjectProductAndAccountInformationData,
  IRegionalBrand,
  ISelectValue,
} from "../../interfaces";
import { IHoldingCompany } from "../account-information/types";
import { customStyles } from "../details/styles";
import style from "../../styles";

interface IProps {
  projectProductAndAccountInformationData: IProjectProductAndAccountInformationData;
  updateProjectProductAndAccountInformationData: (
    projectProductAndAccountInformationData: IProjectProductAndAccountInformationData
  ) => void;
  holdingCompany?: IHoldingCompany;
}

interface IDropdownLabel {
  label: string;
  value: string | number;
}

const ProductInformation = memo(
  ({
    projectProductAndAccountInformationData,
    updateProjectProductAndAccountInformationData,
    holdingCompany,
  }: IProps): ReactElement => {
    const isMobile = getIsMobile();
    const { t } = useTranslation();
    const { fetchWrapper } = useAuth();
    useWindowSize();
    const globalBrandName = useMemo(() => {
      return projectProductAndAccountInformationData.globalBrand
        ? parseJSONToLabel(
            projectProductAndAccountInformationData.globalBrand as ISelectValue
          )
        : undefined;
    }, [projectProductAndAccountInformationData.globalBrand]);

    const regionalBrandName = useMemo(() => {
      return projectProductAndAccountInformationData.regionalBrand
        ? parseJSONToLabel(
            projectProductAndAccountInformationData.regionalBrand as ISelectValue
          )
        : undefined;
    }, [projectProductAndAccountInformationData.regionalBrand]);

    const productDivision = useMemo(() => {
      return projectProductAndAccountInformationData.productDivision
        ? parseJSONToLabel(
            projectProductAndAccountInformationData.productDivision as ISelectValue
          )
        : undefined;
    }, [projectProductAndAccountInformationData.productDivision]);

    const [globalBrands, setGlobalBrands] = useState<IParsedBrands[]>();
    const [productDivisions, setProductDivisions] = useState<ILabel[]>();
    const [regionalBrands, setRegionalBrands] = useState<ILabel[]>();

    const handleUpdate = useCallback(
      (
        event:
          | React.ChangeEvent<HTMLInputElement>
          | React.ChangeEvent<HTMLTextAreaElement>
      ) => {
        const { name, value } = event.target;
        updateProjectProductAndAccountInformationData({ [name]: value });
      },
      [updateProjectProductAndAccountInformationData]
    );

    const handleDropdownChange = useCallback(
      (field: string, data: ILabel) => {
        const { label, value } = data;
        type FieldData = IGlobalBrand | IRegionalBrand | IProductDivision;
        updateProjectProductAndAccountInformationData({
          [field]: {
            id: value,
            name: label,
          } as FieldData,
        });
      },
      [updateProjectProductAndAccountInformationData]
    );

    useEffect(() => {
      const setupProductInfo = async () => {
        if (holdingCompany?.id) {
          const res = await fetchWrapper(
            getProductInformation,
            holdingCompany?.id
          );

          const parsedProductDivisons = parseProductDivisions(
            res.productDivisions
          ).sort((a, b) => a.label.localeCompare(b.label));
          const parsedGlobalBrands = parseGlobalBrands(
            res.globalBrands
          ).sort((a, b) => a.label.localeCompare(b.label));

          setGlobalBrands(parsedGlobalBrands);
          setProductDivisions(parsedProductDivisons);
        }
      };

      void setupProductInfo();
    }, [fetchWrapper, holdingCompany?.id]);

    useEffect(() => {
      // Reset the regional brands select dropdown anytime the global brand changes.
      const selectedBrand = globalBrands?.find(
        (x) => globalBrandName?.label === x.label
      );

      setRegionalBrands(
        selectedBrand?.regionalBrands.sort((a, b) =>
          a.label.localeCompare(b.label)
        )
      );
    }, [globalBrands, globalBrandName]);

    const emptyField = useMemo(
      () => ({
        id: "",
        name: "",
      }),
      []
    );

    const handleClearGlobalBrand = useCallback(() => {
      updateProjectProductAndAccountInformationData({
        globalBrand: emptyField,
        regionalBrand: emptyField,
        productDivision: emptyField,
      });
    }, [emptyField, updateProjectProductAndAccountInformationData]);

    const handleClearRegionalBrand = useCallback(() => {
      updateProjectProductAndAccountInformationData({
        regionalBrand: emptyField,
        productDivision: emptyField,
      });
    }, [emptyField, updateProjectProductAndAccountInformationData]);

    const handleClearProductDivision = useCallback(() => {
      updateProjectProductAndAccountInformationData({
        productDivision: emptyField,
      });
    }, [emptyField, updateProjectProductAndAccountInformationData]);

    const showGlobalBrand = useMemo(
      () => holdingCompany?.name !== undefined || holdingCompany?.name !== "",
      [holdingCompany?.name]
    );

    const handleGlobalBrandChange = useCallback(
      (data: ILabel) => {
        if (data === null) {
          handleClearGlobalBrand();
        } else {
          handleDropdownChange("globalBrand", data);
        }
      },
      [handleClearGlobalBrand, handleDropdownChange]
    );

    const handleRegionalBrandChange = (data: ILabel) => {
      if (data === null) {
        handleClearRegionalBrand();
      } else {
        handleDropdownChange("regionalBrand", data);
      }
    };

    const handleProductDivisionChange = (data: ILabel) => {
      if (data === null) {
        handleClearProductDivision();
      } else {
        handleDropdownChange("productDivision", data);
      }
    };

    return (
      <>
        <div
          css={style.assetsContainer}
          data-testid="product-information-section"
        >
          <div css={style.titleLabelContainer}>
            <Typography.Text css={style.titleLabel}>
              {t(
                "ProjectsPage##productInformationSection##Product Information"
              )}
            </Typography.Text>
          </div>
          <div
            css={[
              isMobile ? style.mobileSectionContainer : style.clientContainer,
              style.lastOfSectionBottom,
              style.additionalProductInformationContainer,
            ]}
          >
            <div css={style.formItemContainer}>
              <div css={style.basicInputContainer}>
                <Typography.Text css={style.basicInputLabel}>
                  {t(
                    "ProjectsPage##productInformationSection##Global brand name"
                  )}
                </Typography.Text>
              </div>
              <Select
                isClearable
                isSearchable
                placeholder=""
                isDisabled={!showGlobalBrand}
                onChange={handleGlobalBrandChange}
                value={
                  globalBrandName === undefined
                    ? null
                    : (globalBrandName as IDropdownLabel)
                }
                options={globalBrands}
                styles={customStyles}
                id="global-brand-name-dropdown"
              />
            </div>
            <div css={style.formItemContainer}>
              <div css={style.basicInputContainer}>
                <Typography.Text css={style.basicInputLabel}>
                  {t(
                    "ProjectsPage##productInformationSection##Regional brand name"
                  )}
                </Typography.Text>
              </div>
              <Select
                isClearable
                isSearchable
                isDisabled={regionalBrands === undefined}
                placeholder=""
                onChange={handleRegionalBrandChange}
                value={
                  regionalBrandName === undefined
                    ? null
                    : (regionalBrandName as IDropdownLabel)
                }
                options={regionalBrands}
                styles={customStyles}
                id="regional-brand-name-dropdown"
              />
            </div>
            <div css={style.formItemContainer}>
              <div css={style.basicInputContainer}>
                <Typography.Text css={style.basicInputLabel}>
                  {t(
                    "ProjectsPage##productInformationSection##Product division"
                  )}
                </Typography.Text>
              </div>
              <Select
                isClearable
                isSearchable
                onChange={handleProductDivisionChange}
                placeholder=""
                value={
                  productDivision === undefined
                    ? null
                    : (productDivision as IDropdownLabel)
                }
                options={productDivisions}
                styles={customStyles}
                id="product-division-dropdown"
              />
            </div>
            <div css={style.formItemContainer}>
              <div css={style.basicInputContainer}>
                <Typography.Text css={style.basicInputLabel}>
                  {t("ProjectsPage##productInformationSection##Product name")}
                </Typography.Text>
              </div>
              <Input
                type="text"
                name="product"
                onChange={handleUpdate}
                value={projectProductAndAccountInformationData.product}
                data-testid="product-name-input"
              />
            </div>
          </div>
        </div>
        <Divider css={style.sectionDivider} />
      </>
    );
  }
);

export default ProductInformation;
