enum Statuses {
  Created = "Created",
  Deleted = "Deleted",
  Active = "Active",
}
const BlockedEmailProviders = [
  "aol.com",
  "aol.it",
  "gmail.com",
  "gmx.at",
  "gmx.com",
  "gmx.de",
  "gmx.fr",
  "gmx.li",
  "gmx.net",
  "googlemail.com",
  "hotmail.com",
  "hotmail.co.uk",
  "icloud.com",
  "live.be",
  "live.ca",
  "live.co.uk",
  "live.com.ar",
  "live.com.au",
  "live.com.mx",
  "live.com",
  "live.de",
  "live.fr",
  "live.it",
  "live.nl",
  "mail.com",
  "outlook.com.br",
  "outlook.com",
  "outlook.ie",
  "protonmail.com",
  "thundermail.com",
  "yahoo.ca",
  "yahoo.co.id",
  "yahoo.co.in",
  "yahoo.co.jp",
  "yahoo.co.kr",
  "yahoo.co.nz",
  "yahoo.co.uk",
  "yahoo.com.ar",
  "yahoo.com.au",
  "yahoo.com.br",
  "yahoo.com.cn",
  "yahoo.com.hk",
  "yahoo.com.is",
  "yahoo.com.mx",
  "yahoo.com.ph",
  "yahoo.com.ru",
  "yahoo.com.sg",
  "yahoo.com",
  "yahoo.de",
  "yahoo.dk",
  "yahoo.es",
  "yahoo.fr",
  "yahoo.ie",
  "yahoo.in",
  "yahoo.it",
  "yahoo.jp",
  "yahoo.ru",
  "yahoo.se",
  "yandex.com",
  "yandex.ru",
  "zoho.com",
];

enum Operation {
  add = "add",
  update = "update",
  remove = "remove",
}

interface ILookup {
  code: string;
  displayText: string;
}

const Currencies: { [id: string]: ILookup } = {};
Currencies.GBP = { code: "GBP", displayText: "£ (GBP)" };
Currencies.USD = { code: "USD", displayText: "$ (USD)" };
Currencies.SGD = { code: "SGD", displayText: "$ (SGD)" };
Currencies.EUR = { code: "EUR", displayText: "€ (EUR)" };
Currencies.JPY = { code: "JPY", displayText: "¥ (JPY)" };
Currencies.CNY = { code: "CNY", displayText: "¥ (CNY)" };
Currencies.THB = { code: "THB", displayText: "฿ (THB)" };
Currencies.HKD = { code: "HKD", displayText: "$ (HKD)" };
Currencies.PHP = { code: "PHP", displayText: "₱ (PHP)" };
Currencies.ZAR = { code: "ZAR", displayText: "R (ZAR)" };
Currencies.IDR = { code: "IDR", displayText: "Rp (IDR)" };
Currencies.AUD = { code: "AUD", displayText: "$ (AUD)" };
Currencies.BRL = { code: "BRL", displayText: "R$ (BRL)" };
Currencies.TRY = { code: "TRY", displayText: "₺ (TRY)" };
Currencies.MMK = { code: "MMK", displayText: "K (MMK)" };
Currencies.ARS = { code: "ARS", displayText: "$ (ARS)" };
Currencies.PLN = { code: "PLN", displayText: "zł (PLN)" };
Currencies.MXN = { code: "MXN", displayText: "$ (MXN)" };
Currencies.CAD = { code: "CAD", displayText: "$ (CAD)" };
Currencies.SEK = { code: "SEK", displayText: "kr (SEK)" };
Currencies.AED = { code: "AED", displayText: "د.إ (AED)" };

const ProjectsPageSize = 20;

const MinTalentHubInputDropDownWidth = 309;

const MaxTalentHubInputDropDownTextWidth = 20;

const TalentHubInputPaddingWidth = 8;

const LibraryTrackPageSize = 20;

const ContactsPageSize = 20;

const MusicTypes: { [id: string]: ILookup } = {};

MusicTypes.commercialMusic = {
  code: "CommercialMusic",
  displayText: "Commercial Music",
};

MusicTypes.productionMusic = {
  code: "ProductionMusic",
  displayText: "Production Music",
};

MusicTypes.bespokeReRecord = {
  code: "BespokeRerecord",
  displayText: "Bespoke re record",
};

MusicTypes.cover = {
  code: "Cover",
  displayText: "Cover",
};

MusicTypes.customMusicComposition = {
  code: "CustomMusicComposition",
  displayText: "Custom Music Composition",
};

MusicTypes.enterprise = {
  code: "Enterprise",
  displayText: "Enterprise",
};

MusicTypes.remix = {
  code: "Remix",
  displayText: "Remix",
};

MusicTypes.songtradrMarketplace = {
  code: "SongtradrMarketplace",
  displayText: "Songtradr Marketplace",
};

enum TermTypes {
  Term = "Term",
  Option = "Option",
}

enum Exclusivity {
  None = "None",
  Full = "Full",
  Market = "Market",
  Category = "Category",
}

enum MediaTypes {
  AllMedia = "All Media",
  AllMediaExclTheatrical = "All Media excl Theatrical",
  Podcast = "Podcast",
  Radio = "Radio",
  OutOfHome = "Out Of Home",
  NationalTV = "National TV",
  RegionalTV = "Regional TV",
  SatelliteTV = "Satellite TV",
  TheatricalCinema = "Theatrical / Cinema",
  Events = "Events",
  OutOfContext = "Out Of Context",
  OnlineFullPackage = "Online Full Package",
  UnpaidOnline = "Unpaid Online",
  InternalCorporate = "Internal Corporate",
  PaidSocialMedia = "Paid Social Media",
  UnpaidSocialMedia = "Unpaid Social Media",
  TikTok = "TikTok",
  VideoOnDemand = "Video On Demand",
  BroadcasterVideoOnDemand = "Broadcaster Video On Demand",
  Meta = "Meta",
  Instagram = "Instagram",
  Spotify = "Spotify",
}

enum LicenseFixedDurations {
  ThreeMonths = "3 months",
  SixMonths = "6 months",
  OneYear = "1 year",
  EighteenMonths = "18 months",
  TwoYears = "2 years",
  ThreeYears = "3 years",
  FourYears = "4 years",
  FiveYears = "5 years",
  Archival = "Archival",
  InPerpetuity = "In perpetuity",
  CustomDuration = "Custom duration",
}

enum FormModes {
  Add,
  Edit,
}

enum UpdateActions {
  Updated = "Updated",
  UpdatedWithVersionConflict = "UpdatedWithVersionConflict",
  Recreated = "Recreated",
}
enum AttachmentType {
  Brief = "Brief",
  Track = "Track",
  Documents = "Documents",
  MusicAsset = "MusicAsset",
  VideoAsset = "VideoAsset",
}

enum TalentContactAttachmentType {
  Document = "Document",
  ShowReel = "ShowReel",
}

const DateDisplayFormat = "MMM Do, YYYY";
const RangeDateDisplayFormat = "MMMM Do YYYY";
const ISODateParseFormat = "YYYY-MM-DD";

enum Regions {
  Worldwide = "Worldwide",
  Africa = "Africa",
  CentralAfrica = "Central Africa",
  EasternAfrica = "Eastern Africa",
  NorthernAfrica = "Northern Africa",
  SouthernAfrica = "Southern Africa",
  WesternAfrica = "Western Africa",
  Americas = "Americas",
  CentralAmerica = "Central America",
  NorthernAmerica = "Northern America",
  SouthernAmerica = "Southern America",
  Asia = "Asia",
  CentralAsia = "Central Asia",
  EasternAsia = "Eastern Asia",
  NorthernAsia = "Northern Asia",
  NorthEasternAsia = "North Eastern Asia",
  SouthernAsia = "Southern Asia",
  SouthEasternAsia = "South Eastern Asia",
  GreaterChina = "Greater China",
  Europe = "Europe",
  CEE = "CEE (Central and Eastern Europe)",
  EasternEurope = "Eastern Europe",
  SouthernEurope = "Southern Europe",
  SEE = "South Eastern Europe (SEE)",
  WesternEurope = "Western Europe",
  DACH = "DACH (Germany, Austria, Switzerland)",
  MiddleEast = "Middle East",
  Namet = "NAMET",
  Oceania = "Oceania",
  Rub = "RUB",
  Other = "Other",
}

const ExcludedFileExtensions = [".EXE"];

export interface IRegionWithCountries {
  [region: string]: string[];
}
const regionsWithCountries: IRegionWithCountries = {
  africa: [
    "Algeria",
    "Angola",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cameroon",
    "Central African Republic",
    "Chad",
    "Comoros",
    "Congo Republic",
    "Côte d'Ivoire",
    "Djibouti",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Eswatini",
    "Ethiopia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Guinea",
    "Guinea-Bissau",
    "Kenya",
    "Lesotho",
    "Liberia",
    "Libya",
    "Madagascar",
    "Malawi",
    "Mali",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Niger",
    "Nigeria",
    "Réunion",
    "Rwanda",
    "São Tomé and Príncipe",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Sudan",
    "Tanzania",
    "Togo",
    "Tunisia",
    "Uganda",
    "Western Sahara",
    "Zambia",
    "Zimbabwe",
  ],
  asia: [
    "Afghanistan",
    "Armenia",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Bhutan",
    "Brunei",
    "Cambodia",
    "China",
    "Cyprus",
    "Georgia",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Israel",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Lebanon",
    "Malaysia",
    "Maldives",
    "Mongolia",
    "Myanmar",
    "Nepal",
    "North Korea",
    "Oman",
    "Pakistan",
    "Palestine",
    "Philippines",
    "Qatar",
    "Saudi Arabia",
    "Singapore",
    "South Korea",
    "Sri Lanka",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Thailand",
    "Timor-Leste",
    "Turkmenistan",
    "United Arab Emirates",
    "Uzbekistan",
    "Vietnam",
    "Yemen",
  ],
  europe: [
    "Albania",
    "Andorra",
    "Austria",
    "Belarus",
    "Belgium",
    "Bosnia and Herzegovina",
    "Bulgaria",
    "Croatia",
    "Czechia",
    "Denmark",
    "Estonia",
    "Faroe Islands",
    "Finland",
    "France",
    "Germany",
    "Gibraltar",
    "Greece",
    "Guernsey",
    "Hungary",
    "Iceland",
    "Ireland",
    "Isle of Man",
    "Italy",
    "Jersey",
    "Kosovo",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia (North Macedonia)",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russia",
    "San Marino",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Ukraine",
    "United Kingdom",
    "Vatican City",
  ],
  middleEast: [
    "Bahrain",
    "Cyprus",
    "Iran",
    "Iraq",
    "Israel",
    "Jordan",
    "Kuwait",
    "Lebanon",
    "Oman",
    "Palestine",
    "Qatar",
    "Saudi Arabia",
    "Syria",
    "Turkey",
    "United Arab Emirates",
    "Yemen",
  ],

  northAmerica: ["Canada", "United States of America"],
  centralAmerica: [
    "Belize",
    "Costa Rica",
    "El Salvador",
    "Guatemala",
    "Honduras",
    "Mexico",
    "Nicaragua",
    "Panama",
  ],

  southAmerica: [
    "Argentina",
    "Bolivia",
    "Brazil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Guyana",
    "Paraguay",
    "Peru",
    "Suriname",
    "Uruguay",
    "Venezuela",
  ],
  oceania: [
    "Australia",
    "Fiji",
    "New Zealand",
    "Papua New Guinea",
    "Solomon Islands",
    "Vanuatu",
  ],
  antarctica: ["Antarctica"],
  other: [
    "Åland Islands",
    "American Samoa",
    "Andorra",
    "Anguilla",
    "Antigua and Barbuda",
    "Aruba",
    "Bahamas",
    "Barbados",
    "Bermuda",
    "Bonaire, Sint Eustatius and Saba",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Cayman Islands",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Cook Islands",
    "Curaçao",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gibraltar",
    "Greenland",
    "Guadeloupe",
    "Guam",
    "Heard Island and McDonald Islands",
    "Hong Kong",
    "Macao",
    "Martinique",
    "Mayotte",
    "Micronesia",
    "Moldova",
    "Montserrat",
    "Netherlands Antilles",
    "New Caledonia",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Palau",
    "Pitcairn Islands",
    "Puerto Rico",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "Sint Maarten",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Turks and Caicos Islands",
    "U.S. Minor Outlying Islands",
    "U.S. Virgin Islands",
    "Wallis and Futuna",
  ],
};

const MaxDropDownTagLength = 20;
const MinDropdownTagLength = 13;

export {
  Statuses,
  MinTalentHubInputDropDownWidth,
  TalentHubInputPaddingWidth,
  MaxTalentHubInputDropDownTextWidth,
  BlockedEmailProviders,
  MaxDropDownTagLength,
  MinDropdownTagLength,
  Currencies,
  Operation,
  ProjectsPageSize,
  MusicTypes,
  Exclusivity,
  TermTypes,
  FormModes,
  LicenseFixedDurations,
  MediaTypes,
  UpdateActions,
  AttachmentType,
  TalentContactAttachmentType,
  ExcludedFileExtensions,
  DateDisplayFormat,
  RangeDateDisplayFormat,
  ISODateParseFormat,
  Regions,
  LibraryTrackPageSize,
  ContactsPageSize,
  regionsWithCountries,
};
