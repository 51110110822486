import React, { ReactElement, ReactNode, useState } from "react";
import getOrganisation from "src/api/organisation/get-organisation";
import { ErrorToast } from "src/components/toast-notification";
import { IOrganisation } from "src/interfaces/organisation";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import OrganisationContext from "./context";

interface IOrganisationProvider {
  children: ReactNode;
}

const OrganisationProvider = ({
  children,
}: IOrganisationProvider): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [organisation, setOrg] = useState<IOrganisation>();

  const getOrg = async (organisationId: string, accessToken: string) => {
    if (organisationId) {
      try {
        setIsLoading(true);
        const response = await getOrganisation(organisationId, accessToken);
        if (response) {
          setOrg(response);
        }

        setIsLoading(false);
      } catch (err) {
        log(DataDogLogTypes.ERROR, "Error getting organisation", err);
        ErrorToast("org-subscription-toast", "An error has occurred");
      }
    }
  };

  return (
    <OrganisationContext.Provider
      value={{
        isLoading,
        organisation,
        getOrg,
      }}
    >
      {children}
    </OrganisationContext.Provider>
  );
};

export default OrganisationProvider;
