import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOutsideClick } from "src/utils/outside-click/useOutsideClick";
import {
  ICombinedAgenciesWithTalents,
  IContactsResults,
} from "src/pages/contact/interfaces";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import { Tag } from "antd";
import { MaxDropDownTagLength, MinDropdownTagLength } from "src/constants";
import { isAgencyResults } from "src/providers/project-talent-search/state/provider";
import styles from "./styles";
import CustomDropDownInput from "./components/custom-drop-down-input";
import TalentHubDropDownMenu from "./components/talent-hub-drop-down-menu";
import {
  IContactReferences,
  IProjectTalents,
  TalentType,
} from "../../interfaces";
import AddTalent from "../add-talent";

const loadingInterval = 250;
const maxDropDownHeight = 404;

interface ITalentHubInput {
  selectedTalents: IContactReferences[];
  talentsResponse?: IContactsResponse;
  areTalentsLoading: boolean;
  id?: string;
  // This ref should be attached to the first parent div of TalentHubInput (talentHubRef.current.clientWidth)
  talenthubwidth?: number;
  isMultiSelect?: boolean;
  isWritersShareSection?: boolean;
  agenciesAndContacts?: IAgencyWithContactsResults[];
  areAgenciesIntegrated?: boolean;
  isServiceProvider?: boolean;
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onAddGuestWithEmail?: (guest: IContactReferences) => void;
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onSelectedTalentsChange: (selectValues: IContactReferences[]) => void;
  onResetNarrowSearch: () => void;
}
const TalentHubInput = ({
  selectedTalents,
  talentsResponse,
  talenthubwidth,
  areTalentsLoading,
  id,
  areAgenciesIntegrated = false,
  isMultiSelect = true,
  isWritersShareSection = false,
  isServiceProvider = false,
  agenciesAndContacts,
  onScrollTalents,
  onAddGuestWithEmail,
  onSelectedTalentsChange,
  onUpdateNarrowSearchText,
  onResetNarrowSearch,
}: ITalentHubInput): JSX.Element => {
  const [isCustomInputOpen, setCustomInputOpen] = useState(false);
  const [isSearchTextLoading, setSearchTextLoading] = useState(false);
  const [isAddForm, setAddForm] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const dropDownButton = useRef<HTMLButtonElement>(null);
  const [widthOffSet, setWidthOffSet] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    talentsResponse?.currentPage ?? 0
  );
  const [isCreateContactOpen, setCreateContactOpen] = useState(false);

  const [combinedAgenciesWithTalent, setCombinedAgenciesWithTalent] = useState<
    ICombinedAgenciesWithTalents[]
  >([]);

  const [searchValue, setSearchValue] = useState("");
  const [isTalentInputFocused, setTalentInputFocused] = useState(false);

  const handleCloseModal = useCallback(() => {
    setCustomInputOpen(false);
  }, []);

  const resetDropDownValues = useCallback(() => {
    onResetNarrowSearch();
    setCombinedAgenciesWithTalent([]);
    setPageNumber(0);
  }, [onResetNarrowSearch]);

  const handleSetPageNumber = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  const handleResetComponentStates = useCallback(() => {
    handleCloseModal();
    resetDropDownValues();
    setSearchValue("");
    setTalentInputFocused(false);
    setAddForm(false);
  }, [handleCloseModal, resetDropDownValues]);

  useOutsideClick(dropDownRef, (event) => {
    if (
      dropDownButton?.current &&
      !dropDownButton.current.contains(event.target as Node)
    ) {
      handleCloseModal();
      resetDropDownValues();
      setSearchValue("");
      setTalentInputFocused(false);
    }
  });

  useEffect(() => {
    setPageNumber(talentsResponse?.currentPage ?? 0);
  }, [talentsResponse?.currentPage]);

  useEffect(() => {
    if (isTalentInputFocused) {
      const tmpAgenciesWithTalents: ICombinedAgenciesWithTalents[] = [];
      agenciesAndContacts?.forEach((agencyOrTalent) => {
        if (isAgencyResults(agencyOrTalent)) {
          const agencyResult: ICombinedAgenciesWithTalents = {
            agency: agencyOrTalent.agency,
            talents: (agencyOrTalent?.talents ?? []).map((talent) => ({
              ...talent,
              groupByAgency: false,
            })),
          };
          tmpAgenciesWithTalents.push(agencyResult);
        } else {
          // Typescript is setting agencyOrTalent to type never. If we ignore the error with eslint disable or reassign type, it works
          const talentWithType = agencyOrTalent as IContactsResults;
          const contactResult: ICombinedAgenciesWithTalents = {
            id: talentWithType.id,
            name: talentWithType.name,
            email: talentWithType.email,
            agencyWritingPartnership: talentWithType.agencyWritingPartnership,
            groupByAgency: talentWithType?.groupByAgency ?? false,
            ...(isWritersShareSection && { ipi: talentWithType?.ipi }),
          };
          tmpAgenciesWithTalents.push(contactResult);
        }
      });
      setCombinedAgenciesWithTalent(tmpAgenciesWithTalents);
    }
  }, [
    agenciesAndContacts,
    areAgenciesIntegrated,
    isTalentInputFocused,
    isWritersShareSection,
  ]);

  useEffect(() => {
    if (isCustomInputOpen && dropDownRef?.current) {
      const rect = dropDownRef.current.getBoundingClientRect();

      if (rect.right >= 0 && rect.right >= window.innerWidth) {
        const newWidthOffSet = Math.round(rect.right - window.innerWidth);
        setWidthOffSet(newWidthOffSet);
      }
    } else {
      setWidthOffSet(0);
    }
  }, [isCustomInputOpen]);

  const handleRemoveTag = useCallback(
    (tagId?: string, type?: TalentType) => {
      const newSelectedTalents = selectedTalents.filter((selectedTalent) => {
        if (type === TalentType.OpenForm) {
          return selectedTalent.name !== tagId;
        }
        return selectedTalent.id !== tagId;
      });

      onSelectedTalentsChange(newSelectedTalents);
    },
    [onSelectedTalentsChange, selectedTalents]
  );

  const handleRemoveAgencyTag = useCallback(
    (agencyWritingPartnership: string) => {
      const newTalents = selectedTalents.filter(
        (selectedTalent) =>
          selectedTalent.agencyWritingPartnership !== agencyWritingPartnership
      );
      onSelectedTalentsChange(newTalents);
    },
    [onSelectedTalentsChange, selectedTalents]
  );
  const tags = useMemo(() => {
    const filteredTalents = selectedTalents.reduce(
      (allTalents, currentTalent) => {
        if (currentTalent.groupByAgency) {
          const isTalentAlreadyInArray = allTalents.find(
            (talent) =>
              talent.agencyWritingPartnership ===
                currentTalent.agencyWritingPartnership && talent.groupByAgency
          );
          if (isTalentAlreadyInArray) {
            return allTalents;
          }
        }
        allTalents.push(currentTalent);
        return allTalents;
      },
      [] as IContactReferences[]
    );

    return filteredTalents.map((talent) => {
      const tagName = talent.groupByAgency
        ? talent.agencyWritingPartnership
        : talent.name;

      const isLongTag: boolean = (tagName?.length ?? 0) > MinDropdownTagLength;
      const isOpenForm = talent.type === TalentType.OpenForm;
      const tagId = isOpenForm ? tagName : talent.id;
      return (
        <Tag
          key={`${talent?.name ?? ""}${talent?.type ?? ""}${
            talent.email ?? ""
          }`}
          css={styles.tag(isOpenForm)}
          onClose={() =>
            talent.groupByAgency
              ? handleRemoveAgencyTag(talent.agencyWritingPartnership)
              : handleRemoveTag(tagId, talent.type)
          }
          closable
        >
          <span>
            {isLongTag
              ? `${tagName.slice(0, MaxDropDownTagLength) ?? ""}...`
              : tagName}
          </span>
        </Tag>
      );
    });
  }, [handleRemoveAgencyTag, handleRemoveTag, selectedTalents]);

  const handleUpdateTalentsOrAgencies = useCallback(
    (searchText: string) => {
      onUpdateNarrowSearchText(
        searchText,
        areAgenciesIntegrated,
        isServiceProvider
      );
      setSearchValue(searchText);
      setTimeout(() => {
        setSearchTextLoading(false);
      }, loadingInterval);
    },
    [areAgenciesIntegrated, isServiceProvider, onUpdateNarrowSearchText]
  );

  const handleInputChange = useCallback(
    (searchText: string) => {
      setSearchTextLoading(true);
      setAddForm(false);
      handleUpdateTalentsOrAgencies(searchText);
    },
    [handleUpdateTalentsOrAgencies]
  );

  const handleTalentInputFocusChange = useCallback(() => {
    if (combinedAgenciesWithTalent?.length !== 0) {
      onResetNarrowSearch();
    }
    setTalentInputFocused(true);
    setAddForm(false);
    onUpdateNarrowSearchText(
      searchValue,
      areAgenciesIntegrated,
      isServiceProvider
    );
  }, [
    combinedAgenciesWithTalent?.length,
    onUpdateNarrowSearchText,
    searchValue,
    areAgenciesIntegrated,
    isServiceProvider,
    onResetNarrowSearch,
  ]);

  const handleMainButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (
        dropDownButton?.current &&
        dropDownButton.current.contains(event.target as Node)
      ) {
        event.stopPropagation();
        event.preventDefault();
        if (!isCustomInputOpen && !isTalentInputFocused) {
          setCustomInputOpen(!isCustomInputOpen);
          setTalentInputFocused(!isTalentInputFocused);
        }
        setAddForm(false);

        onUpdateNarrowSearchText(
          searchValue,
          areAgenciesIntegrated,
          isServiceProvider
        );
      }
    },
    [
      areAgenciesIntegrated,
      isCustomInputOpen,
      isServiceProvider,
      isTalentInputFocused,
      onUpdateNarrowSearchText,
      searchValue,
    ]
  );
  const handleAddOrCloseNewTalent = useCallback(() => {
    setCreateContactOpen(!isCreateContactOpen);
  }, [isCreateContactOpen]);

  const handleContactCreated = useCallback(
    (talentData: IProjectTalents) => {
      const talentDataWithoutAgency: IContactReferences = {
        ...talentData,
        agencyWritingPartnership: "",
        groupByAgency: false,
      };
      onSelectedTalentsChange([talentDataWithoutAgency, ...selectedTalents]);
    },
    [onSelectedTalentsChange, selectedTalents]
  );

  const handleAddForm = useCallback((isFormOpen: boolean) => {
    setAddForm(isFormOpen);
  }, []);

  const handleAddNewGuest = useCallback(
    (name: string) => {
      if (name) {
        const newGuest: IContactReferences = {
          name,
          type: TalentType.OpenForm,
          agencyWritingPartnership: "",
          groupByAgency: false,
        };
        onSelectedTalentsChange([newGuest, ...selectedTalents]);
        handleResetComponentStates();
      }
    },
    [handleResetComponentStates, onSelectedTalentsChange, selectedTalents]
  );

  return (
    <div css={styles.dropDownWrapper} style={{ width: talenthubwidth }}>
      <AddTalent
        isOpen={isCreateContactOpen}
        onClose={handleAddOrCloseNewTalent}
        onContactCreated={handleContactCreated}
      />
      <button
        type="button"
        css={styles.mainDropDownContainer}
        ref={dropDownButton}
        onClick={handleMainButtonClick}
      >
        <CustomDropDownInput
          id={id}
          tags={tags}
          isOpen={isCustomInputOpen}
          placeholder="Search or add an artist"
          onInputChange={handleInputChange}
          onInputFocusChange={handleTalentInputFocusChange}
        />
      </button>
      {isCustomInputOpen && (
        <div
          css={styles.mainDropDownBody(
            widthOffSet,
            isServiceProvider ? maxDropDownHeight : undefined
          )}
          ref={dropDownRef}
        >
          <TalentHubDropDownMenu
            tags={tags}
            id={id}
            totalPages={talentsResponse?.totalPages ?? 0}
            selectedTalents={selectedTalents}
            searchValue={searchValue}
            pageNumber={pageNumber}
            isSearchTextLoading={isSearchTextLoading}
            combinedAgenciesWithTalent={combinedAgenciesWithTalent}
            dropDownWidth={talenthubwidth}
            isLoading={areTalentsLoading}
            isServiceProvider={isServiceProvider}
            areAgenciesIntegrated={areAgenciesIntegrated}
            isMultiSelect={isMultiSelect}
            isWritersShareSection={isWritersShareSection}
            isAddGuestWithEmail={!!onAddGuestWithEmail}
            isAddForm={isAddForm}
            onAddForm={handleAddForm}
            onAddGuestWithEmail={(values) => {
              onAddGuestWithEmail?.(values);
              handleResetComponentStates();
            }}
            onAddNewGuest={handleAddNewGuest}
            onAddNewTalent={handleAddOrCloseNewTalent}
            onScrollTalents={onScrollTalents}
            onSetPageNumber={handleSetPageNumber}
            onSelectedTalentsChange={onSelectedTalentsChange}
          />
        </div>
      )}
    </div>
  );
};

export default TalentHubInput;
