import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";
import { StylesConfig } from "react-select";

const sharedHeaderStyling = css`
  width: 100%;
  font-family: ${theme.fonts.sofiaPro};

  .ant-tabs-tab {
    margin: 0 3rem 0 0;
  }
`;

type MyOptionType = {
  label?: string;
  value?: string;
};

export const selectStyles: StylesConfig<MyOptionType> = {
  menu: (provided) => ({
    ...provided,
    fontSize: 16,
    fontWeight: 400,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
  }),
  multiValue: (provided) => ({
    ...provided,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
    color: `${theme.colors.primary.blue}`,

    ":hover": {
      color: `${theme.colors.secondary.lightGray}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 56,
    maxHeight: 56,
    fontSize: 16,
    fontWeight: 400,
    overflow: "overlay",
  }),
};

export default {
  heading: css`
    font-family: ${theme.fonts.sofiaPro};
    margin-bottom: 0 !important;
    h1.ant-typography {
      margin: 0 !important;
    }
    @media ${queries.medium} {
      font-size: 2.25rem !important;
    }

    @media ${queries.large} {
      font-size: 2.75rem !important;
    }
  `,
  profileDivider: css`
    margin: 0 !important;
  `,
  profileStickyHeader: css`
    position: sticky;
    z-index: ${theme.zIndex.pageStickyHeader};
    top: 48px;
    background-color: ${theme.colors.background.white};
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
    @media ${queries.large} {
      top: 0;
    }
  `,
  subHeaderContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3rem;
    align-items: center;
    z-index: 2000;
  `,
  profileUpperHeader: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem;
    height: 88px;
  `,
  typographyTitle: css`
    margin-top: 0;
    margin-bottom: 0 !important;
    font-weight: 700 !important;
    font-size: 32px !important;
    align-self: center;
    font-family: ${theme.fonts.sofiaPro};
  `,
  profileContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  `,
  mobileSectionContainer: css`
    display: flex;
    flex-direction: column;
  `,
  sectionContainer: css`
    display: flex;
    flex-direction: row;
    > div {
      flex: 1 1 0;
      width: 0;
    }
    column-gap: 1.5rem;
    width: 100%;
  `,
  formItem: css`
    margin-bottom: 0 !important;
    input {
      font-size: 16px;
    }
  `,
  basicInputContainer: css`
    margin-bottom: 0.5rem;
  `,
  basicInputLabel: css`
    font-size: 0.875rem;
    line-height: 1.55rem;
    font-weight: 400;
  `,
  headerContainer: css`
    display: none;
    width: 100%;
    background-color: ${theme.colors.background.white};
    box-shadow: 0.063rem -0.063rem 0.313rem 0 var(--colors-dark-gray);
    padding: 1rem 0;

    @media ${queries.medium} {
      display: flex;
      font-size: 2.25rem !important;
      margin-bottom: 0 !important;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  `,
  changeOrgContainer: css`
    max-width: 25rem;
    margin-top: 2rem;
  `,
  stickyHeaderContainer: css`
    ${sharedHeaderStyling}
    position: fixed;
    z-index: 1051;
    @media ${queries.large} {
      margin-top: 0;
    }
  `,
  container: css`
    ${sharedHeaderStyling}
  `,
  contentContainer: css`
    padding-top: 1.5rem;
    border-radius: 0;

    :empty {
      padding: 0.125rem 0 0 0 !important;
    }

    @media ${queries.medium} {
      border-radius: 5px;
      padding: 1.5rem 3.125rem;
    }
  `,
  tabs: css`
    .ant-tabs-nav-wrap {
      padding-left: 1.25rem;
    }

    @media ${queries.medium} {
      .ant-tabs-tabpane {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
      }

      .ant-tabs-tab {
        padding-top: 0.3125rem !important;
      }
    }

    @media ${queries.large} {
      .ant-tabs-nav-wrap {
        padding-left: 3rem !important;
      }

      .ant-tabs-tabpane {
        padding-left: 3.125rem !important;
        padding-right: 3.125rem !important;
      }

      .ant-tabs-top {
        padding-top: 0.625rem !important;
      }
    }
  `,
  tabLink: css`
    color: ${theme.colors.black};

    :hover {
      color: ${theme.colors.black};
      text-decoration: none;
    }

    :visited {
      color: ${theme.colors.black};
    }
  `,
  formContainer: css`
    flex: 1;
    margin: 1.875rem 2rem 0 2rem;
    border-radius: 0.25rem;
    padding: 2rem;
    background-color: ${theme.colors.white};
    box-shadow: 0 0.063rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5rem;
    .ant-form {
      max-width: 25rem;
    }
    h1.ant-typography {
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1.5rem !important;
      line-height: 1.5rem !important;
      margin-bottom: 0.313rem !important;
    }
    div.ant-typography {
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1rem !important;
      line-height: 1.875rem !important;
      margin-bottom: 1.2rem !important;
    }
    .ant-divider {
      max-width: 25rem !important;
      min-width: unset;
    }
    button {
      max-width: 25rem;
    }

    .ant-divider-horizontal {
      margin-bottom: 2rem;
    }
  `,
  submit: css`
    margin-bottom: 0;
    button {
      width: 100%;
      border-radius: 4px;
      background-color: ${theme.colors.primary.blue};
      font-weight: 600;
      box-shadow: none;
      font-size: 1rem;
    }
  `,
  link: css`
    color: ${theme.colors.primary.blue};
    background-color: unset;
    font-weight: bold;
    padding: 0 !important;

    &:hover {
      color: ${theme.colors.primary.blue};
      background-color: unset;
      padding: 0 !important;
      text-decoration: underline;
    }
  `,
  select: css`
    height: 50px;
  `,
};
