import React, { ComponentType, ReactElement } from "react";
import { Drawer } from "antd";
import styles from "./styles";
import { IStyledMobileDrawerProps } from "./interfaces";

/*
    This allows us to wrap AntDesign's Drawer component and inject our
    own custom props and styling to re-use across the app.
*/
const withMobileDrawerStyles = <P extends unknown>(
  Component: ComponentType<P>
) => {
  return ({
    className,
    children,
    ...rest
  }: IStyledMobileDrawerProps): ReactElement => {
    return (
      <Component {...(rest as P)} css={styles.main} className={className}>
        {children}
      </Component>
    );
  };
};

export default withMobileDrawerStyles(Drawer);
