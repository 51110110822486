import React, { ReactElement, useCallback } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Form, Typography } from "antd";
import StyledButton from "src/components/button";
import { IOption } from "src/pages/projects/project/components/services/types";
import styles from "src/components/modals/styles";

interface IProps {
  handleDeleteThirdPartySubmit: (option: IOption) => void;
  onClose?: () => void;
  thirdPartyOption?: IOption;
}

const Content = ({
  handleDeleteThirdPartySubmit,
  onClose,
  thirdPartyOption,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleFormSubmit = useCallback(() => {
    if (thirdPartyOption) {
      handleDeleteThirdPartySubmit(thirdPartyOption);
    }
  }, [handleDeleteThirdPartySubmit, thirdPartyOption]);

  const labelTranslationKey = "deleteThirdPartyService##Delete";
  return (
    <div>
      <Typography.Title css={styles.modalTitle}>
        {t("deleteThirdPartyService##Are you sure?")}
      </Typography.Title>
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <p>
          <Trans i18nKey="deleteThirdPartyService##confirmationPrompt">
            {{ thirdPartyName: thirdPartyOption?.label }}
          </Trans>
        </p>
        <div css={styles.footer}>
          <StyledButton
            ariaLabel={t("Cancel")}
            type="secondary"
            onClick={onClose}
          >
            {t("Cancel")}
          </StyledButton>
          <StyledButton
            ariaLabel={labelTranslationKey}
            htmlType="submit"
            data-testid="delete-third-party-button"
          >
            {labelTranslationKey}
          </StyledButton>
        </div>
      </Form>
    </div>
  );
};

export default Content;
