import { useMountedRef } from "@songtradr/spa-common";
import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { SuccessToast } from "src/components/toast-notification";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import UserProfile from "..";
import useAuth from "../../../auth/use-auth";
import { IProfileUpdateForm } from "../interfaces";
import patchOrgMember from "../../../api/organisation-members/patch-org-member";
import getOrgMember from "../../../api/organisation-members/get-org-member";
import { IOrganisationMemberProps } from "../../team/interfaces";

function ProfileContainer(): ReactElement | null {
  const mountedRef = useMountedRef();
  const { t } = useTranslation();
  const {
    organisationId,
    user,
    getAccessToken,
    fetchWrapper,
    isSessionValid,
  } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isSavePending, setIsSavePending] = useState(false);
  const [orgMember, setOrgMember] = useImmer<IOrganisationMemberProps>(
    {} as IOrganisationMemberProps
  );
  const history = useHistory();

  const submitForm = async (formData: IProfileUpdateForm) => {
    setIsSavePending(true);
    const isSession = await isSessionValid();
    if (!isSession) return;
    try {
      if (organisationId && user?.id) {
        await fetchWrapper(patchOrgMember, organisationId, user?.id, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          jobTitle: formData.jobTitle,
          phoneNumber: formData.phoneNumber,
          country: formData.country,
        });
      }

      setOrgMember((draft) => {
        draft.firstName = formData.firstName;
        draft.lastName = formData.lastName;
        draft.jobTitle = formData.jobTitle;
        draft.phoneNumber = formData.phoneNumber;
        draft.country = formData.country;
      });

      SuccessToast(t("UserProfilePage##Details updated."));
    } finally {
      if (mountedRef.current) {
        setIsSavePending(false);
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchMember = async () => {
      const isSession = await isSessionValid();

      const accessToken = getAccessToken();

      if (!accessToken || !organisationId || !user?.id || !isSession) {
        return;
      }

      setIsLoading(true);

      const userProfileResponse = await getOrgMember(
        accessToken,
        organisationId,
        user.id
      );

      if (mountedRef.current) {
        setOrgMember(userProfileResponse);
        setIsLoading(false);
      }
    };

    void fetchMember();
    return () => {
      source.cancel("Cancelling in cleanup");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserProfile
      orgMember={orgMember}
      resetPassword={() => history.push("/reset-password")}
      submitForm={submitForm}
      isSavePending={isSavePending}
      isLoading={isLoading}
    />
  );
}

export default ProfileContainer;
