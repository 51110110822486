import config from "src/config";
import { getAuthHeader, getJsonContentHeader } from "../../headers";
import { ITalentHubTypes } from "../interfaces";

const getTalentHubTypes = async (
  accessToken: string,
  orgId: string
): Promise<ITalentHubTypes | null> => {
  const options = {
    headers: {
      ...getAuthHeader(accessToken),
      ...getJsonContentHeader(),
    },
  };

  return fetch(
    `${config.projectManagementService.url}/talent-hub/types?orgId=${orgId}`,
    options
  )
    .then((response) => response.json())
    .then((data) => data as ITalentHubTypes);
};

export default getTalentHubTypes;
