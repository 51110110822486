import { Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getIsDesktop, getIsMobile } from "@songtradr/spa-common";
import ControlButton from "src/components/control-buttons/base-button";
import Button from "src/components/button";
import { DownloadIcon } from "src/app/assets/icons/component-icons";
import WaveSurfer from "wavesurfer.js";
import { useTranslation } from "react-i18next";
import ProgressCircle from "./components/progress-circle";
import styles from "./styles";

interface IProps {
  url: string | HTMLMediaElement;
  fileName: string;
  handleDownloadClick?: () => void;
  setTrackPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isTrackPlaying: boolean;
  textColor?: string;
}

// This is a wrapper around the Waveform.js library for easier usage within React.
export default function Waveform({
  url,
  fileName,
  handleDownloadClick,
  setTrackPlaying,
  isTrackPlaying,
  textColor = "white",
}: IProps): JSX.Element {
  const wavesurfer = useRef<WaveSurfer>();
  const [playing, setPlay] = useState(false);
  const [isActiveTrack, setActiveTrack] = useState(false);
  const [activeTrackCurrentTime, setActiveTrackCurrentTime] = useState<number>(
    0
  );
  const { t } = useTranslation();

  const isDesktop = getIsDesktop();
  const isMobile = getIsMobile();

  const playButtonWidth = isMobile ? 64 : 74;

  // Create a unique identifer for the waveform container using filename, remove any spaces or full stops
  const containerId = fileName.replace(/[.,-\s]/g, "");
  // Create a new WaveSurfer instance on component mount and when url changes
  useEffect(() => {
    setPlay(false);

    wavesurfer.current = WaveSurfer.create({
      container: `#${containerId}`,
      waveColor: "blue",
      progressColor: "#5cc9ca",
      cursorColor: "transparent",
      barWidth: 4,
      barRadius: 1,
      responsive: true,
      height: 45,
      normalize: true,
      partialRender: true,
    });

    /* TODO - API integration and generating peaks for waveforms
      - Because loading tracks takes a few seconds (or more for large tracks)
      - We want to generate the waveform and only download the track for streaming when the user clicks play
      - Reach out the relevant lambda function for the peaks JSON data
      - Pass into wavesurfer to generate the waveform
      - Then fetch track when user clicks play
      (For now, we will simply load the song in below)
    */
    wavesurfer.current.load(url);

    // Removes events, elements and disconnects web audio nodes on unmount
    return () => wavesurfer.current?.destroy();
  }, [url, containerId]);

  const trackDuration = wavesurfer?.current?.getDuration() ?? 0;

  // Interval set to update the track progress button every 500ms
  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;

    if (!!wavesurfer.current && playing) {
      interval = setInterval(() => {
        const currentTime = wavesurfer?.current?.getCurrentTime() ?? 0;
        const progress = Math.min(currentTime / trackDuration, 1);

        // If the song has completed playing, reset the play button.
        if (progress === 1) {
          setTrackPlaying(false);
          setActiveTrack(false);
          setPlay(false);
          setActiveTrackCurrentTime(0);
          wavesurfer?.current?.setCurrentTime(0);
        } else {
          setActiveTrackCurrentTime(progress);
        }
      }, 500);
    }

    return () => clearInterval(interval);
  }, [trackDuration, playing, setTrackPlaying]);

  const handlePlayPause = async () => {
    if (isTrackPlaying && !isActiveTrack) {
      return;
    }

    if (playing && isTrackPlaying) {
      setTrackPlaying(false);
      setActiveTrack(false);
      setPlay(false);
    }

    if (!playing && !isTrackPlaying) {
      setActiveTrack(true);
      setTrackPlaying(true);
      setPlay(true);
    }

    await wavesurfer.current?.playPause();
  };

  const renderProgressCircle = (
    progress: number,
    isPlaying: boolean,
    isLoading: boolean
  ) => {
    /*
      TODO - While fetching track, set a local isLoading value in state so we can 
      show a loading spinner around the button itself, then on track load we can 
      return the normal play button
    */
    if (isPlaying && isLoading) {
      return (
        <ProgressCircle
          width={playButtonWidth}
          progress={0.15}
          playing={isPlaying}
          spin
        />
      );
    }

    return (
      <ProgressCircle
        width={playButtonWidth}
        progress={progress}
        playing={isPlaying}
      />
    );
  };

  return (
    <div css={styles.container}>
      <div css={styles.playButtonContainer}>
        <ControlButton
          css={[styles.control, playing ? styles.playing : styles.paused]}
          label={playing ? t("Pause track") : t("Play track")}
          onClick={async () => {
            await handlePlayPause();
          }}
          data-testid={
            playing ? "music-player-pause-button" : "music-player-play-button"
          }
        >
          {renderProgressCircle(activeTrackCurrentTime, playing, false)}
        </ControlButton>
      </div>
      <div css={styles.fileNameContainer}>
        <Typography.Text css={styles.fileName && { color: textColor }}>
          {fileName}
        </Typography.Text>
      </div>
      <div css={styles.waveform} id={containerId} />
      {handleDownloadClick && (
        <div css={styles.downloadButtonContainer}>
          {isDesktop ? (
            <Button
              ariaLabel="Download"
              noLabelTranslation
              onClick={handleDownloadClick}
            >
              {t("Download")} <DownloadIcon fill="white" />
            </Button>
          ) : (
            <button
              type="button"
              css={styles.tabletDownloadButton}
              aria-label={t("Download")}
              onClick={handleDownloadClick}
            >
              <DownloadIcon fill="white" height={64} width={64} />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
