import axios from "axios";
import { createCreativeAgenciesUrl } from "src/api/creative-agencies-url-helper";
import { ICreativeAgency } from "src/pages/projects/project/components/details/components/creative-agency-row/interfaces";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const createCreativeAgency = async (
  accessToken: string,
  organisationId: string,
  thirdPartyName: string
): Promise<ICreativeAgency> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const payload = { name: thirdPartyName, organisationId };

  return axios
    .post(createCreativeAgenciesUrl(), payload, options)
    .then(({ data }) => data as ICreativeAgency);
};

export default createCreativeAgency;
