import axios, { AxiosResponse } from "axios";
import { getAuthHeader, getAxiosOptions } from "../../headers";
import getJsonContentHeader from "../../headers/get-json-content-header";
import { getCreativeAgenciesDeleteUrl } from "../../creative-agencies-url-helper";

const deleteCreativeAgency = async (
  accessToken: string,
  organisationId: string,
  creativeAgencyId: string
): Promise<boolean | AxiosResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  return axios.delete(
    getCreativeAgenciesDeleteUrl(creativeAgencyId, organisationId),
    options
  );
};
export default deleteCreativeAgency;
