import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  submit: css`
    margin-top: 1rem;
    margin-bottom: 0;
    button {
      width: 100%;
      border-radius: 4px;
      background-color: ${theme.colors.primary.blue};
      font-weight: 600;
      box-shadow: none;
      font-size: 1rem;
    }
  `,
  input: css`
    input {
      border: 1px solid ${theme.colors.background.lightGray};
      height: 3.125rem;
      border-radius: 4px;
    }
  `,
  signInPrompt: css`
    text-align: center;
    margin-top: 2rem;
  `,
  link: css`
    color: ${theme.colors.primary.blue};
    font-weight: bold;
  `,
  title: css`
    text-align: center;
    font-size: 2.75rem !important;
    font-family: ${theme.fonts.sofiaPro}, serif;
    font-weight: bold;
    margin-bottom: 1rem !important;
    @media ${queries.medium} {
      text-align: left;
    }
  `,
};
