import { Typography, Input } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "@songtradr/spa-common";
import { IProjectDetailsData } from "src/pages/projects/project/interfaces";
import CreativeAgencyRows from "../creative-agency-row";
import style from "../../../../styles";
import RelatedProjectRows from "../related-projects-row";

interface IProps {
  projectDetailsData: IProjectDetailsData;
  onUpdateProjectDetailsData: (projectDetailsData: IProjectDetailsData) => void;
  onUpdate: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onToggleUpdate: (field: string, value: boolean) => void;
}
const ProjectDescriptionSection = ({
  projectDetailsData,
  onUpdateProjectDetailsData,
  onUpdate,
  onToggleUpdate,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  return (
    <div css={style.projectDescriptionSection}>
      <div css={style.titleLabelContainer}>
        <Typography.Text css={style.titleLabel}>
          {t("ProjectsPage##Description")}
        </Typography.Text>
      </div>
      <div
        css={[
          isMobile ? style.mobileSectionContainer : style.clientContainer,
          style.lastOfSectionBottom,
        ]}
      >
        <div css={style.leftSideDescriptionContainer}>
          <div css={style.basicInputContainer}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##Project Description/Brief")}
            </Typography.Text>
          </div>
          <Input.TextArea
            onChange={onUpdate}
            rows={6}
            name="description"
            value={projectDetailsData.description}
          />
        </div>
        <div css={style.rightSideDescriptionContainer}>
          <div>
            <RelatedProjectRows
              projectRowData={{
                relatedProjects: projectDetailsData.relatedProjects,
                isExtension: projectDetailsData.isExtension,
              }}
              onUpdateProjectRowData={onUpdateProjectDetailsData}
            />
          </div>
          <div>
            <CreativeAgencyRows
              projectCreativeAgencyRowData={{
                creativeAgencyInvolved:
                  projectDetailsData.creativeAgencyInvolved,
                creativeAgencyId: projectDetailsData.creativeAgencyId,
                creativeAgencyName: projectDetailsData.creativeAgencyName,
                creativeAgencyOffice: projectDetailsData?.creativeAgencyOffice,
              }}
              updateProjectCreativeAgencyRowData={onUpdateProjectDetailsData}
              onToggleUpdate={onToggleUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDescriptionSection;
