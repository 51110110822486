import React, { ReactElement } from "react";
import { Typography, Space, Row, Divider } from "antd";
import StyledButton from "src/components/button";
import { useTranslation } from "react-i18next";
import { ISavedUserFilter } from "src/api/user-filters/interfaces";
import { DeleteIcon } from "src/app/assets/icons/component-icons";
import styles from "./styles";

interface IProps {
  onClose: () => void;
  savedFilters: ISavedUserFilter[] | null;
  onDeleteFilter: (filterName: string) => void;
}

const DeleteFiltersContent = ({
  onClose,
  savedFilters,
  onDeleteFilter,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography.Title css={styles.modalTitle}>
        {t("DeleteFilterModal##SavedFilters")}
      </Typography.Title>
      <Space />
      {(savedFilters ?? []).length === 0 && (
        <div>
          <Row gutter={[16, 16]} css={styles.row}>
            <div css={styles.noFilters}>
              {t("DeleteFilterModal##NoFiltersFound")}
            </div>
          </Row>
        </div>
      )}
      {(savedFilters ?? []).map((filter) => (
        <div key={filter.name}>
          <Row gutter={[16, 16]} css={styles.row}>
            <div css={styles.itemName}>{filter.name}</div>
            <div
              role="button"
              css={styles.deleteIcon}
              onClick={() => onDeleteFilter(filter.name)}
              tabIndex={0}
              onKeyDown={(e) => {
                e.persist();
              }}
            >
              <DeleteIcon />
            </div>
          </Row>
          <Divider css={styles.divider} />
        </div>
      ))}
      <div css={styles.controls}>
        <StyledButton
          css={styles.button}
          type="secondary"
          ariaLabel={t("DeleteFilterModal##Close")}
          onClick={onClose}
        >
          DeleteFilterModal##Close
        </StyledButton>
      </div>
    </div>
  );
};

export default DeleteFiltersContent;
