import { SerializedStyles } from "@emotion/react";
import { useTranslation } from "react-i18next";

interface IContentProps {
  testid: string;
  text: string;
  customStyles: SerializedStyles;
}

const NotificationText = ({
  text,
  testid,
  customStyles,
}: IContentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <p css={customStyles} data-testid={testid}>
      {t(text)}
    </p>
  );
};

export default NotificationText;
