import axios from "axios";
import { DocumentCategories } from "src/pages/projects/project/interfaces";
import { AttachmentType } from "src/constants";
import { attachmentUploadUrl } from "../project-url-helper";
import { getAuthHeader } from "../headers";
import getJsonContentHeader from "../headers/get-json-content-header";
import {
  IGetPresignedURLResponse,
  IGetPresignedURLResponseData,
} from "./interfaces";

interface IProps {
  name: string;
  contentType: string;
  attachmentType: AttachmentType;
  projectId: string;
  organisationId: string;
  accessToken: string;
  fullName: string;
  documentCategory?: DocumentCategories;
  trackId?: string;
}

async function getPresignedUploadUrl({
  name,
  contentType,
  attachmentType,
  projectId,
  organisationId,
  accessToken,
  fullName,
  documentCategory,
  trackId,
}: IProps): Promise<IGetPresignedURLResponseData> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  const isFinalTrack = attachmentType === AttachmentType.Track;

  const data = {
    userFullName: fullName,
    attachmentType,
    public: isFinalTrack, // all docs except final track file are uploaded as 'unshared' by default
    fileName: name,
    contentType,
    documentCategory,
    organisationId,
    trackId,
  };

  const response: IGetPresignedURLResponse = await axios.post(
    attachmentUploadUrl(projectId),
    data,
    options
  );

  return response.data;
}

export default getPresignedUploadUrl;
