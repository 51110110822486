import { Button } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "src/app/assets/icons/component-icons";
import styles from "./styles";

interface IProps {
  onClick: () => void;
  ariaLabel: string;
  label: string;
  dataTestId: string;
}

const DeleteIconButtonWithLabel = ({
  onClick,
  ariaLabel,
  label,
  dataTestId,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <Button
        aria-label={t(ariaLabel)}
        data-testid={dataTestId}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <div css={styles.iconContainer}>
          <DeleteIcon width={40} height={40} /> {t(label)}
        </div>
      </Button>
    </div>
  );
};

export default DeleteIconButtonWithLabel;
