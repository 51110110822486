import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import useAuth from "src/auth/use-auth";
import { AxiosError } from "axios";
import {
  IContactsFilters,
  ITalentContactGroupsResponse,
} from "src/pages/talent-hub/interfaces";
import { log } from "src/utils/data-dog";
import {
  IContactsGroupResponse,
  IContactsRequest,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import getContacts from "src/api/talent-hub/get-talent-hub-contacts";
import getContactsFilters from "src/api/talent-hub/get-talent-hub-filters";
import useDebounce from "src/utils/useDebounce";
import { useHistory, useLocation } from "react-router-dom";
import { ContactsPageSize } from "src/constants";
import { IPaginationOptions } from "src/pages/projects/project-listings/interfaces";
import getGroups from "src/api/talent-hub/get-groups";
import createContactsGroup from "src/api/talent-hub/create-group";
import { ICreateContactsGroupRequest } from "src/pages/contact/interfaces";
import { useTranslation } from "react-i18next/";
import deleteGroup from "src/api/talent-hub/delete-group";
import addContactToGroup from "src/api/talent-hub/add-contact-to-group";
import removeContactFromGroup from "src/api/talent-hub/remove-contact-from-group";
import { wait } from "@songtradr/spa-common";
import { getUserFilters } from "src/api/user-filters";
import {
  ISavedUserColumn,
  IUserFilters,
} from "src/api/user-filters/interfaces";
import { AllTalentHubColumns } from "src/components/modals/view-columns-modal";
import mapColumnsInCheckboxLabels from "src/components/modals/view-columns-modal/utils";
import ContactsSearchContext from "./context";

interface IOrganisationProvider {
  children: ReactNode;
}

const ContactsSearchProvider = ({
  children,
}: IOrganisationProvider): ReactElement => {
  const clearGroupSearchAndFiltersData = useRef(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const defaultFiltersValues: IContactsFilters = useMemo(() => {
    return {
      collaboration: [],
      genre: [],
      instrument: [],
      language: [],
      location: [],
      skillSet: [],
      vocalist: [],
      serviceProvider: [],
      racialCharacteristic: [],
      genderIdentity: [],
      signedNDA: [],
      signedCMA: [],
    };
  }, []);
  const [savedColumns, setSavedColumns] = useState<null | ISavedUserColumn[]>(
    null
  );
  const [isLoadingSavedColumns, setIsLoadingSavedColumns] = useState(true);
  const [
    selectedContactFilters,
    setSelectedContactFilters,
  ] = useState<IContactsFilters>(defaultFiltersValues);
  const [
    selectedGroupContactFilters,
    setSelectedGroupContactFilters,
  ] = useState<IContactsFilters>(defaultFiltersValues);
  const [contactsResponse, setContactsResponse] = useState<IContactsResponse>();
  const [allGroups, setAllGroups] = useState<ITalentContactGroupsResponse[]>(
    []
  );
  const [
    specificGroupContacts,
    setSpecificGroupContacts,
  ] = useState<IContactsGroupResponse>();
  const { organisationId, fetchWrapper } = useAuth();
  const [searchText, setSearchText] = useState<string>("");
  const [groupSearchText, setGroupSearchText] = useState<string>("");
  const location = useLocation();
  const history = useHistory();
  const debouncedSearchTerm = useDebounce(searchText, 300);
  const debouncedGroupSearchTerm = useDebounce(groupSearchText, 300);
  const [
    availableContactFilters,
    setAvailableContactFilters,
  ] = useState<IContactsFilters>(defaultFiltersValues);
  const [
    availableGroupContactFilters,
    setAvailableGroupContactFilters,
  ] = useState<IContactsFilters>(defaultFiltersValues);
  const [
    paginationOptions,
    setPaginationOptions,
  ] = useState<IPaginationOptions>({
    total: 0,
    currentPage: 1,
  });
  const [selectedGroup, setSelectedGroup] = useState<{
    id: string;
    isUserGroup: boolean;
  }>({ id: "", isUserGroup: false });
  const [groupCurrentPage, setGroupCurrentPage] = useState(1);

  useEffect(() => {
    void (async () => {
      const filters = await fetchWrapper(getContactsFilters, organisationId);

      if (filters) {
        setAvailableContactFilters(filters);
      }
    })();
  }, [fetchWrapper, organisationId]);

  const updateSavedColumns = useCallback(async () => {
    setIsLoadingSavedColumns(true);
    const savedColumnsResponse = await fetchWrapper(
      getUserFilters,
      organisationId
    );
    if (savedColumnsResponse) {
      const { talentHubDisplayColumns } = savedColumnsResponse;
      setSavedColumns(
        mapColumnsInCheckboxLabels(
          talentHubDisplayColumns,
          Object.values(AllTalentHubColumns)
        )
      );
    }
    setIsLoadingSavedColumns(false);
  }, [fetchWrapper, organisationId]);

  useEffect(() => {
    void updateSavedColumns();
  }, [updateSavedColumns]);

  const fetchContacts = useCallback(async () => {
    const searchTextQuery: string = debouncedSearchTerm.trim();
    const searchParams = new URLSearchParams(location.search);
    const page: string | null = searchParams.get("page");
    let from = 0;
    if (page) {
      from = (Number(page) - 1) * ContactsPageSize;
    }
    const isVocalist =
      selectedContactFilters.vocalist.length === 2
        ? null
        : selectedContactFilters.vocalist[0];

    const isServiceProvider =
      selectedContactFilters.serviceProvider.length === 2
        ? null
        : selectedContactFilters.serviceProvider[0];

    const requestData: IContactsRequest = {
      organisationId,
      from,
      perPage: ContactsPageSize,
      sort: {
        by: "Name",
        order: "ASC",
      },
      searchText: searchTextQuery,
      filters: {
        ...selectedContactFilters,
        vocalist: isVocalist,
        serviceProvider: isServiceProvider,
      },
    };
    const contacts = await fetchWrapper(
      getContacts,
      requestData,
      organisationId
    );
    return contacts;
  }, [
    debouncedSearchTerm,
    fetchWrapper,
    location.search,
    organisationId,
    selectedContactFilters,
  ]);

  const fetchGroups = useCallback(async () => {
    const groups = await fetchWrapper(getGroups, organisationId);
    setAllGroups(groups);
  }, [fetchWrapper, organisationId]);

  useEffect(() => {
    void (async () => {
      if (history.location.pathname !== "/talents") {
        return;
      }

      setIsLoading(true);
      try {
        const contacts = await fetchContacts();
        if (contacts) {
          setContactsResponse(contacts);
          setPaginationOptions({
            total: contacts.totalResults,
            currentPage: contacts.currentPage,
          });
        }
        await fetchGroups();
      } catch (e) {
        const error = e as AxiosError;
        log("error", "Failed to fetch contacts filters", error);
        ErrorToast(
          "contacts-filter-error",
          "There was a problem retrieving contacts filters. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchTerm,
    defaultFiltersValues,
    fetchWrapper,
    history.location.pathname,
    location.search,
    selectedContactFilters,
  ]);

  const handlePageChange = useCallback(
    (page: number) => {
      history.push({
        search: page > 1 ? `page=${page}` : "",
      });
    },
    [history]
  );

  useEffect(() => {
    handlePageChange(1);
  }, [handlePageChange, selectedContactFilters]);

  const handleUpdateSearchText = (searchValue: string) => {
    handlePageChange(1);
    setSearchText(searchValue);
  };

  const handleUpdateGroupSearchText = (searchValue: string) => {
    setGroupSearchText(searchValue);
  };

  const handleFetchSelectedFilters = (selectedFilters: IContactsFilters) => {
    handlePageChange(1);
    setSelectedContactFilters(selectedFilters);
  };

  const handleFetchSelectedGroupFilters = (
    selectedGroupFilters: IContactsFilters
  ) => {
    setSelectedGroupContactFilters(selectedGroupFilters);
  };

  const handleCreateContactsGroup = useCallback(
    async (isUserGroup: boolean, groupName: string) => {
      const createRequest: ICreateContactsGroupRequest = {
        isUserGroup,
        groupName,
      };
      try {
        setIsLoading(true);
        const group = await fetchWrapper(
          createContactsGroup,
          createRequest,
          organisationId
        );

        if (group) {
          setAllGroups([...allGroups, group]);
          SuccessToast(
            `${group.name ?? ""} ${t("group successfully created")}`
          );
        }
      } catch {
        ErrorToast("error-create-groups", t("Failed to create group"));
      } finally {
        setIsLoading(false);
      }
    },
    [allGroups, fetchWrapper, organisationId, t]
  );
  const handleDeleteGroup = useCallback(
    async (groupId: string, isUserGroup: string) => {
      try {
        setIsLoading(true);

        await fetchWrapper(deleteGroup, groupId, isUserGroup, organisationId);
        await fetchGroups();

        SuccessToast(t("Group successfully deleted"));
      } catch {
        ErrorToast("error-delete-group", t("Failed to delete group"));
      } finally {
        setIsLoading(false);
      }
    },
    [fetchGroups, fetchWrapper, organisationId, t]
  );

  const handleAddContactToGroup = useCallback(
    async (groupId: string, talentId: string) => {
      const isUserGroup = !!allGroups.find((g) => g.id === groupId)
        ?.isUserGroup;
      try {
        setIsLoading(true);
        await fetchWrapper(
          addContactToGroup,
          groupId,
          isUserGroup,
          organisationId,
          talentId
        );
        // Wait 1s to ensure the contact is added to the elastic search
        await wait(1000);
        await fetchGroups();

        SuccessToast(t("TalentPage##Contact successfully added to group"));
      } catch {
        ErrorToast("error-delete-group", t("Failed to add contact to group"));
      } finally {
        setIsLoading(false);
      }
    },
    [allGroups, fetchGroups, fetchWrapper, organisationId, t]
  );

  const fetchGroupFilters = useCallback(
    async (groupId: string, isUserGroup: boolean) => {
      const filters = await fetchWrapper(
        getContactsFilters,
        organisationId,
        groupId,
        isUserGroup
      );

      if (filters) {
        setAvailableGroupContactFilters(filters);
      }
    },
    [fetchWrapper, organisationId]
  );

  const fetchGroupContacts = useCallback(
    async (
      groupId: string,
      isUserGroup: boolean,
      page = 1,
      clearFilters = false
    ) => {
      const searchTextQuery: string = debouncedGroupSearchTerm.trim();
      let from = 0;
      if (page) {
        from = (page - 1) * ContactsPageSize;
      }
      const isVocalist =
        selectedGroupContactFilters.vocalist.length === 2
          ? null
          : selectedGroupContactFilters.vocalist[0];

      const isServiceProvider =
        selectedGroupContactFilters.serviceProvider.length === 2
          ? null
          : selectedGroupContactFilters.serviceProvider[0];

      const requestData: IContactsRequest = {
        organisationId,
        byGroup: {
          groupId,
          isUserGroup,
        },
        from,
        perPage: ContactsPageSize,
        sort: {
          by: "Name",
          order: "ASC",
        },
        searchText: clearFilters ? "" : searchTextQuery,
        filters: clearFilters
          ? {
              ...defaultFiltersValues,
              vocalist: null,
              serviceProvider: null,
            }
          : {
              ...selectedGroupContactFilters,
              vocalist: isVocalist,
              serviceProvider: isServiceProvider,
            },
      };
      const contacts = await fetchWrapper(
        getContacts,
        requestData,
        organisationId
      );
      return contacts;
    },
    [
      debouncedGroupSearchTerm,
      defaultFiltersValues,
      fetchWrapper,
      organisationId,
      selectedGroupContactFilters,
    ]
  );

  const handleSetClearGroupValues = useCallback((clear: boolean) => {
    if (clear) {
      clearGroupSearchAndFiltersData.current = true;
    } else {
      // Reset the flag after 500ms because we have debounce of 300ms for the search text.
      setTimeout(() => {
        clearGroupSearchAndFiltersData.current = false;
      }, 500);
    }
  }, []);

  const clearGroupSearchAndFilters = useCallback(() => {
    clearGroupSearchAndFiltersData.current = true;
    setGroupSearchText("");
    setSelectedGroupContactFilters(defaultFiltersValues);
    setTimeout(() => {
      clearGroupSearchAndFiltersData.current = false;
    }, 1000);
  }, [defaultFiltersValues]);

  const handleSetSelectedGroup = useCallback(
    (group: { id: string; isUserGroup: boolean }) => {
      if (!group.id) {
        handleSetClearGroupValues(true);
        clearGroupSearchAndFilters();
        handleSetClearGroupValues(false);
      }
      setSelectedGroup(group);
    },
    [clearGroupSearchAndFilters, handleSetClearGroupValues]
  );

  const handleFetchSpecificGroupContacts = useCallback(
    async (
      groupId: string,
      isUserGroup: boolean,
      clearFilters = false,
      page = 1,
      fetchFilters = true
    ) => {
      if (clearFilters) {
        handleSetClearGroupValues(true);
        clearGroupSearchAndFilters();
      }
      try {
        setIsLoading(true);
        const groupContacts = await fetchGroupContacts(
          groupId,
          isUserGroup,
          page,
          clearFilters
        );
        if (fetchFilters) {
          await fetchGroupFilters(groupId, isUserGroup);
        }
        setSpecificGroupContacts({ ...groupContacts, groupId });
      } catch {
        ErrorToast("error-fetching-group", t("Failed to fetch contact group"));
      } finally {
        if (clearFilters) {
          handleSetClearGroupValues(false);
        }
        setIsLoading(false);
      }
    },
    [
      clearGroupSearchAndFilters,
      fetchGroupContacts,
      fetchGroupFilters,
      handleSetClearGroupValues,
      t,
    ]
  );

  const handleRemoveContactFromGroup = useCallback(
    async (groupId: string, isUserGroup: boolean, talentId: string) => {
      try {
        setIsLoading(true);
        await fetchWrapper(
          removeContactFromGroup,
          groupId,
          isUserGroup,
          organisationId,
          talentId
        );
        // Wait 1s to ensure the contact is removed from the elastic search
        await wait(1000);
        const groupContacts = await fetchGroupContacts(groupId, isUserGroup);
        setSpecificGroupContacts({ ...groupContacts, groupId });
        await fetchGroups();
      } catch {
        ErrorToast(
          "error-removing-contact-from-group",
          t("Failed to remove contact from group")
        );
      } finally {
        setIsLoading(false);
      }
    },
    [fetchGroupContacts, fetchGroups, fetchWrapper, organisationId, t]
  );

  useEffect(() => {
    // Skip this step is cleanup is in progress.
    if (!!selectedGroup.id && !clearGroupSearchAndFiltersData.current) {
      void handleFetchSpecificGroupContacts(
        selectedGroup.id,
        selectedGroup.isUserGroup,
        false,
        1,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedGroupSearchTerm,
    selectedGroupContactFilters,
    handleFetchSpecificGroupContacts,
  ]);

  const handleGroupPageChange = useCallback(
    (page: number, fetchUsers = true) => {
      setGroupCurrentPage(page);
      if (selectedGroup.id && fetchUsers) {
        void handleFetchSpecificGroupContacts(
          selectedGroup.id,
          selectedGroup.isUserGroup,
          false,
          page,
          false
        );
      }
    },
    [
      handleFetchSpecificGroupContacts,
      selectedGroup.id,
      selectedGroup.isUserGroup,
    ]
  );

  return (
    <ContactsSearchContext.Provider
      value={{
        isLoading,
        searchText,
        availableContactFilters,
        contactsResponse,
        selectedContactFilters,
        paginationOptions,
        allGroups,
        specificGroupContacts,
        groupSearchText,
        availableGroupContactFilters,
        selectedGroupContactFilters,
        groupCurrentPage,
        defaultFiltersValues,
        savedColumns,
        isLoadingSavedColumns,
        handleRemoveContactFromGroup,
        handleFetchSpecificGroupContacts,
        handleAddContactToGroup,
        handleCreateContactsGroup,
        handleUpdateSearchText,
        handleFetchSelectedFilters,
        handleDeleteGroup,
        handlePageChange,
        handleUpdateGroupSearchText,
        handleFetchSelectedGroupFilters,
        handleSetSelectedGroup,
        handleGroupPageChange,
        clearGroupSearchAndFilters,
        updateSavedColumns,
      }}
    >
      {children}
    </ContactsSearchContext.Provider>
  );
};

export default ContactsSearchProvider;
