import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useOutsideClick } from "src/utils/outside-click/useOutsideClick";
import ArrowUp from "src/app/assets/icons/component-icons/arrow_up";
import ArrowDown from "src/app/assets/icons/component-icons/arrow_down";
import CrossIcon from "src/app/assets/icons/component-icons/cross";
import { useTranslation } from "react-i18next/";
import { Divider } from "antd";
import styles from "./styles";
import Button from "../button";

interface ISearchFiltersDropDownProps {
  content: JSX.Element | (JSX.Element | null)[];
  value: string;
  label: string;
  onClearAllFilters: (value: string, isResetFilterValues: boolean) => void;
  onResetFilters: (filterValue: string) => void;
  onApplyFilters: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const SearchFiltersDropDown = ({
  content,
  label,
  value,
  onClearAllFilters,
  onApplyFilters,
  onResetFilters,
}: ISearchFiltersDropDownProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const dropDownButton = useRef<HTMLButtonElement>(null);
  const [widthOffSet, setWidthOffSet] = useState(0);
  const { t } = useTranslation();

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    onResetFilters(value);
  }, [onResetFilters, value]);

  useOutsideClick(dropDownRef, (event) => {
    if (
      dropDownButton?.current &&
      !dropDownButton.current.contains(event.target as Node)
    ) {
      handleCloseModal();
    }
  });

  useEffect(() => {
    if (isOpen && dropDownRef?.current) {
      const rect = dropDownRef.current.getBoundingClientRect();

      if (rect.right >= 0 && rect.right >= window.innerWidth) {
        const newWidthOffSet = Math.round(rect.right - window.innerWidth);
        setWidthOffSet(newWidthOffSet);
      }
    } else {
      setWidthOffSet(0);
    }
  }, [isOpen]);

  const handleClearFilters = useCallback(() => {
    onClearAllFilters(value, true);
    setIsOpen(false);
  }, [onClearAllFilters, value]);

  const handleApplyFilters = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onApplyFilters(e);
      setIsOpen(false);
    },
    [onApplyFilters]
  );

  return (
    <div css={styles.dropDownWrapper}>
      <button
        type="button"
        css={styles.mainDropDownContainer}
        ref={dropDownButton}
        onClick={(e) => {
          if (
            dropDownButton?.current &&
            dropDownButton.current.contains(e.target as Node)
          ) {
            e.stopPropagation();
            e.preventDefault();
            setIsOpen(!isOpen);
          }
        }}
      >
        <div css={styles.labelContainer}>
          {label}
          {isOpen ? (
            <ArrowUp height={24} width={24} />
          ) : (
            <ArrowDown height={24} width={24} />
          )}
        </div>
      </button>
      {isOpen && (
        <div css={styles.mainDropDownBody(widthOffSet)} ref={dropDownRef}>
          <div css={styles.gradientContainer}>
            <button
              css={styles.iconButton}
              type="button"
              onClick={handleCloseModal}
            >
              <CrossIcon height={24} width={24} />
            </button>
          </div>
          <div css={styles.bodyAndFooterContainer}>
            <div css={styles.contentContainer}>{content}</div>
            <div>
              <Divider css={styles.buttonDivider} />
              <div css={styles.dropDownFooter}>
                <button
                  type="button"
                  css={styles.buttonContainerClearAll}
                  onClick={handleClearFilters}
                >
                  <div css={styles.iconContainer}>{t("Clear all")}</div>
                </button>
                <Button ariaLabel="close" onClick={handleApplyFilters}>
                  Apply filters
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFiltersDropDown;
