import { createContext } from "react";
import { IAgencyResults } from "src/api/talent-hub/interfaces";
import { IContactAttachment, IContactForm } from "src/pages/contact/interfaces";

export interface IContactProviderContext {
  isLoading: boolean;
  contactFound: boolean;
  contact: IContactForm;
  agencies: IAgencyResults[];
  areAgenciesLoading: boolean;
  handleResetAgencies: () => void;
  handleAgenciesPageChange: (page: number) => void;
  handleUpdateNarrowSearchText: (searchText: string) => void;
  storeContact: (contact: IContactForm) => void;
  storeAttachments: (attachments: IContactAttachment[]) => void;
}

export default createContext<IContactProviderContext>({
  isLoading: true,
  contactFound: true,
  areAgenciesLoading: false,
  agencies: [],
  handleResetAgencies: () => {},
  handleUpdateNarrowSearchText: () => {},
  handleAgenciesPageChange: () => {},
  storeContact: () => {},
  storeAttachments: () => {},
  contact: {
    id: "",
    name: "",
    pronoun: null,
    agencyWritingPartnership: "",
    email: "",
    phone: { countryCode: "", number: "" },
    baseLocation: "",
    socialMediaWebsite: [],
    showreelUrls: [],
    mainLanguage: "",
    otherLanguages: [],
    billingName: "",
    billingAddress: "",
    physicalConditionsIllness: null,
    neurodiverse: null,
    neurodiverseNote: "",
    racialCharacteristics: "",
    genderIdentity: null,
    sexualOrientation: null,
    signedCMA: null,
    signedNDA: null,
    minimumDemoFee: null,
    performingRightsOrganisationNumber: "",
    connectedToOrgId: null,
    serviceProvider: null,
    collaborationStatus: null,
    skillSet: [],
    principalInstruments: [],
    additionalInstruments: [],
    keyGenres: [],
    additionalGenres: [],
    vocalist: null,
    minimumDemoTurnaroundHours: null,
    previousWork: [],
    interestedProjects: [],
    talentNotes: [],
    awardNotes: [],
    internalNotes: [],
    createdByOrgId: "",
    showReel: [],
    documents: [],
  },
});
