import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";

const ArrowUpIcon = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
  fill = theme.colors.secondary.purple,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby="arrow-pointing-up"
    >
      <title id="arrow-pointing-up">{t("Sort descending")}</title>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6.23079043,11.7064759 C6.24322127,11.5276083 6.3178063,11.3522514 6.45454552,11.2155122 L6.45454552,11.2155122 L11.1752284,6.49482929 C11.4760547,6.194003 11.9637905,6.194003 12.2646168,6.49482929 C12.5654431,6.79565558 12.5654431,7.28339136 12.2646168,7.58421765 L12.2646168,7.58421765 L8.94179043,10.9062096 L16.9988957,10.9062275 C17.4243283,10.9062275 17.7692096,11.2511088 17.7692096,11.6765414 C17.7692096,12.101974 17.4243283,12.4468553 16.9988957,12.4468553 L8.75679043,12.4462096 L12.2646168,15.9542015 C12.5654431,16.2550278 12.5654431,16.7427636 12.2646168,17.0435899 C11.9637905,17.3444161 11.4760547,17.3444161 11.1752284,17.0435899 L6.45454552,12.322907 C6.3178063,12.1861677 6.24322127,12.0108109 6.23079043,11.8319433 Z"
        transform="rotate(90 12 11.77)"
      />
    </svg>
  );
};

export default ArrowUpIcon;
