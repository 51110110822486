import { IMonetaryValue } from "src/interfaces/monetary-value";

export interface IOption {
  label: string;
  value: string;
}
export interface IAllProjectTypes {
  types: IOption[];
  statuses: IOption[];
  regions: IOption[];
}
export enum ServiceFormType {
  Add = "Add",
  Edit = "Edit",
  Delete = "Delete",
}

export interface IThirdParty {
  id: string;
  organisationId: string;
  name: string;
}
