/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { css, SerializedStyles } from "@emotion/react";
import { StylesConfig } from "react-select";
import theme from "src/theme";

type MyOptionType = {
  label: string;
  value: string;
};

export const CustomSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    fontSize: 16,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: 16,
    flexWrap: "nowrap",
  }),
};
export const customStylesForProjectLeads: StylesConfig<MyOptionType> = {
  menu: (provided) => ({
    ...provided,
    fontSize: 16,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
    marginTop: -8,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 4,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
    color: `${theme.colors.primary.blue}`,

    ":hover": {
      color: `${theme.colors.secondary.lightGray}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: "auto",
    height: 36,
  }),
};
export default {
  modalTitle: css`
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    line-height: 1.75rem !important;
    color: ${theme.colors.secondary.black} !important;
    margin-bottom: 1.5rem !important;
    margin-top: 0 !important;
  `,
  basicInputContainer: css`
    margin-bottom: 0.5rem;
  `,
  filterDivider: css`
    margin: 1rem 0;
  `,
  sectionTitle: css`
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    line-height: 1.75rem !important;
    color: ${theme.colors.secondary.blueOnBlack} !important;
    margin-bottom: 1rem !important;
  `,
  formItem: css`
    margin-bottom: 0 !important;
  `,
  basicInputLabel: css`
    font-size: 0.875rem;
    line-height: 1.55rem;
    font-weight: 400;
  `,
  filterContainer: css`
    zoom: 95%;
  `,
  clientContainer: css`
    display: flex;
    flex-direction: row;
    > div {
      flex: 1 1 0;
      width: 0;
    }
    column-gap: 1.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
  `,
  mobileSectionContainer: css`
    display: flex;
    flex-direction: column;
  `,
  row: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0.1rem !important;

    .floatingLabel {
      margin-bottom: 0;
      label {
        top: 0.5rem;
        color: hsl(0, 0%, 50%);
      }
      .active-focus {
        top: 4px;
      }
    }
  `,
  rightInput: css`
    margin-left: auto;
  `,
  input: css`
    height: 38px !important;
  `,
  button: css`
    float: right;
  `,
  secondaryButton: css`
    border: 1px solid ${theme.colors.primary.blue} !important;
    margin-right: 16px;
  `,
  tertiaryButton: css`
    float: left;
  `,
  rightControls: css`
    height: 15px;
    float: right;
  `,
  controls: css`
    .ant-row {
      margin-bottom: 0;
    }
  `,
  errorMessage: css`
    min-height: 32px;
    color: ${theme.colors.secondary.alertRed};
  `,
  clearText: css`
    display: inline-block;
    color: ${theme.colors.primary.blue};
    margin-right: 16px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    height: 40px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  `,
  selectDropdownText: css`
    cursor: pointer;
    margin-left: 18px;
    font-family: ${theme.fonts.sofiaPro};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  emailLabel: css`
    color: ${theme.colors.background.lightGray};
  `,
};
