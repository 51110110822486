import React, { Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import MobileDrawer from "src/components/mobile-drawer";
import Button from "src/components/button";
import generateDrawerContent from "./drawers";
import style from "../styles";
import { DrawerTypes, IProjectProps, IProjectTableSort } from "../interfaces";
import { ProjectStatus } from "../../project/interfaces";

interface IProps {
  mobileDrawerVisible: boolean;
  currentActiveDrawer: DrawerTypes;
  handleDrawerToggle: () => void;
  handleSort: (params: IProjectTableSort) => void;
  isMobile: boolean;
  sortOptions: IProjectTableSort;
  handleDeleteProject: (id: string) => void;
  handleMobileDeleteProjectClick: () => void;
  handleMobileEditProjectClick: (id: string) => void;
  handleCreateProject: (name: string, status: string) => void;
}

const ProjectsMobileControls = ({
  mobileDrawerVisible,
  handleDrawerToggle,
  handleSort,
  currentActiveDrawer,
  isMobile,
  sortOptions,
  handleDeleteProject,
  handleMobileDeleteProjectClick,
  handleMobileEditProjectClick,
  handleCreateProject,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const Drawer = generateDrawerContent(
    currentActiveDrawer,
    handleSort,
    sortOptions,
    handleDeleteProject,
    handleMobileDeleteProjectClick,
    handleMobileEditProjectClick,
    handleDrawerToggle
  );

  return (
    <Fragment>
      {isMobile && (
        <Button
          noLabelTranslation
          css={style.stickyFooterButton}
          ariaLabel={t("ProjectsPage##Create new project")}
          onClick={() =>
            handleCreateProject("Untitled Project", ProjectStatus.NewOpen)
          }
          data-testid="add-project-sticky-button"
        >
          {t("ProjectsPage##Create new project")}
        </Button>
      )}
      <MobileDrawer
        placement="bottom"
        onClose={handleDrawerToggle}
        open={mobileDrawerVisible}
        key="bottom"
        closable={false}
        data-testid="mobile-drawer-container"
      >
        {Drawer}
      </MobileDrawer>
    </Fragment>
  );
};

export default ProjectsMobileControls;
