import { cloneDeep } from "lodash";
import { DropResult } from "react-beautiful-dnd";
import { ISavedUserColumn } from "src/api/user-filters/interfaces";

const mapColumnsInCheckboxLabels = (
  columns: ISavedUserColumn[] | null,
  allColumns: string[]
): ISavedUserColumn[] => {
  if (!columns) {
    return allColumns.map((name) => ({
      name,
      enabled: true,
    }));
  }

  let additionalColumns: ISavedUserColumn[] = [];
  if (allColumns.length > (columns ?? []).length) {
    const columnNames = columns.map(({ name }) => name);
    additionalColumns = allColumns
      .filter((columnName) => !columnNames.includes(columnName))
      .map((name) => ({
        name,
        enabled: true, // All newly added columns should be enabled by default
      }));
  }

  return columns.concat(additionalColumns);
};

export const skipDragAndDropSort = (result: DropResult): boolean => {
  return (
    !result.source ||
    !result.destination ||
    result.source.index === result.destination.index
  );
};

export const sortItemsAfterDragAndDrop = (
  result: DropResult,
  columnsData: ISavedUserColumn[]
): ISavedUserColumn[] => {
  const {
    source: { index: sourceIndex },
    destination,
  } = result;
  const destinationIndex = destination?.index;

  const items = cloneDeep(columnsData);
  const sourceItem = { ...items[sourceIndex] };

  items.splice(sourceIndex, 1);
  if (destinationIndex !== undefined) {
    items.splice(destinationIndex, 0, sourceItem);
  }

  return items;
};

export default mapColumnsInCheckboxLabels;
