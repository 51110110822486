import React, { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "../interfaces";

interface IProps extends IComponentIconProps {
  isChevronPointingDown: boolean;
  viewBox?: string;
}

const Chevron = ({
  height = theme.svgSize.large,
  width = theme.svgSize.large,
  viewBox = `0 0 ${theme.svgSize.large} ${theme.svgSize.large}`,
  isChevronPointingDown,
}: IProps): ReactElement => {
  return (
    <>
      {isChevronPointingDown ? (
        <svg
          width={width}
          height={height}
          viewBox={viewBox}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2727 25.6994C18.6744 26.1002 19.3256 26.1002 19.7273 25.6994L30.6987 14.752C31.1004 14.3512 31.1004 13.7014 30.6987 13.3006C30.2971 12.8998 29.6458 12.8998 29.2441 13.3006L19 23.5223L8.75588 13.3006C8.3542 12.8998 7.70294 12.8998 7.30126 13.3006C6.89958 13.7014 6.89958 14.3512 7.30126 14.752L18.2727 25.6994Z"
            fill={theme.colors.primary.blue}
          />
        </svg>
      ) : (
        <svg
          width={width}
          height={height}
          viewBox={viewBox}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7273 13.3006C19.3256 12.8998 18.6744 12.8998 18.2727 13.3006L7.30126 24.248C6.89958 24.6488 6.89958 25.2986 7.30126 25.6994C7.70294 26.1002 8.3542 26.1002 8.75588 25.6994L19 15.4777L29.2441 25.6994C29.6458 26.1002 30.2971 26.1002 30.6987 25.6994C31.1004 25.2986 31.1004 24.6488 30.6987 24.248L19.7273 13.3006Z"
            fill={theme.colors.primary.blue}
          />
        </svg>
      )}
    </>
  );
};
export default Chevron;
