import { Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import STLoadingLogo from "src/components/st-loading-logo";
import { IProjectProps } from "../../interfaces";
import styles from "./styles";

interface IProps {
  activeProject?: IProjectProps;
  handleDrawerToggle: () => void;
  removeProject: (projectId: string) => void;
}

const DeleteProjectDrawer = ({
  activeProject,
  handleDrawerToggle,
  removeProject,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const handleDeleteProject = () => {
    if (activeProject) {
      removeProject(activeProject.id);
    }
    handleDrawerToggle();
  };

  if (activeProject) {
    return (
      <div data-testid="delete-project-drawer">
        <div css={styles.drawerContentContainer}>
          <Typography.Title css={styles.addClientHeaderText}>
            {t("deleteClient##Are you sure?")}
          </Typography.Title>
          <div>
            <Typography.Text>
              <Trans i18nKey="ProjectsPage##DeleteProject##confirmationPrompt">
                {{ projectName: activeProject.name }}
              </Trans>
            </Typography.Text>
          </div>
        </div>
        <div aria-label={t("Cancel")} css={styles.drawerButtonContainer}>
          <button
            type="button"
            css={styles.mobileButtonContainerCancel}
            onClick={handleDrawerToggle}
          >
            <div css={styles.iconContainer}>{t("Cancel")}</div>
          </button>
          <button
            aria-label={t("ProjectsPage##DeleteProject##Delete project")}
            type="button"
            css={styles.mobileButtonContainerPrimary}
            onClick={handleDeleteProject}
            data-testid="delete-project-drawer-button"
          >
            <div css={styles.iconContainer}>
              {t("ProjectsPage##DeleteProject##Delete project")}
            </div>
          </button>
        </div>
      </div>
    );
  }

  return <STLoadingLogo />;
};

export default DeleteProjectDrawer;
