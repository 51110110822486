import { OrganisationTypes } from "@songtradr/vinyl-common/lib/organisation";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

interface IProps {
  onChange: (e: string) => void;
}

const OrganisationTypeSelect = ({ onChange }: IProps): ReactElement => {
  const { t } = useTranslation();

  const options = Object.keys(OrganisationTypes).map((key) => ({
    value: key,
    label: t(`organisationTypes##${key}`),
  }));

  return (
    <Select
      options={options}
      getPopupContainer={(triggerNode) => triggerNode as HTMLElement}
      onChange={(value) => onChange(value as string)}
    />
  );
};

export default OrganisationTypeSelect;
