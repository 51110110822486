import React, { ReactElement, useCallback, useMemo } from "react";
import { Form, FormInstance, Typography } from "antd";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import {
  IFeeSplitDetailState,
  IFinalTrackSection,
  TalentType,
} from "src/pages/projects/project/interfaces";
import { Currencies, Operation } from "src/constants";
import { useTranslation } from "react-i18next/";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import * as uuid from "uuid";
import { formatCurrency } from "src/utils/currency";
import CurrencyAndPrice from "src/components/currency-and-price";
import isValidPriceValue from "src/utils/validate-price";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import getValuesFromTalent from "src/utils/getValuesFromTalent";
import mainStyles from "../../../../styles";
import FinalTrackFeeSplit, {
  FeeSplitName,
} from "../../../multi-tracks/components/fee-split";
import { IOption } from "../../../account-information/types";
import styles from "../final-track-details/styles";
import FeeSplitWithTalent from "../../../multi-tracks/components/fee-split-with-talent";

interface IProps {
  track?: IFinalTrackSection;
  masterFeeSplits: IFeeSplitDetailState[];
  publisherFeeSplits: IFeeSplitDetailState[];
  writerFeeSplits: IFeeSplitDetailState[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  validateWriterFeeField: boolean;
  validateWriterFields: boolean;
  validateTrackOwnership: boolean;
  talentsResponse?: IContactsResponse;
  areTalentsLoading: boolean;
  agenciesAndContacts: IAgencyWithContactsResults[];
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onSelectedTrackChange: (track?: IFinalTrackSection) => void;
  onMasterFeeSplitsChange: (value: IFeeSplitDetailState[]) => void;
  onPublisherFeeSplitsChange: (value: IFeeSplitDetailState[]) => void;
  onWriterFeeSplitsChange: (value: IFeeSplitDetailState[]) => void;
}

export type FeeSplitFields =
  | "masterFeeSplits"
  | "publisherFeeSplits"
  | "writerFeeSplits";

enum FeeSplits {
  masterFeeSplits = "masterFeeSplits",
  publisherFeeSplits = "publisherFeeSplits",
  writerFeeSplits = "writerFeeSplits",
}

const FinalTrackOwnership = ({
  track,
  masterFeeSplits,
  publisherFeeSplits,
  writerFeeSplits,
  form,
  areTalentsLoading,
  validateWriterFeeField,
  validateWriterFields,
  agenciesAndContacts,
  validateTrackOwnership,
  talentsResponse,
  onScrollTalents,
  onResetNarrowSearch,
  onUpdateNarrowSearchText,
  onSelectedTrackChange,
  onMasterFeeSplitsChange,
  onPublisherFeeSplitsChange,
  onWriterFeeSplitsChange,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  useWindowSize();

  const currencyOptions: IOption[] = useMemo(
    () =>
      Object.values(Currencies).map((value) => {
        return {
          value: value.code,
          label: value.displayText,
        };
      }),
    []
  );

  const getCurrency = useCallback(
    (currentCurrency: string) =>
      currencyOptions.find((option) => option.value === currentCurrency),
    [currencyOptions]
  );

  const handleUpdateFee = useCallback(
    (field: string, updatedFee: IMonetaryValue) => {
      if (track) {
        onSelectedTrackChange({ ...track, [field]: updatedFee });
        form.setFieldsValue({
          [`${field}Currency`]: getCurrency(updatedFee.currency),
          [`${field}Price`]: isValidPriceValue(updatedFee.value)
            ? formatCurrency(updatedFee.value)
            : undefined,
        });
      }
    },
    [form, getCurrency, onSelectedTrackChange, track]
  );

  const handleCreateNewRow = useCallback(
    (fieldName: FeeSplitFields) => {
      const newFeeSplit: IFeeSplitDetailState = {
        contactReferences: [
          {
            name: "",
            email: "",
            agencyWritingPartnership: "",
            groupByAgency: false,
            type: TalentType.OpenForm,
          },
        ],
        split: "",
        isNew: true,
        taxWithholdingFee: {
          currency: Currencies.USD.code,
          value: 0,
        },
        bankFee: {
          currency: Currencies.USD.code,
          value: 0,
        },
        key: uuid.v4(),
        paythrough: false,
      };
      if (fieldName === "writerFeeSplits") {
        delete newFeeSplit.taxWithholdingFee;
        delete newFeeSplit.bankFee;
        delete newFeeSplit.paythrough;
      }
      switch (fieldName) {
        case "masterFeeSplits":
          onMasterFeeSplitsChange([...masterFeeSplits, newFeeSplit]);
          break;
        case "publisherFeeSplits":
          onPublisherFeeSplitsChange([...publisherFeeSplits, newFeeSplit]);
          break;
        case "writerFeeSplits":
          onWriterFeeSplitsChange([...writerFeeSplits, newFeeSplit]);
          break;
        default:
          break;
      }
    },
    [
      masterFeeSplits,
      onMasterFeeSplitsChange,
      onPublisherFeeSplitsChange,
      onWriterFeeSplitsChange,
      publisherFeeSplits,
      writerFeeSplits,
    ]
  );

  const handleUpdateRow = useCallback(
    (
      index: number,
      updatedRow: IFeeSplitDetailState,
      fieldName: FeeSplitFields
    ) => {
      switch (fieldName) {
        case "masterFeeSplits": {
          const masterArray = [...masterFeeSplits];
          masterArray.splice(index, 1, updatedRow);
          onMasterFeeSplitsChange(masterArray);
          break;
        }
        case "publisherFeeSplits": {
          const publisherArray = [...publisherFeeSplits];
          publisherArray.splice(index, 1, updatedRow);
          onPublisherFeeSplitsChange(publisherArray);
          break;
        }
        case "writerFeeSplits": {
          const writerArray = [...writerFeeSplits];
          writerArray.splice(index, 1, updatedRow);
          onWriterFeeSplitsChange(writerArray);
          break;
        }
        default:
          break;
      }
    },
    [
      masterFeeSplits,
      onMasterFeeSplitsChange,
      onPublisherFeeSplitsChange,
      onWriterFeeSplitsChange,
      publisherFeeSplits,
      writerFeeSplits,
    ]
  );

  const handleDeleteRow = useCallback(
    (index: number, fieldName: FeeSplitFields) => {
      switch (fieldName) {
        case "masterFeeSplits": {
          const masterArray = [...masterFeeSplits];
          masterArray.splice(index, 1);
          onMasterFeeSplitsChange(masterArray);
          break;
        }
        case "publisherFeeSplits": {
          const publisherArray = [...publisherFeeSplits];
          publisherArray.splice(index, 1);
          onPublisherFeeSplitsChange(publisherArray);
          break;
        }
        case "writerFeeSplits": {
          const writerArray = [...writerFeeSplits];
          writerArray.splice(index, 1);
          onWriterFeeSplitsChange(writerArray);
          break;
        }
        default:
          break;
      }
    },
    [
      masterFeeSplits,
      onMasterFeeSplitsChange,
      onPublisherFeeSplitsChange,
      onWriterFeeSplitsChange,
      publisherFeeSplits,
      writerFeeSplits,
    ]
  );

  const handlePerformWriterFeeSplitOperation = useCallback(
    (
      operation: Operation,
      data: { index: number; value?: IFeeSplitDetailState }
    ) => {
      const writerFeeSplitsRow = {
        contactReferences: [
          getValuesFromTalent(data.value?.contactReferences?.[0]),
        ],
        split: data.value?.split,
        isNew: operation === Operation.add,
        key: data.value && data.value.key ? data.value.key : uuid.v4(),
      };
      if (operation === Operation.add) {
        handleCreateNewRow(FeeSplits.writerFeeSplits);
      } else if (operation === Operation.remove) {
        handleDeleteRow(data.index, FeeSplits.writerFeeSplits);
      } else if (operation === Operation.update) {
        handleUpdateRow(
          data.index,
          writerFeeSplitsRow,
          FeeSplits.writerFeeSplits
        );
      }
    },
    [handleCreateNewRow, handleDeleteRow, handleUpdateRow]
  );

  const handleFeeSplitOperation = useCallback(
    (
      operation: Operation,
      data: { index: number; value?: IFeeSplitDetailState },
      feeSplits: FeeSplits
    ) => {
      const feeSplitsRow: IFeeSplitDetailState = {
        contactReferences: [
          getValuesFromTalent(data.value?.contactReferences?.[0]),
        ],
        split: data.value?.split,
        taxWithholdingFee: data.value?.taxWithholdingFee,
        bankFee: data.value?.bankFee,
        paythrough: data.value?.paythrough,
        isNew: operation === Operation.add,
        key: data.value && data.value.key ? data.value.key : uuid.v4(),
      };

      if (operation === Operation.add) {
        handleCreateNewRow(feeSplits);
      } else if (operation === Operation.remove) {
        handleDeleteRow(data.index, feeSplits);
      } else if (operation === Operation.update) {
        handleUpdateRow(data.index, feeSplitsRow, feeSplits);
      }
    },
    [handleCreateNewRow, handleDeleteRow, handleUpdateRow]
  );

  const initialMasterFeeSplits = useMemo(() => {
    return masterFeeSplits
      .map((splits, index) => {
        const taxWithholdingFeeCurrency =
          splits.taxWithholdingFee?.currency ?? Currencies.USD.code;
        const taxWithholdingFeePrice = splits.taxWithholdingFee?.value ?? "";
        const bankFeeCurrency = splits.bankFee?.currency ?? Currencies.USD.code;
        const bankFeePrice = splits.bankFee?.value ?? "";

        return {
          [`masterFeeSplits${index}contactReferences0Name`]:
            splits.contactReferences?.[0]?.name ?? "",
          [`masterFeeSplits${index}contactReferences0Email`]:
            splits.contactReferences?.[0]?.email ?? "",
          [`masterFeeSplits${index}Split`]: splits.split ?? "",
          [`masterFeeSplits${index}TaxWithholdingFeeCurrency`]: getCurrency(
            taxWithholdingFeeCurrency
          ),
          [`masterFeeSplits${index}TaxWithholdingFeePrice`]: isValidPriceValue(
            taxWithholdingFeePrice
          )
            ? formatCurrency(taxWithholdingFeePrice)
            : undefined,
          [`masterFeeSplits${index}BankFeeCurrency`]: getCurrency(
            bankFeeCurrency
          ),
          [`masterFeeSplits${index}BankFeePrice`]: isValidPriceValue(
            bankFeePrice
          )
            ? formatCurrency(bankFeePrice)
            : undefined,
        };
      })
      .reduce((cv, pv) => ({ ...pv, ...cv }), {});
  }, [getCurrency, masterFeeSplits]);

  const initialPublisherFeeSplits = useMemo(() => {
    return publisherFeeSplits
      .map((splits, index) => {
        const taxWithholdingFeeCurrency =
          splits.taxWithholdingFee?.currency ?? Currencies.USD.code;
        const taxWithholdingFeePrice = splits.taxWithholdingFee?.value ?? "";
        const bankFeeCurrency = splits.bankFee?.currency ?? Currencies.USD.code;
        const bankFeePrice = splits.bankFee?.value ?? "";

        return {
          [`publisherFeeSplits${index}contactReferences0Name`]:
            splits.contactReferences?.[0]?.name ?? "",

          [`publisherFeeSplits${index}contactReferences0Email`]:
            splits.contactReferences?.[0]?.email ?? "",

          [`publisherFeeSplits${index}Split`]: splits.split ?? "",
          [`publisherFeeSplits${index}TaxWithholdingFeeCurrency`]: getCurrency(
            taxWithholdingFeeCurrency
          ),
          [`publisherFeeSplits${index}TaxWithholdingFeePrice`]: isValidPriceValue(
            taxWithholdingFeePrice
          )
            ? formatCurrency(taxWithholdingFeePrice)
            : undefined,
          [`publisherFeeSplits${index}BankFeeCurrency`]: getCurrency(
            bankFeeCurrency
          ),
          [`publisherFeeSplits${index}BankFeePrice`]: isValidPriceValue(
            bankFeePrice
          )
            ? formatCurrency(bankFeePrice)
            : undefined,
        };
      })
      .reduce((cv, pv) => ({ ...pv, ...cv }), {});
  }, [getCurrency, publisherFeeSplits]);

  const initialWriterFeeSplits = useMemo(() => {
    return writerFeeSplits
      .map((splits, index) => {
        return {
          [`writerFeeSplits${index}contactReferences0Name`]:
            splits.contactReferences?.[0]?.name ?? "",

          [`writerFeeSplits${index}contactReferences0Email`]:
            splits.contactReferences?.[0]?.email ?? "",
          [`writerFeeSplits${index}contactReferences0ipi`]:
            splits.contactReferences?.[0]?.ipi ?? "",

          [`writerFeeSplits${index}Split`]: splits.split ?? "",
        };
      })
      .reduce((cv, pv) => ({ ...pv, ...cv }), {});
  }, [writerFeeSplits]);

  const masterFeeCurrency = useMemo(
    () => track?.masterFee?.currency ?? Currencies.USD.code,
    [track?.masterFee?.currency]
  );
  const masterFeePrice = useMemo(() => track?.masterFee?.value ?? "", [
    track?.masterFee?.value,
  ]);
  const publisherFeeCurrency = useMemo(
    () => track?.publisherFee?.currency ?? Currencies.USD.code,
    [track?.publisherFee?.currency]
  );
  const publisherFeePrice = useMemo(() => track?.publisherFee?.value ?? "", [
    track?.publisherFee?.value,
  ]);
  const writerFeeCurrency = useMemo(
    () => track?.writerFee?.currency ?? Currencies.USD.code,
    [track?.writerFee?.currency]
  );
  const writerFeePrice = useMemo(() => track?.writerFee?.value ?? "", [
    track?.writerFee?.value,
  ]);

  const unionFeeCurrency = useMemo(
    () => track?.unionFee?.currency ?? Currencies.USD.code,
    [track?.unionFee?.currency]
  );
  const unionFeePrice = useMemo(() => track?.unionFee?.value ?? "", [
    track?.unionFee?.value,
  ]);

  const initialFormValues = useMemo(
    () => ({
      masterFeeCurrency: getCurrency(masterFeeCurrency),
      masterFeePrice: isValidPriceValue(masterFeePrice)
        ? formatCurrency(masterFeePrice)
        : undefined,
      ...initialMasterFeeSplits,
      publisherFeeCurrency: getCurrency(publisherFeeCurrency),
      publisherFeePrice: isValidPriceValue(publisherFeePrice)
        ? formatCurrency(publisherFeePrice)
        : undefined,
      ...initialPublisherFeeSplits,
      writerFeeCurrency: getCurrency(writerFeeCurrency),
      writerFeePrice: isValidPriceValue(writerFeePrice)
        ? formatCurrency(writerFeePrice)
        : undefined,
      ...initialWriterFeeSplits,
      unionFeeCurrency: getCurrency(unionFeeCurrency),
      unionFeePrice: isValidPriceValue(unionFeePrice)
        ? formatCurrency(unionFeePrice)
        : undefined,
    }),
    [
      getCurrency,
      initialMasterFeeSplits,
      initialPublisherFeeSplits,
      initialWriterFeeSplits,
      masterFeeCurrency,
      masterFeePrice,
      publisherFeeCurrency,
      publisherFeePrice,
      writerFeeCurrency,
      writerFeePrice,
      unionFeeCurrency,
      unionFeePrice,
    ]
  );

  const getConfig = useCallback((feeSplitName: FeeSplitName) => {
    switch (feeSplitName) {
      case FeeSplitName.writerFee:
        return {
          totalFeeLabel: "ProjectsPage##musicSection##Total writer fee",
          nameLabel: "ProjectsPage##musicSection##Writer name",
          emailLabel: "ProjectsPage##musicSection##Writer email",
        };
      case FeeSplitName.publisherFee:
        return {
          totalFeeLabel: "ProjectsPage##musicSection##Total publisher fee",
          nameLabel: "ProjectsPage##musicSection##Publisher rightsholder",
          emailLabel: "ProjectsPage##musicSection##Publisher email",
        };
      default:
        return {
          totalFeeLabel: "ProjectsPage##musicSection##Total master fee",
          nameLabel: "ProjectsPage##musicSection##Master rightsholder",
          emailLabel: "ProjectsPage##musicSection##Master email",
        };
    }
  }, []);

  return (
    <div css={styles.musicSectionWithColumnsContainer}>
      <Form
        form={form}
        initialValues={initialFormValues}
        validateTrigger="onChange"
        scrollToFirstError
        css={styles.musicSectionWithColumnsContainer}
      >
        <div
          css={mainStyles.sectionFormContainer}
          data-testid="master-fee-section"
        >
          <Typography.Text css={mainStyles.trackTitleLabel}>
            {t("ProjectsPage##musicSection##Masters")}
          </Typography.Text>
          <FeeSplitWithTalent
            form={form}
            talentsResponse={talentsResponse}
            totalMonetaryValue={track?.masterFee}
            items={masterFeeSplits}
            agenciesAndContacts={agenciesAndContacts}
            propertyName={FeeSplitName.masterFee}
            validateFeeField={validateTrackOwnership}
            validateDetailsFields={validateTrackOwnership}
            areTalentsLoading={areTalentsLoading}
            labelConfig={getConfig(FeeSplitName.masterFee)}
            onPerformOperation={(...args) =>
              handleFeeSplitOperation(...args, FeeSplits.masterFeeSplits)
            }
            onScrollTalents={onScrollTalents}
            onResetNarrowSearch={onResetNarrowSearch}
            onUpdateNarrowSearchText={onUpdateNarrowSearchText}
            onUpdateFee={handleUpdateFee}
          />
        </div>
        <div
          css={mainStyles.sectionFormContainer}
          data-testid="publisher-fee-section"
        >
          <Typography.Text css={mainStyles.trackTitleLabel}>
            {t("ProjectsPage##musicSection##Publishers")}
          </Typography.Text>
          <FinalTrackFeeSplit
            form={form}
            totalMonetaryValue={track?.publisherFee}
            items={publisherFeeSplits}
            propertyName={FeeSplitName.publisherFee}
            validateFeeField={validateTrackOwnership}
            validateDetailsFields={validateTrackOwnership}
            labelConfig={getConfig(FeeSplitName.publisherFee)}
            onUpdateFee={handleUpdateFee}
            onPerformOperation={(...args) =>
              handleFeeSplitOperation(...args, FeeSplits.publisherFeeSplits)
            }
          />
        </div>
        <div
          css={mainStyles.sectionFormContainer}
          data-testid="writer-fee-section"
        >
          <Typography.Text css={mainStyles.trackTitleLabel}>
            {t("ProjectsPage##musicSection##Writers")}
          </Typography.Text>
          <FeeSplitWithTalent
            form={form}
            talentsResponse={talentsResponse}
            agenciesAndContacts={agenciesAndContacts}
            totalMonetaryValue={(track?.writerFee as unknown) as IMonetaryValue}
            items={writerFeeSplits}
            isWritersShareSection
            propertyName={FeeSplitName.writerFee}
            validateFeeField={validateWriterFeeField}
            validateDetailsFields={
              validateTrackOwnership && validateWriterFields
            }
            areTalentsLoading={areTalentsLoading}
            labelConfig={getConfig(FeeSplitName.writerFee)}
            onScrollTalents={onScrollTalents}
            onResetNarrowSearch={onResetNarrowSearch}
            onUpdateNarrowSearchText={onUpdateNarrowSearchText}
            onUpdateFee={handleUpdateFee}
            onPerformOperation={handlePerformWriterFeeSplitOperation}
          />
        </div>
        <div css={mainStyles.sectionFormContainer}>
          <div css={!isMobile && mainStyles.sizeOfQuarterDiv}>
            <CurrencyAndPrice
              currency={unionFeeCurrency}
              price={unionFeePrice}
              currencyFormItemName="unionFeeCurrency"
              priceFormItemName="unionFeePrice"
              onChange={handleUpdateFee}
              label={t("ProjectsPage##musicSection##Union fee")}
              testId="fee-currency"
              propertyName="unionFee"
              validateRequiredFields={false}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FinalTrackOwnership;
