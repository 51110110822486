import { getIsValidEmail } from "src/pages/team/components/org-invites/components/invite-members/email-validator";
import { array, object, string, number, AnyObject, TestContext } from "yup";

const requiredFieldMessage = "This is a required field";
const validEmailFieldMessage = "Email format is invalid";
const validPhoneFieldMessage = "Phone format is invalid";

const isPhoneNumberValid = (
  context: TestContext<AnyObject>,
  phoneNumber?: string
) => {
  if (!phoneNumber) {
    context.createError({
      path: context.path,
      message: requiredFieldMessage,
    });

    return false;
  }
  if (phoneNumber && phoneNumber.includes(".")) {
    context.createError({
      path: context.path,
      message: validPhoneFieldMessage,
    });

    return false;
  }
  return true;
};

const personalDetailsSchema = object().shape({
  name: string().required(requiredFieldMessage),
  email: string().required(requiredFieldMessage),
  phone: object().shape({
    countryCode: string().required(requiredFieldMessage),
    number: string().test(
      "Phone number validation",
      requiredFieldMessage,
      (value, context) => {
        return isPhoneNumberValid(context, value);
      }
    ),
  }),
  baseLocation: string().required(requiredFieldMessage),
  mainLanguage: string().required(requiredFieldMessage),
});

const publishingInformationSchema = object().shape({
  signedCMA: string().required(requiredFieldMessage),
  signedNDA: string().required(requiredFieldMessage),
  minimumDemoFee: object().shape({
    currency: string().required(requiredFieldMessage),
    value: number().required(requiredFieldMessage),
  }),
});

const aAndRSchema = object().shape({
  collaborationStatus: string().required(requiredFieldMessage),
  skillSet: array()
    .of(string().required(requiredFieldMessage))
    .min(1, requiredFieldMessage),
  principalInstruments: array()
    .of(string().required(requiredFieldMessage))
    .min(1, requiredFieldMessage),
  keyGenres: array()
    .of(string().required(requiredFieldMessage))
    .min(1, requiredFieldMessage),
  vocalist: string().required(requiredFieldMessage),
  minimumDemoTurnaroundHours: number().required(requiredFieldMessage),
});

const contactSchema = personalDetailsSchema
  .concat(publishingInformationSchema)
  .concat(aAndRSchema);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const requiredFieldValidator = () => {
  return [
    () => ({
      validator(_, value?: string) {
        if (!value || value.length === 0) {
          return Promise.reject(new Error(requiredFieldMessage));
        }
        return Promise.resolve();
      },
    }),
  ];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const requiredPhoneFieldValidator = () => {
  return [
    () => ({
      validator(_, value?: string) {
        if (!value || value.length === 0) {
          return Promise.reject(new Error(requiredFieldMessage));
        }
        if (value.includes(".")) {
          return Promise.reject(new Error(validPhoneFieldMessage));
        }
        return Promise.resolve();
      },
    }),
  ];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const emailValidator = () => {
  return [
    () => ({
      validator(_, value?: string) {
        if (value) {
          const isEmailValid = getIsValidEmail(value);

          if (!isEmailValid) {
            return Promise.reject(new Error(validEmailFieldMessage));
          }
        }
        return Promise.resolve();
      },
    }),
  ];
};

export default {
  personalDetailsSchema,
  publishingInformationSchema,
  aAndRSchema,
  contactSchema,
  requiredFieldValidator,
  requiredPhoneFieldValidator,
  emailValidator,
};
