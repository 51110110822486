import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { IContactAttachment, IContactForm } from "src/pages/contact/interfaces";
import useAuth from "src/auth/use-auth";
import getContact from "src/api/talent-hub/get-contact";
import { useHistory, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { IAgencyResponse, IAgencySearch } from "src/api/talent-hub/interfaces";
import getAgencies from "src/api/talent-hub/get-agencies";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import { cloneDeep } from "lodash";
import ContactContext from "./context";
import { InitialContactState, contactReducer } from "./store";
import { ContactActions } from "./types";
import mapResponseToContactForm, {
  getAttachmentsByType,
} from "./contactResponseMapper";

interface IContactProvider {
  children: ReactNode;
}

const ContactProvider = ({ children }: IContactProvider): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contactFound, setContactFound] = useState<boolean>(true);
  const [agenciesResponse, setAgenciesResponse] = useState<IAgencyResponse>();
  const [agenciesNextPage, setAgenciesNextPage] = useState("");
  const [areAgenciesLoading, setAreAgenciesLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [state, dispatch] = useImmerReducer(
    contactReducer,
    InitialContactState
  );
  const { getAccessToken, isAuthenticated, organisationId } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const storeContact = useCallback(
    (contactToStore: IContactForm) => {
      dispatch({
        type: ContactActions.STORE_CONTACT,
        value: { contact: contactToStore },
      });
    },
    [dispatch]
  );

  const storeAttachments = useCallback(
    (attachments: IContactAttachment[]) => {
      const attachmentsByType = getAttachmentsByType(attachments);
      dispatch({
        type: ContactActions.STORE_SHOW_REEL,
        value: {
          attachments: attachmentsByType.showReels,
        },
      });
      dispatch({
        type: ContactActions.STORE_DOCUMENTS,
        value: { attachments: attachmentsByType.documents },
      });
    },
    [dispatch]
  );

  const handleSetAgencies = useCallback(
    async (text: string, isReset: boolean, nextPage: string) => {
      const searchTextQuery: string = (text || searchText).trim();
      try {
        setAreAgenciesLoading(true);
        const agencyBody: IAgencySearch = {
          organisationId,
          includeTalents: false,
          searchText: searchTextQuery,
          pagination: {
            perPage: 40,
            startAfterAgency: nextPage,
          },
        };
        const accessToken = getAccessToken();

        const response = await getAgencies(accessToken, agencyBody);
        if (response) {
          setAgenciesResponse((prevAgencyResponse) => {
            const agencyResponseCopy = cloneDeep(response);
            let combinedPrevAndNewAgency = agencyResponseCopy?.results;
            if (prevAgencyResponse) {
              const previousContacts = prevAgencyResponse?.results;
              combinedPrevAndNewAgency = agencyResponseCopy.results.reduce(
                (prev, curr) => {
                  const isAgencyAlreadySet = previousContacts?.find(
                    (prevAgency) => prevAgency.agency === curr.agency
                  );
                  if (!isAgencyAlreadySet) {
                    prev.push(curr);
                  }
                  return prev;
                },
                [...previousContacts]
              );
              if (!isReset) {
                agencyResponseCopy.results = combinedPrevAndNewAgency;
              }
            }

            return agencyResponseCopy;
          });
          setAgenciesNextPage(response.nextPaginationToken ?? "");
        }
      } catch (error) {
        log(DataDogLogTypes.ERROR, "Error creating project", error);
      } finally {
        setAreAgenciesLoading(false);
      }
    },
    [getAccessToken, organisationId, searchText]
  );

  useEffect(() => {
    const fetchContact = async () => {
      if (isAuthenticated && id) {
        try {
          setIsLoading(true);
          const accessToken = getAccessToken();
          const response = await getContact(accessToken, id, organisationId);
          if (response) {
            const contact = mapResponseToContactForm(response);
            storeContact(contact);
            storeAttachments(response.attachments ?? []);
          }
        } catch (reason) {
          const error = reason as AxiosError;
          if (error.response?.status === 403) {
            history.push("/talents");
          }
          if (error.response?.status === 404) {
            setContactFound(false);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };

    void fetchContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToken, isAuthenticated, t, id]);

  const handleAgenciesPageChange = useCallback(async () => {
    await handleSetAgencies(searchText, false, agenciesNextPage);
  }, [agenciesNextPage, handleSetAgencies, searchText]);

  const handleUpdateNarrowSearchText = useCallback(
    async (text: string) => {
      await handleSetAgencies(text, true, "");
    },
    [handleSetAgencies]
  );

  const handleResetAgencies = useCallback(() => {
    setSearchText("");
    setAgenciesResponse(undefined);
    setAgenciesNextPage("");
  }, []);

  return (
    <ContactContext.Provider
      value={{
        contact: state,
        isLoading,
        contactFound,
        areAgenciesLoading,
        agencies: agenciesResponse?.results ?? [],
        storeContact,
        handleAgenciesPageChange,
        storeAttachments,
        handleResetAgencies,
        handleUpdateNarrowSearchText,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
export default ContactProvider;
