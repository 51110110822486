import { Divider, Form, Input, Typography } from "antd";
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "src/components/button";
import { useTranslation } from "react-i18next";
import useAuth from "src/auth/use-auth";
import STLoadingLogo from "src/components/st-loading-logo";
import ValidationError from "src/components/validation-error";
import { useImmer } from "use-immer";
import { OrganisationRequesterRoles } from "@songtradr/vinyl-common";
import selfOrganisations, {
  IOrganisationSearchResponse,
} from "src/api/organisation/self";
import { ErrorToast } from "src/components/toast-notification";
import Select from "react-select";
import { getIsMobile } from "@songtradr/spa-common";
import countries from "i18n-iso-countries";
import { IProfileUpdateForm } from "./interfaces";
import styles, { selectStyles } from "./styles";
import { IOrganisationMemberProps } from "../team/interfaces";

const FIELDS = {
  firstName: "firstName",
  lastName: "lastName",
  phoneNumber: "phoneNumber",
  jobTitle: "jobTitle",
  country: "country",
  companyName: "companyName",
};

interface IOption {
  value: string;
  label: string;
}

interface IFormState {
  companyName: string | undefined;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  jobTitle?: IOption;
  country?: IOption;
}

interface IProps {
  submitForm: (formData: IProfileUpdateForm) => Promise<void>;
  isLoading: boolean;
  isSavePending: boolean;
  orgMember: IOrganisationMemberProps | undefined;
  resetPassword: () => void;
}

const Profile = ({
  submitForm,
  isLoading,
  isSavePending,
  orgMember,
  resetPassword,
}: IProps): ReactElement => {
  const {
    fetchWrapper,
    logout,
    switchOrg,
    organisationId,
    hasMultipleOrgs,
  } = useAuth();

  const [allOrganisations, setAllOrganisations] = useState<IOption[] | []>([]);
  const isMobile = getIsMobile();
  const { t } = useTranslation();

  const [formState, setFormState] = useImmer<IFormState>({} as IFormState);

  const jobTitleOptions: IOption[] = Object.keys(
    OrganisationRequesterRoles
  ).map((enumKey) => {
    return {
      value: enumKey.toString(),
      label: t(`requesterRoles##${enumKey}`),
    };
  });

  const countryOptions = useMemo(
    () =>
      Object.values(countries.getNames("en", { select: "official" })).map(
        (countryName) => {
          return {
            value: countryName,
            label: countryName,
          };
        }
      ),
    []
  );

  useEffect(() => {
    setFormState((draft) => {
      draft.firstName = orgMember?.firstName as string;
      draft.lastName = orgMember?.lastName as string;
      draft.companyName = orgMember?.companyName as string;
      draft.phoneNumber = orgMember?.phoneNumber as string;
      draft.jobTitle = {
        value: orgMember?.jobTitle,
        label: orgMember?.jobTitle,
      } as IOption;
      draft.country = {
        value: orgMember?.country,
        label: orgMember?.country,
      } as IOption;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgMember]);

  const handleSelectValueChange = (
    property: "jobTitle" | "country",
    data: IOption
  ) => {
    setFormState((draft) => {
      draft[property] = data;
    });
  };

  const handleSubmit = useCallback(() => {
    const formData: IProfileUpdateForm = {
      firstName: formState.firstName ?? "",
      lastName: formState.lastName ?? "",
      jobTitle: formState.jobTitle?.value,
      phoneNumber: formState.phoneNumber,
      country: formState.country?.value,
      companyName: formState.companyName ?? "",
    };

    void submitForm(formData);
  }, [formState, submitForm]);

  useEffect(() => {
    if (hasMultipleOrgs) {
      const fetchOrganisations = async () => {
        return fetchWrapper<IOrganisationSearchResponse | null>(
          selfOrganisations
        );
      };

      fetchOrganisations()
        .then((response) => {
          if (response && response.items) {
            setAllOrganisations(
              response.items.map((org) => {
                return {
                  value: org.id,
                  label: org.name,
                };
              })
            );
          }
        })
        .catch(() => {
          ErrorToast(
            "profile-page-get-organisations",
            "There was a problem retrieving organisations data. Please try again."
          );
        });
    }
  }, [fetchWrapper, hasMultipleOrgs]);

  const jobTitleValue = useMemo(() => {
    return {
      label: formState.jobTitle,
      value: formState.jobTitle,
    };
  }, [formState.jobTitle]);

  const countryValue = useMemo(() => {
    return {
      label: formState.country,
      value: formState.country,
    };
  }, [formState.country]);

  return (
    <Fragment>
      <div css={styles.profileStickyHeader}>
        <div css={styles.profileUpperHeader}>
          <Typography.Title css={styles.typographyTitle}>
            {t("profile")}
          </Typography.Title>
        </div>
      </div>
      {isLoading ? (
        <STLoadingLogo pageCentered />
      ) : (
        <>
          {hasMultipleOrgs && (
            <div css={styles.formContainer}>
              <div css={styles.heading}>
                <Typography.Title>Change organisation</Typography.Title>
              </div>
              <div css={styles.changeOrgContainer}>
                <div css={styles.basicInputContainer}>
                  <Typography.Text css={styles.basicInputLabel}>
                    {t("Select organisation")}
                  </Typography.Text>
                </div>
                <Select
                  onChange={async (data) => {
                    await switchOrg((data as IOption).value);
                  }}
                  options={allOrganisations}
                  data-testid="org-select"
                  styles={selectStyles}
                  isSearchable
                  placeholder=""
                  value={{
                    label: orgMember?.companyName,
                    value: organisationId,
                  }}
                />
              </div>
            </div>
          )}

          <div css={styles.formContainer}>
            <div css={styles.heading}>
              <Typography.Title>
                {t("UserProfilePage##Hi")} {orgMember?.firstName},
              </Typography.Title>
              <Typography.Paragraph>{orgMember?.email}</Typography.Paragraph>
            </div>

            <Form layout="vertical" initialValues={formState}>
              <div css={styles.profileContainer}>
                <div>
                  <div css={styles.basicInputContainer}>
                    <Typography.Text css={styles.basicInputLabel}>
                      {t("UserProfilePage##Organisation name")}
                    </Typography.Text>
                  </div>
                  <Form.Item
                    name={FIELDS.companyName}
                    css={styles.formItem}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: (
                          <ValidationError>
                            {t(
                              "UserProfilePage##validation##Please provide your organisation name"
                            )}
                          </ValidationError>
                        ),
                      },
                    ]}
                  >
                    <Input
                      disabled
                      onBlur={(e) =>
                        setFormState((draft) => {
                          draft.companyName = e.target.value;
                        })
                      }
                    />
                  </Form.Item>
                </div>

                <div>
                  <div css={styles.basicInputContainer}>
                    <Typography.Text css={styles.basicInputLabel}>
                      {t("UserProfilePage##First name")}
                    </Typography.Text>
                  </div>
                  <Form.Item
                    name={FIELDS.firstName}
                    css={styles.formItem}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: (
                          <ValidationError>
                            {t(
                              "UserProfilePage##validation##Please provide your first name"
                            )}
                          </ValidationError>
                        ),
                      },
                    ]}
                  >
                    <Input
                      onBlur={(e) =>
                        setFormState((draft) => {
                          draft.firstName = e.target.value;
                        })
                      }
                    />
                  </Form.Item>
                </div>
                <div>
                  <div css={styles.basicInputContainer}>
                    <Typography.Text css={styles.basicInputLabel}>
                      {t("UserProfilePage##Last name")}
                    </Typography.Text>
                  </div>
                  <Form.Item
                    name={FIELDS.lastName}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: (
                          <ValidationError>
                            {t(
                              "UserProfilePage##validation##Please provide your last name"
                            )}
                          </ValidationError>
                        ),
                      },
                    ]}
                    css={styles.formItem}
                  >
                    <Input
                      onBlur={(e) =>
                        setFormState((draft) => {
                          draft.lastName = e.target.value;
                        })
                      }
                    />
                  </Form.Item>
                </div>

                <div>
                  <div css={styles.basicInputContainer}>
                    <Typography.Text css={styles.basicInputLabel}>
                      {t("UserProfilePage##Phone")}
                    </Typography.Text>
                  </div>

                  <Form.Item name={FIELDS.phoneNumber} css={styles.formItem}>
                    <Input
                      onBlur={(e) =>
                        setFormState((draft) => {
                          draft.phoneNumber = e.target.value;
                        })
                      }
                    />
                  </Form.Item>
                </div>
                <div>
                  <div css={styles.basicInputContainer}>
                    <Typography.Text css={styles.basicInputLabel}>
                      {t("UserProfilePage##Job title")}
                    </Typography.Text>
                  </div>
                  <Form.Item name={FIELDS.jobTitle} css={styles.formItem}>
                    <Select
                      onChange={(data) => {
                        if (data) {
                          handleSelectValueChange("jobTitle", data as IOption);
                        }
                      }}
                      options={jobTitleOptions}
                      styles={selectStyles}
                      isSearchable
                      placeholder=""
                      value={jobTitleValue}
                    />
                  </Form.Item>
                </div>
                <div>
                  <div css={styles.basicInputContainer}>
                    <Typography.Text css={styles.basicInputLabel}>
                      {t("UserProfilePage##Country")}
                    </Typography.Text>
                  </div>
                  <Form.Item name={FIELDS.country} css={styles.formItem}>
                    <Select
                      onChange={(data) => {
                        if (data) {
                          handleSelectValueChange("country", data as IOption);
                        }
                      }}
                      options={countryOptions}
                      styles={selectStyles}
                      isSearchable
                      placeholder=""
                      value={countryValue}
                    />
                  </Form.Item>
                </div>
                <Divider css={styles.profileDivider} />
                <div>
                  <Form.Item css={styles.submit}>
                    <Button
                      ariaLabel={t("UserProfilePage##Update details")}
                      type="primary"
                      block
                      size="large"
                      loading={isSavePending}
                      noLabelTranslation
                      onClick={handleSubmit}
                    >
                      {t("UserProfilePage##Update details")}
                    </Button>
                  </Form.Item>
                  <Typography.Paragraph>
                    <a
                      role="link"
                      onClick={resetPassword}
                      onKeyDown={resetPassword}
                      css={styles.link}
                      tabIndex={0}
                    >
                      {t("UserProfilePage##Reset password")}
                    </a>
                    {", "}
                    {t("UserProfilePage##EmailInstructions")}
                  </Typography.Paragraph>
                </div>
              </div>
            </Form>
            {isMobile && <Divider />}
            <Button
              ariaLabel={t("Sign out")}
              type="tertiary-purple"
              htmlType="submit"
              block
              size="large"
              onClick={logout}
              noLabelTranslation
            >
              {t("Sign out")}
            </Button>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Profile;
