import axios, { CancelTokenSource } from "axios";
import { eApplicationType } from "src/interfaces/auth";
import {
  IMemberInvite,
  IMembersInviteResponse,
  IOrganisationInviteProps,
  IOrganisationItem,
  IOrganisationMembersResponse,
} from "src/pages/team/interfaces";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { getOrganisationInvitesUrl } from "../../organisation-invites-url-helper";

let call: CancelTokenSource;

export const getOrganisationInvites = (
  accessToken: string,
  organisationId: string
): Promise<Array<IMemberInvite>> => {
  call = axios.CancelToken.source();
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    cancelToken: call.token,
  };
  return axios
    .post(
      getOrganisationInvitesUrl(organisationId),
      {
        start: 0,
        perPage: 10000,
        application: eApplicationType.ProjectsInternal,
        organisationId,
        orderBy: "Desc",
      },
      options
    )
    .then(({ data }: { data: IMembersInviteResponse }) => data.items);
};

export default getOrganisationInvites;
