import axios from "axios";
import { buildOrganisationSubscriptionsBaseUrl } from "src/api/base-url-helper";
import { IOrganisation } from "src/interfaces/organisation";
import { getJsonContentHeader, getAuthHeader } from "../../headers";

export interface IOrganisationSearchResponse {
  length: number;
  total: number;
  limit: number;
  start: number;
  items: IOrganisation[];
}

const selfOrganisations = (
  accessToken: string
): Promise<IOrganisationSearchResponse | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  return axios
    .get(`${buildOrganisationSubscriptionsBaseUrl()}/self`, options)
    .then(({ data }) => data as IOrganisationSearchResponse);
};

export default selfOrganisations;
