import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

export default {
  formContainer: css`
    flex: 1;
    border-radius: 0.25rem;
    background-color: ${theme.colors.white};
    box-shadow: 0 0.063rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-left: 2.5rem;
    overflow: auto;
    margin-right: 2.5rem;
    margin-bottom: 1.5rem;
    @media ${queries.large} {
      margin-left: 19rem;
    }
  `,
};
