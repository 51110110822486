import { cloneDeep } from "lodash";
import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import deleteTerm from "src/api/terms/delete-term";
import useAuth from "src/auth/use-auth";
import {
  ICreateTermRequest,
  IProjectForm,
  IProjectRouteParams,
} from "src/pages/projects/project/interfaces";
import insertSpaces, { capitalizeFirstLetter } from "src/utils/string";
import useProject from "src/providers/project/hooks";
import { getProject } from "src/api/projects";
import { ErrorToast } from "src/components/toast-notification";
import { ISODateParseFormat, LicenseFixedDurations } from "src/constants";
import createTerm from "src/api/terms/create-term";
import ValidationErrorIcon from "src/app/assets/icons/component-icons/alert-error";
import style from "../../../../multi-tracks/styles/index";
import mainStyles from "../../../../services/styles";
import { ITermViewModel } from "../../../interfaces";
import CollapsibleLicensingHeaderDeleteAndDuplicate from "../collapsible-licensing-header-delete-and-duplicate/CollapsibleLicensingHeaderDeleteAndDuplicate";

interface IProps {
  termVm: ITermViewModel;
  isValidationInvalid: boolean;
  onDelete: () => void;
}
const LicensingFormHeader = ({
  termVm,
  isValidationInvalid,
  onDelete,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const { termDetails, termSummary } = termVm;
  const { id: projectId } = useParams<IProjectRouteParams>();
  const { storeTerms } = useProject();

  const {
    isSessionValid,
    fetchWrapper,
    getAccessToken,
    organisationId,
  } = useAuth();

  const handleDeleteClick = useCallback(async (): Promise<void> => {
    try {
      await fetchWrapper(deleteTerm, projectId, organisationId, termDetails.id);
      const response = await fetchWrapper<IProjectForm | null>(
        getProject,
        projectId,
        organisationId
      );
      if (response) {
        storeTerms({ terms: response.terms, projectVersion: response.version });
      }
    } catch (e) {
      ErrorToast(
        "term-delete-error",
        t("ProjectsPage##licensingSection##UnableToDelete")
      );
    }
    onDelete();
  }, [
    fetchWrapper,
    organisationId,
    projectId,
    storeTerms,
    t,
    termDetails.id,
    onDelete,
  ]);

  const handleDuplicateTermClickHandler = useCallback(async (): Promise<void> => {
    const isSession = await isSessionValid();
    if (!isSession) return;

    const termWithOrgId = {
      organisationId,
      ...termDetails,
    };

    const termToDuplicate = cloneDeep(termWithOrgId) as ICreateTermRequest;

    termToDuplicate.startDate = termDetails.startDate?.format(
      ISODateParseFormat
    );
    termToDuplicate.endDate = termDetails.endDate?.format(ISODateParseFormat);

    if (
      termToDuplicate.fixedDuration === LicenseFixedDurations.CustomDuration
    ) {
      termToDuplicate.fixedDuration = undefined;
    } else {
      termToDuplicate.endDate = undefined;
    }

    termToDuplicate.name = `${t(
      "ProjectsPage##licensingSection##DuplicatePrefix"
    )} ${termToDuplicate.name}`;

    try {
      await fetchWrapper(createTerm, projectId, termToDuplicate);
      const accessToken: string = getAccessToken();
      const response = await getProject(accessToken, projectId, organisationId);
      if (response) {
        storeTerms({
          terms: response.terms,
          projectVersion: response.version,
        });
      }
    } catch (e) {
      ErrorToast(
        "term-duplicate-error",
        t("ProjectsPage##licensingSection##UnableToDuplicate")
      );
    }
  }, [
    getAccessToken,
    fetchWrapper,
    isSessionValid,
    organisationId,
    projectId,
    storeTerms,
    t,
    termDetails,
  ]);

  const handleDeleteConfirmation = useCallback(() => {
    void handleDeleteClick();
  }, [handleDeleteClick]);

  const handleDuplicateConfirmation = useCallback(() => {
    void handleDuplicateTermClickHandler();
  }, [handleDuplicateTermClickHandler]);

  return (
    <div
      role="button"
      data-testid="term-summary-row"
      tabIndex={-1}
      onKeyDown={(e) => {
        e.persist();
      }}
      css={style.collapseSummaryContainer}
    >
      <div css={mainStyles.collapsibleTableHeaderFirstCell}>
        <div css={style.summaryColumnInformation}>
          {termSummary?.name ? termSummary.name : "-"}
        </div>
      </div>
      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {termSummary?.type
            ? capitalizeFirstLetter(termSummary.type.toLowerCase())
            : "-"}
        </div>
      </div>
      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {termSummary?.mediaType ? insertSpaces(termSummary.mediaType) : "-"}
        </div>
      </div>
      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {termSummary?.tracks ? termSummary.tracks : "-"}
        </div>
      </div>
      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          <div css={style.overflowContainer}>
            {termSummary?.territories ? termSummary.territories : "-"}
          </div>
        </div>
      </div>
      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {termSummary?.duration ? termSummary.duration : "-"}
        </div>
      </div>
      <div css={style.summaryIconContainer}>
        <CollapsibleLicensingHeaderDeleteAndDuplicate
          viewModel={termSummary}
          onDuplicateTerm={handleDuplicateConfirmation}
          onDeleteClick={handleDeleteConfirmation}
        />
      </div>
      {isValidationInvalid && (
        <ValidationErrorIcon
          width={24}
          height={24}
          title="Required Fields Missing"
        />
      )}
    </div>
  );
};

export default LicensingFormHeader;
