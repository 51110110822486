import { ReactElement } from "react";

const GraphicLeft = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
    >
      <mask
        id="mask0_404_2660"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="66"
        height="66"
      >
        <rect
          x="66"
          y="66"
          width="66"
          height="66"
          transform="rotate(-180 66 66)"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_404_2660)">
        <path
          d="M1066 59.7513L8.9983 59.7512C8.31507 59.7502 7.66029 59.4786 7.17677 58.9958C6.69325 58.5131 6.42066 57.8585 6.41859 57.1753L6.41859 2.89633C6.41859 2.12816 6.11342 1.39143 5.5702 0.84825C5.02699 0.305068 4.29042 -9.28176e-05 3.52223 -9.28848e-05C2.75404 -9.29519e-05 2.01735 0.305068 1.47413 0.84825C0.930921 1.39143 0.625623 2.12816 0.625623 2.89633L0.625618 57.1753C0.62867 59.3946 1.51209 61.522 3.08167 63.0909C4.65125 64.6598 6.77906 65.542 8.9983 65.5441L1066 65.5441L1066 59.7513ZM-1058.98 90.2601L-1.97338 90.2602C-1.28991 90.2612 -0.634516 90.5335 -0.151606 91.0171C0.331304 91.5008 0.602544 92.1564 0.602544 92.8399L0.602539 147.103C0.602539 147.872 0.907715 148.608 1.45093 149.152C1.99414 149.695 2.73096 150 3.49915 150C4.26733 150 5.00391 149.695 5.54712 149.152C6.09033 148.608 6.39551 147.872 6.39551 147.103L6.39551 92.8322C6.39344 90.613 5.51111 88.4851 3.94227 86.9155C2.37342 85.3459 0.245855 84.4627 -1.97338 84.4596L-1058.98 84.4595L-1058.98 90.2601Z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};

export default GraphicLeft;
