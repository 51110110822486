import { ReactElement } from "react";

const DisclaimerInfoIcon = (): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1344_19352)">
        <circle cx="12" cy="12" r="12" fill="#F0F0F0" />
        <path
          d="M11.9944 8.216C12.7304 8.216 13.3224 7.656 13.3224 6.952C13.3224 6.248 12.7304 5.704 11.9944 5.704C11.2744 5.704 10.6664 6.248 10.6664 6.952C10.6664 7.656 11.2744 8.216 11.9944 8.216ZM13.0504 17V9.528H10.9544V17H13.0504Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1344_19352">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default DisclaimerInfoIcon;
