import { useContext, useEffect, useState } from "react";
import { ConfirmContext, IConfirmData } from "../state";

interface IConfirm extends IConfirmData {
  isConfirmed: (
    prompt: string,
    okButtonName?: string,
    cancelButtonName?: string
  ) => Promise<boolean>;
}

const useConfirm = (): IConfirm => {
  const { confirm, handleSetConfirm } = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = (
    prompt: string,
    okButtonName = "Yes",
    cancelButtonName = "Cancel"
  ) => {
    const promise = new Promise((resolve, reject) => {
      handleSetConfirm({
        prompt,
        okButtonName,
        cancelButtonName,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      });
      setNeedsCleanup(true);
    });

    const reset = () => {
      handleSetConfirm({
        ...confirm,
        prompt: "",
        okButtonName: "Yes",
        cancelButtonName: "Cancel",
        isOpen: false,
      });
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed,
  };
};

export default useConfirm;
