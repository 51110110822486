import { SerializedStyles } from "@emotion/react";
import { Button } from "antd";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface IMenuItem {
  customStyles?: SerializedStyles;
  icon?: ReactElement;
  isLoading?: boolean;
  buttonLabel: string;
  ariaLabel: string;
  id?: string;
  onClick: () => void;
}
const MenuItem = ({
  customStyles,
  buttonLabel,
  icon,
  isLoading = false,
  ariaLabel,
  id,
  onClick,
}: IMenuItem): ReactElement => {
  const { t } = useTranslation();
  return (
    <Button
      css={customStyles}
      aria-label={t(ariaLabel)}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (!isLoading) {
          onClick();
        }
      }}
      id={id}
      loading={isLoading}
    >
      {!isLoading && icon}
      {t(buttonLabel)}
    </Button>
  );
};

export default MenuItem;
