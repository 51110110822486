import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  modalTitle: css`
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    line-height: 1.75rem !important;
    color: ${theme.colors.secondary.black} !important;
    margin-bottom: 2rem !important;
  `,
  row: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0.1rem !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    align-content: center;
    align-items: center;
  `,
  deleteIcon: css`
    float: right;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  itemName: css`
    flex: 1;
  `,
  divider: css`
    margin: 8px 0;
  `,
  noFilters: css`
    flex: 1;
    text-align: center;
  `,
  columnsList: css`
    margin-top: 16px;
  `,
  columnName: css`
    margin: 8px 0;
  `,
  button: css`
    float: right;
  `,
  secondaryButton: css`
    float: right;
    border: 1px solid ${theme.colors.primary.blue} !important;
    margin-right: 16px;
  `,
  controls: css`
    height: 52px;
    margin-top: 32px;
  `,
};
