import { ReactElement, useCallback } from "react";
import { Collapse } from "antd";
import {
  IFinalTrackSection,
  IProjectForm,
  IProjectTracksUpdate,
} from "src/pages/projects/project/interfaces";
import SecondaryModalButtonLink from "src/components/modals/modal-buttons/secondary-button-link";
import Button from "src/components/button";
import { UploadFile } from "antd/lib/upload/interface";
import NoData from "src/components/no-data";
import useAuth from "src/auth/use-auth";
import deleteTrack from "src/api/tracks/delete-track";
import { getProject } from "src/api/projects";
import { ErrorToast } from "src/components/toast-notification";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import style from "../../styles";
import TrackForm from "../track-form";
import TrackFormHeader from "../track-form-header";
import mainStyles from "../../../../styles";
import TrackFormTableHeader from "../track-form-table-header/TrackFormTableHeader";

interface IProps {
  finalTracks?: IFinalTrackSection[];
  talentsResponse?: IContactsResponse;
  areTalentsLoading: boolean;
  attachments?: UploadFile[];
  selectedTrack?: IFinalTrackSection;
  tracksValidity: { trackId: string; isValid: boolean }[];
  validateWriterFeeFields: boolean;
  validateTrackOwnership: boolean;
  projectId: string;
  isNewTrack: boolean;
  agenciesAndContacts: IAgencyWithContactsResults[];
  onSelectedTrackChange: (track?: IFinalTrackSection) => void;
  onFormSubmit: () => void;
  onShowEditForm: (show: boolean) => void;
  onUpdateIsNewTrack: (newTrack: boolean) => void;
  storeTracks: (tracksUpdate: IProjectTracksUpdate) => void;
  onTrackDataChanged: () => void;
  onChangesMade: (changed: boolean) => void;
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
}

const TracksSummary = ({
  finalTracks,
  attachments,
  selectedTrack,
  validateWriterFeeFields,
  validateTrackOwnership,
  tracksValidity,
  projectId,
  isNewTrack,
  talentsResponse,
  agenciesAndContacts,
  areTalentsLoading,
  onSelectedTrackChange,
  onFormSubmit,
  onShowEditForm,
  onTrackDataChanged,
  onChangesMade,
  onResetNarrowSearch,
  onScrollTalents,
  onUpdateNarrowSearchText,
  onUpdateIsNewTrack,
  storeTracks,
}: IProps): ReactElement => {
  const { fetchWrapper, organisationId } = useAuth();
  const { Panel } = Collapse;

  const handleFormCancel = useCallback(async () => {
    if (isNewTrack && selectedTrack?.id) {
      try {
        await fetchWrapper(
          deleteTrack,
          projectId,
          organisationId,
          selectedTrack.id
        );
        const projectResponse = await fetchWrapper(
          getProject,
          projectId,
          organisationId
        );
        if (projectResponse) {
          storeTracks({
            tracks: projectResponse?.tracks ?? [],
            projectVersion: projectResponse.version,
          });
        }
      } catch (error) {
        ErrorToast(
          "DeleteTrackError",
          "Sorry, there was a problem deleting this track. Please try again."
        );
      }
    }
    onUpdateIsNewTrack(false);
    onShowEditForm(false);
    onSelectedTrackChange();
    onChangesMade(false);
  }, [
    isNewTrack,
    selectedTrack?.id,
    onUpdateIsNewTrack,
    onShowEditForm,
    onSelectedTrackChange,
    onChangesMade,
    fetchWrapper,
    projectId,
    organisationId,
    storeTracks,
  ]);

  const handleSubmit = useCallback(() => {
    onFormSubmit();
  }, [onFormSubmit]);

  const handleArrowClick = useCallback(
    (key?: string | string[]) => {
      if (finalTracks) {
        let trackId;
        if (typeof key === "string") {
          trackId = finalTracks.find((track) => track.id === key);
        } else {
          trackId = finalTracks.find((track) => key?.includes(track.id));
        }
        onSelectedTrackChange(trackId);
      }
      onChangesMade(Boolean(key));
    },
    [finalTracks, onSelectedTrackChange, onChangesMade]
  );

  const isValidTrack = useCallback(
    (track: IFinalTrackSection) => {
      return (
        tracksValidity.find((v) => v.trackId === track.id)?.isValid ?? true
      );
    },
    [tracksValidity]
  );
  return finalTracks ? (
    <Collapse
      accordion
      destroyInactivePanel
      activeKey={selectedTrack?.id}
      onChange={handleArrowClick}
      css={mainStyles.collapseContainer}
    >
      {!!finalTracks?.length && (
        <Panel
          key="1"
          css={mainStyles.headerPanelContainer}
          showArrow={false}
          header={<TrackFormTableHeader />}
        />
      )}
      {finalTracks.map((track) => {
        return (
          <Panel
            header={
              <TrackFormHeader
                onSelectedTrackChange={onSelectedTrackChange}
                finalTrack={track}
                isValid={isValidTrack(track)}
                onDelete={() => onChangesMade(false)}
              />
            }
            key={track.id}
            css={mainStyles.panelContainer}
          >
            <div css={mainStyles.editCollapseContainer}>
              <TrackForm
                finalTrack={selectedTrack}
                talentsResponse={talentsResponse}
                attachments={attachments}
                validateWriterFeeFields={validateWriterFeeFields}
                validateTrackOwnership={validateTrackOwnership}
                areTalentsLoading={areTalentsLoading}
                agenciesAndContacts={agenciesAndContacts}
                onTrackDataChanged={onTrackDataChanged}
                onSelectedTrackChange={onSelectedTrackChange}
                onScrollTalents={onScrollTalents}
                onUpdateNarrowSearchText={onUpdateNarrowSearchText}
                onResetNarrowSearch={onResetNarrowSearch}
              />
              <div css={style.collapseButtonRow}>
                <SecondaryModalButtonLink
                  wrapperStyles={style.secondaryButton}
                  onClick={handleFormCancel}
                  ariaLabel="Cancel"
                  buttonText="Cancel"
                  css={style.cancelButton}
                />
                <Button
                  size="small"
                  noLabelTranslation
                  ariaLabel="Submit"
                  onClick={handleSubmit}
                  data-testid="add-update-track-button"
                >
                  Save track
                </Button>
              </div>
            </div>
          </Panel>
        );
      })}
    </Collapse>
  ) : (
    <NoData text="ProjectsPage##musicSection##No tracks" />
  );
};

export default TracksSummary;
