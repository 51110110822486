import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Typography, Space, Row, Checkbox } from "antd";
import StyledButton from "src/components/button";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { ISavedUserColumn } from "src/api/user-filters/interfaces";
import styles from "./styles";
import mapColumnsInCheckboxLabels, {
  skipDragAndDropSort,
  sortItemsAfterDragAndDrop,
} from "./utils";

interface IProps {
  columnsForTable: "projects" | "talentHub";
  selectedColumns: ISavedUserColumn[] | null;
  onClose: () => void;
  onSaveChanges: (columns: ISavedUserColumn[]) => void;
}

export enum AllProjectColumns {
  ProjectName = "Project name",
  CampaignName = "Campaign name",
  ClientName = "Client name",
  ProjectType = "Project type",
  ProjectLead = "Project lead",
  ProjectStatus = "Project status",
  ProjectRegion = "Project region",
  FinalTrackName = "Final track name",
  FinalTrackArtist = "Final track artist",
  Territory = "Territory",
  Term = "Term",
  StartDate = "Start date",
  EndDate = "End date",
  MasterFee = "Master fee",
  PublishingFee = "Publishing fee",
  PublishingNotified = "Publishing notified",
  CreatedDate = "Created date",
  ProjectListedDate = "Project listed date",
  LastUpdated = "Last updated",
}

export enum AllTalentHubColumns {
  Name = "Name",
  SkillSet = "Skill set",
  Instruments = "Instrument(s)",
  Genres = "Genre(s)",
  Vocals = "Vocals",
  Rating = "Rating",
  Pronoun = "Pronoun",
  AgencyWritingPartnership = "Agency/Writing Partnership",
  Email = "Email",
  PhoneNumber = "Phone Number",
  BaseLocation = "Base Location",
  SocialMediaWebsite = "Social Media/Website",
  Showreel = "Showreel",
  NativeMainLanguage = "Native/Main Language",
  OtherLanguages = "Other Languages",
  Neurodiverse = "Neurodiverse",
  NeurodiverseNote = "Neurodiverse Note",
  PhysicalConditionIllness = "Physical Condition/Illness",
  RacialCharacteristics = "Racial Characteristics",
  GenderIdentity = "Gender Identity",
  SexualOrientation = "Sexual Orientation",
  CMASigned = "CMA Signed",
  NDASigned = "NDA Signed",
  MinimumDemoFee = "Minimum Demo Fee",
  PerformingRightsOrganisationNumber = "Performing Rights Organisation Number",
  AdditionalInstruments = "Additional Instruments",
  AdditionalGenres = "Additional Genre(s)",
  MinimumDemoTurnaroundHours = "Minimum Demo Turnaround (hours)",
  PreviousWork = "Previous Work",
  InterestedProjects = "Interested Projects",
}

const ViewColumnsModalContent = ({
  onClose,
  selectedColumns,
  columnsForTable,
  onSaveChanges,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const tableColumns = useMemo(
    () =>
      columnsForTable === "projects" ? AllProjectColumns : AllTalentHubColumns,
    [columnsForTable]
  );
  const [columns, setColumns] = useState<ISavedUserColumn[]>(
    mapColumnsInCheckboxLabels(selectedColumns, Object.values(tableColumns))
  );

  useEffect(() => {
    setColumns(
      mapColumnsInCheckboxLabels(selectedColumns, Object.values(tableColumns))
    );
  }, [selectedColumns, tableColumns]);

  const handleSaveChanges = useCallback(() => {
    onSaveChanges(columns);
  }, [columns, onSaveChanges]);

  const handleUpdateColumns = useCallback(
    (columnName: string, checked: boolean) => {
      const columnsCopy = cloneDeep(columns);
      const tmpColumnData = columnsCopy.find(
        (columnData) => columnName === columnData.name
      );
      if (tmpColumnData) {
        tmpColumnData.enabled = checked;
        setColumns(columnsCopy);
      }
    },
    [columns]
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      document.body.classList.remove("draggable-body");
      if (skipDragAndDropSort(result)) {
        return;
      }
      const newColumnsDataOrder = sortItemsAfterDragAndDrop(result, columns);
      setColumns(newColumnsDataOrder);
    },
    [columns]
  );

  return (
    <div>
      <Typography.Title css={styles.modalTitle}>
        {t("ViewColumnsModal##ConfigureColumns")}
      </Typography.Title>
      <Space />
      <Typography.Text>
        {t("ViewColumnsModal##DragColumnsToReorder")}
      </Typography.Text>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided: DroppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              <div css={styles.columnsList}>
                {columns.map((columnData, index) => (
                  <div key={columnData.name}>
                    <Draggable
                      key={columnData.name}
                      draggableId={columnData.name}
                      index={index}
                    >
                      {(draggableProvided: DraggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          <Row gutter={[16, 16]} css={styles.row}>
                            <Checkbox
                              checked={columnData.enabled}
                              css={styles.columnName}
                              onChange={(event) =>
                                handleUpdateColumns(
                                  columnData.name,
                                  event.target.checked
                                )
                              }
                            >
                              {columnData.name}
                            </Checkbox>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div css={styles.controls}>
        <StyledButton
          css={styles.button}
          ariaLabel="ViewColumnsModal##SaveChanges"
          onClick={handleSaveChanges}
        >
          ViewColumnsModal##SaveChanges
        </StyledButton>
        <StyledButton
          css={styles.secondaryButton}
          type="secondary"
          ariaLabel="ViewColumnsModal##Cancel"
          onClick={onClose}
        >
          ViewColumnsModal##Cancel
        </StyledButton>
      </div>
    </div>
  );
};

export default ViewColumnsModalContent;
