import config from "../config/config";

const buildOrganisationsBaseUrl = (organisationId: string): string => {
  return `${config.organizationsService.url}/${organisationId}`;
};

export const buildOrganisationSubscriptionsBaseUrl = (): string => {
  return `${config.organizationsService.url}`;
};

export const buildVinylProjectManagementBaseUrl = (): string => {
  return `${config.projectManagementService.url}/projects`;
};
export const buildVinylContactsManagementUrl = (): string => {
  return `${config.projectManagementService.url}/talent-hub`;
};

export default buildOrganisationsBaseUrl;
