import axios, { AxiosResponse } from "axios";
import { getSendNotificationToPublishingTeamUrl } from "src/api/project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const notifyPublishingTeam = (
  accessToken: string,
  projectId: string,
  organisationId: string
): Promise<AxiosResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios
    .post(
      getSendNotificationToPublishingTeamUrl(projectId, organisationId),
      {},
      options
    )
    .then((response) => {
      return response;
    });
};

export default notifyPublishingTeam;
