import { Currencies, DateDisplayFormat } from "src/constants";
import {
  getIsDesktop,
  getIsMobile,
  getIsTablet,
  useWindowSize,
} from "@songtradr/spa-common/lib/utils";
import { Divider, Form } from "antd";
import { ActionMeta } from "react-select";
import { LabeledValue } from "antd/lib/select";
import { IUserDropdownProps } from "src/pages/team/interfaces";
import { cloneDeep, startCase } from "lodash";
import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import useAuth from "src/auth/use-auth";
import MobileDrawer from "src/components/mobile-drawer";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import { formatCurrency } from "src/utils/currency";
import ConfirmationDrawer from "src/components/mobile-drawer/confirmation-drawer";
import Content from "src/components/modals/confirmation-modal/content";
import BaseModal from "src/components/modals/base-modal";
import { IConfirmContentProps } from "src/components/interfaces";
import getServicedOrgs from "src/api/organisation/get-orgs-by-serviceOrgId";
import getOrganisationMembers from "src/api/organisation-members/get-org-members";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import dayjs from "dayjs";
import {
  Contributor,
  ILeadDropdownProps,
  ILeads,
  IProjectDetailsData,
  IProjectProductAndAccountInformationData,
  ProjectStatus,
} from "../../interfaces";
import style from "../../styles";
import { parseProjectLeads } from "./utils";
import {
  IHoldingCompany,
  IMember,
  IOption,
  IClient,
} from "../account-information/types";
import ProjectDatesSection from "./components/project-dates-section/ProjectDatesSection";
import ProjectFeesSection from "./components/project-fees-section/ProjectFeesSection";
import ProjectDescriptionSection from "./components/project-description-section/ProjectDescriptionSection";
import ProjectOverviewDetailsSection from "./components/project-overview-details-section/ProjectOverviewDetailsSection";

type ProjectProductAndAccountInformationField =
  | "regionalBrand"
  | "productDivision"
  | "globalBrand"
  | "client"
  | "clientContact"
  | "product";

interface IProps {
  publishingTeamEmails: string[];
  projectDetailsData: IProjectDetailsData;
  saveClicked: boolean;
  enforceValidation: boolean;
  projectProductAndAccountInformationData: IProjectProductAndAccountInformationData;
  isRegionsRequiredField: boolean;
  onUpdateProjectDetailsData: (projectDetailsData: IProjectDetailsData) => void;
  updateProjectProductAndAccountInformationData: (
    projectProductAndAccountInformationData: IProjectProductAndAccountInformationData
  ) => void;
  setHoldingCompany: (data: IHoldingCompany) => void;
}

const Details = memo(
  ({
    projectDetailsData,
    saveClicked,
    enforceValidation,
    projectProductAndAccountInformationData,
    isRegionsRequiredField,
    onUpdateProjectDetailsData,
    updateProjectProductAndAccountInformationData,
    setHoldingCompany,
  }: IProps): ReactElement => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { allProjectStatuses, allProjectRegions } = useGlobalStates();
    const { fetchWrapper, users, superAdmins, organisationId } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [servicedOrgOptions, setServicedOrgOptions] = useState<IClient[]>([]);
    const [clientOptions, setClientOptions] = useState<IOption[]>([]);
    const [client, setClient] = useState(
      projectProductAndAccountInformationData.client
        ? {
            label: projectProductAndAccountInformationData.client.name,
            value: projectProductAndAccountInformationData.client.id,
          }
        : undefined
    );
    const [selectedClient, setSelectedClient] = useState<IClient>();
    const [formHoldingCompany, setFormHoldingCompany] = useState<
      IHoldingCompany | undefined
    >(
      projectProductAndAccountInformationData.client?.holdingCompany
        ? {
            id:
              projectProductAndAccountInformationData?.client?.holdingCompany
                .id,
            name:
              projectProductAndAccountInformationData?.client?.holdingCompany
                .name,
          }
        : undefined
    );
    const [contactOptions, setContactOptions] = useState<IOption[]>([]);
    const [contact, setContact] = useState<IOption | undefined>(
      projectProductAndAccountInformationData.clientContact
        ? {
            label: projectProductAndAccountInformationData.clientContact.name,
            value: projectProductAndAccountInformationData.clientContact.id,
          }
        : undefined
    );
    const [clientMembers, setClientMembers] = useState<IMember[]>([]);

    useWindowSize();

    const isMobile = getIsMobile();
    const isTablet = getIsTablet();
    const isDesktop = getIsDesktop();

    const parsedLeadOptions = useMemo(
      () =>
        (users ?? [])
          .concat(superAdmins ?? [])
          .map((user) => {
            return {
              label: user?.name,
              value: user?.id,
              email: user?.email,
              key: user?.id,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      [superAdmins, users]
    );

    const parsedProjectLeads = useMemo(
      () => parseProjectLeads(projectDetailsData.leads),
      [projectDetailsData.leads]
    );

    const parsedProjectCurators = useMemo(
      () => parseProjectLeads(projectDetailsData.curators),
      [projectDetailsData.curators]
    );
    const parsedProjectOriginators = useMemo(
      () => parseProjectLeads(projectDetailsData.originators),
      [projectDetailsData.originators]
    );
    const handleUpdate = useCallback(
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const projectData = {
          [event.target.name]: event.target.value,
        };

        onUpdateProjectDetailsData(projectData);
      },
      [onUpdateProjectDetailsData]
    );
    const handleLeadMultiSelectChange = useCallback(
      (contributor: Contributor, action: ActionMeta<IUserDropdownProps>) => {
        const isCurator = contributor === Contributor.CURATOR;
        const isProjectContributor =
          contributor === Contributor.CURATOR ||
          contributor === Contributor.ORIGINATOR;
        let projectContributors: ILeads[] | undefined;

        if (isProjectContributor) {
          if (isCurator) {
            projectContributors = projectDetailsData.curators;
          } else {
            projectContributors = projectDetailsData.originators;
          }
        } else {
          projectContributors = projectDetailsData.leads;
        }
        const leads = cloneDeep(projectContributors ?? []);

        if (action.action === "select-option" && action.option) {
          const newLead: IUserDropdownProps = action.option;
          const index = leads?.length || 0; // length starts at 1, where as index starts at 0 - we can use this to find the next index to add
          leads[index] = {
            id: newLead.value,
            name: newLead.label,
            email: newLead.email,
          };
        }

        if (action.action === "remove-value") {
          const leadToRemove: ILeadDropdownProps = action.removedValue;

          const indexToRemove =
            leads?.findIndex((lead) => lead.id === leadToRemove.value) || 0;
          leads.splice(indexToRemove, 1);
        }
        switch (contributor) {
          case Contributor.CURATOR:
            onUpdateProjectDetailsData({ curators: leads });
            break;
          case Contributor.ORIGINATOR:
            onUpdateProjectDetailsData({
              originators: leads,
            });
            break;
          default:
            onUpdateProjectDetailsData({
              leads,
            });
        }
      },
      [
        projectDetailsData.leads,
        projectDetailsData.curators,
        projectDetailsData.originators,
        onUpdateProjectDetailsData,
      ]
    );

    const handleDatePickerChange = useCallback(
      (field: string, value: dayjs.Dayjs | null) => {
        if (value) {
          onUpdateProjectDetailsData({ [field]: value.toISOString() });
        }
      },
      [onUpdateProjectDetailsData]
    );

    const handleProjectStatusChange = useCallback(
      (data: LabeledValue) => {
        const value = data.value as ProjectStatus;
        const isStatusComplete = [
          ProjectStatus.InContractInvoiced,
          ProjectStatus.Complete,
          ProjectStatus.Cancelled,
        ].includes(value);
        const projectCloseDate = isStatusComplete
          ? dayjs().format(DateDisplayFormat)
          : "";

        onUpdateProjectDetailsData({ status: value, projectCloseDate });
      },
      [onUpdateProjectDetailsData]
    );

    const handleRegionChange = useCallback(
      (data: LabeledValue) => {
        const projectRegion = (data?.value ?? "") as string;
        onUpdateProjectDetailsData({ projectRegion });
      },
      [onUpdateProjectDetailsData]
    );

    const musicBudgetCurrency = useMemo(
      () => projectDetailsData.musicBudget?.currency ?? Currencies.USD.code,
      [projectDetailsData.musicBudget?.currency]
    );
    const musicBudgetPrice = useMemo(
      () => projectDetailsData.musicBudget?.value ?? "",
      [projectDetailsData.musicBudget?.value]
    );
    const earningsCurrency = useMemo(
      () => projectDetailsData.earnings?.currency ?? Currencies.USD.code,
      [projectDetailsData.earnings?.currency]
    );
    const earningsPrice = useMemo(
      () => projectDetailsData.earnings?.value ?? "",
      [projectDetailsData.earnings?.value]
    );
    const initialMusicQuoteCurrency = useMemo(
      () =>
        projectDetailsData.initialMusicQuote?.currency ?? Currencies.USD.code,
      [projectDetailsData.initialMusicQuote?.currency]
    );
    const initialMusicQuotePrice = useMemo(
      () => projectDetailsData.initialMusicQuote?.value ?? "",
      [projectDetailsData.initialMusicQuote?.value]
    );
    const costAvoidanceCurrency = useMemo(
      () => projectDetailsData.costAvoidance?.currency ?? Currencies.USD.code,
      [projectDetailsData.costAvoidance?.currency]
    );
    const costAvoidancePrice = useMemo(
      () => projectDetailsData.costAvoidance?.value ?? "",
      [projectDetailsData.costAvoidance?.value]
    );
    const totalAmountCurrency = useMemo(
      () => projectDetailsData.totalAmount?.currency ?? Currencies.USD.code,
      [projectDetailsData.totalAmount?.currency]
    );
    const totalAmountPrice = useMemo(
      () => projectDetailsData.totalAmount?.value ?? "",
      [projectDetailsData.totalAmount?.value]
    );

    const currencyOptions: IOption[] = useMemo(
      () =>
        Object.values(Currencies).map((value) => {
          return {
            value: value.code,
            label: value.displayText,
          };
        }),
      []
    );

    const getCurrency = useCallback(
      (currentCurrency: string) =>
        currencyOptions.find((option) => option.value === currentCurrency),
      [currencyOptions]
    );

    const handleMonetaryValueChange = useCallback(
      (field: string, value: IMonetaryValue) => {
        onUpdateProjectDetailsData({ [field]: value });
        form.setFieldsValue({
          [`${field}Currency`]: getCurrency(value.currency),
          [`${field}Price`]: formatCurrency(value.value),
        });
      },
      [form, getCurrency, onUpdateProjectDetailsData]
    );

    const handleToggleUpdate = useCallback(
      (field: string, value: boolean) => {
        onUpdateProjectDetailsData({ [field]: value });
      },
      [onUpdateProjectDetailsData]
    );

    useEffect(() => {
      if (saveClicked || enforceValidation) {
        const validationFields = ["name", "jobListingDate", "status", "leads"];
        if (isRegionsRequiredField) {
          validationFields.push("projectRegion");
        }
        void form.validateFields(validationFields);
      }
    }, [form, isRegionsRequiredField, saveClicked, enforceValidation]);

    useEffect(() => {
      const fetchServicedOrgs = async () => {
        if (organisationId) {
          const clientOrgs = await fetchWrapper(
            getServicedOrgs,
            organisationId
          );
          if (clientOrgs) {
            const clients = Object.values(clientOrgs).map((org) => {
              return {
                value: org.id,
                label: org.name,
                holdingCompany: {
                  name: org.holdingCompany?.name,
                  id: org.holdingCompany?.id,
                },
              };
            });
            setServicedOrgOptions(clients);
          }
        }
      };
      void fetchServicedOrgs();
    }, [fetchWrapper, organisationId]);

    const getHoldingCompany = useCallback(
      (value: string) => {
        const clientItem = servicedOrgOptions.find(
          (option) => option.value === value
        );
        setFormHoldingCompany(clientItem?.holdingCompany);
        setHoldingCompany({
          id: clientItem?.holdingCompany?.id,
          name: clientItem?.holdingCompany?.name,
        });
      },
      [servicedOrgOptions, setHoldingCompany]
    );

    useEffect(() => {
      if (servicedOrgOptions) {
        const clients = Object.values(servicedOrgOptions)
          .map((org) => {
            return {
              value: org.value,
              label: org.label,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label));
        setClientOptions(clients);
      }
    }, [servicedOrgOptions]);

    useEffect(() => {
      void (async () => {
        if (client) {
          const selectedOrg = servicedOrgOptions.find(
            (org) => org.value === client?.value
          );
          if (selectedOrg) {
            const membersResponse = await fetchWrapper(
              getOrganisationMembers,
              selectedOrg.value
            );
            setClientMembers(membersResponse);
            const contacts = membersResponse
              .map((member) => {
                return {
                  value: member.id,
                  label: `${member.firstName} ${member.lastName}`,
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label));
            setContactOptions(contacts);
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, servicedOrgOptions]);

    const handleClientDropdownChange = useCallback(
      (field: string, data: IOption) => {
        const { label, value } = data;
        const holdingCompany = servicedOrgOptions.find(
          (option) => option.value === data.value
        );
        setSelectedClient({
          label: data.label,
          value: data.value,
          holdingCompany: {
            id: holdingCompany?.holdingCompany?.id,
            name: holdingCompany?.holdingCompany?.name,
          },
        });

        if (
          (holdingCompany?.holdingCompany?.id !== formHoldingCompany?.id &&
            formHoldingCompany) ||
          (contact?.value && contact.value !== undefined)
        ) {
          setIsModalOpen(true);
        } else if (
          projectProductAndAccountInformationData[
            field as ProjectProductAndAccountInformationField
          ] !== data.value
        ) {
          updateProjectProductAndAccountInformationData({
            [field]: {
              id: value,
              name: label,
            },
          });
          getHoldingCompany(value);
          setClient(data);
        }
      },
      [
        contact,
        formHoldingCompany,
        getHoldingCompany,
        projectProductAndAccountInformationData,
        servicedOrgOptions,
        updateProjectProductAndAccountInformationData,
      ]
    );

    const handleContactDropdownChange = useCallback(
      (field: string, data: IOption | null) => {
        if (!data) {
          const emptyContactData = { label: "", value: "" };
          setContact(emptyContactData);
          updateProjectProductAndAccountInformationData({
            [field]: emptyContactData,
          });
          return;
        }

        setContact({ label: data.label, value: data.value });

        const contactDetails = clientMembers.find(
          (member) => member.id === data.value
        );
        if (
          projectProductAndAccountInformationData[
            field as ProjectProductAndAccountInformationField
          ] !== data.value &&
          contactDetails
        ) {
          const updatedContact = {
            id: contactDetails.id,
            name: `${contactDetails.firstName} ${contactDetails.lastName}`,
            email: contactDetails.email,
          };
          updateProjectProductAndAccountInformationData({
            [field]: updatedContact,
          });
        }
      },
      [
        clientMembers,
        projectProductAndAccountInformationData,
        updateProjectProductAndAccountInformationData,
      ]
    );

    const handleConfirmClientClick = useCallback(() => {
      if (selectedClient !== undefined) {
        updateProjectProductAndAccountInformationData({
          client: {
            id: selectedClient.value,
            name: selectedClient.label,
            holdingCompany: {
              id: selectedClient?.holdingCompany?.id,
              name: selectedClient?.holdingCompany?.name,
            },
          },
          clientContact: {
            id: "",
            name: "",
            email: "",
          },
          globalBrand: {
            id: "",
            name: "",
          },
          regionalBrand: {
            id: "",
            name: "",
          },
          productDivision: {
            id: "",
            name: "",
          },
          product: "",
        });

        setIsModalOpen(false);

        getHoldingCompany(selectedClient.value);
        setClient(selectedClient);
        setContact({ label: "", value: "" });
      }
    }, [
      getHoldingCompany,
      selectedClient,
      updateProjectProductAndAccountInformationData,
    ]);

    const confirmClientChangeContentProps: IConfirmContentProps = useMemo(
      () => ({
        confirmAction: handleConfirmClientClick,
        onClose: () => setIsModalOpen(false),
        primaryButtonLabel: t("Ok"),
        secondaryButtonLabel: t("Cancel"),
        mainContent: (
          <p data-testid="confirmation-content">
            {t(
              "ProjectsPage##Changing clients will clear any information already added in the Product Information section"
            )}
          </p>
        ),
      }),
      [handleConfirmClientClick, t]
    );

    const selectedStatus = useMemo(() => {
      return allProjectStatuses.find(
        (projectStatus) => projectStatus.value === projectDetailsData.status
      );
    }, [allProjectStatuses, projectDetailsData.status]);

    return (
      <Form
        form={form}
        initialValues={{
          name: projectDetailsData.name,
          leads: parsedProjectLeads,
          jobListingDate: projectDetailsData.jobListingDate
            ? dayjs(projectDetailsData.jobListingDate)
            : undefined,
          status: {
            label: selectedStatus?.label,
            value: selectedStatus?.value,
          },
          musicBudgetCurrency: getCurrency(musicBudgetCurrency),
          musicBudgetPrice: musicBudgetPrice
            ? formatCurrency(musicBudgetPrice)
            : musicBudgetPrice,
          earningsCurrency: getCurrency(earningsCurrency),
          earningsPrice: earningsPrice
            ? formatCurrency(earningsPrice)
            : earningsPrice,
          initialMusicQuoteCurrency: getCurrency(initialMusicQuoteCurrency),
          initialMusicQuotePrice: initialMusicQuotePrice
            ? formatCurrency(initialMusicQuotePrice)
            : initialMusicQuotePrice,
          costAvoidanceCurrency: getCurrency(costAvoidanceCurrency),
          costAvoidancePrice: costAvoidancePrice
            ? formatCurrency(costAvoidancePrice)
            : costAvoidancePrice,
          totalAmountCurrency: getCurrency(totalAmountCurrency),
          totalAmountPrice: totalAmountPrice
            ? formatCurrency(totalAmountPrice)
            : totalAmountPrice,
          projectRegion: projectDetailsData.projectRegion
            ? {
                label: startCase(projectDetailsData.projectRegion),
                value: startCase(projectDetailsData.projectRegion),
              }
            : null,
          curators: parsedProjectCurators,
          originators: parsedProjectOriginators,
        }}
        validateTrigger="onChange"
        scrollToFirstError
      >
        <div id="Details">
          <div css={style.projectDetailsContainer}>
            <ProjectOverviewDetailsSection
              contact={contact}
              contactOptions={contactOptions}
              client={client}
              clientOptions={clientOptions}
              parsedLeadOptions={parsedLeadOptions}
              onLeadMultiSelectChange={handleLeadMultiSelectChange}
              onContactDropdownChange={handleContactDropdownChange}
              onClientDropdownChange={handleClientDropdownChange}
              projectDetailsData={projectDetailsData}
              onUpdate={handleUpdate}
              projectRegionOptions={allProjectRegions}
              onUpdateProjectDetailsData={onUpdateProjectDetailsData}
              onRegionChange={handleRegionChange}
              isRegionsRequiredField={isRegionsRequiredField}
            />
            <Divider css={style.sectionDivider} />
            <div css={style.additionalSectionContainerStyles}>
              <ProjectDatesSection
                projectDetailsData={projectDetailsData}
                onUpdate={handleUpdate}
                onDatePickerChange={handleDatePickerChange}
                onProjectStatusChange={handleProjectStatusChange}
              />
            </div>
            <Divider css={style.sectionDivider} />
            <div css={style.additionalSectionContainerStyles}>
              <ProjectFeesSection
                costAvoidanceCurrency={costAvoidanceCurrency}
                costAvoidancePrice={costAvoidancePrice}
                initialMusicQuoteCurrency={initialMusicQuoteCurrency}
                initialMusicQuotePrice={initialMusicQuotePrice}
                earningsPrice={earningsPrice}
                earningsCurrency={earningsCurrency}
                musicBudgetCurrency={musicBudgetCurrency}
                musicBudgetPrice={musicBudgetPrice}
                totalAmountCurrency={totalAmountCurrency}
                totalAmountPrice={totalAmountPrice}
                onMonetaryValueChange={handleMonetaryValueChange}
              />
            </div>
            <Divider css={style.sectionDivider} />
            <div css={style.projectDescriptionSectionContainer}>
              <ProjectDescriptionSection
                projectDetailsData={projectDetailsData}
                onUpdateProjectDetailsData={onUpdateProjectDetailsData}
                onUpdate={handleUpdate}
                onToggleUpdate={handleToggleUpdate}
              />
            </div>
            <Divider css={style.sectionDivider} />
            {isDesktop && (
              <BaseModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                content={<Content {...confirmClientChangeContentProps} />}
                footer={null}
              />
            )}
            {(isMobile || isTablet) && (
              <MobileDrawer
                data-testid="mobile-drawer"
                placement="bottom"
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
                key="bottom"
                closable={false}
                destroyOnClose
                css={style.drawer}
              >
                <ConfirmationDrawer {...confirmClientChangeContentProps} />
              </MobileDrawer>
            )}
          </div>
        </div>
      </Form>
    );
  }
);

export default Details;
