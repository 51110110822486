import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import { Layout } from "antd";
import Team from "src/pages/team";
import ProjectListings from "src/pages/projects/project-listings/container";
import ProjectsProvider from "src/providers/project/state/provider";
import STLoadingLogo from "src/components/st-loading-logo";
import Project from "src/pages/projects/project/container";
import UserProfile from "src/pages/user-profile/container";
import Profile from "src/pages/profile/container";
import Dashboard from "src/pages/dashboard/container";
import ProjectsSearchProvider from "src/providers/projects-search/state/provider";
import RestrictedRoute, { RestrictionType } from "src/auth/restricted-route";
import Login from "src/pages/login";
import ResetPassword from "src/pages/reset-password/container";
import SelectOrg from "src/pages/select-org";
import ConfirmContextProvider from "src/providers/confirm-context-provider/state";
import useOrg from "src/providers/organisation/hooks";
import JiraIssueCollector from "src/components/jira-issue-collector";
import GlobalContextProvider from "src/providers/global-context-provider/state/provider";
import ContactProvider from "src/providers/contact/state/provider";
import ContactCreate from "src/pages/contact/container-create";
import ContactEdit from "src/pages/contact/container-edit";
import TalentHub from "src/pages/talent-hub";
import ContactsSearchProvider from "src/providers/talent-search/state/provider";
import ProjectContactsSearchProvider from "src/providers/project-talent-search/state/provider";
import AttachmentDownload from "src/pages/attachment-download";
import useAuth from "../auth/use-auth";
import SignUp from "../pages/sign-up/container";
import NotFound from "../pages/not-found";
import Navigation from "../pages/navigation";
import ShareProjectPreview from "../pages/projects/share/preview/container";
import ShareProjectPublic from "../pages/projects/share/public/container";
import styles from "./styles";
import Heap from "../components/heap";

interface IMatchParams {
  organisationId: string;
  organisationInviteId: string;
}

function App(): ReactElement {
  const {
    isLoading,
    isAuthenticated,
    userPermissions,
    isSessionValid,
    isSuperAdmin,
    user,
  } = useAuth();
  const { organisation } = useOrg();

  const [isSession, setSession] = useState(false);

  useEffect(() => {
    const checkValidity = async () => {
      setSession(await isSessionValid());
    };
    void checkValidity();
  }, [isSessionValid]);

  const isValid = isSession && isAuthenticated;
  if (isLoading) return <STLoadingLogo pageCentered />;
  const RoutesWithNavigationBar = () => {
    return (
      <GlobalContextProvider>
        <ConfirmContextProvider>
          <Fragment>
            <Navigation />
            <div css={styles.navContainer}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  exact
                  path="/dashboard"
                >
                  <Dashboard />
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  exact
                  path="/team"
                >
                  {userPermissions.canAccessTeams ? (
                    <Team />
                  ) : (
                    <Redirect to="/dashboard" />
                  )}
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  exact
                  path="/profile"
                >
                  <Profile />
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  exact
                  path="/reset-password"
                >
                  <ResetPassword />
                </RestrictedRoute>
                <Route
                  exact
                  path={[
                    "/project/:id",
                    "/projects",
                    "/project/:id/file/:attachmentId",
                  ]}
                >
                  <ProjectsSearchProvider>
                    <ProjectContactsSearchProvider>
                      <Switch>
                        <RestrictedRoute
                          restriction={RestrictionType.AUTHENTICATED_ONLY}
                          exact
                          path="/projects"
                        >
                          <ProjectListings />
                        </RestrictedRoute>
                        <ProjectsProvider>
                          <RestrictedRoute
                            restriction={RestrictionType.AUTHENTICATED_ONLY}
                            exact
                            path="/project/:id/file/:attachmentId"
                          >
                            <AttachmentDownload />
                          </RestrictedRoute>
                          <RestrictedRoute
                            restriction={RestrictionType.AUTHENTICATED_ONLY}
                            exact
                            path="/project/:id"
                          >
                            <Project />
                          </RestrictedRoute>
                        </ProjectsProvider>
                      </Switch>
                    </ProjectContactsSearchProvider>
                  </ProjectsSearchProvider>
                </Route>
                <Route
                  exact
                  path={["/talent-create", "/talent/:id", "/talents"]}
                >
                  <ContactsSearchProvider>
                    <RestrictedRoute
                      restriction={RestrictionType.AUTHENTICATED_ONLY}
                      exact
                      path="/talent-create"
                    >
                      <ContactProvider>
                        <ContactCreate />
                      </ContactProvider>
                    </RestrictedRoute>
                    <RestrictedRoute
                      restriction={RestrictionType.AUTHENTICATED_ONLY}
                      exact
                      path="/talent/:id"
                    >
                      <ContactProvider>
                        <ContactEdit />
                      </ContactProvider>
                    </RestrictedRoute>
                    <RestrictedRoute
                      restriction={RestrictionType.AUTHENTICATED_ONLY}
                      exact
                      path="/talents"
                    >
                      <TalentHub />
                    </RestrictedRoute>
                  </ContactsSearchProvider>
                </Route>
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </Fragment>
        </ConfirmContextProvider>
      </GlobalContextProvider>
    );
  };

  return (
    <div className="app" css={styles.app}>
      <Heap
        userId={user?.id}
        email={user?.email}
        orgId={organisation?.id}
        orgName={organisation?.name}
      />
      {isSuperAdmin && <JiraIssueCollector />}
      <Layout>
        <Layout.Content css={styles.pageContainer}>
          <Switch>
            <Route exact path="/index.html">
              <Redirect to="/" />
            </Route>
            <Route path="/sign-up">
              {isValid ? <Redirect to="/dashboard" /> : <SignUp />}
            </Route>
            <Route path="/login">
              {isValid ? <Redirect to="/dashboard" /> : <Login />}
            </Route>
            <Route
              exact
              path="/organisations/:organisationId/invites/:organisationInviteId"
            >
              {isValid ? (
                <Redirect to="/dashboard" />
              ) : (
                ({ match }: RouteComponentProps<IMatchParams>) => (
                  <UserProfile
                    organisationId={match.params.organisationId}
                    organisationInviteId={match.params.organisationInviteId}
                  />
                )
              )}
            </Route>
            <RestrictedRoute
              restriction={RestrictionType.AUTHENTICATED_ONLY}
              exact
              path="/project/preview/:id"
            >
              <ShareProjectPreview />
            </RestrictedRoute>
            <RestrictedRoute
              restriction={RestrictionType.AUTHENTICATED_ONLY}
              exact
              path="/select-org"
            >
              <SelectOrg />
            </RestrictedRoute>
            <Route exact path="/projects/:projectId/sharecode/:shareId">
              <ShareProjectPublic />
            </Route>
            <Route component={RoutesWithNavigationBar} />
          </Switch>
        </Layout.Content>
      </Layout>
    </div>
  );
}

export default App;
