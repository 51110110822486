import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  column1: css`
    background: linear-gradient(254.16deg, #e5e5e5 11.05%, #fff 88.95%);
    flex: 1 1 100%;
    @media ${queries.medium} {
      padding-left: 1.0244rem;
      flex: 1 1 50%;
    }
    @media ${queries.large} {
      padding-left: 2.8125rem;
      padding-top: 10.5rem;
    }
  `,
  sectionHeader: css`
    color: ${theme.colors.black};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 0.625rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding-left: 1.0625rem;
    padding-top: 6rem;
    padding-bottom: 0.625rem;
    line-height: 1.125rem;
    @media ${queries.large} {
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      font-size: 0.75rem;
      font-weight: 400;
      float: left;
      padding-left: 2.8125rem;
    }
  `,
  projectTitle: css`
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.375rem;
    padding-left: 1.0625rem;
    padding-right: 2rem;
    padding-bottom: 1.3125rem;
    @media ${queries.medium} {
      font-size: 2.25rem;
      line-height: 2.7406rem;
    }
    @media ${queries.large} {
      font-size: 3rem;
      line-height: 3.6538rem;
      padding-left: 4.9375rem;
    }
  `,
  brandList: css`
    font-size: 1rem;
    padding-left: 1.0625rem;
    padding-bottom: 4rem;
    font-family: ${theme.fonts.sofiaPro};
    @media ${queries.large} {
      font-size: 1.5rem;
      padding-left: 4.9375rem;
    }
  `,
  subHeader: css`
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding-top: 1.5rem;
    padding-bottom: 0.9375rem;
    @media ${queries.medium} {
      padding-top: 0;
    }
  `,
  projectLead: css`
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 0.9375rem;
  `,
  email: css`
    color: ${theme.colors.primary.blue};
    font-size: 1rem;
    font-weight: 700;
  `,
  column2: css`
    position: relative;
    background: linear-gradient(
      89.67deg,
      ${theme.colors.primary.blue} 0.57%,
      #0cc 51.37%,
      ${theme.colors.secondary.purple} 99.09%
    );
    min-width: 320px;
    min-height: 205px;
  `,
  overlay: css`
    background: rgba(255, 255, 255, 0.5);
    min-height: 125px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: 0.9875rem;
    padding-bottom: 0.9375rem;
    @media ${queries.medium} {
      padding-left: 3.3231rem;
      padding-top: 3.3125rem;
    }
  `,
  dashboardIcon: css`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    svg {
      background: ${theme.colors.primary.blue};
      width: 40px;
      height: 40px;
      @media ${queries.medium} {
        width: 56px;
        height: 56px;
      }
    }
    .iconStroke {
      stroke: ${theme.colors.white};
      stroke-width: 1.5;
    }
    .dashboardIcon {
      fill: ${theme.colors.white};
    }
  `,
};
