import React, { ReactElement } from "react";
import TalentHubHeader from "./talent-hub-header";
import TalentHubContent from "./talent-hub-content";

const TalentHub = (): ReactElement => {
  return (
    <>
      <TalentHubHeader />
      <TalentHubContent />
    </>
  );
};

export default TalentHub;
