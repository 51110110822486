import { css } from "@emotion/react";
import theme from "src/theme";

const sharedButtonStyle = `
  flex: 1;
  display: flex;
  font-size: 0.9rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 0;
  padding: 0;
  background-color: ${theme.colors.white};
  font-family: ${theme.fonts.sofiaPro};
  font-weight: bold;
  border: 0;

  :first-of-type {
    border-right: 1px solid ${theme.colors.background.veryLightGray};
  }
`;

export default {
  drawerContentContainer: css`
    padding: 1rem 1rem 0 1rem;

    .ant-form-item-explain.ant-form-item-explain-error {
      padding-top: 0.4375rem;
      padding-left: 0.125rem;
      font-size: 16px;
    }
    margin-bottom: 5rem;
  `,
  drawerInfoContainer: css`
    margin-bottom: 1.5rem;
  `,
  mobileButtonContainerPrimary: css`
    ${sharedButtonStyle}
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary.blue};
  `,
  drawerHeader: css`
    color: ${theme.colors.secondary.navyBlue} !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;

    :first-of-type {
      margin-top: 1rem;
    }
  `,
  teamAdminCol: css`
    text-align: left;
  `,
  mobileButtonContainer: css`
    height: 3.125rem;
    background-color: ${theme.colors.white};
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    border: 0;
    border-top: 0.06rem solid ${theme.colors.background.veryLightGray};
    justify-content: center;
    font-size: 0.9rem;
    font-weight: bold;
    align-items: center;
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  `,
  subText: css`
    font-size: 0.875rem;
    color: ${theme.colors.background.darkGray};
    margin-top: -0.125rem;
  `,
  multiLine: css`
    display: flex;
    flex-direction: column;
  `,
  teamAdminPill: css`
    color: ${theme.colors.background.black};
    font-size: 0.625rem;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    margin-left: 0.3125rem;
    letter-spacing: 0;
    background-color: rgba(0, 204, 204, 0.2);
    border-radius: 0.75rem;
    vertical-align: middle;
  `,
  name: css`
    text-transform: capitalize;
  `,
};
