import { ReactElement, useEffect } from "react";
import { useImmer } from "use-immer";
import { FormModes } from "src/constants";
import TermForm from "./components/term-form";
import { ITermViewModel } from "../../interfaces";

interface IProps {
  viewModel: ITermViewModel;
  termsChanged: { [termId: string]: boolean };
  onChangesMade: (
    updatedTerms: { [termId: string]: boolean },
    isUpdateClicked?: boolean
  ) => void;
  onValidationChange?: (termId: string, isInvalid: boolean) => void;
  onCancelTerm: () => void;
}

const Term = ({
  viewModel,
  termsChanged,
  onChangesMade,
  onValidationChange,
  onCancelTerm,
}: IProps): ReactElement => {
  const [viewModelState, setViewModelState] = useImmer<ITermViewModel>(
    viewModel
  );

  useEffect(() => {
    setViewModelState(viewModel);
  }, [viewModel, setViewModelState]);

  return (
    <div id="term" data-testid="term-section">
      <TermForm
        mode={FormModes.Edit}
        term={viewModelState.termDetails}
        termChanged={termsChanged[viewModel.termDetails.id] ?? false}
        onValidationChange={onValidationChange}
        onChangesMade={onChangesMade}
        onCancelTerm={onCancelTerm}
      />
    </div>
  );
};

export default Term;
