import { Button, Drawer, Input, Typography } from "antd";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import SelectedGroupButton from "src/components/selected-group-button";
import DisclaimerInfoIcon from "src/app/assets/icons/component-icons/disclaimer-info-icon";
import styles from "../styles";

interface IProps {
  isOpen: boolean;
  isUserGroup: boolean;
  onClose: () => void;
  onCreateContactsGroup: (isUserGroup: boolean, groupName: string) => void;
}
export enum GroupType {
  MyGroups = "myGroups",
  OrgGroups = "orgGroups",
}
const CreateGroup: React.FC<IProps> = ({
  isOpen,
  isUserGroup,
  onCreateContactsGroup,
  onClose,
}: IProps): React.ReactElement => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(
    isUserGroup ? GroupType.MyGroups : GroupType.OrgGroups
  );

  const handleGroupNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setGroupName(e.target.value);
    },
    []
  );

  const handleClose = useCallback(() => {
    setGroupName("");
    setSelectedGroup(GroupType.MyGroups);
    onClose();
  }, [onClose]);

  const handleCreateContactsGroup = useCallback(() => {
    onCreateContactsGroup(selectedGroup === GroupType.MyGroups, groupName);
    handleClose();
  }, [groupName, handleClose, onCreateContactsGroup, selectedGroup]);

  const handleSelectedGroupChange = useCallback((groupType: GroupType) => {
    setSelectedGroup(groupType);
  }, []);

  return (
    <Drawer
      title={t("TalentPage##Create a Group")}
      placement="right"
      closable
      onClose={handleClose}
      open={isOpen}
      zIndex={1053}
      css={styles.drawerContainer}
      width={512}
      destroyOnClose
      closeIcon={
        <CloseIcon
          fill={theme.colors.black}
          height={13}
          width={14}
          title="Close"
          role="button"
        />
      }
    >
      <div css={styles.createGroupBodyContainer}>
        <div>
          <div css={styles.basicInputContainer}>
            <Typography.Text css={styles.basicInputLabel}>
              {t("TalentPage##Group name")}
            </Typography.Text>
          </div>
          <div>
            <Input
              value={groupName}
              onChange={handleGroupNameChange}
              data-testid="group-name-input"
            />
          </div>
        </div>
        <div css={styles.sectionMarginTop}>
          <div css={styles.basicInputContainer}>
            <Typography.Text css={styles.basicInputLabel}>
              {t("TalentPage##Select group type")}
            </Typography.Text>
          </div>
          <div css={styles.selectGroupRow}>
            <SelectedGroupButton
              selectedGroup={selectedGroup}
              groupType={GroupType.MyGroups}
              label={t("My groups")}
              onSelectedGroupChange={handleSelectedGroupChange}
            />
            <SelectedGroupButton
              selectedGroup={selectedGroup}
              groupType={GroupType.OrgGroups}
              label={t("Org groups")}
              onSelectedGroupChange={handleSelectedGroupChange}
            />
          </div>
        </div>
        <div css={styles.sectionMarginTop}>
          <Typography.Text css={styles.disclaimerLabel}>
            <DisclaimerInfoIcon />
            {t("TalentPage##DisclaimerForGroupCreation")}
          </Typography.Text>
        </div>
      </div>
      <div css={styles.createGroupFooter}>
        <div>
          <div />
          <Button
            css={styles.closeButton}
            tabIndex={0}
            aria-label="close"
            onClick={handleClose}
            type="text"
          >
            {t("Cancel")}
          </Button>
          <Button
            css={styles.manageButton}
            tabIndex={0}
            aria-label="ok"
            onClick={handleCreateContactsGroup}
            type="primary"
            data-testid="create-group-button"
          >
            {t("Confirm")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default CreateGroup;
