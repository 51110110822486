export const scrollToHash = (id: string, headerOffset: number): void => {
  const anchor = document.getElementById(id);

  if (anchor) {
    const elementPosition =
      anchor.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export default { scrollToHash };
