import { ReactElement } from "react";
import theme from "src/theme";

const ViewColumnsIcon = (): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16_11422)">
        <path
          d="M4 5V18H21V5H4ZM14 7V16H11V7H14ZM6 7H9V16H6V7ZM19 16H16V7H19V16Z"
          fill={theme.colors.black}
        />
      </g>
      <defs>
        <clipPath id="clip0_16_11422">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ViewColumnsIcon;
