import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import * as uuid from "uuid";
import {
  IFeeSplitDetailState,
  ITrackOwnershipLabelConfig,
} from "src/pages/projects/project/interfaces";
import { Currencies, Operation } from "src/constants";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import CurrencyAndPrice from "src/components/currency-and-price";
import { FormInstance } from "antd/lib/form";
import getValuesFromTalent from "src/utils/getValuesFromTalent";
import FeeSplitRow from "../../../music/components/fee-split/fee-split";
import { IFeeSplitConfig } from "../../../music/components/final-track-ownership/interfaces";
import mainStyles from "../../../../styles";

export enum FeeSplitName {
  writerFee = "writerFee",
  masterFee = "masterFee",
  publisherFee = "publisherFee",
}
interface IProps {
  // We should use useFormInstance instead of passing down the form as prop, but current antd version does not support that
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  totalMonetaryValue?: IMonetaryValue;
  items: Array<IFeeSplitDetailState>;
  labelConfig: ITrackOwnershipLabelConfig;
  isWritersShareSection?: boolean;
  propertyName: string;
  validateFeeField: boolean;
  validateDetailsFields: boolean;
  onUpdateFee: (field: string, updatedFee: IMonetaryValue) => void;
  onPerformOperation: (
    operation: Operation,
    data: { index: number; value?: IFeeSplitDetailState }
  ) => void;
}

const FinalTrackFeeSplit = ({
  totalMonetaryValue,
  items,
  form,
  labelConfig,
  isWritersShareSection = false,
  propertyName,
  validateFeeField = true,
  validateDetailsFields = true,
  onPerformOperation,
  onUpdateFee,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  useWindowSize();
  const isMobile = getIsMobile();
  const [rowItems, setRowItems] = useState<IFeeSplitDetailState[]>([]);

  const [totalFee, setTotalFee] = useState<number>(
    totalMonetaryValue?.value ?? 0
  );

  const handleFeeChange = useCallback(
    (field: string, updatedFee: IMonetaryValue) => {
      setTotalFee(updatedFee.value);
      onUpdateFee(field, updatedFee);
    },
    [onUpdateFee]
  );

  useEffect(() => {
    if (items.length !== 0) {
      setRowItems(items);
    } else {
      setRowItems([
        {
          contactReferences: [getValuesFromTalent()],
          split: "100",
          isNew: true,
          key: uuid.v4(),
        },
      ]);
    }
  }, [items]);

  const handleAddNewRowItem = useCallback(() => {
    onPerformOperation(Operation.add, { index: items.length });
  }, [items.length, onPerformOperation]);

  const handleDeleteRowItem = useCallback(
    (index: number) => {
      onPerformOperation(Operation.remove, { index });
    },
    [onPerformOperation]
  );

  const handleUpdateRowItem = useCallback(
    (index: number, feeSplitDetailState: IFeeSplitDetailState) => {
      onPerformOperation(Operation.update, {
        index,
        value: feeSplitDetailState,
      });
    },
    [onPerformOperation]
  );

  const totalMonetaryValueCurrency = useMemo(() => {
    return totalMonetaryValue?.currency ?? Currencies.USD.code;
  }, [totalMonetaryValue?.currency]);
  const totalMonetaryValuePrice = useMemo(() => {
    return totalMonetaryValue?.value ?? "";
  }, [totalMonetaryValue?.value]);

  return (
    <>
      <div css={mainStyles.collapsibleSectionContainer}>
        <div css={!isMobile && mainStyles.sizeOfQuarterDiv}>
          <CurrencyAndPrice
            currency={totalMonetaryValueCurrency}
            price={totalMonetaryValuePrice}
            currencyFormItemName={`${propertyName}Currency`}
            priceFormItemName={`${propertyName}Price`}
            testId="fee-currency"
            onChange={handleFeeChange}
            label={t(labelConfig.totalFeeLabel)}
            propertyName={propertyName}
            validateRequiredFields={validateFeeField}
          />
        </div>
        {(rowItems ?? []).map((feeSplit, feeSplitIndex) => {
          const isLastItem = feeSplitIndex === rowItems.length - 1;
          return (
            <FeeSplitRow
              onAddNewRowItem={handleAddNewRowItem}
              // eslint-disable-next-line react/no-array-index-key
              key={`${propertyName}Splits-${feeSplitIndex}`}
              form={form}
              totalFee={totalFee.toString()}
              feeSplitDetail={feeSplit}
              config={labelConfig as IFeeSplitConfig}
              index={feeSplitIndex}
              isLastItem={isLastItem}
              onDeleteRow={handleDeleteRowItem}
              onUpdateRow={handleUpdateRowItem}
              isWritersShareSection={isWritersShareSection}
              propertyName={`${propertyName}Splits`}
              validateRequiredFields={validateDetailsFields}
            />
          );
        })}
      </div>
    </>
  );
};

export default FinalTrackFeeSplit;
