import { Typography, Form, Switch, Input, Divider } from "antd";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IProjectRouteParams,
  IProjectPublishingData,
  IPublishingTriggeredBy,
} from "src/pages/projects/project/interfaces";
import ButtonSendNotifications from "src/components/button-send-notification";
import { IConfirmContentProps } from "src/components/interfaces";
import { useParams } from "react-router-dom";
import notifyPublishingTeam from "src/api/projects/notify-publishing-team";
import { AxiosError } from "axios";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import useAuth from "src/auth/use-auth";
import MobileDrawer from "src/components/mobile-drawer";
import ConfirmationDrawer from "src/components/mobile-drawer/confirmation-drawer";
import BaseModal from "src/components/modals/base-modal";
import { getIsMobile, getIsTablet, useWindowSize } from "@songtradr/spa-common";
import Content from "src/components/modals/confirmation-modal/content";
import formatDateTime from "src/utils/date-time";
import mainStyles from "../../styles";
import styles from "./styles";

interface IProps {
  projectPublishingData: IProjectPublishingData;
  publishingTeamEmails: string[];
  publishingTriggeredBy?: IPublishingTriggeredBy | null;
  onUpdatePublishingData: (data: IProjectPublishingData) => void;
  onNotifyPublishingTeam: () => Promise<void>;
}

const Publishing = ({
  projectPublishingData,
  publishingTeamEmails,
  publishingTriggeredBy,
  onUpdatePublishingData,
  onNotifyPublishingTeam,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const { id: projectId } = useParams<IProjectRouteParams>();
  const { fetchWrapper, organisationId } = useAuth();
  const [isNotificationModelOpen, setNotificationModelOpen] = useState(false);

  useWindowSize();
  const isMobile = getIsMobile();
  const isTablet = getIsTablet();

  const sendNotificationsToPublishingTeam = useCallback(async (): Promise<void> => {
    try {
      await fetchWrapper(notifyPublishingTeam, projectId, organisationId);
      await onNotifyPublishingTeam();
      SuccessToast(
        t(
          "ProjectsPage##The email was successfully sent to the publishing teams."
        )
      );
    } catch (reason) {
      const error = reason as AxiosError;

      log(
        DataDogLogTypes.ERROR,
        "An error occurred when sending an email to the publishing teams.",
        error
      );
      ErrorToast(
        "error sending notification to publishing team",
        t(
          "ProjectsPage##An error occurred when sending an email to the publishing teams."
        )
      );
    } finally {
      setNotificationModelOpen(false);
    }
  }, [fetchWrapper, onNotifyPublishingTeam, organisationId, projectId, t]);

  const confirmSendNotificationContentProps: IConfirmContentProps = useMemo(
    () => ({
      confirmAction: sendNotificationsToPublishingTeam,
      onClose: () => setNotificationModelOpen(false),
      primaryButtonLabel: "Confirm",
      secondaryButtonLabel: "defaultSecondaryButton",
      modalTitle: "ProjectsPage##Notify Publishing Team",
      mainContent: (
        <p css={mainStyles.sendNotificationsParagraph}>
          {`${t(
            "ProjectsPage##Please confirm you wish to send an email notification to"
          )} `}
          <b>{publishingTeamEmails.join(", ")}</b>
          {` ${t(
            "ProjectsPage##that this project is ready for publishing registration."
          )}`}
        </p>
      ),
    }),
    [publishingTeamEmails, sendNotificationsToPublishingTeam, t]
  );

  const handleSendNotificationModelOpen = useCallback(() => {
    setNotificationModelOpen(true);
  }, []);

  const handleToggleUpdate = useCallback(
    (field: string, value: boolean) => {
      onUpdatePublishingData({ [field]: value });
    },
    [onUpdatePublishingData]
  );

  const handleUpdateCopyright = useCallback(
    (field: string, checked: boolean, notes: string) => {
      onUpdatePublishingData({
        [field]: {
          checked,
          notes,
        },
      });
    },
    [onUpdatePublishingData]
  );

  const publishingTriggered = useMemo(() => {
    if (!publishingTriggeredBy) {
      return "";
    }
    const { name, atDateTime } = publishingTriggeredBy;

    return `${formatDateTime(new Date(atDateTime))} - ${name}`;
  }, [publishingTriggeredBy]);

  return (
    <div css={styles.container}>
      {!!publishingTeamEmails.length &&
        (isTablet || isMobile ? (
          <MobileDrawer
            placement="bottom"
            open={isNotificationModelOpen}
            closable={false}
            destroyOnClose
          >
            <ConfirmationDrawer {...confirmSendNotificationContentProps} />
          </MobileDrawer>
        ) : (
          <BaseModal
            onCancel={() => setNotificationModelOpen(false)}
            open={isNotificationModelOpen}
            content={<Content {...confirmSendNotificationContentProps} />}
            buttons={[]}
          />
        ))}
      <div>
        <div
          css={[
            mainStyles.sectionContainer,
            styles.publishingSectionContainer,
            mainStyles.lastOfSectionBottom,
          ]}
        >
          <div css={mainStyles.titleLabelContainer}>
            <Typography.Text css={mainStyles.titleLabel}>
              {t("ProjectsPage##Internal Creative")}
            </Typography.Text>
          </div>
          <div css={mainStyles.switchContainer}>
            <div css={mainStyles.basicInputContainer}>
              <Typography.Text
                css={[
                  mainStyles.basicInputLabel,
                  styles.basicLabelAdditionalStyles,
                ]}
              >
                {t("ProjectsPage##Is internal creative engaged?")}
              </Typography.Text>
            </div>
            <Form.Item
              css={[mainStyles.formItem, styles.additionalFormItemStyle]}
            >
              <Switch
                onChange={(toggle: boolean) => {
                  handleToggleUpdate("internalCreativeTeamEngaged", toggle);
                }}
                checked={
                  projectPublishingData.internalCreativeTeamEngaged ?? false
                }
                data-testid="internal-creative-team-engaged-toggle"
              />
            </Form.Item>
          </div>
          <ButtonSendNotifications
            onClick={handleSendNotificationModelOpen}
            ariaLabel="ProjectsPage##Notify Publishing Team"
            label="ProjectsPage##Notify Publishing Team"
            disabled={
              !(
                projectPublishingData.internalCreativeTeamEngaged &&
                !!publishingTeamEmails.length
              )
            }
          />
          <span css={mainStyles.basicInputLabel}>{publishingTriggered}</span>
        </div>
      </div>
      <Divider css={mainStyles.sectionDivider} />
      <div
        css={[
          mainStyles.sectionContainer,
          styles.publishingSectionContainer,
          mainStyles.lastOfSectionBottom,
        ]}
      >
        <div css={mainStyles.titleLabelContainer}>
          <Typography.Text css={mainStyles.titleLabel}>
            {t("ProjectsPage##Copyright Info")}
          </Typography.Text>
        </div>
        <div css={mainStyles.switchContainer}>
          <div css={mainStyles.basicInputContainer}>
            <Typography.Text
              css={[
                mainStyles.basicInputLabel,
                styles.basicLabelAdditionalStyles,
              ]}
            >
              {t("ProjectsPage##Is Copyright Info Processed")}
            </Typography.Text>
          </div>
          <Form.Item
            css={[mainStyles.formItem, styles.additionalFormItemStyle]}
          >
            <Switch
              onChange={(toggle: boolean) => {
                handleUpdateCopyright(
                  "copyright",
                  toggle,
                  projectPublishingData?.copyright?.notes ?? ""
                );
              }}
              checked={projectPublishingData?.copyright?.checked ?? false}
            />
          </Form.Item>
        </div>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text
            css={[
              mainStyles.basicInputLabel,
              styles.basicLabelAdditionalStyles,
            ]}
          >
            {t("ProjectsPage##Notes")}
          </Typography.Text>
        </div>
        <Input.TextArea
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleUpdateCopyright(
              "copyright",
              projectPublishingData?.copyright?.checked ?? false,
              event.target.value
            )
          }
          rows={6}
          name="copyrightNotes"
          value={projectPublishingData?.copyright?.notes ?? ""}
        />
      </div>
      <Divider css={mainStyles.sectionDivider} />
      <div
        css={[
          mainStyles.sectionContainer,
          styles.publishingSectionContainer,
          mainStyles.lastOfSectionBottom,
        ]}
      >
        <div css={mainStyles.titleLabelContainer}>
          <Typography.Text css={mainStyles.titleLabel}>
            {t("ProjectsPage##Publishing Rights")}
          </Typography.Text>
        </div>
        <div css={mainStyles.switchContainer}>
          <div css={mainStyles.basicInputContainer}>
            <Typography.Text
              css={[
                mainStyles.basicInputLabel,
                styles.basicLabelAdditionalStyles,
              ]}
            >
              {t("ProjectsPage##Are Publishing Rights Signed")}
            </Typography.Text>
          </div>
          <Form.Item
            css={[mainStyles.formItem, styles.additionalFormItemStyle]}
          >
            <Switch
              onChange={(toggle: boolean) => {
                handleUpdateCopyright(
                  "publishing",
                  toggle,
                  projectPublishingData?.publishing?.notes ?? ""
                );
              }}
              checked={projectPublishingData?.publishing?.checked ?? false}
            />
          </Form.Item>
        </div>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text
            css={[
              mainStyles.basicInputLabel,
              styles.basicLabelAdditionalStyles,
            ]}
          >
            {t("ProjectsPage##Notes")}
          </Typography.Text>
        </div>
        <Input.TextArea
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleUpdateCopyright(
              "publishing",
              projectPublishingData?.publishing?.checked ?? false,
              event.target.value
            )
          }
          rows={6}
          name="publishingNotes"
          value={projectPublishingData?.publishing?.notes ?? ""}
        />
      </div>
    </div>
  );
};

export default Publishing;
