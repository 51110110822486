import { Typography, Form } from "antd";
import React, { ReactElement, useCallback } from "react";
import ReactSelect, { ActionMeta } from "react-select";
import projectValidation from "src/utils/projectValidation";
import { useTranslation } from "react-i18next";
import { IUserDropdownProps } from "src/pages/team/interfaces";
import { getIsMobile } from "@songtradr/spa-common";
import { Contributor } from "src/pages/projects/project/interfaces";
import { customStyles, customStylesForProjectDropdown } from "../../styles";
import { IOption } from "../creative-agency-row";
import style from "../../../../styles";

export interface IParseLeadOptions {
  label: string;
  value: string;
  email: string;
  key: string;
}
interface IProps {
  client?: {
    label?: string;
    value?: string;
  };
  clientOptions: IOption[];
  contact?: IOption;
  contactOptions: IOption[];
  parsedLeadOptions: IParseLeadOptions[];
  onLeadMultiSelectChange: (
    contributor: Contributor,
    action: ActionMeta<IUserDropdownProps>
  ) => void;
  onContactDropdownChange: (field: string, data: IOption) => void;
  onClientDropdownChange: (field: string, data: IOption) => void;
}
const ClientDetailsAndProjectLeadsRow = ({
  client,
  clientOptions,
  contact,
  contactOptions,
  parsedLeadOptions,
  onLeadMultiSelectChange,
  onContactDropdownChange,
  onClientDropdownChange,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const formatOptionLabel = useCallback((data: IUserDropdownProps) => {
    const { label, email } = data;
    return (
      <>
        <div>{label}</div>
        <div css={style.emailLabel}>{email}</div>
      </>
    );
  }, []);
  return (
    <div css={isMobile ? style.mobileSectionContainer : style.clientContainer}>
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##Client")}
          </Typography.Text>
        </div>
        <ReactSelect
          value={client as IOption}
          placeholder=""
          isSearchable
          name="client"
          options={clientOptions}
          onChange={(data) => onClientDropdownChange("client", data as IOption)}
          styles={customStyles}
          id="client-dropdown"
          data-testid="client-dropdown"
        />
      </div>
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##Contact details")}
          </Typography.Text>
        </div>
        <ReactSelect
          value={contact}
          isClearable
          isSearchable
          name="contact"
          placeholder=""
          options={contactOptions}
          onChange={(data) => {
            const selectedValue = data as IOption | null;
            if (selectedValue) {
              onContactDropdownChange("clientContact", selectedValue);
            }
          }}
          styles={customStyles}
          id="client-contact-dropdown"
          data-testid="client-contact-dropdown"
        />
      </div>
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##Project leads")}
          </Typography.Text>
        </div>
        <Form.Item
          name="leads"
          rules={[projectValidation.yupDetailsSchemaSync]}
          css={style.formItem}
        >
          <ReactSelect
            isMulti
            isClearable={false}
            styles={customStylesForProjectDropdown}
            placeholder=""
            options={parsedLeadOptions}
            formatOptionLabel={formatOptionLabel}
            onChange={(_, action) =>
              onLeadMultiSelectChange(Contributor.LEAD, action)
            }
            data-testid="project-lead-dropdown"
            id="project-lead-dropdown"
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default ClientDetailsAndProjectLeadsRow;
