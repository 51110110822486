import axios from "axios";
import { IAllProjectTypes } from "src/pages/projects/project/components/services/types";
import { getProjectTypesUrl } from "../../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const getProjectTypes = (
  accessToken: string,
  organisationId: string
): Promise<IAllProjectTypes | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios
    .get(getProjectTypesUrl(organisationId), options)
    .then(({ data }) => {
      return data as IAllProjectTypes;
    });
};

export default getProjectTypes;
