import React, { ReactElement } from "react";
import { Row, Col } from "antd";
import { IOrganisation } from "../../interfaces/organisation";
import { IOrganisationUrlParams } from "../../interfaces/organisation-url-params";
import ThankYou from "./components/thank-you";
import PageFlourish from "../../components/page-flourish";
import RegistrationForm from "./components/registration-form";
import styles from "./styles";
import OrganisationExists from "./components/organisation-exists";

interface IProps {
  data: IOrganisation;
  onChange: (updatedData: IOrganisation) => void;
  onSubmit: () => Promise<void>;
  isSavePending: boolean;
  isRegistered: boolean;
  inviteParams?: IOrganisationUrlParams;
  organisationExists: boolean;
}

const SignUp = (props: IProps): ReactElement | null => {
  const {
    data,
    onChange,
    onSubmit,
    isSavePending,
    isRegistered,
    inviteParams,
    organisationExists,
  } = props;

  if (isRegistered) {
    return (
      <Row>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 24, order: 2 }}
          lg={{ span: 12, order: 1 }}
          xl={{ span: 12, order: 1 }}
        >
          <PageFlourish />
        </Col>
        <Col
          xs={{ span: 22, offset: 2, order: 1 }}
          sm={{ span: 22, offset: 2, order: 1 }}
          md={{ span: 22, offset: 2, order: 1 }}
          lg={{ span: 10, offset: 2, order: 2 }}
          xl={{ span: 10, offset: 2, order: 2 }}
          css={styles.column}
        >
          <ThankYou
            organisationType={data.orgType}
            requesterEmailAddress={data.contact.email}
            inviteParams={inviteParams}
          />
        </Col>
      </Row>
    );
  }

  if (organisationExists) {
    return (
      <Row>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 24, order: 2 }}
          lg={{ span: 12, order: 1 }}
          xl={{ span: 12, order: 1 }}
        >
          <PageFlourish />
        </Col>
        <Col
          xs={{ span: 22, offset: 2, order: 1 }}
          sm={{ span: 22, offset: 2, order: 1 }}
          md={{ span: 22, offset: 2, order: 1 }}
          lg={{ span: 10, offset: 2, order: 2 }}
          xl={{ span: 10, offset: 2, order: 2 }}
          css={styles.column}
        >
          <OrganisationExists />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 24, order: 2 }}
        lg={{ span: 12, order: 1 }}
        xl={{ span: 12, order: 1 }}
      >
        <PageFlourish />
      </Col>
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 22, offset: 2, order: 1 }}
        lg={{ span: 10, offset: 2, order: 2 }}
        xl={{ span: 10, offset: 2, order: 2 }}
        css={styles.column}
      >
        <div css={styles.formWrapper}>
          <RegistrationForm
            data={data}
            onChange={onChange}
            onSubmit={onSubmit}
            isSavePending={isSavePending}
          />
        </div>
      </Col>
    </Row>
  );
};

export default SignUp;
