import { Button, Drawer, Typography } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { INote } from "src/pages/contact/interfaces";
import styles from "../styles";
import { IContactMappedData } from "./utils";

interface IProps {
  isOpen: boolean;
  contact: IContactMappedData;
  organisationId: string;
  onClose: () => void;
}
interface IViewContactPropertyProps {
  title: string;
  value?: string;
  valueElement?: JSX.Element;
}
interface IViewContactEmailProps {
  title: string;
  email?: string;
}
interface IViewContactHyperLinkProps {
  title: string;
  value?: string;
}

const ViewContactProperty = ({
  title,
  value,
  valueElement,
}: IViewContactPropertyProps): React.ReactElement => {
  return (
    <div css={styles.propertyContent}>
      <Typography.Text>{title}</Typography.Text>
      {!valueElement && <Typography.Text>{value || "-"}</Typography.Text>}
      {valueElement}
    </div>
  );
};

const getFormattedWebsite = (website: string) => {
  // We have to check if website starts with https, http or //
  const isProtocolPrefix =
    website.includes("http") ||
    website.includes("https") ||
    /^\/\//.test(website);

  const formattedWebsite = isProtocolPrefix ? website : `//${website.trim()}`;
  return formattedWebsite;
};

const ViewContactEmail = ({
  title,
  email,
}: IViewContactEmailProps): React.ReactElement => {
  return (
    <div css={styles.propertyContent}>
      <Typography.Text>{title}</Typography.Text>
      <Typography.Text>
        {email ? (
          <a className="mailto" href={`mailto:${email}`}>
            {email}
          </a>
        ) : (
          "-"
        )}
      </Typography.Text>
    </div>
  );
};

const ViewContactHyperLink = ({
  title,
  value,
}: IViewContactHyperLinkProps): React.ReactElement => {
  const values = value?.split(",") ?? [];
  const valuesWithHref = values.map((link, index) => {
    return (
      <>
        <a href={getFormattedWebsite(link)}>{link}</a>
        {index < values.length - 1 && ", "}
      </>
    );
  });

  return (
    <div css={styles.propertyContent}>
      <Typography.Text>{title}</Typography.Text>
      <Typography.Text>{value ? valuesWithHref : "-"}</Typography.Text>
    </div>
  );
};
const ViewContact: React.FC<IProps> = ({
  contact,
  isOpen,
  organisationId,
  onClose,
}: IProps): React.ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const renderNotes = useCallback((property: string, notes: INote[]) => {
    if (notes.length === 0) {
      return <Typography.Text>-</Typography.Text>;
    }
    const nbspChar = "\xa0";
    const dateFromNameSeparator = `${nbspChar}${nbspChar}-${nbspChar}${nbspChar}`;

    return (
      <>
        {notes.map((note, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${property}-${index}`}
            css={[
              styles.notesContainer,
              notes.length - 1 !== index && styles.notesMarginBottom,
            ]}
          >
            <Typography.Text>{note.note}</Typography.Text>
            {note.date && (
              <Typography.Text css={styles.noteDate}>
                {format(parseISO(note.date), "MMM do, yyyy")}
                {note.userFullname &&
                  `${dateFromNameSeparator}${note.userFullname}`}
              </Typography.Text>
            )}
          </div>
        ))}
      </>
    );
  }, []);

  return (
    <Drawer
      title={t("ViewContact##View contact details")}
      placement="right"
      closable
      onClose={onClose}
      open={isOpen}
      zIndex={1053}
      css={styles.drawerContainer}
      width={512}
      destroyOnClose
      closeIcon={
        <CloseIcon
          fill={theme.colors.black}
          height={13}
          width={14}
          title="Close"
          role="button"
        />
      }
    >
      <ViewContactProperty
        title={t("ViewContact##Full Name")}
        value={contact.name}
      />
      <ViewContactProperty
        title={t("ViewContact##Pronoun")}
        value={contact.pronoun}
      />
      <ViewContactProperty
        title={t("ViewContact##Agency/Writing Partnership")}
        value={contact.agencyWritingPartnership}
      />
      <ViewContactEmail title={t("ViewContact##Email")} email={contact.email} />

      <ViewContactProperty
        title={t("ViewContact##Phone Number")}
        value={contact.phone}
      />
      <ViewContactProperty
        title={t("ViewContact##Base Location")}
        value={contact.baseLocation}
      />
      <ViewContactHyperLink
        title={t("ViewContact##Social Media/Website")}
        value={contact.socialMediaWebsite}
      />
      <ViewContactHyperLink
        title={t("ViewContact##Showreel")}
        value={contact.showreelUrls}
      />
      <ViewContactProperty
        title={t("ViewContact##Native/Main Language")}
        value={contact.mainLanguage}
      />
      <ViewContactProperty
        title={t("ViewContact##Other Languages")}
        value={contact.otherLanguages}
      />
      <ViewContactProperty
        title={t("ViewContact##Billing Name")}
        value={contact.billingName}
      />
      <ViewContactProperty
        title={t("ViewContact##Billing Address")}
        value={contact.billingAddress}
      />
      <ViewContactProperty
        title={t("ViewContact##Neurodiverse")}
        value={contact.neurodiverse}
      />
      <ViewContactProperty
        title={t("ViewContact##Neurodiverse Note")}
        value={contact.neurodiverseNote}
      />
      <ViewContactProperty
        title={t("ViewContact##Physical Condition/Illness")}
        value={contact.physicalConditionsIllness}
      />
      <ViewContactProperty
        title={t("ViewContact##Racial Characteristics")}
        value={contact.racialCharacteristics}
      />
      <ViewContactProperty
        title={t("ViewContact##Gender Identity")}
        value={contact.genderIdentity}
      />
      <ViewContactProperty
        title={t("ViewContact##Sexual Orientation")}
        value={contact.sexualOrientation}
      />
      <ViewContactProperty
        title={t("ViewContact##CMA Signed")}
        value={contact.signedCMA}
      />
      <ViewContactProperty
        title={t("ViewContact##NDA Signed")}
        value={contact.signedNDA}
      />
      <ViewContactProperty
        title={t("ViewContact##Minimum Demo Fee")}
        value={contact.minimumDemoFee}
      />
      <ViewContactProperty
        title={t("ViewContact##Performing Rights Organisation Number")}
        value={contact.performingRightsOrganisationNumber}
      />
      <ViewContactProperty
        title={t("ViewContact##Rating")}
        value={contact.collaborationStatus}
      />
      <ViewContactProperty
        title={t("ViewContact##Skill Set")}
        value={contact.skillSet}
      />
      <ViewContactProperty
        title={t("ViewContact##Principal Instruments")}
        value={contact.principalInstruments}
      />
      <ViewContactProperty
        title={t("ViewContact##Additional Instruments")}
        value={contact.additionalInstruments}
      />
      <ViewContactProperty
        title={t("ViewContact##Key Genres")}
        value={contact.keyGenres}
      />
      <ViewContactProperty
        title={t("ViewContact##Additional Genres")}
        value={contact.additionalGenres}
      />
      <ViewContactProperty
        title={t("ViewContact##Vocalist")}
        value={contact.vocalist}
      />
      <ViewContactProperty
        title={t("ViewContact##Minimum Demo Turnaround")}
        value={contact.minimumDemoTurnaroundHours}
      />
      <ViewContactProperty
        title={t("ViewContact##Previous Work")}
        value={contact.previousWork}
      />
      <ViewContactProperty
        title={t("ViewContact##Interested Projects")}
        value={contact.interestedProjects}
      />
      <ViewContactProperty
        title={t("ViewContact##Talent Notes")}
        valueElement={renderNotes("talentNotes", contact.talentNotes ?? [])}
      />
      <ViewContactProperty
        title={t("ViewContact##Awards")}
        valueElement={renderNotes("awardNotes", contact.awardNotes ?? [])}
      />
      <ViewContactProperty
        title={t("ViewContact##Internal Notes")}
        valueElement={renderNotes("internalNotes", contact.internalNotes ?? [])}
      />
      <div css={styles.viewContactFooter}>
        <div>
          <div />
          <Button
            css={styles.closeButton}
            tabIndex={0}
            aria-label="close"
            onClick={onClose}
            type="text"
          >
            {t("ViewContact##Close")}
          </Button>
          {organisationId === contact.createdByOrgId && (
            <Button
              css={styles.manageButton}
              tabIndex={0}
              aria-label="ok"
              onClick={() => history.push(`/talent/${contact.id}`)}
              type="primary"
              data-testid="manage-contact-button"
            >
              {t("ViewContact##Manage Contact")}
            </Button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default ViewContact;
