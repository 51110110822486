import { ReactElement } from "react";
import theme from "src/theme";
import styles from "./styles";

interface IProps {
  width: number;
  progress: number;
  spin?: boolean;
  playing?: boolean;
}

const ProgressCircle = ({
  width,
  progress,
  spin = false,
  playing = true,
}: IProps): ReactElement | null => {
  if (!width) return null;

  const thickness = 3;
  const radius = width / 2 - thickness / 2 - width * 0.05;
  const circumference = radius * 2 * Math.PI;

  return (
    <svg height={width} width={width}>
      <circle
        css={[styles.circle].concat(spin ? [styles.animated] : [])}
        stroke={playing ? "white" : theme.colors.primary.blue}
        strokeWidth={thickness}
        fill="transparent"
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={circumference - circumference * progress}
        r={radius}
        cx={width / 2}
        cy={width / 2}
      />
    </svg>
  );
};

export default ProgressCircle;
