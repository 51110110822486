import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  fileUploadTable: css`
    height: 100%;
    pointer-events: none;
  `,
  fileUploadText: css`
    margin-top: 1.25rem;
  `,
  browseBtn: css`
    color: ${theme.colors.primary.blue};
    font-weight: bold;
    .ant-upload-drag-container {
      cursor: pointer;
    }
    pointer-events: auto;
  `,
  deleteButton: css`
    pointer-events: auto;
    margin-left: -0.625rem;
  `,
  downloadButton: css`
    pointer-events: auto;
    margin-left: -0.625rem;
  `,
};
