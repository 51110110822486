import { css } from "@emotion/react";
import { StylesConfig } from "react-select";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common";

type MyOptionType = {
  label: string;
  value: string;
};
export const customStyles: StylesConfig<MyOptionType> = {
  menu: (provided) => ({
    ...provided,
    fontSize: 16,
    fontWeight: 400,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
    marginTop: -8,
    minWidth: 55,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 4,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
    color: `${theme.colors.primary.blue}`,

    ":hover": {
      color: `${theme.colors.secondary.lightGray}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 16,
    height: 56,
    fontWeight: 400,
  }),
  control: (provided) => ({
    ...provided,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    border: `0.0625rem solid ${theme.colors.background.lightGray}`,
    borderRight: "none",
    maxHeight: 56,
  }),
};

const musicSectionContainerStyle = `
  display: flex;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  justify-content: space-between;
  gap: 0.5rem;
`;
export default {
  unionFeeContainer: css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1;
    > div:last-child {
      width: 100%;
    }
    width: 100%;
    @media ${queries.large} {
      width: calc((100% - 76px) / 5 - 0.5rem);
    }
  `,
  musicSectionWithColumnsContainer: css`
    ${musicSectionContainerStyle}
    flex-direction: column;
    gap: 0.5rem;
  `,
  unionFeeMobileContainer: css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    > div:last-child {
      height: auto !important;
    }
    width: 100%;
    @media ${queries.medium} {
      > div:last-child {
        height: 38px !important;
      }
    }
    @media ${queries.large} {
      width: calc((100% - 76px) / 5 - 0.5rem);
    }
  `,
  formContainer: css`
    margin-bottom: 0 !important;
  `,
  feeContainer: css`
    display: flex;
    flex-direction: row;
  `,

  mobileFeeContainer: css`
    display: flex;
    flex-direction: column;
  `,
  container: css`
    width: 100%;
    .ant-row {
      margin-left: 0 !important;
    }
  `,

  valueInput: css`
    :not(:focus) {
      border-left: none;
    }
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  `,
};
