import {
  IContactReferences,
  TalentType,
} from "src/pages/projects/project/interfaces";

const getValuesFromTalent = (
  talent?: IContactReferences
): IContactReferences => {
  const newTalent: IContactReferences = {
    name: talent?.name ?? "",
    email: talent?.email ?? "",
    id: talent?.id ?? "",
    agencyWritingPartnership: talent?.agencyWritingPartnership ?? "",
    groupByAgency: talent?.groupByAgency ?? false,
    type: talent?.type ?? TalentType.OpenForm,
  };
  if (talent?.ipi) {
    newTalent.ipi = talent.ipi;
  }
  return newTalent;
};

export default getValuesFromTalent;
