import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

export default {
  customHeaderContentContainer: css`
    display: none;
    @media ${queries.medium} {
      display: initial;
      position: absolute;
      right: 1.3rem;
      top: 1rem;
    }
    @media ${queries.large} {
      right: 3.1rem;
      top: 1rem;
    }
  `,
  titleMembers: css`
    font-size: 16px;
    color: var(--colors-secondary-navy-blue);
  `,
  heading: css`
    font-family: ${theme.fonts.sofiaPro};
    margin-top: auto;
    padding-bottom: 0.3125rem;
    @media ${queries.medium} {
      font-size: 2.25rem !important;
      margin-bottom: 0.9375rem;
    }
    @media ${queries.large} {
      margin-bottom: 0 !important;
      font-size: 2.75rem !important;
    }
  `,
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3.125rem;
  `,
  contentContainer: css`
    width: 410px;
  `,
  flexContainer: css`
    display: flex;
  `,
  image: css`
    height: 240px;
    width: 240px;
    @media ${queries.medium} {
      width: 230px;
      height: 230px;
    }
  `,
  button: css`
    width: 100%;
  `,
  stickyFooterButton: css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 100;
    &:focus,
    &:hover {
      border-radius: 0 !important;
    }

    @media ${queries.medium} {
      display: none;
    }
  `,
  textContainer: css`
    display: block;
    text-align: left;
    padding-top: 0.625rem;
    padding-bottom: 1.25rem;
  `,
  teamName: css`
    height: 28px;
    line-height: 28px;
  `,
  teamEmail: css`
    font-size: 0.875rem;
    color: ${theme.colors.background.darkGray};
    margin-top: -0.3125rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  teamAdminCol: css`
    text-align: center;
  `,
  teamAdminPill: css`
    color: ${theme.colors.background.black};
    font-size: 0.625rem;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    margin-left: 0.625rem;
    letter-spacing: 0;
    background-color: rgba(0, 204, 204, 0.2);
    border-radius: 0.75rem;
    vertical-align: top;
  `,
  teamInviteEmail: css`
    font-size: 1rem;
    color: ${theme.colors.background.darkGray};
    margin-top: -0.3125rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  teamInviteStatus: css`
    font-size: 0.75rem;
    text-align: center;
    padding: 0.5rem 1rem;
    color: ${theme.colors.background.black};
    margin-top: -0.3125rem;
    background-color: rgba(0, 204, 204, 0.2);
    border-radius: 1rem;
  `,
  teamInviteResend: css`
    font-size: 1rem;
    font-weight: ${theme.typography.proximaNova.link.fontWeight};
    color: ${theme.colors.primary.blue};
    margin-top: -0.25rem;
    text-align: right;
    cursor: pointer;
    margin-left: 1rem;

    :hover {
      color: #0d2481;
      font-weight: bold;
    }

    @media ${queries.medium} {
      margin-top: -0.3125rem;
      margin-left: 0 !important;
      padding-right: 0.5rem;
    }
  `,
  teamInviteMobile: css`
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    max-height: 2rem;
  `,
  teamInviteStatusMobile: css`
    font-size: 0.625rem;
    text-align: center;
    color: ${theme.colors.background.black};
    background-color: rgba(0, 204, 204, 0.2);
    border-radius: 0.75rem;
    padding: 0.15rem 0.4rem;
    align-self: center;
  `,
  deleteButtonMobile: css`
    button {
      max-height: 1rem !important;
      margin-right: 0 !important;
      padding-right: 0 !important;

      & svg {
        margin-right: -1rem !important;
      }
    }
  `,
  titleContainer: css`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  `,
  headerContainer: css`
    padding: 0.8rem 0;
  `,
  emailContainer: css`
    padding: 0.75rem 0;
    color: ${theme.colors.background.darkGray};
  `,
  sortContainer: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};
    position: relative;
    left: 1rem;
  `,
  sortSpan: css`
    left: 0.4rem;
    position: relative;
    font-size: 1rem;
    font-weight: bold;
  `,
  sortButton: css`
    padding: 0;
    min-width: auto;
    height: auto;
    margin-left: 0.3125rem;
  `,
  table: css`
    .ant-table-tbody > tr > td {
      font-size: 16px;
    }

    .delete-team-member-button {
      text-align: right;
    }
    .team-invite-align-right {
      text-align: right;
    }
  `,
  drawer: css`
    .ant-drawer-content {
      min-height: 18.75rem !important;
    }
  `,
  moreInfo: css`
    margin-right: -1rem;
  `,
  formContainer: css`
    flex: 1;
    border-radius: 0.25rem;
    overflow: auto;
    background-color: ${theme.colors.white};
    box-shadow: 0 0.063rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    margin-bottom: 5rem;
    @media ${queries.large} {
      margin-left: 19rem;
    }
  `,
};
