import { getIsMobile } from "@songtradr/spa-common";
import { Col, Row } from "antd";
import { cloneDeep } from "lodash";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MobileDrawer from "src/components/mobile-drawer";
import useAuth from "src/auth/use-auth";
import FileUploader from "src/components/file-upload";
import { checkFilesCurrentlyUploading } from "src/components/file-upload/helpers";
import { IDeleteSelectedFile } from "src/components/file-upload/interfaces";
import {
  DocumentCategories,
  IProjectAttachment,
  IProjectRouteParams,
} from "src/pages/projects/project/interfaces";
import { AttachmentType } from "src/constants";
import style from "../../../../styles";
import { IProjectUploadFile } from "../../../details/components/interfaces";
import { DocumentsTableColumns, DocumentsTableData } from "../../table-data";
import { FileDrawerTypes, generateDrawer } from "../mobile-drawers";

interface IProps {
  attachments: IProjectAttachment[];
}

const LoopsResults = ({ attachments }: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const { getAccessToken, organisationId } = useAuth();
  const [testResultFileList, setTestResultFileList] = useState<
    IProjectUploadFile[]
  >([]);
  const handleSetTestResultFileList = useCallback(
    (files: IProjectUploadFile[]) => {
      setTestResultFileList(files);
    },
    []
  );
  const [accessToken, setAccessToken] = useState("");
  const { id: projectId } = useParams<IProjectRouteParams>();
  const [
    selectedFileToDelete,
    setSelectedFileToDelete,
  ] = useState<IDeleteSelectedFile>();
  const handleSetSelectedFileToDelete = useCallback(
    (file: IDeleteSelectedFile | undefined) => {
      setSelectedFileToDelete(file);
    },
    []
  );
  const [showFileUploadDeleteModal, setShowFileUploadDeleteModal] = useState(
    false
  );
  const handleShowFileUploadDeleteModal = useCallback((show: boolean) => {
    setShowFileUploadDeleteModal(show);
  }, []);
  const [isDrawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [activeFile, setActiveFile] = useState<IProjectUploadFile>();
  const handleSetActiveFile = useCallback(
    (file: IProjectUploadFile | undefined) => {
      setActiveFile(file);
    },
    []
  );
  const [activeDrawer, setActiveDrawer] = useState<FileDrawerTypes>(
    FileDrawerTypes.FileInfoDrawer
  );
  const handleSetActiveDrawer = useCallback((drawerType: FileDrawerTypes) => {
    setActiveDrawer(drawerType);
  }, []);

  const toggleDrawer = useCallback(() => {
    setDrawerVisible((state) => !state);
  }, []);

  useEffect(() => {
    if (!checkFilesCurrentlyUploading(testResultFileList)) {
      setTestResultFileList(cloneDeep(attachments));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  useEffect(() => {
    void (() => {
      const userAccessToken: string = getAccessToken();
      setAccessToken(userAccessToken);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Row role="row" gutter={[8, 8]}>
        <Col span={24}>
          <div css={style.documentsUploadContainer}>
            <FileUploader
              attachmentType={AttachmentType.Documents}
              documentCategory={DocumentCategories.TestResult}
              invalidFileSizeErrorMessage={t(
                "File size exceeds maximum limit of 2GB"
              )}
              existingFiles={testResultFileList}
              setFileList={handleSetTestResultFileList}
              tableData={DocumentsTableData({
                filesList: testResultFileList,
                accessToken,
                projectId,
                organisationId,
                setShowModal: handleShowFileUploadDeleteModal,
                setSelectedFileToDelete: handleSetSelectedFileToDelete,
                toggleDrawer,
                setActiveDrawer: handleSetActiveDrawer,
                setActiveFile: handleSetActiveFile,
              })}
              selectedFileToDelete={selectedFileToDelete}
              tableColumns={DocumentsTableColumns(isMobile, t)}
              showModal={showFileUploadDeleteModal}
              setShowModal={handleShowFileUploadDeleteModal}
              additionalContainerStyles={style.documentsFileUploader}
            />
          </div>
        </Col>
      </Row>
      {isMobile && (
        <MobileDrawer
          placement="bottom"
          onClose={toggleDrawer}
          open={isDrawerVisible}
          key="bottom"
          closable={false}
          data-testid="mobile-drawer-container"
          className="documents-drawer-container"
        >
          {generateDrawer(
            activeDrawer,
            handleSetActiveDrawer,
            toggleDrawer,
            accessToken,
            projectId,
            organisationId,
            handleSetTestResultFileList,
            testResultFileList,
            handleShowFileUploadDeleteModal,
            selectedFileToDelete,
            activeFile
          )}
        </MobileDrawer>
      )}
    </div>
  );
};

export default LoopsResults;
