import { OrganisationRequesterRoles } from "@songtradr/vinyl-common";
import { IPostOrganisationRequestDto } from "src/api/organisation-request/post-organisation-request-dto.interface";
import { IOrganisation } from "../../../interfaces/organisation";

interface IProps {
  organisation: IOrganisation;
}

/*
  TODO - I've hardcoded some values here temporarily to get around the linting issues.
  We are planning on redoing the entire sign up flow, so likely this wont be used.
*/
export default (request: IProps): IPostOrganisationRequestDto => ({
  name: request.organisation.name,
  orgType: request.organisation.orgType,
  requester: {
    email: request.organisation.contact.email,
    role: OrganisationRequesterRoles.Creative,
    phone: "0000000000",
  },
});
