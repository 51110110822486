import { ReactElement } from "react";

const SelectedIcon = (): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75347 16.8492L6.11051 13.2063C5.96316 13.0589 5.96316 12.8194 6.11051 12.672L7.71323 11.0693C7.86058 10.922 8.10012 10.922 8.24747 11.0693L9.75347 12.5753C9.90082 12.7227 10.1404 12.7227 10.2877 12.5753L15.7525 7.11051C15.8999 6.96316 16.1394 6.96316 16.2868 7.11051L17.8895 8.71323C18.0368 8.86058 18.0368 9.10012 17.8895 9.24747L10.2877 16.8492C10.1404 16.9966 9.90082 16.9966 9.75347 16.8492Z"
        fill="#111111"
      />
    </svg>
  );
};

export default SelectedIcon;
