import axios from "axios";
import buildOrganisationsBaseUrl from "src/api/base-url-helper";
import { IOrganisation } from "../../../interfaces/organisation";
import { getJsonContentHeader, getAuthHeader } from "../../headers";

const getOrganisation = (
  organisationId: string,
  accessToken: string
): Promise<IOrganisation | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };
  return axios
    .get(buildOrganisationsBaseUrl(organisationId), options)
    .then(({ data }) => data as IOrganisation);
};

export default getOrganisation;
