import { SerializedStyles } from "@emotion/react";
import {
  getIsDesktop,
  getIsMobile,
  getIsTablet,
  useWindowSize,
} from "@songtradr/spa-common";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/lib/upload/interface";
import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AttachmentType, ExcludedFileExtensions } from "src/constants";
import getPresignedUploadUrl from "src/api/attachments/get-presigned-upload-url";
import { IGetPresignedURLResponseData } from "src/api/attachments/interfaces";
import { getProject } from "src/api/projects";
import UploadIcon from "src/app/assets/icons/component-icons/upload-icon";
import useAuth from "src/auth/use-auth";
import MobileDrawer from "src/components/mobile-drawer";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import { IProjectUploadFile } from "src/pages/projects/project/components/details/components/interfaces";
import { IFinalTrackFilesDataSource } from "src/pages/projects/project/components/music/components/final-track-attachments/table-data/interfaces";
import { IFileDataSource } from "src/pages/projects/project/components/documents/table-data";
import useProject from "src/providers/project/hooks";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import {
  DocumentCategories,
  IProjectRouteParams,
} from "src/pages/projects/project/interfaces";
import Button from "../button";
import { IConfirmContentProps } from "../interfaces";
import ConfirmationDrawer from "../mobile-drawer/confirmation-drawer";
import BaseModal from "../modals/base-modal";
import Content from "../modals/confirmation-modal/content";
import FileUploadTable from "./components/table";
import FileUploadProgress from "./components/upload-progress";
import { handleDeleteFile, handleVerifyFileUpload } from "./helpers";
import {
  ICustomRequest,
  IDeleteSelectedFile,
  IFileUploadStatus,
  IRcFile,
} from "./interfaces";
import styles from "./styles";
import { ErrorToast } from "../toast-notification";

interface IProps {
  maxFileSize?: number;
  existingFiles: Array<UploadFile>;
  setFileList: (files: IProjectUploadFile[]) => void;
  tableColumns: Array<IColumnsData>;
  tableData: Array<IFinalTrackFilesDataSource | IFileDataSource>;
  invalidFileSizeErrorMessage: string;
  attachmentType: AttachmentType;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  selectedFileToDelete: IDeleteSelectedFile | undefined;
  additionalContainerStyles?: SerializedStyles;
  documentCategory?: DocumentCategories;
  trackId?: string;
}

const FileUploader = memo(
  ({
    maxFileSize = 2147483648,
    existingFiles,
    setFileList,
    tableColumns,
    tableData,
    invalidFileSizeErrorMessage,
    attachmentType,
    setShowModal,
    showModal,
    selectedFileToDelete,
    additionalContainerStyles,
    documentCategory,
    trackId,
  }: IProps): ReactElement => {
    const [uploadingComplete, setUploadingComplete] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const { getAccessToken, isSessionValid, user, organisationId } = useAuth();

    const { t } = useTranslation();
    const { storeAttachments } = useProject();

    useWindowSize();

    const isTablet = getIsTablet();
    const isMobile = getIsMobile();
    const showDragAndDrop = getIsDesktop();
    const browseButtonText = useMemo(
      () => (showDragAndDrop ? t("or browse for files") : t("BrowseForFiles")),
      [showDragAndDrop, t]
    );

    const dragAndDropButton = (
      <span>
        <Button
          css={styles.textButton}
          ariaLabel={browseButtonText}
          noLabelTranslation
        >
          {t("Choose files")}
        </Button>
        <b css={styles.dragAndDropButtonAdditionalText}>{` ${t(
          "or drag here"
        )}`}</b>
      </span>
    );

    const sizeLimitText = (
      <span css={styles.sizeLimitText}>
        {`${t("Size limit")}: ${Math.ceil(maxFileSize / 8 / 1024 / 1024)}MB`}
      </span>
    );
    const antUploadDragIconStyleClass = useMemo(
      () =>
        showDragAndDrop
          ? "ant-upload-drag-icon"
          : "ant-upload-drag-icon-no-drag",
      [showDragAndDrop]
    );

    const { id: projectId } = useParams<IProjectRouteParams>();

    const fileUploaderId = useMemo(
      () =>
        `fileUploader${attachmentType}${
          documentCategory ? documentCategory.toString() : ""
        }`,
      [attachmentType, documentCategory]
    );

    const isFileValid = useCallback(
      (
        _: AttachmentType,
        file: UploadFile
      ): { isValid: boolean; errorMessage: string } => {
        const documentExtension: string = file.name.substring(
          file.name.lastIndexOf(".")
        );

        if (ExcludedFileExtensions.includes(documentExtension.toUpperCase()))
          return {
            isValid: false,
            errorMessage: "File format is not supported.",
          };

        if (file.size > maxFileSize)
          return { isValid: false, errorMessage: invalidFileSizeErrorMessage };

        return {
          isValid: true,
          errorMessage: "",
        };
      },
      [invalidFileSizeErrorMessage, maxFileSize]
    );

    const container = useMemo(() => {
      const containerStyles =
        existingFiles.length > 0
          ? [styles.container, styles.containerWithFiles]
          : [styles.container, styles.containerNoFiles];

      if (additionalContainerStyles) {
        containerStyles.push(additionalContainerStyles);
      }

      return containerStyles;
    }, [additionalContainerStyles, existingFiles.length]);

    const beforeUpload = useCallback(
      async (file: IRcFile): Promise<void | Blob | File> => {
        const isSession = await isSessionValid();
        const isFileValidResult = isFileValid(attachmentType, file);
        if (!isFileValidResult.isValid && !isSession) {
          ErrorToast(
            "invalid-file-error",
            t("Error"),
            isFileValidResult.errorMessage
          );

          return Promise.reject();
        }

        let presignedURl: IGetPresignedURLResponseData;

        if (user) {
          presignedURl = await getPresignedUploadUrl({
            name: file.name,
            contentType: file.type,
            attachmentType,
            projectId,
            organisationId,
            accessToken,
            fullName: user.name,
            documentCategory,
            trackId,
          });
          const updatedFile = file;

          updatedFile.url = presignedURl.url;
          updatedFile.request = new XMLHttpRequest();
          updatedFile.uid = presignedURl.id;
          if (user) {
            updatedFile.uploaded = {
              userFullname: user.name,
              userId: user.id,
            };
          }
          if (attachmentType === AttachmentType.Track) {
            updatedFile.trackId = trackId;
          }

          return Promise.resolve(updatedFile);
        }
        return Promise.reject();
      },
      [
        accessToken,
        attachmentType,
        documentCategory,
        isFileValid,
        isSessionValid,
        organisationId,
        projectId,
        t,
        trackId,
        user,
      ]
    );

    const checkAllFilesValidOrComplete = useCallback(
      (fileList: UploadFile[]): boolean => {
        for (let index = 0; index < fileList.length; index += 1) {
          if (
            fileList[index].percent !== 100 ||
            fileList[index].status === IFileUploadStatus.error
          ) {
            return false;
          }
        }

        return true;
      },
      []
    );

    const refreshAttachmentList = useCallback(async () => {
      if (checkAllFilesValidOrComplete(existingFiles)) {
        const response = await getProject(
          accessToken,
          projectId,
          organisationId
        );
        if (response) {
          storeAttachments({
            attachments: response?.attachments ?? [],
            projectVersion: response.version,
          });
        }
      }
    }, [
      accessToken,
      checkAllFilesValidOrComplete,
      existingFiles,
      organisationId,
      projectId,
      storeAttachments,
    ]);

    const handleChange = useCallback(
      async ({
        file,
        fileList,
      }: {
        file: IProjectUploadFile;
        fileList: IProjectUploadFile[];
      }) => {
        const foundFileIndex = fileList.findIndex((fileToFind: UploadFile) => {
          return fileToFind.uid === file.uid;
        });

        const newFileList: IProjectUploadFile[] = [];
        Object.assign(newFileList, fileList);
        const validFile = isFileValid(attachmentType, file);

        if (file.response) {
          newFileList[foundFileIndex].uid = file.response.uid;

          newFileList[foundFileIndex].uploaded = {
            userFullname: file.response.uploaded.userFullname,
            userId: file.response.uploaded.userId,
          };
          newFileList[foundFileIndex].public = false;
        }

        if (file.status && file.status === IFileUploadStatus.error) {
          newFileList[foundFileIndex].error = `${t(
            "File could not be uploaded, please try again"
          )}`;
        }

        if (!validFile.isValid) {
          newFileList[foundFileIndex].error = validFile.errorMessage;
          newFileList[foundFileIndex].status = IFileUploadStatus.error;
        }

        if (
          file.percent !== 100 &&
          validFile.isValid &&
          file.status !== IFileUploadStatus.error
        ) {
          newFileList[foundFileIndex].status = IFileUploadStatus.uploading;
          if (user) {
            newFileList[foundFileIndex].uploaded = {
              userFullname: user.name,
              userId: user.id,
            };
          }
        }

        if (
          file.percent === 100 &&
          !checkAllFilesValidOrComplete(fileList) &&
          file.status !== IFileUploadStatus.error
        ) {
          newFileList[foundFileIndex].status = IFileUploadStatus.success;
        }

        // While uploading multiple files some of them will be valid and some will not be valid,
        // by setting it to success the valid files would show in the list at the same time as the invalid files but that would mean the previously successful files
        // would also show, this will make sure all previous files will no longer show when all files at that point are valid and uploaded as files with a done status do not show in progress
        if (checkAllFilesValidOrComplete(fileList)) {
          for (let index = 0; index < fileList.length; index += 1) {
            if (
              fileList[index].percent === 100 &&
              fileList[index].status === IFileUploadStatus.success
            ) {
              newFileList[index].status = IFileUploadStatus.done;
            }
          }
          await refreshAttachmentList();
        }

        setFileList(newFileList);
      },
      [
        attachmentType,
        checkAllFilesValidOrComplete,
        isFileValid,
        refreshAttachmentList,
        setFileList,
        t,
        user,
      ]
    );

    const handleUpload = useCallback(
      ({ onSuccess, onError, file, onProgress }: ICustomRequest) => {
        const xhr = file.request;
        if (xhr) {
          if (file.url) {
            xhr.open("PUT", file.url);

            xhr.upload.addEventListener(
              "progress",
              (e) => {
                onProgress({ percent: (e.loaded / e.total) * 100 });
              },
              false
            );

            xhr.onreadystatechange = async () => {
              if (xhr.readyState === 4) {
                let uploadSuccess = false;

                if (xhr.status === 200) {
                  let uploadVerified = false;
                  const XMLResponse = xhr?.responseXML;
                  if (XMLResponse?.body) {
                    if (XMLResponse.body.toString().includes("<Error>")) {
                      log(
                        DataDogLogTypes.ERROR,
                        "File has failed to upload to S3 storage",
                        XMLResponse.body.toString()
                      );
                    } else if (!/<[\w_]/.test(XMLResponse.body.toString())) {
                      log(
                        DataDogLogTypes.ERROR,
                        "S3 storage request was aborted by client",
                        XMLResponse.body.toString()
                      );
                    }
                    ErrorToast(
                      "Error",
                      t("An error has occurred while uploading the file")
                    );
                  } else {
                    uploadVerified = await handleVerifyFileUpload(
                      t,
                      accessToken,
                      projectId,
                      organisationId,
                      file,
                      true
                    );
                  }

                  if (uploadVerified) {
                    uploadSuccess = true;
                  }
                }

                if (uploadSuccess) {
                  onSuccess(file, file);
                } else {
                  onError(xhr.responseText, xhr.response, file);

                  // 0 is aborted/cancelled
                  if (xhr.status !== 0) {
                    // Remove the file from the project as if the upload fails it will still be attached to the project
                    await handleDeleteFile(
                      setFileList,
                      existingFiles,
                      setShowModal,
                      t,
                      selectedFileToDelete?.index,
                      accessToken,
                      projectId,
                      organisationId,
                      file,
                      false
                    );
                  }
                }
              }
            };
            xhr.send(file);
          }
        }
      },
      [
        accessToken,
        existingFiles,
        organisationId,
        projectId,
        selectedFileToDelete?.index,
        setFileList,
        setShowModal,
        t,
      ]
    );

    useEffect(() => {
      void (() => {
        setUploadingComplete(checkAllFilesValidOrComplete(existingFiles));
      })();
    }, [checkAllFilesValidOrComplete, existingFiles]);

    useEffect(() => {
      void (() => {
        const userAccessToken: string = getAccessToken();
        setAccessToken(userAccessToken);
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const confirmDeleteContentProps: IConfirmContentProps = useMemo(
      () => ({
        confirmAction: async () => {
          if (selectedFileToDelete) {
            await handleDeleteFile(
              setFileList,
              existingFiles,
              setShowModal,
              t,
              selectedFileToDelete?.index,
              accessToken,
              projectId,
              organisationId,
              selectedFileToDelete?.file
            );
            await refreshAttachmentList();
          }
        },
        onClose: () => setShowModal(false),
        primaryButtonLabel: "Delete File",
        secondaryButtonLabel: "Cancel",
        mainContent: (
          <p>
            {`${t("You're about to delete the file")}`}
            <strong>{`${
              selectedFileToDelete ? selectedFileToDelete?.file.name : ""
            }`}</strong>
          </p>
        ),
      }),
      [
        accessToken,
        existingFiles,
        organisationId,
        projectId,
        refreshAttachmentList,
        selectedFileToDelete,
        setFileList,
        setShowModal,
        t,
      ]
    );

    return (
      <div css={container} id={fileUploaderId}>
        {isTablet || isMobile ? (
          <MobileDrawer
            data-testid="mobile-drawer"
            placement="bottom"
            open={showModal}
            key="bottom"
            closable={false}
            destroyOnClose
          >
            <ConfirmationDrawer {...confirmDeleteContentProps} />
          </MobileDrawer>
        ) : (
          <BaseModal
            open={showModal}
            content={<Content {...confirmDeleteContentProps} />}
            buttons={[]}
            onClose={() => setShowModal(false)}
          />
        )}
        <Dragger
          fileList={existingFiles}
          multiple
          css={styles.fileUpload}
          beforeUpload={beforeUpload}
          itemRender={() => null}
          className="fileUploader"
          headers={{
            "x-amz-acl": "public-read",
            "Content-Type": "audio/mpeg",
          }}
          method="PUT"
          customRequest={(options: unknown) => {
            const customRequestOptions: ICustomRequest = options as ICustomRequest;
            void handleUpload(customRequestOptions);
          }}
          onChange={handleChange}
          data-testid="fileUploader"
        >
          {(existingFiles.length && uploadingComplete) > 0 && (
            <FileUploadTable
              tableData={tableData}
              tableColumns={tableColumns}
              showDragAndDrop={showDragAndDrop}
              dragAndDropButton={dragAndDropButton}
              attachmentType={attachmentType}
            />
          )}

          {(existingFiles.length && !uploadingComplete) > 0 && (
            <FileUploadProgress
              setFileList={setFileList}
              filesList={existingFiles}
              setShowModal={setShowModal}
              uploaderId={fileUploaderId}
              showDragAndDrop={showDragAndDrop}
              dragAndDropButton={dragAndDropButton}
              accessToken={accessToken}
              projectId={projectId}
              organisationId={organisationId}
            />
          )}

          {existingFiles.length === 0 && (
            <div>
              <div className={antUploadDragIconStyleClass}>
                <UploadIcon height="38" width="38" title="upload-icon" />
              </div>
              {showDragAndDrop ? (
                <>
                  <div className="ant-upload-text">{dragAndDropButton}</div>
                  <div>{sizeLimitText}</div>
                </>
              ) : null}
            </div>
          )}
        </Dragger>
      </div>
    );
  }
);

export default FileUploader;
