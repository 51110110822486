import { Button } from "antd";
import React from "react";
import styles from "../../styles";

interface IProps {
  cancelButtonText: string;
  backButtonText?: string;
  okButtonText: string;
  isPreviousButtonVisible: boolean;
  isOKButtonVisible: boolean;
  onCancelButtonClick: () => void;
  onBackButtonClick?: () => void;
  onOkButtonClick: () => void;
}

const ContactFooter = ({
  cancelButtonText,
  backButtonText,
  okButtonText,
  isPreviousButtonVisible,
  isOKButtonVisible,
  onCancelButtonClick,
  onBackButtonClick,
  onOkButtonClick,
}: IProps): React.ReactElement => {
  return (
    <div css={styles.footerContainer}>
      <Button
        css={styles.cancelButtonStyles}
        tabIndex={0}
        aria-label="cancel"
        onClick={onCancelButtonClick}
        type="text"
      >
        {cancelButtonText}
      </Button>
      <div>
        {isPreviousButtonVisible && (
          <Button
            css={styles.backButtonStyles}
            tabIndex={0}
            aria-label="back"
            onClick={onBackButtonClick}
          >
            {backButtonText}
          </Button>
        )}
        {isOKButtonVisible && (
          <Button
            css={styles.okButtonStyles}
            tabIndex={0}
            aria-label="ok"
            onClick={onOkButtonClick}
            type="primary"
            data-testid="ok-button"
          >
            {okButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ContactFooter;
