export const GetNavigationLinksStrokeClasses = (isActive: boolean): string => {
  return isActive ? "iconStroke iconStrokeSelected" : "iconStroke";
};

export const GetNavigationLinksFillClasses = (isActive: boolean): string => {
  return isActive ? "iconFill iconFillSelected" : "iconFill";
};

export default {
  GetNavigationLinksStrokeClasses,
  GetNavigationLinksFillClasses,
};
