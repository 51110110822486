/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState, useRef } from "react";
import { Col, Row, Form, Input, Button } from "antd";
import useAuth from "src/auth/use-auth";
import {
  GraphicLeft,
  GraphicRight,
} from "src/app/assets/icons/component-icons";
import { useTranslation } from "react-i18next";
import PasswordInput from "src/pages/user-profile/components/user-profile-form/components/password-input";
import { ICognitoError } from "src/auth/interfaces";
import Logo from "./assets/images/login-logo.svg";
import {
  PasswordResetMsg,
  ErrorMsg,
  MigrationMsg,
} from "./components/messages";
import styles from "./styles";

const isRequiredReset = (error?: ICognitoError | null) => {
  if (!error) return false;
  return error?.error_description
    .toString()
    ?.includes("PasswordResetRequiredException");
};

const Login = (): ReactElement => {
  const {
    handleLogin,
    resetPassword,
    confirmResetVerificationCode,
    error,
    isLoading,
    isPasswordReset,
  } = useAuth();
  const [showResetFrom, setShowResetFrom] = useState<boolean>(false);
  const [showResetCodeFrom, setShowResetCodeFrom] = useState<boolean>(false);
  const emailToReset = useRef<string>("");
  const { t } = useTranslation();
  const requiredReset = isRequiredReset(error);

  const LoginForm = (): ReactElement => {
    const [form] = Form.useForm();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const onSignIn = ({ email, password }: any) => {
      handleLogin(email, password);
    };

    return (
      <Form form={form} onFinish={onSignIn} css={styles.loginContainer}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={24} css={styles.row}>
            <img css={styles.logo} src={Logo} alt="Songtradr Logo" />
          </Col>
        </Row>
        <Row css={styles.row}>
          <Col span={24}>
            <h2 css={styles.title}>{t("Sign in")}</h2>
            {error ? <ErrorMsg error={error} /> : null}
            {isPasswordReset ? <PasswordResetMsg /> : null}
            <div css={styles.inputContainer}>
              <div css={styles.label}>{t("Email")}</div>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter a valid email!",
                  },
                ]}
              >
                <Input css={styles.input} placeholder="Email address" />
              </Form.Item>
            </div>
            <div css={styles.inputContainer}>
              <div css={styles.label}>{t("Password")}</div>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter a valid password!" },
                ]}
              >
                <Input
                  css={styles.input}
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                />
              </Form.Item>
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => setShowPassword(!showPassword)}
              onClick={() => setShowPassword(!showPassword)}
              css={styles.subText}
            >
              {!showPassword ? t("Show password") : t("Hide password")}
            </div>
          </Col>
        </Row>
        <Row css={styles.row}>
          <Col span={24}>
            <Button
              id="sign-in"
              loading={isLoading}
              css={styles.button}
              htmlType="submit"
            >
              {t("Sign in")}
            </Button>

            <div
              id="forgot-password"
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                setShowResetFrom(true);
              }}
              onClick={() => {
                setShowResetFrom(true);
              }}
              css={styles.forgotPassword}
            >
              {t("Forgot password?")}
            </div>
          </Col>
        </Row>
      </Form>
    );
  };

  const ResetCodeForm = (): ReactElement => {
    const [form] = Form.useForm();
    const [password, setPassword] = useState("");
    const [isValidPassword, setIsValidPassword] = useState(false);

    const onSetNewPassword = ({ code }: any) => {
      confirmResetVerificationCode(code, password, emailToReset.current);
      setShowResetFrom(false);
      setShowResetCodeFrom(false);
    };

    return (
      <Form form={form} onFinish={onSetNewPassword} css={styles.loginContainer}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={24} css={styles.row}>
            <img css={styles.logo} src={Logo} alt="Songtradr Logo" />
          </Col>
        </Row>
        <Row css={styles.row}>
          <Col span={24}>
            <div css={styles.inputContainer}>
              <h2 css={styles.title}>{t("We sent the code to your email!")}</h2>
              <p>{t("Enter the verification code we sent to you.")}</p>
              <div css={styles.label}>Code</div>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please input your verification code!",
                  },
                ]}
              >
                <Input name="verification-code" css={styles.input} />
              </Form.Item>
            </div>
            <div css={styles.inputContainer}>
              <div css={styles.label}>Password</div>
              <Form.Item name="password">
                <PasswordInput
                  css={styles.input}
                  setPassword={setPassword}
                  isValidPassword={isValidPassword}
                  setIsValidPassword={setIsValidPassword}
                  isError={!!error && !isValidPassword && !requiredReset}
                  name="password"
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row css={styles.row}>
          <Col span={24}>
            <Button htmlType="submit" css={styles.button}>
              {t("Set new password")}
            </Button>

            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => setShowResetCodeFrom(false)}
              onClick={() => setShowResetCodeFrom(false)}
              css={styles.backToSignin}
            >
              {t("Back to send email")}
            </div>
          </Col>
        </Row>
      </Form>
    );
  };

  const ResetPasswordForm = (): ReactElement => {
    const [form] = Form.useForm();

    const onSendResetEmail = ({ email }: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      emailToReset.current = email;
      resetPassword(emailToReset.current);
      setShowResetCodeFrom(true);
    };

    return (
      <Form form={form} onFinish={onSendResetEmail} css={styles.loginContainer}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={24} css={styles.row}>
            <img css={styles.logo} src={Logo} alt="Songtradr Logo" />
          </Col>
        </Row>
        <Row css={styles.row}>
          <Col span={24}>
            {requiredReset ? (
              <MigrationMsg />
            ) : (
              <h2 css={styles.title}>{t("Forgot Password?")}</h2>
            )}
            <p>{t("Enter the email")}</p>
            <div css={styles.inputContainer}>
              <div css={styles.label}>{t("Email")}</div>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter a valid email!",
                  },
                ]}
              >
                <Input css={styles.input} placeholder="Email address" />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row css={styles.row}>
          <Col span={24}>
            <Button htmlType="submit" css={styles.resetButton}>
              {t("Send reset link")}
            </Button>

            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => setShowResetFrom(false)}
              onClick={() => setShowResetFrom(false)}
              css={styles.backToSignin}
            >
              {t("Back to sign in")}
            </div>
          </Col>
        </Row>
      </Form>
    );
  };

  const ResetPassword = (): ReactElement => {
    return !showResetCodeFrom ? <ResetPasswordForm /> : <ResetCodeForm />;
  };

  if (isRequiredReset(error)) {
    return <ResetPassword />;
  }

  return (
    <div css={styles.container}>
      <div css={styles.graphicLeft}>
        <GraphicLeft />
      </div>
      <div css={styles.mainContainer}>
        {!showResetFrom ? <LoginForm /> : <ResetPassword />}
      </div>
      <div css={styles.graphicRight}>
        <GraphicRight />
      </div>
    </div>
  );
};

export default Login;
