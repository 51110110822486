import { Divider, Form, Input, Typography } from "antd";
import Select from "react-select";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import { getIsMobile } from "@songtradr/spa-common";
import NumericInputEvents from "src/components/currency-and-price/utils";
import contactValidation from "src/utils/contactValidation";
import styles, { customStyles } from "./styles";
import mainStyles from "../../styles";
import { IPhoneNumber } from "../../interfaces";
import getCountryPhoneCodeOptions from "../../utils";

interface IProps {
  countryCode: string;
  phoneNumber: string;
  countryCodeFormItemName: string;
  phoneNumberFormItemName: string;
  title: string;
  propertyName: string;
  onChange: (field: string, value: IPhoneNumber) => void;
}

const PhoneNumberWithCountryCode = ({
  countryCode,
  phoneNumber,
  countryCodeFormItemName,
  phoneNumberFormItemName,
  title,
  propertyName,
  onChange,
}: IProps): ReactElement => {
  const isMobile = getIsMobile();
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCode);

  const handleChangeValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(propertyName, {
        number: event.target.value.trim(),
        countryCode: selectedCountryCode,
      });
    },
    [onChange, propertyName, selectedCountryCode]
  );

  const handleCountryCodeChange = useCallback(
    (countryCodeChange: IOption) => {
      if (countryCodeChange && countryCodeChange.value !== countryCode) {
        setSelectedCountryCode(countryCodeChange.value);
        if (String(phoneNumber).trim() !== "") {
          onChange(propertyName, {
            countryCode: countryCodeChange.value,
            number: phoneNumber,
          });
        }
      }
    },
    [onChange, phoneNumber, propertyName, countryCode]
  );

  const countryCodeOptions = useMemo(() => getCountryPhoneCodeOptions(), []);

  return (
    <div>
      <div css={mainStyles.basicInputContainer}>
        <Typography.Text css={mainStyles.basicInputLabel}>
          {title}
        </Typography.Text>
      </div>
      <div id={title} css={styles.phoneContainer}>
        <div css={mainStyles.phoneContainer}>
          <Form.Item
            name={countryCodeFormItemName}
            css={mainStyles.formItem}
            rules={[
              () => ({
                validator(_rule, value?: string) {
                  if (!value) {
                    return Promise.reject(new Error("Required"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              menuPlacement="auto"
              styles={customStyles}
              data-testid={countryCodeFormItemName}
              onChange={handleCountryCodeChange}
              options={countryCodeOptions}
              id={countryCodeFormItemName}
            />
          </Form.Item>
        </div>
        <div css={mainStyles.verticalDividerContainer}>
          <Divider type="vertical" css={mainStyles.verticalDivider} />
        </div>
        <div
          css={[!isMobile && mainStyles.sizeOfHalfDiv, mainStyles.priceInput]}
        >
          <div css={mainStyles.basicInput}>
            <Form.Item
              css={mainStyles.formItem}
              rules={contactValidation.requiredPhoneFieldValidator()}
              name={phoneNumberFormItemName}
            >
              <Input
                autoComplete="off"
                css={styles.valueInput}
                aria-label={title}
                data-testid={phoneNumberFormItemName}
                onChange={handleChangeValue}
                onKeyDown={NumericInputEvents.onKeyDownNumberInput}
                onPaste={NumericInputEvents.onPastePhoneNumberInput}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberWithCountryCode;
