import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { ErrorToast } from "src/components/toast-notification";
import Dashboard from "..";
import useAuth from "../../../auth/use-auth";
import getOrgMember from "../../../api/organisation-members/get-org-member";

const DashboardContainer = (): ReactElement | null => {
  const {
    user,
    userPermissions,
    getAccessToken,
    organisationId,
    isSessionValid,
  } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState<string | undefined>(user?.name);
  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchMember = async () => {
      try {
        setIsLoading(true);
        const isCurrentSessionValid = await isSessionValid();
        const accessToken = getAccessToken();

        if (
          !!user?.id &&
          !!organisationId &&
          !!accessToken &&
          isCurrentSessionValid
        ) {
          const response = await getOrgMember(
            accessToken,
            organisationId,
            user.id
          );
          setFirstName(response.firstName);
        }
      } catch (e) {
        ErrorToast(
          "fetch-member-error",
          "There was a problem with user credentials."
        );
      } finally {
        setIsLoading(false);
      }
    };
    void fetchMember();
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [getAccessToken, isSessionValid, organisationId, user?.id, user]);

  return (
    <Dashboard
      firstName={firstName}
      isLoading={isLoading}
      userPermissions={userPermissions}
    />
  );
};

export default DashboardContainer;
