import axios, { ResponseType } from "axios";
import { buildVinylProjectManagementBaseUrl } from "../base-url-helper";
import { getAuthHeader, getJsonContentHeader } from "../headers";

export const getMusicUsageDeclarationReportDownloadUrl = (
  projectId: string,
  trackId: string,
  organisationId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/library-tracks/${trackId}/report?orgId=${organisationId}`;
};

const getMusicUsageDeclarationReport = async (
  projectId: string,
  trackId: string,
  accessToken: string,
  organisationId: string
): Promise<Blob> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    responseType: "blob" as ResponseType,
  };

  return axios
    .get(
      getMusicUsageDeclarationReportDownloadUrl(
        projectId,
        trackId,
        organisationId
      ),
      options
    )
    .then((response) => response.data as Blob);
};
export default getMusicUsageDeclarationReport;
