import React, { ReactElement } from "react";
import { Typography } from "antd";
import { useWindowSize } from "@songtradr/spa-common";
import { useTranslation } from "react-i18next/";
import STLoadingLogo from "src/components/st-loading-logo";
import { IUserPermissions } from "src/auth/access-token-utils";
import styles from "./styles";
import Card from "./components/card";

interface IProps {
  firstName: string | undefined;
  isLoading: boolean;
  userPermissions: IUserPermissions;
}

const Dashboard = ({
  firstName,
  isLoading,
  userPermissions,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  useWindowSize();

  return (
    <>
      {isLoading ? (
        <STLoadingLogo pageCentered />
      ) : (
        <>
          <div css={styles.heading}>
            <Typography.Title>
              {t("DashboardPage##Hi")} {firstName}
            </Typography.Title>
            <Typography.Paragraph css={styles.subHeading}>
              {t("DashboardPage##What would you like to do today?")}
            </Typography.Paragraph>
          </div>
          <div css={styles.cardContainer}>
            {userPermissions.canAccessProjects && (
              <Card
                title="Projects"
                description="DashboardPage##Control and share your projects, start your smart music management."
                buttonLink="/projects"
                buttonText="DashboardPage##Manage projects"
              />
            )}
            {userPermissions.canAccessTeams && (
              <Card
                title="Team"
                description="DashboardPage##Build your team and start collaborating across multiple projects."
                buttonLink="/team"
                buttonText="DashboardPage##Manage my team"
              />
            )}
            {userPermissions.canAccessProjects && (
              <Card
                title="TalentHub"
                description="DashboardPage##Add and share contacts, start your contact management."
                buttonLink="/talents"
                buttonText="DashboardPage##Manage contacts"
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
