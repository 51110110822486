import { ReportType } from "src/components/create-report-button";
import { buildVinylProjectManagementBaseUrl } from "./base-url-helper";

export const getProjectUrl = (
  projectId: string,
  organisationId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}?orgId=${organisationId}`;
};

export const getDuplicateProjectUrl = (
  projectId: string,
  organisationId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/_clone?orgId=${organisationId}`;
};

export const getProjectTypesUrl = (organisationId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/types?orgId=${organisationId}`;
};

export const getPublishingTeamEmailsUrl = (
  projectId: string,
  organisationId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/notify-publishing-team/preview?orgId=${organisationId}`;
};

export const getSendNotificationToPublishingTeamUrl = (
  projectId: string,
  organisationId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/notify-publishing-team/?orgId=${organisationId}`;
};

export const createProjectUrl = (): string => {
  return buildVinylProjectManagementBaseUrl();
};
export const attachmentDownloadUrl = (
  projectId: string,
  attachmentId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/attachments/${attachmentId}/download`;
};

export const getProjectsReportDownloadUrl = (
  projectId: string,
  organisationId: string,
  reportType: string
): string => {
  let apiUrl;
  if (reportType === ReportType.LicenseRequest) {
    apiUrl = `${reportType}?orgId=${organisationId}&showMasterPublishingSplits=true`;
  } else {
    apiUrl = `${reportType}?orgId=${organisationId}`;
  }
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/reports/${apiUrl}`;
};

export const attachmentVerifyUploadUrl = (
  projectId: string,
  attachmentId: string,
  organisationId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/attachments/${attachmentId}/verify?orgId=${organisationId}`;
};

export const attachmentUploadUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/attachments`;
};

export const searchProjectsUrl = (): string => {
  return `${buildVinylProjectManagementBaseUrl()}/_search`;
};

export const exportProjectsUrl = (): string => {
  return `${buildVinylProjectManagementBaseUrl()}/_search/export`;
};

export const getProjectFiltersUrl = (organisationId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/_filter-options?orgId=${organisationId}`;
};

export const shareProjectsUrl = (
  projectId: string,
  organisationId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/share-code?orgId=${organisationId}`;
};

export const updateProjectUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}`;
};

export const createTermUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/terms`;
};

export const updateTermUrl = (projectId: string, termId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/terms/${termId}`;
};

export const deleteTermUrl = (
  projectId: string,
  organisationId: string,
  termId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/terms/${termId}?orgId=${organisationId}`;
};

export const createTrackUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/tracks`;
};

export const updateTrackUrl = (projectId: string, trackId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/tracks/${trackId}`;
};

export const deleteTrackUrl = (
  projectId: string,
  orgId: string,
  trackId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/tracks/${trackId}?orgId=${orgId}`;
};

export const getCreativeAgenciesUrl = (): string => {
  return `${buildVinylProjectManagementBaseUrl()}/creative-agencies`;
};

export const createCreativeAgenciesUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/creative-agencies`;
};

export const updateCreativeAgenciesUrl = (
  projectId: string,
  creativeAgencyId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/creative-agencies/${creativeAgencyId}`;
};

export const createServiceUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/services`;
};

export const updateServiceUrl = (
  projectId: string,
  projectServiceId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/services/${projectServiceId}`;
};

export const deleteServiceUrl = (
  projectId: string,
  projectServiceId: string,
  organisationId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/services/${projectServiceId}?orgId=${organisationId}`;
};
