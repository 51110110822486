import { Typography } from "antd";
import { format, parseISO } from "date-fns";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon, EditIcon } from "src/app/assets/icons/component-icons";
import STLoadingLogo from "src/components/st-loading-logo";
import StatusPill from "src/components/status-pill";
import drawerStyles from "./styles";
import style from "../../styles";
import { IProjectProps } from "../../interfaces";
import { formatProjectLeads } from "../../utils";

interface IProps {
  activeProject?: IProjectProps;
  handleMobileEditProjectClick: (id: string) => void;
  handleMobileDeleteProjectClick: () => void;
}

const ProjectInfoDrawer = ({
  activeProject,
  handleMobileEditProjectClick,
  handleMobileDeleteProjectClick,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  if (activeProject) {
    return (
      <div className="projects-info-drawer" data-testid="projects-info-drawer">
        <div
          css={[drawerStyles.drawerContentContainer, style.projectInfoDrawer]}
        >
          <div css={drawerStyles.drawerInfoContainer}>
            <Typography.Title css={drawerStyles.drawerHeader} level={5}>
              {t("ProjectsPage##Project name")}
            </Typography.Title>
            <Typography.Text>{activeProject?.name}</Typography.Text>
          </div>
          <div css={drawerStyles.drawerInfoContainer}>
            <Typography.Title css={drawerStyles.drawerHeader} level={5}>
              {t("ProjectsPage##Project type")}
            </Typography.Title>
            <Typography.Text>{activeProject?.projectType}</Typography.Text>
          </div>
          <div css={drawerStyles.drawerInfoContainer}>
            <Typography.Title css={drawerStyles.drawerHeader} level={5}>
              {t("ProjectsPage##Project ID label")}
            </Typography.Title>
            <Typography.Text>{activeProject?.sequenceNo}</Typography.Text>
          </div>
          <div css={drawerStyles.drawerInfoContainer}>
            <Typography.Title css={drawerStyles.drawerHeader} level={5}>
              {t("ProjectsPage##Project lead")}
            </Typography.Title>
            <Typography.Text>
              {activeProject?.leads && formatProjectLeads(activeProject.leads)}
            </Typography.Text>
          </div>
          <div css={drawerStyles.drawerInfoContainer}>
            <Typography.Title css={drawerStyles.drawerHeader} level={5}>
              {t("ProjectsPage##Project status")}
            </Typography.Title>
            <StatusPill status={activeProject.status} />
          </div>
          <div css={drawerStyles.drawerInfoContainer}>
            <Typography.Title css={drawerStyles.drawerHeader} level={5}>
              {t("ProjectsPage##Contract start")}
            </Typography.Title>
            <Typography.Text>
              {activeProject.contractStartDate &&
                format(
                  parseISO(activeProject.contractStartDate),
                  "MMM do, yyyy"
                )}
            </Typography.Text>
          </div>
          <div css={drawerStyles.drawerInfoContainer}>
            <Typography.Title css={drawerStyles.drawerHeader} level={5}>
              {t("ProjectsPage##Contract end")}
            </Typography.Title>
            <Typography.Text>
              {activeProject.contractEndDate &&
                format(parseISO(activeProject.contractEndDate), "MMM do, yyyy")}
            </Typography.Text>
          </div>
          <div css={drawerStyles.drawerInfoContainer}>
            <Typography.Title css={drawerStyles.drawerHeader} level={5}>
              {t("ProjectsPage##Last updated")}
            </Typography.Title>
            <Typography.Text>
              {activeProject.lastUpdated &&
                format(parseISO(activeProject.lastUpdated), "MMM do, yyyy")}
            </Typography.Text>
            <Typography.Text className="secondary">
              {activeProject.lastUpdated &&
                format(parseISO(activeProject.lastUpdated), "HH:mmaaa")}
            </Typography.Text>
          </div>
        </div>
        <div aria-label={t("Edit")} css={drawerStyles.drawerButtonContainer}>
          <button
            type="button"
            css={drawerStyles.mobileButtonContainer}
            onClick={() => handleMobileEditProjectClick(activeProject?.id)}
            data-testid="edit-project-button"
          >
            <div css={drawerStyles.iconContainer}>
              <EditIcon /> {t("Edit")}
            </div>
          </button>
          <button
            aria-label={t("Delete")}
            type="button"
            css={drawerStyles.mobileButtonContainer}
            onClick={handleMobileDeleteProjectClick}
            data-testid="delete-project-button"
          >
            <div css={drawerStyles.iconContainer}>
              <DeleteIcon /> {t("Delete")}
            </div>
          </button>
        </div>
      </div>
    );
  }

  return <STLoadingLogo />;
};

export default ProjectInfoDrawer;
