import { Form, Typography } from "antd";
import { memo, ReactElement, useCallback, useEffect, useMemo } from "react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common/lib/utils";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { startCase } from "lodash";
import projectValidation from "src/utils/projectValidation";
import { customStyles } from "../details/styles";
import { IOption } from "./types";
import styles from "../../styles";
import { IProjectDetailsData } from "../../interfaces";

interface IProps {
  projectType?: IOption;
  allProjectTypes: IOption[];
  projectFormat?: string;
  saveClicked: boolean;
  enforceValidation: boolean;
  isEnterpriseLibraryProject: boolean;
  onProjectTypeChange: (projectType: IOption) => void;
  onUpdateProjectDetailsData: (projectDetailsData: IProjectDetailsData) => void;
}

enum ProjectFormat {
  Advertising = "Advertising",
  TalentAndEvents = "Talent and Events",
  Internal = "Internal",
  BroadcastPromo = "Broadcast Promo",
  Trailer = "Trailer",
  TV = "TV",
  Film = "Film",
  Podcast = "Podcast",
}

const ProjectTypeAndFormat = memo(
  ({
    projectType,
    allProjectTypes,
    projectFormat,
    saveClicked,
    enforceValidation,
    isEnterpriseLibraryProject,
    onProjectTypeChange,
    onUpdateProjectDetailsData,
  }: IProps): ReactElement => {
    useWindowSize();
    const isMobile = getIsMobile();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const showFormatField = useMemo(
      () => projectType?.label && !isEnterpriseLibraryProject,
      [isEnterpriseLibraryProject, projectType?.label]
    );

    const projectFormatOptions = useMemo(() => {
      return Object.entries(ProjectFormat).map(([key, value]) => ({
        label: value,
        value: key,
      }));
    }, []);

    const handleFormatChange = useCallback(
      (data: IOption) => {
        const format = data?.value ?? "";
        onUpdateProjectDetailsData({ format });
      },
      [onUpdateProjectDetailsData]
    );

    useEffect(() => {
      if (saveClicked || enforceValidation) {
        void form.validateFields(["format"]);
      }
    }, [form, saveClicked, enforceValidation, showFormatField]);

    return (
      <Form
        form={form}
        initialValues={{
          format: {
            label: startCase(projectFormat),
            value: projectFormat,
          },
        }}
        validateTrigger="onChange"
        scrollToFirstError
      >
        <div id="Project Type" css={styles.sectionContainer}>
          <div>
            <div css={styles.titleLabelContainer}>
              <Typography.Text css={styles.titleLabel}>
                {t("ProjectsPage##Overview")}
              </Typography.Text>
            </div>
            <div
              css={
                isMobile
                  ? styles.mobileSectionContainer
                  : styles.clientContainer
              }
            >
              <div>
                <div css={styles.basicInputContainer}>
                  <Typography.Text css={styles.basicInputLabel}>
                    {t("ProjectsPage##Type")}
                  </Typography.Text>
                </div>
                <div
                  css={[
                    !showFormatField && !isMobile && styles.sizeOfHalfDiv,
                    styles.sectionBottom,
                  ]}
                >
                  <Select
                    value={projectType}
                    placeholder=""
                    isSearchable
                    name="projectType"
                    options={allProjectTypes}
                    onChange={(data) => onProjectTypeChange(data as IOption)}
                    styles={customStyles}
                    inputId="project-type-input-id"
                    id="project-type-dropdown"
                  />
                </div>
              </div>
              {showFormatField && (
                <div>
                  <div css={styles.basicInputContainer}>
                    <Typography.Text css={styles.basicInputLabel}>
                      {t("ProjectsPage##Format")}
                    </Typography.Text>
                  </div>
                  <div css={styles.sectionBottom}>
                    <Form.Item
                      css={styles.formItem}
                      name="format"
                      rules={projectValidation.requiredOptionFieldValidator()}
                    >
                      <Select
                        placeholder=""
                        name="format"
                        options={projectFormatOptions}
                        onChange={handleFormatChange}
                        styles={customStyles}
                        id="format-dropdown"
                      />
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    );
  }
);
export default ProjectTypeAndFormat;
