import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";

const SaveAndCloseIcon = ({
  height = theme.svgSize.large,
  width = theme.svgSize.large,
  fill = theme.colors.white,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${height} ${width}`}
      aria-labelledby="arrow-pointing-forward"
    >
      <title id="arrow-pointing-forward">
        {t("ProjectsPage##Save and close")}
      </title>

      <g fill="none" fillRule="evenodd">
        <circle cx="19" cy="19" r="11.25" stroke={fill} strokeWidth="1.5" />
        <path
          fill={fill}
          d="M13.3829299,18.9389206 C13.3950329,18.76477 13.467651,18.5940374 13.6007843,18.4609041 L13.6007843,18.4609041 L18.1969784,13.86471 C18.4898716,13.5718168 18.9647454,13.5718168 19.2576386,13.86471 C19.5505318,14.1576033 19.5505318,14.632477 19.2576386,14.9253702 L19.2576386,14.9253702 L16.0229299,18.1590401 L23.8670701,18.1597755 C24.2812837,18.1597755 24.6170701,18.495562 24.6170701,18.9097755 C24.6170701,19.3239891 24.2812837,19.6597755 23.8670701,19.6597755 L15.8419299,19.6590401 L19.2576386,23.0746298 C19.5505318,23.367523 19.5505318,23.8423967 19.2576386,24.13529 C18.9647454,24.4281832 18.4898716,24.4281832 18.1969784,24.13529 L13.6007843,19.5390959 C13.467651,19.4059626 13.3950329,19.23523 13.3829299,19.0610794 Z"
          transform="matrix(-1 0 0 1 38 0)"
        />
      </g>
    </svg>
  );
};

export default SaveAndCloseIcon;
