import { Typography, Form, Input } from "antd";
import React, { ReactElement, useCallback, useMemo } from "react";
import ReactSelect, { ActionMeta } from "react-select";
import { useTranslation } from "react-i18next";
import { getIsMobile } from "@songtradr/spa-common";
import {
  IProjectDetailsData,
  OfficeLocation,
  Contributor,
} from "src/pages/projects/project/interfaces";
import { startCase } from "lodash";
import { LabeledValue } from "antd/lib/select";
import projectValidation from "src/utils/projectValidation";
import { IUserDropdownProps } from "src/pages/team/interfaces";
import { customStyles } from "../../styles";
import style from "../../../../styles";
import ProjectContributors from "../project-contributors";
import { IParseLeadOptions } from "../client-details-and-project-leads-row/ClientDetailsAndProjectLeadsRow";

interface IProps {
  projectDetailsData: IProjectDetailsData;
  projectRegionOptions: {
    value: string;
    label: string;
  }[];
  isRegionsRequiredField: boolean;
  parsedLeadOptions: IParseLeadOptions[];
  onLeadMultiSelectChange: (
    contributor: Contributor,
    action: ActionMeta<IUserDropdownProps>
  ) => void;
  onUpdateProjectDetailsData: (projectDetailsData: IProjectDetailsData) => void;
  onRegionChange: (data: LabeledValue) => void;
  onUpdate: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const ClientReferenceAndOfficeDetails = ({
  projectDetailsData,
  projectRegionOptions,
  isRegionsRequiredField,
  parsedLeadOptions,
  onLeadMultiSelectChange,
  onUpdateProjectDetailsData,
  onRegionChange,
  onUpdate,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const handleOfficeLocationChange = useCallback(
    (data: LabeledValue) => {
      const officeLocation = (data?.value ?? "") as string;
      onUpdateProjectDetailsData({ officeLocation });
    },
    [onUpdateProjectDetailsData]
  );

  const officeLocationOptions = useMemo(
    () =>
      Object.keys(OfficeLocation)
        .sort((a, b) => a.localeCompare(b))
        .map((key) => ({
          value: key,
          label: startCase(key),
        })),
    []
  );

  return (
    <>
      <div
        css={isMobile ? style.mobileSectionContainer : style.clientContainer}
      >
        <div>
          <div css={[style.basicInputContainer, style.basicInput]}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##Client reference No")}
            </Typography.Text>
          </div>
          <Form.Item css={style.formItem}>
            <Input
              name="clientReferenceNo"
              onChange={onUpdate}
              value={projectDetailsData.clientReferenceNo}
              data-testid="client-reference-no-input"
            />
          </Form.Item>
        </div>
        <div>
          <div css={[style.basicInput, style.basicInputContainer]}>
            <Typography.Text css={style.basicInputLabel}>
              {t("ProjectsPage##Project region")}
            </Typography.Text>
          </div>
          <Form.Item
            css={style.formItem}
            name="projectRegion"
            rules={projectValidation.requiredFieldValidator(
              isRegionsRequiredField
            )}
          >
            <ReactSelect
              placeholder=""
              isClearable
              isSearchable
              options={projectRegionOptions}
              onChange={onRegionChange}
              styles={customStyles}
              data-testid="project-region-dropdown"
              id="project-region-dropdown-options"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item css={style.formItem}>
            <div css={[style.basicInput, style.basicInputContainer]}>
              <Typography.Text css={style.basicInputLabel}>
                {t("ProjectsPage##OfficeCountry")}
              </Typography.Text>
            </div>
            <ReactSelect
              value={{
                label: startCase(projectDetailsData.officeLocation),
                value: startCase(projectDetailsData.officeLocation),
              }}
              isClearable={false}
              isSearchable
              options={officeLocationOptions}
              onChange={handleOfficeLocationChange}
              styles={customStyles}
              id="project-office-location-dropdown-options"
            />
          </Form.Item>
        </div>
      </div>
      <div
        css={[
          isMobile ? style.mobileSectionContainer : style.clientContainer,
          style.lastOfSectionBottom,
        ]}
      >
        <ProjectContributors
          onProjectCuratorsOrOriginatorsChange={onLeadMultiSelectChange}
          parsedLeadOptions={parsedLeadOptions}
        />
      </div>
    </>
  );
};

export default ClientReferenceAndOfficeDetails;
