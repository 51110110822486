import React, { Fragment } from "react";
import { TFunction } from "react-i18next";
import DeleteButton from "src/components/control-buttons/delete-button";
import { IOrganisationMemberInviteDataSource } from "src/components/table/interfaces";
import style from "../styles";
import { IMemberInvite } from "../interfaces";

export default (
  orgInvites: Array<IMemberInvite>,
  isTablet: boolean,
  isMobile: boolean,
  handleResendInviteClick: (inviteId: string) => void,
  handleDeleteInvite: (inviteId: string) => void,
  t: TFunction<string>
): Array<IOrganisationMemberInviteDataSource> => {
  if (isMobile) {
    return orgInvites?.map(({ email, id }: IMemberInvite) => {
      return {
        key: id,
        email: (
          <Fragment>
            <div css={style.teamInviteEmail}>{email}</div>
            <div css={style.teamInviteMobile}>
              <span css={style.teamInviteStatusMobile}>
                {t("INVITE PENDING")}
              </span>
              <span
                role="button"
                tabIndex={0}
                onClick={() => {
                  handleResendInviteClick(id);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleResendInviteClick(id);
                  }
                }}
                css={style.teamInviteResend}
              >
                {t("Resend")}
              </span>
              <span css={style.deleteButtonMobile}>
                <DeleteButton
                  showTooltip
                  onClick={() => handleDeleteInvite(id)}
                />
              </span>
            </div>
          </Fragment>
        ),
      };
    });
  }

  if (isTablet) {
    return orgInvites?.map(({ email, id }: IMemberInvite) => {
      return {
        key: id,
        email: (
          <Fragment>
            <div css={style.emailContainer}>{email}</div>
          </Fragment>
        ),
        status: (
          <span css={style.teamInviteStatus}>
            {t("manageTeam##invites##INVITE PENDING")}
          </span>
        ),
        resend: (
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              handleResendInviteClick(id);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleResendInviteClick(id);
              }
            }}
            css={style.teamInviteResend}
          >
            {t("manageTeam##invites##Resend")}
          </span>
        ),
        delete: (
          <DeleteButton showTooltip onClick={() => handleDeleteInvite(id)} />
        ),
      };
    });
  }

  return orgInvites?.map(({ email, id }: IMemberInvite) => {
    return {
      key: id,
      email: (
        <Fragment>
          <div css={style.teamInviteEmail}>{email}</div>
        </Fragment>
      ),
      status: <span css={style.teamInviteStatus}>{t("INVITE PENDING")}</span>,
      resend: (
        <span
          role="button"
          tabIndex={0}
          onClick={() => {
            handleResendInviteClick(id);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleResendInviteClick(id);
            }
          }}
          css={style.teamInviteResend}
        >
          {t("Resend")}
        </span>
      ),
      delete: (
        <DeleteButton showTooltip onClick={() => handleDeleteInvite(id)} />
      ),
    };
  });
};
