import axios from "axios";
import {
  getAuthHeader,
  getJsonContentHeader,
  getAxiosOptions,
} from "../../headers";
import { getContactsUrl } from "../../talent-url-helper";
import { IContactsRequest, IContactsResponse } from "../interfaces";

const getContacts = async (
  accessToken: string,
  request: IContactsRequest
): Promise<IContactsResponse | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  return axios.post(getContactsUrl(), request, options).then(({ data }) => {
    return data as IContactsResponse;
  });
};

export default getContacts;
