import { isEqual } from "lodash";
import {
  IProjectForm,
  IProjectHeaderData,
  IProjectProductAndAccountInformationData,
  IProjectTag,
  IFormContacts,
  IProjectMusicSection,
  IProjectDetailsData,
  IFormRelatedProject,
  IProjectClient,
  IProjectPublishingData,
  IFormContactsInvoices,
  IFormInvoicee,
  IProjectSubscriptionDetails,
} from "src/pages/projects/project/interfaces";

const areChangesMadeInProject = (
  project: IProjectForm,
  {
    projectType,
    projectDetailsData,
    projectHeaderData,
    projectProductAndAccountInformationData,
    projectTags,
    projectContacts,
    projectOtherAssets,
    servicesChanged,
    trackChanged,
    termsChanged,
    projectPublishingData,
    projectContactInvoices,
    projectInvoicee,
    projectSubscriptionDetails,
  }: {
    projectType?: string;
    projectDetailsData: IProjectDetailsData;
    projectHeaderData: IProjectHeaderData;
    projectProductAndAccountInformationData: IProjectProductAndAccountInformationData;
    projectTags: IProjectTag[];
    projectContacts: IFormContacts[];
    projectOtherAssets: IProjectMusicSection;
    servicesChanged: { [serviceId: string]: boolean };
    trackChanged: boolean;
    termsChanged: { [termId: string]: boolean };
    projectPublishingData: IProjectPublishingData;
    projectContactInvoices: IFormContactsInvoices[];
    projectInvoicee: IFormInvoicee;
    projectSubscriptionDetails: IProjectSubscriptionDetails;
  }
): boolean => {
  const formatComparingDate = (date?: string) => {
    return date?.replace("T", " ");
  };
  const mapRelatedUrls = (relatedUrls?: IFormRelatedProject[]) => {
    return (relatedUrls ?? []).map(({ link }) => link);
  };
  const isExtensionChanged =
    project.isExtension !== projectDetailsData.isExtension;
  const relatedUrlsChanged =
    !isExtensionChanged &&
    projectDetailsData.isExtension &&
    !isEqual(
      mapRelatedUrls(project.relatedProjects),
      mapRelatedUrls(projectDetailsData.relatedProjects)
    );
  const hasCreativeAgencyInvolvedChanged =
    (project.creativeAgencyInvolved ?? false) !==
    (projectDetailsData.creativeAgencyInvolved ?? false);
  const hasCreativeAgencyIdChanged =
    !hasCreativeAgencyInvolvedChanged &&
    (projectDetailsData.creativeAgencyId ?? false) &&
    project.creativeAgencyId !== projectDetailsData.creativeAgencyId;
  const hasCreativeAgencyOfficeChanged =
    !hasCreativeAgencyInvolvedChanged &&
    (projectDetailsData.creativeAgencyOffice ?? false) &&
    project.creativeAgencyOffice !== projectDetailsData.creativeAgencyOffice;
  const getClientIdAndName = (client?: IProjectClient) => {
    return {
      id: client?.id ?? "",
      name: client?.name ?? "",
    };
  };
  const mapContacts = (contacts: IFormContacts[]) => {
    return (contacts ?? []).map(
      ({ contactType = "", email = "", name = "" }) => ({
        contactType,
        email,
        name,
      })
    );
  };
  const mapOtherAssets = (otherAssets: IProjectMusicSection) => {
    return {
      finalVideo: (
        otherAssets.finalVideo ?? []
      ).map(({ name = "", link = "" }) => ({ name, link })),
      musicAssets: (
        otherAssets.musicAssets ?? []
      ).map(({ name = "", link = "" }) => ({ name, link })),
    };
  };
  const hasServicesChanged =
    Object.values(servicesChanged).filter((item) => item).length > 0;
  const hasTermsChanges =
    Object.values(termsChanged).filter((item) => item).length > 0;
  const hasChanges =
    project.projectType !== projectType ||
    project.name !== projectDetailsData.name ||
    project.format !== projectDetailsData.format ||
    !isEqual(project.leads, projectDetailsData.leads) ||
    project.status !== projectDetailsData.status ||
    !isEqual(project.earnings, projectDetailsData.earnings) ||
    !isEqual(project.musicBudget, projectDetailsData.musicBudget) ||
    project.estimatedProjectClosedDate !==
      formatComparingDate(projectDetailsData.estimatedProjectClosedDate) ||
    project.description !== projectDetailsData.description ||
    project.campaignName !== projectDetailsData.campaignName ||
    project.crmDealId !== projectDetailsData.crmDealId ||
    project.internalCreativeTeamEngaged !==
      projectPublishingData.internalCreativeTeamEngaged ||
    (project.copyright?.checked ?? false) !==
      (projectPublishingData?.copyright?.checked ?? false) ||
    (project.copyright?.notes ?? "") !==
      (projectPublishingData?.copyright?.notes ?? "") ||
    (project.publishing?.checked ?? false) !==
      (projectPublishingData?.publishing?.checked ?? false) ||
    (project.publishing?.notes ?? "") !==
      (projectPublishingData?.publishing?.notes ?? "") ||
    project.jobListingDate !==
      formatComparingDate(projectDetailsData.jobListingDate) ||
    isExtensionChanged ||
    relatedUrlsChanged ||
    hasCreativeAgencyInvolvedChanged ||
    hasCreativeAgencyIdChanged ||
    hasCreativeAgencyOfficeChanged ||
    project.clientReferenceNo !== projectDetailsData.clientReferenceNo ||
    project.projectRegion !== projectDetailsData.projectRegion ||
    !isEqual(project.costAvoidance, projectDetailsData.costAvoidance) ||
    !isEqual(project.initialMusicQuote, projectDetailsData.initialMusicQuote) ||
    project.sharedWithClient !== projectHeaderData.sharedWithClient ||
    !isEqual(
      project.regionalBrand,
      projectProductAndAccountInformationData.regionalBrand
    ) ||
    !isEqual(
      project.productDivision,
      projectProductAndAccountInformationData.productDivision
    ) ||
    !isEqual(
      project.globalBrand,
      projectProductAndAccountInformationData.globalBrand
    ) ||
    !isEqual(
      getClientIdAndName(project.client),
      getClientIdAndName(projectProductAndAccountInformationData.client)
    ) ||
    !isEqual(
      project.clientContact,
      projectProductAndAccountInformationData.clientContact
    ) ||
    project.product !== projectProductAndAccountInformationData.product ||
    !isEqual(project.tags, projectTags) ||
    !isEqual(mapContacts(project.contacts), mapContacts(projectContacts)) ||
    !isEqual(project.invoiceeContacts, projectContactInvoices) ||
    !isEqual(project.invoicee, projectInvoicee) ||
    !isEqual(
      mapOtherAssets(project.music),
      mapOtherAssets(projectOtherAssets)
    ) ||
    hasServicesChanged ||
    trackChanged ||
    hasTermsChanges ||
    !isEqual(project.curators, projectDetailsData.curators) ||
    !isEqual(project.originators, projectDetailsData.originators) ||
    project.contractStartDate !==
      projectSubscriptionDetails.contractStartDate ||
    project.contractEndDate !== projectSubscriptionDetails.contractEndDate ||
    project.description !== projectSubscriptionDetails.description;

  return hasChanges;
};

export default areChangesMadeInProject;
