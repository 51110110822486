import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import { StylesConfig } from "react-select";
import theme from "src/theme";

type IsMulti = boolean;
interface IOption {
  label: string;
  value: string;
}

export const selectStyle: StylesConfig<IOption, IsMulti> = {
  control: (provided) => {
    return {
      ...provided,
      minHeight: 50,
    };
  },
};

export default {
  container: css`
    display: flex;
    flex-direction: column;
  `,
  optionRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    :hover {
      background-color: none !important;
    }
  `,
  summaryIconsContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
    width: 4.75rem;
    min-width: 4.75rem;
    max-width: 4.75rem;
  `,

  summaryIconContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
  overflowContainer: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  moreInfoContainer: css`
    text-align: right;
  `,

  optionRowDelete: css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    background: none !important;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 32px;
    z-index: 999;

    :hover {
      background: rgba(31, 72, 231, 0.16) !important;
    }
  `,
  headerContainer: css`
    margin-bottom: 1rem;
  `,
  headerText: css`
    font-size: 1rem;
    color: ${theme.colors.secondary.navyBlue};
    font-weight: bold;
  `,
  iconContainer: css`
    margin-right: 0.5rem;
    padding-top: 0 !important;
    margin-left: 0.5rem;

    @media ${queries.medium} {
      margin-left: 0;
    }
  `,
  addButton: css`
    margin-left: 6px;
    margin-bottom: 1rem;
    div {
      float: right;
    }
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
      color: #fff;
      background: ${theme.colors.background.lightGray};
      border-color: ${theme.colors.background.gray};
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  `,
  submitButton: css`
    float: right;
  `,
  cancelButton: css`
    height: 1.6rem;
    width: 98px;
    margin-right: 1.0625rem;
    vertical-align: middle;
  `,
  buttonRow: css`
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    float: right;
  `,
  deleteIconContainer: css`
    margin-left: -0.5rem;
    padding-top: 0 !important;

    @media ${queries.medium} {
      padding-top: 0.7rem;
    }
  `,
  summaryContainer: css`
    background-color: unset;
    box-shadow: none;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 0.2rem;
  `,
  summaryColumnContainer: css`
    width: 9rem;
    min-width: 9rem !important;
    flex-grow: 1;
    @media ${queries.medium} {
      width: 5rem !important;
      min-width: 5rem !important;
    }
  `,
  summaryColumnTitle: css`
    font-size: 0.688rem;
    line-height: 1.25rem;
    color: ${theme.colors.background.darkGray};
  `,
  summaryColumnInformation: css`
    font-family: ${theme.fonts.sofiaPro};
    font-style: normal;
    font-weight: 400;
    text-align: start;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    min-width: 80%;
  `,
  expandButtonCol: css`
    margin-left: -2rem;
    margin-right: 3rem;

    @media ${queries.medium} {
      margin-left: 0;
      margin-right: 0;
    }
  `,
  moreInfoButton: css`
    vertical-align: middle;
  `,
  editContainer: css`
    box-shadow: 0 0 0.15rem 0.03rem ${theme.colors.background.boxShadowGray};
    padding-bottom: 0.5rem;
  `,

  editCollapseContainer: css`
    background-color: ${theme.colors.white};
    border: none !important;
  `,

  collapseContainer: css`
    box-shadow: none;
    border: none !important;
    border-radius: 0 !important;
    background-color: ${theme.colors.white};
  `,
  panelContainer: css`
    border-radius: 0 !important;
    .ant-collapse-item {
      border-bottom: none !important;
    }
    .ant-collapse {
      border-top: 0.5px solid ${theme.colors.background.boxShadowGray};
    }
    .ant-collapse-content {
      border: none !important;
      .ant-collapse-content-box {
        background-color: ${theme.colors.white};
      }
    }
    .ant-collapse-header {
      border-radius: 0;
      border: none !important;
      padding: 0 !important;
    }
  `,

  collapseSummaryContainer: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    padding-right: 1.5rem;
    align-items: center;
    text-align: center;
  `,
  sectionDivider: css`
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  `,
  sectionHeader: css`
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: ${theme.colors.secondary.blueOnBlack};
    font-weight: bold;
    padding-left: 1rem !important;
    padding-top: 0.5rem !important;
  `,
  collapseButtonRow: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  `,
  secondaryButton: css`
    font-size: 1rem;
    align-self: center;
    justify-content: center;
    height: 100%;
    border-radius: 0;
    padding: 0;
    background-color: ${theme.colors.white} !important;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: bold;
    border: none;
    color: ${theme.colors.primary.blue} !important;
    cursor: pointer;
    box-shadow: none;

    :hover {
      color: #0d2481;
      font-weight: bold;
      background-color: unset;
    }

    :focus {
      outline-style: unset;
    }
  `,
};
