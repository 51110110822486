import axios, { CancelTokenSource } from "axios";
import config from "src/config";
import {
  IOrganisation,
  IServicingOrgsInviteResponse,
} from "src/interfaces/organisation";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

let call: CancelTokenSource;

export const getServicedOrgs = (
  accessToken: string,
  organisationId: string
): Promise<Array<IOrganisation>> => {
  call = axios.CancelToken.source();
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    cancelToken: call.token,
  };
  return axios
    .post(
      `${config.organizationsService.url}/_search?orgId=${organisationId}`,
      {
        start: 0,
        perPage: 10000,
        orderBy: "Desc",
        servicingOrganisationId: organisationId,
      },
      options
    )
    .then(({ data }: { data: IServicingOrgsInviteResponse }) => data.items);
};

export default getServicedOrgs;
