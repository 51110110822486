import { ReactElement, ReactNode, useCallback, useEffect, useRef } from "react";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import useAuth from "src/auth/use-auth";
import { useImmerReducer } from "use-immer";
import getProjectTypes from "src/api/projects/get-project-types/getProjectTypes";
import { AxiosError } from "axios";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import { ErrorToast } from "src/components/toast-notification";
import getTalentHubTypes from "src/api/talent-hub/get-talent-hub-types";
import { ITalentHubTypes } from "src/api/talent-hub/interfaces";
import STLoadingLogo from "src/components/st-loading-logo";
import GlobalContext from "./context";
import { GlobalContextActions } from "./types";
import { InitialGlobalState, globalContextReducer } from "./store";

interface IGlobalProvider {
  children: ReactNode;
}

const GlobalContextProvider = ({ children }: IGlobalProvider): ReactElement => {
  const { getAccessToken, isAuthenticated, organisationId, logout } = useAuth();
  const isLoading = useRef(true);
  const [state, dispatch] = useImmerReducer(
    globalContextReducer,
    InitialGlobalState
  );

  const storeProjectTypes = useCallback(
    (projectTypes: IOption[]) => {
      dispatch({
        type: GlobalContextActions.STORE_PROJECT_TYPES,
        value: projectTypes,
      });
    },
    [dispatch]
  );

  const storeProjectStatuses = useCallback(
    (projectStatuses: IOption[]) => {
      dispatch({
        type: GlobalContextActions.STORE_STATUSES,
        value: projectStatuses,
      });
    },
    [dispatch]
  );

  const storeProjectRegions = useCallback(
    (projectRegions: IOption[]) => {
      dispatch({
        type: GlobalContextActions.STORE_PROJECT_REGIONS,
        value: projectRegions,
      });
    },
    [dispatch]
  );

  const setIsProjectStatusesLoading = useCallback(
    (isProjectStatusesOrTypesLoading: boolean) => {
      dispatch({
        type: GlobalContextActions.SET_LOADING,
        value: isProjectStatusesOrTypesLoading,
      });
    },
    [dispatch]
  );

  const storeTalentHubTypes = useCallback(
    (talentHubTypes: ITalentHubTypes) => {
      dispatch({
        type: GlobalContextActions.STORE_TALENT_HUB_TYPES,
        value: talentHubTypes,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    void (async () => {
      const accessToken = getAccessToken();
      if (accessToken && isAuthenticated) {
        try {
          isLoading.current = true;
          setIsProjectStatusesLoading(true);
          const responses = await Promise.all([
            getProjectTypes(accessToken, organisationId),
            getTalentHubTypes(accessToken, organisationId),
          ]);
          const projectTypesResponse = responses[0];
          const talentHubTypesResponse = responses[1];
          if (projectTypesResponse?.types) {
            storeProjectTypes(projectTypesResponse.types);
          }
          if (projectTypesResponse?.statuses) {
            storeProjectStatuses(projectTypesResponse.statuses);
          }
          if (projectTypesResponse?.regions) {
            storeProjectRegions(projectTypesResponse.regions);
          }
          if (talentHubTypesResponse) {
            storeTalentHubTypes(talentHubTypesResponse);
          }
        } catch (e) {
          const error = e as AxiosError;
          log(DataDogLogTypes.ERROR, "Error retrieving projects data", error);
          ErrorToast(
            "project-listings-error",
            "There was a problem retrieving projects data. Please try again."
          );
        } finally {
          isLoading.current = false;
          setIsProjectStatusesLoading(false);
        }
      } else {
        logout();
      }
    })();
  }, [
    getAccessToken,
    logout,
    isAuthenticated,
    organisationId,
    setIsProjectStatusesLoading,
    storeProjectRegions,
    storeProjectStatuses,
    storeProjectTypes,
    storeTalentHubTypes,
  ]);

  if (isLoading.current) return <STLoadingLogo pageCentered />;

  return (
    <GlobalContext.Provider
      value={{
        allProjectStatuses: state.allProjectStatuses,
        isGlobalDataLoading: state.isGlobalDataLoading,
        allProjectTypes: state.allProjectTypes,
        allProjectRegions: state.allProjectRegions,
        talentHubTypes: state.talentHubTypes,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
