import { createContext } from "react";
import IGlobalContextProvider from "./types";

export default createContext<IGlobalContextProvider>({
  isGlobalDataLoading: false,
  allProjectTypes: [],
  allProjectStatuses: [],
  allProjectRegions: [],
  talentHubTypes: {
    genderIdentities: [],
    genderPronouns: [],
    musicGenres: [],
    racialCharacteristics: [],
    sexualOrientations: [],
    skillSets: [],
    talentCollaborations: [],
    talentInstruments: [],
    talentWorkTypes: [],
    countryCodes: [],
    languageCodes: [],
  },
});
