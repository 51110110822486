import React, {
  Fragment,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "antd";
import { useWindowSize } from "@songtradr/spa-common";
import STLoadingLogo from "src/components/st-loading-logo";
import TabbedPageContainer from "src/components/tabbed-page-container";
import SaveAndCloseButton from "src/components/save-and-close-button";
import ConfirmModal from "src/components/modals/confirm-modal";
import {
  IBadgeCounter,
  IProjectHeaderData,
  ITab,
  TabsTitles,
} from "./interfaces";
import style from "./styles";
import { IOption } from "./components/account-information/types";
import { IProjectHeaderAndSubHeader } from "./components/product-information/types";
import { isEnterpriseLibraryProject } from "./utils";

interface IChildrenProps {
  contacts: ReactNode;
  details: ReactNode;
  services: ReactNode;
  music: ReactNode;
  documents: ReactNode;
  licensing: ReactNode;
  productInformation: ReactNode;
  projectTags: ReactNode;
  projectTypeAndFormat: ReactNode;
  publishing: ReactNode;
  otherMusicAssets: ReactNode;
  subscriptionDetails: ReactNode;
  libraryTrackDetails: ReactNode;
  libraryTrackAccountInformation: ReactNode;
}
interface IProps {
  isSaving: boolean;
  isLoading: boolean;
  children: IChildrenProps;
  projectOrgId: string;
  version: number;
  selectedProjectType?: IOption;
  activeTab?: ITab;
  setActiveTab: (activeTab: ITab) => void;
  projectHeaderDate: IProjectHeaderData;
  onSaveProject: () => Promise<void>;
  updateProjectHeaderData: (projectHeaderDate: IProjectHeaderData) => void;
  validation: {
    projectDetails: boolean;
    music: boolean;
    licensing: boolean;
    subscription: boolean;
    contacts: boolean;
  };
  projectHeaderAndSubHeader?: IProjectHeaderAndSubHeader;
  badgeCounter?: IBadgeCounter;
  onTabChangedResetTracksChanges: (changed: boolean) => void;
}
const Project = memo(
  ({
    validation,
    isSaving,
    isLoading,
    children,
    activeTab,
    projectOrgId,
    version,
    projectHeaderDate,
    selectedProjectType,
    projectHeaderAndSubHeader,
    setActiveTab,
    updateProjectHeaderData,
    onSaveProject,
    badgeCounter,
    onTabChangedResetTracksChanges,
  }: IProps): ReactElement => {
    const { t } = useTranslation();
    const {
      details,
      music,
      documents,
      services,
      licensing,
      contacts,
      productInformation,
      projectTags,
      projectTypeAndFormat,
      otherMusicAssets,
      subscriptionDetails,
      libraryTrackDetails,
      libraryTrackAccountInformation,
      publishing,
    } = children;
    useWindowSize();

    const updateShareWithClient = useCallback(
      (toggle: boolean) => {
        updateProjectHeaderData({ sharedWithClient: toggle });
      },
      [updateProjectHeaderData]
    );

    const saveAndCloseComponent = useMemo(
      () => (
        <SaveAndCloseButton
          dataTestId="save-and-close-button"
          ariaLabel="ProjectsPage##Save and close"
          label="ProjectsPage##Save and close"
          onSaveProject={onSaveProject}
        />
      ),
      [onSaveProject]
    );

    const sharedWithClient = useMemo(() => {
      return (
        <div css={style.switchWithClient}>
          <div css={style.switchWithClientText}>{t("Share with client")}</div>
          <Switch
            onChange={updateShareWithClient}
            defaultChecked={projectHeaderDate.sharedWithClient}
            css={style.switchWithClientTextAlign}
          />
        </div>
      );
    }, [projectHeaderDate.sharedWithClient, t, updateShareWithClient]);

    const showOtherTabsAndData = useMemo(
      () => !!version || selectedProjectType?.label,
      [selectedProjectType?.label, version]
    );

    const ProjectDetailsTab = useMemo(() => {
      return (
        <div css={style.formContainer}>
          {projectTypeAndFormat}
          {showOtherTabsAndData && (
            <>
              {details}
              {productInformation}
              {otherMusicAssets}
            </>
          )}
        </div>
      );
    }, [
      details,
      otherMusicAssets,
      productInformation,
      projectTypeAndFormat,
      showOtherTabsAndData,
    ]);

    const PublishingTab = useMemo(() => {
      return <div css={style.formContainer}>{publishing}</div>;
    }, [publishing]);

    const ServicesTab = useMemo(() => {
      return <div css={style.formContainer}>{services}</div>;
    }, [services]);

    const MusicTab = useMemo(() => {
      return <div css={style.formContainer}>{music}</div>;
    }, [music]);

    const LicensingTab = useMemo(() => {
      return <div css={style.formContainer}>{licensing}</div>;
    }, [licensing]);

    const DocumentsTab = useMemo(() => {
      return <div css={style.formContainer}>{documents}</div>;
    }, [documents]);

    const ContactsTab = useMemo(() => {
      return <div css={style.formContainer}>{contacts}</div>;
    }, [contacts]);

    const TagsTab = useMemo(() => {
      return <div css={style.formContainer}>{projectTags}</div>;
    }, [projectTags]);

    const EnterpriseLibraryProjectDetailsTab = useMemo(() => {
      return (
        <div css={style.formContainer}>
          {projectTypeAndFormat}
          {showOtherTabsAndData && libraryTrackAccountInformation}
        </div>
      );
    }, [
      libraryTrackAccountInformation,
      projectTypeAndFormat,
      showOtherTabsAndData,
    ]);

    const SubscriptionTab = useMemo(() => {
      return <div css={style.formContainer}>{subscriptionDetails}</div>;
    }, [subscriptionDetails]);

    const LibraryTrackTab = useMemo(() => {
      return <div css={style.formContainer}>{libraryTrackDetails}</div>;
    }, [libraryTrackDetails]);

    const EnterpriseLibraryTabs = useMemo(() => {
      return [
        {
          tabTitle: TabsTitles.ProjectDetails,
          content: EnterpriseLibraryProjectDetailsTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.Subscription,
          content: SubscriptionTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.LibraryTrack,
          content: LibraryTrackTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.Contacts,
          content: ContactsTab,
          hashLink: true,
        },
      ];
    }, [
      ContactsTab,
      EnterpriseLibraryProjectDetailsTab,
      LibraryTrackTab,
      SubscriptionTab,
    ]);

    const DefaultProjectTypeTabs = useMemo(() => {
      return [
        {
          tabTitle: TabsTitles.ProjectDetails,
          content: ProjectDetailsTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.Services,
          content: ServicesTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.Music,
          content: MusicTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.Licenses,
          content: LicensingTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.Documents,
          content: DocumentsTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.Contacts,
          content: ContactsTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.Tags,
          content: TagsTab,
          hashLink: true,
        },
        {
          tabTitle: TabsTitles.Publishing,
          content: PublishingTab,
          hashLink: true,
        },
      ];
    }, [
      ContactsTab,
      DocumentsTab,
      LicensingTab,
      MusicTab,
      ProjectDetailsTab,
      ServicesTab,
      TagsTab,
      PublishingTab,
    ]);

    const tabsHeader = useMemo(() => {
      if (!showOtherTabsAndData) {
        return [
          {
            tabTitle: TabsTitles.ProjectDetails,
            content: ProjectDetailsTab,
            hashLink: true,
          },
        ];
      }

      if (isEnterpriseLibraryProject(selectedProjectType?.value ?? "")) {
        return EnterpriseLibraryTabs;
      }

      return DefaultProjectTypeTabs;
    }, [
      DefaultProjectTypeTabs,
      EnterpriseLibraryTabs,
      ProjectDetailsTab,
      selectedProjectType?.value,
      showOtherTabsAndData,
    ]);

    useEffect(() => {
      if (activeTab?.tabTitle !== TabsTitles.Music) {
        onTabChangedResetTracksChanges(false);
      }
    }, [activeTab, onTabChangedResetTracksChanges]);

    const tabs = useMemo(() => {
      return (
        <TabbedPageContainer
          isSaving={isSaving}
          projectOrgId={projectOrgId}
          projectHeaderAndSubHeader={projectHeaderAndSubHeader}
          badgeCounter={badgeCounter}
          tabs={tabsHeader}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          validation={validation}
          saveAndCloseComponent={saveAndCloseComponent}
          sharedWithClient={sharedWithClient}
        />
      );
    }, [
      isSaving,
      projectOrgId,
      projectHeaderAndSubHeader,
      badgeCounter,
      tabsHeader,
      activeTab,
      setActiveTab,
      validation,
      saveAndCloseComponent,
      sharedWithClient,
    ]);

    return (
      <div css={style.projectContainer}>
        {isLoading ? (
          <STLoadingLogo pageCentered />
        ) : (
          <>
            <ConfirmModal />
            {tabs}
          </>
        )}
      </div>
    );
  }
);

export default Project;
