import axios from "axios";
import config from "src/config";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const deleteGroup = async (
  accessToken: string,
  groupId: string,
  isUserGroup: string,
  organisationId: string
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  await axios.delete(
    `${config.projectManagementService.url}/talent-hub/group/${groupId}?orgId=${organisationId}&isUserGroup=${isUserGroup}`,
    options
  );
};

export default deleteGroup;
