import axios from "axios";
import { createThirdPartyServicesUrl } from "src/api/third-party-services-url-helper";
import { IThirdParty } from "src/pages/projects/project/components/services/types";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const createThirdParty = async (
  accessToken: string,
  organisationId: string,
  thirdPartyName: string
): Promise<IThirdParty> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const payload = { name: thirdPartyName, organisationId };

  return axios
    .post(createThirdPartyServicesUrl(), payload, options)
    .then(({ data }) => data as IThirdParty);
};

export default createThirdParty;
