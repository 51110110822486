import React, { Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import ResetPassword from "..";
import styles from "../styles";

function ResetContainer(): ReactElement | null {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div css={styles.container}>
        <div css={styles.headerContainer}>
          <Typography.Title css={styles.heading}>
            {t("Reset Password")}
          </Typography.Title>
        </div>
        <ResetPassword />
      </div>
    </Fragment>
  );
}

export default ResetContainer;
