import axios from "axios";
import { IFinalTrackSection } from "src/pages/projects/project/interfaces";
import { createTrackUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const createTrack = async (
  accessToken: string,
  organisationId: string,
  projectId: string
): Promise<IFinalTrackSection> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const response = await axios.post(
    createTrackUrl(projectId),
    { trackType: "FinalTrack", organisationId },
    options
  );
  return response.data as IFinalTrackSection;
};

export default createTrack;
