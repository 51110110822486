import { Typography, Form } from "antd";
import React, { ReactElement, useCallback } from "react";
import ReactSelect, { ActionMeta } from "react-select";
import { useTranslation } from "react-i18next";
import { IUserDropdownProps } from "src/pages/team/interfaces";
import { Contributor } from "src/pages/projects/project/interfaces";
import style from "../../../../styles";
import { IParseLeadOptions } from "../client-details-and-project-leads-row/ClientDetailsAndProjectLeadsRow";
import { customStylesForProjectDropdown } from "../../styles";

interface IProjectContributors {
  parsedLeadOptions: IParseLeadOptions[];
  onProjectCuratorsOrOriginatorsChange: (
    contributor: Contributor,
    action: ActionMeta<IUserDropdownProps>
  ) => void;
}

const ProjectContributors = ({
  parsedLeadOptions,
  onProjectCuratorsOrOriginatorsChange,
}: IProjectContributors): ReactElement => {
  const { t } = useTranslation();
  const formatOptionLabel = useCallback((data: IUserDropdownProps) => {
    const { label, email, name } = data;
    return (
      <>
        <div>{name ?? label}</div>
        <div css={style.emailLabel}>{email}</div>
      </>
    );
  }, []);

  return (
    <>
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##Project Originator")}
          </Typography.Text>
        </div>
        <Form.Item name="originators" css={style.formItem}>
          <ReactSelect
            name="originators"
            isClearable={false}
            isMulti
            styles={customStylesForProjectDropdown}
            placeholder=""
            options={parsedLeadOptions}
            formatOptionLabel={formatOptionLabel}
            onChange={(_, action) =>
              onProjectCuratorsOrOriginatorsChange(
                Contributor.ORIGINATOR,
                action
              )
            }
            id="project-originator-dropdown"
          />
        </Form.Item>
      </div>
      <div>
        <div css={style.basicInputContainer}>
          <Typography.Text css={style.basicInputLabel}>
            {t("ProjectsPage##Project Curator")}
          </Typography.Text>
        </div>
        <Form.Item name="curators" css={style.formItem}>
          <ReactSelect
            isMulti
            name="curators"
            isClearable={false}
            styles={customStylesForProjectDropdown}
            placeholder=""
            options={parsedLeadOptions}
            formatOptionLabel={formatOptionLabel}
            onChange={(_, action) =>
              onProjectCuratorsOrOriginatorsChange(Contributor.CURATOR, action)
            }
            id="project-curator-dropdown"
          />
        </Form.Item>
      </div>
    </>
  );
};

export default ProjectContributors;
