import { useMountedRef } from "@songtradr/spa-common/lib/utils";
import React, { ReactElement, useCallback, useState } from "react";
import getOrganisationInvite from "src/api/organisation-invites/get-org-invite";
import { IOrganisationInvite, IProfileForm } from "src/interfaces/organisation";
import { IOrganisationUrlParams } from "src/interfaces/organisation-url-params";
import UserProfile from "..";
import acceptOrganisationInvite from "../../../api/organisation-invites/accept-org-invite";
import useAuth from "../../../auth/use-auth";

function UserProfileContainer(
  orgParams: IOrganisationUrlParams
): ReactElement | null {
  const isMountedRef = useMountedRef();
  const { handleLogin } = useAuth();
  const [isSavePending, setIsSavePending] = useState(false);
  const [organisationInvite, setOrganisationInvite] = useState<
    IOrganisationInvite | undefined
  >(undefined);

  const submitForm = async (formData: IProfileForm) => {
    setIsSavePending(true);
    try {
      if (organisationInvite !== undefined) {
        const requestBody = {
          ...organisationInvite,
          ...orgParams,
          ...formData,
          email: organisationInvite.email,
        };
        const { organisationId, organisationInviteId } = orgParams;
        await acceptOrganisationInvite(
          organisationId,
          organisationInviteId,
          requestBody
        );
      }

      if (organisationInvite?.email) {
        handleLogin(organisationInvite.email, formData.password);
      }
    } finally {
      if (isMountedRef.current) setIsSavePending(false);
    }
  };

  const getData = useCallback(async () => {
    try {
      const { organisationId, organisationInviteId } = orgParams;

      const organisationInviteResponse = await getOrganisationInvite(
        organisationId,
        organisationInviteId
      );
      if (isMountedRef.current)
        setOrganisationInvite(organisationInviteResponse);
    } catch (e) {
      if (isMountedRef.current) setOrganisationInvite(e);
    }
  }, [isMountedRef, orgParams]);

  const callGetData = useCallback((): IOrganisationInvite | undefined => {
    void getData();
    return organisationInvite;
  }, [getData, organisationInvite]);

  return (
    <UserProfile
      getData={callGetData}
      organisationInvite={organisationInvite}
      onSubmit={submitForm}
      isSavePending={isSavePending}
    />
  );
}

export default UserProfileContainer;
