import { IProjectLibraryTrackDetails } from "../../../interfaces";

export enum LibraryTrackSortType {
  trackId = "trackId",
  trackTitle = "trackTitle",
  trackArtist = "trackArtist",
  downloadedBy = "downloadedBy",
  declaredBy = "declaredBy",
  createdDate = "createdDate",
  projectName = "projectName",
  projectDescription = "projectDescription",
  businessBrand = "businessBrand",
  goLiveDate = "goLiveDate",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface ILibraryTrackTableSort {
  sortBy: LibraryTrackSortType;
  order: SortOrder;
}

export interface ILibraryTrackSearchResponse {
  totalPages: number;
  totalResults: number;
  currentPage: number;
  results: IProjectLibraryTrackDetails[];
}

export interface IPaginationOptions {
  total: number;
  currentPage: number;
}

export interface ILibraryTrackDataSource {
  key: string;
  trackName?: JSX.Element;
  projectName?: JSX.Element;
  businessBrand?: JSX.Element;
  platform?: JSX.Element | null;
  channels?: JSX.Element;
  declaredUsage?: JSX.Element;
  goLiveDate?: JSX.Element;
  createdDate?: JSX.Element;
}
