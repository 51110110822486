import React, { ReactElement } from "react";
import BaseModal from "../modals/base-modal";
import Content from "../modals/unlimted-scripts";

interface IScriptModal {
  setShowModal: (value: boolean) => void;
  resetScriptForm: () => void;
  showModal: boolean;
  removeAllScripts: () => void;
}

const ScriptModal = (props: IScriptModal): ReactElement => {
  const { setShowModal, showModal, removeAllScripts, resetScriptForm } = props;

  const onClose = () => {
    setShowModal(false);
    resetScriptForm();
  };

  return (
    <BaseModal
      open={showModal}
      width={520}
      content={
        <Content onClose={onClose} removeAllScripts={removeAllScripts} />
      }
      buttons={[]}
      onClose={onClose}
    />
  );
};

export default ScriptModal;
