import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  contentContainer: css`
    padding: 0 1rem 0 1rem;
    height: 5rem;
    @media ${queries.medium} {
      padding: 0;
    }

    .ant-form-item-explain {
      height: 0.938rem;
      color: ${theme.colors.functional.red};
      font-family: ${theme.fonts.sofiaPro};
      font-size: 0.75rem;
      letter-spacing: 0;
      line-height: 0.938rem;
      margin-left: 0.7rem;
      margin-top: 0.5rem;
    }

    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: ${theme.colors.functional.red} !important;
    }

    @media ${queries.large} {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  `,
  header: css`
    color: ${theme.colors.secondary.navyBlue} !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    margin-bottom: 1.5rem !important;
    height: 1.5rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    margin-left: 1rem;

    @media ${queries.medium} {
      margin-left: 0;
    }
    font-family: ${theme.fonts.sofiaPro};

    :first-of-type {
      margin-top: 1.375rem;

      @media ${queries.medium} {
        margin-top: 1rem;
      }
    }
  `,
  tabletDesktopButtonContainer: css`
    height: 3.125rem;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    align-items: center;
    column-gap: 1.5rem;
    & button {
      box-shadow: none;
    }
  `,
  primaryButton: css`
    flex: 1;
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: bold;
    padding: 0 1rem 0 1rem;
    border: 0;
    color: ${theme.colors.white};
    box-shadow: none;
    border-radius: 0.3rem !important;

    &:hover {
      box-shadow: none;
      background-color: ${theme.colors.primary.blue} !important;
      @media ${queries.large} {
        background-color: ${theme.colors.secondary.navyBlue} !important;
      }
    }
  `,
};
