import { createContext } from "react";
import { IOrganisation } from "src/interfaces/organisation";

export interface IOrganisationProviderContext {
  getOrg: (organisationId: string, accessToken: string) => Promise<void>;
  isLoading: boolean;
  organisation: IOrganisation | undefined;
}

export default createContext<IOrganisationProviderContext>({
  getOrg: () => Promise.resolve(),
  isLoading: true,
  organisation: undefined,
});
