import { startCase, upperFirst } from "lodash";
import { AllContactsFiltersValues } from "src/pages/talent-hub/interfaces";

const getFilterLabel = (filterName: string): string => {
  switch (filterName) {
    case AllContactsFiltersValues.Collaboration:
      return "Rating";
    case AllContactsFiltersValues.CMASigned:
      return "CMA Signed";
    case AllContactsFiltersValues.NDASigned:
      return "NDA Signed";
    case AllContactsFiltersValues.RacialCharacteristics:
      return "Racial Characteristics";
    default:
      return upperFirst(startCase(filterName));
  }
};

export default getFilterLabel;
