import config from "./config";

export interface IConfig {
  production?: boolean;
  staging?: boolean;
  development?: boolean;
  isClientOrg: boolean;
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
    redirectUri: string;
    appName: string;
    dbConnection?: string;
  };
  cognito: {
    userPoolId: string;
    clientId: string;
    domain: string;
    isSecure: boolean;
  };
  recaptcha: {
    key: string;
  };
  organizationsService: {
    url: string;
  };
  projectManagementService: {
    url: string;
  };
  creativeAgenciesService: {
    url: string;
  };
  thirdPartyService: {
    url: string;
  };
  userMetaDataService: {
    url: string;
  };
  externalResources: {
    termsOfService: string;
    registrationLearnMore: string;
  };
  webHookUrl: string;
  dataDogToken: string;
  basePathname: string;
  heapId: string;
  hubspotBaseUrl: string;
  regionRequiredFieldForOrganisations: string[];
  contactNameAndEmailRequiredForOrganisations: string[];
}

export default config as IConfig;
