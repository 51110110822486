import React, { ReactElement, useCallback } from "react";
import { Typography } from "antd";
import { Operation } from "src/constants";
import mainStyles from "src/pages/projects/project/styles";
import * as uuid from "uuid";
import useAuth from "src/auth/use-auth";
import NoteRow from "./note-row";
import { INote } from "../../interfaces";

interface IProps {
  title: string;
  fieldName: string;
  items: INote[];
  disableActions: boolean;
  setNotes: (notes: INote[]) => void;
}

const Notes = ({
  title,
  fieldName,
  items,
  disableActions,
  setNotes,
}: IProps): ReactElement => {
  const { user } = useAuth();
  const onPerformOperation = useCallback(
    (operation: Operation, data?: INote, index?: number) => {
      if (data !== undefined) {
        const isRemoveOperation = operation === Operation.remove;
        const isUpdateOperation = operation === Operation.update;
        const isValidIndex = index || index === 0;

        if (isRemoveOperation && isValidIndex) {
          const newArray = [...items];
          newArray.splice(index, 1);
          setNotes(newArray);
        } else if (operation === Operation.add) {
          setNotes([...items, data]);
        } else if (isUpdateOperation && isValidIndex) {
          const newArray = [...items];
          newArray[index] = data;
          setNotes(newArray);
        }
      }
    },
    [items, setNotes]
  );

  const handleAddRowItem = useCallback(() => {
    onPerformOperation(
      Operation.add,
      {
        note: "",
        date: new Date().toISOString(),
        userId: user?.id || "",
        userFullname: user?.name || "",
      },
      items.length
    );
  }, [items.length, onPerformOperation, user?.id, user?.name]);

  const handleDeleteRowItem = useCallback(
    (index: number) => {
      const itemToDelete = items[index];
      if (itemToDelete) {
        onPerformOperation(Operation.remove, itemToDelete, index);
      }
    },
    [items, onPerformOperation]
  );

  const handleUpdateRowItem = useCallback(
    (index: number, value: INote) => {
      onPerformOperation(Operation.update, value, index);
    },
    [onPerformOperation]
  );

  return (
    <div css={mainStyles.sectionFormContainer}>
      <Typography.Text css={mainStyles.basicInputLabel}>
        {title}
      </Typography.Text>
      {items.map((note, index) => {
        return (
          <NoteRow
            key={`${fieldName}-noteRow-${uuid.v4()}`}
            data={note}
            index={index}
            totalRows={items.length}
            fieldName={fieldName}
            disableActions={disableActions}
            onAddNewRow={handleAddRowItem}
            onDeleteRow={handleDeleteRowItem}
            onUpdateRow={handleUpdateRowItem}
            userData={{
              userId: user?.id || "",
              userFullname: user?.name || "",
            }}
          />
        );
      })}
    </div>
  );
};

export default Notes;
