import axios from "axios";
import {
  SortOrder,
  ProjectSortType,
  IProjectSearchResponse,
  IFiltersSearch,
} from "src/pages/projects/project-listings/interfaces";
import { searchProjectsUrl } from "../../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const searchProjects = (
  accessToken: string,
  organisationId: string,
  sort: { by: ProjectSortType; order: SortOrder },
  from = 0,
  searchText = "",
  filters?: IFiltersSearch
): Promise<IProjectSearchResponse | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  const body = {
    from,
    sort,
    searchText,
    filters,
    organisationId,
  };
  return axios
    .post(searchProjectsUrl(), body, options)
    .then(({ data }) => data as IProjectSearchResponse);
};

export default searchProjects;
