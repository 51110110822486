import { startCase } from "lodash";
import { IFilterData } from "src/api/user-filters/interfaces";
import { IGlobalBrands } from "src/pages/projects/project-listings/interfaces";
import {
  IContact,
  IHoldingCompany,
} from "src/pages/projects/project/components/account-information/types";
import {
  ICreativeAgency,
  IProjectClient,
  IProjectLead,
  MusicTypes,
  ProjectStatus,
  ServicesTypes,
} from "src/pages/projects/project/interfaces";

export interface ISelectProjectsLeads extends ISelectLabel {
  email: string;
}

export interface ISelectLabel {
  value: string;
  label: string;
}

export const parseContactToLabel = (
  data: { name: string; email: string }[]
): ISelectLabel[] => {
  return (data ?? []).map(({ name, email }) => ({
    label: name.trim(),
    value: email,
  }));
};

export const parseJSONToIdValue = (
  data: { id?: string | number }[]
): string[] => {
  return (data ?? [])
    .map(({ id = "" }) => id.toString())
    .filter((id) => id !== undefined);
};

export const parseJSONToEmailValue = (data: { email?: string }[]): string[] => {
  return (data ?? [])
    .map(({ email = "" }) => email)
    .filter((id) => id !== undefined);
};

export const parseJSONToLabel = (
  data: {
    name?: string;
    id?: string | number;
  }[]
): ISelectLabel[] => {
  return (data ?? [])
    .map(({ name = "", id = "" }) => {
      if (!name || id === "") {
        return null;
      }
      return {
        label: name.trim(),
        value: id.toString(),
      };
    })
    .filter(
      (labelData): labelData is NonNullable<ISelectLabel> => labelData !== null
    );
};

export const parseStatusToLabel = (data: string[]): ISelectLabel[] => {
  return data?.map((status) => {
    return {
      label: startCase(status.trim()),
      value: status,
    };
  });
};

const getPropertyPayloadById = <T extends { id?: string | number }>(
  selectedItems: ISelectLabel[],
  values: T[]
): T[] => {
  return selectedItems
    .map((selected) => {
      return (
        values.find((value) => value.id?.toString() === selected.value) ?? null
      );
    })
    .filter((data): data is NonNullable<T> => data !== null);
};

const getPropertyPayloadByEmail = <T extends { email?: string }>(
  selectedItems: ISelectLabel[],
  values: T[]
): T[] => {
  return selectedItems
    .map((selected) => {
      return values.find((value) => value.email === selected.value) ?? null;
    })
    .filter((data): data is NonNullable<T> => data !== null);
};

const getPropertyPayloadByValue = <T>(
  selectedItems: ISelectLabel[],
  values: T[]
): T[] => {
  return selectedItems
    .map((selected) => {
      return values.find((value) => value === selected.value) ?? null;
    })
    .filter((data): data is NonNullable<T> => data !== null);
};

const getArrayValueOrNull = <T>(values: T[]): T[] | null => {
  return values.length > 0 ? values : null;
};

export const getFilterDataPayload = (
  selectedClients: ISelectLabel[],
  clients: IProjectClient[],
  selectedGlobalBrands: ISelectLabel[],
  globalBrands: IGlobalBrands[],
  selectedCreativeAgencies: ISelectLabel[],
  creativeAgencies: ICreativeAgency[],
  selectedHoldingCompanies: ISelectLabel[],
  holdingCompanies: IHoldingCompany[],
  selectedStatus: ISelectLabel[],
  statuses: ProjectStatus[],
  selectedProjectTypes: ISelectLabel[],
  projectTypes: string[],
  selectedMusicTypes: ISelectLabel[],
  musicTypes: MusicTypes[],
  selectedServiceTypes: ISelectLabel[],
  serviceTypes: ServicesTypes[],
  selectedLeads: ISelectLabel[],
  leads: IProjectLead[],
  selectedContacts: ISelectLabel[],
  contacts: IContact[],
  selectedProjectCloseDate: (string | null)[],
  selectedJobListingDate: (string | null)[],
  selectedProjectRegions: ISelectLabel[],
  projectRegions: string[],
  selectedOfficeLocation: ISelectLabel[],
  officeLocations: string[],
  selectedCopyrightInfoProcessed: ISelectLabel,
  selectedPublishingRightsSigned: ISelectLabel,
  selectedPublishingTriggered: ISelectLabel
): IFilterData => {
  const clientsPayload = getPropertyPayloadById<IProjectClient>(
    selectedClients,
    clients
  );
  const globalBrandsPayload = getPropertyPayloadById<IGlobalBrands>(
    selectedGlobalBrands,
    globalBrands
  );
  const creativeAgenciesPayload = getPropertyPayloadById<ICreativeAgency>(
    selectedCreativeAgencies,
    creativeAgencies
  );
  const holdingCompaniesPayload = getPropertyPayloadById<IHoldingCompany>(
    selectedHoldingCompanies,
    holdingCompanies
  );
  const statusesPayload = getPropertyPayloadByValue<ProjectStatus>(
    selectedStatus,
    statuses
  );
  const projectTypesPayload = getPropertyPayloadByValue<string>(
    selectedProjectTypes,
    projectTypes
  );
  const musicTypesPayload = getPropertyPayloadByValue<MusicTypes>(
    selectedMusicTypes,
    musicTypes
  );
  const serviceTypesPayload = getPropertyPayloadByValue<ServicesTypes>(
    selectedServiceTypes,
    serviceTypes
  );
  const leadsPayload = getPropertyPayloadById<IProjectLead>(
    selectedLeads,
    leads
  );
  const contactsPayload = getPropertyPayloadByEmail<IContact>(
    selectedContacts,
    contacts
  );
  const projectRegionsPayload = getPropertyPayloadByValue<string>(
    selectedProjectRegions,
    projectRegions
  );

  const officeLocationPayload = getPropertyPayloadByValue<string>(
    selectedOfficeLocation,
    officeLocations
  );

  const getPropertyPayloadBySingleValue = (selected: ISelectLabel) => {
    if (selected.value === "") {
      return null;
    }

    return selected.value === "yes";
  };

  return {
    clients: getArrayValueOrNull(clientsPayload),
    globalBrands: getArrayValueOrNull(globalBrandsPayload),
    creativeAgencies: getArrayValueOrNull(creativeAgenciesPayload),
    holdingCompanies: getArrayValueOrNull(holdingCompaniesPayload),
    statuses: getArrayValueOrNull(statusesPayload),
    projectTypes: getArrayValueOrNull(projectTypesPayload),
    musicTypes: getArrayValueOrNull(musicTypesPayload),
    serviceTypes: getArrayValueOrNull(serviceTypesPayload),
    leads: getArrayValueOrNull(leadsPayload),
    contacts: getArrayValueOrNull(contactsPayload),
    projectCloseDateFrom: selectedProjectCloseDate[0],
    projectCloseDateTo: selectedProjectCloseDate[1],
    jobListingDateFrom: selectedJobListingDate[0],
    jobListingDateTo: selectedJobListingDate[1],
    projectRegions: getArrayValueOrNull(projectRegionsPayload),
    officeLocations: getArrayValueOrNull(officeLocationPayload),
    copyrightInfoProcessed: getPropertyPayloadBySingleValue(
      selectedCopyrightInfoProcessed
    ),
    publishingRightsSigned: getPropertyPayloadBySingleValue(
      selectedPublishingRightsSigned
    ),
    publishingTriggered: getPropertyPayloadBySingleValue(
      selectedPublishingTriggered
    ),
  };
};

export default parseJSONToLabel;
