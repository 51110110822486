import React, { ReactElement, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { debounce } from "lodash";
import { Form, Input, Checkbox, Button, notification, Typography } from "antd";
import { IOrganisation } from "src/interfaces/organisation";
import { convertDataToFields } from "@songtradr/spa-common/lib/utils";
import FloatingLabel from "src/components/floating-label";
import { BlockedEmailProviders } from "src/constants";
import { RuleObject } from "antd/lib/form";
import config from "../../../../config";
import ValidationError from "../../../../components/validation-error";
import { log, DataDogLogTypes } from "../../../../utils/data-dog";
import styles from "./styles";
import OrganisationTypeSelect from "../organisation-type-select";

interface IProps {
  data: IOrganisation;
  onChange: (updatedData: IOrganisation) => void;
  onSubmit: () => Promise<void>;
  isSavePending: boolean;
}

const FIELDS = {
  organisationName: ["organisationName"],
  organisationType: ["organisationType"],
  requesterRole: ["requesterRole"],
  requesterEmailAddress: ["requesterEmailAddress"],
  requesterPhoneNumber: ["requesterPhoneNumber"],
  hasAcceptedTermsAndConditions: ["hasAcceptedTermsAndConditions"],
};

const RegistrationForm = ({
  data,
  onChange,
  onSubmit,
  isSavePending,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const fields = convertDataToFields(FIELDS, data);
  const [organisationEmail, setOrganisationEmail] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [organisationType, setOrganisationType] = useState("");

  const onValuesChange = debounce((changed, updatedFields) => {
    onChange(updatedFields);
  }, 200);

  const onFormSubmit = async () => {
    try {
      await onSubmit();
    } catch (error) {
      /* TODO: Redirect to "Looks like this organisation already exists" screen on 409 error (AP-127) */
      log(DataDogLogTypes.ERROR, "Sign up user error", error);
      notification.error({
        message: t("Error Signing Up"),
        description: t(
          "An error has ocurred when signing you up. Please try again."
        ),
        duration: 0,
      });
    }
  };

  return (
    <React.Fragment>
      <Typography.Title css={styles.title} level={3}>
        {t("Create your account")}
      </Typography.Title>
      <Form
        layout="vertical"
        fields={fields}
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFormSubmit}
      >
        <FloatingLabel
          label={t("Organisation email")}
          name={FIELDS.requesterEmailAddress[0]}
          value={organisationEmail}
        >
          <Form.Item
            validateTrigger={["onBlur"]}
            name={FIELDS.requesterEmailAddress}
            css={styles.input}
            rules={[
              {
                validator: (rule: RuleObject, value: string) => {
                  try {
                    if (!value) {
                      throw new Error(
                        t(
                          "registration##validation##Please provide a contact email address"
                        )
                      );
                    }
                    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!emailRegex.test(value)) {
                      throw new Error(
                        t(
                          "registration##validation##Please provide a valid contact email address"
                        )
                      );
                    }
                    if (
                      value.includes("@") &&
                      BlockedEmailProviders.includes(value.split("@")[1])
                    ) {
                      throw new Error(
                        t(
                          "registration##validation##This looks like a personal email, please enter your organisation email."
                        )
                      );
                    }
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject(error);
                  }
                },
              },
            ]}
          >
            <Input
              type="email"
              value={organisationEmail}
              onBlur={(e) => setOrganisationEmail(e.target.value)}
            />
          </Form.Item>
        </FloatingLabel>

        <FloatingLabel
          label={t("Organisation name")}
          name={FIELDS.organisationName[0]}
          value={organisationName}
        >
          <Form.Item
            name={FIELDS.organisationName}
            css={styles.input}
            rules={[
              {
                required: true,
                whitespace: true,
                message: (
                  <ValidationError>
                    {t(
                      "registration##validation##Please provide your organisation name"
                    )}
                  </ValidationError>
                ),
              },
              {
                max: 80,
                message: (
                  <ValidationError>
                    {t(
                      "registration##validation##Organisation name exceeds limit",
                      {
                        characterLimit: 80,
                      }
                    )}
                  </ValidationError>
                ),
              },
            ]}
          >
            <Input
              value={organisationName}
              onBlur={(e) => setOrganisationName(e.target.value)}
            />
          </Form.Item>
        </FloatingLabel>

        <FloatingLabel
          label={t("manageTeam##members##Phone")}
          name={FIELDS.requesterPhoneNumber[0]}
          value={phoneNumber}
        >
          <Form.Item name={FIELDS.requesterPhoneNumber} css={styles.input}>
            <Input
              value={phoneNumber}
              onBlur={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
        </FloatingLabel>

        <FloatingLabel
          label={t("registration##What best describes your organisation?")}
          name={FIELDS.organisationType[0]}
          value={organisationType}
        >
          <Form.Item
            name={FIELDS.organisationType}
            css={styles.input}
            rules={[
              {
                required: true,
                whitespace: true,
                message: (
                  <ValidationError>
                    {t(
                      "registration##validation##Please provide the type that best describes your organisation"
                    )}
                  </ValidationError>
                ),
              },
            ]}
          >
            <OrganisationTypeSelect
              onChange={(value) => setOrganisationType(value)}
            />
          </Form.Item>
        </FloatingLabel>

        <Form.Item
          name={FIELDS.hasAcceptedTermsAndConditions}
          valuePropName="checked"
          rules={[
            {
              required: true,
              transform: (value: boolean): boolean | undefined =>
                value || undefined,
              type: "boolean",
              message: (
                <ValidationError>
                  {t(
                    "registration##validation##Please agree to the terms of service"
                  )}
                </ValidationError>
              ),
            },
          ]}
        >
          <Checkbox>
            <Trans i18nKey="registration##terms of service">
              I have read and agree Sontradr Enterprise{" "}
              <a
                href={config.externalResources.termsOfService}
                target="_blank"
                rel="noopener noreferrer"
                css={styles.link}
              >
                terms of service
              </a>
            </Trans>
          </Checkbox>
        </Form.Item>

        <Form.Item css={styles.submit}>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isSavePending}
          >
            {t("Create account")}
          </Button>
        </Form.Item>
        <Typography.Paragraph css={styles.signInPrompt}>
          Already have an account?{" "}
          <a css={styles.link} href="/dashboard">
            Sign in.
          </a>
        </Typography.Paragraph>
      </Form>
    </React.Fragment>
  );
};

export default RegistrationForm;
