import axios from "axios";
import { IProjectEmails } from "src/pages/projects/project/interfaces";
import { ErrorToast } from "src/components/toast-notification";
import { getPublishingTeamEmailsUrl } from "../../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const getPublishingTeamEmails = async (
  accessToken: string,
  projectId: string,
  organisationId: string
): Promise<IProjectEmails> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  return axios
    .get(getPublishingTeamEmailsUrl(projectId, organisationId), options)
    .then(({ data }) => {
      return data as IProjectEmails;
    })
    .catch(() => {
      ErrorToast(
        "publishing-team-email-fetch--error",
        "There was a problem retrieving publishing team emails. Please try again."
      );
      return { publishingTeamEmails: [] } as IProjectEmails;
    });
};

export default getPublishingTeamEmails;
