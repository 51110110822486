import { getIsMobile } from "@songtradr/spa-common";
import {
  IGlobalBrand,
  ILabel,
  IParsedBrands,
  SelectVal,
  IProductDivision,
} from "../types";

export const parseProductDivisions = (
  productDivisions: IProductDivision[]
): ILabel[] => {
  return productDivisions?.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });
};

export const parseGlobalBrands = (
  globalBrands: IGlobalBrand[]
): IParsedBrands[] => {
  return globalBrands?.map((x) => {
    const regionalBrands = x.regionalBrands.map((y) => {
      return {
        label: y.brandName,
        value: y.id,
      };
    });

    return {
      label: x.brandName,
      value: x.id,
      regionalBrands,
    };
  });
};

export const parseJSONToLabel = (data: {
  id: number;
  name: string;
}): SelectVal => {
  return {
    label: data.name,
    value: data.id,
  };
};

interface IColumnConfig {
  globalBrand: number;
  regionalBrand: number;
  productDivison: number;
  product: number;
}

export const columnConfig = (): IColumnConfig => {
  const isMobile = getIsMobile();

  if (isMobile) {
    return {
      globalBrand: 24,
      regionalBrand: 24,
      productDivison: 24,
      product: 24,
    };
  }

  return {
    globalBrand: 6,
    regionalBrand: 6,
    productDivison: 6,
    product: 6,
  };
};
