import { LabeledValue } from "antd/lib/select";
import { ReactElement } from "react";
import { ActionMeta } from "react-select";
import {
  Contributor,
  IProjectDetailsData,
} from "src/pages/projects/project/interfaces";
import { IUserDropdownProps } from "src/pages/team/interfaces";
import style from "../../../../styles";
import ClientReferenceAndOfficeDetails from "../client-reference-and-office-details";
import { IOption } from "../creative-agency-row";
import ProjectAndCampaignNameWithDealIdRow from "../project-and-campaign-name-with-deal-id-row/ProjectAndCampaignNameWithDealIdRow";
import ClientDetailsAndProjectLeadsRow, {
  IParseLeadOptions,
} from "../client-details-and-project-leads-row/ClientDetailsAndProjectLeadsRow";

interface IProps {
  projectDetailsData: IProjectDetailsData;
  projectRegionOptions: {
    value: string;
    label: string;
  }[];
  client?: {
    label?: string;
    value?: string;
  };
  clientOptions: IOption[];
  contact?: IOption;
  contactOptions: IOption[];
  parsedLeadOptions: IParseLeadOptions[];
  isRegionsRequiredField: boolean;
  onUpdateProjectDetailsData: (projectDetailsData: IProjectDetailsData) => void;
  onRegionChange: (data: LabeledValue) => void;
  onUpdate: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;

  onLeadMultiSelectChange: (
    contributor: Contributor,
    action: ActionMeta<IUserDropdownProps>
  ) => void;
  onContactDropdownChange: (field: string, data: IOption) => void;
  onClientDropdownChange: (field: string, data: IOption) => void;
}
const ProjectOverviewDetailsSection = ({
  projectDetailsData,
  projectRegionOptions,
  client,
  clientOptions,
  contact,
  contactOptions,
  parsedLeadOptions,
  isRegionsRequiredField,
  onUpdateProjectDetailsData,
  onRegionChange,
  onUpdate,
  onLeadMultiSelectChange,
  onContactDropdownChange,
  onClientDropdownChange,
}: IProps): ReactElement => {
  return (
    <div css={style.sectionContainer}>
      <ClientDetailsAndProjectLeadsRow
        contact={contact}
        contactOptions={contactOptions}
        client={client}
        clientOptions={clientOptions}
        parsedLeadOptions={parsedLeadOptions}
        onLeadMultiSelectChange={onLeadMultiSelectChange}
        onContactDropdownChange={onContactDropdownChange}
        onClientDropdownChange={onClientDropdownChange}
      />
      <ProjectAndCampaignNameWithDealIdRow
        projectDetailsData={projectDetailsData}
        onUpdate={onUpdate}
      />
      <ClientReferenceAndOfficeDetails
        projectRegionOptions={projectRegionOptions}
        projectDetailsData={projectDetailsData}
        onUpdateProjectDetailsData={onUpdateProjectDetailsData}
        onRegionChange={onRegionChange}
        onUpdate={onUpdate}
        isRegionsRequiredField={isRegionsRequiredField}
        onLeadMultiSelectChange={onLeadMultiSelectChange}
        parsedLeadOptions={parsedLeadOptions}
      />
    </div>
  );
};

export default ProjectOverviewDetailsSection;
