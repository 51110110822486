import axios from "axios";
import { getAuthHeader, getAxiosOptions } from "src/api/headers";
import { eApplicationType } from "src/interfaces/auth";
import { IOrganisationUrlParams } from "src/interfaces/organisation-url-params";
import getJsonContentHeader from "../../headers/get-json-content-header";
import { resendOrganisationInviteUrl } from "../../organisation-invites-url-helper";

async function resendOrganisationInvite(
  accessToken: string,
  request: IOrganisationUrlParams
): Promise<void> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.post(
    resendOrganisationInviteUrl(request.organisationId),
    {
      applicationType: eApplicationType.ProjectsInternal,
      inviteId: request.organisationInviteId,
    },
    options
  );
}

export default resendOrganisationInvite;
