import axios from "axios";
import { getProductInformationUrl } from "src/api/product-information-helper";
import { IProductInfoResponse } from "src/pages/projects/project/components/product-information/types";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../headers";

const getProductInformation = async (
  accessToken: string,
  orgId: string
): Promise<IProductInfoResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios
    .get(getProductInformationUrl(orgId), options)
    .then(({ data }) => data as IProductInfoResponse);
};

export default getProductInformation;
