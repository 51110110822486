import React, { useCallback } from "react";
import SortButton, {
  sortTypes,
} from "src/components/control-buttons/sort-button";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import { useTranslation } from "react-i18next";
import theme from "src/theme";
import ViewColumnsIcon from "src/app/assets/icons/view-columns";
import { AllProjectColumns } from "src/components/modals/view-columns-modal";
import mapColumnsInCheckboxLabels from "src/components/modals/view-columns-modal/utils";
import { ISavedUserColumn } from "src/api/user-filters/interfaces";
import style from "../styles";
import { IProjectTableSort, ProjectSortType, SortOrder } from "../interfaces";

export default (
  isTablet: boolean,
  handleSort: (params: IProjectTableSort) => void,
  sortOptions: IProjectTableSort,
  columns: ISavedUserColumn[] | null,
  handleSetShowColumnsModal: (visibility: boolean) => void
): Array<IColumnsData> => {
  const { t } = useTranslation();

  const sortByColumn = useCallback(
    (column: ProjectSortType) => {
      handleSort({
        sortBy: column,
        order:
          sortOptions.sortBy === column && sortOptions.order === SortOrder.ASC
            ? SortOrder.DESC
            : SortOrder.ASC,
      });
    },
    [handleSort, sortOptions.order, sortOptions.sortBy]
  );

  const getColumnsBySelectedColumnsOrder = useCallback((): IColumnsData[] => {
    const tableColumns: { [columnKey: string]: IColumnsData } = {
      [AllProjectColumns.ProjectName]: {
        title: (
          <div css={style.titleContainer}>
            <span>
              {isTablet ? t("Project") : t("ProjectsPage##Project name")}
            </span>
            {!isTablet && (
              <SortButton
                tooltipText={
                  sortOptions.sortBy !== ProjectSortType.name ||
                  (sortOptions.sortBy === ProjectSortType.name &&
                    sortOptions.order === SortOrder.ASC)
                    ? t("Sort A-Z")
                    : t("Sort Z-A")
                }
                css={style.sortButton}
                dataTestId="project-name-sort-button"
                sortType={
                  sortOptions.sortBy !== ProjectSortType.name ||
                  (sortOptions.sortBy === ProjectSortType.name &&
                    sortOptions.order === SortOrder.ASC)
                    ? sortTypes.A_Z
                    : sortTypes.Z_A
                }
                onClick={() => sortByColumn(ProjectSortType.name)}
                fillColor={
                  sortOptions.sortBy === ProjectSortType.name
                    ? undefined
                    : theme.colors.background.lightGray
                }
              />
            )}
          </div>
        ),
        dataIndex: "name",
        key: "projectName",
        width: 150,
      },
      [AllProjectColumns.CampaignName]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Campaign name")}</span>
          </div>
        ),
        dataIndex: "campaignName",
        key: "projectCampaignName",
        width: 150,
      },
      [AllProjectColumns.ClientName]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Client name")}</span>
          </div>
        ),
        dataIndex: "projectId",
        key: "projectId",
        width: 150,
      },
      [AllProjectColumns.ProjectType]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Project type")}</span>
          </div>
        ),
        dataIndex: "projectType",
        key: "projectType",
        width: 150,
      },
      [AllProjectColumns.ProjectLead]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Project lead")}</span>
          </div>
        ),
        dataIndex: "leads",
        key: "projectLeads",
        width: 150,
      },
      [AllProjectColumns.ProjectStatus]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Project status")}</span>
            {!isTablet && (
              <SortButton
                tooltipText={
                  sortOptions.sortBy === ProjectSortType.status &&
                  sortOptions.order === SortOrder.DESC
                    ? t("Sort Z-A")
                    : t("Sort A-Z")
                }
                css={style.sortButton}
                dataTestId="project-status-sort-button"
                sortType={
                  sortOptions.sortBy === ProjectSortType.status &&
                  sortOptions.order === SortOrder.DESC
                    ? sortTypes.Z_A
                    : sortTypes.A_Z
                }
                onClick={() => sortByColumn(ProjectSortType.status)}
                fillColor={
                  sortOptions.sortBy === ProjectSortType.status
                    ? undefined
                    : theme.colors.background.lightGray
                }
              />
            )}
          </div>
        ),
        dataIndex: "status",
        key: "projectStatus",
        width: 200,
      },
      [AllProjectColumns.ProjectRegion]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Project region")}</span>
          </div>
        ),
        dataIndex: "projectRegion",
        key: "projectRegion",
        width: 150,
      },
      [AllProjectColumns.FinalTrackName]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##FinalTrackName")}</span>
          </div>
        ),
        dataIndex: "finalTrackName",
        key: "finalTrackName",
        width: 150,
      },
      [AllProjectColumns.FinalTrackArtist]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##FinalTrackArtist")}</span>
          </div>
        ),
        dataIndex: "finalTrackArtist",
        key: "finalTrackArtist",
        width: 150,
      },
      [AllProjectColumns.Territory]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Territory")}</span>
          </div>
        ),
        dataIndex: "territory",
        key: "territory",
        width: 150,
      },
      [AllProjectColumns.Term]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Term")}</span>
          </div>
        ),
        dataIndex: "term",
        key: "term",
        width: 150,
      },
      [AllProjectColumns.StartDate]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##StartDate")}</span>
          </div>
        ),
        dataIndex: "startDate",
        key: "startDate",
        width: 150,
      },
      [AllProjectColumns.EndDate]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##EndDate")}</span>
            {!isTablet && (
              <SortButton
                tooltipText={
                  sortOptions.sortBy === ProjectSortType.termEndDate &&
                  sortOptions.order === SortOrder.DESC
                    ? t("Sort descending")
                    : t("Sort ascending")
                }
                css={style.sortButton}
                dataTestId="term-end-date-sort-button"
                sortType={
                  sortOptions.sortBy === ProjectSortType.termEndDate &&
                  sortOptions.order === SortOrder.DESC
                    ? sortTypes.Z_A
                    : sortTypes.A_Z
                }
                onClick={() => sortByColumn(ProjectSortType.termEndDate)}
                fillColor={
                  sortOptions.sortBy === ProjectSortType.termEndDate
                    ? undefined
                    : theme.colors.background.lightGray
                }
              />
            )}
          </div>
        ),
        dataIndex: "endDate",
        key: "endDate",
        width: 150,
      },
      [AllProjectColumns.MasterFee]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##MasterFee")}</span>
          </div>
        ),
        dataIndex: "masterFee",
        key: "masterFee",
        width: 150,
      },
      [AllProjectColumns.PublishingFee]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##PublishingFee")}</span>
          </div>
        ),
        dataIndex: "publishingFee",
        key: "publishingFee",
        width: 150,
      },
      [AllProjectColumns.PublishingNotified]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##PublishingNotified")}</span>
          </div>
        ),
        dataIndex: "publishingNotified",
        key: "publishingNotified",
        width: 150,
      },
      [AllProjectColumns.CreatedDate]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##CreatedDate")}</span>
            {!isTablet && (
              <SortButton
                tooltipText={
                  sortOptions.sortBy === ProjectSortType.created &&
                  sortOptions.order === SortOrder.DESC
                    ? t("Sort descending")
                    : t("Sort ascending")
                }
                css={style.sortButton}
                sortType={
                  sortOptions?.sortBy === ProjectSortType.created &&
                  sortOptions.order === SortOrder.DESC
                    ? sortTypes.Z_A
                    : sortTypes.A_Z
                }
                onClick={() => sortByColumn(ProjectSortType.created)}
                fillColor={
                  sortOptions.sortBy === ProjectSortType.created
                    ? undefined
                    : theme.colors.background.lightGray
                }
              />
            )}
          </div>
        ),
        dataIndex: "created",
        key: "created",
        width: 150,
      },
      [AllProjectColumns.ProjectListedDate]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Project listed date")}</span>
            {!isTablet && (
              <SortButton
                tooltipText={
                  sortOptions.sortBy === ProjectSortType.jobListingDate &&
                  sortOptions.order === SortOrder.DESC
                    ? t("Sort descending")
                    : t("Sort ascending")
                }
                css={style.sortButton}
                sortType={
                  sortOptions?.sortBy === ProjectSortType.jobListingDate &&
                  sortOptions.order === SortOrder.DESC
                    ? sortTypes.Z_A
                    : sortTypes.A_Z
                }
                onClick={() => sortByColumn(ProjectSortType.jobListingDate)}
                fillColor={
                  sortOptions.sortBy === ProjectSortType.jobListingDate
                    ? undefined
                    : theme.colors.background.lightGray
                }
              />
            )}
          </div>
        ),
        dataIndex: "jobListingDate",
        key: "jobListingDate",
        width: 200,
      },
      [AllProjectColumns.LastUpdated]: {
        title: (
          <div css={style.titleContainer}>
            <span>{t("ProjectsPage##Last updated")}</span>
            {!isTablet && (
              <SortButton
                tooltipText={
                  sortOptions.sortBy === ProjectSortType.lastUpdated &&
                  sortOptions.order === SortOrder.DESC
                    ? t("Sort descending")
                    : t("Sort ascending")
                }
                css={style.sortButton}
                dataTestId="estimated-close-sort-button"
                sortType={
                  sortOptions?.sortBy === ProjectSortType.lastUpdated &&
                  sortOptions.order === SortOrder.DESC
                    ? sortTypes.Z_A
                    : sortTypes.A_Z
                }
                onClick={() => sortByColumn(ProjectSortType.lastUpdated)}
                fillColor={
                  sortOptions.sortBy === ProjectSortType.lastUpdated
                    ? undefined
                    : theme.colors.background.lightGray
                }
              />
            )}
          </div>
        ),
        dataIndex: "lastUpdated",
        key: "projectLastUpdated",
        width: 150,
      },
    };

    return mapColumnsInCheckboxLabels(columns, Object.values(AllProjectColumns))
      .filter((columnData) => columnData.enabled)
      .map((columnData) => tableColumns?.[columnData.name] ?? null)
      .filter(
        (columnData): columnData is NonNullable<IColumnsData> =>
          columnData !== null
      );
  }, [
    columns,
    isTablet,
    sortByColumn,
    sortOptions.order,
    sortOptions.sortBy,
    t,
  ]);

  return [
    ...getColumnsBySelectedColumnsOrder(),
    {
      title: (
        <div css={[style.titleContainer, style.columnContainer]}>
          <div
            role="button"
            css={style.viewColumnsContainer}
            onClick={() => handleSetShowColumnsModal(true)}
            tabIndex={0}
            onKeyDown={(e) => {
              e.persist();
            }}
          >
            <ViewColumnsIcon />
          </div>
        </div>
      ),
      dataIndex: "edit",
      key: "projectEdit",
      width: 50,
      fixed: "right",
    },
  ];
};
