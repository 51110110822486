import axios from "axios";
import {
  IProjectCreateRequest,
  IProjectCreateResponse,
} from "src/pages/projects/project/interfaces";
import { createProjectUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const createProject = async (
  accessToken: string,
  createRequest: IProjectCreateRequest
): Promise<IProjectCreateResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const response = await axios.post(createProjectUrl(), createRequest, options);
  return response.data as IProjectCreateResponse;
};

export default createProject;
