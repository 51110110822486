import { Typography } from "antd";
import React from "react";
import styles from "./styles";

interface IProps {
  title: string;
}

const PageHeader = ({ title }: IProps): React.ReactElement => {
  return (
    <div css={styles.stickyHeader}>
      <Typography.Title css={styles.typographyTitle}>{title}</Typography.Title>
    </div>
  );
};

export default PageHeader;
