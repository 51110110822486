import { css } from "@emotion/react";
import theme from "src/theme";
import { StylesConfig } from "react-select";
import { IOption } from "src/pages/projects/project/components/account-information/types";

export const customStyles: StylesConfig<IOption> = {
  menu: (provided) => ({
    ...provided,
    minHeight: 56,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 56,
    maxHeight: 56,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
};
export default {
  drawerContainer: css`
    .ant-drawer-header {
      background-color: ${theme.colors.background.white};
      border-bottom: 1px solid #f0f0f0;
      border-radius: 0;
      .ant-drawer-title {
        font-size: 24px !important;
      }
    }
    .ant-drawer-body {
      background-color: ${theme.colors.background.white};
      padding-top: 1rem;
      padding-bottom: 5rem;
    }
    .ant-form {
      > #InfoText {
        font-size: 16px;
        margin: 24px 0;
        a {
          color: ${theme.colors.black};
          text-decoration-line: underline;
        }
      }
      > #PersonalDetails,
      > #PublishingInformation,
      > #AAndR,
      > #NotesAndDocuments {
        padding-bottom: 1rem;
        font-weight: 800;
        > div {
          padding-top: 0.5rem;
        }
      }
    }
  `,
  createGroupFooter: css`
    position: fixed;
    bottom: 0;
    display: flex;
    padding: 1rem 0;
    margin-left: -1.5rem;
    margin-right: 1.5rem;
    background-color: ${theme.colors.background.white};
    border-top: 1px solid #f0f0f0;
    width: -webkit-fill-available;
    div {
      display: flex;
      flex: 1;
      button {
        display: flex;
        align-items: center;
        -webkit-box-shadow: none;
        justify-content: flex-end;
        min-height: 2.6rem;
      }
    }
  `,
  errorMessage: css`
    padding-left: 1.5rem;
    color: ${theme.colors.functional.red} !important;
  `,
  closeButton: css`
    display: flex;
    justify-content: flex-start !important;
    border: none;
    font-size: 16px;
    font-weight: 600;
    background: none !important;
    border-radius: 4px;
    :hover {
      border: none;
      background: none;
    }
    :focus {
      border: none;
      background: none;
    }
    &:disabled {
      border: 2px solid ${theme.colors.secondary.fadeToGrey} !important;
    }
    margin-right: 1rem;
    align-self: end;
  `,
  createButton: css`
    border: 2px solid ${theme.colors.primary.blue};
    background: ${theme.colors.primary.blue};
    font-size: 16px;
    font-weight: 600;
    color: white;
    border-radius: 4px;
    :hover {
      background: ${theme.colors.secondary.navyBlue};
      border: 2px solid ${theme.colors.secondary.navyBlue};
      color: ${theme.colors.white};
    }
    :focus {
      background: ${theme.colors.secondary.navyBlue};
      border: 3px solid ${theme.colors.secondary.navyBlue};
      color: ${theme.colors.white};
    }
    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active {
      background-color: ${theme.colors.background.lightGray} !important;
      border-color: ${theme.colors.background.lightGray};
    }
    &:disabled {
      border: 0 !important;
    }
    align-self: flex-end;
  `,
  switchContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  basicInputContainer: css`
    margin-bottom: 0.5rem;
  `,
  basicInputLabel: css`
    font-size: 0.875rem;
    line-height: 1.55rem;
    font-weight: 400;
  `,
  basicLabelAdditionalStyles: css`
    margin-right: 10px;
    line-height: 32px;
  `,
  formItem: css`
    margin-bottom: 0 !important;
  `,
  additionalFormItemStyle: css`
    .ant-form-item-control-input-content {
      display: flex !important;
      justify-content: flex-start !important;
    }
  `,
  disclaimerLabel: css`
    padding-top: 1rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    align-items: center;
    display: flex;
    svg {
      margin-right: 0.5rem;
    }
  `,
  formStyle: css`
    font-size: 16px;
  `,
  saving: css`
    position: absolute;
    top: 0.8rem;
    margin-left: 1rem;
    font-size: 0.875rem;
    color: ${theme.colors.primary.blue};
  `,
  disabledContainer: css`
    button {
      &:disabled {
        background: ${theme.colors.secondary.fadeToGrey} !important;
        color: #888 !important;
        box-shadow: none !important;
      }
    }
  `,
};
