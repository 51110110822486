import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  defaultStyles: css`
    display: flex;
    font-size: 1rem;
    align-items: center;
    height: 100%;
    border-radius: 0;
    padding: 0;
    background-color: ${theme.colors.white} !important;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: bold;
    border: none;
    letter-spacing: 0;
    line-height: 1.25rem;
    color: ${theme.colors.primary.blue};
    cursor: pointer;
    box-shadow: none;

    :hover {
      color: ${theme.colors.secondary.darkBlue} !important;
    }

    :focus {
      outline-style: unset;
    }

    :first-of-type {
      @media ${queries.medium} {
        border-right: 0;
        margin-left: 12.1875rem;
        margin-right: 0.5rem;
      }
    }
  `,
};
