import React, { useCallback, useMemo } from "react";
import { Tabs } from "antd";
import ArrowRight from "src/app/assets/icons/component-icons/arrow_right";
import Mark from "src/app/assets/icons/component-icons/mark";
import { getIsMobile } from "@songtradr/spa-common";
import { ITab, TabsTitles } from "../../interfaces";
import styles from "../../styles";

interface IProps {
  tabs: ITab[];
  activeTab?: ITab;
  disabledTabsForClick: boolean;
  setActiveTab?: (activeTab: ITab) => void;
}

const ContactPageTabs = ({
  tabs,
  activeTab,
  disabledTabsForClick,
  setActiveTab,
}: IProps): React.ReactElement => {
  const isMobile = getIsMobile();
  const handleTabChange = useCallback(
    (tabTitle: string) => {
      const tab = tabs.find((tabData) => tabData.tabTitle === tabTitle);
      const hasCurrentTabValidData = activeTab?.isDataValid ?? true;
      if (tab && hasCurrentTabValidData) {
        setActiveTab?.(tab);
      }
    },
    [activeTab?.isDataValid, setActiveTab, tabs]
  );

  const activeTabIndex = useMemo(
    () => tabs.findIndex((tab) => tab.tabTitle === activeTab?.tabTitle),
    [activeTab, tabs]
  );

  const isFinishedTab = useMemo(() => activeTabIndex === tabs.length - 1, [
    activeTabIndex,
    tabs.length,
  ]);
  const tabsItems = useMemo(() => {
    return tabs.map(({ tabTitle, content }, index) => {
      const isFirstTab = index === 0;
      const isActiveCurrentTab =
        (activeTab?.tabTitle ?? TabsTitles.PersonalDetails) === tabTitle;
      const showMarkIcon = activeTabIndex > index && index !== 0;
      const isLastTab = index === tabs.length - 1;
      return {
        disabled:
          isFirstTab || disabledTabsForClick || isLastTab || isFinishedTab,
        label: (
          <div css={[styles.activeTab, isFirstTab && styles.tabsTitle]}>
            {!isLastTab && (
              <>
                <div>{tabTitle}</div>
                {isActiveCurrentTab && <ArrowRight />}
                {(disabledTabsForClick || isFinishedTab) && showMarkIcon && (
                  <Mark />
                )}
              </>
            )}
          </div>
        ),
        key: tabTitle,
        children: <div css={styles.contentContainer}>{content}</div>,
      };
    });
  }, [
    activeTab?.tabTitle,
    activeTabIndex,
    disabledTabsForClick,
    isFinishedTab,
    tabs,
  ]);

  return (
    <div id="ContactTabs" css={[styles.contactTabsHeader]}>
      <Tabs
        defaultActiveKey={TabsTitles.PersonalDetails}
        activeKey={activeTab?.tabTitle}
        onChange={handleTabChange}
        destroyInactiveTabPane
        tabPosition={isMobile ? "top" : "left"}
        css={styles.tabsStyles}
        data-testid="contact-tabs"
        items={tabsItems}
      />
    </div>
  );
};

export default ContactPageTabs;
