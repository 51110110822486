import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Input, Typography } from "antd";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import mainStyle from "src/pages/projects/project/styles";
import useDebounce from "src/utils/useDebounce";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { LibraryTrackPageSize } from "src/constants";
import csvDownload from "json-to-csv-export";
import Button from "src/components/button";
import {
  ILibraryTrackDetailsChannel,
  IProjectLibraryTrackDetails,
} from "../../interfaces";
import style from "./styles";
import LibraryTrackTable from "./library-track-table";
import {
  ILibraryTrackTableSort,
  LibraryTrackSortType,
  SortOrder,
} from "./interfaces";
import getExportDataSource from "./table-data/get-export-data-source";

interface IProps {
  isLoading: boolean;
  libraryTracks: IProjectLibraryTrackDetails[];
}

const LibraryTrackDetails = ({
  isLoading,
  libraryTracks,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [libraryTracksCurrentPage, setLibraryTrackCurrentPage] = useState(1);
  const [libraryTrackSearchText, setLibraryTrackSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(libraryTrackSearchText, 250);
  const [libraryTracksSortOption, setLibraryTracksSortOption] = useState({
    sortBy: LibraryTrackSortType.createdDate,
    order: SortOrder.DESC,
  });

  const handleLibraryTrackPageChange = useCallback((page: number) => {
    setLibraryTrackCurrentPage(page);
  }, []);

  const handleLibraryTrackUpdateSearchText = useCallback((text: string) => {
    setLibraryTrackSearchText(text);
  }, []);

  const handleLibraryTrackSortChange = useCallback(
    (sort: ILibraryTrackTableSort) => {
      setLibraryTracksSortOption(sort);
    },
    []
  );

  const projectLibraryTracks = useMemo(() => {
    let searchedTracks = cloneDeep(libraryTracks);

    let searchText =
      libraryTrackSearchText === debouncedSearchTerm
        ? libraryTrackSearchText
        : debouncedSearchTerm;
    searchText = debouncedSearchTerm.toLocaleLowerCase();
    const isStringIncludedInText = (text: string) => {
      return (text ?? "").toLocaleLowerCase().includes(searchText);
    };
    const isStringIncludedInChannelsArray = (
      channels: ILibraryTrackDetailsChannel[],
      prop: "type" | "name" | "id"
    ) => {
      return (channels ?? [])
        .map((channel) => channel[prop])
        .filter((channelType) => channelType)
        .join(", ")
        .toLocaleLowerCase()
        .includes(searchText);
    };

    if (searchText !== "") {
      searchedTracks = libraryTracks.filter((track) => {
        return (
          isStringIncludedInText(track.trackId) ||
          isStringIncludedInText(track.trackTitle) ||
          isStringIncludedInText(track.trackArtist) ||
          isStringIncludedInText(track.downloadedByUser.email) ||
          isStringIncludedInText(track.downloadedByUser.name) ||
          isStringIncludedInText(track.declaredByUser.email) ||
          isStringIncludedInText(track.declaredByUser.name) ||
          isStringIncludedInText(track.projectName) ||
          isStringIncludedInText(track.projectDescription) ||
          isStringIncludedInText(track.businessBrand) ||
          isStringIncludedInText(track.regions.join(", ")) ||
          isStringIncludedInChannelsArray(track.channels, "type") ||
          isStringIncludedInChannelsArray(track.channels, "name") ||
          isStringIncludedInChannelsArray(track.channels, "id") ||
          isStringIncludedInText(
            ((track.channels ?? [])?.[0]?.usageLinks ?? "").join(", ")
          )
        );
      });
    }
    const { sortBy, order } = libraryTracksSortOption;

    if (sortBy === LibraryTrackSortType.declaredBy) {
      return searchedTracks.sort((a, b) => {
        return order === SortOrder.ASC
          ? (a.declaredByUser.name ?? "").localeCompare(
              b.declaredByUser.name ?? ""
            )
          : (b.declaredByUser.name ?? "").localeCompare(
              a.declaredByUser.name ?? ""
            );
      });
    }
    if (sortBy === LibraryTrackSortType.downloadedBy) {
      return searchedTracks.sort((a, b) => {
        return order === SortOrder.ASC
          ? (a.downloadedByUser.name ?? "").localeCompare(
              b.downloadedByUser.name ?? ""
            )
          : (b.downloadedByUser.name ?? "").localeCompare(
              a.downloadedByUser.name ?? ""
            );
      });
    }

    const sortProp:
      | "trackId"
      | "trackTitle"
      | "trackArtist"
      | "createdDate"
      | "projectName"
      | "projectDescription"
      | "businessBrand"
      | "goLiveDate" = sortBy || "createdDate";

    return searchedTracks.sort((a, b) => {
      return order === SortOrder.ASC
        ? (a[sortProp] ?? "").localeCompare(b[sortProp] ?? "")
        : (b[sortProp] ?? "").localeCompare(a[sortProp] ?? "");
    });
  }, [
    debouncedSearchTerm,
    libraryTrackSearchText,
    libraryTracks,
    libraryTracksSortOption,
  ]);

  useEffect(() => {
    if (
      Math.ceil(projectLibraryTracks.length / LibraryTrackPageSize) <
      libraryTracksCurrentPage
    ) {
      handleLibraryTrackPageChange(1);
    }
  }, [
    handleLibraryTrackPageChange,
    libraryTracksCurrentPage,
    projectLibraryTracks.length,
  ]);

  const dataHeaders = useMemo(() => {
    return [
      "Track ID",
      "Track Title",
      "Track Artist",
      "Downloaded by",
      "Declared by",
      "Declared Date",
      "Project Name",
      "Project Description",
      "Business Unit/Brand",
      "Territories",
      "Go live date",
      "Youtube Channel/s",
      "Facebook Page/s",
      "Instagram Page/s",
      "TikTok account/s",
      "X account/s",
      "Company website",
      "Other",
      "Video links",
    ];
  }, []);

  const handleExportAsCSV = useCallback(() => {
    const dataToConvert = {
      data: getExportDataSource(projectLibraryTracks),
      filename: `music_usage_declaration_${Date.now()}`,
      delimiter: ",",
      headers: dataHeaders,
    };

    return csvDownload(dataToConvert);
  }, [dataHeaders, projectLibraryTracks]);

  return (
    <div>
      <div css={mainStyle.titleLabelContainer}>
        <Typography.Text css={mainStyle.titleLabel}>
          {t("ProjectsPage##Library Tracks")}
        </Typography.Text>
      </div>
      <div>
        <Typography.Text css={mainStyle.basicInputLabel}>
          Search by keyword
        </Typography.Text>
      </div>
      <div css={style.actionsContainer}>
        <Input
          autoFocus
          css={style.input}
          value={libraryTrackSearchText}
          placeholder=""
          onChange={(e) => {
            handleLibraryTrackUpdateSearchText(e.target.value);
          }}
          addonAfter={
            <button
              css={style.iconButton}
              type="button"
              onClick={() => {
                handleLibraryTrackUpdateSearchText("");
              }}
            >
              <CloseIcon
                css={style.inputClear}
                fill={theme.colors.primary.blue}
                height={11}
                width={11}
                title="Close"
                role="button"
              />
            </button>
          }
        />
        <Button
          ariaLabel={t(`ProjectsPage##Export as CSV`)}
          css={style.exportButton}
          onClick={handleExportAsCSV}
        >
          {t(`ProjectsPage##Export as CSV`)}
        </Button>
      </div>
      <LibraryTrackTable
        isLoading={isLoading || libraryTrackSearchText !== debouncedSearchTerm}
        libraryTracks={projectLibraryTracks}
        paginationOptions={{
          total: projectLibraryTracks.length,
          currentPage: libraryTracksCurrentPage,
        }}
        handlePageChange={handleLibraryTrackPageChange}
        debouncedSearchTerm={debouncedSearchTerm}
        sortOptions={libraryTracksSortOption}
        handleSort={handleLibraryTrackSortChange}
      />
    </div>
  );
};

export default LibraryTrackDetails;
