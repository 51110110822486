import { Table } from "antd";
import React, { Fragment, ReactElement } from "react";
import { IAttachmentTableProps } from "./interfaces";
import styles from "./styles";

const FileUploadTable = ({
  tableColumns,
  tableData,
  showDragAndDrop,
  dragAndDropButton,
}: IAttachmentTableProps): ReactElement => {
  return (
    <Fragment>
      <Table
        onRow={(record) => ({
          onClick: (event) => {
            event.stopPropagation();
            if (record) {
              void record.handleRowDownloadClick?.();
            }
          },
          onDoubleClick: (event) => {
            event.stopPropagation();
          },
        })}
        css={styles.fileUploadTable}
        dataSource={tableData}
        columns={tableColumns}
        pagination={false}
        data-testid="file-upload-table"
        className="fileUploadTable"
      />
      <p css={styles.fileUploadText}>{showDragAndDrop && dragAndDropButton}</p>
    </Fragment>
  );
};

export default FileUploadTable;
