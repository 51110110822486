import axios, { CancelTokenSource } from "axios";
import { IOrganisationMemberResponse } from "src/interfaces/org-member-response";
import { IOrganisationMemberProps } from "src/pages/team/interfaces";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { getOrganisationUserUrl } from "../../organisation-members-url-helper";

let call: CancelTokenSource;

const responseToOrgMember = (
  response: IOrganisationMemberResponse
): IOrganisationMemberProps => {
  const { organisationId } = response;
  const isSuperAdmin = false; // This endpoint never returns the isSuperAdmin flag
  const {
    id,
    firstName,
    lastName,
    auth0UserId,
    email,
    phoneNumber,
    jobTitle,
    applications,
    companyName,
    country,
  } = response;

  return {
    isCurrentUser: false,
    id,
    key: id,
    organisationId,
    firstName,
    lastName,
    jobTitle,
    phoneNumber,
    auth0UserId,
    email,
    applications,
    isSuperAdmin,
    companyName,
    country,
  };
};

const getOrganisationMember = async (
  accessToken: string,
  orgId: string,
  currentUserId: string
): Promise<IOrganisationMemberProps> => {
  call = axios.CancelToken.source();
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    cancelToken: call.token,
  };

  const response: IOrganisationMemberResponse = await axios
    .get(getOrganisationUserUrl(orgId, currentUserId), options)
    .then(({ data }: { data: IOrganisationMemberResponse }) => data);

  return responseToOrgMember(response);
};

export default getOrganisationMember;
