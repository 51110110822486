import { IContactsFilters } from "src/pages/talent-hub/interfaces";

/* eslint-disable no-param-reassign */
export function insertSpaces(data = ""): string {
  data = data.replace(/([a-z])([A-Z])/g, "$1 $2");
  data = data.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
  return data;
}

export function getStringWithQuotesAroundForwardSlashes(text: string): string {
  const arrayOfStrings = text.split(" ");

  const arrayOfStringsWithQuotesAroundForwardSlashes = arrayOfStrings.map(
    (word) => (word.includes("/") ? `"${word}"` : word)
  );

  return arrayOfStringsWithQuotesAroundForwardSlashes.join(" ");
}

export const getConvertedArrayOfBooleansToStrings = (
  object: IContactsFilters
): string[] => {
  return ([] as string[]).concat(
    ...Object.values(object)
      .filter(Array.isArray)
      .map((arr: string[] | boolean[]) => arr.map(String) as string[])
  );
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export default insertSpaces;
