export const isEnterpriseLibraryProject = (projectType: string): boolean => {
  return projectType === "EnterpriseLibrary";
};
export const getCalculatedSplitFee = (
  totalFee: string,
  split: string
): string => {
  if (!split || !totalFee) {
    return "";
  }

  return String(
    (
      Math.round(
        (Number(totalFee) * (Number(split) / 100) + Number.EPSILON) * 100
      ) / 100
    ).toFixed(2)
  );
};
export default { isEnterpriseLibraryProject };
