import React, { ReactElement, useEffect } from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import useAuth from "src/auth/use-auth";

export enum RestrictionType {
  GUEST_ONLY = "GUEST_ONLY",
  AUTHENTICATED_ONLY = "AUTHENTICATED_ONLY",
}

interface IProps extends RouteProps {
  restriction: RestrictionType;
  whenLoadingShow?: ReactElement | null;
  onFailRedirectTo?: string;
}

const RestrictedRoute = ({
  restriction,
  whenLoadingShow = null,
  onFailRedirectTo,
  ...rest
}: IProps): ReactElement | null => {
  const { isSessionValid, isAuthenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    void (async () => {
      const isValid = await isSessionValid();

      if (!isValid) {
        history.push(onFailRedirectTo ?? "/login");
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isSessionValid, location, onFailRedirectTo]);

  return (
    <Route
      {...rest}
      {...((restriction === RestrictionType.AUTHENTICATED_ONLY &&
        isAuthenticated) ||
      (restriction === RestrictionType.GUEST_ONLY && !isAuthenticated)
        ? {}
        : { children: whenLoadingShow })}
    />
  );
};

export default RestrictedRoute;
