import { IThirdParty } from "src/pages/projects/project/interfaces";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import DeleteThirdPartyServiceModal from "src/components/modals/delete-third-party-service";
import BaseModal from "src/components/modals/base-modal";
import useAuth from "src/auth/use-auth";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import deleteThirdPartyService from "src/api/third-party-services/delete-third-party-service";
import getThirdParties from "src/api/third-party-services/get-third-party-services";
import { FormInstance } from "antd/lib/form";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import { IOption } from "../../types";
import ThirdPartyRow from "./components/thirdPartyRow";

interface IProps {
  thirdPartyServices: IThirdParty[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  talentsResponse?: IContactsResponse;
  areTalentsLoading: boolean;
  agenciesAndContacts: IAgencyWithContactsResults[];
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  setThirdPartyServices: (items: IThirdParty[]) => void;
  onThirdPartyToggleChange: (value: boolean) => void;
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
}

const ThirdParties = ({
  thirdPartyServices,
  form,
  talentsResponse,
  areTalentsLoading,
  agenciesAndContacts,
  onScrollTalents,
  setThirdPartyServices,
  onThirdPartyToggleChange,
  onUpdateNarrowSearchText,
  onResetNarrowSearch,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [thirdPartyOptions, setThirdPartyOptions] = useState<IOption[]>([]);
  const [
    selectedOptionToDelete,
    setSelectedOptionToDelete,
  ] = useState<IOption>();

  const { organisationId, fetchWrapper } = useAuth();

  useEffect(() => {
    const fetchThirdParties = async () => {
      const thirdParties = await fetchWrapper(getThirdParties, organisationId);

      const options = thirdParties.map((x) => {
        return {
          value: x.id,
          label: x.name,
        };
      });

      setThirdPartyOptions(options);
    };

    void fetchThirdParties();
  }, [fetchWrapper, organisationId]);

  const handleDeleteThirdPartySubmit = useCallback(
    async (thirdPartyOption: IOption) => {
      try {
        if (selectedOptionToDelete) {
          const deleted = await fetchWrapper(
            deleteThirdPartyService,
            organisationId,
            selectedOptionToDelete?.value
          );
          if (deleted) {
            const newOptions = [...thirdPartyOptions];
            const index = thirdPartyOptions.findIndex(
              (x) => x.value === thirdPartyOption.value
            );

            if (index > -1) {
              newOptions.splice(index, 1);
            }

            setThirdPartyOptions(newOptions);

            SuccessToast(t("deleteThirdPartyService##Third party deleted"));
          }
        }

        setIsModalOpen(false);
      } catch (e) {
        setIsModalOpen(false);
        ErrorToast(
          "delete-third-party-error",
          t("deleteThirdPartyService##Failed to delete third party")
        );
      }
    },
    [fetchWrapper, organisationId, selectedOptionToDelete, t, thirdPartyOptions]
  );

  const handleCreateNewRow = useCallback(() => {
    const newThirdPartyService = {
      thirdParty: {
        id: "",
        name: "",
      },
      thirdPartyFee: {
        currency: "USD",
        value: 0,
      },
      bankFee: {
        currency: "USD",
        value: 0,
      },
      taxWithholdingFee: {
        currency: "USD",
        value: 0,
      },
      paythrough: false,
    };
    setThirdPartyServices([...thirdPartyServices, newThirdPartyService]);
  }, [setThirdPartyServices, thirdPartyServices]);

  const handleUpdateRow = useCallback(
    (index: number, updatedRow: IThirdParty) => {
      const items = [...thirdPartyServices];
      items.splice(index, 1, updatedRow);
      setThirdPartyServices(items);
    },
    [setThirdPartyServices, thirdPartyServices]
  );

  const handleDeleteRow = useCallback(
    (index: number) => {
      const items = [...thirdPartyServices];
      items.splice(index, 1);
      if (items.length === 0) {
        onThirdPartyToggleChange(false);
      }
      setThirdPartyServices(items);
    },
    [setThirdPartyServices, thirdPartyServices, onThirdPartyToggleChange]
  );

  useEffect(() => {
    if ((thirdPartyServices ?? []).length === 0) {
      handleCreateNewRow();
    }
  }, [handleCreateNewRow, thirdPartyServices]);

  return (
    <>
      {(thirdPartyServices ?? []).map((thirdPartyRow, thirdPartyIndex) => {
        return (
          <ThirdPartyRow
            // eslint-disable-next-line react/no-array-index-key
            key={thirdPartyIndex}
            form={form}
            index={thirdPartyIndex}
            thirdPartyService={thirdPartyRow}
            showAddButton={
              thirdPartyIndex === (thirdPartyServices ?? []).length - 1
            }
            thirdPartyOptions={thirdPartyOptions}
            talentsResponse={talentsResponse}
            areTalentsLoading={areTalentsLoading}
            agenciesAndContacts={agenciesAndContacts}
            onScrollTalents={onScrollTalents}
            setThirdPartyOptions={setThirdPartyOptions}
            onUpdateRow={handleUpdateRow}
            onDeleteRow={handleDeleteRow}
            setSelectedOptionToDelete={setSelectedOptionToDelete}
            setIsModalOpen={setIsModalOpen}
            onCreateNewRow={handleCreateNewRow}
            onUpdateNarrowSearchText={onUpdateNarrowSearchText}
            onResetNarrowSearch={onResetNarrowSearch}
          />
        );
      })}
      <BaseModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={
          <DeleteThirdPartyServiceModal
            thirdPartyOption={selectedOptionToDelete}
            handleDeleteThirdPartySubmit={handleDeleteThirdPartySubmit}
            onClose={() => setIsModalOpen(false)}
          />
        }
        footer={null}
      />
    </>
  );
};

export default ThirdParties;
