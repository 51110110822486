import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  main: css`
    .ant-drawer-content-wrapper {
      height: auto !important;
    }

    .ant-drawer-content {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      min-height: 220px;
      background-color: ${theme.colors.white};
    }

    .ant-drawer-header {
      background-color: ${theme.colors.primary.blue} !important;
      border-radius: 0;
      border-bottom: 0;
    }

    .ant-drawer-body {
      background-color: ${theme.colors.white};
      padding: 0 !important;
      overflow: unset;
    }
  `,
};
