import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { getIsMobile, getIsTablet, useWindowSize } from "@songtradr/spa-common";
import { Col, Typography } from "antd";
import _ from "lodash";
import React, { ReactElement, Fragment, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { IProjectTerm } from "src/pages/projects/project/interfaces";
import countries from "i18n-iso-countries";
import styles from "../../styles";
import TermProgress from "../term-progress";

const maxTerritoriesBeforeFold = 10;
interface IProps {
  term: IProjectTerm;
}

const formatMediaTypeList = (mediaTypes: string[]) => {
  const list = mediaTypes.map((type, index) => {
    let value = _.startCase(type);
    if (mediaTypes[index + 1]) {
      value += ", ";
    }
    return value;
  });
  return list;
};

const Term = ({ term }: IProps): ReactElement => {
  const { t } = useTranslation();
  const { language } = getI18n();
  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();

  const [isCollapsed, setIsCollapsed] = useState(isTablet || isMobile);
  const [isTerritoriesCollapsed, setIsTerritoriesCollapsed] = useState(true);

  const selectedCountryCodes = term.territories.worldwide
    ? ["Worldwide"]
    : term.territories.includedCountries;

  return (
    <Fragment>
      <div css={styles.flexContainer}>
        <div css={styles.term} className="term-container">
          <Typography css={styles.termHeader}>{term.type}</Typography>
          <div css={styles.flex}>
            <Typography css={styles.name}>{term.name}</Typography>
            {isTablet ||
              (isMobile &&
                (isCollapsed ? (
                  <DownOutlined
                    onClick={() => setIsCollapsed(false)}
                    css={styles.viewMoreIcon}
                  />
                ) : (
                  <UpOutlined
                    onClick={() => {
                      setIsCollapsed(true);
                      setIsTerritoriesCollapsed(true);
                    }}
                    css={styles.viewMoreIcon}
                  />
                )))}
          </div>
          {!isCollapsed && (
            <Fragment>
              <Typography.Paragraph>{term.notes}</Typography.Paragraph>
              <Typography css={styles.subheader}>
                {t("ProjectsPage##licensingSection##Media Type")}
              </Typography>
              <Typography css={styles.name}>
                {term.mediaType && formatMediaTypeList(term.mediaType)}
              </Typography>
              <Typography css={styles.subheader}>
                {t("ProjectsPage##licensingSection##Territories")}
              </Typography>
              <ul css={styles.territoryList}>
                {selectedCountryCodes.map((code, index, territories) => {
                  if (
                    index === maxTerritoriesBeforeFold &&
                    isTerritoriesCollapsed
                  ) {
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        onClick={() => setIsTerritoriesCollapsed(false)}
                      >
                        <Typography css={styles.showMoreText}>
                          {t(
                            "ProjectsPage##licensingSection##Show more territories"
                          )}{" "}
                          ({territories.length - maxTerritoriesBeforeFold}){" "}
                          <DownOutlined />
                        </Typography>
                      </Col>
                    );
                  }
                  if (
                    index > maxTerritoriesBeforeFold &&
                    isTerritoriesCollapsed
                  ) {
                    return false;
                  }
                  return (
                    <li key={code} css={styles.territory}>
                      {code === "Worldwide" ||
                      !countries.getName(code, language)
                        ? code
                        : countries.getName(code, language)}
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          )}
        </div>
        <div css={styles.progressContainer}>
          <TermProgress term={term} />
        </div>
      </div>
    </Fragment>
  );
};

export default Term;
