import { eApplicationType } from "../interfaces/auth";

export interface IAuth0JwtPathload {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  scope?: string;
  "https://vinyl.songtradr.com/app_metadata": {
    OrganisationId: string;
    Roles: string[];
  };
}

export interface IUserPermissions {
  canAccessProjects: boolean;
  canAccessTeams: boolean;
}

export const getUserPermissions = (
  isSuperAdmin: boolean,
  applications: eApplicationType[]
): IUserPermissions => {
  if (isSuperAdmin) {
    return {
      canAccessTeams: true,
      canAccessProjects: true,
    };
  }

  return {
    canAccessProjects: applications.includes(eApplicationType.ProjectsInternal),
    canAccessTeams: applications.includes(eApplicationType.TeamManagement),
  };
};
