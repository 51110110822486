import { css, SerializedStyles } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import { play, pause } from "src/app/assets/icons/svg-icons";
import theme from "src/theme";

export default {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  `,
  playButtonContainer: css`
    width: 64px;

    @media ${queries.medium} {
      width: 76px;
    }
  `,
  playButton: css`
    border-radius: 50%;
    background-color: ${theme.colors.white};
    height: 64px;
    width: 64px;
  `,
  fileNameContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;

    @media ${queries.medium} {
      margin-left: 1rem;
      width: 20%;
      margin-right: 1.5rem;
    }

    @media ${queries.large} {
      margin-right: 2.5rem;
    }
  `,
  fileName: css`
    color: ${theme.colors.white};
    font-size: 0.875rem;
  `,
  waveform: css`
    display: none;

    @media ${queries.medium} {
      display: block;
      width: 50%;
      margin-top: 0.438rem;
      margin-right: 0.625rem;
    }

    @media ${queries.large} {
      width: 65%;
    }
  `,
  tabletDownloadButton: css`
    background-color: transparent;
    border: none;
  `,
  downloadButtonContainer: css`
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${queries.large} {
      width: 20%;
      padding-left: 2%;
      display: initial;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `,
  playing: css`
    background-image: url(${pause});
  `,
  paused: css`
    background-image: url(${play});
  `,
  control: css`
    padding: 0;
    height: 64px;

    @media ${queries.medium} {
      height: 76px;
    }

    :hover circle {
      display: none;
    }
  `,
};
