import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { IComponentIconProps } from "../interfaces";
import { GetNavigationLinksFillClasses } from "./helper";

const Projects = ({
  height = theme.svgSize.nav,
  width = theme.svgSize.nav,
  isActive = false,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  const fillClasses = GetNavigationLinksFillClasses(isActive);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 -10 25 50"
      aria-labelledby="projects-nav-link"
    >
      <title id="projects-nav-link">{t("Projects Page")}</title>
      <path
        className={fillClasses}
        d="M20 6H12L10.59 4.59C10.21 4.21 9.7 4 9.17 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM19 18H5C4.45 18 4 17.55 4 17V9C4 8.45 4.45 8 5 8H19C19.55 8 20 8.45 20 9V17C20 17.55 19.55 18 19 18Z"
      />
    </svg>
  );
};
export default Projects;
