import React, { ReactElement, ReactNode } from "react";
import MobileNavigation from "src/components/mobile-navigation";
import SideNavigation from "src/components/side-navigation";
import {
  TeamIcon,
  ProjectsIcon,
  ProfileIcon,
} from "src/app/assets/icons/component-icons";
import {
  useWindowSize,
  getIsTablet,
  getIsMobile,
} from "@songtradr/spa-common/lib/utils";
import useAuth from "src/auth/use-auth";
import { SerializedStyles } from "@emotion/react";
import sideStyles from "src/components/side-navigation/styles";
import mobileStyles from "src/components/mobile-navigation/styles";
import { useTranslation } from "react-i18next";
import TalentIcon from "src/app/assets/icons/component-icons/navigation/talentsIcon";
import config from "../../config/config";

export interface ISideNavProp {
  key: string;
  to: string;
  dataTestId: string;
  title: string;
  icon: (isActive: boolean) => ReactNode;
  visible: boolean;
  customStyles: SerializedStyles;
}

export interface INavRouteProp {
  routeMatch: RegExp;
  titleTranslation: string;
}

export interface ISideNavProps {
  navLinks: Array<ISideNavProp>;
}

export interface INavRouteProps {
  navRoutes: Array<INavRouteProp>;
}

const basePathname = config.basePathname === "/" ? "" : config.basePathname;
const navRoutes: Array<INavRouteProp> = [
  {
    routeMatch: /^\/$/i,
    titleTranslation: "",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/dashboard/?$`, "i"),
    titleTranslation: "Dashboard",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/project/.+`, "i"),
    titleTranslation: "Projects",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/projects/?$`, "i"),
    titleTranslation: "Projects",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/team/?$`, "i"),
    titleTranslation: "manageTeam##Manage your team",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/talents/?$`, "i"),
    titleTranslation: "TalentHub",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/talent/.+`, "i"),
    titleTranslation: "Talent Hub",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/talent-create`, "i"),
    titleTranslation: "Talent Hub",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/profile/?$`, "i"),
    titleTranslation: "Profile",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/reset-password/?$`, "i"),
    titleTranslation: "Reset password",
  },
];

const Navigation = (): ReactElement => {
  useWindowSize();
  const isMobile = getIsMobile();
  const isTabletOrMobile = getIsTablet() || isMobile;
  const { user, userPermissions } = useAuth();
  const { t } = useTranslation();

  const getUserName = (): string => {
    if (user?.name) {
      return user?.name;
    }
    return "";
  };

  const menuItemStyle = isTabletOrMobile
    ? mobileStyles.navLink
    : sideStyles.menuItem;

  const profileMenuItemStyle = isTabletOrMobile
    ? mobileStyles.profileNavLink
    : sideStyles.profileMenuItem;

  const navLinks: ISideNavProp[] = [
    {
      key: "/projects",
      to: "/projects",
      dataTestId: "projects-nav-link",
      title: t("Projects"),
      icon: (isActive: boolean | undefined) => (
        <ProjectsIcon isActive={isActive} />
      ),
      visible: userPermissions.canAccessProjects,
      customStyles: menuItemStyle,
    },
    {
      key: "/team",
      to: "/team",
      dataTestId: "team-nav-link",
      title: t("Team"),
      icon: (isActive: boolean | undefined) => <TeamIcon isActive={isActive} />,
      visible: userPermissions.canAccessTeams,
      customStyles: menuItemStyle,
    },
    {
      key: "/talents",
      to: "/talents",
      dataTestId: "talent-nav-link",
      title: t("TalentHub"),
      icon: (isActive: boolean | undefined) => (
        <TalentIcon isActive={isActive} />
      ),
      visible: userPermissions.canAccessProjects,
      customStyles: menuItemStyle,
    },
    {
      key: "/profile",
      to: "/profile",
      dataTestId: "profile-nav-link",
      title: getUserName(),
      icon: (isActive: boolean | undefined) => (
        <ProfileIcon isActive={isActive} />
      ),
      visible: true,
      customStyles: profileMenuItemStyle,
    },
  ].filter((navLink) => navLink.visible);

  return isTabletOrMobile ? (
    <MobileNavigation
      isMobile={isMobile}
      navLinks={navLinks}
      navRoutes={navRoutes}
    />
  ) : (
    <SideNavigation navLinks={navLinks} />
  );
};

export default Navigation;
