import axios from "axios";
import { eApplicationType } from "src/interfaces/auth";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { applicationsUrl } from "../../organisation-members-url-helper";

const removeApplications = (
  accessToken: string,
  organisationId: string,
  userId: string,
  applications: eApplicationType[]
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    data: {
      applications,
    },
  };

  return axios.delete(applicationsUrl(organisationId, userId), options);
};

export default removeApplications;
