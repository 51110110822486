import React from "react";
import { Form, Typography } from "antd";
import StyledButton from "src/components/button";
import styles from "src/components/modals/styles";
import { useTranslation } from "react-i18next";
import { IConfirmContentProps } from "../../../interfaces";
import SecondaryModalButtonLink from "../../modal-buttons/secondary-button-link";

const Content = ({
  modalTitle = "confirmation##defaultTitle",
  primaryButtonLabel = "defaultPrimaryButton",
  secondaryButtonLabel = "defaultSecondaryButton",
  mainContent,
  confirmAction,
  onClose,
}: IConfirmContentProps): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  return (
    <div>
      <Typography.Title data-testid="modal-Title" css={styles.modalTitle}>
        {t(modalTitle)}
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={() => confirmAction()}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {mainContent}
        <div css={styles.footer}>
          <SecondaryModalButtonLink
            onClick={onClose}
            ariaLabel={secondaryButtonLabel}
            buttonText={secondaryButtonLabel}
            className="confirmationModalCancelButton"
          />
          <StyledButton
            ariaLabel={primaryButtonLabel}
            type="primary"
            htmlType="submit"
            className="confirmationModalConfirmButton"
            data-testid="confirm-button"
          >
            {primaryButtonLabel}
          </StyledButton>
        </div>
      </Form>
    </div>
  );
};

export default Content;
