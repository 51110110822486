import { css, SerializedStyles } from "@emotion/react";
import theme from "src/theme";
import { StylesConfig } from "react-select";
import { queries } from "@songtradr/spa-common";

type MyOptionType = {
  label: string;
  value: string;
};
const dropDownMenu = `
  width: 292px;
  height: 48px;
  top: 8px;
  padding: 4px 16px 12px 16px;
  margin-bottom: 8px;
  gap: 16px;
`;
export const customStyles: StylesConfig<MyOptionType> = {
  menu: (provided) => ({
    ...provided,
    fontSize: 16,
    fontWeight: 400,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: `${theme.fonts.sofiaPro}`,
    marginTop: -8,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 4,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: `${theme.colors.secondary.veryLightPurple}`,
    color: `${theme.colors.primary.blue}`,

    ":hover": {
      color: `${theme.colors.secondary.lightGray}`,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 56,
    maxHeight: 56,
    fontWeight: 400,
    overflow: "overlay",
  }),
};

export default {
  contactContainer: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    .ant-picker-suffix {
      color: ${theme.colors.secondary.black} !important;
    }
    width: 100%;
    background-color: ${theme.colors.white};
    position: sticky;
    overflow: visible !important;
    top: 0;
  `,
  mainContainer: css`
    display: flex;
    flex: 1;
  `,
  emptyAgency: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
  `,

  rowWrapper: css`
    height: 100%;
    width: 100%;
  `,
  bodyContentTags: css`
    display: flex;
    max-width: inherit;
    flex-direction: row;
    width: 100%;
    padding: 8px;
    height: 55px;
    overflow: auto;
    gap: 0.2rem;
    ::-webkit-scrollbar {
      height: 0;
    }
  `,
  agenciesLoadingContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  `,

  agenciesSearchLoadWrapper: css`
    ${dropDownMenu}
    height: 100%;
    width: 100%;
  `,
  agencyContent: css`
    cursor: pointer;
    background-color: transparent;
    color: inherit;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  `,
  blurMainContainer: css`
    opacity: 0.5;
    pointer-events: none;
  `,
  footerNoActionsAllowed: css`
    pointer-events: none;
  `,
  contactTabsHeader: css`
    flex: 1;
    .ant-tabs-content-holder {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 7rem;
      @media ${queries.large} {
        padding-left: 19rem;
        padding-right: 3rem;
      }
    }
    .ant-tabs-nav {
      border-color: ${theme.colors.background.veryLightGray};
      border-width: 0.15rem;
      width: 100%;
      @media ${queries.large} {
        width: inherit;
      }
    }
    .ant-tabs-nav-wrap {
      box-shadow: none;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      border-right: 1px solid #f0f0f0;
      height: 100%;
    }
    .ant-tabs-left > .ant-tabs-nav {
      margin-top: 0.5rem;
      .ant-tabs-tab-btn .ant-badge {
        font-size: 1.5rem;
        font-weight: 300;
        color: ${theme.colors.secondary.lightGray};
      }
      .ant-tabs-tab-active .ant-tabs-tab-btn .ant-badge {
        color: ${theme.colors.black};
      }
    }
    .ant-tabs-nav .ant-tabs-tab {
      width: fit-content;
      padding: 0.5 0.5rem !important;
      margin: 0 !important;
      :first-of-type {
        padding-top: 0.5rem;
      }
      padding-bottom: 1rem;
      color: ${theme.colors.secondary.lightGray};
      @media ${queries.large} {
        width: 16rem;
        padding: 1rem 2rem;
      }
    }
    @media ${queries.large} {
      margin-top: 2rem;
    }
  `,
  contentContainer: css`
    border-radius: 0;
    padding-left: 0 !important;
    height: 100%;
    :empty {
      padding: 0.125rem 0 0 0 !important;
    }
  `,
  tabsStyles: css`
    overflow: visible;

    .ant-tabs-nav {
      position: fixed;
      z-index: 1051;
      margin-top: 0 !important;
      .ant-tabs-tab {
        padding: 0.5rem 1.5rem !important;
        .ant-tabs-tab-btn {
          width: 100%;
        }
      }
      .ant-tabs-tab-disabled {
        cursor: default;
      }
      @media ${queries.large} {
        height: 100%;
      }
    }
  `,

  agency(isAgencySelected: boolean): SerializedStyles {
    return css`
      :hover {
        background-color: rgb(222, 235, 255);
      }
      background-color: ${isAgencySelected && "rgb(38, 132, 255) !important"};
      display: flex;
      align-items: center;
      font-size: inherit;
      padding: 8px 12px;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
    `;
  },
  activeTab: css`
    display: flex;
    align-items: center;
    font-size: 16px;
    div:first-of-type {
      display: flex;
      flex: 1;
    }
    div:last-child {
      display: flex;
      align-self: flex-end;
    }
  `,
  tabsTitle: css`
    @media ${queries.large} {
      font-size: 24px;
      font-weight: bold !important;
      color: var(--colors-black) !important;
    }
  `,
  footerContainer: css`
    position: fixed;
    bottom: 0;
    z-index: 2000;
    width: -webkit-fill-available;
    display: flex;
    flex: 1;
    background: white;
    border-top: 1px solid #f0f0f0;
    padding: 1rem;
    justify-content: space-between;
    > div {
      display: flex;
      margin-right: 2rem;
    }
    button {
      display: flex;
      font-size: 16px;
      align-items: center;
      font-weight: 600;
      justify-content: center;
      -webkit-box-shadow: none;
      min-height: 2.6rem;
    }
  `,
  cancelButtonStyles: css`
    display: flex;
    justify-content: flex-start !important;
    border: none;
    background: none;
    border-radius: 4px;
    :hover {
      border: none;
      background: none;
    }
    :focus {
      border: none;
      background: none;
    }
  `,
  finalContactPageButtonsContainer: css`
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    button {
      display: flex;
      margin-top: 1rem;
      align-items: center;
      justify-content: center;
      -webkit-box-shadow: none;
      min-height: 2.6rem;
    }
  `,
  backButtonStyles: css`
    border: 1px solid ${theme.colors.primary.blue};
    background: ${theme.colors.white};
    color: ${theme.colors.primary.blue};
    border-radius: 4px;
    :hover {
      border: 1px solid ${theme.colors.secondary.navyBlue};
      background: ${theme.colors.white};
      color: ${theme.colors.secondary.navyBlue};
    }
    :focus {
      border: 1px solid ${theme.colors.secondary.navyBlue};
      background: ${theme.colors.white};
      color: ${theme.colors.secondary.navyBlue};
    }
    align-self: flex-end;
    margin-right: 1rem;
  `,
  okButtonStyles: css`
    border: 2px solid ${theme.colors.primary.blue};
    background: ${theme.colors.primary.blue};
    color: white;
    border-radius: 4px;
    :hover {
      background: ${theme.colors.secondary.navyBlue};
      border: 2px solid ${theme.colors.secondary.navyBlue};
      color: ${theme.colors.white};
    }
    :focus {
      background: ${theme.colors.secondary.navyBlue};
      border: 3px solid ${theme.colors.secondary.navyBlue};
      color: ${theme.colors.white};
    }
    :disabled {
      pointer-events: none;
      background: ${theme.colors.secondary.lightGray};
      border: 3px solid ${theme.colors.secondary.lightGray};
      color: ${theme.colors.white};
      box-shadow: none;
    }
    align-self: flex-end;
  `,
  sectionContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
  `,
  mobileSectionContainer: css`
    display: flex;
    flex-direction: column;
    column-gap: 1.5rem;
    row-gap: 2.5rem;
    > div {
      flex: 1 1 0;
    }
  `,
  clientContainer: css`
    display: flex;
    flex-direction: row;
    > div {
      flex: 1 1 0;
      width: 0;
    }
    column-gap: 1.5rem;
    row-gap: 2.5rem;
    width: 100%;
  `,
  basicInputContainer: css`
    margin-bottom: 0.5rem;
  `,
  basicInputLabel: css`
    font-size: 0.875rem;
    line-height: 1.55rem;
    font-weight: 400;
  `,
  formItem: css`
    margin-bottom: 0 !important;
  `,
  select: css`
    &:has(div.ant-form-item-has-error) {
      .ant-row
        .ant-col
        .ant-form-item-control-input
        .ant-form-item-control-input-content
        div
        div {
        border-color: ${theme.colors.functional.red};
        border-radius: 0.25rem;
      }
    }
  `,
  verticalDividerContainer: css`
    display: flex;
    border-top: 0.0625rem solid ${theme.colors.background.lightGray};
    border-width: 1px;
    border-bottom: 0.0625rem solid ${theme.colors.background.lightGray};
  `,
  verticalDivider: css`
    display: flex;
    margin: 0;
    align-self: center;
    height: 60%;
  `,
  sizeOfHalfDiv: css`
    width: 50% !important;
  `,
  entireSizeOfDiv: css`
    width: 100% !important;
  `,
  sizeNinetyPercent: css`
    width: 90% !important;
  `,
  priceInput: css`
    flex: auto;
  `,
  phoneContainer: css`
    width: fit-content;
    &:has(div.ant-form-item-has-error) {
      .ant-row
        .ant-col
        .ant-form-item-control-input
        .ant-form-item-control-input-content {
        div div {
          border-color: ${theme.colors.functional.red};
          border-radius: 0.25rem 0 0 0.25rem;
        }
      }
    }
  `,
  basicInput: css`
    position: relative;
    .floatingLabel {
      margin-bottom: 0;
      label {
        top: 0.5rem;
      }
      label.active-focus {
        top: 0 !important;
      }
    }
    .name-link-row,
    .music-track-details,
    .primary-contact {
      input {
        height: 56px !important;
      }
    }
    .ant-form-item-explain-error {
      font-weight: 400;
      font-size: 16px;
    }
    .ant-row {
      label {
        top: 0.5rem;
      }
      label.active-focus {
        top: 0 !important;
      }
      .ant-select {
        .ant-select-selector {
          .ant-select-selection-item {
            padding-top: 0;
          }
        }
      }
    }
  `,
  switchContainer: css`
    display: flex;
    flex-direction: row;
  `,
  basicLabelAdditionalStyles: css`
    margin-right: 10px;
    line-height: 32px;
  `,
  additionalFormItemStyle: css`
    .ant-form-item-control-input-content {
      display: flex !important;
      justify-content: flex-start !important;
    }
  `,
  disclaimerLabel: css`
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    align-items: center;
    display: flex;
    svg {
      margin-right: 0.5rem;
    }
  `,
};
