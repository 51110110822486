import React, { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "./interfaces";

const ErrorIcon = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Foundations-&amp;-Components"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons" transform="translate(-884.000000, -1292.000000)">
          <rect fill="#FFE5E4" x="0" y="0" width="1440" height="2836" />
          <g
            id="Icons/Alert-Error"
            transform="translate(884.000000, 1292.312500)"
          >
            <circle id="Oval" fill="#E81E25" cx="12" cy="12" r="12" />
            <path
              d="M12,16.1281943 C12.8284271,16.1281943 13.5,16.7997671 13.5,17.6281943 C13.5,18.4566214 12.8284271,19.1281943 12,19.1281943 C11.1715729,19.1281943 10.5,18.4566214 10.5,17.6281943 C10.5,16.7997671 11.1715729,16.1281943 12,16.1281943 Z M12,5.07858343 C12.6472087,5.07858343 13.1795339,5.57045809 13.2435464,6.20077819 L13.25,6.32858343 L13.25,13.3285834 C13.25,14.0189394 12.6903559,14.5785834 12,14.5785834 C11.3527913,14.5785834 10.8204661,14.0867088 10.7564536,13.4563887 L10.75,13.3285834 L10.75,6.32858343 C10.75,5.63822749 11.3096441,5.07858343 12,5.07858343 Z"
              id="Shape"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ErrorIcon;
