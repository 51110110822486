import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  floatingLabel: css`
    position: relative;
    width: 100%;
    color: ${theme.colors.background.darkGray};
    input,
    textarea {
      padding-top: 1rem;
    }
    flex: 1;
  `,

  invisibleContainer: css`
    visibility: hidden;
  `,
  formContainer: css`
    margin-bottom: 0 !important;
  `,
  additionalFunctions: css`
    display: flex;
    align-items: center;
    width: 76px;
    > div div button {
      margin-top: 5px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  `,
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    flex: 1;
  `,
  mobileContainer: css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
  input: css`
    input {
      border: 1px solid ${theme.colors.background.lightGray};
      height: 3.125rem;
      border-radius: 0.25rem;
    }
  `,
  totalFee: css`
    padding-bottom: 0 !important;
    margin-left: 0 !important;

    .ant-form-item {
      margin-bottom: 0.8rem !important;
    }
  `,
  iconContainer: css`
    margin-bottom: 0.5rem;
    margin-top: -0.5rem;
    margin-left: -0.2rem !important;

    @media ${queries.medium} {
      margin-right: 0.5rem;
      margin-left: 0.3rem !important;
      margin-top: -0.2rem;
    }
  `,
  mobileDelete: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  feeAmount: css`
    cursor: default !important;
    color: ${theme.colors.black} !important;
    &:hover {
      border: 1px solid ${theme.colors.background.lightGray} !important;
    }
    &:focus {
      border: 1px solid ${theme.colors.background.lightGray} !important;
    }
  `,
  splitColumn: css`
    margin-right: 0.75rem !important;
    @media ${queries.medium} {
      margin-right: 0 !important;
    }
  `,
  feeColumn: css`
    margin-left: 0.75rem !important;
    @media ${queries.medium} {
      margin-left: 0 !important;
    }
  `,
  feeSplit: css`
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield !important;
  `,
};
