/* eslint-disable no-param-reassign */
import {
  IProjectAttachment,
  INameLinkState,
  IProjectForm,
  IFormRelatedProject,
  IFormContacts,
} from "src/pages/projects/project/interfaces";
import * as uuid from "uuid";

function initialiseRelatedProjects(relatedProjects: IFormRelatedProject[]) {
  if (relatedProjects.length > 0) {
    relatedProjects.forEach((project) => {
      project.key = uuid.v4();
      project.isNew = false;
    });
  }
}

function initialiseNameLinks(nameLinks: Array<INameLinkState>) {
  if (nameLinks.length === 0) {
    nameLinks.push({
      name: "",
      link: "",
      isNew: true,
      key: uuid.v4(),
    });
  } else {
    nameLinks.forEach((nameLink) => {
      nameLink.key = uuid.v4();
    });
  }
}

function initialiseContacts(contacts: IFormContacts[]) {
  if (contacts.length === 0) {
    contacts.push({
      name: "",
      email: "",
      contactType: "",
      isNew: true,
      key: uuid.v4(),
    });
  } else {
    contacts.forEach((contact) => {
      contact.key = uuid.v4();
    });
  }
}

export const initialiseAttachments = (
  attachments: IProjectForm["attachments"],
  attachmentType: string,
  isCategory = false
): IProjectAttachment[] => {
  if (!attachments || attachments.length === 0) {
    return [];
  }

  return attachments.filter((attachment: IProjectAttachment) => {
    // Map ANTD props
    attachment.status = "done";
    attachment.percent = 100;
    attachment.type = attachment.contentType;
    attachment.uid = attachment.id;
    attachment.size = 0;

    if (isCategory) {
      return attachment.documentCategory === attachmentType;
    }

    return attachment.attachmentType === attachmentType;
  });
};

const mapProjectResponse = (project: IProjectForm): IProjectForm => {
  if (!project.attachments) {
    project.attachments = [];
  }

  if (!project.tracks) {
    project.tracks = [];
  }

  initialiseRelatedProjects(project.relatedProjects);

  initialiseNameLinks(project.music.musicAssets);

  initialiseNameLinks(project.music.finalVideo);

  initialiseContacts(project.contacts);

  return project;
};
export default mapProjectResponse;
