import { Button, Typography } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import waitForMilliseconds from "src/utils/waitForMilliseconds";
import style from "../../styles";

interface IProps {
  updated?: boolean;
  contactId?: string;
  onSetWaitingForElasticSearch: () => void;
  onContactUpdated?: () => void;
}
const ELASTIC_SEARCH_WAIT_TIME = 500;
const ContactSuccessfullyCreatedOrUpdated = ({
  updated,
  contactId,
  onSetWaitingForElasticSearch,
  onContactUpdated,
}: IProps): React.ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleTalentHubTableRedirect = useCallback(async () => {
    // When react version update to v18+ use flushSync
    onSetWaitingForElasticSearch();
    await waitForMilliseconds(ELASTIC_SEARCH_WAIT_TIME);
    onSetWaitingForElasticSearch();
    history.replace("/talents");
  }, [history, onSetWaitingForElasticSearch]);

  return (
    <div id="SuccessScreen" css={style.sectionContainer}>
      <Typography.Text
        data-testid="success-message-label"
        id="success-message-label"
        css={style.tabsTitle}
      >
        {updated
          ? t("ContactPage##Contact successfully updated")
          : t("ContactPage##Contact successfully added")}
      </Typography.Text>
      <div>
        <Typography.Text css={style.basicInputLabel} style={{ maxWidth: 848 }}>
          {updated
            ? t("ContactPage##ContactUpdatedStatementSection")
            : t("ContactPage##ContactCreatedStatementSection")}
        </Typography.Text>
        <div css={style.finalContactPageButtonsContainer}>
          <Button
            css={style.backButtonStyles}
            tabIndex={0}
            aria-label="edit-contact"
            data-testid="edit-contact-button"
            onClick={() => {
              if (contactId) {
                if (updated) {
                  onContactUpdated?.();
                } else {
                  history.replace(`/talent/${contactId}`);
                }
              } else {
                history.replace(`/talents`);
              }
            }}
          >
            {t("ContactPage##Edit Contact")}
          </Button>
          <Button
            css={style.okButtonStyles}
            tabIndex={0}
            aria-label="back-to-talent-hub"
            onClick={handleTalentHubTableRedirect}
            type="primary"
          >
            {t("ContactPage##Back to Talent Hub")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactSuccessfullyCreatedOrUpdated;
