import axios from "axios";
import config from "src/config";
import { IPresignedDownloadUrlResponse } from "src/pages/projects/project/interfaces";
import { getJsonContentHeader } from "../headers";

const getSharedFileUrl = async (
  projectId: string,
  attachmentId: string,
  shareCode?: string
): Promise<IPresignedDownloadUrlResponse> => {
  const options = {
    headers: { ...getJsonContentHeader() },
  };

  const body = {
    shareCode,
  };

  return axios.post(
    `${config.projectManagementService.url}/projects/${projectId}/sharing/attachments/${attachmentId}/download`,
    body,
    options
  );
};

export default getSharedFileUrl;
