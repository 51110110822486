import React, {
  useState,
  ReactElement,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { UploadFile } from "antd/lib/upload/interface";
import { checkFilesCurrentlyUploading } from "src/components/file-upload/helpers";
import { cloneDeep } from "lodash";
import * as uuid from "uuid";
import { useParams } from "react-router-dom";
import { IDeleteSelectedFile } from "src/components/file-upload/interfaces";
import { Divider, Form } from "antd";
import {
  IAgencyWithContactsResults,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import {
  IFeeSplitDetailState,
  IFinalTrackSection,
  INameLinkState,
  IProjectRouteParams,
} from "../../../../interfaces";
import FinalTrackLinks from "../../../music/components/final-track-links";
import FinalTrackDetails from "../../../music/components/final-track-details";
import FinalTrackOwnership from "../../../music/components/final-track-ownership";
import {
  finalTrackAttachmentsTableColumns,
  FinalTrackAttachmentsTableData,
} from "../../../music/components/final-track-attachments/table-data";
import FinalTracksUploader from "../final-tracks-uploader";
import mainStyle from "../../../../styles";

interface IProps {
  finalTrack?: IFinalTrackSection;
  attachments?: UploadFile[];
  validateWriterFeeFields: boolean;
  validateTrackOwnership: boolean;
  talentsResponse?: IContactsResponse;
  agenciesAndContacts: IAgencyWithContactsResults[];
  areTalentsLoading: boolean;
  onUpdateNarrowSearchText: (
    searchValue: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
  onResetNarrowSearch: () => void;
  onSelectedTrackChange: (track?: IFinalTrackSection) => void;
  onTrackDataChanged: () => void;
  onScrollTalents: (
    page: number,
    searchText: string,
    areAgenciesIntegrated: boolean,
    isServiceProvider: boolean
  ) => void;
}

const addKeys = (assets?: INameLinkState[]) => {
  if (!assets || assets.length < 1) {
    return [{ key: uuid.v4(), isNew: true, split: "100" }];
  }
  const withKeys = assets.map((asset) => {
    return { ...asset, key: uuid.v4() };
  });
  return withKeys;
};

const TrackForm = ({
  onSelectedTrackChange,
  finalTrack,
  attachments,
  talentsResponse,
  validateWriterFeeFields,
  agenciesAndContacts,
  validateTrackOwnership,
  areTalentsLoading,
  onUpdateNarrowSearchText,
  onResetNarrowSearch,
  onScrollTalents,
  onTrackDataChanged,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [finalTrackLinks, setFinalTrackLinks] = useState<INameLinkState[] | []>(
    addKeys(finalTrack?.assets)
  );
  const [masterFeeSplits, setMasterFeeSplits] = useState(
    addKeys(finalTrack?.masterFeeSplits)
  );
  const [publisherFeeSplits, setPublisherFeeSplits] = useState(
    addKeys(finalTrack?.publisherFeeSplits)
  );

  const [writerFeeSplits, setWriterFeeSplits] = useState(
    addKeys(finalTrack?.writerFeeSplits)
  );

  const { id: projectId } = useParams<IProjectRouteParams>();
  const [showFileUploadDeleteModal, setShowFileUploadDeleteModal] = useState(
    false
  );
  const handleShowFileUploadDeleteModal = useCallback((show: boolean) => {
    setShowFileUploadDeleteModal(show);
  }, []);

  const [
    selectedFileToDelete,
    setSelectedFileToDelete,
  ] = useState<IDeleteSelectedFile>();
  const handleSetSelectedFileToDelete = useCallback(
    (file: IDeleteSelectedFile | undefined) => {
      setSelectedFileToDelete(file);
    },
    []
  );

  const [finalTrackFilesList, setFinalTrackFilesList] = useState<
    Array<UploadFile>
  >([]);

  const handleSetFinalTrackFilesList = useCallback((files: UploadFile[]) => {
    setFinalTrackFilesList(files);
  }, []);

  useEffect(() => {
    void (() => {
      if (!checkFilesCurrentlyUploading(finalTrackFilesList) && attachments) {
        const trackAttachments = attachments.filter(
          (attachment) => attachment?.trackId === finalTrack?.id
        );
        setFinalTrackFilesList(cloneDeep(trackAttachments));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (finalTrackLinks && finalTrack) {
      onSelectedTrackChange({ ...finalTrack, assets: finalTrackLinks });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalTrackLinks]);

  useEffect(() => {
    if (finalTrack) {
      onSelectedTrackChange?.({
        ...finalTrack,
        publisherFeeSplits,
        masterFeeSplits,
        writerFeeSplits,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherFeeSplits, masterFeeSplits, writerFeeSplits]);

  const handleMasterFeeSplitsChange = useCallback(
    (value: IFeeSplitDetailState[]) => {
      setMasterFeeSplits(addKeys(value));
      onTrackDataChanged();
    },
    [onTrackDataChanged]
  );

  const handlePublisherFeeSplitsChange = useCallback(
    (value: IFeeSplitDetailState[]) => {
      setPublisherFeeSplits(addKeys(value));
      onTrackDataChanged();
    },
    [onTrackDataChanged]
  );

  const handleWriterFeeSplitsChange = useCallback(
    (value: IFeeSplitDetailState[]) => {
      setWriterFeeSplits(addKeys(value));
      onTrackDataChanged();
    },
    [onTrackDataChanged]
  );

  const handleSetFinalTrackLinks = useCallback(
    (links: INameLinkState[]) => {
      setFinalTrackLinks(links);
      onTrackDataChanged();
    },
    [onTrackDataChanged]
  );

  const handleSetTrackData = useCallback(
    (track?: IFinalTrackSection) => {
      onSelectedTrackChange(track);
      onTrackDataChanged();
    },
    [onSelectedTrackChange, onTrackDataChanged]
  );

  const initialFormItemFieldNames = useMemo(() => {
    const generateRowKeys = (
      fieldName: string,
      index: number,
      includeFees: boolean
    ) => {
      const rowKeys = [
        `${fieldName}${index}contactReferences0Name`,
        `${fieldName}${index}contactReferences0Email`,
        `${fieldName}${index}Split`,
      ];
      if (!includeFees) {
        return rowKeys;
      }

      return rowKeys.concat([
        `${fieldName}${index}TaxWithholdingFeeCurrency`,
        `${fieldName}${index}TaxWithholdingFeePrice`,
        `${fieldName}${index}BankFeeCurrency`,
        `${fieldName}${index}BankFeePrice`,
      ]);
    };
    const generateFeeSplitsKeys = (
      fieldName: string,
      splits: IFeeSplitDetailState[],
      includeFees = true
    ) => {
      let keys: string[] = [];
      if ((splits ?? []).length === 0) {
        keys = [...generateRowKeys(fieldName, 0, includeFees)];
      } else {
        splits.forEach((_, index) => {
          keys = [...keys, ...generateRowKeys(fieldName, index, includeFees)];
          return keys;
        });
      }
      return keys;
    };

    return [
      "title",
      "musicType",
      "masterFeeCurrency",
      "masterFeePrice",
      "writerFeeCurrency",
      "writerFeePrice",
      "publisherFeeCurrency",
      "publisherFeePrice",
    ]
      .concat(
        generateFeeSplitsKeys(
          "masterFeeSplits",
          finalTrack?.masterFeeSplits ?? []
        )
      )
      .concat(
        generateFeeSplitsKeys(
          "writerFeeSplits",
          finalTrack?.writerFeeSplits ?? []
        )
      )
      .concat(
        generateFeeSplitsKeys(
          "publisherFeeSplits",
          finalTrack?.publisherFeeSplits ?? [],
          false
        )
      );
  }, [
    finalTrack?.masterFeeSplits,
    finalTrack?.publisherFeeSplits,
    finalTrack?.writerFeeSplits,
  ]);

  useEffect(() => {
    void form.validateFields(initialFormItemFieldNames);
  }, [
    form,
    initialFormItemFieldNames,
    finalTrack,
    validateWriterFeeFields,
    validateTrackOwnership,
  ]);

  return (
    <div css={mainStyle.projectDetailsContainer}>
      <div css={mainStyle.collapsibleSectionContainer}>
        <FinalTrackDetails
          track={finalTrack}
          form={form}
          talentsResponse={talentsResponse}
          areTalentsLoading={areTalentsLoading}
          agenciesAndContacts={agenciesAndContacts}
          onScrollTalents={onScrollTalents}
          onUpdateNarrowSearchText={onUpdateNarrowSearchText}
          onResetNarrowSearch={onResetNarrowSearch}
          onSelectedTrackChange={handleSetTrackData}
        />
      </div>
      <Divider css={mainStyle.collapsibleSectionDivider} />
      <div css={mainStyle.collapsibleSectionContainer}>
        <FinalTracksUploader
          finalTrackFilesList={finalTrackFilesList}
          onSetFinalTrackFilesList={handleSetFinalTrackFilesList}
          tableData={FinalTrackAttachmentsTableData({
            filesList: finalTrackFilesList,
            setFileList: handleSetFinalTrackFilesList,
            projectId,
            t,
            setShowModal: handleShowFileUploadDeleteModal,
            setSelectedFileToDelete: handleSetSelectedFileToDelete,
          })}
          selectedFileToDelete={selectedFileToDelete}
          tableColumns={finalTrackAttachmentsTableColumns()}
          showFileUploadDeleteModal={showFileUploadDeleteModal}
          onShowFileUploadDeleteModal={handleShowFileUploadDeleteModal}
          trackId={finalTrack?.id}
        />
      </div>
      <Divider css={mainStyle.collapsibleSectionDivider} />
      <div css={mainStyle.collapsibleSectionContainer}>
        <FinalTrackLinks
          fieldName="finalTrackLinks"
          items={finalTrackLinks}
          setFinalTrackLinks={handleSetFinalTrackLinks}
        />
      </div>
      <Divider css={mainStyle.collapsibleSectionDivider} />
      <div css={mainStyle.collapsibleSectionContainer}>
        <FinalTrackOwnership
          track={finalTrack}
          masterFeeSplits={masterFeeSplits}
          publisherFeeSplits={publisherFeeSplits}
          writerFeeSplits={writerFeeSplits}
          form={form}
          validateWriterFeeField={false}
          validateWriterFields={validateWriterFeeFields}
          validateTrackOwnership={validateTrackOwnership}
          talentsResponse={talentsResponse}
          areTalentsLoading={areTalentsLoading}
          agenciesAndContacts={agenciesAndContacts}
          onScrollTalents={onScrollTalents}
          onWriterFeeSplitsChange={handleWriterFeeSplitsChange}
          onPublisherFeeSplitsChange={handlePublisherFeeSplitsChange}
          onMasterFeeSplitsChange={handleMasterFeeSplitsChange}
          onSelectedTrackChange={handleSetTrackData}
          onUpdateNarrowSearchText={onUpdateNarrowSearchText}
          onResetNarrowSearch={onResetNarrowSearch}
        />
      </div>
    </div>
  );
};

export default TrackForm;
