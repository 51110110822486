import React, { ReactElement } from "react";
import styles from "./styles";

interface IProps {
  children: string;
}
const ValidationError = ({ children }: IProps): ReactElement => (
  <span css={styles.error}>{children}</span>
);

export default ValidationError;
