import { css } from "@emotion/react";
import theme from "../../../theme";

export default {
  selectionInput: css`
    width: 100%;
    border-color: ${theme.colors.secondary.blueOnBlack};
    border-radius: 4px;
    align-self: center;
    .ant-select-selection-item {
      padding-top: unset !important;
      align-self: center;
    }
    .ant-select-selection-search-input {
      margin-top: 0.5rem !important;
    }
    margin-bottom: 3.75rem;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f3f3f3;
    font-family: ${theme.fonts.sofiaPro};
    width: 100%;
  `,
  section: css`
    background: transparent;
    display: flex;
    flex-direction: column;
    flex: auto;
    min-height: 0;
  `,
  mainContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: auto;
  `,
  loginContainer: css`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    align-self: center;
    overflow: hidden;
    margin: 20px auto;
    margin-top: 0;
    min-height: 700px;
    width: 90vw;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.secondary.lightGrey};
    box-shadow: 0 4px 48px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 3rem;
  `,
  logo: css`
    margin-bottom: 5rem;
    margin-top: 3rem;
  `,
  row: css`
    width: 100%;
  `,
  inputContainer: css`
    margin-top: 2rem;
  `,
  input: css`
    border-color: ${theme.colors.black} !important;
    font-family: ${theme.fonts.sofiaPro} !important;
  `,
  subText: css`
    color: black;
    font-size: 14px !important;
    font-weight: 600;
    margin-top: -8px;
    margin-bottom: 48px;
    text-align: right;
    cursor: pointer;
    width: 110px;
    float: right;
  `,
  label: css`
    font-size: 0.9rem;
    padding-bottom: 0.3rem;
  `,
  title: css`
    font-size: 1.6rem !important;
    font-weight: 700;
    align-self: flex-start;
    margin-top: 1rem;
  `,
  graphicLeft: css`
    position: absolute;
    right: 0;
    top: 75px;
  `,
  graphicRight: css`
    position: absolute;
    bottom: 75px;
  `,
};
