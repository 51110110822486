import { ReactElement } from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

import styles from "../styles";

const TalentHubHeader = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={styles.talentStickyHeader}>
      <div css={styles.talentUpperHeader}>
        <Typography.Title css={styles.typographyTitle}>
          {t("TalentHub")}
        </Typography.Title>
      </div>
    </div>
  );
};

export default TalentHubHeader;
