import produce from "immer";
import { IContactForm } from "src/pages/contact/interfaces";
import { ContactActions, ContactAction } from "./types";

export const contactReducer = produce(
  (draft: IContactForm, action: ContactAction) => {
    switch (action.type) {
      case ContactActions.STORE_CONTACT:
        // eslint-disable-next-line no-param-reassign
        draft = action.value.contact;
        return draft;
      case ContactActions.STORE_SHOW_REEL:
        draft.showReel = action.value.attachments;
        return draft;
      case ContactActions.STORE_DOCUMENTS:
        draft.documents = action.value.attachments;
        return draft;
      default:
        throw new Error();
    }
  }
);

export const InitialContactState = {
  id: "",
  version: 0,
  name: "",
  pronoun: null,
  agencyWritingPartnership: "",
  email: "",
  phone: { countryCode: "", number: "" },
  baseLocation: "",
  socialMediaWebsite: [],
  showreelUrls: [],
  mainLanguage: "",
  otherLanguages: [],
  billingName: "",
  billingAddress: "",
  physicalConditionsIllness: null,
  neurodiverse: null,
  neurodiverseNote: "",
  racialCharacteristics: "",
  genderIdentity: null,
  sexualOrientation: null,
  signedCMA: null,
  signedNDA: null,
  minimumDemoFee: null,
  performingRightsOrganisationNumber: "",
  connectedToOrgId: null,
  serviceProvider: null,
  collaborationStatus: null,
  skillSet: [],
  principalInstruments: [],
  additionalInstruments: [],
  keyGenres: [],
  additionalGenres: [],
  vocalist: null,
  minimumDemoTurnaroundHours: null,
  previousWork: [],
  interestedProjects: [],
  talentNotes: [],
  awardNotes: [],
  internalNotes: [],
  createdByOrgId: "",
  showReel: [],
  documents: [],
};
