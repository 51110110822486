import { useEffect } from "react";
import ReactHeap from "reactjs-heap";
import config from "src/config";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    heap: {
      resetIdentity: () => void;
      identify: (userId: string) => void;
      addUserProperties: (properties: Record<string, string>) => void;
    };
  }
}

interface IProps {
  userId?: string;
  email?: string;
  orgId?: string;
  orgName?: string;
}

ReactHeap.initialize(config.heapId);

const Heap = ({ userId, email, orgId, orgName }: IProps): null => {
  useEffect(() => {
    if (!window.heap) {
      return;
    }

    if (!userId) {
      window.heap.resetIdentity();
      return;
    }

    window.heap.identify(userId);

    window.heap.addUserProperties({
      userId,
      email: email ?? "",
      orgId: orgId ?? "",
      orgName: orgName ?? "",
    });
  }, [userId, email, orgId, orgName]);

  return null;
};

export default Heap;
