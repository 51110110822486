import React, { Fragment, ReactNode, useCallback } from "react";
import { IProjectsDataSource } from "src/components/table/interfaces";
import { format, parseISO } from "date-fns";
import StatusPill from "src/components/status-pill";
import MoreInfoButton from "src/components/control-buttons/more-info-button";
import { startCase } from "lodash";
import { Dropdown, Tooltip } from "antd";
import MenuItem from "src/components/menu-item";
import { DeleteIcon, EditIcon } from "src/app/assets/icons/component-icons";
import PopOverText from "src/components/table-popover";
import { LicenseFixedDurations, Regions } from "src/constants";
import { getI18n } from "react-i18next";
import { CopyOutlined } from "@ant-design/icons";
import { formatPriceWithCurrency } from "src/utils/currency";
import { IDeleteProjectProps, IProjectProps } from "../interfaces";
import style from "../styles";
import { formatProjectLeads, mapBooleanValuesIntoLabels } from "../utils";
import { IFinalTrackSection, IProjectTerm } from "../../project/interfaces";
import { mapCountries } from "../../project/components/licensing/view-model-mapper";
import { IOption } from "../../project/components/account-information/types";

export default (
  projects: Array<IProjectProps>,
  allProjectTypes: IOption[],
  isDuplicatingProject: boolean,
  handleDeleteProjectClick: ({ id, name }: IDeleteProjectProps) => void,
  handleEditProjectClick: (id: string) => void,
  onDuplicateProject: (id: string) => void
): IProjectsDataSource[] => {
  const { language } = getI18n();
  const handleDeleteClick = ({ id, name }: IDeleteProjectProps) => {
    handleDeleteProjectClick({ id, name });
  };

  const getFinalTrackName = useCallback((tracks: IFinalTrackSection[]) => {
    return (tracks ?? [])
      .map(({ title }) => title)
      .filter((title) => title)
      .join(", ");
  }, []);

  const getFinalTrackArtist = useCallback((tracks: IFinalTrackSection[]) => {
    return (tracks ?? []).map(({ artistContacts }) =>
      artistContacts?.map((contact) => contact.name).join(", ")
    );
  }, []);

  const getTermTerritories = useCallback(
    (terms: IProjectTerm[]) => {
      return (terms ?? [])
        .map((term) => {
          let territories = "";
          if (term.territories?.worldwide) {
            territories = Regions.Worldwide;
            if (term.territories?.excludedCountries.length > 0) {
              const mappedCountries = mapCountries(
                term.territories?.excludedCountries ?? [],
                language
              );

              territories = `${territories} (excl ${mappedCountries.join(
                ", "
              )})`;
            }
          }
          if (term.territories?.includedCountries.length > 0) {
            const mappedCountries = mapCountries(
              term.territories?.includedCountries,
              language
            );
            territories = mappedCountries.join(", ");
          }
          return territories;
        })
        .filter((territories) => territories)
        .join(", ");
    },
    [language]
  );

  const getTermNames = useCallback((terms: IProjectTerm[]) => {
    return (terms ?? [])
      .map(({ name }) => name)
      .filter((termName) => termName)
      .join(", ");
  }, []);

  const getTermStartDate = useCallback((terms: IProjectTerm[]) => {
    return (terms ?? [])
      .map(({ startDate }) =>
        startDate ? format(parseISO(startDate), "MMM do, yyyy") : startDate
      )
      .filter((startDate) => startDate)
      .join(", ");
  }, []);

  const getTermEndDate = useCallback((terms: IProjectTerm[]) => {
    return (terms ?? [])
      .map(({ endDate, fixedDuration }) => {
        if (
          fixedDuration &&
          [
            LicenseFixedDurations.Archival,
            LicenseFixedDurations.InPerpetuity,
          ].includes(fixedDuration)
        ) {
          return fixedDuration;
        }
        return endDate ? format(parseISO(endDate), "MMM do, yyyy") : endDate;
      })
      .filter((endDate) => endDate)
      .join(", ");
  }, []);

  const getTrackMasterFee = useCallback((tracks: IFinalTrackSection[]) => {
    return (tracks ?? [])
      .map(({ masterFee }) =>
        masterFee
          ? formatPriceWithCurrency(masterFee.value, masterFee.currency)
          : undefined
      )
      .filter((masterFee) => masterFee)
      .join(", ");
  }, []);

  const getTrackPublisherFee = useCallback((tracks: IFinalTrackSection[]) => {
    return (tracks ?? [])
      .map(({ publisherFee }) =>
        publisherFee
          ? formatPriceWithCurrency(publisherFee.value, publisherFee.currency)
          : undefined
      )
      .filter((publisherFee) => publisherFee)
      .join(", ");
  }, []);

  return projects.map(
    ({
      id,
      name,
      campaignName,
      sequenceNo,
      leads,
      status,
      lastUpdated,
      client,
      tracks,
      terms,
      services,
      projectType,
      created,
      projectRegion,
      jobListingDate,
      hasPublishingBeenTriggered,
    }: IProjectProps) => {
      const title: ReactNode = <div>{name ?? ""}</div>;
      const finalTrackName = getFinalTrackName(tracks);
      const finalTrackArtist = getFinalTrackArtist(tracks);
      const territories = getTermTerritories(terms);
      const termName = getTermNames(terms);
      const termStartDate = getTermStartDate(terms);
      const termEndDate = getTermEndDate(terms);
      const trackMasterFee = getTrackMasterFee(tracks);
      const trackPublisherFee = getTrackPublisherFee(tracks);
      const currentProjectType =
        (allProjectTypes ?? []).find((type) => type.value === projectType)
          ?.label ?? "";
      const publisherNotified = mapBooleanValuesIntoLabels(
        hasPublishingBeenTriggered
      ).label;

      const items = [
        {
          label: (
            <MenuItem
              ariaLabel="Edit"
              buttonLabel="Edit"
              onClick={() => {
                handleEditProjectClick(id);
              }}
              id="edit-project-button"
              icon={<EditIcon />}
            />
          ),
          key: "Edit",
        },
        {
          label: (
            <MenuItem
              ariaLabel="Duplicate"
              buttonLabel="Duplicate"
              onClick={() => {
                onDuplicateProject(id);
              }}
              id="duplicate-project-button"
              isLoading={isDuplicatingProject}
              icon={<CopyOutlined css={style.duplicateButton} />}
            />
          ),
          key: "Duplicate",
        },
        {
          label: (
            <MenuItem
              ariaLabel="Delete"
              buttonLabel="Delete"
              onClick={() => {
                handleDeleteClick({ id, name });
              }}
              id="delete-project-button"
              icon={<DeleteIcon />}
            />
          ),
          key: "Delete",
        },
      ];
      return {
        key: id,
        name: (
          <Fragment>
            <PopOverText title={title} content={name ?? ""} />
            <div css={style.tableDataSubText} data-testid="project-product">
              {sequenceNo}
            </div>
          </Fragment>
        ),
        campaignName: (
          <div css={style.tableDataText} data-testid="project-sequence-no">
            {campaignName ?? ""}
          </div>
        ),
        projectId: (
          <Fragment>
            <div css={style.tableDataText} data-testid="project-client-name">
              {client ? client.name : <div />}
            </div>
            <div css={style.tableDataSubText}>
              {client?.holdingCompany ? client.holdingCompany.name : <div />}
            </div>
          </Fragment>
        ),
        projectType: (
          <Fragment>
            {services !== undefined ? (
              <div>
                <div css={style.tableDataText} data-testid="project-type">
                  {currentProjectType}
                </div>

                <div
                  css={style.tableDataSubText}
                  data-testid="project-music-type"
                >
                  {services.length > 0 ? startCase(services[0].musicType) : ""}
                </div>
              </div>
            ) : (
              <div />
            )}
          </Fragment>
        ),
        leads: (
          <div css={style.tableDataText} data-testid="project-leads">
            {leads && formatProjectLeads(leads)}
          </div>
        ),
        projectRegion: (
          <div css={style.tableDataText} data-testid="project-region">
            {projectRegion}
          </div>
        ),
        status: <StatusPill status={status} dataTestId="project-status" />,
        finalTrackName: (
          <Fragment>
            <Tooltip placement="bottom" title={finalTrackName}>
              <div css={[style.tableDataText, style.tableDataTextMaxWidth]}>
                {finalTrackName}
              </div>
            </Tooltip>
          </Fragment>
        ),
        finalTrackArtist: (
          <Fragment>
            <Tooltip placement="bottom" title={finalTrackArtist}>
              <div css={[style.tableDataText, style.tableDataTextMaxWidth]}>
                {finalTrackArtist}
              </div>
            </Tooltip>
          </Fragment>
        ),
        territory: (
          <Fragment>
            <Tooltip placement="bottom" title={territories}>
              <div css={[style.tableDataText, style.tableDataTextMaxWidth]}>
                {territories}
              </div>
            </Tooltip>
          </Fragment>
        ),
        term: (
          <Fragment>
            <Tooltip placement="bottom" title={termName}>
              <div css={[style.tableDataText, style.tableDataTextMaxWidth]}>
                {termName}
              </div>
            </Tooltip>
          </Fragment>
        ),
        startDate: (
          <Fragment>
            <Tooltip placement="bottom" title={termStartDate}>
              <div css={[style.tableDataText, style.tableDataTextMaxWidth]}>
                {termStartDate}
              </div>
            </Tooltip>
          </Fragment>
        ),
        endDate: (
          <Fragment>
            <Tooltip placement="bottom" title={termEndDate}>
              <div css={[style.tableDataText, style.tableDataTextMaxWidth]}>
                {termEndDate}
              </div>
            </Tooltip>
          </Fragment>
        ),
        masterFee: (
          <Fragment>
            <Tooltip placement="bottom" title={trackMasterFee}>
              <div css={[style.tableDataText, style.tableDataTextMaxWidth]}>
                {trackMasterFee}
              </div>
            </Tooltip>
          </Fragment>
        ),
        publishingFee: (
          <Fragment>
            <Tooltip placement="bottom" title={trackPublisherFee}>
              <div css={[style.tableDataText, style.tableDataTextMaxWidth]}>
                {trackPublisherFee}
              </div>
            </Tooltip>
          </Fragment>
        ),
        publishingNotified: (
          <Fragment>
            <div css={[style.tableDataText, style.tableDataTextMaxWidth]}>
              {publisherNotified}
            </div>
          </Fragment>
        ),
        created: (
          <Fragment>
            <div css={style.tableDataText}>
              {created && format(parseISO(created), "MMM do, yyyy")}
            </div>
            <div css={style.tableDataSubText}>
              {created && format(parseISO(created), "HH:mmaaa")}
            </div>
          </Fragment>
        ),
        jobListingDate: (
          <Fragment>
            <div
              css={style.tableDataText}
              data-testid="project-job-listing-date"
            >
              {jobListingDate &&
                format(parseISO(jobListingDate), "MMM do, yyyy")}
            </div>
            <div
              css={style.tableDataSubText}
              data-testid="project-job-listing-time"
            >
              {jobListingDate && format(parseISO(jobListingDate), "HH:mmaaa")}
            </div>
          </Fragment>
        ),
        lastUpdated: (
          <Fragment>
            <div
              css={style.tableDataText}
              data-testid="project-last-updated-date"
            >
              {lastUpdated && format(parseISO(lastUpdated), "MMM do, yyyy")}
            </div>
            <div
              css={style.tableDataSubText}
              data-testid="project-last-updated-time"
            >
              {lastUpdated && format(parseISO(lastUpdated), "HH:mmaaa")}
            </div>
          </Fragment>
        ),
        edit: (
          <Dropdown
            aria-label="action-items-dropdown"
            menu={{ items }}
            data-testid="action-items-dropdown"
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode}
            placement="bottomRight"
            css={style.dropdownMenu}
          >
            <MoreInfoButton
              showTooltip
              customStyles={style.moreInfoButton}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        ),
      };
    }
  );
};
