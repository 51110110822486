import React from "react";
import { Form, Input, Typography } from "antd";
import { Rule } from "antd/lib/form";
import style from "../../styles";

interface IProps {
  title: string;
  name: string;
  rules: Rule[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContactInput = ({
  title,
  name,
  rules,
  onChange,
}: IProps): React.ReactElement => {
  return (
    <div>
      <div css={style.basicInputContainer}>
        <Typography.Text css={style.basicInputLabel}>{title}</Typography.Text>
      </div>
      <Form.Item css={style.formItem} name={name} rules={rules}>
        <Input
          autoComplete="off"
          data-testid={name}
          name={name}
          onChange={onChange}
        />
      </Form.Item>
    </div>
  );
};

export default ContactInput;
