import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

const contactColumnOrRow = `
display: flex;
justify-content: space-between;
gap: 0.5rem;
margin: 0 !important;
`;

const contactDetails = `
display: flex;
flex-grow: 1;
gap: 1.5rem;
justify-content: flex-between;

`;
export default {
  primaryContact: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
  `,
  contactRowContainer: css`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  `,
  floatLabel: css`
    position: relative;
    color: ${theme.colors.background.darkGray};
    flex-grow: 1;
  `,
  contactRowDetails: css`
    ${contactDetails}
    flex-direction: row;
  `,

  contactColumnDetails: css`
    ${contactDetails}
    flex-direction: column;
  `,
  contactRow: css`
    ${contactColumnOrRow}
    flex-direction: row;
  `,
  contactColumn: css`
    ${contactColumnOrRow}
    flex-direction: column;
  `,

  container: css`
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .floatingLabel {
      margin-bottom: 0 !important;
      @media ${queries.medium} {
        margin-bottom: 0.781rem !important;
      }
    }

    @media ${queries.medium} {
      padding-left: 0.9rem !important;
      padding-right: 0.9rem !important;
    }

    .ant-divider {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 1rem !important;
      border-top: 0 !important;

      ::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid #c4c4c4;
      }
    }

    .ant-row {
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;

      & .ant-divider {
        margin-top: 0 !important;
      }
      :nth-of-type(1) {
        & .ant-divider {
          margin-top: 1rem !important;
          margin-bottom: 1.8rem !important;
        }
      }
    }
  `,
  emptyStateText: css`
    font-family: ${theme.fonts.sofiaPro};
    color: #888491;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 1rem;
  `,
  input: css`
    input {
      border: 1px solid ${theme.colors.background.lightGray};
      height: 3.125rem;
      border-radius: 0.25rem;
    }
  `,
  deleteIconContainer: css`
    flex-grow: 0;
    float: right;
    display: flex;
    align-self: center;
    padding-top: 1.5rem;
    flex-direction: column;
    justify-content: end;
  `,
  iconContainer: css`
    margin-top: 1rem;
    margin-right: 0.5rem;
    padding-top: 0 !important;
    margin-left: 0.5rem;

    @media ${queries.medium} {
      margin-left: 0;
    }
  `,
  mobileDelete: css`
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  `,
};
