import countries from "i18n-iso-countries";
import { TFunction } from "react-i18next";
import { Regions } from "src/constants";
import registerTerritoryLocales from "../helpers";

registerTerritoryLocales();

export interface ITreeDataNode {
  title?: string;
  key: string;
  children?: ITreeDataNode[];
  className?: string;
  parents?: string[];
  code?: string;
  treeNumber?: number;
  isParentGrouping?: boolean;
  isCountryGrouping?: boolean;
}

const generateTableData = (
  treeData: ITreeDataNode[],
  language: string,
  t: TFunction<string>,
  treeNumber: number
): ITreeDataNode[] => {
  treeData.map((node: ITreeDataNode) => {
    const topLevelNode = node;

    if (topLevelNode.className) {
      topLevelNode.className += " FirstLevel";
    } else {
      topLevelNode.className = "FirstLevel";
    }

    if (node.key !== Regions.Other) {
      topLevelNode.className += " noSwitcher";
    }

    topLevelNode.title = t(
      `ProjectsPage##licensingSection##TerritoryTitles##${topLevelNode.key}`
    );

    topLevelNode.code = topLevelNode.key;
    topLevelNode.treeNumber = treeNumber;

    if (topLevelNode.children) {
      topLevelNode.children = topLevelNode.children.map(
        (nodeSecondLevel: ITreeDataNode) => {
          const secondLevelNode = nodeSecondLevel;
          secondLevelNode.className = "SecondLevel";

          if (topLevelNode.code) {
            secondLevelNode.parents = [topLevelNode.code];

            const secondLevelNodeCountryName = countries.getName(
              secondLevelNode.key,
              language
            );

            if (secondLevelNodeCountryName) {
              secondLevelNode.title = secondLevelNodeCountryName;
            } else {
              secondLevelNode.title = t(
                `ProjectsPage##licensingSection##TerritoryTitles##${secondLevelNode.key}`
              );
            }

            secondLevelNode.code = secondLevelNode.key;
            secondLevelNode.treeNumber = treeNumber;
            secondLevelNode.key = `${secondLevelNode.parents.join(".")}.${
              secondLevelNode.code
            }`;
          }

          if (secondLevelNode.children) {
            secondLevelNode.children = secondLevelNode.children.map(
              (nodeThirdLevel: ITreeDataNode) => {
                const thirdLevelNode = nodeThirdLevel;

                thirdLevelNode.className = "ThirdLevel";

                const thirdLevelNodeCountryName = countries.getName(
                  thirdLevelNode.key,
                  language
                );

                if (thirdLevelNodeCountryName) {
                  thirdLevelNode.title = thirdLevelNodeCountryName;
                } else {
                  thirdLevelNode.title = t(
                    `ProjectsPage##licensingSection##TerritoryTitles##${thirdLevelNode.key}`
                  );
                }

                if (topLevelNode.code && secondLevelNode.code) {
                  thirdLevelNode.parents = [
                    topLevelNode.code,
                    secondLevelNode.code,
                  ];

                  thirdLevelNode.code = thirdLevelNode.key;
                  thirdLevelNode.treeNumber = treeNumber;
                  thirdLevelNode.key = `${thirdLevelNode.parents.join(".")}.${
                    thirdLevelNode.key
                  }`;
                }

                return thirdLevelNode;
              }
            );
          }
          return secondLevelNode;
        }
      );
    }

    return topLevelNode;
  });

  return treeData;
};

export const firstTreeData = (
  language: string,
  t: TFunction<string>
): ITreeDataNode[] => {
  return generateTableData(
    [
      {
        key: Regions.Worldwide,
        className: "WorldWide",
        isParentGrouping: true,
      },
      {
        key: Regions.Africa,
        isParentGrouping: true,
        children: [
          {
            key: "Central Africa",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "AGO" },
              { key: "CMR" },
              { key: "CAF" },
              { key: "TCD" },
              { key: "COD" },
              { key: "GNQ" },
              { key: "GAB" },
              { key: "STP" },
            ],
          },
          {
            key: "Eastern Africa",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "BDI" },
              { key: "COM" },
              { key: "DJI" },
              { key: "ERI" },
              { key: "ETH" },
              { key: "KEN" },
              { key: "MDG" },
              { key: "MUS" },
              { key: "MWI" },
              { key: "MYT" },
              { key: "MOZ" },
              { key: "RWA" },
              { key: "TZA" },
              { key: "SYC" },
              { key: "SOM" },
              { key: "UGA" },
              { key: "ZMB" },
              { key: "ZWE" },
            ],
          },
          {
            key: "Northern Africa",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "DZA" },
              { key: "EGY" },
              { key: "LBY" },
              { key: "MRT" },
              { key: "MAR" },
              { key: "SDN" },
              { key: "TUN" },
              { key: "ESH" },
            ],
          },
          {
            key: "Southern Africa",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [{ key: "ZAF" }, { key: "BWA" }, { key: "NAM" }],
          },
          {
            key: "Western Africa",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "BFA" },
              { key: "CIV" },
              { key: "GMB" },
              { key: "CPV" },
              { key: "GHA" },
              { key: "GIN" },
              { key: "GNB" },
              { key: "LBR" },
              { key: "MLI" },
              { key: "MRT" },
              { key: "NER" },
              { key: "NGA" },
              { key: "SLE" },
              { key: "SEN" },
              { key: "TGO" },
            ],
          },
        ],
      },
      {
        key: Regions.Americas,
        isParentGrouping: true,
        children: [
          {
            key: "Central America",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "BLZ" },
              { key: "CRI" },
              { key: "SLV" },
              { key: "GTM" },
              { key: "HND" },
              { key: "NIC" },
              { key: "PAN" },
            ],
          },
          {
            key: "Northern America",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "Alaska" },
              { key: "AIA" },
              { key: "ATG" },
              { key: "ABW" },
              { key: "BHS" },
              { key: "BRB" },
              { key: "BLZ" },
              { key: "BMU" },
              { key: "VGB" },
              { key: "CAN" },
              { key: "Caribbean Netherlands" },
              { key: "CYM" },
              { key: "CRI" },
              { key: "CUB" },
              { key: "CUW" },
              { key: "DMA" },
              { key: "DOM" },
              { key: "SLV" },
              { key: "GRL" },
              { key: "GRD" },
              { key: "GLP" },
              { key: "GTM" },
              { key: "HTI" },
              { key: "HND" },
              { key: "JAM" },
              { key: "MTQ" },
              { key: "MEX" },
              { key: "MSR" },
              { key: "NIC" },
              { key: "PAN" },
              { key: "PRI" },
              { key: "BLM" },
              { key: "KNA" },
              { key: "LCA" },
              { key: "MAF" },
              { key: "SPM" },
              { key: "VCT" },
              { key: "SXM" },
              { key: "TTO" },
              { key: "TCA" },
              { key: "USA" },
              { key: "VIR" },
            ],
          },
          {
            key: "Southern America",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "ARG" },
              { key: "BOL" },
              { key: "BRA" },
              { key: "CHL" },
              { key: "COL" },
              { key: "ECU" },
              { key: "FLK" },
              { key: "GUF" },
              { key: "GUY" },
              { key: "PRY" },
              { key: "PER" },
              { key: "SGS" },
              { key: "SUR" },
              { key: "URY" },
              { key: "VEN" },
            ],
          },
        ],
      },
      {
        key: Regions.Asia,
        isParentGrouping: true,
        children: [
          {
            key: "Central Asia",
            isParentGrouping: true,
            children: [
              { key: "AFG" },
              { key: "KAZ" },
              { key: "KGZ" },
              { key: "TJK" },
              { key: "TKM" },
              { key: "UZB" },
            ],
          },
          {
            key: "Eastern Asia",
            isParentGrouping: true,
            children: [
              { key: "CHN" },
              { key: "HKG" },
              { key: "JPN" },
              { key: "MAC" },
              { key: "MNG" },
              { key: "PRK" },
              { key: "KOR" },
              { key: "TWN" },
            ],
          },
          {
            key: "Northern Asia",
            isParentGrouping: true,
            children: [
              { key: "JPN" },
              { key: "Mainland China" },
              { key: "HKG" },
              { key: "MAC" },
              { key: "MNG" },
              { key: "PRK" },
              { key: "KOR" },
              { key: "TWN" },
            ],
          },
          {
            key: "North Eastern Asia",
            isParentGrouping: true,
            children: [{ key: "JPN" }, { key: "PRK" }, { key: "KOR" }],
          },
          {
            key: "Southern Asia",
            isParentGrouping: true,
            children: [
              { key: "AFG" },
              { key: "BGD" },
              { key: "BTN" },
              { key: "IND" },
              { key: "MDV" },
              { key: "NPL" },
              { key: "PAK" },
              { key: "LKA" },
            ],
          },
          {
            key: "South Eastern Asia",
            isParentGrouping: true,
            children: [
              { key: "BRN" },
              { key: "KHM" },
              { key: "TLS" },
              { key: "LAO" },
              { key: "MMR" },
              { key: "THA" },
              { key: "MYS" },
              { key: "PHL" },
              { key: "SGP" },
              { key: "IDN" },
              { key: "VNM" },
            ],
          },
          {
            key: "Greater China",
            isParentGrouping: true,
            children: [
              { key: "Mainland China" },
              { key: "HKG" },
              { key: "MAC" },
              { key: "TWN" },
            ],
          },
        ],
      },
      {
        key: Regions.Other,
        isParentGrouping: true,
        isCountryGrouping: true,
        children: [
          { key: "ATA" },
          { key: "BEN" },
          { key: "BWA" },
          { key: "BVT" },
          { key: "IOT" },
          { key: "COG" },
          { key: "FRO" },
          { key: "ATF" },
          { key: "HMD" },
          { key: "LSO" },
          { key: "NAM" },
          { key: "NRU" },
          { key: "REU" },
          { key: "SHN" },
          { key: "SJM" },
          { key: "SWZ" },
          { key: "UMI" },
          { key: "ALA" },
          { key: "BES" },
          { key: "GGY" },
          { key: "IMN" },
          { key: "JEY" },
          { key: "SSD" },
        ],
      },
    ],
    language,
    t,
    1
  );
};

export const secondTreeData = (
  language: string,
  t: TFunction<string>
): ITreeDataNode[] => {
  return generateTableData(
    [
      {
        key: Regions.Europe,
        isParentGrouping: true,
        children: [
          {
            key: "CEE (Central and Eastern Europe)",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "ALB" },
              { key: "BIH" },
              { key: "BLR" },
              { key: "BGR" },
              { key: "HRV" },
              { key: "CZE" },
              { key: "EST" },
              { key: "HUN" },
              { key: "Kosovo" },
              { key: "LVA" },
              { key: "LTU" },
              { key: "MKD" },
              { key: "MDA" },
              { key: "MNE" },
              { key: "POL" },
              { key: "ROU" },
              { key: "RUS" },
              { key: "SRB" },
              { key: "SVK" },
              { key: "SVN" },
              { key: "UKR" },
            ],
          },
          {
            key: "Eastern Europe",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "BLR" },
              { key: "BGR" },
              { key: "CZE" },
              { key: "HUN" },
              { key: "MDA" },
              { key: "POL" },
              { key: "ROU" },
              { key: "RUS" },
              { key: "SVK" },
              { key: "UKR" },
            ],
          },
          {
            key: "Southern Europe",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "AND" },
              { key: "GIB" },
              { key: "GRC" },
              { key: "ITA" },
              { key: "PRT" },
              { key: "SMR" },
              { key: "ESP" },
              { key: "VAT" },
            ],
          },
          {
            key: "South Eastern Europe (SEE)",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "ALB" },
              { key: "AUT" },
              { key: "BGR" },
              { key: "BIH" },
              { key: "HRV" },
              { key: "GRC" },
              { key: "HUN" },
              { key: "ITA" },
              { key: "MKD" },
              { key: "MDA" },
              { key: "MNE" },
              { key: "SRB" },
              { key: "SVK" },
              { key: "SVN" },
              { key: "UKR" },
            ],
          },
          {
            key: "Western Europe",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "BEL" },
              { key: "FRA" },
              { key: "DEU" },
              { key: "IRL" },
              { key: "LUX" },
              { key: "NLD" },
              { key: "CHE" },
              { key: "GBR" },
            ],
          },
          {
            key: Regions.Other,
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "ARM" },
              { key: "AZE" },
              { key: "DNK" },
              { key: "FIN" },
              { key: "GEO" },
              { key: "ISL" },
              { key: "LIE" },
              { key: "MLT" },
              { key: "MCO" },
              { key: "NOR" },
              { key: "SWE" },
            ],
          },
        ],
      },
      {
        key: Regions.MiddleEast,
        isParentGrouping: true,
        isCountryGrouping: true,
        children: [
          { key: "BHR" },
          { key: "CYP" },
          { key: "EGY" },
          { key: "IRN" },
          { key: "IRQ" },
          { key: "ISR" },
          { key: "JOR" },
          { key: "KWT" },
          { key: "LBN" },
          { key: "OMN" },
          { key: "PSE" },
          { key: "QAT" },
          { key: "SAU" },
          { key: "SDN" },
          { key: "SYR" },
          { key: "TUR" },
          { key: "ARE" },
          { key: "YEM" },
        ],
      },
      {
        key: Regions.Namet,
        isParentGrouping: true,
        children: [
          {
            key: "North Africa",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "DZA" },
              { key: "EGY" },
              { key: "LBY" },
              { key: "MRT" },
              { key: "MAR" },
              { key: "SDN" },
              { key: "TUN" },
              { key: "ESH" },
            ],
          },
          {
            key: "Middle Eastern",
            isParentGrouping: true,
            isCountryGrouping: true,
            children: [
              { key: "BHR" },
              { key: "CYP" },
              { key: "EGY" },
              { key: "IRN" },
              { key: "IRQ" },
              { key: "ISR" },
              { key: "JOR" },
              { key: "KWT" },
              { key: "LBN" },
              { key: "OMN" },
              { key: "PSE" },
              { key: "QAT" },
              { key: "SAU" },
              { key: "SDN" },
              { key: "SYR" },
              { key: "TUR" },
              { key: "ARE" },
              { key: "YEM" },
            ],
          },
        ],
      },
      {
        key: Regions.Rub,
        isParentGrouping: true,
        isCountryGrouping: true,
        children: [{ key: "RUS" }, { key: "BLR" }, { key: "UKR" }],
      },
    ],
    language,
    t,
    2
  );
};

export const thirdTreeData = (
  language: string,
  t: TFunction<string>
): ITreeDataNode[] => {
  return generateTableData(
    [
      {
        key: Regions.Oceania,
        isParentGrouping: true,
        isCountryGrouping: true,
        children: [
          { key: "AUS" },
          { key: "CXR" },
          { key: "CCK" },
          { key: "NZL" },
          { key: "NFK" },
          { key: "FJI" },
          { key: "NCL" },
          { key: "Papua" },
          { key: "West Papua" },
          { key: "PNG" },
          { key: "SLB" },
          { key: "VUT" },
          { key: "FSM" },
          { key: "GUM" },
          { key: "KIR" },
          { key: "MHL" },
          { key: "Maura" },
          { key: "MNP" },
          { key: "PLW" },
          { key: "Wake Island" },
          { key: "ASM" },
          { key: "COK" },
          { key: "Easter Island" },
          { key: "PYF" },
          { key: "Hawaii" },
          { key: "NIU" },
          { key: "PCN" },
          { key: "WSM" },
          { key: "TKL" },
          { key: "TON" },
          { key: "TUV" },
          { key: "WLF" },
        ],
      },
    ],
    language,
    t,
    3
  );
};
