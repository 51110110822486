import { ICognitoContext } from "src/auth/context";

export interface ISimpleUser {
  id: string;
  name: string;
  email: string;
}

export interface IAuthorization {
  Authorization: string;
}

export enum eApplicationType {
  Portal = "Portal",
  ProjectsInternal = "ProjectsInternal",
  LibraryFull = "LibraryFull",
  ProjectsClient = "ProjectsClient",
  LibraryReadOnly = "LibraryReadOnly",
  Validate = "Validate",
  Resources = "Resources",
  Requests = "Requests",
  Insights = "Insights",
  TeamManagement = "TeamManagement",
}

export type AuthMockWithoutSessionValid = Partial<ICognitoContext>;
