import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Typography } from "antd";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common/lib/utils";
import DeleteButton from "src/components/control-buttons/delete-button";
import { INameLinkState } from "src/pages/projects/project/interfaces";
import ControlButton from "src/components/control-buttons/base-button";
import { PlusOutlined } from "@ant-design/icons";
import style from "../final-track-details/styles";
import styles from "./styles";
import mainStyles from "../../../../styles";

interface IProps {
  data: INameLinkState;
  index: number;
  onDeleteRow: (index: number) => void;
  onUpdateRow: (index: number, musicSearch: INameLinkState) => void;
  nameHelpText?: string;
  totalRows: number;
  fieldName: string;
  customLinkText?: string;
  addNewRowItem: () => void;
}

const NameLinkRow = ({
  data,
  index,
  onDeleteRow,
  onUpdateRow,
  nameHelpText,
  totalRows,
  fieldName,
  customLinkText,
  addNewRowItem,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  useWindowSize();
  const isMobile = getIsMobile();

  const [name, setName] = useState<string>(data.name ?? "");
  const [link, setLink] = useState<string>(data.link ?? "");
  return (
    <div
      className="name-link-row"
      css={
        isMobile
          ? style.mobileMusicSectionContainer
          : style.musicSectionContainer
      }
    >
      <div css={!isMobile && mainStyles.sizeOfHalfDiv}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t(nameHelpText ?? "Name")}
          </Typography.Text>
        </div>
        <Input
          id={`${fieldName}-name-${index}`}
          aria-label={t("Name")}
          onBlur={(e) => {
            if (e.target.value !== name) {
              setName(e.target.value);
              onUpdateRow(index, {
                name: e.target.value,
                link: data.link,
                isNew: data.isNew,
                key: data.key,
              });
            }
          }}
          defaultValue={name}
        />
      </div>

      <div css={!isMobile && mainStyles.sizeOfHalfDiv}>
        <div css={mainStyles.basicInputContainer}>
          <Typography.Text css={mainStyles.basicInputLabel}>
            {t(customLinkText ?? "ProjectsPage##Link")}
          </Typography.Text>
        </div>
        <Input
          id={`${fieldName}-link-${index}`}
          aria-label={t("ProjectsPage##Link")}
          onBlur={(e) => {
            if (e.target.value !== link) {
              setLink(e.target.value);
              onUpdateRow(index, {
                name: data.name,
                link: e.target.value,
                isNew: data.isNew,
                key: data.key,
              });
            }
          }}
          defaultValue={link}
        />
      </div>
      <div css={mainStyles.iconsContainer}>
        <div
          css={
            totalRows > 1
              ? styles.deleteIconContainer
              : styles.invisibleContainer
          }
        >
          <DeleteButton onClick={() => onDeleteRow(index)} />
        </div>
        <div
          css={
            index === totalRows - 1
              ? styles.addIconContainer
              : styles.invisibleContainer
          }
        >
          <div css={mainStyles.addIconContainer}>
            <ControlButton
              showTooltip
              tooltipColor="#222222"
              label={t("Add new")}
              data-testid="add-new-row-button"
              onClick={addNewRowItem}
            >
              <PlusOutlined />
            </ControlButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameLinkRow;
