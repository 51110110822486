import { SerializedStyles, css } from "@emotion/react";
import theme from "src/theme";

const dropDownMenu = `
  width: 292px;
  height: 48px;
  top: 8px;
  padding: 4px 16px 12px 16px;
  margin-bottom: 8px;
  gap: 16px;
`;
const containerStyles = `button {
    display: flex;
    align-items: center;
    -webkit-box-shadow: none;
    padding-left: 0 !important;
    background: ${theme.colors.white};
    min-height: 100%;
    color: ${theme.colors.primary.blue};
    border:none;
    font-size: 16px;
    font-weight: 600;
    svg {
      margin-right: 0;
      circle {
        stroke: none;
      }
      line {
        stroke: ${theme.colors.primary.blue};
      }
    }
  
    :hover {
      background: ${theme.colors.white} !important;
      color: ${theme.colors.secondary.navyBlue} !important;
      svg {
        margin-right: 0;
        circle {
          stroke: none;
        }
        line {
          stroke: ${theme.colors.secondary.navyBlue};
        }
      }
    }
    :focus {
      background: ${theme.colors.white};
      color: ${theme.colors.secondary.navyBlue};
      svg {
        margin-right: 0;
        circle {
          stroke: none;
        }
        line {
          stroke: ${theme.colors.secondary.navyBlue};
        }
      }
    }
  }`;
export default {
  buttonContainer: css`
    ${containerStyles}
    height: 100%;
  `,
  addFormWithIpi: css`
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
  `,
  addContainer: css`
    background-color: #f0f0f0;
    padding: 8px 12px;
    height: 55px !important;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  `,
  emptyTalents: css`
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    height: 80%;
  `,
  footerButtons: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  talentUnderAgency: css`
    margin: 0 16px;
    border-left: 1px solid #d9d9d9;
  `,
  talentsUnderAgencyWrapper: css`
    padding: 0 16px;
    height: 48px;
    :hover {
      cursor: pointer;
      background: #deebff;
    }
  `,
  emailErrorVisibility: css`
    visibility: hidden;
  `,

  talentsOrAgencyWrapper: css`
    font-size: 16px;
    padding-top: 6px;
    padding-bottom: 2px;
    padding-left: 16px;
    height: 100%;
    :hover {
      cursor: pointer;
      background: #deebff;
    }
  `,
  emailError: css`
    color: var(--colors-functional-red) !important;
    font-weight: 400;
  `,
  lastRow: css`
    height: 102 px;
  `,
  buttonContent: css`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  addButton: css`
    width: 100%;
    background-color: #f0f0f0 !important;
  `,
  addFormContainer: css`
    display: flex;
    height: 285px;
    row-gap: 0.5rem;
    flex-direction: column;
    padding: 6px 16px 6px 16px;
  `,
  addForm: css`
    display: flex;
    flex-direction: column;
  `,
  addText: css`
    font-size: 14px;
    font-weight: 400;
  `,
  arrowWrapper: css`
    display: flex;
    justify-content: flex-end;
    width: 20px;
  `,
  customButton: css`
    border-radius: 8px;
    width: fit-content;
  `,
  addButtonText: css`
    color: ${theme.colors.black};
    padding-top: 2px;
  `,
  tagWrapper: css`
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;
  `,
  bodyContentTags: css`
    display: flex;
    max-width: inherit;
    flex-direction: row;
    width: 100%;
    padding: 16px;
    overflow: auto;
    gap: 0.2rem;
    ::-webkit-scrollbar {
      height: 0;
    }
  `,
  talentHubLoadingContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  `,

  talentHubSearchLoadWrapper: css`
    ${dropDownMenu}
    height: 100%;
    width: 100%;
  `,

  selected: css`
    background-color: rgb(222, 235, 255);
  `,
  talentInputWrapper: css`
    :hover {
      cursor: pointer;
      background: #deebff;
    }
  `,
  talentMenuListMainText: css`
    font-weight: 400;
    size: 14px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  talentMenuListSubText: css`
    font-weight: 400;
    size: 12px;
    line-height: 20px;
    color: #888;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  dropDownWrapper: css`
    position: relative;
  `,
  customInput: css`
    border: none;
    background-color: ${theme.colors.background.white} !important;
    width: 100%;
    height: 98%;
    .ant-input {
      padding: 0;
    }
  `,
  mainDropDownContainer: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.primary};
    margin-bottom: 0.5rem;
    padding: 1rem 0.6rem 1rem 1rem;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    height: 56px;
    width: 100%;
    outline: none;
    white-space: nowrap;
    background-color: ${theme.colors.white};
    position: relative;
    cursor: pointer;
    .ant-input::placeholder {
      font-size: 16px;
    }
  `,
  iconButton: css`
    background-color: ${theme.colors.white};
    border: none;
    :hover {
      cursor: pointer;
    }
  `,
  buttonDivider: css`
    margin: 0;
    width: 100%;
  `,
  bodyAndFooterContainer: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  iconContainer: css`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-right: 0.5rem;
    :hover {
      font-weight: bold;
      cursor: pointer;
    }
    button {
      padding: 0 !important;
      margin: 0 !important;
    }
  `,
  buttonContainerClearAll: css`
    display: flex;
    font-size: 0.9rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 0;
    padding: 0;
    background-color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    border: 0;
    color: ${theme.colors.primary.blue};
  `,
  mainDropDownBody(width: number, maxListHeight?: number): SerializedStyles {
    return css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      background: ${theme.colors.white};
      z-index: 1000;
      min-width: 309px !important;
      transform: translate(-${width}px, 0);
      height: ${maxListHeight || "480"}px;
      box-sizing: border-box;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      box-shadow: 0 16px 32px rgba(0, 0, 0, 0.5);
    `;
  },
  tag(isOpenForm: boolean): SerializedStyles {
    return css`
      height: 36px;
      padding: 8px;
      background: ${isOpenForm ? "#FFD6C3" : "#E0EBFD"};
      border: none;
    `;
  },

  gradientContainer: css`
    display: flex;
    padding: 1rem;
    justify-content: flex-end;
    height: 30px;
  `,
  dropDownFooter: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    align-items: center;
    padding: 8px 16px;
    column-gap: 0.5rem;
  `,
  labelContainer: css`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    color: #111;
    font-size: 16px;
    font-family: ${theme.fonts.sofiaPro};
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  contentContainer: css`
    height: 100%;
    width: 100%;
    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: visible !important;
    }
  `,
};
