import { ReactElement, useCallback } from "react";
import { IScript } from "src/pages/projects/project/interfaces";
import { Checkbox, Form, Input, Typography } from "antd";
import ButtonWithLabelAndPlusIcon from "src/components/plus-button-with-label";
import { useTranslation } from "react-i18next";
import { SuccessToast } from "src/components/toast-notification";
import { DeleteIcon } from "src/app/assets/icons/component-icons";
import { FormInstance } from "antd/lib/form";
import mainStyle from "src/pages/projects/project/styles";
import { getIsMobile } from "@songtradr/spa-common/lib/utils";
import styles from "../../styles";

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  scripts: IScript[];
  handleScripts: (scripts: IScript[]) => void;
  nameField: string;
  durationField: string;
  cutdownsField: string;
  noField: string;
  hasUnlimitedScripts: boolean;
  detailsField?: string;
}

interface IScriptInputFieldProps {
  label: string;
  name: string;
  initialValue: string;
  dataTestId: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ScriptInputField = ({
  label,
  name,
  initialValue,
  dataTestId,
  onChange,
}: IScriptInputFieldProps): ReactElement => {
  return (
    <div>
      <div css={mainStyle.basicInputContainer}>
        <Typography.Text css={mainStyle.basicInputLabel}>
          {label}
        </Typography.Text>
      </div>
      <Form.Item
        css={mainStyle.formItem}
        initialValue={initialValue}
        name={name}
      >
        <Input
          type="text"
          id={name}
          name={name}
          onChange={onChange}
          data-testid={dataTestId}
        />
      </Form.Item>
    </div>
  );
};

const Scripts = ({
  form,
  scripts,
  handleScripts,
  nameField,
  durationField,
  cutdownsField,
  noField,
  hasUnlimitedScripts,
  detailsField = "",
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const onAddScript = () => {
    const newScripts = [...scripts];
    newScripts.push({
      name: "",
      details: "",
      duration: 0,
      includesCutdowns: false,
    });
    const fName = `${nameField}-${newScripts.length - 1}`;
    const fDetails = `${detailsField}-${newScripts.length - 1}`;
    const fDuration = `${durationField}-${newScripts.length - 1}`;
    const fCutodowns = `${cutdownsField}-${newScripts.length - 1}`;
    form.setFieldsValue({
      [fName]: "",
      [fDetails]: "",
      [fDuration]: 0,
      [fCutodowns]: false,
    });
    handleScripts(newScripts);
  };

  const onDeleteScript = (index: number) => {
    const newScripts = [...scripts];
    newScripts.splice(index, 1);
    handleScripts(newScripts);
    SuccessToast(`Script deleted`);
  };

  const getScriptKeyIndex = useCallback(
    (scriptName: string, scriptIndex: number) => {
      return `${scriptName}${scriptIndex}`;
    },
    []
  );
  return (
    <div css={mainStyle.projectDetailsContainer}>
      {scripts?.map((script: IScript, index: number) => (
        <div
          key={getScriptKeyIndex(script.name, index)}
          css={styles.filmsScripts}
        >
          {index === 0 ? (
            <div css={!isMobile && mainStyle.sizeOfQuarterDiv}>
              <div css={mainStyle.basicInputContainer}>
                <Typography.Text css={mainStyle.basicInputLabel}>
                  {t("ProjectsPage##licensingSection##Scripts #")}
                </Typography.Text>
              </div>
              <Form.Item css={mainStyle.formItem}>
                <Input
                  value={scripts.length}
                  className={`${noField}`}
                  id={`${noField}`}
                  type="text"
                  disabled
                  width={138}
                  data-testid="scripts-number-textbox"
                />
              </Form.Item>
            </div>
          ) : null}
          <div css={styles.filmOrScriptsContainer}>
            <ScriptInputField
              label={t("ProjectsPage##licensingSection##Script name")}
              name={`${nameField}-${index}`}
              initialValue={script.name}
              dataTestId="script-name-textbox"
              onChange={(e) => {
                const newScripts: IScript[] = [...scripts];
                newScripts[index] = {
                  ...scripts[index],
                  name: e.target.value,
                };
                handleScripts(newScripts);
              }}
            />
            <ScriptInputField
              label={t("ProjectsPage##licensingSection##Script details")}
              name={`${detailsField}-${index}`}
              initialValue={script.details}
              dataTestId="script-details-textbox"
              onChange={(e) => {
                const newScripts: IScript[] = [...scripts];
                newScripts[index] = {
                  ...scripts[index],
                  details: e.target.value,
                };
                handleScripts(newScripts);
              }}
            />
            <div>
              <div css={mainStyle.basicInputContainer}>
                <Typography.Text css={mainStyle.basicInputLabel}>
                  {t("ProjectsPage##licensingSection##Duration (secs)")}
                </Typography.Text>
              </div>
              <Form.Item
                css={mainStyle.formItem}
                initialValue={script.duration}
                name={`${durationField}-${index}`}
              >
                <Input
                  id={`${durationField}-${index}`}
                  type="number"
                  className={`${durationField}-${index}`}
                  css={!isMobile && styles.durationInput}
                  onChange={(e) => {
                    const duration = Number(e.target.value);
                    const newScripts: IScript[] = [...scripts];
                    newScripts[index] = {
                      ...scripts[index],
                      duration,
                    };
                    handleScripts(newScripts);
                  }}
                  data-testid="script-duration-textbox"
                />
              </Form.Item>
            </div>
            <div css={!isMobile && styles.includeCutdown}>
              <Form.Item
                css={mainStyle.formItem}
                initialValue={script.includesCutdowns}
                name={`${cutdownsField}-${index}`}
              >
                <Checkbox
                  checked={script.includesCutdowns}
                  onChange={() => {
                    const newScripts: IScript[] = [...scripts];
                    const includesCutdowns = !script.includesCutdowns;
                    newScripts[index] = {
                      ...scripts[index],
                      includesCutdowns,
                    };
                    handleScripts(newScripts);
                  }}
                  data-testid="includes-cutdowns-checkbox"
                  style={!isMobile ? { paddingTop: 40 } : undefined}
                  css={styles.checkBoxText}
                >
                  {t("ProjectsPage##licensingSection##Includes cutdowns")}
                </Checkbox>
              </Form.Item>
            </div>
            <div css={!isMobile && styles.deleteButton}>
              <Form.Item name="delete" css={mainStyle.formItem}>
                <div css={styles.iconContainerPointer(scripts.length === 1)}>
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => onDeleteScript(index)}
                    onClick={() => onDeleteScript(index)}
                    css={styles.iconContainer(scripts.length === 1)}
                    data-testid="delete-script-button"
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
      ))}
      {hasUnlimitedScripts === false ? (
        <div css={styles.addScriptButton}>
          <ButtonWithLabelAndPlusIcon
            onClick={onAddScript}
            dataTestId="add-another-script"
            ariaLabel="ProjectsPage##licensingSection##Add another script"
            label="ProjectsPage##licensingSection##Add another script"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Scripts;
