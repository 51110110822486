import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import getProjectsReport from "src/api/attachments/get-estimated-music-report-url";
import { debounce } from "lodash";
import useAuth from "src/auth/use-auth";
import useProject from "src/providers/project/hooks";
import ButtonWithLabelAndPlusIcon from "../plus-button-with-label";
import { ErrorToast, SuccessToast } from "../toast-notification";

interface IProps {
  onIsLoadingReport: (isLoading: boolean) => void;
  ariaLabel: string;
  label: string;
  dataTestId: string;
  reportType: string;
  reportFileName: string;
}

export enum ReportType {
  MusicEstimate = "_musicestimate",
  LicenseRequest = "_licenserequest",
  MasterLicenseAgreement = "_masterlicenseagreement",
  PublishingLicenseAgreement = "_publishinglicenseagreement",
  ScheduleADocument = "_schedulea",
  PublishingSSA = "_publishingsinglesongassignment",
  MasterSSA = "_mastersinglesongassignment",
}

export enum ReportLabelName {
  MusicEstimate = "Create Music estimate report",
  LicenseRequest = "Create License request report",
  MasterLicenseAgreement = "Create Master license agreement report",
  PublishingLicenseAgreement = "Create Publishing license agreement report",
  ScheduleAReport = "Create schedule A report",
  PublishingSSA = "Create Publishing single song assignment report",
  MasterSSA = "Create Master single song assignment report",
}

export enum ReportFileName {
  MusicEstimate = "Music Estimate - Project",
  LicenseRequest = "License Request - Project",
  MasterLicenseAgreement = "Master License Agreement - Project",
  PublishingLicenseAgreement = "Publishing License Agreement - Project",
  ScheduleAReport = "Schedule A - Project",
  PublishingSSA = "Publishing Single Song Assignment - Project",
  MasterSSA = "Master Single Song Assignment - Project",
}

const CreateReportButton = ({
  onIsLoadingReport,
  ariaLabel,
  label,
  dataTestId,
  reportType,
  reportFileName,
}: IProps): ReactElement => {
  const {
    project: { id, sequenceNo },
  } = useProject();
  const { t } = useTranslation();
  const { organisationId, isSessionValid, fetchWrapper } = useAuth();

  const handleDownloadMusicEstimateReport = debounce(
    async (): Promise<void> => {
      const isSession = await isSessionValid();
      if (!isSession) return;
      try {
        onIsLoadingReport(true);
        const response = await fetchWrapper<Blob>(
          getProjectsReport,
          id,
          organisationId,
          reportType
        );

        const data = new Blob([response], {
          type: "application/octet-stream",
        });

        saveAs(data, `${reportFileName} ${sequenceNo}.docx`);

        SuccessToast(t("File downloading to your file storage"));
      } catch (error) {
        ErrorToast(
          `FileUploadErrorToast`,
          t("There was a problem downloading the file")
        );
      } finally {
        onIsLoadingReport(false);
      }
    },
    500
  );

  return (
    <ButtonWithLabelAndPlusIcon
      dataTestId={dataTestId}
      onClick={handleDownloadMusicEstimateReport}
      ariaLabel={ariaLabel}
      label={label}
    />
  );
};

export default CreateReportButton;
