import { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "../interfaces";

const UserMore = ({
  height = theme.svgSize.large,
  width = theme.svgSize.large,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6188 13.5H22.3812C21.8945 13.5 21.5 13.8918 21.5 14.375V17.625C21.5 18.1082 21.8945 18.5 22.3812 18.5H25.6188C26.1055 18.5 26.5 18.1082 26.5 17.625V14.375C26.5 13.8918 26.1055 13.5 25.6188 13.5Z"
        fill="#111111"
      />
      <path
        d="M25.6188 21.5H22.3812C21.8945 21.5 21.5 21.8918 21.5 22.375V25.625C21.5 26.1082 21.8945 26.5 22.3812 26.5H25.6188C26.1055 26.5 26.5 26.1082 26.5 25.625V22.375C26.5 21.8918 26.1055 21.5 25.6188 21.5Z"
        fill="#111111"
      />
      <path
        d="M25.6188 29.5H22.3812C21.8945 29.5 21.5 29.8918 21.5 30.375V33.625C21.5 34.1082 21.8945 34.5 22.3812 34.5H25.6188C26.1055 34.5 26.5 34.1082 26.5 33.625V30.375C26.5 29.8918 26.1055 29.5 25.6188 29.5Z"
        fill="#111111"
      />
    </svg>
  );
};

export default UserMore;
