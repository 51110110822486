import { useCallback, useMemo } from "react";
import { startCase } from "lodash";
import { IService } from "src/pages/projects/project/interfaces";
import parseCurrencyCode from "src/utils/currency";
import CollapsibleHeaderDeleteAndDuplicate from "src/components/collapsible-header-delete-and-duplicate/CollapsibleHeaderDeleteAndDuplicate";
import style from "../../../multi-tracks/styles/index";
import { ServiceFormType } from "../../types";
import mainStyles from "../../styles";

type IProps = {
  service: IService;
  onDuplicateClick: (service: IService) => void;
  onFormSubmit: (service: IService, action: ServiceFormType) => void;
};
const ServicesFormHeader = ({
  service,
  onDuplicateClick,
  onFormSubmit,
}: IProps): JSX.Element => {
  const handleDuplicateConfirmation = useCallback(() => {
    onDuplicateClick(service);
  }, [onDuplicateClick, service]);

  const handleDeleteConfirmation = useCallback(() => {
    onFormSubmit(service, ServiceFormType.Delete);
  }, [onFormSubmit, service]);

  const totalFee = useMemo(() => {
    let fee = service?.feeAmount?.value ?? 0;
    fee += service?.taxWithholdingFee?.value ?? 0;
    fee += service?.bankFee?.value ?? 0;

    return fee;
  }, [
    service?.bankFee?.value,
    service?.feeAmount?.value,
    service?.taxWithholdingFee?.value,
  ]);

  const calculateTotalThirdPartyFee = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const amounts: any[] = [];

    service.thirdParties?.forEach((item) => {
      amounts.push(item.thirdPartyFee?.value);
    });

    if (amounts.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const sum: number = amounts.reduce(
        (previousValue: number, currentValue: number) => {
          return previousValue + currentValue;
        }
      );
      return sum;
    }
    return 0;
  }, [service?.thirdParties]);

  const calculateTotalFee = useCallback(
    (thirdPartyFee: number) => {
      let totalServiceFee = 0;

      if (thirdPartyFee !== undefined) {
        totalServiceFee += thirdPartyFee ?? 0;
        totalServiceFee += totalFee;
        return totalServiceFee;
      }
      return totalFee;
    },
    [totalFee]
  );

  return (
    <div
      role="button"
      data-testid="service-summary-row"
      tabIndex={-1}
      onKeyDown={(e) => {
        e.persist();
      }}
      css={style.collapseSummaryContainer}
    >
      <div css={mainStyles.collapsibleTableHeaderFirstCell}>
        <div css={style.summaryColumnInformation}>
          {startCase(service.serviceType)}
        </div>
      </div>
      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {service?.musicType ? startCase(service.musicType) : "-"}
        </div>
      </div>

      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {service?.thirdPartyInvolved ? "Yes" : "No"}
        </div>
      </div>

      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {calculateTotalThirdPartyFee() > 0
            ? `${parseCurrencyCode(
                service?.thirdParties &&
                  service?.thirdParties[0]?.thirdPartyFee !== undefined
                  ? service.thirdParties[0]?.thirdPartyFee?.currency
                  : "USD"
              )}${calculateTotalThirdPartyFee().toFixed(2)}`
            : "-"}
        </div>
      </div>
      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {service?.feeAmount?.value && service?.feeAmount?.value > 0
            ? `${parseCurrencyCode(
                service?.feeAmount?.currency ?? "USD"
              )}${service?.feeAmount?.value.toFixed(2)}`
            : "-"}
        </div>
      </div>
      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {calculateTotalFee(calculateTotalThirdPartyFee()) > 0
            ? `${parseCurrencyCode(service?.feeAmount?.currency ?? "")}
              ${calculateTotalFee(calculateTotalThirdPartyFee()).toFixed(2)}`
            : "-"}
        </div>
      </div>
      <div css={style.summaryIconContainer}>
        <CollapsibleHeaderDeleteAndDuplicate
          service={service}
          onDuplicateTerm={handleDuplicateConfirmation}
          onDeleteClick={handleDeleteConfirmation}
        />
      </div>
    </div>
  );
};

export default ServicesFormHeader;
