import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Typography } from "antd";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common/lib/utils";
import DeleteButton from "src/components/control-buttons/delete-button";
import DeleteIconButtonWithLabel from "src/components/delete-icon-button-with-label";
import { IFormContacts } from "src/pages/projects/project/interfaces";
import BaseModal from "src/components/modals/base-modal";
import DeleteContactModal from "src/components/modals/delete-contact";
import { getIsValidEmail } from "src/pages/team/components/org-invites/components/invite-members/email-validator";
import projectValidation from "src/utils/projectValidation";
import styles from "./styles";
import mainStyle from "../../../../styles";
import { ValidateFormItem } from "../../../music/components/fee-split/fee-split";

interface IProps {
  data: IFormContacts;
  index: number;
  propertyName: string;
  isContactNameRequired?: boolean;
  isEmailAddressRequired?: boolean;
  deleteRow: (index: number) => void;
  updateRow: (index: number, additionalContacts: IFormContacts) => void;
}

const ContactEmailRow = ({
  data,
  index,
  propertyName,
  isContactNameRequired = false,
  isEmailAddressRequired = false,
  deleteRow,
  updateRow,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  useWindowSize();
  const isMobile = getIsMobile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [validationType, setValidationType] = useState<string | undefined>(
    ValidateFormItem.onBlur
  );
  const handleDeleteContact = useCallback(() => {
    deleteRow(index);
    setIsModalOpen(false);
  }, [deleteRow, index]);

  const handleUpdateContactRow = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, property: string) => {
      updateRow(index, {
        ...data,
        [property]: event.target.value,
      });
    },
    [data, index, updateRow]
  );

  const handleDeleteContactRow = useCallback(
    () =>
      data.name !== "" ||
      data.email !== "" ||
      data.contactType !== "" ||
      data.companyName !== ""
        ? setIsModalOpen(true)
        : handleDeleteContact(),
    [
      data.contactType,
      data.email,
      data.name,
      data.companyName,
      handleDeleteContact,
    ]
  );

  return (
    <div css={isMobile ? styles.contactColumn : styles.contactRow}>
      <div
        css={[
          isMobile ? styles.contactColumnDetails : styles.contactRowDetails,
          mainStyle.basicInput,
        ]}
      >
        <div css={styles.floatLabel} className="primary-contact">
          <Typography.Text css={mainStyle.basicInputLabel}>
            {t("Contact type")}
          </Typography.Text>
          <Form.Item
            name={`${propertyName}ContactType`}
            validateTrigger={validationType}
            css={mainStyle.formItem}
          >
            <Input
              aria-label={t("Contact type")}
              onChange={(event) => handleUpdateContactRow(event, "contactType")}
            />
          </Form.Item>
        </div>
        <div css={styles.floatLabel} className="primary-contact">
          <Typography.Text css={mainStyle.basicInputLabel}>
            {t("Contact name")}
          </Typography.Text>
          <Form.Item
            name={`${propertyName}Name`}
            validateTrigger={validationType}
            css={mainStyle.formItem}
            rules={projectValidation.requiredFieldValidator(
              isContactNameRequired
            )}
          >
            <Input
              aria-label={t("Contact name")}
              onChange={(event) => handleUpdateContactRow(event, "name")}
              onBlur={() => {
                setValidationType(ValidateFormItem.onBlur);
              }}
            />
          </Form.Item>
        </div>
        <div css={styles.floatLabel} className="primary-contact">
          <Typography.Text css={mainStyle.basicInputLabel}>
            {t("ProjectsPage##contactsSection##emailAddress")}
          </Typography.Text>
          <Form.Item
            name={`${propertyName}Email`}
            validateTrigger={validationType}
            css={mainStyle.formItem}
            rules={[
              () => ({
                validator(_rule, value?: string) {
                  if (value && validationType !== ValidateFormItem.onChange) {
                    const isEmailValid = getIsValidEmail(value);

                    if (!isEmailValid) {
                      return Promise.reject(
                        new Error("Email format is invalid")
                      );
                    }
                  }
                  if (isEmailAddressRequired && !value) {
                    return Promise.reject(
                      new Error("This is a required field")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              aria-label={t("ProjectsPage##contactsSection##emailAddress")}
              onChange={(event) => {
                handleUpdateContactRow(event, "email");
              }}
              onBlur={() => {
                setValidationType(ValidateFormItem.onBlur);
              }}
            />
          </Form.Item>
        </div>
        <div css={styles.floatLabel} className="primary-contact">
          <Typography.Text css={mainStyle.basicInputLabel}>
            {t("ProjectsPage##contactsSection##companyName")}
          </Typography.Text>
          <Form.Item
            name={`${propertyName}CompanyName`}
            validateTrigger={validationType}
            css={mainStyle.formItem}
          >
            <Input
              aria-label={t("ProjectsPage##contactsSection##companyName")}
              onChange={(event) => handleUpdateContactRow(event, "companyName")}
            />
          </Form.Item>
        </div>
      </div>
      {isMobile ? (
        <div css={styles.mobileDelete}>
          <DeleteIconButtonWithLabel
            onClick={handleDeleteContactRow}
            dataTestId={`delete-row-button-${index}`}
            ariaLabel={t("Delete")}
            label={t("Delete")}
          />
        </div>
      ) : (
        <div css={styles.deleteIconContainer}>
          <DeleteButton onClick={handleDeleteContactRow} />
        </div>
      )}
      <BaseModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={
          <DeleteContactModal
            data={data}
            index={index}
            onClose={() => setIsModalOpen(false)}
            handleDeleteContact={handleDeleteContact}
          />
        }
        footer={null}
      />
    </div>
  );
};

export default ContactEmailRow;
