import axios from "axios";
import config from "src/config";
import { IContactCreateRequest } from "src/pages/contact/interfaces";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

interface IContactCreateResponse {
  id: string;
}

const updateContact = async (
  accessToken: string,
  id: string,
  createRequest: IContactCreateRequest,
  organisationId: string
): Promise<IContactCreateResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const response = await axios.put(
    `${config.projectManagementService.url}/talent-hub/talent-contact/${id}?orgId=${organisationId}`,
    createRequest,
    options
  );

  return response.data as IContactCreateResponse;
};

export default updateContact;
