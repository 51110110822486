import { UploadFile } from "antd/lib/upload/interface";

export interface IRcFile extends File {
  uid: string;
  url?: string;
  uploaded?: {
    dateTime?: string;
    userFullname: string;
    userId: string;
  };
  readonly lastModifiedDate: Date;
  readonly webkitRelativePath: string;
  request?: XMLHttpRequest;
  trackId?: string;
}
export interface ICustomRequest {
  onSuccess: (body: unknown, file: UploadFile) => void;
  onProgress: (event: { percent: number }) => void;
  onError: (event: string, body: unknown, file: UploadFile) => void;
  file: IRcFile;
}

export enum IFileUploadStatus {
  error = "error",
  success = "success",
  done = "done",
  uploading = "uploading",
  removed = "removed",
}

export interface IDeleteSelectedFile {
  index: number;
  file: UploadFile;
}
