import { SerializedStyles } from "@emotion/react";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import UserMore from "src/app/assets/icons/component-icons/buttons/user-more";
import ControlButton from "../base-button";

interface IProps {
  showLabel?: boolean;
  showTooltip?: boolean;
  className?: string;
  onClick?:
    | (() => void)
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  tooltipColor?: string;
  customStyles?: SerializedStyles;
  label?: string;
}

const MoreInfo = ({
  showLabel = false,
  showTooltip = false,
  className = "",
  onClick,
  tooltipColor,
  customStyles,
  label,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <ControlButton
      className={className}
      css={customStyles}
      showLabel={showLabel}
      showTooltip={showTooltip}
      label={label || t("More")}
      data-testid="more-info-button"
      tooltipColor={tooltipColor}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <UserMore />
    </ControlButton>
  );
};

export default MoreInfo;
