import { createContext } from "react";
import {
  IContactsGroupResponse,
  IContactsResponse,
} from "src/api/talent-hub/interfaces";
import { ISavedUserColumn } from "src/api/user-filters/interfaces";
import { IPaginationOptions } from "src/pages/projects/project-listings/interfaces";
import {
  IContactsFilters,
  ITalentContactGroupsResponse,
} from "src/pages/talent-hub/interfaces";

export interface IContactsFiltersProviderContext {
  isLoading: boolean;
  availableContactFilters: IContactsFilters;
  contactsResponse?: IContactsResponse;
  searchText: string;
  selectedContactFilters: IContactsFilters;
  paginationOptions: IPaginationOptions;
  allGroups: ITalentContactGroupsResponse[];
  specificGroupContacts?: IContactsGroupResponse;
  groupSearchText: string;
  availableGroupContactFilters: IContactsFilters;
  selectedGroupContactFilters: IContactsFilters;
  groupCurrentPage: number;
  defaultFiltersValues: IContactsFilters;
  savedColumns: ISavedUserColumn[] | null;
  isLoadingSavedColumns: boolean;
  handleRemoveContactFromGroup: (
    groupId: string,
    isUserGroup: boolean,
    talentId: string
  ) => void;
  handleFetchSpecificGroupContacts: (
    groupId: string,
    isUserGroup: boolean,
    clearFilters?: boolean,
    page?: number,
    fetchFilters?: boolean
  ) => void;
  handleAddContactToGroup: (groupId: string, talentId: string) => void;
  handleCreateContactsGroup: (isUserGroup: boolean, groupName: string) => void;
  handleUpdateSearchText: (searchValue: string) => void;
  handleFetchSelectedFilters: (selectedFilters: IContactsFilters) => void;
  handlePageChange: (page: number) => void;
  handleDeleteGroup: (groupId: string, isUserGroup: string) => void;
  handleUpdateGroupSearchText: (searchValue: string) => void;
  handleFetchSelectedGroupFilters: (
    selectedGroupFilters: IContactsFilters
  ) => void;
  handleSetSelectedGroup: (group: { id: string; isUserGroup: boolean }) => void;
  handleGroupPageChange: (page: number, fetchUsers?: boolean) => void;
  clearGroupSearchAndFilters: () => void;
  updateSavedColumns: () => Promise<void>;
}
const defaultFilters = {
  collaboration: [],
  genre: [],
  instrument: [],
  language: [],
  location: [],
  skillSet: [],
  vocalist: [],
  serviceProvider: [],
  racialCharacteristic: [],
  genderIdentity: [],
  signedNDA: [],
  signedCMA: [],
};
export default createContext<IContactsFiltersProviderContext>({
  isLoading: false,
  searchText: "",
  contactsResponse: {
    totalPages: 0,
    totalResults: 0,
    currentPage: 0,
    results: [],
  },
  paginationOptions: {
    total: 0,
    currentPage: 1,
  },
  allGroups: [],
  specificGroupContacts: undefined,
  availableContactFilters: defaultFilters,
  selectedContactFilters: defaultFilters,
  defaultFiltersValues: defaultFilters,
  groupSearchText: "",
  availableGroupContactFilters: defaultFilters,
  selectedGroupContactFilters: defaultFilters,
  groupCurrentPage: 1,
  savedColumns: null,
  isLoadingSavedColumns: true,
  handleRemoveContactFromGroup: () => {},
  handleFetchSpecificGroupContacts: () => {},
  handleAddContactToGroup: () => {},
  handleCreateContactsGroup: () => {},
  handleUpdateSearchText: () => {},
  handleFetchSelectedFilters: () => {},
  handlePageChange: () => {},
  handleDeleteGroup: () => {},
  handleUpdateGroupSearchText: () => {},
  handleFetchSelectedGroupFilters: () => {},
  handleSetSelectedGroup: () => {},
  handleGroupPageChange: () => {},
  clearGroupSearchAndFilters: () => {},
  updateSavedColumns: async () => {},
});
