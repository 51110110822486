import { eApplicationType } from "src/interfaces/auth";
import { IPaginationProps } from "src/interfaces/pagination";

export interface IOrganisationMember {
  id: string;
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  organisationId: string;
  isCurrentUser?: boolean;
  auth0UserId: string;
  jobTitle?: string;
  phoneNumber?: string;
  country?: string;
  companyName?: string;
  companyUrl?: string;
  applications: eApplicationType[];
  isSuperAdmin: boolean;
}

export interface IOrganisationMemberProps extends IOrganisationMember {
  key: string;
  isCurrentUser: boolean;
}

export interface IUserDropdownProps {
  label: string;
  value: string;
  name?: string;
  email?: string;
}

export interface IMembersInviteResponse extends IPaginationProps {
  items: [IMemberInvite];
}

export interface IMemberInvite {
  application: string;
  email: string;
  expiryDate: string;
  id: string;
  role: string;
}

export interface IOrganisationItem {
  applications: eApplicationType[];
  audit: [
    {
      dateTime: string;
      description: string;
    }
  ];
  auth0UserId: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  organisationId: string;
  phoneNumber?: string;
  jobTitle?: string;
  useProjectsWhitelist: boolean;
  projectsWhitelist: string[];
  isSuperAdmin: boolean;
}
export interface IOrganisationMembersResponse extends IPaginationProps {
  items: IOrganisationItem[];
}
export interface IOrganisationMemberIdentifierProps {
  key: string;
  organisationId: string;
}
export interface IOrganisationInviteProps {
  application: eApplicationType.ProjectsInternal;
  email: string;
  expiryDate: string;
  id: string;
}

export interface ITableSort {
  sortType: OrgMembersSortType;
  sortOrder: SortOrder;
}

export enum OrgMembersSortType {
  Name = "Name",
  JobTitle = "Job Title",
  LastLogin = "Last Login",
}

export enum SortOrder {
  Ascending = "Ascending",
  Descending = "Descending",
}
