import { AxiosError } from "axios";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import useAuth from "src/auth/use-auth";
import { getProject, updateProject } from "src/api/projects";
import {
  ErrorFetchingProjectToast,
  ErrorSavingProjectToast,
  ErrorToast,
} from "src/components/toast-notification";
import useProject from "src/providers/project/hooks";
import useGlobalStates from "src/providers/global-context-provider/hooks";
import { cloneDeep } from "lodash";
import NotFound from "src/pages/not-found";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import { AttachmentType, DateDisplayFormat } from "src/constants";
import waitForMilliseconds from "src/utils/waitForMilliseconds";
import projectValidation from "src/utils/projectValidation";
import { useTranslation } from "react-i18next";
import areChangesMadeInProject from "src/utils/project-changes-check";
import useConfirm from "src/providers/confirm-context-provider/hooks";
import * as H from "history";
import dayjs from "dayjs";
import config, { Organisations } from "src/config/config";
import useProjectContactsSearch from "src/providers/project-talent-search/hooks";
import Project from "..";
import {
  IProjectRouteParams,
  ITab,
  IFormContacts,
  IProjectDetailsData,
  IProjectHeaderData,
  IProjectTag,
  IProjectOtherAssetsData,
  IProjectProductAndAccountInformationData,
  ProjectStatus,
  IBadgeCounter,
  IProjectSubscriptionDetails,
  IProjectPublishingData,
  IProjectForm,
  IFormContactsInvoices,
  TabsTitles,
} from "../interfaces";
import {
  IHoldingCompany,
  IOption,
} from "../components/account-information/types";
import Details from "../components/details";
import Licensing from "../components/licensing";
import Documents from "../components/documents";
import Contacts from "../components/contacts";
import ProductInformation from "../components/product-information";
import Services from "../components/services";
import ProjectTags from "../components/project-tags";
import MultiTracks from "../components/multi-tracks";
import OtherAssets from "../components/music/components/other-assets";
import ProjectTypeAndFormat from "../components/project-type-and-format";
import { IProjectHeaderAndSubHeader } from "../components/product-information/types";
import SubscriptionDetails from "../components/subscription-details";
import LibraryTrackDetails from "../components/library-track-details";
import LibraryTrackAccountInformation from "../components/library-track-account-information";
import { isEnterpriseLibraryProject } from "../utils";
import Publishing from "../components/publishing";

const ProjectContainer = (): ReactElement => {
  const { isConfirmed } = useConfirm();
  const contactsSearchAndFilters = useProjectContactsSearch();

  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const [badgeCounter, setBadgeCounter] = useState<IBadgeCounter>();
  const [numberOfAttachments, setNumberOfAttachments] = useState<
    undefined | number
  >();
  const {
    getAccessToken,
    isSessionValid,
    organisationId,
    fetchWrapper,
  } = useAuth();
  const { id } = useParams<IProjectRouteParams>();
  const [activeTab, setActiveTab] = useState<ITab>();
  const [holdingCompany, setHoldingCompany] = useState<IHoldingCompany>();
  const history = useHistory();
  const {
    project,
    isLoading,
    projectFound,
    publishingTeamEmails,
    allOrganizations,
    storeProject,
    resetProjectState,
    storePublishingTriggered,
  } = useProject();
  const isRegionsRequiredField = useMemo(
    () =>
      config.regionRequiredFieldForOrganisations.includes(
        organisationId as Organisations
      ),
    [organisationId]
  );
  const areContactNameAndEmailRequired = useMemo(
    () =>
      config.contactNameAndEmailRequiredForOrganisations.includes(
        organisationId as Organisations
      ),
    [organisationId]
  );
  const { allProjectTypes } = useGlobalStates();
  const [saveClicked, setSaveClicked] = useState(false);
  const [isFirstTimeSaveClicked, setIsFirstTimeSaveClicked] = useState(false);
  const [isDetailsInvalidTabChanged, setIsDetailsInvalidTabChanged] = useState(
    false
  );
  const formatProjectCloseDate = useCallback((closeDate) => {
    return closeDate ? dayjs(closeDate).format(DateDisplayFormat) : "";
  }, []);
  const [projectDetailsData, setProjectDetailsData] = useState({
    name: project.name,
    leads: project.leads,
    status: project.status,
    earnings: project.earnings,
    musicBudget: project.musicBudget,
    estimatedProjectClosedDate: project.estimatedProjectClosedDate,
    description: project.description,
    campaignName: project.campaignName,
    crmDealId: project.crmDealId,
    projectCloseDate: formatProjectCloseDate(project.projectCloseDate),
    jobListingDate: project.jobListingDate,
    isExtension: project.isExtension,
    relatedProjects: project.relatedProjects,
    creativeAgencyInvolved: project.creativeAgencyInvolved,
    creativeAgencyId: project.creativeAgencyId,
    creativeAgencyName: project.creativeAgencyName,
    creativeAgencyOffice: project.creativeAgencyOffice,
    clientReferenceNo: project.clientReferenceNo,
    projectRegion: project.projectRegion,
    costAvoidance: project.costAvoidance,
    initialMusicQuote: project.initialMusicQuote,
    officeLocation: project.officeLocation,
    totalAmount: project.totalAmount,
    format: project.format,
    originators: project.originators,
    curators: project.curators,
  });

  const [projectPublishingData, setProjectPublishingData] = useState({
    internalCreativeTeamEngaged: project.internalCreativeTeamEngaged,
    copyright: project?.copyright,
    publishing: project?.publishing,
  });

  const [projectType, setProjectType] = useState<IOption | undefined>();

  const [projectHeaderData, setProjectHeaderData] = useState({
    sharedWithClient: project.sharedWithClient,
    sequenceNo: project.sequenceNo,
  });
  const [
    projectProductAndAccountInformationData,
    setProjectProductAndAccountInformationData,
  ] = useState({
    regionalBrand: project.regionalBrand,
    productDivision: project.productDivision,
    globalBrand: project.globalBrand,
    client: project.client,
    clientContact: project.clientContact,
    product: project.product,
  });
  const [projectTags, setProjectTags] = useState(project.tags ?? []);
  const [projectContacts, setProjectContacts] = useState(
    project.contacts ?? []
  );

  const [projectContactInvoices, setProjectContactInvoices] = useState(
    project.invoiceeContacts ?? []
  );

  const [projectInvoicee, setProjectInvoicee] = useState(project.invoicee);

  const [projectOtherAssets, setProjectOtherAssets] = useState(project.music);
  const [saveRouteChange, setSaveRouteChange] = useState(false);
  const unmountedChangeRoute = useRef(false);
  const [servicesChanged, setServicesChanged] = useState<{
    [serviceId: string]: boolean;
  }>({});
  const [areProjectDetailsValid, setProjectDetailsValid] = useState(true);
  const [isProjectSubscriptionValid, setProjectSubscriptionValid] = useState(
    true
  );
  const [isProjectMusicValid, setProjectMusicValid] = useState(true);
  const [isProjectLicensingValid, setProjectLicensingValid] = useState(true);
  const [isProjectContactsValid, setProjectContactsValid] = useState(true);

  const [trackChanged, setTrackChanged] = useState(false);
  const [termsChanged, setTermsChanged] = useState<{
    [termId: string]: boolean;
  }>({});
  const [projectSubscriptionDetails, setProjectSubscriptionDetails] = useState({
    contractStartDate: project.contractStartDate,
    contractEndDate: project.contractStartDate,
    description: project.description,
  });
  const [projectLibraryTrackDetails, setProjectLibraryTrackDetails] = useState(
    project.libraryTracks ?? []
  );

  const handleSetServicesChanged = useCallback(
    (updatedServices: { [serviceId: string]: boolean }) => {
      setServicesChanged({ ...servicesChanged, ...updatedServices });
    },
    [servicesChanged]
  );

  const handleSetTrackChanged = useCallback((changed: boolean) => {
    setTrackChanged(changed);
  }, []);

  const handleSetTermsChanged = useCallback(
    (updatedTerms: { [termId: string]: boolean }) => {
      setTermsChanged({ ...termsChanged, ...updatedTerms });
    },
    [termsChanged]
  );

  useEffect(
    () =>
      setProjectDetailsData({
        name: project.name,
        leads: project.leads,
        status: project.status,
        earnings: project.earnings,
        musicBudget: project.musicBudget,
        estimatedProjectClosedDate: project.estimatedProjectClosedDate,
        description: project.description,
        campaignName: project.campaignName,
        crmDealId: project.crmDealId,
        projectCloseDate: formatProjectCloseDate(project.projectCloseDate),
        jobListingDate: project.jobListingDate,
        isExtension: project.isExtension,
        relatedProjects: project.relatedProjects,
        creativeAgencyInvolved: project.creativeAgencyInvolved,
        creativeAgencyId: project.creativeAgencyId,
        creativeAgencyName: project.creativeAgencyName,
        creativeAgencyOffice: project.creativeAgencyOffice,
        clientReferenceNo: project.clientReferenceNo,
        projectRegion: project.projectRegion,
        costAvoidance: project.costAvoidance,
        initialMusicQuote: project.initialMusicQuote,
        officeLocation: project.officeLocation,
        totalAmount: project.totalAmount,
        format: project.format,
        curators: project.curators,
        originators: project.originators,
      }),
    [
      formatProjectCloseDate,
      project.campaignName,
      project.client,
      project.clientContact,
      project.clientReferenceNo,
      project.costAvoidance,
      project.creativeAgencyId,
      project.creativeAgencyInvolved,
      project.creativeAgencyName,
      project.creativeAgencyOffice,
      project.crmDealId,
      project.curators,
      project.description,
      project.earnings,
      project.estimatedProjectClosedDate,
      project.format,
      project.initialMusicQuote,
      project.isExtension,
      project.jobListingDate,
      project.leads,
      project.officeLocation,
      project.musicBudget,
      project.name,
      project.originators,
      project.projectCloseDate,
      project.projectRegion,
      project.relatedProjects,
      project.status,
      project.totalAmount,
    ]
  );

  useEffect(
    () =>
      setProjectPublishingData({
        internalCreativeTeamEngaged: project.internalCreativeTeamEngaged,
        copyright: project?.copyright,
        publishing: project?.publishing,
      }),
    [
      project?.copyright,
      project.internalCreativeTeamEngaged,
      project?.publishing,
    ]
  );

  useEffect(() => {
    if (project.projectType) {
      const selectedProjectType = allProjectTypes.find(
        (type) => type.value === project.projectType
      );

      setProjectType(selectedProjectType);
    }
  }, [allProjectTypes, project.projectType]);

  useEffect(() => {
    setProjectHeaderData({
      sharedWithClient: project.sharedWithClient,
      sequenceNo: project.sequenceNo,
    });
  }, [project.sequenceNo, project.sharedWithClient]);

  useEffect(() => {
    setProjectProductAndAccountInformationData({
      regionalBrand: project.regionalBrand,
      productDivision: project.productDivision,
      globalBrand: project.globalBrand,
      client: project.client,
      clientContact: project.clientContact,
      product: project.product,
    });
  }, [
    project.client,
    project.clientContact,
    project.globalBrand,
    project.product,
    project.productDivision,
    project.regionalBrand,
  ]);

  useEffect(() => {
    setProjectSubscriptionDetails({
      contractStartDate: project.contractStartDate,
      contractEndDate: project.contractEndDate,
      description: project.description,
    });
  }, [
    formatProjectCloseDate,
    project.contractEndDate,
    project.contractStartDate,
    project.description,
  ]);

  useEffect(() => {
    setProjectLibraryTrackDetails(project.libraryTracks ?? []);
  }, [project.libraryTracks]);

  useEffect(() => {
    setProjectTags(project.tags ?? []);
  }, [project.tags]);

  useEffect(() => {
    setProjectContacts(project.contacts);
  }, [project.contacts]);

  useEffect(() => {
    setProjectContactInvoices(project.invoiceeContacts);
  }, [project.invoiceeContacts]);

  useEffect(() => {
    setProjectInvoicee(project.invoicee);
  }, [project.invoicee]);

  useEffect(() => {
    setProjectOtherAssets(project.music);
  }, [project.music]);

  const handleUpdateProjectDetailsData = useCallback(
    (changes: IProjectDetailsData) => {
      setProjectDetailsData({ ...projectDetailsData, ...changes });
    },
    [projectDetailsData]
  );

  const handleUpdateProjectPublishingData = useCallback(
    (changes: IProjectPublishingData) => {
      setProjectPublishingData({ ...projectPublishingData, ...changes });
    },
    [projectPublishingData]
  );

  const handleUpdateProjectHeaderData = useCallback(
    (changes: IProjectHeaderData) => {
      setProjectHeaderData({ ...projectHeaderData, ...changes });
    },
    [projectHeaderData]
  );

  const handleUpdateProjectProductAndAccountInformationData = useCallback(
    (changes: IProjectProductAndAccountInformationData) => {
      setProjectProductAndAccountInformationData({
        ...projectProductAndAccountInformationData,
        ...changes,
      });
    },
    [projectProductAndAccountInformationData]
  );

  const handleUpdateProjectSubscriptionDetails = useCallback(
    (changes: IProjectSubscriptionDetails) => {
      setProjectSubscriptionDetails({
        ...projectSubscriptionDetails,
        ...changes,
      });
    },
    [projectSubscriptionDetails]
  );

  const handleUpdateProjectTags = useCallback((tags: IProjectTag[]) => {
    setProjectTags(tags);
  }, []);

  const handelUpdateProjectContacts = useCallback(
    (contacts: IFormContacts[]) => {
      setProjectContacts(contacts);
    },
    []
  );

  const handelUpdateContactsInvoicee = useCallback(
    (invoiceeContacts: IFormContactsInvoices[]) => {
      setProjectContactInvoices(invoiceeContacts);
    },
    []
  );

  const handelUpdateInvoicee = useCallback(
    (propertyName: string, value: string) => {
      const changedData = {
        [propertyName]: value,
      };

      setProjectInvoicee({ ...projectInvoicee, ...changedData });
    },
    [projectInvoicee]
  );

  const handleUpdateProjectOtherAssets = useCallback(
    (changes: IProjectOtherAssetsData) => {
      setProjectOtherAssets({ ...projectOtherAssets, ...changes });
    },
    [projectOtherAssets]
  );

  const fetchProject = useCallback(async () => {
    const response = await fetchWrapper(getProject, id, organisationId);
    if (response) {
      return response;
    }
    return false;
  }, [fetchWrapper, id, organisationId]);

  const getProjectDetailsData = useCallback(() => {
    const creativeAgencyId = projectDetailsData.creativeAgencyInvolved
      ? projectDetailsData.creativeAgencyId
      : undefined;
    const creativeAgencyOffice = projectDetailsData.creativeAgencyInvolved
      ? projectDetailsData.creativeAgencyOffice
      : undefined;

    return {
      name: projectDetailsData.name,
      campaignName: projectDetailsData.campaignName,
      crmDealId: projectDetailsData.crmDealId,
      description: projectDetailsData.description,
      clientReferenceNo: projectDetailsData.clientReferenceNo,
      jobListingDate: projectDetailsData.jobListingDate,
      estimatedProjectClosedDate: projectDetailsData.estimatedProjectClosedDate,
      status: projectDetailsData.status,
      projectCloseDate: projectDetailsData.projectCloseDate,
      projectRegion: projectDetailsData.projectRegion,
      musicBudget: projectDetailsData.musicBudget,
      earnings: projectDetailsData.earnings,
      initialMusicQuote: projectDetailsData.initialMusicQuote,
      costAvoidance: projectDetailsData.costAvoidance,
      internalCreativeTeamEngaged:
        projectPublishingData.internalCreativeTeamEngaged,
      copyright: projectPublishingData.copyright,
      publishing: projectPublishingData.publishing,
      isExtension: projectDetailsData.isExtension,
      relatedProjects: projectDetailsData.relatedProjects,
      creativeAgencyInvolved: projectDetailsData.creativeAgencyInvolved,
      creativeAgencyId,
      creativeAgencyOffice,
      leads: projectDetailsData.leads ?? [],
      officeLocation: projectDetailsData.officeLocation,
      totalAmount: projectDetailsData.totalAmount,
      format: projectDetailsData.format,
      curators: projectDetailsData.curators ?? [],
      originators: projectDetailsData.originators ?? [],
    };
  }, [
    projectDetailsData.creativeAgencyInvolved,
    projectDetailsData.creativeAgencyId,
    projectDetailsData.creativeAgencyOffice,
    projectDetailsData.name,
    projectDetailsData.campaignName,
    projectDetailsData.crmDealId,
    projectDetailsData.description,
    projectDetailsData.clientReferenceNo,
    projectDetailsData.jobListingDate,
    projectDetailsData.estimatedProjectClosedDate,
    projectDetailsData.status,
    projectDetailsData.projectCloseDate,
    projectDetailsData.projectRegion,
    projectDetailsData.musicBudget,
    projectDetailsData.earnings,
    projectDetailsData.initialMusicQuote,
    projectDetailsData.costAvoidance,
    projectDetailsData.isExtension,
    projectDetailsData.relatedProjects,
    projectDetailsData.leads,
    projectDetailsData.officeLocation,
    projectDetailsData.totalAmount,
    projectDetailsData.format,
    projectDetailsData.curators,
    projectDetailsData.originators,
    projectPublishingData.internalCreativeTeamEngaged,
    projectPublishingData.copyright,
    projectPublishingData.publishing,
  ]);

  const getClientPayload = useCallback(() => {
    if (!projectProductAndAccountInformationData.client) {
      return undefined;
    }

    return {
      ...projectProductAndAccountInformationData.client,
      holdingCompany:
        holdingCompany?.id && holdingCompany?.name ? holdingCompany : undefined,
    };
  }, [projectProductAndAccountInformationData.client, holdingCompany]);

  const getPatchProjectDataRequest = useCallback(() => {
    const projectDetailsValidationData = getProjectDetailsData();

    return {
      sharedWithClient: projectHeaderData.sharedWithClient,
      ...projectDetailsValidationData,
      client: getClientPayload(),
      clientContact: projectProductAndAccountInformationData.clientContact,
      product: projectProductAndAccountInformationData.product,
      globalBrand: projectProductAndAccountInformationData.globalBrand,
      regionalBrand: projectProductAndAccountInformationData.regionalBrand,
      productDivision: projectProductAndAccountInformationData.productDivision,
      contacts: projectContacts ?? [],
      invoiceeContacts: projectContactInvoices ?? [],
      invoicee: projectInvoicee,
      music: {
        musicAssets: projectOtherAssets.musicAssets ?? [],
        finalVideo: projectOtherAssets.finalVideo ?? [],
      },
      tags: projectTags ?? [],
      leadsVersion: project?.leadsVersion,
      version: project.version,
      projectType: projectType ? projectType.value : undefined,
      organisationId,
    };
  }, [
    getProjectDetailsData,
    organisationId,
    project?.leadsVersion,
    project.version,
    projectContacts,
    projectHeaderData.sharedWithClient,
    projectContactInvoices,
    projectInvoicee,
    projectOtherAssets.finalVideo,
    projectOtherAssets.musicAssets,
    getClientPayload,
    projectProductAndAccountInformationData.clientContact,
    projectProductAndAccountInformationData.globalBrand,
    projectProductAndAccountInformationData.product,
    projectProductAndAccountInformationData.productDivision,
    projectProductAndAccountInformationData.regionalBrand,
    projectTags,
    projectType,
  ]);

  useEffect(() => {
    const attachmentsCount = {
      purchaseOrderAttachments: project.purchaseOrderAttachments.length,
      invoiceAttachments: project.invoiceAttachments.length,
      licenseAttachments: project.licenseAttachments.length,
      estimateAttachments: project.estimateAttachments.length,
      miscAttachments: project.miscAttachments.length,
      assetAttachments: project.assetAttachments.length,
      testResultAttachments: project.testResultAttachments.length,
      briefAttachments: (project?.briefAttachments ?? []).length,
    };
    setNumberOfAttachments(
      Object.values(attachmentsCount).reduce((pv, cv) => pv + cv, 0)
    );
  }, [
    project.purchaseOrderAttachments.length,
    project.invoiceAttachments.length,
    project.licenseAttachments.length,
    project.estimateAttachments.length,
    project.miscAttachments.length,
    project.assetAttachments.length,
    project.testResultAttachments.length,
    project.briefAttachments,
  ]);

  useEffect(() => {
    setBadgeCounter({
      numberOfTracks: project.tracks?.length ?? 0,
      numberOfServices: project.services?.length ?? 0,
      numberOfDocuments: numberOfAttachments ?? 0,
      numberOfContacts: project.contacts?.length ?? 0,
      numberOfLicenses: project.terms?.length ?? 0,
    });
  }, [
    numberOfAttachments,
    project.contacts?.length,
    project.licenseAttachments.length,
    project.services?.length,
    project.terms?.length,
    project.tracks?.length,
  ]);

  useEffect(() => {
    const validateDetailsTab = async () => {
      let isDataValid = true;
      try {
        if (isEnterpriseLibraryProject(projectType?.value ?? "")) {
          await projectValidation.enterpriseLibraryProjectDetailsTabSchema.validate(
            {
              client: projectProductAndAccountInformationData.client,
              clientContact:
                projectProductAndAccountInformationData.clientContact,
            }
          );
        } else {
          const projectDetailsValidationData = getProjectDetailsData();
          await projectValidation.projectDetailsSchema.validate(
            projectDetailsValidationData
          );
          if (isRegionsRequiredField) {
            await projectValidation.regionProjectDetailsSchema.validate(
              projectDetailsValidationData
            );
          }
        }
      } catch (error) {
        isDataValid = false;
      }
      setProjectDetailsValid(isDataValid);
    };

    void validateDetailsTab();
  }, [
    getProjectDetailsData,
    isRegionsRequiredField,
    projectProductAndAccountInformationData.client,
    projectProductAndAccountInformationData.clientContact,
    projectType?.value,
  ]);

  useEffect(() => {
    const validateSubscriptionTab = async () => {
      let isDataValid = true;
      try {
        await projectValidation.enterpriseLibraryProjectSubscriptionTabSchema.validate(
          {
            contractStartDate: projectSubscriptionDetails.contractStartDate,
            contractEndDate: projectSubscriptionDetails.contractEndDate,
          }
        );
      } catch (error) {
        isDataValid = false;
      }
      setProjectSubscriptionValid(isDataValid);
    };

    void validateSubscriptionTab();
  }, [
    projectSubscriptionDetails.contractEndDate,
    projectSubscriptionDetails.contractStartDate,
  ]);

  const validateTrackOwnership = useMemo(() => {
    if (!projectDetailsData?.status) {
      return false;
    }
    return [ProjectStatus.Complete, ProjectStatus.InContractInvoiced].includes(
      projectDetailsData.status
    );
  }, [projectDetailsData?.status]);

  useEffect(() => {
    const validateMusicTab = async () => {
      let isDataValid = true;
      try {
        await projectValidation.trackSchemaArray.validate(
          project?.tracks ?? []
        );
        const tracksData = validateTrackOwnership ? project?.tracks ?? [] : [];
        if (projectPublishingData.internalCreativeTeamEngaged) {
          await projectValidation.trackSchemaArrayWithWriterFee.validate(
            tracksData
          );
        } else {
          await projectValidation.trackSchemaArrayWithoutWriterFee.validate(
            tracksData
          );
        }
      } catch (error) {
        isDataValid = false;
      }
      setProjectMusicValid(isDataValid);
    };

    void validateMusicTab();
  }, [
    project?.tracks,
    projectPublishingData.internalCreativeTeamEngaged,
    validateTrackOwnership,
  ]);

  useEffect(() => {
    const validateLicensingTab = async () => {
      let isDataValid = true;
      try {
        const allTerms = (project.terms ?? []).map((term) => ({
          ...term,
          // Set fixed duration only for the validation
          fixedDuration: "customDuration",
        }));
        const termsForValidation = allTerms.filter(
          (term) => (term.finalTracks ?? []).length > 0
        );
        await projectValidation.termSchemaDefaultArray.validate(allTerms);
        await projectValidation.termSchemaArray.validate(termsForValidation);
      } catch (error) {
        isDataValid = false;
      }
      setProjectLicensingValid(isDataValid);
    };

    void validateLicensingTab();
  }, [project.terms]);

  const getFilledContactsInList = useCallback((contacts?: IFormContacts[]) => {
    return (contacts ?? []).filter(
      (contact) =>
        contact?.name ||
        contact?.email ||
        contact?.contactType ||
        contact?.companyName
    );
  }, []);

  const getFilledContactsInvoicesEmailsInList = useCallback(
    (invoicees?: IFormContactsInvoices[]) => {
      return (invoicees ?? []).filter((invoicee) => invoicee?.email);
    },
    []
  );

  useEffect(() => {
    const validatesContactTab = async () => {
      let isDataValid = true;
      try {
        const filledContacts = getFilledContactsInList(projectContacts);
        const filledEmailInvoicee = getFilledContactsInvoicesEmailsInList(
          projectContactInvoices
        );

        const isContactsDataFilled = projectValidation.areContactNameAndEmailFilled(
          projectContacts
        );

        const isContactsDataValid = projectValidation.areAllEmailInContactsValid(
          filledContacts
        );
        const areInvoiceeEmailsValid = projectValidation.areAllEmailInContactsValid(
          filledEmailInvoicee
        );

        if (
          (areContactNameAndEmailRequired && !isContactsDataValid) ||
          !areInvoiceeEmailsValid ||
          (areContactNameAndEmailRequired &&
            !isContactsDataFilled &&
            !isEnterpriseLibraryProject(projectType?.value ?? ""))
        ) {
          isDataValid = false;
        } else if (areContactNameAndEmailRequired) {
          await projectValidation.contactsSchemaWithRequiredDataArray.validate({
            contacts: filledContacts,
          });
        } else {
          await projectValidation.contactsSchemaArray.validate({
            contacts: filledContacts,
          });
        }
      } catch (error) {
        isDataValid = false;
      }
      setProjectContactsValid(isDataValid);
    };

    void validatesContactTab();
  }, [
    projectContactInvoices,
    areContactNameAndEmailRequired,
    getFilledContactsInList,
    projectContacts,
    getFilledContactsInvoicesEmailsInList,
    projectType?.value,
  ]);

  const handleTabChange = useCallback(
    (tab: ITab) => {
      if (tab?.tabTitle !== activeTab?.tabTitle) {
        setTermsChanged({});
        setServicesChanged({});
        setTrackChanged(false);
      }
      if (
        (!activeTab?.tabTitle ||
          activeTab?.tabTitle === TabsTitles.ProjectDetails) &&
        !areProjectDetailsValid
      ) {
        setIsDetailsInvalidTabChanged(true);
      }
      setActiveTab(tab);
    },
    [activeTab?.tabTitle, areProjectDetailsValid]
  );
  const organizationName = useMemo(() => {
    const organization = allOrganizations.find(
      (org) => org.value === project?.organisationId
    );
    return organization?.label ?? "";
  }, [allOrganizations, project.organisationId]);

  const projectHeaderAndSubHeader = useMemo((): IProjectHeaderAndSubHeader => {
    return {
      sharedWithClient: projectHeaderData?.sharedWithClient ?? false,
      projectName: projectDetailsData?.name ?? "",
      projectId: projectHeaderData?.sequenceNo ?? 0,
      dealId: projectDetailsData?.crmDealId ?? 0,
      client: projectProductAndAccountInformationData?.client?.name ?? "",
      status: projectDetailsData.status ?? "",
      organizationName,
    };
  }, [
    organizationName,
    projectDetailsData?.crmDealId,
    projectDetailsData?.name,
    projectDetailsData.status,
    projectHeaderData?.sequenceNo,
    projectHeaderData?.sharedWithClient,
    projectProductAndAccountInformationData?.client?.name,
  ]);

  const areChangesMade = useCallback(() => {
    return areChangesMadeInProject(project, {
      projectType: projectType?.value,
      projectDetailsData,
      projectHeaderData,
      projectProductAndAccountInformationData,
      projectTags,
      projectContacts,
      projectOtherAssets,
      servicesChanged,
      trackChanged,
      termsChanged,
      projectPublishingData,
      projectContactInvoices,
      projectInvoicee,
      projectSubscriptionDetails,
    });
  }, [
    project,
    projectContactInvoices,
    projectContacts,
    projectDetailsData,
    projectHeaderData,
    projectInvoicee,
    projectOtherAssets,
    projectProductAndAccountInformationData,
    projectPublishingData,
    projectSubscriptionDetails,
    projectTags,
    projectType?.value,
    servicesChanged,
    termsChanged,
    trackChanged,
  ]);

  useEffect(() => {
    const blocker = (location: H.Location, action: H.Action): boolean => {
      if (saveRouteChange || unmountedChangeRoute.current) {
        return true;
      }
      if (areChangesMade()) {
        void isConfirmed(
          "Your changes will be lost if you don't save them. Do you want to continue?"
        ).then((confirmed) => {
          if (confirmed) {
            unmountedChangeRoute.current = true;
            switch (action) {
              case H.Action.Push:
                history.push(location.pathname);
                break;
              case H.Action.Pop:
              case H.Action.Replace:
              default:
                history.replace(location.pathname);
                break;
            }
          }
          if (!confirmed && action === H.Action.Pop) {
            unmountedChangeRoute.current = true;
            const newLocationPathname = `/project/${project.id}`;
            const currentLocationPathname = window.location.pathname;
            const historyAction =
              currentLocationPathname !== newLocationPathname
                ? "push"
                : "replace";
            history[historyAction](newLocationPathname);
          }
          return true;
        });
        return false;
      }
      return true;
    };
    const unblock = history.block((...args) => {
      const [location, action] = (args as unknown) as [H.Location, H.Action];
      return blocker(location, action);
    });
    return () => {
      unblock();
      unmountedChangeRoute.current = false;
    };
  }, [areChangesMade, history, isConfirmed, project.id, saveRouteChange]);

  const getPatchEnterpriseLibraryProjectDataRequest = useCallback(() => {
    const sharedWithClient = project.projectType
      ? projectHeaderData.sharedWithClient
      : true;

    return {
      sharedWithClient,
      contractStartDate: projectSubscriptionDetails.contractStartDate,
      contractEndDate: projectSubscriptionDetails.contractEndDate,
      description: projectSubscriptionDetails.description,
      client: getClientPayload(),
      clientContact: projectProductAndAccountInformationData.clientContact,
      version: project.version,
      projectType: projectType ? projectType.value : undefined,
      organisationId,
      name: `${projectProductAndAccountInformationData.client?.name ?? ""} ${
        projectType?.label ?? ""
      }`,
    };
  }, [
    getClientPayload,
    organisationId,
    project.projectType,
    project.version,
    projectHeaderData.sharedWithClient,
    projectProductAndAccountInformationData.client?.name,
    projectProductAndAccountInformationData.clientContact,
    projectSubscriptionDetails.contractEndDate,
    projectSubscriptionDetails.contractStartDate,
    projectSubscriptionDetails.description,
    projectType,
  ]);

  const isEnterpriseLibraryRequestValid = useCallback(
    async (request) => {
      let isRequestDataValid = false;
      try {
        await projectValidation.enterpriseLibraryProjectSchema.validate(
          request
        );
        isRequestDataValid = true;
      } catch (error) {
        setSaveClicked(false);
        ErrorToast(
          "project-validation-error",
          t("ProjectsPage##Errors##ValidationError##message")
        );
      }

      return isRequestDataValid;
    },
    [t]
  );

  const isProjectRequestValid = useCallback(
    async (request: ReturnType<typeof getPatchProjectDataRequest>) => {
      let isRequestDataValid = false;
      try {
        const termsForValidation = (project.terms ?? []).filter(
          (term) => (term.finalTracks ?? []).length > 0
        );
        const tracksForValidation = validateTrackOwnership
          ? project?.tracks ?? []
          : [];
        const data = {
          ...request,
          tracks: tracksForValidation,
          terms: termsForValidation.map((term) => ({
            ...term,
            // Set fixed duration only for the validation
            fixedDuration: "customDuration",
          })),
        };
        await projectValidation.trackSchemaArray.validate(
          project?.tracks ?? []
        );
        if (request.internalCreativeTeamEngaged) {
          await projectValidation.projectSchemaWithWriterFee.validate(data);
        } else {
          await projectValidation.projectSchemaWithoutWriterFee.validate(data);
        }
        if (isRegionsRequiredField) {
          await projectValidation.regionProjectDetailsSchema.validate(data);
        }

        isRequestDataValid = true;
      } catch (error) {
        setSaveClicked(false);
        ErrorToast(
          "project-validation-error",
          t("ProjectsPage##Errors##ValidationError##message")
        );
      }

      return isRequestDataValid;
    },
    [
      isRegionsRequiredField,
      project.terms,
      project?.tracks,
      t,
      validateTrackOwnership,
    ]
  );

  const saveProjectData = useCallback(
    async (
      request: ReturnType<
        | typeof getPatchEnterpriseLibraryProjectDataRequest
        | typeof getPatchProjectDataRequest
      >,
      projectFormData: IProjectForm
    ) => {
      setIsSaving(true);

      let currentProjectState = cloneDeep(projectFormData);

      if (getAccessToken) {
        try {
          const latestProjectData = await fetchWrapper(
            getProject,
            id,
            organisationId
          );
          const accessToken: string = getAccessToken();

          if (latestProjectData) {
            await updateProject(accessToken, id, request);

            // Because we're updating multiple fields here, let's rehydrate the state to ensure backend is source of truth
            const response = await getProject(
              accessToken,
              projectFormData.id,
              organisationId
            );

            if (response) {
              currentProjectState = response;
              setSaveRouteChange(true);
              await waitForMilliseconds(2000);
              history.push("/projects");
            }
          }
        } catch (reason) {
          const error = reason as AxiosError;
          if (error.response?.status === 409) {
            try {
              const response = await fetchProject();
              const accessToken: string = getAccessToken();
              if (response && response.version) {
                currentProjectState = response;

                try {
                  const patchResponse = await updateProject(accessToken, id, {
                    ...request,
                    version: response.version,
                    organisationId,
                  });
                  currentProjectState.version = patchResponse.version;
                  setSaveRouteChange(true);
                  await waitForMilliseconds(1000);
                  history.push("/projects");
                } catch (e) {
                  setSaveClicked(false);
                  log(DataDogLogTypes.ERROR, "Error saving project", e);
                  ErrorSavingProjectToast();
                }
              }
            } catch (e) {
              setSaveClicked(false);
              log(DataDogLogTypes.ERROR, "Error fetching project", e);
              ErrorFetchingProjectToast();
            }
          } else {
            setSaveClicked(false);
            log(DataDogLogTypes.ERROR, "Error saving project", error);
            ErrorSavingProjectToast();
          }
        }
      }
      setIsSaving(false);
      setSaveClicked(false);
      storeProject(currentProjectState);
    },
    [
      fetchProject,
      fetchWrapper,
      getAccessToken,
      history,
      id,
      organisationId,
      storeProject,
    ]
  );

  const handleUpdateProjectNotificationTriggeredBy = useCallback(async () => {
    try {
      const latestProjectData = await fetchWrapper(
        getProject,
        id,
        organisationId
      );

      if (latestProjectData) {
        const data = {
          publishingTriggeredBy: latestProjectData.publishingTriggeredBy,
          projectVersion: latestProjectData.version,
        };
        storePublishingTriggered(data);
      }
    } catch (reason) {
      ErrorFetchingProjectToast();
    }
  }, [fetchWrapper, id, organisationId, storePublishingTriggered]);

  const handleSaveEnterpriseLibraryProject = useCallback(async () => {
    setSaveClicked(true);
    setIsFirstTimeSaveClicked(true);
    const request = getPatchEnterpriseLibraryProjectDataRequest();
    const isRequestValid = await isEnterpriseLibraryRequestValid(request);
    if (!isRequestValid) {
      return;
    }
    await saveProjectData(request, project);
  }, [
    getPatchEnterpriseLibraryProjectDataRequest,
    isEnterpriseLibraryRequestValid,
    saveProjectData,
    project,
  ]);

  const handleSaveProject = useCallback(async () => {
    const isSession = await isSessionValid();
    if (!isSession) {
      log(DataDogLogTypes.ERROR, "Error saving project", "error");
      ErrorSavingProjectToast();
      return;
    }

    setIsFirstTimeSaveClicked(true);
    const isValidContactsNameAndEmail = projectValidation.areContactNameAndEmailFilled(
      projectContacts
    );

    if (
      areContactNameAndEmailRequired &&
      !isValidContactsNameAndEmail &&
      !isEnterpriseLibraryProject(projectType?.value ?? "")
    ) {
      ErrorToast(
        "contacts-email-name-validation",
        "Fill the required contact fields, before saving this project."
      );
      return;
    }

    const isContactsDataValid =
      projectValidation.areAllEmailInContactsValid(projectContacts ?? []) &&
      projectValidation.areAllEmailInContactsValid(
        projectContactInvoices ?? []
      );
    if (!isContactsDataValid) {
      ErrorToast(
        "contacts-email-format",
        "One or more emails listed under the 'Contacts' tab have an incorrect format"
      );
      return;
    }

    if (isEnterpriseLibraryProject(projectType?.value ?? "")) {
      await handleSaveEnterpriseLibraryProject();
      return;
    }
    if (trackChanged) {
      ErrorToast(
        "track-not-saved-error",
        "Save the track before saving the project"
      );
      return;
    }
    const hasTermsChanges =
      Object.values(termsChanged).filter((item) => item).length > 0;
    if (hasTermsChanges) {
      ErrorToast(
        "term-not-saved-error",
        "Save the term before saving the project"
      );
      return;
    }
    const hasServicesChanges =
      Object.values(servicesChanged).filter((item) => item).length > 0;
    if (hasServicesChanges) {
      ErrorToast(
        "service-not-saved-error",
        "Save the service before saving the project"
      );
      return;
    }
    if (!projectType) {
      ErrorToast("saving-project-error", "Project type has to be selected.");
      return;
    }
    setSaveClicked(true);
    const request = getPatchProjectDataRequest();
    if (request?.projectCloseDate) {
      request.projectCloseDate = dayjs(
        request?.projectCloseDate,
        DateDisplayFormat
      ).toISOString();
    }
    const isRequestDataValid = await isProjectRequestValid(request);
    if (!isRequestDataValid) {
      return;
    }
    // Check the master publishing and writers shares to match 100%
    const areSharesValid = projectValidation.checkSharesTotalPercentageInAllTracks(
      project.tracks
    );
    if (!areSharesValid) {
      ErrorToast("shares-not-valid", "Shares must equal 100%");
      return;
    }
    let areRequiredFieldsInContactsValid = true;
    if (areContactNameAndEmailRequired) {
      try {
        const filledContacts = getFilledContactsInList(request.contacts);
        await projectValidation.contactsSchemaWithRequiredDataArray.validate({
          contacts: filledContacts,
        });
      } catch (error) {
        areRequiredFieldsInContactsValid = false;
      }
    }
    if (!areRequiredFieldsInContactsValid) {
      ErrorToast(
        "contacts-required-fields",
        "One or more contacts are missing required fields"
      );
      return;
    }
    await saveProjectData(request, {
      ...project,
      projectCloseDate: project?.projectCloseDate
        ? new Date(project.projectCloseDate).toISOString()
        : "",
    });
  }, [
    areContactNameAndEmailRequired,
    getFilledContactsInList,
    getPatchProjectDataRequest,
    handleSaveEnterpriseLibraryProject,
    isProjectRequestValid,
    isSessionValid,
    project,
    projectContactInvoices,
    projectContacts,
    projectType,
    saveProjectData,
    servicesChanged,
    termsChanged,
    trackChanged,
  ]);

  const handleSetHoldingCompany = useCallback((data: IHoldingCompany) => {
    setHoldingCompany(data);
  }, []);

  const handleProjectTypeChange = useCallback((newProjectType: IOption) => {
    setProjectType(newProjectType);
  }, []);

  useEffect(() => {
    return () => {
      resetProjectState();
    };
  }, [resetProjectState]);
  return !projectFound && allProjectTypes?.length === 0 ? (
    <NotFound
      heading="ProjectsPage##projectNotFound##heading"
      description="ProjectsPage##projectNotFound##description"
      buttonText="ProjectsPage##projectNotFound##buttonText"
      buttonPath="/projects"
    />
  ) : (
    <Project
      projectHeaderAndSubHeader={projectHeaderAndSubHeader}
      badgeCounter={badgeCounter}
      isSaving={isSaving}
      isLoading={isLoading}
      activeTab={activeTab}
      projectOrgId={project.organisationId}
      version={project.version}
      setActiveTab={handleTabChange}
      onSaveProject={handleSaveProject}
      selectedProjectType={projectType}
      projectHeaderDate={projectHeaderData}
      updateProjectHeaderData={handleUpdateProjectHeaderData}
      validation={{
        projectDetails: areProjectDetailsValid,
        music: isProjectMusicValid,
        licensing: isProjectLicensingValid,
        subscription: isProjectSubscriptionValid,
        contacts: isProjectContactsValid,
      }}
      onTabChangedResetTracksChanges={handleSetTrackChanged}
    >
      {{
        projectTypeAndFormat: (
          <ProjectTypeAndFormat
            allProjectTypes={allProjectTypes}
            projectType={projectType}
            onProjectTypeChange={handleProjectTypeChange}
            projectFormat={projectDetailsData.format}
            onUpdateProjectDetailsData={handleUpdateProjectDetailsData}
            saveClicked={saveClicked}
            enforceValidation={
              !areProjectDetailsValid &&
              (isDetailsInvalidTabChanged || isFirstTimeSaveClicked)
            }
            isEnterpriseLibraryProject={isEnterpriseLibraryProject(
              projectType?.value ?? ""
            )}
          />
        ),
        publishing: (
          <Publishing
            projectPublishingData={projectPublishingData}
            publishingTeamEmails={publishingTeamEmails}
            publishingTriggeredBy={project.publishingTriggeredBy}
            onUpdatePublishingData={handleUpdateProjectPublishingData}
            onNotifyPublishingTeam={handleUpdateProjectNotificationTriggeredBy}
          />
        ),
        details: (
          <Details
            projectDetailsData={projectDetailsData}
            onUpdateProjectDetailsData={handleUpdateProjectDetailsData}
            saveClicked={saveClicked}
            enforceValidation={
              !areProjectDetailsValid &&
              (isDetailsInvalidTabChanged || isFirstTimeSaveClicked)
            }
            publishingTeamEmails={publishingTeamEmails}
            projectProductAndAccountInformationData={
              projectProductAndAccountInformationData
            }
            updateProjectProductAndAccountInformationData={
              handleUpdateProjectProductAndAccountInformationData
            }
            setHoldingCompany={handleSetHoldingCompany}
            isRegionsRequiredField={isRegionsRequiredField}
          />
        ),
        services: (
          <Services
            projectServices={project.services}
            servicesChanged={servicesChanged}
            talentsResponse={contactsSearchAndFilters.contactsResponse}
            areTalentsLoading={contactsSearchAndFilters.isLoading}
            agenciesAndContacts={contactsSearchAndFilters.agenciesAndContacts}
            onUpdateNarrowSearchText={
              contactsSearchAndFilters.handleUpdateNarrowSearchText
            }
            onScrollTalents={contactsSearchAndFilters.handlePageChange}
            onChangesMade={handleSetServicesChanged}
            onResetNarrowSearch={
              contactsSearchAndFilters.handleResetNarrowSearch
            }
          />
        ),
        contacts: (
          <Contacts
            contactsSection={projectContacts}
            contactsInvoices={projectContactInvoices}
            invoicee={projectInvoicee}
            projectProductAndAccountInformationData={
              projectProductAndAccountInformationData
            }
            projectType={projectType?.value}
            isContactNameRequired={areContactNameAndEmailRequired}
            isEmailAddressRequired={areContactNameAndEmailRequired}
            onUpdateContacts={handelUpdateProjectContacts}
            onUpdateContactsInvoicee={handelUpdateContactsInvoicee}
            onUpdateInvoicee={handelUpdateInvoicee}
          />
        ),
        music: (
          <MultiTracks
            trackChanged={trackChanged}
            internalCreativeTeamEngaged={
              projectPublishingData.internalCreativeTeamEngaged
            }
            validateTrackOwnership={validateTrackOwnership}
            talentsResponse={contactsSearchAndFilters.contactsResponse}
            agenciesAndContacts={contactsSearchAndFilters.agenciesAndContacts}
            areTalentsLoading={contactsSearchAndFilters.isLoading}
            onUpdateNarrowSearchText={
              contactsSearchAndFilters.handleUpdateNarrowSearchText
            }
            onScrollTalents={contactsSearchAndFilters.handlePageChange}
            onResetNarrowSearch={
              contactsSearchAndFilters.handleResetNarrowSearch
            }
            onChangesMade={handleSetTrackChanged}
          />
        ),
        otherMusicAssets: (
          <OtherAssets
            musicAssetsAttachments={project.attachments.filter(
              (attachment) =>
                attachment.attachmentType === AttachmentType.MusicAsset
            )}
            finalVideoAssetsAttachments={project.attachments.filter(
              (attachment) =>
                attachment.attachmentType === AttachmentType.VideoAsset
            )}
            projectOtherAssets={projectOtherAssets}
            updateProjectOtherAssets={handleUpdateProjectOtherAssets}
          />
        ),
        productInformation: (
          <ProductInformation
            projectProductAndAccountInformationData={
              projectProductAndAccountInformationData
            }
            updateProjectProductAndAccountInformationData={
              handleUpdateProjectProductAndAccountInformationData
            }
            holdingCompany={
              holdingCompany ||
              projectProductAndAccountInformationData.client?.holdingCompany
            }
          />
        ),
        documents: <Documents />,
        licensing: (
          <Licensing
            onChangesMade={handleSetTermsChanged}
            termsChanged={termsChanged}
          />
        ),
        projectTags: (
          <ProjectTags
            projectTags={projectTags}
            updateProjectTags={handleUpdateProjectTags}
          />
        ),
        subscriptionDetails: (
          <SubscriptionDetails
            projectSubscriptionDetails={projectSubscriptionDetails}
            updateProjectSubscriptionDetails={
              handleUpdateProjectSubscriptionDetails
            }
          />
        ),
        libraryTrackDetails: (
          <LibraryTrackDetails
            isLoading={isLoading}
            libraryTracks={projectLibraryTrackDetails}
          />
        ),
        libraryTrackAccountInformation: (
          <LibraryTrackAccountInformation
            projectProductAndAccountInformationData={
              projectProductAndAccountInformationData
            }
            updateProjectProductAndAccountInformationData={
              handleUpdateProjectProductAndAccountInformationData
            }
            setHoldingCompany={handleSetHoldingCompany}
          />
        ),
      }}
    </Project>
  );
};

export default ProjectContainer;
