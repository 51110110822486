import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, Input, Switch, Typography } from "antd";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import Creatable from "react-select/creatable";
import { OnChangeValue, components } from "react-select";
import { useTranslation } from "react-i18next";
import DeleteCreativeAgencyModalContent from "src/components/modals/delete-creative-agency";
import BaseModal from "src/components/modals/base-modal";
import getCreativeAgencies from "src/api/creative-agencies/get-creative-agencies";
import createCreativeAgency from "src/api/creative-agencies/create-creative-agency";
import deleteCreativeAgency from "src/api/creative-agencies/delete-creative-agency";
import useAuth from "src/auth/use-auth";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import style, { selectStyle } from "./styles";
import mainStyle from "../../../../styles";

export interface IOption {
  label: string;
  value: string;
}

interface ICreativeAgencyData {
  creativeAgencyId?: string | number;
  creativeAgencyName?: string;
  creativeAgencyInvolved?: boolean;
  creativeAgencyOffice?: string;
}

interface IProps {
  projectCreativeAgencyRowData: ICreativeAgencyData;
  updateProjectCreativeAgencyRowData: (projectCreativeAgencyRowData: {
    creativeAgencyId?: string | number;
    creativeAgencyOffice?: string;
    creativeAgencyInvolved?: boolean;
  }) => void;
  onToggleUpdate: (field: string, toggle: boolean) => void;
}

const CreativeAgencyRow = ({
  projectCreativeAgencyRowData,
  updateProjectCreativeAgencyRowData,
  onToggleUpdate,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const { organisationId, fetchWrapper } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [creativeAgencyName, setCreativeAgencyName] = useState<
    IOption | undefined
  >(
    projectCreativeAgencyRowData.creativeAgencyName
      ? {
          label: projectCreativeAgencyRowData.creativeAgencyName,
          value: projectCreativeAgencyRowData.creativeAgencyName,
        }
      : undefined
  );
  const [creativeAgencyOptions, setCreativeAgencyOptions] = useState<IOption[]>(
    []
  );

  const [selectedOptionToDelete, setSelectedOptionToDelete] = useState<
    IOption | undefined
  >();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const creativeAgencySelectRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomOption = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { label, value } = props;

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      setSelectedOptionToDelete({ label, value } as IOption);
      if (selectedOptionToDelete === creativeAgencyName) {
        setCreativeAgencyName(undefined);
      }
      setIsModalOpen(true);
    };

    return (
      <components.Option {...props}>
        <div css={style.optionRow}>
          <div>{label}</div>
          <button
            type="button"
            css={style.optionRowDelete}
            onClick={handleDelete}
          >
            <CloseIcon
              fill={theme.colors.black}
              height={9}
              width={9}
              title="Close"
              role="button"
            />
          </button>
        </div>
      </components.Option>
    );
  };

  useEffect(() => {
    const fetchCreativeAgencies = async () => {
      const creativeAgencies = await fetchWrapper(
        getCreativeAgencies,
        organisationId
      );
      if (creativeAgencies.length > 0) {
        const options = creativeAgencies.map((creativeAgency) => {
          return {
            value: creativeAgency.id,
            label: creativeAgency.name,
          };
        });

        setCreativeAgencyOptions(options);
      }
    };

    void fetchCreativeAgencies();
  }, [fetchWrapper, organisationId]);

  const resetCreativeAgency = useCallback(() => {
    setCreativeAgencyName(undefined);
    updateProjectCreativeAgencyRowData({
      creativeAgencyId: "",
      creativeAgencyOffice: "",
      creativeAgencyInvolved: false,
    });
  }, [updateProjectCreativeAgencyRowData]);

  const handleChange = useCallback(
    (newValue: OnChangeValue<IOption, false>) => {
      if (newValue !== null && selectedOptionToDelete === undefined) {
        setCreativeAgencyName(newValue);
        updateProjectCreativeAgencyRowData({
          creativeAgencyId: newValue.value,
        });
      } else {
        resetCreativeAgency();
      }
    },
    [
      resetCreativeAgency,
      selectedOptionToDelete,
      updateProjectCreativeAgencyRowData,
    ]
  );

  const handleChangeCreativeAgencyOffice = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (newValue !== projectCreativeAgencyRowData?.creativeAgencyOffice) {
        updateProjectCreativeAgencyRowData({
          creativeAgencyOffice: newValue,
        });
      }
    },
    [
      projectCreativeAgencyRowData?.creativeAgencyOffice,
      updateProjectCreativeAgencyRowData,
    ]
  );

  const handleCreateCreativeAgency = useCallback(
    async (inputValue: string) => {
      setIsCreating(true);
      try {
        const response = await fetchWrapper(
          createCreativeAgency,
          organisationId,
          inputValue
        );

        const newOption = { value: response.id, label: response.name };

        setCreativeAgencyOptions([...creativeAgencyOptions, newOption]);
        setCreativeAgencyName(newOption);
        handleChange(newOption);
      } catch (e) {
        log(DataDogLogTypes.ERROR, "Failed to create creative agency", e);
        ErrorToast(
          "delete-creative-agency-error",
          t("deleteCreativeAgency##Failed to create creative agency")
        );
      } finally {
        setIsCreating(false);
      }
    },
    [creativeAgencyOptions, fetchWrapper, handleChange, organisationId, t]
  );

  const handleDeleteCreativeAgencySubmit = async (
    creativeAgencyOption: IOption
  ) => {
    try {
      if (selectedOptionToDelete) {
        const deleted = await fetchWrapper(
          deleteCreativeAgency,
          organisationId,
          selectedOptionToDelete?.value
        );

        if (deleted) {
          if (creativeAgencyName?.value === creativeAgencyOption.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            creativeAgencySelectRef.current.setValue("");
          }

          const newOptions = [...creativeAgencyOptions];
          const index = creativeAgencyOptions.findIndex(
            (x) => x.value === creativeAgencyOption.value
          );

          if (index > -1) {
            newOptions.splice(index, 1);
          }
          setSelectedOptionToDelete(undefined);
          setCreativeAgencyOptions(newOptions);
          SuccessToast(t("deleteCreativeAgency##Creative agency deleted"));
        }
      }
      setIsModalOpen(false);
    } catch (e) {
      setIsModalOpen(false);
      log(DataDogLogTypes.ERROR, "Failed to delete creative agency", e);
      ErrorToast(
        "delete-creative-agency-error",
        t("deleteCreativeAgency##Failed to delete creative agency")
      );
    }
  };

  const renderModalContent = () => {
    return (
      <DeleteCreativeAgencyModalContent
        handleDeleteCreativeAgency={handleDeleteCreativeAgencySubmit}
        creativeAgencyOption={selectedOptionToDelete}
        setIsModalOpen={setIsModalOpen}
      />
    );
  };

  const handleToggleAgencyInvolved = useCallback(
    (toggle: boolean) => {
      if (!toggle) {
        setCreativeAgencyName(undefined);
        updateProjectCreativeAgencyRowData({
          creativeAgencyId: "",
          creativeAgencyOffice: "",
          creativeAgencyInvolved: toggle,
        });
      } else {
        onToggleUpdate("creativeAgencyInvolved", toggle);
      }
    },
    [onToggleUpdate, updateProjectCreativeAgencyRowData]
  );

  return (
    <div>
      <div css={mainStyle.switchContainer}>
        <div css={mainStyle.basicInputContainer}>
          <Typography.Text css={mainStyle.basicInputLabel}>
            {t("ProjectsPage##Is there a creative agency involved?")}
          </Typography.Text>
        </div>
        <Form.Item
          css={[mainStyle.formItem, mainStyle.additionalFormItemStyle]}
        >
          <Switch
            onChange={handleToggleAgencyInvolved}
            checked={projectCreativeAgencyRowData.creativeAgencyInvolved}
            data-testid="creative-agency-toggle"
          />
        </Form.Item>
      </div>
      {projectCreativeAgencyRowData.creativeAgencyInvolved && (
        <>
          <Typography.Text css={mainStyle.basicInputLabel}>
            {t("ProjectsPage##Creative Agency")}
          </Typography.Text>
          <Creatable
            isClearable
            components={{ Option: CustomOption }}
            onChange={handleChange}
            onCreateOption={handleCreateCreativeAgency}
            ref={creativeAgencySelectRef}
            options={creativeAgencyOptions}
            value={creativeAgencyName}
            styles={selectStyle}
            id="creative-agency-textbox"
            isLoading={isCreating}
            isDisabled={isCreating}
          />
          <Form.Item css={mainStyle.formItem}>
            <div css={mainStyle.basicInputContainer}>
              <Typography.Text css={mainStyle.basicInputLabel}>
                {t("ProjectsPage##creativeAgencyOffice")}
              </Typography.Text>
            </div>
            <Input
              name="creativeAgencyOffice"
              value={projectCreativeAgencyRowData.creativeAgencyOffice}
              onChange={handleChangeCreativeAgencyOffice}
              data-testid="creative-agency-office-location"
              disabled={!projectCreativeAgencyRowData.creativeAgencyId}
            />
          </Form.Item>
        </>
      )}
      <BaseModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        content={renderModalContent()}
        footer={null}
      />
    </div>
  );
};

export default CreativeAgencyRow;
