import axios from "axios";
import config from "src/config";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const removeContactFromGroup = async (
  accessToken: string,
  id: string,
  isUserGroup: string,
  organisationId: string,
  talentId: string
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  await axios.delete(
    `${config.projectManagementService.url}/talent-hub/group/${id}/talent-contact/${talentId}?orgId=${organisationId}&isUserGroup=${isUserGroup}`,
    options
  );
};

export default removeContactFromGroup;
