import { useCallback } from "react";
import { startCase } from "lodash";
import {
  IFinalTrackSection,
  IProjectTalents,
} from "src/pages/projects/project/interfaces";
import deleteTrack from "src/api/tracks/delete-track";
import useAuth from "src/auth/use-auth";
import getProject from "src/api/projects/get-project";
import useProject from "src/providers/project/hooks";
import { ErrorToast } from "src/components/toast-notification";
import ValidationErrorIcon from "src/app/assets/icons/component-icons/alert-error";
import style from "../../styles";
import mainStyles from "../../../services/styles";
import CollapsibleHeaderDeleteTrack from "../collapsible-header-delete-track/CollapsibleHeaderDeleteTrack";

type IProps = {
  finalTrack: IFinalTrackSection;
  onSelectedTrackChange: (track?: IFinalTrackSection) => void;
  isValid: boolean;
  onDelete: () => void;
};
const TrackFormHeader = ({
  finalTrack,
  onSelectedTrackChange,
  isValid,
  onDelete,
}: IProps): JSX.Element => {
  const { getAccessToken, organisationId, fetchWrapper } = useAuth();
  const { storeTracks } = useProject();

  const handleDeleteConfirmation = useCallback(
    async (projectId: string, trackId?: string) => {
      if (trackId) {
        try {
          await fetchWrapper(deleteTrack, projectId, organisationId, trackId);
          const accessToken: string = getAccessToken();

          const projectResponse = await getProject(
            accessToken,
            projectId,
            organisationId
          );
          if (projectResponse) {
            storeTracks({
              tracks: projectResponse.tracks ?? [],
              projectVersion: projectResponse.version,
            });
          } else {
            ErrorToast("GetProjectError", "Error refreshing project data.");
          }
        } catch {
          ErrorToast(
            "DeleteTrackError",
            "Sorry, there was a problem deleting this track. Please try again."
          );
        }

        onSelectedTrackChange();
        onDelete();
      }
    },
    [
      fetchWrapper,
      getAccessToken,
      onSelectedTrackChange,
      organisationId,
      storeTracks,
      onDelete,
    ]
  );
  const handleExpandRow = useCallback(
    (expandedTrack: IFinalTrackSection) => {
      onSelectedTrackChange(expandedTrack);
    },
    [onSelectedTrackChange]
  );

  const getFormattedTalents = useCallback((talents: IProjectTalents[]) => {
    return talents.map((artist) => startCase(artist.name)).join(", ");
  }, []);

  return (
    <div
      role="button"
      tabIndex={-1}
      onKeyDown={(e) => {
        e.persist();
      }}
      onClick={() => handleExpandRow(finalTrack)}
      css={style.collapseSummaryContainer}
      data-testid="track-summary-row"
    >
      <div css={mainStyles.collapsibleTableHeaderFirstCell}>
        <div css={style.summaryColumnInformation}>
          {finalTrack?.title ? startCase(finalTrack.title) : "-"}
        </div>
      </div>
      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {finalTrack?.artistContacts
            ? getFormattedTalents(finalTrack.artistContacts)
            : "-"}
        </div>
      </div>

      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {finalTrack?.writerContacts
            ? getFormattedTalents(finalTrack.writerContacts)
            : "-"}
        </div>
      </div>

      <div css={style.summaryColumnContainer}>
        <div css={style.summaryColumnInformation}>
          {finalTrack?.musicType ? startCase(finalTrack.musicType) : "-"}
        </div>
      </div>
      <div css={style.summaryIconsContainer}>
        {!isValid && (
          <ValidationErrorIcon
            width={24}
            height={24}
            title="Required Fields Missing"
          />
        )}
        <div css={style.summaryIconContainer}>
          <CollapsibleHeaderDeleteTrack
            finalTrack={finalTrack}
            onDeleteClick={handleDeleteConfirmation}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackFormHeader;
