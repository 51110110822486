import axios from "axios";
import {
  ICreateServiceRequest,
  ICreateServiceResponse,
} from "src/pages/projects/project/interfaces";
import { createServiceUrl } from "../../project-url-helper";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const createService = async (
  accessToken: string,
  projectId: string,
  payload: ICreateServiceRequest
): Promise<ICreateServiceResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.post(createServiceUrl(projectId), payload, options);
};

export default createService;
