import { ReactElement } from "react";
import theme from "src/theme";
import IIconComponentProps from "../../interfaces";

const UploadIcon = ({
  height = "38",
  width = "38",
  title,
  role = "img",
}: IIconComponentProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
      role={role}
    >
      <title id="upload-icon-title">{title}</title>
      <g fill="none" fillRule="evenodd">
        <path
          fill={theme.colors.secondary.lightBlueGreen}
          fillRule="nonzero"
          d="M28.8432617,12.0568848 C27.7624512,6.63403319 22.4902344,3.11425781 17.0673828,4.19506837 C13.1010742,4.98559569 9.99975587,8.08496094 9.20678712,12.0507813 C5.36181637,12.4938965 2.60375975,15.9699707 3.04663087,19.8151856 C3.45434569,23.3540039 6.45166012,26.0239258 10.013916,26.021486 L15.0197754,26.021486 C15.5727539,26.021486 16.0209961,25.5732422 16.0209961,25.0202637 C16.0209961,24.4672851 15.5727539,24.019043 15.0197754,24.019043 L10.013916,24.019043 C7.24902344,24.0026856 5.02124025,21.748291 5.0373535,18.9836426 C5.05371094,16.2189941 7.3081055,13.9909668 10.0729981,14.0070801 C10.5832519,14.0073242 11.0119629,13.623291 11.0681152,13.1162109 C11.576416,8.72192381 15.5507812,5.57177737 19.9450684,6.08007812 C23.6394043,6.50756837 26.5537109,9.42163087 26.980957,13.1162109 C27.0649414,13.6323242 27.512207,14.0104981 28.0351562,14.0070801 C30.8000488,14.0070801 33.0412597,16.248291 33.0412597,19.0131836 C33.0412597,21.777832 30.8000488,24.019043 28.0351562,24.019043 L23.0292969,24.019043 C22.4763184,24.019043 22.0280762,24.4672851 22.0280762,25.0202637 C22.0280762,25.5732422 22.4763184,26.021486 23.0292969,26.021486 L28.0354004,26.021486 C31.9057617,25.9973144 35.0239257,22.840332 35.0001368,18.9697266 C34.9780274,15.4450684 32.3417969,12.4851074 28.8432617,12.0568848 L28.8432617,12.0568848 Z"
        />
        <path
          fill={theme.colors.secondary.lightBlueGreen}
          fillRule="nonzero"
          d="M22.5637207,21.9665528 C22.9479981,22.3645019 23.5817871,22.3754883 23.9794922,21.9912109 C24.3771972,21.6071778 24.3881836,20.9733887 24.0041504,20.5756836 C23.9960938,20.5671387 23.987793,20.559082 23.9794922,20.5510254 L19.7333984,16.3039551 C19.3430176,15.9123535 18.7089844,15.9116211 18.3173828,16.3020019 C18.3168945,16.3027344 18.3161621,16.3032226 18.3156738,16.3039551 L14.0695801,20.5510254 C13.671875,20.9350586 13.6608887,21.5688476 14.0449219,21.9665527 C14.4291992,22.3642578 15.0629882,22.3752441 15.4606934,21.9912109 C15.4689941,21.9831543 15.4772949,21.9748535 15.4853516,21.9665527 L18.0234375,19.4274902 L18.0234375,32.0285644 C18.0234375,32.581543 18.4716797,33.0297851 19.0246582,33.0297851 C19.5773926,33.0297851 20.0256348,32.581543 20.0256348,32.0285644 L20.0256348,19.4274902 L22.5637207,21.9665528 Z"
        />
      </g>
    </svg>
  );
};

export default UploadIcon;
