import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "src/app/assets/icons/component-icons/buttons/download";
import ControlButton from "../base-button";

interface IProps {
  showLabel?: boolean;
  showTooltip?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tooltipColor?: string;
}

const DownloadButton = ({
  showLabel = false,
  showTooltip = true,
  className = "",
  onClick,
  tooltipColor,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <ControlButton
      className={className}
      showLabel={showLabel}
      showTooltip={showTooltip}
      label={t("Download")}
      tooltipColor={tooltipColor}
      data-testid="download-button"
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <DownloadIcon />
    </ControlButton>
  );
};

export default DownloadButton;
