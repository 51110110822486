import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import deleteOrganisationInvite from "src/api/organisation-invites/delete-org-invite";
import getOrganisationInvites from "src/api/organisation-invites/get-org-invites";
import useAuth from "src/auth/use-auth";
import { IConfirmContentProps } from "src/components/interfaces";
import { ModalTypes } from "src/components/modals/base-modal";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import OrganisationInvitesTable from "..";
import resendOrganisationInvite from "../../../../../api/organisation-invites/resend-org-invite";
import { IMemberInvite } from "../../../interfaces";
import DeleteTeamInviteModalMainContent from "../components/invite-members/delete-team-invite";

interface IProps {
  invitesLastUpdatedAt: Date;
}

const OrganisationInvitesContainer = ({
  invitesLastUpdatedAt,
}: IProps): ReactElement => {
  const [orgInvitesData, setOrgInvitesData] = useState<IMemberInvite[] | []>(
    []
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModalType, setOpenModalType] = useState<ModalTypes>(
    ModalTypes.deleteOrganisationInvite
  );
  const [selectedInvite, setSelectedInvite] = useState<IMemberInvite>();

  const { t } = useTranslation();
  const { getAccessToken, organisationId, isSessionValid } = useAuth();

  const getData = useCallback(async (): Promise<void> => {
    const isSession = await isSessionValid();
    const accessToken = getAccessToken();

    if (organisationId && accessToken && isSession) {
      const response: IMemberInvite[] = await getOrganisationInvites(
        accessToken,
        organisationId
      );

      setOrgInvitesData(response);
    }
  }, [getAccessToken, isSessionValid, organisationId]);

  const handleResendInvite = useCallback(
    async (inviteId: string) => {
      const isSession = await isSessionValid();
      const accessToken = getAccessToken();

      try {
        if (organisationId && isSession) {
          await resendOrganisationInvite(accessToken, {
            organisationId,
            organisationInviteId: inviteId,
          });
          SuccessToast(t("manageTeam##invites##Invite resent successfully"));
        }
      } catch (e) {
        log(DataDogLogTypes.ERROR, "Error resending invite", e);
        ErrorToast(
          "invite-resend-error",
          t("manageTeam##invites##An error occured resending the invite.")
        );
      }
    },
    [getAccessToken, isSessionValid, organisationId, t]
  );

  const handleDeleteInvite = useCallback(
    (inviteId: string) => {
      setIsModalOpen(true);
      setOpenModalType(ModalTypes.deleteOrganisationInvite);

      const orgInvite = orgInvitesData.find((invite) => {
        return invite.id === inviteId;
      });

      setSelectedInvite(orgInvite);
    },
    [orgInvitesData]
  );

  const handleOnCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleConfirmDeleteInviteClick = useCallback(async () => {
    if (selectedInvite) {
      setIsModalOpen(false);
      const isSession = await isSessionValid();
      const accessToken = getAccessToken();

      try {
        if (organisationId && isSession) {
          await deleteOrganisationInvite(
            organisationId,
            selectedInvite.id,
            accessToken
          );

          const orgInvite = orgInvitesData.find((invite) => {
            return invite.id === selectedInvite.id;
          });

          setSelectedInvite(orgInvite);
        }

        await getData();
        SuccessToast(t("manageTeam##invites##Organisation invite deleted"));
      } catch (e) {
        log(DataDogLogTypes.ERROR, "Error deleting invite", e);
        ErrorToast(
          "member-delete-error",
          t("manageTeam##invites##An error occurred deleting this invitation")
        );
      }
    }
  }, [
    getAccessToken,
    getData,
    isSessionValid,
    orgInvitesData,
    organisationId,
    selectedInvite,
    t,
  ]);

  const orgMemberInvite = selectedInvite ? `${selectedInvite.email}` : "";

  const confirmDeleteContentProps: IConfirmContentProps = useMemo(() => {
    return {
      confirmAction: handleConfirmDeleteInviteClick,
      onClose: () => setIsModalOpen(false),
      primaryButtonLabel: "manageTeam##invites##Delete invite",
      secondaryButtonLabel: "Cancel",
      mainContent: (
        <DeleteTeamInviteModalMainContent inviteEmail={orgMemberInvite} />
      ),
    };
  }, [handleConfirmDeleteInviteClick, orgMemberInvite]);

  return (
    <OrganisationInvitesTable
      data-testid="team-invites-table"
      orgInvites={orgInvitesData}
      getData={getData}
      handleResendInvite={handleResendInvite}
      handleDeleteInvite={handleDeleteInvite}
      handleConfirmDeleteInviteClick={handleConfirmDeleteInviteClick}
      invitesLastUpdatedAt={invitesLastUpdatedAt}
      isModalOpen={isModalOpen}
      openModalType={openModalType}
      confirmDeleteContentProps={confirmDeleteContentProps}
      handleOnCloseModal={handleOnCloseModal}
    />
  );
};

export default OrganisationInvitesContainer;
