import React, { useCallback, useEffect, useMemo } from "react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import { useTranslation } from "react-i18next";
import { Form, Typography } from "antd";
import CurrencyAndPrice from "src/components/currency-and-price";
import { Currencies } from "src/constants";
import { IMonetaryValue } from "src/interfaces/monetary-value";
import contactValidation from "src/utils/contactValidation";
import { useForm } from "antd/lib/form/Form";
import { formatCurrency } from "src/utils/currency";
import useAuth from "src/auth/use-auth";
import isValidPriceValue from "src/utils/validate-price";
import ContactSelect from "../contact-select";
import style from "../../styles";
import ContactInput from "../contact-input";
import { IPublishingInformationData, Signed } from "../../interfaces";
import ContactSwitch from "../contact-switch";

interface IProps {
  publishingInformationData: IPublishingInformationData;
  validateTabFields: number;
  onPublishingInformationDataChange: (data: IPublishingInformationData) => void;
}

const PublishingInformation = ({
  publishingInformationData,
  validateTabFields,
  onPublishingInformationDataChange,
}: IProps): React.ReactElement => {
  useWindowSize();
  const isMobile = getIsMobile();
  const { t } = useTranslation();
  const [form] = useForm();
  const { organisationId, isSuperAdmin } = useAuth();

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onPublishingInformationDataChange({
        [event.target.name]: event.target.value,
      });
    },
    [onPublishingInformationDataChange]
  );

  const onSingleSelectChange = useCallback(
    (property: string, option: IOption) => {
      onPublishingInformationDataChange({
        [property]: option.value,
      });
    },
    [onPublishingInformationDataChange]
  );

  const signedOptions = useMemo(() => {
    return Object.entries(Signed).map(([value, label]) => ({
      label,
      value,
    }));
  }, []);

  const minimumDemoFeeCurrency = useMemo(() => {
    return (
      publishingInformationData?.minimumDemoFee?.currency ?? Currencies.USD.code
    );
  }, [publishingInformationData?.minimumDemoFee?.currency]);

  const minimumDemoFeePrice = useMemo(() => {
    return publishingInformationData?.minimumDemoFee?.value !== 0
      ? publishingInformationData?.minimumDemoFee?.value
      : "";
  }, [publishingInformationData?.minimumDemoFee?.value]);

  const currencyOptions: IOption[] = useMemo(
    () =>
      Object.values(Currencies).map((value) => {
        return {
          value: value.code,
          label: value.displayText,
        };
      }),
    []
  );

  const getCurrency = useCallback(
    (currentCurrency: string) =>
      currencyOptions.find((option) => option.value === currentCurrency),
    [currencyOptions]
  );

  const handleFeeChange = useCallback(
    (field: string, updatedFee: IMonetaryValue) => {
      onPublishingInformationDataChange({
        [field]: updatedFee,
      });
    },
    [onPublishingInformationDataChange]
  );

  useEffect(() => {
    if (validateTabFields) {
      void form.validateFields([
        "signedCMA",
        "signedNDA",
        "minimumDemoFeeCurrency",
        "minimumDemoFeePrice",
      ]);
    }
  }, [form, validateTabFields]);

  const findSingleSelectOption = useCallback(
    (options: IOption[], value: string) => {
      return options.find((option) => option.value === value);
    },
    []
  );

  return (
    <Form
      form={form}
      initialValues={{
        signedCMA:
          publishingInformationData?.signedCMA &&
          findSingleSelectOption(
            signedOptions,
            publishingInformationData.signedCMA
          ),
        signedNDA:
          publishingInformationData?.signedNDA &&
          findSingleSelectOption(
            signedOptions,
            publishingInformationData.signedNDA
          ),
        minimumDemoFeeCurrency: getCurrency(minimumDemoFeeCurrency),
        minimumDemoFeePrice:
          minimumDemoFeePrice && isValidPriceValue(minimumDemoFeePrice)
            ? formatCurrency(minimumDemoFeePrice)
            : undefined,
        performingRightsOrganisationNumber:
          publishingInformationData?.performingRightsOrganisationNumber,
      }}
      scrollToFirstError
    >
      <div id="PublishingInformation" css={style.sectionContainer}>
        <Typography.Text css={style.tabsTitle}>
          {t("ContactPage##Publishing Information")}
        </Typography.Text>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSelect
            title={t("ContactPage##CMA Signed?")}
            name="signedCMA"
            rules={contactValidation.requiredFieldValidator()}
            options={signedOptions}
            id="cma-signed-dropdown"
            onChange={(signedCMA: IOption) => {
              onSingleSelectChange("signedCMA", signedCMA);
            }}
          />
          <ContactSelect
            title={t("ContactPage##NDA Signed?")}
            name="signedNDA"
            rules={contactValidation.requiredFieldValidator()}
            options={signedOptions}
            id="nda-signed-dropdown"
            onChange={(signedNDA: IOption) => {
              onSingleSelectChange("signedNDA", signedNDA);
            }}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <CurrencyAndPrice
            currency={minimumDemoFeeCurrency}
            price={minimumDemoFeePrice}
            currencyFormItemName="minimumDemoFeeCurrency"
            priceFormItemName="minimumDemoFeePrice"
            onChange={handleFeeChange}
            label={t("ContactPage##Minimum Demo Fee")}
            propertyName="minimumDemoFee"
            validateRequiredFields
            testId="minimumDemoFeePrice"
          />
          <ContactInput
            title={t("ContactPage##Performing Rights Organisation Number")}
            name="performingRightsOrganisationNumber"
            rules={[]}
            onChange={onInputChange}
          />
        </div>
        <div
          css={isMobile ? style.mobileSectionContainer : style.clientContainer}
        >
          <ContactSwitch
            title={t("ContactPage##Share contact with all of Songtradr")}
            checked={publishingInformationData?.connectedToOrgId === null}
            onChange={(toggle: boolean) => {
              onPublishingInformationDataChange({
                connectedToOrgId: toggle ? null : organisationId,
              });
            }}
          />
          {isSuperAdmin ? (
            <ContactSwitch
              title={t("ContactPage##Is service provider")}
              checked={publishingInformationData?.serviceProvider ?? false}
              onChange={(serviceProvider: boolean) => {
                onPublishingInformationDataChange({
                  serviceProvider,
                });
              }}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    </Form>
  );
};

export default PublishingInformation;
