import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

export default {
  heading: css`
    font-family: ${theme.fonts.sofiaPro};
    margin-bottom: 0 !important;

    @media ${queries.medium} {
      font-size: 2.25rem !important;
    }

    @media ${queries.large} {
      font-size: 2.75rem !important;
    }
  `,
  headerContainer: css`
    display: flex;
    width: 100%;
  `,
  container: css`
    padding: 0;
    padding-bottom: 1rem;
    background-color: ${theme.colors.background.white};
    font-family: ${theme.fonts.sofiaPro};
    .ant-tabs-tab {
      margin: 0 3rem 0 0;
    }
  `,
  projectUpperHeaderBoxShadowing: css`
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
  `,
  subHeaderBoxShadow: css`
    height: 1px;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
  `,
  contentContainer: css`
    border-radius: 0;
  `,
  typographyTitle: css`
    margin-top: 0;
    margin-bottom: 0 !important;
    font-weight: 700 !important;
    font-size: 32px !important;
    align-self: center;
    font-family: ${theme.fonts.sofiaPro};
  `,
  subHeaderContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3rem;
    align-items: center;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
    z-index: 2000;
  `,
};
