import { css, SerializedStyles } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  progressBar: css`
    width: 80%;

    @media ${queries.medium} {
      padding-left: 5rem;
      padding-right: 0.625rem;
      width: 90%;
    }
    float: left;

    .ant-progress-bg {
      height: 0.625rem !important;
    }

    .anticon-check-circle {
      display: none;
    }
  `,

  fileName: css`
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    @media ${queries.medium} {
      padding-left: 5rem;
    }
    margin-top: -0.625rem;
  `,
  fileRow(isInValid: boolean): SerializedStyles {
    return css`
      margin-bottom: ${isInValid ? "3.75rem" : "2.5rem"};
    `;
  },
  progressBarContainer: css`
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
    pointer-events: none;
    min-height: 12.5rem;

    .uploadCompleteIcon {
      margin-top: -0.4375rem;
    }

    .uploadDeleteIconValid {
      pointer-events: auto;
      margin-top: -0.3125rem;
    }

    .uploadDeleteIconInValid {
      pointer-events: auto;
      margin-bottom: -0.75rem;
    }

    .validationErrorIcon {
      margin-right: 0.5rem;
      margin-bottom: -0.1875rem;
    }
  `,
  fileUploadErrorMessage: css`
    color: ${theme.colors.functional.red};
    text-align: left;
    font-size: 0.625rem;

    @media ${queries.medium} {
      padding-left: 5rem;
      font-size: 0.75rem;
    }
    margin-top: -0.625rem;
  `,
  fileUploadText: css`
    margin-bottom: -2.5rem;
  `,
  browseBtn: css`
    color: ${theme.colors.primary.blue};
    font-weight: bold;
    .ant-upload-drag-container {
      cursor: pointer;
    }
    pointer-events: auto;
  `,
};
