import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { Operation } from "src/constants";
import mainStyles from "src/pages/projects/project/styles";
import * as uuid from "uuid";
import SocialMediaLinkRow from "./social-media-link-row";

interface IProps {
  fieldName: string;
  items: string[];
  setSocialMediaLinks: (link: string[]) => void;
}

const SocialMediaLinks = ({
  fieldName,
  items,
  setSocialMediaLinks,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const onPerformOperation = useCallback(
    (operation: Operation, data?: string, index?: number) => {
      if (data !== undefined) {
        const isRemoveOperation = operation === Operation.remove;
        const isUpdateOperation = operation === Operation.update;
        const isValidIndex = index || index === 0;

        if (isRemoveOperation && isValidIndex) {
          const newArray = [...items];
          newArray.splice(index, 1);
          setSocialMediaLinks(newArray);
        } else if (operation === Operation.add) {
          setSocialMediaLinks([...items, data]);
        } else if (isUpdateOperation && isValidIndex) {
          const newArray = [...items];
          newArray[index] = data;
          setSocialMediaLinks(newArray);
        }
      }
    },
    [items, setSocialMediaLinks]
  );

  const handleAddRowItem = useCallback(() => {
    onPerformOperation(Operation.add, "", items.length);
  }, [items.length, onPerformOperation]);

  const handleDeleteRowItem = useCallback(
    (index: number) => {
      const itemToDelete = items[index];
      if (itemToDelete !== undefined) {
        onPerformOperation(Operation.remove, itemToDelete, index);
      }
    },
    [items, onPerformOperation]
  );

  const handleUpdateRowItem = useCallback(
    (index: number, value: string) => {
      onPerformOperation(Operation.update, value, index);
    },
    [onPerformOperation]
  );

  return (
    <div css={mainStyles.sectionFormContainer}>
      <Typography.Text css={mainStyles.basicInputLabel}>
        {t("ContactPage##Social Media")}
      </Typography.Text>
      {items.map((socialLink, index) => {
        return (
          <SocialMediaLinkRow
            key={`${fieldName}-linkRow-${uuid.v4()}`}
            data={socialLink}
            index={index}
            totalRows={items.length}
            fieldName={fieldName}
            onAddNewRow={handleAddRowItem}
            onDeleteRow={handleDeleteRowItem}
            onUpdateRow={handleUpdateRowItem}
          />
        );
      })}
    </div>
  );
};

export default SocialMediaLinks;
