import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ErrorToast, SuccessToast } from "src/components/toast-notification";
import {
  IProjectProps,
  IProjectTableSort,
  IPaginationOptions,
  ProjectSortType,
  SortOrder,
  IFiltersResponse,
  IFiltersSearch,
  IActiveLabels,
} from "src/pages/projects/project-listings/interfaces";
import searchProjects from "src/api/projects/search-projects";
import useAuth from "src/auth/use-auth";
import { ProjectsPageSize } from "src/constants";
import { useHistory, useLocation } from "react-router-dom";
import useDebounce from "src/utils/useDebounce";
import { createProject, getProject, updateProject } from "src/api/projects";
import { useTranslation } from "react-i18next";
import { IProjectCreateResponse } from "src/pages/projects/project/interfaces";
import getProjectFilters from "src/api/projects/get-project-filters";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import duplicateProject from "src/api/projects/duplicate-project";
import { isDateSelectedAsFilter } from "src/pages/projects/project-listings/utils";
import {
  ISavedUserColumn,
  ISavedUserFilter,
} from "src/api/user-filters/interfaces";
import { getUserFilters } from "src/api/user-filters";
import mapColumnsInCheckboxLabels from "src/components/modals/view-columns-modal/utils";
import { AllProjectColumns } from "src/components/modals/view-columns-modal";
import { getStringWithQuotesAroundForwardSlashes } from "src/utils/string";
import ProjectsSearchContext from "./context";

interface IOrganisationProvider {
  children: ReactNode;
}

const ProjectsSearchProvider = ({
  children,
}: IOrganisationProvider): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDuplicatingProject, setIsDuplicatingProject] = useState(false);
  const [projects, setProjects] = useState<IProjectProps[] | []>([]);
  const [activeLabels, setActiveLabels] = useState<IActiveLabels>({
    statuses: [],
    clients: [],
    leads: [],
    globalBrands: [],
    contacts: [],
    creativeAgencies: [],
    holdingCompanies: [],
    musicTypes: [],
    projectTypes: [],
    serviceTypes: [],
    projectCloseDate: [],
    jobListingDate: [],
    projectRegions: [],
    officeLocations: [],
    copyrightInfoProcessed: [],
    publishingRightsSigned: [],
    publishingTriggered: [],
  });

  const [appliedFilters, setAppliedFilters] = useState<IFiltersSearch>({
    statuses: [],
    clients: [],
    leads: [],
    globalBrands: [],
    contacts: [],
    creativeAgencies: [],
    holdingCompanies: [],
    musicTypes: [],
    projectTypes: [],
    serviceTypes: [],
    projectCloseDateFrom: null,
    projectCloseDateTo: null,
    jobListingDateFrom: null,
    jobListingDateTo: null,
    projectRegions: [],
    officeLocations: [],
    copyrightInfoProcessed: null,
    publishingRightsSigned: null,
    publishingTriggered: null,
  });
  const [availableFilters, setAvailableFilters] = useState<IFiltersResponse>({
    statuses: [],
    clients: [],
    leads: [],
    globalBrands: [],
    contacts: [],
    creativeAgencies: [],
    holdingCompanies: [],
    musicTypes: [],
    projectTypes: [],
    serviceTypes: [],
    projectRegions: [],
    officeLocations: [],
    copyrightInfoProcessed: null,
    publishingRightsSigned: null,
    publishingTriggered: null,
  });
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchText, 1000);
  const [sortOptions, setSortOptions] = useState<IProjectTableSort>({
    sortBy: ProjectSortType.lastUpdated,
    order: SortOrder.DESC,
  });
  const [
    paginationOptions,
    setPaginationOptions,
  ] = useState<IPaginationOptions>({
    total: 0,
    currentPage: 1,
  });
  const [myPresetFilters, setMyPresetFilters] = useState({
    myOpenProjects: false,
    myAtContractProjects: false,
    myClosedProjects: false,
  });
  const [savedFilters, setSavedFilters] = useState<ISavedUserFilter[] | null>(
    null
  );
  const [savedColumns, setSavedColumns] = useState<null | ISavedUserColumn[]>(
    null
  );
  const [isLoadingUserFilters, setIsLoadingUserFilters] = useState(true);
  const history = useHistory();
  const { getAccessToken, organisationId, fetchWrapper } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  const noFiltersSet =
    appliedFilters.clients.length === 0 &&
    appliedFilters.globalBrands.length === 0 &&
    appliedFilters.statuses.length === 0 &&
    appliedFilters.leads.length === 0 &&
    appliedFilters.contacts.length === 0 &&
    appliedFilters.creativeAgencies.length === 0 &&
    appliedFilters.holdingCompanies.length === 0 &&
    appliedFilters.musicTypes.length === 0 &&
    appliedFilters.projectTypes.length === 0 &&
    appliedFilters.serviceTypes.length === 0 &&
    isDateSelectedAsFilter(
      appliedFilters.projectCloseDateFrom,
      appliedFilters.projectCloseDateTo
    ) === 0 &&
    isDateSelectedAsFilter(
      appliedFilters.jobListingDateFrom,
      appliedFilters.jobListingDateTo
    ) === 0 &&
    appliedFilters.projectRegions.length === 0 &&
    appliedFilters.officeLocations.length === 0 &&
    appliedFilters.copyrightInfoProcessed === null &&
    appliedFilters.publishingRightsSigned === null &&
    appliedFilters.publishingTriggered === null;

  const handleDeleteProject = async (id: string) => {
    const accessToken = getAccessToken();

    try {
      setIsLoading(true);
      const projectData = await getProject(accessToken, id, organisationId);
      if (!projectData) throw new Error();
      await updateProject(accessToken, id, {
        status: "Deleted",
        version: projectData.version,
        organisationId,
      });
      const updatedProjects = projects.filter((project) => {
        return project.id !== id;
      });
      setProjects(updatedProjects);
      if (updatedProjects.length === 0 && paginationOptions.currentPage > 1) {
        history.push({
          search: `sortBy=${sortOptions.sortBy}&order=${
            sortOptions.order
          }&page=${paginationOptions.currentPage - 1}`,
        });
      }
      setPaginationOptions({
        ...paginationOptions,
        total: paginationOptions.total - 1,
      });
      setIsLoading(false);
      SuccessToast(t("ProjectsPage##DeleteProject##Project deleted."));
    } catch (error) {
      log(DataDogLogTypes.ERROR, "Error deleting project", error);
      ErrorToast(
        "delete-project-error",
        t(
          "ProjectsPage##DeleteProject##There was an error deleting this project, please try again."
        )
      );
      setIsLoading(false);
    }
  };

  const handleCreateProject = async (
    name: string,
    status: string
  ): Promise<IProjectCreateResponse> => {
    try {
      setIsLoading(true);
      const request = {
        name,
        status,
        organisationId,
        jobListingDate: new Date().toISOString(),
      };

      const newProject = await fetchWrapper(createProject, request);

      const projectId: string = newProject.id;
      history.push(`/project/${projectId}`);
      return Promise.resolve(newProject);
    } catch (error) {
      log(DataDogLogTypes.ERROR, "Error creating project", error);
      ErrorToast(
        "create-project-error",
        t("There was an error creating a new project. Please try again.")
      );
      return Promise.reject(
        Error(t("There was an error creating a new project. Please try again."))
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjects = async (
    { sortBy, order }: IProjectTableSort,
    searchTextQuery?: string,
    from?: number
  ) => {
    const modifiedSearchTextQuery: string | undefined =
      searchTextQuery && searchTextQuery.includes("/")
        ? getStringWithQuotesAroundForwardSlashes(searchTextQuery)
        : searchTextQuery;

    const sort = {
      by: sortBy,
      order,
    };
    const response = await fetchWrapper(
      searchProjects,
      organisationId,
      sort,
      from,
      modifiedSearchTextQuery,
      appliedFilters
    );

    return response;
  };

  const handleDuplicateProject = async (projectId: string) => {
    try {
      setIsDuplicatingProject(true);
      const data = await fetchWrapper(
        duplicateProject,
        projectId,
        organisationId
      );
      if (data) {
        SuccessToast(t("ProjectsPage##Project duplicated"));
        const { id } = data;
        history.push(`/project/${id}`);
      }
    } catch (error) {
      log(DataDogLogTypes.ERROR, "Error duplicating project", error);
      ErrorToast(
        "duplicate-project-error",
        t(
          "ProjectsPage##There was an error when duplicating a project, please try again."
        )
      );
    } finally {
      setIsDuplicatingProject(false);
    }
  };

  const clearSearch = () => {
    if (searchText === "") return;
    setIsLoading(true);
    setSearchText("");
  };

  const handlePageChange = useCallback(
    (page: number) => {
      if (page > 1) {
        history.push({
          search: `sortBy=${sortOptions.sortBy}&order=${sortOptions.order}&page=${page}`,
        });
      } else {
        history.push({
          search: `sortBy=${sortOptions.sortBy}&order=${sortOptions.order}`,
        });
      }
    },
    [history, sortOptions.order, sortOptions.sortBy]
  );

  const clearFilters = () => {
    if (noFiltersSet) return;
    handlePageChange(1);
    setAppliedFilters({
      statuses: [],
      clients: [],
      leads: [],
      globalBrands: [],
      contacts: [],
      creativeAgencies: [],
      holdingCompanies: [],
      musicTypes: [],
      projectTypes: [],
      serviceTypes: [],
      projectCloseDateFrom: null,
      projectCloseDateTo: null,
      jobListingDateFrom: null,
      jobListingDateTo: null,
      projectRegions: [],
      officeLocations: [],
      copyrightInfoProcessed: null,
      publishingRightsSigned: null,
      publishingTriggered: null,
    });
    setActiveLabels({
      statuses: [],
      clients: [],
      leads: [],
      globalBrands: [],
      contacts: [],
      creativeAgencies: [],
      holdingCompanies: [],
      musicTypes: [],
      projectTypes: [],
      serviceTypes: [],
      projectCloseDate: [],
      jobListingDate: [],
      projectRegions: [],
      officeLocations: [],
      copyrightInfoProcessed: [{ label: "None", value: "" }],
      publishingRightsSigned: [{ label: "None", value: "" }],
      publishingTriggered: [{ label: "None", value: "" }],
    });
  };

  const handleSetMyPresetFilters = useCallback(
    (
      myOpenProjects: boolean,
      myAtContractProjects: boolean,
      myClosedProjects: boolean
    ) => {
      handlePageChange(1);
      setMyPresetFilters({
        myOpenProjects,
        myAtContractProjects,
        myClosedProjects,
      });
    },
    [handlePageChange]
  );

  const updateSavedFilters = useCallback(async () => {
    setIsLoadingUserFilters(true);
    const savedFiltersResponse = await fetchWrapper(
      getUserFilters,
      organisationId
    );
    if (savedFiltersResponse) {
      const { displayColumns, filters } = savedFiltersResponse;
      setSavedFilters(filters);
      setSavedColumns(
        mapColumnsInCheckboxLabels(
          displayColumns,
          Object.values(AllProjectColumns)
        )
      );
    }
    setIsLoadingUserFilters(false);
  }, [fetchWrapper, organisationId]);

  useEffect(() => {
    void (async () => {
      const response = await fetchWrapper(getProjectFilters, organisationId);

      if (response) setAvailableFilters(response);

      await updateSavedFilters();
    })();
  }, [fetchWrapper, organisationId, updateSavedFilters]);

  useEffect(() => {
    if (history.location.pathname !== "/projects") {
      return;
    }
    const setUpFetchData = async () => {
      try {
        const searchTextQuery: string = debouncedSearchTerm.trim();
        const searchParams = new URLSearchParams(location.search);
        const sortBy: string | null = searchParams.get("sortBy");
        const order: string | null = searchParams.get("order");
        const page: string | null = searchParams.get("page");
        let from = 0;
        setIsLoading(true);
        if (page) {
          from = (Number(page) - 1) * ProjectsPageSize;
        }
        if (
          sortBy &&
          Object.keys(ProjectSortType).includes(sortBy) &&
          order &&
          Object.keys(SortOrder).includes(order)
        ) {
          const sortTyped = sortBy as ProjectSortType;
          const orderTyped = order as SortOrder;
          const response = await fetchProjects(
            { sortBy: sortTyped, order: orderTyped },
            searchTextQuery,
            from
          );
          if (response && response?.results) {
            setProjects(response.results);
            setPaginationOptions({
              total: response.totalResults,
              currentPage: response.currentPage,
            });
            setSortOptions({
              sortBy: sortTyped,
              order: orderTyped,
            });
          }
        } else {
          const defaultSort = {
            sortBy: ProjectSortType.lastUpdated,
            order: SortOrder.DESC,
          };
          const response = await fetchProjects(defaultSort, searchTextQuery);
          if (response && response?.results) {
            setProjects(response.results);
            setPaginationOptions({
              total: response.totalResults,
              currentPage: response.currentPage,
            });
          }
        }
      } catch (e) {
        log(DataDogLogTypes.ERROR, "Error retrieving projects data", e);
        ErrorToast(
          "project-listings-error",
          "There was a problem retrieving projects data. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    };

    void setUpFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchTerm,
    location.search,
    appliedFilters,
    history.location.pathname,
  ]);

  const handleSort = ({ sortBy, order }: IProjectTableSort) => {
    setSortOptions({
      sortBy,
      order,
    });
    history.push({ search: `sortBy=${sortBy}&order=${order}` });
  };

  const handleApplyLabels = (labels: IActiveLabels) => {
    setActiveLabels(labels);
  };

  const handleApplyFilters = (
    clients: string[],
    globalBrands: string[],
    statuses: string[],
    leads: string[],
    contacts: string[],
    creativeAgencies: string[],
    holdingCompanies: string[],
    musicTypes: string[],
    projectTypes: string[],
    serviceTypes: string[],
    projectCloseDateFrom: string | null,
    projectCloseDateTo: string | null,
    jobListingDateFrom: string | null,
    jobListingDateTo: string | null,
    projectRegions: string[],
    officeLocations: string[],
    copyrightInfoProcessed: boolean | null,
    publishingRightsSigned: boolean | null,
    publishingTriggered: boolean | null
  ) => {
    setAppliedFilters({
      clients,
      statuses,
      leads,
      globalBrands,
      contacts,
      creativeAgencies,
      holdingCompanies,
      musicTypes,
      projectTypes,
      serviceTypes,
      projectCloseDateFrom,
      projectCloseDateTo,
      jobListingDateFrom,
      jobListingDateTo,
      projectRegions,
      officeLocations,
      copyrightInfoProcessed,
      publishingRightsSigned,
      publishingTriggered,
    });
  };

  const updateSearchText = (value: string) => {
    handlePageChange(1);
    setSearchText(value);
  };

  return (
    <ProjectsSearchContext.Provider
      value={{
        availableFilters,
        isLoading,
        paginationOptions,
        projects,
        searchText,
        sortOptions,
        debouncedSearchTerm,
        appliedFilters,
        activeLabels,
        noFiltersSet,
        myPresetFilters,
        savedFilters,
        savedColumns,
        isLoadingUserFilters,
        isDuplicatingProject,
        handleDuplicateProject,
        handleSetMyPresetFilters,
        updateSavedFilters,
        clearFilters,
        handleApplyFilters,
        handleApplyLabels,
        handleDeleteProject,
        handleCreateProject,
        updateSearchText,
        clearSearch,
        handlePageChange,
        handleSort,
      }}
    >
      {children}
    </ProjectsSearchContext.Provider>
  );
};

export default ProjectsSearchProvider;
