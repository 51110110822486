import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  stickyHeader: css`
    position: sticky;
    z-index: 1052;
    top: 48px;
    background-color: ${theme.colors.background.white};
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: row;
    padding: 1rem 2rem;
    height: 88px;
    @media ${queries.large} {
      top: 0;
    }
  `,
  typographyTitle: css`
    margin-bottom: 0 !important;
    font-weight: 700 !important;
    font-size: 32px !important;
    align-self: center;
    font-family: ${theme.fonts.sofiaPro};
    position: relative;
    overflow: hidden;
    text-wrap: nowrap;
    line-height: 1.4 !important;
    min-width: 0;
    text-overflow: ellipsis;
    margin-top: 0;
  `,
};
