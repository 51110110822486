import axios from "axios";
import { IFiltersResponse } from "src/pages/projects/project-listings/interfaces";
import { getProjectFiltersUrl } from "../../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const getProjectFilters = (
  accessToken: string,
  organisationId: string
): Promise<IFiltersResponse | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  return axios
    .get(getProjectFiltersUrl(organisationId), options)
    .then(({ data }) => data as IFiltersResponse);
};

export default getProjectFilters;
