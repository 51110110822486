import { ITalentHubTypes } from "src/api/talent-hub/interfaces";
import {
  IContactsResults,
  INote,
  Neurodiverse,
  PhysicalCondition,
  Signed,
} from "src/pages/contact/interfaces";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import { formatPriceWithCurrency } from "src/utils/currency";

export interface IContactMappedData {
  id: string;
  name?: string;
  pronoun?: string;
  agencyWritingPartnership?: string;
  email?: string;
  phone?: string;
  baseLocation?: string;
  socialMediaWebsite?: string;
  showreelUrls?: string;
  mainLanguage?: string;
  otherLanguages?: string;
  billingName?: string;
  billingAddress?: string;
  neurodiverse?: string;
  neurodiverseNote?: string;
  physicalConditionsIllness?: string;
  racialCharacteristics?: string;
  genderIdentity?: string;
  sexualOrientation?: string;
  signedCMA?: string;
  signedNDA?: string;
  minimumDemoFee?: string;
  performingRightsOrganisationNumber?: string;
  collaborationStatus?: string;
  skillSet?: string;
  principalInstruments?: string;
  additionalInstruments?: string;
  keyGenres?: string;
  additionalGenres?: string;
  vocalist?: string;
  minimumDemoTurnaroundHours?: string;
  previousWork?: string;
  interestedProjects?: string;
  talentNotes?: INote[];
  awardNotes?: INote[];
  internalNotes?: INote[];
  createdByOrgId: string;
}

const mapContactDataForViewContact = (
  data: IContactsResults | null,
  talentHubTypes: ITalentHubTypes
): IContactMappedData => {
  if (!data) {
    return { id: "", createdByOrgId: "" };
  }

  const findOptionLabel = (options: IOption[], value?: string | null) => {
    return (options ?? []).find((option) => option.value === value)?.label;
  };
  const signedOptions = Object.entries(Signed).map(([value, label]) => ({
    value,
    label,
  }));

  let vocalist = "";
  if (data.vocalist) {
    vocalist = "Yes";
  } else if (data.vocalist === false) {
    vocalist = "No";
  }

  return {
    id: data.id,
    name: data.name,
    pronoun: findOptionLabel(talentHubTypes.genderPronouns, data.pronoun),
    agencyWritingPartnership: data.agencyWritingPartnership,
    email: data.email,
    phone: data.phone?.fullNumber,
    baseLocation: findOptionLabel(
      talentHubTypes.countryCodes,
      data.baseLocation
    ),
    socialMediaWebsite: data.socialMediaWebsite?.join(", "),
    showreelUrls: data.showreelUrls?.join(", "),
    mainLanguage: findOptionLabel(
      talentHubTypes.languageCodes,
      data.mainLanguage
    ),
    otherLanguages: (data.otherLanguages ?? [])
      .map((lang) => findOptionLabel(talentHubTypes.languageCodes, lang))
      ?.join(", "),
    billingName: data.billingName,
    billingAddress: data.billingAddress,
    neurodiverse: findOptionLabel(
      Object.entries(Neurodiverse).map(([value, label]) => ({
        label,
        value,
      })),
      data.neurodiverse?.toString()
    ),
    neurodiverseNote: data.neurodiverseNote,
    physicalConditionsIllness: findOptionLabel(
      Object.entries(PhysicalCondition).map(([value, label]) => ({
        value,
        label,
      })),
      data.physicalConditionsIllness
    ),
    racialCharacteristics: findOptionLabel(
      talentHubTypes.racialCharacteristics,
      data.racialCharacteristics
    ),
    genderIdentity: findOptionLabel(
      talentHubTypes.genderIdentities,
      data.genderIdentity
    ),
    sexualOrientation: findOptionLabel(
      talentHubTypes.sexualOrientations,
      data.sexualOrientation
    ),
    signedCMA: findOptionLabel(signedOptions, data.signedCMA?.toString()),
    signedNDA: findOptionLabel(signedOptions, data.signedNDA?.toString()),
    minimumDemoFee: data.minimumDemoFee?.value
      ? formatPriceWithCurrency(
          data.minimumDemoFee.value,
          data.minimumDemoFee.currency
        )
      : "",
    performingRightsOrganisationNumber: data.performingRightsOrganisationNumber,
    collaborationStatus: findOptionLabel(
      talentHubTypes.talentCollaborations,
      data.collaborationStatus
    ),
    skillSet: (data.skillSet ?? [])
      .map((skill) => findOptionLabel(talentHubTypes.skillSets, skill))
      ?.join(", "),
    principalInstruments: (data.principalInstruments ?? [])
      .map((instrument) =>
        findOptionLabel(talentHubTypes.talentInstruments, instrument)
      )
      ?.join(", "),
    additionalInstruments: (data.additionalInstruments ?? [])
      .map((instrument) =>
        findOptionLabel(talentHubTypes.talentInstruments, instrument)
      )
      ?.join(", "),
    keyGenres: (data.keyGenres ?? [])
      .map((genre) => findOptionLabel(talentHubTypes.musicGenres, genre))
      ?.join(", "),
    additionalGenres: (data.additionalGenres ?? [])
      .map((genre) => findOptionLabel(talentHubTypes.musicGenres, genre))
      ?.join(", "),
    vocalist,
    minimumDemoTurnaroundHours: data.minimumDemoTurnaroundHours
      ? data.minimumDemoTurnaroundHours.toString()
      : "",
    previousWork: (data.previousWork ?? [])
      .map((work) => findOptionLabel(talentHubTypes.talentWorkTypes, work))
      ?.join(", "),
    interestedProjects: (data.interestedProjects ?? [])
      .map((work) => findOptionLabel(talentHubTypes.talentWorkTypes, work))
      ?.join(", "),
    talentNotes: data?.talentNotes ?? [],
    awardNotes: data?.awardNotes ?? [],
    internalNotes: data?.internalNotes ?? [],
    createdByOrgId: data?.createdByOrgId ?? "",
  };
};

export default mapContactDataForViewContact;
