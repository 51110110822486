import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import BaseModal from "src/components/modals/base-modal";
import Content from "src/components/modals/confirmation-modal/content";
import { IConfirmContentProps } from "src/components/interfaces";
import {
  IFinalTrackSection,
  IService,
} from "src/pages/projects/project/interfaces";
import DeleteButton from "src/components/control-buttons/delete-button";
import useProject from "src/providers/project/hooks";
import { startCase } from "lodash";
import style from "../../../../styles";

interface IProps {
  finalTrack: IFinalTrackSection;
  onDeleteClick: (projectId: string, trackId?: string) => void;
}
const CollapsibleHeaderDeleteAndDuplicate = ({
  finalTrack,
  onDeleteClick,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { project } = useProject();

  const handleOnCloseModalClick = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleConfirmDeleteClick = useCallback(() => {
    onDeleteClick(project.id, finalTrack.id);

    setIsModalOpen(false);
  }, [finalTrack.id, onDeleteClick, project.id]);

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setIsModalOpen(true);
    },
    []
  );

  const confirmDeleteContentProps: IConfirmContentProps = useMemo(() => {
    return {
      confirmAction: handleConfirmDeleteClick,
      onClose: handleOnCloseModalClick,
      primaryButtonLabel: t("Delete"),
      secondaryButtonLabel: t("Cancel"),
      mainContent: (
        <p data-testid="confirmation-content">
          <Trans i18nKey="ProjectsPage##musicSection##Are you sure?">
            {{
              trackName: finalTrack?.title ? startCase(finalTrack.title) : "-",
            }}
          </Trans>
        </p>
      ),
    };
  }, [finalTrack.title, handleConfirmDeleteClick, handleOnCloseModalClick, t]);

  return (
    <div css={style.switchContainer}>
      <DeleteButton onClick={handleDeleteClick} />
      <BaseModal
        open={isModalOpen}
        content={<Content {...confirmDeleteContentProps} />}
        buttons={[]}
        onClose={handleOnCloseModalClick}
        onCancel={handleOnCloseModalClick}
      />
    </div>
  );
};

export default CollapsibleHeaderDeleteAndDuplicate;
