import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  fileUploadTable: css`
    height: 100%;
    .ant-table-row:hover {
      outline: 0 !important;
    }
    .ant-table-thead > tr > th {
      font-size: 16px;
      background: none;
      padding: 5px !important;
      color: ${theme.colors.secondary.navyBlue};
      width: fit-content;
      max-width: 10rem !important;
      ::before {
        content: none !important;
      }
    }
    .ant-table-tbody > tr > td {
      font-size: 16px;
      padding: 5px !important;
      width: fit-content;
      max-width: 5rem !important;
    }
  `,
  fileUploadText: css`
    margin-top: 1.25rem;
  `,
  browseBtn: css`
    color: ${theme.colors.primary.blue};
    font-weight: bold;
    .ant-upload-drag-container {
      cursor: pointer;
    }
    pointer-events: auto;
  `,
  fileUploadMobile: css`
    h1 {
      font-size: 1rem;
      color: ${theme.colors.secondary.navyBlue};
    }
    font-size: 1rem;
    text-align: left;
  `,
  fileUploadButtonMobile: css`
    margin-top: 1.25rem;
    text-align: center;
  `,
};
