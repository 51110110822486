import axios from "axios";
import getAuthHeader from "../../headers/get-auth-header";
import { getOrganisationDeleteInviteUrl } from "../../organisation-invites-url-helper";
import { getJsonContentHeader } from "../../headers";

const deleteOrganisationInvite = (
  organisationId: string,
  organisationInviteId: string,
  accessToken: string
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };
  return axios.delete(
    getOrganisationDeleteInviteUrl(organisationId, organisationInviteId),
    options
  );
};

export default deleteOrganisationInvite;
