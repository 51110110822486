import React from "react";
import { Trans } from "react-i18next";

interface IContentProps {
  inviteEmail: string;
}

const DeleteTeamInviteModalMainContent = (
  contentProps: IContentProps
): JSX.Element => {
  const i18nKey = "manageTeam##invites##About to delete";
  const { inviteEmail } = contentProps;

  return (
    <p data-testid="main-Text">
      <Trans i18nKey={i18nKey}>{{ inviteEmail }}</Trans>
    </p>
  );
};

export default DeleteTeamInviteModalMainContent;
