import React from "react";
import { Trans } from "react-i18next";

interface IContentProps {
  memberName: string;
}

const DeleteTeamMemberModalMainContent = (
  contentProps: IContentProps
): JSX.Element => {
  const i18nKey = "manageTeam##members##About to delete";
  const { memberName: teamMemberName } = contentProps;

  return (
    <p data-testid="main-Text">
      <Trans i18nKey={i18nKey}>{{ teamMemberName }}</Trans>
    </p>
  );
};

export default DeleteTeamMemberModalMainContent;
