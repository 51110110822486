import React, { ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Form, Typography } from "antd";
import Button from "src/components/button";
import styles from "src/components/modals/styles";
import STLoadingLogo from "src/components/st-loading-logo";
import { IFormContacts } from "src/pages/projects/project/interfaces";

interface IProps {
  data: IFormContacts;
  index: number;
  onClose?: () => void;
  handleDeleteContact: (index: number) => void;
}

const Content = ({
  data,
  onClose,
  index,
  handleDeleteContact,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  if (!data) {
    return <STLoadingLogo />;
  }

  return (
    <div data-testid="delete-project-modal">
      <Typography.Title css={styles.modalTitle}>
        {t("ProjectsPage##contactsSection##deleteModalHeader")}
      </Typography.Title>
      <Form>
        <p>
          <Trans i18nKey="ProjectsPage##contactsSection##confirmationPrompt">
            {{ contactName: data.name || data.email }}
          </Trans>
        </p>
        <div css={styles.footer}>
          <Button ariaLabel={t("Cancel")} type="secondary" onClick={onClose}>
            {t("Cancel")}
          </Button>
          <Button
            ariaLabel={t("ProjectsPage##contactsSection##delete")}
            htmlType="submit"
            data-testid="delete-contact-button"
            onClick={() => handleDeleteContact(index)}
          >
            {t("ProjectsPage##contactsSection##delete")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Content;
