import { ReactElement, ReactNode } from "react";
import { IContactsResults } from "src/pages/contact/interfaces";
import { IPaginationOptions } from "src/pages/projects/project-listings/interfaces";

type AlignType = "left" | "center" | "right";

export interface ITalentDataSource {
  key: string;
  id: string;
  name: ReactElement;
  skillSet: ReactElement;
  instruments: ReactElement;
  genres: ReactElement;
  vocals: ReactElement;
  rating: ReactElement;
}

interface IBaseContactsFilters {
  collaboration: string[];
  genre: string[];
  instrument: string[];
  language: string[];
  location: string[];
  skillSet: string[];
}
export interface ITalentContactGroupsResponse {
  id: string | null;
  name: string | null;
  size: number;
  isUserGroup: boolean;
  talents: IContactsResults[];
  created: string | null;
  createdBy: string | null;
  lastUpdated: string | null;
  lastUpdatedBy: string | null;
}
export interface IContactsFilters extends IBaseContactsFilters {
  vocalist: (boolean | null)[];
  serviceProvider: (boolean | null)[];
  racialCharacteristic: string[];
  genderIdentity: string[];
  signedNDA: string[];
  signedCMA: string[];
  [key: string]: string[] | (boolean | null)[];
}
export interface IContactsPostFilters extends IBaseContactsFilters {
  vocalist: boolean | null;
  serviceProvider: boolean | null;
}
export interface ITalentTab {
  tabKey: string;
  tabTitle: ReactElement;
  dataSource: ITalentDataSource[];
  rawData: IContactsResults[];
  totalResults: number;
  paginationOptions?: IPaginationOptions;
  onPageChange?: (page: number) => void;
  availableContactFilters?: IContactsFilters;
  selectedContactFilters?: IContactsFilters;
  searchText?: string;
  onUpdateSearchText?: (searchText: string) => void;
  onFetchSelectedFilters?: (selectedFilters: IContactsFilters) => void;
}

export enum AllContactsFiltersValues {
  SkillSet = "skillSet",
  Collaboration = "collaboration",
  Genre = "genre",
  Instrument = "instrument",
  Language = "language",
  Location = "location",
  Vocalist = "vocalist",
  ServiceProvider = "serviceProvider",
  RacialCharacteristics = "racialCharacteristic",
  GenderIdentity = "genderIdentity",
  NDASigned = "signedNDA",
  CMASigned = "signedCMA",
}

export enum CheckedLabels {
  VocalistNo = "Is vocalist?: No",
  VocalistYes = "Is vocalist?: Yes",
  ServiceProviderNo = "Is service provider?: No",
  ServiceProviderYes = "Is service provider?: Yes",
}

export interface IColumnsData {
  title?: ReactNode;
  dataIndex: string;
  key: string;
  width: number | string;
  className?: string;
  align?: AlignType;
  fixed?: "left" | "right";
}

export interface INoContactsProps {
  handleCreateContact: () => void;
}
