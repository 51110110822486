import { Typography } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import FileUploader from "src/components/file-upload";
import { IDeleteSelectedFile } from "src/components/file-upload/interfaces";
import { AttachmentType } from "src/constants";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import { IFinalTrackFilesDataSource } from "../../../music/components/final-track-attachments/table-data/interfaces";
import mainStyle from "../../../../styles";

type IProps = {
  finalTrackFilesList: Array<UploadFile>;
  onSetFinalTrackFilesList: (files: UploadFile[]) => void;
  tableData: Array<IFinalTrackFilesDataSource>;
  selectedFileToDelete?: IDeleteSelectedFile;
  tableColumns: IColumnsData[];
  showFileUploadDeleteModal: boolean;
  onShowFileUploadDeleteModal: (show: boolean) => void;
  trackId?: string;
};
const FinalTracksUploader = ({
  finalTrackFilesList,
  onSetFinalTrackFilesList,
  tableData,
  selectedFileToDelete,
  tableColumns,
  showFileUploadDeleteModal,
  trackId,
  onShowFileUploadDeleteModal,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <div css={mainStyle.sectionFormContainer}>
      <Typography.Text css={mainStyle.trackTitleLabel}>
        {t("ProjectsPage##musicSection##Assets")}
      </Typography.Text>
      <FileUploader
        attachmentType={AttachmentType.Track}
        invalidFileSizeErrorMessage={t(
          "File size exceeds maximum limit of 2GB"
        )}
        existingFiles={finalTrackFilesList}
        setFileList={onSetFinalTrackFilesList}
        tableData={tableData}
        selectedFileToDelete={selectedFileToDelete}
        tableColumns={tableColumns}
        showModal={showFileUploadDeleteModal}
        setShowModal={onShowFileUploadDeleteModal}
        trackId={trackId}
      />
    </div>
  );
};

export default FinalTracksUploader;
