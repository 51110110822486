import { createContext } from "react";
import {
  ISavedUserColumn,
  ISavedUserFilter,
} from "src/api/user-filters/interfaces";
import {
  IProjectProps,
  IProjectTableSort,
  ProjectSortType,
  SortOrder,
  IPaginationOptions,
  IFiltersResponse,
  IFiltersSearch,
  IActiveLabels,
  IMyPresetFilters,
} from "src/pages/projects/project-listings/interfaces";

export interface IProjectSearchProviderContext {
  isLoading: boolean;
  paginationOptions: IPaginationOptions;
  projects: IProjectProps[];
  savedFilters: ISavedUserFilter[] | null;
  savedColumns: ISavedUserColumn[] | null;
  isLoadingUserFilters: boolean;
  searchText: string;
  debouncedSearchTerm: string;
  sortOptions: IProjectTableSort;
  appliedFilters: IFiltersSearch;
  availableFilters: IFiltersResponse;
  isDuplicatingProject: boolean;
  activeLabels: IActiveLabels;
  myPresetFilters: IMyPresetFilters;
  noFiltersSet: boolean;
  clearFilters: () => void;
  handleDeleteProject: (id: string) => void;
  updateSearchText: (value: string) => void;
  clearSearch: () => void;
  handleCreateProject: (name: string, status: string) => void;
  handlePageChange: (page: number) => void;
  handleSort: ({ sortBy, order }: IProjectTableSort) => void;
  handleApplyLabels: (labels: IActiveLabels) => void;
  handleApplyFilters: (
    clients: string[],
    globalBrands: string[],
    statuses: string[],
    leads: string[],
    contacts: string[],
    creativeAgencies: string[],
    holdingCompanies: string[],
    musicTypes: string[],
    projectTypes: string[],
    serviceTypes: string[],
    projectCloseDateFrom: string | null,
    projectCloseDateTo: string | null,
    jobListingDateFrom: string | null,
    jobListingDateTo: string | null,
    projectRegions: string[],
    officeLocations: string[],
    copyrightInfoProcessed: boolean | null,
    publishingRightsSigned: boolean | null,
    publishingTriggered: boolean | null
  ) => void;
  handleSetMyPresetFilters: (
    myOpenProjects: boolean,
    myAtContractProjects: boolean,
    myClosedProjects: boolean
  ) => void;
  updateSavedFilters: () => Promise<void>;

  handleDuplicateProject: (id: string) => void;
}

export default createContext<IProjectSearchProviderContext>({
  isLoading: true,
  paginationOptions: {
    total: 0,
    currentPage: 1,
  },
  projects: [],
  isDuplicatingProject: false,
  clearSearch: () => {},
  searchText: "",
  debouncedSearchTerm: "",
  updateSearchText: () => {},
  sortOptions: {
    sortBy: ProjectSortType.lastUpdated,
    order: SortOrder.ASC,
  },
  activeLabels: {
    statuses: [],
    clients: [],
    leads: [],
    globalBrands: [],
    contacts: [],
    creativeAgencies: [],
    holdingCompanies: [],
    musicTypes: [],
    projectTypes: [],
    serviceTypes: [],
    projectCloseDate: [],
    jobListingDate: [],
    projectRegions: [],
    officeLocations: [],
    copyrightInfoProcessed: [],
    publishingRightsSigned: [],
    publishingTriggered: [],
  },
  handleApplyLabels: (labels: IActiveLabels) => labels,
  handleDeleteProject: () => {},
  handleDuplicateProject: () => {},
  handleCreateProject: () => {},
  handlePageChange: (page: number) => page,
  handleSort: ({ sortBy, order }: IProjectTableSort) => ({ sortBy, order }),
  appliedFilters: {
    statuses: [],
    clients: [],
    leads: [],
    globalBrands: [],
    contacts: [],
    creativeAgencies: [],
    holdingCompanies: [],
    musicTypes: [],
    projectTypes: [],
    serviceTypes: [],
    projectCloseDateFrom: null,
    projectCloseDateTo: null,
    jobListingDateFrom: null,
    jobListingDateTo: null,
    projectRegions: [],
    officeLocations: [],
    copyrightInfoProcessed: null,
    publishingRightsSigned: null,
    publishingTriggered: null,
  },
  availableFilters: {
    statuses: [],
    clients: [],
    leads: [],
    globalBrands: [],
    contacts: [],
    creativeAgencies: [],
    holdingCompanies: [],
    musicTypes: [],
    projectTypes: [],
    serviceTypes: [],
    projectRegions: [],
    officeLocations: [],
    copyrightInfoProcessed: null,
    publishingRightsSigned: null,
    publishingTriggered: null,
  },
  clearFilters: () => {},
  handleApplyFilters: (
    clients: string[],
    globalBrands: string[],
    statuses: string[],
    leads: string[],
    contacts: string[],
    creativeAgencies: string[],
    holdingCompanies: string[],
    musicTypes: string[],
    projectTypes: string[],
    serviceTypes: string[],
    projectCloseDateFrom: string | null,
    projectCloseDateTo: string | null,
    jobListingDateFrom: string | null,
    jobListingDateTo: string | null,
    projectRegions: string[],
    officeLocations: string[] | null,
    copyrightInfoProcessed: boolean | null,
    publishingRightsSigned: boolean | null,
    publishingTriggered: boolean | null
  ) => ({
    clients,
    globalBrands,
    statuses,
    leads,
    contacts,
    creativeAgencies,
    holdingCompanies,
    musicTypes,
    projectTypes,
    serviceTypes,
    projectCloseDateFrom,
    projectCloseDateTo,
    jobListingDateFrom,
    jobListingDateTo,
    projectRegions,
    officeLocations,
    copyrightInfoProcessed,
    publishingRightsSigned,
    publishingTriggered,
  }),
  noFiltersSet: true,
  myPresetFilters: {
    myOpenProjects: false,
    myAtContractProjects: false,
    myClosedProjects: false,
  },
  handleSetMyPresetFilters: () => {},
  updateSavedFilters: async () => {},
  savedFilters: null,
  savedColumns: null,
  isLoadingUserFilters: true,
});
