import { Typography } from "antd";
import { memo, ReactElement, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Tags from "src/components/tags";
import { IProjectTag } from "../../interfaces";
import styles from "../../styles";

interface IProps {
  projectTags?: IProjectTag[];
  updateProjectTags: (tags: IProjectTag[]) => void;
}

const ProjectTags = memo(
  ({ projectTags, updateProjectTags }: IProps): ReactElement => {
    const { t } = useTranslation();

    const tags = useMemo(() => (projectTags ?? []).map((tag) => tag.tagName), [
      projectTags,
    ]);

    const handleUpdateTags = useCallback(
      (updatedTags: string[]) => {
        const newTags = updatedTags.map((tag) => ({
          tagName: tag,
        }));
        updateProjectTags(newTags);
      },
      [updateProjectTags]
    );

    return (
      <div css={styles.sectionContainer}>
        <div>
          <div css={styles.titleLabelContainer}>
            <Typography.Text css={styles.titleLabel}>
              {t("ProjectsPage##Tags")}
            </Typography.Text>
          </div>
          <div css={styles.tagsSectionBottom}>
            <Tags selectedTags={tags} onUpdate={handleUpdateTags} />
          </div>
        </div>
      </div>
    );
  }
);

export default ProjectTags;
