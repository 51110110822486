import {
  AllContactsFiltersValues,
  IContactsFilters,
} from "src/pages/talent-hub/interfaces";
import { IOption } from "src/pages/projects/project/components/account-information/types";
import getFilterLabel from "./getLabelFromFilterValue";

const getFilterOptionsWithConvertedValuesToString = (
  filterValue: string,
  availableFilters: IContactsFilters
): IOption[] => {
  // We have arrays of strings and boolean, so we need to convert booleans to strings
  // We must not forget to change booleans back to string when sending data to backend
  const specificFilterValues = availableFilters[filterValue] as string[];
  return (specificFilterValues ?? []).map((value: string | boolean) => {
    let label = "";
    if (filterValue === AllContactsFiltersValues.Vocalist) {
      label = `Is vocalist?: ${value ? "Yes" : "No"}`;
    } else if (filterValue === AllContactsFiltersValues.ServiceProvider) {
      label = `Is service provider?: ${value ? "Yes" : "No"}`;
    } else {
      label = getFilterLabel(String(value));
    }
    return {
      value: String(value),
      label,
    };
  });
};

export default getFilterOptionsWithConvertedValuesToString;
